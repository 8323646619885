//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import TeamInfoTable from './TeamInfoTable';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
const AdminInformation = props => {
  const { register, setValue, currentValues = {}, isEditing = true, option } = props;
  const fields = {
    execution_credit: 100
  };
  const { t } = useLanguageHooks([TRANS_KEYS.BD_TEAM_ADMIN_LABEL, TRANS_KEYS.BD_TEAM_ADMIN_TITLE]);
  const [admins, setAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState([]);

  useEffect(() => {
    register('project_admin');
    setValue('project_admin', admins, { shouldDirty: true });
  }, [register, admins, setValue]);

  useEffect(() => {
    if (admins && admins.length > 0) {
      const options = option?.filter(array_el => {
        return admins.every(anotherOne_el => anotherOne_el?.user?.id !== array_el?.id);
      });
      setSelectedAdmin(options);
    } else {
      setSelectedAdmin(option);
    }
  }, [register, admins, setValue]);

  useEffect(() => {
    setAdmins(currentValues.project_admin);
  }, [currentValues.project_admin]);
  return (
    <>
      <TeamInfoTable
        title={t(`${TRANS_KEYS.BD_TEAM_ADMIN_TITLE}:key`)}
        label={t(`${TRANS_KEYS.BD_TEAM_ADMIN_LABEL}:key`)}
        inputConfig={{ list: admins, fields, setter: setAdmins }}
        isEditing={isEditing}
        option={selectedAdmin}
      />
    </>
  );
};

AdminInformation.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  option: PropTypes.array
};

export default AdminInformation;
