//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchPickList } from '../../../actions';

function LocationSelection(props) {
  const { register, className = 'w-100', label = 'Location', name = 'location', placeholder = 'Location', InputLabelProps, ...rest } = props;
  const [options, setOptions] = useState([]);
  const locations = useSelector(state => state.commonReducer.locations);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!locations) {
      dispatch(fetchPickList('locations'));
    } else {
      setOptions(locations);
    }
  }, [locations, dispatch]);

  return (
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      getOptionSelected={(option, value) => {
        if (!value) {
          return false;
        }
        if (typeof value === 'string') {
          return option.name === value;
        }
        return option.name === value.name;
      }}
      renderInput={params => <TextField {...params} inputRef={register} name={name} InputLabelProps={InputLabelProps} label={label} placeholder={placeholder} />}
    />
  );
}

LocationSelection.propTypes = {
  register: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object
};

export default LocationSelection;
