import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../services/axios';
import { PUBLIC_ROUTES, ROUTES } from '../../services/constantService';
import { getDynamicUrl } from '../../utils/common';

const params = new URLSearchParams(window.location.search);

const Login = () => {
  const path = params.get('url');
  const navigate = useNavigate();

  useEffect(() => {
    if (path && PUBLIC_ROUTES.includes(path.split('/')[1])) {
      return;
    } else {
      // The url should be TLD with .app inserted in between subdomain and domain
      const url = window.location.origin,
        subdomain = url.split('.')[0];

      const restOfDomain = url.substring(url.indexOf('.') + 1); // Get the rest of the domain

      const dynamicUrl = getDynamicUrl(subdomain, restOfDomain);
      axios
        .get(dynamicUrl, {
          withCredentials: true
        })
        .then(res => {
          try {
            if (res && res.status === 200) {
              if (res.data.alive === true) {
                //TODO Here we will get the security data for the user
                //TODO Save the User Security data in redux store
                //? If the user is logged in, redirect to the url passed in the query params
                if (path) {
                  navigate(path);
                } else {
                  navigate('/');
                }
              } else {
                // If the user is not logged in, call login api forcefully with current location
                if (!dynamicUrl.includes('localhost') && !process.env.REACT_APP_IS_LOCAL) {
                  navigate(ROUTES.unauthorized);
                }
              }
            } else {
              alert('Login - User is not authenticated / CORS issue');
              // If the user is not logged in, call login api forcefully with current location
              if (!dynamicUrl.includes('localhost') && !process.env.REACT_APP_IS_LOCAL) {
                navigate(ROUTES.unauthorized);
              }
            }
          } catch {
            console.error('Error in UseUserSecurityHook', res);
          }
        });
    }
  }, []);
  return <div></div>;
};

export default Login;
