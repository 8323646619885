import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Step, StepConnector, StepLabel, Stepper } from '@mui/material';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    //   top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: '#009d81 !important'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#009d81 !important'
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#c3c3c35c',
    borderRadius: 1
  }
})(StepConnector);

const CustomStepper = props => {
  const { navItems, steppersProps = {}, tabValue, handleChange, connector = <ColorlibConnector /> } = props;

  // const useStyles = makeStyles(() => ({
  //   container: {
  //     backgroundColor: 'transparent',
  //     // cursor: 'pointer'
  //   },
  // }));

  // const classes = useStyles();

  return (
    <Stepper alternativeLabel sx={{ backgroundColor: 'transparent' }} activeStep={tabValue} connector={connector}>
      {navItems?.map((label, index) => (
        <Step className='step' {...steppersProps} onClick={() => handleChange(index)} key={label}>
          <StepLabel>
            <span className={`${index === tabValue ? 'step-child-border' : ''} step-child`}>{label}</span>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

CustomStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  // labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  navItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  steppersProps: PropTypes.object,
  tabValue: PropTypes.number,
  handleChange: PropTypes.func,
  connector: PropTypes.node
};

export default CustomStepper;
