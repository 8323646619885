//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Typography, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../../../../common/InputField';

export default function TargetOffLimits(props) {
  const { register, setValue, currentValues = {}, unregister, readOnly } = props;
  const [offLimits, setOffLimits] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);

  useEffect(() => {
    register('off_limits');
    setOffLimits(currentValues.off_limits || []);
    return () => {
      unregister('off_limits');
    };
  }, [currentValues.off_limits, register, setValue]);

  // useEffect(() => {

  //   setValue('off_limits',offLimits);
  // }, [register, offLimits, setValue])

  const updateOffLimits = (index, data) => {
    const currentOffLimits = [...offLimits];
    currentOffLimits[index] = data;
    setOffLimits(currentOffLimits);
    if (updateCount > 1) {
      register('off_limits');
      setValue('off_limits', currentOffLimits);
    }
    setUpdateCount(updateCount + 1);
  };

  const deleteOffLimit = index => {
    const currentOffLimits = [...offLimits];
    const callApi = Object.keys(currentOffLimits[index])?.length > 0 ? true : false;
    currentOffLimits.splice(index, 1);
    setOffLimits(currentOffLimits);
    if (callApi) {
      register('off_limits');
      setValue('off_limits', currentOffLimits);
    }
  };

  const addOffLimit = () => {
    const currentOffLimits = [...offLimits, ''];
    setOffLimits(currentOffLimits);
  };
  return (
    <div className={`section-content ${readOnly ? 'read-only' : ''}`}>
      <Typography className='section-label'>Off Limits</Typography>
      {offLimits &&
        offLimits.map((item, index) => (
          <Grid container spacing={1} key={index}>
            <Grid container>
              <Grid item sm={10} style={{ marginTop: '-2%' }}>
                <div className='d-flex flex-grow-1'>
                  <InputField
                    className='input-form-field input-field-old'
                    value={item || ''}
                    InputProps={{
                      startAdornment: (
                        <Typography style={{ padding: '0 5px' }} color='primary'>
                          {index + 1}.
                        </Typography>
                      )
                    }}
                    InputLabelProps={{ focused: true }}
                    onChange={e => {
                      updateOffLimits(index, e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <DeleteIcon onClick={() => deleteOffLimit(index)} className='cursor-pointer' />
            </Grid>
          </Grid>
        ))}
      <Button variant={'text'} startIcon={<AddCircleIcon />} onClick={addOffLimit} color='primary'>
        Add
      </Button>
    </div>
  );
}

TargetOffLimits.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  readOnly: PropTypes.bool,
  currentValues: PropTypes.object,
  unregister: PropTypes.func
};
