//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';

export default function ConfirmationPopup(props) {
  const { header = 'Confirm', message = '', extraClassName = '', onCancel, onClose, onConfirm, cancelText = 'No', confirmText = 'Yes', disabledCancel = false } = props;
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const handleCancel = () => {
    if (onCancel) {
      setOpen(false);
      onCancel && onCancel();
    } else {
      handleClose();
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    onConfirm && onConfirm();
  };

  return (
    <Popup overlayStyle={{ zIndex: '1401 !important' }} open={open} modal closeOnDocumentClick={false} closeOnEscape={false} className='confirmation-popup'>
      <>
        <div style={{ minWidth: 400 }} className='confirmation-content d-flex'>
          <div className='flex-grow-1 d-flex flex-column pr-5'>
            <div className='confirmation-header' dangerouslySetInnerHTML={{ __html: header }}></div>
            {message && <div className={`confirmation-message ${extraClassName}`} dangerouslySetInnerHTML={{ __html: message }}></div>}
          </div>
          <span className='close-icon d-flex align-items-center' onClick={handleClose}>
            <CloseIcon fontSize='inherit' />
          </span>
        </div>
        <div className='action-buttons'>
          {!disabledCancel && (
            <Button style={{ marginRight: 10 }} variant='text' color='primary' className='button' onClick={handleCancel}>
              {cancelText}
            </Button>
          )}
          <Button variant='contained' className='button' color='primary' onClick={handleConfirm}>
            {confirmText}
          </Button>
        </div>
      </>
    </Popup>
  );
}

ConfirmationPopup.propTypes = {
  header: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onCancel: PropTypes.func,
  extraClassName: PropTypes.string,
  disabledCancel: PropTypes.bool
};
