/* eslint-disable no-prototype-builtins */
/* eslint-disable dot-notation */

const toProperCase = dataString => {
  return dataString.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const processZoomVtt = inputVtt => {
  const outputArray = [];
  const fileArray = inputVtt.toString().split('\n');
  let speaker_list = ['All'];
  const utterance_by_speaker = {};

  const value_json = {
    speakers: ['All'],
    parsed_json: [],
    by_speaker: {}
  };

  if (fileArray[0].trim() === 'WEBVTT') {
    for (let line = 0; line < fileArray.length; line++) {
      if (fileArray[line].includes(' --> ')) {
        let [speaker, ...utterance] = ['', ''];

        if (fileArray[line + 1].includes(':')) {
          [speaker, ...utterance] = fileArray[line + 1].split(':');
        } else {
          [speaker, ...utterance] = ['Speaker not Identified', fileArray[line + 1]];
        }
        const current_speaker = speaker.trim();

        const outputItem = {
          index: Number(fileArray[line - 1]),
          starttime: fileArray[line].split(' --> ')[0],
          endtime: fileArray[line].split(' --> ')[1],
          speaker: speaker.trim(),
          text: utterance.join(':').trim()
        };

        speaker_list = [...new Set(speaker_list.concat(current_speaker))];

        if (!utterance_by_speaker.hasOwnProperty(current_speaker)) {
          utterance_by_speaker[current_speaker] = [];
        }

        utterance_by_speaker[current_speaker].push({
          text: utterance.join(':').trim(),
          index: Number(fileArray[line - 1]),
          starttime: fileArray[line].split(' --> ')[0],
          endtime: fileArray[line].split(' --> ')[1]
        });

        outputArray.push(outputItem);
      }
    }

    value_json.parsed_json = outputArray;
    value_json.speakers = speaker_list;
    value_json.by_speaker = utterance_by_speaker;

    console.log(`Parsed ${outputArray.length} items`);
    return value_json;
  } else {
    return { error: 'Invalid File!' };
  }
};

export const vttToJson = vtt_value => {
  let more_tags = true;
  let tag_start_index = 0;
  let tag_close_index = 0;
  let value_close_index = 0;
  let tag_start_from = 0;
  let tag_name = '';
  let tag_value = '';
  let index = 1;

  const tag_begin_character = '<v';
  const tag_end_character = '>';
  const tag_close_character = '</v>';

  const value_json = {
    speakers: ['All'],
    parsed_json: [],
    by_speaker: {}
  };

  if (vtt_value.indexOf(tag_begin_character, tag_start_from) < 0) {
    return processZoomVtt(vtt_value.toString());
  }

  while (more_tags) {
    tag_start_index = vtt_value.indexOf(tag_begin_character, tag_start_from);

    if (tag_start_index > 0) {
      tag_close_index = vtt_value.indexOf(tag_end_character, tag_start_index);
      value_close_index = vtt_value.indexOf(tag_close_character, tag_close_index);

      tag_name = toProperCase(vtt_value.substring(tag_start_index + tag_begin_character.length, tag_close_index).trim());
      tag_value = vtt_value.substring(tag_close_index + tag_end_character.length, value_close_index).trim();

      value_json.parsed_json.push({ speaker: tag_name, text: tag_value, index });

      if (!value_json.by_speaker.hasOwnProperty(tag_name)) {
        value_json.by_speaker[tag_name] = [];
      }

      value_json.by_speaker[tag_name].push({ text: tag_value, index });

      if (!value_json.speakers.includes(tag_name)) {
        value_json.speakers.push(tag_name);
      }

      tag_start_from = value_close_index + tag_close_character.length;
      index = index + 1;
    } else {
      more_tags = false;
    }
  }

  return value_json;
};
export const awsTranscribeToJson = input_payload => {
  const transcript_items = input_payload['results']['items'];
  let current_speaker = '';
  let current_speaker_text = '';
  let current_start_time = '';
  let current_end_time = '';
  const utterance_array = [];
  let utterance_index = 0;
  let speaker_list = ['All'];
  const utterance_by_speaker = {};

  for (const item in transcript_items) {
    // console.log('current_speaker:', current_speaker);
    // console.log(transcript_items[item]['speaker_label']);
    if (current_speaker !== transcript_items[item]['speaker_label']) {
      if (current_speaker.length > 0 && current_speaker_text.length > 0) {
        utterance_index += 1;
        utterance_array.push({
          speaker: current_speaker,
          text: current_speaker_text,
          index: utterance_index,
          starttime: current_start_time,
          endtime: current_end_time
        });

        speaker_list = [...new Set(speaker_list.concat(current_speaker))];

        if (!utterance_by_speaker.hasOwnProperty(current_speaker)) {
          utterance_by_speaker[current_speaker] = [];
        }

        utterance_by_speaker[current_speaker].push({
          text: current_speaker_text,
          index: utterance_index,
          starttime: current_start_time,
          endtime: current_end_time
        });
      }

      current_speaker = transcript_items[item]['speaker_label'];
      current_speaker_text = transcript_items[item]['alternatives'][0]['content'];
      current_start_time = transcript_items[item]['start_time'];
      current_end_time = transcript_items[item]['end_time'];
    } else {
      if (transcript_items[item]['type'] === 'punctuation') {
        current_speaker_text = `${current_speaker_text}${transcript_items[item]['alternatives'][0]['content']}`;
      } else {
        current_speaker_text = `${current_speaker_text} ${transcript_items[item]['alternatives'][0]['content']}`;
      }
      // console.log('current_speaker_text:', current_speaker_text);
      if (transcript_items[item].hasOwnProperty('end_time')) {
        current_end_time = transcript_items[item]['end_time'];
      }

      // If its the last item
      if (item === transcript_items.length - 1) {
        utterance_index += 1;
        utterance_array.push({
          speaker: current_speaker,
          text: current_speaker_text,
          index: utterance_index,
          starttime: current_start_time,
          endtime: current_end_time
        });

        speaker_list = [...new Set(speaker_list.concat(current_speaker))];

        if (!utterance_by_speaker.hasOwnProperty(current_speaker)) {
          utterance_by_speaker[current_speaker] = [];
        }

        utterance_by_speaker[current_speaker].push({
          text: current_speaker_text,
          index: utterance_index,
          starttime: current_start_time,
          endtime: current_end_time
        });
      }
    }
  }

  const parsed_json_output = {
    speakers: speaker_list,
    parsed_json: utterance_array,
    by_speaker: utterance_by_speaker
  };

  return parsed_json_output;
};

export const processTranscriptData = (body, type = 'application/json') => {
  let jsonData = {};
  if (type === 'application/json' || type === 'binary/octet-stream') {
    jsonData = awsTranscribeToJson(body);
  } else if (type === 'text/vtt' || type === 'application/octet-stream') {
    jsonData = vttToJson(body);
  }

  const speakers = jsonData.speakers.slice(1);
  const speakerData = [];
  let rowIndex = 1;
  speakers.forEach(item => {
    speakerData.push({
      rowIndex: rowIndex,
      originalSpeaker: item,
      speaker: item,
      candidate: false,
      firstUtterance: jsonData.by_speaker[item][0].text,
      startTime: jsonData.by_speaker[item][0].starttime,
      endTime: jsonData.by_speaker[item][0].endtime
    });
    rowIndex++;
  });
  return { speakers, speakerData };
};

export const getViewedProgress = () => {
  try {
    const viewedData = localStorage.getItem('viewed_records');
    if (!viewedData) {
      return [];
    }
    try {
      return JSON.parse(viewedData);
    } catch (error) {
      localStorage.removeItem('viewed_records');
      return [];
    }
  } catch (error) {
    return [];
  }
};

export const storeViewedProgress = taskId => {
  try {
    const currentIds = getViewedProgress();
    if (!currentIds.includes(taskId)) {
      currentIds.push(taskId);
      localStorage.setItem('viewed_records', JSON.stringify(currentIds));
    }
  } catch (error) {
    return null;
  }
};
