//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

// -------------------------------------------------// Internal Imports// -------------------------------------------------
import { Box } from '@mui/material';
import './index.scss';

import { enqueueSnackbar } from 'notistack';
import Loader from '../../../../components/common/Loader';
import { BDDataApi } from '../../../../services/ApiService';
import { ERROR, GET, PATCH, SUCCESS } from '../../../../services/constantService';
import { getFixedDecimal } from '../../../../utils/common';
import { currencyWithCommas, getCurrencyPrefix } from '../../../Commons/Utils';
import InvoiceInfoTable from './Component/InvoiceInfoTable';

const InvoiceInfo = props => {
  const { register, setValue, watch, getValues, currentValues, setCurrentData } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue('bd_currency_values', currentValues['bd_currency_values']);
    register('bd_currency_values');
  }, [register, setValue]);

  const getValueWithCurrency = data => {
    console.log("watch('bd_currency_values')", watch('bd_currency_values'));
    return `${getCurrencyPrefix(watch('bd_currency_values.code') || '')} ${currencyWithCommas(getFixedDecimal(data, 2).toFixed(2))}`;
  };

  const prepareInvoiceInfoPayload = (formData, index, obj) => {
    let invoiceObj = null;
    if (formData && formData.project_invoice_info && formData.project_invoice_info.length > index) {
      invoiceObj = formData.project_invoice_info[index];
    }

    if (!obj && invoiceObj) {
      invoiceObj.billed_date = invoiceObj.billed_date || null;
      invoiceObj.due_by_date = invoiceObj.due_by_date || null;
      invoiceObj.collected_date = invoiceObj.collected_date || null;
    }

    return {
      id: currentValues.id,
      total_invoice_amt: formData?.total_invoice_amt || 0,
      total_collected_amt: formData?.total_collected_amt || 0,
      collected_percentage: formData?.collected_percentage || 0,
      invoice_info_obj: obj ? obj : invoiceObj,
      total_invoice_expense: formData?.total_invoice_expense || 0,
      updated_at: currentValues.updated_at
    };
  };
  const updateField = async (index, message, updateType, deletedInvoice) => {
    let formData = getValues();
    formData = prepareInvoiceInfoPayload(formData, index, deletedInvoice);
    formData.updateType = updateType;
    console.log('index', index);
    setLoading(true);
    const { status, data } = await BDDataApi(PATCH, currentValues.id, formData, 'update-invoice-info');
    if (status === 200) {
      setLoading(false);
      enqueueSnackbar(`Invoice Info ${updateType} Successfully`, { variant: SUCCESS });
      if (['update', 'create'].includes(updateType)) {
        setLoading(true);
        const { data: res } = await BDDataApi(GET, currentValues.id, null, null, null);
        setLoading(false);
        setCurrentData(prevState => {
          const projectInvoiceInfo = res?.project_invoice_info || [];
          const updatedInvoiceInfo = projectInvoiceInfo.map(inv => (inv?.invoice_id === data?.id?.invoice_id ? { ...inv, ...data } : inv));

          return {
            ...prevState,
            project_invoice_info: updatedInvoiceInfo
          };
        });
      }
      return true;
    }
    if (status === 500) {
      //setRecordData(data)
      //setIsConfirmationPopup(true)
      setLoading(false);
      enqueueSnackbar(`${data.message}:${data.reason}`, { variant: ERROR });
    }
  };
  console.log(currentValues, 'currrr');
  return (
    <Box>
      <Loader show={loading} />
      <Box id='billing-client-info' className=''>
        <Box className='my-cards flex flex-wrap'>
          <Box className='form-section flex flex-column'>
            <Box className='py-2 px-3'>
              <Box className='row d-flex justify-content-between'>
                <Box className='col-md-4 mb-3'>
                  <Box className='education-input-base d-flex align-items-center'>
                    <Box className='education-input'>{`Term Type: ${currentValues?.term_type || '--'}`}</Box>
                  </Box>
                </Box>
                <Box className='col-md-4 mb-3'>
                  <Box className='education-input-base d-flex align-items-center  justify-content-center'>
                    <Box className='education-input'>{`Intervals: ${currentValues?.intervals || '--'}`}</Box>
                  </Box>
                </Box>
                <Box className='col-md-4 mb-3'>
                  <Box className='education-input-base d-flex align-items-center justify-content-end'>
                    <Box className='education-input'>{`Term Of Invoice: ${currentValues?.invoice_terms_desc?.short_desc || currentValues?.invoice_terms || '--'}`}</Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className='d-flex justify-content-start'>
          <Box className='mr-3' sx={{ width: '50vw' }}>
            <Box id='team-info' className='d-flex flex-column team-information-table revenue-table p-2' sx={{ border: '0 0 8px black' }}>
              <Box className='table-heading font-weight-bold fs-15 mb-2'>Estimated Revenue</Box>
              <Box className='d-flex flex-column py-1 px-3'>
                <Box className='d-flex p-1'>
                  <Box className='col-4 px-0 text-truncate'>Base Fee</Box>
                  <Box className='col pr-0 text-truncate'>{currentValues?.estimated_percentage_base ? getValueWithCurrency(currentValues.estimated_percentage_base) : '--'}</Box>
                </Box>
                <Box className='d-flex p-1'>
                  <Box className='col-4 px-0 text-truncate'>Indirect Fee</Box>
                  <Box className='col pr-0 text-truncate'>{currentValues?.estimated_indirect_total_fee ? getValueWithCurrency(currentValues.estimated_indirect_total_fee) : '--'}</Box>
                </Box>
                <Box className='d-flex p-1'>
                  <Box className='col-4 px-0 text-truncate'>Revenue</Box>
                  <Box className='col pr-0 text-truncate'>{currentValues?.estimated_revenue ? getValueWithCurrency(currentValues.estimated_revenue) : '--'}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className='w-50'>
            <Box id='team-info' className='d-flex flex-column team-information-table revenue-table p-2'>
              <Box className='table-heading font-weight-bold fs-15 mb-2'>Actual Revenue</Box>
              <Box className='d-flex flex-column py-1 px-3'>
                <Box className='d-flex p-1'>
                  <Box className='col-4 px-0 text-truncate'>Base Fee</Box>
                  <Box className='col pr-0 text-truncate'>{currentValues?.actual_percentage_base ? getValueWithCurrency(currentValues.actual_percentage_base) : '--'}</Box>
                </Box>
                <Box className='d-flex p-1'>
                  <Box className='col-4 px-0 text-truncate'>Indirect Fee</Box>
                  <Box className='col pr-0 text-truncate'>{currentValues?.actual_indirect_total_fee ? getValueWithCurrency(currentValues.actual_indirect_total_fee) : '--'}</Box>
                </Box>
                <Box className='d-flex p-1'>
                  <Box className='col-4 px-0 text-truncate'>Revenue</Box>
                  <Box className='col pr-0 text-truncate'>{currentValues?.actual_revenue ? getValueWithCurrency(currentValues.actual_revenue) : '--'}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className='d-flex justify-content-start'>
          <Box sx={{ width: '100%' }}>
            <Loader show={loading} />
            <InvoiceInfoTable
              register={register}
              setValue={setValue}
              currentValues={currentValues}
              watch={watch}
              getValueWithCurrency={getValueWithCurrency}
              updateField={updateField}
              loading={loading}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

InvoiceInfo.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  getValues: PropTypes.func,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  currencyDetails: PropTypes.object,
  handelScroll: PropTypes.func,
  setCurrentData: PropTypes.func
};

export default InvoiceInfo;
