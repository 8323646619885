import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Grid, Skeleton, Stack, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomMuiDropdown from '../../../components/common/CustomMuiDropdown';
import { getUserSelector } from '../../../selectors';
import { fetchLOETasks, loeDataApi, LOEInfoDataApi, updateLOE } from '../../../services/ApiService';
import { FEE_TYPES, POST } from '../../../services/constantService';

export default function AddLoePopup({ open, setOpen, refetch, edit = false, editValues, page, setPage }) {
  const toggleDrawer = newOpen => () => {
    setOpen(newOpen);
    unMount();
  };
  const { id: projectId } = useParams();
  const [consultantLoading, setConsultantLoading] = React.useState(false);
  const [consultantDetails, setConsultantDetails] = React.useState([]);
  const [clientLoading, setClientLoading] = React.useState(false);
  const [clientDetails, setClientDetails] = React.useState([]);
  const [BDData, setBDData] = React.useState({});
  const user = useSelector(getUserSelector);

  const { enqueueSnackbar } = useSnackbar();

  const [loeEmbedUrl, setLoeEmbedUrl] = React.useState('');
  const [instanceId, setInstanceId] = React.useState(null);
  const [startCount, setStartCount] = React.useState(false);
  const userData = useSelector(getUserSelector);
  const [infoDetails, setInfoDetails] = React.useState({
    position: '',
    totalCashCompensation: 0,
    estimated: 0,
    feeStructure: '',
    flatFeeAmount: 0,
    progressPaymentIntervals: '',
    includeAdministrativeFee: '',
    administrativeFeeAmount: 0
  });
  const maxPageLimit = 2;
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
    control,
    clearErrors,
    reset: resetField,
    handleSubmit
  } = useForm();
  React.useEffect(() => {
    //------------------------------------------------
    register('id');
    register('status');
    register('consultantName', { required: true });
    register('consultantEmail', { required: true });
    register('consultationTitle', { required: true });
    register('consultationEngagementCountry', { required: true });
    register('clientEmail', { required: true });
    register('clientPrefix');
    register('clientFirstName', { required: true });
    register('clientLastName', { required: true });
    register('clientTitle', { required: true });
    register('clientCompanyName', { required: true });
    // -----------------------------------
    register('initialFeeAmount', { required: true });
    register('progressFeeAmount', { required: true });
    // -----------------------------------

    return () => unMount();
  }, []);

  const unMount = () => {
    resetField();
    setPage(0);
    setStartCount(false);
    setInstanceId(null);
    // unregister('id');
    // unregister('status');
    // unregister('consultantName');
    // unregister('consultantEmail');
    // unregister('consultationTitle');
    // unregister('consultationEngagementCountry');
    // unregister('clientEmail');
    // unregister('clientPrefix');
    // unregister('clientFirstName');
    // unregister('clientLastName');
    // unregister('clientTitle');
    // unregister('clientCompanyName');
    // unregister('initialFeeAmount');
    // unregister('progressFeeAmount');

    // -----------------------------------
  };
  useEffect(() => {
    if (edit) {
      setValues(editValues);
    } else {
      resetField();
      clearErrors();
    }
  }, [edit, editValues]);

  const handleMessage = async message => {
    if (!message.origin.includes('docubee.app')) {
      return;
    }

    const { _, event } = JSON.parse(message.data);

    switch (event) {
      case 'FATAL_ERROR':
        await updateLOE(getValues('id'), { status: 'FAILED' });
        refetch();
        break;
      case 'NO_TASKS_AVAILABLE':
        await updateLOE(getValues('id'), { status: 'IN_PROGRESS' });
        refetch();
        break;
      case 'WORKFLOW_CANCELED':
        await updateLOE(getValues('id'), { status: 'CANCELLED' });
        refetch();
        break;
      case 'WORKFLOW_COMPLETE':
        await updateLOE(getValues('id'), { status: 'COMPLETED' });
        refetch();
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const getPrimaryEmail = contact => {
    try {
      return (
        contact?.contact_emails?.filter(item => {
          return item.is_primary === true;
        })[0]?.email || ''
      );
    } catch (err) {
      return '';
    }
  };

  const setValues = values => {
    Object.keys(values).forEach(key => {
      setValue(key, values[key]);
    });
  };
  const intervalDuration = 15 * 60 * 1000; // use this to edit refresh time for loe tasks
  const { data: loeData, isSuccess } = useQuery({
    queryKey: ['LOEInfoDataApi', projectId],
    queryFn: async () => {
      return await LOEInfoDataApi(projectId);
    }
  });
  const { data: _ } = useQuery({
    queryKey: ['loeEmbed', 'tasks', watch('instance_id')],
    queryFn: async () => {
      if (!getValues('instance_id') || !userData?.email) return;
      const res = await fetchLOETasks(getValues('instance_id'), userData?.email);
      setLoeEmbedUrl(res?.data?.url);
      return res;
    },
    refetchInterval: intervalDuration
    // enabled: !!watch('instance_id') && !!userData?.email && startCount
  });

  useEffect(() => {
    getInternalContactDetails();
    setPage(0);
  }, [loeData, isSuccess]);
  const calculateAdministartiveFee = projectIndirectFees => {
    const findAdministrationFee = projectIndirectFees?.filter(item => item?.type === 'administration');
    const calculateFee = findAdministrationFee?.reduce((acc, item) => {
      return acc + +item?.calculation_amt || 0;
    }, 0);

    return calculateFee || 'N/A';
  };
  const list = [
    {
      title: 'Position',
      value: infoDetails?.position || 'N/A',
      prefix: ''
    },
    {
      title: 'Total Cash Compensation',
      value: +infoDetails?.totalCashCompensation || 'N/A',
      prefix: BDData?.bd_currency_values?.currency_icon || ''
    },
    {
      title: 'Estimated',
      value: +infoDetails?.estimated || 'N/A',
      prefix: BDData?.bd_currency_values?.currency_icon || ''
    },
    {
      title: 'Fee Structure',
      value: infoDetails?.feeStructure || 'N/A',
      prefix: ''
    },
    {
      title: infoDetails?.feeType === FEE_TYPES.FIXED_FEE ? 'Flat Fee Amount' : 'Fee Percentage',
      value: infoDetails?.flatFeeAmount || 'N/A',
      prefix: infoDetails?.feeType === FEE_TYPES.FIXED_FEE ? BDData?.bd_currency_values?.currency_icon || '' : ''
    },
    {
      title: 'Progress Payment intervals',
      value: infoDetails?.progressPaymentIntervals || 'N/A',
      prefix: ''
    },
    {
      title: 'Include Administrative Fee',
      value: infoDetails?.includeAdministrativeFee || 'N/A',
      prefix: ''
    },
    {
      title: 'Administrative Fee Amount',
      value: +infoDetails?.administrativeFeeAmount || 'N/A',
      prefix: BDData?.bd_currency_values?.currency_icon || ''
    }
  ];
  const handleCreateLoe = async data => {
    try {
      const body = {
        project_id: projectId,
        status: 'DRAFT',
        initial_fee: data.initialFeeAmount,
        progress_fee: data.progressFeeAmount,
        consultant_name: data.consultantName?.data?.user?.name,
        consultant_email: data.consultantEmail,
        consultant_title: data.consultationTitle,
        engagement_country: data.consultationEngagementCountry,
        client_email: data?.clientEmail?.data?.contact?.email,
        client_prefix: data?.clientPrefix || '',
        client_first_name: data.clientFirstName,
        client_last_name: data.clientLastName,
        created_by: user?.id,
        client_title: data.clientTitle,
        client_company: data.clientCompanyName,
        position: infoDetails?.position,
        total_cash_compensation: +infoDetails?.totalCashCompensation,
        fee_structure: infoDetails?.feeStructure,
        progress_payment_intervals: infoDetails?.progressPaymentIntervals,
        fee_percentage: parseInt(infoDetails?.flatFeeAmount?.replace('%', '')),
        include_administrative_fees: infoDetails?.includeAdministrativeFee,
        initiator_email: user?.email
      };
      const res = await loeDataApi(POST, '/', body);
      if (res?.data?.data?.url) {
        setLoeEmbedUrl(res?.data?.data?.url);
        setInstanceId(res?.data?.data?.instance_id);
        resetField();
        setPage(page + 1);
        setInterval(() => {
          setStartCount(true);
        }, intervalDuration);
      }
      refetch();
    } catch (err) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const getInternalContactDetails = async () => {
    try {
      if (projectId) {
        setConsultantLoading(true);
        setClientLoading(true);

        if (isSuccess && loeData) {
          const data = loeData.data;
          setBDData(data?.project || {});
          setInfoDetails({
            position: data?.project?.helia_title,
            totalCashCompensation: +data?.project?.min_compensation + +data?.project?.target_compensation_bonus,
            estimated: +data?.project?.estimated_revenue,
            feeType: data?.project?.fee_type,
            feeStructure: data?.project?.fee_type,
            flatFeeAmount: data?.project?.fee_type === FEE_TYPES.FIXED_FEE ? +data?.project?.fixed_fee : `${data?.project?.fee_percentage}%`,
            progressPaymentIntervals: data?.project?.intervals,
            includeAdministrativeFee: calculateAdministartiveFee(data?.project?.project_indirect_fees) > 0 ? 'No' : 'No',
            administrativeFeeAmount: calculateAdministartiveFee(data?.project?.project_indirect_fees)
          });
          const partners = data?.partner?.map(item => {
            if (item && item?.user && item?.user?.id) {
              return {
                label: item?.user?.name,
                value: item?.user?.id,
                type: 'partners',
                data: item
              };
            } else {
              return {
                label: '',
                type: 'partners',
                value: undefined
              };
            }
          });

          const clients = data?.clientTeam?.map(item => {
            if (item && item?.contact && item?.contact?.id) {
              if (!getPrimaryEmail(item?.contact)) return;
              return {
                label: getPrimaryEmail(item?.contact) || item?.contact?.first_name + ' ' + item?.contact?.last_name,
                value: getPrimaryEmail(item?.contact) || item?.contact?.first_name + ' ' + item?.contact?.last_name,
                type: 'Users on This BD',
                data: item
              };
            } else {
              return {
                label: '',
                type: 'Other Users',
                value: undefined
              };
            }
          });
          const otherBD = data?.otherBD?.map(item => {
            if (item && item?.email) {
              if (!item?.email) return;
              return {
                label: item?.email,
                value: item?.email,
                type: 'Other Users',
                data: {
                  contact: {
                    email: item?.email || '',
                    first_name: item?.first_name || '',
                    last_name: item?.last_name || '',
                    current_job_title: item?.current_job_title || '',
                    contact_company: {
                      name: item?.company_name || ''
                    }
                  }
                }
              };
            } else {
              return {
                label: '',
                type: 'Other Users',
                value: undefined
              };
            }
          });
          setConsultantLoading(false);
          setConsultantDetails(partners);
          setClientLoading(false);
          setClientDetails([...clients, ...otherBD] || []);
          setValues({
            consultationEngagementCountry: data?.marketDetails?.market || ''
          });
        }
      }
    } catch (err) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const consultantList = [
    {
      type: 'dropdown',
      title: 'Consultant Name *',
      value: watch('consultantName'),
      onChange: (e, v) => {
        setValues({ consultantName: v });
        setValues({
          consultantEmail: v?.data?.user?.email
        });
        setValues({
          consultationTitle: v?.data?.user?.user_contacts?.length > 0 ? v?.data?.user?.user_contacts[0]?.current_job_title : ''
        });
      },
      multiple: true,
      required: true,
      disabled: false,
      isLoading: consultantLoading,
      options: consultantDetails || [],
      error: errors?.consultantName,
      key: 'consultantName'
    },
    {
      type: 'text',
      title: 'Consultant Email  *',
      value: watch('consultantEmail'),
      onChange: e => setValues({ consultantEmail: e.target.value }),
      multiple: true,
      required: true,
      disabled: false,
      isLoading: false,
      options: [],
      error: errors?.consultantEmail,
      key: 'consultantEmail'
    },
    {
      type: 'text',
      title: 'Consultation Title *',
      value: watch('consultationTitle'),
      onChange: e => setValues({ consultationTitle: e.target.value }),
      multiple: true,
      required: true,
      disabled: false,
      isLoading: false,
      options: [],
      error: errors?.consultationTitle,
      key: 'consultationTitle'
    },
    {
      type: 'text',
      title: 'Engagement Country *',
      value: watch('consultationEngagementCountry'),
      onChange: e => setValues({ consultationEngagementCountry: e.target.value }),
      multiple: true,
      required: true,
      disabled: true,
      isLoading: false,
      options: [],
      error: errors?.consultationEngagementCountry,
      key: 'consultationEngagementCountry'
    }
  ];
  const clientList = [
    {
      type: 'dropdown',
      title: 'Client Email *',
      value: watch('clientEmail'),
      onChange: (e, v) => {
        setValues({ clientEmail: v });
        setValues({ clientFirstName: v?.data?.contact?.first_name });
        setValues({ clientLastName: v?.data?.contact?.last_name });
        setValues({ clientTitle: v?.data?.contact?.current_job_title });
        setValues({ clientCompanyName: v?.data?.contact?.contact_company?.name });
      },
      multiple: true,
      required: false,
      disabled: false,
      isLoading: clientLoading,
      options: clientDetails || [],
      props: {
        autoCompleteProps: {
          groupBy: option => option?.type
        }
      },
      error: errors?.clientEmail,
      key: 'clientEmail'
    },
    {
      type: 'text',
      title: 'Client Prefix (Mr./Mrs.) ',
      value: watch('clientPrefix'),
      onChange: (e, _) => setValues({ clientPrefix: e.target.value }),
      multiple: false,
      required: false,
      disabled: false,
      isLoading: false,
      options: [
        {
          value: 'Mr.',
          label: 'Mr.'
        },
        {
          value: 'Mrs.',
          label: 'Mrs.'
        }
      ],
      error: errors?.clientPrefix,
      key: 'clientPrefix'
    },
    {
      type: 'text',
      title: 'Client First Name *',
      value: watch('clientFirstName'),
      onChange: (e, _) => setValues({ clientFirstName: e.target.value }),
      multiple: true,
      required: true,
      disabled: false,
      isLoading: false,
      options: [
        {
          value: 'John',
          label: 'John'
        }
      ],
      error: errors?.clientFirstName,
      key: 'clientFirstName'
    },
    {
      type: 'text',
      title: 'Client Last Name *',
      value: watch('clientLastName'),
      onChange: (e, _) => setValues({ clientLastName: e.target.value }),
      multiple: true,
      required: true,
      disabled: false,
      isLoading: false,
      options: [
        {
          value: 'Doe',
          label: 'Doe'
        }
      ],
      error: errors?.clientLastName,
      key: 'clientLastName'
    },
    {
      type: 'text',
      title: 'Client Title *',
      value: watch('clientTitle'),
      onChange: (e, _) => setValues({ clientTitle: e.target.value }),
      multiple: true,
      required: true,
      disabled: false,
      isLoading: false,
      options: [
        {
          value: 'Software Engineer',
          label: 'Software Engineer'
        }
      ],
      error: errors?.clientTitle,
      key: 'clientTitle'
    },
    {
      type: 'text',
      title: 'Client Company Name *',
      value: watch('clientCompanyName'),
      onChange: (e, _) => setValues({ clientCompanyName: e.target.value }),
      multiple: true,
      required: true,
      disabled: false,
      isLoading: false,
      options: [
        {
          value: 'Google',
          label: 'Google'
        }
      ],
      error: errors?.clientCompanyName,
      key: 'clientCompanyName'
    }
  ];
  const onValidationError = () => {
    enqueueSnackbar('Please fill all the required fields', { variant: 'error' });
  };
  const firstPage = (
    <List
      disablePadding
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        minHeight: '90%'
      }}
    >
      <ListItem
        sx={{
          padding: '20px'
        }}
      >
        <Stack>
          <Typography variant='body1' fontWeight={'bold'} className='fs-14'>
            Engagement Details
          </Typography>
          <Typography variant='p' className='fs-12' color={'gray'}>
            Share Engagement Details for LOE
          </Typography>
        </Stack>
      </ListItem>
      <ListItem
        sx={{
          height: '212px',
          padding: '20px'
        }}
      >
        <Grid
          container
          sx={{
            borderBottom: '1px solid #e0e0e0'
          }}
        >
          <Grid item xs={8} paddingBottom={'12px'}>
            <Grid container gap={4}>
              {list.map((item, index) => (
                <Grid
                  item
                  xs={3}
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    borderLeft: '2px solid #e0e0e0',
                    paddingLeft: '14px'
                  }}
                >
                  <Typography variant='p' className='fs-10' color={'gray'}>
                    {item.title}
                  </Typography>
                  {!consultantLoading ? (
                    <Stack className='fs-14' direction={'row'}>
                      <Typography variant='p' className='fs-14' style={{ direction: 'ltl' }}>
                        {item.prefix}
                      </Typography>
                      <Typography variant='p' className='fs-14' style={{ direction: 'ltl' }}>
                        {item.value}
                      </Typography>
                    </Stack>
                  ) : (
                    <Skeleton variant='text' width={'100%'} height={'20px'} />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
              padding: '10px',
              backgroundColor: '#e0e0e01c'
            }}
          >
            <Controller
              name='initialFeeAmount'
              control={control}
              rules={{ required: 'Initial Fee Amount is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Initial Fee Amount *'
                  size='small'
                  value={watch('initialFeeAmount')}
                  error={!!errors?.initialFeeAmount}
                  helperText={errors?.initialFeeAmount?.message}
                  InputProps={{
                    inputComponent: NumberFormatCurrency,
                    startAdornment: BDData?.bd_currency_values?.currency_icon
                  }}
                  onChange={e => setValues({ initialFeeAmount: e.target.value })}
                  sx={{
                    backgroundColor: 'white',
                    width: '80%'
                  }}
                />
              )}
            />
            <Controller
              name='progressFeeAmount'
              control={control}
              rules={{ required: 'Progress Fee Amount is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label='Progress Fee Amount *'
                  size='small'
                  InputProps={{
                    inputComponent: NumberFormatCurrency,
                    startAdornment: BDData?.bd_currency_values?.currency_icon
                  }}
                  value={watch('progressFeeAmount')}
                  error={!!errors?.progressFeeAmount}
                  helperText={errors?.progressFeeAmount?.message}
                  onChange={e => setValues({ progressFeeAmount: e.target.value })}
                  sx={{
                    backgroundColor: 'white',
                    width: '80%'
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </ListItem>
      {/* <Divider /> */}
      <ListItem
        sx={{
          padding: '20px'
        }}
      >
        <Stack>
          <Typography variant='body1' fontWeight={'bold'} className='fs-14'>
            Consultant Details
          </Typography>
          <Typography variant='p' className='fs-12' color={'gray'}>
            Consultant signature information for LOE
          </Typography>
        </Stack>
      </ListItem>
      <ListItem
        sx={{
          padding: '20px'
        }}
      >
        <Grid container justifyContent={'start'} gap={2}>
          {consultantList.map((item, index) => (
            <Grid item xs={2.7} key={index}>
              {item.type === 'text' && (
                <Controller
                  name={item.key}
                  control={control}
                  rules={{ required: `${item.title} is required` }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      name={item.key}
                      label={item.title}
                      size='small'
                      error={!!item.error}
                      value={item.value || ''}
                      onChange={item.onChange}
                      disabled={item.disabled}
                      helperText={item.error?.message}
                      sx={{
                        backgroundColor: 'white',
                        width: '100%'
                      }}
                    />
                  )}
                />
              )}

              {
                // Dropdown
                item.type === 'dropdown' && (
                  <Controller
                    name={item.key}
                    control={control}
                    rules={{ required: `${item.title} is required` }}
                    render={({ field }) => (
                      <CustomMuiDropdown
                        {...field}
                        name={item.key}
                        label={item.title}
                        options={item.options}
                        value={item.value || { value: '', label: '' }}
                        onChange={item.onChange}
                        disabled={item.disabled}
                        isLoading={item.isLoading}
                        size='small'
                        getOptionLabel={option => option?.label}
                        autoCompleteProps={{}}
                        textFieldProps={{
                          error: !!item.error,
                          helperText: item.error?.message
                        }}
                      />
                    )}
                  />
                )
              }
            </Grid>
          ))}
        </Grid>
      </ListItem>
      <ListItem
        sx={{
          padding: '20px'
        }}
      >
        <Stack>
          <Typography variant='body1' fontWeight={'bold'} className='fs-14'>
            Client Details
          </Typography>
          <Typography variant='p' className='fs-12' color={'gray'}>
            Client signature information for LOE
          </Typography>
        </Stack>
      </ListItem>
      <ListItem
        sx={{
          padding: '20px'
        }}
      >
        <Grid container gap={2} rowGap={6}>
          {clientList.map((item, index) => (
            <Grid item xs={2.7} key={index}>
              {item.type === 'text' && (
                <>
                  {item.required ? (
                    <Controller
                      name={item.key}
                      control={control}
                      rules={{ required: `${item.title} is required` }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={item.title}
                          size='small'
                          error={!!item.error}
                          value={item.value || ''}
                          onChange={item.onChange}
                          disabled={item.disabled}
                          helperText={item.error?.message}
                          sx={{
                            backgroundColor: 'white',
                            width: '100%'
                          }}
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      label={item.title}
                      size='small'
                      value={item.value || ''}
                      onChange={item.onChange}
                      disabled={item.disabled}
                      helperText={item.error?.message}
                      sx={{
                        backgroundColor: 'white',
                        width: '100%'
                      }}
                    />
                  )}
                </>
              )}

              {
                // Dropdown
                item.type === 'dropdown' && (
                  <Controller
                    name={item.key}
                    control={control}
                    rules={{ required: `${item.title} is required` }}
                    render={({ field }) => (
                      <CustomMuiDropdown
                        {...field}
                        label={item.title}
                        options={item.options}
                        value={item.value}
                        onChange={item.onChange}
                        disabled={item.disabled}
                        isLoading={item.isLoading}
                        size='small'
                        getOptionLabel={option => option?.label}
                        autoCompleteProps={{}}
                        textFieldProps={{
                          error: !!item.error,
                          helperText: item.error?.message
                        }}
                      />
                    )}
                  />
                )
              }
            </Grid>
          ))}
        </Grid>
      </ListItem>
    </List>
  );
  const secondPage = (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        minHeight: '90vh'
      }}
    >
      <iframe src={loeEmbedUrl} id='loe-iframe' style={{ height: '90vh', width: '100%' }}></iframe>
    </Stack>
  );
  const DrawerList = (
    <Box sx={{ width: '90vw', position: 'relative', height: '100%', overflowY: 'auto' }} role='presentation'>
      <List
        disablePadding
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          minHeight: '80px'
        }}
      >
        <ListItem disablePadding>
          <Grid container direction='row' width={'100%'} alignItems={'center'} justifyContent={'space-between'} p={'12px'} px={'20px'} bgcolor={'primary.main'}>
            <Grid item xs={4}>
              <Typography variant='body1' fontWeight={'bold'} className='fs-16' color={'#23cea7'}>
                Create LOE
              </Typography>
              <Typography variant='p' className='fs-12' color={'white'}>
                Develop a clear, detailed, and actionable project scope
              </Typography>
            </Grid>
            <Grid item xs={4} display={'flex'} direction='row' alignItems='center' justifyContent='center'>
              <Stack
                sx={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  backgroundColor: '#23cea7'
                }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                1
              </Stack>
              <Box
                sx={{
                  height: '2px',
                  width: '100px',
                  background: 'white'
                }}
              >
                <Box
                  width={`${(page / maxPageLimit) * 100}%`}
                  sx={{
                    backgroundColor: '#23cea7',
                    height: '100%',
                    transition: 'width 1s'
                  }}
                />
              </Box>
              <Stack
                sx={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  backgroundColor: '#23cea7'
                }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                2
              </Stack>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button onClick={toggleDrawer(false)} variant='text' sx={{ color: 'white' }} startIcon={<CloseOutlinedIcon />}></Button>
            </Grid>
          </Grid>
        </ListItem>
      </List>
      {page === 0 && firstPage}
      {page === 1 && secondPage}
      {page === 2 && secondPage}
      <Stack
        sx={{
          position: 'sticky',
          bottom: '0',
          width: '100%',
          padding: '8px',
          borderTop: '1px solid #e0e0e0',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          zIndex: 1000,
          display: page !== 0 ? 'none' : 'flex'
        }}
        direction={'row'}
      >
        <Box>
          {page > 0 && (
            <Button variant='outlined' sx={{ width: '100px' }} onClick={() => setPage(page - 1)} disabled={edit && page === 1}>
              Back
            </Button>
          )}
        </Box>
        <Button
          variant='contained'
          sx={{ width: '100px' }}
          onClick={() => {
            if (page === 0) handleSubmit(() => handleCreateLoe(getValues()), onValidationError)();
            if (page === 1) setPage(page + 1);
          }}
          disabled={page === maxPageLimit}
        >
          Continue
        </Button>
      </Stack>
    </Box>
  );

  return (
    <div>
      <Drawer
        open={open}
        onClose={() => {
          toggleDrawer(false);
        }}
        anchor='right'
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
function NumberFormatCurrency(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values =>
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }
      thousandSeparator=','
      isNumericString
      allowNegative={false}
      format='###,##,##,###'
    />
  );
}
NumberFormatCurrency.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
AddLoePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  refetch: PropTypes.func,
  edit: PropTypes.bool,
  editValues: PropTypes.object,
  page: PropTypes.number,
  setPage: PropTypes.func
};
