import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook.js';
import { TRANS_KEYS } from '../../../utils/languageNamespaces.js';
import CustomPopup from '../CustomPopup/index.js';
import '../../../utils/common.scss';
import './index.scss';
import CustomButton from '../CustomButton/index.js';
import { useForm } from 'react-hook-form';
import { ERROR, PATCH, SUCCESS } from '../../../services/constantService.js';
import { useSnackbar } from 'notistack';
import { ignContactDataApi } from '../../../services/ApiService.js';
import { successMessage } from '../../../services/MessageService.js';
// import Loader from "../Loader/index.js";
import CustomInputField from '../StyledComponents/CustomInputField/index.js';
import CompanySelection from '../FunctionalComponents/CompanySelection/index.js';

const UpdateContactInfo = props => {
  const { data, isPopupOpen, handleClose = () => {}, getHeaderDetails = () => {} } = props;
  const { setValue, getValues, register, watch } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_UPDATE, TRANS_KEYS.CONTACTS_EDIT, TRANS_KEYS.CONTACTS_COMPANY_NAME, TRANS_KEYS.CONTACTS_JOB_TITLE]);

  useEffect(() => {
    register('contact_company');
    register('current_job_title');
    register('current_company_id');
  }, [register]);

  useEffect(() => {
    setValue('contact_company', data?.contact_company);
    setValue('current_job_title', data?.current_job_title);
    setValue('current_company_id', data?.contact_company?.id);
  }, [data]);

  const cleanupPayload = formValue => {
    let payload = { ...formValue };
    payload['current_company_id'] = payload?.contact_company?.id || null;
    payload['contact_company'] = { id: payload?.contact_company?.id || null, name: payload?.contact_company?.name || null };
    return payload;
  };
  const handleUpdateContact = async () => {
    try {
      const formValue = getValues();
      setIsLoading(true);
      const payload = cleanupPayload(formValue);
      const { status, data: responseData } = await ignContactDataApi(PATCH, data?.id, payload);
      if (status === 200) {
        setIsLoading(false);
        handleClose();
        getHeaderDetails();
        const message = successMessage('Contact', 'updated');
        enqueueSnackbar(message, { variant: SUCCESS });
        return true;
      } else {
        setIsLoading(false);
        enqueueSnackbar(`${responseData.message}:${responseData.reason}`, { variant: ERROR });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <CustomPopup onClose={handleClose} aria-labelledby='customized-dialog-title' open={isPopupOpen} title={t(`${TRANS_KEYS.CONTACTS_EDIT}:key`)} loading={isLoading}>
      {/* <Loader show={isLoading} /> */}
      <Box className='d-flex flex flex-column' gap={4} id='update-contact'>
        <Box className='flex flex-column row-gap content-gap '>
          <Box className='input-field-data flex justify-center align-center'>
            <CustomInputField
              label={t(`${TRANS_KEYS.CONTACTS_JOB_TITLE}:key`)}
              defaultValue={data?.current_job_title || ''}
              onChange={e => {
                setValue('current_job_title', e.target.value, { shouldDirty: true });
              }}
            />
          </Box>
        </Box>
        <Box className='flex flex-column row-gap content-gap '>
          <Box className='input-field-data flex justify-center align-center'>
            <CompanySelection
              label={t(`${TRANS_KEYS.CONTACTS_COMPANY_NAME}:key`)}
              value={watch('contact_company')?.name || ''}
              onChange={(e, data) => {
                setValue('contact_company', data || '', { shouldDirty: true });
              }}
              className='w-100'
            />
          </Box>
        </Box>
        <Box className='d-flex justify-content-end'>
          <CustomButton type={'primary'} size={'small'} buttonText={t(`${TRANS_KEYS.CONTACTS_UPDATE}:key`)} customWidth={96} variant='contained' onClick={handleUpdateContact} />
        </Box>
      </Box>
    </CustomPopup>
  );
};

UpdateContactInfo.propTypes = {
  isPopupOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
  getHeaderDetails: PropTypes.func
};
export default UpdateContactInfo;
