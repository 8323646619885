import { Edit } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const EditableTextField = ({ disabled = false, InputProps, defaultValue, onSave, textStyle = {} }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(defaultValue);

  const [showEditIcon, setShowEditIcon] = useState(false);

  // Handle edit button click
  const handleEditClick = () => {
    if (disabled) return;
    setIsEditing(true);
  };

  // Handle tick (save)
  const handleSave = () => {
    setIsEditing(false);
    onSave(defaultValue, tempValue);
  };

  return (
    <Box>
      {isEditing ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            value={tempValue}
            onChange={e => setTempValue(e.target.value)}
            variant='outlined'
            disabled={disabled}
            size='small'
            InputProps={{
              style: { fontSize: '13px', ...InputProps, paddingX: '2px', width: 'auto' }
            }}
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '13px !important',
                paddingX: '2px !important'
              },
              '&:hover': {
                '& .MuiOutlinedInput-root': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderStyle: 'none'
                  }
                }
              },
              width: 'auto'
            }}
          />
          <Tooltip title='Save' placement='top'>
            <IconButton onClick={handleSave} sx={{ color: 'white', padding: 0, ml: 1 }}>
              <SaveIcon className='fs-16' cursor='pointer' color={'secondary'} />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <Box display={'flex'} sx={{ cursor: 'pointer' }} onMouseLeave={() => setShowEditIcon(false)} onMouseEnter={() => setShowEditIcon(true)}>
          <Typography onClick={handleEditClick} sx={{ fontSize: '13px', ...textStyle }}>
            {defaultValue}
          </Typography>
          {!disabled && (
            <IconButton onClick={handleEditClick} sx={{ color: 'white', padding: 0 }}>
              <Tooltip title='Edit' placement='top'>
                <Edit sx={{ visibility: showEditIcon ? 'visible' : 'hidden' }} className='fs-16 ml-2' cursor='pointer' color='secondary' />
              </Tooltip>
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
};

export { EditableTextField };

EditableTextField.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  textStyle: PropTypes.object,
  InputProps: PropTypes.object,
  saveIconColor: PropTypes.string,
  closeIconColor: PropTypes.string,
  disabled: PropTypes.bool
};
