//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { KeyboardDatePicker } from '@material-ui/pickers';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import PhonePopover from '../Contacts/PhonePopover';
import { DateRenderer, getDateFilterOptions, getMatchFilterOptions } from '../Commons/Utils';
import { updateUser } from '../Commons/Utils';
import WorkgroupSelection from '../../components/AddUser/components/WorkgroupSelection';
import CountrySelection from '../../components/CountrySelection';
import StateSelection from '../../components/StateSelection';
import InputField from '../../components/common/InputField';
import WebsiteTagsSelection from '../../components/AddUser/components/WebsiteTagsSelection';
import UserSelection from '../../components/UserSelection';
import LocationSelection from '../../components/AddUser/components/LocationSelection';
import PracticeSelection from '../../components/AddUser/components/PracticeSelection';
import RegionSelection from '../../components/AddUser/components/RegionSelection';
import { ERROR, EMAIL_REGEX } from '../../services/constantService';
import CompanySelection from '../../components/CompanySelection';
import { requireMessage, validateMessage, VALIDATION_MESSAGE } from '../../services/MessageService';
import OffLimitsSelection from '../../components/OffLimitsSelection';
import { tooltipValueGrid } from '../../utils/common';

export const defaultColumns = ['first_name', 'freeze_dropdown'];

const getName = params => params.data && (params.data.first_name ? params.data.first_name + ' ' : '') + (params.data.last_name ? params.data.last_name : '');
const getRevenueBudget = params => params.data && (params.data.revenue_budget ? parseInt(params.data.revenue_budget) : '');
const getKGPNumber = params => params.data && (params.data.kgp_number ? parseInt(params.data.kgp_number) : '');
// const isActive = (params => params.value ? 'Yes' : 'No')
const getLocation = params => params.data && params.data.location;
const getVerticalPractice = params => params.data && params.data.vertical_practice;
const getHorizontalPractice = params => params.data && params.data.horizontal_practice;
const getRegion = params => params.data && params.data.region;
const getKGP_Employee = params => (params.value ? 'Yes' : params.value === null ? '' : 'No');
const getProjectManager = params => {
  const name = [];
  if (params && params.data) {
    if (params && params.data && params.data.project_manager && Array.isArray(params.data.project_manager)) {
      params.data.project_manager.forEach(item => {
        if (item.name) {
          name.push(item.name);
        } else {
          let string = `${item.first_name ? item.first_name : ''} ${item.last_name ? item.last_name : ''}`;
          name.push(string);
        }
      });
    }
  }
  return name.join(', ');
};
const stateSelection = params => {
  const country = params.data.country ? params.data.country : null;
  return StateSelection({ ...params, country: country });
};
const getReferredBy = params => {
  if (params.data) if (params.data.reference_user !== null) return params.data.reference_user.name;
};
const verticalPracticeData = params => {
  const type = 'vertical';
  return PracticeSelection({ ...params, type });
};
const getEditable = params => {
  return params?.data?.country ? true : false;
};
export const columnDefs = (enqueueSnackbar, showFilter = true, roles, SetLoader, option) => {
  let columnDefs = [
    {
      headerName: '',
      field: 'check_box',
      colId: 'check_box',
      maxWidth: 50,
      minWidth: 50,
      headerComponent: 'HeaderCheckbox',
      headerCheckboxSelectionFilteredOnly: false,
      checkboxSelection: true,
      sortable: false,
      pinned: 'left',
      lockPinned: true,
      lockPosition: true
    },
    {
      headerName: 'Name',
      valueGetter: getName,
      field: 'first_name',
      colId: 'first_name',
      sortable: true,
      maxWidth: 300,
      minWidth: 50,
      cellRenderer: 'NameRenderer',
      tooltipValueGetter: getName,
      pinned: 'left',
      lockPinned: true,
      filter: showFilter && 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'title',
      colId: 'title',
      headerName: 'Title',
      tooltipField: 'title',
      sortable: true,
      maxWidth: 200,
      minWidth: 50,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      valueSetter: params => {
        if (!params.newValue) {
          const message = requireMessage('Title', 'is');
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        } else {
          return updateUser(params, 'title', enqueueSnackbar, false, SetLoader);
        }
      },
      filter: showFilter && 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'email',
      colId: 'email',
      headerName: 'Email',
      tooltipField: 'email',
      cellRenderer: 'EmailRenderer',
      editable: false,
      maxWidth: 320,
      minWidth: 50,
      cellEditor: 'GenericCellEditor',
      cellEditorParams: {
        InputComponent: InputField,
        type: 'email',
        label: ''
      },
      valueSetter: params => {
        return updateUser(params, 'email', enqueueSnackbar, true, SetLoader);
      },
      filter: showFilter && 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'mobile_phone',
      colId: 'mobile_phone',
      headerName: 'Mobile Number',
      maxWidth: 300,
      minWidth: 200,
      cellRenderer: 'PhoneRenderer',
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      sortable: false,
      cellEditor: 'PhoneEditor',
      valueSetter: params => {
        return updateUser(params, 'mobile_phone', enqueueSnackbar, false, SetLoader);
      },
      filter: showFilter && 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'work_phone',
      colId: 'work_phone',
      headerName: 'Work Number',
      maxWidth: 300,
      minWidth: 200,
      cellRenderer: 'PhoneRenderer',
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      sortable: false,
      cellEditor: 'PhoneEditor',
      valueSetter: params => {
        return updateUser(params, 'work_phone', enqueueSnackbar, false, SetLoader);
      },
      filter: showFilter && 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'extension',
      colId: 'extension',
      headerName: 'Extension',
      tooltipField: 'extension',
      maxWidth: 200,
      minWidth: 50,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      cellEditor: 'GenericCellEditor',
      cellEditorParams: {
        InputComponent: InputField,
        label: ''
      },
      valueSetter: params => {
        return updateUser(params, 'extension', enqueueSnackbar, false, SetLoader);
      },
      filter: showFilter && 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'birth_date',
      colId: 'birth_date',
      headerName: 'Birth Date',
      maxWidth: 120,
      minWidth: 50,
      tooltipValueGetter: DateRenderer,
      cellRenderer: DateRenderer,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      cellEditor: 'GenericCellEditor',
      hide: true,
      cellEditorParams: {
        InputComponent: KeyboardDatePicker,
        label: '',
        placeholder: '',
        autoOk: true,
        variant: 'inline',
        type: 'Date',
        format: 'yyyy-MM-dd'
      },
      valueSetter: params => {
        return updateUser(params, 'birth_date', enqueueSnackbar, false, SetLoader);
      },

      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'hiring_date',
      colId: 'hiring_date',
      maxWidth: 120,
      minWidth: 50,
      headerName: 'Hire Date',
      tooltipValueGetter: DateRenderer,
      cellRenderer: DateRenderer,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      cellEditor: 'GenericCellEditor',
      hide: true,
      cellEditorParams: {
        InputComponent: KeyboardDatePicker,
        label: '',
        placeholder: '',
        autoOk: true,
        variant: 'inline',
        type: 'Date',
        format: 'yyyy-MM-dd'
      },
      valueSetter: params => {
        if (!params.newValue) {
          const message = requireMessage('Hire Date', 'is');
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        } else {
          return updateUser(params, 'hiring_date', enqueueSnackbar, false, SetLoader);
        }
      },
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'termination_date',
      colId: 'termination_date',
      headerName: 'Termination Date',
      maxWidth: 120,
      minWidth: 50,
      tooltipValueGetter: DateRenderer,
      cellRenderer: DateRenderer,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      cellEditor: 'GenericCellEditor',
      hide: true,
      cellEditorParams: {
        InputComponent: KeyboardDatePicker,
        label: '',
        placeholder: '',
        autoOk: true,
        variant: 'inline',
        type: 'Date',
        format: 'yyyy-MM-dd'
      },
      valueSetter: params => {
        return updateUser(params, 'termination_date', enqueueSnackbar, false, SetLoader);
      },

      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'project_manager',
      colId: 'project_manager',
      headerName: 'Project Manager',
      tooltipValueGetter: getProjectManager,
      maxWidth: 200,
      minWidth: 50,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      sortable: false,
      cellEditor: 'GenericCellEditor',
      cellEditorParams: {
        InputComponent: UserSelection,
        label: '',
        placeholder: '',
        multiple: true,
        option: option ? option : []
      },
      valueSetter: params => {
        params.data.project_manager = params.newValue;
        params.newValue = params.newValue?.length ? params.newValue.map(data => data.id) : [];
        return updateUser(params, 'project_managers', enqueueSnackbar, false, SetLoader);
      },
      cellRenderer: getProjectManager,
      filter: showFilter && 'CustomFilter',
      filterParams: {
        Component: UserSelection,
        multiple: true,
        option: option ? option : []
      }
    },
    {
      field: 'company_name',
      colId: 'company_name',
      headerName: 'Company',
      maxWidth: 200,
      minWidth: 50,
      tooltipField: 'company_name',
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      sortable: true,
      hide: true,
      cellEditor: 'GenericCellEditor',
      cellEditorParams: {
        InputComponent: CompanySelection,
        label: '',
        placeholder: ''
      },
      valueSetter: params => {
        return updateUser(params, 'company_name', enqueueSnackbar, false, SetLoader);
      },
      filter: showFilter && 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'linkedin_url',
      colId: 'linkedin_url',
      headerName: 'LinkedIn',
      maxWidth: 120,
      minWidth: 50,
      // tooltipField: "linkedin_url",
      tooltipValueGetter: params => {
        return tooltipValueGrid(params);
      },
      cellRenderer: 'LinkedInRenderer',
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      valueSetter: params => {
        return updateUser(params, 'linkedin_url', enqueueSnackbar, false, SetLoader);
      },
      hide: true,
      filter: showFilter && 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'twitter_handle',
      colId: 'twitter_handle',
      headerName: 'Twitter',
      maxWidth: 100,
      minWidth: 50,
      tooltipField: 'twitter_handle',
      cellRenderer: 'TwitterRenderer',
      hide: true,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      valueSetter: params => {
        return updateUser(params, 'twitter_handle', enqueueSnackbar, false, SetLoader);
      },
      filter: showFilter && 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'skype_id',
      colId: 'skype_id',
      headerName: 'Skype',
      tooltipField: 'skype_id',
      hide: true,
      maxWidth: 100,
      minWidth: 50,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      valueSetter: params => {
        return updateUser(params, 'skype_id', enqueueSnackbar, false, SetLoader);
      },
      filter: showFilter && 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'website_tag',
      colId: 'website_tag',
      headerName: 'Website Tag',
      tooltipField: 'website_tag',
      sortable: true,
      maxWidth: 200,
      minWidth: 50,
      hide: true,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      cellEditor: 'GenericCellEditor',
      cellEditorParams: {
        InputComponent: WebsiteTagsSelection,
        label: ''
      },
      valueSetter: params => {
        params.newValue = params.newValue.name;
        return updateUser(params, 'website_tag', enqueueSnackbar, false, SetLoader);
      },
      filter: showFilter && 'CustomFilter',
      filterParams: {
        Component: WebsiteTagsSelection,
        field: 'name',
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'work_group',
      colId: 'work_group',
      headerName: 'Work Group',
      tooltipField: 'work_group',
      sortable: true,
      maxWidth: 150,
      minWidth: 50,
      hide: true,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      cellEditor: 'GenericCellEditor',
      cellEditorParams: {
        InputComponent: WorkgroupSelection,
        label: ''
      },
      valueSetter: params => {
        params.newValue = params.newValue.name;
        return updateUser(params, 'work_group', enqueueSnackbar, false, SetLoader);
      },
      filter: showFilter && 'CustomFilter',
      filterParams: {
        Component: WorkgroupSelection,
        field: 'name',
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'revenue_budget',
      colId: 'revenue_budget',
      headerName: 'Revenue Budget',
      valueGetter: getRevenueBudget,
      tooltipField: 'revenue_budget',
      maxWidth: 200,
      minWidth: 50,
      hide: true,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      cellEditor: 'GenericCellEditor',
      cellEditorParams: {
        InputComponent: InputField,
        type: 'number',
        label: ''
      },
      valueSetter: params => {
        if (params.newValue === false) {
          params.newValue = null;
        }
        return updateUser(params, 'revenue_budget', enqueueSnackbar, false, SetLoader);
      },
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: getMatchFilterOptions(),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'kgp_number',
      colId: 'kgp_number',
      headerName: 'KGP Number',
      maxWidth: 200,
      minWidth: 50,
      valueGetter: getKGPNumber,
      tooltipField: 'kgp_number',
      hide: true,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      cellEditor: 'GenericCellEditor',
      cellEditorParams: {
        InputComponent: InputField,
        type: 'number',
        label: ''
      },
      valueSetter: params => {
        if (params.newValue && (params.newValue < 0 || params.newValue > 9999)) {
          enqueueSnackbar(VALIDATION_MESSAGE.kgp_number_validation, { variant: ERROR });
        } else {
          if (params.newValue === false) {
            params.newValue = null;
          }
          return updateUser(params, 'kgp_number', enqueueSnackbar, false, SetLoader);
        }
      },
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: getMatchFilterOptions(),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'capacity',
      colId: 'capacity',
      headerName: 'Capacity',
      tooltipField: 'capacity',
      maxWidth: 200,
      minWidth: 50,
      hide: true,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      cellEditor: 'GenericCellEditor',
      cellEditorParams: {
        InputComponent: InputField,
        type: 'number',
        label: ''
      },
      valueSetter: params => {
        if (params.newValue === false) {
          params.newValue = null;
        }
        return updateUser(params, 'capacity', enqueueSnackbar, false, SetLoader);
      },
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: getMatchFilterOptions(),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: '',
      colId: 'mark_user_as',
      headerName: 'Mark User As',
      tooltipField: '',
      hide: true,
      maxWidth: 150,
      minWidth: 50,
      cellRenderer: 'ActionsRenderer',
      sortable: false
    },
    // {
    //   field: 'is_active',
    //   colId: 'is_active',
    //   headerName: 'Active',
    //   //tooltipValueGetter: isActive,
    //   cellRenderer: isActive,
    //   hide: true,
    //   // Because only Active user display so no meaning of sorting
    //   sortable: false,
    //   filter: showFilter && 'CustomFilter',
    //   filterParams: {
    //     Component: ActiveUserSelection,
    //     field: "id"
    //   }
    // },
    {
      field: 'updated_at',
      colId: 'updated_at',
      headerName: 'Updated On',
      cellRenderer: 'DateRenderer',
      tooltipValueGetter: DateRenderer,
      hide: true,
      maxWidth: 200,
      minWidth: 50,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: '',
      headerName: 'Profile',
      colId: 'profile',
      maxWidth: 100,
      minWidth: 50,
      tooltipField: '',
      tooltipValueGetter: () => 'Download Profile',
      hide: true,
      sortable: false,
      cellRenderer: 'ProfileRenderer'
    },
    {
      field: 'V Card',
      colId: 'V Card',
      headerName: 'V Card',
      maxWidth: 100,
      minWidth: 50,
      tooltipField: '',
      tooltipValueGetter: () => 'Download V Card',
      hide: true,
      sortable: false,
      cellRenderer: 'VCardRendered'
    },
    {
      field: 'vertical_practice',
      colId: 'vertical_practice',
      headerName: 'Vertical Practice',
      minWidth: 50,
      maxWidth: 160,
      tooltipField: 'vertical_practice',
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      sortable: true,
      cellEditor: 'GenericCellEditor',
      cellRenderer: getVerticalPractice,
      cellEditorParams: {
        InputComponent: verticalPracticeData,
        label: '',
        placeholder: 'Vertical Practice',
        classes: { option: 'ag-custom-component-popup' },
        type: 'vertical'
      },
      valueSetter: params => {
        params.newValue = params.newValue ? params.newValue.name : '';
        if (params.newValue !== params.oldValue) return updateUser(params, 'vertical_practice', enqueueSnackbar, false, SetLoader);
      },
      filter: showFilter && 'CustomFilter',
      filterParams: {
        Component: params => PracticeSelection({ ...params, type: 'vertical' }),
        field: 'name',
        placeholder: 'Vertical Practice'
      }
    },
    {
      field: 'horizontal_practice',
      colId: 'horizontal_practice',
      headerName: 'Horizontal Practice',
      tooltipField: 'horizontal_practice',
      minWidth: 50,
      maxWidth: 160,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      sortable: true,
      cellEditor: 'GenericCellEditor',
      cellRenderer: getHorizontalPractice,
      cellEditorParams: {
        InputComponent: PracticeSelection,
        label: '',
        placeholder: 'Horizontal Practice',
        classes: { option: 'ag-custom-component-popup' },
        type: 'horizontal'
      },
      valueSetter: params => {
        params.newValue = params.newValue ? params.newValue.name : '';
        if (params.newValue !== params.oldValue) return updateUser(params, 'horizontal_practice', enqueueSnackbar, false, SetLoader);
      },
      filter: showFilter && 'CustomFilter',
      filterParams: {
        Component: params => PracticeSelection({ ...params, type: 'horizontal' }),
        field: 'name',
        placeholder: 'Horizontal Practice',
        type: 'horizontal_practice'
      }
    },
    {
      field: 'region',
      colId: 'region',
      headerName: 'Region',
      tooltipField: 'region',
      minWidth: 50,
      maxWidth: 120,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      sortable: true,
      cellEditor: 'GenericCellEditor',
      cellRenderer: getRegion,
      cellEditorParams: {
        InputComponent: RegionSelection,
        label: 'Region',
        placeholder: ''
      },
      valueSetter: params => {
        params.newValue = params.newValue ? params.newValue.name : '';
        return updateUser(params, 'region', enqueueSnackbar, false, SetLoader);
      },
      filter: showFilter && 'CustomFilter',
      filterParams: {
        Component: RegionSelection,
        field: 'name'
      }
    },
    {
      field: 'freeze_dropdown',
      colId: 'freeze_dropdown',
      headerName: '',
      sortable: false,
      filter: false,
      minWidth: 44,
      maxWidth: 44,
      width: 44,
      pinned: 'right',
      lockPinned: true
    },
    {
      field: 'reference_user',
      colId: 'reference_user',
      headerName: 'Referred By',
      tooltipField: 'reference_user.name',
      hide: true,
      minWidth: 50,
      maxWidth: 200,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      sortable: false,
      cellEditor: 'GenericCellEditor',
      cellEditorParams: {
        InputComponent: UserSelection,
        label: '',
        placeholder: '',
        option: option ? option : []
      },
      valueSetter: params => {
        params.data.reference_user = params.newValue;
        params.newValue = params.newValue?.length ? params.newValue.map(data => data.id) : [];
        return updateUser(params, 'reference_users', enqueueSnackbar, false, SetLoader);
      },
      cellRenderer: getReferredBy,
      filter: showFilter && 'CustomFilter',
      filterParams: {
        Component: UserSelection,
        field: 'id'
      }
    },
    {
      field: 'website_url',
      colId: 'website_url',
      headerName: 'Website URL',
      maxWidth: 150,
      minWidth: 50,
      tooltipField: 'website_url',
      cellRenderer: 'WebsiteRenderer',
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      valueSetter: params => {
        return updateUser(params, 'website_url', enqueueSnackbar, false, SetLoader);
      },
      hide: true,
      filter: showFilter && 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'kgp_employee',
      colId: 'kgp_employee',
      hide: true,
      headerName: 'KGP Employee',
      maxWidth: 120,
      minWidth: 50,
      tooltipValueGetter: getKGP_Employee,
      cellRenderer: getKGP_Employee,
      editable: true,
      cellEditor: 'ToggleSelection',
      valueSetter: params => {
        return updateUser(params, 'kgp_employee', enqueueSnackbar, false, SetLoader);
      },
      filter: 'CustomFilter',
      filterParams: {
        Component: OffLimitsSelection,
        field: 'id'
      }
    },
    {
      field: 'postal_code',
      colId: 'postal_code',
      headerName: 'Postal Code',
      tooltipField: 'postal_code',
      sortable: true,
      maxWidth: 200,
      minWidth: 50,
      editable: roles === 'admin' || roles === 'finance' ? true : false,
      valueSetter: params => {
        return updateUser(params, 'postal_code', enqueueSnackbar, false, SetLoader);
      },
      filter: showFilter && 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    }
  ];

  if (roles === 'admin' || roles === 'finance') {
    const personalDetails = [
      {
        value: {
          field: 'personal_email',
          colId: 'personal_email',
          headerName: 'Personal Email',
          tooltipField: 'personal_email',
          maxWidth: 320,
          minWidth: 50,
          cellRenderer: 'EmailRenderer',
          editable: roles === 'admin' || roles === 'finance' ? true : false,
          cellEditor: 'GenericCellEditor',
          cellEditorParams: {
            InputComponent: InputField,
            type: 'email',
            label: ''
          },
          valueSetter: params => {
            const email = EMAIL_REGEX.test(params.newValue) ? params.newValue : null;
            if (!email && params.newValue) {
              const message = validateMessage('valid personal email', 'enter');
              enqueueSnackbar(message, { variant: ERROR });
              return false;
            } else {
              const label = 'personal_email';
              return updateUser(params, label, enqueueSnackbar, false, SetLoader);
            }
          },
          filter: showFilter && 'agTextColumnFilter',
          filterParams: {
            filterOptions: ['contains'],
            buttons: ['reset', 'apply'],
            suppressAndOrCondition: true,
            closeOnApply: true
          }
        },
        index: 4
      },
      {
        value: {
          field: 'location',
          colId: 'location',
          headerName: 'Location',
          tooltipField: 'location',
          maxWidth: 200,
          minWidth: 50,
          editable: roles === 'admin' || roles === 'finance' ? true : false,
          sortable: true,
          cellEditor: 'GenericCellEditor',
          cellRenderer: getLocation,
          cellEditorParams: {
            InputComponent: LocationSelection,
            placeholder: ''
          },
          valueSetter: params => {
            params.newValue = params.newValue ? params.newValue.name : '';
            return updateUser(params, 'location', enqueueSnackbar, false, SetLoader);
          },
          filter: showFilter && 'CustomFilter',
          filterParams: {
            Component: LocationSelection,
            multiple: true
          }
        },
        index: 5
      },
      {
        value: {
          field: 'street',
          colId: 'street',
          headerName: 'Street',
          tooltipField: 'street',
          maxWidth: 200,
          minWidth: 50,
          sortable: true,
          hide: true,
          editable: roles === 'admin' || roles === 'finance' ? true : false,
          valueSetter: params => {
            return updateUser(params, 'street', enqueueSnackbar, false, SetLoader);
          },
          filter: showFilter && 'agTextColumnFilter',
          filterParams: {
            filterOptions: ['contains'],
            buttons: ['reset', 'apply'],
            suppressAndOrCondition: true,
            closeOnApply: true
          }
        },
        index: 14
      },
      {
        value: {
          field: 'city',
          colId: 'city',
          headerName: 'City',
          tooltipField: 'city',
          maxWidth: 200,
          minWidth: 50,
          sortable: true,
          hide: true,
          editable: roles === 'admin' || roles === 'finance' ? true : false,
          valueSetter: params => {
            return updateUser(params, 'city', enqueueSnackbar, false, SetLoader);
          },
          filter: showFilter && 'agTextColumnFilter',
          filterParams: {
            filterOptions: ['contains'],
            buttons: ['reset', 'apply'],
            suppressAndOrCondition: true,
            closeOnApply: true
          }
        },
        index: 15
      },
      {
        value: {
          field: 'state',
          colId: 'state',
          headerName: 'State',
          tooltipField: 'state',
          maxWidth: 200,
          minWidth: 50,
          sortable: true,
          hide: true,
          editable: roles === 'admin' || roles === 'finance' ? getEditable : false,
          cellEditor: 'GenericCellEditor',
          cellEditorParams: {
            InputComponent: stateSelection,
            label: '',
            placeholder: ''
          },
          valueSetter: params => {
            params.newValue = params.newValue.inputValue ? params.newValue.inputValue : params.newValue.title ? params.newValue.title : params.newValue;
            if (params.oldValue === params.newValue || params.newValue === undefined) {
              return false;
            }
            return updateUser(params, 'state', enqueueSnackbar, false, SetLoader);
          },
          filter: false, // showFilter && "agTextColumnFilter",
          filterParams: {
            Component: StateSelection,
            filterOptions: ['contains'],
            buttons: ['reset', 'apply'],
            suppressAndOrCondition: true,
            closeOnApply: true
          }
        },
        index: 16
      },
      {
        value: {
          field: 'country',
          colId: 'country',
          headerName: 'Country',
          maxWidth: 220,
          minWidth: 50,
          tooltipField: 'country',
          editable: roles === 'admin' || roles === 'finance' ? true : false,
          sortable: true,
          hide: true,
          cellEditor: 'GenericCellEditor',
          cellEditorParams: {
            InputComponent: CountrySelection,
            label: '',
            placeholder: ''
          },
          valueSetter: params => {
            params.newValue = params.newValue.name;
            return updateUser(params, 'country', enqueueSnackbar, false, SetLoader);
          },
          filter: showFilter && 'CustomFilter',
          filterParams: {
            Component: CountrySelection,
            multiple: true
          }
        },
        index: 17
      }
    ];

    const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];

    personalDetails.forEach(data => (columnDefs = insert(columnDefs, data.index, data.value)));
  }
  return columnDefs;
};

export const PhoneRenderer = params => {
  const phones = [];
  if (params.value) {
    phones.push({ label: 'Work', value: params.value });
  }

  if (params.data && params.data.mobile_phone) {
    phones.push({ label: 'Personal', value: params.data.mobile_phone });
  }

  if (phones?.length > 0) {
    return (
      <PhonePopover phones={phones}>
        <a href={`tel:${phones[0].value}`} target={'noopener'} className='action-label'>
          {phones[0].value}
        </a>
      </PhonePopover>
    );
  }
  return <div />;
};
