//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { Delete } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';

import Header from './index';
import DeletePopup from '../../../../Containers/Commons/DeletePopup';
import { DELETE_CONFIRMATION_POPUP_MESSAGE, PENDING_REJECT_BILLING_STATUS } from '../../../../services/constantService';
import { getUserSelector } from '../../../../selectors';
import { checkBillingRole, checkSearchApprovalRoles } from '../../../../Containers/Commons/Utils';

export default function EditAction(props) {
  const {
    company,
    jobNumber,
    companyDetails,
    jobTitle,
    confidential,
    isEditing,
    handleCancel,
    handleEdit,
    handleSave,
    renderActionFlag,
    hideEditAction = false,
    search,
    index,
    setInitialLoader
  } = props;
  const userData = useSelector(getUserSelector);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const disableEdit = checkSearchApprovalRoles(userData);
    if (!disableEdit && index === 2 && PENDING_REJECT_BILLING_STATUS.includes(search.billing_status)) {
      setIsHide(true);
    } else if (index === 2 && search.stage === 'Closed') {
      const editable = checkBillingRole(userData);
      if (!editable) {
        setIsHide(true);
      }
    }
  }, [search]);

  const handlePopupClose = deleted => {
    setPopupOpen(false);
    if (deleted) {
      navigate('/searches');
    }
  };
  const handleDelete = () => {
    setPopupOpen(true);
    setIsRemove(true);
  };
  const update = nextState => {
    isRemove && setIsRemove(nextState);
  };
  return (
    <Header companyDetails={companyDetails} company={company} jobNumber={jobNumber} jobTitle={jobTitle} confidential={confidential}>
      {isPopupOpen ? (
        <DeletePopup
          setInitialLoader={setInitialLoader}
          module='searches'
          search={search}
          onClose={handlePopupClose}
          id={id}
          updateParent={update}
          popupText={search.stage === 'BD' ? DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_BD_SEARCH : DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_SEARCH}
        />
      ) : null}
      <div className='d-flex'>
        {userData?.isAdmin ? (
          <div className='action-container d-flex align-items-center' onClick={handleDelete} style={{ paddingLeft: 15 }}>
            <span className='action-icon'>
              <Delete fontSize='inherit' />
            </span>
            <span className='action-text' style={{ color: '#df7928' }}>
              Delete Record
            </span>
          </div>
        ) : null}
        {!hideEditAction ? (
          isEditing && !isHide ? (
            <React.Fragment>
              <Button style={{ color: '#2A364C', border: '1px solid #2A364C', borderRadius: '4px', marginRight: 25 }} className='button button-text-capitalized' variant='text' onClick={handleCancel}>
                Cancel
              </Button>
              <Button style={{ backgroundColor: '#4BB543', color: 'white' }} className='button button-text-capitalized' variant='contained' onClick={handleSave} disabled={renderActionFlag}>
                Save
              </Button>
            </React.Fragment>
          ) : (
            <Button disabled={isHide} style={!isHide ? { backgroundColor: '#2a364c', color: 'white' } : {}} className='button button-text-capitalized' variant='contained' onClick={handleEdit}>
              Edit
            </Button>
          )
        ) : null}
      </div>
    </Header>
  );
}

EditAction.propTypes = {
  company: PropTypes.string,
  companyDetails: PropTypes.object,
  jobNumber: PropTypes.number,
  jobTitle: PropTypes.string,
  isEditing: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
  handleEdit: PropTypes.func,
  confidential: PropTypes.bool,
  renderActionFlag: PropTypes.bool,
  hideEditAction: PropTypes.bool,
  search: PropTypes.object,
  index: PropTypes.number,
  setInitialLoader: PropTypes.func
};
