import React, { useEffect, useState } from 'react';
import CustomDropdown from '../../CustomDropdown';
import PropTypes from 'prop-types';
import { fetchPickLists } from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';
/* import { useState } from "react";
import { useEffect } from "react";
import { fetchPickList } from "../../../../actions";
import { useDispatch, useSelector } from "react-redux"; */

const AddressTypeSelection = props => {
  const { value, name = '', onChange = () => {}, disabled = false, selectedList, isDrawer = false, label = '', setAddressTypeList = () => {}, ...rest } = props;
  const [options, setOptions] = useState([]);
  // const addressTypeOption = [
  //   { label: "Work", value: "Work" },
  //   { label: "Residential", value: "Residential" },
  //   { label: "Direct Line", value: "Direct Line" },
  // ];
  const addressType = useSelector(state => state.commonReducer.addressType);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!addressType) {
      dispatch(fetchPickLists('ADDRESS_TYPE', 'addressType'));
    } else {
      if (addressType) {
        setAddressTypeList(addressType);
        const filterData = addressType?.filter(item => selectedList?.every(listItem => (listItem?.address_type?.field_value || listItem?.address_type) !== item?.field_value));
        setOptions(filterData);
      } else {
        setOptions([]);
      }
    }
  }, [addressType, dispatch, selectedList]);

  return (
    <>
      <CustomDropdown {...rest} value={value} options={options} name={name} onChange={onChange} disabled={disabled} label={label} isDrawer={isDrawer} />
    </>
  );
};

AddressTypeSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isDrawer: PropTypes.bool,
  onChange: PropTypes.func,
  setAddressTypeList: PropTypes.func,
  selectedList: PropTypes.array,
  label: PropTypes.string
};

export default AddressTypeSelection;
