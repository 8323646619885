//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect, useCallback } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Tabs, Tab, Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useDispatch, useSelector } from 'react-redux';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import Header from '../Header';
import Loader from '../../../common/Loader';
import Attributes from './Components/Attributes';
import { getDefaultValues, getUpdateSFPAPayload, initialSFPA } from './utils';
import Targets from './Components/Targets';
import './index.scss';
import PartnerBrief from './Components/PartnerBrief';
import Skills from './Components/Skills';
import Footer from './Components/Footer';
import ClientAppAccess from './Components/ClientAppAccess';
// import LegacySkills from './Components/LegacySkills';
// import LegacyAttributes from './Components/LegacyAttributes'
import ActionsPopover from '../../../../Containers/Contacts/ActionsPopover';
import ReportOptions from '../../../common/ReportOptions';
import { checkForPriorLegacySkillsAndAttribute } from '../../../../utils/date';
import { searchDataApi } from '../../../../services/ApiService';
import { PUT, GET, API, ERROR, SUCCESS, WARNING, SFPA_BUILDER_VALIDATE } from '../../../../services/constantService';
import { VALIDATION_MESSAGE, unableMessage, successMessage, notFoundMessage, validateMessage } from '../../../../services/MessageService';
import { localTimeZoneHandler } from '../../../../Containers/Commons/Utils';
import { UPDATE_SFPADATA, UPDATE_TIMEZONE_DATA } from '../../../../types';
import { fetchCurrencyDetails, fetchPickList, fetchTypeList } from '../../../../actions';
import { searchActions } from '../../../../store/searchSlice';
import SfpaAutomationPopup from './Components/SfpaAutomation';
import ConfirmationPopup from '../../../ConfirmationPopup';

function TabPanel(props) {
  const { children, value, index } = props;
  return value === index && <React.Fragment>{children}</React.Fragment>;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
const defaultSyncFitValue = {
  field_value: 'Athena Questions V2',
  short_desc: 'Athena questionnaire V2'
};
const RICH_TEXT_FIELDS = ['benchmark_feedback', 'summary_feedback', 'executive_feedback', 'partner_brief', 'attributes'];
const SFPA = props => {
  const { search = {}, jobTitle, currentSfpaIndex, confidential, onValueChange, enqueueSnackbar, closeSnackbar, setSFPADirty, setUpdateSFPA, onClose, readOnly, acquire } = props;
  const { id } = useParams();
  const [currentValues, setCurrentValues] = useState({});
  const { register, setValue, unregister, getValues, reset, watch, formState } = useForm();
  const { isDirty } = formState;
  const [value, setTabValue] = useState();
  const [sfpa, setSFPA] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [reportsParams, setReportsParams] = useState({});
  const [startDate, setStartDate] = useState(false);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [defaultLocalTime, setDefaultLocalTime] = useState({});
  const [isSfpaAutomation, setSfpaAutomation] = useState(false);
  const [isSfpaAutoDataGenerated, setSfpaAutoDataGenerated] = useState(false);
  const [searchValidatePopup, setSearchValidatePopup] = useState(false);
  const [invalidSearchMessage, setInvalidSearchMessage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [option, setOptions] = useState([]);
  const [syncfitData, setSyncfitData] = useState(defaultSyncFitValue);
  const timezone = useSelector(state => state.rootReducer.timezone);
  const sfpaData = useSelector(state => state.rootReducer.sfpaData);
  const syncfit_version = useSelector(state => state.commonReducer.syncfit_version);
  const dispatch = useDispatch();
  const currencyDetails = useSelector(state => (state.commonReducer[watch('currency')] ? state.commonReducer[watch('currency')] : {}));
  const sfpaAttributes = useSelector(state => state.commonReducer.sfpaAttributes);
  const navigate = useNavigate();

  useEffect(() => {
    if (value) {
      setTabValue(currentSfpaIndex);
    } else {
      setTabValue(currentSfpaIndex || 0);
    }
  });
  useEffect(() => {
    register('syncfit_version');
  }, [register]);
  useEffect(() => {
    if (!syncfit_version) {
      dispatch(fetchTypeList('SYNCFIT', 'syncfit_version'));
    } else {
      const data = [...syncfit_version];
      setOptions(data);
    }
  }, [syncfit_version, dispatch]);

  useEffect(() => {
    // Initially Render tab components after load sfpa and set currentValues
    if (sfpa) {
      const defaultValues = getDefaultValues(sfpa);
      setCurrentValues(defaultValues);
      reset(defaultValues);
    }
    if (!sfpaAttributes) {
      dispatch(fetchPickList('sfpa-attributes', 'sfpaAttributes'));
    }
  }, [sfpa, setCurrentValues, reset]);
  useEffect(() => {
    setValue('syncfit_version', syncfitData?.field_value);
  }, [syncfitData]);
  useEffect(() => {
    if (Object.keys(search)?.length > 0) {
      if (search.syncfit_version && search.syncfit_desc)
        setSyncfitData({
          field_value: search.syncfit_version,
          short_desc: search.syncfit_desc
        });
    }
  }, [search]);
  const selectSyncfitVersion = data => {
    setSyncfitData(data);
    setAnchorEl(null);
  };
  const getStatus = useCallback(() => {
    const status = checkForPriorLegacySkillsAndAttribute(search?.start_date);
    if (!status) {
      setStartDate(!status);
    }
  }, [search]);
  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(watch('currency'), dispatch);
      } catch (e) {
        console.log('Error found in currencyHandler::', e);
      }
    };
    if (watch('currency') && Object.keys(currencyDetails)?.length === 0) {
      currencyHandler();
    }
  }, [watch('currency')]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!timezone) {
          const data = await localTimeZoneHandler(timeZoneOptions);
          dispatch({ type: UPDATE_TIMEZONE_DATA, payload: { key: 'timezone', data } });
          setTimeZoneOptions(data.timeZoneOptions);
          setDefaultLocalTime(data.defaultLocalTime);
        } else {
          setTimeZoneOptions(timezone.timeZoneOptions);
          setDefaultLocalTime(timezone.defaultLocalTime);
        }
      } catch (e) {
        console.log('Error found in fetchData::', e);
      }
    };
    if (!timeZoneOptions?.length) {
      fetchData();
    }
  }, [timeZoneOptions, timezone, dispatch]);

  const getData = useCallback(
    async (loading = true, returnData) => {
      loading && setLoading(loading);
      const { status, data } = await searchDataApi(GET, id, {}, 'sfpa');
      if (status && status === 200) {
        if (data) {
          if (data.skills && data.skills?.length === 0) {
            data.skills = [{}];
          }
          if (data.off_limits === null) {
            data.off_limits = [];
          }
          if (data.target_industries === null) {
            data.target_industries = [];
          }
          if (data.target_locations === null) {
            data.target_locations = [];
          }
          if (data.target_companies === null) {
            data.target_companies = [];
          }
          if (returnData) return data;
          dispatch({ type: UPDATE_SFPADATA, payload: { key: 'sfpaData', data } });
          setSFPA(data);
        } else {
          if (returnData) return initialSFPA;
          setSFPA(initialSFPA);
        }
      } else {
        const message = unableMessage('SFPA', 'fetch');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setLoading(false);
    },
    [id, enqueueSnackbar]
  );

  useEffect(() => {
    if (!sfpaData) {
      getData();
    } else {
      if (sfpaData.skills && sfpaData.skills?.length === 0) {
        sfpaData.skills = [{}];
      }
      if (sfpaData.off_limits === null) {
        sfpaData.off_limits = [];
      }
      if (sfpaData.target_industries === null) {
        sfpaData.target_industries = [];
      }
      if (sfpaData.target_locations === null) {
        sfpaData.target_locations = [];
      }
      if (sfpaData.target_companies === null) {
        sfpaData.target_companies = [];
      }
      setSFPA(sfpaData);
    }
    if (search && search.start_date === null) {
      setStartDate(true);
    }
    if (search?.start_date) {
      getStatus();
    }
  }, [search, getData, getStatus]);

  const reportURL = () => {
    if (value === 1) {
      const updateURL = `${API.reports}/skill-report/?searchId=${id}`;
      window.open(updateURL);
    } else if (value === 2) {
      const updateURL = `${API.reports}/attribute-report/?searchId=${id}`;
      window.open(updateURL);
    }
    onClose && onClose();
  };

  const validateSearch = () => {
    let fields = [];
    if (!search?.company_id) {
      fields = [...fields, 'Company'];
    }
    if (!search?.job_title) {
      fields = [...fields, 'Job Title'];
    }
    if (!search?.country) {
      fields = [...fields, 'Location'];
    }
    if (!fields?.length) {
      return true;
    }

    const message = `The ${fields.join(', ')} field has missing or incomplete data`;
    setSearchValidatePopup(true);
    setInvalidSearchMessage(message);
    return false;
  };

  const sfpaAutomation = async () => {
    const isValid = validateSearch();
    if (!isValid) return false;

    setSfpaAutomation(true);
  };

  const handleChange = async (event, newValue, oldValue) => {
    try {
      reset({ ...currentValues, ...getValues() });
      setCurrentValues(prevState => ({ ...prevState, ...getValues() }));
      let isDirtyFields =
        formState.dirtyFields && typeof formState.dirtyFields === 'object' && formState.dirtyFields.length > 0 ? [...formState.dirtyFields].filter(item => !RICH_TEXT_FIELDS.includes(item)) : [];
      // if want to display button keep this code and remove code from footer file
      // if(newValue<5|| (startDate && newValue>=5))
      if (!readOnly && isDirty && isDirtyFields?.length > 0) {
        await updateSFPA(oldValue, isDirty);
      }
      onValueChange(newValue);
      setTabValue(newValue);
    } catch (e) {
      console.log('Error found in handleChange::', e);
    }
  };

  // useEffect(() => {
  //   getData()
  // }, [search, getData])

  useEffect(() => {
    getData();
    setSfpaAutoDataGenerated(false);
  }, [isSfpaAutoDataGenerated]);

  const setSfpaData = field => {
    let newSfpaData = { ...sfpaData, ...field };
    if (Object.prototype.hasOwnProperty.call(field, 'attributes')) {
      newSfpaData.attributes = newSfpaData.attributes?.map(item => {
        return {
          ...item,
          attribute: {
            id: item.id,
            definition: item.definition,
            name: item.name
          }
        };
      });
    }
    dispatch({ type: UPDATE_SFPADATA, payload: { key: 'sfpaData', data: newSfpaData } });
    // It impacts on update latest search info tab data
    dispatch(searchActions.resetSearchState());
  };
  // useEffect(() => {
  //   getData().then(null)
  // }, [getData])

  const goToTab = (oldTabNUmber, tabNumber) => {
    if (tabNumber < 0 || tabNumber > 7) {
      return;
    }
    handleChange(null, tabNumber, oldTabNUmber);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const checkValidWeight = (field, rankingArray) => {
    if (rankingArray && rankingArray?.length > 0) {
      const myarray = rankingArray.map(item => item.ranking)?.filter(item => item > 101 || item < 0);
      if (myarray?.length > 0) {
        const message = validateMessage(`valid ${field} weight`, 'enter');
        enqueueSnackbar(message, { variant: WARNING });
        return false;
      }
    }
    return true;
  };

  const handleCloseSfpaAutomation = () => {
    setSfpaAutomation(false);
  };
  const handleConfirmSearchPopup = () => {
    setSearchValidatePopup(false);
    setInvalidSearchMessage('');
    navigate(`/searches/${search.id}/client-info`);
  };

  const handleCloseSearchPopup = () => {
    setSearchValidatePopup(false);
    setInvalidSearchMessage('');
  };

  const updateSFPA = useCallback(
    async (oldValue, isDirty) => {
      try {
        const data = await acquire(id);
        if (data) {
          let isDirtyFields =
            formState.dirtyFields && typeof formState.dirtyFields === 'object' && formState.dirtyFields.length > 0 ? [...formState.dirtyFields].filter(item => !RICH_TEXT_FIELDS.includes(item)) : [];
          if (isDirty && isDirtyFields?.length > 0) {
            const formValues = Object.assign(currentValues, getValues());
            let payload = getUpdateSFPAPayload(formValues, id);
            if (typeof payload.max_years_of_experience_current_company === 'string') {
              payload.max_years_of_experience_current_company = parseInt(payload.max_years_of_experience_current_company);
              if (isNaN(payload.max_years_of_experience_current_company)) {
                payload.max_years_of_experience_current_company = null;
              }
            }
            if (payload.max_years_of_experience_current_company !== '') {
              const yearsValue = payload.max_years_of_experience_current_company;

              // const yearsValue = (payload.max_years_of_experience_current_company)
              if (yearsValue < 0 || yearsValue > 99) {
                const message = validateMessage('valid max year of experience', 'enter');
                enqueueSnackbar(message, { variant: ERROR });
                return;
              }
            }
            if (payload.years_of_experience !== '') {
              const yearsValue = payload.years_of_experience;
              if (yearsValue < 0 || yearsValue > 99) {
                const message = validateMessage('valid min year of experience', 'enter');
                enqueueSnackbar(message, { variant: ERROR });
                return;
              }
            }
            // Validate Weight
            const validIndustryRanking = checkValidWeight('industry', payload.search_industry_rankings);
            if (!validIndustryRanking) return;
            const validCountryRanking = checkValidWeight('country', payload.search_target_country_rankings);
            if (!validCountryRanking) return;

            payload.updated_at = sfpa.updated_at;
            setLoading(true);
            delete payload.cs_location_placeofinterest;
            delete payload.cs_location_realestate;
            delete payload.cs_location_restaurantsandshopping;
            delete payload.cs_location_schoolsandcolleges;
            delete payload.cs_location_weather;
            delete payload.target_industries_group;
            delete payload.target_country_group;
            if (oldValue === 0 || value === 0) {
              delete payload.client_team;
              delete payload.attributes;
              delete payload.company;
              delete payload.cs_location_placeofinterest;
              delete payload.cs_location_realestate;
              delete payload.cs_location_restaurantsandshopping;
              delete payload.cs_location_schoolsandcolleges;
              delete payload.cs_location_weather;
              delete payload.skills;
            } else if (oldValue === 1 || value === 1) {
              payload = { skills: payload.skills };
            } else if (oldValue === 2 || value === 2) {
              payload = { attributes: payload.attributes };
            } else if (oldValue === 4 || value === 4) {
              payload = { client_team: payload.client_team };
            }
            const { status, data } = await searchDataApi(PUT, id, payload, 'sfpa');
            if (status && status === 200) {
              const message = successMessage('SFPA', VALIDATION_MESSAGE.updated_message);
              enqueueSnackbar(data?.message || message, { variant: SUCCESS });
              if (data.errorDetails) {
                const message = unableMessage('in HL', 'update data');
                enqueueSnackbar(message, { variant: ERROR });
              }
              await getData();
              // It impacts on update latest search info tab data
              dispatch(searchActions.resetSearchState());
            } else if (status === 409) {
              const message = unableMessage('activity info', 'save');
              enqueueSnackbar(data?.message || message, {
                persist: true,
                variant: ERROR,
                // eslint-disable-next-line react/display-name
                action: key => (
                  <Button
                    size='small'
                    variant='text'
                    color='inherit'
                    onClick={() => {
                      closeSnackbar(key);
                    }}
                  >
                    Dismiss
                  </Button>
                )
              });
            } else {
              const message = unableMessage('SFPA', 'update');
              enqueueSnackbar(data?.message || message, { variant: ERROR });
            }
            setLoading(false);
          } else {
            const message = notFoundMessage('Changes');
            enqueueSnackbar(message, { variant: WARNING });
          }
        }
      } catch (e) {
        console.log('Error found in updateSFPA::', e);
      }
    },
    [currentValues, sfpa, id, getValues, enqueueSnackbar, closeSnackbar, getData]
  );

  useEffect(() => {
    setSFPADirty(isDirty);
  }, [isDirty, setSFPADirty]);

  useEffect(() => {
    setUpdateSFPA(updateSFPA);
  }, [setUpdateSFPA, updateSFPA]);

  return (
    <div className='position-relative h-100' style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Loader show={isLoading} />
      {isReportsOpen && (
        <ReportOptions
          timeZoneOptions={timeZoneOptions}
          defaultLocalTime={defaultLocalTime}
          onClose={() => {
            setIsReportsOpen(false);
            setReportsParams({});
          }}
          {...reportsParams}
        />
      )}
      {sfpa && Object.keys(currentValues)?.length && (
        <>
          <Header companyDetails={search.company} company={search.company && search.company.name} jobNumber={search.job_number} jobTitle={jobTitle} confidential={confidential}>
            <div className='d-flex'>
              <Button
                variant='outlined'
                color='primary'
                style={{ textTransform: 'none', margin: '0 20px 0', width: '189px' }}
                size='small'
                aria-controls='simple-menu'
                aria-haspopup='true'
                onClick={event => handleClick(event)}
                disabled={readOnly}
              >
                <span className='ml-1'>{syncfitData.short_desc}</span>
              </Button>
              <Menu id='simple-menu' style={{ margin: '50px 0' }} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {option &&
                  option.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={item.field_value}
                        onClick={() => {
                          selectSyncfitVersion({
                            field_value: item.field_value,
                            short_desc: item.short_desc
                          });
                        }}
                      >
                        {item.short_desc}
                      </MenuItem>
                    );
                  })}
              </Menu>
              {!readOnly && (
                <div className='action-container d-flex align-items-center' onClick={sfpaAutomation}>
                  {' '}
                  <span className='material-icons psychology-icon action-icon'>psychology</span>
                  <span className='action-text'>SFPA Builder</span>
                </div>
              )}
              {isSfpaAutomation && (
                <SfpaAutomationPopup onClose={handleCloseSfpaAutomation} search={search} sfpa={sfpa} enqueueSnackbar={enqueueSnackbar} setSfpaAutoDataGenerated={setSfpaAutoDataGenerated} />
              )}
              {[1, 2].includes(value) && (
                <div className='action-container d-flex align-items-center' onClick={reportURL}>
                  <span className='action-icon'>
                    <PrintIcon fontSize='inherit' />
                  </span>
                  <span className='action-text'>Print</span>
                </div>
              )}

              <ActionsPopover
                className='action-container d-flex align-items-center'
                label={
                  <div className='action-container d-flex align-items-center'>
                    <span className='action-icon'>
                      <InsertDriveFileIcon fontSize='inherit' />
                    </span>
                    <span className='action-text'>SFPA Reports</span>
                  </div>
                }
                list={[
                  {
                    label: 'Athena Comparison Report Candidates',
                    onClick: () => {
                      setIsReportsOpen(true);
                      setReportsParams({
                        params: {
                          searchId: id,
                          timeZone: false
                        },
                        url: `${API.reports}/athena-benchmark-comparison-multiple-candidates`
                      });
                    }
                  },
                  {
                    label: 'Attribute Selector Comparison Report',
                    onClick: () => {
                      setIsReportsOpen(true);
                      setReportsParams({
                        params: {
                          searchId: id,
                          timeZone: false
                        },
                        options: {
                          pageSize: true,
                          locale: false
                        },
                        url: `${API.reports}/attribute-selector-results-comparison`
                      });
                    }
                  },
                  {
                    label: 'Athena Executive Summary Report',
                    onClick: () => {
                      setIsReportsOpen(true);
                      setReportsParams({
                        params: {
                          searchId: id,
                          timeZone: false
                        },
                        url: `${API.reports}/athena-executive-summary`
                      });
                    }
                  },
                  {
                    label: 'Athena Summary Report Package',
                    onClick: () => {
                      setIsReportsOpen(true);
                      setReportsParams({
                        params: {
                          searchId: id,
                          timeZone: false
                        },
                        url: `${API.reports}/athena-summary-package`
                      });
                    }
                  },
                  {
                    label: 'SFPA Report',
                    onClick: () => {
                      setIsReportsOpen(true);
                      setReportsParams({
                        params: {
                          searchId: id,
                          timeZone: true
                        },
                        url: `${API.reports}/sfpa-report`
                      });
                    }
                  },
                  {
                    label: 'Position Profile',
                    onClick: () => {
                      setIsReportsOpen(true);
                      setReportsParams({
                        params: {
                          searchId: id,
                          timeZone: false
                        },
                        url: `${API.reports}/position-profile-report`
                      });
                    }
                  }
                ]}
              />
              {!readOnly && (
                <Button color='primary' className='button' variant='contained' onClick={() => updateSFPA(undefined, isDirty)}>
                  Save
                </Button>
              )}
            </div>
            {searchValidatePopup && (
              <ConfirmationPopup
                header={SFPA_BUILDER_VALIDATE}
                message={invalidSearchMessage}
                onClose={handleCloseSearchPopup}
                onConfirm={handleConfirmSearchPopup}
                onCancel={handleCloseSearchPopup}
                confirmText='Go to Search'
                cancelText='Close'
              />
            )}
          </Header>
          <Tabs
            value={value}
            indicatorColor='secondary'
            textColor='secondary'
            style={{ background: '#f4f4f5', flex: 1, overflow: 'auto' }}
            onChange={(e, newValue) => handleChange(e, newValue, value)}
            aria-label='disabled tabs example'
          >
            {/* eslint-disable-next-line react/display-name */}
            <Tab
              component={React.forwardRef((params, ref) => {
                return (
                  <div ref={ref} className='details-section align-items-center' style={{ color: value === 0 ? '#DE9F6D' : '' }} onClick={params.onClick}>
                    Target
                  </div>
                );
              })}
            />
            {/* eslint-disable-next-line react/display-name */}
            <Tab
              disabled
              component={React.forwardRef((params, ref) => {
                return (
                  <div ref={ref} className='details-section align-items-center' style={{ color: value === 1 ? '#DE9F6D' : '' }} onClick={params.onClick}>
                    Skills
                  </div>
                );
              })}
            />
            {/* eslint-disable-next-line react/display-name */}
            <Tab
              component={React.forwardRef((params, ref) => {
                return (
                  <div ref={ref} className='details-section align-items-center ' style={{ color: value === 2 ? '#DE9F6D' : '' }} onClick={params.onClick}>
                    Attributes
                  </div>
                );
              })}
            />
            {/* eslint-disable-next-line react/display-name */}
            <Tab
              component={React.forwardRef((params, ref) => {
                return (
                  <div ref={ref} className='details-section align-items-center' style={{ color: value === 3 ? '#DE9F6D' : '' }} onClick={params.onClick}>
                    Partner Brief
                  </div>
                );
              })}
            />
            {/* eslint-disable-next-line react/display-name */}
            <Tab
              component={React.forwardRef((params, ref) => {
                return (
                  <div ref={ref} className='details-section align-items-center' style={{ color: value === 4 ? '#DE9F6D' : '' }} onClick={params.onClick}>
                    <span className='section-icon'></span>
                    Client App Access
                  </div>
                );
              })}
            />
            {/* {startDate && (
            // eslint-disable-next-line react/display-name 
            <Tab component={React.forwardRef((params, ref) => {
              return (
                <div ref={ref} className="details-section align-items-center" style={{ color: value === 5 ? '#DE9F6D' : '' }} onClick={params.onClick}>
                  <span className="section-icon">
                  </span>
                  Legacy Skills
                </div>
              )
            })} />
          )} */}
            {/* {startDate && (
            // eslint-disable-next-line react/display-name
            <Tab component={React.forwardRef((params, ref) => {
              return (
                <div ref={ref} className="details-section align-items-center" style={{ color: value === 6 ? '#DE9F6D' : '' }} onClick={params.onClick}>
                  <span className="section-icon">
                  </span>
                  Legacy Attributes
                </div>
              )
            })} />
          )} */}
          </Tabs>
          <div className='company-view-container'>
            <div style={{ padding: 0, flex: 1, overflow: 'auto', height: 'calc(100% - 70px)' }}>
              <TabPanel index={0} value={value}>
                <Targets readOnly={readOnly} currencyDetails={currencyDetails} register={register} unregister={unregister} watch={watch} setValue={setValue} currentValues={currentValues} />
              </TabPanel>
              <TabPanel index={1} value={value}>
                <Skills
                  readOnly={readOnly}
                  currentValues={currentValues}
                  setValue={setValue}
                  register={register}
                  getData={getData}
                  setLoading={setLoading}
                  setCurrentValues={setCurrentValues}
                  setSfpaData={setSfpaData}
                  enqueueSnackbar={enqueueSnackbar}
                />
              </TabPanel>
              <TabPanel index={2} value={value}>
                <Attributes
                  readOnly={readOnly}
                  currentValues={currentValues}
                  register={register}
                  setValue={setValue}
                  getData={getData}
                  searchCreatedAt={search.created_at}
                  setSfpaData={setSfpaData}
                  setCurrentValues={setCurrentValues}
                  setLoading={setLoading}
                  enqueueSnackbar={enqueueSnackbar}
                />
              </TabPanel>
              <TabPanel index={3} value={value}>
                <PartnerBrief
                  readOnly={readOnly}
                  searchInfo={search}
                  register={register}
                  setValue={setValue}
                  currentValues={currentValues}
                  setCurrentValues={setCurrentValues}
                  setSfpaData={setSfpaData}
                />
              </TabPanel>
              <TabPanel index={4} value={value}>
                <ClientAppAccess
                  readOnly={readOnly}
                  setCurrentValues={setCurrentValues}
                  setSfpaData={setSfpaData}
                  searchInfo={search}
                  register={register}
                  setValue={setValue}
                  currentValues={currentValues}
                  watch={watch}
                />
              </TabPanel>
              {/* <TabPanel index={5} value={value}>
              <LegacySkills readOnly={readOnly} currentValues={currentValues} />
            </TabPanel>
            <TabPanel index={6} value={value}>
              <LegacyAttributes readOnly={readOnly} currentValues={currentValues} />
            </TabPanel> */}
            </div>
            <Footer prev={() => goToTab(value, value - 1)} next={() => goToTab(value, value + 1)} value={value} startDate={startDate} />
          </div>
        </>
      )}
    </div>
  );
};

SFPA.propTypes = {
  id: PropTypes.string,
  search: PropTypes.object,
  jobTitle: PropTypes.string,
  confidential: PropTypes.bool,
  readOnly: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  acquire: PropTypes.func,
  closeSnackbar: PropTypes.func,
  setSFPADirty: PropTypes.func,
  setUpdateSFPA: PropTypes.func,
  onClose: PropTypes.func,
  currentSfpaIndex: PropTypes.number,
  onValueChange: PropTypes.func
};

export default SFPA;
