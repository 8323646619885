import React, { useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ExpandableSection } from '../../../../../components/ActivityLog/Components/ExpandableSection';
import FeetypeSelection from '../../../../../components/common/FunctionalComponents/FeetypeSelection';
import RegionSelection from '../../../../../components/common/FunctionalComponents/RegionSelection';
import CustomInputField from '../../../../../components/common/StyledComponents/CustomInputField';
// import "../../../../../Containers/Commons/SwitchCss/index.scss"

//----------------------------------------------// Internal Imports // -------------------------------------------------

const BillingInformation = props => {
  const { register, watch, setValue, isEditing = true, isDisabled = false, currentValues } = props;
  useEffect(() => {
    if (isEditing) {
      const field = [
        'billable',
        'billing_region',
        'contact_name',
        'billing_notes',
        'zip_code',
        'mobile_phone',
        'work_email',
        'is_po_direct',
        'invoice_delivery_type',
        'po_number',
        'billing_notes',
        'vendor_desc'
      ];
      field.forEach(field => setValue(field, currentValues[field]));
    }
  }, []);
  useEffect(() => {
    register('invoice_delivery_type');
    register('contact_name');
    register('vendor_portal_url');
    register('vendor_portal');
    register('billing_notes');
  }, [register]);
  return (
    <ExpandableSection title='Billing Information' defaultOpen={true} accordionClassName='accordion' titleClassName='title-text' isTeamInfo={true}>
      <Box>
        <Box id='education-experiences' className='p-4'>
          <Box className='my-cards flex flex-wrap'>
            <Box className='form-section flex flex-column'>
              <Box className='d-flex flex-wrap p-2 justify-content-between'>
                <Box className='col-md-6 d-flex align-items-center'>
                  <Typography className='p-2' variant='body2'>
                    {'Billable'}
                  </Typography>
                  <FormControlLabel
                    className='mb-0'
                    control={
                      <Switch
                        disabled={isDisabled}
                        className='SwitchStyle'
                        checked={watch('billable') || false}
                        onChange={e => {
                          setValue('billable', e.target.checked);
                        }}
                        {...register('billable')}
                      />
                    }
                  />
                </Box>
                <Box className='col-md-6'>
                  <RegionSelection
                    isDisabled={isDisabled}
                    defaultValue={watch('billing_region') || currentValues?.billing_region || null}
                    onChange={(e, data) => {
                      setValue('billing_region', data?.short_desc);
                    }}
                    label='Billing Region'
                    required={true}
                    viewAddress={true}
                  />
                </Box>
              </Box>

              <Box className='d-flex flex-wrap p-2 justify-content-between'>
                <Box className='col-md-6 d-flex align-items-center'>
                  <Typography className='p-2' variant='body2'>
                    {'PO Required'}
                  </Typography>
                  <FormControlLabel
                    className='mb-0'
                    control={
                      <Switch
                        disabled={isDisabled}
                        className='SwitchStyle'
                        checked={watch('is_po_direct') || false}
                        onChange={e => {
                          setValue('is_po_direct', e.target.checked);
                        }}
                        {...register('is_po_direct')}
                      />
                    }
                  />
                </Box>
                <Box className='col-md-6'>
                  {watch('is_po_direct') ? (
                    <CustomInputField
                      disabled={!isEditing}
                      isDisabled={isDisabled}
                      label='PO'
                      defaultValue={watch('po_number') || null}
                      InputLabelProps={{ focused: true }}
                      onChange={e => {
                        setValue('po_number', e.target.value);
                      }}
                    />
                  ) : (
                    <Box component={'span'} className='search-details'>
                      &nbsp;
                    </Box>
                  )}
                </Box>
              </Box>

              <Box className='d-flex flex-wrap p-2 justify-content-between'>
                <Box className='col-md-12'>
                  <CustomInputField
                    disabled={!isEditing}
                    isDisabled={isDisabled}
                    multiline
                    rowsMax={3}
                    defaultValue={currentValues?.billing_address || null}
                    label='Billing Address'
                    InputLabelProps={{ focused: true }}
                    onChange={e => {
                      setValue('billing_address', e.target.value);
                    }}
                  />
                </Box>
              </Box>

              <Box className='d-flex flex-wrap p-2 justify-content-between'>
                <Box className='col-md-12'>
                  <CustomInputField
                    disabled={!isEditing}
                    isDisabled={isDisabled}
                    multiline
                    rowsMax={3}
                    defaultValue={currentValues?.billing_notes || null}
                    label='Billing Notes'
                    InputLabelProps={{ focused: true }}
                    onChange={e => {
                      setValue('billing_notes', e.target.value);
                    }}
                  />
                </Box>
              </Box>

              <Box className='d-flex flex-wrap p-2 justify-content-between'>
                <Box className='col-md-6'>
                  <CustomInputField
                    ref={register}
                    disabled={!isEditing}
                    isDisabled={isDisabled}
                    label='Email addresses'
                    defaultValue={currentValues.email_invoice_to || null}
                    InputLabelProps={{ focused: true }}
                    name='email_invoice_to'
                    onChange={e => {
                      setValue('email_invoice_to', e.target.value);
                    }}
                  />
                </Box>
                <Box className='col-md-6'>
                  <FeetypeSelection
                    isDisabled={isDisabled}
                    value={currentValues?.vendor_portal_desc?.short_desc || watch('vendor_desc') || null}
                    ref={register('vendor_desc')}
                    InputLabelProps={{ focused: true }}
                    type='VENDOR_PORTAL'
                    feeType='vendor_portal'
                    onChange={(e, data) => {
                      setValue('vendor_portal', data ? data.field_value : null);
                      setValue('vendor_desc', data ? data.short_desc : null);
                      setValue('vendor_portal_url', data ? data.long_desc : null);
                    }}
                    label='Vendor Portal'
                    viewAddress={true}
                  />
                </Box>
              </Box>

              <Box className='d-flex flex-wrap p-2 justify-content-between'>
                <Box className='col-md-12 d-flex align-items-center'>
                  <Box className='fs-14'>Portal URL</Box>
                  <Box component='span' className='fs-14 ml-3'>
                    <a
                      href={`${watch('vendor_portal_url')}`}
                      rel='noopener noreferrer'
                      target='_blank'
                      className={watch('vendor_portal_url') ? 'text-lowercase action-text company-redirect-link' : 'contact-view-label'}
                    >
                      {watch('vendor_portal_url') || '--'}
                    </a>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ExpandableSection>
  );
};

BillingInformation.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  isEditing: PropTypes.bool,
  isDisabled: PropTypes.bool,
  currencyDetails: PropTypes.object,
  currentValues: PropTypes.object
};

export default BillingInformation;
