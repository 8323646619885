//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import PopoverStickOnHover from '../../components/common/PopoverStickOnHover';

const EmailPopover = props => {
  const { emails = [], children } = props;

  const renderEmailView = (email, index) => {
    return (
      <div className='d-flex flex-wrap pop-over-action-item' key={index}>
        <span className='email-label'>{`${email.label || 'Email'}:`}</span>
        <a href={`mailto:${email.value}`} target={'noopener'} className='pop-over-action-value'>
          {email.value}
        </a>
      </div>
    );
  };

  const renderPopoverContent = emails => {
    return (
      <Typography className='pop-over-actions pop-over-email' component='div'>
        {emails.map((email, index) => {
          return renderEmailView(email, index);
        })}
      </Typography>
    );
  };

  return emails?.length > 0 ? (
    <PopoverStickOnHover component={renderPopoverContent(emails)} placement='bottom' onMouseEnter={() => {}} delay={200}>
      {children}
    </PopoverStickOnHover>
  ) : (
    <div />
  );
};

EmailPopover.propTypes = {
  emails: PropTypes.array,
  children: PropTypes.node
};

export default EmailPopover;
