//----------------------------------------------// Internal Imports // -------------------------------------------------

import { compareByPosition } from '../../../../utils/common';
export const getDefaultValues = sfpa => {
  if (sfpa && sfpa.skills) {
    if (sfpa.skills?.length === 0) {
      sfpa.skills = [{}];
    }
    sfpa.skills.forEach(skill => {
      if (!skill.elements) {
        skill.elements = [];
      }
      if (!skill.title) {
        skill.title = '';
      }
    });
  }
  const defaultValues = { ...sfpa };
  if (defaultValues.target_locations) {
    defaultValues.target_locations = defaultValues.target_locations.map(item => {
      return { name: item };
    });
  }
  if (defaultValues.target_industries) {
    defaultValues.target_industries = defaultValues.target_industries.map(item => {
      return { name: item };
    });
  }
  if (!defaultValues.contact_targets || defaultValues.contact_targets?.length === 0) {
    defaultValues.contact_targets = [{}];
  } else {
    defaultValues.contact_targets = defaultValues.contact_targets.sort(compareByPosition);
  }
  if (!defaultValues.representative_target_companies || defaultValues.representative_target_companies?.length === 0) {
    defaultValues.representative_target_companies = [{}];
  } else {
    defaultValues.representative_target_companies = defaultValues.representative_target_companies.sort(compareByPosition);
  }
  defaultValues.attributes =
    defaultValues?.attributes && defaultValues.attributes?.length > 0
      ? defaultValues.attributes
          .map(item => {
            return {
              ...item.attribute,
              position: item.position,
              search_attribute_id: item.id,
              question: item.question
            };
          })
          .sort((a, b) => a.position - b.position)
      : [{}, {}, {}, {}];
  if (defaultValues.skills?.length) {
    defaultValues.skills = defaultValues.skills.sort(compareByPosition).map(skill => {
      if (skill.elements?.length) {
        skill.elements = skill.elements.sort(compareByPosition);
      }
      return skill;
    });
  }
  if (defaultValues.client_team?.length) {
    defaultValues.client_team = defaultValues.client_team.map(item => ({ ...item, ...item.contact, client_id: item.id }));
    defaultValues.client_team = defaultValues.client_team.sort(compareByPosition);
  }
  return defaultValues;
};
export const getUpdateSFPAPayload = formValues => {
  const payload = { ...formValues };
  if (payload.contact_targets) {
    payload.contact_targets = payload.contact_targets.map((contactTarget, position) => {
      return {
        id: contactTarget.id,
        title: contactTarget.title ? contactTarget.title : null,
        employee_max: contactTarget.employee_max ? parseInt(contactTarget.employee_max) : null,
        employee_min: contactTarget.employee_min ? parseInt(contactTarget.employee_min) : null,
        revenue_max: contactTarget.revenue_max ? parseInt(contactTarget.revenue_max) : null,
        revenue_min: contactTarget.revenue_min ? parseInt(contactTarget.revenue_min) : null,
        position
      };
    });
  }
  const companyarray = [];
  companyarray.push(payload.company);
  payload.company = companyarray;
  payload.representative_target_companies = payload.representative_target_companies.map((item, index) => ({ ...item, position: index }));
  payload.target_companies = payload.target_companies?.filter(companyName => companyName && companyName !== '');
  payload.target_base_max = payload.target_base_max ? parseInt(payload.target_base_max) : null;
  payload.target_base_min = payload.target_base_min ? parseInt(payload.target_base_min) : null;
  payload.target_bonus = payload.target_bonus ? parseInt(payload.target_bonus) : null;
  payload.target_total_base_and_bonus = payload.target_total_base_and_bonus ? parseInt(payload.target_total_base_and_bonus) : null;
  payload.years_of_experience = payload.years_of_experience ? parseInt(payload.years_of_experience) : null;
  if (payload.target_locations) {
    payload.target_locations = payload.target_locations.map(item => {
      return item.name;
    });
  }
  if (payload.target_industries) {
    payload.target_industries = payload.target_industries.map(item => {
      return item.name;
    });
  }
  if (payload.off_limits) {
    payload.off_limits = payload.off_limits?.filter(item => item !== '');
  }
  payload.attributes = payload?.attributes
    ?.filter(item => item && item.id)
    .map((item, index) => {
      return {
        attribute_id: item.id,
        position: index,
        id: item.search_attribute_id,
        question: item.question
      };
    });
  if (payload.skills) {
    payload.skills = payload.skills.map((skill, index) => {
      skill.position = index;
      if (skill.elements) {
        skill.elements = skill.elements
          ?.filter(element => element.minimum || element.ideal)
          .map((item, eleIndex) => {
            item.position = eleIndex;
            return item;
          });
      }
      return skill;
    });
  }
  payload.client_team = payload.client_team
    ?.filter(item => item.contact)
    .map((item, index) => {
      return {
        contact_id: item.contact?.id,
        is_hiring_manager: item.is_hiring_manager,
        position: index,
        id: item.client_id
      };
    });
  return payload;
};
export const initialSFPA = {
  skills: [{}],
  off_limits: [],
  target_industries: [],
  target_locations: [],
  target_companies: []
};
