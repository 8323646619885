import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';

import { notFoundMessage } from '../../services/MessageService';
import {
  columnDefs,
  CustomLoadingOverlayComponent,
  getFilterParamStringForApprovalSearch,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE
  // processAddSearchesResponse,
} from './utils';
import { DateRenderer, DateTimeRenderer } from '../Commons/Utils';
import { SCROLL_TIMEOUT } from '../../utils/common';
//import { getFilterParamStringForTranslateValue } from './utils'
import { fetchUserList } from '../../actions';
import { GET, WARNING } from '../../services/constantService';
import { Link } from 'react-router-dom';
import withRouter from '../../utils/withRouter';
import './index.scss';
import { searchDataApi } from '../../services/ApiService';
import { RichTextPopupRendered } from '../../utils/common';

//-----------------------------------------------------------// Internal Imports // ------------------------------

class SearchApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewColumns: false,
      anchorEl: null,
      isClassicQuery: false,
      minimizeQuery: true,
      rowCount: 0,
      showSearch: false,
      searchValue: '',
      isAllRowsSelected: false,
      isRowSelected: false,
      isAddToWorkbenchOpen: false,
      searchIds: [],
      isCopyToWorkbenchOpen: false,
      selectedRowsCount: 0,
      linkedPopup: false,
      linkedInValue: {},
      isLoading: false
    };
  }

  dataSource = {
    getRows: async params => {
      this.setState({ isLoading: true });
      if (params.filterModel && Object.keys(params.filterModel)?.length > 0) this.gridApi.deselectAll();

      const { searchType } = this.props;
      const { filterModel, sortModel } = params;
      const paginationString = `limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}`;
      let url = '';
      this.gridApi.showLoadingOverlay();
      //const paramsString = getWorkbenchQueryParamsString(queryData);
      //const classicQueryParams = paramsString?.length > 0 && classicSelector ? paramsString : '';
      switch (searchType) {
        case 'approvalRejected':
          url = `approval?${paginationString}&searchApprovalType=${searchType}`;
          break;
        case 'approvalPending':
          url = `approval?${paginationString}&searchApprovalType=${searchType}`;
          break;
        default:
          console.log('No Match Found');
      }

      if (Object.keys(filterModel)?.length) {
        url = url.concat(getFilterParamStringForApprovalSearch(filterModel));
      }

      if (sortModel?.length > 0 && !this.isSelectedFromList) {
        url = url.concat(`&sortOn=${sortModel[0].colId}&sortType=${sortModel[0].sort.toUpperCase()}`);
      }
      const { status, data } = await searchDataApi(GET, '', {}, url);
      if (status === SUCCESS_STATUS_CODE && data?.data) {
        if (data?.paging?.totalCount === 0) {
          const message = notFoundMessage('records');
          this.props.enqueueSnackbar(message, { variant: WARNING });
        }

        params.successCallback(data.data, data.paging?.totalCount);
        this.setState({ rowCount: data.paging?.totalCount });
        this.state.isAllRowsSelected && this.setSelectAllRows(this.state.isAllRowsSelected);
      }
      params.failCallback();
      this.gridApi.hideOverlay();
      if (this.state.defaultValue) {
        this.setState({ defaultValue: false });
      }
      this.setState({ isLoading: false });
    },
    rowCount: null
  };

  onGridReady = params => {
    params.columnApi.autoSizeColumns();
    this.gridApi = params.api;
  };

  IdRenderer = params => {
    return (
      <Link
        to={{
          pathname: `/searches/${params?.data?.id}/billing-info`,
          state: { isFromApproval: true },
          previousPath: this.props.location?.pathname
        }}
        rel='noopener noreferrer'
      >
        <Typography className='text-link' color='primary'>
          {params.value}
        </Typography>
      </Link>
    );
  };

  TitleRenderer = params => {
    return (
      <Link
        to={{
          pathname: `/searches/${params?.data?.id}/billing-info`,
          state: { isFromApproval: true },
          previousPath: this.props.location?.pathname
        }}
        rel='noopener noreferrer'
      >
        <Typography className='text-link' color='primary'>
          {params.value}
          {params?.data?.is_confidential === true ? ' (Confidential)' : ''}
        </Typography>
      </Link>
    );
  };

  render() {
    const { searchType } = this.props;
    return (
      <div className='all-candidates d-flex flex-column h-100 search-approval-container'>
        {/* {isPopupOpen ? <DeletePopup module='searches' onClose={handlePopupClose} id={id} updateParent={update} popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_SEARCH} /> : null} */}
        <div className='d-flex justify-content-between align-items-end position-sticky search-header p-3'>
          <Typography className='job-title'>Approval List</Typography>
        </div>
        <div className={'d-flex flex-column team-information-table h-100'}>
          <div className='d-flex table-header'>
            <div className='d-flex'>{searchType === 'approvalPending' ? 'Pending Approval' : 'Rejected Approval'}</div>
          </div>
          <div className='d-flex flex-column w-100 h-100'>
            <div className='list-view' id='search-approval-grid'>
              <div id='myGrid' className='ag-theme-alpine'>
                <AgGridReact
                  onGridReady={this.onGridReady}
                  enableBrowserTooltips={true}
                  defaultColDef={{
                    minWidth: 100,
                    resizable: true,
                    sortable: true,
                    sortingOrder: ['asc', 'desc', null]
                  }}
                  cacheBlockSize={PAGE_LIMIT}
                  loadingOverlayComponent={'CustomLoadingOverlayComponent'}
                  frameworkComponents={{
                    CustomLoadingOverlayComponent,
                    IdRenderer: this.IdRenderer,
                    TitleRenderer: this.TitleRenderer,
                    DateTimeRenderer,
                    DateRenderer,
                    RichTextPopupRendered
                  }}
                  onRowSelected={() => {
                    this.setState({
                      isRowSelected: this.gridApi.getSelectedRows()?.length > 0
                    });
                  }}
                  getRowNodeId={data => data.id}
                  blockLoadDebounceMillis={SCROLL_TIMEOUT}
                  scrollbarWidth={12}
                  suppressHorizontalScroll={false}
                  rowModelType={'infinite'}
                  datasource={this.dataSource}
                  columnDefs={columnDefs()}
                  paginationPageSize={PAGE_LIMIT}
                  rowSelection={'multiple'}
                  suppressRowClickSelection={true}
                  suppressMenuHide={true}
                  suppressDragLeaveHidesColumns={true}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SearchApproval.propTypes = {
  searchType: PropTypes.string.isRequired,
  columnStateKey: PropTypes.string.isRequired,
  searchStatus: PropTypes.string,
  title: PropTypes.string,
  placedSearches: PropTypes.bool,
  activeSearches: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  userData: PropTypes.object,
  id: PropTypes.string,
  users: PropTypes.array,
  fetchUserList: PropTypes.func,
  location: PropTypes.object
};

const mapStateToProps = state => {
  return {
    userData: state.commonReducer.userData,
    users: state.rootReducer.users
  };
};

const mapDispatchToProps = { fetchUserList };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchApproval));
