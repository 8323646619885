import React, { useEffect, useRef, useState } from 'react';

import { CopyAllOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { updateText } from '../../Containers/ProductSetup/MessageTemplates/EmailTemplates/utils';
import useMessageTemplateData from '../../Containers/ProductSetup/MessageTemplates/MessageTemplateData';
import { emailTemplatesApi, ProjectDataApi } from '../../services/ApiService';
import { invalidMessage, requireMessage, validateMessage } from '../../services/MessageService';
import { CLIPBOARD_COPY_MESSAGE, MESSAGE_STATUS, POST } from '../../services/constantService';
import useApi from '../../utils/Hooks/useApiHook';
import CustomButton from '../common/CustomButton';
import CustomPopup from '../common/CustomPopup';
import Loader from '../common/Loader';
import MessageTemplatesRT from './MessageTemplatesRT';
import './index.scss';
import { renderSelectGroup, replacePlaceholders, validateEmail, validateEmailCSV } from './utils';

const InitialFormState = {
  subject: '',
  body: '',
  to: '',
  cc: '',
  bcc: ''
};

function removeColorSpans(text) {
  // Define a regular expression to match the <span> elements with id="pop-color".
  const regex = /<span\s+id="pop-color"[^>]*>(.*?)<\/span>/g;

  // Use the replace method with a callback function to remove the matching <span> elements.
  return text.replaceAll(regex, (match, capturedText) => capturedText);
}

const MessageTemplatePopup = ({ isOpen, setIsPopupOpen, data }) => {
  const { handleSubmit, setValue, register, unregister, watch, reset, getValues } = useForm({
    InitialFormState
  });

  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const { messageTemplateData } = useMessageTemplateData(data);

  const [currentTemplate, setCurrentTemplate] = useState('');
  const [lastActiveElement, setLastActiveElement] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [sortedResponse, setSortedResponse] = useState([]);

  const subjectRef = useRef(null);
  const toRef = useRef(null);
  const ccRef = useRef(null);
  const bccRef = useRef(null);
  const bodyRef = useRef(null);

  useEffect(() => {
    register('subject');
    register('body');
    register('to');
    register('cc');
    register('bcc');

    return () => {
      unregister('subject');
      unregister('body');
      unregister('to');
      unregister('cc');
      unregister('bcc');

      // Reset the form
      reset(InitialFormState);
    };
  }, [register]);

  // Queries;
  const { data: apiResponse, success: isSuccess } = useApi({
    queryFn: () => {
      return emailTemplatesApi('get', null);
    }
  });

  // Queries
  const templateData = useApi({
    queryKey: currentTemplate,
    queryFn: () => {
      if (currentTemplate !== null) {
        return emailTemplatesApi('get', currentTemplate);
      }
      // Return a placeholder value, or null, if currentTemplate is null
      return null;
    }
  });

  const {
    isPending,
    error,
    data: projects
  } = useQuery({
    queryKey: ['user', 'projects', id],
    queryFn: () => {
      if (id !== null) {
        return ProjectDataApi(POST, null, {}, '/all');
      }
      // Return a placeholder value, or null, if currentTemplate is null
      return null;
    }
  });

  useEffect(() => {
    if (apiResponse?.data) {
      const publicTemplates = apiResponse?.data?.filter(template => template.is_public);
      const privateTemplates = apiResponse?.data?.filter(template => !template.is_public);
      setSortedResponse([
        {
          name: 'Your Templates',
          subItems: privateTemplates
        },
        {
          name: 'Public Templates',
          subItems: publicTemplates
        }
      ]);
    }
  }, [apiResponse?.data]);

  useEffect(() => {
    if (templateData?.data?.data && currentTemplate === templateData?.data?.data?.id) {
      const autofillFormData = templateData?.data?.data;
      console.log(autofillFormData.htmlPart);
      setValue('subject', replacePlaceholders(autofillFormData.subject, messageTemplateData));
      setValue('body', replacePlaceholders(autofillFormData.htmlPart, messageTemplateData));
      setValue('to', replacePlaceholders(autofillFormData.to, messageTemplateData));
      setValue('cc', replacePlaceholders(autofillFormData.cc, messageTemplateData));
      setValue('bcc', replacePlaceholders(autofillFormData.bcc, messageTemplateData));
    }
  }, [templateData?.data]);

  const handleValidation = async () => {
    if (validateEmailCSV(getValues('to')) !== '') {
      enqueueSnackbar(validateMessage('fill', 'required fields'), { variant: 'error' });
      return false;
    }

    if (!validateEmail(getValues('cc')) && !validateEmail(getValues('bcc')) && !validateEmail(getValues('from'))) {
      enqueueSnackbar(validateMessage('fill', 'required fields'), { variant: 'error' });
      return false;
    }

    if (!getValues('subject')) {
      enqueueSnackbar(validateMessage('fill', 'Subject'), { variant: 'error' });
      return false;
    }

    if (!getValues('to')) {
      enqueueSnackbar(validateMessage('fill', 'recipient email address'), {
        variant: 'error'
      });
      return false;
    }

    return true;
  };

  const handleInsertText = async (textToInsert, color) => {
    switch (lastActiveElement) {
      case 'subject':
        updateText(replacePlaceholders(textToInsert, messageTemplateData), subjectRef, 'subject', setValue);
        break;
      case 'to':
        updateText(replacePlaceholders(textToInsert, messageTemplateData), toRef, 'to', setValue);
        break;
      case 'cc':
        updateText(replacePlaceholders(textToInsert, messageTemplateData), ccRef, 'cc', setValue);
        break;
      case 'bcc':
        updateText(replacePlaceholders(textToInsert, messageTemplateData), bccRef, 'bcc', setValue);
        break;
      case 'body':
        bodyRef.current.selection.setContent(replacePlaceholders(textToInsert, messageTemplateData, color, true));
        break;
      default:
        try {
          await navigator.clipboard.writeText(replacePlaceholders(textToInsert, messageTemplateData));
          enqueueSnackbar(CLIPBOARD_COPY_MESSAGE.SUCCESS, {
            variant: 'success'
          });
        } catch (error) {
          console.error('Failed to copy:', error);
          enqueueSnackbar(CLIPBOARD_COPY_MESSAGE.FAILED, { variant: 'error' });
        }
        break;
    }
  };

  const handleSubmitForm = async formData => {
    setLoading(true);

    if (!handleValidation()) {
      setLoading(false);
      return;
    }

    const requestBody = {
      subject: formData.subject,
      html_part: removeColorSpans(formData.body),
      to: formData.to,
      cc: formData.cc,
      bcc: formData.bcc,
      template_name: formData.template_name,
      template_description: formData.template_description,
      language_code: 'en',
      is_public: formData.is_public
    };

    if (currentTemplate) {
      await emailTemplatesApi('post', `send/${currentTemplate}`, requestBody);
      setLoading(false);
      enqueueSnackbar(MESSAGE_STATUS.EMAIL_SUCCESS, { variant: 'success' });
    } else {
      setLoading(false);
      enqueueSnackbar(MESSAGE_STATUS.EMAIL_FAILED, { variant: 'error' });
    }
  };

  return (
    <CustomPopup
      open={isOpen}
      title='Email'
      onClose={() => {
        setIsPopupOpen(false);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '70vw',
          height: '80vh'
        }}
      >
        <Loader show={templateData.isLoading || loading || templateData.isRefetching} />
        <Box
          sx={{
            rowGap: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            width: '75%'
          }}
        >
          <Box className='d-flex justify-content-between gap-2'>
            <Box className='w-100 mr-1'>
              <Typography className='header-font font-weight-semibold'>Choose Template</Typography>
              <Select
                className='w-100 team-info-field text-left'
                variant='outlined'
                value={currentTemplate}
                onChange={e => {
                  if (currentTemplate === e.target.value) return;
                  setCurrentTemplate(e.target.value);
                }}
              >
                {isSuccess && sortedResponse.map(template => renderSelectGroup(template))}
              </Select>
            </Box>
            <Box className='w-100 ml-1'>
              <Typography className='header-font font-weight-semibold'>Choose Project</Typography>
              <Select
                className='w-100 team-info-field text-left'
                variant='outlined'
                value={''}
                // onChange={(e) => handleChangeIndirectFee(e.target.value, "calculation_type", index)}
              >
                <MenuItem value='Percentages'>Percentage</MenuItem>
                <MenuItem value='Flat Amount'>Flat Amount</MenuItem>
              </Select>
            </Box>
          </Box>
          <Box className='d-flex justify-content-between gap-2'>
            <TextField
              className='w-100'
              error={validateEmailCSV(watch('to')) !== ''}
              fullWidth
              helperText={validateEmailCSV(watch('to'))}
              id='outlined-basic'
              inputRef={toRef}
              label='To'
              onFocus={() => setLastActiveElement('to')}
              onChange={e => {
                setValue('to', e.target.value);
              }}
              required
              variant='outlined'
              value={watch('to') || ''}
            />
          </Box>

          <Box className='d-flex'>
            <TextField
              id='outlined-basic'
              label='Cc'
              error={!validateEmail(watch('cc'))}
              helperText={!validateEmail(watch('cc')) ? invalidMessage('email address') : ''}
              variant='outlined'
              fullWidth
              className='mr-1'
              inputRef={ccRef}
              onFocus={() => setLastActiveElement('cc')}
              value={watch('cc') || ''}
              onChange={e => {
                setValue('cc', e.target.value);
              }}
            />

            <TextField
              id='outlined-basic'
              label='Bcc'
              variant='outlined'
              fullWidth
              error={!validateEmail(watch('bcc'))}
              helperText={!validateEmail(watch('bcc')) ? invalidMessage('email address') : ''}
              className='ml-1'
              inputRef={bccRef}
              onFocus={() => setLastActiveElement('bcc')}
              value={watch('bcc') || ''}
              onChange={e => {
                setValue('bcc', e.target.value);
              }}
            />
          </Box>

          <TextField
            id='outlined-basic'
            label='Subject'
            variant='outlined'
            fullWidth
            required
            defaultValue='Subject'
            error={watch('subject') === ''}
            helperText={watch('subject') === '' ? requireMessage('Subject', 'field is') : ''}
            inputRef={subjectRef}
            onFocus={() => setLastActiveElement('subject')}
            value={watch('subject') || ''}
            onChange={e => {
              setValue('subject', e.target.value);
            }}
          />
          <Box id='message-template-rtf'>
            <Typography className='header-font'>Body</Typography>
            <MessageTemplatesRT
              onFocus={() => setLastActiveElement('body')}
              onChange={value => {
                setValue('body', value);
              }}
              value={watch('body') || ''}
              inputRef={bodyRef}
            />
          </Box>
          <Box className='w-100 d-flex justify-content-end'>
            <CustomButton className='button-add' buttonText='Send' onClick={handleSubmit(handleSubmitForm)} />
          </Box>
        </Box>
        <Box
          sx={{
            width: '25%',
            textAlign: 'left',
            padding: '0 1rem'
          }}
        >
          <Typography className='pb-3 header-font'>Form Assistant</Typography>

          {messageTemplateData.map(option => {
            return (
              <Accordion key={option.header}>
                <AccordionSummary>
                  <Typography
                    className='header-font'
                    sx={{
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {option.header /* This is the header for each accordion */}
                    <Box
                      className='p-2'
                      sx={{
                        backgroundColor: option.color,
                        borderRadius: '100%',
                        marginLeft: '8px'
                      }}
                    />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left'
                  }}
                >
                  {option.values.map((option, index) => (
                    <Typography
                      onClick={() => {
                        handleInsertText(option.value, option.color);
                      }}
                      className='mb-2 cursor-pointer'
                      width='100%'
                      key={index}
                    >
                      {option.name} <CopyAllOutlined />
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </Box>
    </CustomPopup>
  );
};

MessageTemplatePopup.propTypes = {
  isOpen: PropTypes.bool,
  setIsPopupOpen: PropTypes.func,
  data: PropTypes.array
};

export default MessageTemplatePopup;
