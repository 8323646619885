import { Box, Checkbox } from '@mui/material';
import React from 'react';
import '../../../Containers/Commons/CheckboxStyle/index.scss';
import './index.scss';
import PropTypes from 'prop-types';

const MakeConfidential = props => {
  const { name, handleChange = () => {}, disabled = false, isHiddenDefaultPadding = false, defaultChecked = false } = props;
  return (
    <>
      <Box id='make-confidential' className='fs-10'>
        <Checkbox defaultChecked={defaultChecked} checked={defaultChecked} onChange={handleChange} className={`checkboxStyle ${isHiddenDefaultPadding ? 'p-0 pr-1' : ''}`} disabled={disabled} />
        <Box>{name}</Box>
      </Box>
    </>
  );
};

MakeConfidential.propTypes = {
  name: PropTypes.string,
  handleChange: PropTypes.func,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  isHiddenDefaultPadding: PropTypes.bool
};

export default MakeConfidential;
