import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomDropdown from '../../CustomDropdown';
import '../../../../Containers/Commons/AutoCompleteStyle/index.scss';
import { picklistDropDownApis } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';

const BasicPartnerSelection = props => {
  const { placeholder, disabled = false, label = '', list = [], ...rest } = props;
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState('');
  const [newOptions, setNewOptions] = useState([]);

  const filterDataOptions = data => {
    return data?.filter(item => {
      return list.every(listItem => listItem?.id !== item?.id);
    });
  };
  const filterSearchOptions = data => {
    return data?.filter(item => item?.name?.match(new RegExp(value, 'gi')));
  };
  useEffect(() => {
    const getCompanyList = async () => {
      const sub_route = `user?name=${value}`;
      const { status, data } = await picklistDropDownApis(GET, sub_route);
      if ((status === 200) & (data.data.length > 0)) {
        setOptions(data.data);
      } else {
        setOptions([]);
      }
    };
    getCompanyList();
  }, [value]);

  useEffect(() => {
    if (list.length > 0) {
      const filterData = filterDataOptions(options);
      const SearchOption = filterSearchOptions(filterData);
      setNewOptions(SearchOption);
    } else {
      setNewOptions(options);
    }
  }, [options, list]);
  return (
    <CustomDropdown
      {...rest}
      // required={true}
      options={newOptions}
      filterOptions={options => options}
      placeholder={placeholder}
      onInputChange={(e, val, reason) => {
        if (reason === 'input') {
          setValue(val);
        }
      }}
      disabled={disabled}
      label={label}
    />
  );
};

BasicPartnerSelection.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  list: PropTypes.array
};
export default BasicPartnerSelection;
