//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { CircularProgress } from '@mui/material';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { formatDate, formatTime } from '../../../src/utils/date';

export const PAGE_LIMIT = 200;
export const SUCCESS_STATUS_CODE = 200;
const getCompany = params => (params.value ? params.value.name : '');
const getDate = params => (params.value ? formatDate(params.value) : '');
const getStage = params => (params.value ? params.value[0].stage : '');
// const getRationale = (params => (params.value ? params.value[0].rationale : ''))
const getLastActivity = params => {
  if (Array.isArray(params.value)) {
    const lastActivity = params.value[params.value?.length - 1];
    return lastActivity ? formatDate(lastActivity.updated_at) + ' ' + formatTime(lastActivity.updated_at) : '';
  }
  return '';
};
const getPartnerInterview = params => {
  if (params.value) {
    let dates = [];
    if (params.value[0].kgp_interview_date1) {
      dates.push(params.value[0].kgp_interview_date1);
    }
    if (params.value[0].kgp_interview_date2) {
      dates.push(params.value[0].kgp_interview_date2);
    }
    if (params.value[0].kgp_interview_date3) {
      dates.push(params.value[0].kgp_interview_date3);
    }
    return dates ? dates.join(', ') : '';
  }
  return '';
};
const getClientInterview = params => {
  if (params.value) {
    let dates = [];
    params.value[0].interviews.forEach(interview => {
      dates.push(interview.interview_date);
    });
    return dates ? dates.join(', ') : '';
  }
  return '';
};

export const columnDefs = [
  {
    field: 'job_number',
    colId: 'job_number',
    headerName: 'Job Number',
    pinned: 'left',
    lockPinned: true,
    cellRenderer: 'IdRenderer',
    tooltipField: 'job_number'
  },
  {
    field: 'job_title',
    colId: 'job_title',
    headerName: 'Search Title',
    cellRenderer: 'IdRenderer',
    tooltipField: 'job_title',
    pinned: 'left',
    lockPinned: true
  },
  {
    field: 'company',
    colId: 'company',
    headerName: 'Company Name',
    cellRenderer: 'NameRenderer',
    tooltipValueGetter: getCompany
  },
  {
    field: 'close_date',
    colId: 'close_date',
    headerName: 'Closed Date',
    tooltipField: 'close_date',
    cellRenderer: getDate,
    tooltipValueGetter: getDate
  },
  {
    field: 'result',
    colId: 'result',
    headerName: 'Result',
    tooltipField: 'result'
  },
  {
    field: 'candidates',
    colId: 'stage',
    headerName: 'Stage',
    tooltipValueGetter: getStage,
    cellRenderer: getStage
  },
  {
    field: 'candidates',
    colId: 'rationale',
    headerName: 'Rationale',
    // tooltipValueGetter: getRationale,
    // cellRenderer: getRationale
    cellRenderer: 'RichTextPopupRendered',
    cellRendererParams: {
      sourceField: 'candidates'
    }
  },
  {
    field: 'activities',
    colId: 'last_activity',
    headerName: 'Last activity',
    tooltipValueGetter: getLastActivity,
    cellRenderer: 'DateTimeRendererLastActivity'
  },
  {
    field: 'candidates',
    colId: 'partner_interview_dates',
    headerName: 'Partner interview dates',
    tooltipValueGetter: getPartnerInterview,
    cellRenderer: getPartnerInterview
  },
  {
    field: 'candidates',
    colId: 'client_interview_dates',
    headerName: 'Client interview dates',
    tooltipValueGetter: getClientInterview,
    cellRenderer: getClientInterview
  }
];

export const CustomLoadingOverlayComponent = () => {
  return <CircularProgress />;
};

export const DateTimeRendererLastActivity = params => {
  if (Array.isArray(params.value)) {
    const lastActivity = params.value[params.value?.length - 1];
    return lastActivity ? (
      <span className='d-flex flex-column'>
        <span>{formatDate(lastActivity.updated_at)}</span>
        <span>{formatTime(lastActivity.updated_at)}</span>
      </span>
    ) : (
      ''
    );
  }
  return null;
};

export const getOldValue = (contact, field) => {
  const oldValue = { [field]: contact[field] };
  if (field === 'bd_target_id') {
    oldValue.bd_target_id = contact.bd_target?.id;
  }
  return oldValue;
};
