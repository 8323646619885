import { Delete, Edit } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { ignUsersApi } from '../../../services/ApiService';
import { DELETE } from '../../../services/constantService';

const columnDefs = [
  {
    field: 'name',
    headerName: 'Name',
    cellRenderer: 'nameRenderer',
    width: 350,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  // Using dot notation to access nested property
  {
    field: 'email',
    headerName: 'Email',
    width: 350,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  // Show default header name
  {
    field: 'user_roles',
    headerName: 'Edit',
    cellRenderer: 'manageRenderer',
    width: 250,
    resizable: false,
    sortable: false,
    pinned: 'right',
    lockPinned: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'user_roles',
    headerName: 'Role',
    cellRenderer: 'roleRenderer',
    width: 450,
    resizable: true,
    sortable: false
  }
];

const NameRenderer = props => {
  return (
    <div
      className='py-2 text-left w-100'
      onClick={() => {
        props.setCurrentUser(props.data);
        props.setIsDrawerOpen(true);
      }}
    >
      {props.value}
    </div>
  );
};

const RoleRenderer = props => {
  return (
    <div className='py-2 w-100 text-center'>
      {props.data.user_roles.splice(0, 3).map((role, idx) => {
        return idx ? <span key={idx}>, {role.roles.name}</span> : <span key={idx}>{role.roles.name}</span>;
      })}
    </div>
  );
};

const ManageRenderer = props => {
  async function handleDelete() {
    await ignUsersApi(DELETE, `deleteById/${props.data.id}`);
  }

  return (
    <Box className='w-100 text-center'>
      <Button
        className='px-2 mx-2'
        sx={{
          minWidth: 0
        }}
        onClick={() => {
          props.setCurrentUser(props.data);
          props.setIsDrawerOpen(true);
        }}
      >
        <Edit className='icon-size' />
      </Button>
      <Button
        className='px-2 mx-2'
        sx={{
          minWidth: 0
        }}
        onClick={() => {
          handleDelete().then(() => {
            props.refetch();
          });
        }}
      >
        <Delete />
      </Button>
    </Box>
  );
};

NameRenderer.propTypes = {
  data: PropTypes.object,
  value: PropTypes.string,
  setIsDrawerOpen: PropTypes.func,
  setCurrentUser: PropTypes.func
};

ManageRenderer.propTypes = {
  refetch: PropTypes.func,
  data: PropTypes.object,
  value: PropTypes.string,
  setIsDrawerOpen: PropTypes.func,
  setCurrentUser: PropTypes.func
};

RoleRenderer.propTypes = {
  data: PropTypes.object
};

export { ManageRenderer, NameRenderer, RoleRenderer, columnDefs };
