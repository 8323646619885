import React from 'react';
import PropTypes from 'prop-types';
import CustomDropdown from '../../CustomDropdown';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';

const CompanyStatusSelection = props => {
  const {
    value,
    name = '',
    label = '',
    onChange = () => {},
    disabled = false,
    isDrawer = false,
    // selectedList,
    // setEmailList = () => { },
    required = false,
    ...rest
  } = props;
  const [options, setOptions] = useState([]);
  const companyStatusOption = useSelector(state => state.commonReducer.companyStatus);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!companyStatusOption) {
      dispatch(fetchPickLists('COMPANY_STATUS', 'companyStatus'));
    } else {
      setOptions(companyStatusOption);
    }
  }, [companyStatusOption, dispatch]);

  const handleDropdownChange = (e, selectedOption) => {
    onChange(selectedOption);
  };
  return <CustomDropdown {...rest} value={value} options={options} name={name} onChange={handleDropdownChange} isDrawer={isDrawer} disabled={disabled} label={label} required={required} />;
};
CompanyStatusSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isDrawer: PropTypes.bool,
  onChange: PropTypes.func,
  selectedList: PropTypes.array,
  label: PropTypes.string,
  required: PropTypes.bool
};

export default CompanyStatusSelection;
