//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import Popup from 'reactjs-popup';
import CloseIcon from '@mui/icons-material/Close';
import { InputLabel, Typography } from '@mui/material';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import InputField from '../common/InputField';
import ScoreRoleSelection from '../CreateSearch/Components/SearchInformation/Components/ScoreRoleSelection';
import ScoreRangeSelection from '../CreateSearch/Components/SearchInformation/Components/ScoreRangeSelection';
import { useForm } from 'react-hook-form';
import { SCORES_DESCRIPTION, SCORE_RANKING } from '../../services/constantService';
export default function ScoresAdvanceFilterPopup(props) {
  const { setValue, watch, register, getValues, unregister } = useForm({});
  const { onClose, gridApi } = props;
  const [score, setScore] = useState('');
  const [disableIndividualFields, setDisableIndividualFields] = useState(false);
  const [individualFieldHasValue, setIndividualFieldHasValue] = useState(false);
  const [scoreChanged, setScoreChanged] = useState(false);
  const [filterChanged, setFilterChanged] = useState(false);
  const columnsToFilter = ['title_score', 'job_function_score', 'industry_score', 'company_score', 'tenure_weight', 'stint_weight', 'recency_weight', 'relevancy_weight'];
  const [isReset, setReset] = useState(false);
  const handleInputChange = event => {
    setScore(event.target.value);
  };

  const validateRankingValue = value => {
    return value < 0 || value > 100;
  };
  const checkIndividualScore = () => {
    const hasValue = disableIndividualFields ? (SCORES_DESCRIPTION.find((ele, i) => watch(`score_${i}`)) ? true : false) : watch('score');
    setIndividualFieldHasValue(hasValue);
  };
  const handleInputIndividualChange = () => {
    setScoreChanged(true);
    checkIndividualScore();
  };
  useEffect(() => {
    checkIndividualScore();
  }, [checkIndividualScore, individualFieldHasValue, scoreChanged, filterChanged]);
  const isApplyButtonDisabled = !individualFieldHasValue ? true : !score && !scoreChanged && !filterChanged;

  const closePopup = () => {
    onClose && onClose();
  };
  const applyFilter = () => {
    const formValues = getValues();
    if (formValues && formValues.score_role !== 'Individual Scores') {
      for (const columnId of columnsToFilter) {
        const column = gridApi.getColumnDef(columnId);
        const filterInstance = gridApi.getFilterInstance(column);
        filterInstance.setModel({
          filter: formValues.score,
          filterType: 'number',
          type: formValues.score_range === 'Less Than Or Equal' ? 'lessThan' : 'greaterThan'
        });
        filterInstance.applyModel();
      }
      gridApi.onFilterChanged();
    } else {
      for (let i = 0; i <= columnsToFilter?.length; i++) {
        let scoreKey = `score_${i}`;
        let rangeKey = `score_range_${i}`;
        let nameKey = `name_${i}`;
        formValues[nameKey] = columnsToFilter[i];

        if (formValues[scoreKey] === '' || !formValues[nameKey]) {
          delete formValues[scoreKey];
          delete formValues[rangeKey];
          delete formValues[nameKey];
        }
      }
      let scoreObjects = [];
      for (let key in formValues) {
        if (key.startsWith('name_')) {
          let index = key.split('_')[1];
          let scoreKey = `score_${index}`;
          let rangeKey = `score_range_${index}`;

          let scoreObj = {
            score: formValues[scoreKey],
            name: formValues[key],
            range: formValues[rangeKey]
          };
          scoreObjects.push(scoreObj);
        }
      }
      for (const columnId of scoreObjects) {
        const column = gridApi.getColumnDef(columnId.name);
        const filterInstance = gridApi.getFilterInstance(column);
        filterInstance.setModel({
          filter: columnId.score,
          filterType: 'number',
          type: columnId.range === 'Less Than Or Equal' ? 'lessThan' : 'greaterThan'
        });
        filterInstance.applyModel();
      }
      gridApi.onFilterChanged();
    }
    closePopup();
  };
  const applyReset = () => {
    if (!isReset) {
      if (!disableIndividualFields) {
        register('score_range');
        setValue('score_range', 'Greater Than Or Equal');
        setValue('score', '');
        setReset(true);
      } else {
        for (let i = 0; i <= columnsToFilter?.length; i++) {
          let scoreKey = `score_${i}`;
          let scoreRangeName = `score_range_${i}`;
          setValue(scoreKey, '');
          register(scoreRangeName);
          setValue(scoreRangeName, 'Greater Than Or Equal');
        }
        setReset(true);
      }
      resetScoreState();
      gridApi.setFilterModel(null);
    }
  };
  const checkSameFilterValues = (filterValues, propName) => {
    const firstValue = filterValues[0][propName];
    const filterType = filterValues[0].type;
    for (let i = 1; i < filterValues?.length; i++) {
      if (filterValues[i]['type'] !== filterType) {
        return false;
      }
      if (Number.isInteger(filterValues[i][propName]) && filterValues[i][propName] !== firstValue) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.keyCode === 27) {
        onClose();
      }
    }
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [props]);

  useEffect(() => {
    const filterModel = gridApi.getFilterModel();
    const filteredColumns = Object.keys(filterModel);
    const filteredValues = Object.values(filterModel);
    filteredValues.push(filteredColumns);
    if (filteredValues?.length > 0) {
      const allSame = checkSameFilterValues(filteredValues, 'filter');
      if (filteredColumns?.length === columnsToFilter?.length && (allSame || filteredColumns?.length === 0)) {
        setValue('score_role', 'All Scores');
        setValue('score', filteredValues[0]?.filter);
        setTimeout(() => {
          register('score_range');
          setValue('score_range', 'Greater Than Or Equal');
        });
      } else {
        const indexArray = filteredColumns.map(column => columnsToFilter.indexOf(column));
        register('score_role');
        setValue('score_role', 'Individual Scores');
        setDisableIndividualFields(true);
        for (let i = 0; i < indexArray?.length; i++) {
          setTimeout(() => {
            setValue(`score_${indexArray[i]}`, filteredValues[i]?.filter);
            register(`score_range_${indexArray[i]}`);
            setValue(`score_range_${indexArray[i]}`, filteredValues[i].type === 'lessThan' ? 'Less Than Or Equal' : 'Greater Than Or Equal');
          });
        }
      }
    }
  }, [setValue]);

  useEffect(() => {
    const filterModel = gridApi.getFilterModel();
    const filteredColumns = Object.keys(filterModel);
    const filteredValues = Object.values(filterModel);
    filteredValues.push(filteredColumns);
    const allSame = checkSameFilterValues(filteredValues, 'filter');
    if (filteredValues?.length > 0) {
      if (!disableIndividualFields) {
        setValue('score_role', 'All Scores');
        setValue('score', allSame ? filteredValues[0]?.filter : null);
        setTimeout(() => {
          register('score_range');
          setValue('score_range', 'Greater Than Or Equal');
        });
      } else {
        const indexArray = filteredColumns.map(column => columnsToFilter.indexOf(column));
        register('score_role');
        setValue('score_role', 'Individual Scores');
        setDisableIndividualFields(true);
        for (let i = 0; i < indexArray?.length; i++) {
          setTimeout(() => {
            setValue(`score_${indexArray[i]}`, filteredValues[i]?.filter);
            register(`score_range_${indexArray[i]}`);
            setValue(`score_range_${indexArray[i]}`, filteredValues[i].type === 'lessThan' ? 'Less Than Or Equal' : 'Greater Than Or Equal');
          });
        }
      }
    }
  }, [disableIndividualFields]);

  useEffect(() => {
    register('score_role');
    register('score_range');
    //   setValue('score_role', 'All Scores');
    setValue('score_range', 'Greater Than Or Equal');
    SCORES_DESCRIPTION.forEach((item, index) => {
      const scoreRangeName = `score_range_${index}`;
      register(scoreRangeName);
      setValue(scoreRangeName, 'Greater Than Or Equal');
    });
    return () => {
      unregister('score_role');
      unregister('score_range');
      SCORES_DESCRIPTION.forEach((item, index) => {
        const scoreRangeName = `score_range_${index}`;
        unregister(scoreRangeName);
      });
    };
  }, [register, unregister]);

  const resetScoreState = () => {
    setIndividualFieldHasValue(false);
    // setScore("");
    setFilterChanged(false);
    setScoreChanged(false);
  };

  return (
    <Popup overlayStyle={{ zIndex: '1401 !important' }} className={'quick-add-company advanced-filter-popup'} open={true} modal closeOnDocumentClick={false} closeOnEscape={false}>
      <>
        <div className='' style={{ background: '#2a364c', color: '#fff' }}>
          <div className='quick-add-header'>
            <Typography>Advanced Filter</Typography>
            <CloseIcon className='cursor-pointer' onClick={closePopup} />
          </div>
        </div>

        <div className='d-flex flex-column py-3'>
          <div className='d-flex contact-details-row'>
            <div className='d-flex intersection-page-view w-100'>
              <div className='col-4'>
                <ScoreRoleSelection
                  label=''
                  required={true}
                  variant='outlined'
                  className='input-form-field'
                  value={watch('score_role') || 'All Scores'}
                  onChange={(e, data) => {
                    if (data === 'Individual Scores') {
                      setDisableIndividualFields(true);
                    } else {
                      setDisableIndividualFields(false);
                    }
                    resetScoreState();

                    setValue('score_role', data);
                  }}
                  InputLabelProps={{ focused: true }}
                />
              </div>
              <div className='col-4'>
                {!disableIndividualFields && (
                  <ScoreRangeSelection
                    label=''
                    required={true}
                    variant='outlined'
                    className='input-form-field'
                    value={watch('score_range') || 'Greater Than Or Equal'}
                    onChange={(e, data) => {
                      setValue('score_range', data);
                      setFilterChanged(true);
                    }}
                    InputLabelProps={{ focused: true }}
                  />
                )}
              </div>
              <div className='col-4'>
                {!disableIndividualFields && (
                  <InputField
                    variant='outlined'
                    className='input-form-field'
                    label=''
                    placeholder='Enter Score'
                    name='score'
                    InputLabelProps={{ focused: true }}
                    required={true}
                    type='number'
                    max={100}
                    {...register('score')}
                    onChange={handleInputChange}
                    InputProps={{
                      inputProps: { min: 0, max: 100, pattern: '[0-9]*' }
                    }}
                    error={validateRankingValue(watch('score'))}
                    helperText={validateRankingValue(watch('score')) ? SCORE_RANKING.SCORE_VALIDATION_MESSAGE : ''}
                  />
                )}
              </div>
            </div>
          </div>
          {disableIndividualFields && (
            <div className='d-flex py-3'>
              <div className='col-12'>
                {SCORES_DESCRIPTION.map((item, index) => {
                  const scoreRangeName = `score_range_${index}`;
                  const scoreName = `score_${index}`;

                  return (
                    <div key={index} className='d-flex contact-details-row '>
                      <div className='col-3 intersection-page-view font-weight-bold w-100'>{item.name !== 'Lifer' && <InputLabel className='page-label'>{item.name}</InputLabel>}</div>
                      <div className='col-4'>
                        {item.name !== 'Lifer' && (
                          <ScoreRangeSelection
                            label=''
                            required={true}
                            variant='outlined'
                            className='input-form-field'
                            value={watch(scoreRangeName) || 'Greater Than Or Equal'}
                            onChange={(e, data) => {
                              setValue(scoreRangeName, data);
                              setFilterChanged(true);
                              handleInputIndividualChange();
                            }}
                            InputLabelProps={{ focused: true }}
                          />
                        )}
                      </div>
                      <div className='col-4'>
                        {item.name !== 'Lifer' && (
                          <InputField
                            variant='outlined'
                            className='input-form-field'
                            label=''
                            placeholder='Enter Score'
                            name={scoreName}
                            InputLabelProps={{ focused: true }}
                            onChange={() => handleInputIndividualChange()}
                            type='number'
                            {...register(scoreName)}
                            InputProps={{
                              inputProps: { min: 0, max: 100, pattern: '[0-9]*' }
                            }}
                            error={validateRankingValue(watch(scoreName))}
                            helperText={validateRankingValue(watch(scoreName)) ? SCORE_RANKING.SCORE_VALIDATION_MESSAGE : ''}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className='page-content-footer'>
          {/* <Button className="page-btn-cancel" onClick={closePopup} >Cancel</Button> */}
          <Button className='page-btn-cancel' variant='contained' type='reset' onClick={applyReset}>
            Reset
          </Button>
          <Button variant='contained' type='submit' className='page-btn-save' disabled={isApplyButtonDisabled} onClick={applyFilter}>
            Apply
          </Button>
        </div>
      </>
    </Popup>
  );
}

ScoresAdvanceFilterPopup.propTypes = {
  onClose: PropTypes.func,
  gridApi: PropTypes.object
};
