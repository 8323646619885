import { Check, Clear, Close, DragHandle, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import CustomButton from '../../../components/common/CustomButton';
import Loader from '../../../components/common/Loader';
import RichText from '../../../components/common/RichText';
import { ProjectDataApi, ProjectSkillDetailsDataApi, SkillDetailsDataApi, skillDetailApi } from '../../../services/ApiService';
import { DELETE, GET, POST, PUT } from '../../../services/constantService';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CustomModalWithHeader from '../../Commons/CustomModalWithHeader';
import { EditModal } from './Comman';
import { ProjectDataContext } from './Context';

const AddElementModal = ({ isOpen, onClose = () => {}, onSave = () => {} }) => {
  const [data, setData] = useState();
  const handleRichTextChange = (key, value) => {
    data[key] = value;
  };
  const { t } = useLanguageHooks([TRANS_KEYS.ADD_ELEMENT, TRANS_KEYS.MINIMAL_TITLE, TRANS_KEYS.IDEAL_TITLE, TRANS_KEYS.QUESTION_TITLE]);

  useEffect(() => {
    setData({
      id: 'new',
      minimal: '',
      ideal: '',
      question: '',
      description: '',
      seq_no: 0
    });
  }, []);

  return (
    <CustomModalWithHeader style={{ minWidth: '80vw' }} isOpen={isOpen} label={t(`${TRANS_KEYS.ADD_ELEMENT}:key`)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography>{t(`${TRANS_KEYS.MINIMAL_TITLE}:key`)}</Typography>
          <RichText onChange={value => handleRichTextChange('minimal', value)} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography>{t(`${TRANS_KEYS.IDEAL_TITLE}:key`)}</Typography>
          <RichText onChange={value => handleRichTextChange('ideal', value)} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography>{t(`${TRANS_KEYS.QUESTION_TITLE}:key`)}</Typography>
          <RichText onChange={value => handleRichTextChange('question', value)} />
        </Grid>
      </Grid>
      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='flex-end' spacing={2} style={{ marginTop: '2vh' }}>
        <CustomButton buttonText={t(`${TRANS_KEYS.CONTACTS_CANCEL}:key`)} type={'btn btn-outline-secondary m-1'} onClick={onClose} />
        <CustomButton
          buttonText={t(`${TRANS_KEYS.CONTACTS_SAVE}:key`)}
          type={'primary m-1'}
          onClick={() => {
            onSave(data);
          }}
        />
      </Stack>
    </CustomModalWithHeader>
  );
};
AddElementModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func
};

function Experience() {
  const { t } = useLanguageHooks([
    TRANS_KEYS.NEXT,
    TRANS_KEYS.MINIMAL_TITLE,
    TRANS_KEYS.QUESTION_TITLE,
    TRANS_KEYS.IDEAL_TITLE,
    TRANS_KEYS.CONTACTS_CANCEL,
    TRANS_KEYS.EXPAND_ALL,
    TRANS_KEYS.COLLAPSE_ALL,
    TRANS_KEYS.ADD_ELEMENT,
    TRANS_KEYS.ADD_EXPERIENCE,
    TRANS_KEYS.EXPERIENCE_HEADER
  ]);

  const { projectData: data, handleUniversalState, handleTabChanges, dummyText } = useContext(ProjectDataContext);

  const [experiences, setExperience] = useState([]);
  const [modalDescription, setModalDescription] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [elementIndex, setElementIndex] = useState('');
  const [index, setIndex] = useState('');
  const [keyValue, setKeyValue] = useState('');
  const [expandedAccordion, setExpandedAccordion] = useState([]);
  const [addElementModal, setAddElementModal] = useState(false);
  const [addElementIndex, setAddElementIndex] = useState(0);
  const [indexArray, setIndexArray] = useState([-1, -1]);
  const [richTextTempChange, setRichTextTempChange] = useState('');
  const [draggingItem, setDraggingItem] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoader, setIsLoader] = useState(false);
  const EXPERIENCE_TITLE = 'Experience Title';

  const handleUniversalStateLocally = value => {
    setExperience([...value]);
    const event = {
      target: {
        name: 'skills',
        value: value
      }
    };
    handleUniversalState(event);
  };

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        setIsLoader(true);
        const res = await ProjectDataApi(GET, data.id, null, null, null);
        setExperience(Object.entries(res?.data?.projectSkillDetails));
      } catch (error) {
        enqueueSnackbar(`Failed to fetch project data ${error}`, 'error');
      } finally {
        setIsLoader(false);
      }
    };

    fetchProjectData();
  }, [data.id]);

  const handleChangeExperience = async (key, index, elementIndex, value) => {
    try {
      setIsLoader(true);
      const project_id = data?.id;
      if (key == EXPERIENCE_TITLE) {
        experiences[index][0] = value;
        if (experiences[index][1][1]?.skill_id) {
          const skill_id = experiences[index][1]?.skill_id;
          const payload = {
            id: skill_id,
            skill_name: value
          };
          await skillDetailApi(PUT, '', payload);
        } else {
          const payload = {
            skill_name: value,
            language_code: 'en',
            minimal: '',
            ideal: '',
            question: '',
            seq_no: 1,
            project_id: project_id
          };
          const res = await SkillDetailsDataApi(POST, '', payload);
          experiences[index][1] = [res?.data];
        }
      } else {
        let experience = experiences[index];

        if (elementIndex != null) {
          experience[1][elementIndex][key] = value;

          const payload = {
            ...experience[1][elementIndex]
          };
          await ProjectSkillDetailsDataApi(PUT, '', payload);
        } else {
          experience[1] = value;
        }
        experiences[index] = experience;
      }

      handleUniversalStateLocally(experiences);
      setKeyValue('');
      setIndex('');
      setElementIndex('');
      setModalDescription(' ');
      setIsLoader(false);
    } catch (err) {
      enqueueSnackbar(err, 'error');
    }
  };

  const handleClickOnExperience = (key, index, elementIndex, description) => {
    setKeyValue(key);
    setIndex(index);
    setElementIndex(elementIndex);
    setModalDescription(description);
    setModalOpen(true);
  };

  const handleAddElement = index => {
    setAddElementModal(true);
    setAddElementIndex(index);
  };

  const handleAddElementSave = async data => {
    try {
      setIsLoader(true);

      const experience = experiences[addElementIndex];

      const payload = {
        ...data,
        skill_id: experience[1][0]?.data?.skill_id || experience[1][0]?.skill_id,
        skill_details_id: experience[1][0]?.data?.skill_details_id || experience[1][0]?.skill_details_id,
        project_skill_id: experience[1][0]?.data?.project_skill_id || experience[1][0]?.project_skill_id,
        seq_no: experience[1].length + 1
      };
      delete payload.id;
      const res = await ProjectSkillDetailsDataApi(POST, '', payload);

      if (res?.data) {
        if (experience[1].length === 1 && experience[1][0].id === 'new') {
          experience[1] = [res.data];
        } else {
          experience[1] = Array.isArray(experience[1]) ? [...experience[1], res.data] : [experience[1], res.data];
        }

        handleUniversalStateLocally([...experiences]);
        setAddElementModal(false);
      } else {
        throw new Error('Failed to add new element');
      }
    } catch (err) {
      enqueueSnackbar(err.message || 'An error occurred while adding the element', { variant: 'error' });
    } finally {
      setIsLoader(false);
    }
  };
  const handleAddElementClose = () => {
    setAddElementModal(false);
  };

  const handleAddExperience = () => {
    setExperience([
      ...experiences,
      [
        '',
        [
          {
            id: 'new',
            minimal: '',
            ideal: '',
            question: '',
            description: '',
            seq_no: 0
          }
        ]
      ]
    ]);
    handleClickOnExperience(EXPERIENCE_TITLE, experiences.length, null, '');
  };

  const handleExpandAll = () => {
    const indexOfExperience = experiences.map((data, index) => index);
    setExpandedAccordion(indexOfExperience);
  };

  const handleAccordionClick = index => {
    if (expandedAccordion.includes(index)) {
      setExpandedAccordion(expandedAccordion.filter(data => data != index));
    } else {
      setExpandedAccordion([...expandedAccordion, index]);
    }
  };

  const handleExperienceDelete = async id => {
    const experience = experiences[id];
    experiences.splice(id, 1);
    experience[1].forEach(async experience => {
      await ProjectSkillDetailsDataApi(DELETE, '', {
        id: experience?.id
      });
    });
    handleUniversalStateLocally(experiences);
  };

  const editOn = (dataIndex, elementIndex, key) => {
    return dataIndex == indexArray[0] && elementIndex == indexArray[1] && key == indexArray[2];
  };

  const handleDragDrop = targetItem => {
    if (!draggingItem) return;
    const currentIndex = experiences.indexOf(draggingItem);
    const targetIndex = experiences.indexOf(targetItem);

    if (currentIndex !== -1 && targetIndex !== -1) {
      experiences.splice(currentIndex, 1);
      experiences.splice(targetIndex, 0, draggingItem);
      handleUniversalStateLocally(experiences);
    }
  };

  // const handleSave = () => {
  //   const skills = getValueFromUniversalState('skills');
  // };

  const handleNext = () => {
    handleTabChanges(1, 3);
  };

  const handleDeleteElement = async (index, elementIndex, data) => {
    try {
      setIsLoader(true);
      const payload = {
        id: data?.id
      };
      await ProjectSkillDetailsDataApi(DELETE, '', payload);
      const experience = experiences[index];
      experience[1].splice(elementIndex, 1);
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setIsLoader(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: 'titleBarBackground.main' }}>
      <Loader show={isLoader} />
      <Box className='p-2'>
        <div
          className='fs-12 info-panel'
          dangerouslySetInnerHTML={{
            __html: dummyText?.experience
          }}
        />
      </Box>
      <Stack direction='row' justifyContent={'flex-end'}>
        <CustomButton
          buttonText={t(`${TRANS_KEYS.SAVE_AND_NEXT}:key`)}
          variant={'contained'}
          type='m-1 primary'
          onClick={() => {
            // handleSave();
            handleNext();
          }}
        />
      </Stack>
      <Stack direction='row' justifyContent={'flex-end'} className='mt-1'>
        <CustomButton variant={'text'} buttonText={t(`${TRANS_KEYS.EXPAND_ALL}:key`)} type={'btn'} onClick={() => handleExpandAll()} />
        <CustomButton variant={'text'} buttonText={t(`${TRANS_KEYS.COLLAPSE_ALL}:key`)} type={'btn'} onClick={() => setExpandedAccordion([])} />
      </Stack>

      {/*onClick Richtext */}
      <Stack className='w-100 mt-3 mb-4' rowGap={4}>
        {experiences.map((skill, dataIndex) => (
          <Box key={dataIndex} sx={{ position: 'relative' }}>
            <Stack
              direction='row'
              sx={{
                position: 'absolute',
                left: '-40px',
                top: '13px',
                cursor: 'move !important'
              }}
              draggable='true'
              onDragStart={() => {
                setDraggingItem(skill);
              }}
              onDragEnd={() => {
                setDraggingItem(null);
              }}
              onDragOver={e => {
                e.preventDefault();
              }}
              onDrop={() => {
                handleDragDrop(skill);
              }}
            >
              <DragHandle fontSize='small' />

              <Typography variant='caption'>{dataIndex + 1}</Typography>
            </Stack>
            <Close
              fontSize='small'
              sx={{
                position: 'absolute',
                right: '-30px',
                top: '13px',
                cursor: 'pointer',
                color: 'error.main'
              }}
              onClick={() => handleExperienceDelete(dataIndex)}
            ></Close>

            <Accordion defaultExpanded={dataIndex == 0} expanded={expandedAccordion.includes(dataIndex)}>
              <AccordionSummary expandIcon={<ExpandMore onClick={() => handleAccordionClick(dataIndex)} sx={{ cursor: 'pointer' }} />}>
                <Tooltip title='Click To Edit'>
                  <Typography variant='subtitle2' fontWeight={'bold'} onClick={() => handleClickOnExperience(EXPERIENCE_TITLE, dataIndex, null, skill[0])} sx={{ cursor: 'text' }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: skill[0]
                      }}
                    />
                  </Typography>
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container className='w-100 mt-1' spacing={1} rowGap={1}>
                  <Grid
                    item
                    xs={12}
                    onClick={() => {
                      handleClickOnExperience('description', dataIndex, null, skill[0]);
                    }}
                  >
                    <Tooltip title='Click To Edit'>
                      <Typography variant='caption'>
                        <div dangerouslySetInnerHTML={{ __html: skill[0] }} />
                      </Typography>
                    </Tooltip>

                    <Divider className='mt-1' />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='caption' fontWeight={'bold'}>
                      {t(`${TRANS_KEYS.MINIMAL_TITLE}:key`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='caption' fontWeight={'bold'}>
                      {t(`${TRANS_KEYS.IDEAL_TITLE}:key`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='caption' fontWeight={'bold'}>
                      {t(`${TRANS_KEYS.QUESTION_TITLE}:key`)}
                    </Typography>
                  </Grid>
                  {skill[1].map((data, elementIndex) => (
                    <Grid container item xs={12} key={elementIndex} style={{ minHeight: '40px' }}>
                      <Grid
                        container
                        className='mt-1'
                        item
                        xs={4}
                        direction={'column'}
                        justifyContent={'space-between'}
                        sx={{ cursor: 'text' }}
                        onClick={() => !editOn(dataIndex, elementIndex, 'minimum') && setIndexArray([dataIndex, elementIndex, 'minimum'])}
                      >
                        {editOn(dataIndex, elementIndex, 'minimum') ? (
                          <Stack direction={'column'} alignItems={'flex-start'} className='w-100'>
                            <RichText className='mb-1 w-100' style={{ minWidth: '100%' }} onChange={value => setRichTextTempChange(value)} defaultValue={data?.minimal || ''} />
                            <Stack direction='row' className='w-100' justifyContent={'flex-end'}>
                              <Check
                                sx={{
                                  color: 'primary.main',
                                  cursor: 'pointer'
                                }}
                                className='m-2'
                                onClick={() => {
                                  setIndexArray([-1, -1, -1]);
                                  handleChangeExperience('minimal', dataIndex, elementIndex, richTextTempChange);
                                  setRichTextTempChange(null);
                                }}
                              />
                              <Clear
                                sx={{
                                  color: 'error.main',
                                  cursor: 'pointer'
                                }}
                                className='m-2'
                                onClick={() => {
                                  setIndexArray([-1, -1, -1]);
                                  setRichTextTempChange(null);
                                }}
                              />
                            </Stack>
                          </Stack>
                        ) : (
                          <Tooltip title='Click To Edit' className='w-100'>
                            <Typography variant='caption'>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data?.minimal || 'enter value for minimal'
                                }}
                              />
                            </Typography>
                          </Tooltip>
                        )}

                        <Divider className='mt-1' />
                      </Grid>
                      <Grid
                        container
                        direction={'column'}
                        justifyContent={'space-between'}
                        className='mt-1'
                        item
                        xs={4}
                        sx={{ cursor: 'text' }}
                        onClick={() => !editOn(dataIndex, elementIndex, 'ideal') && setIndexArray([dataIndex, elementIndex, 'ideal'])}
                      >
                        {editOn(dataIndex, elementIndex, 'ideal') ? (
                          <Stack direction={'column'} alignItems={'flex-start'} className='w-100'>
                            <RichText className='mb-1' onChange={value => setRichTextTempChange(value)} defaultValue={data?.ideal || ''} />
                            <Stack direction='row' className='w-100' justifyContent={'flex-end'}>
                              <Check
                                sx={{
                                  color: 'primary.main',
                                  cursor: 'pointer'
                                }}
                                className='m-2'
                                onClick={() => {
                                  setIndexArray([-1, -1, -1]);
                                  handleChangeExperience('ideal', dataIndex, elementIndex, richTextTempChange);
                                  setRichTextTempChange(null);
                                }}
                              />
                              <Clear
                                sx={{
                                  color: 'error.main',
                                  cursor: 'pointer'
                                }}
                                className='m-2'
                                onClick={() => {
                                  setIndexArray([-1, -1, -1]);
                                  setRichTextTempChange(null);
                                }}
                              />
                            </Stack>
                          </Stack>
                        ) : (
                          <Tooltip title='Click To Edit'>
                            <Typography variant='caption' onClick={() => setIndexArray([dataIndex, elementIndex, 'ideal'])}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data?.ideal || 'enter value for ideal'
                                }}
                              />
                            </Typography>
                          </Tooltip>
                        )}
                        <Divider className='mt-1' />
                      </Grid>
                      <Grid
                        container
                        direction={'column'}
                        justifyContent={'space-between'}
                        className='mt-1'
                        item
                        xs={4}
                        sx={{ cursor: 'text' }}
                        onClick={() => !editOn(dataIndex, elementIndex, 'question') && setIndexArray([dataIndex, elementIndex, 'question'])}
                      >
                        {editOn(dataIndex, elementIndex, 'question') ? (
                          <Stack direction={'column'} alignItems={'flex-start'}>
                            <RichText className='mb-1' onChange={value => setRichTextTempChange(value)} defaultValue={data?.question || ''} />
                            <Stack direction='row' className='w-100' justifyContent={'flex-end'}>
                              {' '}
                              <Check
                                sx={{
                                  color: 'primary.main',
                                  cursor: 'pointer'
                                }}
                                className='m-2'
                                onClick={() => {
                                  setIndexArray([-1, -1, -1]);
                                  handleChangeExperience('question', dataIndex, elementIndex, richTextTempChange);
                                  setRichTextTempChange(null);
                                }}
                              />
                              <Clear
                                sx={{
                                  color: 'error.main',
                                  cursor: 'pointer'
                                }}
                                className='m-2'
                                onClick={() => {
                                  setIndexArray([-1, -1, -1]);
                                  setRichTextTempChange(null);
                                }}
                              />
                            </Stack>
                          </Stack>
                        ) : (
                          <Tooltip title='Click To Edit'>
                            <Typography variant='caption' onClick={() => setIndexArray([dataIndex, elementIndex, 'question'])}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data?.question || 'enter value for question'
                                }}
                              />
                            </Typography>
                          </Tooltip>
                        )}
                        <Divider className='mt-1' />
                      </Grid>

                      <Clear
                        sx={{
                          color: 'error.main',
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '-1%'
                        }}
                        onClick={() => handleDeleteElement(dataIndex, elementIndex, data)}
                        className='m-2'
                      />
                    </Grid>
                  ))}

                  <Grid item xs={4}>
                    <CustomButton
                      buttonText={'+ ' + t(`${TRANS_KEYS.ADD_ELEMENT}:key`)}
                      variant={'outlined'}
                      type='btn btn-outline-none'
                      onClick={() => {
                        handleAddElement(dataIndex);
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Stack>
      <EditModal
        index={index}
        keyValue={keyValue}
        elementIndex={elementIndex}
        isOpen={modalOpen}
        onClose={val => {
          setModalOpen(false);
          if (val === 'CANCEL') {
            experiences.pop();
          }
        }}
        label={keyValue}
        defaultValue={modalDescription}
        onChange={handleChangeExperience}
      ></EditModal>
      <AddElementModal isOpen={addElementModal} onSave={data => handleAddElementSave(data)} onClose={handleAddElementClose}></AddElementModal>
      <CustomButton
        buttonText={'+ ' + t(`${TRANS_KEYS.ADD_EXPERIENCE}:key`)}
        variant={'outlined'}
        type='primary w-25'
        onClick={() => {
          handleAddExperience();
        }}
      />
    </Box>
  );
}

Experience.propTypes = {
  data: PropTypes.any
};

export default Experience;
