//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

//import IndustryAndJobFunction from "../../../AddContact/Components/IndustryAndJobFunction";

export default function IndustryAndJobFunctionEdit() {
  //const {defaultValues, register,watch,setValue,unregister,control} = props

  return (
    // <IndustryAndJobFunction
    //   register={register}
    //   unregister={unregister}
    //   watch={watch}
    //   setValue={setValue}
    //   values={defaultValues}
    //   control={control}
    // />
    <></>
  );
}

IndustryAndJobFunctionEdit.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  dynamicKey: PropTypes.string,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  defaultValues: PropTypes.object
};
