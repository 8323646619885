import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import DrawerComponent from '../../../components/common/Drawer';

import { Box, Divider, Typography } from '@mui/material';
import { resourcesApi, roleResourcesApi } from '../../../services/ApiService';
import { GET } from '../../../services/constantService';
import useApi from '../../../utils/Hooks/useApiHook';
import '../index.scss';
import ManageResourcesGrid from './components/ManageResourcesGrid';
import RoleAutocomplete from './components/RoleAutocomplete';

const ManageRole = props => {
  const { isDrawerOpen, setIsDrawerOpen, setCurrentRole, currentRole } = props;

  const { data: rootResourcesData, refetch: refetchRootResources } = useApi({
    queryFn: () => resourcesApi(GET, 'getByParent')
  });

  const { data: allResourcesData, refetch: refetchAllResources } = useApi({
    queryFn: () => resourcesApi(GET, 'getAll')
  });

  const { data: roleResourceData, refetch: refetchRoleResource } = useApi({
    queryFn: () => {
      if (currentRole?.id) return roleResourcesApi(GET, 'getRecordsByRole?roleId=' + currentRole?.id);
      return [];
    }
  });

  useEffect(() => {
    if (currentRole?.id) refetchRoleResource();
    if (currentRole?.id) refetchRootResources();
    if (currentRole?.id) refetchAllResources();
  }, [currentRole]);

  return (
    currentRole && (
      <Box>
        <DrawerComponent
          anchor={'right'}
          open={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
            setCurrentRole(null);
          }}
          width='80vw'
        >
          <Box
            className='p-4'
            sx={{
              overflowY: 'auto'
            }}
            id='drawer-scroll'
          >
            <Typography className='body-font fs-10'>Role Name</Typography>
            <Typography className='header-font font-weight-bold fs-20 pb-3'>{currentRole.name}</Typography>
            <Divider />

            {rootResourcesData.data && roleResourceData.data && (
              <RoleAutocomplete refetchRoleResource={refetchRoleResource} roleId={currentRole.id} resources={rootResourcesData?.data} roleResourceData={roleResourceData?.data} />
            )}

            {roleResourceData.data && allResourcesData.data && (
              <ManageResourcesGrid refetchRoleResource={refetchRoleResource} roleId={currentRole.id} roleResourceData={roleResourceData.data} allResourcesData={allResourcesData.data} />
            )}
          </Box>
        </DrawerComponent>
      </Box>
    )
  );
};

ManageRole.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.string,
  isDrawerOpen: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func,
  templateData: PropTypes.object,
  currentRole: PropTypes.object,
  setCurrentRole: PropTypes.func
};

export default ManageRole;
