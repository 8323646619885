import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import toLower from 'lodash/toLower';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoCompleteSearch from '../../../../components/common/AutoCompleteSearch';
import CustomButton from '../../../../components/common/CustomButton';
import TotalCount from '../../../../components/common/TotalCount';
import { deactivateStage, updateStageData } from '../api';
import AddEditStagePopup from './AddEditStagePopup';
import DescriptionRender from './DescriptionRendered';
import StageActionRenderer from './StageActionRenderer';

const COMMON_TOAST_CONFIG = {
  preventDuplicate: true,
  autoHideDuration: 3000
};
const defaultColumnOptions = {
  resizable: true,
  flex: 1,
  cellStyle: { display: 'block !important', 'text-overflow': 'ellipsis', 'white-space': 'nowrap !important', overflow: 'hidden' },
  tooltip: params => params.value
};
const columnDefs = [
  { field: 'stage_name', headerName: 'Stage Code', ...defaultColumnOptions },
  { field: 'stage_label', headerName: 'Stage Label', ...defaultColumnOptions },
  { field: 'description', enableBrowserTooltips: false, headerName: 'Short Description', ...defaultColumnOptions, valueFormatter: p => (!p.value ? '-' : p.value), cellRenderer: 'descriptionRender' },
  { field: 'color', headerName: 'Color', flex: 1, cellRenderer: 'colorRenderer', cellStyle: { display: 'flex !important', 'justify-content': 'center' } },
  { field: 'seq_no', headerName: 'Seq No.', maxWidth: 100 },
  { field: 'deleted_at', headerName: 'Status', maxWidth: 100, valueFormatter: p => (!p.value ? 'Active' : 'Disabled') },
  { field: 'action', headerName: '', colId: 'action', cellRenderer: 'getActionRenderer', width: 80, maxWidth: 80, minWidth: 80, pinned: 'right', lockPinned: true }
];
const ColorRenderer = params => <Box className='color-label' sx={{ backgroundColor: params?.data.color || '#1e79ab' }} />;

const StageTable = ({ stages }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [masterData, setMasterData] = useState(stages);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [stageData, setStageData] = useState(masterData);
  const [selectedStage, setSelectedStage] = useState(null);

  const onApiUpdate = useCallback(newStageData => {
    if (!newStageData) return;
    let dataCopy = [...masterData];
    setMasterData(prevMasterData => {
      const updatedData = [...prevMasterData];
      const existingStage = updatedData.find(stage => stage.id === newStageData.id);
      if (existingStage) {
        // If found, update the existing stage data
        const index = updatedData.indexOf(existingStage);
        updatedData[index] = newStageData;
      } else {
        // If not found, add the new stage data to the updated data
        updatedData.push(newStageData);
      }
      // Sort the updated data by seq_no in ascending order
      updatedData.sort((a, b) => a.seq_no - b.seq_no);
      dataCopy = updatedData;
      return updatedData;
    });
    setStageData(dataCopy);
  }, []);

  const filterStage = useCallback(
    searchKey => {
      if (!searchKey) {
        setStageData(stages);
      }
      const filteredData = masterData.filter(item => toLower(item.stage_name).includes(toLower(searchKey)));
      setStageData(filteredData);
    },
    [stages]
  );

  const debounceLoadData = useCallback(debounce(filterStage, 300), []);

  const onEditOrAddClick = useCallback(newStage => {
    if (newStage) {
      setSelectedStage(newStage);
    }
    setTimeout(() => {
      setIsPopupOpen(true);
    }, 10);
  }, []);

  const toggleStage = useCallback(
    async currentStage => {
      if (currentStage.deleted_at) {
        // Activate it
        const response = await updateStageData(currentStage.id, { deleted_at: null });
        if (response.success) {
          onApiUpdate(response.data);
          enqueueSnackbar(response.msg, { ...COMMON_TOAST_CONFIG, variant: 'success' });
        } else {
          enqueueSnackbar(response.error, { ...COMMON_TOAST_CONFIG, variant: 'error' });
        }
      } else {
        // Deactivate it
        const response = await deactivateStage(currentStage.id);
        if (response.success) {
          enqueueSnackbar(response.data, {
            ...COMMON_TOAST_CONFIG,
            variant: 'success',
            onExit: () => {
              if (response.warnMsg) {
                enqueueSnackbar(response.warnMsg, { autoHideDuration: 3000, variant: 'warning' });
              }
            }
          });
          onApiUpdate({ ...currentStage, deleted_at: new Date() });
        } else {
          enqueueSnackbar(response.error, { ...COMMON_TOAST_CONFIG, variant: 'error' });
        }
      }
    },
    [onApiUpdate]
  );

  const handlePopupClose = useCallback(() => {
    setIsPopupOpen(false);
    setTimeout(() => {
      setSelectedStage(null);
    }, 100);
  }, []);

  const StageRendered = useCallback(
    params => {
      return <StageActionRenderer params={params} toggleStage={toggleStage} handleEditClick={onEditOrAddClick} />;
    },
    [toggleStage]
  );

  return (
    <>
      <Box height='100%' position='relative' pr={5} pb='50px'>
        <div className='d-flex justify-content-between'>
          <div>
            <AutoCompleteSearch placeholder={t('common.search')} onTextChange={debounceLoadData} className='search-bar' />
          </div>
          <CustomButton onClick={() => onEditOrAddClick()} iconLeft={<Add sx={{ fontSize: '1rem', mr: 1 }} />} buttonText={t('stageSetup.addStage')} />
        </div>
        <Box className='d-flex justify-content-end mb-3'>
          <TotalCount rowCount={stageData.length} />
        </Box>
        <div className='ag-theme-alpine text-center over-flow-hide custom-theme'>
          <AgGridReact
            enableTextSelection
            columnDefs={columnDefs}
            rowData={stageData}
            frameworkComponents={{
              colorRenderer: ColorRenderer,
              getActionRenderer: StageRendered,
              descriptionRender: DescriptionRender
            }}
          />
        </div>
      </Box>
      <AddEditStagePopup onApiUpdate={onApiUpdate} selectedStage={selectedStage} isPopupOpen={isPopupOpen} handlePopupClose={handlePopupClose} />
    </>
  );
};

StageTable.propTypes = {
  stages: PropTypes.array
};

const StageTableDataUnchanged = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

export default React.memo(StageTable, StageTableDataUnchanged);
