import React, { useEffect, useState } from 'react';
import CustomPopup from '../../components/common/CustomPopup';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import './index.scss';
import CompanyStatusSelection from '../../components/common/FunctionalComponents/CompanyStatusSelection';
import { enqueueSnackbar } from 'notistack';
import { useCustomMessageHook } from '../../utils/Hooks/useCustomMessageHook';
import { ignCompanyDataApi } from '../../services/ApiService';
import { ERROR, PATCH, SUCCESS } from '../../services/constantService';
import Loader from '../../components/common/Loader';
import { useForm } from 'react-hook-form';

const CompanyChangeStatusPopup = props => {
  const { setIsPopupOpen, isPopupOpen, companyStatus, setChange } = props;
  const { translateMessage } = useCustomMessageHook();
  const [loading, setLoading] = useState(false);
  const { register, unregister, setValue, getValues, watch } = useForm({});

  const handleClose = () => {
    setIsPopupOpen(false);
  };
  useEffect(() => {
    register('company_status');
    return () => {
      unregister('company_status');
    };
  }, [register, unregister]);
  // const handleSubmit = () => {
  //   setIsPopupOpen(false);
  // }
  const handleSubmit = async () => {
    try {
      let formValues = { ...getValues() };
      const payload = {
        company_status: formValues['company_status']?.field_value || ''
      };
      console.log(payload, 'ASSIGN TAG PAYLOAD');
      setLoading(true);
      const { status, data } = await ignCompanyDataApi(PATCH, payload, companyStatus?.id);
      if (status === 200) {
        console.log('data', data);
        setChange(true);
        setValue('company_status', null);
        // getHeaderDetails();
        // setIsEdited(true);
        const message = translateMessage('Successfully', false, 'Status', 'Changed');
        enqueueSnackbar(message, { variant: SUCCESS });
      } else {
        const message = translateMessage('UnableMessage', false, 'Change Status', '');

        enqueueSnackbar(message, { variant: ERROR });
      }
      setLoading(false);
      setIsPopupOpen(false);
    } catch (e) {
      console.log('Error found in handleSubmit::', e);
    }
  };
  return (
    <div>
      <CustomPopup dropdown={true} title={'Change Status'} open={isPopupOpen} onClose={handleClose} showAction={true} handleSubmit={handleSubmit}>
        <Loader show={loading} className='h-75' />
        <Box id='change-status'>
          <Box className='status-dropdown p-4'>
            <CompanyStatusSelection
              className='tag-auto-complete'
              value={watch('company_status') || companyStatus?.company_status_trans_value}
              onChange={data => {
                setValue('company_status', data);
              }}
            ></CompanyStatusSelection>
          </Box>
        </Box>
      </CustomPopup>
    </div>
  );
};
CompanyChangeStatusPopup.propTypes = {
  setIsPopupOpen: PropTypes.func,
  isPopupOpen: PropTypes.bool,
  companyStatus: PropTypes.object,
  setChange: PropTypes.func
};

export default CompanyChangeStatusPopup;
