//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import TeamInfoTable from './TeamInfoTable';
import { VALIDATION_MESSAGE } from '../../../../../services/MessageService';

export default function ResearcherInformation(props) {
  const { register, setValue, currentValues = {}, isEditing = true, option, message } = props;

  const fields = {
    execution_credit: 100
  };

  const [researchers, setResearchers] = useState([]);
  const [selectedResearchers, setSelectedReachers] = useState([]);
  useEffect(() => {
    register('researchers');
    setValue('researchers', researchers);
  }, [register, researchers, setValue]);
  useEffect(() => {
    if (researchers && researchers?.length > 0) {
      const options = option?.filter(array_el => {
        return researchers.every(anotherOne_el => anotherOne_el?.user?.id !== array_el?.id);
      });
      setSelectedReachers(options);
    } else {
      setSelectedReachers(option);
    }
  }, [register, researchers, setValue]);
  useEffect(() => {
    setResearchers(currentValues.researchers);
  }, [currentValues.researchers]);

  return (
    <TeamInfoTable
      readOnlyMessage={message}
      header={VALIDATION_MESSAGE.researcher_information}
      label='Researcher'
      inputConfig={{ list: researchers, fields, setter: setResearchers }}
      isEditing={isEditing}
      option={selectedResearchers}
    />
  );
}

ResearcherInformation.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  option: PropTypes.array,
  message: PropTypes.string
};
