//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Grid, Button, InputLabel, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import AttributeSelection from './AttributeSelection';
import { getIcon } from './utils';
import RichTextPopup from '../../../../../RichTextPopup';
export default function Attribute(props) {
  const { position, attribute: attributeInfo, updateAttribute = () => {}, readOnly = false, selectedOptions = [], attributeIndex, updatedDate } = props;

  const [attribute, setAttribute] = useState(attributeInfo);
  const [flag, setFlag] = useState(false);

  const currentQuestion = attribute && Object.keys(attribute)?.length > 0 && (flag || attribute.question?.trim()?.length > 0) ? [attribute.question] : [];

  useEffect(() => {
    setAttribute(attributeInfo);
  }, [attributeInfo]);

  const addQuestionElement = async () => {
    try {
      let newAttribute = { ...attribute };
      newAttribute.question = '';
      setFlag(true);
      await updateAttribute(newAttribute, attributeIndex);
      setAttribute(newAttribute);
    } catch (e) {
      console.log('Error found in removeQuestionElement::', e);
    }
  };

  const removeQuestionElement = async () => {
    try {
      let newAttribute = { ...attribute };
      newAttribute.question = '';
      setFlag(false);
      await updateAttribute(newAttribute, attributeIndex, true, true);
      setAttribute(newAttribute);
    } catch (e) {
      console.log('Error found in removeQuestionElement::', e);
    }
  };

  const renderAttributeDefinition = definition => {
    if (!definition) return '';

    const definitionList = definition.split(';');

    return (
      <div className='attribute-defination'>
        {definitionList.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </div>
    );
  };

  const renderAddQuestionButton = currentQuestion => {
    if (currentQuestion?.length > 0 && currentQuestion[0] !== undefined) {
      return;
    }
    return (
      <>
        {!readOnly && (
          <Button variant={'text'} startIcon={<AddCircleIcon />} color='primary' style={{ justifyContent: 'flex-start' }} onClick={addQuestionElement}>
            Add Question
          </Button>
        )}
      </>
    );
  };

  const saveQuestion = async (payload, index, type, item, setAutoSaveLoading) => {
    try {
      attribute.question = payload.question;
      await updateAttribute(attribute, attributeIndex, true, false, setAutoSaveLoading);
    } catch (e) {
      console.log('Error found in saveQuestion::', e);
    }
  };

  const renderAttributeQuestions = (definition, readOnly) => {
    if (!definition) return '';
    return (
      <div className='clients-list d-flex flex-column attributeQuestions'>
        <DndProvider backend={HTML5Backend}>
          {currentQuestion?.length > 0 && currentQuestion[0] !== undefined && (
            <Grid container item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid item xs={12}>
                <Typography color={'primary'} style={{ fontSize: '12px' }}>
                  Question
                </Typography>
              </Grid>
            </Grid>
          )}
          {currentQuestion &&
            currentQuestion.map(
              (item, index) =>
                item !== undefined && (
                  <Grid className='d-flex' key={index} item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className='container'>
                      <div className='row'>
                        <div className='col-11 text-view-value p-0 w-100'>
                          <div className='outlined-border transform p-0'>
                            <RichTextPopup
                              // className="input-form-field"
                              // ref={register('bio')}
                              onSave={async data => {
                                attribute.question = data.question;
                                await updateAttribute(attribute, attributeIndex, true);
                                setAttribute(attribute);
                              }}
                              key={index}
                              setOpen={readOnly ? false : true}
                              index={index}
                              placeholder={''}
                              title='Question'
                              autoSave={saveQuestion}
                              name={'question'}
                              value={item || ''}
                              expandToContent={true}
                              saveStatus={true}
                              InputLabelProps={{ focused: true }}
                              updatedDate={updatedDate}
                              payloadType={'default'}
                              spfaSkillFlag={true}
                              restrictHeight={true}
                            />
                          </div>
                        </div>
                        <span>
                          <DeleteIcon onClick={() => removeQuestionElement()} className='cursor-pointer' />
                        </span>
                      </div>
                    </div>
                  </Grid>
                )
            )}
          {renderAddQuestionButton(currentQuestion)}
        </DndProvider>
      </div>
    );
  };

  return (
    <div className={`attribute d-flex section-content mt-2 ${readOnly ? 'read-only' : ''}`}>
      <div className='attribute-image'>{attribute?.name && getIcon(attribute?.name)}</div>
      <div className='d-flex flex-column flex-grow-1'>
        {!readOnly ? (
          <AttributeSelection
            InputLabelProps={{ focused: true }}
            selectedOptions={selectedOptions}
            value={attribute?.name || null}
            label={`Attribute ${position !== null ? position + 1 : ''}`}
            onChange={async (e, data) => {
              await updateAttribute(data, position, true, true);
              setAttribute(data);
            }}
          />
        ) : (
          <div className='d-flex flex-column'>
            <InputLabel focused color='primary' style={{ fontSize: 12 }} className='m-0'>
              Attribute {position + 1}
            </InputLabel>
            <span style={{ fontSize: 14 }}>{attribute.name}</span>
          </div>
        )}

        <div className='row mt-3 attributeDefinitioneQuestions'>
          <div className='clients-list pr-5 d-flex flex-column'>{renderAttributeDefinition(attribute?.definition)}</div>
          {renderAttributeQuestions(attribute?.definition, readOnly)}
        </div>
      </div>
    </div>
  );
}

Attribute.propTypes = {
  position: PropTypes.number,
  attribute: PropTypes.object,
  updateAttribute: PropTypes.func,
  readOnly: PropTypes.bool,
  selectedOptions: PropTypes.array,
  attributeIndex: PropTypes.number,
  updatedDate: PropTypes.string
};
