//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InvoiceInfoView from './Components/InvoiceInfoView';

const InvoiceInfoContainer = props => {
  const { register, setValue, watch, currentValues, renderFieldLabel, updateField } = props;

  return <InvoiceInfoView register={register} setValue={setValue} watch={watch} currentValues={currentValues} renderFieldLabel={renderFieldLabel} updateField={updateField} />;
};

InvoiceInfoContainer.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  renderFieldLabel: PropTypes.func,
  updateField: PropTypes.func
};

export default InvoiceInfoContainer;
