import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../../components/common/CustomButton';
import CustomModalWithHeader from '../../../../Commons/CustomModalWithHeader';

export default function AddBulkCompany({ isOpen = false, onClose = () => {}, onSave = () => {} }) {
  const [textValue, setTextValue] = useState('');
  const { t } = useTranslation();

  return (
    <CustomModalWithHeader label={t('actions.bulkAddCompanies')} onClose={onClose} isOpen={isOpen} closeIcon={true}>
      <div className='w-100 p-2'>
        <TextField placeholder={t('helperText.lineSeparatedCompany')} rows={8} className='w-100 mt-2' multiline={true} onChange={e => setTextValue(e.target.value)} />
        <div className='w-100 mt-4 d-flex justify-content-end'>
          <CustomButton buttonText={t('actions.add')} onClick={() => onSave(textValue)} />
        </div>
      </div>
    </CustomModalWithHeader>
  );
}

AddBulkCompany.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func
};
