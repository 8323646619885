import React, { useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Box, Button, IconButton, InputAdornment, Switch, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import '../index.scss';
import Loader from '../../../components/common/Loader';
import { PAGE_LIMIT, columnDefs, getFilterParamStringForTranslateValue } from './utils';
import AddTranslateValue from './AddTranslateValue';
import CustomButton from '../../../components/common/CustomButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import TotalCount from '../../../components/common/TotalCount';
import { dashboardDataApi } from '../../../services/ApiService';
import ViewTranslateValueDrawer from './ViewTranslateValueDrawer';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { API, ERROR, GET, POST, PUT, SUCCESS, WARNING } from '../../../services/constantService';
import { useNavigate } from 'react-router-dom';
import { notFoundMessage } from '../../../services/MessageService';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import { Search } from '@mui/icons-material';

const TranslateValueSetupList = () => {
  const gridApi = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const { getValues, setValue, register, unregister, watch } = useForm({});
  const navigate = useNavigate();
  const { t } = useLanguageHooks([TRANS_KEYS.BACK, TRANS_KEYS.TRANSLATE_VALUE_ADD_VALUES]);

  const backMenu = () => {
    navigate('/productSetup');
  };

  const { enqueueSnackbar } = useSnackbar();
  const [translateData, setTranslateData] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
  };

  const timerRef = useRef();
  const paramsRef = useRef();

  const handleSearchChange = event => {
    const { value } = event.target;
    setSearchValue(value);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      gridApi.current.api.setFilterModel({ searchValueFilter: { filterType: 'text', filter: value } });
    }, 500);
  };

  const dataSource = {
    getRows: async params => {
      const { filterModel, sortModel } = params;
      paramsRef.current = params;

      const updatedFilterModel = { ...filterModel };

      const searchValue = updatedFilterModel?.searchValueFilter?.filter;
      let paginationString = `limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}`;
      let url = '';
      url = `${API.product_setting}/translate_value`;

      gridApi.current?.api?.showLoadingOverlay();

      if (sortModel?.length > 0) {
        paginationString = paginationString.concat(`&sortOn=${sortModel[0].colId}&sortType=${sortModel[0].sort.toUpperCase()}`);
      }

      if (searchValue) {
        paginationString = paginationString.concat(`&searchValue=${searchValue}`);
      }

      delete updatedFilterModel?.searchValueFilter;

      if (Object.keys(updatedFilterModel)?.length) {
        paginationString = paginationString.concat(getFilterParamStringForTranslateValue(updatedFilterModel));
      }

      await getRows(url, paginationString, enqueueSnackbar, params, setRowCount, gridApi, setIsLoading);
    }
  };

  const updateStatus = async params => {
    const value = params.getValue();
    let url = '';
    url = `${API.product_setting}/translate_value/${params?.data?.id}`;
    gridApi.current?.api?.showLoadingOverlay();

    const { status } = await dashboardDataApi(PUT, url, '', { ...params.data, is_active: !value });
    gridApi.current?.api?.hideOverlay();

    if (status === 200) {
      params.setValue(!value);
      return;
    }
    return enqueueSnackbar('Failed to update status', { variant: ERROR });
  };

  const updateTranslateValue = async () => {
    const body = getValues();
    let url = '';
    url = `${API.product_setting}/translate_value/${body?.id}`;
    gridApi.current?.api?.showLoadingOverlay();

    const { status } = await dashboardDataApi(PUT, url, '', body);
    gridApi.current?.api?.hideOverlay();
    if (status === 200) {
      enqueueSnackbar('Translate value updated successfully', { variant: SUCCESS });
      setIsDrawerOpen(false);
      setIsEdited(false);
      const rowNode = gridApi.current.api.getRowNode(body.id);
      rowNode.setData(body);
      return;
    }
    return enqueueSnackbar('Failed to update!', { variant: ERROR });
  };

  const ActionsRenderer = params => {
    return (
      <Switch
        checked={params?.data?.is_active}
        inputProps={{ 'aria-label': 'controlled' }}
        onChange={e => {
          e.stopPropagation();
          updateStatus(params);
        }}
      />
    );
  };

  const translateViewRenderer = params => {
    return (
      <Typography
        className='text-link'
        color='primary'
        onClick={() => {
          setTranslateData(params.data);
        }}
      >
        {params.value}
      </Typography>
    );
  };

  const handleSaveTranslateValues = async () => {
    const body = getValues();

    if (!body?.field_name) {
      return enqueueSnackbar('Field Name is mandatory', { variant: ERROR });
    }
    if (!body?.field_value) {
      return enqueueSnackbar('Field Value is mandatory', { variant: ERROR });
    }
    let url = '';
    url = `${API.product_setting}/translate_value`;
    const { status, data } = await dashboardDataApi(POST, url, '', body);
    if (status === 200) {
      setRowCount(rowCount + 1);
      enqueueSnackbar('Translate value added successfully', { variant: SUCCESS });
      gridApi.current.api.applyTransaction({ add: [{ ...body, id: data?.id }], addIndex: 0 });
      setIsPopupOpen(false);
      return true;
    }
    return false;
  };

  return (
    <div
      style={{
        height: '82vh'
      }}
    >
      <Loader show={isLoading} />

      <Box height='75%' paddingX='20px'>
        <Box className='mb-3 pt-3'>
          <CustomButton
            onClick={() => backMenu()}
            buttonText={t(`${TRANS_KEYS.BACK}:key`)}
            type={'tertiary'}
            size={'small'}
            variant=''
            width={42}
            customWidth={42}
            iconLeft={<KeyboardBackspaceIcon sx={{ width: 20, height: 20 }} />}
          />
        </Box>

        <div className='d-flex justify-content-between mb-3'>
          <div>
            <TextField
              size='small'
              label='Search'
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={searchValue}
              onChange={handleSearchChange}
            />
          </div>

          <Button variant='contained' className='text-capitalize' startIcon={<AddIcon className='icon-size' />} onClick={() => setIsPopupOpen(true)}>
            {t(`${TRANS_KEYS.TRANSLATE_VALUE_ADD_VALUES}:key`)}
          </Button>
        </div>
        <Box className='d-flex justify-content-end mb-3'>
          <TotalCount rowCount={rowCount} />
        </Box>
        <Box className='ag-theme-alpine ag-theme-alpine-1 text-center' height={'100vh'} style={{ width: '100%', height: '100%', overflowX: 'scroll' }}>
          <AgGridReact
            ref={gridApi}
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              width: 100,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            tooltipShowDelay={0}
            cacheBlockSize={PAGE_LIMIT}
            frameworkComponents={{
              ActionsRenderer: ActionsRenderer,
              translateViewRenderer: translateViewRenderer
            }}
            alwaysShowHorizontalScroll
            suppressHorizontalScroll={false}
            suppressRowClickSelection={true}
            columnDefs={columnDefs(useLanguageHooks)}
            datasource={dataSource}
            rowSelection={'multiple'}
            rowModelType={'infinite'}
            getRowNodeId={data => data.id}
            onCellClicked={params => {
              setIsDrawerOpen(true);
              setTranslateData(params.data);
            }}
          ></AgGridReact>
        </Box>
        <AddTranslateValue
          setIsPopupOpen={setIsPopupOpen}
          isPopupOpen={isPopupOpen}
          saveData={handleSaveTranslateValues}
          setValue={setValue}
          register={register}
          unregister={unregister}
          watch={watch}
        />
        <ViewTranslateValueDrawer
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          data={translateData}
          gridApi={gridApi.current}
          setValue={setValue}
          register={register}
          unregister={unregister}
          onSubmit={updateTranslateValue}
          watch={watch}
          isEdited={isEdited}
          setIsEdited={setIsEdited}
        />
      </Box>
    </div>
  );
};

export default TranslateValueSetupList;

async function getRows(url, paginationString, enqueueSnackbar, params, setRowCount, gridApi, setIsLoading) {
  const { status, data } = await dashboardDataApi(GET, url, paginationString);
  if (status === 200 && data?.data) {
    if (data?.data?.count === 0) {
      const message = notFoundMessage('records');
      enqueueSnackbar(message, { variant: WARNING });
    }
    params.successCallback(data?.data?.rows, data?.data?.count);
    setRowCount(data?.data?.count);
  }
  gridApi.current?.api?.hideOverlay();
  setIsLoading(false);
}
