//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { Button, InputLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import Loader from '../common/Loader';
import InputField from '../common/InputField';
import './index.scss';
import { Helmet } from 'react-helmet';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { SUCCESS, ERROR, PUT } from '../../services/constantService';
import { SfpaSettingsApi } from '../../services/ApiService';
import { VALIDATION_MESSAGE, successMessage, unableMessage } from '../../services/MessageService';
import { customTemplateFormValidator, requireValidTemplateMessage } from '../../utils/common';
import { useSnackbar } from 'notistack';
import Popup from 'reactjs-popup';

export default function EditSfpaBuilderPopup(props) {
  const { sfpaBuilderData, closePopup, setIsEditing, setSfpaBuilderData, setIsDataUpdated } = props;
  const { register, watch, handleSubmit } = useForm({
    defaultValues: { ...sfpaBuilderData }
  });
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const addSchema = yup.object().shape({
    name: yup.string().required(),
    model: yup.string().required(),
    temperature: yup.number().required().min(0).max(1),
    frequency_penalty: yup.number().required().min(0).max(2),
    max_tokens: yup.number().required().min(0).max(2000),
    system_content: yup.string().required().min(0).max(2),
    presence_penalty: yup.number().required()
  });

  const editSfpaBuilder = async templateData => {
    templateData.temperature = templateData.temperature === '' ? null : Number(templateData.temperature);
    templateData.frequency_penalty = templateData.frequency_penalty === '' ? null : Number(templateData.frequency_penalty);
    templateData.max_tokens = templateData.max_tokens === '' ? null : Number(templateData.max_tokens);
    templateData.presence_penalty = templateData.presence_penalty === '' ? null : Number(templateData.presence_penalty);

    const isValid = await addSchema.isValid(templateData);
    if (!isValid) {
      let requiredField = [
        { fieldName: 'name', label: 'Template Name', type: String },
        { fieldName: 'model', label: 'Model', type: String },
        {
          fieldName: 'temperature',
          label: 'Temperature',
          type: Number,
          min: 0,
          max: 1
        },
        {
          fieldName: 'frequency_penalty',
          label: 'FrequencyPenalty',
          type: Number,
          min: 0,
          max: 2
        },
        {
          fieldName: 'max_tokens',
          label: 'MaxTokens',
          type: Number,
          min: 0,
          max: 2000
        },
        { fieldName: 'system_content', label: 'SystemContent', type: String },
        {
          fieldName: 'presence_penalty',
          label: 'PresencePenalty',
          type: Number,
          min: 0,
          max: 2
        }
      ];
      let dirtyField = customTemplateFormValidator(templateData, requiredField);

      if (dirtyField) {
        const message = requireValidTemplateMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }

    setLoading(true);
    const { status, data } = await SfpaSettingsApi(PUT, templateData, 'update', sfpaBuilderData.id);
    if (status === 200) {
      const message = successMessage('Template', VALIDATION_MESSAGE.updated_message);
      enqueueSnackbar && enqueueSnackbar(message, { variant: SUCCESS });
      setIsDataUpdated(true);
      setSfpaBuilderData(data[1][0]);
      setLoading(false);
      setIsEditing(false);
    } else {
      const message = unableMessage('Template', 'update');
      enqueueSnackbar && enqueueSnackbar(data?.message || message, { variant: ERROR });
      setLoading(false);
    }
  };
  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <Popup open={true} modal className={'add-document-value'}>
      <Helmet>
        <title>Edit AI Template - KGP Galaxy</title>
      </Helmet>
      <div>
        <Loader show={loading} />
        <form autoComplete='off'>
          <div className='' style={{ background: '#2a364c', color: '#fff' }}>
            <div className='quick-add-header'>
              <Typography>Edit AI Template</Typography>
              <CloseIcon className='cursor-pointer' onClick={closePopup} />
            </div>
          </div>

          <div className='d-flex flex-column py-3'>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className='page-label'>
                  Template Name
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant='outlined'
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='Template Name'
                    {...register('name')}
                    name='name'
                    InputLabelProps={{ focused: true }}
                    value={watch('name') || null}
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className='page-label'>
                  Model
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant='outlined'
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='Model'
                    {...register('model')}
                    name='model'
                    InputLabelProps={{ focused: true }}
                    value={watch('model') || null}
                  />
                </div>
              </div>
            </div>

            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className='page-label'>
                  Temperature
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    type='number'
                    variant='outlined'
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='Temperature'
                    {...register('temperature')}
                    name='temperature'
                    InputLabelProps={{ focused: true }}
                    value={watch('temperature') || null}
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <InputLabel required='true' className='page-label'>
                  Frequency Penalty
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    type='number'
                    variant='outlined'
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='Frequency penalty'
                    {...register('frequency_penalty')}
                    name='frequency_penalty'
                    InputLabelProps={{ focused: true }}
                    value={watch('frequency_penalty') || null}
                  />
                </div>
              </div>
            </div>

            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel required='true' className='page-label'>
                  Max Tokens
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    type='number'
                    variant='outlined'
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='Max Tokens'
                    {...register('max_tokens')}
                    name='max_tokens'
                    InputLabelProps={{ focused: true }}
                    value={watch('max_tokens') || null}
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <InputLabel required='true' className='page-label'>
                  System Content
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant='outlined'
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='System Content'
                    {...register('system_content')}
                    name='system_content'
                    InputLabelProps={{ focused: true }}
                    value={watch('system_content') || null}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel required='true' className='page-label'>
                  Presence Penalty
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    type='number'
                    variant='outlined'
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='Penalty Penalty'
                    {...register('presence_penalty')}
                    name='presence_penalty'
                    InputLabelProps={{ focused: true }}
                    value={watch('presence_penalty') || null}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='page-content-footer'>
            <Button className='page-btn-cancel' onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant='contained' type='submit' className='page-btn-save' onClick={handleSubmit(editSfpaBuilder)}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Popup>
  );
}

EditSfpaBuilderPopup.propTypes = {
  sfpaBuilderData: PropTypes.object,
  closePopup: PropTypes.func,
  setIsEditing: PropTypes.func,
  setSfpaBuilderData: PropTypes.func,
  setIsDataUpdated: PropTypes.func
};
