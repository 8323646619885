import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import { toLower } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import '../index.scss';

const TagDeleteConfirmation = ({ open, onClose, onDelete, tagCount = {}, tagStatus, isDeleteTag }) => {
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_CANCEL]);
  const tagAction = isDeleteTag ? 'Delete' : tagStatus;
  function createAssignmentMessage(tagCount) {
    const createMessage = tagCount => {
      let message = 'This tag is assigned to ';

      for (const moduleName in tagCount) {
        if (Object.hasOwnProperty.call(tagCount, moduleName)) {
          const count = tagCount[moduleName];
          if (count > 0) {
            message += `${count} ${moduleName}, `;
          }
        }
      }
      if (message.endsWith('assigned to ')) {
        return '';
      }
      return (message = message.slice(0, -2));
    };
    const displayMessage = createMessage(tagCount);
    const finalMeassage = `${displayMessage ? `${displayMessage},` : ''}  Do you want to ${tagAction} Tag`;

    return finalMeassage;
  }
  return (
    <Dialog id='tag-confirmation' open={open} onClose={onClose}>
      <DialogTitle className='dialog-content'>{`${tagStatus} Tags`}</DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <DialogContentText className='dialog-content-text fs-16 '>{createAssignmentMessage(tagCount)}</DialogContentText>
      </DialogContent>

      <DialogActions className='pr-3'>
        <Box className='button-section-personality p-0'>
          <Button variant='text' id='button-cancle' className='text-capitalize' autoFocus onClick={onClose}>
            {t('actions.cancel')}
          </Button>
          <Button autoFocus id='button-add' className='text-capitalize' variant='contained' onClick={() => onDelete(tagAction)}>
            {t(`actions.${toLower(tagAction)}`)}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
TagDeleteConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  tagCount: PropTypes.func,
  tagStatus: PropTypes.bool,
  isDeleteTag: PropTypes.bool
};

export default TagDeleteConfirmation;
