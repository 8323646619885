import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet';
import EditIcon from '@mui/icons-material/Edit';
import { InputLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ViewSfpaBuilderPopup = props => {
  const { sfpaBuilderData, editForm, closePopup } = props;

  return (
    <>
      <Helmet>
        <title>Template - KGP Galaxy</title>
      </Helmet>

      <div className=''>
        <div className='' style={{ background: '#2a364c', color: '#fff' }}>
          <div className='quick-add-header'>
            <Typography>View AI Template</Typography>
            <CloseIcon className='cursor-pointer' onClick={closePopup} />
          </div>
        </div>

        <div className='d-flex flex-column py-3'>
          <div className='d-flex contact-details-row '>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>Template Name</InputLabel>
              <span className='page-label-value'>{sfpaBuilderData.name ? sfpaBuilderData.name : '--'}</span>
            </div>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>Model</InputLabel>
              <span className='page-label-value'>{sfpaBuilderData.model ? sfpaBuilderData.model : '--'}</span>
            </div>
          </div>
          <div className='d-flex contact-details-row '>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>Temperature</InputLabel>
              <span className='page-label-value'>{sfpaBuilderData.temperature === 0 || sfpaBuilderData.temperature ? sfpaBuilderData.temperature : '--'}</span>
            </div>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>Frequency Penalty</InputLabel>
              <span className='page-label-value'>{sfpaBuilderData.frequency_penalty === 0 || sfpaBuilderData.frequency_penalty ? sfpaBuilderData.frequency_penalty : '--'}</span>
            </div>
          </div>
          <div className='d-flex contact-details-row '>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>Max Tokens</InputLabel>
              <span className='page-label-value'>{sfpaBuilderData.max_tokens === 0 || sfpaBuilderData.max_tokens ? sfpaBuilderData.max_tokens : '--'}</span>
            </div>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>System Content</InputLabel>
              <span className='page-label-value'>{sfpaBuilderData.system_content ? sfpaBuilderData.system_content : '--'}</span>
            </div>
          </div>

          <div className='d-flex contact-details-row '>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>Presence Penalty</InputLabel>
              <span className='page-label-value'>{sfpaBuilderData.presence_penalty === 0 || sfpaBuilderData.presence_penalty ? sfpaBuilderData.presence_penalty : '--'}</span>
            </div>
          </div>
        </div>

        <div className='page-content-edit-footer'>
          <div className='d-flex align-items-center action-edit' onClick={editForm}>
            <span className='section-icon'>
              <EditIcon fontSize='inherit' />
            </span>
            <span>Edit Details</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewSfpaBuilderPopup;

ViewSfpaBuilderPopup.propTypes = {
  sfpaBuilderData: PropTypes.object,
  editForm: PropTypes.bool,
  closePopup: PropTypes.func
};
