import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';

const ListItem = props => {
  const { option, handleOnChecked, checked: defaultChecked, deleteIcon = false } = props;
  const [checked, setChecked] = useState(defaultChecked);

  const handleOnchange = () => {
    setChecked(!checked);
    handleOnChecked(!checked, option);
  };

  const handleRowHover = e => {
    e.currentTarget.style.backgroundColor = '#f0f0f0'; // Change background color on hover
  };

  const handleRowLeave = e => {
    e.currentTarget.style.backgroundColor = 'transparent'; // Reset background color on leave
  };
  return (
    <Box
      onClick={deleteIcon ? null : handleOnchange}
      onMouseEnter={handleRowHover}
      onMouseLeave={handleRowLeave}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        paddingX: 2,
        paddingY: 1,
        marginY: 1,
        alignItems: 'center',
        borderRadius: '4px',
        transition: 'background-color 0.3s ease',
        cursor: 'pointer'
      }}
    >
      {deleteIcon && (
        <Button onClick={handleOnchange}>
          <Delete sx={{ marginRight: '1rem' }} />
        </Button>
      )}
      {!deleteIcon && <FormControlLabel sx={{ width: '30px' }} control={<Checkbox checked={checked} onChange={handleOnchange} />} onClick={e => e.stopPropagation()} />}
      <Box sx={{ flexGrow: 1 }}>
        <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>{option?.ign_companies?.name}</Typography>
        <Typography style={{ fontSize: '14px' }}>{option.job_title}</Typography>
        <Typography style={{ fontSize: '12px', fontStyle: 'italic' }}>({option.job_number})</Typography>
      </Box>
    </Box>
  );
};

export default ListItem;

ListItem.propTypes = {
  option: PropTypes.object,
  handleOnChecked: PropTypes.func,
  checked: PropTypes.bool,
  deleteIcon: PropTypes.bool
};
