import React, { useState } from 'react';
import DynamicTabLayout from '../../../components/DynamicTabs/TabLayout';
import TranslateValueSetupList from './List';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';

const TranslateValueSetup = () => {
  const { t } = useLanguageHooks([TRANS_KEYS.TRANSLATE_VALUE_SETUP, TRANS_KEYS.TRANSLATE_VALUE_CONTENT]);
  const TabData = [
    {
      label: t(`${TRANS_KEYS.TRANSLATE_VALUE_SETUP}:key`),
      content: t(`${TRANS_KEYS.TRANSLATE_VALUE_CONTENT}:key`),
      subTabs: []
    }
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <React.Fragment>
      <div className='position-relative m-3'>
        <DynamicTabLayout navItems={TabData} isDrawer={true} isDrawerCss={true} activeTab={activeTab} tabIndex={tabIndex} setTabIndex={setTabIndex} setActiveTab={setActiveTab}>
          <TranslateValueSetupList index={t(`${TRANS_KEYS.TRANSLATE_VALUE_SETUP}:key`)} />
        </DynamicTabLayout>
      </div>
    </React.Fragment>
  );
};

export default TranslateValueSetup;
