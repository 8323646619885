//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import './index.scss';
import PhonePopover from '../../../Containers/Contacts/PhonePopover';

export default function PhoneView(props) {
  const { contact, increaseFontSize = false } = props;

  const getPhoneList = () => {
    let phones = [];
    if (contact.work_phone) {
      phones.push({ label: 'Home', value: contact.work_phone });
    }
    if (contact.mobile_phone) {
      phones.push({ label: 'Mobile', value: contact.mobile_phone });
    }
    if (contact.home_phone) {
      phones.push({ label: 'Home', value: contact.home_phone });
    }
    return phones;
  };

  const renderPhoneView = () => {
    let phones = getPhoneList();
    return phones?.length > 0 ? (
      <PhonePopover phones={phones}>
        <a href={`tel:${phones[0].value}`} target={'noopener'} className='contact-profile-link'>
          <LocalPhoneIcon fontSize={increaseFontSize ? 'default' : 'inherit'} />
        </a>
      </PhonePopover>
    ) : (
      <span className='contact-profile-link'>
        <LocalPhoneIcon fontSize={increaseFontSize ? 'default' : 'inherit'} color='disabled' />
      </span>
    );
  };

  return renderPhoneView();
}

PhoneView.propTypes = {
  contact: PropTypes.object,
  increaseFontSize: PropTypes.bool
};
