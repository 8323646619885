import { Box, MenuItem } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomButtonDropDown from '../../components/common/CustomButtonDropDown';
import { ROUTES } from '../../services/constantService';

const SecurityPage = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex'
        }}
        // id='security-page'
      >
        <CustomButtonDropDown
          iconRight={<KeyboardArrowDownIcon />}
          size='large'
          buttonText={'Security'}
          color='text'
          variant={'text'}
          customWidth={200}
          styledMenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            style: {
              marginTop: 8,
              borderRadius: 6,
              color: 'black'
            }
          }}
        >
          <MenuItem
            sx={{
              width: 200,
              display: 'flex'
            }}
            onClick={() => navigate(ROUTES.userSecurity)}
            key={'user-security-item'}
          >
            Users
          </MenuItem>
          <MenuItem
            sx={{
              width: 200,
              display: 'flex'
            }}
            onClick={() => navigate(ROUTES.roleSecurity)}
            key={'role-security-item'}
          >
            Roles
          </MenuItem>
          <MenuItem
            sx={{
              width: 200,
              display: 'flex'
            }}
            onClick={() => navigate(ROUTES.resourceSecurity)}
            key={'resource-security-item'}
          >
            Resources
          </MenuItem>
        </CustomButtonDropDown>
      </Box>
    </React.Fragment>
  );
};

export default SecurityPage;
