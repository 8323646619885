import React from 'react';
// import CustomDropdown from "../../CustomDropdown";
import PropTypes from 'prop-types';
import '../../../../Containers/Commons/AutoCompleteStyle/index.scss';
import { useThemeHook } from '../../../../../src/utils/Hooks/UseThemeHook';
import CustomDropdown from '../../CustomDropdown';
import { useState } from 'react';
import { useEffect } from 'react';
const ColorSelection = props => {
  const { label, value, onChange } = props;
  const [options, setOptions] = useState([]);

  const { theme } = useThemeHook();
  let themeData = {
    primary: theme.palette?.primary?.main,
    secondary: theme.palette?.secondary?.main,
    text: theme.palette.text?.primary,
    heading: theme.palette.heading?.main,
    error: theme.palette.error?.main,
    background: theme.palette?.background?.main
  };
  const color = Object.values(themeData);
  useEffect(() => {
    setOptions(color);
  }, [color]);

  return (
    <>
      <CustomDropdown
        label={label}
        options={options}
        // className={className}
        onChange={onChange}
        defaultValue={value}
        selectColor={color}
        coloredDropdown={true}
      />
    </>
  );
};
ColorSelection.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default ColorSelection;
