//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Button, Typography, InputLabel, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Delete } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Popup from 'reactjs-popup';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Loader from '../../common/Loader';
import ReferenceStatusSelection from './ReferenceStatusSelection';
import ReferenceSourceSelection from './ReferenceSourceSelection';
import ReferenceTypeSelection from './ReferenceTypeSelection';
import RichTextPopup from '../../RichTextPopup';
import SearchSelection from '../../SearchSelection';
import ContactSelection from '../../ContactSelection/ContactSelection';
import ConfirmationPopup from '../../ConfirmationPopup';
import { contactReferencesApi } from '../../../services/ApiService';
import { DELETE, PUT, SUCCESS, ERROR } from '../../../services/constantService';
import { unableMessage, successMessage, VALIDATION_MESSAGE, validateMessage } from '../../../services/MessageService';
import { customFormValidator, requireValidMessage } from '../../../utils/common';

const getCleanedReference = data => {
  const payload = { ...data };
  payload.search_id = payload.search != null ? payload.search?.id : null;
  delete payload.search;

  payload.ref_contact_id = payload.contact.id;
  delete payload.contact;

  if (!payload.years_known) {
    delete payload.years_known;
  }

  return payload;
};

export default function EditReference(props) {
  const { handleClose, open, reference: values, contactId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [reference, setReference] = useState(values);
  const { getValues, trigger, /* errors,*/ register, watch, setValue, reset } = useForm();
  const [isLastSaveSuccess, setLastSaveSuccess] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const search = watch('search');
  const contact = watch('contact');

  const deleteReference = async () => {
    setIsLoading(true);
    const sub_route = `references/${reference.id}`;
    const { status, data } = await contactReferencesApi(DELETE, sub_route);
    if (status === 200) {
      const message = successMessage('Reference details', 'deleted');
      enqueueSnackbar(message, { variant: SUCCESS });
      handleClose(true);
    } else {
      const message = unableMessage('reference', 'delete');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };

  const saveRichText = async (data, skipMessage, setAutoSaveLoading) => {
    const sub_route = `references/${reference.id}`;
    let returnResult;
    if (setAutoSaveLoading) {
      setAutoSaveLoading(true);
    }
    const { status } = await contactReferencesApi(PUT, sub_route, data);
    if (status === 200) {
      setLastSaveSuccess(true);
      delete data.old;
      let newReferenceData = { ...reference, ...getValues(), ...data, updated_at: new Date().toLocaleString('en-US') };
      setReference(newReferenceData);
      returnResult = true;
    } else {
      setLastSaveSuccess(false);
      returnResult = false;
    }
    if (setAutoSaveLoading) {
      setTimeout(() => {
        setAutoSaveLoading(false);
      }, 1000);
    }
    return returnResult;
  };

  const handleDeleteRecord = () => {
    setShowConfirmPopup(true);
  };

  const cancelDelete = () => {
    setShowConfirmPopup(false);
  };

  useEffect(() => {
    reset({ ...reference });
  }, [reference, reset]);

  const CreateSchema = yup.object().shape({
    contact: yup.object().required(),
    source: yup.string().required(),
    type: yup.string().required()
  });

  const saveEdit = async () => {
    try {
      await trigger();
      // if (Object.keys(errors)?.length) {
      //   const message = validateMessage("required fields in Details tab", "fill all")
      //   enqueueSnackbar(message, { variant: WARNING })
      //   return
      // }
      const formValue = getValues();
      const isValid = await CreateSchema.isValid(formValue);
      if (!isValid) {
        let requiredField = [
          { fieldName: 'contact', label: 'Reference Name', type: Object },
          { fieldName: 'source', label: 'Source', type: String },
          { fieldName: 'type', label: 'Reference Type', type: String }
        ];
        let dirtyField = customFormValidator(formValue, requiredField);
        if (dirtyField) {
          const message = requireValidMessage(dirtyField);
          enqueueSnackbar(message.replace('Contact', 'Reference Name'), { variant: ERROR });
          return;
        }
      }
      if (formValue.years_known !== '') {
        const yearsValue = parseInt(formValue.years_known);
        if (yearsValue < 0 || yearsValue > 99) {
          const message = validateMessage('valid years known', 'enter');
          enqueueSnackbar(message, { variant: ERROR });
          return;
        }
      }
      setIsLoading(true);
      const payload = getCleanedReference(getValues());
      payload.updated_at = reference.updated_at;
      if (!payload.search_id) payload.search_id = null;
      const sub_route = `references/${reference.id}`;
      const { status, data } = await contactReferencesApi(PUT, sub_route, payload);
      setIsLoading(false);
      if (status === 200) {
        const message = successMessage('Reference details', VALIDATION_MESSAGE.updated_message);
        enqueueSnackbar(message, { variant: SUCCESS });
        handleClose(true);
      } else {
        const message = unableMessage('reference details', 'update');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
    } catch (e) {
      console.log('Error found in saveEdit::', e);
    }
  };

  useEffect(() => {
    register('search');
    register('contact', { required: true });
    register('type', { required: true });
    register('source', { required: true });
    register('status');
    register('general_comments');
    register('skill_comments');
    register('recommendation');
  });

  const handleCancel = () => {
    handleClose(true);
  };

  const renderRichText = (label, field) => {
    return (
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1c-content' id='panel1c-header'>
          <Typography className='section-label mb-0'>{label}</Typography>
        </AccordionSummary>
        <AccordionDetails className='w-100'>
          <div className='w-100 react-quill-container'>
            <div className='outlined-border transform '>
              <RichTextPopup
                bounds={'.react-quill-container'}
                value={reference[field]}
                onChange={data => {
                  setValue(field, data);
                }}
                name={field}
                title={label}
                saveStatus={isLastSaveSuccess}
                autoSave={saveRichText}
                onSave={saveRichText}
                updatedDate={reference.updated_at}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderCompanyLink = company => {
    return company ? (
      <Link to={`/companies/${company?.id}/details`} target='_blank' rel='noopener noreferrer'>
        <Typography color='primary' className='cursor-pointer value'>
          {company?.name}
        </Typography>
      </Link>
    ) : (
      <span className='value'>--</span>
    );
  };
  const defaultValue = reference?.search != null ? reference?.search : 'Not related to a search';
  return (
    reference && (
      <Popup open={open} className='edit-reference' onClose={() => handleClose()} closeOnDocumentClick={false} closeOnEscape={false}>
        <React.Fragment>
          <Loader show={isLoading} />
          {showConfirmPopup && <ConfirmationPopup onConfirm={deleteReference} onClose={cancelDelete} message='Are you sure you want to permanently delete this record from the Galaxy database?' />}
          <div className='overflow-auto'>
            <div className='quick-add-header justify-content-between'>
              <div className='d-flex align-items-center'>
                <Typography className='pr-4 font-weight-bold'>Edit Reference</Typography>
                <div className='action-container d-inline-flex align-items-center' onClick={handleDeleteRecord}>
                  <span className='action-icon'>
                    <Delete fontSize='inherit' />
                  </span>
                  <span className='action-text'>Delete Record</span>
                </div>
              </div>
              <CloseIcon className='cursor-pointer' onClick={handleCancel} />
            </div>
            <div className='quick-add-container'>
              <div className='d-flex flex-column popup-height'>
                <div className='quick-add-content input-form'>
                  <div className='reference-row'>
                    <div className='reference-details'>
                      <InputLabel className='label'>Search #</InputLabel>
                      <SearchSelection defaultValue={defaultValue} className='value' label='' placeholder={'Search here'} contactId={contactId} onChange={(e, data) => setValue('search', data)} />
                    </div>
                    <div className='reference-details'>
                      <InputLabel className='label'>Search Title</InputLabel>
                      {search ? (
                        <Link to={`/searches/${search?.id}/active-candidates`} target='_blank' rel='noopener noreferrer'>
                          <Typography color='primary' className='cursor-pointer value'>
                            {search?.job_title}
                          </Typography>
                        </Link>
                      ) : (
                        <span className='value'>--</span>
                      )}
                    </div>
                    <div className='reference-details'>
                      <InputLabel className='label'>Search Company</InputLabel>
                      {renderCompanyLink(search?.company)}
                    </div>
                  </div>
                  <div className='reference-row'>
                    <div className='reference-details'>
                      <InputLabel className='label' required={true}>
                        Reference Name
                      </InputLabel>
                      <ContactSelection defaultValue={reference.contact} className='value' label='' placeholder={'Search here'} onChange={(e, data) => setValue('contact', data)} />
                    </div>
                    <div className='reference-details'>
                      <InputLabel className='label'>Reference Title</InputLabel>
                      {contact ? (
                        <Link to={`/contacts/${contact?.id}/details`} target='_blank' rel='noopener noreferrer'>
                          <Typography color='primary' className='cursor-pointer value'>
                            {contact?.current_job_title}
                          </Typography>
                        </Link>
                      ) : (
                        <span className='value'>--</span>
                      )}
                    </div>
                    <div className='reference-details'>
                      <InputLabel className='label'>Reference Company</InputLabel>
                      {renderCompanyLink(contact?.company)}
                    </div>
                  </div>
                  <div className='reference-row'>
                    <div className='reference-details'>
                      <InputLabel className='label'>Reference Status</InputLabel>
                      <ReferenceStatusSelection defaultValue={reference.status} className='value' label='' placeholder='Select' onChange={(e, data) => setValue('status', data)} />
                    </div>
                    <div className='reference-details'>
                      <InputLabel required={true} className='label'>
                        Source
                      </InputLabel>
                      <ReferenceSourceSelection defaultValue={reference.source} className='value' label='' placeholder='Select' onChange={(e, data) => setValue('source', data)} />
                    </div>
                  </div>
                  <div className='reference-row'>
                    <div className='reference-details'>
                      <InputLabel required={true} className='label'>
                        Reference Type
                      </InputLabel>
                      <ReferenceTypeSelection defaultValue={reference.type} className='value' label='' placeholder='select' onChange={(e, data) => setValue('type', data?.name)} />
                    </div>
                    <div className='reference-details'>
                      <InputLabel className='label'>Relationship</InputLabel>
                      <TextField {...register('relationship')} name='relationship' placeholder='Relationship' />
                    </div>
                  </div>
                  <div className='reference-row'>
                    <div className='reference-details'>
                      <InputLabel className='label'>Years known</InputLabel>
                      <TextField {...register('number')} type='number' name='years_known' placeholder='Years known' />
                    </div>
                    <div className='reference-details' />
                  </div>
                  <div className='mb-4'>
                    {renderRichText('General Comments', 'general_comments')}
                    {renderRichText('Skills and Attributes Comments', 'skill_comments')}
                    {renderRichText('Summary and Recommendation', 'recommendation')}
                  </div>
                </div>
                <div className='quick-add-footer'>
                  <Button variant='outlined' onClick={handleCancel} color='primary'>
                    Cancel
                  </Button>
                  <Button color='primary' className='ml-3' variant='contained' onClick={saveEdit}>
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </Popup>
    )
  );
}

EditReference.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  reference: PropTypes.object,
  contactId: PropTypes.string
};
