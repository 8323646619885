//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import CloseIcon from '@mui/icons-material/Close';
import { Button, Checkbox, Paper, Radio, RadioGroup, TableContainer } from '@mui/material';
import isEqual from 'lodash.isequal';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import Popup from 'reactjs-popup';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Loader from '../../components/common/Loader';
import ConfirmationPopup from '../../components/ConfirmationPopup';
import { capitalizeCaseKeys, getContactName, getContactValue, getOldValueOfContact, pdlContactFieldTitles, pdlContactKeys } from '../../components/ViewCompany/Contacts/utils';
import { /* contactDataApi, */ getGalaxyAndPdlContactDataApi, pdlContactMergeApi } from '../../services/ApiService';
import { ERROR, LINKEDIN_SCRAPER, SUCCESS } from '../../services/constantService';
import { successMessage, unableMessage } from '../../services/MessageService';
import { isEmpty } from '../../utils/common';
import { formatDate } from '../../utils/date';
import './pdl-contactmerge.scss';

export default function PdlContactsMergingPopup(props) {
  const { onClose, contacts } = props;
  // const [firstRecord, secondRecord] = contacts
  const [firstContact, setFirstContact] = useState({});
  const [secondContact, setSecondContact] = useState({});
  useEffect(() => {
    const [firstRecord] = contacts;
    if (firstRecord) {
      getGalaxyAndPdlContact(firstRecord.id);
    }
  }, [contacts]);

  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [conflictingFields, setConflictingFields] = useState([]);
  const [contact, setContact] = useState({});
  const [highLighted, setHighLighted] = useState(0);
  const [loading, setLoading] = useState(false);
  const [enriched, setEnriched] = useState([]);
  const [selectedFields, setSelectedFields] = useState(['job_history', 'education_details']);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [stripeCheck, setStripeCheck] = useState([]);

  useEffect(() => {
    const conflictingKeys = [];
    const mergedContact = {};
    pdlContactKeys.forEach(key => {
      const firstContactValue = getOldValueOfContact(firstContact, key, null, true, false, true)[key];
      const secondContactValue = getOldValueOfContact(secondContact, key, null, true, true)[key];
      if (isEmpty(firstContactValue) && key != 'job_history' && key != 'education_details') {
        mergedContact[key] = secondContactValue;
      } else if (isEmpty(secondContactValue) && key != 'job_history' && key != 'education_details') {
        mergedContact[key] = firstContactValue;
      } else if (!isEqual(firstContactValue, secondContactValue)) {
        if (key === 'job_history' || key === 'education_details') {
          mergedContact[key] = firstContactValue;
          conflictingKeys.push(key);
        } else if (key === 'first_name' || key === 'last_name' || key === 'current_job_title' || key === 'country' || key === 'current_company_id') {
          if (firstContactValue.toLowerCase() === secondContactValue.toLowerCase()) {
            mergedContact[key] = firstContactValue;
          } else {
            conflictingKeys.push(key);
          }
        } else {
          conflictingKeys.push(key);
        }
      } else {
        mergedContact[key] = firstContactValue;
      }
    });

    setConflictingFields(conflictingKeys);
    setContact(mergedContact);
  }, [secondContact]);

  const handleClose = merged => {
    setOpen(false);
    onClose && onClose(merged);
  };

  const handleCancel = () => {
    handleClose(false);
  };

  const getGalaxyAndPdlContact = async id => {
    setLoading(true);
    setShowTable(false);
    const data = await getGalaxyAndPdlContactDataApi(id);
    if (data.data.message) {
      setLoading(false);
      setShowTable(false);
    } else {
      setLoading(false);
      setShowTable(true);
      setFirstContact(data.data.galaxyContact);
      setSecondContact(data.data.pdlContact);
    }
  };

  const handleRadioChange = async (id, index) => {
    setHighLighted(index);
    setSelectedFields(['job_history', 'education_details']);
    setStripeCheck([]);
    await getGalaxyAndPdlContact(id);
  };

  const handleConfirmPopupClose = () => {
    setShowConfirmPopup(false);
  };

  const changeContactField = (field, value, isChecked = null) => {
    if (!selectedFields.includes(field)) {
      setSelectedFields([...selectedFields, field]);
    }
    setContact(prevState => {
      if (field === 'job_history' || field === 'education_details') {
        if (isChecked) {
          if (!prevState[field]) prevState[field] = [];
          if (value && !value.id) value['id'] = new Date().getTime();
          prevState[field].push(value);
        } else {
          const index = prevState[field].findIndex(item => item.id === value.id);
          if (index !== -1) {
            prevState[field].splice(index, 1);
          }
        }
        return prevState;
      } else {
        prevState[field] = value;
        return prevState;
      }
    });
  };

  const handleMerge = () => {
    const merge = enriched?.filter(ele => {
      return ele.data.id === contact.id;
    });
    if (Object.keys(contact)?.length !== pdlContactKeys?.length + 1) {
      let count = 0;
      if (!firstContact.job_history?.length && !secondContact.job_history?.length) {
        count++;
      }
      if (!firstContact.education_details?.length && !secondContact.education_details?.length) {
        count++;
      }
      if (conflictingFields?.length !== selectedFields?.length - count - 1) {
        const message = 'All of the fields are not selected.';
        enqueueSnackbar(message, { variant: ERROR });
        return;
      } else if (merge?.length > 0) {
        const message = 'Record has already been enriched.';
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    } else if (merge?.length > 0) {
      const message = 'Record has already been enriched.';
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    setShowConfirmPopup(true);
  };

  const mergeContacts = async () => {
    setLoading(true);
    const title = contact.current_company_id;
    if (title.includes(firstContact?.company?.name)) {
      contact.current_company_id = firstContact.company.id;
    }
    if (contact.data_fetching_status) {
      contact.data_fetching_status = LINKEDIN_SCRAPER.COMPLETED;
    }
    const payload = { data: contact };
    const { status, data } = await pdlContactMergeApi(payload);
    if (status === 200) {
      const matchedIndex = contacts.findIndex(ele => contact.id === ele.id);
      contacts[matchedIndex] = {
        ...contacts[matchedIndex],
        pdlStatus: [{ merged: true }]
      };
      setEnriched([...enriched, payload]);
      const message = successMessage('Contact', 'enriched');
      enqueueSnackbar(message, { variant: SUCCESS });
      setLoading(false);
    } else {
      const message = unableMessage('contact', 'enriched');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
      setLoading(false);
    }
  };

  const handleConfirmPopup = async () => {
    try {
      handleConfirmPopupClose();
      await mergeContacts();
    } catch (e) {
      console.log('Error found in handleConfirmPopup::', e);
    }
  };

  const changeJobAndEducation = (e, field, index, type) => {
    const { checked } = e.target;
    if (type === 'FIRST') {
      changeContactField(field, getOldValueOfContact(firstContact, field, index, true, false)[field], checked);
      if (!checked) {
        setStripeCheck([...stripeCheck, { value: `${field}-${type}-${index + 1}`, checked: checked }]);
      } else {
        let details = [...stripeCheck];
        const pos = details.findIndex(elem => elem.value === `${field}-${type}-${index + 1}`);
        details.splice(pos, 1);
        setStripeCheck(details);
      }
    } else {
      changeContactField(field, getOldValueOfContact(secondContact, field, index, true, true)[field], checked);
    }
    /* changeContactField(field,
      isEqual(value, firstValue) ?
        getOldValueOfContact(firstContact, field, index)[field] :
        getOldValueOfContact(secondContact, field, index)[field]
    ) */
  };

  const createArrayForListValue = (field, value, type) => {
    let array = [];
    const data = [field === 'education_details' || field === 'job_history' ? value && value.split('<ul>') : null];
    if (data && data?.length > 0 && data[0]) {
      data[0].map(data => {
        const object = {
          value: data,
          checked: type === 'FIRST' ? true : false
        };
        array.push(object);
      });
      return array;
    }
  };

  const checkDisableSelection = item => {
    return item.value?.match('disabledDetails=true') || false;
  };

  const renderCell = (field, value, showRadio, type) => {
    let data = createArrayForListValue(field, value, type);

    return (
      <div
        className={` ${field === 'job_history' || field === 'education_details' ? 'flex-column' : ''} table-cell d-flex align-items-start`}
        style={capitalizeCaseKeys.includes(field) ? { textTransform: 'capitalize' } : {}}
      >
        {!(field === 'job_history' || field === 'education_details' || field === 'pdl_emails') && showRadio && value && <Radio value={value} size='small' />}
        {field === 'job_history' || field === 'education_details' ? (
          data &&
          data != null &&
          data?.length > 0 &&
          data.map((item, index) => {
            return item.value ? (
              <span key={index} className='d-flex align-items-start'>
                <Checkbox
                  defaultChecked={item.checked}
                  value={index}
                  data-index-name={`${field}-${type}-${index}`}
                  onChange={(e, checked) => {
                    data[index]['checked'] = checked;
                    changeJobAndEducation(e, field, index - 1, type);
                  }}
                  disabled={checkDisableSelection(item)}
                />
                {data ? (
                  <span
                    key={index}
                    style={{
                      textDecoration: type == 'FIRST' && stripeCheck.some(data => data.value === `${field}-${type}-${index}` && data.checked === false) ? 'line-through' : 'none'
                    }}
                  >
                    <ReactQuill key={index} value={item.value} readOnly={true} theme='bubble' />
                  </span>
                ) : (
                  ''
                )}
              </span>
            ) : (
              ''
            );
          })
        ) : field === 'pdl_emails' ? (
          <ReactQuill value={value} readOnly={true} theme='bubble' />
        ) : (
          field !== 'id' && value
        )}
      </div>
    );
  };

  const renderRow = (name, firstValue, secondValue, field, key, showRadio) => {
    if (field === 'id') {
      showRadio = true;
      firstValue = 'id1';
      secondValue = 'id2';
    }
    return (
      <RadioGroup
        row
        onChange={e => {
          const { value } = e.target;
          changeContactField(
            field,
            isEqual(value, firstValue) ? getOldValueOfContact(firstContact, field, null, true, false)[field] : getOldValueOfContact(secondContact, field, null, true, true)[field]
          );
        }}
        name={field}
        key={key}
      >
        <div className='d-flex flex-grow-1 table-header'>
          <div className={'text-right table-cell table-cell-header w-25 ' + (showRadio && 'highlight')} dangerouslySetInnerHTML={{ __html: name }}></div>
          {renderCell(field, firstValue, showRadio, 'FIRST', true)}
          {renderCell(field, secondValue, showRadio, 'SECOND')}
        </div>
      </RadioGroup>
    );
  };

  return (
    <Popup open={open} modal closeOnEscape={false} closeOnDocumentClick={false}>
      <div className='merge-pdlcontact'>
        <Loader show={loading} />
        {showConfirmPopup && (
          <ConfirmationPopup
            header='Are you sure you want to enrich this contact? This process cannot be undone.'
            onConfirm={handleConfirmPopup}
            onClose={handleConfirmPopupClose}
            cancelText={'Cancel'}
            confirmText={'Enrich'}
          />
        )}
        <div className='header-section '>
          <span className='view-company-header'>Enrich Contacts</span>
          <CloseIcon onClick={handleCancel} cursor='pointer' />
        </div>
        <div className='view-container'>
          <div className='table-header sidebar-width'>
            <div>
              <div className='table-cell popup-header'>List of contacts</div>
            </div>
            <div>
              {contacts.map((item, index) => (
                <div className='table-header' key={item.id}>
                  <div className={'table-cell click ' + (index === highLighted ? ' active ' : '')}>
                    <span
                      onClick={function () {
                        handleRadioChange(item.id, index);
                      }}
                      style={{ color: item?.pdlStatus[0]?.merged ? 'green' : '' }}
                    >
                      {getContactName(item)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {!showTable && (
            <div className='message-container'>
              <div className='center'>Record Not Found In PDL</div>
            </div>
          )}
          {showTable && (
            <TableContainer component={Paper} className='table-container table-width enrich-contact-popup'>
              {firstContact.pdlStatus && firstContact.pdlStatus[0].merged && (
                <div className='d-flex flex-grow-1 table-header'>
                  <div className='text-right table-cell popup-header w-25'>Last Enriched Date</div>
                  <div className='table-cell w-75'>{formatDate(firstContact.pdlStatus[0].updated_at)}</div>
                </div>
              )}
              <div className='d-flex flex-grow-1 table-header'>
                <div className='table-cell w-25'></div>
                <div className='table-cell popup-header'>Galaxy</div>
                <div className='table-cell popup-header'>PDL</div>
              </div>
              <div>
                {renderRow('Master Record', '', '', 'id')}
                {conflictingFields.map((field, index) =>
                  renderRow(pdlContactFieldTitles[field], getContactValue(firstContact, field, true, false), getContactValue(secondContact, field, true, true), field, index, true)
                )}
                {pdlContactKeys
                  ?.filter(key => key !== 'data_fetching_status')
                  ?.filter(key => !conflictingFields.includes(key))
                  .map((field, index) => renderRow(pdlContactFieldTitles[field], getContactValue(firstContact, field, true, false), getContactValue(secondContact, field, true, true), field, index))}
              </div>
            </TableContainer>
          )}
        </div>
        <div className='actions-container'>
          <Button color='primary' onClick={handleCancel}>
            Close
          </Button>
          <Button className='merge-button' variant='contained' color='primary' onClick={handleMerge} disabled={!showTable}>
            Enrich
          </Button>
        </div>
      </div>
    </Popup>
  );
}

PdlContactsMergingPopup.propTypes = {
  onClose: PropTypes.func,
  contacts: PropTypes.array
};
