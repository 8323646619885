import React, { useEffect, useState } from 'react';
import CustomDropdown from '../../CustomDropdown';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchPickLists } from '../../../../actions';

const CertificationTypeSelection = props => {
  const { onChange = () => {}, isDrawer = false, label = '', defaultValue, isApplyWidth = false } = props;
  const [options, setOptions] = useState([]);
  const certification_type = useSelector(state => state.commonReducer.certificationType);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!certification_type) {
      dispatch(fetchPickLists('CERTIFICATION_TYPE', 'certificationType'));
    } else {
      setOptions(certification_type);
    }
  }, [certification_type, dispatch]);

  return (
    <>
      <CustomDropdown options={options} onChange={onChange} isDrawer={isDrawer} value={defaultValue} label={label} defaultValue={defaultValue} viewAddress={isApplyWidth} />
    </>
  );
};
CertificationTypeSelection.propTypes = {
  onChange: PropTypes.func,
  isDrawer: PropTypes.bool,
  value: PropTypes.object,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  isApplyWidth: PropTypes.bool
};
export default CertificationTypeSelection;
