import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import Tooltip from '@mui/material/Tooltip';
import PapaParse from 'papaparse';
import PropTypes from 'prop-types';
import React from 'react';
import CustomButton from '../components/common/CustomButton';
// import IosShareIcon from '@mui/icons-material/IosShare';

// data :- which need to be in csv file
// fileName :- name of the csv file
// columns :- columns which are going to be in csv file
// arrayKeyMapping :- if data have array fields

const ExportCSV = ({ iconHeight = 20, iconWidth = 20, buttonSize = '', data = [], fileName = 'Data.csv', columns = [], arrayKeyMapping = {} }) => {
  //transform data for csv
  // console.log('data in scv', data);

  function flattenData(row, columns) {
    let flatRow = {};

    columns.forEach(({ key, label }) => {
      const keys = key.split('.'); // Support nested keys
      let value = row;

      // Traverse nested objects
      for (let k of keys) {
        if (Array.isArray(value)) {
          const arrayMapping = arrayKeyMapping[key];

          // If the array contains objects and mapping key exists, extract values using that mapping
          if (arrayMapping && typeof value[0] === 'object') {
            value = value.map(item => item[arrayMapping] || '').join(', ');
          } else {
            // Join array elements (for arrays of strings or other primitives)
            value = value.join(', ');
          }
          break;
        } else if (value && typeof value === 'object') {
          value = value[k];
        } else {
          value = null; // If key is not in object
          break;
        }
      }

      flatRow[label] = value !== null && value !== undefined ? value : '';
    });

    return flatRow;
  }

  //create and download csv
  const exportContactCSV = () => {
    //if data or column does not exist don't proceed
    if (!data.length || !columns.length) return;

    const transformedData = data.map(row => flattenData(row, columns));
    const csvContent = PapaParse.unparse(transformedData, {
      header: true
    });

    const blob = new Blob([csvContent], { type: 'text/csv' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Tooltip placement='top' arrow title={'Export CSV'}>
        <CustomButton onClick={exportContactCSV} type={'primary'} size={buttonSize} variant='contained' iconLeft={<SystemUpdateAltIcon sx={{ width: iconWidth, height: iconHeight }} />} />
      </Tooltip>
    </>
  );
};
ExportCSV.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  fileName: PropTypes.string,
  arrayKeyMapping: PropTypes.object,
  buttonSize: PropTypes.string,
  iconHeight: PropTypes.number,
  iconWidth: PropTypes.number
};
export default ExportCSV;
