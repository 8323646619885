//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { cleanupCompanyDetails } from '../../AddContact/utils';
import { DELETE, PUT, WARNING, SUCCESS, ERROR, GET } from '../../../services/constantService';
import { acquireLockApi, contactDataApi, releaseLockApi, searchDataApi } from '../../../services/ApiService';
import { successMessage, unableMessage, validateMessage } from '../../../services/MessageService';
import { showSnackbar } from '../../../Containers/Commons/Utils';

export const renderDetails = (details, props) => {
  const { setHoverIndex, renderSubHeader, editIndex, renderEdit, renderView } = props;
  if (details?.length === 0) {
    return <div className='d-flex contact-details-row no-data-available'>--</div>;
  }
  return details.map((item, index) => {
    return (
      <div
        key={index}
        onMouseEnter={() => {
          setHoverIndex(index);
        }}
        onMouseLeave={() => {
          setHoverIndex(-1);
        }}
      >
        {renderSubHeader(index)}
        {editIndex === index ? renderEdit(item, index) : renderView(item)}
      </div>
    );
  });
};

export const renderEditIcon = (
  index,
  editIndex,
  contactId,
  hoverIndex,
  setEditIndex,
  enqueueSnackbar,
  closeSnackbar,
  setEmploymentDetails,
  sortByChronologicalOrder,
  searchId,
  setSearch,
  setIsLoading /* setUpdatelLoader */
) => {
  return (
    !(editIndex === index) && (
      <EditIcon
        className='cursor-pointer editable'
        onClick={async () => {
          if (contactId && contactId !== '') {
            let { status, data } = await acquireLockApi(contactId);
            if (status && status === 200 && data?.message && data?.user === false) {
              //if (data.isAdmin) {
              const object = {
                enqueueSnackbar: enqueueSnackbar,
                closeSnackbar: closeSnackbar,
                message: data.message,
                id: contactId
              };
              showSnackbar(object);
              /* }
            else {
              enqueueSnackbar(data.message, { variant: WARNING });
            } */
            } else {
              setIsLoading(true);
              const { status, data } = await contactDataApi(GET, contactId);
              if (status && status == 200) {
                setEmploymentDetails(sortByChronologicalOrder(JSON.parse(JSON.stringify(data.job_history))));
              }
              setIsLoading(false);
              setEditIndex(index);
            }
          } else {
            if (searchId) {
              const { status, data } = await searchDataApi(GET, searchId);
              if (status && status == 200) {
                setSearch(data);
              }
            }

            setEditIndex(index);
          }
        }}
        fontSize='inherit'
        color='secondary'
      />
    )
  );
};

export const renderActions = (index, params, enqueueSnackbar, closeSnackbar) => {
  const {
    isAdding,
    contactId,
    editIndex,
    deleteEmployment,
    handleUpdate,
    handleCancel,
    hoverIndex,
    setEditIndex,
    setEmploymentDetails,
    sortByChronologicalOrder,
    searchId,
    setSearch,
    setIsLoading,
    setUpdatelLoader
  } = params;
  return editIndex === index ? (
    <div className='ml-2 mb-1' style={{ fontSize: 'inherit' }}>
      {!isAdding && (
        <DeleteIcon
          fontSize='inherit'
          cursor='pointer'
          onClick={async () => {
            await deleteEmployment(index);
          }}
        />
      )}
      <DoneIcon fontSize='inherit' cursor='pointer' color='primary' onClick={() => handleUpdate(index)} />
      <CloseIcon fontSize='inherit' cursor='pointer' onClick={() => handleCancel(index)} />
    </div>
  ) : (
    renderEditIcon(
      index,
      editIndex,
      contactId,
      hoverIndex,
      setEditIndex,
      enqueueSnackbar,
      closeSnackbar,
      setEmploymentDetails,
      sortByChronologicalOrder,
      searchId,
      setSearch,
      setIsLoading,
      setUpdatelLoader
    )
  );
};

export const deleteRecord = async (index, params) => {
  const { contactId, details, enqueueSnackbar, removeEmployment, field, setEditIndex } = params;
  const { status, data } = await contactDataApi(DELETE, contactId, { id: details[index].id }, field);
  if (status === 200) {
    const message = successMessage('', 'Deleted');
    enqueueSnackbar(message, { variant: SUCCESS });
    removeEmployment(index);
    setEditIndex(-1);
  } else {
    releaseLock(contactId);
    const message = unableMessage('', 'Delete');
    enqueueSnackbar(data?.message || message, { variant: ERROR });
  }
};

export const updateScreeningNotes = async (data, currentValues, userdata) => {
  let newNotes;
  let div = document.createElement('div');
  div.innerHTML = data.screening_notes;
  let HTMLArray = Array.from(div.querySelectorAll('p')).map(p => p.outerHTML);
  const user_initial_timestamp = [];
  if (HTMLArray && (HTMLArray?.length === currentValues.html_array?.length || HTMLArray?.length >= currentValues.html_array?.length)) {
    HTMLArray.forEach((item, index) => {
      const firstindex = item.indexOf('<strong>');
      const lastindex = item.indexOf('</strong>');
      item = item.replace(`${item.slice(firstindex, lastindex)}</strong>`, '');
      newNotes = (newNotes ? newNotes : '') + item;
      if (currentValues?.user_initial_timestamp[index]) {
        if (item !== '<p><br></p>') {
          if (item === currentValues.html_array[index].content) {
            const data = {
              id: currentValues?.user_initial_timestamp[index].id,
              updated_by: currentValues?.user_initial_timestamp[index].updated_by,
              updated_field: 'screening_notes',
              created_at: currentValues?.user_initial_timestamp[index].created_at,
              updated_at: currentValues?.user_initial_timestamp[index].updated_at,
              user_updated_at: currentValues?.user_initial_timestamp[index].user_updated_at,
              position: index
            };
            user_initial_timestamp.push(data);
          } else {
            const data = {
              updated_by: userdata.id,
              updated_field: 'screening_notes',
              created_at: new Date(),
              updated_at: new Date(),
              user_updated_at: new Date(),
              position: index
            };
            user_initial_timestamp.push(data);
          }
        }
      } else {
        if (item !== '<p><br></p>') {
          const data = {
            updated_by: userdata.id,
            updated_field: 'screening_notes',
            created_at: new Date(),
            updated_at: new Date(),
            user_updated_at: new Date(),
            position: index
          };
          user_initial_timestamp.push(data);
        }
      }
    });
  } else {
    for (let [i, item] of HTMLArray.entries()) {
      const firstindex = item.indexOf('<strong>');
      const lastindex = item.indexOf('</strong>');
      item = item.replace(`${item.slice(firstindex, lastindex)}</strong>`, '');
      newNotes = (newNotes ? newNotes : '') + item;
      if (currentValues.html_array) {
        for (let [index, element] of currentValues.html_array.entries()) {
          if (item === element.content) {
            if (currentValues?.user_initial_timestamp[index]) {
              const data = {
                id: currentValues?.user_initial_timestamp[index].id,
                updated_by: currentValues?.user_initial_timestamp[index].updated_by,
                updated_field: 'screening_notes',
                created_at: currentValues?.user_initial_timestamp[index].created_at,
                updated_at: currentValues?.user_initial_timestamp[index].updated_at,
                user_updated_at: currentValues?.user_initial_timestamp[index].user_updated_at,
                position: i
              };
              user_initial_timestamp.push(data);
            }
            break;
          }
        }
      }
    }
  }
  if (newNotes) {
    return {
      newNotes: newNotes,
      user_initial_timestamp: user_initial_timestamp
    };
  }
};

export const createRecord = (isAdding, setEditIndex, details, add, setIsAdding, enqueueSnackbar) => {
  if (isAdding) {
    const message = validateMessage('add', 'complete');
    enqueueSnackbar(message, { variant: WARNING });
    return;
  }
  setEditIndex(details?.length);
  add();
  setIsAdding(true);
};

export const updateRecord = async (index, params) => {
  const { formData, contactId, enqueueSnackbar, isAdding, handleChange, setIsAdding, setEditIndex, field, successMessage, errorMessage } = params;
  const details = cleanupCompanyDetails(formData);
  console.log(details);
  const { status, data } = await contactDataApi(PUT, contactId, details[index], field);
  if (status === 200) {
    enqueueSnackbar(successMessage, { variant: SUCCESS });
    if (isAdding) {
      handleChange(index, 'id', data.id, true);
      setIsAdding(false);
    }
  } else {
    enqueueSnackbar(data?.message || errorMessage, { variant: ERROR });
  }
  releaseLock(contactId);
  setEditIndex(-1);
};
const releaseLock = async id => {
  await releaseLockApi(id);
};
