//-----------------------------------------------------------// In-built Imports // ------------------------------
import React, { useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------
import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------
import CustomDropdown from '../../CustomDropdown';

const TagSelection = props => {
  const { label, defaultValue, commonValue, options, setSelectedTag, setValue } = props;
  const [values, setValues] = useState({});
  const [option, setOption] = useState(false);

  const handleChange = (e, item) => {
    setOption(true);
    setValues(item);
    setSelectedTag(item);
    setValue('tags', item);
  };

  return (
    <CustomDropdown
      className='tag-auto-complete'
      options={options}
      isCheckBox={true}
      label={label}
      disableCloseOnSelect
      commonValue={commonValue}
      value={option ? values : defaultValue}
      onChange={handleChange}
      multiple
    />
  );
};

TagSelection.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.object,
  commonValue: PropTypes.object,
  options: PropTypes.array,
  setSelectedTag: PropTypes.func,
  setValue: PropTypes.func
};
export default TagSelection;
