//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../../common/InputField';
import YearSelection from '../../../YearSelection';
import CompanySelection from '../../../AddContact/Components/CompanySelection';

export default function BoardDetails(props) {
  const { register, setValue, currentValues, readOnly } = props;
  const [boardDetails, setBoardDetails] = useState([]);
  const [startYear, setStartYear] = useState('');

  useEffect(() => {
    register('board_details');
    setBoardDetails(currentValues.board_details);
  }, [currentValues, register]);

  useEffect(() => {
    setValue('board_details', boardDetails);
  }, [boardDetails, setValue]);

  const handleChange = (index, field, value) => {
    const currentBoardDetails = [...boardDetails];
    currentBoardDetails[index][field] = value;
    if (currentBoardDetails[index]['company'] === null) {
      currentBoardDetails[index]['company_id'] = null;
    }
    setBoardDetails(currentBoardDetails);
    setValue(`board_details[${index}].${field}`, value, { shouldDirty: true });
  };

  const addBoardCompany = () => {
    setBoardDetails(prevState => [...prevState, {}]);
  };

  const removeBoardCompany = index => {
    let details = [...boardDetails];
    details.splice(index, 1);
    setBoardDetails(details);
  };

  return (
    <React.Fragment>
      <Typography className='section-label'>Board Details</Typography>
      <div className='section-header d-flex'>
        <Typography className='name-field header-label'>Previous Company</Typography>
        <Typography className='name-field header-label'>Job Title</Typography>
        <Typography className='field header-label'>Start Year</Typography>
        <Typography className='field header-label'>End Year</Typography>
        <Typography className='field header-label'>Committee</Typography>
        <div style={{ width: 24 }}></div>
      </div>
      {boardDetails.map((item, index) => {
        return (
          <div key={index} className='section d-flex'>
            <CompanySelection
              startAdornment={
                <Typography style={{ padding: '3.5px 5px' }} color='primary'>
                  {index + 1}.
                </Typography>
              }
              defaultValue={item.company}
              onChange={(e, company) => {
                handleChange(index, 'company', company);
              }}
              className='name-field'
              label=''
              placeholder=''
              InputLabelProps={{ focused: true }}
            />
            <InputField
              value={item.title || ''}
              className='name-field'
              InputLabelProps={{ focused: true }}
              onChange={e => {
                handleChange(index, 'title', e.target.value);
              }}
            />
            <YearSelection
              className='field'
              value={item.start_date}
              onChange={(e, data) => {
                setStartYear(data);
                handleChange(index, 'start_date', data);
              }}
              label=''
              placeholder=''
              InputLabelProps={{ focused: true }}
              yearType='start'
            />
            <YearSelection
              className='field'
              value={item.end_date}
              onChange={(e, data) => {
                handleChange(index, 'end_date', data);
              }}
              label=''
              placeholder=''
              InputLabelProps={{ focused: true }}
              yearType='end'
              selectedStartYear={startYear}
            />
            <InputField
              value={item.committee || ''}
              className='field'
              label=''
              onChange={e => {
                handleChange(index, 'committee', e.target.value);
              }}
            />
            <span className='align-self-center'>
              <CloseIcon cursor='pointer' fontSize='small' onClick={() => removeBoardCompany(index)} />
            </span>
          </div>
        );
      })}
      <Button disabled={readOnly} variant={'text'} startIcon={<AddCircleIcon />} style={{ marginLeft: 15 }} onClick={addBoardCompany} color='primary'>
        Add Board Details
      </Button>
    </React.Fragment>
  );
}

BoardDetails.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  watch: PropTypes.func,
  getValues: PropTypes.func,
  readOnly: PropTypes.bool
};
