import PropTypes from 'prop-types';
import React from 'react';

const CustomOptions = ({ value, label, checked, onChange }) => (
  <label className='flex items-center space-x-2 cursor-pointer'>
    <input type='checkbox' value={value} checked={checked} onChange={onChange} className='form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out' />
    <span className='text-gray-700'>{label}</span>
  </label>
);

CustomOptions.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CustomOptions;
