export const UPDATE_USER_DETAILS = 'UPDATE_USER';

export const UPDATE_PICK_LIST = 'UPDATE_PICK_LIST';

export const UPDATE_TOKEN = 'UPDATE_TOKEN';

export const UPDATE_USER_LIST = 'UPDATE_USER_LIST';

export const GET_CLIENT_LIST = 'GET_CLIENT_LIST';

export const GET_KGP_TEAM_LIST = 'GET_KGP_TEAM_LIST';

export const UPDATE_ATTACHMENTS_LIST = 'UPDATE_ATTACHMENTS_LIST';

export const UPDATE_LOES_LIST = 'UPDATE_LOES_LIST';

export const UPDATE_TIMEZONE_DATA = 'UPDATE_TIMEZONE_DATA';

export const UPDATE_SEARCHDATA = 'UPDATE_SEARCHDATA';

export const UPDATE_SFPADATA = 'UPDATE_SFPADATA';

export const UPDATE_CANDIDATE_LIST = 'UPDATE_CANDIDATE_LIST';

export const CONTACT_ATHENA = 'CONTACT_ATHENA';

export const CONTACT_ATTRIBUTE = 'CONTACT_ATTRIBUTE';

export const UPDATE_STATE_LIST = 'UPDATE_STATE_LIST';

export const UPDATE_TRACK_COMMENTS = 'UPDATE_TRACK_COMMENTS';

export const UPDATE_ARENA_FEEDBACK = 'UPDATE_ARENA_FEEDBACK';

export const UPDATE_CLIENTSUITE_FEEDBACK = 'UPDATE_CLIENTSUITE_FEEDBACK';

export const UPDATE_CANDIDATESUITE_FEEDBACK = 'UPDATE_CANDIDATESUITE_FEEDBACK';

export const RELOAD_ALL_CANDIDATE_GRID = 'RELOAD_ALL_CANDIDATE_GRID';

export const RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE = 'RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE';

export const UPDATE_SOCKET = 'UPDATE_SOCKET';

export const LINKEDIN_SCRAPER_COMPLETED1 = 'linkedinScraperCompleted';

export const REFRESH_CONTACTS = 'REFRESH_CONTACTS';

export const REFRESH_SEARCH = 'REFRESH_SEARCH';

export const REFRESH_BDLIST = 'REFRESH_BDLIST';
