//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import PartnerInformation from './Components/PartnerInformation';
import RecruiterInformation from './Components/RecruiterInformation';
import ResearcherInformation from './Components/ResearcherInformation';
import AdminInformation from './Components/AdminInformation';

export default function KGPTeamInfo(props) {
  const { register, control, setValue, watch, currentValues, isEditing = true, option, bd, types, currentTabIndex } = props;
  const recruiterMessage =
    watch('brand') === (watch('brand') === 'Advisory' ? 'Advisory' : 'Advisory')
      ? '<span><b>Note:</b>  Recruiter Information section is not available for ‘Advisory’ Service Offering.<br/> You may consider selecting another ‘Service Offering’ in the Basic Information page ,if Recruiter Information is required.</span>'
      : null;
  const researcherMessage =
    watch('brand') === (watch('brand') === 'Advisory' ? 'Advisory' : 'Advisory')
      ? '<span><b>Note:</b> Researcher Information section is not available for ‘Advisory’ Service Offering.<br/> You may consider selecting another ‘Service Offering’ in the Basic Information page if Researcher Information is required.</span>'
      : null;
  return (
    <div className='input-form tab-content d-flex'>
      <div className='tab-view-content'>
        <div className='section-content'>
          <Typography className='section-label'>Team Information</Typography>
          <div className='team-information-table d-flex flex-column'>
            {/* <div className="d-flex align-items-center table-main-header">
              <div className="team-info-field name-field">Name</div>
              <div className="team-info-field">Origination Credit</div>
              <div className="team-info-field">Selling Credit</div>
              <div className="team-info-field">Execution</div>
              <div className="team-info-field">Total Credit</div>
              <div className="close-action"></div>
            </div>  */}
            <PartnerInformation
              option={option}
              register={register}
              control={control}
              setValue={setValue}
              watch={watch}
              isEditing={isEditing}
              currentValues={currentValues}
              bd={bd}
              types={types}
              currentTabIndex={currentTabIndex}
            />
            <RecruiterInformation
              option={option}
              register={register}
              control={control}
              setValue={setValue}
              watch={watch}
              isEditing={isEditing}
              currentValues={currentValues}
              message={recruiterMessage}
            />
            <ResearcherInformation
              option={option}
              register={register}
              control={control}
              setValue={setValue}
              watch={watch}
              isEditing={isEditing}
              currentValues={currentValues}
              message={researcherMessage}
            />
            <AdminInformation option={option} register={register} control={control} setValue={setValue} watch={watch} isEditing={isEditing} currentValues={currentValues} />
          </div>
        </div>
      </div>
    </div>
  );
}

KGPTeamInfo.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  option: PropTypes.array,
  bd: PropTypes.string,
  types: PropTypes.string,
  currentTabIndex: PropTypes.number
};
