//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchPickList } from '../../../actions';

function DepartmentSelection(props) {
  const { className = 'w-100', label = 'Department', placeholder = 'Department', InputLabelProps, ...rest } = props;
  const [options, setOptions] = useState([]);
  const departments = useSelector(state => state.commonReducer.departments);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!departments) {
      dispatch(fetchPickList('departments'));
    } else {
      setOptions(departments);
    }
  }, [departments, dispatch]);

  return (
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => option.name === value.name}
      renderInput={params => <TextField {...params} InputLabelProps={InputLabelProps} label={label} placeholder={placeholder} />}
    />
  );
}

DepartmentSelection.propTypes = {
  register: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  enqueueSnackbar: PropTypes.func
};

export default DepartmentSelection;
