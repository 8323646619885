import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTagData } from '../../../actions';
import CustomPopup from '../../../components/common/CustomPopup';
import TagSelection from '../../../components/common/FunctionalComponents/TagSelection';
import Loader from '../../../components/common/Loader';
import { projectTagsApi } from '../../../services/ApiService';
import { ERROR, PATCH, SUCCESS } from '../../../services/constantService';
import { useCustomMessageHook } from '../../../utils/Hooks/useCustomMessageHook';

const AssignTag = props => {
  const { setIsPopupOpen, isPopupOpen, tagPopUp, label, projectId = null, setTagChange = () => {}, getHeaderDetails = () => {}, fetchProjectData } = props;
  const { register, unregister, setValue } = useForm({});
  const [commonValue, setCommonValue] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const moduleTag = useSelector(state => state.commonReducer.projectsTag);
  const { translateMessage } = useCustomMessageHook();
  useEffect(() => {
    register('tags');
    return () => {
      unregister('tags');
    };
  }, [register, unregister]);

  useEffect(() => {
    if (!moduleTag) {
      dispatch(fetchTagData('', 'projectsTag', '?module=projects&name='));
    } else {
      setOptions(moduleTag);
    }
  }, [moduleTag, dispatch]);

  useEffect(() => {
    let objectValues;
    if (tagPopUp) {
      objectValues = options && options.map(item => item.name);
      const commonValues = tagPopUp.filter(value => objectValues.includes(value));
      setCommonValue(commonValues);
    }
  }, [tagPopUp]);

  const getValueOfSelected = () => {
    let array = [];
    for (let x of tagPopUp) {
      const index = options && options.findIndex(obj => obj?.name === x?.tag?.name);
      array.push(index);
    }
    let value = [];
    for (let y of array) {
      value.push(options[y]);
    }
    return value;
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const getPayload = () => {
    let payload;
    const requestPayloadArray = [];

    selectedTag.map(item => {
      payload = {
        tag_id: item.id
      };
      requestPayloadArray.push(payload);
    });
    return requestPayloadArray;
  };

  const handleSubmit = async () => {
    try {
      let payload;
      let status;

      payload = { tags: getPayload() };
      setLoading(true);

      const response = await projectTagsApi(PATCH, payload, { projectId }, '/bulk-update');
      status = response.status;

      if (status === 200) {
        setTagChange(true);
        getHeaderDetails();
        const message = translateMessage('Successfully', false, 'Tag', 'Assigned');
        fetchProjectData();
        enqueueSnackbar(message, { variant: SUCCESS });
      } else {
        const message = translateMessage('UnableMessage', false, 'Assign Tag', '');

        enqueueSnackbar(message, { variant: ERROR });
      }
      setLoading(false);
      setIsPopupOpen(false);
    } catch (e) {
      console.log('Error found in handleSubmit::', e);
    }
  };

  return (
    <div>
      <CustomPopup dropdown={true} title={'Assign Tag'} open={isPopupOpen} onClose={handleClose} showAction={true} handleSubmit={handleSubmit}>
        <Loader show={loading} className='h-75' />
        <Box id='assign-tag'>
          <Box className='tag-dropdown p-4'>
            <TagSelection
              {...register('tags')}
              className='tag-auto-complete'
              defaultValue={tagPopUp && getValueOfSelected()}
              commonValue={tagPopUp ? commonValue : null}
              label={label}
              options={options}
              setSelectedTag={setSelectedTag}
              setValue={setValue}
            />
          </Box>
        </Box>
      </CustomPopup>
    </div>
  );
};
AssignTag.propTypes = {
  title: PropTypes.string,
  setIsPopupOpen: PropTypes.func,
  isPopupOpen: PropTypes.bool,
  tagPopUp: PropTypes.array,
  selectedRows: PropTypes.array,
  tags: PropTypes.array,
  label: PropTypes.string,
  projectId: PropTypes.string,
  setTagChange: PropTypes.func,
  getHeaderDetails: PropTypes.func,
  fetchProjectData: PropTypes.func
};

export default AssignTag;
