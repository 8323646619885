//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import UserSelection from '../../UserSelection';
// import { isEmpty } from "../../../utils/common";

export default function UsersSelection(props) {
  const { users, register, setValue } = props;

  useEffect(() => {
    setValue('users', users);
  }, [users]);

  return (
    <UserSelection
      name='users'
      option={[]}
      {...register('users')}
      defaultValue={users}
      multiple
      openOnFocus
      size='small'
      label={
        <label>
          Users<span className='MuiInputLabel-asterisk'>*</span>
        </label>
      }
      className='input'
      // inputRef={usersRef}
      filterSelectedOptions={true}
      InputLabelProps={{ focused: true }}
      onChange={(e, data) => {
        setValue('users', data);
      }}
    />
  );
}

UsersSelection.propTypes = {
  control: PropTypes.object,
  users: PropTypes.array,
  register: PropTypes.func,
  setValue: PropTypes.func
};
