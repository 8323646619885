//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useMemo } from 'react';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import RecordTypeSelection from '../FunctionalComponents/RecordType/RecordType';
import CustomInputField from '../StyledComponents/CustomInputField';
//----------------------------------------------// External Imports // -------------------------------------------------
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import SourceSelection from '../FunctionalComponents/SourceSelection';
import ResumeCompanySelection from './ResumeCompanySelection';

const ProfileDetails = props => {
  const { setValue = () => {}, data, onBlurLinkedInUrl, companyList } = props;

  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_BASIC_INFO,
    TRANS_KEYS.CONTACTS_RECORD_TYPE,
    TRANS_KEYS.CONTACTS_SOURCE,
    TRANS_KEYS.CONTACTS_FIRST_NAME,
    TRANS_KEYS.CONTACTS_LAST_NAME,
    TRANS_KEYS.CONTACTS_EMAIL,
    TRANS_KEYS.CONTACTS_LINKEDIN_URL,
    TRANS_KEYS.CONTACTS_PROFESSIONAL_INFO,
    TRANS_KEYS.CONTACTS_COMPANY_NAME,
    TRANS_KEYS.CONTACTS_JOB_TITLE
  ]);

  const handleRecordTypeChange = data => {
    setValue('contact_record_types', data, { shouldDirty: true });
  };

  const currentCompanyName = useMemo(() => {
    let name = '';
    const experiences = data['contact_work_experience'] || [];
    if (experiences.length > 0) {
      const currentCompany = experiences[0];
      name = currentCompany?.company?.name ? currentCompany.company.name : currentCompany.company;
    }
    return name;
  }, [data['contact_work_experience']]);

  // const handleEmail = e => {
  //   const data = getSpecificValue('contact_emails', 'primary', true);
  //   setSpecificValue('contact_emails', data.id, {
  //     email: e.target.value || null,
  //     primary: true,
  //     id: data.id
  //   });
  // };

  return (
    <Box className='p-2'>
      {data && (
        <Box id='education-experiences' className='education-container'>
          <Box className='text-label fs-16 pl-1 pb-1 header-font my-4'>{t(`${TRANS_KEYS.CONTACTS_BASIC_INFO}:key`)}</Box>
          <Grid container spacing={2} rowSpacing={2}>
            <Grid item xs={6}>
              <RecordTypeSelection
                viewAddress={true}
                label={t(`${TRANS_KEYS.CONTACTS_RECORD_TYPE}:key`)}
                value={data['contact_record_types']}
                onChange={data => {
                  handleRecordTypeChange(data);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SourceSelection
                className='w-100'
                label={t(`${TRANS_KEYS.CONTACTS_SOURCE}:key`)}
                onChange={(event, data) => {
                  setValue('source', data?.field_value || null, { shouldDirty: true });
                }}
                value={data?.source || null}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputField
                value={data['first_name']}
                required={true}
                label={t(`${TRANS_KEYS.CONTACTS_FIRST_NAME}:key`)}
                onChange={e => {
                  setValue('first_name', e.target.value || null, { shouldDirty: true });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomInputField
                value={data['last_name']}
                label={t(`${TRANS_KEYS.CONTACTS_LAST_NAME}:key`)}
                required={true}
                onChange={e => {
                  setValue('last_name', e.target.value || null, { shouldDirty: true });
                }}
              />
            </Grid>
            {/* <Box className='col-md-6'>
                    <Box className='education-input-base'>
                      <Box className='education-input' sx={{ width: '18vw' }}>
                        <CustomInputField
                          value={getSpecificValue('contact_emails', 'primary', true)?.email}
                          label={t(`${TRANS_KEYS.CONTACTS_EMAIL}:key`)}
                          required={true}
                          onChange={e => {
                            handleEmail(e);
                          }}
                          onBlur={e => onBlurEmail(e)}
                        />
                      </Box>
                    </Box>
                  </Box> */}
            <Grid item xs={6}>
              <CustomInputField
                value={data['linkedin_url']}
                label={t(`${TRANS_KEYS.CONTACTS_LINKEDIN_URL}:key`)}
                onChange={event => {
                  setValue('linkedin_url', event.target.value || null);
                }}
                onBlur={e => onBlurLinkedInUrl(e)}
              />
            </Grid>
          </Grid>
          <Box className='text-label fs-16 pl-1 pb-1 header-font my-4'>{t(`${TRANS_KEYS.CONTACTS_PROFESSIONAL_INFO}:key`)}</Box>
          <Grid container spacing={2} rowSpacing={2} className='mb-4  '>
            <Grid item xs={6}>
              <ResumeCompanySelection
                viewAddress={true}
                label={t(`${TRANS_KEYS.CONTACTS_COMPANY_NAME}:key`)}
                defaultValue={currentCompanyName}
                onChange={(e, data) => {
                  setValue('contact_company', data || null, { shouldDirty: true });
                  setValue('current_company_id', data?.id || null, { shouldDirty: true });
                }}
                disabled={true}
                companyList={companyList}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputField
                label={t(`${TRANS_KEYS.CONTACTS_JOB_TITLE}:key`)}
                defaultValue={data['current_job_title']}
                value={data['current_job_title']}
                onChange={e => {
                  setValue('current_job_title', e.target.value || null, { shouldDirty: true });
                }}
                isDisabled={true}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

ProfileDetails.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  setIsLoading: PropTypes.func,
  unregister: PropTypes.func,
  data: PropTypes.object,
  duplicateRecords: PropTypes.array,
  duplicationField: PropTypes.string,
  setSpecificValue: PropTypes.func,
  onBlurEmail: PropTypes.func,
  onBlurLinkedInUrl: PropTypes.func,
  companyList: PropTypes.array
};

export default ProfileDetails;
