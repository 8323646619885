import { Switch } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function AsyncSwitch({ defaultChecked, onToggle, disable = false }) {
  const [checked, setChecked] = React.useState(defaultChecked);
  const [disabled, setDisabled] = React.useState(disable);

  const onClick = e => {
    setDisabled(true);
    onToggle(e.target.checked)
      .then(() => {
        setChecked(checked => !checked);
      })
      .catch(() => {
        setChecked(checked => checked);
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  return <Switch inputProps={{ 'aria-label': 'controlled' }} defaultChecked={checked} checked={checked} onClick={onClick} disabled={disabled} />;
}

AsyncSwitch.propTypes = {
  defaultChecked: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  disable: PropTypes.bool
};

export default AsyncSwitch;
