//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { CircularProgress, Typography } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';

export default function Loader(props) {
  const { show, className, loadingMessage } = props;

  return (
    show && (
      <>
        <div id='loader' className={`loader-container ${className || ''}`}>
          <CircularProgress color={'primary'} className='loader-class' />
          {loadingMessage && (
            <Typography color='primary' style={{ fontSize: 20, marginLeft: 5 }}>
              {loadingMessage}
            </Typography>
          )}
        </div>
      </>
    )
  );
}

Loader.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  loadingMessage: PropTypes.string
};
