import { enqueueSnackbar } from 'notistack';
import { ignAthenaDataApi } from '../services/ApiService';
import { POST } from '../services/constantService';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomModalWithHeader from '../Containers/Commons/CustomModalWithHeader';
import React from 'react';
import CustomDropdown from '../components/common/CustomDropdown';
import Loader from '../components/common/Loader';
import CustomButton from '../components/common/CustomButton';
import PropTypes from 'prop-types';
import { useLanguageHooks } from './Hooks/UseLanguageHook';
import { TRANS_KEYS } from './languageNamespaces';

export const AthenaEmailInvite = async (email, contact_id, project_id = null) => {
  try {
    const res = await ignAthenaDataApi(POST, '', { email, contact_id, project_id }, 'invite');
    enqueueSnackbar(res?.data, 'success');
  } catch (error) {
    enqueueSnackbar(error, 'error');
  }
};

export const AthenaEmailInviteModal = ({ isOpen, onClose, contact, projectId = null }) => {
   /**
   * contact : {
   *  id, contact_emails, name
   * }
   */

  const [isLoading, setLoading] = useState(false);
  const [noPrimaryEmailModalContactEmail, setNoPrimaryEmailModalContactEmail] = useState('');
  const { t } = useLanguageHooks([
    TRANS_KEYS.ATHENA_INVITE_HEADING,
    TRANS_KEYS.ATHENA_INVITE_SAVE_AND_INVITE,
    TRANS_KEYS.ATHENA_INVITE_SUB_HEADING,
    TRANS_KEYS.CONTACTS_EDIT,
    TRANS_KEYS.CONTACTS_CANCEL,
    TRANS_KEYS.CONTACTS_NAME,
    TRANS_KEYS.CONTACTS_EMAIL
  ]);

  const navigate = useNavigate();

  const openDrawer = params => {
    navigate(`/contacts/list/all-contacts/${params.data.id}`);
  };

  const handleAthenaSaveAndInvite = async () => {
    setLoading(true);
    const email = noPrimaryEmailModalContactEmail?.length > 0 ? noPrimaryEmailModalContactEmail : contact?.contact_emails[0].email;
    await AthenaEmailInvite(email, contact.id, projectId);
    setLoading(false);
  };
  const handleUpdateContact = () => {
    const params = {
      data: contact
    };
    openDrawer(params);
  };

  return (
    <CustomModalWithHeader isOpen={isOpen} closeIcon={true} onClose={() => onClose()} label={t(`${TRANS_KEYS.ATHENA_INVITE_HEADING}:key`)}>
      <div className='fs-14 p-2'>
        {t(`${TRANS_KEYS.CONTACTS_NAME}:key`)} : {contact?.name}
      </div>

      {contact?.contact_emails && contact?.contact_emails?.length > 0 ? (
        <div className='fs-14 p-2'>
          <CustomDropdown
            label={t(`${TRANS_KEYS.CONTACTS_EMAIL}:key`)}
            options={contact?.contact_emails}
            value={contact?.contact_emails[0]}
            onChange={(e, v) => setNoPrimaryEmailModalContactEmail(v?.email)}
            required
            className={'w-100'}
          />
        </div>
      ) : (
        <div className='fs-12 p-2'>{t(`${TRANS_KEYS.ATHENA_INVITE_SUB_HEADING}:key`)}</div>
      )}

      <Loader show={isLoading} />
      <div className='d-flex justify-content-end'>
        <CustomButton buttonText={t(`${TRANS_KEYS.CONTACTS_CANCEL}:key`)} variant={'text'} type='tertiary m-1' onClick={() => onClose()} />

        {contact?.contact_emails && contact?.contact_emails?.length > 0 ? (
          <CustomButton buttonText={t(`${TRANS_KEYS.ATHENA_INVITE_SAVE_AND_INVITE}:key`)} type='primary m-1' onClick={() => handleAthenaSaveAndInvite()} />
        ) : (
          <CustomButton buttonText={t(`${TRANS_KEYS.CONTACTS_EDIT}:key`)} type='primary m-1' onClick={() => handleUpdateContact()} />
        )}
      </div>
    </CustomModalWithHeader>
  );
};

AthenaEmailInviteModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  contact: PropTypes.object,
  projectId: PropTypes.string
};
