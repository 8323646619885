export const getElementsRating = elements => {
  let total = 0,
    count = 0;
  elements.forEach(item => {
    const { minimum, ideal } = item;
    if (minimum && ideal) {
      count += 1;
      if (ideal === 'MEETS') {
        total += 5;
        return;
      }
      switch (minimum) {
        case 'DEFICIENT':
          total += 0;
          break;
        case 'MEETS':
          total += 3;
          break;
        default:
          total += 4;
      }
      return;
    } else if (minimum) {
      switch (minimum) {
        case 'DEFICIENT':
          total += 0;
          break;
        case 'MEETS':
          total += 3;
          break;
        default:
          total += 5;
      }
      count += 1;
      return;
    } else if (ideal === 'MEETS') {
      total += 5;
      count += 1;
    }
  });
  return count ? total / count : count;
};

export const getRating = (skills, field) => {
  let count = 0;
  const total = skills.reduce((previousRating, item) => {
    const rating = item[field];
    if (!isNaN(rating) && rating !== null) {
      count += 1;
      return previousRating + rating;
    }
    return previousRating;
  }, 0);
  if (count === 0) {
    return 0;
  }
  return +(total / count).toFixed(1);
};
