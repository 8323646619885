import { Box, Button, CircularProgress } from '@mui/material';
import React from 'react';
import './index.scss';

import PropTypes from 'prop-types';

const CustomButton = props => {
  const {
    isLoading = false,
    boxClassName = '',
    buttonWrapperClassName = '',
    show = true,
    type = 'primary',
    size = 'small',
    variant = 'contained',
    buttonText = '',
    customWidth,
    iconLeft,
    iconRight,
    onClick,
    disabled = false,
    width,
    buttonType = 'button',
    fontWeight = null,
    buttonStyle = {},
    ...rest
  } = props;

  const fontSize = () => {
    if (size === 'small') return 'fs-12';
    if (size === 'medium') return 'fs-14';
    if (size === 'large') return 'fs-16';
  };

  return show ? (
    <Box className={'customButton'} sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
      <Button
        type={buttonType}
        onClick={onClick}
        style={buttonStyle}
        sx={{ minWidth: customWidth, width: width }}
        disabled={disabled}
        variant={variant}
        size={size}
        {...rest}
        className={`d-flex btn justify-content-center align-item-center text-capitalize ${disabled ? null : type} ${size} ${fontSize()} ${boxClassName} ${buttonWrapperClassName}`}
      >
        {isLoading ? (
          <CircularProgress color='inherit' size='20px' />
        ) : (
          <Box fontWeight={fontWeight} className={'d-flex align-items-center justify-content-center '}>
            {iconLeft} {buttonText}
            {iconRight}
          </Box>
        )}
      </Button>
    </Box>
  ) : (
    <></>
  );
};

CustomButton.propTypes = {
  boxClassName: PropTypes.string,
  buttonWrapperClassName: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  buttonText: PropTypes.string,
  customWidth: PropTypes.number,
  onClick: PropTypes.func,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  buttonType: PropTypes.string,
  show: PropTypes.bool,
  fontWeight: PropTypes.string,
  isLoading: PropTypes.bool,
  buttonStyle: PropTypes.object
};

export default CustomButton;
