//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { GET } from '../../services/constantService';
import InputField from '../common/InputField';
import { picklistsDataApi } from '../../services/ApiService';
import { fetchPickList } from '../../actions';

function JobFunctionSelection(props) {
  const { label = 'JOB FUNCTION', inputRef, placeholder = 'Job Function', InputLabelProps, variant = 'standard', required = false, disabled = false, ...rest } = props;

  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const jobFunctions = useSelector(state => state.commonReducer.jobFunctions);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    if (!jobFunctions) {
      dispatch(fetchPickList(`industries/jobfunctions/search?name=${value}`, 'jobFunctions'));
    } else if (value?.length > 0) {
      const sub_route = `industries/jobfunctions/search?name=${value}`;
      picklistsDataApi(GET, '', sub_route).then(response => {
        const { status, data } = response;
        if (status === 200 && data?.data) {
          setOptions(data.data);
        }
        setLoading(false);
      });
    } else {
      setOptions(jobFunctions);
    }
    setLoading(false);
  }, [value, jobFunctions]);

  return (
    <Autocomplete
      {...rest}
      options={options}
      filterOptions={options => options}
      getOptionLabel={option => {
        if (!option) {
          return '';
        }
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      getOptionSelected={(option, value) => {
        if (!value) {
          return false;
        }
        if (typeof value === 'string') {
          return option.name === value;
        }
        return option.name === value.name;
      }}
      onInputChange={(e, val, reason) => {
        if (reason === 'input') {
          setValue(val);
        }
      }}
      disabled={disabled}
      renderInput={params => (
        <InputField
          {...params}
          required={required}
          variant={variant}
          ref={inputRef}
          InputLabelProps={InputLabelProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color='primary' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}

JobFunctionSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  inputRef: PropTypes.object,
  variant: PropTypes.string
};

export default JobFunctionSelection;
