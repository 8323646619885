import { clarity } from 'clarity-js';

export const shouldTrackOnClarity = () => {
  if (window.location.hostname === 'kgdemo.ignyte.app') {
    return true;
  }
  return false;
};

export const clarityTracker = {
  initiate: () =>
    clarity.start({
      projectId: 'oukxezpwga',
      upload: 'https://m.clarity.ms/collect',
      content: true,
      track: true
    }),
  pause: () => clarity.pause(),
  resume: () => clarity.resume(),
  stop: () => clarity.stop(),
  // Can be User's Email or ID
  identify: identifier => clarity.identify(identifier, null, null, identifier),
  setTag: (key, value) => clarity.set(key, value),
  event: (eventName, value) => clarity.event(eventName, value)
};
