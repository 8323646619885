import { Box, Grid } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { enqueueSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import CustomButton from '../../../../components/common/CustomButton';
import Loader from '../../../../components/common/Loader';
import CustomInputField from '../../../../components/common/StyledComponents/CustomInputField';
import ViewContactDrawer from '../../../../components/ViewContactDrawer';
import { getMethodWithCancelTokenApi } from '../../../../services/ApiService';
import { IGN_API } from '../../../../services/constantService';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import CustomModalWithHeader from '../../../Commons/CustomModalWithHeader';
import { WorkBenchContext } from '../../Context';

export default function AddContactFromExisting() {
  const { setAddContactFromExistingModal, addContactFromExistingModal, addRecord, refetchFunc } = useContext(WorkBenchContext);
  const [rowData, setRowData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const gridRef = React.useRef(null);

  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_CONTACT_NAME, TRANS_KEYS.CONTACTS_SEARCH, TRANS_KEYS.ADD_TO_WORKBENCH]);

  const onSelectionChanged = event => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => {
      return {
        contact_id: node.data.id,
        id: undefined,
        ...node.data
      };
    });
    setSelectedIds(selectedData);
  };

  const fetchContacts = async () => {
    const url = `${IGN_API.picklists}/contacts?name=${inputValue}`;
    setLoading(true);
    if (inputValue && inputValue.length > 2) {
      await getMethodWithCancelTokenApi(url, {}, {}).then(response => {
        const { status, data } = response;
        if (status === 200) {
          setRowData(data?.data);
          if (data?.data?.length === 0) {
            enqueueSnackbar('No data found', { variant: 'error' });
            setRowData([]);
          }
        }
      });
      setLoading(false);
    } else {
      enqueueSnackbar('Please enter atleast 3 characters to search', { variant: 'error' });
      setLoading(false);
    }
  };

  const addToContactWorkbench = async () => {
    try {
      setLoading(true);
      for (let data of selectedIds) {
        await addRecord(data?.contact_id, null);
      }
      enqueueSnackbar('Added to Workbench', 'success');
      setAddContactFromExistingModal(false);
      refetchFunc();
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoading(false);
    }
  };

  const openCandidateDrawer = id => {
    setAddContactFromExistingModal(false);
    setIsDrawerOpen(true);
    setSelectedContact(id);
  };
  const columnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      sortable: false,
      suppressMenu: true,
      resizable: false,
      headerName: '',
      field: 'id'
    },
    {
      headerName: 'Name',
      field: 'first_name',
      sortable: true,
      filter: true,
      cellRendererFramework: params => (
        <div onClick={() => openCandidateDrawer(params.data.id)}>
          {params.data.first_name} {params.data.last_name}
        </div>
      )
    },
    {
      headerName: 'Company',
      field: 'company',
      sortable: true,
      filter: true,
      cellRendererFramework: params => params?.data?.contact_company?.name
    },
    {
      headerName: 'Job Title',
      field: 'current_job_title',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Updated At',
      field: 'updated_at',
      cellRendererFramework: params => {
        return params.value ? new Date(params.value).toLocaleDateString() : '';
      },
      sortable: true,
      filter: true
    },
    {
      headerName: 'Source',
      field: 'source',
      sortable: true,
      filter: true
    }
  ];

  const onCloseDrawer = () => {
    setIsDrawerOpen(false);
    setAddContactFromExistingModal(true);
  };

  return (
    <>
      <CustomModalWithHeader
        isOpen={addContactFromExistingModal}
        style={{ width: '70vw !important', minHeight: '50vh' }}
        onClose={() => setAddContactFromExistingModal(!addContactFromExistingModal)}
        label={'Add Existing Contact'}
        closeIcon={true}
      >
        <div className='min-vw-50 p-4'>
          <Loader show={isLoading} />
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={10}>
              <CustomInputField className='w-100' label={t(`${TRANS_KEYS.CONTACTS_CONTACT_NAME}:key`)} onChange={e => setInputValue(e.target.value)} />
            </Grid>
            <Grid item xs={2}>
              <CustomButton buttonText={t(`${TRANS_KEYS.CONTACTS_SEARCH}:key`)} type='primary w-100' onClick={fetchContacts} />
            </Grid>
          </Grid>
          <div id='myGrid' className='mt-2 w-100 ag-theme-alpine ag-grid-container' style={{ height: '300px' }}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={rowData}
              suppressRowClickSelection={true}
              paginationPageSize={10}
              rowSelection='multiple'
              enableRangeSelection={true}
              onSelectionChanged={onSelectionChanged}
              defaultColDef={{
                resizable: true,
                filter: true,
                sortable: true
              }}
              columnMenu={true}
            />
          </div>

          <div className='mt-2 d-flex justify-content-end'>
            <CustomButton buttonText={t(`${TRANS_KEYS.ADD_TO_WORKBENCH}:key`)} disabled={selectedIds?.length == 0} onClick={addToContactWorkbench} />
          </div>
        </div>
      </CustomModalWithHeader>
      <Box id='view-contact-drawer' zIndex={100000000}>
        <>
          <ViewContactDrawer navigateToAllContacts={false} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={onCloseDrawer} id={selectedContact} />
        </>
      </Box>
    </>
  );
}
