import React, { useContext, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Cancel } from '@mui/icons-material';

import GeoLocationInput from '../../../../../components/common/GeoLocation';
import { ProjectDataContext } from '../../Context';
import { TargetLocationDataApi } from '../../../../../services/ApiService';
import { DELETE } from '../../../../../services/constantService';
import { useSnackbar } from 'notistack';
import Loader from '../../../../../components/common/Loader';
import { useLanguageHooks } from '../../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';

export default function TargetLocation() {
  const { projectData, handleUniversalState } = useContext(ProjectDataContext);
  const [recommendedTargetLocations, setRecommendedTargetLocations] = useState([]);
  const { t } = useLanguageHooks([TRANS_KEYS.HELIA_BUILD_ROAD_MAP_TEXT]);
  const { enqueueSnackbar } = useSnackbar();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleUniversalStateLocally = updatedVal => {
    const event = {
      target: {
        name: 'targetLocation',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };

  const deleteLocation = async cardData => {
    try {
      setLoader(true);
      if (cardData?.id) {
        const res = await TargetLocationDataApi(DELETE, '', {
          id: cardData?.id
        });
        console.log('handle-save-location-delete', res);
      }

      const updateLocations = recommendedTargetLocations.filter(curr => curr?.place_id != cardData?.place_id || curr?.id != cardData?.id);
      setRecommendedTargetLocations(updateLocations);
      handleUniversalStateLocally(updateLocations);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      enqueueSnackbar(err, 'error');
    }
  };
  const addLocation = (event, location) => {
    if (location && typeof location == 'object') {
      const updateLocations = [...recommendedTargetLocations, location];
      setRecommendedTargetLocations(updateLocations);
      handleUniversalStateLocally(updateLocations);
    }
  };

  useEffect(() => {
    if (recommendedTargetLocations.length == 0 && !isDataLoaded) {
      setRecommendedTargetLocations(projectData?.targetLocation || []);
      if (projectData?.targetLocation) {
        setIsDataLoaded(true);
      }
    }
  }, [projectData]);
  return (
    <Box className='w-100'>
      <Loader show={loader} />
      <GeoLocationInput onChange={addLocation} style={{ width: '50%' }} size='small' />
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant='caption' className='p-1'>
          {t(`${TRANS_KEYS.HELIA_BUILD_ROAD_MAP_TEXT}:key`)}
        </Typography>
      </Stack>
      <Stack direction='row' justifyContent={'flex-start'} flexWrap={'wrap'} className='mt-2'>
        {recommendedTargetLocations.map((option, index) => (
          <Box
            key={index}
            sx={{
              backgroundColor: 'secondary.main',
              color: 'white',
              borderRadius: '20px',
              position: 'relative'
            }}
            className='p-2 m-1 border'
          >
            <Cancel
              sx={{
                position: 'absolute',
                top: '-27%',
                right: '-4%',
                color: 'primary.main',
                backgroundColor: 'white',
                borderRadius: '50%',
                cursor: 'pointer'
              }}
              fontSize='small'
              onClick={() => {
                deleteLocation(option);
              }}
            />
            <Typography variant='subtitle2'>{option?.description || option?.place_text}</Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
