//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

import { useDispatch, useSelector } from 'react-redux';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { getCurrencyPrefix, separator } from '../Utils';
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR } from '../../../services/constantService';
import { fetchCurrencyDetails } from '../../../actions';

// eslint-disable-next-line react/display-name
const NumberEditor = forwardRef((props, ref) => {
  const { data: rowData, stopEditing, field = 'revenue', decimalScale } = props;
  const [state, setState] = useState(rowData[field]);
  const dispatch = useDispatch();
  const currencyDetails = useSelector(state => (state.commonReducer[rowData.currency_iso_code] ? state.commonReducer[rowData.currency_iso_code] : {}));

  useImperativeHandle(ref, () => {
    return {
      getValue: () => state,
      afterGuiAttached: () => {}
    };
  });

  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(rowData.currency_iso_code, dispatch);
      } catch (e) {
        console.log('Error found in currencyHandler::', e);
      }
    };
    if (rowData.currency_iso_code && Object.keys(currencyDetails)?.length === 0) {
      currencyHandler();
    }
  }, [rowData]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react/prop-types
      props.api.refreshCells(props.column);
      stopEditing();
    };
  }, [stopEditing, props]);

  return (
    <div className=''>
      <NumericFormat
        value={state || ''}
        thousandSeparator={separator(rowData.currency_iso_code, THOUSAND_SEPARATOR)}
        decimalSeparator={separator(rowData.currency_iso_code, DECIMAL_SEPARATOR)}
        decimalScale={decimalScale}
        isNumericString={true}
        prefix={getCurrencyPrefix(rowData.currency_iso_code !== 'RUR' ? rowData.currency_iso_code : '')}
        suffix={getCurrencyPrefix(rowData.currency_iso_code === 'RUR' ? 'RUR' : '')}
        onValueChange={values => {
          const { value } = values;
          setState(value ? value : null);
        }}
      />
    </div>
  );
});

export default NumberEditor;

NumberEditor.propTypes = {
  data: PropTypes.object,
  api: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  stopEditing: PropTypes.func,
  field: PropTypes.string,
  decimalScale: PropTypes.number
};
