import PropTypes from 'prop-types';
import * as React from 'react';

function PdfIcon(props) {
  return (
    <svg width={15} height={15} viewBox='0 0 15 15' fill='none' {...props}>
      <path d='M3.5 8H3V7h.5a.5.5 0 110 1zM7 10V7h.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H7z' fill={props.fill || '#000'} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 1.5A1.5 1.5 0 012.5 0h8.207L14 3.293V13.5a1.5 1.5 0 01-1.5 1.5h-10A1.5 1.5 0 011 13.5v-12zM3.5 6H2v5h1V9h.5a1.5 1.5 0 000-3zm4 0H6v5h1.5A1.5 1.5 0 009 9.5v-2A1.5 1.5 0 007.5 6zm2.5 5V6h3v1h-2v1h1v1h-1v2h-1z'
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

PdfIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string
};

export default PdfIcon;
