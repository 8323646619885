import { createSlice } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';
import CustomSkeletonCard from '../components/GlobalSearch/Components/CustomSkeletonCard';
import RecentActivitySkeleton from '../components/GlobalSearch/Components/RecentActivitySkeleton';

export const globalSearchTabs = [
  {
    id: uniqueId(),
    index: 'all',
    model: 'all',
    label: 'All',
    skeletonComponent: CustomSkeletonCard
  },
  {
    id: uniqueId(),
    model: 'ign-contacts',
    label: 'Contacts',
    skeletonComponent: CustomSkeletonCard
  },
  {
    id: uniqueId(),
    model: 'ign-projects',
    label: 'Projects',
    skeletonComponent: CustomSkeletonCard
  },
  {
    id: uniqueId(),
    model: 'ign-companies',
    label: 'Companies',
    skeletonComponent: CustomSkeletonCard
  },
  {
    id: '4',
    model: 'ign-contact-notes-all',
    label: 'Activities',
    skeletonComponent: RecentActivitySkeleton
  }
];

const initialState = {
  tabs: globalSearchTabs,
  selectedTabId: 'all',
  page: -1,
  limit: 50,
  search: '',
  data: [],
  loading: false,
  filterModel: {},
  refetch: 0,
  gridApi: null,
  selectedSubTabId: null,
  selectedMoreFilterTabId: null,
  filters: {},
  cachedImages: {},
  subTabs: [],
  filtersLoading: false,
  colId: null,
  sort: null,
  selectedContactId: null,
  selectedCompanyId: null,

  // This state is used in Activity date filter
  selectedRanges: {
    today: false,
    yesterday: false,
    thisWeek: false,
    thisMonth: false,
    custom: false,
    startDate: null,
    endDate: null
  },
  // This state is used in Activity date filter
  savedDate: {
    today: false,
    yesterday: false,
    thisWeek: false,
    thisMonth: false,
    custom: false,
    startDate: null,
    endDate: null
  }
};

const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    updateGlobalSearchData(state, action) {
      state[action.payload.key] = action.payload.data;
    },
    updateAllTabData(state, action) {
      const index = state.data.findIndex(item => item?.model === 'all');
      if (index !== -1) {
        state.data[index]?.data?.hits?.hits?.push(...(action.payload?.data?.data?.hits?.hits || []));
      }
    },
    updateTabDataByModelName(state, action) {
      const oldData = state.data;
      const index = state.data.findIndex(item => item?.model === action.payload.modelName);
      state.data = action.payload.data;

      if (index !== -1) {
        state.data[index].data.hits.hits = [...oldData[index].data.hits.hits, ...state.data[index].data.hits.hits];
      }
    }
  }
});

export const { updateGlobalSearchData, updateAllTabData, updateTabDataByModelName } = globalSearchSlice.actions;

export default globalSearchSlice.reducer;
