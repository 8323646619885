import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const LevelOfExpertiseSelection = props => {
  const { onChange = () => {}, isDrawer = false, label = '', defaultValue, viewAddress, ...res } = props;
  const [options, setOptions] = useState([]);
  const languages_expertise = useSelector(state => state.commonReducer.languageExpertise);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!languages_expertise) {
      dispatch(fetchPickLists('LEVEL_OF_EXPERTISE', 'languageExpertise'));
    } else {
      setOptions(languages_expertise);
    }
  }, [languages_expertise, dispatch]);

  return <CustomDropdown {...res} options={options} onChange={onChange} isDrawer={isDrawer} value={defaultValue} label={label} defaultValue={defaultValue} viewAddress={viewAddress} />;
};
LevelOfExpertiseSelection.propTypes = {
  onChange: PropTypes.func,
  isDrawer: PropTypes.bool,
  value: PropTypes.object,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  viewAddress: PropTypes.bool
};
export default LevelOfExpertiseSelection;
