import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CustomSwitch from '../../../../../components/common/CustomSwitch';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const ReportOption = ({ title = '', checked = false, onChange, children }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const ExpandIcon = isExpanded ? ExpandLessIcon : ExpandMoreIcon;

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'} border={'0.5px solid lightgray'}>
      <Box display={'flex'} paddingY={1} paddingX={2} justifyContent={'space-between'} borderRadius={0.5} alignItems={'center'} width={'100%'} bgcolor={'#e9f3f8'} gap={0.5}>
        <Box display={'flex'}>
          <CustomSwitch checked={checked} onChange={onChange} />
          <Typography sx={{ fontSize: 12, fontWeight: 800, color: '#1b4f76' }}>{title}</Typography>
        </Box>
        {children && (
          <IconButton sx={{ paddingY: 0 }} onClick={() => setIsExpanded(!isExpanded)}>
            <ExpandIcon style={{ fontSize: 25, marginLeft: 10 }} />
          </IconButton>
        )}
      </Box>

      {children && isExpanded && (
        <Box display={'flex'} flexDirection={'column'} marginY={2} paddingX={2} gap={2}>
          {children}
        </Box>
      )}
    </Box>
  );
};

export { ReportOption };

ReportOption.propTypes = {
  title: PropTypes.string,
  checked: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node
};
