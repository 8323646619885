import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
const SnackbarCloseButton = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <CloseIcon
      className='cursor-pointer'
      onClick={() => {
        closeSnackbar(snackbarKey);
      }}
    />
  );
};

SnackbarCloseButton.propTypes = {
  snackbarKey: PropTypes.any
};
export default SnackbarCloseButton;
