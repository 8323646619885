import React, { useState } from 'react';
import '../../ProductSetup/index.scss';
import PropTypes from 'prop-types';
import CustomPopup from '../../../components/common/CustomPopup';
import TranslateForm from './TranslateForm';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';

const AddTranslateValue = props => {
  const { setIsPopupOpen, isPopupOpen, saveData, setValue, register, unregister, watch, isEdit = false } = props;

  const { t } = useLanguageHooks([TRANS_KEYS.TRANSLATE_VALUE_ADD_VALUE, TRANS_KEYS.TRANSLATE_VALUE_ADD_VALUES, TRANS_KEYS.TRANSLATE_VALUE_EDIT_VALUE]);

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const [loading, setLoading] = useState(false);

  return (
    <CustomPopup
      dropdown={true}
      title={t(`${isEdit ? TRANS_KEYS.TRANSLATE_VALUE_EDIT_VALUE : TRANS_KEYS.TRANSLATE_VALUE_ADD_VALUE}:key`)}
      open={isPopupOpen}
      onClose={handleClose}
      showAction={true}
      disabled={loading}
      handleSubmit={async () => {
        setLoading(true);
        await saveData();
        setLoading(false);
      }}
      confirmText={t(`${TRANS_KEYS.TRANSLATE_VALUE_ADD_VALUES}:key`)}
    >
      <TranslateForm watch={watch} unregister={unregister} register={register} setValue={setValue}></TranslateForm>
    </CustomPopup>
  );
};
AddTranslateValue.propTypes = {
  setIsPopupOpen: PropTypes.func,
  isPopupOpen: PropTypes.bool,
  saveData: PropTypes.func,
  setValue: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  watch: PropTypes.func,
  isEdit: PropTypes.bool
};

export default AddTranslateValue;
