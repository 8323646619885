//----------------------------------------------// In-built Imports // -------------------------------------------------

import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------

import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------

import { fetchPickList } from '../../../actions';

function ActivitySelection(props) {
  const { watch, setValue, selectedHistory } = props;

  const [activityTypeOptions, setActivityTypeOptions] = useState([]);
  const [activityNameOptions, setActivityNameOptions] = useState([]);

  const activityNames = useSelector(state => state.commonReducer.activityNames);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!activityNames) {
      dispatch(fetchPickList('activity-names', 'activityNames'));
    } else {
      setActivityNameOptions(activityNames);
    }
  }, [activityNames, dispatch]);

  useEffect(() => {
    if (activityNameOptions?.length > 0) {
      if (selectedHistory) {
        let typeOptions = getActivityTypeOptions(selectedHistory?.activity);
        setActivityTypeOptions(typeOptions);
      }
    }
  }, [activityNameOptions]);

  const getActivityTypeOptions = activityName => {
    if (!activityName) {
      return [];
    }
    let filteredOptions = activityNameOptions?.filter(activity => activity.name === activityName);
    if (filteredOptions?.length > 0) {
      return filteredOptions[0].types.map(type => {
        return {
          name: type
        };
      });
    } else {
      return [];
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        className='input-form-field input-field-old'
        value={watch('activity') || null}
        options={activityNameOptions || []}
        onChange={(e, data) => {
          setValue('activity', data ? data.name : null);
          setValue('type', null);
          let typeOptions = data ? getActivityTypeOptions(data.name) : [];
          setActivityTypeOptions(typeOptions);
        }}
        getOptionSelected={(option, value) => {
          if (typeof value === 'string') {
            return option.name === value;
          }
          return option.name === value.name;
        }}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option;
          }
          return option.name;
        }}
        renderInput={params => <TextField {...params} required={true} InputLabelProps={{ focused: true }} label='Activity' placeholder='Activity' />}
      />
      <Autocomplete
        className='input-form-field input-field-old'
        required={true}
        value={watch('type') || null}
        onChange={(e, data) => setValue('type', data ? data.name : null)}
        options={activityTypeOptions || []}
        getOptionSelected={(option, value) => {
          if (typeof value === 'string') {
            return option.name === value;
          }
          return option.name === value.name;
        }}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option;
          }
          return option.name;
        }}
        renderInput={params => <TextField {...params} required={true} InputLabelProps={{ focused: true }} label='Type' placeholder='Type' />}
      />
    </React.Fragment>
  );
}

ActivitySelection.propTypes = {
  watch: PropTypes.func,
  setValue: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  selectedHistory: PropTypes.object
};

export default ActivitySelection;
