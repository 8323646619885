import { IGN_API } from '../../../services/constantService';

const url = window.location.origin,
  subdomain = url.split('.')[0];

const restOfDomain = url.substring(url.indexOf('.') + 1); // Get the rest of the domain

const dynamicUrl = subdomain.includes('localhost') && process.env.REACT_APP_IS_LOCAL ? 'http://localhost:3000' : `${subdomain}.app.${restOfDomain}`;

/**
 *
 * @param {string} input - The text to be summarized
 * @param {function} manipulateExistingTextFunction - A function that manipulates the existing text
 * @param {function} stateWriterFunction - A function that writes the text to the state
 * @returns {Promise<void>}
 */
export const generateText = async (input, manipulateExistingTextFunction, stateWriterFunction) => {
  const eventSource = new EventSource(`${dynamicUrl}${IGN_API.gpt}/summarize?text=${encodeURIComponent(input)}`, {
    withCredentials: true
  });

  manipulateExistingTextFunction();

  eventSource.onmessage = function (event) {
    const data = JSON.parse(event.data);
    stateWriterFunction(data);
  };

  eventSource.onerror = function (error) {
    console.error('EventSource failed:', error);
    eventSource.close();
  };
};
