import React from 'react';
import CustomDropdown from '../../CustomDropdown';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { getUserSelector } from '../../../../selectors';
import { createFilterOptions } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { picklistDropDownApis } from '../../../../services/ApiService';
import { UPDATE_STATE_LIST } from '../../../../types';
import { GET } from '../../../../services/constantService';

const StateSelection = props => {
  const {
    value,
    name = '',
    onChange = () => {},
    disabled = false,
    isDrawer = false,
    multiple = false,
    disableCloseOnSelect = false,
    className = '',
    isCheckBox = false,
    country = '',
    label = '',
    ...rest
  } = props;
  const [options, setOptions] = useState([]);
  const usState = getUserSelector(state => state.usState);
  const filter = createFilterOptions();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchStates = async () => {
      if (country) {
        if (!usState) {
          const sub_route = `countries/states?countryName=${country}`;
          const { status, data } = await picklistDropDownApis(GET, sub_route);
          if (status === 200) {
            if (data && data?.length) {
              const sortAscState = (a, b) => (a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1);
              data.sort(sortAscState);
            }
            dispatch({
              type: UPDATE_STATE_LIST,
              payload: { key: 'usState', data }
            });
            let array = [];
            data.map(data => array.push({ label: data.name, value: data.name, id: data.id }));
            setOptions(array);
          }
        } else {
          let array = [];
          usState.map(data => array.push({ label: data.name, value: data.name, id: data.id }));
          setOptions(array);
        }
      } else {
        setOptions([]);
      }
    };
    fetchStates().then(null);
  }, [country]);
  return (
    <>
      <CustomDropdown
        {...rest}
        options={options}
        value={value}
        name={name}
        label={label}
        onChange={onChange}
        disabled={disabled}
        isDrawer={isDrawer}
        multiple={multiple}
        disableCloseOnSelect={disableCloseOnSelect}
        className={className}
        isCheckBox={isCheckBox}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;

          // Suggest the creation of a new value
          const isExisting = options.some(option => option.title === inputValue);
          if (inputValue !== '' && !isExisting && country) {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add item "${inputValue}"`
            });
          }
          return filtered;
        }}
      ></CustomDropdown>
    </>
  );
};
StateSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isDrawer: PropTypes.bool,
  multiple: PropTypes.bool,
  isCheckBox: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool,
  country: PropTypes.string
};
export default StateSelection;
