//----------------------------------------------// Internal Imports // -------------------------------------------------

import { formatDate } from '../../utils/date';

export const getWorkbenchUsers = users => {
  return users?.map(item => item?.name).join(', ');
};

export const getArchivedDate = () => {
  const archiveDate = new Date();
  archiveDate.setDate(archiveDate.getDate() + 365);
  return archiveDate;
};

export const cleanupWorkbenchData = formData => {
  formData.users = formData.users.map(user => user.id);
  if (!formData.archive_date) {
    formData.archive_date = getArchivedDate();
  }
  formData.archive_date = formatDate(formData.archive_date, 'YYYY-MM-DD');
  return formData;
};

export const checkWorkbenchAccess = (workbench, userData) => {
  const taggedUsersIds = workbench.tagged_users.map(item => item.id);
  const AccessWorkbench = userData.isAdmin || taggedUsersIds.includes(userData.id) ? true : workbench.is_locked ? false : true;
  return AccessWorkbench;
};

export const checkForUnlockWorkbench = (workbench, userData) => {
  const taggedUsersIds = workbench.tagged_users.map(item => item.id);
  const canLockWorkbench = userData.isAdmin || taggedUsersIds.includes(userData.id);
  return canLockWorkbench;
};
