//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import SearchApproval from './List.js';

export default function searchApprovalPending() {
  return <SearchApproval searchType={'approvalPending'} columnStateKey={'myPlacementsColumns'} searchStatus={'Placement'} title='My Placements' placedSearches />;
}
