import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import propTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import { AttendeesComponent, DateComponent, NameComponent, ProjectName, TimeComponent } from './components';

// let columnApi;
function InterviewTable(props) {
  const { data } = props;
  // const [isGridReady, setIsGridReady] = useState(false);
  const gridRef = useRef();
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      filter: true,
      minHeight: 300,
      resizable: true,
      sortable: true
    };
  }, []);

  // const onGridReady = (params) => {
  //   columnApi = params.columnApi;
  //   params.api.sizeColumnsToFit();
  //   params.columnApi.autoSizeColumns();

  //   setIsGridReady(true);
  //   // refresh the grid for row data
  //   params.api.refreshCells();
  // };

  const agCol = () => {
    return [
      {
        minWidth: 100,
        field: 'project',
        headerName: 'Project ID',
        editable: false,
        cellRendererFramework: ProjectName
      },
      {
        minWidth: 200,
        field: 'ign_contacts.name',
        headerName: 'Candidate Name',
        cellRendererFramework: NameComponent,
        editable: false
      },
      {
        minWidth: 250,
        field: 'project.job_title',
        headerName: 'Job Title',
        editable: false
      },
      {
        minWidth: 200,
        field: 'project.name',
        headerName: 'Project Name',
        editable: false
      },
      {
        minWidth: 250,
        field: 'attendees',
        headerName: 'Attendees',
        cellRendererFramework: AttendeesComponent,
        editable: false
      },
      {
        minWidth: 100,
        field: 'interview_date',
        headerName: 'Time scheduled',
        cellRendererFramework: TimeComponent,
        editable: false,
        sort: 'desc'
      },
      {
        minWidth: 100,
        field: 'interview_date',
        headerName: 'Date scheduled',
        cellRendererFramework: DateComponent,
        editable: false,
        sort: 'desc'
      }
    ];
  };

  const components = useMemo(() => {
    return {
      attendeesComponent: AttendeesComponent,
      timeComponent: TimeComponent,
      dateComponent: DateComponent
    };
  }, []);

  return (
    <div
      className={'px-4 ag-theme-alpine'}
      style={{
        height: '100%',
        width: '100%'
      }}
    >
      <AgGridReact
        // className=""
        ref={gridRef}
        rowStyle={{ justifyContent: 'start' }}
        columnDefs={agCol()}
        rowData={data}
        rowSelection='multiple'
        defaultColDef={defaultColDef}
        components={components}
        rowHeight={70}
      />
    </div>
  );
}
//proptypes
InterviewTable.propTypes = {
  data: propTypes.array
};

export default InterviewTable;
