import PropTypes from 'prop-types';
import React from 'react';
import CustomPopup from '../../../common/CustomPopup';
import RichText from '../../../common/RichText';

const RichTextCustomPopup = props => {
  const { isOpen = false, title = '', setIsOpen = () => {}, defaultValue = '', onChange = () => {} } = props;
  const [richTextValue, setRichTextValue] = React.useState(null);

  React.useEffect(() => {
    setRichTextValue(defaultValue);
  }, [defaultValue]);
  return (
    <CustomPopup
      open={isOpen}
      showAction
      title={title}
      onClose={() => setIsOpen(false)}
      handleSubmit={() => {
        onChange(richTextValue);
        setIsOpen(false);
        setRichTextValue(null);
      }}
    >
      <RichText
        defaultValue={richTextValue}
        onChange={v => {
          setRichTextValue(v);
        }}
      />
    </CustomPopup>
  );
};

RichTextCustomPopup.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  setIsOpen: PropTypes.func,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

export default RichTextCustomPopup;
