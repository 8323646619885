import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';
import CustomSelectionComponent from '../../CustomSelectionComponent';

//-----------------------------------------------------------// Internal Imports // ------------------------------
import PropTypes from 'prop-types';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import CustomButton from '../../CustomButton';

const MarkNoteAsSelection = props => {
  const { onChange = () => {}, selectedOption, setSelectedOption = () => {}, size = 'medium', editActivityNote } = props;
  const [options, setOptions] = useState([]);
  const markNote = useSelector(state => state.commonReducer.markNote);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_MARK_NOTE_AS]);
  const open = Boolean(anchorEl);

  const [finalOptions, setFinalOptions] = useState(null);
  useEffect(() => {
    if (!markNote) {
      dispatch(fetchPickLists('CONTACTS_MARK_NOTE_AS', 'markNote'));
    } else {
      setOptions(markNote);
    }
  }, [markNote, dispatch]);

  const handleMenuItemClick = option => {
    setSelectedOption(option?.short_desc);
    setFinalOptions(option?.field_value);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (editActivityNote && markNote) {
      const selectedActivity = markNote.find(item => item.field_value === editActivityNote);
      if (selectedActivity) {
        setSelectedOption(selectedActivity?.short_desc);
        setFinalOptions(selectedActivity?.field_value);
      }
    }
  }, [editActivityNote, markNote]);

  const customButtonForMark = (
    <CustomButton
      iconRight={<ExpandMoreIcon className={`${open ? 'animate-icon-open' : 'animate-icon-closed'}`} sx={{ width: 18, height: 18 }} />}
      customWidth={56}
      onChange={onChange(finalOptions)}
      buttonText={selectedOption ? selectedOption : t(`${TRANS_KEYS.CONTACTS_MARK_NOTE_AS}:key`)}
      type={'tertiary'}
      size={size}
      variant='text'
    ></CustomButton>
  );

  return (
    <CustomSelectionComponent
      component={customButtonForMark}
      options={options}
      handleMenuItemClick={handleMenuItemClick}
      setAnchorEl={setAnchorEl}
      anchorEl={anchorEl}
      menuTitle='Show Selected Value'
      variant='text'
      showEndIcon={true}
      className='text-capitalize title-style'
    />
  );
};

MarkNoteAsSelection.propTypes = {
  onChange: PropTypes.func,
  selectedOption: PropTypes.string,
  setSelectedOption: PropTypes.func,
  size: PropTypes.string,
  editActivityNote: PropTypes.string
};

export default MarkNoteAsSelection;
