import React from 'react';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import proptype from 'prop-types';
import { SectionModel, generateAddColumn } from './Base';
import { useTheme } from '@mui/material/styles';
import style from '../Home.module.scss';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { PROJECT_SCREEN, ROUTES } from '../../../services/constantService';
import { useTranslation } from 'react-i18next';
function LeftSection({ name, handleInterviewPopup, count, interviewCount }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const addColumns = generateAddColumn(t);

  const getCount = (type, name, avg_days_open = false) => {
    try {
      if (!count) return 0;
      let res = count?.[type]?.filter(item => item.record_type === name);
      if (avg_days_open) return res?.length > 0 && res[0].avg_days_open;
      return res?.length > 0 && res[0].count;
    } catch (err) {
      console.log(err);
      return 0;
    }
  };
  const content = [
    {
      title: t('module.projects'),
      leftContent: (
        <SectionModel
          headerTitle={getCount('my', 'Project') || 0}
          bottomContent={t('metrics.myProjects')}
          route={ROUTES.mySearches}
          navigateState={{ type: PROJECT_SCREEN.PROJECT, filter: PROJECT_SCREEN.MY_PROJECT }}
        />
      ),
      rightContent: (
        <SectionModel
          headerTitle={getCount('all', 'Project') || 0}
          bottomContent={t('metrics.allProjects')}
          route={ROUTES.mySearches}
          navigateState={{ type: PROJECT_SCREEN.PROJECT, filter: PROJECT_SCREEN.ALL_PROJECT }}
        />
      )
    },

    {
      title: 'Business Development',
      leftContent: (
        <SectionModel
          headerTitle={getCount('my', 'Business Development') || 0}
          bottomContent={'My BDs'}
          route={ROUTES.mySearches}
          navigateState={{ type: PROJECT_SCREEN.BUSINESS_DEVELOPMENT, filter: PROJECT_SCREEN.MY_BD }}
        />
      ),
      rightContent: (
        <SectionModel
          headerTitle={getCount('all', 'Business Development') || 0}
          bottomContent={'All BDs'}
          route={ROUTES.mySearches}
          navigateState={{ type: PROJECT_SCREEN.BUSINESS_DEVELOPMENT, filter: PROJECT_SCREEN.ALL_BD }}
        />
      )
    },
    {
      title: 'Interviews',
      leftContent: <SectionModel headerTitle={interviewCount || '0'} bottomContent={'Candidates in Partner Interview Stage'} />,
      rightContent: <SectionModel headerTitle={<CalendarTodayOutlinedIcon height={'28px'} width={'28px'} />} middleContent={'Check Scheduled Interviews'} onClick={handleInterviewPopup} />
    },
    {
      title: 'Days Open',
      leftContent: <SectionModel headerTitle={getCount('my', 'Project', true) || 0} middleContent={'Days'} bottomContent={t('metrics.myProjectsAverage')} />,
      rightContent: <SectionModel headerTitle={getCount('all', 'Project', true) || 0} middleContent={'Days'} bottomContent={t('metrics.allProjectsAverage')} />
    }
  ];
  return (
    <>
      <Grid item xs={12} height={'10%'}>
        <Typography color={theme.palette.heading.main} gutterBottom>
          Hello {name}!
        </Typography>
      </Grid>
      <Grid item xs={12} height={'35%'}>
        <Paper className={style.left_first_section}>
          {addColumns.map((item, index) => (
            <Stack
              key={index}
              color={theme.palette.primary.main}
              className={`${style.left_first_section_item} fs-1 ${index < addColumns.length - 1 && style.not_last}`}
              onClick={() => {
                navigate(item.route, { state: { ...item?.data } });
              }}
              margin={{
                lg: '16px auto',
                md: '8px auto',
                sm: '4px auto',
                xs: '2px auto'
              }}
            >
              {item.icon}
              <Typography className={` ${style.left_first_section_item_text} fs-10`}>{item.title}</Typography>
            </Stack>
          ))}
        </Paper>
      </Grid>

      {content.map((item, index) => (
        <Grid item xs={6} sm={3} height={'55%'} key={index}>
          <Paper className={style.paper_custom} width={{ md: '80%', sm: '100%' }}>
            <Stack className={style.stack_custom} spacing={2}>
              <Typography className={`${style.typography_custom} fs-10`} color={theme.palette.heading.main}>
                {item.title}
              </Typography>
              <Stack className={style.inner_stack_custom}>
                {item.leftContent}
                <Box className={style.box_custom}></Box>
                {item.rightContent}
              </Stack>
            </Stack>
          </Paper>
        </Grid>
      ))}
    </>
  );
}
LeftSection.propTypes = {
  name: proptype.string,
  handleInterviewPopup: proptype.func,
  count: proptype.object,
  interviewCount: proptype.number
};
export default LeftSection;
