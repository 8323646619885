import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

import { ArrowBack } from '@mui/icons-material';
import { ignUsersApi } from '../../../services/ApiService';
import { GET } from '../../../services/constantService';
import useApi from '../../../utils/Hooks/useApiHook';
import AddUserPopup from './components/AddUserPopup';
import ManageUser from './ManageUser';
import { columnDefs, ManageRenderer, NameRenderer, RoleRenderer } from './utils';
import CustomInputField from '../../../components/common/StyledComponents/CustomInputField';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import useDebounce from '../../../utils/Hooks/useDebounce';

const UserSecurity = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_SEARCH]);

  const { data: userData, refetch } = useApi({
    queryFn: () => ignUsersApi(GET, `getAll?search=${debouncedSearchValue}`)
  });

  const refetchAndOpenDrawer = async data => {
    await refetch().then(res => {
      if (res?.data.length > 0) {
        setCurrentUser(res.data.find(user => user.id === data.id));
        setIsDrawerOpen(true);
      }
    });
  };

  useEffect(() => {
    refetch();
  }, [debouncedSearchValue]);

  return (
    <React.Fragment>
      <div
        className='position-relative'
        style={{
          top: '50%',
          transform: 'translateY(-50%)'
        }}
      >
        <div
          style={{
            height: '90vh',
            backgroundColor: '#fff',
            marginRight: '20px',
            borderRadius: '10px'
          }}
        >
          <Box height='85%' paddingX='20px'>
            <div className='py-2 text-right d-flex justify-content-between'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3
                }}
              >
                <div
                  className={`${styles.roles_back_button}`}
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  <ArrowBack className='icon-size' /> Back
                </div>
                <CustomInputField label={t(`${TRANS_KEYS.CONTACTS_SEARCH}:key`)} size='small' value={searchValue} onChange={e => setSearchValue(e.target.value)} />
              </Box>
              <Button variant='contained' className='button-add' startIcon={<AddIcon className='icon-size' />} onClick={() => setIsPopupOpen(!isPopupOpen)}>
                Add User
              </Button>
            </div>
            <div className='ag-theme-alpine' id='myGrid'>
              <AgGridReact
                rowData={userData?.data}
                columnDefs={columnDefs}
                frameworkComponents={{
                  manageRenderer: props => <ManageRenderer {...props} refetch={refetch} setIsDrawerOpen={setIsDrawerOpen} setCurrentUser={setCurrentUser} />,
                  nameRenderer: props => <NameRenderer {...props} setIsDrawerOpen={setIsDrawerOpen} setCurrentUser={setCurrentUser} />,
                  roleRenderer: props => <RoleRenderer {...props} />
                }}
              />
            </div>
            <AddUserPopup isOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} refetch={refetch} />
          </Box>
        </div>
      </div>
      <ManageUser isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} setCurrentUser={setCurrentUser} currentUser={currentUser} refetchAndOpenDrawer={refetchAndOpenDrawer} />
    </React.Fragment>
  );
};

export default UserSecurity;
