//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Link } from 'react-router-dom';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { Box } from '@mui/material';
import BrandLogoDark from '../../../../../components/Header/BrandLogoDark';
import './index.scss';

const ClientSuiteBrandLogo = () => {
  return (
    <Box id='ClientSuiteBrandLogo'>
      <Link to='/'>
        <BrandLogoDark />
      </Link>
    </Box>
  );
};

export default ClientSuiteBrandLogo;
