import { createTheme } from '@mui/material/styles';
import variables from './variables.scss';

export const defaultTheme = (themeConfig, userConfiguration) =>
  createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true
        }
      }
    },
    props: {
      // need to fix this
      MuiDateFormat: {
        short: userConfiguration?.dateFormat || 'dd/MM/yyyy',
        medium: userConfiguration?.dateFormat + ' ' + userConfiguration?.timeFormats || 'dd/MM/yyyy',
        long: userConfiguration?.dateFormat + ' ' + userConfiguration?.timeFormats || 'dd/MM/yyyy'
      },
      MuiTimeFormat: {
        short: userConfiguration?.timeFormats || '12-hours',
        medium: userConfiguration?.timeFormats || '12-hours',
        long: userConfiguration?.timeFormats || '12-hours'
      },
      MuiTimeZoneFormat: {
        short: userConfiguration?.timeZoneFormat || 'UTC',
        medium: userConfiguration?.timeZoneFormat || 'UTC',
        long: userConfiguration?.timeZoneFormat || 'UTC'
      }
    },
    palette: {
      primary: {
        main: themeConfig?.primary || variables?.primary
      },
      secondary: {
        main: themeConfig?.secondary || variables?.secondary
      },
      text: {
        primary: themeConfig?.text || variables?.text
      },
      heading: {
        main: themeConfig?.heading || variables?.heading
      },
      error: {
        main: themeConfig?.error || variables?.error
      },
      background: {
        main: themeConfig?.background || variables?.background
      },
      titleBarBackground: {
        main: themeConfig?.titleBarBackground || variables?.titleBarBackground
      },
      success: {
        main: themeConfig?.success || variables?.success
      }
    },
    typography: {
      fontFamily: '"open-sans" !important',
      button: {
        textTransform: 'none'
      }
    },
    overrides: {
      MuiCheckbox: {
        colorSecondary: {
          '&$checked': {
            color: themeConfig?.secondary || variables?.secondary
          }
        }
      }
    }
  });
