//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchPickListData } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';
// import { fetchPickList } from "../../../../actions";
const CurrencySelection = props => {
  const {
    value,
    name = '',
    onChange = () => {},
    disabled = false,
    isDrawer = false,
    multiple = false,
    placeholder,
    disableCloseOnSelect = false,
    className = '',
    isCheckBox = false,
    label = '',
    defaultValue = '',
    required = false,
    style = {}
  } = props;
  const [options, setOptions] = useState([]);
  const [optionsValue, setOptionsValue] = useState([]);
  const currencies = useSelector(state => state.commonReducer.currencies);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currencies) {
      dispatch(fetchPickListData('currencies'));
    } else {
      setOptions(currencies);
    }
  }, [currencies, dispatch]);

  useEffect(() => {
    const selectedItem = options.map(item => {
      return {
        name: item.name,
        label: item.code,
        id: item.id,
        currency_icon: item.currency_icon
      };
    });
    setOptionsValue(selectedItem);
  }, [options]);
  return (
    <CustomDropdown
      style={style}
      options={optionsValue}
      value={value}
      defaultValue={defaultValue}
      name={name}
      onChange={onChange}
      disabled={disabled}
      isDrawer={isDrawer}
      multiple={multiple}
      placeholder={placeholder}
      disableCloseOnSelect={disableCloseOnSelect}
      className={className}
      isCheckBox={isCheckBox}
      label={label}
      required={required}
    />
  );
};

CurrencySelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isDrawer: PropTypes.bool,
  multiple: PropTypes.bool,
  isCheckBox: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.object
};

export default CurrencySelection;
