import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomPopup from '../../../components/common/CustomPopup';
import Loader from '../../../components/common/Loader';
import { echoSenseApi } from '../../../services/ApiService';
import { ERROR, GET, POST, PUT, STANDARD_ERROR_MESSAGE, SUCCESS } from '../../../services/constantService';
import { sleepAsync } from '../../../utils/common';
import { ANALYSIS_TYPE_LIST, getDefaultDecisionOptions, showToast } from '../constants';
import { getViewedProgress, storeViewedProgress } from '../helpers';
import '../index.scss';
import AudioUploadForm from './escnr/AudioUploadForm';
import RequestFileUploadManager from './escnr/RequestFileUploadManager';
import TranscriptHeaderInputs from './escnr/TranscriptHeaderInputs';
import TranscriptUploadForm from './escnr/TranscriptUploadForm';
import TranscriptionInProcessMessage from './escnr/TranscriptionInProcessMessage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24
};

export default function EchoSenseCreateNewRequest(props) {
  const {
    subRoute = null,
    open = false,
    setOpen = () => {},
    defaultFile = null,
    defaultProject = null,
    defaultCandidate = null,
    defaultFileType = null,
    refetch = () => {},
    navigateTo = () => {},
    processingList = [],
    completedList = [],
    analyzeTranscript = () => {},
    downloadReport = () => {},
    isFromLogAnActivity = false
  } = props;
  const { t } = useTranslation();
  const [fileType, setFileType] = useState(null);
  const [file, setFile] = useState(null);
  const [project, setProject] = useState(null);
  const [listOfProjectCandidates, setListOfProjectCandidates] = useState([]);
  const [listOfProject, setListOfProject] = useState([]);
  const [projectCandidate, setProjectCandidate] = useState(null);
  const [friendlyName, setFriendlyName] = useState(null);
  const [noOfSpeaker, setNoOfSpeaker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [audioUploadRes, setAudioUploadRes] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({
    showProgress: false,
    progress: 0
  });
  const [analysisType, setAnalysisType] = useState('Consultant Interview');
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [speakerData, setSpeakerData] = useState([]);
  const [selectedDecisions, setSelectedDecisions] = useState(getDefaultDecisionOptions().map(option => option.value));
  const [isOnClosePopupOpen, setIsOnClosePopupOpen] = useState(false);
  const [viewedRecords, setViewRecords] = useState(getViewedProgress());

  const handleClick = useCallback(taskId => {
    storeViewedProgress(taskId);
    setViewRecords(getViewedProgress());
  }, []);

  const filteredCompletedList = useMemo(() => completedList.filter(item => !viewedRecords.includes(item.id)), [completedList, viewedRecords]);

  useEffect(() => {
    setProject(defaultProject);
    setProjectCandidate(defaultCandidate);
    setFileType(defaultFileType);
    setFile(defaultFile);
  }, [defaultProject, defaultCandidate, defaultFileType, defaultFile]);

  useEffect(() => {
    if (open && defaultCandidate && defaultCandidate?.contact_id) {
      fetchCandidateProjects(defaultCandidate);
    }
  }, [defaultCandidate, open]);

  useEffect(() => {
    fetchProjectCandidates();
  }, [project]);

  const clear = () => {
    setFileType(null);
    setFile(null);
    setProject(null);
    setListOfProjectCandidates([]);
    setListOfProject([]);
    setProjectCandidate(null);
    setFriendlyName(null);
    setNoOfSpeaker(null);
    setAudioUploadRes(false);
    setAnalysisType('Consultant Interview');
    setSelectedLanguage('English');
    setSelectedDecisions(getDefaultDecisionOptions().map(option => option.value));
    setUploadLoading(false);
  };

  const fetchCandidateProjects = async candidate => {
    try {
      setIsLoading(true);
      if (candidate && candidate.contact_id) {
        const projects = await echoSenseApi(GET, `projects?contactId=${candidate.contact_id}`);
        setListOfProject(projects.data.data);
      } else {
        setListOfProject([]);
      }
    } catch (err) {
      setListOfProject([]);
      showToast(err.message || 'Unable to fetch candidates', ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = (fetchAgain = false, clearData = true, forceClose = true) => {
    if (uploadLoading) return;
    if (file && !forceClose) {
      setIsOnClosePopupOpen(true);
      return;
    }
    if (fetchAgain && !isFromLogAnActivity) refetch();
    if (clearData) clear();
    setOpen(false);
    setUploadProgress({ showProgress: false, progress: 0 });
  };

  const validateAnalyzeFields = () => {
    let notFound = [];
    if (!file) {
      notFound.push('File');
    }
    if (!analysisType) {
      notFound.push('Analysis Type');
    }
    if (ANALYSIS_TYPE_LIST.slice(0, 2).includes(analysisType) && !project) {
      notFound.push(t('module.project'));
    }
    if (!friendlyName) {
      notFound.push('Friendly Name');
    }
    if (!selectedLanguage) {
      notFound.push('Report Language');
    }

    return { status: notFound.length > 0, notFound };
  };

  const submitAnalyzeRequest = async () => {
    try {
      setUploadLoading(true);
      let fieldValidation = validateAnalyzeFields();
      if (fieldValidation.status) {
        showToast(`${fieldValidation.notFound.toString()} not found, please enter these field(s).`, ERROR);
        setUploadLoading(false);
        return;
      }
      const formData = new FormData();
      let speakers = speakerData.map(item => item.speaker);
      formData.append('friendly_name', friendlyName);
      formData.append('report_language', selectedLanguage);
      formData.append('analysis_type', analysisType);
      formData.append('decisions', selectedDecisions);
      formData.append('speakers', speakers.toString());
      if (projectCandidate && projectCandidate?.full_name) {
        formData.append('candidate_name', projectCandidate?.full_name);
      }
      if (projectCandidate && projectCandidate?.contact_id) {
        formData.append('contact_id', projectCandidate?.contact_id);
      }
      if (projectCandidate && projectCandidate?.candidate_id) {
        formData.append('candidate_id', projectCandidate?.candidate_id);
      }
      if (project && project?.id) {
        formData.append('project_id', project?.id);
      }

      if (file.transcribeId) {
        formData.append('transcribeId', file.transcribeId);
      } else {
        formData.append('file', file);
      }
      const { data, status } = await echoSenseApi(POST, 'analyze', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      if (status == 200) {
        showToast(t('echoSense.requestCreated'), SUCCESS);
        handleClose(true, true);
        if (file.transcribeId) {
          navigateTo('analyze');
        }
      } else {
        showToast(data.msg || 'Failed to added new request for Analysis.', ERROR);
        setUploadLoading(false);
      }
    } catch (err) {
      setUploadLoading(false);
      showToast(t('common.somethingWentWrong'), ERROR);
    }
  };

  const fetchProjectCandidates = async () => {
    try {
      setIsLoading(true);
      if (project && project.id) {
        const candidates = await echoSenseApi(GET, `candidates?projectId=${project.id}`);
        setListOfProjectCandidates(candidates.data.data);
      } else {
        setListOfProjectCandidates([]);
      }
    } catch (err) {
      setListOfProjectCandidates([]);
      showToast(err.message || 'Unable to fetch candidates', ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const validateTranscribeFields = () => {
    let notFound = [];
    if (!file) {
      notFound.push('File');
    }
    if (!friendlyName) {
      notFound.push('Friendly Name');
    }
    if (!noOfSpeaker) {
      notFound.push('Number of Speakers');
    }
    return { status: notFound.length > 0, notFound };
  };
  const beginTranscribe = async () => {
    setUploadLoading(true);
    let fieldValidation = validateTranscribeFields();
    if (fieldValidation.status) {
      showToast(`${fieldValidation.notFound.toString()} not found, please enter these field(s).`, ERROR);
      setUploadLoading(false);
      return;
    }
    const payload = {
      file_name: file.name,
      file_type: file.type,
      project_id: project?.id,
      friendly_name: friendlyName,
      no_of_speakers: noOfSpeaker,
      candidate_id: projectCandidate?.candidate_id,
      contact_id: projectCandidate?.contact_id
    };
    const response = await echoSenseApi(POST, 'transcribe', payload);
    let dataToUse = response.data;
    if (typeof dataToUse === 'string') {
      dataToUse = JSON.parse(dataToUse);
    }
    if (response.status !== 200) {
      setUploadLoading(false);
      showToast(dataToUse.msg || 'Failed to create record.', ERROR);
      return;
    }
    if (dataToUse.error) {
      setUploadLoading(false);
      showToast(dataToUse.msg, ERROR);
      return;
    }
    if (window.location.hostname.includes('localhost')) {
      await sleepAsync(2000);
      showToast('Access denied from localhost', ERROR);
      console.info(`Use the presignedUrl "${dataToUse.data.presignedUrl}" and upload via ThunderClient or Postman`);
      // setAudioUploadRes(data);
      // setFile(file);
      // setFileType('audio');
      setUploadLoading(false);
      if (!isFromLogAnActivity) refetch();
      return;
    }
    if (dataToUse.data.presignedUrl) {
      try {
        setUploadProgress({ showProgress: true, progress: 0 });
        await axios.put(dataToUse.data.presignedUrl, file, {
          headers: { 'Content-Type': file.type, 'Cache-Control': 'no-store' },
          onUploadProgress: progressEvent => {
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress({ showProgress: true, progress: percentage });
          }
        });

        const updateRecord = await echoSenseApi(PUT, 'transcribe-upload-complete', {
          taskId: dataToUse.data.taskId,
          success: true,
          error_reason: null
        });
        if (updateRecord.status == 200) {
          setAudioUploadRes(dataToUse);
          setFile(null);
          showToast(t('echoSense.transcribeProcessing'), SUCCESS);
        } else {
          showToast(updateRecord.data.msg || 'Failed to update record', ERROR);
        }
        setUploadLoading(false);
      } catch (err) {
        setUploadProgress({ showProgress: false, progress: 0 });
        const updateRecord = await echoSenseApi(PUT, 'transcribe-upload-complete', {
          taskId: dataToUse.data.taskId,
          success: false,
          error_reason: err.toString()
        });
        const { error, msg } = updateRecord.data;
        if (error) {
          showToast(msg, ERROR);
        } else {
          showToast(t('echoSense.audioUploadError'), ERROR);
          setAudioUploadRes(dataToUse);
          setFile(null);
          setFileType(null);
        }
        setUploadLoading(false);
        handleClose(true);
        return;
      }
      if (!isFromLogAnActivity) refetch();
      setAudioUploadRes(dataToUse);
      setFileType(null);
      setFile(null);
      setUploadProgress({ showProgress: false, progress: 0 });
      setUploadLoading(false);
    } else {
      setUploadLoading(false);
      showToast(STANDARD_ERROR_MESSAGE, ERROR);
      return;
    }
  };

  return (
    <>
      <Loader show={isLoading} />
      <CustomPopup
        open={isOnClosePopupOpen}
        handleSubmit={async () => {
          //remove file from be
          setFile(null);
          handleClose();
          setIsOnClosePopupOpen(false);
          setOpen(false);
          clear();
        }}
        onClose={() => setIsOnClosePopupOpen(false)}
        title={t('actions.confirm')}
        confirmText={t('actions.confirm')}
        cancelText={t('actions.cancel')}
        showAction={true}
      >
        <div>{t('echoSense.closeConfirm')}</div>
      </CustomPopup>
      <Modal open={open} onClose={() => handleClose(false, true, false)} style={{ borderRadius: 2 }}>
        <Box sx={{ ...style, maxWidth: '90%', minWidth: '70%', border: 'none' }}>
          <Box
            className='px-3 py-2 modal-title'
            style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ebedf1', paddingBottom: '.6rem', marginBottom: '1rem', alignItems: 'center' }}
          >
            <Typography variant='h4' style={{ fontWeight: 'bolder', fontSize: '1rem' }}>
              {t('echoSense.createNewRequest')}
            </Typography>

            <IconButton onClick={() => handleClose(true, true, false)} style={{ cursor: 'pointer', color: 'white' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className='row m-3 align-items-center'>
            {!file && !fileType && (
              <TranscriptionInProcessMessage
                isFromLogAnActivity={isFromLogAnActivity}
                analyzeTranscript={analyzeTranscript}
                downloadReport={downloadReport}
                subRoute={subRoute}
                fileType={fileType}
                processingList={processingList}
                completedList={filteredCompletedList}
                handleClose={handleClick}
              />
            )}
            {fileType == 'transcript' && (
              <TranscriptHeaderInputs
                projectMandatory={ANALYSIS_TYPE_LIST.slice(0, 2).includes(analysisType)}
                listOfProjectCandidates={listOfProjectCandidates}
                project={project}
                projectCandidate={projectCandidate}
                setProject={setProject}
                setProjectCandidate={setProjectCandidate}
                isFromLogAnActivity={isFromLogAnActivity}
                listOfProject={listOfProject}
              />
            )}
            <RequestFileUploadManager
              file={file}
              audioUploadRes={audioUploadRes}
              fileType={fileType}
              setFile={setFile}
              setFileType={setFileType}
              setAudioUploadRes={setAudioUploadRes}
              setSpeakerData={setSpeakerData}
            />
          </Box>
          {!audioUploadRes && file && fileType == 'audio' && (
            <AudioUploadForm
              submitDisabled={!friendlyName || !noOfSpeaker || noOfSpeaker == 0}
              uploadLoading={uploadLoading}
              uploadProgress={uploadProgress}
              project={project}
              setProject={setProject}
              friendlyName={friendlyName}
              setFriendlyName={setFriendlyName}
              noOfSpeaker={noOfSpeaker}
              setNoOfSpeaker={setNoOfSpeaker}
              beginTranscribe={beginTranscribe}
              listOfProjectCandidates={listOfProjectCandidates}
              projectCandidate={projectCandidate}
              setProjectCandidate={setProjectCandidate}
              isFromLogAnActivity={isFromLogAnActivity}
              listOfProject={listOfProject}
            />
          )}
          {!audioUploadRes && !file && !fileType && !processingList.length && !filteredCompletedList.length && (
            <>
              <hr />
              <Box className='fs-16 m-3'>
                <Box className='bold my-2'>Help:</Box>
                <Box>
                  {t('echoSense.zoomInstruction')},{' '}
                  <a className='custom-link' href='https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0057886' target='_blank' rel='noreferrer'>
                    click here
                  </a>
                  .
                </Box>
              </Box>
            </>
          )}
          {file && fileType == 'transcript' && (
            <TranscriptUploadForm
              file={file}
              analysisTypeList={ANALYSIS_TYPE_LIST}
              submitDisabled={!friendlyName || !analysisType}
              uploadLoading={uploadLoading}
              analysisType={analysisType}
              setAnalysisType={setAnalysisType}
              setFriendlyName={setFriendlyName}
              friendlyName={friendlyName}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
              submitAnalyzeRequest={submitAnalyzeRequest}
              selectedDecisions={selectedDecisions}
              setSelectedDecisions={setSelectedDecisions}
              speakerData={speakerData}
              setSpeakerData={setSpeakerData}
              projectCandidate={projectCandidate}
            />
          )}
        </Box>
      </Modal>
    </>
  );
}

EchoSenseCreateNewRequest.propTypes = {
  subRoute: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  defaultProject: PropTypes.object,
  defaultCandidate: PropTypes.object,
  defaultFileType: PropTypes.string,
  defaultFile: PropTypes.object,
  refetch: PropTypes.func,
  navigateTo: PropTypes.func,
  processingList: PropTypes.array,
  completedList: PropTypes.array,
  analyzeTranscript: PropTypes.func,
  downloadReport: PropTypes.func,
  isFromLogAnActivity: PropTypes.bool
};
