import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { Box, TableFooter } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import '../../../../src/utils/common.scss';
import styles from '../../../variables.scss';
import CustomButton from '../CustomButton';
import MakeConfidential from '../MakeConfidential/MakeConfidential';
import './index.scss';
export default function CustomTable(props) {
  const {
    children,
    headerData,
    title,
    handleAnotherRow,
    titleSize = 'fs-16',
    headerSize = 'fs-14',
    rows,
    isEditing = false,
    onEditChange,
    customTableId = '',
    showAddAnotherButton = true,
    isShowApprovalCheckbox = false,
    checked = false,
    handleChangeCheckBox = () => {},
    checkboxLabel = '',
    isShowHeader = true,
    isDrawer = false,
    updateField,
    handleCloseClick = () => {},
    saveDataKey = '',
    dataList,
    isShowDoneIconForAddress,
    addContact = false,
    handleAddContact = () => {},
    disabledDone = false,
    onEditCancel = () => {},
    isDisabled = false,
    ...res
  } = props;
  const [isShowDoneIcon, setIsShowDoneIcon] = useState(false);
  const { error: errorColor } = styles;
  const [isShowEdit, setIsShowEdit] = useState(true);
  const handleEdit = () => {
    setIsShowDoneIcon(true);
    onEditChange(false);
    setIsShowEdit(false);
  };
  const handleDone = async () => {
    let result = await updateField(saveDataKey, false, handleCloseClick);
    if (result) {
      handleClose();
    }
  };

  const handleClose = () => {
    if (saveDataKey === 'contact_address') {
      handleCloseClick('contact_address');
    } else if (saveDataKey === 'company_address') {
      handleCloseClick('company_address');
    }
    setIsShowDoneIcon(false);
    onEditChange(true);
    setIsShowEdit(true);
  };
  return (
    <Box id={customTableId} {...res}>
      <TableContainer component={Paper} className='paper-border'>
        <Table aria-label='customized table'>
          <TableHead>
            <TableRow className='table-header-background'>
              <TableCell colSpan={headerData?.length + 1} className='font-weight-bold p-2 title-content'>
                <Box className='d-flex justify-content-between align-items-center '>
                  <Box className={titleSize}>
                    {title}
                    <Box component='span' onClick={handleEdit}>
                      {isShowEdit && isEditing && <EditIcon className='table-edit-icon'></EditIcon>}
                    </Box>
                    {(isShowDoneIcon || isShowDoneIconForAddress) && (
                      <Box component='span'>
                        <Box component='span'>
                          <DoneIcon
                            className='table-edit-icon'
                            style={{
                              color: disabledDone ? 'grey' : 'white'
                            }}
                            onClick={handleDone}
                          ></DoneIcon>
                        </Box>
                        <Box component='span'>
                          <Box
                            component='span'
                            onClick={(...rest) => {
                              onEditCancel?.();
                              handleClose(...rest);
                            }}
                            className='delete-icon-pointer'
                          >
                            <CloseIcon className='table-editclose-icon' htmlColor={errorColor}></CloseIcon>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {isShowApprovalCheckbox && (
                    <Box id='is-approved-checkbox-view'>
                      <MakeConfidential defaultChecked={checked} handleChange={handleChangeCheckBox} name={checkboxLabel} isHiddenDefaultPadding={true} />
                    </Box>
                  )}
                </Box>
              </TableCell>
            </TableRow>
            {isShowHeader && (
              <TableRow className='header-color'>
                {headerData?.map((header, index) => (
                  <TableCell align='center' key={index} className={`${headerSize} p-2 table-header-content ${isDrawer ? 'viewContent' : ''}`}>
                    {header}
                  </TableCell>
                ))}
                {rows?.length > 1 && <TableCell></TableCell>}
              </TableRow>
            )}
          </TableHead>
          <TableBody>{children}</TableBody>
          <TableFooter sx={{ borderRadius: '20px' }}>
            {(showAddAnotherButton || isShowDoneIcon || isShowDoneIconForAddress) && (
              <Box>
                <CustomButton
                  onClick={handleAnotherRow}
                  iconLeft={<AddIcon sx={{ width: 14 }} />}
                  customWidth={96}
                  buttonText='Add Another'
                  type={'tertiary'}
                  variant='text'
                  size={'small'}
                  disabled={rows?.length ? dataList?.length === rows?.length || isDisabled : isDisabled}
                ></CustomButton>
              </Box>
            )}
          </TableFooter>
          <TableFooter sx={{ borderRadius: '20px' }}>
            {addContact && !isEditing && (
              <Box className='fs-10 ml-2 mb-2 add-contact-table' onClick={handleAddContact}>
                {<AddIcon sx={{ width: 10 }} />} {'Add Contact'}
              </Box>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
}
CustomTable.propTypes = {
  headerData: PropTypes.array,
  title: PropTypes.string,
  children: PropTypes.element,
  handleAnotherRow: PropTypes.func,
  handleRemoveRows: PropTypes.func,
  updateField: PropTypes.func,
  handleCloseClick: PropTypes.func,
  saveDataKey: PropTypes.string,
  rows: PropTypes.array,
  disable: PropTypes.number,
  isEditing: PropTypes.bool,
  isShowDoneIconForAddress: PropTypes.bool,
  onEditChange: PropTypes.func,
  customTableId: PropTypes.string,
  showAddAnotherButton: PropTypes.bool,
  isShowApprovalCheckbox: PropTypes.bool,
  checked: PropTypes.bool,
  handleChangeCheckBox: PropTypes.func,
  checkboxLabel: PropTypes.string,
  titleSize: PropTypes.string,
  headerSize: PropTypes.string,
  isShowHeader: PropTypes.bool,
  headerCell: PropTypes.bool,
  isDrawer: PropTypes.bool,
  dataList: PropTypes.array,
  selectionList: PropTypes.array,
  addContact: PropTypes.bool,
  handleAddContact: PropTypes.func,
  disabledDone: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onEditCancel: PropTypes.func
};
