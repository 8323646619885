import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

/**
options = { name : "Button Name" , onClick=(data)=> func(data)} 
*/
export const CustomActionRenderer = ({ options = [], item, otherButtons = [] }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [renderMenu, setRenderMenu] = useState([]);

  const handleMenuOpen = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  useEffect(() => {
    setRenderMenu([...options]);
  }, [options]);

  return (
    <div className='d-flex align-items-center justify-content-center'>
      {otherButtons.map((button, index) => {
        return <button.component key={index} onClick={() => button.onClick(item)} />;
      })}
      {renderMenu.length > 0 && (
        <>
          <IconButton style={{ cursor: 'pointer' }} onClick={handleMenuOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id='lock-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox'
            }}
          >
            {renderMenu.map(option => (
              <MenuItem
                onClick={() => {
                  option.onClick(item);
                  handleClose();
                }}
                key={option.name}
                className='fs-14'
              >
                {option.name}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </div>
  );
};

CustomActionRenderer.propTypes = {
  options: PropTypes.array,
  item: PropTypes.object,
  otherButtons: PropTypes.array
};
