import * as yup from 'yup';
import { COMPARE_DATE, FROM, INVALID_DATE, INVALID_MESSAGE, TO } from '../../services/constantService';
import { isEmpty, sortByChronologicalOrder } from '../../utils/common';
export const getCleanedContact = (data, skipKgField = false, useData) => {
  const cleanupData = cleanupJobHistoryData(data);
  const contact = cleanUpCompensation(cleanupData);
  contact.first_name = contact.first_name?.trim();
  contact.last_name = contact.last_name?.trim();
  // Backend expect the value if none selected removing
  if (!contact['gender']) {
    delete contact['gender'];
  }

  delete contact['image_id'];

  if (!contact.age) {
    contact.age = null;
  }
  if (!skipKgField) {
    if (contact.kg) {
      const arr = [];
      contact.kg.forEach(element => {
        arr.push(element.id);
      });
      contact.kg = arr;
    }
    // rename kg to kg_25 as per requirement for db schema
    delete Object.assign(contact, { kg_25: contact['kg'] });
    delete contact.kg;
  }
  // Adding as per expected keys
  if (contact['contact_company']) {
    contact['current_company_id'] = contact['contact_company']?.id;
  }

  delete contact['contact_company'];
  //   if (contact["contact_industries"]) {
  //     contact["contact_industries"].forEach((elem) => {
  //       elem["updated_by"] = useData.id;
  //       elem["industry_id"] = elem.id;
  //       delete elem["name"];
  //       delete elem["id"];
  //     })
  //   }
  //   if (contact["contact_job_functions"]) {
  //     contact["contact_job_functions"].forEach((elem) => {
  //       elem["updated_by"] = useData.id;
  //       elem["job_function_id"] = elem.id;
  //       delete elem["name"];
  //       delete elem["id"];
  //     })
  //   }

  contact['country'] = contact['country']?.name || contact.country;
  if (contact.bd_target) {
    contact.bd_target_id = contact.bd_target.id;
    delete contact.bd_target;
  } else {
    contact.bd_target_id = null;
  }
  if (contact.mailing_list) {
    contact.mailing_list = contact.mailing_list.map(data => {
      return data.id;
    });
  }

  if (contact && contact.state && typeof contact.state !== 'string') {
    if (contact.state.inputValue) {
      contact.state = contact.state.inputValue;
    } else {
      contact.state = contact.state.title;
    }
  }
  //Adding for phone input clear issue
  contact.direct_line = contact.direct_line === '+' ? null : contact.direct_line;
  contact.mobile_phone = contact.mobile_phone === '+' ? null : contact.mobile_phone;
  contact.home_phone = contact.home_phone === '+' ? null : contact.home_phone;
  contact.assistant_direct_line = contact.assistant_direct_line === '+' ? null : contact.assistant_direct_line;
  contact.assistant_mobile_phone = contact.assistant_mobile_phone === '+' ? null : contact.assistant_mobile_phone;

  contact['job_history'] = contact.job_history && sortByChronologicalOrder(cleanupCompanyDetails(contact['job_history'], contact.id));
  contact['board_details'] = contact.board_details && cleanupCompanyDetails(contact['board_details'], contact.id);
  contact['education_details'] = contact.education_details && addPositionByIndex(contact.education_details, contact.id);
  contact['international_experience'] = contact.international_experience && contact.international_experience.map(item => item?.name || item);
  if (contact['contact_record_type']) {
    contact['contact_record_type'].forEach(elem => {
      elem['created_by'] = useData?.id;
    });
  }
  if (contact['contact_added_by']) {
    contact['contact_added_by'].forEach(elem => {
      elem['created_by'] = useData?.id;
      elem['updated_by'] = useData?.id;
    });
  }
  // if (contact["contact_address"]) {
  //   contact["contact_address"].forEach((elem) => {
  //     elem["created_by"] = useData?.id;
  //   })
  // }
  if (contact['contact_emails']) {
    contact['contact_emails'].forEach(elem => {
      elem['created_by'] = useData?.id;
    });
  }

  if (contact['contact_phones']) {
    contact['contact_phones'].forEach(elem => {
      elem['created_by'] = useData?.id;
    });
  }

  if (contact['contact_address']) {
    contact['contact_address'].forEach(elem => {
      elem['updated_by'] = useData?.id;
    });
  }

  if (contact.from === 'Work Experience') {
    const workPayload = {
      contact_work_experience: [
        {
          city: data?.city,
          company_id: data?.company_id,
          description: data?.description,
          state_id: data?.state_id,
          title: data?.title,
          country_id: data?.country_id,
          start_date: data?.start_date,
          end_date: data?.end_date,
          is_present: data?.is_present,
          board_is_present: data?.board_is_present,
          board_end_date: data?.board_end_date,
          board_start_date: data?.board_start_date,
          board_committee: data?.board_committee,
          contact_id: data?.contact_id,
          created_by: useData?.id,
          updated_by: useData?.id
        }
      ]
    };
    if (data.id) {
      workPayload.contact_work_experience[0].id = data?.id;
      delete workPayload?.contact_work_experience[0]?.created_by && workPayload?.contact_work_experience[0]?.created_by;
    }
    if (data?.is_present === true) {
      delete workPayload?.contact_work_experience[0]?.end_date && workPayload?.contact_work_experience[0]?.end_date;
    }
    return workPayload;
  }
  if (contact.from === 'Education Details') {
    const educationPayload = {
      contact_education_details: [
        {
          contact_id: data?.contact_id,
          country_id: data?.country_id,
          degree_name: data?.degree_name,
          start_date: data?.start_date,
          end_date: data?.end_date,
          is_present: data?.is_present,
          // major: data?.major,
          school_name: data?.school_name,
          created_by: useData?.id,
          updated_by: useData?.id
        }
      ]
    };
    if (data.id) {
      educationPayload.contact_education_details[0].id = data?.id;
      delete educationPayload?.contact_education_details[0]?.created_by && educationPayload?.contact_education_details[0]?.created_by;
    }
    return educationPayload;
  }

  // if (contact["compensation_bonus"]) {
  //   const compensation_table = {
  //     ...contact.compensation_bonus.compensation_bonus
  //   };

  //   return compensation_table
  // }

  return contact;
};

export const addPositionByIndex = (details, contact_id) => {
  if (Array.isArray(details) && details?.length !== 0) {
    return details.map((item, position) => ({ ...item, position, contact_id }));
  }
  return [{}];
};

export const cleanupCompanyDetails = (companyDetails, contact_id) => {
  if (Array.isArray(companyDetails) && companyDetails?.length !== 0) {
    return companyDetails
      ?.filter(element => {
        if (element?.company && element?.company?.id) {
          return element;
        }
      })
      .map((item, position) => {
        return {
          ...item,
          company_id: item.company.id,
          position,
          contact_id: contact_id
        };
      });
  }
  return [{}];
};

export const cleanUpCompensation = data => {
  let contact = { ...data };
  contact['target_bonus_value'] = parseFloat(contact['target_bonus_value']);
  contact['actual_bonus_value'] = parseFloat(contact['actual_bonus_value']);
  if (contact['currency_iso_code'] && contact['currency_iso_code'].code) {
    contact['currency_iso_code'] = contact['currency_iso_code'].code;
  }
  return contact;
};
const cleanupJobHistoryData = data => {
  let contact = { ...data };
  contact['contact_job_history'] = [
    {
      title: contact?.start_date ? null : contact?.title,
      country: contact?.job_history_country ? contact?.job_history_country : null,
      state: contact?.job_history_state ? contact?.job_history_state : null,
      city: contact?.job_history_city ? contact?.job_history_city : null,
      end_year: contact?.end_year,
      start_year: contact?.start_year,
      description: contact?.job_history_description,
      contact_company: contact?.job_history_contact_company ? contact?.job_history_contact_company : null,
      contact_time_period: contact?.contact_time_period ? contact?.contact_time_period : false,
      contact_board: {
        start_year: contact?.contact_board_from_year ? contact?.contact_board_from_year : null,
        end_year: contact?.contact_board_end_year ? contact?.contact_board_end_year : null,
        committee_details: contact?.contact_board_committee_details ? contact?.contact_board_committee_details : null
      }
    }
  ];
  // delete contact?.title && contact.title;
  delete contact?.job_history_country && contact?.job_history_country;
  delete contact?.job_history_state && contact?.job_history_state;
  delete contact?.job_history_city && contact?.job_history_city;
  delete contact?.end_year && contact?.end_year;
  delete contact?.start_year && contact?.start_year;
  delete contact?.job_history_description && contact?.job_history_description;
  delete contact?.job_history_contact_company && contact?.job_history_contact_company;
  delete contact?.contact_board_from_year && contact?.contact_board_from_year;
  delete contact?.contact_board_end_year && contact?.contact_board_end_year;
  delete contact?.contact_board_committee_details && contact?.contact_board_committee_details;
  delete contact?.contact_time_period && contact?.contact_time_period;

  return contact;
};

export const removeHtmlTags = str => {
  if (str === null || str === '') return false;
  else str = str.toString();
  return str.replace(/<[^>]*>/g, '');
};

export const capitalizeFirstLetter = str => {
  if (str) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  } else {
    return;
  }
};
export const validateDate = (fromDate, toDate) => {
  const fromDateJsDate = new Date(fromDate);
  const toDateJsDate = new Date(toDate);
  /* if (isNaN(fromDateJsDate)) {
    return {
      isValid: false,
      message: INVALID_DATE,
      field: FROM,
    };
  }
  if (isNaN(toDateJsDate)) {
    return {
      isValid: false,
      message: INVALID_DATE,
      field: TO,
    };

  } */
  if (fromDateJsDate.getFullYear() < 1900 || fromDateJsDate.getFullYear() > 2100) {
    return {
      isValid: false,
      message: INVALID_DATE,
      field: FROM
    };
  }

  if (toDateJsDate.getFullYear() < 1900 || toDateJsDate.getFullYear() > 2100) {
    return {
      isValid: false,
      message: INVALID_DATE,
      field: TO
    };
  }

  if (toDate === null) {
    return {
      isValid: true,
      message: ''
    };
  }

  if (fromDateJsDate.getTime() > toDateJsDate.getTime() || (fromDate === null && toDate !== null)) {
    return {
      isValid: false,
      message: INVALID_MESSAGE,
      field: COMPARE_DATE
    };
  }
  return {
    isValid: true,
    message: ''
  };
};

export const CreateSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required()
  // contact_address: yup.array().of(
  //   yup.object().shape({
  //     country_id: yup.string().required(),
  //   })
  // ),
});

export const CreateSchemaQuickAddContact = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required()
});

export const createSchemaWorkExperience = yup.object().shape({
  title: yup.string().required(),
  company_id: yup.string().required()
});
export const createSchemaEducationDetails = yup.object().shape({
  degree_name: yup.string().required(),
  major: yup.string().required()
});
export const createRequiredFieldWorkExperience = [
  { fieldName: 'title', label: 'Job Title', type: String },
  { fieldName: 'company_id', label: 'Company Name', type: String }
];
export const createRequiredFieldEducationDetails = [
  { fieldName: 'degree_name', label: 'Level Of Education', type: String }
  // { fieldName: 'major', label: 'Field of Study', type: String }
];

export const createRequiredField = [
  { fieldName: 'first_name', label: 'First Name', type: String },
  { fieldName: 'last_name', label: 'Last Name', type: String }
];

export const createRequiredFieldQuickAddContact = [
  { fieldName: 'first_name', label: 'First Name', type: String },
  { fieldName: 'last_name', label: 'Last Name', type: String }
];

export const getProfileDetailsDefaultValues = activityInfo => {
  if (isEmpty(activityInfo)) return null;
  let tabInfo = {
    ...activityInfo
  };

  return tabInfo;
};
export const removeUnusedField = (formValues, dirtyFields) => {
  SKIP_FIELDS_LIST.forEach(ele => delete formValues[ele]);
  let isDirtyFields = dirtyFields;
  let newForm = {};
  isDirtyFields.forEach(field => {
    Object.keys(field).forEach(key => {
      if (key in formValues) {
        newForm[key] = formValues[key];
      }
    });
  });

  return newForm;
};
export const getProfileInfoPayload = (formValues = {}, dirtyFields = [], userData) => {
  const removeUnused = removeUnusedField(formValues, dirtyFields);
  if (removeUnused.contact_company || removeUnused.contact_company === null) {
    if (removeUnused.contact_company) {
      removeUnused.current_company_id = formValues?.contact_company?.id ? formValues?.contact_company?.id : null;
    } else {
      removeUnused.current_company_id = null;
    }
  }
  if (removeUnused['contact_emails']) {
    removeUnused['contact_emails'].forEach(elem => {
      elem['created_by'] = userData.id;
      elem['updated_by'] = userData.id;
    });
  }
  if (removeUnused['contact_record_types']) {
    removeUnused['contact_record_types'].forEach(elem => {
      elem['created_by'] = userData.id;
      elem['updated_by'] = userData.id;
    });
  }
  if (removeUnused['contact_added_by']) {
    removeUnused['contact_added_by'].forEach(elem => {
      elem['created_by'] = userData.id;
      elem['updated_by'] = userData.id;
    });
    if (removeUnused['contact_relationship']) {
      removeUnused['contact_relationship'].forEach(elem => {
        elem['created_by'] = userData.id;
        elem['updated_by'] = userData.id;
      });
    }
  }

  if (removeUnused['contact_relationship_type']) {
    removeUnused['contact_relationship_type'] = removeUnused['contact_relationship_type'].filter(elem => {
      delete elem['contact_data'];
      delete elem['createdAt'];
      delete elem['id'];
      delete elem['updatedAt'];
      delete elem['ign_translate_value'];
      return elem.contact_ref_id && elem.type;
    });
  }

  if (!Object.keys(removeUnused)?.length) return false;
  return {
    ...removeUnused
  };
};

export const getEducationLanguagePayload = (formValues = {}, dirtyFields = [], userData, id, currentValues, isEdit, indexValue, isPopupOpen) => {
  const removeUnused = removeUnusedField(formValues, dirtyFields);
  if (removeUnused['contact_languages']) {
    removeUnused['contact_languages'].forEach(elem => {
      elem['created_by'] = userData.id;
      elem['updated_by'] = userData.id;
      elem['language_id'] = elem.language?.id;
    });
    if (!Object.keys(removeUnused)?.length) return false;
    return {
      ...removeUnused
    };
  }
  if (removeUnused['contact_certificate']) {
    removeUnused['contact_certificate'].forEach(elem => {
      elem['created_by'] = userData.id;
      elem['updated_by'] = userData.id;
    });
    if (!Object.keys(removeUnused)?.length) return false;
    return {
      ...removeUnused
    };
  } else {
    if (isPopupOpen) {
      const workPayload = {};
      if (!isEdit) {
        workPayload['contact_education_details'] = [removeUnused];
        workPayload['contact_education_details'][0]['created_by'] = userData.id;
        workPayload['contact_education_details'][0]['updated_by'] = userData.id;
        workPayload['contact_education_details'][0]['contact_id'] = id;
      } else {
        Object.keys(removeUnused).forEach(key => {
          currentValues.contact_education_details[indexValue][key] = removeUnused[key];
        });
        delete currentValues.contact_education_details[indexValue]['created_at'];
        delete currentValues.contact_education_details[indexValue]['updated_at'];
        workPayload['contact_education_details'] = [currentValues.contact_education_details[indexValue]];
      }
      return workPayload;
    }
  }
};

export const getCompensationPayload = (formValues = {}, dirtyFields = [], userData, id, currentValues) => {
  const removeUnused = removeUnusedField(formValues, dirtyFields);
  if (removeUnused['contact_compensations']) {
    removeUnused['contact_compensations']['created_by'] = userData?.id;
    removeUnused['contact_compensations']['updated_by'] = userData?.id;
    if (!currentValues?.contact_compensation?.id) {
      removeUnused['contact_compensations']['contact_id'] = id;
    } else {
      removeUnused['contact_compensations']['id'] = currentValues?.contact_compensation?.id;
    }
    return removeUnused;
  }
};

export const getProfessionalExperiencePayload = (formValues = {}, dirtyFields = [], userData, id, currentValues, isEdit, indexValue, isPopupOpen) => {
  const removeUnused = removeUnusedField(formValues, dirtyFields);
  if (removeUnused['contact_industries']) {
    removeUnused['contact_industries'].forEach(elem => {
      elem['created_by'] = userData.id;
      elem['updated_by'] = userData.id;
      if (!currentValues?.contact_industries?.id) {
        elem['contact_id'] = id;
      } else {
        elem['id'] = currentValues?.contact_industries?.id;
      }
    });
    if (!Object.keys(removeUnused)?.length) return false;
    return {
      ...removeUnused
    };
  } else if (removeUnused['contact_job_functions']) {
    removeUnused['contact_job_functions'].forEach(elem => {
      elem['created_by'] = userData.id;
      elem['updated_by'] = userData.id;
      if (!currentValues?.contact_job_functions?.id) {
        elem['contact_id'] = id;
      } else {
        elem['id'] = currentValues?.contact_job_functions?.id;
      }
    });
    if (!Object.keys(removeUnused)?.length) return false;
    return {
      ...removeUnused
    };
  } else {
    if (isPopupOpen) {
      const workPayload = {};
      if (!isEdit) {
        workPayload['contact_work_experience'] = [removeUnused];
        workPayload['contact_work_experience'][0]['created_by'] = userData.id;
        workPayload['contact_work_experience'][0]['updated_by'] = userData.id;
        workPayload['contact_work_experience'][0]['contact_id'] = id;
      } else {
        Object.keys(removeUnused).forEach(key => {
          currentValues.contact_work_experience[indexValue][key] = removeUnused[key];
        });
        delete currentValues.contact_work_experience[indexValue]['created_at'];
        delete currentValues.contact_work_experience[indexValue]['updated_at'];
        workPayload['contact_work_experience'] = [currentValues.contact_work_experience[indexValue]];
      }
      return workPayload;
    }
  }
};

export const getPersonalityPayload = (formValues = {}, dirtyFields = []) => {
  const removeUnused = removeUnusedField(formValues, dirtyFields);
  // if (removeUnused["contact_notes_all"]) {
  //   removeUnused["contact_notes_all"] = removeUnused["contact_notes_all"].filter(elem => elem.notes !== "")
  // }
  if (!Object.keys(removeUnused)?.length) return false;
  return {
    ...removeUnused
  };
};
export const getCommunicationPayload = (formValues = {}, dirtyFields = [], userData) => {
  const removeUnused = removeUnusedField(formValues, dirtyFields);
  if (removeUnused['contact_emails']) {
    removeUnused['contact_emails'] = removeUnused['contact_emails'].filter(ele => ele?.email !== '' && ele?.email_type !== '');
    removeUnused['contact_emails'].forEach(elem => {
      elem['created_by'] = userData.id;
      elem['updated_by'] = userData.id;
    });
  }
  if (removeUnused['contact_phones']) {
    removeUnused['contact_phones'] = removeUnused['contact_phones'].filter(ele => ele?.phone_number !== '' && ele?.phone_type !== '');
    removeUnused['contact_phones'].forEach(elem => {
      elem['created_by'] = userData.id;
      elem['updated_by'] = userData.id;
    });
  }

  if (!Object.keys(removeUnused)?.length) return false;
  return {
    ...removeUnused
  };
};
export const cleanupFormValues = data => {
  const values = { ...data };
  const keys = ['screening_notes', 'career_snapshot_profile', 'recommendations_profile', 'personal_profile', 'published_bio'];
  keys.forEach(key => delete values[key]);
  return values;
};

export const SKIP_FIELDS_LIST = [
  'candidate_name',
  'matrix_notes',
  'next_steps',
  'rationale',
  'recommendations_profile',
  'published_bio',
  'career_snapshot_profile',
  'personal_profile',
  'screening_notes',
  'strengths',
  'issues'
];
export const unique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const removeSkipFields = list => {
  list = Object.keys(list).length > 0 ? Object.values(list) : [];
  return list?.filter(ele => !SKIP_FIELDS_LIST.includes(ele) || '')?.filter(unique);
};
