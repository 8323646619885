import { Paper, Box, Tab, Tabs, List, ListItem, useTheme, IconButton } from '@mui/material';
import React from 'react';
import style from '../Home.module.scss';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../services/constantService';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import RecentActivityDialog from './Popup/RecentActivityDialog';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function RightSection({ activities, loading, setContactId }) {
  const [value, setValue] = React.useState(0);
  const [openActivityDialog, setOpenActivityDialog] = React.useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const filteredActivities = activities.filter(activity => Object.values(activity).every(value => value !== null)).slice(0, 10);

  return (
    <>
      <Paper elevation={2} className={`${style.recent_scroll} ${style.paper_custom}`}>
        <Box height={'100%'}>
          <Box className={style.border_bottom}>
            <div className={style.tab_bar}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='basic tabs example'
                color={theme.palette.heading.main}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: theme.palette.heading.main
                  }
                }}
              >
                <Tab label='Recent Activities' {...a11yProps(0)} style={{ color: theme.palette.heading.main }} />
              </Tabs>
              <IconButton>
                <OpenInFullOutlinedIcon className={style.clickable} onClick={() => setOpenActivityDialog(true)} />
              </IconButton>
              <RecentActivityDialog open={openActivityDialog} setOpen={setOpenActivityDialog} />
            </div>
          </Box>

          <Box className={style.activities_list_custom}>
            <List component='nav' aria-label='Recent activities'>
              {filteredActivities.map((activity, index) => {
                console.log('activity', activity);
                return (
                  <React.Fragment key={index}>
                    {activity?.activity_type === 'candidate_stage_change' && (
                      <ListItem className={`fs-10 ${style.list}`}>
                        <span
                          onClick={() => {
                            setContactId(activity.contact_id);
                          }}
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer'
                          }}
                        >
                          {activity.name}{' '}
                        </span>
                        <span>{activity.action}</span>
                        <span className={style.activity_reference} onClick={() => navigate(`${ROUTES.projectDetails}/${activity.project_id}`)}>
                          #{activity.reference}.
                        </span>
                      </ListItem>
                    )}
                    {activity?.activity_type === 'project_stage_update' && (
                      <ListItem className={`fs-10 ${style.list}`}>
                        <span className={style.activity_reference} onClick={() => navigate(`${ROUTES.projectDetails}/${activity.project_id}`)}>
                          #{activity.reference}.
                        </span>
                        <span>{activity.action}</span>
                        <span
                          style={{
                            fontWeight: 'bold'
                          }}
                        >
                          {activity.stage_to}
                        </span>
                      </ListItem>
                    )}
                    {activity?.activity_type !== 'candidate_stage_change' && activity?.activity_type !== 'project_stage_update' && (
                      <ListItem className={`fs-10 ${style.list}`}>
                        <span>{activity.name} </span>
                        <span>{activity.action}</span>
                        <span className={style.activity_reference} onClick={() => navigate(`${ROUTES.projectDetails}/${activity.project_id}`)}>
                          #{activity.reference}.
                        </span>
                      </ListItem>
                    )}
                  </React.Fragment>
                );
              })}
            </List>
          </Box>
        </Box>
      </Paper>
    </>
  );
}

RightSection.propTypes = {
  activities: propTypes.array,
  loading: propTypes.bool,
  setContactId: propTypes.func
};

export default RightSection;
