export const QUICK_ADD_CONTACT_COMMON_FIELD = {
  first_name: 'first_name',
  last_name: 'last_name',
  contact_emails: 'contact_emails',
  linkedin_url: 'linkedin_url',
  contact_phones: 'contact_phones',
  current_job_title: 'current_job_title',
  email: 'email',
  phonenumber: 'phonenumber',
  firstName: 'firstName',
  lastName: 'lastName',
  linkedin: 'linkedin',
  jobtitle: 'jobtitle',
  company: 'company'
};
