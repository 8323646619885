import React from 'react';
import propTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import styles from '../Home.module.scss';

function Button({ addIcon, value }) {
  return (
    <Box className={`${styles.button_container} fs-30`}>
      {value}
      {addIcon && <AddIcon className={`${styles.add_icon} fs-30`} />}
    </Box>
  );
}

Button.propTypes = {
  addIcon: propTypes.bool,
  value: propTypes.node
};

export default Button;
