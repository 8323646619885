//----------------------------------------------// External Imports // -------------------------------------------------
import * as yup from 'yup';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { PUT, SUCCESS, ERROR, FEE_TYPES } from '../../services/constantService';
import { contactDataApi } from '../../services/ApiService';
import { unableMessage, successMessage, VALIDATION_MESSAGE } from '../../services/MessageService';

export const defaultValues = {
  vc_pe: [],
  client_team: [{}, {}, {}],
  currency: 'USD',
  retainers: [{ field: 'Retainer 1' }, { field: 'Retainer 2' }, { field: 'Retainer 3' }, { field: 'Final Retainer' }, { field: 'Contingent Fee' }, { field: 'Monthly Fee' }],
  actual_percentage_bases: [{}],
  estimated_percentage_bases: [{}],
  indirect_fees_searches: [{}],
  notes: [{}],
  partners: [{}, {}, {}],
  recruiters: [{}, {}, {}],
  researchers: [{}, {}, {}],
  eas: [{}, {}, {}],
  search_documents: [
    {
      doctype_code: '',
      files: []
    }
  ],
  fee_type: 'STD',
  fee_type_desc: FEE_TYPES.ONE_THIRD,
  fee_percentage: 33.33
};

export const formSchema = yup.object().shape({
  company: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string().required()
    })
    .required(),
  type: yup.string().required(),
  stage: yup.string().required(),
  job_title: yup.string().required(),
  country: yup.string().required(),
  currency: yup.string().required(),
  industries: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string()
      })
    )
    .required(),
  job_functions: yup.array().of(yup.string()).required(),
  brand: yup.string().required().min(1),
  basic_partners: yup.array().required().min(1),
  probability: yup.string().required()
});

export const cleanupSearchData = (searchData, clone = '', currentTabIndex) => {
  if (searchData.company) {
    searchData.company_id = searchData.company.id;
  } else {
    searchData.company_id = null;
  }
  if (searchData.markets) {
    searchData.market = searchData.markets.id;
  } else {
    searchData.market = null;
  }

  if (searchData.contact_name) {
    searchData.billing_contact_id = searchData.contact_name.id;
  }
  if (searchData.billing_country) {
    searchData.billing_country = searchData.billing_country.name;
  }
  if (searchData.contact_name == null) {
    searchData.billing_contact_id = searchData.contact_name;
  }
  // if (searchData.admin_fee) {
  //   searchData.admin_fee = searchData.admin_fee
  // }
  if (currentTabIndex !== 4) {
    if (searchData.basic_partners) {
      searchData.basic_partners = searchData.basic_partners.map(partner => {
        return {
          user_id: partner?.id,
          user: partner
        };
      });
      if (searchData.partners && searchData.basic_partners) {
        searchData.partners = searchData.partners?.filter(ele => (!ele?.user ? true : searchData.basic_partners.find(el => el?.user_id === ele?.user?.id)));
        searchData.basic_partners.forEach(ele => {
          if (searchData.partners) {
            const response = searchData.partners.find(item => item.user && item.user.id === ele.user_id);
            if (!response) {
              searchData.partners.push(ele);
            }
          }
        });
      }
    }
  }
  searchData.estimated_total_comp = parseFloat(searchData.estimated_total_comp) || null;
  searchData.actual_total_comp = parseFloat(+searchData.actual_total_comp) || null;
  if (searchData.contact_name) {
    searchData.contact_name = searchData.contact_name.id;
  }
  if (clone === 'clone') {
    if (Array.isArray(searchData.vc_pe)) {
      searchData.vc_pe = searchData.vc_pe?.filter(item => item);
      searchData.vc_pe = searchData.vc_pe.map(item => item);
    }
  } else {
    if (Array.isArray(searchData.vc_pe)) {
      searchData.vc_pe = searchData.vc_pe?.filter(item => item && item.name);
      searchData.vc_pe = searchData.vc_pe.map(item => item.name);
    }
  }
  searchData.fee_amount = parseFloat(searchData.fee_amount);
  searchData.fee_percentage = parseFloat(searchData.fee_percentage);
  searchData.by_product_fee = parseFloat(searchData.by_product_fee);
  searchData.retainers = searchData.retainers.map((item, position) => {
    return {
      ...item,
      amount: parseFloat(item.amount),
      position
    };
  });
  if (searchData.industries) {
    searchData.industries = searchData.industries && searchData.industries.map(item => (typeof item === 'string' ? item : item.name));
  }
  if (searchData.estimated_percentage_bases && searchData.estimated_percentage_bases?.length > 0) {
    const esimatedPercentageBases = searchData.estimated_percentage_bases?.filter(ele => {
      if (Object.keys(ele)?.length > 0) {
        return ele;
      }
    });
    searchData.estimated_percentage_bases = esimatedPercentageBases;
  }
  if (searchData.actual_percentage_bases && searchData.actual_percentage_bases?.length > 0) {
    const actualPercentageBases = searchData.actual_percentage_bases?.filter(ele => {
      if (Object.keys(ele)?.length > 0) {
        return ele;
      }
    });
    searchData.actual_percentage_bases = actualPercentageBases;
  }
  if (searchData.actual_percentage_bases && searchData.actual_percentage_bases?.length > 0) {
    searchData.actual_percentage_bases.forEach((item, index) => {
      searchData.actual_percentage_bases[index] = { ...searchData.actual_percentage_bases[index], actual_amt: item.actual_amt === '' ? null : item.actual_amt };
    });
  }
  if (searchData.estimated_percentage_bases && searchData.estimated_percentage_bases?.length > 0) {
    searchData.estimated_percentage_bases.forEach((item, index) => {
      searchData.estimated_percentage_bases[index] = { ...searchData.estimated_percentage_bases[index], estimated_amt: item.estimated_amt === '' ? null : item.estimated_amt };
    });
  }
  if (searchData.indirect_fees_searches && searchData.indirect_fees_searches?.length > 0) {
    const indirectFeesSearches = searchData.indirect_fees_searches?.filter(ele => {
      if (Object.keys(ele)?.length > 0) {
        return ele;
      }
    });
    searchData.indirect_fees_searches = indirectFeesSearches;
  }
  searchData.client_team = searchData.client_team?.filter(item => !!item.contact);
  searchData.client_team = searchData.client_team.map((item, position) => ({
    is_hiring_manager: item.is_hiring_manager,
    contact_id: item.contact.id,
    position,
    id: item.client_id
  }));
  searchData.partners = searchData.partners
    ?.filter(item => item.user && item.user.id)
    .map((item, position) => {
      let formatted = {};
      if (item.origination_credit) {
        formatted.origination_credit = parseFloat(item.origination_credit);
      }
      if (item.selling_credit) {
        formatted.selling_credit = parseFloat(item.selling_credit);
      }
      if (item.execution_credit) {
        formatted.execution_credit = parseFloat(item.execution_credit);
      }
      return {
        ...formatted,
        user_id: item.user && item.user.id,
        position
      };
    });
  searchData.recruiters = searchData.recruiters
    ?.filter(item => item.user && item.user.id)
    .map((item, position) => {
      let formatted = {};
      if (item.execution_credit) {
        formatted.execution_credit = parseFloat(item.execution_credit);
      }
      return {
        ...formatted,
        user_id: item.user && item.user.id,
        position
      };
    });
  searchData.researchers = searchData.researchers
    ?.filter(item => item.user && item.user.id)
    .map((item, position) => {
      let formatted = {};
      if (item.execution_credit) {
        formatted.execution_credit = parseFloat(item.execution_credit);
      }
      return {
        ...formatted,
        user_id: item.user && item.user.id,
        position
      };
    });
  searchData.notes = searchData.notes?.filter(item => item.title !== '' || item.description !== '');
  searchData.eas = searchData.eas
    ?.filter(item => item.user && item.user.id)
    .map((item, position) => {
      let formatted = {};
      if (item.execution_credit) {
        formatted.execution_credit = parseFloat(item.execution_credit);
      }
      return {
        ...formatted,
        user_id: item.user && item.user.id,
        position
      };
    });
  if (searchData.next_status_call_date) {
    const nextStatusCallDate = new Date(searchData.next_status_call_date);
    if (searchData.next_status_call_time) {
      const nextStatusCallTime = new Date(searchData.next_status_call_time);
      nextStatusCallDate.setHours(nextStatusCallTime.getHours(), nextStatusCallTime.getMinutes());
    } else {
      nextStatusCallDate.setHours(0, 0);
    }
    searchData.next_status_call = nextStatusCallDate;
  }

  searchData.actual_overridden_revenue = Number(searchData?.actual_overridden_revenue) || 0;
  if (searchData?.is_actual_overridden_revenue) {
    searchData.actual_revenue = searchData.actual_overridden_revenue;
  }
  delete searchData.target_locations;
  delete searchData.target_states;
  delete searchData.target_industries;
  return searchData;
};

export const updateContactDetails = async (contacts, enqueueSnackbar, setIsLoading) => {
  contacts = contacts?.filter(item => {
    return item.contact && !!item.contact.id && (item.mobile_phone !== item.contact.mobile_phone || item.work_email !== item.contact.work_email);
  });
  //use async loop for updating contact details
  for await (const [, item] of contacts.entries()) {
    const contact = { work_email: item.work_email, mobile_phone: item.mobile_phone };
    // todo: this condition applied for api being called multiple time in searchDocument Screen Notes update
    if (contact.work_email || contact.mobile_phone) {
      contact.mobile_phone = contact.mobile_phone === '+' ? null : contact.mobile_phone;
      const { status, data } = await contactDataApi(PUT, item.contact.id, contact);
      if (status === 200) {
        const message = successMessage(`Contact(${item.first_name} ${item.last_name}) details`, VALIDATION_MESSAGE.updated_message);
        enqueueSnackbar(message, { variant: SUCCESS });
      } else {
        const message = unableMessage(`contact(${item.first_name} ${item.last_name}) details`, 'update');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
    }
  }
};

export const getBillingContactDetails = data => {
  if (data.contact_name) {
    return {
      ...data.contact_name,
      work_email: data.work_email,
      mobile_phone: data.mobile_phone,
      contact: {
        ...data.contact_name
      }
    };
  }
  return {};
};

export const cleanupCloneData = searchData => {
  delete searchData.job_number;
  searchData.clone = true;
  if (searchData.retainers?.length) {
    searchData.retainers.forEach(ele => {
      delete ele.id;
    });
  }
  if (searchData.notes?.length) {
    searchData.notes.forEach(ele => {
      delete ele.id;
    });
  }
  if (searchData.client_team?.length) {
    searchData.client_team.forEach(ele => {
      delete ele.id;
    });
  }
  if (searchData.partners?.length) {
    searchData.partners.forEach(ele => {
      delete ele.id;
    });
  }
  if (searchData.researchers?.length) {
    searchData.researchers.forEach(ele => {
      delete ele.id;
    });
  }
  if (searchData.recruiters?.length) {
    searchData.recruiters.forEach(ele => {
      delete ele.id;
    });
  }
  if (searchData.eas?.length) {
    searchData.eas.forEach(ele => {
      delete ele.id;
    });
  }
  if (searchData.search_industry_ranking?.length) {
    searchData.search_industry_ranking.forEach(ele => {
      delete ele.id;
      delete ele.search_id;
      delete ele.updated_at;
      delete ele.created_at;
    });
  }
  if (searchData.search_target_country_ranking?.length) {
    searchData.search_target_country_ranking.forEach(ele => {
      delete ele.id;
      delete ele.search_id;
      delete ele.updated_at;
      delete ele.created_at;
    });
  }
  if (searchData.estimated_percentage_bases?.length) {
    searchData.estimated_percentage_bases.forEach(ele => {
      delete ele.id;
    });
  }
  if (searchData.actual_percentage_bases?.length) {
    searchData.actual_percentage_bases.forEach(ele => {
      delete ele.id;
    });
  }
  if (searchData.indirect_fees_searches?.length) {
    searchData.indirect_fees_searches.forEach(ele => {
      delete ele.id;
    });
  }
  return searchData;
};
