//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import Popup from 'reactjs-popup';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import { SCORES_DESCRIPTION } from '../../services/constantService';

export default function ScoresDefinitionPopup(props) {
  const { onClose } = props;
  const closePopup = () => {
    onClose && onClose();
  };
  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.keyCode === 27) {
        onClose();
      }
    }
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [props]);

  return (
    <Popup overlayStyle={{ zIndex: '1401 !important' }} open={true} modal closeOnDocumentClick={false} closeOnEscape={false}>
      <>
        <div className='' style={{ background: '#2a364c', color: '#fff' }}>
          <div className='quick-add-header'>
            <Typography>Scores Definition</Typography>
            <CloseIcon className='cursor-pointer' onClick={closePopup} />
          </div>
        </div>
        <div className='d-flex py-3 pr-5 score-definition-content'>
          <div className='col-10'>
            {SCORES_DESCRIPTION.map((item, index) => {
              return (
                <div key={index} className='d-flex contact-details-row  padding-score-definition'>
                  <div className='col-3 font-weight-bold score-label'>{item.name}</div>
                  <div className='col-7'>{item.description}</div>
                </div>
              );
            })}
          </div>
          <div className='col-2'>
            <span className='font-weight-bold score-label'>Score Range</span>
            <div className='border score-height contact-details-row'>
              <div className='product-one-score-content'>
                <div className='product-one-color color-green'></div>
                <span className='score-legend-text'>85 - 100</span>
              </div>
              <div className='product-one-score-content'>
                <div className='product-one-color color-orange'></div>
                <span className='score-legend-text'>65 - 84</span>
              </div>
              <div className='product-one-score-content'>
                <div className='product-one-color color-gray'></div>
                <span className='score-legend-text'>0 - 64</span>
              </div>
            </div>
          </div>
        </div>
      </>
    </Popup>
  );
}
ScoresDefinitionPopup.propTypes = {
  onClose: PropTypes.func
};
