import { debounce } from 'lodash';
import { MuiColorInput } from 'mui-color-input';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

function InputColorPicker({ onColorChange, defaultColor, ...rest }) {
  const [color, setColor] = useState(defaultColor);

  const onColorChangeDebounce = useCallback(
    debounce(value => onColorChange(value), 300),
    []
  );

  const onChange = value => {
    setColor(value);
    onColorChangeDebounce(value);
  };
  return <MuiColorInput format='hex' value={color} onChange={onChange} {...rest} />;
}

InputColorPicker.propTypes = {
  onColorChange: PropTypes.func,
  defaultColor: PropTypes.string
};

export default InputColorPicker;
