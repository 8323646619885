import { Box, CircularProgress, IconButton } from '@mui/material';
import 'ag-grid-community/dist/styles/ag-grid.css';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DocumentIcon, PdfIcon } from '../../../../components/Icons';
import { echoSenseApi } from '../../../../services/ApiService';
import { ERROR, GET } from '../../../../services/constantService';
import { showToast } from '../../constants';
import '../../index.scss';

const AnalyzeActionCellRender = params => {
  const [loading, setLoading] = useState(false);

  const downloadReport = async (taskId, type = 'docx') => {
    setLoading(true);
    try {
      const subRoute = `download-analyze-report?taskId=${taskId}&type=${type}`;
      const response = await echoSenseApi(GET, subRoute);
      const { error, msg, data: reportUrl } = response.data;
      if (error) {
        showToast(msg, ERROR);
      } else {
        window.open(reportUrl, '_blank');
      }
    } catch (error) {
      showToast(error.message, ERROR);
    }
    setLoading(false);
  };

  return loading ? (
    <Box textAlign='center'>
      <CircularProgress size='20px' />
    </Box>
  ) : (
    <Box textAlign='center'>
      <IconButton
        className='mx-2 p-0'
        onClick={() => {
          if (params.data.decision_summary_completed_at) downloadReport(params.data.id, 'pdf');
        }}
        disabled={!params.data.decision_summary_completed_at}
      >
        <PdfIcon height={20} width={20} fill={`${!params.data.decision_summary_completed_at ? 'gray' : '#23cea7'}`} />
      </IconButton>
      <IconButton
        className={'p-0 mx-2'}
        onClick={() => {
          if (params.data.decision_summary_completed_at) downloadReport(params.data.id, 'docx');
        }}
        disabled={!params.data.decision_summary_completed_at}
      >
        <DocumentIcon height={20} width={20} fill={`${!params.data.decision_summary_completed_at ? 'gray' : '#23cea7'}`} />
      </IconButton>
    </Box>
  );
};

export default AnalyzeActionCellRender;

AnalyzeActionCellRender.propTypes = {
  params: PropTypes.object
};
