import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomDropdown from '../../CustomDropdown';
import '../../../../Containers/Commons/AutoCompleteStyle/index.scss';
import { picklistDropDownApis } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
/* import { useDispatch, useSelector } from "react-redux";
import { fetchPickList } from "../../../../actions"; */
export default function CountrySelection(props) {
  const { placeholder, disabled = false, label = '', ...rest } = props;
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState('');
  /* uncomment this when use redux for picklist */
  useEffect(() => {
    const getCompanyList = async () => {
      const sub_route = `countries?countryName=${value}`;
      const { status, data } = await picklistDropDownApis(GET, sub_route);
      if ((status === 200) & (data.data.length > 0)) {
        let array = [];
        data.data.map(data => array.push({ label: data.name, name: data.name, id: data?.id ? data?.id : null }));
        setOptions(array);
      } else {
        setOptions([]);
      }
    };
    getCompanyList();
  }, [value]);
  return (
    <CustomDropdown
      {...rest}
      // required={true}
      options={options}
      filterOptions={options => options}
      placeholder={placeholder}
      onInputChange={(e, val, reason) => {
        if (reason === 'input') {
          setValue(val);
        }
      }}
      disabled={disabled}
      label={label}
    />
  );
}

CountrySelection.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string
};
