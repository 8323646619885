//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InputField from '../../common/InputField';

export default function ReferenceSourceSelection(props) {
  const { label = '', placeholder = '', InputLabelProps, required = false, inputRef, ...rest } = props;

  const options = ['Candidate Provided', 'Discrete'];

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionLabel={option => option}
      renderInput={params => <InputField {...params} required={required} variant='standard' ref={inputRef} InputLabelProps={InputLabelProps} label={label} placeholder={placeholder} />}
    />
  );
}

ReferenceSourceSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  inputRef: PropTypes.func
};
