//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import AddCircleIcon from '@mui/icons-material/AddCircle';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { DELETE, PUT, SUCCESS, GET, ERROR, WARNING } from '../../../../services/constantService';
import { acquireLockApi, contactDataApi, releaseLockApi } from '../../../../services/ApiService';
import ExpertiseSelection from '../../../languageExpertise';
import { successMessage, unableMessage, validateMessage } from '../../../../services/MessageService';
import { showSnackbar } from '../../../../Containers/Commons/Utils';
import LanguagesSelection from '../../../common/FunctionalComponents/LanguagesSelection';

export default function LanguageDetailsView(props) {
  const { languageDetails: language = [], contactId, setIsLoading, setContactData } = props;

  const [languageDetails, setLanguageDetails] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [isAdding, setIsAdding] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [oldRecords, setOldRecords] = useState([]);
  const sub_route = 'contact-languages';
  useEffect(() => {
    setLanguageDetails(language);
    setOldRecords(JSON.parse(JSON.stringify(language)));
  }, [language, setLanguageDetails]);

  const handleChange = (index, field, value, updateProps = false) => {
    const details = [...languageDetails];
    details[index][field] = value;
    if (field === 'language') details[index].language_id = value?.id;
    if (field === 'language_expertise') details[index].expertise_id = value?.id;
    setLanguageDetails(details);
    if (updateProps) {
      setContactData(details, 'contact_languages');
    }
  };

  const addLanguage = () => {
    setLanguageDetails(prevState => [...prevState, {}]);
  };

  const removeLanguage = index => {
    let details = [...languageDetails];
    details.splice(index, 1);
    setLanguageDetails(details);
    setContactData(details, 'contact_languages');
  };

  const deleteLanguage = async index => {
    setIsLoading(true);
    const { status, data } = await contactDataApi(DELETE, contactId, { id: languageDetails[index].id }, sub_route);
    if (status === 200) {
      const message = successMessage('Language', 'deleted');
      enqueueSnackbar(message, { variant: SUCCESS });
      removeLanguage(index);
      setEditIndex(-1);
    } else {
      const message = unableMessage('language details', 'delete');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
      // enqueueSnackbar(data?.message || 'Unable to delete language details', { variant: ERROR })
    }
    releaseLock();
    setIsLoading(false);
  };

  const handleAdd = () => {
    if (isAdding) {
      const message = validateMessage('add language or remove it', 'complete');
      enqueueSnackbar(message, { variant: WARNING });
      return;
    }
    setEditIndex(languageDetails?.length);
    addLanguage();
    setIsAdding(true);
  };
  const isSameUser = (a, b) => {
    if (a.expertise_id === b.expertise_id && a.language_id === b.language_id && a.position === b.position) {
      return true;
    } else {
      return false;
    }
  };
  const handleUpdate = async index => {
    let payload = { ...languageDetails[index] };
    payload.language_id = payload.language && payload.language.id ? payload.language.id : '';
    payload.expertise_id = payload.language_expertise && payload.language_expertise.id ? payload.language_expertise.id : '';
    payload.position = index;
    delete payload.language;
    delete payload.language_expertise;
    if (!payload.language_id || !payload.expertise_id) {
      const message = validateMessage('all language details', 'fill');
      enqueueSnackbar(message, { variant: ERROR });
    } else if (oldRecords?.length === languageDetails?.length) {
      const result = isSameUser(oldRecords[index], payload);
      if (result === true) {
        handleCancel(index);
      } else {
        setIsLoading(true);
        const { status, data } = await contactDataApi(PUT, contactId, payload, sub_route);
        if (status === 200) {
          const message = successMessage('Language details', 'saved');
          enqueueSnackbar(message, { variant: SUCCESS });
          if (isAdding) {
            handleChange(index, 'id', data.id, true);
            setIsAdding(false);
          } else {
            setContactData(JSON.parse(JSON.stringify(languageDetails)), 'contact_languages');
          }
          setOldRecords(JSON.parse(JSON.stringify(languageDetails)));
        } else {
          const message = unableMessage('language details', 'delete');
          enqueueSnackbar(data?.message || message, { variant: ERROR });
        }
        releaseLock();
        setEditIndex(-1);
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      const { status, data } = await contactDataApi(PUT, contactId, payload, sub_route);
      if (status === 200) {
        const message = successMessage('Language details', 'saved');
        enqueueSnackbar(message, { variant: SUCCESS });
        if (isAdding) {
          handleChange(index, 'id', data.id, true);
          setIsAdding(false);
        }
        setOldRecords(JSON.parse(JSON.stringify(languageDetails)));
      } else {
        const message = unableMessage('language details', 'delete');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      releaseLock();
      setEditIndex(-1);
      setIsLoading(false);
    }
  };
  const releaseLock = async () => {
    await releaseLockApi(contactId);
  };
  const handleCancel = async index => {
    if (isAdding) {
      removeLanguage(index);
      setIsAdding(false);
    }
    setIsLoading(true);
    const { status, data } = await contactDataApi(GET, contactId);
    if (status && status == 200) setLanguageDetails(data.contact_languages);
    setIsLoading(false);
    setEditIndex(-1);
    releaseLock();
  };

  const renderView = item => {
    return (
      <React.Fragment>
        <div className='d-flex contact-details-row'>
          <div className='contact-details'>
            <div className='contact-view-label'>Language</div>
            <div className='contact-view-value'>{(item.language && item.language.name) || ''}</div>
          </div>
          <div className='contact-details'>
            <div className='contact-view-label'>Level of Expertise </div>
            <div className='contact-view-value'>{(item.language_expertise && item.language_expertise.level) || ''}</div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderEditIcon = index => {
    return (
      !(editIndex === index || hoverIndex !== index) && (
        <EditIcon
          className='ml-2 cursor-pointer'
          onClick={async () => {
            let { status, data } = await acquireLockApi(contactId);
            if (status && status === 200 && data.message && data.user === false) {
              //if (data.isAdmin) {
              const object = {
                enqueueSnackbar: enqueueSnackbar,
                closeSnackbar: closeSnackbar,
                message: data.message,
                id: contactId
              };
              showSnackbar(object);
              /* }
          else {
            enqueueSnackbar(data.message, { variant: WARNING });
          } */
            } else {
              const { status, data } = await contactDataApi(GET, contactId);
              if (status && status == 200) {
                setLanguageDetails(data.contact_languages);
              }
              setEditIndex(index);
            }
          }}
          fontSize='inherit'
          color='secondary'
        />
      )
    );
  };

  const renderActions = index => {
    return editIndex === index ? (
      <div className='ml-2' style={{ fontSize: 16 }}>
        {!isAdding && (
          <DeleteIcon
            cursor='pointer'
            fontSize='inherit'
            onClick={async () => {
              await deleteLanguage(index);
            }}
          />
        )}
        <DoneIcon cursor='pointer' fontSize='inherit' color='primary' onClick={() => handleUpdate(index)} />
        <CloseIcon cursor='pointer' fontSize='inherit' onClick={() => handleCancel(index)} />
      </div>
    ) : (
      renderEditIcon(index)
    );
  };

  const renderSubHeader = index => {
    return (
      <div className='secion-sub-header d-flex align-items-center text-right'>
        <span>ENTRY {index + 1}</span>
        {renderActions(index)}
      </div>
    );
  };

  const renderEdit = (item, index) => {
    return (
      <>
        <div className='d-flex flex-grow-1'>
          <LanguagesSelection
            className='input-form-field input-field-old contact-view-value'
            defaultValue={item.language || null}
            onChange={(e, data) => {
              handleChange(index, 'language', data);
            }}
            InputLabelProps={{ focused: true, shrink: true }}
          />
          <ExpertiseSelection
            className='input-form-field input-field-old'
            InputLabelProps={{ focused: true, shrink: true }}
            value={item.language_expertise || null}
            onChange={(e, data) => {
              handleChange(index, 'language_expertise', data);
            }}
          />
        </div>
      </>
    );
  };

  const renderLanguageDetails = () => {
    if (languageDetails?.length === 0) {
      return <div className='d-flex contact-details-row no-data-available'>--</div>;
    }
    return languageDetails.map((item, index) => {
      return (
        <div
          key={index}
          onMouseEnter={() => {
            setHoverIndex(index);
          }}
          onMouseLeave={() => {
            setHoverIndex(-1);
          }}
        >
          {renderSubHeader(index)}
          {editIndex === index ? renderEdit(item, index) : renderView(item)}
        </div>
      );
    });
  };

  return (
    <div className='section-container'>
      <div className='section-header'>
        <span>Languages</span>
        <AddCircleIcon className='ml-2 cursor-pointer' fontSize='inherit' color='primary' onClick={handleAdd} />
      </div>
      {renderLanguageDetails()}
    </div>
  );
}

LanguageDetailsView.propTypes = {
  languageDetails: PropTypes.array,
  contactId: PropTypes.string,
  setIsLoading: PropTypes.func,
  setContactData: PropTypes.func
};
