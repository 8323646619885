import { Avatar, Box, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { globalSearchSelector } from '../../../selectors';
import { contactDocumentApi, ignCompanyDataApi } from '../../../services/ApiService';
import { GET, ROUTES } from '../../../services/constantService';
import { updateGlobalSearchData } from '../../../store/globalSearchSlice';
import { convertArrayBufferToBlobUrl } from '../../../utils/common';

export const CustomAvatar = ({ contactId = null, companyId = null, isProject, projectId = null }) => {
  const [profileUrl, setProfileUrl] = useState('');
  const [imgLoading, setImgLoading] = useState(false);

  const location = useLocation();

  const dispatch = useDispatch();
  const { cachedImages } = useSelector(globalSearchSelector);
  const fetchImages = async () => {
    setImgLoading(true);
    let url;
    try {
      let response;
      if (contactId) {
        response = await contactDocumentApi(GET, contactId, null, null, null, 'image');
      } else {
        response = await ignCompanyDataApi(GET, null, companyId, 'image');
      }

      if (response.status === 200) {
        url = convertArrayBufferToBlobUrl(response?.data?.Body?.data);
        setProfileUrl(url);
      }
      setImgLoading(false);
      if (contactId) {
        dispatch(updateGlobalSearchData({ key: 'cachedImages', data: { ...cachedImages, [contactId]: url || 'not_found' } }));
      }
      if (companyId) {
        dispatch(updateGlobalSearchData({ key: 'cachedImages', data: { ...cachedImages, [companyId]: url || 'not_found' } }));
      }
    } catch (error) {
      setImgLoading(false);
      if (contactId) {
        dispatch(updateGlobalSearchData({ key: 'cachedImages', data: { ...cachedImages, [contactId]: url || 'not_found' } }));
      }
      if (companyId) {
        dispatch(updateGlobalSearchData({ key: 'cachedImages', data: { ...cachedImages, [companyId]: url || 'not_found' } }));
      }
    }
  };

  useEffect(() => {
    const image = cachedImages[contactId];

    if (image === 'not_found') return;
    if (image) {
      setProfileUrl(image);
      return;
    }
    if (contactId) {
      fetchImages();
    }
  }, [contactId]);

  useEffect(() => {
    const image = cachedImages[companyId];
    if (image === 'not_found') return;
    if (image) {
      setProfileUrl(image);
      return;
    }
    if (companyId) {
      fetchImages();
    }
  }, [companyId]);

  const handleOnClick = () => {
    if (contactId) {
      dispatch(updateGlobalSearchData({ key: 'selectedContactId', data: contactId }));
    }
    if (companyId && !isProject) {
      dispatch(updateGlobalSearchData({ key: 'selectedCompanyId', data: companyId }));
    }
  };

  return (
    <Link target={!isProject ? '_self' : '_blank'} to={!isProject ? location.pathname : `${ROUTES.projectDetails}/${projectId}`}>
      <Box onClick={handleOnClick} sx={{ display: 'flex', alignItems: 'center' }}>
        {imgLoading && <Skeleton variant='circular' width={'53px'} height={'53px'} />}
        {!imgLoading && <Avatar variant='circular' sx={{ width: '53px', height: '53px', borderRadius: '50%', cursor: 'pointer' }} src={profileUrl} />}
      </Box>
    </Link>
  );
};

CustomAvatar.propTypes = {
  contactId: PropTypes.string,
  companyId: PropTypes.string,
  projectId: PropTypes.string,
  isProject: PropTypes.bool
};
