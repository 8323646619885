import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import BackendAdapter from 'i18next-multiload-backend-adapter';
import HttpBackend from 'i18next-http-backend'; // *** added this ***

i18n
  // .use(BackendAdapter) // *** to load multiple namespaces at a time ***
  .use(HttpBackend) // *** added this ***
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'es', 'pt'], // *** supported languages list ***
    ns: ['translations'],
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    nsMode: 'fallback',
    defaultNS: 'translations',
    backend: {
      loadPath: ''
      // loadPath: `/locales/lng={{lng}}/ns={{ns}}.json`, // *** to load single namespace with language skipped backend api call
      // loadPath: `/locales/resources?lng={{lng}}&ns={{ns}}`, // *** to load multiple namespace at one backend api call use adapter 'i18next-multiload-backend-adapter'
    },
    react: {
      wait: true
    }
  });

i18n.addResourceBundle('translations', 'en', {});

export { i18n };

export default i18n;
