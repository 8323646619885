//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { IdRenderer } from '../../../Containers/Searches/Utils';
import { getDateFilterOptions, getFilterQuery, getMatchFilterOptions, validate } from '../../../Containers/Commons/Utils';
import InputField from '../../common/InputField';
import ReferenceTypeSelection from './ReferenceTypeSelection';
import ReferenceStatusSelection from './ReferenceStatusSelection';
import CompanySelection from '../../CompanySelection';
import ReferenceSourceSelection from './ReferenceSourceSelection';
import { PUT, ERROR, SUCCESS } from '../../../services/constantService';
import { contactReferencesApi } from '../../../services/ApiService';
import { successMessage, unableMessage, requireMessage, validateMessage } from '../../../services/MessageService';
import { CompanyNameRenderer } from '../../../Containers/Companies/Utils';

export const defaultColumns = ['job_number', 'job_title', 'first_name', 'details'];

const getSearchField = (params, field) => {
  if (params.data) {
    return params.data.search != null ? params.data.search[field] : null;
  }
};

const getContactField = (params, field) => {
  return params.data?.contact[field];
};

const textFilterParams = {
  filter: 'agTextColumnFilter',
  filterParams: {
    filterOptions: ['contains'],
    buttons: ['reset', 'apply'],
    suppressAndOrCondition: true,
    closeOnApply: true
  }
};
const textFilterForSearchParams = {
  filter: 'agTextColumnFilter',
  filterParams: {
    filterOptions: ['equal to'],
    buttons: ['reset', 'apply'],
    suppressAndOrCondition: true,
    closeOnApply: true
  }
};

export const columnDefs = (enqueueSnackbar, editable = true) => [
  {
    headerName: '',
    field: 'check_box',
    colId: 'check_box',
    maxWidth: 50,
    minWidth: 50,
    headerComponent: 'HeaderCheckbox',
    headerCheckboxSelectionFilteredOnly: false,
    checkboxSelection: true,
    sortable: false,
    pinned: 'left',
    lockPinned: true
  },
  {
    field: 'job_number',
    colId: 'job_number',
    headerName: 'Search#',
    cellRenderer: 'SearchIdRenderer',
    pinned: 'left',
    lockPinned: true,
    minWidth: 50,
    maxWidth: 120,
    width: 90,
    ...textFilterForSearchParams
  },
  {
    field: 'job_title',
    colId: 'job_title',
    headerName: 'Search Title',
    cellRenderer: 'SearchTitleRenderer',
    pinned: 'left',
    minWidth: 50,
    maxWidth: 200,
    lockPinned: true,
    ...textFilterParams
  },
  {
    field: 'company',
    colId: 'company',
    headerName: 'Search Company',
    cellRenderer: 'SearchCompanyRenderer',
    filter: 'CustomFilter',
    minWidth: 50,
    maxWidth: 200,
    filterParams: {
      Component: CompanySelection,
      field: 'id'
    }
  },
  {
    field: 'first_name',
    colId: 'first_name',
    headerName: 'Reference Name',
    cellRenderer: 'NameRenderer',
    pinned: 'left',
    maxWidth: 200,
    minWidth: 50,
    lockPinned: true,
    ...textFilterParams
  },
  {
    field: 'details',
    colId: 'details',
    headerName: 'Details',
    cellRenderer: 'DetailsRenderer',
    pinned: 'left',
    lockPinned: true,
    sortable: false,
    width: 80,
    maxWidth: 80,
    minWidth: 60
  },
  {
    field: 'current_job_title',
    colId: 'current_job_title',
    headerName: 'Reference Title',
    maxWidth: 200,
    minWidth: 50,
    cellRenderer: params => getContactField(params, 'current_job_title'),
    ...textFilterParams
  },
  {
    field: 'contact_company',
    colId: 'contact_company',
    headerName: 'Reference Company',
    cellRenderer: 'ReferenceCompanyRenderer',
    filter: 'CustomFilter',
    maxWidth: 200,
    minWidth: 50,
    filterParams: {
      Component: CompanySelection,
      field: 'id'
    }
  },
  {
    field: 'source',
    colId: 'source',
    headerName: 'Source',
    filter: 'CustomFilter',
    maxWidth: 180,
    minWidth: 50,
    filterParams: {
      Component: ReferenceSourceSelection
    },
    editable: editable,
    cellEditor: 'GenericCellEditor',
    cellEditorParams: {
      InputComponent: ReferenceSourceSelection
    },
    valueSetter: params => {
      return updateReference(params, 'source', enqueueSnackbar, true);
    }
  },
  {
    field: 'years_known',
    colId: 'years_known',
    headerName: 'Years Known',
    editable: editable,
    maxWidth: 150,
    minWidth: 50,
    cellEditor: 'GenericCellEditor',
    cellEditorParams: {
      InputComponent: InputField,
      type: 'number',
      label: ''
    },
    valueSetter: params => {
      return updateReference(params, 'years_known', enqueueSnackbar);
    },
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: getMatchFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'relationship',
    colId: 'relationship',
    headerName: 'Relationship',
    tooltipField: 'relationship',
    maxWidth: 200,
    minWidth: 50,
    editable: editable,
    valueSetter: params => {
      return updateReference(params, 'relationship', enqueueSnackbar);
    },
    ...textFilterParams
  },
  {
    field: 'type',
    colId: 'type',
    headerName: 'Reference Type',
    editable: editable,
    cellEditor: 'GenericCellEditor',
    maxWidth: 200,
    minWidth: 50,
    cellEditorParams: {
      InputComponent: ReferenceTypeSelection
    },
    valueSetter: params => {
      if (!params.newValue) {
        const message = requireMessage('Reference Type', 'is');
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      } else {
        params.newValue = params.newValue.name;
        return updateReference(params, 'type', enqueueSnackbar);
      }
    },
    filter: 'CustomFilter',
    filterParams: {
      Component: ReferenceTypeSelection,
      field: 'name'
    }
  },
  {
    field: 'private_email',
    colId: 'private_email',
    headerName: 'Private Email',
    cellRenderer: 'EmailRenderer',
    maxWidth: 300,
    minWidth: 50,
    valueGetter: params => getContactField(params, 'private_email'),
    ...textFilterParams
  },
  {
    field: 'work_email',
    colId: 'work_email',
    headerName: 'Work Email',
    cellRenderer: 'EmailRenderer',
    maxWidth: 300,
    minWidth: 50,
    valueGetter: params => getContactField(params, 'work_email'),
    ...textFilterParams
  },
  {
    field: 'linkedin_url',
    colId: 'linkedin_url',
    headerName: 'LinkedIn',
    maxWidth: 100,
    minWidth: 50,
    cellRenderer: 'LinkedInRenderer',
    sortable: false,
    valueGetter: params => getContactField(params, 'linkedin_url')
  },
  {
    field: 'status',
    colId: 'status',
    headerName: 'Status',
    editable: editable,
    maxWidth: 100,
    minWidth: 50,
    cellEditor: 'GenericCellEditor',
    cellEditorParams: {
      InputComponent: ReferenceStatusSelection
    },
    valueSetter: params => {
      return updateReference(params, 'status', enqueueSnackbar);
    },
    filter: 'CustomFilter',
    filterParams: {
      Component: ReferenceStatusSelection
    }
  },
  {
    field: 'created_at',
    colId: 'created_at',
    maxWidth: 150,
    minWidth: 50,
    headerName: 'Created On',
    cellRenderer: 'DateRenderer',
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: getDateFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  }
];

export const SearchIdRenderer = params => {
  const payload = {
    data: { id: getSearchField(params, 'id') },
    value: getSearchField(params, 'job_number')
  };
  return IdRenderer(payload);
};

export const SearchTitleRenderer = params => {
  const payload = {
    data: { id: getSearchField(params, 'id') },
    value: getSearchField(params, 'job_title')
  };
  return IdRenderer(payload);
};

export const SearchCompanyRenderer = params => {
  return <CompanyNameRenderer company={getSearchField(params, 'company')} />;
};

export const ReferenceCompanyRenderer = params => {
  return <CompanyNameRenderer company={getContactField(params, 'company')} />;
};

export const updateReference = async (params, fieldName, enqueueSnackbar, required = false, failureCallback) => {
  if (!validate(params, required)) {
    const message = requireMessage(`${params.colDef.headerName || fieldName}`, 'is');
    enqueueSnackbar && enqueueSnackbar(message, { variant: ERROR });
    return false;
  }

  if (fieldName === 'years_known') {
    if (params.newValue !== '') {
      const yearsValue = parseInt(params.newValue);
      if (yearsValue < 0 || yearsValue > 99) {
        const message = validateMessage('valid years known', 'enter');
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
  }

  const formData = {};
  params.data[fieldName] = params.newValue;
  formData[fieldName] = params.newValue;
  const sub_route = `references/${params.data.id}`;
  params.api.showLoadingOverlay();
  const { status, data } = await contactReferencesApi(PUT, sub_route, formData);
  if (status === 200) {
    const message = successMessage('Reference', 'updated');
    enqueueSnackbar && enqueueSnackbar(message, { variant: SUCCESS });
    params.api.hideOverlay();
  } else {
    const oldData = {
      ...params.data,
      [fieldName]: params.oldValue
    };
    params.node.setData(oldData);
    const message = unableMessage('reference', 'update');
    enqueueSnackbar && enqueueSnackbar(data?.message || message, { variant: ERROR });
    failureCallback && failureCallback();
    params.api.hideOverlay();
    return false;
  }
  params.api && params.api.refreshCells();
  return true;
};

export const getReferenceFilterQuery = data => {
  return getFilterQuery(data, {}, {});
};
