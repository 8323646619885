import { Box, ListItem, Skeleton } from '@mui/material';
import React from 'react';

export default function RecentActivitySkeleton() {
  return (
    <ListItem sx={{ padding: 0, marginBottom: 2 }}>
      <Box display={'flex'} alignItems={'center'} width={'100%'} height={'10vh'} paddingBottom={1} borderBottom={'1px solid rgba(0, 0, 0, 0.3)'}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton variant='rounded' width={'53px'} height={'53px'} />
        </Box>
        <Box width={'100%'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <Skeleton variant='text' width={'250px'} sx={{ marginLeft: 1, fontSize: 12 }} />
            <Skeleton variant='text' width={'250px'} sx={{ marginLeft: 1, fontSize: 10 }} />
          </Box>
          <Skeleton variant='rounded' width={'70%'} height={'53px'} />
        </Box>
      </Box>
    </ListItem>
  );
}
