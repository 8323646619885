//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { InputLabel } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { ExpandableSection } from '../../ExpandableSection';
import RichTextPopup from '../../../../RichTextPopup';

export function NextStepsAndRationale(props) {
  const { register, currentValues, isContactActivityLog, saveStatus, autoSave } = props;

  useEffect(() => {
    register('next_steps');
  }, [register]);

  return !isContactActivityLog ? (
    <ExpandableSection className='section-content flex-column' title='Next Steps and Rationale'>
      <div className='contact-details'>
        <InputLabel className='contact-view-label mr-2 mt-2 mb-2'>Next Steps</InputLabel>
        <div className='outlined-border transform p-0'>
          <RichTextPopup
            name='next_steps'
            {...register('next_steps')}
            onSave={autoSave}
            autoSave={autoSave}
            field={'next_steps'}
            value={currentValues.next_steps}
            saveStatus={saveStatus}
            title='Next Steps'
            updatedDate={currentValues.candidateUpdatedAt}
          />
        </div>
      </div>
      <div className='contact-details'>
        <InputLabel className='contact-view-label mr-2 mt-2 mb-2'>Rationale/Internal Notes</InputLabel>
        <div className='outlined-border transform p-0'>
          <RichTextPopup
            name='rationale'
            {...register('rationale')}
            onSave={autoSave}
            autoSave={autoSave}
            field={'rationale'}
            value={currentValues.rationale}
            saveStatus={saveStatus}
            title='Rationale'
            updatedDate={currentValues.candidateUpdatedAt}
          />
        </div>
      </div>
    </ExpandableSection>
  ) : null;
}

NextStepsAndRationale.propTypes = {
  register: PropTypes.func,
  isContactActivityLog: PropTypes.string,
  currentValues: PropTypes.object,
  saveStatus: PropTypes.bool,
  autoSave: PropTypes.func
};
