import { Box, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import './index.scss';

const LoadingScreen = ({ title, description }) => {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' textAlign='center' className='loading-screen'>
      {/* Circular Loader */}
      <CircularProgress size={60} className='loading-screen__loader' />

      {/* Title */}
      <Typography variant='h5' fontWeight='bold' gutterBottom>
        {title}
      </Typography>

      {/* Description */}
      <Typography variant='body1' color='textSecondary' margin={'24px'}>
        {description}
      </Typography>
    </Box>
  );
};

LoadingScreen.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default LoadingScreen;
