import React, { useCallback, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';

import './index.scss';

const AutoCompleteSearch = props => {
  const { placeholder = 'Search', onTextChange, onSearchClick, type, ...rest } = props;
  const [value, setValue] = useState('');

  const handleTextChange = useCallback(newText => {
    setValue(newText);
    onTextChange(newText);
  }, []);

  return (
    <div id='contact-search' className='px-4 add-search'>
      <Autocomplete
        {...rest}
        className='grid-search'
        freeSolo
        filterOptions={options => options}
        options={[]}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option;
          } else if (type === 'contacts') {
            return option.first_name + ' ' + option.last_name;
          } else if (type === 'companies') {
            return option.name;
          }
        }}
        renderOption={(props, option) => {
          const jobTitle = option.current_job_title || '';
          const companyName = option.company?.name || '';
          return (
            <li {...props}>
              {type === 'contacts' && (
                <div>
                  <Typography>{option.first_name + ' ' + option.last_name}</Typography>
                  <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>{'(' + jobTitle + ' - ' + companyName + ')'}</Typography>
                </div>
              )}
              {type === 'companies' && (
                <div>
                  <Typography>{option.name}</Typography>
                </div>
              )}
            </li>
          );
        }}
        onInputChange={(e, val) => handleTextChange(val)}
        value={props.value ? props.value : ''}
        renderInput={params => (
          <TextField
            {...params}
            // onChange={() => { setListShow(true) }}
            // onFocus={() => {setListShow(true) }}
            // onBlur={() => { if(!value)setListShow(false) }}
            variant='standard'
            className='search p-1'
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: params.InputProps.endAdornment
            }}
          />
        )}
      />
      <span onClick={() => onSearchClick(value)} className={'search-icon p-0'}>
        <SearchIcon />
      </span>
    </div>
  );
};

AutoCompleteSearch.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onTextChange: PropTypes.func,
  onSearchClick: PropTypes.func,
  type: PropTypes.string
};

export default AutoCompleteSearch;
