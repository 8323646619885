import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CustomButton from '../CustomButton';
import './index.scss';

/* file drag and drop */
const ResumeUpload = props => {
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_UPLOAD_FILE, TRANS_KEYS.PARSE, TRANS_KEYS.RESUME_PARSE_LIMIT, TRANS_KEYS.RESUME_PARSE_HELPER]);
  const { width = 300, fileUpoaderClass = '', setFile, onClickUpload } = props;
  const [value, setValue] = useState([]);
  const validFileType = ['DOC', 'DOCX', 'PDF', 'RTF', 'TXT', 'ODT', 'HTM', 'HTML', 'DOCM', 'DOTM', 'DOT', 'DOTX'];
  const MAX_LIMIT = 25;
  const handleChange = file => {
    const fileArray = Object.values(file);
    setValue(fileArray);
    setFile(fileArray);
  };

  const deleteFile = index => {
    value.splice(index, 1);
    setValue([...value]);
    setFile([...value]);
  };

  return (
    <div className='flex flex-column'>
      <div className='d-flex flex-wrap justify-content-around mb-1 p-2'>
        {value.map((file, index) => (
          <div className='m-1 border rounded bg-custom-grey p-2 d-flex align-items-center' key={index}>
            <div> {file.name}</div>
            <CloseIcon onClick={() => deleteFile(index)} className='close-icon ml-1' />
          </div>
        ))}
      </div>
      <div className='d-flex justify-content-center align-items-center'>
        <Box id='resume'>
          <FileUploader handleChange={handleChange} name='file' multiple={true} classes={fileUpoaderClass} types={validFileType}>
            <Box sx={{ width: width }} className={'resumeParent'}>
              <Box className='label-text fs-13 d-flex align-items-center'>{t(`${TRANS_KEYS.CONTACTS_UPLOAD_FILE}:key`)}</Box>
            </Box>
          </FileUploader>
        </Box>
        {value && value.length > 0 && (
          <CustomButton
            type={'primary ml-2'}
            size={'medium'}
            disabled={value.length > MAX_LIMIT}
            onClick={onClickUpload}
            buttonText={t(`${TRANS_KEYS.PARSE}:key`)}
            customWidth={96}
            variant='contained'
          />
        )}
      </div>
      <div className='mt-2 w-100 d-flex justify-content-center'>
        {value.length == 0 && <p>{t(`${TRANS_KEYS.RESUME_PARSE_HELPER}:key`)}</p>}
        {value.length > MAX_LIMIT && (
          <p className='font-color-red'>
            {t(`${TRANS_KEYS.RESUME_PARSE_LIMIT}:key`)}
            {value.length - MAX_LIMIT} days
          </p>
        )}
      </div>
    </div>
  );
};
ResumeUpload.propTypes = {
  setFile: PropTypes.func,
  width: PropTypes.number,
  fileUpoaderClass: PropTypes.string,
  onClickUpload: PropTypes.func
};
export default ResumeUpload;
