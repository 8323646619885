//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InvoiceSummary from './List';

export default function NextTenDays() {
  return <InvoiceSummary type='nextTenDays' storageKey='invoiceSummery' title='Next Ten Days Invoice' />;
}
