//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';
//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

// -------------------------------------------------// External Imports// -------------------------------------------------

import { Button } from '@mui/material';

export default function Footer(props) {
  const { prev, next, value, startDate } = props;
  return (
    <div style={{ overflow: 'hidden', position: 'sticky', bottom: 0, height: '70px', width: '100%', backgroundColor: 'rgb(244, 244, 245)' }}>
      <span style={{ float: 'right', position: 'relative', top: '50%', msTransform: 'translateY(-50%)', transform: 'translateY(-50%)' }}>
        {value !== 0 ? (
          <Button onClick={prev} style={{ marginRight: '25px' }} variant={value === 6 ? 'outlined' : 'text'} color={'primary'}>
            {' '}
            {value === 6 ? '' : '<'} Previous
          </Button>
        ) : (
          <span />
        )}
        {value < 4 || (startDate && value >= 4) ? (
          <Button onClick={next} style={{ marginRight: '25px' }} variant={value !== 6 ? 'outlined' : ''} color={'primary'}>
            Next
          </Button>
        ) : (
          <span />
        )}
      </span>
    </div>
  );
}

Footer.propTypes = {
  prev: PropTypes.func,
  next: PropTypes.func,
  value: PropTypes.number,
  startDate: PropTypes.bool
};
