/* eslint-disable react/prop-types */
import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import CheckboxSelection from '../../CheckboxSelection';
import CustomPopup from '../CustomPopup';
import OffLimitInfo from './OffLimitInfo';
import './index.scss';

const OffLimitWarning = ({ open, onClose, onConfirm, offlimits, buttonText = 'Add to Project', avoidHardLimit = false, showTickWarning = false, companyOffLimit, contactDetails }) => {
  const isHardOfflimit = !avoidHardLimit && offlimits?.find(offlimit => offlimit.severity === 'hard');
  const confirmText = isHardOfflimit ? 'OK' : buttonText;

  const [isChecked, setChecked] = useState(!showTickWarning);

  const updateInfoState = checked => setChecked(checked);

  return (
    <CustomPopup
      open={open}
      onClose={onClose}
      title='Off-limit(s) Warning'
      confirmText={confirmText}
      cancelText='Cancel'
      showAction={true}
      disabled={!isChecked}
      hideSubmit={isHardOfflimit}
      handleSubmit={onConfirm}
      overFlowClass='overflowScroll'
      titleClassName='offLimit-warning-popup-title'
      paperStyles='warning-offLimit'
    >
      <Box id='offlimit-warning-popup-v2'>
        <Typography>Warning: The following contact is considered off-limits because of the following:</Typography>
        <Typography className='italic'>{"Note: If a record has a 'Hard' off-limit it cannot be added to a new project unless the 'hard' off-limit is removed."}</Typography>
        {companyOffLimit && (
          <Typography
            sx={{
              marginBottom: '5px',
              fontWeight: 'bold'
            }}
          >
            {isHardOfflimit
              ? `The contact (${contactDetails?.name}) has been successfully created, but the company associated with this candidate is marked as hard off-limits. Candidates from this company cannot be added to the project.`
              : `The contact (${contactDetails?.name}) has been successfully created, but the company associated with this candidate is marked as soft off-limits`}
          </Typography>
        )}
        {offlimits.map((offlimit, index) => {
          if (offlimit?.offLimits) return <OffLimitsForUsers key={index} offLimits={offlimit?.offLimits} contactDetails={offlimit?.contactDetails} />;
          return <OffLimitInfo key={index} details={offlimit} />;
        })}
        {!isHardOfflimit && showTickWarning && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              marginTop: offlimits[0]?.offLimits ? '10px' : 0
            }}
          >
            <CheckboxSelection updateInfoState={updateInfoState} value={isChecked} />
            <Typography
              sx={{
                marginBottom: 0,
                fontStyle: ' italic !important',
                fontSize: '15px !important'
              }}
            >{`Yes, I would like to add ${offlimits[0]?.offLimits ? 'these profiles' : 'this profile'} to the Client Portal for this project`}</Typography>
          </Box>
        )}
      </Box>
    </CustomPopup>
  );
};

export default OffLimitWarning;

function OffLimitsForUsers(props) {
  const { offLimits, contactDetails } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px'
      }}
    >
      <Typography>{contactDetails?.name ? contactDetails?.name : contactDetails?.first_name ? `${contactDetails?.first_name} ${contactDetails?.last_name}` : ''} -</Typography>
      {offLimits.map((offLimit, index) => {
        return <OffLimitInfo key={index} details={offLimit} />;
      })}
    </Box>
  );
}
