import { Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const ClientContact = props => {
  return (
    <div className='flex-center-w-full'>
      <div
        className='circle-avatar'
        style={{
          backgroundImage: `url(${props.image})`
        }}
      ></div>
      <Typography style={{ marginLeft: '1rem', fontSize: '13px' }}>{props.name}</Typography>
    </div>
  );
};

ClientContact.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string
};

export default ClientContact;
