import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/common/CustomButton';
import CustomPopup from '../../../components/common/CustomPopup';
import { ROUTES } from '../../../services/constantService';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import successPhoto from './../../../assets/images/project_create_success.svg';
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: '8px'
};
function SuccessModal(props) {
  const { projectId, isLoading, isMessage = false, isLive = true } = props;
  const navigate = useNavigate();
  //Translation
  const { t } = useLanguageHooks([
    TRANS_KEYS.ADD_PROJECT_SUCCESS_MODAL_HEADER,
    TRANS_KEYS.ADD_PROJECT_SUCCESS_MODAL_BUTTON,
    TRANS_KEYS.ADD_PROJECT_SUCCESS_MODAL_SUBTITLE,
    TRANS_KEYS.ADD_PROJECT_SUCCESS_MODAL_SUBTITLE_SUCCESS,
    TRANS_KEYS.ALL_PROJECTS,
    TRANS_KEYS.ADD_PROJECT_GENERATE_MESSAGE
  ]);

  const handleViewButton = () => {
    let pathWithId = ROUTES.viewProjectWithDeepLink.replace(':id', projectId);
    pathWithId = pathWithId.replace(':parentTab', 'Roadmap');
    pathWithId = pathWithId.replace(':subTab', 'Position Profile');
    navigate(pathWithId);
  };
  return (
    <CustomPopup open={props.open} onClose={props.handleOpen}>
      <Box sx={{ ...modalStyle, width: '40vw' }} className='p-4'>
        <Stack direction='row' justifyContent={'flex-end'}>
          {/* {!isMessage && <CloseIcon onClick={props.handleOpen} />} */}
        </Stack>
        <Stack direction={'column'} spacing={2} justifyContent={'center'} alignItems={'center'}>
          <img style={{ width: '6vw' }} src={successPhoto} />
          {isLive && (
            <Typography variant='subtitle1' textAlign={'center'} sx={{ fontWeight: 'bold' }}>
              {t(`${TRANS_KEYS.ADD_PROJECT_SUCCESS_MODAL_HEADER}:key`)}
            </Typography>
          )}
          {!isMessage && isLoading && (
            <Typography variant='caption' textAlign={'center'} width={'20vw'}>
              {t(`${TRANS_KEYS.ADD_PROJECT_SUCCESS_MODAL_SUBTITLE}:key`)}
            </Typography>
          )}
          {!isMessage && !isLoading && (
            <Typography variant='caption' textAlign={'center'} width={'20vw'}>
              {t(`${TRANS_KEYS.ADD_PROJECT_SUCCESS_MODAL_SUBTITLE_SUCCESS}:key`)}
            </Typography>
          )}
          {isMessage && (
            <Typography variant='caption' textAlign={'center'} width={'20vw'}>
              {t(`${TRANS_KEYS.ADD_PROJECT_GENERATE_MESSAGE}:key`)}
            </Typography>
          )}
          {isLoading && <CircularProgress color={'primary'} className='loader-class' />}

          {!isMessage && !isLoading && (
            <>
              <CustomButton type={'primary'} onClick={handleViewButton} buttonText={t(`${TRANS_KEYS.ADD_PROJECT_SUCCESS_MODAL_BUTTON}:key`)} />
              <Link to={ROUTES.mySearches}>
                <Typography
                  variant='subtitle2'
                  textAlign={'center'}
                  sx={{
                    textDecoration: 'underline !important'
                  }}
                  color={'primary.main'}
                >
                  {t(`${TRANS_KEYS.ALL_PROJECTS}:key`)}
                </Typography>
              </Link>
            </>
          )}
        </Stack>
      </Box>
    </CustomPopup>
  );
}

SuccessModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  isLoading: PropTypes.bool,
  isMessage: PropTypes.bool,
  isLive: PropTypes.bool
};

export default SuccessModal;
