//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
//----------------------------------------------// External Imports // -------------------------------------------------
import { saveAs } from 'file-saver';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { BLOB, GET, ERROR } from '../../../../../services/constantService';
import { searchDocumentApi } from '../../../../../services/ApiService';
import { unableMessage } from '../../../../../services/MessageService';
import MessageLoader from '../../../../common/MessageLoader/MessageLoader';

function SearchDocumentView(props) {
  const { currentValues, enqueueSnackbar } = props;
  const [searchDocuments, setSearchDocuments] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    setSearchDocuments(currentValues.search_documents ? currentValues.search_documents : []);
  }, [currentValues]);

  const downloadDocument = async (id, name) => {
    setIsLoading(true);
    setStatusMessage('Downloading Search Document');
    const sub_route = `documents/${id}/download`;
    const { status, data } = await searchDocumentApi(GET, sub_route, {}, {}, BLOB);
    if (status === 200) {
      saveAs(data, name);
    } else {
      const message = unableMessage(`${name}`, 'download');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setIsLoading(false);
    setStatusMessage('');
  };

  return (
    <>
      <MessageLoader show={loading} message={statusMessage} />
      <div className='flex-grow-1' id='clientInformation'>
        <Accordion defaultExpanded={true} className='w-100 mb-5' style={{ background: '#fff', color: '#fff' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />} aria-controls='panel1a-content' style={{ background: '#2a364c' }}>
            <div>Upload & Attach Files</div>
          </AccordionSummary>
          <AccordionDetails className='d-flex flex-column p-0 '>
            <div className='section-content team-information-table flex-grow-1 mb-2 p-0'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center table-main-header'>
                  <div className='col-0-5'></div>
                  <div className='col-3 team-info-field'>Document Type</div>
                  <div className='col-2 team-info-field'>Allowed Extensions</div>
                  <div className='col-4 team-info-field' style={{ marginLeft: '15px' }}>
                    Upload
                  </div>
                  <div className='col-2 team-info-field'>Show In Candidatesuite</div>
                </div>
                {searchDocuments?.length > 0 &&
                  searchDocuments.map((item, index) => (
                    <div className='d-flex pt-4 pb-4' key={index}>
                      <div className=' d-flex  col-0-5 align-items-center justify-content-center align-self-baseline team-info-field' style={{ color: 'black', whiteSpace: 'normal' }}>
                        {index + 1}{' '}
                      </div>
                      <div className='d-flex col-3 align-items-center justify-content-center align-self-baseline ml-8 team-info-field' style={{ color: 'black', wordBreak: 'break-word' }}>
                        {' '}
                        {item.doc_desc || '--'}
                      </div>
                      <div
                        className=' d-flex  col-2 align-items-center justify-content-center align-self-baseline team-info-field'
                        style={{ color: 'black', whiteSpace: 'normal', wordBreak: 'break-word' }}
                      >
                        {' '}
                        {item.allowed_extension ? item.allowed_extension : '--'}{' '}
                      </div>
                      <div
                        className=' d-flex col-4 align-items-center justify-content-center align-self-baseline action-text company-redirect-link team-info-field'
                        style={{ cursor: 'pointer', whiteSpace: 'normal', wordBreak: 'break-word' }}
                        onClick={() => {
                          downloadDocument(item.id, item.file_name);
                        }}
                      >
                        {' '}
                        {item.file_name || '--'}{' '}
                      </div>
                      <div className=' d-flex col-2 align-items-center justify-content-center align-self-baseline action-text company-redirect-link team-info-field'>
                        <input type='checkbox' className='custom-checkbox' checked={item.show_in_candidatesuite} disabled />
                      </div>
                    </div>
                  ))}
                {searchDocuments?.length === 0 && (
                  <div className='d-flex pt-4'>
                    <div className=' d-flex  col-0-5 align-items-center justify-content-center align-self-baseline' style={{ color: 'black', whiteSpace: 'normal' }}>
                      {'--'}{' '}
                    </div>
                    <div className=' d-flex col-3 align-items-center justify-content-center align-self-baseline' style={{ color: 'black' }}>
                      {' '}
                      {'--'}
                    </div>
                    <div className=' d-flex  col-2 align-items-center justify-content-center align-self-baseline' style={{ color: 'black', whiteSpace: 'normal' }}>
                      {' '}
                      {'--'}{' '}
                    </div>
                    <div className=' d-flex col-4 align-items-center justify-content-center align-self-baseline mr-8' style={{ color: 'black' }}>
                      {' '}
                      {'--'}{' '}
                    </div>
                    <div className=' d-flex col-2 align-items-center justify-content-center align-self-baseline mr-8' style={{ color: 'black' }}>
                      {' '}
                      {'--'}{' '}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}

SearchDocumentView.propTypes = {
  currentValues: PropTypes.object,
  enqueueSnackbar: PropTypes.func
};

export default SearchDocumentView;
