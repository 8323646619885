import * as React from 'react';
import Menu from '@mui/material/Menu';

import CustomButton from '../CustomButton';
import PropTypes from 'prop-types';

export default function CustomButtonDropDown(props) {
  const { show = true, children, type = 'primary', size = 'small', variant = 'contained', buttonText = '', customWidth, iconLeft, iconRight, disabled = false, width, styledMenuProps } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return !show ? (
    <></>
  ) : (
    <div>
      <CustomButton
        disableElevation
        onClick={handleClick}
        type={type}
        size={size}
        variant={variant}
        buttonText={buttonText}
        customWidth={customWidth}
        iconLeft={iconLeft}
        iconRight={iconRight}
        disabled={disabled}
        width={width}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} {...styledMenuProps}>
        {children.map(child => {
          return (
            <child.type
              key={child.key}
              onClick={() => {
                handleClose();
                if (child.props.onClick) {
                  child.props.onClick();
                }
                if (props.onClick) {
                  props.onClick(child.key);
                }
              }}
              {...child.props}
            >
              {child.props.children}
            </child.type>
          );
        })}
      </Menu>
    </div>
  );
}

CustomButtonDropDown.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  buttonText: PropTypes.string,
  customWidth: PropTypes.number,
  onClick: PropTypes.func,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  styledMenuProps: PropTypes.object,
  show: PropTypes.bool,
};
