//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { POST, SUCCESS } from '../../../services/constantService';
import { userDataApi } from '../../../services/ApiService';
import { successMessage } from '../../../services/MessageService';
import { useSnackbar } from 'notistack';

function SaveQueryDialog(props) {
  const { open, handleClose, queryData, userId, type } = props;
  const { register, handleSubmit } = useForm();
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const saveQuery = async data => {
    const { status } = await userDataApi(POST, userId, { ...data, query: queryData }, `${type}/queries`);
    if (status === 201) {
      handleClose();
      const message = successMessage('Contact by Query', 'saved');
      enqueueSnackbar(`${data.name} ${message}`, { variant: SUCCESS });
    } else {
      setError(true);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle style={{ width: 400 }} id='form-dialog-title'>
        Save Query
      </DialogTitle>
      <DialogContent>
        <TextField
          {...register('name', { required: true })}
          autoFocus
          InputLabelProps={{ focused: true }}
          required={true}
          error={error}
          helperText={error ? 'The query with given name already exist' : ''}
          margin='dense'
          name='name'
          label='Query Name'
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleSubmit(saveQuery)} color='primary'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SaveQueryDialog.propTypes = {
  open: PropTypes.bool,
  userId: PropTypes.string,
  type: PropTypes.string,
  queryData: PropTypes.object,
  handleClose: PropTypes.func,
  enqueueSnackbar: PropTypes.func
};

export default SaveQueryDialog;
