//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import TeamInfoTable from './Components/TeamInfoTable';
import { VALIDATION_MESSAGE } from '../../../../services/MessageService';
import { Typography } from '@mui/material';

export default function KGPTeamInfoView(props) {
  const { register, setValue, currentValues, renderActions, renderLabel, editingField, option, watch } = props;
  const recruiterMessage =
    watch('brand') === (watch('brand') === 'Advisory' ? 'Advisory' : 'Advisory')
      ? '<span><b>Note:</b> Recruiter Information section is not available for ‘Advisory’ Service Offering.<br/> You may consider selecting another ‘Service Offering’ in the Basic Information page ,if Recruiter Information is required.</span>'
      : null;
  const researcherMessage =
    watch('brand') === (watch('brand') === 'Advisory' ? 'Advisory' : 'Advisory')
      ? '<span><b>Note:</b> Researcher Information section is not available for ‘Advisory’ Service Offering.<br/> You may consider selecting another ‘Service Offering’ in the Basic Information page if Researcher Information is required.</span>'
      : null;
  return (
    <div className='input-form tab-content d-flex'>
      <div className='tab-view-content'>
        <div className='section-content'>
          <Typography className='section-label'>Team Information</Typography>
          <div className='team-information-table d-flex flex-column'>
            {/* <div className=" d-flex flex-column"> */}
            {/* <div className="d-flex align-items-center table-main-header">
              <div className="team-info-field name-field">Name</div>
              <div className="team-info-field">Origination Credit</div>
              <div className="team-info-field">Selling Credit</div>
              <div className="team-info-field">Execution</div>
              <div className="team-info-field">Total Credit</div>
              <div className="close-action"></div>
            </div> */}
            <TeamInfoTable
              option={option}
              header={'Partner Information'}
              label='Partner'
              field='partners'
              fields={{
                origination_credit: 30,
                selling_credit: 20,
                execution_credit: 50
              }}
              register={register}
              setValue={setValue}
              values={currentValues.partners}
              renderLabel={renderLabel}
              editingField={editingField}
              renderActions={renderActions}
            />
            <TeamInfoTable
              option={option}
              header={'Recruiter Information'}
              label='Recruiter'
              field='recruiters'
              fields={{
                execution_credit: 100
              }}
              register={register}
              setValue={setValue}
              values={currentValues.recruiters}
              renderLabel={renderLabel}
              editingField={editingField}
              renderActions={renderActions}
              readOnlyMessage={recruiterMessage}
            />
            <TeamInfoTable
              option={option}
              header={VALIDATION_MESSAGE.researcher_information}
              label='Researcher'
              field='researchers'
              fields={{
                execution_credit: 100
              }}
              register={register}
              setValue={setValue}
              values={currentValues.researchers}
              renderLabel={renderLabel}
              editingField={editingField}
              renderActions={renderActions}
              readOnlyMessage={researcherMessage}
            />
            <TeamInfoTable
              option={option}
              header={VALIDATION_MESSAGE.admin_information}
              label='Admin'
              field='eas'
              fields={{
                execution_credit: 100
              }}
              register={register}
              setValue={setValue}
              values={currentValues.eas}
              renderLabel={renderLabel}
              editingField={editingField}
              renderActions={renderActions}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

KGPTeamInfoView.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  currentValues: PropTypes.object,
  renderActions: PropTypes.func,
  renderLabel: PropTypes.func,
  editingField: PropTypes.string,
  option: PropTypes.array
};
