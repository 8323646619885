//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CallIcon from '@mui/icons-material/Call';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import FeedbackIcon from '@mui/icons-material/Feedback';
import DescriptionIcon from '@mui/icons-material/Description';
import PeopleIcon from '@mui/icons-material/People';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InformationUpdate from '../../../../../../assets/images/InformationUpdate.png';
import OtherInformation from '../../../../../../assets/images/OtherInformation.png';

export const getActivityIcon = activity => {
  switch (activity) {
    case 'Call':
      return <CallIcon fontSize='inherit' />;
    case 'Email':
      return <EmailIcon fontSize='inherit' />;
    case 'Text':
      return <DescriptionIcon fontSize='inherit' />;
    case 'LinkedIn':
      return <LinkedInIcon fontSize='inherit' style={{ color: 'rgb(40, 103, 178)' }} />;
    case 'Meetings':
      return <PeopleIcon fontSize='inherit' />;
    case 'Feedback':
      return <FeedbackIcon fontSize='inherit' />;
    case 'Other':
      return <img src={OtherInformation} alt='Other Information' style={{ width: 14, height: 14 }} />;
    case 'Information Update':
      return <img src={InformationUpdate} alt='Information Update' style={{ width: 14, height: 14 }} />;
    default:
      return '';
  }
};
