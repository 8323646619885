//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, InputLabel, Typography, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { getAccessToken } from '../../../services/cognitoService';
import RichTextPopup from '../../RichTextPopup';
import { API } from '../../../services/constantService';
import { candidateSnapshotTabDefaultValues, getActivityCandidateSnapshotTabPayload, removeSkipFields } from '../utils';
import TabLayoutFooter from '../../TabLayoutFooter';
import { cleanupFormValues } from '../../../utils/common';

const CandidateSnapshot = forwardRef((props, ref) => {
  const { updateContactInfo, saveStatus, autoSaveContactFields, getActivityLogInfoTabData, handleSaveTabData, readOnly, isRichTextSaved } = props;
  const [profileUrl, setProfileUrl] = useState(null);
  const [tabInfo, setTabInfo] = useState(null);
  const [currentValues, setCurrentValues] = useState(null);
  const [formDirtyFields, setFormDirtyFields] = useState([]);
  const { id, path, contactId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { register, reset, handleSubmit, getValues, setValue, formState, watch } = useForm({});
  const { dirtyFields } = formState;
  const actions = [
    {
      label: 'Save & Close',
      className: 'primary-btn mr-3',
      onClick: () => {
        handleSave(true);
      }
    },
    {
      label: 'Save',
      className: 'primary-btn',
      onClick: () => {
        handleSave(false, false, true);
      }
    }
  ];

  const getActivityLogInfo = useCallback(
    async hideLoader => {
      const data = await getActivityLogInfoTabData(hideLoader, 'CANDIDATE_SNAPSHOT_TAB');
      if (data) {
        setTabInfo(data);
      }
    },
    [contactId, id, path, enqueueSnackbar]
  );

  useEffect(() => {
    getActivityLogInfo();
  }, [getActivityLogInfo]);

  useEffect(() => {
    let defaultValues = candidateSnapshotTabDefaultValues(tabInfo);
    if (defaultValues && isRichTextSaved) {
      defaultValues = { ...defaultValues, ...cleanupFormValues(getValues()) };
      const forcedDirtyFields = removeSkipFields([...dirtyFields, ...formDirtyFields]);
      setFormDirtyFields(forcedDirtyFields);
    }

    if (defaultValues) {
      setCurrentValues(defaultValues);
      reset(defaultValues);
    }
  }, [tabInfo, reset, getValues]);

  const handleSave = async (saveAndClose, skipMessage = false, reload) => {
    const formValues = getValues();
    let result;

    const newDirtyFields = { ...dirtyFields, ...formDirtyFields };
    const payload = await getActivityCandidateSnapshotTabPayload(formValues, newDirtyFields);
    result = await handleSaveTabData(payload, saveAndClose, skipMessage, false, 0);

    if (result && reload) {
      setFormDirtyFields([]);
      await getActivityLogInfo();
    }
    if (!result) {
      handleSubmit(null);
    }
    return result;
  };

  useImperativeHandle(ref, () => ({
    saveTabData: (saveAndClose, skipMessage = false, reload) => {
      return handleSave(saveAndClose, skipMessage, reload);
    },
    isDirty: () => {
      let fields = removeSkipFields(Array.isArray(dirtyFields) ? [...dirtyFields] : Array.isArray(formDirtyFields) ? [...formDirtyFields] : []);
      return fields?.length > 0;
    },
    formData: () => {
      return getValues();
    },
    reload: hideLoader => {
      getActivityLogInfo(hideLoader);
    }
  }));

  useEffect(() => {
    if (currentValues) {
      reset(currentValues);
    }
  }, [currentValues]);
  useEffect(() => {
    if (currentValues) {
      const getProfileURL = async () => {
        const token = await getAccessToken();
        setProfileUrl(`${API.contact}/${currentValues.id}/image?token=${token}&time=${Date.now()}`);
      };

      if (currentValues.image_id) {
        getProfileURL().then(null);
      }
    }
  }, [currentValues?.id, currentValues?.image_id]);

  useEffect(() => {
    register('published_bio');
    register('career_snapshot_profile');
    register('personal_profile');
    register('contact_profile_pic');
  }, [register]);

  const onFileChange = event => {
    const file = event.target.files[0];

    if (file) {
      setNewProfilePic(file);
      const fr = new FileReader();
      fr.onload = function () {
        setProfileUrl(fr.result);
      };
      fr.readAsDataURL(file);
    }
  };

  const setNewProfilePic = profilePic => {
    setValue('contact_profile_pic', profilePic);
  };

  return (
    currentValues && (
      <div>
        <div className='d-flex contact-view input-form'>
          <div className='d-flex flex-column' style={{ width: '100%', maxWidth: 800 }}>
            <h5 className='mb-3'>{currentValues ? `${currentValues.first_name || ''} ${currentValues.last_name || ''}` : ''}</h5>
            <div className='d-flex mb-4'>
              <label className={'profile-image-container contact-profile-image d-flex candidate-snapshot-profile align-items-center justify-content-center edit}'} htmlFor='profile-pic-input'>
                <span className='image-container'>
                  {profileUrl ? (
                    <img src={profileUrl} alt='Profile' />
                  ) : (
                    <span className='default-profile-icon'>
                      <AccountCircleIcon fontSize='inherit' />
                    </span>
                  )}
                </span>
                <input
                  id='profile-pic-input'
                  type='file'
                  accept='image/*'
                  onClick={e => {
                    e.target.value = null;
                    setNewProfilePic(null);
                  }}
                  onChange={onFileChange}
                  hidden
                />
                <div className='profile-pic-edit'>
                  <EditIcon fontSize='inherit' />
                </div>
              </label>
              <div className='flex-grow-1 d-flex flex-column'>
                <div className='d-flex justify-content-between align-items-center'>
                  <InputLabel focused={true} className='field-title' color='primary' style={{ fontSize: 14 }}>
                    Published Bio
                  </InputLabel>
                  <FormControlLabel
                    className='checkbox-container'
                    control={
                      <Checkbox
                        className='checkbox-component'
                        defaultChecked={currentValues.is_published_bio_approved_by_partner}
                        size={'small'}
                        style={{ padding: '0 5px' }}
                        name='is_published_bio_approved_by_partner'
                        {...register('is_published_bio_approved_by_partner')}
                      />
                    }
                    label={<Typography style={{ color: watch('is_published_bio_approved_by_partner') ? '#686A7E' : 'red', fontSize: '14px' }}>Approved By Partner to Publish</Typography>}
                  />
                </div>
                <div className='published-bio react-quill-container'>
                  <div className='text-view-value p-0 w-100'>
                    <div className='outlined-border transform p-0 '>
                      <RichTextPopup
                        bounds={'.published-bio'}
                        name='published_bio'
                        onSave={updateContactInfo}
                        value={currentValues['published_bio']}
                        onChange={data => {
                          setValue('published_bio', data);
                        }}
                        title='Published Bio'
                        autoSave={autoSaveContactFields}
                        saveStatus={saveStatus}
                        updatedDate={currentValues?.updated_at}
                        restrictHeight={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex'>
              <div className='d-flex flex-column flex-grow-1 mr-2' style={{ flex: '1 0' }}>
                <div className='d-flex justify-content-between align-items-center'>
                  <InputLabel focused={true} className='field-title' color='primary' style={{ fontSize: 14 }}>
                    Professional Notes
                  </InputLabel>
                  <FormControlLabel
                    className='checkbox-container'
                    control={
                      <Checkbox
                        className='checkbox-component'
                        defaultChecked={currentValues.is_professional_profile_approved_by_partner}
                        size='small'
                        style={{ padding: '0 5px' }}
                        name='is_professional_profile_approved_by_partner'
                        {...register('is_professional_profile_approved_by_partner')}
                      />
                    }
                    label={<Typography style={{ color: watch('is_professional_profile_approved_by_partner') ? '#686A7E' : 'red', fontSize: '14px' }}>Approved By Partner to Publish</Typography>}
                  />
                </div>
                <div className='career-snapshot-profile react-quill-container'>
                  <div className='text-view-value p-0 w-100'>
                    <div className='outlined-border transform p-0 '>
                      <RichTextPopup
                        bounds={'.career-snapshot-profile'}
                        name='career_snapshot_profile'
                        onSave={updateContactInfo}
                        value={currentValues['career_snapshot_profile'] || ''}
                        onChange={data => {
                          setValue('career_snapshot_profile', data);
                        }}
                        title='Professional'
                        autoSave={autoSaveContactFields}
                        saveStatus={saveStatus}
                        updatedDate={currentValues?.updated_at}
                        restrictHeight={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-column flex-grow-1 ml-2' style={{ flex: '1 0' }}>
                <div className='d-flex justify-content-between align-items-center'>
                  <InputLabel focused={true} className='field-title' color='primary' style={{ fontSize: 14 }}>
                    Personal Notes
                  </InputLabel>
                  <FormControlLabel
                    className='checkbox-container'
                    control={
                      <Checkbox
                        className='checkbox-component'
                        defaultChecked={currentValues.is_personal_profile_approved_by_partner}
                        size='small'
                        style={{ padding: '0 5px' }}
                        name='is_personal_profile_approved_by_partner'
                        {...register('is_personal_profile_approved_by_partner')}
                      />
                    }
                    label={<Typography style={{ color: watch('is_personal_profile_approved_by_partner') ? '#686A7E' : 'red', fontSize: '14px' }}>Approved By Partner to Publish</Typography>}
                  />
                </div>
                <div className='personal-profile react-quill-container'>
                  <div className='text-view-value p-0 w-100'>
                    <div className='outlined-border transform p-0 '>
                      <RichTextPopup
                        bounds={'.personal-profile'}
                        name='personal_profile'
                        onSave={updateContactInfo}
                        value={currentValues['personal_profile'] || ''}
                        onChange={data => {
                          setValue('personal_profile', data);
                        }}
                        title='Personal'
                        autoSave={autoSaveContactFields}
                        saveStatus={saveStatus}
                        updatedDate={currentValues?.updated_at}
                        restrictHeight={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex flex-column flex-grow-1 ml-2 mt-3 pb-5' style={{ flex: '1 0' }}>
              <div className='d-flex justify-content-between align-items-center'>
                <InputLabel focused={true} className='field-title' color='primary' style={{ fontSize: 14 }}>
                  <span className='mr-1'>HL Personal Notes</span>
                  <Tooltip title='This comment will not be available in any reports'>
                    <InfoIcon style={{ fontSize: '1rem' }} />
                  </Tooltip>
                </InputLabel>
              </div>
              <div className='personal-profile react-quill-container'>
                <div className='text-view-value p-0 w-50'>
                  <div className='outlined-border transform p-0 '>
                    <RichTextPopup value={currentValues['hl_personal_profile'] || ''} setOpen={false} restrictHeight={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!readOnly && <TabLayoutFooter actions={actions} />}
      </div>
    )
  );
});

CandidateSnapshot.displayName = 'CandidateSnapshot';

CandidateSnapshot.propTypes = {
  register: PropTypes.func,
  currentValues: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  contact: PropTypes.object,
  updateContactInfo: PropTypes.func,
  saveStatus: PropTypes.bool,
  autoSaveContactFields: PropTypes.func,
  getActivityLogInfoTabData: PropTypes.func,
  handleSaveTabData: PropTypes.func,
  readOnly: PropTypes.bool,
  isRichTextSaved: PropTypes.func
};

export default CandidateSnapshot;
