//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InputField from './common/InputField';

export default function DiversityCandidateSelection(props) {
  const name = 'Diversity Candidate';
  const { label = name, placeholder = name, ...rest } = props;
  return (
    <Autocomplete
      {...rest}
      options={[
        { id: 'true', name: 'Yes' },
        { id: 'false', name: 'No' }
      ]}
      getOptionLabel={option => option.name}
      renderInput={params => <InputField {...params} variant='standard' label={label} placeholder={placeholder} />}
    />
  );
}

DiversityCandidateSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string
};
