import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import PropTypes from 'prop-types';
function PaginationNavigationBar(props) {
  const { page, nextPageRequest, prevPageRequest, pageCount } = props;
  const [loadingNextPage, setLoadingNextPage] = React.useState(false);
  const [loadingPrevPage, setLoadingPrevPage] = React.useState(false);
  const handleNextPage = async () => {
    setLoadingNextPage(true);
    await nextPageRequest();
    setLoadingNextPage(false);
  };
  const handlePrevPage = async () => {
    setLoadingPrevPage(true);
    await prevPageRequest();
    setLoadingPrevPage(false);
  };
  return (
    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} width={'100%'} fontSize={'12px'} backgroundColor={'white'} height={'40px'}>
      <Stack direction={'row'} gap={4} width={'400px'} justifyContent={'space-around'} alignItems={'center'} height={'40px'}>
        <Button onClick={handlePrevPage} disabled={page === 1 || loadingPrevPage}>
          {!loadingPrevPage ? <ArrowBackIosOutlinedIcon fontSize={'12px'} /> : <CircularProgress size={20} color='inherit' />}
        </Button>
        <Typography fontSize={'12px'}>
          <b>Page {page}</b> of <b>{pageCount}</b>
        </Typography>
        <Button onClick={handleNextPage} disabled={page === pageCount || loadingNextPage}>
          {!loadingNextPage ? <ArrowForwardIosOutlinedIcon fontSize={'12px'} /> : <CircularProgress size={20} color='inherit' />}
        </Button>
      </Stack>
    </Stack>
  );
}
PaginationNavigationBar.propTypes = {
  page: PropTypes.number.isRequired,
  nextPageRequest: PropTypes.func.isRequired,
  prevPageRequest: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  limitPerPage: PropTypes.number.isRequired
};

export default PaginationNavigationBar;
