import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import './index.scss';
const defOptions = [
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
  { id: 4, label: '4' },
  { id: 5, label: '5' }
];
const RadioButtonGroupWithLine = props => {
  const { keyId, handleChangeFunction, radioOptions, radioState, value } = props;
  const [selectedOption, setSelectedOption] = useState(value);
  const [options, setOptions] = useState(defOptions);
  const lineWidth = '100';

  useEffect(() => {
    if (radioOptions && radioOptions.length) {
      setOptions(radioOptions);
    }
    if (radioState && radioState[keyId]) {
      setSelectedOption(radioState[keyId]);
    }
  }, [radioOptions, radioState]);
  const handleOptionChange = id => {
    setSelectedOption(id);
    handleChangeFunction(keyId, id);
  };

  return (
    <Stack direction='row' key={keyId}>
      {options.map((option, index) => (
        <form className='radio-button-group' key={'form' + keyId + index}>
          <div key={'div' + keyId + index} className='radio-button-item'>
            <label key={'label' + keyId + index}>{option.label}</label>

            <input type='radio' name='radio-group' key={'input' + keyId + index} value={option.id} checked={selectedOption === option.id} onChange={() => handleOptionChange(option.id)} />
          </div>
          {index < options.length - 1 && (
            <div
              className='line-horizontal'
              style={{
                width: `${lineWidth}%`,
                backgroundColor: 'primary.main'
              }}
            />
          )}
        </form>
      ))}
    </Stack>
  );
};

RadioButtonGroupWithLine.propTypes = {
  keyId: PropTypes.string.isRequired,
  handleChangeFunction: PropTypes.func.isRequired,
  radioOptions: PropTypes.array,
  radioState: PropTypes.object,
  value: PropTypes.string
};

export default RadioButtonGroupWithLine;
