import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import PropTypes from 'prop-types';

/**
 * CustomMuiDropdown component
 * @param {{
 * isLoading?: boolean,
 * disabled?: boolean,
 * label?: string,
 * options?: object[],
 * value?: any,
 * getOptionLabel?: GetOptionLabel,
 * renderOption?: (props: object, option: object) => JSX.Element,
 * className?: string,
 * onChange?: (event: object, value: object) => void,
 * textFieldProps?: TextFieldProps,
 * autoCompleteProps?: AutocompleteProps,
 * size?: string,
 * name?: string
 * }} props
 */
const CustomMuiDropdown = ({
  isLoading = false,
  disabled = false,
  label = '',
  size,
  options = [],
  value = null,
  getOptionLabel = option => option?.short_desc,
  renderOption = (props, option) => <li {...props}>{getOptionLabel(option)}</li>,
  className,
  onChange,
  textFieldProps = {},
  autoCompleteProps = {},
  name,
  InputLabelProps = {},
  multiple = false,
  inputValue,
  onInputChange,
  required = false
}) => {
  const [search, setSearch] = React.useState('');

  return (
    <Autocomplete
      size={size}
      options={options}
      value={value}
      multiple={multiple}
      inputValue={inputValue || search}
      onInputChange={
        onInputChange
          ? onInputChange
          : (event, newInputValue) => {
              setSearch(newInputValue);
            }
      }
      getOptionLabel={getOptionLabel}
      popupIcon={<ExpandMoreIcon />}
      className={`animate-icon ${className}`}
      renderOption={renderOption}
      disabled={disabled}
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          name={name}
          required={required}
          label={label}
          variant='outlined'
          InputLabelProps={InputLabelProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color='primary' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
          {...textFieldProps}
        />
      )}
      {...autoCompleteProps}
    />
  );
};

CustomMuiDropdown.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  className: PropTypes.string,
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  onChange: PropTypes.func,
  autoCompleteProps: PropTypes.object,
  textFieldProps: PropTypes.object,
  onInputChange: PropTypes.func,
  size: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  multiple: PropTypes.bool,
  inputValue: PropTypes.string,
  required: PropTypes.bool
};

export default CustomMuiDropdown;

/**
 * @typedef {import('@mui/material/TextField').TextFieldProps} TextFieldProps
 */

/**
 * @typedef {import('@mui/material/Autocomplete').AutocompleteProps} AutocompleteProps
 */

/**
 * @typedef {import('@mui/material/Autocomplete').GetOptionLabel} GetOptionLabel
 */
