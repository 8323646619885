import { Checkbox, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const columnDefs = [
  {
    field: 'name',
    headerName: 'Resources',
    cellRenderer: 'nameRenderer',
    width: 200,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'ign_role_resources.can_create',
    headerName: 'Create',
    cellRenderer: 'switchRenderer',
    width: 134,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'ign_role_resources.can_read',
    headerName: 'Read',
    cellRenderer: 'switchRenderer',
    width: 134,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'ign_role_resources.can_update',
    headerName: 'Update',
    cellRenderer: 'switchRenderer',
    width: 134,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'ign_role_resources.can_delete',
    headerName: 'Delete',
    cellRenderer: 'switchRenderer',
    width: 134,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'of_type',
    headerName: 'Type',
    width: 200,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'parent',
    headerName: 'Parent',
    width: 200,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  }
];

function SwitchRenderer(props) {
  const { data, colDef, togglePermissions } = props;

  const field = colDef.field.split('.')[1];

  if (!data.usrResourceData) return <Switch defaultChecked={false} disabled size='small' />; // Just to prevent eager loading of switch

  return (
    <Switch
      defaultChecked={data.usrResourceData[field]}
      size='small'
      onChange={e => {
        togglePermissions(data.usrResourceData.id, {
          [field]: e.target.checked
        });
      }}
    />
  );
}

function NameRenderer(props) {
  const { data, toggleResource, roleId } = props;

  const resourceId = data.id;
  const userResourcesDataId = data?.usrResourceData?.id;

  const isChecked = userResourcesDataId ? true : false;

  return (
    <div className='d-flex align-items-center w-100'>
      <Checkbox
        defaultChecked={isChecked}
        onChange={e => {
          toggleResource(resourceId, roleId, e.target.checked);
        }}
      />
      <p>{props.value}</p>
    </div>
  );
}

NameRenderer.propTypes = {
  value: PropTypes.string,
  data: PropTypes.object,
  colDef: PropTypes.object,
  toggleResource: PropTypes.func,
  roleId: PropTypes.string
};

SwitchRenderer.propTypes = {
  value: PropTypes.bool,
  data: PropTypes.object,
  colDef: PropTypes.object,
  userRolesData: PropTypes.array,
  togglePermissions: PropTypes.func
};

export { columnDefs, NameRenderer, SwitchRenderer };
