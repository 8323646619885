import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import { Box, ClickAwayListener, Divider, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import DatePickerCommon from '../../../components/common/DatePicker/DatePicker';
import RichText from '../../../components/common/RichText/index';
import { formatDate, formatDateUtcLocal } from '../../../utils/date';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CustomTable from '../../common/CustomTable';
import ContactRelationTypeSelection from '../../common/FunctionalComponents/ContactRelationTypeSelection';
import ContactTypeSelection from '../../common/FunctionalComponents/ContactTypeSelection';
import GenderSelection from '../../common/FunctionalComponents/GenderSelection';
import RecordTypeSelection from '../../common/FunctionalComponents/RecordType/RecordType';
import './index.scss';

const ViewDetailsProfile = props => {
  const { renderActions, data, register, unregister, setValue = () => {}, updateField, isHeaderNav } = props;

  const edit = {
    birth_date: false,
    gender: false,
    relationshipType: false,
    contactName: false,
    highlights: false,
    contact_record_types: false
  };
  const [editing, setEditing] = useState(edit);
  const [isEditingRelationship, setIsEditingRelationship] = useState(true);
  const [isHighlightEditing, setIsHighlightEditing] = useState(false);
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_GENDER,
    TRANS_KEYS.CONTACTS_DATE_OF_BIRTH,
    TRANS_KEYS.CONTACTS_RELATIONSHIP,
    TRANS_KEYS.CONTACTS_CONTACT_NAME,
    TRANS_KEYS.CONTACTS_RELATION_TYPE,
    TRANS_KEYS.CONTACTS_HIGHLIGHTS,
    TRANS_KEYS.CONTACTS_RECORD_TYPE
  ]);

  const [relationTypeData, setRelationTypeData] = useState([{ contact_id: '', contact_ref_id: '', type: '' }]);
  const datePickerRef = useRef(null);
  const genderInputRef = useRef(null);
  const recordTypeInputRef = useRef(null);
  const highlightRef = useRef(null);
  useEffect(() => {
    setRelationTypeData(data?.contact_relationship_type);
  }, [data]);

  useEffect(() => {
    register('contact_relationship_type');
    register('gender');
    register('birth_date');
    register('highlights');
    register('contact_record_types');

    return () => {
      unregister('contact_relationship_type');
      unregister('gender');
      unregister('birth_date');
      unregister('highlights');
      unregister('contact_record_types');
    };
  }, [register, unregister]);

  const handleEditClick = type => {
    setEditing(prevState => ({ ...prevState, [type]: true }));
  };

  const handleCloseClick = type => {
    setEditing(prevState => ({ ...prevState, [type]: false }));
    if (type === 'highlights') {
      setIsHighlightEditing(false);
    }
  };

  const handleClickAway = type => {
    if (
      (type === 'birth_date' && !datePickerRef.current?.contains(event.target)) ||
      (type === 'gender' && !genderInputRef.current?.contains(event.target)) ||
      (type === 'contact_record_types' && !recordTypeInputRef.current?.contains(event.target)) ||
      (type === 'highlights' && !highlightRef.current?.contains(event.target))
    ) {
      handleCloseClick(type);
    }
  };

  const handleAddRelationship = () => {
    setRelationTypeData([...relationTypeData, { contact_id: '', contact_ref_id: '', type: '' }]);
  };

  const handleEditRelationshipChange = value => {
    setIsEditingRelationship(value);
  };

  const convertToTitleCase = (inputText = '') => {
    return inputText?.charAt(0)?.toUpperCase() + inputText?.slice(1)?.toLowerCase();
  };

  const handleRemoveRelationship = index => {
    const updatedRelation = [...relationTypeData];
    updatedRelation.splice(index, 1);
    setRelationTypeData(updatedRelation);
  };

  const handleHighlightClick = () => {
    setIsHighlightEditing(true);
  };

  useEffect(() => {
    setValue('birth_date', data?.birth_date);
    setValue('gender', data?.gender);
  }, []);

  const handleRelationChange = (index, field, value) => {
    const updatedRelation = relationTypeData.map((relation, i) => {
      if (i === index) {
        if (field === 'contact_ref_id') {
          return { ...relation, [field]: value?.id, contact_data: value };
        }
        if (field === 'type') {
          return { ...relation, [field]: value?.field_value, ign_translate_value: value };
        }
        return { ...relation, [field]: value?.field_value };
      }

      return relation;
    });
    setRelationTypeData(updatedRelation);
  };

  useEffect(() => {
    setValue(
      'contact_relationship_type',
      relationTypeData?.map(elem => {
        return {
          ...elem,
          type: elem?.type?.field_value ? elem?.type?.field_value : elem?.type,
          contact_id: data?.id
        };
      }),
      { shouldDirty: true }
    );
  }, [relationTypeData]);

  const handleChangeDOB = data => {
    setValue('birth_date', data);
  };

  const handleChangeGender = data => {
    setValue('gender', data);
  };

  const handleRecordTypeChange = data => {
    const recordData = data?.map(elem => {
      return {
        type: elem || elem?.field_value ? elem?.field_value || elem : null
      };
    });
    setValue('contact_record_types', recordData, { shouldDirty: true });
  };
  return (
    <div id='view-contact' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className={isHeaderNav === 'closed' ? 'details-container-close drawer-tab-layout-close' : 'details-container'}>
        <Box className={`scroll-content ${isHeaderNav === 'closed' ? 'details-container-subtab-close' : 'details-container-subtab'}`}>
          <Box className='d-flex m-2 justify-content-between'>
            {/* Date of Birth  */}
            <Box className='d-flex flex-column' sx={{ width: '50%', marginTop: '4px' }}>
              <Box className='d-flex align-items-center mb-1'>
                <EventIcon color='disabled' />
                <Box className='pl-1 hover-border'>
                  <Box className='title-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_DATE_OF_BIRTH}:key`)}</Box>
                </Box>
              </Box>
              <ClickAwayListener onClickAway={() => handleClickAway('birth_date')}>
                <Box ref={datePickerRef}>
                  {editing.birth_date ? (
                    <Box className='d-flex align-items-center content-space'>
                      <Box className='contact-details-value content-color fs-12 w-100'>
                        <DatePickerCommon
                          onChange={e => {
                            const newDate = e?.$d ? formatDateUtcLocal(e?.$d, 'YYYY-MM-DD') : null;
                            handleChangeDOB(newDate);
                          }}
                          value={data?.birth_date ? formatDate(data?.birth_date, 'DD/MM/YYYY') : null}
                          className={'viewcontact-datepicker w-100'}
                          disableFuture
                        />
                      </Box>
                      <Box>{renderActions('birth_date', true, handleCloseClick)}</Box>
                    </Box>
                  ) : (
                    <Box
                      className='input-field-data flex'
                      sx={{
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          backgroundColor: '#FFFFFF',
                          border: '1px solid #CCCCCC',
                          borderRadius: '4px',
                          cursor: 'pointer'
                        }
                      }}
                      onClick={() => handleEditClick('birth_date')}
                    >
                      {data?.birth_date ? (
                        <Typography className='contact-details-value content-color fs-12' sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', padding: '0 10px' }}>
                          {formatDate(data.birth_date, 'DD/MM/YYYY')}
                        </Typography>
                      ) : (
                        <DatePickerCommon
                          onChange={e => {
                            handleEditClick('birth_date');
                            const newDate = e?.$d ? formatDateUtcLocal(e?.$d, 'YYYY-MM-DD') : null;
                            handleChangeDOB(newDate);
                          }}
                          value={null}
                          className={'viewcontact-datepicker w-100'}
                          label={t(`${TRANS_KEYS.CONTACTS_DATE_OF_BIRTH}:key`)}
                          disableFuture
                          InputProps={{
                            readOnly: true,
                            onClick: () => handleEditClick('birth_date'),
                            style: { height: '100%' }
                          }}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </ClickAwayListener>
            </Box>
            {/* Gender */}
            <Box className='d-flex flex-column' sx={{ width: '50%', marginTop: '4px' }}>
              <Box className='d-flex align-items-center mb-1'>
                <PersonIcon color='disabled' />
                <Box className='pl-1 hover-border'>
                  <Box className='title-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_GENDER}:key`)}</Box>
                </Box>
              </Box>
              <ClickAwayListener onClickAway={() => handleClickAway('gender')}>
                <Box ref={genderInputRef}>
                  {editing.gender ? (
                    <Box className='d-flex align-items-center content-space'>
                      <Box className='contact-details-value content-color fs-12 w-100'>
                        <GenderSelection
                          onChange={(e, data) => {
                            handleChangeGender(data?.field_value);
                          }}
                          value={data?.gender}
                          className={'viewcontact-datepicker w-100'}
                          label={t(`${TRANS_KEYS.CONTACTS_GENDER}:key`)}
                        />
                      </Box>
                      <Box>{renderActions('gender', true, handleCloseClick)}</Box>
                    </Box>
                  ) : (
                    <Box
                      className='input-field-data flex'
                      sx={{
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          backgroundColor: '#FFFFFF',
                          border: '1px solid #CCCCCC',
                          borderRadius: '4px',
                          cursor: 'pointer'
                        }
                      }}
                      onClick={() => handleEditClick('gender')}
                    >
                      {data?.gender ? (
                        <Typography className='contact-details-value content-color fs-12' sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', padding: '0 10px' }}>
                          {convertToTitleCase(data.gender)}
                        </Typography>
                      ) : (
                        <GenderSelection
                          onChange={(e, data) => {
                            handleEditClick('gender');
                            handleChangeGender(data?.field_value);
                          }}
                          value={null}
                          className={'viewcontact-datepicker w-100'}
                          label={t(`${TRANS_KEYS.CONTACTS_GENDER}:key`)}
                          InputProps={{
                            readOnly: true,
                            onClick: () => handleEditClick('gender'),
                            style: { height: '100%' }
                          }}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </ClickAwayListener>
            </Box>
          </Box>

          <Box className='p-2 d-flex flex-column row-gap w-50'>
            {/* Record Type */}
            <Box className='d-flex flex-column' sx={{ width: '100%', marginTop: '4px' }}>
              <Box className='d-flex align-items-center mb-1'>
                <PersonIcon color='disabled' />
                <Box className='pl-1 hover-border'>
                  <Box className='title-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_RECORD_TYPE}:key`)}</Box>
                </Box>
              </Box>
              <ClickAwayListener onClickAway={() => handleClickAway('contact_record_types')}>
                <Box ref={recordTypeInputRef}>
                  {editing.contact_record_types ? (
                    <Box className='d-flex align-items-center content-space'>
                      <Box className='contact-details-value content-color fs-12'>
                        <RecordTypeSelection
                          label={t(`${TRANS_KEYS.CONTACTS_RECORD_TYPE}:key`)}
                          defaultValue={data?.contact_record_types}
                          onChange={data => {
                            handleRecordTypeChange(data);
                          }}
                        />
                      </Box>
                      <Box>{renderActions('contact_record_types', true, handleCloseClick)}</Box>
                    </Box>
                  ) : (
                    <Box
                      className='input-field-data flex'
                      sx={{
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          borderRadius: '4px',
                          cursor: 'pointer'
                        }
                      }}
                      onClick={() => handleEditClick('contact_record_types')}
                    >
                      {data?.contact_record_types && data.contact_record_types.length > 0 ? (
                        <Typography className='contact-details-value content-color fs-12' sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', padding: '0 10px' }}>
                          {data.contact_record_types.map(recordType => convertToTitleCase(recordType.type)).join(', ')}
                        </Typography>
                      ) : (
                        <RecordTypeSelection
                          label={t(`${TRANS_KEYS.CONTACTS_RECORD_TYPE}:key`)}
                          defaultValue={[]}
                          onChange={data => {
                            handleEditClick('contact_record_types');
                            handleRecordTypeChange(data);
                          }}
                          InputProps={{
                            readOnly: true,
                            onClick: () => handleEditClick('contact_record_types'),
                            style: { height: '100%' }
                          }}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </ClickAwayListener>
            </Box>
          </Box>

          <Divider variant='middle' />

          <Box className='section-details m-2 justify-content-center'>
            <Box className='language-section'>
              <CustomTable
                headerData={[t(`${TRANS_KEYS.CONTACTS_RELATION_TYPE}:key`), t(`${TRANS_KEYS.CONTACTS_CONTACT_NAME}:key`)]}
                title={t(`${TRANS_KEYS.CONTACTS_RELATIONSHIP}:key`)}
                handleAnotherRow={handleAddRelationship}
                rows={relationTypeData}
                disable={3}
                isEditing={isEditingRelationship}
                onEditChange={handleEditRelationshipChange}
                customTableId='view-contact-wfull'
                showAddAnotherButton={false}
                headerSize={'fs-12'}
                titleSize={'fs-13'}
                updateField={updateField}
                saveDataKey={'contact_relationship_type'}
                handleCloseClick={handleCloseClick}
              >
                {isEditingRelationship ? (
                  relationTypeData?.length === 0 ? (
                    <TableRow>
                      <TableCell align='center' className='p-2'>
                        <Box className='fs-11'>-</Box>
                      </TableCell>
                      <TableCell align='center' className='p-2'>
                        <Box className='fs-11'>-</Box>
                      </TableCell>
                    </TableRow>
                  ) : (
                    relationTypeData?.map((relation, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align='center' className='p-2'>
                            <Box className='fs-11'>{relation?.ign_translate_value?.short_desc ? relation?.ign_translate_value?.short_desc : relation.type}</Box>
                          </TableCell>
                          <TableCell align='center' className='p-2'>
                            <Box className='fs-11'>{relation?.contact_data?.name ? relation?.contact_data?.name : relation.name}</Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )
                ) : (
                  relationTypeData?.map((relation, index) => {
                    return (
                      <TableRow key={relation?.id}>
                        <TableCell className='justify-center align-center p-2'>
                          <Box className='d-flex align-item-center viewcontact-icon-style'>
                            <ContactRelationTypeSelection
                              searchResult='All'
                              label={t(`${TRANS_KEYS.CONTACTS_RELATION_TYPE}:key`)}
                              value={relation?.ign_translate_value?.short_desc ? relation?.ign_translate_value?.short_desc : relation?.type}
                              isDrawer={true}
                              selectedList={relationTypeData}
                              onChange={(e, data) => handleRelationChange(index, 'type', data)}
                              id={`type${index}`}
                            />
                          </Box>
                        </TableCell>
                        <TableCell className='input-field-data justify-center align-center p-2'>
                          <Box className='viewcontact-icon-style'>
                            <ContactTypeSelection
                              // defaultValue={relation?.relationType || ""}
                              isDrawer={true}
                              value={relation?.contact_data?.name ? relation?.contact_data?.name : relation.name || ''}
                              onChange={(e, data) => handleRelationChange(index, 'contact_ref_id', data)}
                              selectedList={relationTypeData}
                              // id={`relationType_${index}`}
                              // label={t(`${TRANS_KEYS.CONTACTS_CONTACT_NAME}:key`)}
                            />
                          </Box>
                        </TableCell>
                        {relationTypeData?.length > 1 && (
                          <TableCell className='remove-another-button p-2' mt={2}>
                            <CloseIcon onClick={() => handleRemoveRelationship(index)} className='table-close-icon' />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                )}
              </CustomTable>
            </Box>
          </Box>

          <Divider variant='middle' />
          <Box className='section_details m-2'>
            <Box className='highlight-section viewcontact-icon-style'>
              <Box className='d-flex align-items-center mb-1 justify-content-between'>
                <Box className='d-flex align-items-center'>
                  <DescriptionIcon color='disabled' />
                  <Box className='pl-1 hover-border'>
                    <Box className='title-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_HIGHLIGHTS}:key`)}</Box>
                  </Box>
                </Box>
                {editing.highlights && <Box>{renderActions('highlights', true, handleCloseClick)}</Box>}
              </Box>
              <ClickAwayListener onClickAway={() => handleClickAway('highlights')}>
                <Box ref={highlightRef}>
                  {editing.highlights ? (
                    <Box className='d-flex align-items-center content-space'>
                      <Box className='contact-details-value content-color fs-12 w-100' sx={{ marginTop: '10px' }}>
                        <RichText
                          defaultValue={data?.highlights || ''}
                          onChange={value => {
                            setValue('highlights', value.replace(/<\/?p>/g, ''));
                          }}
                          readOnly={false}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      className='input-field-data flex'
                      sx={{
                        minHeight: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        '&:hover': {
                          borderColor: '#CCCCCC',
                          borderRadius: '4px',
                          cursor: 'pointer'
                        },
                        marginTop: '10px'
                      }}
                      onClick={() => handleEditClick('highlights')}
                    >
                      {data?.highlights ? (
                        <Typography className='contact-details-value content-color fs-12' sx={{ width: '100%', minHeight: '40px', display: 'flex', alignItems: 'center', padding: '10px' }}>
                          {data.highlights.replace(/<\/?p>/g, '')}
                        </Typography>
                      ) : (
                        <RichText defaultValue='' onChange={() => {}} readOnly={true} placeholder={t(`${TRANS_KEYS.CONTACTS_HIGHLIGHTS}:key`)} />
                      )}
                    </Box>
                  )}
                </Box>
              </ClickAwayListener>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
ViewDetailsProfile.propTypes = {
  renderActions: PropTypes.func,
  data: PropTypes.object,
  register: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  unregister: PropTypes.func,
  values: PropTypes.object,
  watch: PropTypes.func,
  isHeaderNav: PropTypes.string,
  handleSave: PropTypes.func,
  defaultValues: PropTypes.object,
  updateField: PropTypes.func
};

export default ViewDetailsProfile;
