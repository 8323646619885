//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
//----------------------------------------------// Internal Imports // -------------------------------------------------

import { invitesTabDefaultValues } from '../utils';
import TabLayoutFooter from '../../TabLayoutFooter';
import CandidateSuite from './CandidateSuite';
import Athena from './Athena';

const Invites = forwardRef((props, ref) => {
  const { getActivityLogInfoTabData, readOnly, handleBack } = props;
  const [tabInfo, setTabInfo] = useState(null);
  const [currentValues, setCurrentValues] = useState(null);
  const { id, path, contactId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { reset, getValues } = useForm({});
  const actions = [
    {
      label: 'Close',
      className: 'primary-btn mr-3',
      onClick: () => {
        handleBack(true);
      }
    }
  ];

  const getActivityLogInfo = useCallback(
    async hideLoader => {
      const data = await getActivityLogInfoTabData(hideLoader, 'INVITES_TAB');
      if (data) {
        setTabInfo(data);
      }
    },
    [contactId, id, path, enqueueSnackbar]
  );

  useEffect(() => {
    getActivityLogInfo();
  }, [getActivityLogInfo]);

  useEffect(() => {
    let defaultValues = invitesTabDefaultValues(tabInfo);
    if (defaultValues) {
      setCurrentValues(defaultValues);
      reset(defaultValues);
    }
  }, [tabInfo, reset, getValues]);

  useImperativeHandle(ref, () => ({
    saveTabData: () => {
      return true;
    },
    isDirty: () => {
      return false;
    },
    formData: () => {
      return getValues();
    },
    reload: () => {
      getActivityLogInfo();
    }
  }));

  useEffect(() => {
    if (currentValues) {
      reset(currentValues);
    }
  }, [currentValues]);

  return (
    currentValues && (
      <div>
        <div className='contact-view d-flex' style={{ width: '90%' }}>
          <div className='section-container w-50' style={{ paddingRight: 30 }}>
            <Athena activityLog={tabInfo} getActivityLogInfo={getActivityLogInfo} />
          </div>
          <div className='section-container w-50' style={{ paddingRight: 30 }}>
            <CandidateSuite activityLog={tabInfo} getActivityLogInfo={getActivityLogInfo} />
          </div>
        </div>
        {!readOnly && <TabLayoutFooter actions={actions} />}
      </div>
    )
  );
});

Invites.displayName = 'Invites';

Invites.propTypes = {
  updateContactInfo: PropTypes.func,
  saveStatus: PropTypes.bool,
  autoSaveContactFields: PropTypes.func,
  getActivityLogInfoTabData: PropTypes.func,
  handleSaveTabData: PropTypes.func,
  readOnly: PropTypes.bool,
  handleBack: PropTypes.func
};

export default Invites;
