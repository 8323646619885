//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Loader from '../../../components/common/Loader';
import { fetchPickList } from '../../../actions';
import { searchDataApi } from '../../../services/ApiService';
import { GET, ERROR } from '../../../services/constantService';
import { unableMessage } from '../../../services/MessageService';

const eliminatedStages = [
  'Client Interviewed and Declined',
  'Client Declined Background',
  'KGP Partner Interviewed and Declined',
  'KGP Declined',
  'Candidate Declined',
  'Offer Rejected',
  'Offer Withdrawn'
];

function LiveSearchMetrics(props) {
  const { id, open, jobNumber, jobTitle, handleClose, enqueueSnackbar } = props;
  const [metrics, setMetrics] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [isEliminatedStagesOpen, setEliminatedStagesOpen] = useState(false);
  const [eliminatedCandidatesCount, setEliminatedCandidatesCount] = useState(0);
  const [eliminatedStageMetrics, setEliminatedStageMetrics] = useState({});
  const candidateStages = useSelector(state => state.commonReducer.candidateStages);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!candidateStages) {
      dispatch(fetchPickList('candidatestages', 'candidateStages'));
    } else {
      const metrics = {};
      const eliminatedMetrics = {};

      candidateStages.forEach(item => {
        if (eliminatedStages.includes(item.name)) {
          eliminatedMetrics[item.name] = 0;
        } else {
          metrics[item.name] = 0;
        }
      });
      setEliminatedStageMetrics(prevState => ({
        ...eliminatedMetrics,
        ...prevState
      }));
      setMetrics(prevState => ({ ...metrics, ...prevState }));
    }
  }, [candidateStages, dispatch]);

  useEffect(() => {
    const fetchLiveMetrics = async () => {
      const { status, data } = await searchDataApi(GET, id, {}, 'metrics');
      if (status === 200) {
        const currentMetrics = {};
        const eliminatedMetrics = {};
        let eliminatedCandidates = 0;
        let total = 0;
        data.forEach(item => {
          const count = parseInt(item.count);
          if (eliminatedStages.includes(item.stage)) {
            eliminatedMetrics[item.stage] = count;
            eliminatedCandidates += count;
          } else {
            currentMetrics[item.stage] = count;
          }
          total += count;
        });
        setEliminatedCandidatesCount(eliminatedCandidates);
        setEliminatedStageMetrics(prevState => ({
          ...prevState,
          ...eliminatedMetrics
        }));
        setMetrics(prevState => ({ ...prevState, ...currentMetrics }));
        setTotal(total);
      } else {
        const message = unableMessage('live search metrics', 'fetch');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
    };
    setLoading(true);
    open && fetchLiveMetrics().then(null);
    setLoading(false);
  }, [enqueueSnackbar, id, open]);

  const handleClick = () => {
    setEliminatedStagesOpen(prevState => !prevState);
  };

  return (
    <Popup className='live-search-metrics' open={open} onClose={handleClose} closeOnDocumentClick={false} closeOnEscape={false}>
      <React.Fragment>
        <Loader show={isLoading} />
        <div className='popup-container'>
          <div className='d-flex align-items-center justify-content-between top-bar'>
            <div className='d-flex align-items-end'>
              <Typography className='title'>Live Search Metrics</Typography>
              <Typography className='job-title m-0 ml-2' color='primary'>
                {jobTitle} {jobNumber}
              </Typography>
            </div>
            <CloseIcon cursor='pointer' onClick={handleClose} />
          </div>
          <div className='inside-container'>
            {Object.keys(metrics).map((stage, index) => {
              return (
                <div className='metric' key={index}>
                  <Typography className='stage-label'>{stage}</Typography>
                  <Typography className='value-label'>{metrics[stage]}</Typography>
                </div>
              );
            })}
            <div className='metric'>
              <Typography className='stage-label' onClick={handleClick} style={{ cursor: 'pointer' }}>
                {isEliminatedStagesOpen ? <RemoveIcon color='primary' fontSize='inherit' /> : <AddIcon color='primary' fontSize='inherit' />} Eliminated Candidates
              </Typography>
              <Typography className='value-label'>{eliminatedCandidatesCount}</Typography>
            </div>
            {isEliminatedStagesOpen &&
              Object.keys(eliminatedStageMetrics).map((stage, index) => {
                return (
                  <div className='metric ' key={index}>
                    <Typography className='stage-label eliminated'>{stage}</Typography>
                    <Typography className='value-label eliminated'>{eliminatedStageMetrics[stage]}</Typography>
                  </div>
                );
              })}
            <div className='d-flex'>
              <Typography className='stage-label' style={{ fontWeight: 500 }}>
                Total
              </Typography>
              <Typography className='value-label' style={{ fontWeight: 500 }}>
                {total}
              </Typography>
            </div>
          </div>
        </div>
        {/* <div className='d-flex align-items-center justify-content-end bottom-actions'>
          <Button variant='text' color='primary'>Reset</Button>
          <Button variant='contained' color='primary'>Print</Button>
        </div> */}
      </React.Fragment>
    </Popup>
  );
}

LiveSearchMetrics.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  id: PropTypes.string,
  jobNumber: PropTypes.number,
  jobTitle: PropTypes.string,
  enqueueSnackbar: PropTypes.func
};

export default LiveSearchMetrics;
