//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import StrengthsAndPotentialIssuesView from './StrengthsAndPotentialIssuesView';
import Edit from './Edit';

export default function StrengthsAndPotentialIssues(props) {
  const { contact, updateField, isEditing, setIsLoading, individualFieldSave, handelScroll } = props;
  const [issues, setIssues] = useState([]);
  const [strengths, setStrengths] = useState([]);
  const [saveStatus, setSaveStatus] = useState(true);
  const { register, setValue } = props.useForm;

  const addIssue = () => {
    setIssues(prevState => [...prevState, '']);
  };

  const removeIssue = index => {
    setIssues(prevState => {
      const issues = [...prevState];
      issues.splice(index, 1);
      return issues;
    });
  };

  const addStrength = () => {
    setStrengths(prevState => [...prevState, '']);
  };

  const removeStrength = index => {
    setStrengths(prevState => {
      const strengths = [...prevState];
      strengths.splice(index, 1);
      return strengths;
    });
  };

  useEffect(() => {
    setStrengths(contact.strengths || []);
    setIssues(contact.issues || []);
    register('strengths');
    register('issues');
  }, [contact, register]);

  useEffect(() => {
    setValue('issues', issues);
  }, [issues, setValue]);

  useEffect(() => {
    setValue('strengths', strengths);
  }, [strengths, setValue]);

  const handleChange = async (field, index, value) => {
    try {
      const updatedContact = {};
      if (field === 'issues') {
        const updatedIssues = [...issues];
        updatedIssues[index] = value[`Potential Issue ${index + 1}`];
        updatedContact.issues = updatedIssues;
        setIssues(updatedContact.issues);
      } else {
        const updatedStrengths = [...strengths];
        updatedStrengths[index] = value[`Strength ${index + 1}`];
        updatedContact.strengths = updatedStrengths;
        setStrengths(updatedContact.strengths);
      }
      const saveStatus = await individualFieldSave(updatedContact, false, undefined, false);
      setSaveStatus(saveStatus);
    } catch (e) {
      console.log('Error found in handleChange::', e);
    }
  };

  return isEditing ? (
    <Edit
      contact={contact}
      issues={issues}
      strengths={strengths}
      removeStrength={removeStrength}
      removeIssue={removeIssue}
      addStrength={addStrength}
      addIssue={addIssue}
      handleChange={handleChange}
      individualFieldSave={individualFieldSave}
      saveStatus={saveStatus}
      setSaveStatus={setSaveStatus}
      handelScroll={handelScroll}
    />
  ) : (
    <StrengthsAndPotentialIssuesView
      issues={issues}
      strengths={strengths}
      removeStrength={removeStrength}
      removeIssue={removeIssue}
      addStrength={addStrength}
      addIssue={addIssue}
      handleChange={handleChange}
      updateField={updateField}
      contact={contact}
      setIsLoading={setIsLoading}
      individualFieldSave={individualFieldSave}
      saveStatus={saveStatus}
      setSaveStatus={setSaveStatus}
      handelScroll={handelScroll}
    />
  );
}

StrengthsAndPotentialIssues.propTypes = {
  contact: PropTypes.object,
  saveContact: PropTypes.func,
  setContact: PropTypes.func,
  reloadData: PropTypes.func,
  useForm: PropTypes.object,
  updateField: PropTypes.func,
  isEditing: PropTypes.bool,
  setIsLoading: PropTypes.func,
  individualFieldSave: PropTypes.func,
  handelScroll: PropTypes.func
};
