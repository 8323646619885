import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickListData } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const LanguagesSelection = props => {
  const { name = '', onChange = () => {}, disabled = false, selectedList, isDrawer = false, label = '', defaultValue, setLanguageList = () => {}, viewAddress, ...res } = props;
  /*   const [options, setOptions] = useState([]);
  const languageOption = useSelector((state) => state.commonReducer.languageOption);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!languageOption) {
      dispatch(fetchPickList("languageOption"));
    } else {
      setOptions(languageOption);
    }
  }, [languageOption, dispatch]); */

  // const languageOption = [
  //   { label: "English", value: "English" },
  //   { label: "French", value: "French" },
  //   { label: "Hindi", value: "Hindi" },
  // ];

  const [options, setOptions] = useState([]);

  const languagesData = useSelector(state => state.commonReducer.languages);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!languagesData?.data) {
      dispatch(fetchPickListData('languages-picklist?name=', 'languages'));
    } else {
      if (languagesData?.data) {
        setLanguageList(languagesData?.data);
        const filterData = languagesData?.data?.filter(item => selectedList?.every(listItem => listItem?.language?.name !== item?.name));
        setOptions(filterData);
      } else {
        setOptions([]);
      }
    }
  }, [languagesData?.data, dispatch, selectedList]);

  return (
    <CustomDropdown
      {...res}
      value={defaultValue}
      options={options}
      name={name}
      onChange={onChange}
      disabled={disabled}
      label={label}
      isDrawer={isDrawer}
      defaultValue={defaultValue}
      viewAddress={viewAddress}
    />
  );
};

LanguagesSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isDrawer: PropTypes.bool,
  onChange: PropTypes.func,
  selectedList: PropTypes.array,
  label: PropTypes.string,
  setLanguageList: PropTypes.func,
  defaultValue: PropTypes.string,
  viewAddress: PropTypes.bool
};

export default LanguagesSelection;
