import PropTypes from 'prop-types';
import React /* useEffect, useState */ from 'react';
import CustomDropdown from '../../CustomDropdown';
// import { useDispatch, useSelector } from "react-redux";
// import { fetchPickLists } from "../../../../actions";

const SearchStageSelection = props => {
  //   const dispatch = useDispatch();
  //   const gender = useSelector((state) => state.commonReducer.gender)
  //   const [options, setOptions] = useState([]);

  //   useEffect(() => {
  //     if (!gender) {
  //       dispatch(fetchPickLists("GENDER", "gender"));
  //     } else {
  //       setOptions(gender);
  //     }
  //   }, [gender, dispatch]);

  const { className = 'SingleSelectStyle', onChange = () => {}, isDrawer = false, label = '', defaultValue, disabled, required, value, size = 'small' } = props;
  const searchStageOptions = [
    { label: 'Business Development', value: 'business development' },
    { label: 'other Development', value: 'other development' }
  ];

  return (
    <>
      <CustomDropdown
        label={label}
        options={searchStageOptions}
        className={className}
        onChange={onChange}
        isDrawer={isDrawer}
        inputValue={value}
        value={value}
        defaultValue={defaultValue}
        // selectedValue={value}
        disabled={disabled}
        required={required}
        size={size}
      />
    </>
  );
};

SearchStageSelection.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  isDrawer: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  size: PropTypes.string
};

export default SearchStageSelection;
