import { Button, Menu, MenuItem } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomButton from '../../../../components/common/CustomButton';
import Loader from '../../../../components/common/Loader';
import { ignWorkbenchDataApi } from '../../../../services/ApiService';
import { DELETE, GET } from '../../../../services/constantService';
import ExportCSV from '../../../../utils/ExportCSV';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import { WorkBenchContext } from '../../Context';
import NoMyListComponent from './Component/NoMyListComponent';
import ContactGrid from './Grid/ContactGrid';
import ProjectGrid from './Grid/ProjectGrid';
import QueryComponent from './QueryComponent/index';

const ButtonDropDown = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = option => {
    setAnchorEl(null);
    if (props.onClick && typeof props.onClick === 'function') {
      props.onClick(option);
    }
  };

  return (
    <div>
      <Button color={props.color || 'primary'} variant='contained' onClick={handleClick} size='small' disabled={props.disabled ? props.disabled : false}>
        {props.buttonText}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {props.options.map((option, index) => (
          <MenuItem onClick={() => handleClose(option)} key={index}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

ButtonDropDown.propTypes = {
  buttonText: PropTypes.string,
  options: PropTypes.array,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string
};
export default function WorkbenchComponent() {
  const { workbenchId, setWorkbenchId, refetch, setAddContactFromExistingModal, setAddProjectFromExistingModal, refetchFunc, types, noMyList, handleAreYouSureOpen } = useContext(WorkBenchContext);
  const { t } = useLanguageHooks([
    TRANS_KEYS.DESCRIPTION,
    TRANS_KEYS.CREATED_BY,
    TRANS_KEYS.CREATED_ON,
    TRANS_KEYS.UPDATED_ON,
    TRANS_KEYS.ADD_PROJECT,
    TRANS_KEYS.DELETE_WORKBENCH,
    TRANS_KEYS.REMOVE_CONTACT_FROM_WORKBENCH,
    TRANS_KEYS.REMOVE_PROJECT_FROM_WORKBENCH,
    TRANS_KEYS.ADD_PROJECT,
    TRANS_KEYS.CONTACTS_ADD_CONTACT
  ]);
  const { id, queryId } = useParams();
  const [displayData, setDisplayData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const [workbenchType, setWorkbenchType] = useState();

  const fetchWorkbenchData = async id => {
    try {
      if (id) {
        setLoading(true);
        const subRoute = `records?workbenchId=${id}`;
        const res = await ignWorkbenchDataApi(GET, '', {}, subRoute);
        if (res && res?.data) {
          const { data } = res;
          setWorkbenchType(data?.workbench?.type);
          setDisplayData(data);
          setWorkbenchId(id);
        }
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleAddContactButton = () => {
    setWorkbenchId(id);
    setAddContactFromExistingModal(true);
  };

  const onSelectionChanged = event => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => {
      return {
        ...node.data,
        contact_id: node?.data?.contact?.id,
        project_id: node?.data?.project?.id
      };
    });
    setSelectedIds(selectedData);
  };

  const handleRecordDelete = async (selectedIds, bulk = false) => {
    try {
      setLoading(true);
      for (let data of selectedIds) {
        let payload = {
          workbenchId: id
        };
        if (workbenchType == types.CONTACT) {
          payload = { ...payload, contactId: data.contact_id };
        }
        if (workbenchType == types.PROJECT) {
          payload = { ...payload, projectId: data.project_id };
        }

        await ignWorkbenchDataApi(DELETE, '', payload, 'remove-record');
      }
      setLoading(false);
      if (bulk) {
        setSelectedIds([]);
      }
      refetchFunc();
    } catch (err) {
      enqueueSnackbar(err, 'error');
    }
  };

  const handleDeleteList = async id => {
    try {
      setLoading(true);
      const payload = {
        id: id
      };
      const res = await ignWorkbenchDataApi(DELETE, '', payload, 'delete-workbench');
      if (res && res?.data) {
        enqueueSnackbar('Workbench is Delete successfully', 'success');
        setWorkbenchId(undefined);
        setDisplayData(null);
        refetchFunc();
        // onClose();
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoading(false);
      // onClose();
    }
  };

  const onClickDeleteWorkbench = () => {
    handleAreYouSureOpen({ func: handleDeleteList, data: workbenchId });
  };

  useEffect(() => {
    if (id) {
      fetchWorkbenchData(id);
      setWorkbenchId(id);
    }
  }, [id, refetch]);

  const CSVContactsExportColumns = [
    { key: 'contact.name', label: 'Name' },
    { key: 'contact.contact_company.name', label: 'Company Name' },
    { key: 'contact.current_job_title', label: 'Job Title' },
    { key: 'contact.linkedin_username', label: 'Linkedin Username' },
    { key: 'contact.linkedin_url', label: 'Linkedin Url' },
    { key: 'contact.gender', label: 'Gender' },
    { key: 'contact.contact_status', label: 'Contact Status' },
    { key: 'contact.contact_company.industries', label: 'Company Industries' },
    { key: 'contact.contact_address.address_lines', label: 'Contact Address' },
    { key: 'contact.contact_phones.phone_number', label: 'Contact Phone' },
    { key: 'contact.contact_emails.email', label: 'Contact Email' }
  ];
  //fields to access value inside array
  const contactsExportColumnsArrayMapping = {
    'contact.contact_address.address_lines': 'address_lines',
    'contact.contact_phones.phone_number': 'phone_number',
    'contact.contact_emails.email': 'email'
  };

  return (
    <div className='w-100 border background-white custom-vh-80 rounded p-4'>
      <Loader show={isLoading} />
      {queryId && <QueryComponent />}
      {id != undefined && displayData ? (
        <>
          {/* header */}
          <div className='d-flex justify-content-start align-items-center mt-2'>
            <div className='fs-16 fw-bolder ml-4'>
              <b>{displayData?.workbench?.name}</b>
            </div>
            <div className='fs-14 ml-4'>
              <b>{t(`${TRANS_KEYS.DESCRIPTION}:key`)}</b> : {displayData?.workbench?.description}
            </div>
            <div className='fs-14 ml-4'>
              <b>{t(`${TRANS_KEYS.CREATED_BY}:key`)}</b> : {displayData?.workbench?.owner?.name}
            </div>

            <div className='fs-14 ml-4'>
              <b>{t(`${TRANS_KEYS.CREATED_ON}:key`)}</b> : {new Date(displayData?.workbench?.created_at).toDateString()}
            </div>
            <div className='fs-14 ml-4'>
              <b>{t(`${TRANS_KEYS.UPDATED_ON}:key`)}</b> :{new Date(displayData?.workbench?.updated_at).toDateString()}
            </div>
          </div>

          {/* button section */}

          <div className='d-flex justify-content-between align-items-center mt-2 p-4'>
            {workbenchType == types.PROJECT && (
              <>
                <CustomButton buttonText={t(`${TRANS_KEYS.ADD_PROJECT}:key`)} onClick={() => setAddProjectFromExistingModal(true)} />
                <div className='d-flex'>
                  <ExportCSV iconHeight={16} iconWidth={16} columns={[]} data={displayData?.records || []} fileName='Projects.csv' />
                  <CustomButton
                    disabled={selectedIds.length == 0}
                    buttonText={t(`${TRANS_KEYS.REMOVE_PROJECT_FROM_WORKBENCH}:key`)}
                    type={'remove-workbench-button ml-1 mr-1'}
                    onClick={() => handleRecordDelete(selectedIds, true)}
                  />
                  <CustomButton buttonText={t(`${TRANS_KEYS.DELETE_WORKBENCH}:key`)} type={'remove-workbench-button'} onClick={data => onClickDeleteWorkbench(data)} />
                </div>
              </>
            )}
            {workbenchType == types.CONTACT && (
              <>
                <CustomButton buttonText={t(`${TRANS_KEYS.CONTACTS_ADD_CONTACT}:key`)} color='primary' onClick={handleAddContactButton} />
                <div className='d-flex'>
                  <ExportCSV
                    iconHeight={16}
                    iconWidth={16}
                    columns={CSVContactsExportColumns}
                    data={displayData?.records || []}
                    fileName='Contacts.csv'
                    arrayKeyMapping={contactsExportColumnsArrayMapping}
                  />
                  <CustomButton
                    disabled={selectedIds.length == 0}
                    buttonText={t(`${TRANS_KEYS.REMOVE_CONTACT_FROM_WORKBENCH}:key`)}
                    type={'remove-workbench-button mr-1'}
                    onClick={() => handleRecordDelete(selectedIds, true)}
                  />
                  <CustomButton buttonText={t(`${TRANS_KEYS.DELETE_WORKBENCH}:key`)} type={'remove-workbench-button'} onClick={data => onClickDeleteWorkbench(data)} />
                </div>
              </>
            )}
          </div>

          {/* table */}
          {!isLoading && (
            <div className='pl-4 pr-4'>
              {workbenchType == types.PROJECT && (
                <ProjectGrid data={displayData?.records} onSelect={onSelectionChanged} handleDeleteContact={data => handleAreYouSureOpen({ func: handleRecordDelete, data })} />
              )}
              {workbenchType == types.CONTACT && (
                <ContactGrid data={displayData?.records} onSelect={onSelectionChanged} handleDeleteContact={data => handleAreYouSureOpen({ func: handleRecordDelete, data })} />
              )}
            </div>
          )}
        </>
      ) : (
        <>{noMyList && <NoMyListComponent />}</>
      )}
    </div>
  );
}
