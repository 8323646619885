import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import NoteIcon from '@mui/icons-material/Note';
import PaidIcon from '@mui/icons-material/Paid';
import { Box, ClickAwayListener, TextField } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../src/utils/common.scss';
import { fetchCurrencyDetails } from '../../../actions';
import RichText from '../../../components/common/RichText/index';
import { picklistDropDownApis } from '../../../services/ApiService';
import { GET } from '../../../services/constantService';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CustomTable from '../../common/CustomTable';
import BonusTypeSelection from '../../common/FunctionalComponents/BonusTypeSelection';
import BonusUnitSelection from '../../common/FunctionalComponents/BonusUnitSelection';
import CurrencySelection from '../../common/FunctionalComponents/CurrencySelection';
import MakeConfidential from '../../common/MakeConfidential/MakeConfidential';
import CustomInputField from '../../common/StyledComponents/CustomInputField';
import './index.scss';

const ViewCompensation = props => {
  const { renderActions, data, register, unregister, setValue = () => {}, updateField, watch = () => {}, isHeaderNav } = props;

  const edit = {
    currency_iso_code: false,
    base_salary: false,
    compensation_release: false,
    bonusUnitSelection: false,
    bonusType: false,
    bonusValue: false,
    lti: false,
    compensation_expectation: false,
    compensation_notes: false
  };
  const contact_compensations = {
    ...edit
  };
  const [editing, setEditing] = useState(contact_compensations);
  const [isEditingBonus, setIsEditingBonus] = useState(true);
  const [compensationDetails, setCompensationDetails] = useState([]);
  const [bonusList, setBonusList] = useState([]);
  const dispatch = useDispatch();
  const [isCheckbox, setIsCheckbox] = useState(data?.contact_compensation?.compensation_release === true ? true : false);
  const currencyDetails = useSelector(state => (state.commonReducer[watch('contact_compensations.currency_iso_code')] ? state.commonReducer[watch('contact_compensations.currency_iso_code')] : {}));
  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(watch('contact_compensations.currency_iso_code'), dispatch);
      } catch (e) {
        console.log('Error found in currencyHandler::', e);
      }
    };
    if (watch('contact_compensations.currency_iso_code') && Object.keys(currencyDetails).length === 0) {
      currencyHandler();
    }
  }, [watch('contact_compensations.currency_iso_code')]);

  useEffect(() => {
    const bonusObjects = [];
    const sub_route = `currency-details?code=${data?.contact_compensation?.currency_iso_code}`;
    const getUnit = async () => {
      const { status, data } = await picklistDropDownApis(GET, sub_route);
      if (status === 200) {
        return data?.currency_icon;
      } else {
        return null;
      }
    };

    setIsCheckbox(data?.contact_compensation?.compensation_release === true ? true : false);
    const getBonusObject = async () => {
      if (data.contact_compensation && data.contact_compensation.actual_bonus_value) {
        const bonusType = data.contact_compensation.actual_bonus_type;
        const sign = bonusType === 'CURRENCY' ? (await getUnit()) || '$' : '%';

        bonusObjects.push({
          bonus_type: sign,
          bonus: data.contact_compensation.actual_type,
          bonus_value: data.contact_compensation.actual_bonus_value
        });
      }

      if (data.contact_compensation && data.contact_compensation.target_bonus_value) {
        const bonusType = data.contact_compensation.target_bonus_type;
        const sign = bonusType === 'CURRENCY' ? (await getUnit()) || '$' : '%';

        bonusObjects.push({
          bonus_type: sign,
          bonus: data.contact_compensation.target_type,
          bonus_value: data.contact_compensation.target_bonus_value
        });
      }

      if (bonusObjects.length > 0) {
        setCompensationDetails(bonusObjects);
      }
    };
    getBonusObject();
  }, [data.contact_compensation]);

  useEffect(() => {
    // let compensation_table = {
    //     contact_id: data?.id,
    // };
    let compensation_table;
    if (!data?.contact_compensation?.id) {
      compensation_table = { contact_id: data?.id };
    } else {
      compensation_table = { id: data?.contact_compensation?.id };
    }

    compensationDetails.forEach(ele => {
      let sign = ele.bonus_type === '%' ? 'PERCENTAGE' : 'CURRENCY';
      if (ele.bonus === 'Actual') {
        compensation_table.actual_bonus_type = sign;
        compensation_table.actual_bonus_value = ele.bonus_value;
      } else {
        compensation_table.target_bonus_type = sign;
        compensation_table.target_bonus_value = ele.bonus_value;
      }
    });

    if (compensationDetails.length === 1) {
      if (compensationDetails[0].bonus === 'Actual') {
        compensation_table.target_bonus_type = null;
        compensation_table.target_bonus_value = null;
      } else {
        compensation_table.actual_bonus_type = null;
        compensation_table.actual_bonus_value = null;
      }
    }
    setValue('contact_compensations', compensation_table);
  }, [compensationDetails]);

  console.log('compensationDetails', compensationDetails);

  useEffect(() => {
    register('currency_iso_code');
    register('base_salary');
    register('compensation_release');
    register('equity');
    register('compensation_expectation');
    register('compensation_notes');
    register('contact_compensations');
    return () => {
      unregister('currency_iso_code');
      unregister('base_salary');
      unregister('compensation_release');
      unregister('equity');
      unregister('compensation_expectation');
      unregister('compensation_notes');
      unregister('contact_compensations');
    };
  }, [register, unregister]);

  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_CURRENCY_CODE,
    TRANS_KEYS.CONTACTS_LTI,
    TRANS_KEYS.CONTACTS_COMPENSATION_RELEASE,
    TRANS_KEYS.CONTACTS_EXPECTED_COMPENSATION,
    TRANS_KEYS.CONTACTS_COMPENSATION_NOTES,
    TRANS_KEYS.CONTACTS_BASE_SALARY,
    TRANS_KEYS.CONTACT_OR,
    TRANS_KEYS.CONTACTS_BONUS_TYPE,
    TRANS_KEYS.CONTACTS_BONUS_VALUE,
    TRANS_KEYS.CONTACTS_BONUS
  ]);

  const handleEditClick = type => {
    setEditing(prevState => ({
      ...prevState,
      [type]: true
    }));
  };

  const handleCloseClick = type => {
    setEditing(prevState => ({
      ...prevState,
      [type]: false
    }));
  };

  const handleCompensationCheckbox = e => {
    setIsCheckbox(e.target.checked);
    setValue('contact_compensations.compensation_release', e.target.checked);
    if (!data?.contact_compensation?.id) {
      setValue('contact_compensations.contact_id', data?.id);
    } else {
      setValue('contact_compensations.id', data?.contact_compensation?.id);
    }
  };

  const handleChange = (index, field, value) => {
    const updatedCompensationDetails = compensationDetails.map((compensationDetail, i) => {
      if (i === index) {
        return { ...compensationDetail, [field]: value };
      }
      return compensationDetail;
    });
    setCompensationDetails(updatedCompensationDetails);
  };

  const handleAddBonus = () => {
    setCompensationDetails(prevState => [...prevState, { bonus_type: '', bonus: '', bonus_value: '' }]);
  };

  const handleEditBonus = value => {
    setIsEditingBonus(value);
  };

  const handleRemoveBonus = index => {
    const updatedCompensationDetails = [...compensationDetails];
    updatedCompensationDetails.splice(index, 1);
    setCompensationDetails(updatedCompensationDetails);
  };

  return (
    <div id='view-contact' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className={`${isHeaderNav === 'closed' ? 'details-container-close' : 'details-container'} scroll-content`}>
        <Box className='compensation-section m-2'>
          {/* Currency Code */}
          <Box className='d-flex flex-column' sx={{ width: '30%', marginTop: '4px' }}>
            <Box className='d-flex align-items-center mb-1'>
              <PaidIcon color='disabled' />
              <Box className='pl-1 hover-border'>
                <Box className='title-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_CURRENCY_CODE}:key`)}</Box>
              </Box>
            </Box>
            <Box className='section-block d-flex flex-column row-gap pl-0 ml-3'>
              <Box className='d-flex flex-row column-gap viewcontact-icon-style'>
                {editing.currency_iso_code === false}
                <Box className='d-flex row-gap contact-details' style={{ paddingRight: 0 }}>
                  <Box className='d-flex align-items-center'>
                    {editing.currency_iso_code === false && <Box className='title-color fs-12' onDoubleClick={() => handleEditClick('currency_iso_code')}></Box>}
                  </Box>

                  {editing.currency_iso_code ? (
                    <ClickAwayListener onClickAway={() => handleCloseClick('currency_iso_code')}>
                      <Box className='d-flex align-items-center content-space' style={{ width: '100%' }}>
                        <Box className='compact-auto' style={{ flexGrow: 1, width: '100%' }}>
                          <CurrencySelection
                            value={data?.contact_compensation?.currency_iso_code || ''}
                            onChange={(e, value) => {
                              setValue('contact_compensations.currency_iso_code', value?.label, { shouldDirty: true });
                              if (!data?.contact_compensation?.id) {
                                setValue('contact_compensations.contact_id', data?.id);
                              } else {
                                setValue('contact_compensations.id', data?.contact_compensation?.id);
                              }
                            }}
                            isDrawer={true}
                            style={{ width: '100%' }}
                            // className={'custom-drop-down-size'}
                            onFocus={() => renderActions('contact_compensations', true, () => handleCloseClick('currency_iso_code'))}
                          />
                        </Box>
                        <Box>{renderActions('contact_compensations', false, () => handleCloseClick('currency_iso_code'))}</Box>
                      </Box>
                    </ClickAwayListener>
                  ) : (
                    <Box className='d-flex align-center' style={{ width: '100%' }}>
                      {data?.contact_compensation?.currency_iso_code ? (
                        <Box
                          className='input-field-data flex'
                          sx={{
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #CCCCCC',
                              borderRadius: '4px',
                              cursor: 'pointer'
                            }
                          }}
                          onClick={() => handleEditClick('currency_iso_code')}
                        >
                          <Box className='contact-details-value content-color fs-12 w-100'>{data.contact_compensation.currency_iso_code}</Box>
                        </Box>
                      ) : (
                        <Box
                          className='input-field-data flex w-90'
                          sx={{
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #CCCCCC',
                              borderRadius: '4px',
                              cursor: 'pointer'
                            }
                          }}
                          onClick={() => handleEditClick('currency_iso_code')}
                        >
                          <TextField
                            id='outlined-basic'
                            placeholder='Currency Code'
                            type='text'
                            className='contact-details-value content-color fs-12 w-100'
                            InputProps={{
                              readOnly: true
                            }}
                            color='primary'
                            variant='outlined'
                            value=''
                            size='small'
                            fullWidth
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          {/* base salary */}
          <Box className='d-flex flex-column' sx={{ width: '30%', marginTop: '4px' }}>
            <Box className='d-flex align-items-center mb-1'>
              <PaidIcon color='disabled' />
              <Box className='pl-1 hover-border'>
                <Box className='title-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_BASE_SALARY}:key`)}</Box>
              </Box>
            </Box>
            <Box className='section-block d-flex flex-column row-gap pl-0 ml-3'>
              <Box className='d-flex flex-row column-gap viewcontact-icon-style'>
                {editing.base_salary === false}
                <Box className='d-flex row-gap contact-details' style={{ width: '100%', paddingRight: 0 }}>
                  <Box className='d-flex align-items-center'>{editing.base_salary === false && <Box className='title-color fs-12' onDoubleClick={() => handleEditClick('base_salary')}></Box>}</Box>

                  {editing.base_salary ? (
                    <ClickAwayListener onClickAway={() => handleCloseClick('base_salary')}>
                      <Box className='d-flex align-items-center content-space' style={{ width: '100%' }}>
                        <Box className='compact-auto' style={{ flexGrow: 1, width: '100%' }}>
                          <CustomInputField
                            type='number'
                            style={{ width: '100%' }}
                            defaultValue={data?.contact_compensation?.base_salary}
                            onChange={e => {
                              setValue('contact_compensations.base_salary', e.target.value);
                              if (!data?.contact_compensation?.id) {
                                setValue('contact_compensations.contact_id', data?.id);
                              } else {
                                setValue('contact_compensations.id', data?.contact_compensation?.id);
                              }
                            }}
                            sx={{ height: '100%', minWidth: '130px' }}
                          />
                        </Box>
                        <Box>{renderActions('contact_compensations', false, () => handleCloseClick('base_salary'))}</Box>
                      </Box>
                    </ClickAwayListener>
                  ) : (
                    <Box className='d-flex align-center' style={{ width: '100%' }}>
                      {data?.contact_compensation?.base_salary ? (
                        <Box
                          className='input-field-data flex w-90'
                          sx={{
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #CCCCCC',
                              borderRadius: '4px',
                              cursor: 'pointer'
                            }
                          }}
                          onClick={() => handleEditClick('base_salary')}
                        >
                          <Box className='contact-details-value content-color fs-12 w-100'>{data.contact_compensation.base_salary}</Box>
                        </Box>
                      ) : (
                        <Box
                          className='input-field-data flex w-90'
                          sx={{
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #CCCCCC',
                              borderRadius: '4px',
                              cursor: 'pointer'
                            }
                          }}
                          onClick={() => handleEditClick('base_salary')}
                        >
                          <TextField
                            id='outlined-basic'
                            placeholder='Base Salary'
                            type='text'
                            className='contact-details-value content-color fs-12 w-100'
                            InputProps={{
                              readOnly: true
                            }}
                            color='primary'
                            variant='outlined'
                            value=''
                            size='small'
                            fullWidth
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          {/* compensation release */}
          <Box className='d-flex flex-column' sx={{ width: '30%', marginTop: '6px' }}>
            <Box className='d-flex align-items-center mb-1'>
              <PaidIcon color='disabled' />
              <Box className='pl-1 hover-border'>
                <Box className='title-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_COMPENSATION_RELEASE}:key`)}</Box>
              </Box>
            </Box>
            <Box className='section-block col-4 d-flex flex-column row-gap pl-0'>
              <Box className='d-flex flex-row column-gap viewcontact-icon-style'>
                {editing.compensation_release === false}
                <Box className='d-flex row-gap contact-details'>
                  <Box className='d-flex align-items-center'>
                    {editing.compensation_release === false && <Box className='title-color fs-12' onDoubleClick={() => handleEditClick('compensation_release')}></Box>}
                  </Box>

                  {editing.compensation_release ? (
                    <ClickAwayListener onClickAway={() => handleCloseClick('compensation_release')}>
                      <Box className='d-flex align-items-center content-space' style={{ width: '100%' }}>
                        <Box className='compact-auto' style={{ flexGrow: 1, width: '100%', marginLeft: 10 }}>
                          <MakeConfidential
                            defaultChecked={isCheckbox}
                            isHiddenDefaultPadding={true}
                            placeholder='Compensation Release'
                            checked={isCheckbox}
                            name={t(`${TRANS_KEYS.CONTACTS_COMPENSATION_RELEASE}:key`)}
                            handleChange={handleCompensationCheckbox}
                          />
                        </Box>
                        <Box>{renderActions('contact_compensations', false, () => handleCloseClick('compensation_release'))}</Box>
                      </Box>
                    </ClickAwayListener>
                  ) : (
                    <Box className='d-flex align-items-center justify-content-between' style={{ width: '100%', marginLeft: '10px' }}>
                      <Box>
                        <Box className='contact-details-value content-color fs-12 w-100'>{data?.contact_compensation?.compensation_release ? 'Yes' : 'No'}</Box>
                      </Box>
                      <EditIcon className='mr-4' fontSize='small' onClick={() => handleEditClick('compensation_release')} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* row 2 */}

        <Box className='compensation-table m-2'>
          <Box className='section-block'>
            <CustomTable
              headerData={[`% ${t(`${TRANS_KEYS.CONTACT_OR}:key`)} $`, t(`${TRANS_KEYS.CONTACTS_BONUS_TYPE}:key`), t(`${TRANS_KEYS.CONTACTS_BONUS_VALUE}:key`)]}
              title={t(`${TRANS_KEYS.CONTACTS_BONUS}:key`)}
              handleAnotherRow={handleAddBonus}
              rows={compensationDetails}
              disable={2}
              isEditing={isEditingBonus}
              onEditChange={handleEditBonus}
              showAddAnotherButton={false}
              customTableId={'view-contact-content'}
              headerSize={'fs-12'}
              titleSize={'fs-13'}
              dataList={bonusList}
              updateField={updateField}
              saveDataKey={'contact_compensations'}
            >
              {isEditingBonus ? (
                compensationDetails.length === 0 ? (
                  <TableRow>
                    <TableCell align='center' className='p-2'>
                      <Box className='fs-11'>-</Box>
                    </TableCell>
                    <TableCell align='center' className='p-2'>
                      <Box className='fs-11'>-</Box>
                    </TableCell>
                    <TableCell align='center' className='p-2'>
                      <Box className='fs-11'>-</Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  compensationDetails.map((details, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align='center' className='p-2'>
                          <Box className='fs-11'>{details.bonus_type || '-'}</Box>
                        </TableCell>
                        <TableCell align='center' className='p-2'>
                          <Box className='fs-11'>{details.bonus || '-'}</Box>
                        </TableCell>
                        <TableCell align='center' className='p-2'>
                          <Box className='fs-11'>{details.bonus_value || '-'}</Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )
              ) : (
                compensationDetails.map((details, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className='p-2'>
                        <Box className='viewcontact-icon-style'>
                          <BonusUnitSelection
                            value={details.bonus_type || ''}
                            className={'custom-drop-down-size'}
                            isDrawer={true}
                            onChange={(e, value) =>
                              // console.log(value,"BonusUnitSelection")
                              handleChange(index, 'bonus_type', value?.label)
                            }
                            label={`% ${t(`${TRANS_KEYS.CONTACT_OR}:key`)} $`}
                          />
                        </Box>
                      </TableCell>

                      <TableCell className='p-2'>
                        <Box id='compensation-autocomplete' className='viewcontact-icon-style'>
                          <BonusTypeSelection
                            value={details.bonus || ''}
                            className={'custom-drop-down-size'}
                            isDrawer={true}
                            onChange={(e, value) => handleChange(index, 'bonus', value && value?.short_desc ? value.short_desc : null)}
                            selectedList={compensationDetails}
                            setBonusList={setBonusList}
                            label={t(`${TRANS_KEYS.CONTACTS_BONUS_TYPE}:key`)}
                          ></BonusTypeSelection>
                        </Box>
                      </TableCell>
                      <TableCell className='p-2'>
                        <Box className='compensation-input'>
                          <Box className='compensation-field'>
                            <CustomInputField
                              type='number'
                              value={details.bonus_value}
                              onChange={e => handleChange(index, 'bonus_value', e.target.value)}
                              label={t(`${TRANS_KEYS.CONTACTS_BONUS_VALUE}:key`)}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                      {compensationDetails.length > 1 && (
                        <TableCell className='remove-another-button p-2' mt={2}>
                          <CloseIcon onClick={() => handleRemoveBonus(index)} className='table-close-icon' />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              )}
            </CustomTable>
          </Box>
        </Box>

        <Box className='compensation-section m-2'>
          <Box className='d-flex flex-wrap' sx={{ width: '100%' }}>
            {/* LTI */}
            <Box className='d-flex flex-column' sx={{ width: '50%', paddingRight: '8px' }}>
              <Box className='section-block d-flex flex-column row-gap pl-0 mb-3 mb-md-0'>
                <Box className='d-flex flex-row column-gap viewcontact-icon-style'>
                  <PaidIcon color='disabled' style={{ width: 20, height: 20 }} />
                  <Box className='d-flex row-gap contact-details w-100'>
                    <Box className='d-flex align-items-center'>
                      <Box className='title-color fs-12' onDoubleClick={() => handleEditClick('lti')}>
                        {t(`${TRANS_KEYS.CONTACTS_LTI}:key`)}
                      </Box>
                    </Box>

                    {editing.lti ? (
                      <ClickAwayListener onClickAway={() => handleCloseClick('lti')}>
                        <Box className='d-flex align-items-center content-space' style={{ width: '100%' }}>
                          <Box className='compact-auto' style={{ flexGrow: 1, width: '100%' }}>
                            <CustomInputField
                              type='number'
                              defaultValue={data?.contact_compensation?.equity}
                              onChange={e => {
                                setValue('contact_compensations.equity', e.target.value);
                                if (!data?.contact_compensation?.id) {
                                  setValue('contact_compensations.contact_id', data?.id);
                                } else {
                                  setValue('contact_compensations.id', data?.contact_compensation?.id);
                                }
                              }}
                              onFocus={() => renderActions('contact_compensations', true, () => handleCloseClick('lti'))}
                            />
                          </Box>
                          <Box>{renderActions('contact_compensations', false, () => handleCloseClick('lti'))}</Box>
                        </Box>
                      </ClickAwayListener>
                    ) : (
                      <Box className='d-flex align-center' style={{ width: '100%' }}>
                        <Box
                          className='input-field-data flex'
                          sx={{
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'all 0.3s ease',
                            marginTop: '10px',
                            width: '100%',
                            '&:hover': {
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #CCCCCC',
                              borderRadius: '4px',
                              cursor: 'pointer'
                            }
                          }}
                          onClick={() => handleEditClick('lti')}
                        >
                          {data?.contact_compensation?.equity ? (
                            <Box className='contact-details-value content-color fs-12 w-100'>{data.contact_compensation.equity}</Box>
                          ) : (
                            <TextField
                              placeholder='Enter LTI'
                              variant='outlined'
                              size='small'
                              fullWidth
                              InputProps={{
                                readOnly: true
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Expected Compensation */}
            <Box className='d-flex flex-column' sx={{ width: '50%', paddingLeft: '8px' }}>
              <Box className='section-block d-flex flex-column row-gap pl-0'>
                <Box className='d-flex flex-row column-gap viewcontact-icon-style'>
                  <PaidIcon color='disabled' style={{ width: 20, height: 20 }} />
                  <Box className='d-flex row-gap contact-details w-100'>
                    <Box className='d-flex align-items-center'>
                      <Box className='title-color fs-12' onDoubleClick={() => handleEditClick('compensation_expectation')}>
                        {t(`${TRANS_KEYS.CONTACTS_EXPECTED_COMPENSATION}:key`)}
                      </Box>
                    </Box>

                    {editing.compensation_expectation ? (
                      <ClickAwayListener onClickAway={() => handleCloseClick('compensation_expectation')}>
                        <Box className='d-flex align-items-center content-space' style={{ width: '100%' }}>
                          <Box className='compact-auto' style={{ flexGrow: 1, width: '100%' }}>
                            <CustomInputField
                              type='number'
                              defaultValue={data?.contact_compensation?.compensation_expectation}
                              onChange={e => {
                                setValue('contact_compensations.compensation_expectation', e.target.value);
                                if (!data?.contact_compensation?.id) {
                                  setValue('contact_compensations.contact_id', data?.id);
                                } else {
                                  setValue('contact_compensations.id', data?.contact_compensation?.id);
                                }
                              }}
                              onFocus={() => renderActions('contact_compensations', true, () => handleCloseClick('compensation_expectation'))}
                            />
                          </Box>
                          <Box>{renderActions('contact_compensations', false, () => handleCloseClick('compensation_expectation'))}</Box>
                        </Box>
                      </ClickAwayListener>
                    ) : (
                      <Box className='d-flex align-center' style={{ width: '100%' }}>
                        <Box
                          className='input-field-data flex'
                          sx={{
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'all 0.3s ease',
                            marginTop: '10px',
                            width: '100%',
                            '&:hover': {
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #CCCCCC',
                              borderRadius: '4px',
                              cursor: 'pointer'
                            }
                          }}
                          onClick={() => handleEditClick('compensation_expectation')}
                        >
                          {data?.contact_compensation?.compensation_expectation ? (
                            <Box className='contact-details-value content-color fs-12 w-100'>{data.contact_compensation.compensation_expectation}</Box>
                          ) : (
                            <TextField
                              placeholder='Enter Expected Compensation'
                              variant='outlined'
                              size='small'
                              fullWidth
                              InputProps={{
                                readOnly: true
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* row 4*/}

        <Box className='compensation-section m-2'>
          <Box className=' section-block col-12 d-flex flex-column row-gap pl-0'>
            <Box className='d-flex flex-row column-gap viewcontact-icon-style'>
              <NoteIcon color='disabled' style={{ width: 20, height: 20 }}></NoteIcon>
              <Box className='d-flex row-gap contact-details'>
                <Box className='d-flex align-items-center justify-content-between w-100'>
                  <Box className='title-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_COMPENSATION_NOTES}:key`)}</Box>
                  {editing.compensation_notes && <Box className='d-flex'>{renderActions('contact_compensations', false, () => handleCloseClick('compensation_notes'))}</Box>}
                </Box>

                <ClickAwayListener onClickAway={() => editing.compensation_notes && handleCloseClick('compensation_notes')}>
                  <Box className='d-flex align-items-center content-space mt-2 w-100'>
                    <Box
                      className='w-100'
                      onClick={() => handleEditClick('compensation_notes')}
                      sx={{
                        '&:hover': {
                          backgroundColor: '#FFFFFF',
                          border: '1px solid #CCCCCC',
                          borderRadius: '4px',
                          cursor: 'pointer'
                        }
                      }}
                    >
                      {editing.compensation_notes ? (
                        <RichText
                          defaultValue={data?.contact_compensation?.compensation_notes || ''}
                          onChange={compensationNote => {
                            setValue('contact_compensations.compensation_notes', compensationNote);
                            if (!data?.contact_compensation?.id) {
                              setValue('contact_compensations.contact_id', data?.id);
                            } else {
                              setValue('contact_compensations.id', data?.contact_compensation?.id);
                            }
                          }}
                          readOnly={false}
                        />
                      ) : (
                        <Box className='contact-details-value content-color fs-12 w-100'>
                          {data?.contact_compensation?.compensation_notes ? data.contact_compensation.compensation_notes.replace(/<\/?p>/g, '') : 'Click to add compensation notes'}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

ViewCompensation.propTypes = {
  renderActions: PropTypes.func,
  register: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  data: PropTypes.object,
  isHeaderNav: PropTypes.string,
  updateField: PropTypes.func,
  watch: PropTypes.func
};

export default ViewCompensation;
