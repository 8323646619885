//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { Checkbox, FormControlLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { commonActions } from '../../../store/commonSlice';
import PopoverStickOnHover from '../../../components/common/PopoverStickOnHover';

const ColorRenderer = props => {
  const { params, handleChange } = props;
  const dispatch = useDispatch();
  const colors = useSelector(state => state.commonReducer.colors);
  useEffect(() => {
    if (!colors) {
      dispatch(commonActions.updatePicklistData('"colors"'));
    }
  }, []);

  const getColorByValue = value => {
    return colors?.find(item => item.value === Number(value)) || {};
  };

  const color = getColorByValue(params.value);
  const RenderColorsList = () => {
    return (
      <div className='d-flex colors-container justify-content-between'>
        {colors?.map(item => {
          return (
            <div
              key={item.hex_code}
              className='color-code-container d-flex'
              style={{
                background: `#${item.hex_code}`,
                color: `#${item.hex_code}`
              }}
            >
              <FormControlLabel
                style={{ margin: 0 }}
                control={<Checkbox style={{ padding: 0, width: 16, height: 16, color: 'white' }} color='default' checked={params.value === item.value} onChange={handleChange} name={item.value} />}
                label=''
              />
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className='d-flex align-items-center'>
      <div className='color-code-box' style={{ background: `#${color.hex_code}` }} />
      <PopoverStickOnHover component={RenderColorsList(params)} placement='bottom' onMouseEnter={() => {}} delay={200}>
        <ExpandMoreIcon color='primary' className='cursor-pointer' />
      </PopoverStickOnHover>
    </div>
  );
};

ColorRenderer.propTypes = {
  params: PropTypes.object,
  colors: PropTypes.array,
  handleChange: PropTypes.func
};

export default ColorRenderer;
