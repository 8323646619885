//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Button, Checkbox, Typography, FormControlLabel } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';

import { saveAs } from 'file-saver';
import withRouter from '../../../../utils/withRouter';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NumericFormat } from 'react-number-format';
import { useSnackbar } from 'notistack';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { CustomLoadingOverlayComponent, PAGE_LIMIT, SUCCESS_STATUS_CODE } from '../../../../Containers/Contacts/utils';
import { getFilterParamString, defaultColumns, TitleRenderer } from '../../../../Containers/Searches/Utils';
import ActionsPopover from '../../../../Containers/Contacts/ActionsPopover';
import {
  // addSearchesToWorkbench,
  currencyValueSetter,
  DateTimeRenderer,
  getCurrencyPrefix,
  loadColumnStateFromLocalStorage,
  removeSearchesFromWorkbench,
  saveColumnStateToLocalStorage
} from '../../../../Containers/Commons/Utils';
import CopyCandidatesToSearch from '../../../../Containers/Searches/AllCandidates/CopyCandidatesToSearch';
import ColumnFilter from '../../../../Containers/Commons/ColumnFilter';
import GenericCellEditor from '../../../../Containers/Commons/CellEditors/GenericCellEditor';
import Header from '../Header';
import AddContactToWorkbench from '../AddContactToWorkbench';
import ConfirmationPopup from '../../../ConfirmationPopup';
import Loader from '../../../common/Loader';
import AddContactsToWorkbench from '../../../../Containers/Commons/AddContactToWorkbench';
import CustomFilter from '../../../../Containers/Commons/CustomFilter';
import ColorLegend from '../../../../Containers/Commons/ColorLegend';
import PopoverStickOnHover from '../../../common/PopoverStickOnHover';
import AthenaInvitePopUpContact from '../../../ViewSearch/Components/SFPA/Components/ClientAppAccess/AthenaInvitePopUpContact';
import { IdRenderer, columnDefs, renderActiveCount, renderTargetCount } from '../../../../Containers/Searches/Utils';
import { DateRenderer } from '../../../../Containers/Commons/Utils';
import { SCROLL_TIMEOUT } from '../../../../utils/common';
import { API, BLOB, EXPORT_LIMIT, GET, POST, PUT, ERROR } from '../../../../services/constantService';
import PopupEditor from '../../../../Containers/Commons/CellEditors/PopupEditor';
import { dataSourceUtils } from '../../../../utils/dataSource';
import { picklistsColorsDataApi, workbenchDataApi } from '../../../../services/ApiService';
import { unableMessage, exportMessage, notFoundMessage, VALIDATION_MESSAGE } from '../../../../services/MessageService';
// import { workbenchActions } from "../../../../store/workbenchSlice";
import { pickListActions } from '../../../../store/pickListSlice';
import { CompanyNameRenderer } from '../../../../Containers/Companies/Utils';
// import QuickAddSearch from "../../../../Containers/Searches/AllCandidates/Components/QuickAddSearch";

let gridApi, columnApi;

const Searches = props => {
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [isAthenaPopupOpen, setIsAthenaPopupOpen] = useState(0);
  //const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  //const [showArchivePopup, setShowArchivePopup] = useState(false);
  /* const [showQuery, setShowQuery] = useState(false); */
  /* const [minimizeQuery, setMinimizeQuery] = useState(false); */
  /* const [paramsData, setParamsData] = useState({}); */
  const [filterModels, setFilterModels] = useState({});
  const [rowCount, setRowCount] = useState(0);
  const [isGridReady, setIsGridReady] = useState(false);
  const [colorData, setColorData] = useState(null);
  /* const [uiSelector, setUiSelector] = useState(false); */
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  /*   const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [queryRun, setQueryRun] = useState(false);
  const [advanceSelector, setAdvanceSelector] = useState(false);
  const [classicSelector, setClassicSelector] = useState(false); */
  const [isRowSelected, setIsRowSelected] = useState(false);
  /*   const [searchString, setSearchString] = useState("");
  const [string, setString] = useState(""); */
  /* const [exportSingleValue, setExportSingleValue] = useState(false); */
  const [isLoading, setIsLoading] = useState(false);
  const [isAddToSearchOpen, setIsAddToSearchOpen] = useState(false);
  const [searchIds, setSearchIds] = useState(null);
  const [isRemoveConfirmationOpen, setIsRemoveConfirmationOpen] = useState(false);
  const [isCopyToWorkbenchOpen, setIsCopyToWorkbenchOpen] = useState(false);
  const [athenaData /* setAthenaData */] = useState({});
  const [searchId, setSearchId] = useState('');
  const [removeSearchId, setRemoveSearchId] = useState(null);
  const [isAddToWorkbenchOpen, setIsAddToWorkbenchOpen] = useState(false);
  /* const [sortModel, setSortModel] = useState({}); */
  const enqueueSnackbar = useSnackbar();
  //const userData = useSelector((state) => state.commonReducer.userData);
  // const color_pick_list = useSelector((state) => state.pickListReducer.color_pick_list);

  const dataSource = {
    getRows: async params => {
      try {
        setShowFilterCount(Object.keys(filterModels)?.length);
        const getURLs = {
          listURl: `${API.workbench}/${id}/searches`
        };

        const thisValue = {
          location
        };

        gridApi.showLoadingOverlay();
        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: getURLs,
          subScreen: true
        };

        const { status, data } = await dataSourceUtils(obj, getFilterParamString);
        if (status === SUCCESS_STATUS_CODE) {
          if (data?.paging) {
            if (data?.paging?.totalCount === 0) {
              /*  const message = notFoundMessage("records"); */
              //enqueueSnackbar(message, { variant: WARNING });
            }
            setRowCount(data.paging?.totalCount);
            getTotalCount(data.paging?.totalCount);
            params.successCallback(data.data, data.paging?.totalCount);
          } else if (Object.keys(data)?.length) {
            if (data?.length === 0) {
              /* const message = notFoundMessage("records"); */
              //enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback([data], 1);
          }
          isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
        } else {
          params.failCallback();
        }
        gridApi.hideOverlay();
      } catch (e) {
        console.log('Error found in getRows::', e);
      }
    },
    rowCount: null
  };

  const setSelectAllRows = isAllRowsSelected => {
    setIsAllRowsSelected(isAllRowsSelected);
    gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };

  const handleAthenaPopupClose = () => {
    setIsAthenaPopupOpen(false);
    gridApi.onFilterChanged();
  };

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    setIsGridReady(true);
  };

  const NameRenderer = params => {
    return <CompanyNameRenderer company={params?.data?.company} />;
  };

  /*  const AddContactToRenderer = (params) => {
    const list = [
      {
        label: "To Search",
        onClick: () => handleAddToSearch(params?.data?.id),
      },
      {
        label: "As BD Target",
        onClick: () =>
          addAsMyBDTarget(params?.data, userData, gridApi, enqueueSnackbar),
      },
    ];
    return params.data ? <ActionsPopover list={list} /> : null;
  }; */

  /*  const handleAddToSearch = (searchId) => {
    setIsAddToSearchOpen(true);
    setSearchIds([searchId]);
  }; */

  const handleAddToSearchClose = () => {
    setIsAddToSearchOpen(false);
    setSearchIds([]);
    gridApi.onFilterChanged();
  };

  const addSearchesToWorkbench = async searches => {
    try {
      setIsLoading(true);
      const status = await addSearchesToWorkbench(id, searches, enqueueSnackbar);
      setIsLoading(false);
      return status;
    } catch (e) {
      console.log('Error found in addSearchesToWorkbench::', e);
    }
  };

  // handleQuickAddSearchClose = async (search, addToWorkbench) => {
  //   setState({ isQuickAddOpen: false });
  //   if (addToWorkbench && search) {
  //     const success = await addSearchesToWorkbench([search.id])
  //     if (success) {
  //       gridApi.onFilterChanged()
  //     }
  //   }
  // };

  const onFirstDataRendered = params => {
    const columnApi = params.columnApi;
    const gridApi = params.api;
    loadColumnStateFromLocalStorage('workbenchSearchesColumns', {
      columnApi,
      gridApi
    });

    const locationState = location.state;
    if (locationState) {
      const sortModel = locationState.sortModel;
      if (sortModel) {
        gridApi.setSortModel(sortModel);
      }

      const filterModel = locationState.filterModel;
      if (filterModel) {
        gridApi.setFilterModel(filterModel);
      }
    }
  };

  const saveColumnState = async () => {
    try {
      setIsLoading(true);
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage('workbenchSearchesColumns', {
        columnApi: columnApis,
        gridApi: gridApis
      });
      setIsLoading(false);
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForFilter = async () => {
    try {
      setIsLoading(true);
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage('workbenchSearchesColumns', { columnApi: columnApis, gridApi: gridApis }, true, false);
      setIsLoading(false);
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForSort = async () => {
    try {
      setIsLoading(true);
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage('workbenchSearchesColumns', { columnApi: columnApis, gridApi: gridApis }, false, true);
      setIsLoading(false);
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const handleAddToWorkbench = () => {
    setIsAddToWorkbenchOpen(true);
  };

  const handleAddToWorkbenchClose = added => {
    if (added) {
      gridApi.onFilterChanged();
    }
    setIsAddToWorkbenchOpen(false);
  };

  const getSelectedSearchIds = () => {
    // const contactIds = [];
    // gridApi.forEachNode((node) => {
    //   if (node.selected) {
    //     contactIds.push(node.data.id)
    //   }
    // })
    // return contactIds
    const SearchIds = [];
    gridApi.forEachNode(node => {
      if (node.selected) {
        SearchIds.push(node.data.id);
      }
    });
    return SearchIds;
  };

  const dispatchColorPickList = data => {
    dispatch(pickListActions.updateColorPickList(data));
  };

  useEffect(() => {
    async function fetchData() {
      const sub_route = '?type=workbench';
      if (!color_pick_list) {
        const { status, data } = await picklistsColorsDataApi(GET, id, '', sub_route, dispatchColorPickList);
        if (status === 200 && data !== null) {
          setColorData(data);
        } else if (status === 200) {
          const message = notFoundMessage('color details');
          enqueueSnackbar(message, { variant: ERROR });
        } else {
          const message = unableMessage('color details', 'fetch');
          enqueueSnackbar(data?.message || message, { variant: ERROR });
        }
      } else {
        setColorData(color_pick_list);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(pickListActions.updateColorPickList(colorData));
    };
  }, []);

  const setColorDesc = (event, color, index) => {
    if (event.target.value?.length <= 20) {
      let newArr = [...colorData];
      newArr = newArr.map((item, i) => (i === index ? { ...item, desc: event.target.value } : { ...item }));
      setColorData(newArr);
      const data = {
        data: { workbench_id: id, colorId: color.id, desc: event.target.value }
      };

      setTimeout(async () => {
        const sub_route = '?type=workbench';
        await picklistsColorsDataApi(PUT, id, data, sub_route);
      }, 500);
    }
  };

  const getColorByValue = value => {
    return colorData?.find(item => item.value === Number(value)) || {};
  };

  const RenderColorsList = params => {
    const handleChange = async event => {
      setIsLoading(true);
      const color = event.target.name;
      const data = { search_color: color, searchId: params.data?.id };
      const sub_route = 'workbench-search';
      const { status } = await workbenchDataApi(PUT, id, data, sub_route);
      setIsLoading(false);
      if (status === 200) {
        params.node.setDataValue('search_color', color);
      } else {
        const message = unableMessage('Search', 'update');
        enqueueSnackbar(message, { variant: ERROR });
      }
    };

    return (
      <div className='d-flex colors-container justify-content-between'>
        {colorData?.map(item => {
          return (
            <div
              key={item.hex_code}
              className='color-code-container d-flex'
              style={{
                background: `#${item.hex_code}`,
                color: `#${item.hex_code}`
              }}
            >
              <FormControlLabel
                style={{ margin: 0 }}
                control={<Checkbox style={{ padding: 0, width: 16, height: 16 }} color='default' checked={params.value === item.value} onChange={handleChange} name={item.value} />}
                label=''
              />
            </div>
          );
        })}
      </div>
    );
  };

  const ColorRenderer = params => {
    let color;
    if (params.data && params.data?.search_color) {
      const isArray = Array.isArray(params.data?.search_color);
      if (isArray) {
        color = getColorByValue(params.data?.search_color[0]?.search_color);
      } else {
        color = getColorByValue(params.data?.search_color);
      }
    }
    if (params?.data?.color) {
      color = getColorByValue(params?.data?.color);
    }
    return (
      <div className='d-flex align-items-center'>
        <div className='color-code-box' style={{ background: `#${color?.hex_code}` }} />
        <PopoverStickOnHover component={RenderColorsList(params)} placement='bottom' onMouseEnter={() => {}} delay={200}>
          <ExpandMoreIcon color='primary' className='cursor-pointer' />
        </PopoverStickOnHover>
      </div>
    );
  };

  // handleCopyCandidates = () => {
  //   const contactIds = getSelectedSearchIds()
  //   setState({ isAddToSearchOpen: true, contactIds })
  // }

  const handleChange = event => {
    const checked = event.currentTarget.checked;
    setIsAllRowsSelected(checked);
    gridApi.forEachNode(node => {
      node.setSelected(checked);
    });
  };

  const HeaderCheckbox = () => {
    return <Checkbox style={{ padding: 0, width: 16, height: 16, color: 'white' }} size='small' color='primary' onChange={handleChange} />;
  };
  // handleQuickAddSearch = () => {
  //   setState({
  //     isQuickAddOpen: true
  //   });
  // };

  const handleRemoveSearches = () => {
    setIsRemoveConfirmationOpen(true);
  };

  const handleConfirmPopupClose = () => {
    setIsRemoveConfirmationOpen(false);
    setRemoveSearchId(false);
  };

  const removeSearches = async () => {
    try {
      const searcheIds = getSelectedSearchIds();

      setIsLoading(true);
      const removed = await removeSearchesFromWorkbench(id, searcheIds, enqueueSnackbar, { ...filterModels });
      if (removed) {
        setIsRowSelected(false);
        gridApi.onFilterChanged();
      }

      setIsLoading(false);
      handleConfirmPopupClose();
    } catch (e) {
      console.log('Error found in removeSearches::', e);
    }
  };

  const removeSearch = async () => {
    try {
      const searcheId = [searchId];
      setIsLoading(true);
      const removed = await removeSearchesFromWorkbench(id, searcheId, enqueueSnackbar, { ...filterModels });
      if (removed) {
        gridApi.onFilterChanged();
      }
      setIsLoading(false);
      handleConfirmPopupClose();
    } catch (e) {
      console.log('Error found in removeSearch::', e);
    }
  };

  const exportSearches = async () => {
    let selectedSearchIds = [];
    selectedSearchIds = getSelectedSearchIds();
    if (isAllRowsSelected && rowCount > EXPORT_LIMIT) {
      const message = exportMessage(`${EXPORT_LIMIT}`, 'Workbench-Search');
      enqueueSnackbar(message, { variant: ERROR });
    } else if (!isAllRowsSelected && selectedSearchIds?.length > EXPORT_LIMIT) {
      const message = exportMessage(`${EXPORT_LIMIT}`, 'Workbench-Search');
      enqueueSnackbar(message, { variant: ERROR });
    } else {
      let sub_route = 'searches/export?';
      if (Object.keys(filterModels)?.length) {
        sub_route = sub_route.concat(getFilterParamString(filterModels));
      }
      if (isAllRowsSelected) selectedSearchIds = [];
      const columnHeaders = columnApi.getAllDisplayedColumns().map(column => column.getColDef().headerName);
      setIsLoading(true);
      const payload = {
        headers: {
          columnHeaders: columnHeaders,
          selectedRows: { selectedSearchIds },
          allRowSelected: isAllRowsSelected,
          limit: 10000
        }
      };
      const { status, data } = await workbenchDataApi(POST, id, payload, sub_route, BLOB);
      if (status === 200) {
        saveAs(new File([data], 'workbench_searches.xlsx'));
      } else if (status === 400) {
        const message = exportMessage(`${EXPORT_LIMIT}`, 'Workbench-Searches');
        enqueueSnackbar(message, { variant: ERROR });
      } else {
        const message = unableMessage('Workbench-Searches', 'Export');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setIsLoading(false);
    }
  };

  const handleCopySearchesToWorkbench = () => {
    const searchIds = getSelectedSearchIds();
    setIsCopyToWorkbenchOpen(true);
    setSearchIds(searchIds);
  };

  const handleCopySearchesToWorkbenchClose = () => {
    setIsCopyToWorkbenchOpen(false);
  };

  const FeeAmountRenderer = params => {
    const currencyCode = params.data && params.data.currency ? params.data.currency : '';
    const baseSalary = params.data && params.data.fee_amount ? params.data.fee_amount : '';
    return (
      <NumericFormat
        style={{ border: 'none', fontSize: '15px' }}
        color='primary'
        value={baseSalary}
        thousandSeparator={true}
        decimalSeparator='.'
        displayType='text'
        prefix={getCurrencyPrefix(currencyCode !== 'RUR' ? currencyCode : '')}
        suffix={getCurrencyPrefix(currencyCode === 'RUR' ? 'RUR' : '')}
        renderText={data => {
          const value = currencyValueSetter(data, currencyCode);
          return value ? value : '--';
        }}
      />
    );
  };
  const resetFilter = async () => {
    gridApi.setFilterModel(null);
    const customFilterField = columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item?.filter === 'CustomFilter') {
        gridApi.destroyFilter(item.field);
      }
    });
  };

  const resetSort = async () => {
    gridApi.setSortModel(null);
  };

  const ActionsRenderer = params => {
    const extraClass = getExtraClassName();
    if (params.data) {
      const list = [
        {
          label: 'Remove from Workbench',
          onClick: () => {
            setRemoveSearchId(true);
            setSearchId(params.data?.id);
          }
        }
      ];
      return <ActionsPopover list={list} className={extraClass} />;
    }
    return null;
  };

  const getExtraClassName = () => {
    return isLockedWorkbench ? 'locked' : '';
  };

  /* const onSortChanged = (params) => {
    const sortModel = params.api.getSortModel();
    setSortModel(sortModel);
  }; */

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    setFilterModels(filterModel);
    saveColumnStateForFilter();
  };

  const loaderChange = async value => {
    setIsLoading(value);
  };

  const {
    workbench,
    id,
    getTotalCount,
    /* type, */
    reloadData,
    option,
    userData,
    isLockedWorkbench,
    location,
    color_pick_list,
    dispatch
  } = props;
  // const { filter } = filterModels
  // const {
  //   isRowSelected, isAddToSearchOpen, searchIds, removeSearchId, isAddToWorkbenchOpen, /*isQuickAddOpen,*/
  //   isRemoveConfirmationOpen, isLoading, isCopyToWorkbenchOpen, isAllRowsSelected, isAthenaPopupOpen
  // } = state
  const queryData = isAllRowsSelected ? Object.fromEntries(new URLSearchParams(getFilterParamString(gridApi.getFilterModel()))) : null;
  const extraClassName = getExtraClassName();

  return (
    <div className='list-work-benches d-flex flex-column'>
      <Loader show={isLoading} />
      {isAddToSearchOpen && (
        <CopyCandidatesToSearch
          header={VALIDATION_MESSAGE.add_contact_to_search}
          buttonText={VALIDATION_MESSAGE.add_contact_to_search}
          uiLabel='contact'
          open={isAddToSearchOpen}
          searchIds={searchIds}
          workbenchId={isRowSelected ? id : null}
          handleClose={handleAddToSearchClose}
          queryData={queryData}
          hide={true}
        />
      )}
      {isRemoveConfirmationOpen && <ConfirmationPopup message='Are you sure you want to remove the searches from this workbench?' onClose={handleConfirmPopupClose} onConfirm={removeSearches} />}
      {removeSearchId && <ConfirmationPopup message='Are you sure you want to remove the searches from this workbench?' onClose={handleConfirmPopupClose} onConfirm={removeSearch} />}
      {isCopyToWorkbenchOpen && (
        <AddContactsToWorkbench open={isCopyToWorkbenchOpen} searchIds={searchIds} onClose={handleCopySearchesToWorkbenchClose} sourceWorkbenchId={isAllRowsSelected ? id : null} type='Searches' />
      )}
      {isAthenaPopupOpen ? <AthenaInvitePopUpContact inviteFor={'candidate'} selectedDropDown={'Athena'} client={athenaData} onClose={handleAthenaPopupClose} /> : null}
      <AddContactToWorkbench open={isAddToWorkbenchOpen} handleClose={handleAddToWorkbenchClose} addToWorkbench={addSearchesToWorkbench} type='Searches' title='Search' />
      <Header name={workbench.name} users={workbench.tagged_users} workbench={workbench} showEdit={false} reloadData={reloadData} isLockedWorkbench={isLockedWorkbench}>
        <Button variant='contained' color='primary' onClick={exportSearches} disabled={!isRowSelected}>
          Export
        </Button>
      </Header>
      <div className='d-flex align-items-center p-3'>
        <Button color='primary' variant='outlined' className='action-button' onClick={handleAddToWorkbench} disabled={isLockedWorkbench}>
          Add Searches To Workbench
        </Button>
        <Button onClick={handleCopySearchesToWorkbench} className='action-button' disabled={!isRowSelected} variant='outlined' color='primary'>
          Copy Searches to Workbench
        </Button>
        <Button color='primary' variant='outlined' className='action-button' disabled={!isRowSelected || isLockedWorkbench} onClick={handleRemoveSearches}>
          Remove Searches From Workbench
        </Button>
      </div>
      <div className='count-container'>
        <div className='action-container' style={{ minWidth: '0' }} onClick={() => resetFilter()}>
          <span className='action-text'>Reset Filter</span>
        </div>
        <div className='action-container' style={{ minWidth: '0' }} onClick={() => resetSort()}>
          <span className='action-text'>Reset Sort</span>
        </div>
        <ColorLegend colorList={colorData} setColorDesc={setColorDesc} extraClassName={extraClassName} />
        <Typography>Total count: {rowCount}</Typography>
      </div>
      <div className='list-view flex-grow-1'>
        {isGridReady && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} showFilterCount={showFilterCount} filterModel={filterModels} />}
        <div id='myGrid' className='ag-theme-alpine'>
          <AgGridReact
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            frameworkComponents={{
              CustomLoadingOverlayComponent,
              IdRenderer,
              DateTimeRenderer,

              NameRenderer: NameRenderer,
              FeeAmountRenderer: FeeAmountRenderer,
              DateRenderer,
              TitleRenderer,
              renderActiveCount,
              renderTargetCount,
              CustomFilter,
              HeaderCheckbox: HeaderCheckbox,
              ActionsRenderer: ActionsRenderer,
              GenericCellEditor,
              PopupEditor,
              ColorRenderer: ColorRenderer
            }}
            blockLoadDebounceMillis={SCROLL_TIMEOUT}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            rowModelType={'infinite'}
            datasource={dataSource}
            columnDefs={columnDefs(true, true, true, enqueueSnackbar, loaderChange, option, true, userData.roles[0])}
            paginationPageSize={PAGE_LIMIT}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            suppressMenuHide={true}
            suppressDragLeaveHidesColumns={true}
            onDisplayedColumnsChanged={saveColumnState}
            onDragStopped={saveColumnState}
            onFirstDataRendered={onFirstDataRendered}
            onFilterChanged={onFilterChanged}
            onSortChanged={saveColumnStateForSort}
            onRowSelected={e => {
              if (!e.node.selected) {
                setIsAllRowsSelected(false);
              }
              setIsRowSelected(gridApi.getSelectedRows()?.length > 0 ? true : false);
            }}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userData: state.commonReducer.userData,
    color_pick_list: state.pickListReducer.color_pick_list
    // userData :useSelector((state) => state.commonReducer.userData),
    // color_pick_list : useSelector((state) => state.pickListReducer.color_pick_list)
  };
};

// const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(
  mapStateToProps
  //mapDispatchToProps
)(withRouter(Searches));

Searches.propTypes = {
  workbench: PropTypes.object,
  closeSnackbar: PropTypes.func,
  color_pick_list: PropTypes.function,
  dispatch: PropTypes.function,
  id: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  reloadData: PropTypes.func,
  userData: PropTypes.object,
  getTotalCount: PropTypes.func,
  option: PropTypes.array,
  isLockedWorkbench: PropTypes.bool,
  type: PropTypes.string
};
