import { getFilterQuery } from '../Commons/Utils';

export const getFilterParamStringForTranslateValue = data => {
  return getFilterQuery(data, {}, {}, '');
};

export const columnDefs = () => [
  {
    headerName: '',
    colId: 'check_box',
    field: 'check_box',
    maxWidth: 300,
    minWidth: 100,
    headerComponent: 'HeaderCheckbox',
    headerCheckboxSelectionFilteredOnly: false,
    checkboxSelection: true,
    sortable: false,
    pinned: 'left',
    lockPinned: true,
    lockPosition: true
  },
  {
    headerName: 'Organization',
    field: 'org_id',
    sortable: true,
    maxWidth: 150,
    minWidth: 150,
    pinned: 'left',
    lockPinned: true,
    editable: false,
    tooltipField: 'org_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Document Type',
    field: 'doctype_code',
    tooltipField: 'doctype_code',
    sortable: true,
    maxWidth: 380,
    minWidth: 380,
    pinned: 'left',
    editable: false,
    lockPinned: true,
    cellRenderer: 'DocumentTypeRenderer',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Document Description',
    field: 'doctype_desc',
    tooltipField: 'doctype_desc',
    sortable: true,
    maxWidth: 380,
    minWidth: 380,
    pinned: 'left',
    lockPinned: true,
    editable: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Allowed Extensions',
    colId: 'allow_extensions',
    field: 'allow_extensions',
    tooltipField: 'allow_extensions',
    sortable: true,
    maxWidth: 380,
    minWidth: 380,
    pinned: 'left',
    lockPinned: true,
    editable: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  // {
  //   headerName: "Upload Role",
  //   field: "upload_role",
  //   colId: "upload_role",
  //   tooltipField: "upload_role",
  //   sortable: true,
  //   maxWidth: 300,
  //   minWidth: 50,
  //   pinned: "left",
  //   lockPinned: true,
  //   editable: false,
  //   filter: "agTextColumnFilter",
  //   filterParams: {
  //     filterOptions: ["contains"],
  //     buttons: ["reset", "apply"],
  //     suppressAndOrCondition: true,
  //     closeOnApply: true,
  //   },

  // },
  // {
  //   headerName: "Delete Role",
  //   field: "delete_role",
  //   colId: "delete_role",
  //   tooltipField: "delete_role",
  //   sortable: true,
  //   maxWidth: 300,
  //   minWidth: 50,
  //   pinned: "left",
  //   lockPinned: true,
  //   editable: false,
  //   filter: "agTextColumnFilter",
  //   filterParams: {
  //     filterOptions: ["contains"],
  //     buttons: ["reset", "apply"],
  //     suppressAndOrCondition: true,
  //     closeOnApply: true,
  //   },

  // },
  // {
  //   headerName: "View Role",
  //   field: "view_role",
  //   colId: "view_role",
  //   tooltipField: "view_role",
  //   sortable: true,
  //   maxWidth: 300,
  //   minWidth: 50,
  //   pinned: "left",
  //   lockPinned: true,
  //   editable: false,
  //   filter: "agTextColumnFilter",
  //   filterParams: {
  //     filterOptions: ["contains"],
  //     buttons: ["reset", "apply"],
  //     suppressAndOrCondition: true,
  //     closeOnApply: true,
  //   },

  // },
  // {
  //   headerName: "Download Role",
  //   field: "download_role",
  //   colId: "download_role",
  //   tooltipField: "download_role",
  //   sortable: true,
  //   maxWidth: 300,
  //   minWidth: 50,
  //   pinned: "left",
  //   lockPinned: true,
  //   editable: false,
  //   filter: "agTextColumnFilter",
  //   filterParams: {
  //     filterOptions: ["contains"],
  //     buttons: ["reset", "apply"],
  //     suppressAndOrCondition: true,
  //     closeOnApply: true,
  //   },

  // },
  {
    headerName: 'Action',
    field: 'action',
    colId: 'action',
    sortable: false,
    maxWidth: 86,
    minWidth: 86,
    pinned: 'right',
    lockPinned: true,
    filter: false,
    cellRenderer: 'ActionsRenderer'
  }
];
