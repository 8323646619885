import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProfileComponents from '../AddProject/PositionProfile';
import useApi from '../../../utils/Hooks/useApiHook';
import { picklistsDataApis } from '../../../services/ApiService';
import { GET } from '../../../services/constantService';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
const PositionProfile = props => {
  const { t } = useLanguageHooks([TRANS_KEYS.POSITION_PROFILE]);
  const { handleUniversalState, getValueFromUniversalState } = props;
  const [hideOptions, setHideOptions] = useState([]);
  const { data: positionProfileHideOptionResponse, success: isPositionProfileHideOptionSuccess } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, 'POSITION_PROFILE_HIDE_OPTION');
    }
  });
  useEffect(() => {
    if (isPositionProfileHideOptionSuccess) {
      setHideOptions(positionProfileHideOptionResponse?.data);
    }
    console.log(typeof getValueFromUniversalState('hideOption'));
  }, [positionProfileHideOptionResponse]);
  return (
    <Accordion fullWidth className='m-2'>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='body1' sx={{ fontWeight: '600' }}>
          {t(`${TRANS_KEYS.POSITION_PROFILE}:key`)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className='py-2'>
        <ProfileComponents getValueFromUniversalState={getValueFromUniversalState} handleUniversalState={handleUniversalState} reviewAndPublish={false} hideOptions={hideOptions} />
      </AccordionDetails>
    </Accordion>
  );
};

PositionProfile.propTypes = {
  handleUniversalState: PropTypes.func.isRequired,
  getValueFromUniversalState: PropTypes.func.isRequired
};

export default PositionProfile;
