export const RejectReason = [
  { title: 'Capital Structure Mismatch' },
  { title: 'Career Experience' },
  { title: 'Company Revenue too High' },
  { title: 'Company Revenue too Low' },
  { title: 'Company Score' },
  { title: 'Compensation' },
  { title: 'Current Title Tenure' },
  { title: 'Data Mismatch' },
  { title: 'Function Score' },
  { title: 'Geography' },
  { title: 'Inadequate Experience' },
  { title: 'Job Movement' },
  { title: 'Over Qualified' },
  { title: 'Relevancy' },
  { title: 'Skills Mismatch' },
  { title: 'Under Qualified' },
  { title: 'Wrong Industry' }
];
export const headerType = [
  { id: 'Individual', title: 'Individual Scores' },
  { id: 'all', title: 'All Scores' }
];
export const filterType = [
  { id: 'gte', title: '> = ' },
  { id: 'lte', title: '< = ' }
];
export const filterIndividualInitialValue = [
  {
    id: 'title',
    title: 'Title',
    value: null,
    variant: 'text',
    type: filterType[0].id
  },
  {
    id: 'job_function',
    title: 'Job Function',
    value: null,
    variant: 'text',
    type: filterType[0].id
  },
  {
    id: 'industry',
    title: 'Industry',
    value: null,
    variant: 'text',
    type: filterType[0].id
  },
  {
    id: 'company',
    title: 'Company',
    value: null,
    variant: 'text',
    type: filterType[0].id
  },
  {
    id: 'company_employee_range',
    title: 'Company Employee Range',
    value: null,
    variant: 'text',
    type: filterType[0].id
  },
  {
    id: 'company_revenue_range',
    title: 'Company Revenue Range',
    value: null,
    variant: 'text',
    type: filterType[0].id
  },
  {
    id: 'company_industry',
    title: 'Company Industry',
    value: null,
    variant: 'text',
    type: filterType[0].id
  },
  {
    id: 'exact_company_match',
    title: 'Exact Company Match',
    value: null,
    variant: 'text',
    type: filterType[0].id
  },
  {
    id: 'career_experience',
    title: 'Career Experience',
    value: null,
    variant: 'text',
    type: filterType[0].id
  },
  {
    id: 'job_movement',
    title: 'Job Movement',
    value: null,
    variant: 'text',
    type: filterType[0].id
  },
  {
    id: 'current_tenure',
    title: 'Current Tenure',
    value: null,
    variant: 'text',
    type: filterType[0].id
  },
  {
    id: 'relevancy',
    title: 'Relevancy',
    value: null,
    variant: 'text',
    type: filterType[0].id
  }
];

export const PROJECT_CONSTANTS = {
  DRAFT: 'Draft',
  RECORD_TYPE_BUSINESS_DEVELOPMENT: 'Business Development'
};
