import { CheckBox } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomDropdown from '../../../components/common/CustomDropdown';
import { PicklistProjectContactDataApi, ProjectContactDataApi } from '../../../services/ApiService';
import { POST } from '../../../services/constantService';
import { SCROLL_TIMEOUT } from '../../../utils/common';
import { CustomLoadingOverlayComponent, loadColumnStateFromLocalStorage } from '../../Commons/Utils';
import ClientContact from './ClientContact';
import { ProjectDataContext } from './Context';

const colDefs = [
  {
    field: 'clientContact',
    headerName: 'Client Contact',
    suppressSizeToFit: true,
    cellRendererFramework: ({ value }) => value,
    minWidth: 300,
    cellStyle: { textAlign: 'left' }
    // maxWidth: 300,
  },
  {
    field: 'jobTitle',
    headerName: 'Job Title',
    suppressSizeToFit: true,
    minWidth: 350,
    cellStyle: { textAlign: 'left' }
    // maxWidth: 350,
  },
  {
    field: 'hiringManager',
    headerName: 'Hiring Manager',
    suppressSizeToFit: true,
    cellRendererFramework: ({ value }) => value,
    minWidth: 200,
    maxWidth: 200,
    cellStyle: { textAlign: 'left' }
  },
  {
    field: 'inviteToClientPortal',
    headerName: 'Invite to Client Portal',
    suppressSizeToFit: true,
    cellRendererFramework: ({ value }) => value,
    minWidth: 200,
    maxWidth: 200,
    cellStyle: { textAlign: 'left' }
  },
  {
    field: 'inviteDetails',
    headerName: 'Invite Details',
    suppressSizeToFit: true,
    cellRendererFramework: ({ value }) => value,
    minWidth: 350,
    cellStyle: { textAlign: 'left' }
    // maxWidth: 400,
  }
];

const useQuery = () => {
  const search = useParams();
  return useMemo(() => new URLSearchParams(search), [search]);
};

let gridApi;
let columnApi;
function InviteToClientPortal() {
  const gridApiRef = useRef(null);
  const { projectData } = useContext(ProjectDataContext);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [clientEmails, setClientEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [sendInviteButton, setSendInviteButton] = useState({
    text: 'Send Invite',
    disabled: false
  });
  const [selectedClientContact, setSelectedClientContact] = useState({});

  const [alert, setAlert] = useState({
    open: false,
    message: ''
  });

  let query = useQuery();

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    // const columnApi = params.columnApi
    params.columnApi.autoSizeAllColumns();
    loadColumnStateFromLocalStorage('masterSearchContactColumns', {
      columnApi
    });
    // this.setState({ isGridReady: true });
  };

  const showEmailsPopup = data => {
    const emails = data.map(item => ({ label: item.email, id: item.email }));
    console.log({ emails });
    setClientEmails(emails);
    setShowInviteDialog(true);
  };

  const handleSendInvite = async () => {
    setSendInviteButton({ text: 'Sending...', disabled: true });
    try {
      const res = await ProjectContactDataApi(POST, 'invite', {
        email: selectedEmail,
        firstName: selectedClientContact?.contact?.first_name,
        lastName: selectedClientContact?.contact?.last_name,
        id: selectedClientContact?.id
      });

      if (res.status >= 400) {
        throw new Error(res?.data?.message || 'Error sending invite');
      }
      gridApiRef.current.api.refreshInfiniteCache();

      setAlert({ open: true, message: 'Invite Sent Successfully' });
    } catch (err) {
      console.error(err);
      setAlert({ open: true, message: err?.message || 'Error sending invite' });
    } finally {
      setSendInviteButton({ text: 'Send Invite', disabled: false });
      setShowInviteDialog(false);
    }
  };

  const onSendInviteClick = clientContact => {
    showEmailsPopup(clientContact?.contact?.contact_emails);
    setSelectedClientContact(clientContact);
  };

  const formatData = data => {
    return data.map(item => {
      return {
        id: item.id,
        clientContact: <ClientContact name={item?.contact?.name} />,
        inviteDetails: item.invited_by_user && item.clientsuite_invitation_sent_on ? `${moment(item.clientsuite_invitation_sent_on).format('LT, DD MMM YYYY')} by ${item.invited_by_user.name}` : '',
        jobTitle: item?.contact?.current_job_title,
        inviteToClientPortal: (
          <Button variant='outlined' onClick={() => onSendInviteClick(item)}>
            {item?.clientsuite_invitation_sent_on ? 'Resend Invite' : 'Send Invite'}
          </Button>
        ),
        hiringManager: item.hiring_mgr ? <CheckBox checked disabled /> : ''
      };
    });
  };

  const dataSource = {
    getRows: async params => {
      gridApi.showLoadingOverlay();
      const res = await PicklistProjectContactDataApi(
        POST,
        '',
        {
          query: {
            limit: 100,
            page: params.endRow / 100,
            project_id: projectData?.id ? projectData?.id : query.get('id')
          }
        },
        ''
      );

      if (res.status === 200) {
        console.log({ resultData: res.data });
        params.successCallback(formatData(res.data.data), res.data.paging.totalCount);
      } else {
        params.failCallback();
      }

      gridApi.hideOverlay();
    }
  };

  return (
    <div id='myGrid' className='ag-theme-alpine mx-2 ag-grid-container' style={{ height: '55vh' }}>
      <AgGridReact
        enableBrowserTooltips={true}
        ref={gridApiRef}
        rowHeight={60}
        datasource={dataSource}
        columnDefs={colDefs}
        scrollbarWidth={12}
        rowSelection='multiple'
        onGridReady={onGridReady}
        defaultColDef={{
          minWidth: 100,
          resizable: true,
          autoSizeStrategy: 'fitGridWidth'
        }}
        frameworkComponents={{
          CustomLoadingOverlayComponent
        }}
        loadingOverlayComponent={'CustomLoadingOverlayComponent'}
        getRowNodeId={data => data.id}
        blockLoadDebounceMillis={SCROLL_TIMEOUT}
        suppressHorizontalScroll={false}
        rowModelType='infinite'
      />
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={alert.open} onClose={() => setAlert({ ...alert, open: false })} message={alert.message} key={'top-center'} />
      <Dialog
        open={showInviteDialog}
        onClose={() => {
          setShowInviteDialog(false);
          setSelectedEmail(null);
        }}
      >
        <DialogTitle>Select Email</DialogTitle>
        <DialogContent dividers>
          <CustomDropdown label='Email' options={clientEmails} onInputChange={(e, v) => setSelectedEmail(v)} style={{ width: '350px' }} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowInviteDialog(false);
            }}
            variant='text'
            color='error'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSendInvite();
            }}
            disabled={!selectedEmail || sendInviteButton.disabled}
            variant='contained'
            color='primary'
          >
            {sendInviteButton.text}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default InviteToClientPortal;
