export const queryContactColumn = [
  {
    field: 'contact_name',
    headerName: 'Contact Name',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'company_name',
    headerName: 'Company Name',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'location',
    headerName: 'Location',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'job_title',
    headerName: 'Job Title',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'education',
    headerName: 'Education',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'certifications',
    headerName: 'Certifications',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  }
];

export const generateQueryProjectColumn = t => {
  return [
    {
      field: 'project_name',
      headerName: t('grid.projectName'),
      minWidth: 200,
      flex: 1,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      },
      cellRendererFramework: ({ value }) => value
    },
    {
      field: 'company_name',
      headerName: 'Company Name',
      minWidth: 200,
      flex: 1,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      },
      cellRendererFramework: ({ value }) => value
    },
    {
      field: 'projected_start_date',
      headerName: 'Projected Start Date',
      minWidth: 200,
      flex: 1,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      },
      cellRendererFramework: ({ value }) => value
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      minWidth: 200,
      flex: 1,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      },
      cellRendererFramework: ({ value }) => value
    },
    {
      field: 'partner_name',
      headerName: 'Partner Name',
      minWidth: 200,
      flex: 1,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      },
      cellRendererFramework: ({ value }) => value
    },
    {
      field: 'client_name',
      headerName: 'Client Name',
      minWidth: 200,
      flex: 1,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      },
      cellRendererFramework: ({ value }) => value
    },
    {
      field: 'job_title',
      headerName: 'Job Title',
      minWidth: 200,
      flex: 1,
      filter: 'agTextColumnFilter',
      sortable: true,
      menuTabs: ['filterMenuTab'],
      headerComponentParams: {
        menuIcon: 'fa-bars'
      },
      cellRendererFramework: ({ value }) => value
    }
  ];
};
