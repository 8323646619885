import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import { Box, TableCell, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { useSelector } from 'react-redux';
import { separator } from '../../../../../Containers/Commons/Utils';
import { BILLING_VALIDATIONS, ERROR, THOUSAND_SEPARATOR } from '../../../../../services/constantService';
import { getFixedDecimal } from '../../../../../utils/common';
import CustomTable from '../../../../common/CustomTable';
import BaseFeetypeSelection from '../../../../common/FunctionalComponents/BaseFeetypeSelection';
import CustomInputField from '../../../../common/StyledComponents/CustomInputField';

const PercentageOfBase = props => {
  const { register, setValue, currentValues, watch, type = 'estimated', disabled = false, isDisabled = false, getValueWithCurrency, fee_type } = props;
  const [percentagesOfBase, setPercentagesOfBase] = useState([]);
  const label = type === 'estimated' ? 'Estimated' : 'Actual';
  const fieldType = `${type}_percentage_bases`;
  const { enqueueSnackbar } = useSnackbar();
  const [availableType, setAvailableType] = useState([]);
  const feeTypes = useSelector(state => state.commonReducer?.baseFeeTypes);
  useEffect(() => {
    register(fieldType);
    setValue(fieldType, percentagesOfBase);
  }, [register, percentagesOfBase, setValue]);

  useEffect(() => {
    if (currentValues) {
      if (fee_type === 'Fixed Fee' || fee_type === 'FIX') {
        const value = currentValues[fieldType]?.filter(item => item.type === 'flat_amount');
        if (value?.length > 0) {
          setPercentagesOfBase(value);
        } else {
          setPercentagesOfBase([
            {
              calculated_estimated_amt: 0,
              short_desc: 'Flat Amount',
              type: 'flat_amount'
            }
          ]);
        }
      } else {
        setPercentagesOfBase(currentValues[fieldType] || []);
      }
    }
  }, [currentValues, watch('fee_type')]);

  const getValue = item => {
    console.log('item[`calculated_${type}_amt`]', item[`calculated_${type}_amt`]);
    const calculateAmounts = item.type !== 'flat_amount' ? item[`calculated_${type}_amt`] : item[`${type}_amt`];
    return calculateAmounts;
  };

  const handleChangePercentagesOfBase = (data, field, index, shortDesc) => {
    let existingPercentagesOfBase = [...percentagesOfBase];
    existingPercentagesOfBase[index] = { ...existingPercentagesOfBase[index], [field]: data };
    if (field === 'type') {
      existingPercentagesOfBase[index] = { ...existingPercentagesOfBase[index], ['short_desc']: shortDesc };
      if (!watch('fee_percentage') && shortDesc !== 'FLAT') {
        enqueueSnackbar(BILLING_VALIDATIONS.FEE_PERCENTAGE, { variant: ERROR });
      }
    }

    if (field === `${type}_amt` || field === 'type') {
      if (existingPercentagesOfBase[index].short_desc === 'flat_amount') {
        existingPercentagesOfBase[index][`calculated_${type}_amt`] = existingPercentagesOfBase[index][`${type}_amt`];
      } else {
        existingPercentagesOfBase[index][`calculated_${type}_amt`] =
          watch('fee_type') === 'Standard - 1/3' || watch('fee_type') === 'STD'
            ? getFixedDecimal(existingPercentagesOfBase[index][`${type}_amt`] / 3)
            : getFixedDecimal((watch('fee_percentage') * existingPercentagesOfBase[index][`${type}_amt`]) / 100);
      }
    }
    setPercentagesOfBase(existingPercentagesOfBase);
  };

  const validateFeePercentage = index => {
    if (!watch('fee_percentage') && percentagesOfBase[index]?.type !== 'flat_amount') {
      enqueueSnackbar(BILLING_VALIDATIONS.FEE_PERCENTAGE, { variant: ERROR });
    }
  };

  const calculateAmount = () => {
    setPercentagesOfBase(prevState => [
      ...prevState.map(ele => {
        return {
          ...ele,
          [`calculated_${type}_amt`]:
            ele.type === 'flat_amount' ? ele[`${type}_amt`] : watch('fee_type') === 'Standard - 1/3' ? ele[`${type}_amt`] / 3 : (watch('fee_percentage') * ele[`${type}_amt`]) / 100
        };
      })
    ]);
  };

  useEffect(() => {
    if (percentagesOfBase?.length) {
      calculateAmount();
    }
  }, [watch('fee_percentage'), watch('fee_type')]);

  const handleRemovePercentagesOfBase = index => {
    let existingPercentagesOfBase = [...percentagesOfBase];
    existingPercentagesOfBase.splice(index, 1);
    setPercentagesOfBase(existingPercentagesOfBase);
    calculateAmount();
  };
  const handleAddPercentagesOfBase = () => {
    let newPercentagesOfBase = [...percentagesOfBase, {}];
    setPercentagesOfBase(newPercentagesOfBase);
  };

  useEffect(() => {
    setAvailableType(
      percentagesOfBase.map(ele => {
        return {
          short_desc: ele.short_desc,
          type: ele.type
        };
      }) || []
    );
  }, [percentagesOfBase]);

  return (
    <Box id='container-communication'>
      <Box className='head-container'>
        <Box className='sub-head-content'>
          <Box className='field-label-input'>
            <Box className='d-flex flex-column  my-2'>
              <CustomTable
                headerData={['Fee Items', 'Value', 'Calculated Amount', ' ']}
                title={`Base Fee - ${label}`}
                handleAnotherRow={handleAddPercentagesOfBase}
                rows={percentagesOfBase}
                showAddAnotherButton={!disabled}
                customTableId='percentage-table'
                isDisabled={isDisabled}
                dataList={feeTypes}
              >
                {percentagesOfBase.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell sx={{ width: '20vw' }} className='input-field- p-2'>
                        {!disabled ? (
                          <BaseFeetypeSelection
                            isDisabled={isDisabled}
                            filterSelectedOptions
                            defaultValue={item?.type_desc?.short_desc || ''}
                            placeholder='Fee Items'
                            type='EST_FEE_TYPE'
                            disabled={fee_type === 'Fixed Fee' || fee_type === 'FIX'}
                            viewAddress={true}
                            selectedType={availableType}
                            onChange={(e, data) => {
                              // handleChangePercentagesOfBase(data ? data.short_desc : null, 'short_desc', index)
                              handleChangePercentagesOfBase(data ? data.field_value : null, 'type', index, data ? data.short_desc : null);
                            }}
                          />
                        ) : (
                          <Box className=''>{item.type || '--'}</Box>
                        )}
                      </TableCell>
                      <TableCell className='p-2'>
                        {!disabled ? (
                          <NumericFormat
                            isDisabled={isDisabled}
                            defaultValue={item[`${type}_amt`] || ''}
                            customInput={CustomInputField}
                            // type="number"
                            thousandSeparator={separator(watch('currency_iso_code'), THOUSAND_SEPARATOR)}
                            variant='outlined'
                            InputLabelProps={{ focused: true }}
                            decimalScale={2}
                            isNumericString={true}
                            onChange={() => {
                              validateFeePercentage(index);
                            }}
                            onValueChange={values => {
                              const { value } = values;
                              handleChangePercentagesOfBase(value ? Number(value) : null, `${type}_amt`, index);
                            }}
                            onKeyPress={event => {
                              if (event?.key === '-' || event?.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        ) : (
                          <Box className=''>{item[`${type}_amt`] ? getValueWithCurrency(item[`${type}_amt`]) : '--'}</Box>
                        )}
                      </TableCell>
                      <TableCell className='primary-checkbox p-2'>
                        {!disabled ? (
                          <NumericFormat
                            isDisabled={isDisabled}
                            value={getValue(item) || 0}
                            customInput={CustomInputField}
                            thousandSeparator={separator(watch('currency_iso_code'), THOUSAND_SEPARATOR)}
                            error={item[`calculated_${type}_amt`] > 1000000000000000}
                            decimalScale={2}
                            isNumericString={true}
                            onValueChange={values => {
                              const { value } = values;
                              handleChangePercentagesOfBase(value ? Number(value) : null, `calculated_${type}_amt`, index);
                            }}
                            disabled={true}
                          />
                        ) : (
                          <Box className=''>{item[`calculated_${type}_amt`] ? getValueWithCurrency(item[`calculated_${type}_amt`]) : '--'}</Box>
                        )}
                      </TableCell>
                      {!isDisabled && !disabled && percentagesOfBase.length > 0 && (
                        <TableCell className='remove-another-button p-2 ' mt={2}>
                          <CloseIcon onClick={() => handleRemovePercentagesOfBase(index)} className='table-close-icon' />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </CustomTable>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

PercentageOfBase.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  watch: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  getValueWithCurrency: PropTypes.func,
  fee_type: PropTypes.string
};

export default PercentageOfBase;
