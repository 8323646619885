import { Popover } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const CustomPopover = props => {
  const { children, id, open, anchorEl, anchorOrigin, transformOrigin, onClose, pointerEvents = '' } = props;

  return (
    <Popover id={id} sx={{ pointerEvents: pointerEvents }} open={open} anchorEl={anchorEl} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} disableRestoreFocus onClose={onClose}>
      {children}
    </Popover>
  );
};

CustomPopover.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  open: PropTypes.func,
  anchorEl: PropTypes.func,
  anchorOrigin: PropTypes.string,
  transformOrigin: PropTypes.string,
  onClose: PropTypes.func,
  pointerEvents: PropTypes.string
};

export default CustomPopover;
