//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect, useRef } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { Button, Checkbox, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { NumericFormat } from 'react-number-format';
import { saveAs } from 'file-saver';

import { AgGridReact } from 'ag-grid-react';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import '../Commons/index.scss';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { API, BLOB, DELETE_CONFIRMATION_POPUP_MESSAGE, ERROR, EXPORT_LIMIT, POST, WARNING } from '../../services/constantService';
import {
  CustomLoadingOverlayComponent,
  IdRenderer,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE,
  addSearchesToWorkbench,
  columnDefs,
  defaultColumns,
  getFilterParamString,
  getQueryParamsString,
  getSelectedSearchIds as getSelectedIds,
  processAddSearchesResponse,
  renderActiveCount,
  renderTargetCount
} from './Utils';
import {
  DateRenderer,
  DateTimeRenderer,
  EmailRenderer,
  LinkedInRenderer,
  LinksRenderer,
  currencyValueSetter,
  getCurrencyPrefix,
  loadColumnStateFromLocalStorage,
  saveColumnStateToLocalStorage
} from '../Commons/Utils';
import { VALIDATION_MESSAGE, exportMessage, notFoundMessage } from '../../services/MessageService';
import { searchDataApi, workbenchDataApi } from '../../services/ApiService';
import ActionsPopover from '../Contacts/ActionsPopover';
import AddContactsToWorkbench from '../Commons/AddContactToWorkbench';
import AddWorkbench from '../../components/AddWorkbench';
import ColumnFilter from '../Commons/ColumnFilter';
import CustomFilter from '../Commons/CustomFilter';
import DeletePopup from '../Commons/DeletePopup';
import GenericCellEditor from '../Commons/CellEditors/GenericCellEditor';
import LinkedInDialog from '../Commons/LinkedInDialog';
import Loader from '../../../src/components/common/Loader';
import PopupEditor from '../Commons/CellEditors/PopupEditor';
import Query from './Components/Query';
import Search from '../Commons/Search';
import { dataSourceUtils } from '../../utils/dataSource';
import { fetchUserList } from '../../actions';
import { SCROLL_TIMEOUT } from '../../utils/common';
import { useSnackbar } from 'notistack';
import { CompanyNameRenderer } from '../Companies/Utils';
let columnApi, gridApi;
// let isSelectedFromList = false;
const Searches = props => {
  const { placedSearches, activeSearches, id, columnStateKey, searchStatus, title, searchType } = props;
  const [isClassicQuery, setIsClassicQuery] = useState(false);
  const [minimizeQuery, setMinimizeQuery] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  // const [isAddToWorkbenchOpen, setIsAddToWorkbenchOpen] = useState(false);
  const [isCreateWorkbenchOpen, setIsCreateWorkbenchOpen] = useState(false);
  const [searchIds, setSearchIds] = useState([]);
  const [searchId, setSearchId] = useState(null);
  const [isCopyToWorkbenchOpen, setIsCopyToWorkbenchOpen] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [linkedPopup, setLinkedPopup] = useState(false);
  const [linkedInValue, setLinkedInValue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [quickQuery, setQuickQuery] = useState('');
  const [isGridReady, setIsGridReady] = useState(false);
  const [queryData, setQueryData] = useState({});
  const [filterModel, setFilterModel] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const userData = useSelector(state => state.commonReducer.userData);
  const users = useSelector(state => state.rootReducer.users);

  const queryDataRef = useRef();
  queryDataRef.current = queryData;
  const searchValueRef = useRef();
  searchValueRef.current = searchValue;
  const quickQueryRef = useRef();
  quickQueryRef.current = quickQuery;
  const isClassicQueryRef = useRef();
  isClassicQueryRef.current = isClassicQuery;

  useEffect(() => {
    return () => {
      saveColumnStateToLocalStorage(columnStateKey, { columnApi });
    };
  });

  const resetFilter = async () => {
    gridApi.setFilterModel(null);
    const customFilterField = columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item?.filter === 'CustomFilter') {
        gridApi.destroyFilter(item.field);
      }
    });
  };

  const resetSort = async () => {
    gridApi.setSortModel(null);
  };
  useEffect(() => {
    if (!users) {
      fetchUserList('users');
    }
  }, []);

  const getURLs = {
    listURl: `${API.search}/${searchType || 'kgpsearches'}`,
    quickQuery: `${API.search}`,
    classicURl: `${API.search}`,
    advanceURL: API.master_query
  };

  const dataSource = {
    getRows: async params => {
      try {
        let value = localStorage.getItem(columnStateKey);
        if (value === 'true') {
          setIsClassicQuery(true);
          setMinimizeQuery(false);
          setQuickQuery('');
          setSearchValue('');
          // this.resetFilter();
          // this.resetSort();
          return;
        }
        params.filterModel && setFilterModel(params.filterModel);
        setShowFilterCount(Object.keys(filterModel)?.length);
        //  this.gridApi.deselectAll();
        if (params.filterModel && Object.keys(params.filterModel)?.length > 0) gridApi.deselectAll();
        const thisValue = {
          isClassicQuery: isClassicQueryRef.current,
          quickQuery: quickQueryRef.current,
          searchStatus,
          queryData: queryDataRef.current
        };
        gridApi.showLoadingOverlay();
        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: getURLs,
          subScreen: true,
          SearchesSubScreen: true,
          queryString: searchStatus ? `&searchStatus=${searchStatus}` : ''
        };
        const { status, data } = await dataSourceUtils(obj, getFilterParamString, getQueryParamsString);
        if (status === SUCCESS_STATUS_CODE) {
          if (data?.paging?.totalCount === 0) {
            const message = notFoundMessage('records');
            enqueueSnackbar(message, { variant: WARNING });
          }
          params.successCallback(data.data, data.paging?.totalCount);
          setRowCount(data.paging?.totalCount);
          isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
        } else {
          params.failCallback();
        }
        gridApi.hideOverlay();
      } catch (e) {
        console.log('Error found in getRows::', e);
      }
      // localStorage.removeItem(this.props.columnStateKey)
    },
    rowCount: null
  };

  const NameRenderer = params => {
    return <CompanyNameRenderer company={params?.data?.company} />;
  };

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    loadColumnStateFromLocalStorage(columnStateKey, { columnApi, gridApi });
    setIsGridReady(true);
  };

  const handleCopySearchesToWorkbenchClose = () => {
    setIsCopyToWorkbenchOpen(false);
  };

  const linkedInPopupHandler = params => {
    setLinkedPopup(true);
    setLinkedInValue(params.value);
  };

  const linkedInRenderer = params => {
    return <LinkedInRenderer params={params} linkedInPopup={linkedInPopupHandler} />;
  };

  const LinkedInCloseHandler = () => {
    setLinkedPopup(false);
  };

  const getSelectedSearchIds = () => {
    const SearchIds = [];
    gridApi.forEachNode(node => {
      if (node.selected) {
        SearchIds.push(node.data.id);
      }
    });
    return SearchIds;
  };

  const handleCopySearchesToWorkbench = () => {
    const searchIdList = getSelectedSearchIds();
    setIsCopyToWorkbenchOpen(true);
    setSearchIds(searchIdList);
  };

  const handleQuery = queryData => {
    if (isAllRowsSelected === true) {
      setSelectAllRows(false);
      setIsAllRowsSelected(true);
    } else {
      gridApi.forEachNode(node => {
        node.setSelected(false);
      });
    }
    setQueryData(queryData);
    localStorage.removeItem(columnStateKey);
    gridApi.onFilterChanged();
  };

  const FeeAmountRenderer = params => {
    const currencyCode = params.data && params.data.currency ? params.data.currency : '';
    const feeAmount = params.data && params.data.fee_amount ? params.data.fee_amount : '';
    return (
      <NumericFormat
        style={{ border: 'none', fontSize: '15px' }}
        color='primary'
        value={feeAmount}
        thousandSeparator={true}
        decimalSeparator='.'
        displayType='text'
        prefix={getCurrencyPrefix(currencyCode !== 'RUR' ? currencyCode : '')}
        suffix={getCurrencyPrefix(currencyCode === 'RUR' ? 'RUR' : '')}
        renderText={data => {
          const value = currencyValueSetter(data, currencyCode);
          return value ? value : '--';
        }}
      />
    );
  };

  const handleSearchChange = (event, value) => {
    // isSelectedFromList = false;
    if (typeof value === 'string') {
      setQuickQuery(value);
      setSearchValue(value);
    } else if (value && value.id) {
      // isSelectedFromList = true;
      setQuickQuery(value.id);
      setSearchValue(value.job_title);
    }
    setQuickQuery(value?.id ? value?.id : value ? value : '', () => {
      gridApi.onFilterChanged();
    });
    resetFilter();
    resetSort();
  };

  const setSelectAllRows = isAllRowsSelected => {
    setIsAllRowsSelected(isAllRowsSelected);
    gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };

  const handleChange = () => {
    setSelectAllRows(!isAllRowsSelected);
  };

  const HeaderCheckbox = () => {
    return <Checkbox style={{ padding: 0, width: 16, height: 16, color: 'white' }} size='small' color='primary' onChange={handleChange} />;
  };

  // const handleAddSearchesToWorkbench = () => {
  //   const searchIds = getSelectedIds(gridApi);
  //   // setIsAddToWorkbenchOpen(true);
  //   setSearchIds(searchIds);
  // };

  const handleCreateWorkbench = () => {
    setIsCreateWorkbenchOpen(true);
  };
  const getQueryData = () => {
    return;
  };

  const handleCreateWorkbenchClose = async workbenchId => {
    try {
      setIsCreateWorkbenchOpen(false);
      if (workbenchId) {
        setIsLoading(true);
        if (isClassicQuery && isAllRowsSelected) {
          const payload = { query: getQueryData() };
          const sub_route = 'searches/copy';
          const { status, data } = await workbenchDataApi(POST, workbenchId, payload, sub_route);
          processAddSearchesResponse(status, data, enqueueSnackbar);
        } else {
          const searchIds = getSelectedIds(gridApi);
          await addSearchesToWorkbench(workbenchId, searchIds, enqueueSnackbar);
        }
        setIsLoading(false);
      }
    } catch (e) {
      console.log('Error found in handleCreateWorkbenchClose::', e);
    }
  };

  // const getParams = (selectedRows) => {
  //   /* const selectedRows = gridApi.getSelectedRows();
  //   if (this.state.isAllRowsSelected || selectedRows?.length === 0) {
  //     return getQueryParamsString(this.state.queryData).substring(1)
  //   } else {
  //     return selectedRows.map(row => `id=${row.id}`).join('&');
  //   } */
  //   return selectedRows.map((row) => `id=${row.id}`).join("&");
  // };

  const exportCallback = async () => {
    const selectedRows = gridApi.getSelectedRows();
    if (isAllRowsSelected && rowCount > EXPORT_LIMIT) {
      const message = exportMessage(`${EXPORT_LIMIT}`, 'Searches');
      enqueueSnackbar(message, { variant: ERROR });
    } else if (!isAllRowsSelected && selectedRows?.length > EXPORT_LIMIT) {
      const message = exportMessage(`${EXPORT_LIMIT}`, 'Searches');
      enqueueSnackbar(message, { variant: ERROR });
    } else {
      if (selectedRows?.length > 0) {
        let sub_route = 'export-as-excel?';
        if (quickQuery) {
          sub_route = `${sub_route}&searchValue=${quickQuery}&string=true`;
        } else if (isClassicQuery) {
          const paramsString = getQueryParamsString(queryData);
          sub_route = `${sub_route}${paramsString}&classicSelector=${isClassicQuery}`;
        }
        if (filterModel && Object.keys(filterModel)?.length) {
          sub_route = sub_route.concat(getFilterParamString(filterModel, isClassicQuery));
          sub_route = `${sub_route}&filter=true`;
        }
        let id = [];
        if (!isAllRowsSelected) {
          selectedRows.map(data => id.push(data.id));
        }
        const columnHeaders = columnApi.getAllDisplayedColumns().map(column => column.getColDef().headerName);
        gridApi.showLoadingOverlay();
        const payload = {
          headers: {
            columnHeaders: columnHeaders,
            selectedRows: { id },
            type: title,
            allRowSelected: isAllRowsSelected,
            limit: EXPORT_LIMIT
          }
        };
        let { status, data } = await searchDataApi(POST, '', payload, sub_route, BLOB);
        if (status === 200) {
          let fileName = 'Searches.xlsx';
          let fileNameHeader = '';
          //  headers["content-disposition"].split('"');
          if (fileNameHeader && fileNameHeader?.length > 2) {
            fileName = fileNameHeader[1];
          }
          saveAs(new File([data], fileName));
        } else {
          enqueueSnackbar(VALIDATION_MESSAGE.export_fail, {
            variant: ERROR
          });
        }
        gridApi.hideOverlay();
      }
    }
  };

  const ActionsRenderer = params => {
    if (params.data) {
      const list = [
        {
          label: 'Delete Record',
          onClick: () => {
            setSearchId(params.data?.id);
          }
        }
      ];
      return <ActionsPopover list={list} />;
    }
    return null;
  };

  const handleClose = deleted => {
    if (deleted) {
      gridApi.onFilterChanged();
    }
    setSearchId(null);
  };

  const saveColumnState = async () => {
    try {
      if (columnApi) {
        gridApi && gridApi.showLoadingOverlay();
        await saveColumnStateToLocalStorage(columnStateKey, {
          columnApi,
          gridApi
        });
        gridApi && gridApi.hideOverlay();
      }
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForFilter = async () => {
    try {
      if (columnApi) {
        gridApi && gridApi.showLoadingOverlay();
        await saveColumnStateToLocalStorage(columnStateKey, { columnApi, gridApi }, true, false);
        gridApi && gridApi.hideOverlay();
      }
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForSort = async () => {
    try {
      if (columnApi) {
        gridApi && gridApi.showLoadingOverlay();
        await saveColumnStateToLocalStorage(columnStateKey, { columnApi, gridApi }, false, true);
        gridApi && gridApi.hideOverlay();
      }
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const loaderChange = async value => {
    setIsLoading(value);
  };

  return (
    <div className='list-contacts d-flex flex-column'>
      <Loader show={isLoading} />
      <Helmet>
        <title>{title || 'Searches'} - KGP Galaxy</title>
      </Helmet>
      <LinkedInDialog open={linkedPopup} linkedInValue={linkedInValue} handleClose={LinkedInCloseHandler}></LinkedInDialog>
      {searchId ? <DeletePopup module='searches' id={searchId} onClose={handleClose} popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_SEARCH} /> : null}
      {isCopyToWorkbenchOpen && (
        <AddContactsToWorkbench open={isCopyToWorkbenchOpen} searchIds={searchIds} onClose={handleCopySearchesToWorkbenchClose} sourceWorkbenchId={isAllRowsSelected ? id : null} type='Searches' />
      )}
      {isCreateWorkbenchOpen && <AddWorkbench onClose={handleCreateWorkbenchClose} buttonText={VALIDATION_MESSAGE.create_workbech_add_search} DefaultValue={'Searches'} Disabled={'true'} />}
      {isClassicQuery || showSearch ? (
        <div className={'d-flex query-toolbar d-flex'} style={{ padding: '0px' }}>
          <Helmet>
            <title>Query - Searches</title>
          </Helmet>
          {isClassicQuery ? (
            <Accordion className='flex-grow-1 w-100 m-0' expanded={isClassicQuery && !minimizeQuery}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon onClick={() => setMinimizeQuery(true)} />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                onClick={() => setMinimizeQuery(!minimizeQuery)}
                style={{ padding: '0px 14px', lineHeight: 1 }}
              >
                Search Query
              </AccordionSummary>
              <AccordionDetails style={{ padding: '0px 15px 3px', width: '100%' }}>
                <Query handleQuery={handleQuery} option={users} />
              </AccordionDetails>
            </Accordion>
          ) : (
            <div className={'toolbar flex-grow-1'} style={{ padding: '20px' }}>
              <Search value={searchValue} onChange={handleSearchChange} />
            </div>
          )}
          <div className='expansion-panel-close-container'>
            <span
              style={{
                display: minimizeQuery ? 'inline' : 'none'
              }}
              className='cursor-pointer expansion-panel-close d-flex'
              onClick={async () => {
                await resetSort();
                await resetFilter();
                setIsClassicQuery(false);
                setQueryData({});
                setShowSearch(false);

                gridApi.onFilterChanged();
                localStorage.removeItem(columnStateKey);
                setTimeout(() => {
                  window.location.reload(false);
                }, 3000);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
      ) : (
        <div className={'toolbar'}>
          <div style={{ marginRight: '20px' }}>
            <Search onChange={handleSearchChange} type='quicksearches' />
          </div>
          <Button
            className={'start-query m-0'}
            variant={'outlined'}
            color={'primary'}
            onClick={async () => {
              await resetSort();
              await resetFilter();
              // isSelectedFromList = false;
              setIsClassicQuery(true);
              setMinimizeQuery(false);
              setQuickQuery('');
              setSearchValue('');

              localStorage.setItem(columnStateKey, true);
              setTimeout(() => {
                window.location.reload(false);
              }, 3000);
            }}
          >
            Start Query
          </Button>
        </div>
      )}
      <div className='d-flex justify-content-start mt-5 ml-4 position-relative'>
        <div className='d-flex w-100 justify-content-start position-absolute'>
          <Button variant='outlined' color='primary' className='mr-2' disabled={!isRowSelected} onClick={handleCopySearchesToWorkbench}>
            Add Searches To Workbench
          </Button>
          <Button variant='outlined' color='primary' className='mr-2' disabled={!isRowSelected} onClick={handleCreateWorkbench}>
            Create New Workbench
          </Button>
        </div>
        <div className='d-flex w-100 align-items-center justify-content-end mr-4'>
          <div className='action-container' onClick={() => resetFilter()} style={{ minWidth: 0 }}>
            <span className='action-text'>Reset Filter</span>
          </div>
          <div className='action-container' onClick={() => resetSort()} style={{ minWidth: 0 }}>
            <span className='action-text'>Reset Sort</span>
          </div>
          <Button className='mr-3' disabled={!selectedRowsCount} variant='outlined' color='primary' onClick={exportCallback}>
            Export List
          </Button>
          <Typography>Total count: {rowCount}</Typography>
        </div>
      </div>
      <div className='list-view flex-grow-1'>
        {isGridReady && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} filterModel={filterModel} showFilterCount={showFilterCount} />}
        <div id='myGrid' className='ag-theme-alpine'>
          <AgGridReact
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            blockLoadDebounceMillis={SCROLL_TIMEOUT}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            frameworkComponents={{
              CustomLoadingOverlayComponent,
              EmailRenderer,
              LinkedInRenderer: linkedInRenderer,
              FeeAmountRenderer,
              LinksRenderer,
              IdRenderer,
              DateTimeRenderer,
              NameRenderer,
              DateRenderer,
              renderActiveCount,
              renderTargetCount,
              CustomFilter,
              HeaderCheckbox,
              ActionsRenderer,
              GenericCellEditor,
              PopupEditor
            }}
            getRowNodeId={data => data.id}
            suppressMenuHide={true}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            rowModelType={'infinite'}
            datasource={dataSource}
            columnDefs={columnDefs(userData?.isAdmin, placedSearches, activeSearches, enqueueSnackbar, loaderChange, users, false, userData.roles[0])}
            paginationPageSize={PAGE_LIMIT}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            suppressDragLeaveHidesColumns={true}
            onDisplayedColumnsChanged={saveColumnState}
            onDragStopped={saveColumnState}
            onSortChanged={saveColumnStateForSort}
            onFilterChanged={saveColumnStateForFilter}
            onRowSelected={() => {
              // if (!e.node.selected) {
              //   this.setState({ isAllRowsSelected: false })
              // }
              setSelectedRowsCount(gridApi.getSelectedRows()?.length);
              setIsRowSelected(gridApi.getSelectedRows()?.length > 0);
            }}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

Searches.propTypes = {
  searchType: PropTypes.string.isRequired,
  columnStateKey: PropTypes.string.isRequired,
  searchStatus: PropTypes.string,
  title: PropTypes.string,
  placedSearches: PropTypes.bool,
  activeSearches: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  userData: PropTypes.object,
  id: PropTypes.string,
  users: PropTypes.array,
  fetchUserList: PropTypes.func
};

export default Searches;
