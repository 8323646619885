//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import ContactsIcon from '@mui/icons-material/Contacts';
import PersonIcon from '@mui/icons-material/Person';
import { Button, Checkbox, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { useSnackbar } from 'notistack';
import { useRef } from 'react';
import { fetchUserList } from '../../actions';
import Loader from '../../components/common/Loader';
import ViewUser from '../../components/ViewUser';
import { userDataApi } from '../../services/ApiService';
import { getAccessToken } from '../../services/cognitoService';
import { API, BLOB, ERROR, EXPORT_CONTACT_LIMIT, GET, POST, SUCCESS, WARNING } from '../../services/constantService';
import { notFoundMessage, unableMessage, VALIDATION_MESSAGE } from '../../services/MessageService';
import { dataSourceUtils } from '../../utils/dataSource';
import GenericCellEditor from '../Commons/CellEditors/GenericCellEditor';
import PhoneEditor from '../Commons/CellEditors/PhoneEditor';
import StateEditor from '../Commons/CellEditors/StateEditor';
import ToggleSelection from '../Commons/CellEditors/ToggleSelection';
import ColumnFilter from '../Commons/ColumnFilter';
import CustomFilter from '../Commons/CustomFilter';
import LinkedInDialog from '../Commons/LinkedInDialog';
import Search from '../Commons/Search';
import {
  CustomLoadingOverlayComponent,
  DateRenderer,
  EmailRenderer,
  LinkedInUserRenderer,
  LinksRenderer,
  loadColumnStateFromLocalStorage,
  PAGE_LIMIT,
  saveColumnStateToLocalStorage,
  SUCCESS_STATUS_CODE,
  TwitterRenderer,
  WebsiteUserRenderer
} from '../Commons/Utils';
import { getFilterParamString } from '../Contacts/utils';
import AlertDialog from './AlertDialog';
import { columnDefs, defaultColumns, PhoneRenderer } from './utils';

let gridApi, columnApi;
let isSelectedFromList = false;
const UsersList = props => {
  const { users } = props;
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [quickQuery, setQuickQuery] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [exportSingleValue, setExportSingleValue] = useState(false);
  const [open, setOpen] = useState(false);
  const [toggleUser, setToggleUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [filterModel, setFilterModel] = useState({});
  const [token, setToken] = useState('');
  const [linkedPopup, setLinkedPopup] = useState(false);
  const [linkedInValue, setLinkedInValue] = useState({});
  const [urlType, setUrlType] = useState('');
  const [userData, setUserData] = useState({});
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [isGridReady, setIsGridReady] = useState(false);
  // const [sortModel, setSortModel] = useState({});
  const [showQuery] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const quickQueryRef = useRef();
  quickQueryRef.current = quickQuery;
  const userDataRedux = useSelector(state => state.commonReducer.userData);
  const usersRedux = useSelector(state => state.rootReducer.users);

  const prevLocationRef = useRef(null);

  const getUserId = async () => {
    const globalLocation = location.pathname.split('/');
    if (globalLocation[globalLocation?.length - 1] !== 'users' && globalLocation[globalLocation?.length - 1] !== '') {
      const { status, data } = await userDataApi(GET, globalLocation[globalLocation?.length - 1]);
      if (status === 200) setUserData(data);
    } else {
      setUserData({});
      navigate('/users');
    }
  };
  useEffect(() => {
    const fetchUser = async () => {
      if (props && location) {
        getUserId();
      }
      const token = await getAccessToken();
      setToken(token);
      if (!usersRedux) {
        fetchUserList('users');
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    return () => {
      const columnApis = columnApi;
      saveColumnStateToLocalStorage('masterSearchCompanyColumns', { columnApis });
    };
  }, []);

  useEffect(() => {
    if (prevLocationRef.location?.pathname !== location?.pathname) {
      getUserId();
    }

    prevLocationRef.current = location?.pathname;
  }, [location?.pathname]);

  const getURLs = {
    listURl: `${API.users}`,
    quickQuery: `${API.users}`
  };

  const suppressKeyboardEvent = params => {
    const key = params.event.key;
    const isKeyPressEnter = params.editing && key === 'Enter';
    return isKeyPressEnter;
  };

  const dataSource = {
    getRows: async params => {
      try {
        params.filterModel && setFilterModel(params.filterModel);
        setShowFilterCount(Object.keys(filterModel)?.length);
        if (params.filterModel && Object.keys(params.filterModel)?.length > 0) gridApi.deselectAll();
        const thisValue = { location, quickQuery: quickQueryRef.current, isSelectedFromList };
        gridApi.showLoadingOverlay();
        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: getURLs,
          subScreen: true,
          usersScreen: true,
          queryString: !params.filterModel.is_active ? '&is_active=true' : ''
        };

        const { status, data } = await dataSourceUtils(obj, getFilterParamString);
        if (status === SUCCESS_STATUS_CODE) {
          if (data?.paging?.totalCount === 0) {
            const message = notFoundMessage('records');
            enqueueSnackbar(message, { variant: WARNING });
          }
          params.successCallback(data.data, data.paging?.totalCount);
          setRowCount(data.paging?.totalCount);
          if (isAllRowsSelected) {
            gridApi.forEachNode(node => {
              node.setSelected(isAllRowsSelected);
            });
          }
        } else {
          params.failCallback();
        }
        gridApi.hideOverlay();
      } catch (e) {
        console.log('Error found in getRows::', e);
      }
    },
    rowCount: null
  };
  const resetFilter = async () => {
    gridApi.setFilterModel(null);
    const customFilterField = columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item?.filter === 'CustomFilter') {
        gridApi.destroyFilter(item.field);
      }
    });
  };

  const resetSort = async () => {
    gridApi.setSortModel(null);
  };

  const handleSearchChange = (event, value) => {
    console.log('Searchdata', value);
    isSelectedFromList = false;
    if (typeof value === 'string') {
      setQuickQuery(value);
      setSearchValue(value);
      setExportSingleValue(false);
    } else if (value && value.id) {
      isSelectedFromList = true;
      setQuickQuery(value.id);
      setSearchValue(value.name);
      setExportSingleValue(true);
    }
    setQuickQuery(value?.id ? value?.id : value ? value : '', () => {
      gridApi.onFilterChanged();
    });
    resetFilter();
    resetSort();
  };

  const updateStatus = async (userId, isActive) => {
    setLoading(true);
    const payload = { is_active: isActive };
    const sub_route = 'status';
    const { status, data } = await userDataApi(POST, userId, payload, sub_route);
    const statusText = isActive ? 'Active' : 'Inactive';
    if (status === 200) {
      enqueueSnackbar(`Marked user as ${statusText}`, {
        variant: SUCCESS
      });
      gridApi.onFilterChanged();
    } else {
      const message = unableMessage(`${statusText}`, 'mark user as');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setLoading(false);
  };

  const exportCallback = async () => {
    const selectedRows = gridApi.getSelectedRows();
    if (selectedRows?.length > 0) {
      let sub_route = 'export-as-excel?';
      let id = [];
      if (Object.keys(filterModel)?.length === 0 && filterModel.constructor === Object) {
        sub_route = `${sub_route}&is_active=true&filter=true`;
      }
      if (quickQuery) {
        if (exportSingleValue) {
          sub_route = `export-as-excel?id=${quickQuery}&quickSearch=true`;
        } else {
          sub_route = `export-as-excel?searchValue=${quickQuery}&quickSearch=true`;
        }
      }
      if (Object.keys(filterModel)?.length) {
        sub_route = sub_route.concat(getFilterParamString(filterModel, showQuery || quickQuery));
        sub_route = `${sub_route}&filter=true&is_active=true`;
      }
      if (!isAllRowsSelected) {
        selectedRows.map(data => id?.push(data.id));
      }
      const columnHeaders = columnApi.getAllDisplayedColumns().map(column => column.getColDef().headerName);
      gridApi.showLoadingOverlay();
      const payload = {
        headers: {
          columnHeaders: columnHeaders,
          selectedRows: { id: id },
          allRowSelected: isAllRowsSelected,
          limit: EXPORT_CONTACT_LIMIT
        }
      };
      let { status, data, headers } = await userDataApi(POST, '', payload, sub_route, BLOB);
      if (status === 200) {
        let fileName = 'Contacts.xlsx';
        let fileNameHeader = headers['content-disposition'].split('"');
        if (fileNameHeader && fileNameHeader?.length > 2) {
          fileName = fileNameHeader[1];
        }
        saveAs(new File([data], fileName));
      } else {
        enqueueSnackbar(VALIDATION_MESSAGE.export_fail, {
          variant: ERROR
        });
      }
      gridApi.hideOverlay();
    } else {
      enqueueSnackbar(VALIDATION_MESSAGE.no_record_selected, {
        variant: ERROR
      });
    }
  };

  const ActionsRenderer = params => {
    if (params.data) {
      return (
        <button
          className='secondary-btn'
          onClick={() => {
            if (userDataRedux.roles[0] === 'admin') {
              setToggleUser(params.data);
              setOpen(true);
            }
          }}
        >
          {params.data.is_active ? 'Inactive' : 'Active'}
        </button>
      );
    }
    return null;
  };

  const linkedInPopupHandler = params => {
    let value = params && params.value ? params.value : '';
    if (value && value.indexOf('https:') < 0) {
      value = `https://${value}`;
    }
    setLinkedPopup(true);
    setLinkedInValue(value);
    setUrlType('linkedIn');
  };
  const websitePopupHandler = params => {
    let value = params && params.value ? params.value : '';
    if (value && value.indexOf('https:') < 0) {
      value = `https://${value}`;
    }
    setLinkedPopup(true);
    setLinkedInValue(value);
    setUrlType('website');
  };

  const LinkedInRenderer = params => {
    return <LinkedInUserRenderer params={params} linkedInPopup={linkedInPopupHandler} />;
  };

  const WebsiteRenderer = params => {
    return <WebsiteUserRenderer params={params} linkedInPopup={websitePopupHandler} />;
  };

  const ProfileRenderer = params => {
    const pageSize = 'USLetter';
    const language = 'en_US';
    let queryURL = `${API.reports}/user-profile/${params.data && params.data.id}`;
    queryURL = queryURL.concat(`?pageSize=${pageSize}&locale=${language}&token=${token}`);
    if (params.data) {
      return (
        <Link className='action-label' to={queryURL} target='_blank'>
          <PersonIcon style={{ color: 'rgb(40, 103, 178)' }} />
        </Link>
      );
    }
    return null;
  };

  const VCardRendered = params => {
    let queryURL = `${API.reports}/v-card/${params.data && params.data.id}`;
    queryURL = queryURL.concat(`?token=${token}`);
    if (params.data) {
      return (
        <Link className='action-label' to={queryURL} target='_blank'>
          <ContactsIcon style={{ color: 'rgb(40, 103, 178)' }} />
        </Link>
      );
    }
    return null;
  };

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    const columnApis = params.columnApi;
    const gridApis = params.api;
    loadColumnStateFromLocalStorage('userTableColumns', { columnApi: columnApis, gridApi: gridApis });
    setIsGridReady(true);
  };

  const setSelectAllRows = isAllRowsSelected => {
    setIsAllRowsSelected(isAllRowsSelected);
    gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };

  const handleChange = event => {
    const checked = event.currentTarget.checked;
    setSelectAllRows(checked);
  };

  const HeaderCheckbox = () => {
    return <Checkbox style={{ padding: 0, width: 16, height: 16, color: 'white' }} size='small' color='primary' onChange={e => handleChange(e)} />;
  };

  const NameRenderer = params => {
    return (
      <Typography
        className='text-link'
        color='primary'
        onClick={() => {
          navigate(`/users/${params.data.id}`);
        }}
      >
        {params.value}
      </Typography>
    );
  };
  const userUpdate = data => {
    setUserData(data);
  };

  const onSortChanged = () => {
    //  const sortModel = params.api.getSortModel();
    // setSortModel(sortModel);
    saveColumnStateForSort();
  };

  const saveColumnState = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage('userTableColumns', { columnApi: columnApis, gridApi: gridApis });
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.error('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForFilter = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage('userTableColumns', { columnApi: columnApis, gridApi: gridApis }, false, true);
    } catch (e) {
      console.error('Error found in saveColumnState::', e);
    }
  };
  const saveColumnStateForSort = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      const columnApi = columnApi;
      const gridApi = gridApi;
      await saveColumnStateToLocalStorage('userTableColumns', { columnApi, gridApi }, false, true);
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.error('Error found in saveColumnState::', e);
    }
  };

  const handleRowSelection = e => {
    if (!e.node.selected) {
      setIsAllRowsSelected(false);
    }
    setIsRowSelected(gridApi.getSelectedRows()?.length > 0 ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
    setLinkedPopup(false);
  };

  const handleSubmit = async () => {
    try {
      handleClose();
      await updateStatus(toggleUser.id, !toggleUser.is_active);
    } catch (e) {
      console.log('Error found in handleSubmit::', e);
    }
  };

  const loaderChange = async value => {
    setLoading(value);
  };

  return (
    <div className='d-flex flex-column w-100 h-100'>
      <Helmet>
        <title>Users - KGP Galaxy</title>
      </Helmet>
      <LinkedInDialog open={linkedPopup} linkedInValue={linkedInValue} handleClose={handleClose} urlType={urlType}></LinkedInDialog>
      <AlertDialog open={open} isActive={toggleUser.is_active} handleClose={handleClose} handleSubmit={handleSubmit} />
      <Loader show={loading} />
      <div className={'toolbar'}>
        <Search value={searchValue} onChange={handleSearchChange} type='users' />
      </div>
      <div className='d-flex align-items-center justify-content-end mr-4'>
        <div className='action-container' style={{ minWidth: '0' }} onClick={() => resetFilter()}>
          <span className='action-text'>Reset Filter</span>
        </div>
        <div className='action-container' style={{ minWidth: '0' }} onClick={() => resetSort()}>
          <span className='action-text'>Reset Sort</span>
        </div>
        <Button className='mr-3' variant={'outlined'} color={'primary'} disabled={!isRowSelected} onClick={exportCallback}>
          Export list
        </Button>
        <Typography>Total count: {rowCount}</Typography>
      </div>
      <div className='list-view flex-grow-1'>
        {isGridReady && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} showFilterCount={showFilterCount} filterModel={filterModel} />}
        <div id='myGrid' className='ag-theme-alpine'>
          <AgGridReact
            enableBrowserTooltips={true}
            suppressHorizontalScroll={false}
            suppressKeyboardEvent={suppressKeyboardEvent}
            datasource={dataSource}
            paginationPageSize={PAGE_LIMIT}
            cacheBlockSize={PAGE_LIMIT}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            frameworkComponents={{
              CustomLoadingOverlayComponent,
              PhoneRenderer,
              EmailRenderer,
              ToggleSelection,
              LinkedInRenderer: LinkedInRenderer,
              LinksRenderer,
              TwitterRenderer,
              CustomFilter,
              NameRenderer: NameRenderer,
              HeaderCheckbox: HeaderCheckbox,
              ActionsRenderer: ActionsRenderer,
              ProfileRenderer: ProfileRenderer,
              VCardRendered: VCardRendered,
              DateRenderer,
              GenericCellEditor,
              PhoneEditor,
              StateEditor,
              WebsiteRenderer: WebsiteRenderer
            }}
            scrollbarWidth={12}
            rowSelection={'multiple'}
            rowModelType={'infinite'}
            onGridReady={onGridReady}
            columnDefs={columnDefs(enqueueSnackbar, true, userDataRedux?.roles[0], loaderChange, users)}
            getRowNodeId={data => data.id}
            onSortChanged={onSortChanged}
            onRowSelected={handleRowSelection}
            suppressRowClickSelection={true}
            suppressDragLeaveHidesColumns={true}
            onDisplayedColumnsChanged={saveColumnState}
            onDragStopped={saveColumnState}
            onFilterChanged={saveColumnStateForFilter}
          ></AgGridReact>
        </div>
        {Object.keys(userData)?.length > 0 && (
          <ViewUser
            visible={true}
            onClose={isDataUpdated => {
              if (isDataUpdated) {
                gridApi.onFilterChanged();
              }
              setUserData({});
              navigate('/users');
            }}
            user={userData}
            userUpdate={userUpdate}
          />
        )}
      </div>
    </div>
  );
};

UsersList.propTypes = {
  users: PropTypes.array
};

export default UsersList;
