//-----------------------------------------------------------// In-built Imports // ------------------------------

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';

//-----------------------------------------------------------// External Imports // ------------------------------

import { getServerSocketConfig } from '../../Containers/Commons/urlUtil';
import { getUserSelector } from '../../selectors';
import { LINKEDIN_SCRAPER, LOE_GENERATION } from '../../services/constantService';
import { UPDATE_SOCKET } from '../../types';

export const useSocketHook = () => {
  const [user, setUser] = useState('');
  const dispatch = useDispatch();
  const userData = useSelector(getUserSelector);

  useEffect(() => {
    const { url, options } = getServerSocketConfig();
    const newSocket = io(url, { ...options, withCredentials: true });

    newSocket.on('connected', msg => {
      console.log('message:::', msg);
      newSocket.emit('newUser', user);
      dispatch({ type: UPDATE_SOCKET, payload: newSocket });
    });

    newSocket.on(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED, (msg, obj) => {
      console.log('message:::', msg);
      dispatch({
        type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED,
        payload: { msg, obj }
      });
    });

    newSocket.on(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES, (msg, obj) => {
      console.log('message:::', msg);
      dispatch({
        type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES,
        payload: { msg, obj }
      });
    });
    newSocket.on(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE, (msg, obj) => {
      console.log('message:::', msg);
      dispatch({
        type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE,
        payload: { msg, obj }
      });
    });
    newSocket.on(LOE_GENERATION.LOE_DOCUMENT_UPLOADED, (msg, obj) => {
      console.log('message:::', msg);
      dispatch({
        type: LOE_GENERATION.LOE_DOCUMENT_UPLOADED,
        payload: { msg, obj }
      });
    });

    return () => {
      newSocket.disconnect(); // Clean up the socket connection when the component unmounts
    };
  }, []);

  useEffect(() => {
    setUser(userData?.id);
  }, [userData]);

  return {};
};
