//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { Button, InputLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import Loader from '../common/Loader';
import { useNavigate } from 'react-router-dom';
import InputField from '../common/InputField';
import './index.scss';
import { Helmet } from 'react-helmet';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { POST, SUCCESS, ERROR } from '../../services/constantService';
import { SfpaSettingsApi } from '../../services/ApiService';
import { successMessage, unableMessage } from '../../services/MessageService';
import { customTemplateFormValidator, requireValidTemplateMessage } from '../../utils/common';
import { useSnackbar } from 'notistack';
import Popup from 'reactjs-popup';

export default function AddSfpaBuilder() {
  const { register, handleSubmit } = useForm({});
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const onClose = () => {
    navigate('/product-setup/sfpa-builder');
  };

  const addSchema = yup.object().shape({
    name: yup.string().required(),
    model: yup.string().required(),
    temperature: yup.number().required().min(0).max(1),
    frequency_penalty: yup.number().required().min(0).max(2),
    max_tokens: yup.number().required().min(0).max(2000),
    system_content: yup.string().required().min(0).max(2),
    presence_penalty: yup.number().required()
  });

  const addSfpa = async userData => {
    userData.temperature = userData.temperature === '' ? null : Number(userData.temperature);

    userData.frequency_penalty = userData.frequency_penalty === '' ? null : Number(userData.frequency_penalty);

    userData.max_tokens = userData.max_tokens === '' ? null : Number(userData.max_tokens);

    userData.presence_penalty = userData.presence_penalty === '' ? null : Number(userData.presence_penalty);

    const isValid = await addSchema.isValid(userData);

    if (!isValid) {
      let requiredField = [
        { fieldName: 'name', label: 'Template Name', type: String },
        { fieldName: 'model', label: 'Model', type: String },
        {
          fieldName: 'temperature',
          label: 'Temperature',
          type: Number,
          min: 0,
          max: 1
        },
        {
          fieldName: 'frequency_penalty',
          label: 'FrequencyPenalty',
          type: Number,
          min: 0,
          max: 2
        },
        {
          fieldName: 'max_tokens',
          label: 'MaxTokens',
          type: Number,
          min: 0,
          max: 2000
        },
        { fieldName: 'system_content', label: 'SystemContent', type: String },
        {
          fieldName: 'presence_penalty',
          label: 'PresencePenalty',
          type: Number,
          min: 0,
          max: 2
        }
      ];

      let dirtyField = customTemplateFormValidator(userData, requiredField);
      if (dirtyField?.length) {
        const message = requireValidTemplateMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }

    setLoading(true);
    const { status, data } = await SfpaSettingsApi(POST, userData, 'get-all');
    if (status === 201 || status === 200) {
      const message = successMessage('Template', 'created');
      enqueueSnackbar(message, { variant: SUCCESS });
      setLoading(false);
      navigate('/product-setup/sfpa-builder');
    } else {
      const message = unableMessage('Template', 'create');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
      setLoading(false);
    }
  };

  return (
    <Popup open={true} modal closeOnDocumentClick={false} closeOnEscape={false} className={'add-document-value'}>
      <Helmet>
        <title>Add AI Template - KGP Galaxy</title>
      </Helmet>
      <div>
        <Loader show={loading} />
        <form autoComplete='off'>
          <div className='' style={{ background: '#2a364c', color: '#fff' }}>
            <div className='quick-add-header'>
              <Typography>Add AI Template</Typography>
              <CloseIcon className='cursor-pointer' onClick={onClose} />
            </div>
          </div>

          <div className='d-flex flex-column py-3'>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className='page-label'>
                  Template Name
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant='outlined'
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='Template Name'
                    {...register('name')}
                    name='name'
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className='page-label'>
                  Model
                </InputLabel>
                <div className='page-label-value'>
                  <InputField variant='outlined' required={true} className='input-form-field' label='' placeholder='Model' {...register('model')} name='model' InputLabelProps={{ focused: true }} />
                </div>
              </div>
            </div>

            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className='page-label'>
                  Temperature
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    type='number'
                    variant='outlined'
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='Temperature'
                    {...register('temperature')}
                    name='temperature'
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <InputLabel required='true' className='page-label'>
                  Frequency Penalty
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    type='number'
                    variant='outlined'
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='Frequency penalty'
                    {...register('frequency_penalty')}
                    name='frequency_penalty'
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </div>
            </div>

            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel required='true' className='page-label'>
                  Max Tokens
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    type='number'
                    variant='outlined'
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='Max Tokens'
                    {...register('max_tokens')}
                    name='max_tokens'
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <InputLabel required='true' className='page-label'>
                  System Content
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant='outlined'
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='System Content'
                    {...register('system_content')}
                    name='system_content'
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel required='true' className='page-label'>
                  Presence Penalty
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    type='number'
                    variant='outlined'
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='Penalty Penalty'
                    {...register('presence_penalty')}
                    name='presence_penalty'
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='page-content-footer'>
            <Button className='page-btn-cancel' onClick={onClose}>
              Cancel
            </Button>
            <Button variant='contained' type='submit' className='page-btn-save' onClick={handleSubmit(addSfpa)}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Popup>
  );
}

AddSfpaBuilder.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  enqueueSnackbar: PropTypes.func
};
