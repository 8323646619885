import PropTypes from 'prop-types';
import React from 'react';
import './CustomSwitch.scss'; // Import the CSS file for styling

const CustomSwitch = ({ checked, onChange }) => {
  return (
    <div className={`custom-switch ${checked ? 'checked' : ''}`} onClick={onChange}>
      <div className='switch-thumb' />
      <div className='switch-track' />
    </div>
  );
};

CustomSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CustomSwitch;
