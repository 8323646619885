import { Box, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
  card: {
    minWidth: '30%',
    perspective: '1000px',
    position: 'relative',
    height: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'
  },
  cardInner: {
    width: '100%',
    height: '100%',
    minHeight: 'inherit',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.5s',
    '&.flipped': {
      transform: 'rotateX(180deg)'
    },
    padding: '4px 6px'
  },
  cardFront: {
    width: '100%',
    height: '100%',
    minHeight: 'inherit',
    backfaceVisibility: 'hidden'
  },
  cardBack: {
    width: '100%',
    height: '100%',
    minHeight: 'inherit',
    // backfaceVisibility: "hidden",
    transform: 'rotateX(-180deg)'
  },
  flipButton: {
    position: 'absolute',
    display: 'none',
    bottom: 10,
    right: 10,
    cursor: 'pointer',
    padding: '4px',
    color: 'primary.main'
  }
}));

const FlipCard = props => {
  const { children, style, ...rest } = props;
  const [isFlipped, setIsFlipped] = useState(false);
  const classes = useStyles();
  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };
  // console.log({ children, style });
  return (
    <Box className={classes.card} style={{ ...style }} {...rest}>
      <Card className={`${classes.cardInner} ${isFlipped ? 'flipped' : ''}`}>
        {!isFlipped ? (
          <Card className={classes.cardFront}>
            {/* Front of the card */}
            {children[0]}
          </Card>
        ) : (
          <Card className={classes.cardBack}>
            {/* Back of the Card */}
            {children[1]}
          </Card>
        )}
      </Card>
      <Box className={classes.flipButton} variant='plain' color='primary.main' onClick={handleFlip}>
        {!isFlipped ? 'Insights' : 'Back'}
      </Box>
    </Box>
  );
};

FlipCard.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object
};
export default FlipCard;
