//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useRef, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import { cleanup, BDSchema, prepareSearchData, BDBillingSchema } from './utils';
import { POST, SUCCESS, ERROR, PUT, GET, BD_PENDING_STATUS_MESSAGE, BILLING_VALIDATIONS, FEE_TYPES, PENDING_REJECT_BILLING_STATUS } from '../../services/constantService';
import { bdDataApi, searchDataApi } from '../../services/ApiService';
import { requireMessage, successMessage, unableMessage, validateMessage, VALIDATION_MESSAGE } from '../../services/MessageService';

import { customFormValidator, requireValidMessage } from '../../utils/common';
import TabLayoutWithSteppers from '../TabLayoutWithSteppers';
import BasicDetails from './BasicDetails';
import KGPTeamInfo from '../CreateSearch/Components/KGPTeamInfo';
import { fetchCurrencyDetails, fetchUserList } from '../../actions';
import BillingDetails from './BillingDetails/index';
import Loader from '../common/Loader';
import ConfirmationPopup from '../ConfirmationPopup';
import { checkForError, validateCalculationAmount, validatePercentageFields } from '../ViewSearch/utils';
import { checkSearchApprovalRoles } from '../../Containers/Commons/Utils';
import { getUserSelector } from '../../selectors';
import { useSnackbar } from 'notistack';
// import { defaultValues as defaultFormValues } from '../CreateSearch/utils';

const defaultValues = {
  currency: 'USD',
  stage: 'BD',
  stage_desc: 'Business Development',
  retainers: [{ field: 'Retainer 1' }, { field: 'Retainer 2' }, { field: 'Retainer 3' }, { field: 'Final Retainer' }, { field: 'Contingent Fee' }, { field: 'Monthly Fee' }],
  notes: [{}],
  partners: [],
  recruiters: [{}],
  researchers: [{}],
  eas: [{}],
  estimated_percentage_bases: [{}],
  indirect_fees_searches: [{}],
  term_type: 'Milestone',
  fee_type: 'STD',
  fee_type_desc: FEE_TYPES.ONE_THIRD,
  fee_percentage: 33.33
};

function TabPanel(props) {
  const { children, value, index } = props;
  return value === index && <React.Fragment>{children}</React.Fragment>;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function AddBD() {
  const { enqueueSnackbar } = useSnackbar();
  const { setValue, watch, register, control, getValues, reset } = useForm({
    ...defaultValues
  });
  //const { dirtyFields } = formState
  const [isLoading, setIsLoading] = useState(false);
  // const [isSaveAndNextClicked, setIsSaveAndNextClicked] = useState(false);
  const [tabValueOfBusinessDev, setTabValueOfBusinessDev] = useState(1);
  const navigate = useNavigate();
  const companyRef = useRef();
  const userRef = useRef();
  const [options, setOptions] = useState([]);
  const users = useSelector(state => state.rootReducer.users);
  const dispatch = useDispatch();
  const [currentValues, setCurrentValues] = useState({ ...defaultValues });
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const currencyCodeDetails = useSelector(state => (state.commonReducer[watch('currency')] ? state.commonReducer[watch('currency')] : {}));
  const [isHide, setIsHide] = useState(false);
  const userData = useSelector(getUserSelector);
  const [thresholdPopup, setThresholdPopup] = useState(false);
  const [thresholdValue, setThresholdValue] = useState(false);
  const [skipCheckThresholdValue, setSkipCheckThresholdValue] = useState(false);
  const [handleSaveArgs, setHandleSaveArgs] = useState({});
  const [tabValueNumber, setTabValueNumber] = useState(null);
  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(watch('currency'), dispatch);
      } catch (e) {
        console.log('Error found in currencyHandler::', e);
      }
    };
    if (watch('currency') && Object.keys(currencyCodeDetails)?.length === 0) {
      currencyHandler();
    }
  }, [watch('currency')]);

  useEffect(() => {
    register('projected_start_date');
    register('company');
    register('country');
    register('stage');
    register('stage_desc');
    register('brand');
    register('term_type');
    register('intervals');
    register('job_title');
    register('client_team');
    register('billing_notes');
    register('currency');
    register('estimated_percentage_bases');
    register('indirect_fees_searches');
    register('probability');
    register('fee_percentage');
    register('estimated_percentage_base');
    register('estimated_indirect_total_fee');
    register('estimated_revenue');
    register('intervals_other');
    register('basic_partners');
    // register('projected_fee')
    register('fee_cap_amount');
    register('fee_type');
  }, [register]);

  const handleTabValue = value => {
    setTabValueOfBusinessDev(value);
  };

  // const handleSaveAndNext = (value) => {
  //   setIsSaveAndNextClicked(value)
  // }

  const validateKgpInfo = formData => {
    let isError = false;
    if (formData.partners && formData.partners?.length > 0) {
      const fields = {
        origination_credit: 30,
        selling_credit: 20,
        execution_credit: 50
      };
      isError = checkForError(formData.partners, fields);
    }
    if (!isError && formData.recruiters && formData.recruiters?.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.recruiters, fields);
    }
    if (!isError && formData.researchers && formData.researchers?.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.researchers, fields);
    }
    if (!isError && formData.eas && formData.eas?.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.eas, fields);
    }
    if (isError) {
      const message = validateMessage('valid number', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
      return false;
    }
    return true;
  };

  const validateBillingInfo = async (formData, tabValue) => {
    const isValid = await BDBillingSchema.isValid(formData);
    if (tabValue === 2) {
      if (!isValid) {
        let requiredField = [
          { fieldName: 'currency', label: 'Currency Code', type: String },
          { fieldName: 'billing_region', label: 'Billing Region', type: String }
        ];
        let dirtyField = customFormValidator(formData, requiredField);
        if (dirtyField) {
          const message = requireValidMessage(dirtyField);
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
      }
      const isValidIndirectFee = validatePercentageFields(formData);
      if (!isValidIndirectFee) {
        enqueueSnackbar(BILLING_VALIDATIONS.FEE_PERCENTAGE_MAX, { variant: ERROR });
        return;
      }
      const isCalculationAmount = validateCalculationAmount(formData);
      if (!isCalculationAmount) {
        enqueueSnackbar(BILLING_VALIDATIONS.CALCULATION_AMOUNT_MAX, { variant: ERROR });
        return;
      }
    }

    return true;
  };

  useEffect(() => {
    if (skipCheckThresholdValue) {
      // handleSave(tabChanged)
      const { busDev, tabValue, save, newTabValue } = handleSaveArgs;
      addBD(busDev, tabValue, save, newTabValue);
    }
  }, [skipCheckThresholdValue]);

  const addBD = async (busDev, tabValue, save, newTabValue) => {
    const isValid = await BDSchema.isValid(busDev);
    if (!isValid) {
      let requiredField = [
        { fieldName: 'company', label: 'Company', type: {} },
        { fieldName: 'job_title', label: 'Job Title', type: String },
        { fieldName: 'basic_partners', label: 'Partners', type: [] },
        // { fieldName: "projected_fee", label: "Projected Fee", type: String },
        { fieldName: 'probability', label: 'Probability', type: Number },
        { fieldName: 'brand', label: 'Service Offering', type: String },
        { fieldName: 'stage', label: 'Stage', type: String },
        { fieldName: 'markets', label: 'Geography', type: String },
        { fieldName: 'country', label: 'Location', type: String },
        { fieldName: 'projected_start_date', label: 'Projected Start Date', type: String }
      ];
      let dirtyField = customFormValidator(busDev, requiredField);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }

    const isValidKgpInfo = validateKgpInfo(busDev);
    if (!isValidKgpInfo) return;

    const isValidBillingInfo = await validateBillingInfo(busDev, tabValue);
    if (!isValidBillingInfo) return;
    if (busDev.projected_start_date === 'Invalid date') {
      const message = validateMessage('valid date', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (busDev.estimated_revenue > 0 && !busDev.market) {
      enqueueSnackbar(BILLING_VALIDATIONS.MARKET_VALUE, { variant: ERROR });
      return;
    }
    if (tabValue === 1) {
      if (busDev.partners) {
        if (!busDev.partners?.length || (busDev.partners?.length && !busDev.partners.find(ele => ele?.user))) {
          const message = requireMessage('Partners', 'is');
          enqueueSnackbar(message, { variant: ERROR });
          return;
        }
      }
    }
    setHandleSaveArgs({ busDev, tabValue, save, newTabValue });
    if (
      tabValue === 2 &&
      busDev &&
      busDev.billing_status !== 'PND_RHEAD' &&
      busDev.brand === 'Executive' &&
      busDev.estimated_revenue < busDev.markets?.threshold &&
      busDev.estimated_revenue !== 0 &&
      !skipCheckThresholdValue
    ) {
      setThresholdPopup(true);
      return;
      //   if(skipCheckThresholdValue){
      //   //   if (save === "saveButton") {
      //   //     return false;
      //   //   } else {
      //   //     return true
      //   //   }
      //   // }else{
      //   //   return false
      //   // }
    }
    setIsLoading(true);
    const payload = cleanup(busDev, tabValue);
    if (skipCheckThresholdValue) {
      if (thresholdValue) {
        payload['isBelowThreshold'] = true;
      } else {
        payload['isBelowThreshold'] = false;
      }
    }

    let { status, data: res } = payload.id ? await searchDataApi(PUT, payload.id, payload) : await bdDataApi(POST, '', payload);
    if (status && status === 201) {
      let { status, data: result } = await searchDataApi(GET, payload.id ? payload.id : res.result.id);
      if (status && status === 200) {
        const basicPartners = result.partners ? result.partners.map(partner => partner.user)?.filter(user => user) : [];
        result.basic_partners = basicPartners;
        result.recruiters = result.recruiters?.length ? result.recruiters : [{}];
        result.researchers = result.researchers?.length ? result.researchers : [{}];
        result.eas = result.eas?.length ? result.eas : [{}];
        result.estimated_percentage_bases = result.estimated_percentage_bases?.length ? result.estimated_percentage_bases : [{}];
        result.indirect_fees_searches = result.indirect_fees_searches?.length ? result.indirect_fees_searches : [{}];
        result.intervals_desc = result.intervals_desc ? result.intervals_desc : result.intervals;
        setCurrentValues(result);
      }
      //const jobNumber = res.result.job_number;
      //setCurrentValues(prevState => ({ ...prevState, job_number: jobNumber, id: res.result.id }))

      if (tabValueOfBusinessDev === 2) {
        const message = successMessage('Business Development', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
      }
    } else if (status && status === 200) {
      let { status: getStatus, data: searchResult } = await searchDataApi(GET, payload.id ? payload.id : res.result.id);
      if (getStatus && getStatus === 200) {
        const searchData = prepareSearchData(searchResult);
        setCurrentValues(searchData);
      }
      const message = successMessage('Business Development', 'updated');
      if (tabValue === 2 && save === 'saveButton') enqueueSnackbar(message, { variant: SUCCESS });
    } else {
      setIsLoading(false);
      const message = unableMessage('Business Development', `${payload.id ? 'update' : 'create'}`);
      enqueueSnackbar(res.message || message, { variant: ERROR });
      return false;
    }
    if (tabValue === 2 && save === 'saveButton' && skipCheckThresholdValue) handleClose();
    setIsLoading(false);
    if (newTabValue && newTabValue > 2) newTabValue = 2;
    skipCheckThresholdValue && setTabValueNumber(newTabValue);
    return true;
  };

  const handleClose = () => {
    if (!showConfirmationPopup) {
      navigate('/searches/my-searches');
    }
    setShowConfirmationPopup(false);
  };

  const handleCancel = () => {
    setShowConfirmationPopup(true);
  };

  const handleChange = async (oldValue, newValue, save) => {
    try {
      console.log(newValue, oldValue);
      const formValues = { ...currentValues, ...getValues() };
      if (!formValues?.id) {
        if (formValues.partners?.length) {
          formValues.partners = [];
        }
      }
      reset({ ...formValues });
      setCurrentValues(formValues);

      const result = await addBD(formValues, oldValue, save, newValue);
      return result;
    } catch (e) {
      console.log('Error found in handleChange::', e);
    }
  };

  useEffect(() => {
    if (!users) {
      dispatch(fetchUserList('users'));
    } else {
      setOptions(users);
    }
  }, [users, dispatch]);

  useEffect(() => {
    reset({ ...currentValues });
  }, [reset, currentValues]);

  const handleConfirmPopup = async () => {
    setShowConfirmationPopup(false);
    navigate('/searches/my-searches');
    //handleClose();
    // setIsLoading(true)
    // const { status } = await searchDataApi(DELETE, currentValues.id);
    // if (status === 200) {
    //   const message = successMessage("deleted", "Business Development")
    //   enqueueSnackbar(message, { variant: SUCCESS })
    //   handleClose();
    // } else {
    //   const message = unableMessage("Business Development", "delete")
    //   enqueueSnackbar(message, { variant: ERROR })
    // }
    // setIsLoading(false)
  };

  const handleCancelPopup = async () => {
    setShowConfirmationPopup(false);
  };

  const handleThresholdClose = () => {
    setThresholdPopup(false);
  };

  const handleConfirmThresholdPopup = () => {
    setThresholdValue(true);
    setThresholdPopup(false);
    setSkipCheckThresholdValue(true);
  };

  const handleThresholdCancelPopup = () => {
    setThresholdValue(false);
    setThresholdPopup(false);
    setSkipCheckThresholdValue(false);
  };

  useEffect(() => {
    if (userData) {
      const disableEdit = checkSearchApprovalRoles(userData);
      if (!disableEdit && PENDING_REJECT_BILLING_STATUS.includes(currentValues?.billing_status)) {
        setIsHide(true);
      }
    }
  }, [currentValues]);

  return (
    <Popup open={true} modal closeOnDocumentClick={false} closeOnEscape={false}>
      <>
        <Helmet>
          <title>Add BD - KGP Galaxy</title>
        </Helmet>
        <div className='w-100 '>
          <Loader show={isLoading} />
          {showConfirmationPopup && (
            <ConfirmationPopup header={VALIDATION_MESSAGE.delete_bd_search} onConfirm={handleConfirmPopup} onCancel={handleCancelPopup} onClose={handleClose} cancelText='Cancel' confirmText='Ok' />
          )}
          {thresholdPopup && (
            <div className='confirmation-popup-bd-search'>
              <ConfirmationPopup
                header={VALIDATION_MESSAGE.threshold_confirmation}
                onConfirm={handleConfirmThresholdPopup}
                onCancel={handleThresholdCancelPopup}
                onClose={handleThresholdClose}
                cancelText='No'
                confirmText='Save & Continue'
              />
            </div>
          )}
          <div className='d-flex add-bd-header align-items-center justify-content-between'>
            <div className='add-bd-header-name'>Add Opportunity</div>
            <div className='bd-close-icon' onClick={handleCancel}>
              <CloseIcon fontSize='inherit' />
            </div>
          </div>

          <div className='add-bd-content d-flex flex-column popup-height'>
            <TabLayoutWithSteppers
              navItems={['Basic Information', 'Team Info', 'Billing Details']}
              tabChange={handleChange}
              onCancel={handleCancel}
              onClose={handleClose}
              statusMessage={BD_PENDING_STATUS_MESSAGE}
              handleTabValue={handleTabValue}
              // isSaveAndNextClicked={isSaveAndNextClicked}
              // handleSaveAndNext={handleSaveAndNext}
              tabValueNumber={tabValueNumber}
              setTabValueNumber={setTabValueNumber}
            >
              <BasicDetails
                register={register}
                setValue={setValue}
                companyRef={companyRef}
                userRef={userRef}
                watch={watch}
                control={control}
                currentValues={currentValues}
                currencyDetails={currencyCodeDetails}
                isHide={isHide}
              />
              <KGPTeamInfo
                register={register}
                setValue={setValue}
                userRef={userRef}
                watch={watch}
                control={control}
                currentValues={currentValues}
                option={options}
                defaultStyle={{}}
                bd='AddBD'
                types='BD'
              />
              <BillingDetails register={register} setValue={setValue} watch={watch} currentValues={currentValues} currencyDetails={currencyCodeDetails} isHide={isHide} />
            </TabLayoutWithSteppers>
          </div>
          {/* <div className="add-bd-footer">
            <Button variant="text" color="primary" className="button" onClick={handleClose}>Cancel</Button>
            <Button variant="contained" className="button" color="primary" onClick={handleSubmit(addBD)}>Save</Button>
              </div> */}
        </div>
      </>
    </Popup>
  );
}

AddBD.propTypes = {
  enqueueSnackbar: PropTypes.func
};

export default AddBD;
