import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';

import { Box } from '@mui/material';
import Switch from '@mui/material/Switch';
import React from 'react';
import '../index.scss';

const IconSwitch = props => {
  return (
    <Switch
      className='congiguration-switch'
      size='small'
      icon={
        <Box className='minus-icon'>
          <RemoveIcon fontSize='small' />
        </Box>
      }
      checkedIcon={
        <Box className='tick-icon'>
          <DoneIcon fontSize='small' />
        </Box>
      }
      {...props}
    />
  );
};

export default IconSwitch;
