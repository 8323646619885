//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Contacts from './Contacts';

const MyLinkedinContacts = () => <Contacts type='my-linkedin-contacts' storageKey='myLinkedinContactsColumns' title='My LinkedIn Contacts' checkStatus={true} />;

export default MyLinkedinContacts;
