import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import React from 'react';
export const FilterItem = ({ label = '', checked = false, onChange, disabled = false }) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              padding: '0 8px 0 0'
            }}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
        }
        label={<Typography sx={{ fontSize: 12, fontWeight: 600, cursor: 'pointer' }}>{label}</Typography>}
        sx={{
          margin: 0
        }}
      />
    </Box>
  );
};

FilterItem.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};
