//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useRef, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Link } from 'react-router-dom';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { CompensationRenderer, SCROLL_TIMEOUT } from '../../utils/common';
import { columnDefs } from './utils';
import { DateRenderer, LinkedInRenderer, loadColumnStateFromLocalStorage, saveColumnStateToLocalStorage } from '../../Containers/Commons/Utils';
import ColumnFilter from '../../Containers/Commons/ColumnFilter';
import {
  ActionsRenderer,
  ContactNameRenderer,
  CustomLoadingOverlayComponent,
  defaultColumns,
  EmailRenderer,
  PAGE_LIMIT,
  PhoneRenderer,
  ResumeRenderer,
  SUCCESS_STATUS_CODE
} from '../../Containers/Contacts/utils';
import { masterSearchApi } from '../../services/ApiService';
import { CompanyNameRenderer } from '../../Containers/Companies/Utils';
// import { useSnackbar } from "notistack";

let gridApi, columnApi, linkedInPopupHandler, handleAddToSearch;
export function Contacts(props) {
  const { searchKey, setContactsCount, height = '100%', rowHeight = 42, minHeight = 180 } = props;
  const [rowCount, setRowCount] = useState(0);
  const [isGridReady, setIsGridReady] = useState(false);
  // const { enqueueSnackbar } = useSnackbar();

  const prevSearchKeyRef = useRef(null);

  useEffect(() => {
    return () => {
      const columnApis = columnApi;
      saveColumnStateToLocalStorage('masterSearchContactColumns', {
        columnApis
      });
    };
  }, []);

  useEffect(() => {
    if (searchKey !== prevSearchKeyRef.searchKey) {
      gridApi && gridApi.onFilterChanged();
    }
    prevSearchKeyRef.current = searchKey;
  }, [searchKey]);

  const dataSource = {
    getRows: async params => {
      gridApi.showLoadingOverlay();
      const value = encodeURIComponent(searchKey);
      let sub_route = `contacts?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}&searchValue=${value}`;
      if (params.sortModel?.length > 0) {
        sub_route = sub_route.concat(`&sortOn=${params.sortModel[0].colId}&sortType=${params.sortModel[0].sort.toUpperCase()}`);
      }
      sub_route = `${sub_route}&mastersearch=true`;
      const { status, data } = await masterSearchApi(sub_route);
      if (status === SUCCESS_STATUS_CODE) {
        params.successCallback(data.data, data.paging?.totalCount);
        // this.setState({ rowCount: data.paging.showTotal ? data.paging.showTotal : data.paging?.totalCount })
        setRowCount(data.paging.showTotal ? data.paging.showTotal : data.paging?.totalCount);
        setContactsCount(data.paging.showTotal ? data.paging.showTotal : data.paging?.totalCount);
      }
      params.failCallback();
      gridApi.hideOverlay();
    },
    rowCount: null
  };

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    // const columnApi = params.columnApi
    params.columnApi.autoSizeAllColumns();
    loadColumnStateFromLocalStorage('masterSearchContactColumns', {
      columnApi
    });
    // this.setState({ isGridReady: true });
    setIsGridReady(true);
  };

  const NameRenderer = params => {
    return <ContactNameRenderer params={params} handleAddToSearch={handleAddToSearch} />;
  };

  const CompanyNameRenderers = params => {
    return <CompanyNameRenderer company={params?.data?.company} />;
  };

  const LinkedInRenderers = params => {
    return <LinkedInRenderer params={params} linkedInPopup={linkedInPopupHandler} />;
  };

  return (
    <div className='list-contacts d-flex flex-column mt-4' style={{ height: rowCount ? height : minHeight }}>
      <div className='list-header d-flex justify-content-between'>
        <Typography className='table-header-text' style={{ paddingLeft: '1.5%' }}>
          <PersonOutlineIcon style={{ fill: '#DE9F6D' }} />
          Contacts
        </Typography>
        <Typography className='total-count' style={{ paddingRight: '1.5%' }}>
          Total count: {rowCount}
        </Typography>
      </div>
      <div className='list-view flex-grow-1'>
        {isGridReady && <ColumnFilter defaultColumns={defaultColumns} gridApi={gridApi} columnApi={columnApi} />}
        <div id='myGrid' className='ag-theme-alpine'>
          <AgGridReact
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            rowHeight={rowHeight}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            frameworkComponents={{
              CustomLoadingOverlayComponent,
              LinkedInRenderer: LinkedInRenderers,
              PhoneRenderer,
              EmailRenderer,
              ActionsRenderer,
              NameRenderer: NameRenderer,
              ResumeRenderer,
              DateRenderer,
              CompanyNameRenderer: CompanyNameRenderers,
              CompensationRenderer
            }}
            blockLoadDebounceMillis={SCROLL_TIMEOUT}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            rowModelType={'infinite'}
            datasource={dataSource}
            columnDefs={columnDefs}
            paginationPageSize={PAGE_LIMIT}
            colResizeDefault={'shift'}
            getRowNodeId={data => data.id}
            suppressRowClickSelection={true}
          ></AgGridReact>
          <Link
            to={{
              pathname: '/contacts',
              state: searchKey
            }}
            style={{ fontSize: '18px', float: 'right' }}
            className='MuiTypography-colorPrimary mt-2'
          >
            More Records&gt;{' '}
          </Link>
        </div>
      </div>
    </div>
  );
}
Contacts.propTypes = {
  enqueueSnackbar: PropTypes.func,
  searchKey: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowHeight: PropTypes.number,
  minHeight: PropTypes.number,
  setContactsCount: PropTypes.func.isRequired
};

export default Contacts;
