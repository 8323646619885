//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { API, DASHBOARD, ERROR, GET } from '../../services/constantService';
import { dashboardDataApi } from '../../services/ApiService';
import './index.scss';
import { unableMessage } from '../../services/MessageService';
import { useSnackbar } from 'notistack';

const DashboardReports = props => {
  const { viewName } = props;
  const [insightUrl, setInsightUrl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const getInsightUrl = async () => {
    try {
      const url = `${API.dashboard}/${DASHBOARD.INSIGHT_URL}`;
      const { status, data } = await dashboardDataApi(GET, url, `reportType=${viewName}`);
      if (status === 200) {
        setInsightUrl(data.result);
      } else {
        const message = unableMessage('Insight URL', 'get');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
    } catch (err) {
      console.log('error in Fetch Token::', err);
    }
  };
  useEffect(() => {
    getInsightUrl();
  }, []);

  return (
    <>
      {insightUrl && (
        <div className='dashboard_head'>
          <iframe title='araging_current_active_light' src={insightUrl} frameBorder='0' allowFullScreen='true' />
        </div>
      )}
    </>
  );
};

DashboardReports.propTypes = {
  viewName: PropTypes.string
};

export default DashboardReports;
