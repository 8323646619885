import React, { useContext, useEffect, useState } from 'react';
import CustomModalWithHeader from '../../../Commons/CustomModalWithHeader';
import CustomInputField from '../../../../components/common/StyledComponents/CustomInputField';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomDropdown from '../../../../components/common/CustomDropdown';
import CustomButton from '../../../../components/common/CustomButton';
import Loader from '../../../../components/common/Loader';
import { enqueueSnackbar } from 'notistack';
import { WorkBenchContext } from '../../Context';
import { ignWorkbenchDataApi, picklistsDataApis } from '../../../../services/ApiService';
import { GET, POST } from '../../../../services/constantService';
import { getUserSelector } from '../../../../selectors';
import { useSelector } from 'react-redux';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';

function AddWorkBenchModal() {
  const { addWorkBenchModalOpen, setAddWorkBenchModalOpen, refetchFunc } = useContext(WorkBenchContext);
  const [workbenchType, setWorkbenchType] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [isLoading, setLoading] = useState(false);
  const user = useSelector(getUserSelector);

  const { t } = useLanguageHooks([TRANS_KEYS.ADD_LIST, TRANS_KEYS.LIST_NAME, TRANS_KEYS.OWNER, TRANS_KEYS.DESCRIPTION, TRANS_KEYS.LIST_TYPE, TRANS_KEYS.CONTACTS_CANCEL, TRANS_KEYS.ADD_LIST]);
  const handleFormChange = (key, value) => {
    formValue[key] = value;
    setFormValue(formValue);
  };

  const onClose = () => {
    setAddWorkBenchModalOpen(false);
  };

  const handleCreateList = async () => {
    try {
      setLoading(true);
      const payload = {
        name: formValue.name ?? '',
        type: formValue.type ?? '',
        description: formValue.description ?? '',
        created_by: user.id
      };
      const res = await ignWorkbenchDataApi(POST, '', payload, '');
      if (res && res?.data) {
        enqueueSnackbar('Workbench is created successfully', 'success');
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoading(false);
      onClose();
      refetchFunc();
    }
  };

  const FetchDropDownValues = async () => {
    try {
      const res = await picklistsDataApis(GET, 'WORKBENCH_TYPE');

      if (res && res?.data) {
        setWorkbenchType(res.data);
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    }
  };

  useEffect(() => {
    FetchDropDownValues();
  }, []);
  return (
    <CustomModalWithHeader label={t(`${TRANS_KEYS.ADD_LIST}:key`)} closeIcon={true} isOpen={addWorkBenchModalOpen} onClose={onClose}>
      <Loader show={isLoading} />
      <Grid container spacing={2} rowGap={1}>
        <Grid item xs={6}>
          <CustomInputField label={t(`${TRANS_KEYS.LIST_NAME}:key`)} onChange={e => handleFormChange('name', e.target.value)} />
        </Grid>
        <Grid item xs={6}>
          <CustomDropdown
            label={t(`${TRANS_KEYS.LIST_TYPE}:key`)}
            options={workbenchType}
            className={'w-100'}
            onChange={(e, value) => {
              handleFormChange('type', value.field_value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomInputField disabled label={t(`${TRANS_KEYS.OWNER}:key`)} value={user?.name} />
        </Grid>
        <Grid xs={6} />
        <Grid item xs={12}>
          <CustomInputField label={t(`${TRANS_KEYS.DESCRIPTION}:key`)} onChange={e => handleFormChange('description', e.target.value)} />
        </Grid>
      </Grid>
      <div className='w-100 d-flex justify-content-end align-items-center mt-4'>
        <CustomButton type='tertiary-error' variant={'text'} buttonText={t(`${TRANS_KEYS.CONTACTS_CANCEL}:key`)} onClick={onClose} />
        <CustomButton buttonText={t(`${TRANS_KEYS.ADD_LIST}:key`)} onClick={handleCreateList} />
      </div>
    </CustomModalWithHeader>
  );
}

AddWorkBenchModal.propTypes = { isOpen: PropTypes.bool, onClose: PropTypes.func };

export default AddWorkBenchModal;
