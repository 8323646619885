import { CheckBox } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, TextField, Typography, useAutocomplete } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import CustomButton from '../../../../components/common/CustomButton';
import CustomPhoneInput from '../../../../components/common/CustomPhoneInput';
import CustomPopup from '../../../../components/common/CustomPopup';
import { ignUsersApi, rolesApi, userRolesApi } from '../../../../services/ApiService';
import { ADD_USER_VALIDATION, GET, POST } from '../../../../services/constantService';
import useApi from '../../../../utils/Hooks/useApiHook';

const AddUserPopup = ({ isOpen, setIsPopupOpen, refetch }) => {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: ''
  });
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { data: rolesData } = useApi({
    queryFn: () => rolesApi(GET, 'getAllWithResource')
  });

  const addUser = async addAnother => {
    try {
      if (!userData.firstName || !userData.lastName || !userData.email || selectedRoles.length === 0) {
        enqueueSnackbar(ADD_USER_VALIDATION.REQUIRED, {
          variant: 'error'
        });
        return;
      }
      setLoading(true);
      await ignUsersApi(POST, '', {
        first_name: userData.firstName,
        last_name: userData.lastName,
        phone_number: userData.phoneNumber,
        email: userData.email
      }).then(async res => {
        await addRole(res.data.email, res.data.id).then(async () => {
          await refetch();

          if (!addAnother) {
            setIsPopupOpen(false);
          }
          setUserData({
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: ''
          });
          setSelectedRoles([]);
          enqueueSnackbar(ADD_USER_VALIDATION.SUCCESS, {
            variant: 'success'
          });
        });
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(ADD_USER_VALIDATION.FAILED, {
        variant: 'error'
      });
      console.log(e);
    }
  };

  const addRole = async (email, id) => {
    if (selectedRoles.length === 0) return;

    const newRolesBody = selectedRoles.map(role => {
      return {
        email: email,
        roleId: role.id,
        userId: id
      };
    });

    await userRolesApi(POST, '', {
      roles: newRolesBody
    });
  };

  return (
    <CustomPopup
      open={isOpen}
      onClose={() => {
        setIsPopupOpen(false);
      }}
      title={'Add User'}
      confirmText={'Add User'}
    >
      <Box
        sx={{
          width: '50vw',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 2,
          justifyContent: 'space-evenly'
        }}
      >
        <TextField
          id='outlined-basic'
          label='First Name'
          variant='outlined'
          required
          size='small'
          sx={{
            mt: 2
          }}
          value={userData.firstName}
          onChange={e => {
            setUserData({
              ...userData,
              firstName: e.target.value
            });
          }}
        />

        <TextField
          id='outlined-basic'
          label='Last Name'
          required
          variant='outlined'
          size='small'
          sx={{
            mt: 2
          }}
          value={userData.lastName}
          onChange={e => {
            setUserData({
              ...userData,
              lastName: e.target.value
            });
          }}
        />

        <TextField
          id='outlined-basic'
          label='Email'
          required
          variant='outlined'
          size='small'
          value={userData.email}
          onChange={e => {
            setUserData({
              ...userData,
              email: e.target.value
            });
          }}
        />

        <CustomPhoneInput
          placeholder='Enter phone number'
          label='Phone Number'
          id='outlined-basic'
          variant='outlined'
          size='small'
          value={userData.phoneNumber}
          onChange={e => {
            setUserData({
              ...userData,
              phoneNumber: e
            });
          }}
        />

        {rolesData?.data && <RoleAutocomplete roles={rolesData?.data} selectedRoles={selectedRoles} setSelectedRoles={setSelectedRoles} />}
      </Box>

      <Box
        sx={{
          display: 'flex',
          mt: 2,
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 2,
          bottom: 0
        }}
      >
        <Spinner animation='border' variant='success' size='sm' className='ml-2' style={{ display: loading ? 'inline-block' : 'none' }} />
        <CustomButton
          buttonText='Add User'
          onClick={() => {
            addUser(false);
          }}
        />
        <CustomButton
          type='outline-primary'
          buttonText='Save and Add Another'
          onClick={() => {
            addUser(true);
          }}
        />
      </Box>
    </CustomPopup>
  );
};

AddUserPopup.propTypes = {
  isOpen: PropTypes.bool,
  setIsPopupOpen: PropTypes.func,
  data: PropTypes.object,
  refetch: PropTypes.func
};

export default AddUserPopup;

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired
};

const RoleAutocomplete = ({ roles, selectedRoles, setSelectedRoles }) => {
  const { getRootProps, getInputProps, getTagProps, getListboxProps, getOptionProps, groupedOptions, value, focused, setAnchorEl } = useAutocomplete({
    value: selectedRoles,
    multiple: true,
    options: roles,
    getOptionLabel: option => option.name,
    disableCloseOnSelect: true
  });

  return (
    <div>
      <div {...getRootProps()}>
        <span className='fs-12'>
          Roles <span className='red'>*</span>
        </span>
        <div id='input-wrapper' ref={setAnchorEl} className={`${focused ? 'focused' : ''} w-100`}>
          {value.map((option, index) => (
            <Tag
              label={option.name}
              {...getTagProps({ index })}
              key={option.name}
              className='role-tag'
              onDelete={() => {
                setSelectedRoles(prev => prev.filter(role => role.id !== option.id));
              }}
            />
          ))}
          <input {...getInputProps()} required />
        </div>
      </div>
      {groupedOptions.length > 0 ? (
        <ul
          {...getListboxProps()}
          id='role-listbox'
          style={{
            width: '24.5vw'
          }}
        >
          {groupedOptions.map((option, index) => (
            <li
              {...getOptionProps({ option, index })}
              key={option.name}
              onClick={() => {
                if (selectedRoles.some(role => role.id === option.id)) {
                  setSelectedRoles(selectedRoles.filter(role => role.id !== option.id));
                } else {
                  setSelectedRoles([...selectedRoles, option]);
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  width: '100%'
                }}
              >
                <Typography className='body-font fs-14 py-1'>{option.name}</Typography>
                <CheckBox className='icon-size' sx={{ ml: 'auto' }} />
              </Box>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

RoleAutocomplete.propTypes = {
  roles: PropTypes.array,
  selectedRoles: PropTypes.array,
  setSelectedRoles: PropTypes.func
};
