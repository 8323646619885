//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InputField from '../common/InputField';

function TypeSelection(props) {
  const { label = 'User', placeholder = 'Types', required = false, disabled = false, InputProps, InputLabelProps, inputRef, ...rest } = props;
  const options = ['Contacts', 'Searches'];

  return (
    <Autocomplete
      {...rest}
      options={options}
      filterOptions={options => options}
      getOptionLabel={option => option}
      getOptionSelected={option => option}
      disabled={disabled}
      renderInput={params => (
        <InputField
          {...params}
          ref={inputRef}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
          }}
          variant='standard'
          InputLabelProps={InputLabelProps}
          required={required}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}

TypeSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputRef: PropTypes.object,
  disabled: PropTypes.bool
};

export default TypeSelection;
