//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect, useCallback } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, CardContent, Typography, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import SkillElementsSelection from './SkillElementsSelection';
import DraggableComponent from '../Draggable/DraggableComponent';
import ItemTypes from '../Draggable/ItemTypes';
import RichTextPopup from '../../../../../RichTextPopup';
import { searchDataApi } from '../../../../../../services/ApiService';
import { ERROR, PUT, SUCCESS } from '../../../../../../services/constantService';
import { useParams } from 'react-router-dom';
import { successMessage, unableMessage, VALIDATION_MESSAGE } from '../../../../../../services/MessageService';
import { getDefaultValues } from '../../utils';

function Skills(props) {
  const { currentValues, setValue, register, setLoading, setCurrentValues, setSfpaData, enqueueSnackbar, readOnly, getData } = props;
  const { id } = useParams();
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    setSkills(currentValues?.skills || [{}]);
  }, [currentValues?.skills]);

  useEffect(() => {
    register('skills');
  }, [register]);

  useEffect(() => {
    setValue('skills', skills);
  }, [skills]);

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      marginBottom: '20px'
    }
  });

  const onSkillElementsChange = (skillIndex, elements) => {
    let currentSkills = [...skills];
    currentSkills[skillIndex].elements = elements;
    setSkills(currentSkills);
  };

  const onSkillChange = async (skillIndex, skillData) => {
    try {
      let currentSkills = [...skills];
      currentSkills[skillIndex] = skillData;
      setLoading(true);
      await updateSkills(currentSkills);
      setLoading(false);
    } catch (e) {
      console.log('Error found in onSkillChange::', e);
    }
  };

  const saveSkillTitle = async (skillData, skillIndex, type, item, setAutoSaveLoading) => {
    try {
      delete skillData.old;
      let currentSkills = [...currentValues.skills];
      currentSkills[skillIndex].title = skillData.title;
      await updateSkills(currentSkills, setAutoSaveLoading);
    } catch (e) {
      console.log('Error found in saveSkillTitle::', e);
    }
  };

  const getSfpaSkills = async () => {
    const data = await getData(false, true);
    if (!data) return;
    const defaultValues = getDefaultValues(data);
    return defaultValues.skills;
  };

  const updateSkills = async (currentSkills, setAutoSaveLoading) => {
    const updatedDate = new Date().toISOString();
    const payload = { skills: currentSkills };
    payload.updated_at = updatedDate;
    if (setAutoSaveLoading) {
      setAutoSaveLoading(true);
    }
    const { status, data } = await searchDataApi(PUT, id, payload, 'sfpa');
    if (status === 200) {
      const message = successMessage('SFPA', VALIDATION_MESSAGE.updated_message);
      !setAutoSaveLoading && enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      currentSkills = await getSfpaSkills();
      // currentSkills.map(item => {
      //   return {
      //     ...item,
      //     updated_at: updatedDate,
      //     elements: item.elements.map(ele => {
      //       return { ...ele, updated_at: updatedDate }
      //     })
      //   }
      // })
      if (currentSkills) {
        setSkills(currentSkills);
        setCurrentValues({ ...currentValues, skills: currentSkills });
        setSfpaData({ skills: currentSkills });
      }
    } else {
      const message = unableMessage('SFPA', 'update');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }

    if (setAutoSaveLoading) {
      setTimeout(() => {
        setAutoSaveLoading(false);
      }, 500);
    }
  };

  const updateSkillElement = async (skillIndex, elementIndex, value, loader = false, setAutoSaveLoading) => {
    try {
      let currentSkills = [...skills];
      currentSkills[skillIndex].elements[elementIndex] = value;
      currentSkills[skillIndex].elements[elementIndex].updated_at = new Date();
      setLoading(loader);
      await updateSkills(currentSkills, setAutoSaveLoading);
      setLoading(false);
    } catch (e) {
      console.log('Error found in updateSkillElement::', e);
    }
  };

  const addSkill = () => {
    let currentSkills = [...skills];
    let position;
    if (currentSkills?.length === 0) {
      position = 0;
    } else {
      position = currentSkills?.length;
    }
    const obj = { elements: [], title: '', position };
    currentSkills = [...currentSkills, obj];
    currentValues.skills.push(obj);
    setSkills(currentSkills);
  };

  const removeSkill = index => {
    let currentSkills = [...skills];
    currentSkills.splice(index, 1);
    currentValues.skills.splice(index, 1);
    currentSkills.map((ele, index) => (ele.position = index));
    currentValues.skills.map((ele, index) => (ele.position = index));
    setSkills(currentSkills);
  };

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragSkill = skills[dragIndex];
      let currentSkills = update(skills, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSkill]
        ]
      });
      let newSkillsArray = currentSkills.map(item => {
        if (item.position === hoverIndex) {
          return { ...item, position: dragIndex };
        } else if (item.position === dragIndex) {
          return { ...item, position: hoverIndex };
        } else {
          return item;
        }
      });
      setSkills(newSkillsArray);
    },
    [skills]
  );

  const classes = useStyles();
  return (
    <div
      className={`${readOnly ? 'section-content read-only' : ''}`}
      style={{
        marginTop: '20px',
        marginLeft: '20px',
        marginBottom: '0px',
        padding: '0px'
      }}
    >
      <DndProvider backend={HTML5Backend}>
        {skills.map((item, index) => (
          <DraggableComponent
            key={index}
            index={index}
            id={item.id || index}
            moveNode={moveCard}
            componentType={ItemTypes.SKILL}
            content={(dragHandle, preview) => (
              <Card className={classes.root} ref={preview}>
                <CardContent style={{ paddingBottom: '5px' }}>
                  <div>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography style={{ float: 'left' }} className='sub-section-label' color={'textSecondary'}>
                          SKILL {index + 1}
                        </Typography>

                        <span style={{ float: 'right' }}>
                          <span ref={dragHandle}>
                            <DragHandleIcon className='cursor-pointer' />
                          </span>
                          <DeleteIcon onClick={() => removeSkill(index)} className='cursor-pointer' />
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <div className='outlined-border transform p-0 mb-2'>
                        <RichTextPopup
                          style={{ width: '98%' }}
                          className='input-form-field'
                          name='title'
                          setOpen={readOnly ? false : true}
                          onChange={value => {
                            let skillTitle = value;
                            let skill = { ...item, title: skillTitle };
                            onSkillChange(index, skill);
                          }}
                          placeholder={''}
                          autoSave={saveSkillTitle}
                          item={item}
                          index={index}
                          title='Skill Title'
                          value={item.title || ''}
                          saveStatus={true}
                          InputLabelProps={{ focused: true }}
                          spfaSkillFlag={true}
                          updatedDate={item.updated_at}
                          payloadType={'default'}
                        />
                      </div>
                    </Grid>
                    <SkillElementsSelection
                      readOnly={readOnly}
                      updateSkillElement={updateSkillElement}
                      register={register}
                      currentElements={skills[index].elements || []}
                      onSkillElementsChange={onSkillElementsChange}
                      skillIndex={index}
                    />
                  </Grid>
                </CardContent>
              </Card>
            )}
          />
        ))}
      </DndProvider>
      <Button variant={'text'} startIcon={<AddCircleIcon />} onClick={addSkill} color='primary'>
        Add Skill
      </Button>
    </div>
  );
}

Skills.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  readOnly: PropTypes.bool,
  setLoading: PropTypes.func,
  setCurrentValues: PropTypes.func,
  setSfpaData: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  getData: PropTypes.func
};

export default Skills;
