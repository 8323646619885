import React, { useEffect } from 'react';
//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import CustomDropdown from '../../CustomDropdown';
import { fetchPickLists } from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

const SourceSelection = props => {
  const { label = '', placeholder = '', onChange = () => {}, defaultValue, required = false, ...rest } = props;
  const contactSourceType = useSelector(state => state.commonReducer.contactSourceType);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!contactSourceType) {
      dispatch(fetchPickLists('CONTACT_SOURCE_TYPE', 'contactSourceType'));
    } else {
      setOptions(contactSourceType);
    }
  }, [contactSourceType, dispatch]);

  return <CustomDropdown {...rest} options={options} label={label} placeholder={placeholder} onChange={onChange} defaultValue={defaultValue} required={required} />;
};

SourceSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  required: PropTypes.bool
};

export default SourceSelection;
