import { ArrowDropDown } from '@mui/icons-material';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddToWorkbench from '../AddToWorkbench';
import { ButtonDropDown } from '../ButtonDropdown';

const TotalCount = props => {
  const { t } = useTranslation();
  const { showAddContactToWorkbench = false, selectedRows, selectedRowsCount, rowCount, isRowSelected, loadedContactCount, showTotalCount = true } = props;
  const [showContactWorkbenchPopup, setContactWorkbenchPopup] = useState(false);
  function handleOptionClick() {
    setContactWorkbenchPopup(true);
  }

  return (
    <Box component='div' className='d-flex align-items-center fs-14'>
      {showAddContactToWorkbench && (
        <ButtonDropDown
          disabled={selectedRows?.length === 0}
          buttonStyle={{
            fontWeight: 600,
            fontSize: 12,
            textTransform: 'capitalize',
            color: '#959fb7',
            border: '1px solid #959fb7',
            opacity: selectedRows?.length === 0 ? 0.5 : 1,
            padding: '0 0.5rem',
            backgroundColor: 'white',
            marginRight: '1rem'
          }}
          MenuItemStyle={{
            borderBottom: '1px solid black',
            fontSize: '12px',
            fontWeight: 600
          }}
          MenuStyle={{
            '& .MuiMenu-list': {
              padding: 0
            }
          }}
          show
          variant='outlined'
          options={['Add to Workbench']}
          buttonText={t('common.bulkActions')}
          onClick={handleOptionClick}
          iconRight={<ArrowDropDown />}
        />
      )}

      {isRowSelected ? (
        <>
          {t('common.count.selected')}
          <Box component='span' className='color-primary-main mx-1'>
            {selectedRowsCount}
          </Box>
          {t('common.count.outOf')} {rowCount}
        </>
      ) : (
        <>
          {' '}
          {showTotalCount
            ? `${t('common.count.totalCount')} : ${rowCount}`
            : loadedContactCount
              ? `${t('common.count.showing')} ${loadedContactCount} / ${rowCount}`
              : `${t('common.count.totalCount')} : ${rowCount}`}
        </>
      )}

      {showContactWorkbenchPopup && (
        <AddToWorkbench
          moduleIds={selectedRows?.map(item => item?.id)}
          handleClose={() => {
            setContactWorkbenchPopup(false);
          }}
          isPopupOpen={showContactWorkbenchPopup}
        />
      )}
    </Box>
  );
};

TotalCount.propTypes = {
  rowCount: PropTypes.number,
  isRowSelected: PropTypes.bool,
  selectedRowsCount: PropTypes.number,
  loadedContactCount: PropTypes.number,
  showTotalCount: PropTypes.bool,
  selectedRows: PropTypes.array,
  showAddContactToWorkbench: PropTypes.bool
};

export default TotalCount;
