import { KeyboardDatePicker } from '@material-ui/pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, IconButton, TextField } from '@mui/material';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import CustomButton from '../../../../components/common/CustomButton';
import CustomDropdown from '../../../../components/common/CustomDropdown';
import CustomInputField from '../../../../components/common/StyledComponents/CustomInputField';
import { ignWorkbenchQueryApi, picklistsDataApis } from '../../../../services/ApiService';
import { DELETE, GET, PUT } from '../../../../services/constantService';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import CustomModalWithHeader from '../../../Commons/CustomModalWithHeader';
import { WorkBenchContext } from '../../Context';

function UpdateQueryModal({ queryData, onClose, showModal }) {
  const { refetchFunc, refetch } = useContext(WorkBenchContext);
  const [queries, setQueries] = useState([]);
  const [description, setDescription] = useState('');
  const [workbenchType, setWorkbenchType] = useState([]);
  const [contactAttribute, setContactAttribute] = useState([]);
  const [projectAttribute, setProjectAttribute] = useState([]);
  const [operationType, setOperationType] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchDropDownValues();
        await fetchAttributeValues();

        if (queryData) {
          setQueries(queryData.ign_query_attributes || []);
          setDescription(queryData.description || '');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        enqueueSnackbar(error.message || 'Error fetching dropdown values', { variant: 'error' });
      }
    };

    fetchData();
  }, [queryData, refetch]);

  const fetchDropDownValues = async () => {
    try {
      const res = await picklistsDataApis(GET, 'WORKBENCH_TYPE');
      const queryOps = await picklistsDataApis(GET, 'QUERY_OPERATION');
      if (res?.data) setWorkbenchType(res.data);
      if (queryOps?.data) setOperationType(queryOps?.data);
    } catch (err) {
      enqueueSnackbar(err.message || 'Error fetching dropdown values', { variant: 'error' });
    }
  };

  const fetchAttributeValues = async () => {
    try {
      const res = await picklistsDataApis(GET, queryData.type?.toLowerCase()?.includes('contact') ? 'CONTACT_ATTRIBUTES' : 'PROJECT_ATTRIBUTES');
      if (res?.data) {
        queryData.type?.includes('contact') ? setContactAttribute(res.data) : setProjectAttribute(res.data);
      }
    } catch (err) {
      enqueueSnackbar(err.message || 'Error fetching attribute values', { variant: 'error' });
    }
  };

  const handleChange = (index, field, value) => {
    const updatedQueries = [...queries];
    updatedQueries[index][field] = value;
    setQueries(updatedQueries);
  };

  const handleDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const handleDeleteQuery = async index => {
    try {
      if (queries[index]?.id) {
        await ignWorkbenchQueryApi(DELETE, null, `/${queryData.id}/attribute/${queries[index].id}`);
      }
      setQueries(queries.filter((_, i) => i !== index));
      enqueueSnackbar('Attribute deleted successfully', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(err.message || 'Error deleting attribute', { variant: 'error' });
    }
  };

  const handleAddMore = () => {
    setQueries([...queries, { attribute: '', operation: '', value: '' }]);
  };

  const handleUpdateAll = async () => {
    try {
      const updatedData = {
        description: description,
        attributes: queries.map(query => ({
          id: query.id,
          attribute: query.attribute,
          operation: query.operation,
          value: query.value
        }))
      };
      await ignWorkbenchQueryApi(PUT, updatedData, `${queryData.id}`);
      enqueueSnackbar('Query updated successfully', { variant: 'success' });
      onClose();
      refetchFunc();
    } catch (err) {
      enqueueSnackbar(err.message || 'Error updating query', { variant: 'error' });
    }
  };
  const findDropdownKey = (currentValue, dropdownValue, fieldMatch) => {
    const matchedValue = dropdownValue.find(val => val[fieldMatch] === currentValue);
    return matchedValue;
  };

  const queryDropdown = queryData.type?.includes('contact') ? contactAttribute : projectAttribute;
  return (
    <CustomModalWithHeader label={t('Update Query')} closeIcon={true} isOpen={showModal} onClose={onClose} maxWidth='md'>
      <div style={{ marginTop: '24px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomDropdown label={t(`${TRANS_KEYS.LIST_TYPE}:key`)} options={workbenchType} value={queryData.type} fullWidth disabled />
          </Grid>

          <Grid item xs={12}>
            <TextField label={t('Description')} value={description} onChange={handleDescriptionChange} fullWidth variant='outlined' multiline rows={2} required />
          </Grid>

          {queries.map((query, index) => (
            <Grid container item spacing={2} key={index}>
              <Grid item xs={4}>
                <CustomDropdown
                  label={t('Attributes')}
                  options={queryData.type?.includes('contact') ? contactAttribute : projectAttribute}
                  value={findDropdownKey(query.attribute, queryDropdown, 'field_value')?.short_desc}
                  onChange={(e, value) => handleChange(index, 'attribute', value.field_value)}
                  required
                  className={'w-100'}
                />
              </Grid>
              <Grid item xs={3}>
                <CustomDropdown
                  label={t('Operations')}
                  options={operationType}
                  value={findDropdownKey(query.operation, operationType, 'long_desc')?.short_desc}
                  onChange={(e, value) => handleChange(index, 'operation', value.long_desc)}
                  required
                  className={'w-100'}
                />
              </Grid>
              <Grid item xs={4}>
                {query.attribute === 'projected_start_date' || query.attribute === 'start_date' ? (
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    format='MM/dd/yyyy'
                    label={t('Value')}
                    value={query.value || null}
                    onChange={newValue => handleChange(index, 'value', newValue)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    fullWidth
                    required
                  />
                ) : (
                  <CustomInputField label={t('Value')} value={query.value} onChange={e => handleChange(index, 'value', e.target.value)} fullWidth required />
                )}
              </Grid>
              {/* <Grid item xs={4}>
                <CustomInputField label={t('Value')} value={query.value} onChange={e => handleChange(index, 'value', e.target.value)} required />
              </Grid> */}
              <Grid item xs={1}>
                <IconButton aria-label='delete' onClick={() => handleDeleteQuery(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}

          <Grid item xs={12}>
            <div className='w-100 d-flex justify-content-end align-items-center mt-4'>
              <CustomButton type='tertiary-error' variant='text' buttonText={t(`${TRANS_KEYS.CONTACTS_CANCEL}:key`)} style={{ marginRight: '12px' }} onClick={onClose} />
              <CustomButton buttonText={t('Add More')} onClick={handleAddMore} style={{ marginRight: '12px' }} />
              <CustomButton type='btn btn-success' buttonText={t('Update')} onClick={handleUpdateAll} />
            </div>
          </Grid>
        </Grid>
      </div>
    </CustomModalWithHeader>
  );
}

UpdateQueryModal.propTypes = {
  queryData: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired
};

export default UpdateQueryModal;
