//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import EditBdPopup from './EditBdPopup';
import ViewBdPopup from './ViewBdPopup';
import { searchDataApi } from '../../services/ApiService';
import { GET, ERROR, FEE_TYPES } from '../../services/constantService';
import Loader from '../common/Loader';
import { unableMessage } from '../../services/MessageService';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrencyDetails } from '../../actions';
import { REFRESH_SEARCH } from '../../types';

function ViewBD(props) {
  const { onClose, bd, enqueueSnackbar } = props;
  const [tabValue, setTabValue] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDataUpdated] = useState(true);
  const [defaultValues, setDefaultValues] = useState({});
  const dispatch = useDispatch();
  const currencyCodeDetails = useSelector(state => (state.commonReducer[defaultValues?.currency] ? state.commonReducer[defaultValues?.currency] : {}));

  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(defaultValues?.currency, dispatch);
      } catch (e) {
        console.log('Error found in currencyHandler::', e);
      }
    };
    if (defaultValues?.currency && Object.keys(currencyCodeDetails)?.length === 0) {
      currencyHandler();
    }
  }, [defaultValues?.currency]);

  const { register, reset } = useForm({ defaultValues });

  const isFormInEditMode = editing => {
    setIsEditing(editing);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const bdbyId = async () => {
    try {
      if (bd.id) {
        setLoading(true);
        const { status, data } = await searchDataApi(GET, bd.id);
        if (status === 200) {
          let feeObj = {};
          if (!data.fee_type_desc || data.fee_type_desc === FEE_TYPES.ONE_THIRD) {
            feeObj = { fee_type_desc: FEE_TYPES.ONE_THIRD, fee_percentage: 33.33 };
          }
          setDefaultValues({
            ...data,
            intervals_desc: data.intervals_desc ? data.intervals_desc : data.intervals,
            indirect_fees_searches: data.indirect_fees_searches?.length ? data.indirect_fees_searches : [{}],
            estimated_percentage_bases: data.estimated_percentage_bases?.length ? data.estimated_percentage_bases : [{}],
            recruiters: data.recruiters?.length ? data.recruiters : [{}],
            researchers: data.researchers?.length ? data.researchers : [{}],
            basic_partners: data.partners ? data.partners.map(partner => partner.user)?.filter(user => user) : [],
            client_team: data.client_team ? data.client_team.map(client_team => client_team?.contact)?.filter(contact => contact) : [],
            eas: data.eas?.length ? data.eas : [{}],
            partners: data.partners ? data.partners : [],
            billing_desc: data.billing_desc ? data.billing_desc : '',
            bd_status: data.bd_status ? data.bd_status : 'Target Identified',
            ...feeObj
          });
        } else {
          const message = unableMessage('BD Details', 'fetch');
          enqueueSnackbar(data?.message || message, { variant: ERROR });
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    bdbyId();
  }, [bd]);

  // useEffect(() => {
  //   setDefaultValues({
  //     id: localBD.id,
  //     company: localBD.company,
  //     currency: localBD.currency,
  //     brand: localBD.brand,
  //     stage: localBD.stage,
  //     stage_desc: localBD.stage_desc,
  //     term_type: localBD.term_type,
  //     term_desc: localBD.term_desc,
  //     job_number: localBD.job_number,
  //     intervals: localBD.intervals,
  //     intervals_desc: localBD.intervals_desc ? localBD.intervals_desc : localBD.intervals,
  //     intervals_other: localBD.intervals_other,
  //     indirect_fees_searches: localBD.indirect_fees_searches?.length ? localBD.indirect_fees_searches : [{}],
  //     estimated_percentage_bases: localBD.estimated_percentage_bases?.length ? localBD.estimated_percentage_bases : [{}],
  //     job_title: localBD.job_title,
  //     fee_percentage: localBD.fee_percentage,
  //     billing_notes: localBD.billing_notes,
  //     billing_region: localBD.billing_region,
  //     projected_fee: localBD.projected_fee,
  //     country: localBD.country,
  //     probability: localBD.probability,
  //     recruiters: localBD.recruiters?.length ? localBD.recruiters : [{}],
  //     researchers: localBD.researchers?.length ? localBD.researchers : [{}],
  //     eas: localBD.eas?.length ? localBD.eas : [{}],
  //     partners: localBD.partners ? localBD.partners : [],
  //     basic_partners: localBD.partners ? localBD.partners.map(partner => partner.user)?.filter(user => user) : [],
  //     projected_start_date: localBD.projected_start_date,
  //     competition: localBD.competition,
  //     work_group: localBD.work_group,
  //     decision: localBD.decision,
  //     client_team: localBD.client_team ? localBD.client_team.map(client_team => client_team?.contact)?.filter(contact => contact) : [],
  //     is_fee_cap: localBD.is_fee_cap,
  //     fee_cap_amount: localBD.fee_cap_amount
  //   })
  // }, [localBD, setDefaultValues])

  useEffect(() => {
    register('company');
    register('currency');
    register('country');
    register('projected_start_date');
    register('partners');
    register('basic_partners');
    register('work_group');
    register('client_team');
    register('brand');
    register('stage');
    register('term_type');
    register('intervals');
  }, [register]);

  const onPopupClose = () => {
    if (defaultValues?.stage !== 'BD') {
      dispatch({ type: REFRESH_SEARCH, payload: true });
      //setTimeout(() => dispatch({ type: REFRESH_SEARCH, payload: false }), 300)
    }
    onClose(isDataUpdated);
  };

  return (
    <Popup open={props.visible} modal onClose={onPopupClose} className='bd-view-popup' closeOnDocumentClick={false} closeOnEscape={false}>
      <Loader show={loading} />
      {isEditing ? (
        <EditBdPopup editForm={isFormInEditMode} setDefaultValues={setDefaultValues} bd={defaultValues} tabValue={tabValue} setTabValue={setTabValue} closePopup={onPopupClose} />
      ) : (
        <ViewBdPopup
          key={defaultValues}
          isBdInViewMode={true}
          setTabValue={setTabValue}
          defaultValues={defaultValues}
          tabValue={tabValue}
          editForm={isFormInEditMode}
          bd={bd}
          closePopup={onPopupClose}
          currencyDetails={currencyCodeDetails}
        />
      )}
    </Popup>
  );
}

ViewBD.propTypes = {
  onClose: PropTypes.func,
  bd: PropTypes.object,
  visible: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func
};

export default ViewBD;
