import { CopyAllOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../components/common/CustomButton';
import DrawerComponent from '../../../../components/common/Drawer';
import Loader from '../../../../components/common/Loader';
import { messageTemplatesApi } from '../../../../services/ApiService';
import { CLIPBOARD_COPY_MESSAGE, MESSATE_TEMPLATE_VALIDATION } from '../../../../services/constantService';
import useMessageTemplateData from '../MessageTemplateData';
import '../index.scss';
import { updateText } from './utils';

const InitialFormState = {
  body: '',
  to: '',
  from: '',
  template_name: '',
  template_description: '',
  is_public: false
};

const AddSmsTemplate = props => {
  const { t } = useTranslation();
  const { isDrawerOpen, setIsDrawerOpen, templateData, currentTemplate, setCurrentTemplate, refetch } = props;

  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, setValue, register, unregister, watch, reset } = useForm({
    InitialFormState
  });

  const { messageTemplateData } = useMessageTemplateData();

  const [lastActiveElement, setLastActiveElement] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [textLength, setTextLength] = React.useState(0);

  const toRef = useRef(null);
  const fromRef = useRef(null);
  const bodyRef = useRef(null);

  useEffect(() => {
    register('body');
    register('to');
    register('from');
    register('template_name');
    register('template_description');
    register('is_public');

    return () => {
      unregister('body');
      unregister('to');
      unregister('from');
      unregister('template_name');
      unregister('template_description');
      unregister('is_public');

      // Reset the form
      reset(InitialFormState);
    };
  }, [register]);

  useEffect(() => {
    if (templateData?.data?.data && currentTemplate === templateData?.data?.data?.id) {
      const autofillFormData = templateData?.data?.data;

      setValue('body', autofillFormData.body);
      setValue('to', autofillFormData.to);
      setValue('from', autofillFormData.from);
      setValue('template_name', autofillFormData.title);
      setValue('template_description', autofillFormData.description);
      setValue('is_public', autofillFormData.is_public);
      setTextLength(autofillFormData.body.length);
    }
  }, [templateData]);

  const handleInsertText = async textToInsert => {
    switch (lastActiveElement) {
      case 'to':
        updateText(textToInsert, toRef, 'to', setValue);
        break;

      case 'from':
        updateText(textToInsert, fromRef, 'from', setValue);
        break;

      default:
        try {
          await navigator.clipboard.writeText(textToInsert);
          enqueueSnackbar(CLIPBOARD_COPY_MESSAGE.SUCCESS, {
            variant: 'success'
          });
        } catch (error) {
          console.error('Failed to copy:', error);
          enqueueSnackbar(CLIPBOARD_COPY_MESSAGE.FAILED, { variant: 'error' });
        }
        break;
    }
  };

  const handleSubmitForm = async formData => {
    setLoading(true);

    const requestBody = {
      body: bodyRef.current.value,
      to: formData.to,
      from: formData.from,
      template_name: formData.template_name,
      template_description: formData.template_description,
      language_code: 'en',
      is_public: formData.is_public
    };

    if (currentTemplate) {
      await messageTemplatesApi('put', `sms/${currentTemplate}`, requestBody);
    } else {
      await messageTemplatesApi('post', 'sms', requestBody);
    }

    setIsDrawerOpen(false);
    setLoading(false);

    // Reset the form
    reset(InitialFormState);
    await refetch();
    enqueueSnackbar(MESSATE_TEMPLATE_VALIDATION.SUCCESS, {
      variant: 'success'
    });
  };

  return (
    <Box id='add-sms-template-drawer'>
      <DrawerComponent
        id={'sms-templates'}
        anchor={'right'}
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setCurrentTemplate(null);

          // Reset the form
          reset(InitialFormState);
        }}
        width='80vw'
      >
        <Loader show={loading || templateData?.loading} />

        <Box
          sx={{
            display: 'flex',
            margin: '1rem'
          }}
        >
          <Box
            sx={{
              rowGap: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              width: '75%'
            }}
          >
            <Box>
              <Typography>Template Name</Typography>
              <TextField
                id='inputTextStyle'
                fullWidth
                hiddenLabel
                variant='outlined'
                disabled={currentTemplate !== null}
                onFocus={() => setLastActiveElement('template_name')}
                value={watch('template_name') || ''}
                onChange={e => {
                  setValue('template_name', e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography>Template Description</Typography>
              <TextField
                id='inputTextStyle'
                fullWidth
                hiddenLabel
                variant='outlined'
                onFocus={() => setLastActiveElement('template_description')}
                value={watch('template_description') || ''}
                onChange={e => {
                  setValue('template_description', e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography>To</Typography>
              <TextField
                id='inputTextStyle'
                fullWidth
                hiddenLabel
                variant='outlined'
                onFocus={() => setLastActiveElement('to')}
                inputRef={toRef}
                value={watch('to') || ''}
                onChange={e => {
                  setValue('to', e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography>From</Typography>
              <TextField
                id='inputTextStyle'
                fullWidth
                hiddenLabel
                variant='outlined'
                onFocus={() => setLastActiveElement('from')}
                inputRef={fromRef}
                value={watch('from') || ''}
                onChange={e => {
                  setValue('from', e.target.value);
                }}
              />
            </Box>
            <Box>
              <Typography>Body</Typography>
              <TextField
                onFocus={() => setLastActiveElement('body')}
                inputRef={bodyRef}
                onChange={e => {
                  if (e.target.value.length > 160) return;
                  setValue('body', e.target.value);
                  setTextLength(e.target.value.length);
                }}
                value={watch('body') || ''}
                multiline
                fullWidth
                className='bg-white'
              />
            </Box>
            <Box className='message-template-footer-submit'>
              <Box
                sx={{
                  display: 'inline-flex',
                  gap: '0.5rem'
                }}
              >
                <Checkbox
                  sx={{
                    padding: '0'
                  }}
                  checked={watch('is_public') || false}
                  onChange={e => {
                    setValue('is_public', e.target.checked);
                  }}
                />
                <Typography>{t('templates.makePublic')}</Typography>
              </Box>
              <Typography className='text-muted'>{textLength} / 160</Typography>
              <Box
                sx={{
                  display: 'inline-flex',
                  gap: '0.5rem'
                }}
              >
                <CustomButton
                  type='tertiary-error'
                  buttonText={t('actions.cancel')}
                  onClick={() => {
                    setIsDrawerOpen(false);
                    setCurrentTemplate(null);

                    // Reset the form
                    reset(InitialFormState);
                  }}
                />

                <CustomButton buttonText={t('actions.save')} onClick={handleSubmit(handleSubmitForm)} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: '25%',
              textAlign: 'left',
              padding: '0 1rem'
            }}
          >
            <Typography className='pb-3'>{t('templates.availableOptions')}</Typography>

            {messageTemplateData.map(option => {
              return (
                <Accordion key={option.header}>
                  <AccordionSummary>
                    <Typography
                      sx={{
                        fontWeight: 'bold'
                      }}
                    >
                      {option.header /* This is the header for each accordion */}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'left'
                    }}
                  >
                    {option.values.map((option, index) => (
                      <Typography
                        onClick={() => {
                          handleInsertText(option.value);
                        }}
                        className='mb-2 cursor-pointer'
                        width='100%'
                        key={index}
                      >
                        {option.name} <CopyAllOutlined />
                      </Typography>
                    ))}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Box>
      </DrawerComponent>
    </Box>
  );
};

AddSmsTemplate.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.string,
  isDrawerOpen: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func,
  templateData: PropTypes.object,
  currentTemplate: PropTypes.string,
  setCurrentTemplate: PropTypes.func,
  refetch: PropTypes.func
};

export default AddSmsTemplate;
