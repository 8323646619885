import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const RegionSelection = props => {
  const dispatch = useDispatch();
  const billingRegion = useSelector(state => state.commonReducer.billingRegion);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!billingRegion) {
      dispatch(fetchPickLists('BILLING_REGION', 'billingRegion'));
    } else {
      setOptions(billingRegion);
    }
  }, [billingRegion, dispatch]);

  const { className = 'SingleSelectStyle', onChange = () => {}, isDrawer = false, label = '', defaultValue, required = false, viewAddress, isDisabled = false, ...rest } = props;
  // const regionOptions = [
  //   { label: "North America", value: "north america" },
  //   { label: "Latin America", value: "latin america" },
  //   { label: "Asia", value: "asia" },
  // ];

  return (
    <>
      <CustomDropdown
        {...rest}
        disabled={isDisabled}
        label={label}
        options={options}
        className={className}
        onChange={onChange}
        isDrawer={isDrawer}
        defaultValue={defaultValue}
        required={required}
        viewAddress={viewAddress}
      />
    </>
  );
};

RegionSelection.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  isDrawer: PropTypes.bool,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  viewAddress: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default RegionSelection;
