//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { Link } from '@mui/material';

const getCompanyName = params => (params.value ? params.value.name : '');
export const columnsDef = [
  {
    field: 'job_number',
    colId: 'job_number',
    headerName: 'Job Number',
    tooltipField: 'job_number',
    cellRenderer: 'IdRenderer',
    checkboxSelection: true
  },
  {
    field: 'job_title',
    colId: 'job_title',
    tooltipField: 'job_title',
    headerName: 'Search Title'
  },
  {
    field: 'company',
    colId: 'company',
    tooltipValueGetter: getCompanyName,
    headerName: 'Company Name',
    cellRenderer: getCompanyName
  }
];

export const IdRenderer = params => {
  return params.data ? (
    <Link className='text-link' color='primary' target='_blank' href={`/searches/${params?.data?.id}/active-candidates`}>
      {params.value}
    </Link>
  ) : (
    ''
  );
};
