//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';

export default function ErrorPopup(props) {
  const { header = 'This company may already exist in Galaxy, please review before saving', viewButtonText = 'View/Edit Existing Companies', onContinue, onCancel, onView } = props;

  const handleView = () => {
    onView && onView();
  };

  const handleContinue = () => {
    onContinue && onContinue();
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <Popup overlayStyle={{ zIndex: 1000 }} open={true} modal closeOnDocumentClick={false} closeOnEscape={false} className='confirmation-popup'>
      <div style={{ maxWidth: 651 }}>
        <div className='confirmation-content d-flex align-items-center'>
          <div className='confirmation-header mr-3' style={{ fontSize: 18 }}>
            {header}
          </div>
          <span className='close-icon d-flex align-items-center' onClick={handleCancel}>
            <CloseIcon fontSize='inherit' />
          </span>
        </div>
        <div className='action-buttons d-flex justify-content-center'>
          <Button style={{ maxWidth: 175 }} variant='contained' color='secondary' className='mr-3' onClick={handleView}>
            {viewButtonText}
          </Button>
          <Button style={{ minWidth: 175 }} variant='contained' className='button' color='secondary' onClick={handleContinue}>
            Create
          </Button>
        </div>
        <div className='action-buttons d-flex justify-content-center mb-4'>
          <Button variant='contained' style={{ background: 'red', color: '#F3F4F5' }} className='mr-3' onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Popup>
  );
}

ErrorPopup.propTypes = {
  header: PropTypes.string,
  onCancel: PropTypes.func,
  onView: PropTypes.func,
  onContinue: PropTypes.func,
  viewButtonText: PropTypes.string
};
