//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Typography, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import RichTextPopup from '../../../../RichTextPopup';
import { searchDataApi } from '../../../../../services/ApiService';
import { SUCCESS, ERROR, PATCH } from '../../../../../services/constantService';
import { unableMessage, successMessage, VALIDATION_MESSAGE } from '../../../../../services/MessageService';
function ProfessionalExperience(props) {
  const { register, setValue, currentValues, searchInfo, removeHtmlTags, sfpa, setSfpaData } = props;
  if (currentValues.professional_experience === undefined) {
    currentValues.professional_experience = sfpa.professional_experience;
  }
  const { enqueueSnackbar } = useSnackbar();
  const [editorHtml, setEditorHtml] = useState(currentValues.professional_experience);
  const [isLastSaveSuccess, setLastSaveSuccess] = useState(true);
  useEffect(() => {
    register('professional_experience');
    setEditorHtml(currentValues.professional_experience);
  }, [currentValues.professional_experience, register]);

  useEffect(() => {
    setValue('professional_experience', editorHtml);
  }, [editorHtml, setValue]);

  const saveProfessionalExperience = async (payload, skipMessage, setAutoSaveLoading) => {
    const plainText = removeHtmlTags(payload.professional_experience);
    let returnResult;
    if (plainText?.length === 0) {
      payload.professional_experience = plainText;
    }
    if (setAutoSaveLoading) {
      setAutoSaveLoading(true);
    }
    const { status, data } = await searchDataApi(PATCH, searchInfo.id, payload, 'search-autosave-field');
    if (status && status === 200) {
      payload.updated_at = data.result.updated_at;
      setSfpaData(payload);
      const message = successMessage('Professional Experience', VALIDATION_MESSAGE.updated_message);
      !skipMessage && enqueueSnackbar(message, { variant: SUCCESS });
      setLastSaveSuccess(true);
      returnResult = true;
    } else {
      const message = unableMessage('Professional Experience', 'update');
      enqueueSnackbar(data.message || message, { variant: ERROR });
      setLastSaveSuccess(false);
      returnResult = false;
    }
    if (setAutoSaveLoading) {
      setTimeout(() => {
        setAutoSaveLoading(false);
      }, 500);
    }
    return returnResult;
  };

  return (
    <div
      style={{
        marginTop: '20px',
        marginLeft: '20px',
        marginBottom: '0px',
        padding: '0px'
      }}
    >
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{ float: 'left', fontWeight: 'bold' }}>Professional Experience</Typography>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className='text-view-value p-0 w-100'>
            <div className='outlined-border transform p-0'>
              <RichTextPopup
                onChange={setEditorHtml}
                value={editorHtml}
                onSave={saveProfessionalExperience}
                title='Professional Experience'
                name='professional_experience'
                autoSave={saveProfessionalExperience}
                saveStatus={isLastSaveSuccess}
                updatedDate={currentValues.updated_at}
                restrictHeight={true}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
ProfessionalExperience.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  sfpa: PropTypes.func,
  currentValues: PropTypes.object,
  searchInfo: PropTypes.object,
  removeHtmlTags: PropTypes.func,
  setSfpaData: PropTypes.func
};
export default ProfessionalExperience;
