//import { Auth } from 'aws-amplify';
export const isLoggedInUserAdmin = () => {
  try {
    //TODO: Confirm with Dipesh
    // debugger
    // const cognitoUser = await (await Auth.currentSession())
    // const userGroups = cognitoUser.getIdToken().payload["cognito:groups"];
    // return userGroups.includes("admin");
    return JSON.parse(localStorage.getItem('isAdmin'));
  } catch (e) {
    console.log('Error found in isLoggedInUserAdmin::', e);
    return false;
  }
};

export const getAccessToken = async () => {
  try {
    return localStorage.getItem('token');
  } catch (e) {
    console.log('Error found in getAccessToken::', e);
    clearAuthFromLocalStorage();
  }
};

export const clearAuthFromLocalStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('isAdmin');
  localStorage.removeItem('env');
  localStorage.removeItem('refresh_token');
};
