import React, { useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FormControlLabel, Switch, InputLabel } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../../../common/InputField';
// import ContactSelection from '../../../../ContactSelection/ContactSelection';
// import InvoiceDeliveryTypeSelection from '../../../../InvoiceDeliveryTypeSelection';
// import { determineInitialCountryGuess } from "../../../../../utils/phone";
import RegionSelection from '../../../../AddUser/components/RegionSelection';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FeetypeSelection from '../../../../FeetypeSelection';
import RichTextPopup from '../../../../RichTextPopup';

export default function BillingInformation(props) {
  const { register, watch, setValue, isEditing = true, currentValues } = props;
  useEffect(() => {
    if (isEditing) {
      const field = ['billable', 'billing_region', 'contact_name', 'billing_notes', 'zip_code', 'mobile_phone', 'work_email', 'is_po_direct', 'invoice_delivery_type', 'po_number', 'billing_notes'];
      field.forEach(field => setValue(field, currentValues[field]));
    }
  }, []);
  useEffect(() => {
    register('invoice_delivery_type');
    register('contact_name');
    register('vendor_portal_url');
    register('vendor_portal');
    register('billing_notes');
  }, [register]);
  // const handleChange = (e) => {
  //   setValue(`${e.target.name}`, e.target.value)
  // }

  return (
    <Accordion defaultExpanded={true} className='panel-bottom-margin' style={{ background: '#fff', color: '#fff' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />} aria-controls='panel1a-content' style={{ background: '#2a364c' }}>
        <div>Billing Information</div>
      </AccordionSummary>
      <AccordionDetails className='d-flex flex-column'>
        <div className='d-flex contact-details-row'>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Billable</InputLabel>
            <div className='page-label-value'>
              <FormControlLabel
                className='input-form-field checkbox-width'
                disabled={!isEditing}
                style={{ color: '#5c52c6', justifyContent: 'flex-end' }}
                control={
                  <Switch
                    checked={watch('billable') || false}
                    onChange={e => {
                      setValue('billable', e.target.checked);
                    }}
                    color='primary'
                    {...register('billable')}
                    name={'billable'}
                  />
                }
                label=''
                labelPlacement='start'
              />
            </div>
          </div>
          <div className='d-flex intersection-page-view'>
            <InputLabel required className='page-label'>
              Billing Region
            </InputLabel>
            <div className='page-label-value'>
              <RegionSelection
                className='input-form-field'
                defaultValue={watch('billing_region') || null}
                {...register('billing_region')}
                label={''}
                InputLabelProps={{ focused: true }}
                disabled={!isEditing}
                onChange={(e, data) => {
                  setValue('billing_region', data ? data.name : '');
                }}
                variant='outlined'
              />
            </div>
          </div>
        </div>
        <div className='d-flex contact-details-row'>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>PO Required</InputLabel>
            <div className='page-label-value'>
              <FormControlLabel
                className='input-form-field checkbox-width'
                disabled={!isEditing}
                style={{ color: '#5c52c6', justifyContent: 'flex-end' }}
                control={
                  <Switch
                    checked={watch('is_po_direct') || false}
                    onChange={e => {
                      setValue('is_po_direct', e.target.checked);
                    }}
                    color='primary'
                    {...register('is_po_direct')}
                    name={'is_po_direct'}
                  />
                }
                label=''
                labelPlacement='start'
              />
            </div>
          </div>
          {watch('is_po_direct') ? (
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>PO</InputLabel>
              <div className='page-label-value'>
                <InputField
                  {...register('po_number')}
                  disabled={!isEditing}
                  className='input-form-field'
                  label=''
                  placeholder='PO'
                  InputLabelProps={{ focused: true }}
                  variant='outlined'
                  name='po_number'
                />
              </div>
            </div>
          ) : (
            <span className='search-details'>&nbsp;</span>
          )}

          {/* <ContactSelection
          className='input-form-field'
          label={`Billing Contact Name`}
          ref={register(`contact_name`)}
          defaultValue={watch('contact_name') || null}
          disabled={!isEditing}
          InputLabelProps={{ focused: true }}
          onChange={(e, data) => {
            let contact = data || {};
            setValue('contact_name', data)
            setValue("mobile_phone", contact.mobile_phone || null)
            setValue("work_email", contact.work_email || null)
          }}
        />
        <div className='input-form-field'>
          <ReactPhoneInput
            ref={register('mobile_phone')}
            placeholder='Phone Number'
            value={watch('mobile_phone') || ''}
            disabled={!isEditing}
            onChange={(data, c, e, value) => {
              if (data?.length > 1) {
                setValue('mobile_phone', value)
              }
              else {
                setValue('mobile_phone', '')
              }
            }}
            enableSearch={true}
            disableInitialCountryGuess={determineInitialCountryGuess(watch('mobile_phone'))}
            inputProps={{
              label: 'Phone Number'
            }}
            component={TextField}
          />
        </div> */}
        </div>
        {/* <div className="d-flex flex-grow-1">
        <InputField
          {...register('')}
          disabled={!isEditing}
          className="input-form-field w-50"
          value={watch('work_email') || ''}
          onChange={handleChange}
          label="Email"
          placeholder='Email'
          InputLabelProps={{ focused: true }}
          name="work_email"
        />
      </div> */}
        <div className='search-details-billing d-flex'>
          <InputLabel className='contact-view-label'>Billing Address</InputLabel>
          <div className='contact-view-value h-overflow'>
            <InputField
              {...register('billing_address')}
              disabled={!isEditing}
              multiline
              rowsMax={3}
              className='input-form-field'
              label=''
              placeholder='Billing Address'
              InputLabelProps={{ focused: true }}
              name='billing_address'
              variant='outlined'
            />
          </div>
        </div>

        {/* <div className="d-flex">
        <InputField
          {...register('')}
          //value={watch('zip_code') || null}
          className='input-form-field'
          disabled={!isEditing}
          label='Zip Code'
          placeholder='Zip Code'
          InputLabelProps={{ focused: true }}
          name='zip_code'
        />
        <InvoiceDeliveryTypeSelection
          value={watch('invoice_delivery_type') || null}
          className="input-form-field"
          InputLabelProps={{ focused: true }}
          disabled={!isEditing}
          onChange={(e, data) => {
            setValue("invoice_delivery_type", data ? data.name : null);
          }}
        />
      </div> */}
        {/* <div className="d-flex flex-grow-1">
        <FormControlLabel
          className="input-form-field"
          disabled={!isEditing}
          style={{ color: '#5c52c6', margin: '10px 0 0', justifyContent: 'flex-end' }}
          control={<Switch checked={watch('is_po_direct') || false} onChange={(e) => { setValue('is_po_direct', e.target.checked); }} color='primary' inputRef={register('is_po_direct')} name={'is_po_direct'} />}
          label="PO Required"
          labelPlacement="start"
        />
        {watch('is_po_direct') &&
          <InputField {...register('')}
            disabled={!isEditing}
            className="input-form-field"
            label="PO"
            placeholder="PO"
            InputLabelProps={{ focused: true }}
            name="po_number" />}
      </div> */}
        <div className='d-flex pt-3'>
          <div className='d-flex search-details-billing'>
            <div className='col-3 pl-0 text-right'>
              <InputLabel className='contact-view-label mr-5'>Billing Notes</InputLabel>
            </div>
            <div className='outlined-box rich-text-field transform margin-box'>
              {/* <InputField
                  {...register('')}
                  //defaultValue={watch('billing_notes') || ''}
                  className="input-form-field"
                  disabled={!isEditing}
                  label=""
                  placeholder='Billing Notes'
                  InputLabelProps={{ focused: true }}
                  name="billing_notes"
                  variant='outlined'
                /> */}
              <RichTextPopup
                className='input-form-field'
                label='Billing Notes'
                title='Billing Notes'
                {...register('billing_notes')}
                placeholder='Billing Notes'
                InputLabelProps={{ focused: true }}
                name='billing_notes'
                variant='outlined'
                value={watch('billing_notes') || ''}
                onSave={data => setValue('billing_notes', data.billing_notes)}
                strengthsAndPotentialIssues={true}
              />
            </div>
          </div>
        </div>
        <div className='d-flex contact-details-row'>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Email Invoice to</InputLabel>
            <div className='page-label-value'>
              <InputField
                {...register('email_invoice_to')}
                className='input-form-field'
                disabled={!isEditing}
                label=''
                placeholder='email addresses'
                InputLabelProps={{ focused: true }}
                name='email_invoice_to'
                variant='outlined'
                autocapitalize='off'
              />
            </div>
          </div>
        </div>
        <div className='d-flex contact-details-row'>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Vendor Portal</InputLabel>
            <div className='page-label-value'>
              <FeetypeSelection
                variant='outlined'
                className='input-form-field'
                value={watch('vendor_desc') || null}
                {...register('vendor_desc')}
                InputLabelProps={{ focused: true }}
                type='VENDOR_PORTAL'
                onChange={(e, data) => {
                  // if (data?.field_value !== 'Others') {
                  //   setValue('intervals_other', '');
                  // }
                  setValue('vendor_portal', data ? data.field_value : null);
                  setValue('vendor_desc', data ? data.short_desc : null);
                  setValue('vendor_portal_url', data ? data.long_desc : null);
                }}
                label=''
              />
            </div>
          </div>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Portal URL </InputLabel>
            <span className='page-label-value'>
              <a
                href={`${watch('vendor_portal_url')}`}
                rel='noopener noreferrer'
                target='_blank'
                className={watch('vendor_portal_url') ? 'text-lowercase action-text company-redirect-link' : 'contact-view-label'}
              >
                {watch('vendor_portal_url') || '--'}
              </a>
            </span>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

BillingInformation.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  isEditing: PropTypes.bool,
  currencyDetails: PropTypes.object,
  currentValues: PropTypes.object
};
