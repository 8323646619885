//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';
//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const options = ['Starting Line', 'Track One', 'Track Two', 'Final Lap', 'Finish Line', 'Disqualified'];

function ArenaStageSelection(props) {
  const { className = 'w-100', label = 'Arena Stage', placeholder = '', InputLabelProps, inputRef, required = false, ...rest } = props;

  return (
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      renderInput={params => <TextField {...params} required={required} inputRef={inputRef} InputLabelProps={InputLabelProps} label={label} placeholder={placeholder} />}
    />
  );
}

ArenaStageSelection.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  inputRef: PropTypes.object
};

export default ArenaStageSelection;
