//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { ERROR, GET } from '../../../services/constantService';
import { userDataApi } from '../../../services/ApiService';
import { unableMessage } from '../../../services/MessageService';
import { useSnackbar } from 'notistack';

function Reference(props) {
  const { className = 'w-100', label, required = false, InputLabelProps, placeholder = 'Referred By', ...rest } = props;
  const enqueueSnackbar = useSnackbar();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const { status, data } = await userDataApi(GET);
      if (status === 200) {
        setOptions(data.data);
      } else {
        const message = unableMessage('users', 'fetch');
        enqueueSnackbar(message, { variant: ERROR });
      }
    };

    fetchUsers().then(null);
  }, [enqueueSnackbar]);

  return (
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      getOptionLabel={option => `${option.first_name || ''} ${option.last_name || ''}`}
      getOptionSelected={(option, value) => option.id === value.id}
      renderInput={params => <TextField required={required} {...params} InputLabelProps={InputLabelProps} label={label} placeholder={placeholder} />}
    />
  );
}

Reference.propTypes = {
  register: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool
};

export default Reference;
