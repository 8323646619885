import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import PersonIcon from '@mui/icons-material/Person';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { Box, Button, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import './style.module.scss';
import moment from 'moment';
const status = {
  DRAFT: {
    color: 'warning',
    text: 'Draft'
  },
  IN_PROGRESS: {
    color: 'primary',
    text: 'In progress'
  },
  COMPLETED: {
    color: 'success',
    text: 'Completed'
  },
  CANCELED: {
    color: 'error',
    text: 'Canceled'
  }
};
function LOEHeader({ search, setSearch, setOpen }) {
  return (
    <Grid container width={'100%'} spacing={2} justifyContent='space-between' className='fs-12'>
      <Grid item xs={3}>
        {/* <TextField
          fullWidth
          label='Search'
          value={search}
          onChange={e => setSearch(e.target.value)}
          size='small'
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            )
          }}
        /> */}
      </Grid>
      <Grid item xs={9}>
        <Stack direction={'row'} justifyContent={'flex-end'} gap={'12px'} width={'100%'}>
          {/* <Button variant='outlined' color='primary' maxWidth={'150px'} className='fs-12'>
            in progress
          </Button>

          <Button variant='outlined' color='success' maxWidth={'150px'} className='fs-12'>
            Completed
          </Button>

          <Button variant='outlined' color='error' maxWidth={'150px'} className='fs-12'>
            Canceled
          </Button> */}
          <Divider orientation='vertical' flexItem />
          <Button
            variant='contained'
            color='primary'
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setOpen(true)}
            maxWidth={'150px'}
            className='fs-12'
            sx={{
              fontWeight: 'bold'
            }}
          >
            Create LOE
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}
function LOEList({ list, onDeleteLOE, selectedLOE, setSelected, downloadLOE, onEdit, onAdd, openCreateLOE, setOpenCreateLOE }) {
  return (
    <Stack
      direction={'column'}
      gap={'12px'}
      sx={{
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        p: '12px',
        pl: '1px',
        pt: '1px'
      }}
    >
      {list?.map((loe, index) => (
        <LOEItem
          key={index}
          id={loe?.instance_id || loe?.id}
          name={`${loe?.user?.first_name || ''} ${loe?.user?.last_name || ''}`}
          consultantEmail={`${loe?.consultant_email || ''}`}
          consultantName={`${loe?.consultant_name || ''}`}
          clientName={`${loe?.client_first_name || ''} ${loe?.client_last_name || ''}`}
          clientEmail={`${loe?.client_email || ''}`}
          time={loe?.created_at || ''}
          status={loe?.status || 'IN_PROGRESS'}
          onDelete={() => onDeleteLOE(loe?.id)}
          downloadLOE={() => downloadLOE(loe?.id)}
          onEdit={() => onEdit(loe?.id, loe)}
          onAdd={() => onAdd(loe?.id)}
          selected={selectedLOE?.id === (loe?.instance_id || loe?.id)}
          setSelected={() => setSelected(loe)}
        />
      ))}
      {list?.length === 0 && (
        <Stack gap={1} p={'16px'} justifyContent={'center'} pt={'24px'}>
          <Typography variant='h6' className='fs-14'>
            Welcome To LOE
          </Typography>
          <Typography variant='body1' className='fs-12' color={'gray'}>
            {"It looks like you don't have any LOE yet. Click on the Create LOE button and get started."}
          </Typography>
          <Button
            variant='contained'
            color='primary'
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setOpenCreateLOE(true)}
            maxWidth={'150px'}
            className='fs-12'
            sx={{
              fontWeight: 'bold',
              maxWidth: '150px'
            }}
          >
            Create LOE
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
function LOEDetails({ selectedLOE }) {
  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 0px 1px;',
        borderRadius: '4px',
        minHeight: '100%'
      }}
    >
      <Grid
        container
        alignItems={'center'}
        sx={{
          padding: '8px 0',
          borderBottom: '1px solid #e0e0e0'
        }}
      >
        <Grid
          item
          xs={1}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <UpdateOutlinedIcon fontSize='small' />
        </Grid>
        <Grid item xs={11}>
          <Typography variant='body1' className='fs-12' fontWeight='600'>
            Activity History
          </Typography>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={11}>
          <Typography variant='body2' className='fs-10' color={'#9e9e9e'}>
            {selectedLOE?.id || ''}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems={'center'}
        sx={{
          width: '100%',
          // height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          overflowY: 'auto'
        }}
      >
        {selectedLOE?.list?.map((item, index) => (
          <>
            <Grid item xs={0.5}></Grid>
            <Grid
              item
              xs={11.5}
              sx={{
                width: '100%',
                height: '60px',
                borderLeft: '1px solid #e0e0e0',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '28px',
                position: 'relative'
              }}
              key={index}
            >
              <Typography variant='body1' className='fs-12' fontWeight='600'>
                {item?.name || ''}
              </Typography>
              <Typography variant='body1' className='fs-10' color={'gray'}>
                {/* format hh:mm a DD (th)/{month in short}/YYYY */}

                {moment(item?.time || '').format('hh:mm A Do MMM, YYYY')}
              </Typography>
              <Box
                sx={{
                  position: 'absolute',
                  left: '-7px',
                  top: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: 'white',
                  borderRadius: '50%',
                  color: '#afafaf',

                  boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px'
                }}
              >
                <KeyboardArrowDownOutlinedIcon fontSize='12px' />
              </Box>
            </Grid>
          </>
        ))}
      </Grid>
    </Stack>
  );
}

const LOEItem = item => {
  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent={'space-between'}
      alignContent={'space-between'}
      sx={{
        border: item.selected ? '1px solid  #e0e0e0' : 'none',
        boxShadow: item.selected ? 'rgba(0, 0, 0, 0.16) 0px 1px 4px' : 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
        transition: 'all 0.3s',
        borderRadius: '4px',
        padding: '20px',

        minHeight: '170px',
        width: '100%'
      }}
      onClick={() => item.setSelected(item)}
    >
      <Grid item xs={12} height={'20%'} maxHeight={'20px'}>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            <Button
              variant='contained'
              className='fs-8'
              color={status[item?.status]?.color || ''}
              sx={{
                borderRadius: '24px',
                padding: '2px 8px',
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none'
                }
              }}
              fontSize={{
                xs: '8px',
                md: '10px',
                lg: '14px'
              }}
              onClick={() => {}}
            >
              {status[item?.status]?.text || ''}
            </Button>
            <Typography variant='body1' className='fs-10'>
              Generated By - {item?.name || ''}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
              <Typography variant='body1' className='fs-10'></Typography>
              <Typography
                variant='body1'
                className='fs-10'
                color={'gray'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '2px'
                }}
              >
                <WatchLaterIcon fontSize='10px' />
                {/* in dd/mm/yyyy format */}
                {moment(item?.time || '').format('hh:mm A Do MMM, YYYY')}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} height={'60%'} maxHeight={'90px'}>
        <Grid container alignItems={'flex-end'} justifyContent={'space-between'} height={'100%'}>
          <Grid item xs={4.5}>
            <Stack
              direction={'column'}
              justifyContent={'flex-end'}
              alignItems={'flex-start'}
              width={'100%'}
              sx={{
                height: '100%',
                gap: '4px',
                borderLeft: '1px solid #e0e0e0',
                paddingLeft: '12px'
              }}
            >
              <Typography variant='body1' className='fs-10' color={'gray'} textTransform={'capitalize'}>
                Consultant
              </Typography>
              <Tooltip title={item?.consultantName || ''} placement='top'>
                <Typography
                  variant='body1'
                  className='fs-12'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100%'
                  }}
                >
                  <PersonIcon
                    fontSize='16px'
                    sx={{
                      color: '#9e9e9e'
                    }}
                  />
                  <Box
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '-webkit-fill-available'
                    }}
                  >
                    {item?.consultantName}
                  </Box>
                </Typography>
              </Tooltip>
              <Tooltip
                title={item?.consultantEmail || ''}
                placement='top'
                sx={{
                  width: '100%'
                }}
              >
                <Typography
                  variant='body1'
                  className='fs-12'
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '8px',
                    width: '100%'
                  }}
                >
                  <MarkunreadIcon
                    fontSize='16px'
                    sx={{
                      color: '#9e9e9e'
                    }}
                  />

                  <Box
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '-webkit-fill-available'
                    }}
                  >
                    {item?.consultantEmail}
                  </Box>
                </Typography>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={4.5}>
            <Stack
              direction={'column'}
              justifyContent={'flex-end'}
              alignItems={'flex-start'}
              width={'100%'}
              sx={{
                height: '100%',
                gap: '4px',
                borderLeft: '1px solid #e0e0e0',

                paddingLeft: '12px'
              }}
            >
              <Typography variant='body1' className='fs-10' color={'gray'} textTransform={'capitalize'}>
                Client
              </Typography>
              <Tooltip title={item?.clientName || ''} placement='top'>
                <Typography
                  // variant='body1'
                  className='fs-12'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    width: '100%'
                  }}
                >
                  <PersonIcon
                    fontSize='16px'
                    sx={{
                      color: '#9e9e9e'
                    }}
                  />
                  <Box
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '-webkit-fill-available'
                    }}
                  >
                    {item?.clientName}
                  </Box>
                </Typography>
              </Tooltip>
              <Typography
                variant='body1'
                className='fs-12'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  width: '100%'
                }}
              >
                <MarkunreadIcon
                  fontSize='16px'
                  sx={{
                    color: '#9e9e9e'
                  }}
                />
                <Tooltip title={item?.clientEmail || ''} placement='top'>
                  <Box
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '-webkit-fill-available'
                    }}
                  >
                    {item?.clientEmail}
                  </Box>
                </Tooltip>
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: 'flex',
              height: '100%',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              gap: '8px',
              fontSize: '24px'
            }}
          >
            <Button
              variant='text'
              sx={{
                width: '20px',
                height: '20px',
                padding: '0px',
                minWidth: '20px',
                minHeight: '20px',

                '&:hover': {
                  background: 'rgba(0,0,0,0.05)'
                }
              }}
              onClick={item?.onDelete}
              disabled={item?.status === 'IN_PROGRESS' ? false : true}
              color='error'
              startIcon={<DoDisturbAltIcon fontSize='inherit' />}
            />

            <Button
              variant='text'
              sx={{
                width: '20px',
                height: '20px',
                padding: '0px',
                minWidth: '20px',

                '&:hover': {
                  background: 'white'
                }
              }}
              onClick={item?.downloadLOE}
              disabled={item?.status === 'COMPLETED' ? false : true}
              color='primary'
              startIcon={<FileDownloadOutlinedIcon fontSize='inherit' />}
            />
            <Button
              variant='text'
              sx={{
                width: '20px',
                height: '20px',
                padding: '0px',
                minWidth: '20px',

                '&:hover': {
                  background: 'rgba(0,0,0,0.05)'
                }
              }}
              onClick={item?.onAdd}
              disabled={item?.status === 'COMPLETED' ? false : true}
              color='success'
              startIcon={<NoteAddOutlinedIcon fontSize='inherit' />}
            />

            <Button
              variant='text'
              sx={{
                width: '20px',
                height: '20px',
                padding: '0px',
                minWidth: '20px',
                '&:hover': {
                  background: 'rgba(0,0,0,0.05)'
                }
              }}
              onClick={item?.onEdit}
              disabled={item?.status === 'DRAFT' ? false : true}
              color='primary'
              startIcon={<EditOutlinedIcon fontSize='inherit' />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
LOEHeader.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refetch: PropTypes.func
};
LOEList.propTypes = {
  list: PropTypes.array,
  onDeleteLOE: PropTypes.func,
  selectedLOE: PropTypes.object,
  setSelected: PropTypes.func,
  downloadLOE: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  openCreateLOE: PropTypes.bool,
  setOpenCreateLOE: PropTypes.func
};
LOEDetails.propTypes = {
  selectedLOE: PropTypes.object
};
LOEItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  consultantEmail: PropTypes.string.isRequired,
  consultantName: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  clientEmail: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  downloadLOE: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired
};
export { LOEDetails, LOEHeader, LOEList };
