//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Switch } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------

import InputField from '../../../../common/InputField';
import RegionSelection from '../../../../AddUser/components/RegionSelection';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FeetypeSelection from '../../../../FeetypeSelection';
import RichTextPopup from '../../../../RichTextPopup';
import { getValueWithoutHtml } from '../../../../../utils/common';
import { checkBillingRole, checkSearchApprovalRoles } from '../../../../../Containers/Commons/Utils';
import { PENDING_REJECT_BILLING_STATUS } from '../../../../../services/constantService';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../../../selectors';

export default function BillingInformationView(props) {
  const { register, watch, setValue, renderActions, renderFieldLabel, editingField, currentValues, renderSectionLabel } = props;

  const [isHide, setIsHide] = useState(false);
  const userData = useSelector(getUserSelector);

  useEffect(() => {
    register('invoice_delivery_type');
    register('contact_name');
    register('vendor_portal');
    register('vendor_portal_url');
    register('billing_notes');
    register('billing_region');
  }, [register]);

  useEffect(() => {
    const disableEdit = checkSearchApprovalRoles(userData);
    if (!disableEdit && PENDING_REJECT_BILLING_STATUS.includes(currentValues.billing_status)) {
      setIsHide(true);
    } else if (currentValues.stage === 'Closed') {
      const editable = checkBillingRole(userData);
      if (!editable) {
        setIsHide(true);
      }
    }
  }, [currentValues]);

  const handleChange = e => {
    setValue(`${e.target.name}`, e.target.value);
  };

  const renderInput = (label, field, extraParams) => {
    return (
      <div className='d-flex intersection-page-view'>
        <div>{renderFieldLabel(label, field)}</div>
        {editingField === 'billing_info' ? (
          <div className='page-label-value'>
            <InputField {...extraParams} {...register(field)} className='input-form-field' label='' name={field} onChange={handleChange} variant='outlined' />
            {renderActions(field)}
          </div>
        ) : (
          <>
            {field === 'email_invoice_to' ? (
              <span className='page-label-value text-transform-none'>{currentValues[field] || '--'}</span>
            ) : (
              <span className='page-label-value'>{currentValues[field] || '--'}</span>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <Accordion defaultExpanded={true} className='panel-bottom-margin' style={{ background: '#fff', color: '#fff' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />} aria-controls='panel1a-content' style={{ background: '#2a364c' }}>
        <div className='d-flex' onClick={e => e.stopPropagation()}>
          {!isHide ? renderSectionLabel('Billing Info', 'billing_info') : renderSectionLabel('Billing Info', '')}
          {renderActions('billing_info')}
        </div>
        <div className='flex-grow-1'>&nbsp;</div>
      </AccordionSummary>
      <AccordionDetails className='d-flex flex-column'>
        <div className='d-flex contact-details-row'>
          <div className='d-flex intersection-page-view'>
            {renderFieldLabel('Billable', 'billable')}
            {editingField === 'billing_info' ? (
              <div className='page-label-value'>
                <Switch
                  checked={watch('billable') || false}
                  onChange={e => {
                    setValue('billable', e.target.checked);
                  }}
                  color='primary'
                  {...register('billable')}
                  name={'billable'}
                />
                {renderActions('billable')}
              </div>
            ) : (
              <span className='page-label-value'>{currentValues.billable ? 'Yes' : 'No'}</span>
            )}
          </div>
          <div className='d-flex intersection-page-view'>
            {renderFieldLabel('Billing Region', 'billing_region', editingField === 'billing_info')}
            {editingField === 'billing_info' ? (
              <div className='page-label-value'>
                <RegionSelection
                  className='input-form-field'
                  defaultValue={watch('billing_region') || null}
                  label={''}
                  {...register('billing_region')}
                  onChange={(e, data) => {
                    setValue('billing_region', data ? data.name : '');
                  }}
                  variant='outlined'
                />
                {renderActions('billing_region')}
              </div>
            ) : (
              <span className='page-label-value'>{currentValues.billing_region ? `${currentValues.billing_region}` : '--'}</span>
            )}
          </div>
        </div>
        {/* <div className="d-flex ">
          <div className='contact-details'>
            {renderLabel('Billing Contact Name', 'billing_contact')}
            {
              editingField === 'billing_contact' ?
                <div className='d-flex'>
                  <ContactSelection
                    className='input-form-field'
                    label=''
                    {...register(`billing_contact`)}
                    defaultValue={watch('billing_contact') || null}
                    onChange={(e, data) => {
                      let contact = data || {};
                      setValue('billing_contact', data)
                      setValue("mobile_phone", contact.mobile_phone || null)
                      setValue("work_email", contact.work_email || null)
                    }}
                  />
                  {renderActions('billing_contact')}
                </div>
                :
                <span
                  className='contact-view-value'>{currentValues.billing_contact ? `${currentValues.billing_contact.first_name || ''} ${currentValues.billing_contact.last_name || ''}` : '--'}</span>
            }
          </div>
          <div className='contact-details'>
            {renderLabel('Phone Number', 'mobile_phone')}
            {
              editingField === 'mobile_phone' ?
                <div className='d-flex'>
                  <div className='input-form-field view-search'>
                    <ReactPhoneInput
                      {...register('mobile_phone')}
                      placeholder='Phone Number'
                      value={watch('mobile_phone') || ''}
                      onChange={(data, c, e, value) => {
                        if (data?.length > 1) {
                          setValue('mobile_phone', value)
                        }
                        else {
                          setValue('mobile_phone', '')
                        }
                      }}
                      enableSearch={true}
                      inputProps={{
                        label: ''
                      }}
                      disableInitialCountryGuess={determineInitialCountryGuess(currentValues.mobile_phone)}
                      component={TextField}
                    />
                  </div>
                  {renderActions('mobile_phone')}
                </div>
                :
                <span className='contact-view-value'>{currentValues.mobile_phone || '--'}</span>
            }
          </div>
        </div> */}
        {/* <div className="d-flex ">
          {renderInput('Email', 'work_email')}
        </div> */}
        <div className='d-flex contact-details-row'>
          <div className='d-flex intersection-page-view'>
            {renderFieldLabel('PO Required', 'is_po_direct')}
            {editingField === 'billing_info' ? (
              <div className='page-label-value'>
                <Switch
                  checked={watch('is_po_direct') || false}
                  onChange={e => {
                    setValue('is_po_direct', e.target.checked);
                  }}
                  color='primary'
                  {...register('billable')}
                  name={'is_po_direct'}
                />
                {renderActions('is_po_direct')}
              </div>
            ) : (
              <span className='page-label-value'>{currentValues.is_po_direct ? 'Yes' : 'No'}</span>
            )}
          </div>

          {watch('is_po_direct') ? renderInput('PO', 'po_number') : <div className='billing-details'>&nbsp;</div>}
        </div>
        <div className={`d-flex  ${editingField === 'billing_info' ? '' : 'contact-details-row'}`}>
          {/* <div className='search-details d-flex'> */}
          {/* {renderInput('Billing Address', 'billing_address', { multiline: true, rowsMax: 3 }, 'pb-3 col text-right px-0')} */}
          <div className={'intersection-page-view d-flex w-100'}>
            <div className='col text-right px-0'>{renderFieldLabel('Billing Address', 'billing_address')}</div>
            {editingField === 'billing_info' ? (
              <div className='d-flex contact-view-value-billing pt-3 multiple-selection-list'>
                <InputField multiline={true} rowsMax={3} {...register('billing_address')} className='input-form-field' label='' name={'billing_address'} onChange={handleChange} variant='outlined' />
                {renderActions('billing_address')}
              </div>
            ) : (
              <span className='page-label-value multiple-view-selection-list col-9 pl-0'>{currentValues['billing_address'] || '--'}</span>
            )}
          </div>
          {/* </div> */}
          {/* <div className='search-details'> &nbsp;
            </div> */}
        </div>
        {/* <div className="d-flex ">
          {renderInput('Zip Code', 'zip_code')}
          <div className='contact-details'>
            {renderLabel('Invoice Delivery Type', 'invoice_delivery_type')}
            {
              editingField === 'invoice_delivery_type' ?
                <div className='d-flex'>
                  <InvoiceDeliveryTypeSelection
                    value={watch('invoice_delivery_type') || null}
                    label=''
                    className="input-form-field"
                    onChange={(e, data) => {
                      setValue("invoice_delivery_type", data ? data.name : null);
                    }}
                  />
                  {renderActions('invoice_delivery_type')}
                </div>
                :
                <span className='contact-view-value'>{currentValues.invoice_delivery_type || '--'}</span>
            }
          </div>
        </div> */}

        {/* <div> &nbsp;</div> */}
        <div className={`d-flex contact-details-row ${editingField === 'billing_info' ? 'pt-3' : ''}`}>
          {/* <div className='search-details d-flex'> */}
          <div className={'intersection-page-view search-details-billing d-flex w-100'}>
            <div className='col-3 pl-0 text-right'>{renderFieldLabel('Billing Notes', 'billing_notes', false, false, null, 'mr-5')}</div>
            {editingField === 'billing_info' ? (
              <div className={`outlined-box rich-text-field transform margin-box ${editingField === 'billing_info' ? '' : 'border-0'}`}>
                <RichTextPopup
                  className='input-form-field'
                  label='Billing Notes'
                  title='Billing Notes'
                  {...register('billing_notes')}
                  placeholder='Billing Notes'
                  InputLabelProps={{ focused: true }}
                  name='billing_notes'
                  variant='outlined'
                  setOpen={editingField === 'billing_info' ? true : false}
                  value={currentValues?.billing_notes || ''}
                  onSave={data => setValue('billing_notes', data.billing_notes)}
                  strengthsAndPotentialIssues={true}
                />
              </div>
            ) : getValueWithoutHtml(currentValues?.billing_notes) ? (
              <div className={'p-0 outlined-box-show rich-text-field transform margin-box'}>
                <RichTextPopup
                  className='input-form-field padding-rich-remove'
                  setOpen={false}
                  {...register('billing_notes')}
                  placeholder=''
                  InputLabelProps={{ focused: true }}
                  name='billing_notes'
                  variant='outlined'
                  value={currentValues?.billing_notes || ''}
                />
              </div>
            ) : (
              <span className='contact-view-value-notes mb-2'>{'--'}</span>
            )}
          </div>
        </div>
        {/* <div className='search-details'> &nbsp;
            </div> */}

        <div className='d-flex contact-details-row'>
          {/* <div className='search-details d-flex'> */}
          {renderInput('Email Invoice to', 'email_invoice_to')}
          {/* </div> */}
          <div className='search-details'> &nbsp;</div>
        </div>
        <div className='d-flex contact-details-row'>
          {/* {renderInput('Vendor Portal', 'vendor_portal')} */}
          <div className='d-flex intersection-page-view'>
            <div>{renderFieldLabel('Vendor Portal', '')}</div>
            {editingField === 'billing_info' ? (
              <div className='page-label-value'>
                <FeetypeSelection
                  variant='outlined'
                  className='input-form-field'
                  defaultValue={watch('vendor_desc') || null}
                  {...register('vendor_desc')}
                  InputLabelProps={{ focused: true }}
                  type='VENDOR_PORTAL'
                  placeholder='Vendor Portal'
                  onChange={(e, data) => {
                    // if (data?.field_value !== 'Others') {
                    //   setValue('intervals_other', '');
                    // }
                    setValue('vendor_portal', data ? data.field_value : null);
                    setValue('vendor_desc', data ? data.short_desc : null);
                    setValue('vendor_portal_url', data ? data.long_desc : null);
                  }}
                  label=''
                />
              </div>
            ) : (
              <span className='page-label-value'>{watch('vendor_desc') || '--'}</span>
            )}
          </div>
          <div className='d-flex intersection-page-view'>
            {renderFieldLabel('Portal URL', '')}
            <span className='page-label-value'>
              <a
                href={`${watch('vendor_portal_url')}`}
                rel='noopener noreferrer'
                target='_blank'
                className={watch('vendor_portal_url') ? 'text-lowercase action-text company-redirect-link' : 'contact-view-label'}
              >
                {watch('vendor_portal_url') || '--'}
              </a>
            </span>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

BillingInformationView.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  isEditing: PropTypes.bool,
  renderFieldLabel: PropTypes.func,
  renderActions: PropTypes.func,
  editingField: PropTypes.string,
  currentValues: PropTypes.object,
  renderSectionLabel: PropTypes.func
};
