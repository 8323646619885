//----------------------------------------------// Internal Imports // -------------------------------------------------

import { compareByPosition } from '../../../../utils/common';

export const getDefaultValues = sfpa => {
  if (sfpa && sfpa.skills) {
    if (sfpa.skills?.length === 0) {
      sfpa.skills = [{}];
    }
    sfpa.skills.forEach(skill => {
      if (!skill.elements) {
        skill.elements = [];
      }
      if (!skill.title) {
        skill.title = '';
      }
      if (!skill.title && !skill?.position) {
        skill.position = 0;
      }
    });
  }
  const defaultValues = { ...sfpa };
  if (defaultValues.target_locations) {
    defaultValues.target_locations = defaultValues.target_locations.map(item => {
      return { name: item };
    });
  }
  if (defaultValues.target_industries) {
    defaultValues.target_industries = defaultValues.target_industries.map(item => {
      return { name: item };
    });
  }
  if (!defaultValues.contact_targets || defaultValues.contact_targets?.length === 0) {
    defaultValues.contact_targets = [{}];
  } else {
    defaultValues.contact_targets = defaultValues.contact_targets.sort(compareByPosition);
  }
  if (!defaultValues.representative_target_companies || defaultValues.representative_target_companies?.length === 0) {
    defaultValues.representative_target_companies = [{}];
  } else {
    defaultValues.representative_target_companies = defaultValues.representative_target_companies.sort(compareByPosition);
  }
  defaultValues.attributes =
    defaultValues?.attributes && defaultValues.attributes?.length > 0
      ? defaultValues.attributes
          .map(item => {
            let data = {
              ...item.attribute,
              position: item.position,
              search_attribute_id: item.search_attribute_id || item.id,
              updated_at: item.updated_at
            };
            Object.prototype.hasOwnProperty.call(item, 'question') && (data.question = item.question);
            Object.prototype.hasOwnProperty.call(item, 'questions') && (data.questions = item.questions);
            return data;
          })
          .sort((a, b) => a.position - b.position)
      : [{}, {}, {}, {}];
  if (defaultValues.skills?.length) {
    defaultValues.skills = defaultValues.skills.sort(compareByPosition).map(skill => {
      if (skill.elements?.length) {
        skill.elements = skill.elements.sort(compareByPosition);
      }
      return skill;
    });
  }
  if (defaultValues.client_team?.length) {
    defaultValues.client_team = defaultValues.client_team.map(item => ({ ...item, ...item.contact, client_id: item.id }));
    defaultValues.client_team = defaultValues.client_team.sort(compareByPosition);
    let isHiringManagerIndex = defaultValues.client_team.findIndex(ele => ele.is_hiring_manager);
    if (isHiringManagerIndex !== -1) {
      defaultValues.client_team = defaultValues.client_team.map((ele, i) => {
        return i === isHiringManagerIndex ? { ...ele } : { ...ele, is_hiring_manager: false };
      });
    }
  }

  return defaultValues;
};

/* to remove record having all empty fields */
export const filterBlankContactTarget = records => {
  return records?.filter(ele => {
    delete ele.position;
    return !Object.values(ele).every(value => value == null);
  });
};

export const getUpdateSFPAPayload = formValues => {
  const payload = { ...formValues };
  if (payload.contact_targets) {
    payload.contact_targets = filterBlankContactTarget(payload.contact_targets);
    payload.contact_targets = payload.contact_targets.map((contactTarget, position) => {
      return {
        id: contactTarget.id,
        title: contactTarget.title ? contactTarget.title : null,
        employee_max: contactTarget.employee_max ? parseInt(contactTarget.employee_max) : null,
        employee_min: contactTarget.employee_min ? parseInt(contactTarget.employee_min) : null,
        revenue_max: contactTarget.revenue_max ? parseInt(contactTarget.revenue_max) : null,
        revenue_min: contactTarget.revenue_min ? parseInt(contactTarget.revenue_min) : null,
        ranking: contactTarget.ranking ? parseInt(contactTarget.ranking) : null,
        position
      };
    });
  }
  payload.representative_target_companies = payload.representative_target_companies.map((item, index) => ({ ...item, position: index }));
  payload.target_companies = payload.target_companies.filter(companyName => companyName && companyName !== '');
  payload.target_base_max = payload.target_base_max ? parseInt(payload.target_base_max) : null;
  payload.target_base_min = payload.target_base_min ? parseInt(payload.target_base_min) : null;
  payload.target_bonus = payload.target_bonus ? parseInt(payload.target_bonus) : null;
  payload.target_total_base_and_bonus = payload.target_total_base_and_bonus ? parseInt(payload.target_total_base_and_bonus) : null;
  payload.years_of_experience = payload.years_of_experience ? parseInt(payload.years_of_experience) : null;
  if (payload.syncfit_version === undefined) {
    payload.syncfit_version = 'Athena Questions V2';
  }
  if (payload.target_locations) {
    payload.target_locations = payload.target_locations.map(item => {
      return item.name;
    });
  }
  // if (payload.target_industries) {
  //   payload.target_industries = payload.target_industries.map((item) => {
  //     return item.name
  //   })
  // }
  // Create payload for Weighted Target Industry
  if (payload.target_industries_group) {
    let newArray = [];
    let industryArray = payload.target_industries_group.reduce((prev, curr) => {
      return [...prev, curr.sub_industries.map(item => item.name)];
    }, []);
    industryArray.forEach(item => item.forEach(itm => (newArray = [...newArray, itm])));
    payload.target_industries = newArray;

    payload.search_industry_rankings = payload.target_industries_group
      .filter(item => item.industry.name)
      .map(item => {
        return {
          ...item,
          industry: item.industry.name,
          sub_industries: item.sub_industries.map(item => item.name),
          ranking: item.ranking || 0
        };
      });
  } else {
    let newArray = [];
    let industryArray = payload.search_industry_rankings.reduce((prev, curr) => {
      return [...prev, curr.sub_industries];
    }, []);
    industryArray.forEach(item => item.forEach(itm => (newArray = [...newArray, itm])));
    payload.target_industries = newArray;
  }

  // Create payload for Weighted Target Country and States
  if (payload.target_country_group) {
    let newArray = [];
    let countryArray = payload.target_country_group.reduce((prev, curr) => {
      return [...prev, curr.country];
    }, []);
    payload.target_locations = countryArray;
    let statesArray = payload.target_country_group.reduce((prev, curr) => {
      return [...prev, curr.states];
    }, []);
    statesArray.forEach(item => item.forEach(itm => (newArray = [...newArray, itm])));
    payload.target_states = newArray;

    payload.search_target_country_rankings = payload.target_country_group
      .filter(item => item.country)
      .map(item => {
        return {
          ...item,
          states: item.states.filter((c, index) => {
            return item.states.indexOf(c) === index;
          }),
          ranking: item.ranking || 0
        };
      });
  } else {
    let newArray = [];
    let countryArray = payload.search_target_country_rankings.reduce((prev, curr) => {
      return [...prev, curr.country];
    }, []);
    payload.target_locations = countryArray;
    let statesArray = payload.search_target_country_rankings.reduce((prev, curr) => {
      return [...prev, curr.states];
    }, []);
    statesArray.forEach(item => item.forEach(itm => (newArray = [...newArray, itm])));
    payload.target_states = newArray;
  }

  if (payload.off_limits) {
    payload.off_limits = payload.off_limits.filter(item => item !== '');
  }
  payload.attributes = payload?.attributes
    .filter(item => item && item.id)
    .map((item, index) => {
      const attribute = {
        definition: item.definition,
        id: item.id,
        name: item.name
      };
      let data = {
        attribute,
        attribute_id: item.id,
        position: index,
        id: item.search_attribute_id
      };
      Object.prototype.hasOwnProperty.call(item, 'question') && (data.question = item.question);
      Object.prototype.hasOwnProperty.call(item, 'questions') && (data.questions = item.questions);
      return data;
    });

  if (payload.skills) {
    payload.skills = payload.skills.map((skill, index) => {
      skill.position = index;
      if (skill.elements) {
        skill.elements = skill.elements
          .filter(element => element.minimum || element.ideal || element.question)
          .map((item, eleIndex) => {
            item.position = eleIndex;
            return item;
          });
      }
      return skill;
    });
  }

  payload.client_team = payload.client_team
    .filter(item => item.contact)
    .map((item, index) => {
      return {
        contact_id: item.contact?.id,
        is_hiring_manager: item.is_hiring_manager,
        position: index,
        id: item.client_id
      };
    });
  if (payload.target_states) {
    payload.target_states = payload.target_states.filter((c, index) => {
      return payload.target_states.indexOf(c) === index;
    });
  }

  return payload;
};

export const initialSFPA = {
  skills: [{}],
  off_limits: [],
  target_industries: [],
  target_locations: [],
  target_companies: []
};
