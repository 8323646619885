//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import PopoverStickOnHover from '../../components/common/PopoverStickOnHover';

const PhonePopover = props => {
  const { phones = [], children } = props;

  const renderPhoneView = (phone, index) => {
    return (
      <div className='d-flex flex-wrap pop-over-action-item' key={index}>
        <span className='email-label'>{`${phone.label || 'Phone'}:`}</span>
        <a href={`tel:${phone.value}`} target='' className='pop-over-action-value'>
          {phone.value}
        </a>
      </div>
    );
  };

  const renderPopoverContent = phones => {
    return (
      <Typography className='pop-over-actions pop-over-phone' component='div'>
        {phones.map((item, index) => {
          return renderPhoneView(item, index);
        })}
      </Typography>
    );
  };

  return phones?.length > 0 ? (
    <PopoverStickOnHover component={renderPopoverContent(phones)} placement='bottom' onMouseEnter={() => {}} delay={200}>
      {children}
    </PopoverStickOnHover>
  ) : (
    <div />
  );
};

PhonePopover.propTypes = {
  phones: PropTypes.array,
  children: PropTypes.node
};

export default PhonePopover;
