//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useRef, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import BusinessIcon from '@mui/icons-material/Business';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import {
  DateRenderer,
  DateTimeRenderer,
  loadColumnStateFromLocalStorage,
  PhoneRenderer,
  saveColumnStateToLocalStorage,
  CustomLoadingOverlayComponent,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE
} from '../../Containers/Commons/Utils';
// import {
//   CompanyNameRenderer,
//   CustomLoadingOverlayComponent,
//   defaultColumns,
//   PAGE_LIMIT,
//   SUCCESS_STATUS_CODE, WebsiteRenderer
// } from "../../Containers/Companies/utils";
import { SCROLL_TIMEOUT } from '../../utils/common';
import GenericCellEditor from '../../Containers/Commons/CellEditors/GenericCellEditor';
import DescriptionEditor from '../../Containers/Commons/CellEditors/DescriptionEditor';
import PhoneEditor from '../../Containers/Commons/CellEditors/PhoneEditor';
import ToggleSelection from '../../Containers/Commons/CellEditors/ToggleSelection';
import ColumnFilter from '../../Containers/Commons/ColumnFilter';
import { companyColumns } from './utils';
import { masterSearchApi } from '../../services/ApiService';
import { CompanyNameRenderer, WebsiteRenderer, defaultColumns } from '../../Containers/Companies/Utils';

let gridApi, columnApi;
export function Companies(props) {
  const { searchKey, setCompaniesCount, height = '100%', rowHeight = 42, minHeight = 180 } = props;
  // const [viewColumns, setViewColumns] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [isGridReady, setIsGridReady] = useState(false);

  const prevSearchKeyRef = useRef(null);

  useEffect(() => {
    return () => {
      const columnApis = columnApi;
      saveColumnStateToLocalStorage('masterSearchCompanyColumns', {
        columnApis
      });
    };
  }, []);

  useEffect(() => {
    if (searchKey !== prevSearchKeyRef.searchKey) {
      gridApi && gridApi.onFilterChanged();
    }
    prevSearchKeyRef.current = searchKey;
  }, [searchKey]);

  const dataSource = {
    getRows: async params => {
      gridApi.showLoadingOverlay();
      const value = encodeURIComponent(searchKey);
      let sub_route = `companies?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}&searchValue=${value}`;
      if (params.sortModel?.length > 0) {
        sub_route = sub_route.concat(`&sortOn=${params.sortModel[0].colId}&sortType=${params.sortModel[0].sort.toUpperCase()}`);
      }
      sub_route = `${sub_route}&mastersearch=true`;
      const { status, data } = await masterSearchApi(sub_route);
      if (status === SUCCESS_STATUS_CODE) {
        params.successCallback(data.data, data.paging?.totalCount);
        // this.setState({ rowCount: data.paging.showTotal ? data.paging.showTotal : data.paging?.totalCount })
        setRowCount(data.paging.showTotal ? data.paging.showTotal : data.paging?.totalCount);
        setCompaniesCount(data.paging.showTotal ? data.paging.showTotal : data.paging?.totalCount);
        columnApi.autoSizeAllColumns();
      }
      params.failCallback();
      gridApi.hideOverlay();
    },
    rowCount: null
  };

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    // const columnApi = params.columnApi
    params.columnApi.autoSizeColumns();
    loadColumnStateFromLocalStorage('masterSearchCompanyColumns', {
      columnApi
    });
    // this.setState({ isGridReady: true })
    setIsGridReady(true);
  };

  const NameRenderer = params => {
    return <CompanyNameRenderer company={params?.data} />;
  };

  return (
    <div className='list-companies d-flex flex-column mt-4' style={{ height: rowCount ? height : minHeight }}>
      <div className='list-header d-flex justify-content-between'>
        <Typography className='table-header-text' style={{ paddingLeft: '1.5%' }}>
          <BusinessIcon style={{ fill: '#DE9F6D' }} /> Companies
        </Typography>
        <Typography className='total-count' style={{ paddingRight: '1.5%' }}>
          Total count: {rowCount}
        </Typography>
      </div>
      <div className='list-view flex-grow-1'>
        {isGridReady && <ColumnFilter defaultColumns={defaultColumns} gridApi={gridApi} columnApi={columnApi} />}
        <div id='myGrid' className='ag-theme-alpine'>
          <AgGridReact
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            rowHeight={rowHeight}
            blockLoadDebounceMillis={SCROLL_TIMEOUT}
            tooltipShowDelay={0}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            frameworkComponents={{
              NameRenderer: NameRenderer,
              WebsiteRenderer,
              PhoneRenderer,
              CustomLoadingOverlayComponent,
              DateTimeRenderer,
              DateRenderer,
              GenericCellEditor,
              DescriptionEditor,
              PhoneEditor,
              ToggleSelection
            }}
            rowModelType={'infinite'}
            datasource={dataSource}
            columnDefs={companyColumns}
            paginationPageSize={20}
            colResizeDefault={'shift'}
          ></AgGridReact>
          <Link
            to={{
              pathname: '/companies',
              state: searchKey
            }}
            style={{ fontSize: '18px', float: 'right' }}
            className='MuiTypography-colorPrimary mt-2'
          >
            More Records&gt;{' '}
          </Link>
        </div>
      </div>
    </div>
  );
}

Companies.propTypes = {
  enqueueSnackbar: PropTypes.func,
  searchKey: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowHeight: PropTypes.number,
  minHeight: PropTypes.number,
  setCompaniesCount: PropTypes.func.isRequired
};

export default Companies;
