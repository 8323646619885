//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import ProjectApproval from './List.js';

const ProjectApprovalPending = () => {
  return <ProjectApproval searchType={'approvalPending'} columnStateKey={'myPlacementsColumns'} searchStatus={'Placement'} title='My Placements' placedSearches />;
};

export default ProjectApprovalPending;
