//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------0
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { capitalize } from '../../../../../utils/string';

const SCORE_OPTIONS = ['Greater Than Or Equal', 'Less Than Or Equal'];
function ScoreRangeSelection(props) {
  const { className = 'w-100', label, InputLabelProps, required, placeholder = 'Select...', variant = '', ...rest } = props;

  const [options, setOptions] = useState(SCORE_OPTIONS);

  useEffect(() => {
    setOptions(SCORE_OPTIONS);
  }, []);

  return (
    <Autocomplete
      {...rest}
      className={className}
      getOptionLabel={option => capitalize(option)}
      options={options}
      renderInput={params => (
        <TextField
          // className={className}
          variant={variant}
          {...params}
          required={required}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}

ScoreRangeSelection.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  InputLabelProps: PropTypes.object
};

export default ScoreRangeSelection;
