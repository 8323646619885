import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  editId: null
};

const logAnActivitySlice = createSlice({
  name: 'logAnActivity',
  initialState,
  reducers: {
    updateEditId(state, action) {
      state.editId = action.payload;
    },
    resetEditId(state) {
      state.editId = null;
    }
  }
});

export const logAnActivityActions = logAnActivitySlice.actions;

export default logAnActivitySlice.reducer;
