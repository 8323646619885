//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { addContactsToWorkbench, addSearchesToWorkbench } from './Utils';
import WorkbenchAction from './WorkbenchAction';

export default function AddContactToWorkbench(props) {
  const { open, contactIds, searchIds, onClose, /*searchId, sourceWorkbenchId, contactsQuery, candidateStatus,*/ type } = props;
  const { enqueueSnackbar } = useSnackbar();
  const handleAddContacts = async workbenchId => {
    // let sub_route = '';
    // if (type === "Searches") {
    //   sub_route = `searches/copy`
    // } else {
    //   sub_route = `contacts/copy`
    // }
    // const formData = {}
    // if (searchId) {
    //   sub_route = sub_route.concat(`/search/${searchId}`)
    //   formData.candidateStatus = candidateStatus
    // } else if (sourceWorkbenchId) {
    //   sub_route = sub_route.concat(`/work-bench/${sourceWorkbenchId}`)
    // } else if (contactsQuery) {
    //   formData.query = contactsQuery
    // }
    if (type === 'Searches') {
      return addSearchesToWorkbench(workbenchId, searchIds, enqueueSnackbar);
    } else {
      return addContactsToWorkbench(workbenchId, contactIds, enqueueSnackbar);
    }
  };

  return (
    <WorkbenchAction
      title={`Add ${type} To Workbench`}
      open={open}
      contactIds={contactIds}
      searchIds={searchIds}
      onClose={onClose}
      buttonText='Add To Workbench'
      onSubmit={handleAddContacts}
      type={type}
    />
  );
}

AddContactToWorkbench.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  contactIds: PropTypes.array,
  searchId: PropTypes.string,
  sourceWorkbenchId: PropTypes.string,
  contactsQuery: PropTypes.object,
  candidateStatus: PropTypes.string,
  searchIds: PropTypes.array,
  type: PropTypes.string
};
