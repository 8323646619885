import { getFilterQuery } from '../Commons/Utils';
export const PAGE_LIMIT = 200;
export const SUCCESS_STATUS_CODE = 200;

export const columnDefs = () => [
  {
    headerName: '',
    colId: 'check_box',
    field: 'check_box',
    maxWidth: 50,
    minWidth: 50,
    headerComponent: 'HeaderCheckbox',
    headerCheckboxSelectionFilteredOnly: false,
    checkboxSelection: true,
    sortable: false,
    pinned: 'left',
    lockPinned: true,
    lockPosition: true
  },
  {
    headerName: 'Organization',
    field: 'org_id',
    sortable: true,
    maxWidth: 150,
    minWidth: 150,
    pinned: 'left',
    lockPinned: true,
    editable: false,
    tooltipField: 'org_id',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Field Name',
    field: 'field_name',
    tooltipField: 'field_name',
    sortable: true,
    maxWidth: 250,
    minWidth: 250,
    pinned: 'left',
    editable: false,
    lockPinned: true,
    cellRenderer: 'translateViewRenderer',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Field Value',
    field: 'field_value',
    tooltipField: 'field_value',
    sortable: true,
    maxWidth: 250,
    minWidth: 250,
    pinned: 'left',
    lockPinned: true,
    editable: false,
    cellRenderer: 'translateViewRenderer',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Language',
    colId: 'lang_cd',
    field: 'lang_cd',
    tooltipField: 'lang_cd',
    sortable: true,
    maxWidth: 120,
    minWidth: 120,
    pinned: 'left',
    lockPinned: true,
    editable: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Short Description',
    field: 'short_desc',
    colId: 'short_desc',
    tooltipField: 'short_desc',
    sortable: true,
    maxWidth: 300,
    minWidth: 300,
    pinned: 'left',
    lockPinned: true,
    editable: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Long Description',
    field: 'long_desc',
    colId: 'long_desc',
    tooltipField: 'long_desc',
    sortable: true,
    maxWidth: 270,
    minWidth: 270,
    pinned: 'left',
    lockPinned: true,
    editable: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Action',
    field: 'action',
    colId: 'action',
    sortable: false,
    maxWidth: 86,
    minWidth: 86,
    pinned: 'right',
    lockPinned: true,
    filter: false,
    cellRenderer: 'ActionsRenderer'
  }
];

export const getFilterParamStringForTranslateValue = data => {
  return getFilterQuery(data, {}, {}, '');
};
