import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import DrawerComponent from '../../../components/common/Drawer';

import { Check, Edit } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import Loader from '../../../components/common/Loader';
import CustomInputField from '../../../components/common/StyledComponents/CustomInputField';
import { ignUsersApi, rolesApi, userRolesApi, userRolesResourceApi } from '../../../services/ApiService';
import { GET, PUT } from '../../../services/constantService';
import useApi from '../../../utils/Hooks/useApiHook';
import ManageRolesGrid from './components/ManageRolesGrid';
import RoleAutocomplete from './components/RoleAutoComplete';
import styles from './index.module.scss';

const ManageUser = props => {
  const { isDrawerOpen, setIsDrawerOpen, setCurrentUser, currentUser, refetchAndOpenDrawer } = props;

  const [isNameEditing, setIsNameEditing] = React.useState(false);
  const [nameData, setNameData] = React.useState({
    first_name: currentUser?.first_name || '',
    last_name: currentUser?.last_name || ''
  });

  const {
    data: rolesData,
    refetch: refetchAllRoles,
    loading: rolesLoading
  } = useApi({
    queryFn: () => rolesApi(GET, 'getAllWithResource')
  });

  const {
    data: userRolesData,
    refetch: refetchUserRoles,
    loading: userRolesLoading
  } = useApi({
    queryFn: () => {
      if (!currentUser) return;
      if (!currentUser?.id) return;
      return userRolesApi(GET, `getUserRoles/${currentUser?.id}`);
    }
  });

  const {
    data: userResourcesData,
    refetch: userResourcesRefetch,
    loading: userResourcesLoading
  } = useApi({
    queryFn: () => userRolesResourceApi(GET, `getRecordsByUserEmail?userEmail=${encodeURIComponent(currentUser?.email)}`)
  });

  useEffect(() => {
    if (currentUser) {
      userResourcesRefetch();
      refetchAllRoles();
      refetchUserRoles();
      setNameData({
        first_name: currentUser.first_name,
        last_name: currentUser.last_name
      });
    }

    return () => {
      setIsNameEditing(false);
    };
  }, [currentUser]);

  const handleUpdateUser = async () => {
    await ignUsersApi(PUT, `updateById/${currentUser.id}`, {
      first_name: nameData.first_name,
      last_name: nameData.last_name
    }).then(() => {
      refetchAndOpenDrawer(currentUser);
    });
    setIsNameEditing(false);
  };

  return (
    currentUser && (
      <Box id='add-email-template-drawer'>
        <DrawerComponent
          id={'manage-user'}
          anchor={'right'}
          open={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
            setCurrentUser(null);
          }}
          width='80vw'
        >
          <Box
            className='p-4'
            sx={{
              overflowY: 'scroll'
            }}
          >
            <Loader show={rolesLoading || userRolesLoading || userResourcesLoading} />
            <Typography className='body-font font-weight-bold fs-10 mb-2'>User Name</Typography>
            <Box className='d-flex align-items-center pb-2'>
              <Box
                sx={{
                  display: isNameEditing ? 'flex' : 'none',
                  gap: 2
                }}
              >
                <CustomInputField
                  defaultValue={currentUser.first_name}
                  label='First Name'
                  onChange={e => {
                    setNameData({ ...nameData, first_name: e.target.value });
                  }}
                />
                <CustomInputField
                  defaultValue={currentUser.last_name}
                  label='Last Name'
                  onChange={e => {
                    setNameData({ ...nameData, last_name: e.target.value });
                  }}
                />
              </Box>
              <Typography
                className='header-font fs-20'
                sx={{
                  display: isNameEditing ? 'none' : 'block'
                }}
              >
                {currentUser.name}
              </Typography>
              <Button
                className='px-2 mx-2'
                sx={{
                  minWidth: 0
                }}
                onClick={() => {
                  if (isNameEditing) {
                    handleUpdateUser();
                  }
                  setIsNameEditing(!isNameEditing);
                }}
              >
                {!isNameEditing ? <Edit className={`${styles.edit_btn} fs-20 w-10 cursor-pointer`} /> : <Check />}
              </Button>
            </Box>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                gap: 2,
                my: 2
              }}
            >
              {rolesData.data && userResourcesData.data && userRolesData?.data && (
                <RoleAutocomplete
                  roles={rolesData.data}
                  userResourcesData={userResourcesData.data}
                  userRolesData={userRolesData?.data}
                  userResourcesRefetch={userResourcesRefetch}
                  refetchUserRoles={refetchUserRoles}
                  currentUser={currentUser}
                />
              )}
            </Box>
            {rolesData.data && userResourcesData.data && userRolesData?.data && (
              <ManageRolesGrid
                rolesData={rolesData.data}
                userRolesResourcesData={userResourcesData.data}
                userRolesData={userRolesData?.data}
                refetchUserRoleResource={userResourcesRefetch}
                currentUser={currentUser}
              />
            )}
          </Box>
        </DrawerComponent>
      </Box>
    )
  );
};

ManageUser.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.string,
  isDrawerOpen: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func,
  templateData: PropTypes.object,
  currentUser: PropTypes.object,
  setCurrentUser: PropTypes.func,
  refetchAndOpenDrawer: PropTypes.func
};

export default ManageUser;
