import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const CalculationTypeSelection = props => {
  const dispatch = useDispatch();
  const indirectCalculation = useSelector(state => state.commonReducer.indirectCalculation);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!indirectCalculation) {
      dispatch(fetchPickLists('INDIRECT_CALCULATION_TYPE', 'indirectCalculation'));
    } else {
      setOptions(indirectCalculation);
    }
  }, [indirectCalculation, dispatch]);

  const { className = 'SingleSelectStyle', onChange = () => {}, isDrawer = false, label = '', defaultValue, viewAddress, isDisabled } = props;
  // const calculationType = [
  //   { label: "Percentage", value: "Percentage" },
  //   { label: "Flat Amount", value: "Flat Amount" },
  // ];

  return (
    <>
      <CustomDropdown label={label} options={options} className={className} onChange={onChange} isDrawer={isDrawer} viewAddress={viewAddress} disabled={isDisabled} defaultValue={defaultValue} />
    </>
  );
};

CalculationTypeSelection.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  isDrawer: PropTypes.bool,
  defaultValue: PropTypes.string,
  viewAddress: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default CalculationTypeSelection;
