import { getFilterQuery } from '../Commons/Utils';

export const getFilterParamStringForTranslateValue = data => {
  return getFilterQuery(data, {}, {}, '');
};

export const columnDefs = () => [
  {
    headerName: '',
    colId: 'check_box',
    field: 'check_box',
    maxWidth: 50,
    minWidth: 50,
    headerComponent: 'HeaderCheckbox',
    headerCheckboxSelectionFilteredOnly: false,
    checkboxSelection: true,
    sortable: false,
    pinned: 'left',
    lockPinned: true,
    lockPosition: true
  },
  {
    headerName: 'Name ',
    field: 'name',
    sortable: true,
    maxWidth: 200,
    minWidth: 200,
    pinned: 'left',
    lockPinned: true,
    editable: false,
    cellRenderer: 'NameRenderer',
    tooltipField: 'name',
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Model',
    field: 'model',
    tooltipField: 'model',
    sortable: true,
    maxWidth: 200,
    minWidth: 200,
    pinned: 'left',
    editable: false,
    lockPinned: true,

    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Temperature',
    field: 'temperature',
    tooltipField: 'temperature',
    sortable: true,
    maxWidth: 125,
    minWidth: 125,
    lockPinned: true,
    editable: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Maximum Tokens',
    colId: 'max_tokens',
    field: 'max_tokens',
    tooltipField: 'max_tokens',
    sortable: true,
    maxWidth: 125,
    minWidth: 125,
    lockPinned: true,
    editable: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },

  {
    headerName: 'Frequency Penalty',
    field: 'frequency_ penalty',
    colId: 'frequency_ penalty',
    sortable: false,
    maxWidth: 125,
    minWidth: 125,

    lockPinned: true,
    filter: false,
    closeOnApply: true
    // cellRenderer: "ActionsRenderer",
  },
  {
    headerName: 'Presence Penalty',
    colId: 'presence_penalty',
    field: 'presence_penalty',
    tooltipField: 'presence_penalty',
    sortable: true,
    maxWidth: 125,
    minWidth: 125,
    lockPinned: true,
    editable: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'System Content',
    colId: 'system_content',
    field: 'system_content',
    tooltipField: 'system_content',
    sortable: true,
    maxWidth: 450,
    minWidth: 450,
    lockPinned: true,
    editable: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Action',
    field: 'action',
    colId: 'action',
    sortable: false,
    maxWidth: 100,
    minWidth: 100,
    pinned: 'right',
    lockPinned: true,
    filter: false,
    cellRenderer: 'ActionsRenderer'
  }
];
