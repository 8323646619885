//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import Popup from 'reactjs-popup';
import CloseIcon from '@mui/icons-material/Close';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import ActivityHistoryDetails from './ActivityHistoryDetails';

const ActivityHistories = props => {
  const { contact = {}, onPopupClose, setActivityCurrentValues, setActivityHistories, readOnly } = props;

  const getContactName = () => {
    return `${contact.first_name || ''} ${contact.last_name || ''}`;
  };

  return (
    <Popup open={true} modal onClose={onPopupClose} className='activity-histories' closeOnDocumentClick={false} closeOnEscape={false}>
      <>
        <div className='d-flex flex-column h-100'>
          <div style={{ background: '#2a364c', color: '#fff' }} className='quick-add-header'>
            <div className='d-flex'>
              <h4 className='mr-2'>Last Activity</h4>
              <Typography color='primary' className='mr-2'>
                {getContactName()}
              </Typography>
              <Typography className='mr-2'>{contact?.company?.name || '--'}</Typography>
              <Typography>{contact?.current_job_title || '--'}</Typography>
            </div>
            <span className='action-icon' onClick={onPopupClose}>
              <CloseIcon cursor='pointer' />
            </span>
          </div>
          <ActivityHistoryDetails contact={contact} setActivityCurrentValues={setActivityCurrentValues} setActivityHistoriesForParent={setActivityHistories} readOnly={readOnly} />
        </div>
      </>
    </Popup>
  );
};

ActivityHistories.propTypes = {
  contact: PropTypes.object,
  onPopupClose: PropTypes.func,
  setActivityCurrentValues: PropTypes.func,
  setActivityHistories: PropTypes.func,
  readOnly: PropTypes.bool
};

export default ActivityHistories;
