import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import '../../../Containers/Commons/DatePickerStyle/index.scss';
const DatePickerCommon = props => {
  const {
    value,
    onChange,
    label = '',
    color = 'heading',
    className,
    maxDate,
    minDate,
    disabled,
    disableFuture,
    onError,
    slotProps,
    format = 'DD/MM/YYYY',
    required = false,
    isDefaultDatePickerStyle = true,
    hideLabel = false,
    slots = {},
    ...res
  } = props;

  const [date, setDate] = useState(value ? dayjs(value, format) : null);

  useEffect(() => {
    setDate(value ? dayjs(value, format) : null);
  }, [value, format]);
  // const [date, setDate] = useState()

  // useEffect(() => {
  //    debugger
  //   // if (value && typeof value === 'string') {
  //   //   const dateFormate = value.split('/').reverse().join('-');
  //   //   const textDate = new Date(dateFormate).toString()
  //   //   setDate(textDate)
  //   // } else {
  //   //   setDate(null);
  //   // }
  //   setDate(value);
  // }, [value]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} {...res}>
      <DatePicker
        {...res}
        defaultValue={date ? dayjs(date, format) : null}
        value={date ? dayjs(date) : null}
        color={color}
        keyboardIcon={<CalendarMonthIcon></CalendarMonthIcon>}
        onChange={onChange}
        InputProps={{
          disableUnderline: true
        }}
        slots={slots}
        format={format}
        className={`${isDefaultDatePickerStyle ? 'DatePickerStyle' : ''} ${className}`}
        label={
          hideLabel ? (
            ''
          ) : required ? (
            <Box component='span' className='required'>
              {label}
            </Box>
          ) : (
            label
          )
        }
        maxDate={maxDate}
        minDate={minDate}
        disabled={disabled}
        disableFuture={disableFuture}
        onError={onError}
        slotProps={slotProps}
      />
    </LocalizationProvider>
  );
};

DatePickerCommon.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  onError: PropTypes.func,
  slotProps: PropTypes.object,
  format: PropTypes.string,
  required: PropTypes.bool,
  isDefaultDatePickerStyle: PropTypes.bool,
  hideLabel: PropTypes.bool,
  slots: PropTypes.object
};

export default DatePickerCommon;
