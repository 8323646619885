export const CONSANTS_MARK_OFF_LIMITS = {
  SPECIFIC: 'specific',
  INDEFINITE: 'indefinite',
  HARD: 'hard',
  DATE: 'date',
  DURATION: 'duration',
  SEVERITY: 'severity',
  REASON: 'reason',
  SOFT: 'soft',
  CLASSNAMES: {
    OFF_LIMIT_TITLE: 'mark-off-limits-title',
    CUSTOM_PAPERE: 'paper-custom-css'
  }
};
