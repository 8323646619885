//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import { useForm } from 'react-hook-form';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import EditSfpaBuilderPopup from './EditSfpaBuilderPopup';
import ViewSfpaBuilderPopup from './ViewSfpaBuilderPopup';

function SfpaBuilder(props) {
  const { onClose, sfpaBuilderData, setSfpaBuilderData } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [localSfpaBuilder] = useState(sfpaBuilderData);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});

  const { register, reset } = useForm({ defaultValues });

  const isFormInEditMode = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  useEffect(() => {
    setDefaultValues({
      name: localSfpaBuilder.name,
      model: localSfpaBuilder.model,
      temperature: localSfpaBuilder.temperature,
      frequency_penalty: localSfpaBuilder.frequency_penalty,
      max_tokens: localSfpaBuilder.max_tokens,
      system_content: localSfpaBuilder.system_content,
      presence_penalty: localSfpaBuilder.presence_penalty
    });
  }, [localSfpaBuilder, setDefaultValues]);

  useEffect(() => {
    register('name');
    register('model');
    register('temperature');
    register('frequency_penalty');
    register('max_tokens');
    register('system_content');
    register('view_role');
    register('presence_penalty');
  }, [register]);

  const onPopupClose = () => {
    onClose(isDataUpdated);
  };
  return (
    <Popup open={props.visible} modal onClose={onPopupClose} className={'add-document-value'} closeOnDocumentClick={false} closeOnEscape={false}>
      {isEditing ? (
        <EditSfpaBuilderPopup sfpaBuilderData={sfpaBuilderData} closePopup={onPopupClose} setIsEditing={setIsEditing} setSfpaBuilderData={setSfpaBuilderData} setIsDataUpdated={setIsDataUpdated} />
      ) : (
        <ViewSfpaBuilderPopup sfpaBuilderData={sfpaBuilderData} editForm={isFormInEditMode} closePopup={onPopupClose} />
      )}
    </Popup>
  );
}

export default SfpaBuilder;

SfpaBuilder.propTypes = {
  onClose: PropTypes.func,
  sfpaBuilderData: PropTypes.object,
  visible: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  setSfpaBuilderData: PropTypes.func
};
