import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import React from 'react';
import PropTypes from 'prop-types';
import '../../../Containers/Commons/DatePickerStyle/index.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const DatePickerYear = props => {
  const { value, onChange, label = '', color = 'heading', className, maxDate, minDate, disabled, disableFuture, onError, slotProps, showOnlyYear = false } = props;
  const handleChange = newValue => {
    if (showOnlyYear) {
      onChange(newValue ? newValue.format('YYYY') : ''); // Store only year value
    } else {
      onChange(newValue ? newValue.format('MM-DD-YYYY') : ''); // Store full date value
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        defaultValue={showOnlyYear ? (value ? dayjs(value, 'YYYY') : null) : value ? dayjs(value, 'DD/MM/YYYY') : null}
        color={color}
        keyboardIcon={<CalendarMonthIcon></CalendarMonthIcon>}
        onChange={handleChange}
        InputProps={{
          disableUnderline: true
        }}
        format={showOnlyYear ? 'YYYY' : 'DD/MM/YYYY'}
        className={`DatePickerStyle ${className}`}
        placeholder={showOnlyYear ? 'YYYY' : 'dd/mm/yyyy'}
        label={label}
        maxDate={maxDate}
        minDate={minDate}
        disabled={disabled}
        disableFuture={disableFuture}
        onError={onError}
        slotProps={slotProps}
        views={showOnlyYear ? ['year'] : ['day', 'month', 'year']}
      />
    </LocalizationProvider>
  );
};

DatePickerYear.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  onError: PropTypes.func,
  slotProps: PropTypes.object,
  showOnlyYear: PropTypes.bool // Prop to determine if only the year should be shown
};

export default DatePickerYear;
