import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import CustomButton from '../CustomButton';

const SuccessScreen = ({ title, description, icon = null, successBtnText = 'Ok', onSuccess }) => {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' textAlign='center' className='loading-screen'>
      {/* Success Icon */}
      {icon ? icon : <CheckCircleIcon style={{ fontSize: 80 }} />}

      {/* Title */}
      <Typography variant='h5' fontWeight='bold' gutterBottom>
        {title}
      </Typography>

      {/* Description */}
      <Typography variant='body1' color='textSecondary'>
        {description}
      </Typography>

      {/* Success Button */}
      <CustomButton variant='contained' boxClassName={'my-2'} type={'primary'} onClick={onSuccess} buttonText={successBtnText} />
    </Box>
  );
};

SuccessScreen.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.element,
  successBtnText: PropTypes.string,
  onSuccess: PropTypes.func
};

export default SuccessScreen;
