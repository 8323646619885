import React from 'react';
import CandidateProjectStatusSetupTable from './CandidateProjectStatusSetupTable';

const CandidateProjectStatusSetup = () => {
  return (
    <React.Fragment>
      <div className='position-relative m-3'>
        <CandidateProjectStatusSetupTable />
      </div>
    </React.Fragment>
  );
};

export default CandidateProjectStatusSetup;
