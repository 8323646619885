import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, Box, Grid, Typography } from '@mui/material';
import { StyledAccordionSummary } from '../CustomAccordionCell';
import RadioButtonGroupWithLine from '../RadioButtonGroupWithLine.js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const CustomAccordionBuilder = props => {
  const { questionary, radioOptions, handleChange, radioState } = props;
  return (
    <React.Fragment>
      {questionary.map(questionary => {
        return (
          <Accordion defaultExpanded key={questionary?.id} fullWidth className='m-1'>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />} aria-controls='panel1a-content' id='panel1a-header'>
              {questionary?.section_title}
            </StyledAccordionSummary>
            <AccordionDetails fullWidth>
              {questionary?.sfpa_decision_questions.map(question => (
                <Box fullWidth key={question?.id}>
                  <Typography variant='subtitle2' sx={{ fontWeight: '600 !important' }}>
                    {question?.question_text}
                  </Typography>
                  <Grid container fullWidth spacing={2} className='mb-4 pl-3'>
                    <Grid item xs={4}>
                      <Typography variant='subtitle2' className='mt-4'>
                        {question?.low_value_label}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <RadioButtonGroupWithLine
                        keyId={question?.id}
                        radioState={radioState}
                        handleChangeFunction={handleChange}
                        radioOptions={radioOptions}
                        value={question?.project_sfpa_decisions?.answer_value ?? 0}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant='subtitle2' className='mt-4'>
                        {question?.high_value_label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </React.Fragment>
  );
};

CustomAccordionBuilder.propTypes = {
  questionary: PropTypes.array.isRequired,
  radioOptions: PropTypes.array,
  handleChange: PropTypes.func,
  radioState: PropTypes.object
};

export default CustomAccordionBuilder;
