import CloseIcon from '@mui/icons-material/Close';
import SchoolIcon from '@mui/icons-material/School';
import { Box, Divider, Table, TableBody } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../src/utils/common.scss';
import { contactDocumentApi } from '../../../services/ApiService';
import { DELETE, SUCCESS } from '../../../services/constantService';
import { useCustomMessageHook } from '../../../utils/Hooks/useCustomMessageHook';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import AddEductionAndExperience from '../../AddContact/Components/EducationAndLanguages/AddEductionAndExperience';
import CustomConfirmationPopup from '../../common/CustomConfirmationPopup';
import CustomTable from '../../common/CustomTable';
import DatePickerYear from '../../common/DatePickerYear/DatePickerYear';
import CertificationTypeSelection from '../../common/FunctionalComponents/CertificationTypeSelection';
import EducationCard from '../../common/FunctionalComponents/EducationCard';
import LanguagesSelection from '../../common/FunctionalComponents/LanguagesSelection';
import LevelOfExpertiseSelection from '../../common/FunctionalComponents/LevelOfExpertiseSelection';
import CustomInputField from '../../common/StyledComponents/CustomInputField';
import './index.scss';
import { AddAnotherButton } from './ViewProfessionalExperience';

const ViewEducationLanguages = props => {
  const {
    register,
    data,
    unregister,
    setValue = () => {},
    updateField,
    watch = () => {},
    handleSave = () => {},
    getContactDetails = () => {},
    setIsPopupOpen = () => {},
    isPopupOpen = () => {}
  } = props;
  const onSave = async () => {
    if (isEdit) {
      await handleSave({ field: 'updated', from: 'Education Details', card_id: data?.contact_education_details[indexValue].id });
    } else {
      await handleSave({ field: 'created', from: 'Education Details' });
    }
  };
  const [isEdit, setIsEdit] = useState(false);
  const [indexValue, setIndexValue] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [isEditingLanguages, setIsEditingLanguages] = useState(true);
  const [isEditingCertificate, setIsEditingCertificate] = useState(true);
  const [localEducationDetails, setLocalEducationDetails] = useState(data?.contact_education_details || []);
  const [loading, setLoading] = useState(false);

  const { translateMessage } = useCustomMessageHook();
  const tr = useTranslation();
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_EDUCATION,
    TRANS_KEYS.CONTACTS_LANGUAGES,
    TRANS_KEYS.CONTACTS_UPDATE_EDUCATION_DETAILS,
    TRANS_KEYS.CONTACTS_ADD_EDUCATION_DETAILS,
    TRANS_KEYS.CONTACTS_LEVEL_OF_EXPERTISE,
    TRANS_KEYS.CONTACTS_ADD_LANGUAGES,
    TRANS_KEYS.CONTACTS_ADD_ANOTHER,
    TRANS_KEYS.CONTACTS_ADD_LANGUAGES,
    TRANS_KEYS.CONTACTS_WORD_ADD,
    TRANS_KEYS.CONTACT_CERTIFICATION_AND_LICENSE,
    TRANS_KEYS.ADD_CERTIFICATION_AND_LICENSE,
    TRANS_KEYS.CERTIFICATION_EXPIRE_ON,
    TRANS_KEYS.CERTIFICATION_TYPE,
    TRANS_KEYS.CERTIFICATION_TITLE
  ]);
  const handleOpenPopup = () => {
    setIsEdit(false);
    setIsPopupOpen(true);
  };
  const openPopupEditing = index => {
    setIndexValue(index);
    setIsEdit(true);
    setIsPopupOpen(true);
  };
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [educationToDelete, setEducationToDelete] = useState(null);

  const handleDeleteClick = index => {
    setOpenConfirmationPopup(true);
    setEducationToDelete(index);
  };

  const onConfirm = async () => {
    setLoading(true);
    const { status } = await contactDocumentApi(DELETE, educationToDelete.id, null, null, null, 'education-details');
    if (status === 200) {
      if (loading == true) {
        setLoading(false);
      }
      await getContactDetails(educationToDelete?.contact_id);
      const message = translateMessage('Successfully', false, 'Education', 'Deleted');
      enqueueSnackbar(message, { variant: SUCCESS });
      const updatedEducationDetails = localEducationDetails.filter(item => item.id !== educationToDelete.id);
      setLocalEducationDetails([...updatedEducationDetails]);
      setOpenConfirmationPopup(false);
    }
  };

  const closeConfirmationPopup = () => {
    setOpenConfirmationPopup(false);
    setEducationToDelete(null);
  };

  useEffect(() => {
    setLocalEducationDetails(data?.contact_education_details || []);
  }, [data]);

  const [language, setLanguage] = useState([{ language: '', expertise: '' }]);
  const [certificate, setCertificate] = useState([{ certification_type: '', certifications: '', expire_on: '' }]);
  useEffect(() => {
    setLanguage(data?.contact_languages);
    setCertificate(data?.contact_certificate);
  }, [data]);

  useEffect(() => {
    register('expire_on');
    register('contact_languages');
    register('contact_certificate');

    return () => {
      unregister('expire_on');
      unregister('contact_languages');
      unregister('contact_certificate');
    };
  }, [register, unregister]);

  const handleAddLanguage = () => {
    setLanguage([...language, { language: '', expertise: '' }]);
  };

  const handleAddCertificate = () => {
    setCertificate([...certificate, { certification_type: '', certifications: '', expire_on: '' }]);
  };

  const handleEditLanguage = value => {
    setIsEditingLanguages(value);
  };

  const handleEditCertificate = value => {
    setIsEditingCertificate(value);
  };

  const handleRemoveLanguage = index => {
    const updatedLanguage = [...language];
    updatedLanguage.splice(index, 1);
    setLanguage(updatedLanguage);
  };

  const handleRemoveCertificate = index => {
    const updatedCertificate = [...certificate];
    updatedCertificate.splice(index, 1);
    setCertificate(updatedCertificate);
  };

  const handleChangeCertification = (index, field, value) => {
    const updatedCertificate = certificate.map((certificate, i) => {
      if (i === index) {
        return { ...certificate, [field]: value };
      }
      return certificate;
    });
    setCertificate(updatedCertificate);
  };

  const handleLanguageChange = (index, field, value) => {
    const updatedLanguage = language?.map((ele, i) => {
      if (i === index) {
        return { ...ele, [field]: value };
      }
      return ele;
    });
    setLanguage(updatedLanguage);
  };
  useEffect(() => {
    setValue(
      'contact_languages',
      language?.map(item => {
        return { expertise: item?.expertise, language_id: item?.language?.id };
      })
    );
  }, [language]);

  useEffect(() => {
    setValue(
      'contact_certificate',
      certificate?.map(elem => {
        return {
          certification_type: elem?.certification_type ? elem.certification_type : '',
          certifications: elem?.certifications ? elem?.certifications : '',
          expire_on: elem?.expire_on ? elem.expire_on : ''
        };
      })
    );
  }, [certificate]);

  return (
    <Box id='view-contact' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className='details-container-subtab scroll-content'>
        <Box className='section-details m-2'>
          <Box className='d-flex flex-column row-gap w-100 viewcontact-icon-style'>
            <Box className='d-flex flex-row column-gap w-100'>
              <SchoolIcon color='disabled'></SchoolIcon>
              <Box className='d-flex flex-column row-gap w-100'>
                <Box className='title-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_EDUCATION}:key`)}</Box>
              </Box>
            </Box>
            <Box className='p-0'>
              <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}>
                <TableBody>
                  {localEducationDetails?.map((card, cardIndex) => (
                    <React.Fragment key={cardIndex}>
                      <EducationCard
                        data={card}
                        index={cardIndex}
                        editItems={openPopupEditing}
                        getContactDetails={getContactDetails}
                        handleDeleteClick={handleDeleteClick}
                        styleData={{
                          titleSize: 13,
                          textSize: 11,
                          height: 100,
                          width: 400,
                          minWidth: 400
                        }}
                      />

                      {openConfirmationPopup && (
                        <CustomConfirmationPopup
                          open={openConfirmationPopup}
                          setOpen={setOpenConfirmationPopup}
                          onClose={closeConfirmationPopup}
                          onConfirm={onConfirm}
                          type='delete'
                          cancelText='No'
                          confirmText='Yes'
                          title='Delete Education'
                          content='Are you sure you want to delete this education?'
                        />
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box>
              <AddAnotherButton className='add-another-btn' onClick={handleOpenPopup} variant='text'>
                +{data?.contact_education_details?.length === 0 ? t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`) : t(`${TRANS_KEYS.CONTACTS_ADD_ANOTHER}:key`)}
              </AddAnotherButton>
            </Box>
          </Box>
          {isPopupOpen && (
            <AddEductionAndExperience
              title={`${isEdit ? 'Update' : 'Add'} Education Experience`}
              setIsPopupOpen={setIsPopupOpen}
              isPopupOpen={isPopupOpen}
              data={isEdit ? data?.contact_education_details[indexValue] : null}
              setIsEdit={setIsEdit}
              isEditing={isEdit}
              register={register}
              unregister={unregister}
              setValue={setValue}
              watch={watch}
              handleSave={onSave}
            />
          )}
        </Box>
        <Divider variant='middle' />

        <Box className='section-details m-2'>
          <Box className='language-section'>
            <CustomTable
              headerData={[t(`${TRANS_KEYS.CONTACTS_LANGUAGES}:key`), t(`${TRANS_KEYS.CONTACTS_LEVEL_OF_EXPERTISE}:key`)]}
              title={tr.t('common.languages')}
              handleAnotherRow={handleAddLanguage}
              isEditing={isEditingLanguages}
              rows={language}
              disable={3}
              onEditChange={handleEditLanguage}
              showAddAnotherButton={false}
              customTableId={'view-contact-wfull'}
              headerSize={'fs-12'}
              titleSize={'fs-13'}
              updateField={updateField}
              dataList={languageList}
              saveDataKey={'contact_languages'}
            >
              {isEditingLanguages ? (
                language?.length === 0 ? (
                  <TableRow>
                    <TableCell align='center' className='p-2'>
                      <Box className='fs-11'>-</Box>
                    </TableCell>
                    <TableCell align='center' className='p-2'>
                      <Box className='fs-11'>-</Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  language?.map((selectedLanguages, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align='center' className='p-2'>
                          <Box className='fs-11'>{selectedLanguages?.language?.name || ''}</Box>
                        </TableCell>
                        <TableCell align='center' className='p-2'>
                          <Box className='fs-11'> {selectedLanguages?.ign_translate_value?.short_desc || ''} </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )
              ) : (
                language?.map((selectedLanguages, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align='center' className='input-field-data justify-center align-center p-2' sx={{ width: '16vw' }}>
                        <Box className='language-dropdown viewcontact-icon-style'>
                          <LanguagesSelection
                            isDrawer={true}
                            defaultValue={selectedLanguages?.language?.name || ''}
                            onChange={(e, data) => handleLanguageChange(index, 'language', data)}
                            label={t(`${TRANS_KEYS.CONTACTS_LANGUAGES}:key`)}
                            selectedList={language}
                            setLanguageList={setLanguageList}
                            viewAddress={true}
                          />
                        </Box>
                      </TableCell>
                      <TableCell align='center' className='input-field-data justify-center align-center p-2' sx={{ width: '16vw' }}>
                        <Box className='language-dropdown viewcontact-icon-style'>
                          <LevelOfExpertiseSelection
                            isDrawer={true}
                            defaultValue={selectedLanguages?.expertise || ''}
                            onChange={(e, data) => {
                              handleLanguageChange(index, 'expertise', data && data?.field_value ? data.field_value : null);
                            }}
                            label={t(`${TRANS_KEYS.CONTACTS_LEVEL_OF_EXPERTISE}:key`)}
                            viewAddress={true}
                          />
                        </Box>
                      </TableCell>
                      {language?.length > 1 && (
                        <TableCell className='remove-another-button d-flex align-center pt-3 p-2'>
                          <CloseIcon onClick={() => handleRemoveLanguage(index)} className='table-close-icon' />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              )}
            </CustomTable>
          </Box>
        </Box>

        <Divider variant='middle' />

        <Box className='section-details m-2'>
          <Box className='language-section'>
            <CustomTable
              headerData={[t(`${TRANS_KEYS.CERTIFICATION_TYPE}:key`), t(`${TRANS_KEYS.CERTIFICATION_TITLE}:key`), t(`${TRANS_KEYS.CERTIFICATION_EXPIRE_ON}:key`)]}
              title={tr.t('common.ceritAndLicense')}
              handleAnotherRow={handleAddCertificate}
              isEditing={isEditingCertificate}
              rows={certificate}
              disable={3}
              onEditChange={handleEditCertificate}
              showAddAnotherButton={false}
              customTableId={'view-contact-wfull'}
              headerSize={'fs-12'}
              titleSize={'fs-13'}
              updateField={updateField}
              saveDataKey={'contact_certificate'}
            >
              {isEditingCertificate ? (
                certificate?.length === 0 ? (
                  <TableRow>
                    <TableCell align='center' className='p-2'>
                      <Box className='fs-11'>-</Box>
                    </TableCell>
                    <TableCell align='center' className='p-2'>
                      <Box className='fs-11'>-</Box>
                    </TableCell>
                    <TableCell align='center' className='p-2'>
                      <Box className='fs-11'>-</Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  certificate &&
                  certificate?.map((selectedCertificate, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align='center' className='p-2'>
                          <Box className='fs-11'>{selectedCertificate?.certification_type || ''}</Box>
                        </TableCell>
                        <TableCell align='center' className='p-2'>
                          <Box className='fs-11'>{selectedCertificate?.certifications || ''}</Box>
                        </TableCell>
                        <TableCell align='center' className='p-2'>
                          <Box className='fs-11'> {selectedCertificate?.expire_on || ''} </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )
              ) : (
                certificate?.map((selectedCertificate, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align='center' className='input-field-data-medium justify-center align-center col-2' sx={{ width: '13vw' }}>
                        <Box className='viewcontact-icon-style'>
                          <CertificationTypeSelection
                            isDrawer={true}
                            defaultValue={selectedCertificate?.certification_type || ''}
                            onChange={(e, data) => {
                              handleChangeCertification(index, 'certification_type', data && data?.field_value ? data.field_value : null);
                            }}
                            label={t(`${TRANS_KEYS.CERTIFICATION_TYPE}:key`)}
                            isApplyWidth={true}
                          />
                        </Box>
                      </TableCell>
                      <TableCell align='center' className='input-field-data-large  justify-center align-center p-2 col-6'>
                        <Box className='viewcontact-icon-style'>
                          <CustomInputField
                            value={selectedCertificate?.certifications || ''}
                            onChange={e => handleChangeCertification(index, 'certifications', e.target.value)}
                            label={t(`${TRANS_KEYS.CONTACT_CERTIFICATION_AND_LICENSE}:key`)}
                          />
                        </Box>
                      </TableCell>
                      <TableCell align='center' className='input-field-data justify-center align-center p-2 col mt-1'>
                        <Box className='viewcontact-icon-style'>
                          <DatePickerYear
                            value={selectedCertificate?.expire_on || ''}
                            label={t(`${TRANS_KEYS.CERTIFICATION_EXPIRE_ON}:key`)}
                            className='input-field-data'
                            onChange={e => handleChangeCertification(index, 'expire_on', e)}
                            showOnlyYear={true}
                          />
                        </Box>
                      </TableCell>
                      {certificate?.length > 1 && (
                        <TableCell className='remove-another-button d-flex align-center pt-3 p-2 '>
                          <CloseIcon onClick={() => handleRemoveCertificate(index)} className='table-close-icon' />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              )}
            </CustomTable>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ViewEducationLanguages.propTypes = {
  data: PropTypes.object,
  register: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  values: PropTypes.object,
  watch: PropTypes.func,
  handleSave: PropTypes.func,
  defaultValues: PropTypes.object,
  updateField: PropTypes.func,
  getContactDetails: PropTypes.func,
  setIsPopupOpen: PropTypes.func,
  isPopupOpen: PropTypes.func
};

export default ViewEducationLanguages;
