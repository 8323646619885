import TabPanel from './TabPanel';

//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Box, Tab, Tabs } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import IconBreadcrumbs from '../BreadCrumbs/BreadCrumbs';

//----------------------------------------------// Internal Imports // -------------------------------------------------

const AntTabs = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.background.main}`,
    backgroundColor: theme.palette.common.white
  },
  indicator: {
    backgroundColor: theme.palette.heading.main
  }
}))(Tabs);

const AntTab = withStyles(() => ({
  selected: {}
}))(props => <Tab disableRipple {...props} />);

const SubTabs = ({
  subTabs,
  activeSubTab,
  onTabContentClick,
  children,
  navLinks = [],
  disableRipple = true,
  showBreadcrumbs = false,
  isDrawer = false,
  isHeaderNav,
  drawerLayoutClass = 'drawer-tab-layout'
}) => {
  const handleChange = (event, newValue) => {
    onTabContentClick(newValue);
  };
  const buildTabDetails = tabItem => {
    const childrenList = Array.isArray(children) ? children : [children];
    return childrenList
      .filter(item => item)
      .map((item, index) => {
        return (
          <TabPanel key={index} index={item.props.childIndex} value={subTabs[activeSubTab]?.label}>
            <Box className={`tab-layout-content  ${isDrawer ? (isHeaderNav === 'closed' ? 'drawer-tab-layout-close' : drawerLayoutClass) : 'p-4'}`}>
              {showBreadcrumbs && (
                <Box className='mb-3'>
                  <IconBreadcrumbs tabItem={tabItem} navLinks={navLinks}></IconBreadcrumbs>
                </Box>
              )}
              {item}
            </Box>
          </TabPanel>
        );
      });
  };
  return (
    <Box id='sub-tab'>
      <AntTabs value={activeSubTab} variant='scrollable' scrollButtons='on' onChange={handleChange} className='ml-4'>
        {subTabs.map((subTab, index) => (
          <AntTab key={index} label={subTab?.label} disabled={subTab.disabled} disableRipple={disableRipple} className={`header-font px-4 py-2 ${isDrawer ? 'drawer-tab' : 'non-drawer-tab'}`} />
        ))}
      </AntTabs>
      {subTabs.map((subTab, index) => (
        <TabPanel key={index} value={activeSubTab} index={index}>
          <Box className='subTabPanelContent'>{buildTabDetails(subTab)}</Box>
        </TabPanel>
      ))}
    </Box>
  );
};

SubTabs.propTypes = {
  children: PropTypes.node,
  subTabs: PropTypes.array,
  activeSubTab: PropTypes.obj,
  onTabContentClick: PropTypes.func,
  navLinks: PropTypes.array,
  disableRipple: PropTypes.bool,
  showBreadcrumbs: PropTypes.bool,
  isDrawer: PropTypes.bool,
  isHeaderNav: PropTypes.string,
  drawerLayoutClass: PropTypes.string
};

export default SubTabs;
