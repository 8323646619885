//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Typography, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import RichTextPopup from '../../../../../RichTextPopup';

import { searchDataApi } from '../../../../../../services/ApiService';
import { SUCCESS, ERROR, PATCH } from '../../../../../../services/constantService';
import { VALIDATION_MESSAGE, unableMessage, successMessage } from '../../../../../../services/MessageService';
function PositionOverview(props) {
  const { register, setValue, removeHtmlTags, currentValues, searchInfo, sfpa, setSfpaData } = props;
  if (currentValues.position_overview === undefined) {
    currentValues.position_overview = sfpa.position_overview;
  }
  const { enqueueSnackbar } = useSnackbar();
  const [editorHtml, setEditorHtml] = useState(currentValues.position_overview);
  const [isLastSaveSuccess, setLastSaveSuccess] = useState(true);

  useEffect(() => {
    register('position_overview');
    setEditorHtml(currentValues.position_overview);
  }, [currentValues.position_overview, register]);

  useEffect(() => {
    setValue('position_overview', editorHtml);
  }, [editorHtml, setValue]);

  const savePositionOverview = async (payload, skipMessage, setAutoSaveLoading) => {
    const plainText = removeHtmlTags(payload.position_overview);
    delete payload.old;
    let returnResult;
    if (plainText?.length === 0) {
      payload.position_overview = plainText;
    }
    if (setAutoSaveLoading) {
      setAutoSaveLoading(true);
    }
    const { status, data } = await searchDataApi(PATCH, searchInfo.id, payload, 'search-autosave-field');
    if (status && status === 200) {
      payload.updated_at = data.result.updated_at;
      setSfpaData(payload);
      const message = successMessage('Position Overview', VALIDATION_MESSAGE.updated_message);
      !skipMessage && enqueueSnackbar(message, { variant: SUCCESS });
      setLastSaveSuccess(true);
      returnResult = true;
    } else {
      const message = unableMessage('Position Overview', 'update');
      enqueueSnackbar(data.message || message, { variant: ERROR });
      setLastSaveSuccess(false);
      returnResult = false;
    }
    if (setAutoSaveLoading) {
      setTimeout(() => {
        setAutoSaveLoading(false);
      }, 500);
    }
    return returnResult;
  };

  return (
    <div
      style={{
        marginTop: '20px',
        marginLeft: '20px',
        marginBottom: '0px',
        padding: '0px'
      }}
    >
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{ float: 'left', fontWeight: 'bold' }}>Position Overview</Typography>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className='text-view-value p-0 w-100'>
            <div className='outlined-border transform p-0'>
              <RichTextPopup
                onChange={setEditorHtml}
                value={editorHtml}
                onSave={savePositionOverview}
                title='Position Overview'
                name='position_overview'
                autoSave={savePositionOverview}
                saveStatus={isLastSaveSuccess}
                updatedDate={currentValues.updated_at}
                restrictHeight={true}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

PositionOverview.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  sfpa: PropTypes.func,
  currentValues: PropTypes.object,
  searchInfo: PropTypes.object,
  removeHtmlTags: PropTypes.func,
  setSfpaData: PropTypes.func
};
export default PositionOverview;
