//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography, Button } from '@mui/material';

//----------------------------------------------// External Imports // -------------------------------------------------
import CompanySelection from '../../../../../AddContact/Components/CompanySelection';

export default function TargetCompanies(props) {
  const { register, setValue, currentValues = {}, unregister, readOnly } = props;
  const [companyTargets, setCompanyTargets] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);

  useEffect(() => {
    setCompanyTargets(currentValues.target_companies || []);
    register('target_companies');
    return () => {
      unregister('target_companies');
    };
  }, [currentValues.target_companies, register, setValue]);

  // useEffect(() => {
  //   setValue('target_companies', companyTargets);
  //   if (updateCount === 0) {
  //     return () => {
  //       unregister('target_companies');
  //     };
  //   }
  // }, [companyTargets, setValue])

  const updateTargetCompany = (index, data) => {
    const currentCompanyTargets = [...companyTargets];
    currentCompanyTargets[index] = data;
    setCompanyTargets(currentCompanyTargets);
    if (updateCount > 0) {
      register('target_companies');
      setValue('target_companies', currentCompanyTargets);
    }
    setUpdateCount(updateCount + 1);
  };

  const deleteTargetCompany = index => {
    const currentCompanyTargets = [...companyTargets];
    const callApi = Object.keys(currentCompanyTargets[index])?.length > 0 ? true : false;
    currentCompanyTargets.splice(index, 1);
    setCompanyTargets(currentCompanyTargets);
    if (callApi) {
      register('target_companies');
      setValue('target_companies', currentCompanyTargets);
    }
  };

  const addTargetCompany = () => {
    const currentCompanyTargets = [...companyTargets, {}];
    setCompanyTargets(currentCompanyTargets);
  };
  return (
    <div className={`section-content ${readOnly ? 'read-only' : ''}`}>
      <Typography className='section-label'>Target Companies</Typography>
      {companyTargets &&
        companyTargets.map((item, index) => (
          <Grid container spacing={1} key={index}>
            <Grid container>
              <Grid item sm={10} style={{ marginTop: '-2%' }}>
                <div className='d-flex flex-grow-1'>
                  <CompanySelection
                    freeSolo={true}
                    value={item || null}
                    defaultValue={item}
                    onInputChange={(e, value) => {
                      updateTargetCompany(index, value);
                    }}
                    onChange={(e, company) => {
                      const name = company ? company.name : '';
                      updateTargetCompany(index, name);
                    }}
                    startAdornment={
                      <Typography style={{ padding: '0 5px' }} color='primary'>
                        {index + 1}.
                      </Typography>
                    }
                    className='input-form-field input-field-old'
                    placeholder={'Search'}
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </Grid>
              <DeleteIcon style={{ marginTop: '15px' }} onClick={() => deleteTargetCompany(index)} className='cursor-pointer' />
            </Grid>
          </Grid>
        ))}
      <Button variant={'text'} startIcon={<AddCircleIcon />} onClick={addTargetCompany} color='primary'>
        Add
      </Button>
    </div>
  );
}

TargetCompanies.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  readOnly: PropTypes.bool,
  currentValues: PropTypes.object,
  unregister: PropTypes.func
};
