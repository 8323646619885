import { Close } from '@mui/icons-material';
import { Box, Divider, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  p: 3,
  borderRadius: '8px',
  minWidth: '40vw'
};
function CustomModalWithHeader(props) {
  const { isOpen, onClose, children, style = {}, label = '', closeIcon = false, colorClass = '' } = props;
  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby='parent-modal-title' aria-describedby='parent-modal-description'>
      <Box sx={{ ...modalStyle, ...style }}>
        <Box className='mb-2 d-flex justify-content-between align-items-center'>
          <Typography className={colorClass} variant='h6'>
            {label}
          </Typography>
          {closeIcon && <Close onClick={onClose} />}
        </Box>
        <Divider />
        {children}
      </Box>
    </Modal>
  );
}

CustomModalWithHeader.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.element,
  label: PropTypes.string,
  closeIcon: PropTypes.bool,
  colorClass: PropTypes.string
};

export default CustomModalWithHeader;
