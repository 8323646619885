//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useCallback, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import update from 'immutability-helper';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import ItemTypes from '../Draggable/ItemTypes';
import InputField from '../../../../../common/InputField';
import DraggableComponent from '../Draggable/DraggableComponent';

export default function PlaceofInterest(props) {
  const { register, setValue, currentValues } = props;
  if (currentValues.cs_location_placeofinterest?.length === 0) {
    currentValues.cs_location_placeofinterest = [{}];
  }
  const [placeOfInterest, setPlaceOfInterest] = useState([{}]);
  const skillIndex = 0;
  useEffect(() => {
    setPlaceOfInterest(currentValues.cs_location_placeofinterest || []);
    register('cs_location_placeofinterest');
  }, [currentValues, register]);

  useEffect(() => {
    setValue('cs_location_placeofinterest', placeOfInterest);
  }, [placeOfInterest, setValue]);

  const updateplaceofinterest = (index, name, data) => {
    const currentplaceofinterest = [...placeOfInterest];
    currentplaceofinterest[index][name] = data;
    setPlaceOfInterest(currentplaceofinterest);
  };

  const deleteplaceofinterest = index => {
    const currentplaceofinterest = [...placeOfInterest];
    currentplaceofinterest.splice(index, 1);
    setPlaceOfInterest(currentplaceofinterest);
  };

  const addplaceofinterest = () => {
    const currentplaceofinterest = [...placeOfInterest, {}];
    setPlaceOfInterest(currentplaceofinterest);
  };

  const onSkillElementsChange = useCallback((skillIndex, elements) => {
    setPlaceOfInterest(elements);
  }, []);

  const moveElement = useCallback(
    (dragIndex, hoverIndex) => {
      const dragSkillElement = placeOfInterest[dragIndex];
      let currentplaceofinterest = update(placeOfInterest, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSkillElement]
        ]
      });
      onSkillElementsChange(skillIndex, currentplaceofinterest);
    },
    [placeOfInterest, skillIndex, onSkillElementsChange]
  );
  return (
    <div className='section-content'>
      <Typography className='section-label'>Place of Interest</Typography>
      <DndProvider backend={HTML5Backend}>
        {placeOfInterest.map((item, index) => (
          <DraggableComponent
            key={index}
            index={index}
            id={item.id || index}
            moveNode={moveElement}
            componentType={ItemTypes.POI}
            content={(dragHandle, preview) => (
              <div key={index} ref={preview} style={{ marginBottom: 10 }}>
                <Typography className='sub-section-label'>Place of Interest Details {index + 1}</Typography>
                <div className='d-flex align-items-center'>
                  <span ref={dragHandle}>
                    <DragIndicatorOutlinedIcon style={{ padding: '0px', color: 'gray' }} />
                  </span>
                  <InputField
                    label='Title'
                    placeholder='Title'
                    className='input-form-field'
                    value={item.title || ''}
                    InputLabelProps={{ focused: true }}
                    onChange={e => {
                      updateplaceofinterest(index, 'title', e.target.value);
                    }}
                  />
                  <InputField
                    label='Description'
                    placeholder='Description'
                    multiline
                    rowsMax='10'
                    className='input-form-field'
                    value={item.description || ''}
                    InputLabelProps={{ focused: true }}
                    onChange={e => {
                      updateplaceofinterest(index, 'description', e.target.value);
                    }}
                  />
                  <InputField
                    label='Website url'
                    placeholder='Website url'
                    className='input-form-field'
                    value={item.website_url || ''}
                    InputLabelProps={{ focused: true }}
                    onChange={e => {
                      updateplaceofinterest(index, 'website_url', e.target.value);
                    }}
                  />
                  <DeleteIcon fontSize='small' className='cursor-pointer' onClick={() => deleteplaceofinterest(index)} />
                </div>
              </div>
            )}
          />
        ))}
      </DndProvider>
      {placeOfInterest?.length < 10 ? (
        <Button variant={'text'} startIcon={<AddCircleIcon />} onClick={addplaceofinterest} color='primary'>
          Add Place of Interest
        </Button>
      ) : null}
    </div>
  );
}

PlaceofInterest.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object
};
