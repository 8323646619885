//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState, useRef } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Checkbox, TextField, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import UserSelection from '../../../UserSelection';
import { isEmpty } from '../../../../utils/common';
import { formatDate } from '../../../../utils/date';

export default function WorkbenchInfoEdit(props) {
  const { register, setValue, watch, workbench, count, option, workbenchLockAccess } = props;
  const [workbenchType, setWorkbenchType] = useState(workbench.type);
  const usersRef = useRef();
  const typeRef = useRef();

  const isDataNull = count === 0 ? false : true;
  useEffect(() => {
    register('archive_date');
    register('tagged_users', { required: true, validate: d => !isEmpty(d) });
    register('type', { required: true });
  });
  const handleChange = (name, value) => {
    setValue(name, value);
  };

  return (
    <div className='input-form' style={{ width: '66.6%' }}>
      <div className='d-flex'>
        <TextField
          // required
          name='name'
          {...register('name')}
          label={
            <label>
              Workbench Name<span className='MuiInputLabel-asterisk'>*</span>
            </label>
          }
          placeholder='Workbench Name'
          className='input-form-field input-field-old'
          InputLabelProps={{ focused: true }}
        />
        <Autocomplete
          className='input-form-field input-field-old'
          defaultValue={workbench.type}
          disabled={isDataNull}
          renderInput={params => (
            <TextField
              {...params}
              inputRef={typeRef}
              variant='standard'
              InputLabelProps={{ focused: true }}
              // required={true}
              label={
                <label>
                  Type<span className='MuiInputLabel-asterisk'>*</span>
                </label>
              }
              placeholder='Type'
              name='type'
            />
          )}
          onChange={(e, data) => {
            setWorkbenchType(data);
            setValue('type', data);
          }}
          options={['Contacts', 'Searches']}
        />
      </div>
      <div className='d-flex align-items-center'>
        {workbenchType === 'Searches' && (
          <FormControl className='' style={{ minWidth: '75px' }}>
            <FormLabel focused={true} className='MuiInputLabel-shrink'>
              Rep List
            </FormLabel>
            <FormControlLabel
              className='checkbox-container'
              label={'Yes'}
              control={<Checkbox className='checkbox-component' style={{ padding: '0px 10px' }} defaultChecked={workbench.is_replist || false} name='is_replist' {...register('is_replist')} />}
            />
          </FormControl>
        )}
        <UserSelection
          multiple
          openOnFocus
          defaultValue={workbench.tagged_users}
          size='small'
          label={
            <label>
              Users<span className='MuiInputLabel-asterisk'>*</span>
            </label>
          }
          className='input-form-field input-field-old'
          // required={true}
          inputRef={usersRef}
          option={option}
          filterSelectedOptions={true}
          InputLabelProps={{ focused: true }}
          onChange={(e, data) => {
            setValue('tagged_users', data);
          }}
        />
        <KeyboardDatePicker
          autoOk
          //disableToolbar
          className='input-form-field input-field-old'
          variant='inline'
          format='dd/MM/yyyy'
          label='Archive Date'
          placeholder='Archive Date'
          value={formatDate(watch('archive_date')) || null}
          onChange={date => {
            handleChange('archive_date', formatDate(date));
          }}
          InputLabelProps={{ focused: true }}
        />
      </div>
      <TextField
        placeholder={'Description here'}
        name='description'
        {...register('description')}
        label={'Description'}
        className='input-form-field input-field-old w-100'
        InputLabelProps={{ focused: true }}
      />
      <div className='d-flex align-items-center'>
        <FormControl className='' style={{ minWidth: '75px' }}>
          <FormLabel focused={true} className='MuiInputLabel-shrink'>
            Lock Workbench
          </FormLabel>
          <FormControlLabel
            className='checkbox-container'
            label={'Yes'}
            disabled={!workbenchLockAccess}
            control={<Checkbox className='checkbox-component' style={{ padding: '0px 10px' }} defaultChecked={workbench.is_locked || false} name='is_locked' {...register('is_locked')} />}
          />
        </FormControl>
      </div>
    </div>
  );
}

WorkbenchInfoEdit.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  workbench: PropTypes.object,
  count: PropTypes.number,
  option: PropTypes.array,
  workbenchLockAccess: PropTypes.bool
};
