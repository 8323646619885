//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// import { Auth } from 'aws-amplify';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { MoreHoriz } from '@mui/icons-material';
import { getUserSelector } from '../../../selectors';
import { API, END_POINTS_URL } from '../../../services/constantService';
import UserMenu from '../UserMenu';
import './index.scss';

const HeaderProfile = props => {
  const { className, isMoreOption = true, showMyProfile = true } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileUrl, setProfileUrl] = useState(null);
  const user = useSelector(getUserSelector);

  useEffect(() => {
    const accessToken = async () => {
      const srcUrl = `${API.users}/${user.id}/${END_POINTS_URL.PROFILE_URL}`;
      setProfileUrl(srcUrl);
    };
    if (user?.profile_image_id) {
      accessToken();
    }
  }, [user]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={`d-flex align-items-center ${className}`} onClick={handleClick}>
        {profileUrl ? (
          <span className='profile-icon d-flex align-items-center'>
            <img src={profileUrl} alt='Profile' className='user-profile-img' />
          </span>
        ) : (
          <span className='profile-icon profile-default-icon d-flex align-items-center'>
            <AccountCircleIcon fontSize='inherit' />
          </span>
        )}
        {/* <span className="profile-name">{user?.name || ""}</span> */}
        {isMoreOption && (
          <span className='profile-dropdown'>
            <MoreHoriz fontSize='inherit' />
          </span>
        )}
      </div>
      <UserMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} handleClose={handleClose} open={Boolean(anchorEl)} showMyProfile={showMyProfile} />
      {/* <Menu
        className="header-menu-logout"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </Menu> */}
    </>
  );
};

HeaderProfile.propTypes = {
  className: PropTypes.string,
  enqueueSnackbar: PropTypes.func,
  isMoreOption: PropTypes.bool,
  showMyProfile: PropTypes.bool
};

export default HeaderProfile;
