//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Fragment, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import AddReference from './AddReference';
import ReferencesGrid from './ReferencesGrid';
import { PAGE_LIMIT, SUCCESS_STATUS_CODE } from '../utils';
import { loadColumnStateFromLocalStorage } from '../../../Containers/Commons/Utils';
import EditReference from './EditReference';
import { getReferenceFilterQuery } from './utils';
import ReportOptions from '../../common/ReportOptions';
import { API, GET, ERROR, WARNING } from '../../../services/constantService';
import { contactReferencesApi } from '../../../services/ApiService';
import { unableMessage, notFoundMessage } from '../../../services/MessageService';

let gridApi, columnApi;

export default function References(props) {
  const { contactId, contact, disabled = false, editable = true } = props;
  const [showAdd, setShowAdd] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [currentReference, setCurrentReference] = useState(null);
  // const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [reportOptions, setReportOptions] = useState(null);
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [filterValue, setFilterValue] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleAdd = () => {
    setShowAdd(true);
  };

  const handleClose = added => {
    setShowAdd(false);
    if (added) {
      gridApi.onFilterChanged();
    }
  };

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    loadColumnStateFromLocalStorage('contactReferences', { columnApi, gridApi });
  };

  const resetFilter = async () => {
    gridApi.setFilterModel(null);
    const customFilterField = columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item?.filter === 'CustomFilter') {
        gridApi.destroyFilter(item.field);
      }
    });
  };

  const resetSort = async () => {
    gridApi.setSortModel(null);
  };

  const dataSource = {
    getRows: async params => {
      params.filterModel && setFilterValue(params.filterModel);
      setShowFilterCount(Object.keys(params.filterModel)?.length);
      gridApi.showLoadingOverlay();
      let sub_route = `${contactId}/references?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}`;
      if (Object.keys(params.filterModel)?.length) {
        sub_route = sub_route.concat(getReferenceFilterQuery(params.filterModel));
      }
      if (params.sortModel?.length) {
        sub_route = sub_route.concat(`&sortOn=${params.sortModel[0].colId}&sortType=${params.sortModel[0].sort.toUpperCase()}`);
      }
      const { status, data } = await contactReferencesApi(GET, sub_route);
      if (status === SUCCESS_STATUS_CODE) {
        if (data?.paging?.totalCount === 0) {
          const message = notFoundMessage('records');
          enqueueSnackbar(message, { variant: WARNING });
        }
        params.successCallback(data.data, data.paging?.totalCount);
        setRowCount(data.paging?.totalCount);
      } else {
        params.failCallback();
      }
      gridApi.hideOverlay();
    },
    rowCount: null
  };

  const handleEdit = data => {
    setCurrentReference(data);
    setShowEdit(true);
  };

  const handleEditClose = edited => {
    setCurrentReference(null);
    setShowEdit(false);
    if (edited) {
      gridApi.onFilterChanged();
    }
  };

  const showEditReference = async referenceId => {
    const sub_route = `references/${referenceId}`;
    const { status, data } = await contactReferencesApi(GET, sub_route);
    if (status === 200) {
      handleEdit(data);
    } else {
      const message = unableMessage('reference details', 'get');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };

  const handleCheckboxChange = e => {
    const ev = e;
    gridApi.forEachNode(node => {
      node.setSelected(ev.target.checked);
    });
  };

  const onRowSelected = e => {
    if (!e.node.selected) {
      // setIsAllRowsSelected(false)
    }
    setSelectedRowsCount(gridApi.getSelectedRows()?.length);
  };

  const getReferenceIds = () => {
    const referenceIds = [];
    gridApi.forEachNode(node => {
      if (node.selected) {
        referenceIds.push(node.data.id);
      }
    });
    return referenceIds;
  };

  const handleReport = () => {
    const referenceIds = getReferenceIds();
    // 744 bug for filtered records with selectAll
    /*     if (isAllRowsSelected && selectedRowsCount > 1) {
          setReportOptions({ params: { contactId }, url: `${API.reports} /contact-reference-consolidated` })
        } else */

    if (selectedRowsCount === 1) {
      setReportOptions({ params: { referenceId: referenceIds[0] }, url: `${API.reports}/contact-reference` });
    } else {
      setReportOptions({ params: { referenceIds }, url: `${API.reports}/contact-reference-consolidated` });
    }
  };

  const handleReportClose = () => {
    setReportOptions(null);
  };

  // const onCellValueChanged = (params) => {
  //   params.newValue !== params.oldValue && gridApi.onFilterChanged()
  // }

  return (
    <Fragment>
      {showAdd && <AddReference open={showAdd} handleClose={handleClose} showEditReference={showEditReference} contact={contact} contactId={contactId} />}
      {showEdit && <EditReference contactId={contactId} handleClose={handleEditClose} open={showEdit} reference={currentReference} />}
      {reportOptions && <ReportOptions onClose={handleReportClose} url={reportOptions.url} params={reportOptions.params} />}
      <div className='d-flex justify-content-between'>
        <div style={{ padding: '20px 20px 0 20px' }}>
          <Button variant='outlined' color='primary' disabled={disabled} className='mr-2' onClick={handleAdd}>
            Add Reference
          </Button>
          <Button variant='outlined' color='primary' onClick={handleReport} disabled={!selectedRowsCount}>
            Reference Report
          </Button>
        </div>
        <div className='d-flex  align-items-center' style={{ padding: '20px 20px 0 20px' }}>
          <div className='action-container' style={{ minWidth: 0 }} onClick={() => resetFilter()}>
            <span className='action-text'>Reset Filter</span>
          </div>
          <div className='action-container' style={{ minWidth: 0 }} onClick={() => resetSort()}>
            <span className='action-text'>Reset Sort</span>
          </div>
          <Typography>Total count: {rowCount}</Typography>
        </div>
      </div>
      <ReferencesGrid
        onGridReady={onGridReady}
        dataSource={dataSource}
        columnApi={columnApi}
        gridApi={gridApi}
        handleEdit={handleEdit}
        handleCheckboxChange={handleCheckboxChange}
        onRowSelected={onRowSelected}
        //onCellValueChanged={onCellValueChanged}
        disabled={disabled}
        editable={editable}
        filterValue={filterValue}
        showFilterCount={showFilterCount}
      />
    </Fragment>
  );
}

References.propTypes = {
  contact: PropTypes.object,
  contactId: PropTypes.string,
  disabled: PropTypes.bool,
  editable: PropTypes.bool
};
