import React from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { Button, Checkbox, Typography } from '@mui/material';
import { columnDefs } from './utils';
import ActionsPopover from '../Contacts/ActionsPopover';
import DeletePopup from '../Commons/DeletePopup';
import { DELETE_CONFIRMATION_POPUP_MESSAGE, WARNING } from '../../services/constantService';
import { API, ERROR, GET, DELETE, SUCCESS } from '../../services/constantService';
import { dashboardDataApi } from '../../services/ApiService';
import { useSnackbar } from 'notistack';
import { unableMessage, successMessage, notFoundMessage } from '../../services/MessageService';
import ConfirmationPopup from '../../components/ConfirmationPopup';
import Loader from '../../components/common/Loader';
import { ProductSettingsApi } from '../../services/ApiService';
import { Link } from 'react-router-dom';
import withRouter from '../../utils/withRouter';
import { PAGE_LIMIT, SUCCESS_STATUS_CODE } from '../Commons/Utils';
import { getFilterParamStringForTranslateValue } from './utils';
import { CustomLoadingOverlayComponent } from '../Commons/Utils';

import DocumentType from '../../components/ViewDocumentTypes/DocumentType';
//-----------------------------------------------------------// Internal Imports // ------------------------------

let gridApi;
const DocumentTypes = () => {
  const [isDeleteTranslateOpen, setDeleteTranslatePopup] = useState(false);
  const [orgId, setOrgId] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [isRemoveSelectedAll, setRemoveSelectedAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [isSingleDelete, setSingleDelete] = useState(false);
  const [rowCount, setRowCount] = useState(null);
  const [documentTypeView, setDocumentTypeView] = useState(false);
  const [documentTypeData, setDocumentTypeData] = useState(null);

  // useEffect(() => {
  //   getData()
  // }, [])

  // const getData = async () => {
  //   try {
  //     setIsLoading(true);
  //     const url = `${API.product_setting}/document_type`
  //     const { status, data } = await dashboardDataApi(GET, url)
  //     if (status === 200) {
  //       setData(data.data.rows)
  //       setIsLoading(false);
  //     } else {
  //       const message = unableMessage("sign token", "get")
  //       enqueueSnackbar(data?.message || message, { variant: ERROR });
  //       setIsLoading(false);
  //     }
  //   } catch (err) {
  //     console.log('error in Fetch Token::', err)
  //   }

  // }

  const onGridReady = params => {
    params.columnApi.autoSizeColumns();
    gridApi = params.api;
  };

  const resetFilter = async () => {
    gridApi.setFilterModel(null);
  };
  const resetSort = async () => {
    gridApi.setSortModel(null);
  };

  const defaultColDef = {
    sortable: true,
    editable: false,
    filter: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    width: 100,
    resizable: true,
    flex: 1,
    minWidth: 100
  };

  const HeaderCheckbox = () => {
    return <Checkbox style={{ padding: 0, width: 16, height: 16, color: 'white' }} size='small' color='primary' onChange={e => handleChange(e)} />;
  };

  const setSelectAllRows = isRowsSelected => {
    //setIsAllRowsSelected(isRowsSelected)
    gridApi.forEachNode(node => {
      node.setSelected(isRowsSelected);
    });
  };

  const handleChange = event => {
    const checked = event.currentTarget.checked;
    setSelectAllRows(checked);
  };

  const dataSource = {
    getRows: async params => {
      const { filterModel, sortModel } = params;
      let paginationString = `limit=${PAGE_LIMIT}&page=${params.endRow / 100}`;
      let url = '';
      url = `${API.product_setting}/document_type`;
      gridApi.showLoadingOverlay();
      if (sortModel?.length > 0) {
        paginationString = paginationString.concat(`&sortOn=${sortModel[0].colId}&sortType=${sortModel[0].sort.toUpperCase()}`);
      }
      if (Object.keys(filterModel)?.length) {
        paginationString = paginationString.concat(getFilterParamStringForTranslateValue(filterModel));
      }
      const { status, data } = await dashboardDataApi(GET, url, paginationString);
      if (status === SUCCESS_STATUS_CODE && data?.data) {
        if (data?.data?.count === 0) {
          const message = notFoundMessage('records');
          enqueueSnackbar(message, { variant: WARNING });
        }
        params.successCallback(data?.data?.rows, data?.data?.count);
        setRowCount(data?.data?.count);
      }
      gridApi.hideOverlay();
      setIsLoading(false);
    }
  };

  const ActionsRenderer = params => {
    const list = [
      {
        label: 'Delete',
        onClick: () => {
          setSingleDelete(true);
          setOrgId(params.data.id);
        }
      }
    ];
    return <ActionsPopover list={list} />;
  };

  const getSelectedIds = () => {
    const translateIds = [];
    gridApi.forEachNode(node => {
      if (node.selected) {
        translateIds.push(node.data.id);
      }
    });

    return translateIds;
  };

  const handleDeleteDocumentClose = () => {
    setDeleteTranslatePopup(false);
  };

  const handleDeleteSelectedItem = () => {
    setRemoveSelectedAll(true);
  };

  const handleConfirmPopupClose = () => {
    setRemoveSelectedAll(false);
    setSingleDelete(false);
  };

  const onViewPopupClose = refreshData => {
    if (refreshData) {
      gridApi.onFilterChanged();
    }
    setDocumentTypeView(false);
  };

  // const handleAddNew = () => {
  //   setIsAddNewOpen(true);
  // };

  // const handleCloseAddNew = () => {
  //   setIsAddNewOpen(false);
  // }

  // const deleteSelectItem = async (id) => {
  //   let sub_route = 'document_type_delete'
  //   const Id = [id];
  //   setIsLoading(true);
  //   const { status, data } = await ProductSettingsApi(
  //     DELETE,
  //     Id,
  //     sub_route
  //   );

  //   if (status === 200) {
  //     const message = successMessage("from document type", "Removed document");
  //     enqueueSnackbar(message, { variant: SUCCESS });
  //     setIsLoading(false);
  //     getData();
  //     return true;
  //   } else {
  //     const message = unableMessage("from document type", "Removed document");
  //     enqueueSnackbar(data?.message || message, { variant: ERROR });
  //     setIsLoading(false);
  //   }

  // }

  const DocumentTypeRenderer = params => {
    return (
      <Typography
        className='text-link'
        color='primary'
        onClick={() => {
          setDocumentTypeView(true);
          setDocumentTypeData(params.data);
          setOrgId(params.data.id);
        }}
      >
        {params.value}
      </Typography>
    );
  };

  const deleteSelectedItem = async () => {
    let sub_route = 'document_type_delete';
    const selectedRows = isSingleDelete ? [orgId] : getSelectedIds();
    //const selectedRows = getSelectedIds();
    setIsLoading(true);
    const { status, data } = await ProductSettingsApi(DELETE, selectedRows, sub_route);

    if (status === 200) {
      const message = successMessage('Document removed', 'from document type');
      enqueueSnackbar(message, { variant: SUCCESS });
      setIsLoading(false);
      setSingleDelete(false);
      setSelectAllRows(0);
      setRemoveSelectedAll(false);
      gridApi.onFilterChanged();
      return true;
    } else {
      const message = unableMessage('from document type', 'Removed document');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
      setSingleDelete(false);
      setIsLoading(false);
      setRemoveSelectedAll(false);
    }
  };
  return (
    <div className='d-flex flex-column w-100 h-100'>
      <Loader show={isLoading} />
      <Helmet>
        <title>Document Type</title>
      </Helmet>
      {isDeleteTranslateOpen ? (
        <DeletePopup
          onClose={handleDeleteDocumentClose}
          payload={{ failed: true }}
          id={orgId}
          module={'product-settings/translate_value_delete'}
          popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_DOCUMENT}
        />
      ) : null}
      {(isRemoveSelectedAll || isSingleDelete) && (
        <ConfirmationPopup message='Are you sure you want to remove the Document Type?' onClose={handleConfirmPopupClose} onConfirm={() => deleteSelectedItem()} />
      )}
      <div className=' d-flex p-4'>
        <div className='d-flex w-100 justify-content-start'>
          <Link to='/product-setup/document-type/add'>
            <Button className='mr-3' variant={'outlined'} color={'primary'}>
              Add New
            </Button>
          </Link>
          <Button className='mr-3' variant={'outlined'} color={'primary'} onClick={handleDeleteSelectedItem} disabled={selectedRowsCount === 0}>
            Delete Selected
          </Button>
        </div>
        <div className='d-flex align-items-center justify-content-end mr-4 w-100'>
          <div className='action-container' onClick={() => resetFilter()}>
            <span className='action-text'>Reset Filter</span>
          </div>
          <div className='action-container' onClick={() => resetSort()}>
            <span className='action-text'>Reset Sort</span>
          </div>

          <Typography>Total count: {rowCount}</Typography>
        </div>
      </div>
      <div className='list-view flex-grow-1 w-100'>
        <div id='myGrid' className='ag-theme-alpine'>
          <AgGridReact
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
            suppressHorizontalScroll={false}
            frameworkComponents={{
              HeaderCheckbox: HeaderCheckbox,
              ActionsRenderer: ActionsRenderer,
              CustomLoadingOverlayComponent,
              DocumentTypeRenderer: DocumentTypeRenderer
            }}
            datasource={dataSource}
            rowModelType={'infinite'}
            onGridReady={onGridReady}
            suppressRowClickSelection={true}
            // rowData={data}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            scrollbarWidth={12}
            columnDefs={columnDefs(enqueueSnackbar, setIsLoading)}
            defaultColDef={defaultColDef}
            rowSelection={'multiple'}
            getRowNodeId={data => data.id}
            onRowSelected={() => {
              setSelectedRowsCount(gridApi.getSelectedRows()?.length);
            }}
          ></AgGridReact>
        </div>
        {documentTypeView && <DocumentType visible={documentTypeView} onClose={onViewPopupClose} documentTypeData={documentTypeData} setDocumentTypeData={setDocumentTypeData} />}
      </div>
    </div>
  );
};

DocumentTypes.propTypes = {
  onClose: PropTypes.func
};

export default withRouter(DocumentTypes);
