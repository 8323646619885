//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { saveAs } from 'file-saver';

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Checkbox, Button, Typography } from '@mui/material';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import { useDispatch, useSelector } from 'react-redux';
//-----------------------------------------------------------// Internal Imports // ------------------------------
import { useSnackbar } from 'notistack';
import ColorLegend from '../../Commons/ColorLegend';
import { columnDefs, defaultColumns, StageRenderer, FQRenderer, ResumeRenderer, getFilterParamString } from './utils';
import { checkFilterAndSort, CompensationRenderer, sortByChronologicalOrder } from '../../../utils/common';
import {
  CustomLoadingOverlayComponent,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE,
  LinkedInRenderer,
  DateTimeRenderer,
  DateRenderer,
  saveColumnStateToLocalStorage,
  loadColumnStateFromLocalStorage,
  addContactsToWorkbench,
  localTimeZoneHandler
} from '../../Commons/Utils';
import AddCandidateByContact from './AddCandidateByContact';
import CopyCandidatesToSearch from './CopyCandidatesToSearch';
import QuickAddContact from './Components/QuickAddContact';
import '../../Commons/index.scss';
import './index.scss';
import ActivityHistories from '../../../components/ActivityLog/Components/GeneralInfo/Components/ActivityHistory/ActivityHistories';
import StageEditor from './Components/StageEditor';
import ActionsPopover from '../../Contacts/ActionsPopover';
import DocsPopup from '../../../components/ViewContact/NotesAttachments/DocsPopup';
import { checkContactFetchingStatus, ContactNameRenderer, EmailRenderer } from '../../Contacts/utils';
//import { HireLogicLinkRenderer } from '../../HireLogic/utils'
import Header from '../../../components/ViewSearch/Components/Header';
import LiveSearchMetrics from './LiveSearchMetrics';
import GenericCellEditor from '../../Commons/CellEditors/GenericCellEditor';
import RichTextBoxEditor from '../../Commons/CellEditors/RichTextBoxEditor';
import { RichTextPopupRendered } from '../../../utils/common';
import CustomFilter from '../../Commons/CustomFilter';
import ReasonEditor from '../../Commons/CellEditors/ReasonEditor';
import ReportOptions from '../../../components/common/ReportOptions';
import StatusReportWithOptions from '../../../components/common/ReportOptions/StatusReportWithOptions';
import ColumnFilter from '../../Commons/ColumnFilter';
import AthenaPopover from './Components/AthenaPopover';
import CandidateSuitePopover from './Components/CandidateSuitePopover';
import { formatDate } from '../../../utils/date';
import AthenaInvitePopUp from '../../../components/ViewSearch/Components/SFPA/Components/ClientAppAccess/AthenaInvitePopUp';
import CandidateSuiteInvitePopUp from '../../../components/ViewSearch/Components/SFPA/Components/ClientAppAccess/CandidateSuiteInvitePopUp';
import AddContactToWorkbench from '../../Commons/AddContactToWorkbench';
import RemoveContactFromWorkbench from '../../Commons/RemoveContactFromWorkbench';
import { fetchPickList } from '../../../actions';
import Loader from '../../../components/common/Loader';
import AddWorkbench from '../../../components/AddWorkbench';
import HireLogic from '../../Commons/HireLogicPopup';
import { isLoggedInUserAdmin } from '../../../services/cognitoService';
import { acquire as acquireUtil } from './utils';
import { API, BLOB, GET, POST, PUT, ERROR, SUCCESS, WARNING, HIRELOGIC_STAGES, HL_SYNC_CANDIDATES, DELETE_CONFIRMATION_POPUP_MESSAGE, LINKEDIN_SCRAPER } from '../../../services/constantService';
import { initializeSSO } from '../../../services/SsoService';
import AddContactsToWorkbench from '../../Commons/AddContactToWorkbench';
import LinkedInDialog from '../../Commons/LinkedInDialog';
import { dataSourceUtils } from '../../../utils/dataSource';
import { fetchUserList } from '../../../actions';
import { acquireLockApi, candidateDataApi, releaseLockApi, searchDataApi, hlDataApi, allCandidateListApi, reportsDataApi } from '../../../services/ApiService';
import { unableMessage, VALIDATION_MESSAGE, notFoundMessage, successMessage } from '../../../services/MessageService';
import DeletePopup from '../../Commons/DeletePopup';
import { UPDATE_CANDIDATE_LIST, UPDATE_TIMEZONE_DATA, RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE, RELOAD_ALL_CANDIDATE_GRID, REFRESH_CONTACTS } from '../../../types';
import SyncAllCandidateToHlPopup from './SyncCandidatesToHLPopup';
import ColorRenderer from './ColorRenderer';
import EmploymentHistoryConfirmationDialog from '../../Commons/EmploymentHistoryConfirmationDialog';
import { cleanupCompanyDetails } from '../../../components/AddContact/utils';
import AddLinkedinPopup from '../../../Containers/Commons/AddLinkedinPopup';
import SearchCloning from './SearchCloning';
import { CompanyNameRenderer } from '../../Companies/Utils';

let gridApi, columnApi;
const CandidatesList = props => {
  const {
    id,
    search = {},
    jobTitle,
    confidential,
    candidateStatus,
    colorList,
    setColorDesc,
    // eslint-disable-next-line react/prop-types
    initialLoader,
    location,
    navigate
  } = props;

  // const [viewColumns, setViewColumns] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isCopyCandidatesOpen, setIsCopyCandidatesOpen] = useState(false);
  const [isAddCandidateOpen, setIsAddCandidateOpen] = useState(false);
  const [isLinkedinPopup, setIsLinkedinPopup] = useState(false);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [isQuickAddOpen, setIsQuickAddOpen] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const [addContact, setAddContact] = useState(null);
  const [contactIds, setContactIds] = useState([]);
  const [activeContact, setActiveContact] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [isAddToSearchOpen, setIsAddToSearchOpen] = useState(false);
  const [isMetricsOpen, setIsMetricsOpen] = useState(false);
  const [activeContactDocs, setActiveContactDocs] = useState(false);
  const [contacts, setContacts] = useState([{}]);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [hireLogic, setHireLogic] = useState(false);
  const [reportsParams, setReportsParams] = useState({});
  const [isStatusWithOptionOpen, setIsStatusWithOptionOpen] = useState(false);
  const [isMatrixWithOptionOpen, setIsMatrixWithOptionOpen] = useState(false);
  const [isAthenaPopupOpen, setIsAthenaPopupOpen] = useState(false);
  const [isCandidateSuitePopupOpen, setIsCandidateSuitePopupOpen] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [options, setOptions] = useState([{}]);
  const [workspace, setWorkspace] = useState([]);
  const [candidateId, setCandidateId] = useState('');
  const [contactId, setContactId] = useState('');
  const [linkedPopup, setLinkedPopup] = useState(false);
  const [linkedInValue, setLinkedInValue] = useState({});
  const [defaultLocalTime, setDefaultLocalTime] = useState({});
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [load, setLoad] = useState(true);
  const [resetGrid, setResetGrid] = useState(false);
  const [isDisableSendButton, setIsDisableSendButton] = useState(false);
  const [IsSyncAllCandidate, setIsSyncAllCandidate] = useState(false);
  const [validCandidatesForHl, setValidCandidatesForHl] = useState([]);
  const [invalidCandidatesForHl, setInvalidCandidatesForHl] = useState([]);
  const [isCandidatesSyncPopup, setIsCandidatesSyncPopup] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const [headerMessage, setHeaderMessage] = useState('');
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [showJobTitleChangedPopup, setShowJobTitleChangedPopup] = useState(false);
  const [employmentHistoryMessageEndYearDate, setEmploymentHistoryMessageEndYearDate] = useState(null);
  const [isUpdateEmploymentHistory, setIsUpdateEmploymentHistory] = useState(false);
  const [skipCheckJobTitle, setSkipCheckJobTitle] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [newContactData, setNewContactData] = useState(null);
  const [linkedinContactData, setLinkedinContactData] = useState(null);
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [filterValue, setFilterValue] = useState({});
  const [isDeleteContactOpen, setIsDeleteContactOpen] = useState(false);
  const [showCloningSearchPopup, setShowCloningSearchPopup] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [isGridReady, setIsGridReady] = useState(false);
  const [isCopyToWorkbenchOpen, setIsCopyToWorkbenchOpen] = useState(false);
  const [isRemoveFromWorkbenchOpen, setIsRemoveFromWorkbenchOpen] = useState(false);
  const [athenaData, setAthenaData] = useState();
  const [candidatesuiteData, setCandidatesuiteData] = useState();
  const [isAddToWorkbenchOpen, setIsAddToWorkbenchOpen] = useState(false);
  const [isCreateWorkbenchOpen, setIsCreateWorkbenchOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const refreshContact = useSelector(state => state.rootReducer.refreshContact);
  const colors = useSelector(state => state.commonReducer.colors);
  const userId = useSelector(state => state.commonReducer.userData.id);
  const users = useSelector(state => state.rootReducer.users);
  const timezone = useSelector(state => state.rootReducer.timezone);
  const allCandidate = useSelector(state => state.rootReducer.allCandidate);
  const activeCandidate = useSelector(state => state.rootReducer.activeCandidate);
  const clientsuiteandreports = useSelector(state => state.rootReducer.clientsuiteandreports);
  // const socket = useSelector(state => state.rootReducer.socket)
  const linkedinScraperNotify = useSelector(state => state.rootReducer[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES]);
  const myLinkedinCandidatesRedux = useSelector(state => state.rootReducer[LINKEDIN_SCRAPER.myLinkedinCandidatesRedux]);

  let queryData;
  useEffect(() => {
    queryData = isAllRowsSelected ? Object.fromEntries(new URLSearchParams(getFilterParamString(gridApi.getFilterModel()))) : null;
    if (queryData && candidateStatus) {
      queryData.candidateStatus = candidateStatus;
    }
  }, [isAllRowsSelected]);

  const isLinkedinCandidate = candidateStatus === LINKEDIN_SCRAPER.MY_LINKEDIN_CANDIDATES;
  console.log(isAdmin);
  useEffect(() => {
    return () => {
      saveColumnStateToLocalStorage(`candidatesColumns-${candidateStatus || ''}`, { columnApi });
      dispatch({ type: 'resetLinkedinScraperEvents' });
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!users) {
          dispatch(fetchUserList('users'));
        }
        dispatch({ type: 'resetLinkedinScraperEvents' });

        if (id) {
          const { status, data } = await searchDataApi(GET, id);
          if (status === 200) {
            // Update state with fetched data
            if (data) {
              setSearchData(data);
            }
          }
        }

        if (!timezone) {
          const data = await localTimeZoneHandler(timeZoneOptions);
          if (data) {
            dispatch({
              type: UPDATE_TIMEZONE_DATA,
              payload: { key: 'timezone', data }
            });
            // Update state with fetched data
            setTimeZoneOptions(data.timeZoneOptions);
            setDefaultLocalTime(data.timeZoneOptions[0]);
          }
        } else {
          // Update state with props data
          setTimeZoneOptions(timezone.timeZoneOptions);
          setDefaultLocalTime(timezone.timeZoneOptions[0]);
        }

        checkUserRole();

        if (!colors) {
          dispatch(fetchPickList('colors'));
        }

        if (!users) {
          dispatch(fetchUserList('users'));
        }

        dispatch({ type: 'resetLinkedinScraperEvents' });
      } catch (error) {
        console.log('error', error);
      }
    };

    fetchData();
  }, [users, timezone, colors]);

  useEffect(() => {
    if (skipCheckJobTitle) {
      handleUpdateJobHistory();
    }
    if (linkedinScraperNotify) {
      const event = linkedinScraperNotify;
      let contactFound;
      gridApi.forEachNode(node => {
        if (node.data?.id === event.obj?.candidate?.id) {
          contactFound = node;
        }
      });
      if (contactFound) {
        contactFound.setData(event.obj.candidate);
        gridApi.refreshCells({ force: true });
        dispatch({ type: RELOAD_ALL_CANDIDATE_GRID });
      }
      dispatch({
        type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES,
        payload: undefined
      });
    }
    if (refreshContact) {
      dispatch({ type: RELOAD_ALL_CANDIDATE_GRID });
      gridApi.onFilterChanged();
      dispatch({ type: REFRESH_CONTACTS, payload: false });
    }
  }, [skipCheckJobTitle, linkedinScraperNotify, refreshContact]);

  const getURLs = {
    listURl: `${API.search}/${id}/candidates`
  };

  const dataSource = {
    getRows: async params => {
      try {
        params.filterModel && setFilterValue(params.filterModel);
        setShowFilterCount(Object.keys(filterValue)?.length);
        if (load || (candidateStatus === undefined && !allCandidate) || Object.keys(params.filterModel)?.length !== 0 || params.sortModel?.length !== 0 || resetGrid === 0) {
          params.filterModel && setFilterModel(params.filterModel);
          gridApi.deselectAll();
          const thisValue = {
            location,
            queryData
          };
          setIsLoading(true);
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: getURLs,
            subScreen: true,
            queryString: candidateStatus ? `&candidateStatus=${candidateStatus}` : ''
          };
          const { status, data } = await dataSourceUtils(obj, getFilterParamString);
          if (checkFilterAndSort(params.filterModel, params.sortModel) && candidateStatus === undefined) {
            dispatch({
              type: UPDATE_CANDIDATE_LIST,
              payload: { key: 'allCandidate', data }
            });
          } else if (checkFilterAndSort(params.filterModel, params.sortModel) && candidateStatus === 'ACTIVE') {
            dispatch({
              type: UPDATE_CANDIDATE_LIST,
              payload: { key: 'activeCandidate', data }
            });
          } else if (checkFilterAndSort(params.filterModel, params.sortModel) && candidateStatus === 'CLIENTSUITEANDREPORTS') {
            dispatch({
              type: UPDATE_CANDIDATE_LIST,
              payload: { key: 'clientsuiteandreports', data }
            });
          } else if (checkFilterAndSort(params.filterModel, params.sortModel) && candidateStatus === LINKEDIN_SCRAPER.MY_LINKEDIN_CANDIDATES) {
            dispatch({
              type: UPDATE_CANDIDATE_LIST,
              payload: {
                key: LINKEDIN_SCRAPER.myLinkedinCandidatesRedux,
                data
              }
            });
          }
          if (status === SUCCESS_STATUS_CODE) {
            if (data?.paging?.totalCount === 0) {
              const message = notFoundMessage('records');
              enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(data.data, data.paging?.totalCount);
            setContacts(data.data);
            setRowCount(data.paging?.totalCount);
            isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
          } else {
            params.failCallback();
          }
          setIsLoading(false);
          gridApi.hideOverlay();
        } else if ((candidateStatus === 'ACTIVE' && !activeCandidate) || Object.keys(params.filterModel)?.length !== 0 || params.sortModel?.length !== 0 || resetGrid === 0) {
          params.filterModel && setFilterModel(params.filterModel);
          // gridApi.deselectAll();
          const thisValue = {
            location,
            queryData
          };
          setIsLoading(true);
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: getURLs,
            subScreen: true,
            queryString: candidateStatus ? `&candidateStatus=${candidateStatus}` : ''
          };
          const { status, data } = await dataSourceUtils(obj, getFilterParamString);
          dispatch({
            type: UPDATE_CANDIDATE_LIST,
            payload: { key: 'activeCandidate', data }
          });
          if (status === SUCCESS_STATUS_CODE) {
            if (data?.paging?.totalCount === 0) {
              const message = notFoundMessage('records');
              enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(data.data, data.paging?.totalCount);
            setContacts(data.data);
            setRowCount(data.paging?.totalCount);
            isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
          } else {
            params.failCallback();
          }

          setIsLoading(false);
          gridApi.hideOverlay();
        } else if ((candidateStatus === 'CLIENTSUITEANDREPORTS' && !clientsuiteandreports) || Object.keys(params.filterModel)?.length !== 0 || params.sortModel?.length !== 0 || resetGrid === 0) {
          params.filterModel && setFilterModel(params.filterModel);
          gridApi.deselectAll();
          const thisValue = {
            location,
            queryData
          };
          setIsLoading(true);
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: getURLs,
            subScreen: true,
            queryString: candidateStatus ? `&candidateStatus=${candidateStatus}` : ''
          };
          const { status, data } = await dataSourceUtils(obj, getFilterParamString);
          dispatch({
            type: UPDATE_CANDIDATE_LIST,
            payload: { key: 'clientsuiteandreports', data }
          });
          if (status === SUCCESS_STATUS_CODE) {
            if (data?.paging?.totalCount === 0) {
              const message = notFoundMessage('records');
              enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(data.data, data.paging?.totalCount);
            setContacts(data.data);
            setRowCount(data.paging?.totalCount);
            isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
          } else {
            params.failCallback();
          }

          setIsLoading(false);
          gridApi.hideOverlay();
        } else if (
          (candidateStatus === LINKEDIN_SCRAPER.MY_LINKEDIN_CANDIDATES && !props[LINKEDIN_SCRAPER.myLinkedinCandidatesRedux]) ||
          Object.keys(params.filterModel)?.length !== 0 ||
          params.sortModel?.length !== 0 ||
          resetGrid === 0
        ) {
          params.filterModel && setFilterModel(params.filterModel);
          //   gridApi.deselectAll();
          const thisValue = {
            location,
            queryData
          };
          setIsLoading(true);
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: getURLs,
            subScreen: true,
            queryString: candidateStatus ? `&candidateStatus=${candidateStatus}` : ''
          };
          const { status, data } = await dataSourceUtils(obj, getFilterParamString);
          dispatch({
            type: UPDATE_CANDIDATE_LIST,
            payload: { key: LINKEDIN_SCRAPER.myLinkedinCandidatesRedux, data }
          });
          if (status === SUCCESS_STATUS_CODE) {
            if (data?.paging?.totalCount === 0) {
              const message = notFoundMessage('records');
              enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(data.data, data.paging?.totalCount);
            setContacts(data.data);
            setRowCount(data.paging?.totalCount);
            isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
          } else {
            params.failCallback();
          }
          setIsLoading(false);
          gridApi.hideOverlay();
        } else {
          if (candidateStatus === undefined) {
            if (allCandidate?.paging?.totalCount === 0) {
              const message = notFoundMessage('records');
              enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(allCandidate.data, allCandidate.paging?.totalCount);
            setContacts(allCandidate.data);
            setRowCount(allCandidate.paging?.totalCount);
            isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
          } else if (candidateStatus === 'ACTIVE') {
            if (activeCandidate?.paging?.totalCount === 0) {
              const message = notFoundMessage('records');
              enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(activeCandidate.data, activeCandidate.paging?.totalCount);
            setContacts(activeCandidate.data);
            setRowCount(activeCandidate.paging?.totalCount);
            isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
          } else if (candidateStatus === LINKEDIN_SCRAPER.MY_LINKEDIN_CANDIDATES) {
            if (activeCandidate?.paging?.totalCount === 0) {
              const message = notFoundMessage('records');
              enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(myLinkedinCandidatesRedux.data, myLinkedinCandidatesRedux.paging?.totalCount);
            setContacts(myLinkedinCandidatesRedux.data);
            setRowCount(myLinkedinCandidatesRedux.paging?.totalCount);
            isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
          } else {
            if (clientsuiteandreports?.paging?.totalCount === 0) {
              const message = notFoundMessage('records');
              enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(clientsuiteandreports.data, clientsuiteandreports.paging?.totalCount);
            setContacts(clientsuiteandreports.data);
            setRowCount(clientsuiteandreports.paging?.totalCount);
            isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
          }
        }
      } catch (e) {
        console.log('Error found in downloadAttachment::', e);
      }
    },
    rowCount: null
  };

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    loadColumnStateFromLocalStorage(`candidatesColumns-${candidateStatus || ''}`, { columnApi, gridApi });
    setIsGridReady(true);
    if (allCandidate || activeCandidate || clientsuiteandreports) setLoad(false);
  };

  const handleCopySearchesToWorkbench = () => {
    setIsCopyToWorkbenchOpen(true);
  };

  const handleCopySearchesToWorkbenchClose = () => {
    setIsCopyToWorkbenchOpen(false);
  };

  const setSelectAllRows = isAllRowsSelected => {
    setIsAllRowsSelected(isAllRowsSelected);
    gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };

  const handleChange = () => {
    setSelectAllRows(!isAllRowsSelected);
  };

  const linkedInPopupHandler = data => {
    setLinkedPopup(true);
    setLinkedInValue(data.value);
  };

  const LinkedInRenderers = params => {
    return <LinkedInRenderer params={params} linkedInPopup={linkedInPopupHandler} />;
  };

  const LinkedInCloseHandler = () => {
    setLinkedPopup(false);
  };

  const handelCloningSearch = async () => {
    setShowCloningSearchPopup(true);
  };
  const handelCloseCloningPopup = () => {
    setShowCloningSearchPopup(false);
  };
  const HeaderCheckbox = () => {
    return <Checkbox style={{ padding: 0, width: 16, height: 16, color: 'white' }} size='small' color='primary' onChange={e => handleChange(e)} />;
  };

  const checkUserRole = () => {
    let isAdminUser = isLoggedInUserAdmin();
    isAdminUser = isAdminUser === 'true' ? true : false;
    setIsAdmin(isAdminUser);
  };

  const ColorRenderers = params => {
    const handleChange = async event => {
      setIsLoading(true);
      const color = event.target.name;
      const { status } = await candidateDataApi(PUT, params.data?.id, {
        color
      });
      setIsLoading(false);
      if (status === 200) {
        params.node.setDataValue('color', color);
        dispatch({ type: RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE });
      } else {
        const message = unableMessage('candidate', 'update');
        enqueueSnackbar(message, { variant: ERROR });
      }
    };
    return <ColorRenderer params={params} handleChange={handleChange} />;
  };

  // const removeCandidate = async (id) => {
  //   const { status } = await candidateDataApi(DELETE, id);
  //   if (status === 200) {
  //     const message = successMessage("Candidate", "removed");
  //     enqueueSnackbar(message, { variant: SUCCESS });
  //     gridApi.onFilterChanged();
  //     setIsRemove(false)
  //   } else {
  //     const message = unableMessage("candidate", "remove");
  //     enqueueSnackbar(message, { variant: ERROR });
  //   }
  // };

  const ActionsRenderer = params => {
    const status = checkContactFetchingStatus(params);
    if (status === LINKEDIN_SCRAPER.PROCESSING_LABEL) return status;

    let list;
    if (status === LINKEDIN_SCRAPER.FAILED_LABEL) {
      list = [
        {
          label: 'Delete Record',
          onClick: () => {
            setIsDeleteContactOpen(true);
            setContactId(params.data?.contact?.id);
          }
        }
      ];
    } else {
      //Note: this should only be turned on for admin until the integration is ready.
      list = HIRELOGIC_STAGES.includes(params?.data?.stage)
        ? [
            {
              label: 'Log An Activity',
              onClick: async () => {
                acquire(params.data?.contact.id, params.data?.id);
              }
            },
            {
              label: 'HireLogic Interview',
              onClick: () => handleWorkspacePopup(params.data.id, params.data?.contact)
            },
            {
              label: 'Add To Workbench',
              onClick: () => handleAddToWorkbench(params.data?.contact.id)
            },
            {
              label: 'Remove From Workbench',
              onClick: () => {
                setIsRemoveFromWorkbenchOpen(true);
                setContactId(params.data?.contact.id);
              }
            },
            {
              label: 'Show History',
              onClick: () => showActivityHistory(params?.data?.contact)
            },
            {
              label: 'Remove',
              onClick: async () => {
                setCandidateId(params.data?.id);
                setIsRemove(true);
                // acquire(params?.data?.contact.id, params.data?.id)
              }
            }
          ]
        : [
            {
              label: 'Log An Activity',
              onClick: async () => {
                acquire(params.data?.contact.id, params.data?.id);
              }
            },
            {
              label: 'Add To Workbench',
              onClick: () => handleAddToWorkbench(params.data?.contact.id)
            },
            {
              label: 'Remove From Workbench',
              onClick: () => {
                setIsRemoveFromWorkbenchOpen(true);
                setContactId(params.data?.contact.id);
              }
            },
            {
              label: 'Show History',
              onClick: () => showActivityHistory(params?.data?.contact)
            },
            {
              label: 'Remove',
              onClick: async () => {
                setCandidateId(params.data?.id);
                setIsRemove(true);
                // acquire(params?.data?.contact.id, params.data?.id)
              }
            }
          ];
    }
    return params.data ? <ActionsPopover list={list} /> : null;
  };

  const acquire = async (contactId, candidateId) => {
    setIsLoading(true);
    let { status, data } = await acquireLockApi(contactId);
    if (status && status === 200 && data.message === undefined) {
      // if (isRemove) {

      //   removeCandidate(candidateId)
      // }
      // else {
      await releaseLockApi(contactId);
      let url = location.pathname;
      if (url.includes('all-candidates') || url.includes('client-suite-report') || url.includes('my-linkedin-candidates')) {
        url = url.concat('');
      } else if (url.includes('active-candidates')) {
        url = url.replace('/active-candidates', '');
        url = url.concat('/candidates');
      } else {
        url = url.concat('/candidates');
      }
      navigate(
        // `/searches/${id}/candidates/${candidateId}/log-an-activity`,
        `${url}/${candidateId}/log-an-activity/general-info`,
        {
          sortModel: sortModel,
          filterModel: filterModel,
          contactId: contactId
        }
      );
      setIsLoading(false);
      // }
    } else {
      //if (data.isAdmin) {
      setIsLoading(false);
      enqueueSnackbar(data.message, {
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: key => (
          <>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key);
                releaseLock(contactId, candidateId);
              }}
            >
              Force Unlock
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                let url = location.pathname;
                if (url.includes('all-candidates') || url.includes('client-suite-report') || url.includes('my-linkedin-candidates')) {
                  url = url.concat('');
                } else if (url.includes('active-candidates')) {
                  url = url.replace('/active-candidates', '');
                  url = url.concat('/candidates');
                } else {
                  url = url.concat('/candidates');
                }
                closeSnackbar(key);
                navigate(`${url}/${candidateId}/log-an-activity/read-only/general-info`, {
                  sortModel: sortModel,
                  filterModel: filterModel,
                  contactId: contactId
                });
              }}
            >
              Open Read Only View
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                closeSnackbar(key);
              }}
            >
              CLOSE
            </Button>
          </>
        )
      });
      /* }
      else {
        enqueueSnackbar(data.message, {
          variant: WARNING,
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key)
                navigate(
                  `/searches/${id}/candidates/${candidateId}/log-an-activity/read-only`,
                  { sortModel: sortModel, filterModel: filterModel, contactId: contactId }
                )
              }}
            >
              Open Read Only View
            </Button>
          )
        });
      } */
      setIsRemove(false);
    }
  };
  const releaseLock = async (contactId, candidateId) => {
    await releaseLockApi(contactId);
    acquire(contactId, candidateId);
  };

  const DocsRenderer = params => {
    const status = checkContactFetchingStatus(params);
    if (status) return status;
    const list = [
      {
        label: 'Docs List',
        onClick: () => showDocsList(params?.data?.contact)
      },
      {
        label: 'Individual Client Athena Report (49 Statement)',
        onClick: () => {
          setIsReportsOpen(true);
          setReportsParams({
            params: {
              contactId: params?.data?.contact?.id,
              timeZone: true
            },
            url: `${API.reports}/athena-individual-consulting`
          });
        }
      },
      {
        label: 'Candidate Fit Profile 3.0',
        onClick: () => {
          setIsReportsOpen(true);
          setReportsParams({
            params: {
              candidateId: params?.data?.id,
              searchId: id,
              stage: params?.data?.stage,
              isArenaSearch: search.type === 'Inside Track',
              timeZone: true
            },
            options: {
              pageSize: true,
              locale: true,
              showLegacy: true
            },
            url: `${API.reports}/candidate-fit-profile`
          });
        }
      },
      {
        label: 'Interview Preparation 3.0',
        onClick: () => {
          setIsReportsOpen(true);
          setReportsParams({
            params: {
              candidateId: params?.data?.id,
              isArenaSearch: search.type === 'Inside Track',
              timeZone: true
            },
            options: {
              pageSize: true,
              locale: false,
              showLegacy: true
            },
            url: `${API.reports}/interview-preparation`
          });
        }
      },
      {
        label: 'Board Candidate Profile',
        onClick: () => {
          setIsReportsOpen(true);
          setReportsParams({
            params: {
              candidateId: params?.data?.id,
              searchId: id,
              timeZone: true
            },
            options: {
              pageSize: true,
              locale: false
            },
            url: `${API.reports}/board-candidate-profile`
          });
        }
      },
      {
        label: 'Proposed Candidate Profile',
        onClick: () => {
          setIsReportsOpen(true);
          setReportsParams({
            params: {
              candidateId: params?.data?.id,
              contactId: params?.data?.contact.id,
              timeZone: true
            },
            options: {
              pageSize: true
            },
            url: `${API.reports}/contact-bio-profile`
          });
        }
      },
      {
        label: 'Athena Results Report',
        onClick: () => {
          setIsReportsOpen(true);
          setReportsParams({
            params: {
              contactId: params?.data?.contact.id,
              timeZone: false
            },
            options: {
              pageSize: true,
              locale: true
            },
            url: `${API.reports}/athena-candidate-results`
          });
        }
      }
    ];
    return params.data ? <ActionsPopover list={list} /> : null;
  };
  const HireLogicRenderer = params => {
    const list = [
      {
        label: 'Add to HireLogic'
        // onClick: () => showDocsList(params?.data?.contact),
      },
      {
        label: 'Sync Details'
        // onClick: () => showDocsList(params?.data?.contact),
      }
    ];
    return params.data ? <ActionsPopover list={list} label={'Actions'} /> : null;
  };
  const showDocsList = activeContactDocs => {
    setActiveContactDocs(activeContactDocs);
  };

  const handleChangeAddCandidate = () => {
    setIsAddCandidateOpen(true);
  };

  const handleChangeAddCandidateFromLinkedin = () => {
    setIsLinkedinPopup(true);
  };

  const handleAddCandidateClose = isRefresh => {
    if (isRefresh) {
      gridApi.onFilterChanged();
    }
    setIsAddCandidateOpen(false);
    setAddContact(null);
  };

  const handleAddLinkedinDataClose = isClose => {
    setIsLinkedinPopup(isClose);
  };

  const handleQuickAddContactClose = (contact, isAddToSearch) => {
    setIsQuickAddOpen(false);
    setLinkedinContactData(null);
    if (isAddToSearch && contact) {
      setAddContact(contact);
      isAddCandidateOpen(true);
    }
  };

  const handleCopyCandidates = () => {
    const contactIds = [];
    gridApi.forEachNode(node => {
      if (node.selected) {
        contactIds.push(node.data.contact.id);
      }
    });
    setIsCopyCandidatesOpen(true);
    setContactIds(contactIds);
  };
  const handleCopyCandidatesClose = () => {
    setIsCopyCandidatesOpen(false);
    setContactIds([]);
  };

  const showActivityHistory = activeContact => {
    setActiveContact(activeContact);
  };

  const CompanyNameRenderers = params => {
    const status = checkContactFetchingStatus(params);
    if (status) return status;
    return <CompanyNameRenderer company={params?.data?.contact?.company} />;
  };

  /* HireLogicLinkRenderer = (params) => {
    let position_id = search.position_id
    return <HireLogicLinkRenderer item={params?.data?.interview[0]?.interviewStatus} position_id={position_id} candidateID={params?.data?.hl_candidate_id} />
  } */

  const NameRenderer = params => {
    return <ContactNameRenderer params={params} handleAddToSearch={handleAddToSearch} />;
  };

  const handleAddToSearch = contactId => {
    setIsAddToSearchOpen(true);
    setContactIds([contactId]);
  };

  const handleAddToSearchClose = () => {
    setIsAddToSearchOpen(false);
    setContactIds([]);
  };

  const AthenaRenderer = params => {
    const status = checkContactFetchingStatus(params);
    if (status) return status;
    const handleInvite = () => {
      if (!params.data.contact.private_email && !params.data.contact.work_email) {
        setIsDisableSendButton(true);
      } else {
        setIsDisableSendButton(false);
      }
      setIsAthenaPopupOpen(true);
      setAthenaData({
        candidate: { id: params.data?.id },
        contact: params.data?.contact
      });
    };

    if (params.data?.contact) {
      const contact = params.data.contact;
      if (contact.athena_completion_date || contact.athena_invitation_sent_on) {
        return (
          <AthenaPopover contactDetails={params.data.contact} handleInvite={handleInvite}>
            <Typography style={{ fontSize: 'inherit', cursor: 'pointer' }} color='primary'>
              {contact.athena_completion_date ? `Completed on: ${formatDate(contact.athena_completion_date)}` : `Invited on: ${formatDate(contact.athena_invitation_sent_on)}`}
            </Typography>
          </AthenaPopover>
        );
      } else {
        return (
          <Button className='invite-button athena-button' variant='contained' color='primary' onClick={handleInvite}>
            Invite to Athena
          </Button>
        );
      }
    }
    return null;
  };

  // CandidateSuiteRenderer = (params) => {
  //   const status = checkContactFetchingStatus(params)
  //   if (status) return status;
  //   const handleInvite = () => {
  //     setState({
  //       isCandidateSuitePopupOpen: true,
  //       candidatesuiteData: {
  //         candidate: { id: params.data?.id },
  //         contact: params.data?.contact,
  //       },
  //     });
  //   };

  //   if (params.data?.contact) {
  //     const contact = params.data.contact;
  //     if (
  //       contact.candidatesuite_completion_date ||
  //       contact.candidatesuite_invitation_sent_on
  //     ) {
  //       return (
  //         <CandidateSuitePopover
  //           contactDetails={params.data.contact}
  //           handleInvite={handleInvite}
  //         >
  //           <Typography
  //             style={{ fontSize: "inherit", cursor: "pointer" }}
  //             color="primary"
  //           >
  //             {contact.candidatesuite_completion_date
  //               ? `Completed on: ${formatDate(
  //                 contact.candidatesuite_completion_date
  //               )}`
  //               : `Invited on: ${formatDate(
  //                 contact.candidatesuite_invitation_sent_on
  //               )}`}
  //           </Typography>
  //         </CandidateSuitePopover>
  //       );
  //     } else {
  //       return (
  //         <Button
  //           className="invite-button athena-button"
  //           variant="contained"
  //           color="primary"
  //           onClick={handleInvite}
  //         >
  //           Invite to CandidateSuite
  //         </Button>
  //       );
  //     }
  //   }
  //   return null;
  // };

  const CandidateSuiteRenderer = params => {
    const status = checkContactFetchingStatus(params);
    if (status) return status;
    const handleInvite = () => {
      if (!params.data.contact.private_email && !params.data.contact.work_email) {
        setIsDisableSendButton(true);
      } else {
        setIsDisableSendButton(true);
      }
      setIsCandidateSuitePopupOpen(true);
      setCandidatesuiteData({
        candidate: { id: params.data?.id },
        contact: params.data?.contact
      });
    };

    if (params.data?.contact) {
      const contact = params.data.contact;
      if (contact.candidatesuite_completion_date || contact.candidatesuite_invitation_sent_on) {
        return (
          <CandidateSuitePopover contactDetails={params.data.contact} handleInvite={handleInvite}>
            <Typography style={{ fontSize: 'inherit', cursor: 'pointer' }} color='primary'>
              {contact.candidatesuite_completion_date ? `Completed on: ${formatDate(contact.candidatesuite_completion_date)}` : `Invited on: ${formatDate(contact.candidatesuite_invitation_sent_on)}`}
            </Typography>
          </CandidateSuitePopover>
        );
      } else {
        return (
          <Button className='invite-button athena-button' variant='contained' color='primary' onClick={handleInvite}>
            Send Invite for CandidateSuite
          </Button>
        );
      }
    }
    return null;
  };

  const handleAthenaPopupClose = () => {
    isAthenaPopupOpen(false);
    gridApi.onFilterChanged();
  };

  const handleIsCandidateSuitePopupClose = () => {
    setIsCandidateSuitePopupOpen(false);
    gridApi.onFilterChanged();
  };

  const getParams = selectedRows => {
    if (isAllRowsSelected) {
      if (candidateStatus) {
        return `candidateStatus=${candidateStatus}`;
      }
      return '';
    } else {
      return selectedRows.map(row => `id=${row.id}`).join('&');
    }
  };

  const exportCallback = async () => {
    const selectedRows = gridApi.getSelectedRows();
    if (selectedRows && selectedRows?.length) {
      let sub_route = 'candidates/export-as-excel?'.concat(getParams(selectedRows));
      if (filterModel && Object.keys(filterModel)?.length) {
        sub_route = sub_route.concat(getFilterParamString(filterModel));
      }
      const columnHeaders = columnApi.getAllDisplayedColumns().map(column => column.getColDef().headerName);
      gridApi.showLoadingOverlay();
      const payload = { headers: columnHeaders };
      const { status, data, headers } = await searchDataApi(POST, id, payload, sub_route, BLOB);
      if (status === 200) {
        let fileName = 'candidates.xlsx';
        let fileNameHeader = headers['content-disposition'].split('"');
        if (fileNameHeader && fileNameHeader?.length > 2) {
          fileName = fileNameHeader[1];
        }
        saveAs(new File([data], fileName));
      }
      gridApi.hideOverlay();
    }
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    setFilterModel(filterModel);
    setResetGrid(Object.keys(filterModel)?.length);
    saveColumnStateForFilter();
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    setSortModel(sortModel);
    saveColumnStateForSort();
  };

  const onFirstDataRendered = () => {
    const locationState = location.state;
    if (locationState) {
      const sortModel = locationState.sortModel;
      if (sortModel) {
        gridApi.setSortModel(sortModel);
      }

      const filterModel = locationState.filterModel;
      if (filterModel) {
        gridApi.setFilterModel(filterModel);
      }
    }
  };

  const saveColumnState = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      await saveColumnStateToLocalStorage(`candidatesColumns-${candidateStatus || ''}`, { columnApi, gridApi });
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForFilter = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      await saveColumnStateToLocalStorage(`candidatesColumns-${candidateStatus || ''}`, { columnApi, gridApi }, true, false);
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForSort = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      await saveColumnStateToLocalStorage(`candidatesColumns-${candidateStatus || ''}`, { columnApi, gridApi }, false, true);
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const handleAddToWorkbench = contactId => {
    setIsAddToWorkbenchOpen(true);
    setContactIds([contactId]);
  };

  const handleAddToWorkbenchClose = () => {
    setIsAddToWorkbenchOpen(false);
  };

  const handleRemoveFromWorkbenchClose = () => {
    setIsRemoveFromWorkbenchOpen(false);
  };

  // const handleValidateEmail = (contact) => {
  //   if (!contact.private_email) {
  //     enqueueSnackbar(VALIDATION_MESSAGE.candidate_personal_email, {
  //       variant: ERROR,
  //     });
  //     return false;
  //   }
  //   const email = EMAIL_REGEX.test(contact.private_email)
  //     ? contact.private_email
  //     : null;
  //   if (!email) {
  //     const message = validationMessage("personal email");
  //     enqueueSnackbar(message, { variant: ERROR });
  //     return false;
  //   }
  //   return true;
  // }

  const handleWorkspacePopup = async (candidateId, contact) => {
    if (candidateId && contact) {
      // const isValidEmail = handleValidateEmail(contact)
      // if (!isValidEmail) {
      //   return;
      // }
      setContactId(contact.id);
      setCandidateId(candidateId);
    }
    setIsLoading(true);
    const sub_route = `user-workspaces?userId=${userId}&searchId=${search.id}`;
    let { status, data } = await hlDataApi(GET, sub_route);
    if (status && status === 200) {
      setIsLoading(false);
      if (data.workspaces?.length > 0) {
        handleWorkspaceChange(data.workspaces[0]);
      } else {
        enqueueSnackbar(VALIDATION_MESSAGE.hireLogic_workspace, {
          variant: ERROR
        });
      }
      if (data.workspaces && data.workspaces?.length === 1) {
        handleWorkspaceSubmit();
      } else {
        setOptions(data.workspaces);
        setHireLogic(true);
      }
    } else {
      const message = unableMessage('Workspaces', 'fetch');
      enqueueSnackbar(message, { variant: ERROR });
      setIsLoading(false);
    }
  };

  const handleWorkspaceChange = value => {
    setWorkspace(value);
  };

  const handleWorkspaceSubmit = async () => {
    try {
      if (IsSyncAllCandidate) {
        return handleSyncValidCandidatesToHL();
      }
      setHireLogic(false);
      setIsLoading(true);
      const payload = {
        userId: userId,
        candidateId: candidateId,
        searchId: search.id,
        workspaceId: workspace[0].id,
        contactId: contactId
      };
      const { status, data } = await initializeSSO(payload);
      if (status && data && status === 200) {
        let newTab = window.open('', '_blank');
        if (newTab !== null) {
          newTab.location.href = data.data.redirectLink;
        }
        const message = successMessage('Workspace', 'saved');
        enqueueSnackbar(message, { variant: SUCCESS });
      } else {
        let str = data.errorDetails.errors[0].title;
        if (data.status === 422) str = str.validationMessages.details[0].message;
        enqueueSnackbar(str, { variant: ERROR });
      }
      setIsLoading(false);
    } catch (e) {
      console.log('Error found in handleWorkspaceSubmit::', e);
    }
  };

  const getSelectedContactIds = () => {
    const contactIds = [];
    gridApi.forEachNode(node => {
      if (node.selected) {
        contactIds.push(node.data.contact.id);
      }
    });
    return contactIds;
  };

  const handleAddContactsToWorkbench = () => {
    const contactIds = getSelectedContactIds();
    setIsAddToWorkbenchOpen(true);
    setContactIds(contactIds);
  };

  const handleCreateWorkbench = () => {
    setIsCreateWorkbenchOpen(true);
  };

  const loaderChange = async value => {
    setIsLoading(value);
  };

  const handleCreateWorkbenchClose = async workbenchId => {
    try {
      setIsCreateWorkbenchOpen(false);
      if (workbenchId) {
        setIsLoading(true);
        const contactIds = getSelectedContactIds(gridApi);
        await addContactsToWorkbench(workbenchId, contactIds, enqueueSnackbar);
      }
      setIsLoading(false);
    } catch (e) {
      console.log('Error found in handleCreateWorkbenchClose::', e);
    }
  };

  const handleDeleteClose = deleted => {
    setIsRemove(false);
    setCandidateId(null);
    if (deleted) {
      gridApi.onFilterChanged();
    }
  };

  const handleDeleteContactClose = deleted => {
    setIsDeleteContactOpen(false);
    setContactId(null);
    if (deleted) {
      gridApi.onFilterChanged();
    }
  };

  const handleSyncAllCandidateToHl = async () => {
    setIsSyncAllCandidate(true);
    setIsLoading(true);
    const { status, data } = await allCandidateListApi(GET, getURLs.listURl, 'limit=1000');
    if (status === SUCCESS_STATUS_CODE) {
      if (data?.paging?.totalCount === 0) {
        const message = notFoundMessage('records');
        enqueueSnackbar(message, { variant: WARNING });
        setIsSyncAllCandidate(false);
        setIsLoading(false);
        return;
      }
      let validCandidates = [];
      // let invalidCandidates = [];

      data.data.forEach(item => {
        // if (HIRELOGIC_STAGES.includes(item.stage)) {
        // if (!item.contact.private_email) {
        //   invalidCandidates = [...invalidCandidates, { ...item, contactId: item.contact.id, candidateId: item.id }]
        // } else {
        //   const email = EMAIL_REGEX.test(item.contact.private_email)
        //     ? item.contact.private_email
        //     : null;
        //   if (!email) {
        //     invalidCandidates = [...invalidCandidates, { ...item, contactId: item.contact.id, candidateId: item.id }]
        //   } else {
        //     validCandidates = [...validCandidates, { ...item, contactId: item.contact.id, candidateId: item.id }]
        //   }
        // }
        validCandidates = [...validCandidates, { ...item, contactId: item.contact.id, candidateId: item.id }];
        //  }
      });

      setIsLoading(false);
      setShowReason(false);
      if (validCandidates?.length) {
        setValidCandidatesForHl(validCandidates);
      }
      // if (invalidCandidates?.length > 0) {
      //   setState({ invalidCandidatesForHl: invalidCandidates, headerMessage: HL_SYNC_CANDIDATES.VALIDATE_EMAIL_MESSAGE })
      //   setState({ isCandidatesSyncPopup: true })
      // } else {
      //   handleWorkspacePopup()
      // }
      handleWorkspacePopup();
    } else {
      enqueueSnackbar(data.message, { variant: ERROR });
    }
  };
  const resetFilter = async () => {
    gridApi.setFilterModel(null);
    const customFilterField = columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item?.filter === 'CustomFilter') {
        gridApi.destroyFilter(item.field);
      }
    });
  };

  const resetSort = async () => {
    gridApi.setSortModel(null);
  };

  const handleSyncValidCandidatesToHL = async () => {
    try {
      setHireLogic(false);
      if (validCandidatesForHl?.length === 0) {
        setIsSyncAllCandidate(false);
        enqueueSnackbar(HL_SYNC_CANDIDATES.NO_FOUND_VALID_CANDIDATES, {
          variant: WARNING
        });
        return;
      }
      let snackbarKey;
      let isStopSyncing = false;
      enqueueSnackbar(HL_SYNC_CANDIDATES.STOP_SYNCING_MESSAGE_LABLE, {
        persist: true,
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: key => {
          snackbarKey = key;
          return (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                setLoadingMessage(HL_SYNC_CANDIDATES.STOPPING_SYNCING_MESSAGE);
                isStopSyncing = true;
                closeSnackbar(key);
              }}
            >
              Stop Sync
            </Button>
          );
        }
      });
      setLoadingMessage(HL_SYNC_CANDIDATES.STOP_SYNCING_CANDIDATES);
      setIsLoading(true);
      let failedToSyncCandidates = [];
      let resultCount = 0;
      for await (const [, item] of validCandidatesForHl.entries()) {
        if (isStopSyncing) {
          break;
        }
        const payload = {
          userId: userId,
          candidateId: item.candidateId,
          searchId: search.id,
          workspaceId: workspace[0].id,
          contactId: item.contactId
        };
        const { status, data } = await initializeSSO(payload);
        if (status && data && status !== 200) {
          let str = data.errorDetails.errors[0].title;
          if (data.status === 422) str = str.validationMessages.details[0].message;
          failedToSyncCandidates = [...failedToSyncCandidates, { ...item, hl_reason: str }];
        }
        resultCount++;
      }
      if (resultCount === validCandidatesForHl?.length || isStopSyncing) {
        setIsLoading(false);
      }
      if (failedToSyncCandidates?.length > 0) {
        setInvalidCandidatesForHl(failedToSyncCandidates);
        setShowReason(true);
        setHeaderMessage(HL_SYNC_CANDIDATES.VALIDATE_REASON_MESSAGE);
        setIsCandidatesSyncPopup(true);
      } else {
        const message = successMessage('Candidates', 'sync with HL');
        enqueueSnackbar(message, { variant: SUCCESS });
      }
      if (snackbarKey) {
        closeSnackbar(snackbarKey);
      }
      setIsSyncAllCandidate(false);
      setLoadingMessage(null);
    } catch (e) {
      console.log('Error found in handleSyncValidCandidatesToHL::', e);
    }
  };

  const handleCloseCandidatesSyncPopup = () => {
    setIsCandidatesSyncPopup(false);
    setIsSyncAllCandidate(false);
  };

  const handleRedirectToReportBuilder = async () => {
    setIsLoading(true);
    const sub_route = 'report-builder/login_url';
    let { status, data } = await reportsDataApi(GET, sub_route);
    if (status && status === 200) {
      let newTab = window.open('', '_blank');
      if (newTab !== null) {
        newTab.location.href = data.redirectLink;
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(data.message, { variant: ERROR });
      setIsLoading(false);
    }
  };
  const setEmploymentHistoryEndYearDate = endDate => {
    setEmploymentHistoryMessageEndYearDate(endDate);
  };

  const handleConfirmJobTitlePopup = async () => {
    setIsUpdateEmploymentHistory(true);
    setShowJobTitleChangedPopup(false);
    setSkipCheckJobTitle(true);
  };
  const handleCancelJobTitlePopup = async () => {
    setIsUpdateEmploymentHistory(false);
    setShowJobTitleChangedPopup(false);
    setSkipCheckJobTitle(true);
  };

  const setJobHistoryPopupStates = data => {
    const { employmentHistoryMessageEndYearDate, isUpdateEmploymentHistory, currentContact, newContactData } = data;
    setEmploymentHistoryMessageEndYearDate(employmentHistoryMessageEndYearDate);
    setIsUpdateEmploymentHistory(isUpdateEmploymentHistory);
    setCurrentContact(currentContact);
    setNewContactData(newContactData);
  };

  const handleUpdateJobHistory = async () => {
    setSkipCheckJobTitle(false);
    const params = newContactData;
    if (isUpdateEmploymentHistory) {
      setIsLoading(true);
      const contact = currentContact;
      let newJobHistory = [
        {
          company: contact.company,
          title: contact.current_job_title,
          start_year: contact.current_job_start_year,
          end_year: employmentHistoryMessageEndYearDate || new Date().getFullYear()
        }
      ];
      newJobHistory = [...newJobHistory, ...contact.job_history]?.filter(item => item?.company);
      params.job_history = sortByChronologicalOrder(cleanupCompanyDetails(newJobHistory, contact.id));
    }
    await acquireUtil(params, 'current_job_title', enqueueSnackbar, closeSnackbar, true, '', loaderChange, dispatch, true);
    setJobHistoryPopupStates({
      employmentHistoryMessageEndYearDate: null,
      isUpdateEmploymentHistory: false,
      currentContact: null,
      newContactData: null
    });
  };

  return (
    <div className='all-candidates d-flex flex-column h-100'>
      <Header companyDetails={search.company} company={search.company && search.company.name} jobNumber={search.job_number} jobTitle={jobTitle} confidential={confidential}>
        <LinkedInDialog open={linkedPopup} linkedInValue={linkedInValue} handleClose={LinkedInCloseHandler}></LinkedInDialog>
        <div className='d-flex'>
          <div className='action-container d-flex align-items-center' onClick={handelCloningSearch}>
            <span className='action-icon'>
              <ControlPointDuplicateIcon fontSize='inherit' />
            </span>
            <span className='action-text'>Clone Search</span>
          </div>
          <div
            onClick={() => {
              setIsMetricsOpen(true);
            }}
            className='action-container d-flex align-items-center'
          >
            <span className='action-icon'>
              <TimelineIcon fontSize='inherit' />
            </span>
            <span className='action-text'>Live Search Metrics</span>
          </div>
          <ActionsPopover
            className='action-container d-flex align-items-center'
            label={
              <div className='action-container d-flex align-items-center'>
                <span className='action-icon'>
                  <TrendingUpIcon fontSize='inherit' />
                </span>
                <span className='action-text'>Reports</span>
              </div>
            }
            list={[
              {
                label: 'Board Status Report 3.0',
                onClick: () => {
                  setIsStatusWithOptionOpen(true);
                  setReportsParams({
                    params: {
                      searchId: id,
                      reportType: 'BoardStatusReport',
                      hideIsShort: true,
                      hideCompensation: true,
                      hideFQDetails: true,
                      hideSummary: true,
                      pipeline: false,
                      timeZone: true
                    },
                    url: `${API.reports}/weekly-client-update`
                  });
                }
              },
              {
                label: 'Search Status Report 3.0',
                onClick: () => {
                  setIsStatusWithOptionOpen(true);
                  setReportsParams({
                    params: {
                      searchId: id,
                      reportType: 'StatusReport',
                      isArenaSearch: search.type === 'Inside Track',
                      timeZone: true
                    },
                    url: `${API.reports}/weekly-client-update`
                  });
                }
              },
              {
                label: 'LatAm Search Status Report',
                onClick: () => {
                  setIsStatusWithOptionOpen(true);
                  setReportsParams({
                    params: {
                      searchId: id,
                      reportType: 'StatusReport',
                      hideIsShort: true,
                      timeZone: true
                    },
                    url: `${API.reports}/latam-search-status`
                  });
                }
              },
              {
                label: 'Candidate Matrix Report',
                onClick: () => {
                  setIsMatrixWithOptionOpen(true);
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      timeZone: true
                    },
                    url: `${API.reports}/candidate-interview-report`
                  });
                }
              },
              {
                label: 'Report Builder',
                onClick: handleRedirectToReportBuilder
              }
            ]}
          />
          <Button onClick={handleCopySearchesToWorkbench} className='action-button mr-3' variant='outlined' color='primary'>
            Add to Search Workbench
          </Button>
          {!isLinkedinCandidate && (
            <Button disabled={!isRowSelected} color='primary' className='button' variant='contained' onClick={exportCallback}>
              Export
            </Button>
          )}
        </div>
      </Header>

      <LiveSearchMetrics
        id={id}
        jobTitle={jobTitle}
        jobNumber={search.job_number}
        open={isMetricsOpen}
        handleClose={() => {
          setIsMetricsOpen(false);
        }}
      />
      <div className='d-flex flex-column w-100 h-100'>
        {showCloningSearchPopup && <SearchCloning open={showCloningSearchPopup} searchId={id} handelClose={handelCloseCloningPopup} searchData={searchData} />}
        {showJobTitleChangedPopup && (
          <EmploymentHistoryConfirmationDialog
            header='Update'
            employmentHistoryMessageEndYearDate={employmentHistoryMessageEndYearDate}
            setEmploymentHistoryEndYearDate={setEmploymentHistoryEndYearDate}
            open={showJobTitleChangedPopup}
            contact={currentContact}
            handleSubmit={handleConfirmJobTitlePopup}
            handleClose={handleCancelJobTitlePopup}
            cancelText='No'
            confirmText='Yes'
            extraClassName='jobhistory-popup'
          />
        )}
        {isAddToWorkbenchOpen && (
          <AddContactToWorkbench
            open={isAddToWorkbenchOpen}
            contactIds={contactIds}
            onClose={handleAddToWorkbenchClose}
            searchId={isAllRowsSelected ? id : null}
            candidateStatus={candidateStatus}
            type='Contacts'
          />
        )}
        {isRemoveFromWorkbenchOpen && <RemoveContactFromWorkbench contactId={contactId} onClose={handleRemoveFromWorkbenchClose} open={isRemoveFromWorkbenchOpen} />}
        {isCopyToWorkbenchOpen && <AddContactsToWorkbench open={isCopyToWorkbenchOpen} searchIds={[id]} onClose={handleCopySearchesToWorkbenchClose} sourceWorkbenchId={null} type='Searches' />}
        {isCandidatesSyncPopup && (
          <SyncAllCandidateToHlPopup
            open={isCandidatesSyncPopup}
            onClose={handleCloseCandidatesSyncPopup}
            candidateData={invalidCandidatesForHl}
            showReason={showReason}
            onSubmit={() => {
              setIsCandidatesSyncPopup(false);
              handleWorkspacePopup();
            }}
            headerMessage={headerMessage}
            validCandidateLength={validCandidatesForHl?.length}
          />
        )}
        {isRemove ? <DeletePopup onClose={handleDeleteClose} id={candidateId} module={'candidates'} popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_CANDIDATE_FROM_SEARCH} /> : null}
        {isDeleteContactOpen ? (
          <DeletePopup onClose={handleDeleteContactClose} payload={{ failed: true }} id={contactId} module={'contacts'} popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_FAILED_CONTACT} />
        ) : null}
        {!initialLoader && <Loader className={'sync-message'} loadingMessage={loadingMessage} show={isLoading} />}
        {isCreateWorkbenchOpen && <AddWorkbench onClose={handleCreateWorkbenchClose} buttonText={VALIDATION_MESSAGE.create_workbench_add_contacts} DefaultValue={'Contacts'} Disabled={true} />}
        {isAthenaPopupOpen ? (
          <AthenaInvitePopUp inviteFor={'candidate'} selectedDropDown={'Athena'} client={athenaData} onClose={handleAthenaPopupClose} isDisableSendButton={isDisableSendButton} />
        ) : null}
        {isCandidateSuitePopupOpen ? (
          <CandidateSuiteInvitePopUp
            inviteFor={'candidate'}
            selectedDropDown={'CandidateSuite'}
            client={candidatesuiteData}
            onClose={handleIsCandidateSuitePopupClose}
            isDisableSendButton={isDisableSendButton}
          />
        ) : null}
        {activeContact && <ActivityHistories contact={activeContact} onPopupClose={() => showActivityHistory(false)} />}
        {activeContactDocs && <DocsPopup contact={activeContactDocs} onPopupClose={() => showDocsList(false)} isResetDocs={true} />}
        {isAddToSearchOpen && (
          <CopyCandidatesToSearch
            contacts={contacts}
            header={VALIDATION_MESSAGE.add_contacts_to_search}
            buttonText={VALIDATION_MESSAGE.add_contacts_to_search}
            uiLabel='contact(s)'
            open={true}
            searchId={null}
            contactIds={contactIds}
            isAllRowsSelected={isAllRowsSelected}
            handleClose={handleAddToSearchClose}
          />
        )}
        {isAddCandidateOpen && (
          <AddCandidateByContact searchId={id} searchName={search.job_title} contact={addContact} open={isAddCandidateOpen} handleClose={handleAddCandidateClose} header='Add Candidate By Contact' />
        )}
        {isLinkedinPopup && <AddLinkedinPopup open={isLinkedinPopup} handleClose={handleAddLinkedinDataClose} searchId={props.id} isCandidate={true} title='Candidates' />}
        {isCopyCandidatesOpen && (
          <CopyCandidatesToSearch
            contacts={contacts}
            open={isCopyCandidatesOpen}
            searchId={isAllRowsSelected ? id : null}
            contactIds={contactIds}
            queryData={queryData}
            handleClose={handleCopyCandidatesClose}
          />
        )}
        {isQuickAddOpen && (
          <QuickAddContact searchId={id} open={isQuickAddOpen} contact={linkedinContactData} handleClose={handleQuickAddContactClose} enqueueSnackbar={enqueueSnackbar} isAddToSearchEnabled={true} />
        )}
        {isReportsOpen && (
          <ReportOptions
            timeZoneOptions={timeZoneOptions}
            defaultLocalTime={defaultLocalTime}
            openOnMatrix={isMatrixWithOptionOpen}
            onClose={() => {
              setIsReportsOpen(false);
              isMatrixWithOptionOpen(false);
              setReportsParams({});
            }}
            {...reportsParams}
          />
        )}
        {hireLogic && options?.length > 0 && (
          <HireLogic
            handleWorkspaceSubmit={handleWorkspaceSubmit}
            handleWorkspaceChange={handleWorkspaceChange}
            workspace={workspace}
            company={search.company && search.company.name}
            options={options}
            jobTitle={jobTitle}
            onClose={() => {
              setHireLogic(false);
            }}
          />
        )}
        {isStatusWithOptionOpen && (
          <StatusReportWithOptions
            onClose={() => setIsStatusWithOptionOpen(false)}
            options={{ pipeline: true, ...reportsParams.params }}
            onContinue={params => {
              setIsStatusWithOptionOpen(false);
              setIsReportsOpen(true);
              setReportsParams(prev => {
                return {
                  ...prev,
                  reportsParams: {
                    ...prev.reportsParams,
                    params: {
                      ...prev.reportsParams.params,
                      ...params
                    }
                  }
                };
              });
            }}
          />
        )}
        <div className='all-candidates-actions d-flex'>
          {!isLinkedinCandidate && (
            <Button
              onClick={() => {
                setIsQuickAddOpen(true);
              }}
              variant='outlined'
              color='primary'
              className='mr-2'
            >
              Quick Add Contact
            </Button>
          )}
          {!isLinkedinCandidate && (
            <Button onClick={handleChangeAddCandidate} variant='outlined' color='primary' className='mr-2'>
              Add Candidate By Contact
            </Button>
          )}
          <Button onClick={handleChangeAddCandidateFromLinkedin} variant='outlined' color='primary' className='mr-2'>
            Add Candidate From LinkedIn
          </Button>
          {!isLinkedinCandidate && (
            <Button disabled={!isRowSelected} onClick={handleCopyCandidates} variant='outlined' color='primary' className='mr-2'>
              Copy Candidates To Search
            </Button>
          )}
          {!isLinkedinCandidate && (
            <Button variant='outlined' color='primary' className='mr-2' disabled={!isRowSelected} onClick={handleAddContactsToWorkbench}>
              Add Contacts To Workbench
            </Button>
          )}
          {!isLinkedinCandidate && (
            <Button variant='outlined' color='primary' disabled={!isRowSelected} onClick={handleCreateWorkbench}>
              Create New Workbench
            </Button>
          )}
        </div>
        <div className='count-container pt-3'>
          <div className='action-container' onClick={() => resetFilter()}>
            <span className='action-text'>Reset Filter</span>
          </div>
          <div className='action-container' onClick={() => resetSort()}>
            <span className='action-text'>Reset Sort</span>
          </div>
          {!isLinkedinCandidate && (
            <div className='action-container' onClick={() => handleSyncAllCandidateToHl()}>
              <span className='action-text'>Sync All Candidates to HL</span>
            </div>
          )}
          <ColorLegend colorList={colorList} setColorDesc={setColorDesc} />
          <Typography>Total count: {rowCount}</Typography>
        </div>
        <div className='list-view'>
          {isGridReady && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} showFilterCount={showFilterCount} filterModel={filterValue} />}
          <div id='myGrid' className='ag-theme-alpine'>
            <AgGridReact
              onGridReady={onGridReady}
              enableBrowserTooltips={true}
              defaultColDef={{
                resizable: true,
                sortable: true,
                minWidth: 150,
                sortingOrder: ['asc', 'desc', null]
              }}
              isRowSelectable={params => {
                const status = checkContactFetchingStatus(params);
                if (status) return false;
                return true;
              }}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={'CustomLoadingOverlayComponent'}
              frameworkComponents={{
                CustomLoadingOverlayComponent,
                LinkedInRenderer: LinkedInRenderers,
                DateTimeRenderer,
                StageEditor,
                StageRenderer,
                FQRenderer,
                EmailRenderer,
                DateRenderer,
                CustomFilter,
                ActionsRenderer: ActionsRenderer,
                ColorRenderer: ColorRenderers,
                HeaderCheckbox: HeaderCheckbox,
                NameRenderer: NameRenderer,
                DocsRenderer: DocsRenderer,
                HireLogicRenderer: HireLogicRenderer,
                CompanyNameRenderer: CompanyNameRenderers,
                //HireLogicLinkRenderer: HireLogicLinkRenderer,
                GenericCellEditor,
                RichTextBoxEditor,
                RichTextPopupRendered,
                CompensationRenderer,
                ResumeRenderer,
                ReasonEditor,
                AthenaRenderer: AthenaRenderer,
                CandidateSuiteRenderer: CandidateSuiteRenderer
              }}
              suppressMenuHide={true}
              getRowNodeId={data => data.id}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              rowModelType={'infinite'}
              datasource={dataSource}
              columnDefs={columnDefs(
                showActivityHistory,
                enqueueSnackbar,
                closeSnackbar,
                candidateStatus !== 'CLIENTSUITEANDREPORTS',
                loaderChange,
                users,
                search.type,
                dispatch,
                setJobHistoryPopupStates
              )}
              paginationPageSize={PAGE_LIMIT}
              rowSelection={'multiple'}
              onRowSelected={() => {
                /* if (!e.node.selected) {
                  setState({ isAllRowsSelected: false })
                } */
                setIsRowSelected(gridApi.getSelectedRows()?.length > 0 ? true : false);
              }}
              suppressRowClickSelection={true}
              suppressDragLeaveHidesColumns={true}
              onFilterChanged={onFilterChanged}
              onSortChanged={onSortChanged}
              onFirstDataRendered={onFirstDataRendered}
              onDisplayedColumnsChanged={saveColumnState}
              onDragStopped={saveColumnState}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
};

CandidatesList.propTypes = {
  id: PropTypes.string,
  search: PropTypes.object,
  jobTitle: PropTypes.string,
  confidential: PropTypes.bool,
  candidateStatus: PropTypes.string,
  location: PropTypes.object,
  navigate: PropTypes.func,
  fetchPickList: PropTypes.func,
  colorList: PropTypes.array,
  setColorDesc: PropTypes.func,
  fetchUserList: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  fetchPickList,
  fetchUserList,
  dispatch
});

export default connect(null, mapDispatchToProps)(CandidatesList);
