import React, { useState } from 'react';
import CustomDropdown from '../../CustomDropdown';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchPickLists } from '../../../../actions';

const DocumentTypeSelection = props => {
  const { placeholder, isForCompany = false, onChange = () => {}, label = '', value = '', required = false, ...rest } = props;
  const [options, setOptions] = useState([]);
  const documentTypeOption = useSelector(state => state.commonReducer.documentType);
  const dispatch = useDispatch();
  //key will replaced by document type key
  useEffect(() => {
    if (!documentTypeOption) {
      dispatch(fetchPickLists('DOCUMENT_TYPE', 'documentType'));
    } else {
      if (isForCompany) {
        const updatedOptions = documentTypeOption.map(option => ({
          ...option,
          disabled: option.short_desc === value
        }));
        setOptions(updatedOptions);
      } else {
        setOptions(documentTypeOption);
      }
    }
  }, [documentTypeOption, dispatch, value]);

  return (
    <>
      <CustomDropdown {...rest} required={required} options={options} placeholder={placeholder} onChange={onChange} label={label} defaultValue={value} disabled={isForCompany} />
    </>
  );
};
DocumentTypeSelection.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  isForCompany: PropTypes.bool
};
export default DocumentTypeSelection;
