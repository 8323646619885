//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useCallback, useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Typography, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { getUserSelector } from '../../../../selectors';
import InputField from '../../../common/InputField';
import { formatDate } from '../../../../utils/date';
import TextPopup from '../../../TextPopup';
import { GET, POST, PUT, ERROR, SUCCESS } from '../../../../services/constantService';
import { candidateDataApi } from '../../../../services/ApiService';
import { unableMessage, successMessage } from '../../../../services/MessageService';
import { UPDATE_CANDIDATESUITE_FEEDBACK } from '../../../../types';
import { forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { useImperativeHandle } from 'react';
import TabLayoutFooter from '../../../TabLayoutFooter';

// eslint-disable-next-line react/display-name
const CandidateSuiteFeedback = forwardRef((props, ref) => {
  const { candidateId, setLoading, readOnly, handleBack } = props;
  const [clientSuiteFeedback, setClientSuiteFeedback] = useState([]);
  const [open, setOpen] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(getUserSelector);
  const { getValues } = useForm({});
  const actions = [
    {
      label: 'Close',
      className: 'primary-btn mr-3',
      onClick: () => {
        handleBack(true);
      }
    }
  ];

  useImperativeHandle(ref, () => ({
    saveTabData: () => {
      return true;
    },
    isDirty: () => {
      return false;
    },
    formData: () => {
      return getValues();
    },
    reload: () => {
      fetchData();
    }
  }));
  const dispatch = useDispatch();
  const reduxCandidateSuiteFeedback = useSelector(state => state.rootReducer.reduxCandidateSuiteFeedback);
  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!reduxCandidateSuiteFeedback) {
      const sub_route = `candidates/${candidateId}/candidate-feedback?sortBy: 'updated_at'`;
      const { status, data } = await candidateDataApi(GET, '', '', sub_route);
      if (status === 200 && Array.isArray(data)) {
        dispatch({ type: UPDATE_CANDIDATESUITE_FEEDBACK, payload: { key: 'reduxCandidateSuiteFeedback', data } });
        setClientSuiteFeedback(data);
      } else {
        const message = unableMessage('Candidate Suite feedback', 'fetch');
        enqueueSnackbar(data.message || message, { variant: ERROR });
      }
    } else {
      setClientSuiteFeedback(reduxCandidateSuiteFeedback);
    }
    setLoading(false);
  }, [candidateId, setLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderField = (label, value) => {
    return (
      <div className='d-flex align-items-center mb-1'>
        <Typography color='primary'>{label}</Typography>
        <span className='ml-2 mr-4'>{value}</span>
      </div>
    );
  };

  const renderTextField = (label, value, feedback) => {
    if (label === 'Reply') {
      if (value?.length === 0) {
        return (
          <div className='mb-2'>
            <Typography color='primary' style={{ fontSize: 14 }}>
              {label}
            </Typography>
            <TextPopup
              title={label}
              onSave={data => {
                updateReply(feedback, data);
              }}
              className='w-100'
              value={value}
            />
          </div>
        );
      } else {
        return value.map((item, key) => (
          <div key={key} className='mb-2'>
            <Typography color='primary' style={{ fontSize: 14 }}>
              {label} By {item?.created_name}{' '}
            </Typography>
            <TextPopup
              title={label}
              onSave={data => {
                updateReply(item, data);
              }}
              className='w-100'
              value={item.reply}
            />
          </div>
        ));
      }
    } else {
      return (
        <div className='mb-2'>
          <Typography color='primary' style={{ fontSize: 14 }}>
            {label} By {feedback.created_name}{' '}
          </Typography>
          <InputField disabled={true} value={value} className='w-100' />
        </div>
      );
    }
  };

  // Update Comments if require un comment this.
  // const updateClientFeedback = async (index, comments) => {
  //   if (clientSuiteFeedback[index].comments !== comments) {
  //     setLoading(true)
  //     const payload = { comments: comments, created_by: user.id, type: "User" }
  // const sub_route=`candidates/${clientSuiteFeedback[index].id}`
  //     const { status, data } = await candidate_data(POST, '', payload,sub_route );
  //     if (status === 200) {
  //       enqueueSnackbar('Updated comments successfully', { variant: SUCCESS })
  //       await fetchData()
  //     } else {
  //       enqueueSnackbar(data.message || 'Unable to update comments', { variant: ERROR })
  //     }
  //     setLoading(false)
  //   }
  // }

  const updateReply = async (feedback, replyText) => {
    try {
      const reply = feedback.reply;
      setLoading(true);
      if (reply || reply === '') {
        if (reply !== replyText) {
          const payload = { reply: replyText, created_by: user.id, type: 'User' };
          const sub_route = `reply/${feedback.id}`;
          const { status, data } = await candidateDataApi(PUT, '', payload, sub_route);
          if (status === 200) {
            const message = successMessage('Reply', 'updated');
            enqueueSnackbar(message, { variant: SUCCESS });
            await fetchData();
          } else {
            const message = unableMessage('reply', 'update');
            enqueueSnackbar(data.message || message, { variant: ERROR });
          }
        }
      } else {
        const payload = { reply: replyText, created_by: user.id, type: 'User' };
        const sub_route = `candidate-feedback/${feedback.id}/${feedback.candidate_id}/reply`;
        const { status, data } = await candidateDataApi(POST, '', payload, sub_route);
        if (status === 200) {
          const message = successMessage('Reply', 'added');
          enqueueSnackbar(message, { variant: SUCCESS });
          await fetchData();
          setOpen();
        } else {
          const message = unableMessage('reply', 'add');
          enqueueSnackbar(data.message || message, { variant: ERROR });
        }
      }
      setLoading(false);
    } catch (e) {
      console.log('Error found in updateReply::', e);
    }
  };

  const renderClientFeedback = (feedback, index) => {
    return (
      <div key={index} className='feedback card'>
        <h6 className='mb-3'>Candidate Feedback</h6>
        <div>
          <div className='mb-2 d-flex justify-content-between'>{renderField('Date:', formatDate(feedback.updated_at || feedback.created_at, 'DD/MM/YYYY'))}</div>
          {renderTextField('Comment', feedback.comments, feedback)}
          <h6 className='mb-3'>CandidateSuite Responses</h6>
          {renderTextField('Reply', feedback.replies, feedback)}
          {open === index && renderTextField('Reply', '', feedback)}
        </div>
        <Button variant={'text'} startIcon={<AddCircleIcon />} style={{ marginLeft: 305 }} onClick={() => setOpen(index)} color='primary'>
          Add New Reply
        </Button>
      </div>
    );
  };

  return (
    <div>
      <div className='client-suite-feedback'>
        <Typography className='heading'>CandidateSuite Feedback Log</Typography>
        {clientSuiteFeedback.map((item, index) => renderClientFeedback(item, index))}
      </div>
      {!readOnly && <TabLayoutFooter actions={actions} />}
    </div>
  );
});

CandidateSuiteFeedback.propTypes = {
  candidateId: PropTypes.string,
  setLoading: PropTypes.func,
  readOnly: PropTypes.bool,
  handleBack: PropTypes.func
};
export default CandidateSuiteFeedback;
