//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { connect, useDispatch } from 'react-redux';
// import { withRouter } from "react-router-dom";
import withRouter from '../../utils/withRouter';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { AgGridReact } from 'ag-grid-react';
import { Button, Checkbox, Typography } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { addContactsToWorkbench, DateRenderer, DateTimeRenderer, LinkedInRenderer, loadColumnStateFromLocalStorage, saveColumnStateToLocalStorage } from '../Commons/Utils';
import {
  addAsMyBDTarget,
  columnDefs,
  columnMergeContactDefs,
  ContactNameRenderer,
  CustomLoadingOverlayComponent,
  defaultColumns,
  EmailRenderer,
  getSelectedContactIds,
  PAGE_LIMIT,
  PhoneRenderer,
  ResumeRenderer,
  SUCCESS_STATUS_CODE,
  getFilterParamString,
  acquire as acquireUtil,
  getQueryParamsString,
  checkContactFetchingStatus
} from './utils';
import ActionsPopover from './ActionsPopover';
import DeletePopup from '../Commons/DeletePopup';
import ActivityHistories from '../../components/ActivityLog/Components/GeneralInfo/Components/ActivityHistory/ActivityHistories';
import CopyCandidatesToSearch from '../Searches/AllCandidates/CopyCandidatesToSearch';
import ColumnFilter from '../Commons/ColumnFilter';
import { CompensationRenderer, SCROLL_TIMEOUT, sortByChronologicalOrder } from '../../utils/common';
import GenericCellEditor from '../Commons/CellEditors/GenericCellEditor';
import AddContactAsClient from './components/AddContactAsClient';
import AddContactToWorkbench from '../Commons/AddContactToWorkbench';
import RemoveContactFromWorkbench from '../Commons/RemoveContactFromWorkbench';
import AddWorkbench from '../../components/AddWorkbench';
import CustomFilter from '../Commons/CustomFilter';
import AthenaPopover from '../Searches/AllCandidates/Components/AthenaPopover';
import { formatDate } from '../../utils/date';
import AthenaInvitePopUpContact from '../../components/ViewSearch/Components/SFPA/Components/ClientAppAccess/AthenaInvitePopUpContact';
import { API, BLOB, EXPORT_CONTACT_LIMIT, POST, ERROR, WARNING, DELETE_CONFIRMATION_POPUP_MESSAGE, LINKEDIN_SCRAPER } from '../../services/constantService';
import LinkedInDialog from '../Commons/LinkedInDialog';
import Loader from '../../components/common/Loader';
import { dataSourceUtils } from '../../utils/dataSource';
import { fetchUserList } from '../../actions';
import { acquireLockApi, contactDataApi, contactDataRefreshApi, releaseLockApi } from '../../services/ApiService';
import { exportMessage, VALIDATION_MESSAGE, notFoundMessage } from '../../services/MessageService';
import EmploymentHistoryConfirmationDialog from '../Commons/EmploymentHistoryConfirmationDialog';
import { cleanupCompanyDetails } from '../../components/AddContact/utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import PdlContactsMergingPopup from '../Commons/PdlContactsMergPopup';
import { useSnackbar } from 'notistack';
import { CompanyNameRenderer } from '../Companies/Utils';
let gridApi, columnApi;

const Contacts = props => {
  const [queryData, setQueryData] = useState({});
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [showQuery, setShowQuery] = useState(false);
  const isAllRowsSelected = false;
  const [contactIds, setContactIds] = useState([]);
  const [activeContact, setActiveContact] = useState(null);
  const [rowCount, setRowCount] = useState(0);
  const [contacts, setContacts] = useState([{}]);
  const [isAthenaPopupOpen, setIsAthenaPopupOpen] = useState(false);
  const [linkedPopup, setLinkedPopup] = useState(false);
  const [minimizeQuery, setMinimizeQuery] = useState(true);
  const [linkedInValue /* , setLinkedInValue */] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showJobTitleChangedPopup, setShowJobTitleChangedPopup] = useState(false);
  const [employmentHistoryMessageEndYearDate, setEmploymentHistoryMessageEndYearDate] = useState(null);
  const [isUpdateEmploymentHistory, setIsUpdateEmploymentHistory] = useState(false);
  const [skipCheckJobTitle, setSkipCheckJobTitle] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [newContactData, setNewContactData] = useState(null);
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [isClassicQuery, setIsClassicQuery] = useState(false);
  const [showClassic, setShowClassic] = useState(true);
  const [showMergePopup, setShowMergePopup] = useState(false);
  const [pdlData, setPdlData] = useState(null);
  const [isDeleteContactOpen, setIsDeleteContactOpen] = useState(false);
  const [savedQuery, setSavedQuery] = useState(false);
  const [isGridReady, setIsGridReady] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isRemoveFromWorkbenchOpen, setIsRemoveFromWorkbenchOpen] = useState(false);
  const [isCreateWorkbenchOpen, setIsCreateWorkbenchOpen] = useState(false);
  const [filterModel, setFilterModel] = useState({});
  const [athenaData, setAthenaData] = useState({
    candidate: '',
    contact: ''
  });
  const [sortModel, setSortModel] = useState();
  const [isAddToSearchOpen, setIsAddToSearchOpen] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [isAddAsClientOpen, setIsAddAsClientOpen] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const [isAddToWorkbenchOpen, setIsAddToWorkbenchOpen] = useState(false);
  const child = React.createRef();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(showClassic, savedQuery);
    dispatch({ type: 'resetLinkedinScraperEvents' });
    if (!props.users) {
      props.fetchUserList('users');
    }
    dispatch({ type: 'resetLinkedinScraperEvents' });
    if (skipCheckJobTitle) {
      handleUpdateJobHistory();
    }
    if (props[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]) {
      const event = props[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED];
      // console.log('socket changes found:: ', this.props.socket)
      console.log('socket event found:: ', LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED, event.msg, event.obj);
      let contactFound;
      gridApi.forEachNode(node => {
        console.log('node', node.data?.id);
        console.log('socket obj::', event.obj?.contact?.id);
        if (node.data?.id === event.obj?.contact?.id) {
          contactFound = node;
        }
      });
      console.log('socket event contact found:: ', contactFound);
      if (contactFound) {
        contactFound.setData(event.obj.contact);
        gridApi.refreshCells({ force: true });
      }
      props.dispatch({
        type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED,
        payload: undefined
      });
      // this.props.socket.on(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED, (msg, obj) => {
      // })
    }
    if (props.refreshContact) {
      gridApi.onFilterChanged();
    }
  }, []);

  const getURLs = {
    listURl: `${API.contact}/${props.type}`,
    classicURl: `${API.contact}`
  };
  const resetFilter = async () => {
    gridApi.setFilterModel(null);
    const customFilterField = columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        gridApi.destroyFilter(item.field);
      }
    });
  };
  const resetSort = async () => {
    gridApi.setSortModel(null);
  };
  const dataSource = {
    getRows: async params => {
      try {
        setFilterModel(params.filterModel);
        params.filterModel && setFilterModel(params.filterModel);
        setShowFilterCount(Object.keys(filterModel)?.length);

        //  gridApi.deselectAll();
        if (params.filterModel && Object.keys(params.filterModel)?.length > 0) gridApi.deselectAll();
        const thisValue = {
          location,
          isClassicQuery,
          queryData
        };
        gridApi.showLoadingOverlay();
        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: getURLs,
          subScreen: true
        };
        const { status, data } = await dataSourceUtils(obj, getFilterParamString, getQueryParamsString);
        if (status === SUCCESS_STATUS_CODE) {
          if (data.paging) {
            const message = notFoundMessage('records');
            if (data?.paging?.totalCount === 0) {
              enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(data.data, data.paging?.totalCount);
            setRowCount(data.paging?.totalCount);
            setContacts(data.data);
          } else if (Object.keys(data)?.length) {
            const message = notFoundMessage('records');
            if (data?.length === 0) {
              enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback([data], 1);
          }
          isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
        } else {
          params.failCallback();
        }
        gridApi.hideOverlay();
      } catch (e) {
        console.log('Error found in getRows::', e);
      }
    },
    rowCount: null
  };

  const handleAthenaPopupClose = () => {
    setIsAthenaPopupOpen(false);
    gridApi.onFilterChanged();
  };

  const athenaRenderer = params => {
    const status = checkContactFetchingStatus(params);
    if (status) return status;
    const handleInvite = () => {
      setIsAthenaPopupOpen(true);
      setIsAthenaPopupOpen(true);
      setAthenaData({
        candidate: { id: params.data?.candidates },
        contact: params.data
      });
    };
    if (params.data) {
      if (params.data.athena_completion_date || params.data.athena_invitation_sent_on) {
        return (
          <AthenaPopover contactDetails={params.data} handleInvite={handleInvite}>
            <Typography style={{ fontSize: 'inherit', cursor: 'pointer' }} color='primary'>
              {params.data.athena_completion_date ? `Completed on: ${formatDate(params.data.athena_completion_date)}` : `Invited on: ${formatDate(params.data.athena_invitation_sent_on)}`}
            </Typography>
          </AthenaPopover>
        );
      } else if (params.data.candidates?.length > 0) {
        return (
          <Button className='invite-button athena-button' variant='contained' color='primary' onClick={handleInvite}>
            Invite to Athena
          </Button>
        );
      } else {
        return (
          <Typography style={{ fontSize: 'inherit', cursor: 'pointer' }} color='primary'>
            Contact Is Not Associated With Any Search
          </Typography>
        );
      }
    }
    return null;
  };

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    // const columnApi = params.columnApi;
    // const gridApi = params.api;
    loadColumnStateFromLocalStorage(props.storageKey, {
      columnApi,
      gridApi
    });
    setIsGridReady(true);
  };
  const exportCallback = async () => {
    const selectedRows = gridApi.getSelectedRows();
    if (isAllRowsSelected && rowCount > EXPORT_CONTACT_LIMIT) {
      const message = exportMessage(`${EXPORT_CONTACT_LIMIT}`, 'Contacts');
      enqueueSnackbar(message, { variant: ERROR });
    } else if (!isAllRowsSelected && selectedRows?.length > EXPORT_CONTACT_LIMIT) {
      const message = exportMessage(`${EXPORT_CONTACT_LIMIT}`, 'Contacts');
      enqueueSnackbar(message, { variant: ERROR });
    } else {
      let sub_route = 'export-as-excel?';
      if (filterModel && Object.keys(filterModel)?.length) {
        sub_route = sub_route.concat(getFilterParamString(filterModel, showQuery));
        sub_route = `${sub_route}&filter=true`;
      }
      let id = [];
      if (!isAllRowsSelected) {
        selectedRows.map(data => id.push(data.id));
      }
      const columnHeaders = columnApi.getAllDisplayedColumns().map(column => column.getColDef().headerName);
      gridApi.showLoadingOverlay();
      const payload = {
        headers: {
          columnHeaders: columnHeaders,
          selectedRows: { id: id },
          type: props.title,
          allRowSelected: isAllRowsSelected,
          limit: EXPORT_CONTACT_LIMIT
        }
      };
      let { status, data, headers } = await contactDataApi(POST, '', payload, sub_route, BLOB);
      if (status === 200) {
        let fileName = 'Contacts.xlsx';
        let fileNameHeader = headers['content-disposition'].split('"');
        if (fileNameHeader && fileNameHeader?.length > 2) {
          fileName = fileNameHeader[1];
        }
        saveAs(new File([data], fileName));
      } else {
        enqueueSnackbar(VALIDATION_MESSAGE.export_fail, {
          variant: ERROR
        });
      }
      gridApi.hideOverlay();
    }
  };

  const setSelectAllRows = isAllRowsSelected => {
    setSelectAllRows(isAllRowsSelected);
    gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };

  const handleChange = () => {
    setSelectAllRows(!isAllRowsSelected);
  };

  const handleAddAsClient = contactId => {
    setIsAddAsClientOpen(true);
    setContactIds([contactId]);
  };

  const handleAddAsClientClose = () => {
    setIsAddAsClientOpen(false);
    setContactIds([]);
  };

  const headerCheckbox = () => {
    return <Checkbox style={{ padding: 0, width: 16, height: 16, color: 'white' }} size='small' color='primary' onChange={handleChange} />;
  };

  const nameRenderer = params => {
    return <ContactNameRenderer params={params} handleAddToSearch={handleAddToSearch} />;
  };

  const linkedInPopupHandler = () => {
    setLinkedPopup(true);
    // setLinkedInValue(value);
  };

  const linkedInRenderer = params => {
    return <LinkedInRenderer params={params} linkedInPopup={linkedInPopupHandler} />;
  };

  const handleClose = () => {
    setLinkedPopup(false);
  };

  const addContactToRenderer = params => {
    const status = checkContactFetchingStatus(params);
    if (status) return status;

    const list = [
      {
        label: 'To Search',
        onClick: () => handleAddToSearch(params?.data?.id)
      },
      {
        label: 'To Workbench',
        onClick: () => handleAddToWorkbench(params.data?.id)
      }
    ];
    if (props.type === 'my-bd-targets') {
      list.push({
        label: 'As Client',
        onClick: () => handleAddAsClient(params?.data?.id)
      });
    } else {
      list.push({
        label: 'As BD Target',
        onClick: () => addAsMyBDTarget(params?.data, props.userData, gridApi, enqueueSnackbar)
      });
    }

    return params.data ? <ActionsPopover list={list} /> : null;
  };

  const handleAddToSearchBySelection = () => {
    setIsAddToSearchOpen(true);
    setContactIds(getSelectedContactIds(gridApi));
  };

  const handleAddToSearch = contactId => {
    setIsAddToSearchOpen(true);
    setContactIds([contactId]);
  };

  const companyNameRenderer = params => {
    const status = checkContactFetchingStatus(params);
    if (status) return status;
    return <CompanyNameRenderer company={params?.data?.company} />;
  };

  const handleAddToSearchClose = () => {
    setIsAddToSearchOpen(false);
    setContactIds([]);
  };

  const showActivityHistory = activeContact => {
    setActiveContact(activeContact);
  };

  const handleDeleteContactClose = deleted => {
    setIsDeleteContactOpen(false);
    setContactId(null);
    if (deleted) {
      gridApi.onFilterChanged();
    }
  };

  const actionsRenderer = params => {
    const status = checkContactFetchingStatus(params);
    if (status === LINKEDIN_SCRAPER.PROCESSING_LABEL) return status;

    let list;
    if (status === LINKEDIN_SCRAPER.FAILED_LABEL) {
      list = [
        {
          label: 'Delete Record',
          onClick: async () => {
            setIsDeleteContactOpen(true);
            setContactId(params.data?.id);
          }
        }
      ];
    } else {
      list = [
        {
          label: 'Log An Activity',
          onClick: async () => {
            setIsRemove(false);
            acquire(params?.data?.id);
          }
        },
        {
          label: 'Remove From Workbench',
          onClick: () => {
            setIsRemoveFromWorkbenchOpen(true);
            setContactId(params.data?.id);
          }
        },
        {
          label: 'Show History',
          onClick: params.colDef ? () => params.colDef.cellRendererParams.showActivityHistory(params.data) : null
        },
        {
          label: 'Delete Record',
          onClick: () => {
            setIsDeleteOpen(true);
            setContactId(params.data?.id);
          }
        }
      ];
    }
    return params.data ? <ActionsPopover list={list} /> : null;
  };
  const acquire = async contactId => {
    let { status, data } = await acquireLockApi(contactId);
    if (status && status === 200 && data.message === undefined) {
      if (isRemove) {
        await releaseLockApi(contactId);
        setIsDeleteOpen(true);
        setContactId(contactId);
      } else {
        navigate(`/contacts/${contactId}/log-an-activity/general-info`, {
          sortModel: sortModel,
          fromContact: true,
          previousPath: props.location.pathname
        });
      }
    } else {
      if (data.isAdmin) {
        enqueueSnackbar(data.message, {
          variant: WARNING,
          // eslint-disable-next-line react/display-name
          action: key => (
            <>
              <Button
                size='small'
                variant='text'
                color='inherit'
                onClick={() => {
                  closeSnackbar(key);
                  releaseLock(contactId);
                }}
              >
                Force Unlock
              </Button>
              <Button
                size='small'
                variant='text'
                color='inherit'
                onClick={() => {
                  closeSnackbar(key);
                  navigate(`/contacts/${contactId}/log-an-activity/read-only/general-info`, {
                    sortModel: sortModel,
                    fromContact: true,
                    previousPath: props.location.pathname
                  });
                }}
              >
                Open Read Only View
              </Button>

              <Button
                size='small'
                variant='text'
                color='inherit'
                onClick={async () => {
                  closeSnackbar(key);
                }}
              >
                CLOSE
              </Button>
            </>
          )
        });
      } else {
        enqueueSnackbar(data.message, {
          variant: WARNING,
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key);
                navigate(`/contacts/${contactId}/log-an-activity/read-only/general-info`, {
                  sortModel: sortModel,
                  fromContact: true,
                  previousPath: props.location.pathname
                });
              }}
            >
              Open Read Only View
            </Button>
          )
        });
      }
      setIsRemove(false);
    }
  };
  const releaseLock = async contactId => {
    await releaseLockApi(contactId);
    acquire(contactId);
  };
  const handleDeleteClose = deleted => {
    setIsDeleteOpen(false);
    setContactId(null);
    if (deleted) {
      gridApi.onFilterChanged();
    }
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    setSortModel(sortModel);
    saveColumnStateForSort();
  };

  const onFirstDataRendered = () => {
    const locationState = props.location.state;
    if (locationState) {
      const sortModel = locationState.sortModel;
      if (sortModel) {
        gridApi.setSortModel(sortModel);
      }
    }
  };

  const saveColumnState = async () => {
    try {
      setIsLoading(true);
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage('contactColumns', {
        columnApi: columnApis,
        gridApi: gridApis
      });
      setIsLoading(false);
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForFilter = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage('contactColumns', { columnApi: columnApis, gridApi: gridApis }, true, false);
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForSort = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage('contactColumns', { columnApi: columnApis, gridApi: gridApis }, false, true);
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const handleRowSelection = () => {
    /* if (!e.node.selected) {
      setState({ isAllRowsSelected: false })
    } */
    setIsRowSelected(gridApi.getSelectedRows()?.length > 0 ? true : false);
  };

  const handleActivityHistoryClose = () => this.showActivityHistory(null);

  const handleAddToWorkbench = contactId => {
    setIsAddToWorkbenchOpen(true);
    setContactIds([contactId]);
  };

  const handleAddToWorkbenchClose = () => {
    setIsAddToWorkbenchOpen(false);
  };

  const handleRemoveFromWorkbenchClose = () => {
    setIsRemoveFromWorkbenchOpen(false);
  };

  const handleMergeClose = () => {
    setShowMergePopup(false);
  };

  const handleAddContactsToWorkbench = () => {
    console.log('Hello from Contacts');
    // const contactIds = ;
    // this.setState({ isAddToWorkbenchOpen: true, contactIds });
    setIsAddToWorkbenchOpen(true);
    setContactIds(getSelectedContactIds(gridApi));
  };

  const handleCreateWorkbench = () => {
    setIsCreateWorkbenchOpen(true);
  };

  const contactDataRefresh = async () => {
    let selectedRows = gridApi.getSelectedRows();
    if (selectedRows?.length > 10) {
      enqueueSnackbar(VALIDATION_MESSAGE.review_contact, {
        variant: WARNING
      });
    } else {
      setIsLoading(true);
      let result = await contactDataRefreshApi(selectedRows);
      if (result && result.data) {
        setPdlData(selectedRows);
        setShowMergePopup(true);
      } else {
        enqueueSnackbar(VALIDATION_MESSAGE.review_contact_fail, {
          variant: ERROR
        });
      }
      setIsLoading(false);
    }
  };

  // const handleQuery = (queryData) => {
  //   if (isAllRowsSelected === true) {
  //     setSelectAllRows(false);
  //     setIsAllRowsSelected(true);
  //   } else {
  //     gridApi.forEachNode((node) => {
  //       node.setSelected(false);
  //     });
  //   }
  //   setQueryData(cleanupQuery(queryData));
  //   gridApi.onFilterChanged();
  // };

  // const classicQuery = (queryData) => {
  //   setIsClassicQuery(true);
  //   handleQuery(queryData);
  // };

  const handleCreateWorkbenchClose = async workbenchId => {
    try {
      setIsCreateWorkbenchOpen(false);
      if (workbenchId) {
        gridApi.showLoadingOverlay();
        const contactIds = getSelectedContactIds(gridApi);
        await addContactsToWorkbench(workbenchId, contactIds, enqueueSnackbar);
        gridApi.hideOverlay();
      }
    } catch (e) {
      console.log('Error found in handleCreateWorkbenchClose::', e);
    }
  };

  const loaderChange = async value => {
    setIsLoading(value);
  };

  const setEmploymentHistoryEndYearDate = endDate => {
    setEmploymentHistoryMessageEndYearDate(endDate);
  };

  const handleConfirmJobTitlePopup = async () => {
    setIsUpdateEmploymentHistory(true);
    setShowJobTitleChangedPopup(false);
    setSkipCheckJobTitle(true);
  };
  const handleCancelJobTitlePopup = async () => {
    setIsUpdateEmploymentHistory(false);
    setShowJobTitleChangedPopup(false);
    setSkipCheckJobTitle(true);
  };

  const setJobHistoryPopupStates = () => {
    setEmploymentHistoryMessageEndYearDate(null);
    setIsUpdateEmploymentHistory(false);
    setCurrentContact(null);
    setNewContactData(null);
  };

  const handleUpdateJobHistory = async () => {
    setSkipCheckJobTitle(false);
    const params = newContactData;
    if (isUpdateEmploymentHistory) {
      setIsLoading(true);
      const contact = currentContact;
      let newJobHistory = [
        {
          company: contact.company,
          title: contact.current_job_title,
          start_year: contact.current_job_start_year,
          end_year: employmentHistoryMessageEndYearDate || new Date().getFullYear()
        }
      ];
      newJobHistory = [...newJobHistory, ...contact.job_history].filter(item => item?.company);
      params.job_history = sortByChronologicalOrder(cleanupCompanyDetails(newJobHistory, contact.id));
    }
    await acquireUtil(params, 'current_job_title', enqueueSnackbar, props.closeSnackbar, true, loaderChange, undefined, undefined, true);
    setJobHistoryPopupStates({
      employmentHistoryMessageEndYearDate: null,
      isUpdateEmploymentHistory: false,
      currentContact: null,
      newContactData: null
    });
  };

  const checkEnrichDisabled = () => {
    if (props.type === 'my-merge-contacts' || props.type === 'my-linkedin-contacts') {
      if (gridApi) {
        let isDisabled = true;
        const selectedRows = gridApi.getSelectedRows();
        for (const row of selectedRows) {
          if (row?.data_fetching_status === LINKEDIN_SCRAPER.FAILED) {
            isDisabled = true;
            break;
          }
          isDisabled = false;
        }
        return isDisabled;
      }
      return true;
    }
    return !isRowSelected;
  };

  const { users } = props;

  return (
    <div className='list-contacts d-flex flex-column'>
      <LinkedInDialog open={linkedPopup} linkedInValue={linkedInValue} handleClose={handleClose}></LinkedInDialog>
      <Loader show={isLoading} />
      <Helmet>
        <title>{props.title} - KGP Galaxy</title>
      </Helmet>
      {isDeleteContactOpen ? (
        <DeletePopup onClose={handleDeleteContactClose} payload={{ failed: true }} id={contactId} module={'contacts'} popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_FAILED_CONTACT} />
      ) : null}
      {showJobTitleChangedPopup && (
        <EmploymentHistoryConfirmationDialog
          header='Update'
          employmentHistoryMessageEndYearDate={employmentHistoryMessageEndYearDate}
          setEmploymentHistoryEndYearDate={setEmploymentHistoryEndYearDate}
          open={showJobTitleChangedPopup}
          contact={currentContact}
          handleSubmit={handleConfirmJobTitlePopup}
          handleClose={handleCancelJobTitlePopup}
          cancelText='No'
          confirmText='Yes'
          extraClassName='jobhistory-popup'
        />
      )}
      {isCreateWorkbenchOpen && <AddWorkbench onClose={handleCreateWorkbenchClose} buttonText={VALIDATION_MESSAGE.create_workbench_add_contacts} />}
      {isAddToWorkbenchOpen && <AddContactToWorkbench open={isAddToWorkbenchOpen} contactIds={contactIds} onClose={handleAddToWorkbenchClose} type='Contacts' />}
      {isRemoveFromWorkbenchOpen && <RemoveContactFromWorkbench contactId={contactId} onClose={handleRemoveFromWorkbenchClose} open={isRemoveFromWorkbenchOpen} />}
      {showMergePopup && <PdlContactsMergingPopup onClose={handleMergeClose} contacts={pdlData} />}
      {activeContact && <ActivityHistories contact={activeContact} onPopupClose={handleActivityHistoryClose} />}
      {isDeleteOpen ? <DeletePopup onClose={handleDeleteClose} id={contactId} module={'contacts'} popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_CONTACT} /> : null}
      {isAthenaPopupOpen ? (
        <AthenaInvitePopUpContact enqueueSnackbar={enqueueSnackbar} inviteFor={'candidate'} selectedDropDown={'Athena'} client={athenaData} onClose={handleAthenaPopupClose} />
      ) : null}
      {props.type === 'my-merge-contacts' && (
        <div className={'d-flex query-toolbar d-flex'} style={{ padding: '0px' }}>
          <Accordion className='w-100 m-0' expanded={showQuery && !minimizeQuery}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon onClick={() => setMinimizeQuery(true)} />}
              aria-controls='panel1a-content'
              id='panel1a-header'
              onClick={() => setMinimizeQuery(!minimizeQuery)}
              style={{ padding: '0px 14px', lineHeight: 1 }}
            >
              Contacts Query
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0px 15px 3px', width: '100%' }}>
              {/* {showClassic && (
                <Query
                  gridApi={gridApi}
                  isAllRowsSelected={isAllRowsSelected}
                  columnApi={columnApi}
                  handleQuery={classicQuery}
                  queryData={queryData}
                  option={users}
                  isRowSelected={isRowSelected}
                  type={props.type}
                  resetValue={null}
                  ref={child}
                  savedQuery={savedQuery}
                />
              )} */}
            </AccordionDetails>
          </Accordion>
          <div className='expansion-panel-close-container'>
            <span
              style={{
                display: minimizeQuery ? 'inline' : 'none'
              }}
              className='cursor-pointer expansion-panel-close d-flex'
              onClick={() => {
                setShowQuery(false);
                setQueryData({});
                setShowClassic(false);
                gridApi.onFilterChanged();
                setIsClassicQuery(false);
                setShowClassic(true);
                setSavedQuery(true);
                child.current.resetForm();
                child.current.resetSavedValue();
                window.location.reload(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
      )}
      {isAddToSearchOpen && (
        <CopyCandidatesToSearch
          contacts={contacts}
          header={VALIDATION_MESSAGE.add_contacts_to_search}
          buttonText={VALIDATION_MESSAGE.add_contacts_to_search}
          uiLabel='contact(s)'
          open={true}
          searchId={null}
          contactIds={contactIds}
          handleClose={handleAddToSearchClose}
        />
      )}
      <AddContactAsClient
        header={VALIDATION_MESSAGE.add_contact_as_client}
        buttonText={VALIDATION_MESSAGE.add_contact_as_client}
        uiLabel='contact'
        open={isAddAsClientOpen}
        contactId={contactIds?.length ? contactIds[0] : null}
        isAllRowsSelected={isAllRowsSelected}
        handleClose={handleAddAsClientClose}
      />
      <div style={{ padding: '20px 20px 0 20px' }}>
        {props.type != 'my-merge-contacts' && (
          <Button disabled={checkEnrichDisabled()} onClick={handleAddToSearchBySelection} variant='outlined' color='primary' className='mr-2'>
            {VALIDATION_MESSAGE.add_contacts_to_search}
          </Button>
        )}
        {props.type != 'my-merge-contacts' && (
          <Button variant='outlined' color='primary' className='mr-2' disabled={checkEnrichDisabled()} onClick={handleAddContactsToWorkbench}>
            Add Contacts To Workbench
          </Button>
        )}
        {props.type != 'my-merge-contacts' && (
          <Button variant='outlined' color='primary' className='mr-2' disabled={checkEnrichDisabled()} onClick={handleCreateWorkbench}>
            Create New Workbench
          </Button>
        )}
        {(props.type === 'my-merge-contacts' || props.type === 'my-linkedin-contacts') && (
          <Button variant='outlined' color='primary' className='mr-2' disabled={!isRowSelected} onClick={contactDataRefresh}>
            {' '}
            Review Enrich Data
          </Button>
        )}
      </div>
      <div className='d-flex align-items-center justify-content-end' style={{ padding: '0 20px' }}>
        <div className='action-container' style={{ minWidth: '0' }} onClick={() => resetFilter()}>
          {props.type != 'my-merge-contacts' && <span className='action-text'>Reset Filter</span>}
        </div>
        <div className='action-container' style={{ minWidth: '0' }} onClick={() => resetSort()}>
          {props.type != 'my-merge-contacts' && <span className='action-text'>Reset Sort</span>}
        </div>
        {props.type != 'my-merge-contacts' && (
          <Button className='mr-3' variant={'outlined'} color={'primary'} disabled={!isRowSelected} onClick={exportCallback}>
            Export list
          </Button>
        )}
        <Typography>Total count: {rowCount}</Typography>
      </div>
      <div className='list-view flex-grow-1'>
        {isGridReady && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} filterModel={filterModel} showFilterCount={showFilterCount} />}
        <div id='myGrid' className='ag-theme-alpine'>
          <AgGridReact
            enableBrowserTooltips={true}
            blockLoadDebounceMillis={SCROLL_TIMEOUT}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            suppressDragLeaveHidesColumns={true}
            rowModelType={'infinite'}
            datasource={dataSource}
            paginationPageSize={PAGE_LIMIT}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            isRowSelectable={params => {
              const status = checkContactFetchingStatus(params);
              if (props.type === 'my-merge-contacts' || props.type === 'my-linkedin-contacts') {
                if (status && status === LINKEDIN_SCRAPER.PROCESSING_LABEL) return false;
              } else if (status) return false;
              return true;
            }}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            frameworkComponents={{
              CustomLoadingOverlayComponent,
              LinkedInRenderer: linkedInRenderer,
              PhoneRenderer,
              EmailRenderer,
              ActionsRenderer: actionsRenderer,
              AddContactToRenderer: addContactToRenderer,
              NameRenderer: nameRenderer,
              ResumeRenderer,
              HeaderCheckbox: headerCheckbox,
              DateRenderer,
              DateTimeRenderer,
              CompanyNameRenderer: companyNameRenderer,
              GenericCellEditor,
              CompensationRenderer,
              CustomFilter,
              AthenaRenderer: athenaRenderer
            }}
            getRowNodeId={data => data.id}
            onGridReady={onGridReady}
            columnDefs={
              props.type != 'my-merge-contacts'
                ? columnDefs(showActivityHistory, enqueueSnackbar, closeSnackbar, loaderChange, users, undefined, setJobHistoryPopupStates)
                : columnMergeContactDefs(enqueueSnackbar, closeSnackbar, loaderChange, setJobHistoryPopupStates)
            }
            onRowSelected={handleRowSelection}
            onSortChanged={onSortChanged}
            onFirstDataRendered={onFirstDataRendered}
            onDisplayedColumnsChanged={saveColumnState}
            onDragStopped={saveColumnState}
            onFilterChanged={saveColumnStateForFilter}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

Contacts.propTypes = {
  enqueueSnackbar: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  navigate: PropTypes.func,
  type: PropTypes.string,
  storageKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  userData: PropTypes.object,
  closeSnackbar: PropTypes.func,
  users: PropTypes.array,
  fetchUserList: PropTypes.func,
  socket: PropTypes.object,
  refreshContact: PropTypes.bool,
  [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]: PropTypes.object,
  dispatch: PropTypes.func
};

const mapStateToProps = state => {
  return {
    userData: state.commonReducer.userData,
    users: state.rootReducer.users,
    socket: state.rootReducer.socket,
    refreshContact: state.rootReducer.refreshContact,
    [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]: state.rootReducer[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]
  };
};
const mapDispatchToProps = dispatch => ({
  fetchUserList,
  dispatch
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Contacts));
