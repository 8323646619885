//----------------------------------------------// Internal Imports // -------------------------------------------------

import { ResumeRenderer } from '../../Containers/Contacts/utils';
import { DEFAULT_DATE_FORMAT, formatDate, getAge } from '../../utils/date';
import { DateRenderer } from '../../Containers/Commons/Utils';
import { tooltipValueGrid } from '../../utils/common';

const getName = params => (params.value ? params.value.name : '');
const getCreatedUser = params => (params.value ? params.value.name : '');
const getIndustries = params => {
  if (params.data && params.data.industries && Array.isArray(params.data.industries)) {
    return params.data.industries.join(', ');
  }
  return '';
};
const getOffLimits = params => (params.value ? 'Yes' : params.value === null ? '' : 'No');
const getParentCompany = params => (params.value ? params.value.name : '');

const getCompany = params => (params.value ? params.value.name : '');
const getAdmins = params => {
  if (params.data && params.data?.eas && params.data?.eas[0]) {
    const admins = [];
    params.data.eas.forEach(admin => {
      if (admin && admin.user) {
        admins.push(`${admin.user.first_name} ${admin.user.last_name}`);
      }
    });
    return admins.join(', ');
  }
};
const getJobFunctions = params => {
  if (params.value) {
    let names = [];
    params.value.map(val => names.push(val));
    return names.join(', ');
  }
  return '';
};

const getPartner = params => {
  if (params.value) {
    let names = [];
    params.value.map(val => val.user && names.push(`${val.user.first_name} ${val.user.last_name}`));
    return names.join(',');
  }
  return '';
};

const getRecruiter = params => {
  if (params.value) {
    let names = [];
    params.value.forEach(val => {
      names.push(`${val.user.first_name} ${val.user.last_name}`);
    });
    return names.join(',');
  }
  return '';
};
const getResearcher = params => {
  if (params.value) {
    let names = [];
    params.value.forEach(val => {
      names.push(`${val.user.first_name} ${val.user.last_name}`);
    });
    return names.join(',');
  }
  return '';
};

const getDays = params => {
  const days = getAge(params?.value);
  if (!days && days !== 0) {
    return '-';
  }
  return days + ' day(s)';
};

const getCreatedAt = params => (params.data && params.data.created_at ? formatDate(params.data.created_at, DEFAULT_DATE_FORMAT) : '');
const getLastUpdatedAt = params => (params.value ? formatDate(params.value, params.column && DEFAULT_DATE_FORMAT) : '');
const getUsers = params => {
  return params.value?.map(item => item?.name).join(', ');
};

export const columnDefs = [
  {
    headerName: 'Name',
    colId: 'names',
    valueGetter: params => params.data && params.data.first_name + ' ' + params.data.last_name,
    field: 'first_name',
    sortable: false,
    tooltipField: 'first_name',
    cellRenderer: 'NameRenderer',
    pinned: 'left',
    lockPinned: true,
    minWidth: 200
  },
  {
    field: 'company',
    colId: 'company',
    headerName: 'Current Company',
    tooltip: getName,
    cellRenderer: 'CompanyNameRenderer',
    sortable: false,
    pinned: 'left',
    lockPinned: true,
    minWidth: 200
  },
  {
    field: 'current_job_title',
    colId: 'current_job_title',
    tooltipField: 'current_job_title',
    sortable: false,
    headerName: 'Current Job Title',
    minWidth: 200
  },
  {
    field: 'job_functions',
    colId: 'job_functions',
    maxWidth: 200,
    minWidth: 50,
    headerName: 'Functions',
    sortable: false,
    tooltipValueGetter: getJobFunctions,
    cellRenderer: getJobFunctions
  },
  {
    field: 'country',
    colId: 'country',
    tooltipField: 'country',
    sortable: false,
    headerName: 'Location',
    minWidth: 100
  },
  {
    field: 'linkedin_url',
    colId: 'linkedin_url',
    headerName: 'LinkedIn',
    sortable: false,
    // tooltipField: "linkedin_url",
    tooltipValueGetter: params => {
      return tooltipValueGrid(params);
    },
    cellRenderer: 'LinkedInRenderer',
    maxWidth: 100,
    minWidth: 50
  },
  {
    field: 'contact_resumes',
    colId: 'contact_resumes',
    headerName: 'Resume',
    sortable: false,
    cellRenderer: 'ResumeRenderer',
    tooltip: ResumeRenderer,
    hide: true,
    maxWidth: 120
  },
  {
    field: 'created_user',
    colId: 'created_user',
    headerName: 'Added by',
    sortable: false,
    tooltip: getCreatedUser,
    hide: true,
    cellRenderer: getCreatedUser
  },
  {
    field: 'updated_at',
    colId: 'updated_at',
    headerName: 'Updated On',
    sortable: false,
    tooltipValueGetter: DateRenderer,
    cellRenderer: 'DateRenderer',
    hide: true
  },
  {
    field: 'mobile_phone',
    colId: 'mobile_phone',
    headerName: 'Phone Number',
    sortable: false,
    tooltipField: 'mobile_phone',
    cellRenderer: 'PhoneRenderer',
    hide: true,
    minWidth: 200
  },
  {
    field: 'work_email',
    colId: 'work_email',
    headerName: 'Email',
    sortable: false,
    tooltipField: 'work_email',
    cellRenderer: 'EmailRenderer',
    minWidth: 200
  },
  {
    headerName: 'Base',
    colId: 'base',
    cellRenderer: 'CompensationRenderer',
    sortable: false
  },
  {
    field: 'industries',
    colId: 'industries',
    headerName: 'Industries',
    tooltip: getIndustries,
    cellRenderer: getIndustries,
    sortable: false
  }
];

export const searchColumnDefs = [
  {
    field: 'job_number',
    colId: 'job_number',
    headerName: 'Search#',
    minWidth: 90,
    maxWidth: 90,
    width: 90,
    sortable: false,
    suppressSizeToFit: true,
    cellRenderer: 'IdRenderer',
    tooltipField: 'job_number',
    pinned: 'left',
    lockPinned: true
  },
  {
    field: 'job_title',
    colId: 'job_title',
    headerName: 'Title',
    tooltipField: 'job_title',
    cellRenderer: 'IdRenderer',
    sortable: false,
    pinned: 'left',
    lockPinned: true,
    minWidth: 200
  },
  {
    field: 'stage',
    colId: 'stage',
    headerName: 'stage',
    type: 'selection',
    sortable: false,
    dataField: 'name'
  },
  {
    field: 'admins',
    colId: 'admins',
    headerName: 'Admin',
    tooltipValueGetter: getAdmins,
    cellRenderer: getAdmins,
    sortable: false
  },
  {
    field: 'company',
    colId: 'company',
    headerName: 'Company',
    sortable: false,
    tooltipValueGetter: getCompany,
    cellRenderer: 'NameRenderer',
    minWidth: 200
  },
  {
    field: 'close_date',
    colId: 'close_date',
    headerName: 'Close Date',
    sortable: false,
    tooltipField: 'close_date',
    cellRenderer: 'DateRenderer'
  },
  {
    field: 'start_date',
    colId: 'start_date',
    headerName: 'Start Date',
    sortable: false,
    tooltipField: 'start_date',
    cellRenderer: 'DateRenderer'
  },
  {
    field: 'start_date',
    colId: 'days_open',
    headerName: 'Days Open',
    sortable: false,
    tooltipValueGetter: getDays,
    cellRenderer: getDays,
    filter: false
  },
  {
    field: 'target',
    colId: 'target',
    width: 90,
    headerName: 'Target#',
    tooltipField: 'target',
    cellRenderer: 'renderTargetCount',
    sortable: false,
    filter: false
  },
  {
    field: 'active',
    colId: 'active',
    width: 90,
    headerName: 'Active#',
    tooltipField: 'active',
    cellRenderer: 'renderActiveCount',
    sortable: false,
    filter: false
  },
  {
    field: 'industries',
    colId: 'industries',
    headerName: 'Industries',
    sortable: false,
    tooltipValueGetter: getIndustries,
    cellRenderer: getIndustries
  },
  {
    field: 'job_functions',
    colId: 'job_functions',
    headerName: 'Functions',
    sortable: false,
    tooltipValueGetter: getJobFunctions,
    cellRenderer: getJobFunctions
  },
  {
    field: 'country',
    colId: 'country',
    sortable: false,
    headerName: 'Location'
  },
  {
    field: 'state',
    colId: 'state',
    sortable: false,
    headerName: 'State'
  },
  {
    field: 'city',
    colId: 'city',
    sortable: false,
    headerName: 'City'
  },
  {
    field: 'partners',
    colId: 'partners',
    headerName: 'Partner(s)',
    tooltipValueGetter: getPartner,
    cellRenderer: getPartner,
    sortable: false,
    hide: true
  },
  {
    field: 'recruiters',
    colId: 'recruiters',
    headerName: 'Recruiter(s)',
    tooltipValueGetter: getRecruiter,
    cellRenderer: getRecruiter,
    sortable: false,
    hide: true
  },
  {
    field: 'researchers',
    colId: 'researchers',
    headerName: 'Researcher(s)',
    tooltipValueGetter: getResearcher,
    cellRenderer: getResearcher,
    sortable: false,
    hide: true
  },
  {
    field: 'freeze_dropdown',
    colId: 'freeze_dropdown',
    headerName: '',
    sortable: false,
    filter: false,
    minWidth: 44,
    maxWidth: 44,
    width: 44,
    pinned: 'right',
    lockPinned: true
  }
];

export const companyColumns = [
  {
    field: 'name',
    colId: 'name',
    headerName: 'Name',
    sortable: false,
    cellRenderer: 'NameRenderer',
    pinned: 'left',
    lockPinned: true,
    minWidth: 200
  },
  {
    field: 'industries',
    colId: 'industries',
    headerName: 'Industries',
    sortable: false,
    tooltipValueGetter: getIndustries,
    cellRenderer: getIndustries
  },
  {
    field: '',
    colId: 'actions',
    headerName: 'Actions',
    tooltipField: '',
    cellRenderer: 'ActionsRenderer',
    sortable: false
  },
  {
    field: 'country',
    colId: 'country',
    headerName: 'Country',
    sortable: false,
    maxWidth: 220,
    minWidth: 50,
    tooltipField: 'country'
  },
  {
    field: 'currency_iso_code',
    colId: 'currency_iso_code',
    sortable: false,
    headerName: 'Revenue Currency',
    tooltipField: 'currency_iso_code'
  },
  {
    field: 'revenue',
    colId: 'revenue',
    sortable: false,
    headerName: 'Annual Revenue',
    tooltipField: 'revenue'
  },
  {
    field: 'country',
    colId: 'country',
    sortable: false,
    headerName: 'Location',
    tooltipField: 'country'
  },
  {
    field: 'state',
    colId: 'state',
    sortable: false,
    headerName: 'State',
    tooltipField: 'state',
    hide: true
  },
  {
    field: 'city',
    colId: 'city',
    headerName: 'City',
    sortable: false,
    tooltipField: 'city',
    hide: true
  },
  {
    field: 'ticker_sym',
    colId: 'ticker_sym',
    sortable: false,
    headerName: 'Ticker Symbol',
    tooltipField: 'ticker_sym'
  },
  {
    field: 'website',
    colId: 'website',
    headerName: 'Website',
    sortable: false,
    cellRenderer: 'WebsiteRenderer',
    tooltipField: 'website'
  },
  {
    field: 'phone',
    colId: 'phone',
    headerName: 'Contact Info',
    sortable: false,
    cellRenderer: 'PhoneRenderer',
    tooltipField: 'phone'
  },
  {
    field: 'updated_at',
    colId: 'updated_at',
    hide: true,
    headerName: 'Updated On',
    sortable: false,
    cellRenderer: 'DateRenderer',
    tooltipValueGetter: DateRenderer
  },
  {
    field: 'off_limits',
    colId: 'off_limits',
    hide: true,
    sortable: false,
    headerName: 'Off Limits',
    tooltipValueGetter: getOffLimits,
    cellRenderer: getOffLimits
  },
  {
    field: 'off_limits_reason',
    colId: 'off_limits_reason',
    hide: true,
    sortable: false,
    headerName: 'Off Limits Reason',
    tooltipField: 'off_limits_reason'
  },
  {
    field: 'off_limits_expiration',
    colId: 'off_limits_expiration',
    hide: true,
    sortable: false,
    headerName: 'Off Limits Expiration Date',
    cellRenderer: 'DateRenderer'
  },
  {
    field: 'description',
    colId: 'description',
    hide: true,
    sortable: false,
    headerName: 'Description',
    tooltipField: 'description',
    cellEditor: 'DescriptionEditor'
  },
  {
    field: 'parent',
    colId: 'parent',
    headerName: 'Parent Company',
    hide: true,
    sortable: false,
    tooltipValueGetter: getParentCompany,
    cellRenderer: getParentCompany
  },
  {
    field: 'created_at',
    colId: 'created_at',
    headerName: 'Created On',
    hide: true,
    sortable: false,
    cellRenderer: 'DateRenderer',
    tooltipValueGetter: DateRenderer
  },
  {
    field: 'created_user',
    colId: 'created_by',
    headerName: 'User Added',
    hide: true,
    sortable: false,
    tooltipValueGetter: getCreatedUser,
    cellRenderer: getCreatedUser
  },
  {
    field: 'freeze_dropdown',
    colId: 'freeze_dropdown',
    headerName: '',
    sortable: false,
    filter: false,
    minWidth: 44,
    maxWidth: 44,
    width: 44,
    pinned: 'right',
    lockPinned: true
  }
];

export const workbenchesColumns = [
  {
    field: 'name',
    colId: 'name',
    headerName: 'Name',
    cellRenderer: 'IdRenderer',
    sortable: false,
    minWidth: 200
  },
  {
    field: 'type',
    colId: 'type',
    headerName: 'Type',
    sortable: false,
    minWidth: 200
  },
  {
    field: 'tagged_users',
    colId: 'users',
    headerName: 'Users',
    sortable: false,
    cellRenderer: getUsers,
    minWidth: 150
  },
  {
    field: 'archive_date',
    colId: 'archive_date',
    headerName: 'Archive Date',
    cellRenderer: 'DateRenderer',
    sortable: false,
    minWidth: 150
  },
  {
    field: 'created_at',
    colId: 'created_at',
    headerName: 'Created On',
    cellRenderer: getCreatedAt,
    tooltipValueGetter: getCreatedAt,
    sortable: false,
    minWidth: 150
  },
  {
    field: 'updated_at',
    colId: 'updated_at',
    headerName: 'Updated On',
    tooltipValueGetter: DateRenderer,
    cellRenderer: getLastUpdatedAt,
    sortable: false,
    minWidth: 150
  },
  {
    field: 'description',
    colId: 'description',
    headerName: 'Description',
    sortable: false,
    minWidth: 150
  }
];
