//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------0
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { capitalize } from '../../../utils/string';
import { API, GET } from '../../../services/constantService';
import { dashboardDataApi } from '../../../services/ApiService';

function DocumentTypeSelection(props) {
  const { className = 'w-100', label, InputLabelProps, required, placeholder = 'Document Type', list, ...rest } = props;
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  // const filterDataOptions = (data) => {
  //   return data?.filter((item) => {
  //     return list.every(listItem => listItem?.doc_desc !== item?.doctype_desc)
  //   })
  // }
  const filterOptions = data => {
    return data?.filter(item => item?.doctype_desc.match(new RegExp(value, 'gi')));
  };

  useEffect(() => {
    getData();
  }, [list, value]);

  const getData = async () => {
    try {
      setLoading(true);
      const url = `${API.product_setting}/document_type`;
      const { status, data } = await dashboardDataApi(GET, url);
      if (status === 200) {
        let array = [];
        //const filterData = data.data.rows
        const filteredData = filterOptions(data.data.rows);
        filteredData.map(data => array.push({ doctype_desc: data.doctype_desc, doctype_code: data.doctype_code }));
        setOptions(array);
        setLoading(false);
      }
    } catch (err) {
      console.log('error in Fetch Token::', err);
    }
  };

  return (
    <Autocomplete
      {...rest}
      className={className}
      options={loading ? [] : options}
      filterOptions={options => options}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        return capitalize(option.doctype_desc);
      }}
      getOptionSelected={(option, value) => {
        if (typeof value === 'string') {
          return option.doctype_desc === value || option.doc_desc === rest.value || option.doctype_code === rest.value;
        }
        return option.doctype_desc === value.doctype_desc || option.doc_desc === rest.value || option.doctype_code === value.doctype_code;
      }}
      onInputChange={(e, val, reason) => {
        if (reason === 'input') {
          setValue(val);
        }
      }}
      renderInput={params => <TextField {...params} required={required} InputLabelProps={InputLabelProps} label={label} placeholder={placeholder} />}
    />
  );
}

DocumentTypeSelection.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  list: PropTypes.object
};

export default DocumentTypeSelection;
