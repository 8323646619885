//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

// -------------------------------------------------// Internal Imports// -------------------------------------------------
import { Box } from '@mui/material';
import BillingInformation from './Component/BillingInformation';
import FeeTypeInformation from './Component/FeeTypeInformation';
import './index.scss';

const BillingInfo = props => {
  const { register, setValue, watch, control, getValues, currentValues, isEditing = true, currencyDetails, isDisabled = false } = props;

  return (
    <Box id='billing-client-info' className=''>
      <BillingInformation register={register} setValue={setValue} watch={watch} control={control} isEditing={isEditing} currentValues={currentValues} isDisabled={isDisabled} />

      <FeeTypeInformation
        register={register}
        setValue={setValue}
        watch={watch}
        control={control}
        currencyDetails={currencyDetails}
        getValues={getValues}
        isEditing={isEditing}
        currentValues={currentValues}
        isDisabled={isDisabled}
      />
    </Box>
  );
};

BillingInfo.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  getValues: PropTypes.func,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  isDisabled: PropTypes.bool,
  currencyDetails: PropTypes.object,
  handelScroll: PropTypes.func
};

export default BillingInfo;
