import CachedIcon from '@mui/icons-material/Cached';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, CircularProgress, IconButton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PAGE_LIMIT_OPTIONS } from '../constants';
import '../index.scss';

const TableControls = props => {
  const {
    subRoute = 'transcribe',
    refreshLoading,
    refetch,
    fetchRequestType = 0,
    setFetchRequestType,
    totalTranscribeCount = 0,
    totalPages = 1,
    currentPageNumber = 1,
    changePageNumber = () => {},
    pageLimit,
    updatePageLimit
  } = props;
  const { t } = useTranslation();
  return (
    <Box className='flex justify-content-between' py={1}>
      <Box>
        {subRoute === 'transcribe' ? (
          <>
            <Button size='small' onClick={() => setFetchRequestType(0)} className={`fs-10 m-1 ${fetchRequestType == 0 ? 'selected-type-btn' : ''} text-transform-none`}>
              {t('echoSense.myRequests')}
            </Button>
            <Button size='small' onClick={() => setFetchRequestType(1)} className={`fs-10 m-1 ${fetchRequestType == 1 ? 'selected-type-btn' : ''} text-transform-none`}>
              {t('echoSense.allRequests')}
            </Button>
          </>
        ) : (
          <>
            <Button size='small' onClick={() => setFetchRequestType(0)} className={`fs-10 m-1 ${fetchRequestType == 0 ? 'selected-type-btn' : ''} text-transform-none`}>
              {t('common.all')}
            </Button>
            <Button size='small' onClick={() => setFetchRequestType(1)} className={`fs-10 m-1 ${fetchRequestType == 1 ? 'selected-type-btn' : ''} text-transform-none`}>
              {t('common.pending')}
            </Button>
            <Button size='small' onClick={() => setFetchRequestType(2)} className={`fs-10 m-1 ${fetchRequestType == 2 ? 'selected-type-btn' : ''} text-transform-none`}>
              {t('common.completed')}
            </Button>
          </>
        )}
      </Box>
      <Box className='flex justify-content-center align-items-center fs-10 mx-4'>
        <Box mr={2}>
          <IconButton onClick={refetch}>{refreshLoading ? <CircularProgress size='25px' /> : <CachedIcon />}</IconButton>
        </Box>
        <Box>
          {totalTranscribeCount ? (
            <Box>
              {totalTranscribeCount} {t('common.records')}
            </Box>
          ) : (
            ''
          )}
        </Box>
        <Box className='flex justify-content-center align-items-center fs-10'>
          <IconButton disabled={currentPageNumber === 1} onClick={() => changePageNumber('left')}>
            <KeyboardArrowLeftIcon />
          </IconButton>
          {t('common.page')} <Box className='bg-white p-1 border mx-1'>{currentPageNumber}</Box> of {totalPages || 1}
          <IconButton disabled={currentPageNumber === (totalPages || 1)} onClick={() => changePageNumber('right')}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
        <Box>
          Count
          <Select value={pageLimit} onChange={e => updatePageLimit(e.target.value)} variant='standard' style={{ fontSize: 12, marginLeft: 5, padding: 3 }}>
            {PAGE_LIMIT_OPTIONS.map(limit => (
              <MenuItem key={limit} value={limit} style={{ fontSize: 12 }}>
                {limit}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </Box>
  );
};

TableControls.propTypes = {
  refreshLoading: PropTypes.bool,
  subRoute: PropTypes.string,
  refetch: PropTypes.func,
  fetchRequestType: PropTypes.string,
  setFetchRequestType: PropTypes.func,
  totalTranscribeCount: PropTypes.number,
  totalPages: PropTypes.number,
  currentPageNumber: PropTypes.number,
  changePageNumber: PropTypes.func,
  pageLimit: PropTypes.number,
  updatePageLimit: PropTypes.func
};

export default TableControls;
