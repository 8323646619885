//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

const VideoDisplay = props => {
  const { video } = props;
  return video && video.fileName ? (
    <div className='section-container mb-5'>
      <div className='section-header'>Video</div>
      <video id='playback-video-screen' className='video-player cursor-pointer' autoPlay={false} controls>
        <track kind='subtitles' />
        <source src={`${video.url}`} />
      </video>
    </div>
  ) : null;
};

VideoDisplay.propTypes = {
  video: PropTypes.object
};
export default VideoDisplay;
