import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Checkbox, DialogTitle, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getUserIp } from '../../services/ApiService';
import { PUBLIC_SUB_ROUTES } from '../../services/constantService';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export default function CustomizedDialogs({ openPrivacyConfirmationBox, setOpenPrivacyConfirmationBox, consent, setConsent, walkthrow }) {
  const [ipAddress, setIpAddress] = useState('');
  const [name, setName] = useState('');
  const handleClose = () => {
    setOpenPrivacyConfirmationBox(false);
    walkthrow(true);
  };
  const navigate = useNavigate();
  const getIP = async () => {
    try {
      const response = await getUserIp();
      setIpAddress(response?.data?.ip);
      console.log('ip-address', response);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  useEffect(() => {
    getIP();
  }, []);
  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby='customized-dialog-title'
        open={openPrivacyConfirmationBox}
        style={{
          zIndex: 999999
        }}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}></DialogTitle>

        <DialogContent>
          <Stack direction='row' spacing={1} justifyContent={'flex-start'} alignItems={'center'}>
            <Checkbox type='checkbox' className='fs-12' checked={consent} onChange={e => setConsent(e.target.checked)} />
            <Typography className='fs-12'>
              I consent to the processing of my personal data in accordance with the{' '}
              <Link to={PUBLIC_SUB_ROUTES.privacyPolicy} rel='noopener noreferrer' target='_blank' className='fs-12'>
                Privacy Policy
              </Link>
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'flex-start'} gap={'100px'} padding={'0 50px'}>
            <Typography
              className='fs-12'
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px'
              }}
            >
             Full Name:{' '}
              <TextField
                autoFocus
                value={name}
                onChange={e => setName(e.target.value)}
                variant='standard'
                style={{
                  zIndex: 9999999
                }}
              />
            </Typography>
            <Typography className='fs-12'>IP: {ipAddress}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant='text'
            color='error'
            onClick={() => {
              navigate(-1);
            }}
          >
            Go Back
          </Button>
          <Button variant='contained' onClick={handleClose} disabled={!consent || !name}>
            Continue
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

CustomizedDialogs.propTypes = {
  setOpenPrivacyConfirmationBox: PropTypes.func,
  openPrivacyConfirmationBox: PropTypes.bool,
  consent: PropTypes.bool,
  setConsent: PropTypes.func,
  walkthrow: PropTypes.func,
};
