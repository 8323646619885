//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import RichTextPopup from '../../RichTextPopup';
import { recommendationsTabDefaultValues, removeSkipFields, getActivityRecommendationsTabPayload } from '../utils';
import TabLayoutFooter from '../../TabLayoutFooter';

const Recommendations = forwardRef((props, ref) => {
  const { updateContactInfo, saveStatus, autoSaveContactFields, getActivityLogInfoTabData, handleSaveTabData, readOnly } = props;
  const [tabInfo, setTabInfo] = useState(null);
  const [currentValues, setCurrentValues] = useState(null);
  const { id, path, contactId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { register, reset, handleSubmit, getValues, setValue, formState } = useForm({});
  const { dirtyFields } = formState;
  const actions = [
    {
      label: 'Save & Close',
      className: 'primary-btn mr-3',
      onClick: () => {
        handleSave(true);
      }
    },
    {
      label: 'Save',
      className: 'primary-btn',
      onClick: () => {
        handleSave(false, false, true);
      }
    }
  ];

  const getActivityLogInfo = useCallback(
    async hideLoader => {
      const data = await getActivityLogInfoTabData(hideLoader, 'RECOMMENDATION_TAB');
      if (data) {
        setTabInfo(data);
      }
    },
    [contactId, id, path, enqueueSnackbar]
  );

  useEffect(() => {
    getActivityLogInfo();
  }, [getActivityLogInfo]);

  useEffect(() => {
    let defaultValues = recommendationsTabDefaultValues(tabInfo);
    // if (defaultValues && isRichTextSaved) {
    //   defaultValues = { ...defaultValues, ...cleanupFormValues(getValues()) }
    // }
    if (defaultValues) {
      setCurrentValues(defaultValues);
      reset(defaultValues);
    }
  }, [tabInfo, reset, getValues]);

  const handleSave = async (saveAndClose, skipMessage = false, reload) => {
    const formValues = getValues();
    let result;

    const payload = await getActivityRecommendationsTabPayload(formValues, dirtyFields);
    result = await handleSaveTabData(payload, saveAndClose, skipMessage, false, 0);
    if (result && reload) {
      await getActivityLogInfo();
    }
    if (!result) {
      handleSubmit(null);
    }
    return result;
  };

  useImperativeHandle(ref, () => ({
    saveTabData: (saveAndClose, skipMessage = false, reload) => {
      return handleSave(saveAndClose, skipMessage, reload);
    },
    isDirty: () => {
      let fields = removeSkipFields({ ...dirtyFields });
      return fields?.length > 0;
    },
    formData: () => {
      return getValues();
    },
    reload: hideLoader => {
      getActivityLogInfo(hideLoader);
    }
  }));

  useEffect(() => {
    if (currentValues) {
      reset(currentValues);
    }
  }, [currentValues]);
  useEffect(() => {
    register('recommendations_profile');
  }, [register]);

  return (
    currentValues && (
      <div>
        <div className='d-flex contact-view input-form recommendations'>
          <div className='w-100 section-content'>
            <Typography className='section-label'>Recommendations</Typography>
            <div className='d-flex recommendations-profile flex-column react-quill-container' style={{ width: '80%', maxWidth: 700 }}>
              <div className='text-view-value p-0 w-100'>
                <div className='outlined-border transform p-0 '>
                  <RichTextPopup
                    bounds={'.recommendations-profile'}
                    name='recommendations_profile'
                    onSave={updateContactInfo}
                    value={currentValues['recommendations_profile']}
                    onChange={data => {
                      setValue('recommendations_profile', data);
                    }}
                    title='Recommendations'
                    autoSave={autoSaveContactFields}
                    saveStatus={saveStatus}
                    updatedDate={currentValues?.updated_at}
                    restrictHeight={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {!readOnly && <TabLayoutFooter actions={actions} />}
      </div>
    )
  );
});

Recommendations.displayName = 'Recommendations';

Recommendations.propTypes = {
  register: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  updateContactInfo: PropTypes.func,
  currentValues: PropTypes.object,
  saveStatus: PropTypes.bool,
  autoSaveContactFields: PropTypes.func,
  getActivityLogInfoTabData: PropTypes.func,
  handleSaveTabData: PropTypes.func,
  readOnly: PropTypes.bool
};

export default Recommendations;
