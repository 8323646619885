//-----------------------------------------------------------// Internal Imports // ------------------------------

import { notesAttachmentDocumentApi } from './ApiService';
import { DELETE, GET, POST, PUT } from './constantService';

export const add = async (id, note, path = 'company') => {
  const url = `/api/${path}/${id}/notes`;
  return await notesAttachmentDocumentApi(POST, url, note);
};

export const getAll = async (id, path = 'company') => {
  const url = `/api/${path}/${id}/notes`;
  return await notesAttachmentDocumentApi(GET, url);
};

export const update = async (noteId, data, path = 'company') => {
  const url = `/api/${path}/notes/${noteId}`;
  return await notesAttachmentDocumentApi(PUT, url, data);
};

export const remove = async (noteId, path = 'company') => {
  const url = `/api/${path}/notes/${noteId}`;
  return await notesAttachmentDocumentApi(DELETE, url);
};
