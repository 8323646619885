//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Box } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------

// import ActivityDashboard from "./ActivityDashboard";
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import LogAnActivity from '../../../Containers/Contacts/LogAnActivity';
import './index.scss';

const ActivityLogs = React.memo(props => {
  const { id, getHeaderDetails = () => {}, isHeaderNav, ifForCompany = false, project_id } = props;
  const [logAnActivityPopUp, setLogAnActivityPopUp] = useState(false);
  const [paramsValue, setParamsValue] = useState(null);
  const location = useLocation();
  const toggleActivity = data => {
    setParamsValue(data);
  };

  useEffect(() => {
    toggleActivity(id);
  }, [id]);

  return (
    <Box
      sx={{
        height: '100%'
      }}
    >
      <LogAnActivity
        id={id}
        location={location}
        params={paramsValue}
        setIsMenuOpen={setLogAnActivityPopUp}
        isMenuOpen={logAnActivityPopUp}
        isViewCloseIcon={false}
        getHeaderDetails={getHeaderDetails}
        isHeaderNav={isHeaderNav}
        ifForCompany={ifForCompany}
        project_id={project_id}
      />
    </Box>
  );
});

ActivityLogs.propTypes = {
  id: PropTypes.string,
  isHeaderNav: PropTypes.string,
  getHeaderDetails: PropTypes.func,
  ifForCompany: PropTypes.bool,
  isCandidate: PropTypes.bool,
  project_id: PropTypes.string,
  candidate_id: PropTypes.string
};

export default ActivityLogs;
