//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
// import { SUCCESS_STATUS_CODE } from '../../../../../Containers/Commons/Utils';
import { useTranslation } from 'react-i18next';
import { SUCCESS_STATUS_CODE } from '../../../../../Containers/Commons/Utils';
import { ProjectCandidateApi } from '../../../../../services/ApiService';
import { GET, TAG_SETUP_LOGS_MESSAGE } from '../../../../../services/constantService';
import TotalCount from '../../../../common/TotalCount';
//----------------------------------------------// Internal Imports // -------------------------------------------------
const ActiveProjects = ({ contactId = null, rowData = [] }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [rowCount, setRowCount] = useState(0);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRowCount(rows?.length || 0);
  }, [rowData]);

  useEffect(() => {
    setRows(rowData);
  }, [rowData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true);

        const { status, data } = await ProjectCandidateApi(
          GET,
          '/all',
          {},
          {
            contactId: contactId || id,
            page: 1,
            limit: 10000000
          }
        );
        if (status === SUCCESS_STATUS_CODE) {
          setRows(data?.rows || []);
          setRowCount(data?.rows?.length || 0);
          // setLoading(false);
          if (data?.length === 0) {
            setRowCount(0);
          }
        } else {
          // setLoading(false);
          throw new Error(TAG_SETUP_LOGS_MESSAGE.GET_ROWS_FOUND_ERROR);
        }
      } catch (error) {
        console.error(TAG_SETUP_LOGS_MESSAGE.GET_ROWS_FOUND_ERROR, error);
        // Handle error
      }
    };

    fetchData();
  }, [id]);

  const getProjects = params => {
    return (
      <Box>
        {
          <Link to={`/projects/view-project/${params?.data?.project?.id}`} target='_blank' className='header-content'>
            <Box className='fs-14 details-width cursor-pointer'>{params?.data?.project?.job_number}</Box>
          </Link>
        }
      </Box>
    );
  };
  const columnDefs = () => {
    let columns = [
      {
        field: 'project.job_number',
        headerName: t('grid.projectId'),
        width: 300,
        resizable: true,
        sortable: true,
        sortingOrder: ['asc', 'desc', null],
        cellRenderer: 'getProjects'
      },
      {
        field: 'project.job_title',
        headerName: t('grid.projectTitle'),
        // cellRenderer: "getProjects",
        width: 300,
        resizable: true,
        sortable: true,
        sortingOrder: ['asc', 'desc', null]
        // cellClass: "text-style"
      },
      {
        field: 'project.ign_companies.name',
        headerName: 'Company Name',
        width: 300,
        resizable: true,
        sortable: true,
        sortingOrder: ['asc', 'desc', null]
        // cellClass: "text-style"
      },
      {
        field: 'stage',
        headerName: 'Stage',
        width: 300,
        resizable: true,
        sortable: true,
        sortingOrder: ['asc', 'desc', null]
      }
    ];
    return columns;
  };

  return (
    <Container id='ActiveProjects'>
      <Box className='Active-project-head'>
        <div id='view-company' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
          <Box className='details-container'>
            <Box className='details-container-subtab scroll-content'>
              <Box className='justify-content-between py-3'>
                <Box className='mb-1 mr-3 float-right'>
                  <TotalCount rowCount={rowCount} />
                </Box>
              </Box>
              <Box className='ag-theme-alpine text-center mr-3 '>
                <AgGridReact
                  rowData={rows}
                  columnDefs={columnDefs()}
                  frameworkComponents={{
                    getProjects: getProjects
                    // getStage: getStage,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </div>{' '}
      </Box>
    </Container>
  );
};

ActiveProjects.propTypes = {
  setLoading: PropTypes.func,
  contactId: PropTypes.string,
  rowData: PropTypes.array
  // data: PropTypes.object
};
export default ActiveProjects;
