import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, FormControlLabel, Radio, RadioGroup, Box } from '@mui/material';
import { FormLabel } from 'react-bootstrap';
import CustomInputField from '../../components/common/StyledComponents/CustomInputField';
import Footer from '../../components/common/Footer';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../utils/languageNamespaces';

export default function ListSave() {
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_SAVE_AS,
    TRANS_KEYS.CONTACTS_SHARE,
    TRANS_KEYS.CONTACTS_SHARE_WITH_IGNYTE_USER,
    TRANS_KEYS.CONTACTS_PRIVATE_CONTACT,
    TRANS_KEYS.CONTACTS_ADD_NAME
  ]);
  const [share, setShare] = useState('');
  const handleChange = event => {
    setShare(event.target.value);
  };

  return (
    <div id='side-pop-up' className='p-4'>
      <form>
        <Stack spacing={2}>
          {/* <FormLabel className="fs-14">
            {t(`${TRANS_KEYS.CONTACTS_SAVE_AS}:key`)}
          </FormLabel> */}
          <Box>
            <CustomInputField
              id='inputTextStyle'
              color='heading'
              fullWidth
              hiddenLabel
              //placeholder={t(`${TRANS_KEYS.CONTACTS_ADD_NAME}:key`)}
              label={t(`${TRANS_KEYS.CONTACTS_SAVE_AS}:key`)}
              variant='outlined'
            />
          </Box>
          <FormLabel className='fs-14'>{t(`${TRANS_KEYS.CONTACTS_SHARE}:key`)}</FormLabel>
          <RadioGroup name='share' value={share} onChange={handleChange} row>
            <FormControlLabel control={<Radio />} label={t(`${TRANS_KEYS.CONTACTS_SHARE_WITH_IGNYTE_USER}:key`)} value='Share with Ignyte User' />
            <FormControlLabel control={<Radio />} label={t(`${TRANS_KEYS.CONTACTS_PRIVATE_CONTACT}:key`)} value='Private Contact' />
          </RadioGroup>
          <Box>
            <Footer></Footer>
          </Box>
        </Stack>
      </form>
    </div>
  );
}

ListSave.propTypes = {
  location: PropTypes.object
};
