import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from '../../services/axios';
import { PUBLIC_ROUTES } from '../../services/constantService';
import { commonActions } from '../../store/commonSlice';
import { getDynamicUrl } from '../common';

// devtenant.non-prod.ignyteai-test.com/login?url=https://devtenant.app.non-prod.ignyteai-test.com/api/v1/health
export const UseUserSecurityHook = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Get current location for Redirect
    const path = window.location.pathname;

    if (PUBLIC_ROUTES.includes(path.split('/')[1])) {
      console.log('Public Route');
      return;
    } else {
      // The url should be TLD with .app inserted in between subdomain and domain
      const url = window.location.origin,
        subdomain = url.split('.')[0];

      const restOfDomain = url.substring(url.indexOf('.') + 1); // Get the rest of the domain

      const dynamicUrl = getDynamicUrl(subdomain, restOfDomain);
      // This will be called everytime just to check if the user is logged in or not
      // It will be a User Auth API call to backend, With credentials to be added in reusalbe axios
      axios
        .get(dynamicUrl, {
          withCredentials: true
        })
        .then(res => {
          try {
            if ((res && res.status === 200) || (res && res.status === 404)) {
              // ? Check if the response is 200 and user is alive. ie: Json or HTML
              if (res.data.alive === true) {
                //TODO Here we will get the security data for the user
                //TODO Save the User Security data in redux store
                dispatch(
                  commonActions.userSecurityData({
                    appSecurity: res.data.appSecurity,
                    isSecurityEnabled: res.data.isSecurityEnabled
                  })
                );
              } else {
                // If the user is not logged in, call login api forcefully with current location
                if (!dynamicUrl.includes('localhost') && !process.env.REACT_APP_IS_LOCAL) {
                  window.location.href = `${subdomain}.app.${restOfDomain}/login?url=${path}`;
                }
              }
            } else {
              // If the user is not logged in, call login api forcefully with current location
              if (!dynamicUrl.includes('localhost') && !process.env.REACT_APP_IS_LOCAL) {
                window.location.href = `${subdomain}.app.${restOfDomain}/login?url=${path}`;
              }
            }
          } catch {
            console.error('Error in UseUserSecurityHook', res);
          }
        });
    }
  }, []);

  return <div>&nbsp;</div>;
};
