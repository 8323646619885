//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import WorkbenchInfoEdit from './Edit';
import WorkbenchInfoView from './View';

export default function WorkbenchInfo(props) {
  const { workbench, isEditing, control, register, setValue, watch, count, option, isLockedWorkbench, workbenchLockAccess } = props;

  return isEditing ? (
    <WorkbenchInfoEdit option={option} register={register} workbench={workbench} watch={watch} setValue={setValue} control={control} count={count} workbenchLockAccess={workbenchLockAccess} />
  ) : (
    <WorkbenchInfoView workbench={workbench} isLockedWorkbench={isLockedWorkbench} />
  );
}

WorkbenchInfo.propTypes = {
  workbench: PropTypes.object,
  isEditing: PropTypes.bool,
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  count: PropTypes.number,
  option: PropTypes.array,
  isLockedWorkbench: PropTypes.bool,
  workbenchLockAccess: PropTypes.bool
};
