import React, { useEffect, useState } from 'react';
import TabLayoutWithSteppers from '../TabLayoutWithSteppers/TabLayoutWithSteppers';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import TeamInfo from '../CreateBD/TeamInfo';
import BasicDetails from '../CreateBD/BasicDetails';
import { BDBillingSchema, IgnBDSchema, cleanup, prepareSearchData } from '../AddBD/utils';
import { customFormValidator, requireValidMessage } from '../../utils/common';
import { useSnackbar } from 'notistack';
import { checkForError, validateCalculationAmount, validatePercentageFields } from '../ViewSearch/utils';
import { requireMessage, successMessage, unableMessage, validateMessage, VALIDATION_MESSAGE } from '../../services/MessageService';
import { BILLING_VALIDATIONS, ERROR, GET, PATCH, POST, SUCCESS } from '../../services/constantService';
import checkForChanges from '../../utils/checkForChanges';
import { fetchCurrencyDetails, fetchUserList } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import CustomConfirmationPopup from '../common/CustomConfirmationPopup';
import { BDDataApi } from '../../services/ApiService';
import BillingDetails from '../CreateBD/BillingDetails';
//----------------------------------------------// External Imports // -------------------------------------------------

const EditBdPopup = props => {
  const { closePopup, bd, editForm, /* setTabValueNumber, */ tabValueNumber, /* currencyDetails, */ isEditing, setDefaultValues, setLoading, companyId } = props;
  const [currentValues, setCurrentValues] = useState({ ...bd });
  const [handleSaveArgs, setHandleSaveArgs] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [isHide /* , setIsHide */] = useState(false);
  const [tabNewValueNumber, setTabNewValueNumber] = useState(null);
  const { setValue, watch, register, control, getValues, reset, formState } = useForm({
    ...bd
  });
  const currencyCodeDetails = useSelector(state => (state.commonReducer[watch('bd_currency_values.code')] ? state.commonReducer[watch('bd_currency_values.code')] : {}));
  const dispatch = useDispatch();
  const users = useSelector(state => state.rootReducer.users);
  const [option, setOptions] = useState([]);
  // const [isLoading, setIsLoading] = useState(false)
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [thresholdPopup, setThresholdPopup] = useState(false);
  const [thresholdValue, setThresholdValue] = useState(false);
  const [skipCheckThresholdValue, setSkipCheckThresholdValue] = useState(false);
  const { isDirty } = formState;
  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(watch('bd_currency_values.code'), dispatch);
      } catch (e) {
        console.log('Error found in currencyHandler::', e);
      }
    };
    if (watch('bd_currency_values.code') && Object.keys(currencyCodeDetails)?.length === 0) {
      currencyHandler();
    }
  }, [watch('bd_currency_values.code')]);

  useEffect(() => {
    register('stage');
    register('stage_desc');
    register('ign_companies');
    register('company_id');
    register('client_team');
    register('job_title');
    register('country');
    register('projected_start_date');
    register('basic_partners');
    register('probability');
    register('bd_status');
    register('brand');
    register('markets');
    // register('market')
    register('competition');
    register('bd_currency_values.code');
    //billing form
    register('currency');
    register('bd_currency');
    register('billing_region');
    register('fee_cap_amount');
    register('term_type');
    register('intervals_other');
    register('intervals');
    register('fee_type');
    register('fee_percentage');
    register('billing_notes');
    register('estimated_percentage_bases');
    register('project_indirect_fees');
    register('estimated_percentage_base');
    register('estimated_indirect_total_fee');
    register('estimated_revenue');
    register('projected_fee');
  }, [register]);

  useEffect(() => {
    setCurrentValues({ ...bd });
  }, [bd]);
  const validateKgpInfo = formData => {
    let isError = false;
    if (formData.partners && formData.partners.length > 0) {
      const fields = {
        origination_credit: 30,
        selling_credit: 20,
        execution_credit: 50
      };
      isError = checkForError(formData.partners, fields);
    }
    if (!isError && formData.recruiters && formData.recruiters.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.recruiters, fields);
    }
    if (!isError && formData.researchers && formData.researchers.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.researchers, fields);
    }
    if (!isError && formData.eas && formData.eas.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.eas, fields);
    }
    if (!isError && formData.project_admin && formData.project_admin?.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.project_admin, fields);
    }
    if (isError) {
      const message = validateMessage('valid number', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
      return false;
    }
    return true;
  };
  const validateBillingInfo = async (formData, tabValue) => {
    const isValid = await BDBillingSchema.isValid(formData);
    if (tabValue === 2 && !isValid) {
      let requiredField = [
        { fieldName: 'bd_currency', label: 'Currency Code', type: String },
        { fieldName: 'billing_region', label: 'Billing Region', type: String }
      ];
      let dirtyField = customFormValidator(formData, requiredField);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    }
    const isValidIndirectFee = validatePercentageFields(formData);
    if (!isValidIndirectFee) {
      enqueueSnackbar(BILLING_VALIDATIONS.FEE_PERCENTAGE_MAX, { variant: ERROR });
      return;
    }
    const isCalculationAmount = validateCalculationAmount(formData);
    if (!isCalculationAmount) {
      enqueueSnackbar(BILLING_VALIDATIONS.CALCULATION_AMOUNT_MAX, { variant: ERROR });
      return;
    }
    return true;
  };

  useEffect(() => {
    if (skipCheckThresholdValue) {
      // handleSave(tabChanged)
      const { businessDevelopment, currentTabValue, save, newTabValue } = handleSaveArgs;
      updateBD(businessDevelopment, currentTabValue, save, newTabValue);
    }
  }, [skipCheckThresholdValue]);

  const updateBD = async (businessDevelopment, currentTabValue, save, newTabValue) => {
    const isValid = await IgnBDSchema.isValid(businessDevelopment);
    setHandleSaveArgs({ businessDevelopment, currentTabValue, save, newTabValue });
    if (!isValid) {
      let requiredField = [
        { fieldName: 'ign_companies', label: 'Company', type: {} },
        { fieldName: 'job_title', label: 'Job Title', type: String },
        { fieldName: 'basic_partners', label: 'Partners', type: [] },
        { fieldName: 'probability', label: 'Probability', type: String },
        // { fieldName: "brand", label: "Service Offering", type: String },
        { fieldName: 'stage', label: 'Stage', type: String },
        { fieldName: 'markets', label: 'Geography', type: String },
        { fieldName: 'country_id', label: 'Location', type: String },
        { fieldName: 'projected_start_date', label: 'Projected Start Date', type: String }
      ];
      let dirtyField = customFormValidator(businessDevelopment, requiredField);
      if (dirtyField && currentTabValue == 0) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    if (currentTabValue === 1) {
      const isValidKgpInfo = validateKgpInfo(businessDevelopment);
      if (!isValidKgpInfo) return;
    }
    if (currentTabValue === 2) {
      const isValidBillingInfo = await validateBillingInfo(businessDevelopment, currentTabValue);
      if (!isValidBillingInfo) return;
    }
    if (businessDevelopment.projected_start_date === 'Invalid date') {
      const message = validateMessage('valid date', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    // if (businessDevelopment.estimated_revenue > 0 && !businessDevelopment.market) {
    //   enqueueSnackbar(BILLING_VALIDATIONS.MARKET_VALUE, { variant: ERROR });
    //   return
    // }
    if (currentTabValue === 1 && businessDevelopment.partners) {
      if (!businessDevelopment.partners.length || (businessDevelopment.partners.length && !businessDevelopment.partners.find(ele => ele?.user))) {
        const message = requireMessage('Partners', 'is');
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    setHandleSaveArgs({ businessDevelopment, currentTabValue, save, newTabValue });
    const payload = cleanup(businessDevelopment, currentTabValue);
    const isDataequal = checkForChanges(currentValues, businessDevelopment);
    let est_value, curr_value;
    if (!Number.isInteger(businessDevelopment?.estimated_revenue)) {
      est_value = businessDevelopment?.estimated_revenue && parseFloat(businessDevelopment?.estimated_revenue.toFixed(2));
      curr_value = currentValues?.estimated_revenue && parseFloat(currentValues?.estimated_revenue.toFixed(2));
    } else {
      est_value = businessDevelopment?.estimated_revenue && parseInt(businessDevelopment?.estimated_revenue);
      curr_value = currentValues?.estimated_revenue && parseInt(currentValues?.estimated_revenue);
    }
    if (
      payload &&
      payload.billing_status !== 'PND_RHEAD' &&
      payload.estimated_revenue < payload.markets?.threshold &&
      payload.estimated_revenue !== 0 &&
      !skipCheckThresholdValue &&
      curr_value !== est_value
    ) {
      setThresholdPopup(true);
      return;
    }
    if (skipCheckThresholdValue) {
      setSkipCheckThresholdValue(false);
      if (thresholdValue) {
        payload['isBelowThreshold'] = true;
      } else {
        payload['isBelowThreshold'] = false;
      }
    }
    if (!isDataequal) {
      setLoading(true);
      const obj = payload.id ? await BDDataApi(PATCH, payload.id, payload, 'update') : await BDDataApi(POST, '', payload);
      let { status, data: res } = obj;
      if (status && status === 201) {
        const jobNumber = res.result.job_number;
        setCurrentValues(prevState => ({ ...prevState, job_number: jobNumber, id: res.result.id }));
        const message = successMessage('Business Development', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
      } else if (status && status === 200) {
        let { status, data: searchResult } = await BDDataApi(GET, payload.id ? payload.id : res.result.id);
        if (status && status === 200) {
          const searchData = prepareSearchData(searchResult);
          setCurrentValues(searchData);
          setDefaultValues(searchData);
        }
        const message = successMessage('Business Development', 'updated');
        if (currentTabValue === 2 && save === 'saveButton') enqueueSnackbar(message, { variant: SUCCESS });
      } else {
        const message = unableMessage('Business Development', `${payload.id ? 'update' : 'create'}`);
        enqueueSnackbar(res.message || message, { variant: ERROR });
        return false;
      }
      setLoading(false);
    }
    if (tabValueNumber === 2 && save === 'saveButton' && skipCheckThresholdValue) handleClose();
    if (newTabValue && newTabValue > 2) newTabValue = 2;
    skipCheckThresholdValue && setTabNewValueNumber(newTabValue);
    return true;
  };
  const handleClose = () => {
    if (!showConfirmationPopup) {
      closePopup(false);
    }
    setShowConfirmationPopup(false);
  };
  const handleChange = async (oldValue, newValue, save = '') => {
    try {
      const getCurrentData = getValues();
      const formValues = { ...currentValues, ...getCurrentData };
      console.log('formaVa', formValues);
      reset({ ...formValues });
      if (skipCheckThresholdValue) setCurrentValues(formValues);

      const result = await updateBD(formValues, oldValue, save, newValue);
      return result;
    } catch (e) {
      console.log('Error found in handleChange::', e);
    }
  };
  useEffect(() => {
    if (!users) {
      dispatch(fetchUserList('users'));
    } else {
      setOptions(users);
    }
  }, [users, dispatch]);

  const onConfirm = () => {
    setShowConfirmationPopup(false);
    editForm(false);
    setTabNewValueNumber(tabValueNumber);
  };
  const handleCancel = () => {
    if (isDirty) {
      setShowConfirmationPopup(true);
    } else {
      editForm(false);
    }
  };

  const handleConfirmThresholdPopup = () => {
    setThresholdValue(true);
    setThresholdPopup(false);
    setSkipCheckThresholdValue(true);
  };

  const handleThresholdCancelPopup = () => {
    setThresholdValue(false);
    setThresholdPopup(false);
    setSkipCheckThresholdValue(false);
  };

  return (
    <>
      {/* <Loader show={isLoading} /> */}
      {showConfirmationPopup && (
        <CustomConfirmationPopup
          open={showConfirmationPopup}
          onClose={handleClose}
          cancelText='Cancel'
          confirmText='Ok'
          onConfirm={onConfirm}
          customMessage={VALIDATION_MESSAGE.delete_bd_search}
          setOpen={setShowConfirmationPopup}
        />
      )}
      {thresholdPopup && (
        <div className='confirmation-popup-bd-search'>
          <CustomConfirmationPopup
            open={thresholdPopup}
            customMessage={VALIDATION_MESSAGE.threshold_confirmation}
            onConfirm={handleConfirmThresholdPopup}
            // onCancel={handleThresholdCancelPopup}
            onClose={handleThresholdCancelPopup}
            cancelText='No'
            confirmText='Save & Continue'
            setOpen={setThresholdPopup}
          />
        </div>
      )}
      <TabLayoutWithSteppers
        navItems={['Basic Information', 'Team Info', 'Billing Details']}
        tabValueNumber={tabNewValueNumber}
        setTabValueNumber={setTabNewValueNumber}
        tabChange={handleChange}
        editPopup='editPopup'
        onCancel={handleCancel}
        onClose={handleClose}
        showTab={tabValueNumber}
      >
        <BasicDetails register={register} setValue={setValue} watch={watch} currentValues={currentValues} isEditing={isEditing} companyId={companyId} />
        <TeamInfo register={register} setValue={setValue} watch={watch} currentValues={currentValues} option={option} defaultStyle={{}} control={control} isEditing={isEditing} bd='AddBD' />
        <BillingDetails register={register} setValue={setValue} watch={watch} currentValues={currentValues} isEditing={isEditing} currencyDetails={currencyCodeDetails} isHide={isHide} />
      </TabLayoutWithSteppers>
    </>
  );
};
EditBdPopup.propTypes = {
  bd: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  closePopup: PropTypes.func,
  editForm: PropTypes.func,
  defaultValues: PropTypes.object,
  tabValueNumber: PropTypes.number,
  currencyDetails: PropTypes.object,
  isEditing: PropTypes.bool,
  setTabValueNumber: PropTypes.number,
  setDefaultValues: PropTypes.func,
  setLoading: PropTypes.func,
  companyId: PropTypes.string
};
export default EditBdPopup;
