import { Box, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import CustomButton from '../../../../components/common/CustomButton';
import CustomDropdown from '../../../../components/common/CustomDropdown';
import CustomPopup from '../../../../components/common/CustomPopup';
import { resourcesApi } from '../../../../services/ApiService';
import { RESOURCE_VALIDATION } from '../../../../services/constantService';
import { ParentOptions, TypeOptions } from '../utils';

const AddResourcePopup = ({ isOpen, setIsPopupOpen, refetch }) => {
  const [data, setData] = useState({
    name: '',
    type: '',
    parent: '',
    description: ''
  });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const addResource = async () => {
    setLoading(true);
    await resourcesApi('post', '', data).then(async res => {
      if (res.status === 201) {
        enqueueSnackbar(RESOURCE_VALIDATION.SUCCESS, { variant: 'success' });
        await refetch();
        setLoading(false);
        setIsPopupOpen(false);
      } else {
        enqueueSnackbar(RESOURCE_VALIDATION.FAILED, { variant: 'error' });
        setLoading(false);
      }
    });
    setData({
      name: '',
      type: '',
      parent: '',
      description: ''
    });
  };

  return (
    <CustomPopup
      open={isOpen}
      onClose={() => {
        setIsPopupOpen(false);
      }}
      title={'Add Resource'}
      confirmText={'Add Resource'}
    >
      <Box
        sx={{
          width: '50vw',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            gap: 2
          }}
        >
          <TextField
            id='outlined-basic'
            label='Resource Name'
            variant='outlined'
            required
            size='small'
            sx={{
              width: '40%'
            }}
            onChange={e => {
              setData({
                ...data,
                name: e.target.value
              });
            }}
          />

          <CustomDropdown
            options={TypeOptions}
            label='Type'
            required
            onInputChange={(e, value) => {
              setData({
                ...data,
                type: value
              });
            }}
          />
          <CustomDropdown
            options={ParentOptions}
            label='Parent'
            required
            onInputChange={(e, value) => {
              setData({
                ...data,
                parent: value
              });
            }}
          />
        </Box>

        <TextField
          id='outlined-basic'
          label='Description'
          variant='outlined'
          size='small'
          sx={{
            my: 2
          }}
          onChange={e => {
            setData({
              ...data,
              description: e.target.value
            });
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2
          }}
        >
          <CustomButton
            type='tertiary-error'
            buttonText='Cancel'
            onClick={() => {
              setIsPopupOpen(false);
              setData({
                name: '',
                type: '',
                parent: '',
                description: ''
              });
            }}
          />
          <CustomButton
            buttonText='Add Resource'
            iconRight={<Spinner animation='border' variant='light' size='sm' className='ml-2' style={{ display: loading ? 'inline-block' : 'none' }} />}
            onClick={() => {
              addResource();
            }}
          />
        </Box>
      </Box>
    </CustomPopup>
  );
};

AddResourcePopup.propTypes = {
  isOpen: PropTypes.bool,
  setIsPopupOpen: PropTypes.func,
  data: PropTypes.object,
  refetch: PropTypes.func
};

export default AddResourcePopup;
