//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useCallback } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Grid, Button, Typography } from '@mui/material';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import update from 'immutability-helper';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import DraggableComponent from '../Draggable/DraggableComponent';
import ItemTypes from '../Draggable/ItemTypes';
import RichTextPopup from '../../../../../RichTextPopup';
function SkillElementsSelection(props) {
  const { updateSkillElement, currentElements, skillIndex, onSkillElementsChange, readOnly } = props;
  const addSkillElement = () => {
    let currentSkillElements = [...currentElements, { position: currentElements?.length }];
    onSkillElementsChange(skillIndex, currentSkillElements);
  };

  const removeSkillElement = index => {
    let currentSkillElements = [...currentElements];
    currentSkillElements.splice(index, 1);
    currentSkillElements.map((ele, index) => (ele.position = index));
    onSkillElementsChange(skillIndex, currentSkillElements);
  };

  const moveElement = useCallback(
    (dragIndex, hoverIndex) => {
      const dragSkillElement = currentElements[dragIndex];
      let currentSkillElements = update(currentElements, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSkillElement]
        ]
      });
      let newElementArray = currentSkillElements.map(item => {
        if (item.position === hoverIndex) {
          return { ...item, position: dragIndex };
        } else if (item.position === dragIndex) {
          return { ...item, position: hoverIndex };
        } else {
          return item;
        }
      });
      onSkillElementsChange(skillIndex, newElementArray);
    },
    [currentElements, skillIndex, onSkillElementsChange]
  );

  const saveMinimum = async (payload, position, commentType, item, setAutoSaveLoading) => {
    try {
      let element = { ...item, minimum: payload.Minimum };
      await updateSkillElement(skillIndex, position, element, false, setAutoSaveLoading);
    } catch (e) {
      console.log('Error found in saveMinimum::', e);
    }
  };

  const saveIdeal = async (payload, position, commentType, item, setAutoSaveLoading) => {
    try {
      let element = { ...item, ideal: payload.Ideal };
      await updateSkillElement(skillIndex, position, element, false, setAutoSaveLoading);
    } catch (e) {
      console.log('Error found in saveIdeal::', e);
    }
  };

  const saveQuestion = async (payload, position, commentType, item, setAutoSaveLoading) => {
    try {
      let element = { ...item, question: payload.Question };
      await updateSkillElement(skillIndex, position, element, false, setAutoSaveLoading);
    } catch (e) {
      console.log('Error found in saveQuestion::', e);
    }
  };
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={4}>
          <Typography color={'primary'} style={{ fontSize: '12px' }}>
            Minimum
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color={'primary'} style={{ fontSize: '12px' }}>
            Ideal
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className='pl-4' color={'primary'} style={{ fontSize: '12px' }}>
            Question
          </Typography>
        </Grid>
      </Grid>
      {currentElements &&
        currentElements.map((item, index) => (
          <DraggableComponent
            key={index}
            index={index}
            id={item.id || index}
            moveNode={moveElement}
            componentType={ItemTypes.SKILL_ELEMENT}
            content={(dragHandle, preview) => (
              <Grid container ref={preview}>
                <Grid item xs={4} className='d-flex'>
                  <span ref={dragHandle}>
                    <DragIndicatorOutlinedIcon style={{ padding: '0px', color: 'gray' }} />
                  </span>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-11 text-view-value p-0'>
                        <div className='outlined-border transform p-0'>
                          <RichTextPopup
                            setOpen={readOnly ? false : true}
                            className='input-form-field'
                            onChange={data => {
                              data = data?.replaceAll('<p><br /></p>', '');
                              let element = { ...item, minimum: data };
                              updateSkillElement(skillIndex, index, element, true);
                            }}
                            placeholder={''}
                            title='Minimum'
                            name='Minimum'
                            autoSave={saveMinimum}
                            item={item}
                            index={index}
                            value={item.minimum || ''}
                            saveStatus={true}
                            spfaSkillFlag={true}
                            InputLabelProps={{ focused: true }}
                            updatedDate={item.updated_at}
                            payloadType={'default'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className='text-view-value p-0 w-100'>
                    <div className='outlined-border transform p-0'>
                      <RichTextPopup
                        className='input-form-field'
                        setOpen={readOnly ? false : true}
                        onChange={data => {
                          data = data?.replaceAll('<p><br /></p>', '');
                          let element = { ...item, ideal: data };
                          updateSkillElement(skillIndex, index, element, true);
                        }}
                        placeholder={''}
                        title='Ideal'
                        name='Ideal'
                        autoSave={saveIdeal}
                        item={item}
                        index={index}
                        value={item.ideal || ''}
                        saveStatus={true}
                        spfaSkillFlag={true}
                        InputLabelProps={{ focused: true }}
                        updatedDate={item.updated_at}
                        payloadType={'default'}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4} className='d-flex'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-11 text-view-value pl-4'>
                        <div className='outlined-border transform'>
                          <RichTextPopup
                            className='input-form-field'
                            setOpen={readOnly ? false : true}
                            onChange={data => {
                              let element = { ...item, question: data };
                              updateSkillElement(skillIndex, index, element, true);
                            }}
                            placeholder={''}
                            title='Question'
                            name='Question'
                            autoSave={saveQuestion}
                            item={item}
                            index={index}
                            spfaSkillFlag={true}
                            value={item.question || ''}
                            saveStatus={true}
                            InputLabelProps={{ focused: true }}
                            updatedDate={item.updated_at}
                            payloadType={'default'}
                          />
                        </div>
                      </div>
                      <span>
                        <DeleteIcon onClick={() => removeSkillElement(index)} className='cursor-pointer' />
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
          />
        ))}
      <Button variant={'text'} startIcon={<AddCircleIcon />} onClick={addSkillElement} color='primary'>
        Add Element
      </Button>
    </React.Fragment>
  );
}

SkillElementsSelection.propTypes = {
  updateSkillElement: PropTypes.func,
  currentElements: PropTypes.array,
  readOnly: PropTypes.bool,
  skillIndex: PropTypes.number,
  onSkillElementsChange: PropTypes.func
};

export default SkillElementsSelection;
