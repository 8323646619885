//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import CloseIcon from '@mui/icons-material/Close';
import { Button, Typography } from '@mui/material';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import Loader from '../../../../../../components/common/Loader';
import { formatDate } from '../../../../../../utils/date';
import { getUserSelector } from '../../../../../../selectors';
import { POST, SUCCESS, ERROR } from '../../../../../../services/constantService';
import { candidateDataApi } from '../../../../../../services/ApiService';
import { unableMessage, successMessage, inviteEmailValidateMessage } from '../../../../../../services/MessageService';
function CandidateSuiteInvitePopUp(props) {
  const { client, inviteFor, onClose, enqueueSnackbar, isdisableSendButton = false } = props;
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = {
    emailType: inviteFor === 'client' ? (client.contact.work_email ? 'Work' : 'Private') : client.contact.private_email ? 'Private' : 'Work',
    locale: 'en_US',
    bcc: '',
    reset: false
  };
  const { register, handleSubmit, setValue } = useForm({ defaultValues });
  const [popUpOpen, setPopUpOpen] = useState(true);
  const [resetInvite, setResetInvite] = useState(false);
  const userData = useSelector(getUserSelector);

  useEffect(() => {
    if (isdisableSendButton) {
      const message = inviteEmailValidateMessage('Candidate suite');
      enqueueSnackbar(message, { variant: ERROR });
    }
    if (client.contact.candidatesuite_invitation_sent_on) {
      setResetInvite(true);
    }
  }, [client]);

  const sendCandidateSuiteInvite = async formData => {
    setIsLoading(true);
    const inviteId = inviteFor === 'client' ? client.client_id : client.candidate.id;
    let BCC;
    if (formData.bcc?.length === 0) BCC = [];
    else {
      BCC = formData.bcc.split(',');
    }
    const email = formData.emailType === 'Work' ? client.contact.work_email : client.contact.private_email;
    const payload = {
      'Candidate Id': inviteId,
      Language: formData.locale,
      Email: email,
      BCC: BCC,
      'remove Duplicate': true,
      'Partner Id': userData.id
    };
    const { status, data } = await candidateDataApi(POST, inviteId, payload, 'invite');
    if (status && data.status === 'OK') {
      const message = successMessage('Candidate Suite invite', 'sent');
      enqueueSnackbar(message, { variant: SUCCESS });
      setPopUpOpen(false);
      onClose(true);
    } else {
      const message = unableMessage('candidate suite invite', 'send');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
      setPopUpOpen(false);
      onClose(false);
    }
    setIsLoading(false);
  };

  const getName = client => {
    const firstName = client?.contact.first_name ? client.contact.first_name : '';
    const lastName = client?.contact.last_name ? client.contact.last_name : '';
    return firstName + ' ' + lastName;
  };

  useEffect(() => {
    register('locale');
    register('bcc');
    register('reset');
  }, [register]);

  const getDate = date => {
    if (date) {
      return formatDate(date);
    }
    return 'N.A';
  };

  const handleResentInvite = () => {
    setResetInvite(false);
    setValue('reset', true);
  };

  return (
    <Popup open={popUpOpen} className='athena-invite client-suite-invite' closeOnDocumentClick={false} closeOnEscape={false}>
      <>
        <Loader show={isLoading} />
        <div className='d-flex flex-column h-100'>
          <div className='d-flex align-items-center justify-content-between popup-header' style={{ background: '#c3c3c35c' }}>
            <div className='d-flex align-items-center color-white'>
              <h4 className='mr-5'>CandidateSuite</h4>
            </div>
            <span className='action-icon cursor-pointer color-white' onClick={onClose}>
              <CloseIcon />
            </span>
          </div>
          {resetInvite ? (
            <div className='client-suite-invite-options text-center'>
              <Typography style={{ fontSize: 18, paddingBottom: 10 }}>
                An invitation has already been sent to <span style={{ fontWeight: 600 }}>{getName(client)}</span>
              </Typography>
              <Typography style={{ paddingBottom: 20 }}>Are you sure you want to send an invite again?</Typography>
              <Typography>CandidateSuite invitation sent on: {getDate(client.contact.candidatesuite_invitation_sent_on)}</Typography>
              <Typography>CandidateSuite first logged in on: {getDate(client.contact.candidatesuite_first_login_date)}</Typography>
              <Typography>CandidateSuite last logged in on: {getDate(client.contact.candidatesuite_last_login_date)}</Typography>
            </div>
          ) : (
            <div className='client-suite-invite-options'>
              <div className='field-details'>
                <span className='field-title'>Contact Name</span>
                <span className='field-value'>{getName(client)}</span>
              </div>
              <div className='field-details'>
                <span className='field-title'>Select Language</span>
                <div className='field-value'>
                  <Select defaultValue={defaultValues.locale} disabled={true}>
                    <MenuItem value='en_US'>English</MenuItem>
                    {/* <MenuItem value='es_ES'>Spanish</MenuItem> 
                    <MenuItem value='pt_BR'>Portuguese</MenuItem> */}
                  </Select>
                </div>
              </div>
              <div className='field-details align-items-start'>
                <span className='field-title mt-2'>Send Email To</span>
                <RadioGroup aria-label='email' name='emailType' defaultValue={defaultValues.emailType} className='field-value'>
                  <FormControlLabel
                    control={<Radio size='small' value={'Work'} inputRef={register('emailType')} />}
                    label={`Work Email(${client.contact.work_email || 'N.A'})`}
                    disabled={!client.contact.work_email}
                  />
                  <FormControlLabel
                    control={<Radio size='small' value={'Private'} inputRef={register('emailType')} />}
                    label={`Private Email(${client.contact.private_email || 'N.A'})`}
                    disabled={!client.contact.private_email}
                  />
                </RadioGroup>
              </div>
              <div className='field-details'>
                <span className='field-title'>BCC</span>
                <TextField required={true} InputLabelProps={{ focused: true }} className='input-form-field' onChange={e => setValue('bcc', e.target.value)} /*{...register('')} name={'bcc'}*/ />
              </div>
            </div>
          )}
          {resetInvite ? (
            <div className='action-buttons text-center' style={{ background: '#c3c3c35c' }}>
              <Button onClick={onClose} style={{ marginRight: '20%' }} color='primary' variant='outlined'>
                No
              </Button>
              <Button onClick={handleResentInvite} color='primary' variant='contained'>
                Yes
              </Button>
            </div>
          ) : (
            <div className='action-buttons' style={{ background: '#c3c3c35c' }}>
              <Button onClick={onClose} color='primary' className='mr-2'>
                Cancel
              </Button>
              <Button variant='contained' className='button' color='primary' disabled={isdisableSendButton} onClick={handleSubmit(sendCandidateSuiteInvite)} autoFocus>
                Send
              </Button>
            </div>
          )}
        </div>
      </>
    </Popup>
  );
}

CandidateSuiteInvitePopUp.propTypes = {
  client: PropTypes.object,
  inviteFor: PropTypes.string,
  onClose: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  isdisableSendButton: PropTypes.bool
};

export default CandidateSuiteInvitePopUp;
