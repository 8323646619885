import { Person } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomMuiDropdown from '../../../../../components/common/CustomMuiDropdown';
import CustomPopup from '../../../../../components/common/CustomPopup';
import { CustomReportTemplatesApi, ignUsersApi } from '../../../../../services/ApiService';
import { ERROR, GET, POST, SUCCESS } from '../../../../../services/constantService';
import styles from '../../../../../variables.scss';

const ShareTemplatePopup = ({ show = false, template, setShareTemplatePopup }) => {
  if (!show) return null;
  const [selectedUsers, setSelectedUsers] = useState([]);
  /** Users with whom the template has already been shared */
  const [sharedUsers, setSharedUsers] = useState([]);

  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState('');

  const filteredOptions = options?.filter(u => !sharedUsers?.some(v => v.user_id === u.id));

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShareTemplatePopup(false);
  };

  const handleChange = (e, val) => {
    setSelectedUsers(val);
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const res = await ignUsersApi(GET, `getAll?search=${search}`);
      if (res.status === 200) {
        setOptions(res.data);
      } else {
        enqueueSnackbar(res?.data?.message, { variant: ERROR });
      }
    } catch (error) {
      enqueueSnackbar(`Failed to get users ${error?.message}`, { variant: ERROR });
    } finally {
      setLoading(false);
    }
  };

  const timeoutRef = useRef(null);

  useEffect(() => {
    if (search) {
      timeoutRef.current = setTimeout(() => {
        fetchUsers();
      }, 500);
    }

    return () => {
      if (timeoutRef.current) clearInterval(timeoutRef.current);
    };
  }, [search]);

  const fetchSharedUsers = async () => {
    setLoading(true);
    try {
      const res = await CustomReportTemplatesApi(GET, `/shared-users?templateName=${template?.template_name}`);
      if (res.status === 200) {
        setSharedUsers(res?.data || []);
      } else {
        setSharedUsers([]);
        enqueueSnackbar(res.data?.message, {
          variant: ERROR
        });
      }
    } catch (error) {
      setSharedUsers([]);
      enqueueSnackbar(`Failed to get shared users for the ${template?.template_name}: ${error?.message}`, {
        variant: ERROR
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSharedUsers();
  }, []);

  const handleShareTemplate = async () => {
    try {
      setLoading(true);
      const res = await CustomReportTemplatesApi(POST, '/share', {
        template_name: template?.template_name,
        report_name: 'search_template_report',
        userIds: selectedUsers?.map(u => u.id)
      });
      if (res.status === 200) {
        enqueueSnackbar(res?.data?.message, { variant: SUCCESS });
        fetchSharedUsers();
        setSelectedUsers([]);
      } else {
        enqueueSnackbar(res?.data?.message, { variant: ERROR });
      }
    } catch (error) {
      enqueueSnackbar(`Failed to save template: ${error?.message}`, { variant: ERROR });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomPopup
      disabled={selectedUsers?.length === 0}
      loading={loading}
      width={'40vw'}
      popupHeight={'40vh'}
      title={t('reports.share_template')}
      confirmText={t('reports.share_template')}
      open={show}
      onClose={handleClose}
      showAction={true}
      handleSubmit={handleShareTemplate}
      headerClassName={'primary'}
    >
      <Box display='flex' height={'100%'} flexDirection={'column'}>
        <CustomMuiDropdown
          onInputChange={(_e, newVal) => setSearch(newVal)}
          inputValue={search}
          options={filteredOptions}
          label={'Select Users'}
          value={selectedUsers}
          getOptionLabel={opt => opt?.name}
          multiple
          size='small'
          onChange={handleChange}
          isLoading={loading}
        />
        {sharedUsers?.length > 0 && (
          <Box display='flex' flex={1} overflow={'auto'} flexDirection={'column'} align-items='center'>
            <Typography sx={{ paddingY: 1, marginTop: 1, background: styles.white, position: 'sticky', top: 0, fontWeight: 600, color: 'var(--palette-secondary-main)', fontSize: 14 }}>
              Already Shared With
            </Typography>
            <Box display='flex' flexDirection={'column'} align-items='center' gap={1}>
              {sharedUsers?.map(user => (
                <Typography sx={{ display: 'flex', gap: 1, alignItems: 'center', fontWeight: 600, color: styles.text, fontSize: 14 }} key={user?.user_id}>
                  <Person fontSize='small' /> {user?.shared_user?.name}
                </Typography>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </CustomPopup>
  );
};

export default ShareTemplatePopup;

ShareTemplatePopup.propTypes = {
  show: PropTypes.string,
  setShareTemplatePopup: PropTypes.func,
  template: PropTypes.object
};
