import { useEffect, useState } from 'react';

/**
 * This hook is used to fetch data from an API
 * @param {Object} params
 * @param {String | Number | null} params.queryKey - The key to be used for refetching the data, changing this key will trigger a refetch
 * @param {Function} params.queryFn - The function to be called to fetch the data
 * @returns {useApi} - An object containing the data, loading, success, error and refetch function
 */
const useApi = ({ queryKey, queryFn }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const fetchData = async (skipLoading = false) => {
    if (!skipLoading) {
      setLoading(true);
    }
    setError(null);

    try {
      const response = await queryFn();
      setData(response);
      setSuccess(true);
      setLoading(false);
      return response;
    } catch (err) {
      setSuccess(false);
      console.log({ err });
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (queryKey) {
      fetchData();
    }
  }, [queryKey]);

  return { data, loading, success, error, refetch: fetchData };
};

/**
 * @typedef {Object} useApi
 * @property {Object} data - The data fetched from the API
 * @property {Boolean} loading - A boolean that indicates if the data is being fetched
 * @property {Boolean} success - A boolean that indicates if the data was fetched successfully
 * @property {Object} error - An object containing the error details
 * @property {Function} refetch - A function that can be called to refetch the data
 */
export default useApi;
