import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const pickListSlice = createSlice({
  name: 'pick_list',
  initialState,
  reducers: {
    updateColorPickList(state, action) {
      state.color_pick_list = action.payload;
    }
  }
});

export const pickListActions = pickListSlice.actions;

export default pickListSlice.reducer;
