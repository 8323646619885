import { KeyboardDatePicker } from '@material-ui/pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, IconButton, TextField } from '@mui/material';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import CustomButton from '../../../../components/common/CustomButton';
import CustomDropdown from '../../../../components/common/CustomDropdown';
import CustomInputField from '../../../../components/common/StyledComponents/CustomInputField';
import { ignWorkbenchQueryApi, picklistsDataApis } from '../../../../services/ApiService'; // Add API service for fetching queries
import { GET, POST } from '../../../../services/constantService';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import CustomModalWithHeader from '../../../Commons/CustomModalWithHeader';
import { WorkBenchContext } from '../../Context';

function AddQueryModal() {
  const { addQueryModalOpen, setAddQueryModalOpen, refetchFunc } = useContext(WorkBenchContext);
  const [queries, setQueries] = useState([{ operation: '', value: '', attribute: '' }]);
  const [selectedType, setSelectedType] = useState('');
  const [description, setDescription] = useState('');
  const [workbenchType, setWorkbenchType] = useState([]);
  const [contactAttribute, setContactAttribute] = useState([]);
  const [projectAttribute, setProjectAttribute] = useState([]);
  const [operationType, setOperationType] = useState([]);

  // Fetch dropdown values for type and operations
  const fetchDropDownValues = async () => {
    try {
      const res = await picklistsDataApis(GET, 'WORKBENCH_TYPE');
      const queryOps = await picklistsDataApis(GET, 'QUERY_OPERATION');
      if (res?.data) {
        setWorkbenchType(res.data);
      }
      if (queryOps?.data) {
        setOperationType(queryOps?.data);
      }
    } catch (err) {
      enqueueSnackbar(err.message || 'Error fetching dropdown values', { variant: 'error' });
    }
  };

  // Fetch attributes based on selected type
  const fetchAttributeValues = async () => {
    try {
      const res = await picklistsDataApis(GET, selectedType?.toLowerCase()?.includes('contact') ? 'CONTACT_ATTRIBUTES' : 'PROJECT_ATTRIBUTES');
      if (res?.data) {
        selectedType?.includes('contact') ? setContactAttribute(res.data) : setProjectAttribute(res.data);
      }
    } catch (err) {
      enqueueSnackbar(err.message || 'Error fetching dropdown values', { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchAttributeValues();
  }, [selectedType]);

  useEffect(() => {
    fetchDropDownValues();
  }, []);

  const handleAddQuery = () => {
    setQueries([...queries, { operation: '', value: '', attribute: '' }]);
  };

  const handleChange = (index, field, value) => {
    const updatedQueries = [...queries];
    updatedQueries[index][field] = value;
    setQueries(updatedQueries);
  };

  const handleTypeChange = value => {
    setSelectedType(value);
  };

  const handleAttributeChange = (value, index) => {
    handleChange(index, 'attribute', value.field_value);
  };

  const handleOperationChange = (value, index) => {
    handleChange(index, 'operation', value.long_desc);
  };

  const handleDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const resetForm = () => {
    setQueries([{ operation: '', value: '', attribute: '' }]);
    setSelectedType('');
    setDescription('');
  };

  const onClose = () => {
    resetForm();
    setAddQueryModalOpen(false);
  };

  const handleSave = async () => {
    if (!selectedType?.trim()) {
      enqueueSnackbar('Type is required.', { variant: 'error' });
      return;
    }

    if (!description?.trim()) {
      enqueueSnackbar('Description is required.', { variant: 'error' });
      return;
    }

    const hasEmptyFields = queries.some(query => !query.attribute || !query.operation || !query.value);
    if (hasEmptyFields) {
      enqueueSnackbar('All fields in each query must be filled out.', { variant: 'error' });
      return;
    }

    const payload = {
      type: selectedType,
      description,
      attributes: queries.map(query => ({
        attribute: query.attribute,
        operation: query.operation,
        value: query.value
      }))
    };
    try {
      await ignWorkbenchQueryApi(POST, payload);
      resetForm();
      refetchFunc();
      setAddQueryModalOpen(false);
    } catch (err) {
      enqueueSnackbar(err.message || 'Error saving query', { variant: 'error' });
    }
  };

  const handleDeleteQuery = index => {
    setQueries(queries.filter((_, i) => i !== index));
  };

  const findDropdownKey = (currentValue, dropdownValue, fieldMatch) => {
    const matchedValue = dropdownValue.filter(val => val[fieldMatch] === currentValue);
    return matchedValue[0];
  };
  const attributeDropdownSelected = selectedType?.includes('contact') ? contactAttribute : projectAttribute;
  return (
    <CustomModalWithHeader label={t('Query List')} closeIcon={true} isOpen={addQueryModalOpen} onClose={onClose} maxWidth='md'>
      <div style={{ marginTop: '24px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomDropdown label={t(`${TRANS_KEYS.LIST_TYPE}:key`)} options={workbenchType} value={selectedType} onChange={(e, value) => handleTypeChange(value.short_desc)} fullWidth required />
          </Grid>

          <Grid item xs={12}>
            <TextField label={t('Description')} value={description} onChange={handleDescriptionChange} fullWidth variant='outlined' multiline rows={2} required />
          </Grid>

          {queries.map((query, index) => (
            <Grid container item spacing={2} key={index}>
              <Grid item xs={3}>
                <CustomDropdown
                  label={t('Attributes')}
                  options={attributeDropdownSelected}
                  value={findDropdownKey(query.attribute, attributeDropdownSelected, 'long_desc')?.short_desc}
                  onChange={(e, value) => handleAttributeChange(value, index)}
                  getOptionLabel={option => option.short_desc}
                  required
                  className={'w-100'}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomDropdown
                  label={t('Operations')}
                  options={operationType}
                  value={findDropdownKey(query.operation, operationType)}
                  onChange={(e, value) => handleOperationChange(value, index)}
                  getOptionLabel={option => option.short_desc}
                  required
                  className={'w-100'}
                />
              </Grid>
              <Grid item xs={4}>
                {query.attribute === 'projected_start_date' || query.attribute === 'start_date' ? (
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    format='MM/dd/yyyy'
                    label={t('Value')}
                    value={query.value || null}
                    onChange={newValue => handleChange(index, 'value', newValue)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    fullWidth
                    required
                  />
                ) : (
                  <CustomInputField label={t('Value')} value={query.value} onChange={e => handleChange(index, 'value', e.target.value)} fullWidth required />
                )}
              </Grid>
              <Grid item xs={1}>
                <IconButton aria-label='delete' onClick={() => handleDeleteQuery(index)} disabled={queries.length === 1}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}

          <Grid item xs={12}>
            <div className='w-100 d-flex justify-content-end align-items-center mt-4'>
              <CustomButton type='tertiary-error' variant='text' buttonText={t(`${TRANS_KEYS.CONTACTS_CANCEL}:key`)} onClick={onClose} style={{ marginRight: '12px' }} />
              <CustomButton buttonText={t('Add More')} onClick={handleAddQuery} style={{ marginRight: '12px' }} />
              <CustomButton type='btn btn-success' buttonText={t('Save')} onClick={handleSave} />
            </div>
          </Grid>
        </Grid>
      </div>
    </CustomModalWithHeader>
  );
}

export default AddQueryModal;
