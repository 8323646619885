//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { GET } from '../../services/constantService';
import { picklistsDataApi } from '../../services/ApiService';

export default function ReasonSelection(props) {
  const [options, setOptions] = useState([]);
  const { label = '', placeholder = '', ...rest } = props;

  useEffect(() => {
    const fetchSearchStages = async () => {
      const sub_route = 'candidatestages';
      let { status, data } = await picklistsDataApi(GET, '', sub_route);
      if (status && status === 200) {
        const reasons = new Set();
        data.forEach(stage => {
          if (stage.reason) {
            stage.reason.forEach(item => {
              reasons.add(item);
            });
          }
        });
        localStorage.setItem('reasons', JSON.stringify(Array.from(reasons)));
        setOptions(Array.from(reasons));
      }
    };
    const reasons = JSON.parse(localStorage.getItem('reasons'));
    if (Array.isArray(reasons)) {
      setOptions(reasons);
    } else {
      fetchSearchStages().then(null);
    }
  }, []);

  return <Autocomplete {...rest} options={options} renderInput={params => <TextField {...params} label={label} placeholder={placeholder} />} />;
}

ReasonSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string
};
