//-----------------------------------------------------------// Internal Imports // ------------------------------
import { API, CANDIDATE, DELETE, ERROR_MESSAGE, GET, IGN_API, IGN_PUBLIC_API, PATCH, POST, PUT, SFPA_SUB_ROUTE } from './constantService';
import httpService from './httpService';

export const acquireLockApi = async (id, body = {}) => {
  try {
    return await httpService.post(`${API.acquire_lock}/${id}`, body);
  } catch (e) {
    console.log('Error found in acquireLockApi::', e);
  }
};

export const contactDataRefreshApi = async body => {
  try {
    return await httpService.post(`${API.contact_data_refresh}`, body);
  } catch (e) {
    console.log('Error found in contactDataRefreshApi::', e);
  }
};

export const pdlContactMergeApi = async body => {
  try {
    return await httpService.patch(`${API.pdl_contact_data_merge}`, body);
  } catch (e) {
    console.log('Error found in pdlContactMergeApi::', e);
  }
};

export const releaseLockApi = async (id, body = {}) => {
  try {
    let url = `${API.release_lock}`;
    if (id) {
      url = `${url}/${id}`;
    }
    return await httpService.post(url, body);
  } catch (e) {
    console.log('Error found in releaseLockApi::', e);
  }
};

export const renewLockApi = async id => {
  try {
    return await httpService.post(`${API.renew_lock}/${id}`);
  } catch (e) {
    console.log('Error found in renewLockApi::', e);
  }
};

export const searchDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${API.search}/${id}/${sub_route}`) : await httpService.get(`${API.search}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${API.search}/${id}/${sub_route}`, body) : await httpService.put(`${API.search}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${API.search}/${id}/${sub_route}`, body) : await httpService.delete(`${API.search}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${API.search}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${API.search}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${API.search}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.search}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = await httpService.patch(`${API.search}/${id}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in searchDataApi::', e);
  }
};

export const LogCandidateActivityAPI = async (methodName, id = '', body = {}) => {
  try {
    if (methodName === POST) {
      const result = await httpService.post(`${IGN_API.create_log_an_activity}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = await httpService.patch(`${IGN_API.update_log_an_activity}/${id}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in LogActivityAPI::', e);
  }
};

export const BDDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.business_developments}/${id}`) : await httpService.get(`${IGN_API.business_developments}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${API.search}/${id}/${sub_route}`, body) : await httpService.put(`${API.search}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${API.search}/${id}/${sub_route}`, body) : await httpService.delete(`${API.search}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.business_developments}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.business_developments}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.business_developments}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.business_developments}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = await httpService.patch(`${IGN_API.business_developments}/${sub_route}/${id}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in searchDataApi::', e);
  }
};
export const LOEInfoDataApi = async (id = '') => {
  try {
    const result = id?.length > 0 ? await httpService.get(`${IGN_API.business_developments_loe}/${id}`) : await httpService.get(`${IGN_API.business_developments}`);
    return result;
  } catch (e) {
    console.log('Error found in searchDataApi::', e);
  }
};

export const BillingInfoApi = async (methodName, body = {}, sub_route = '') => {
  try {
    if (methodName === POST) {
      const result = await httpService.post(`${IGN_API.business_developments}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in searchDataApi::', e);
  }
};

export const StatusApprovalApi = async (methodName, body = {}, sub_route = '') => {
  try {
    if (methodName === PATCH) {
      const result = await httpService.patch(`${IGN_API.business_developments}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in searchDataApi::', e);
  }
};

export const invoiceSummaryApiIGN = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.invoice_summary}/?id=${id}`) : await httpService.get(`${IGN_API.invoice_summary}/${sub_route}`);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.invoice_summary}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.invoice_summary}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.invoice_summary}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.invoice_summary}/${sub_route}`, body);
      return result;
    }
  } catch (error) {
    console.log('error', error);
  }
};
export const userDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${API.users}/${id}/${sub_route}`) : await httpService.get(`${API.users}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${API.users}/${id}/${sub_route}`, body) : await httpService.put(`${API.users}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${API.users}/${id}/${sub_route}`, body) : await httpService.delete(`${API.users}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${API.users}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${API.users}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${API.users}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.users}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in userDataApi::', e);
  }
};

export const candidateDataApi = async (methodName, id = '', body = {}, sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${API.candidate}/${id}/${sub_route}`) : await httpService.get(`${API.candidate}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${API.candidate}/${id}/${sub_route}`, body) : await httpService.put(`${API.candidate}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${API.candidate}/${id}/${sub_route}`, body) : await httpService.delete(`${API.candidate}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result = id?.length > 0 ? await httpService.post(`${API.candidate}/${id}/${sub_route}`, body) : await httpService.post(`${API.candidate}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = id?.length > 0 ? await httpService.patch(`${API.candidate}/${id}/${sub_route}`, body) : await httpService.patch(`${API.candidate}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in candidateDataApi::', e);
  }
};

/**
 * @param {string} methodName
 * @param {string} id
 * @param {string} sub_route
 * @param {object} body
 */
export const activityInfoApi = async (methodName, id = '', sub_route = '', body = {}) => {
  try {
    if (methodName === GET && !id && sub_route) {
      return await httpService.get(`${API.activities}/${sub_route}`);
    }
    if (methodName === GET) {
      const result = await httpService.get(`${API.activities}/${id}/${sub_route}`);
      return result;
    }
    if (methodName === PUT && !id && sub_route) {
      const result = await httpService.put(`${API.activities}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST && !id && sub_route) {
      const result = await httpService.post(`${API.activities}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    throw new Error('Error found in activityInfoApi::', e);
  }
};
export const invoiceSummaryApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  if (methodName === POST) {
    const result =
      responseType?.length > 0
        ? id?.length > 0
          ? await httpService.post(`${API.invoice_summary_export}/${id}/${sub_route}`, body, responseType)
          : await httpService.post(`${API.invoice_summary_export}/${sub_route}`, body, responseType)
        : id?.length > 0
          ? await httpService.post(`${API.invoice_summary_export}/${id}/${sub_route}`, body, responseType)
          : await httpService.post(`${API.invoice_summary_export}/${sub_route}`, body);
    return result;
  }
};

export const contactDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${API.contact}/${id}/${sub_route}`) : await httpService.get(`${API.contact}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${API.contact}/${id}/${sub_route}`, body) : await httpService.put(`${API.contact}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${API.contact}/${id}/${sub_route}`, body) : await httpService.delete(`${API.contact}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${API.contact}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${API.contact}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${API.contact}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.contact}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};
export const getGalaxyAndPdlContactDataApi = async id => {
  // TODO : delete code from full project related to this, but not the route
  try {
    return await httpService.get(`${API.pdl_contact_comparison}?contactId=${id}`);
  } catch (e) {
    console.log('Error found in contactDataRefreshApi::', e);
  }
};

export const candidateContactDataApi = async (methodName, id = '', type, body = {}, sub_route = '') => {
  try {
    if (type === CANDIDATE) {
      return await candidateDataApi(methodName, id, body, sub_route);
    } else {
      return await contactDataApi(methodName, id, body, sub_route);
    }
  } catch (e) {
    console.log('Error found in candidateContactDataApi::', e);
  }
};
export const candidateSuiteDataApi = async (methodName, sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${API.candidatesuite}/${sub_route}`);
      return result;
    }
  } catch (e) {
    console.log('Error found in candidateSuiteDataApi::', e);
  }
};

export const workbenchDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${API.workbench}/${id}/${sub_route}`) : await httpService.get(`${API.workbench}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${API.workbench}/${id}/${sub_route}`, body) : await httpService.put(`${API.workbench}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${API.workbench}/${id}/${sub_route}`, body) : await httpService.delete(`${API.workbench}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${API.workbench}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${API.workbench}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${API.workbench}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.workbench}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in workbenchDataApi::', e);
  }
};
export const ProductSettingsApi = async (methodName, body = {}, sub_route = '', id = '', responseType = '') => {
  try {
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${API.product_setting}/${id}/${sub_route}`, body) : await httpService.delete(`${API.product_setting}/${sub_route}`, body);
      return result;
    }
    if (methodName === PUT) {
      const result = await httpService.put(`${API.product_setting}/${sub_route}/${id}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType.length > 0
          ? id.length > 0
            ? await httpService.post(`${API.product_setting}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${API.product_setting}/${sub_route}`, body, responseType)
          : id.length > 0
            ? await httpService.post(`${API.product_setting}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${API.product_setting}/${sub_route}`, body);
      return result;
    }

    if (methodName === GET) {
      return await httpService.get(`${API.product_setting}/${sub_route}`);
    }
  } catch (e) {
    console.log('Error found in ProductSettingsApi::', e);
  }
};
export const SfpaSettingsApi = async (methodName, body = {}, sub_route = '', id = '', responseType = '') => {
  try {
    if (methodName === DELETE) {
      const result = await httpService.delete(`${API.sfpa_setting}/${sub_route}`, body);

      return result;
    }
    if (methodName === PUT) {
      const result = await httpService.put(`${API.sfpa_setting}/${sub_route}/${id}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${API.sfpa_setting}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${API.sfpa_setting}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${API.sfpa_setting}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.sfpa_setting}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in SfpaSettingsApi::', e);
  }
};

export const companyDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${API.companies}/${id}/${sub_route}`) : await httpService.get(`${API.companies}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${API.companies}/${id}/${sub_route}`, body) : await httpService.put(`${API.companies}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${API.companies}/${id}/${sub_route}`, body) : await httpService.delete(`${API.companies}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${API.companies}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${API.companies}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${API.companies}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.companies}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in companyDataApi::', e);
  }
};

export const userSearchDataApi = async methodName => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${API.user_search}`);
    }
  } catch (e) {
    console.log('Error found in userSearchDataApi::', e);
  }
};

export const currencyInfoApi = async (methodName, code) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.picklist_value}/currency-details?code=${code}`);
    }
  } catch (e) {
    console.log('Error found in currencyInfoApi::', e);
  }
};

export const picklistsDataApi = async (methodName, id = '', sub_route = '') => {
  try {
    if (methodName === GET) {
      const result =
        id?.length > 0
          ? sub_route?.length > 0
            ? await httpService.get(`${API.picklists}/${id}/${sub_route}`)
            : await httpService.get(`${API.picklists}/${id}`)
          : await httpService.get(`${API.picklists}/${sub_route}`);
      return result;
    }
  } catch (e) {
    console.log('Error found in picklistsDataApi::', e);
  }
};

export const messageDataApi = async (methodName, sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${API.message_picklist}/${sub_route}`);
      return result;
    }
  } catch (e) {
    console.log('Error found in picklistsDataApi::', e);
  }
};

export const revenueMarketDataApi = async methodName => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${API.product_setting}/revenue_threshold`);
      return result;
    }
  } catch (e) {
    console.log('Error found in picklistsDataApi::', e);
  }
};

export const ignRevenueMarketDataApi = async methodName => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${IGN_API.revenue_threshold}/all-revenue-threshold`);
      return result;
    }
  } catch (e) {
    console.log('Error found in picklistsDataApi::', e);
  }
};

export const picklistsCountryStateDataApi = async (methodName, sub_route = '') => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${API.picklists_country_states}${sub_route}`);
    }
  } catch (e) {
    console.log('Error found in picklistsCountryStateDataApi::', e);
  }
};

export const picklistsTypeDataApi = async (methodName, sub_route = '') => {
  try {
    if (methodName === GET) {
      const url = `${API.type_list}/${sub_route}`;
      return await httpService.get(url);
    }
  } catch (e) {
    console.log('Error found in picklistsCountryStateDataApi::', e);
  }
};

export const picklistsColorsDataApi = async (methodName, id = '', body = {}, sub_route = '', dispatchColorPickList) => {
  if (methodName === GET) {
    try {
      let url;
      if (id) {
        url = `${API.picklists_colors}/${id}${sub_route}`;
      } else {
        url = `${API.picklists_colors}`;
      }
      const result = await httpService.get(url);
      dispatchColorPickList && dispatchColorPickList(result.data);
      return result;
    } catch (e) {
      console.log('Error found in picklistsColorsDataApi::', e);
    }
  }
  if (methodName === PUT) {
    try {
      const result = id?.length > 0 ? await httpService.put(`${API.picklists_colors}/${id}${sub_route}`, body) : await httpService.put(`${API.picklists_colors}${sub_route}`, body);
      return result;
    } catch (e) {
      console.log('Error found in picklistsColorsDataApi::', e);
    }
  }
};

export const picklistsSearchDataApi = async (methodName, id = '', sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${API.picklists_search}/${id}/${sub_route}`) : await httpService.get(`${API.picklists_search}/${sub_route}`);
      return result;
    }
  } catch (e) {
    console.log('Error found in picklistsSearchDataApi::', e);
  }
};

export const clientFeedbackDataApi = async (methodName, id = '', body = {}, sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${API.client_feedback}/${id}/${sub_route}`) : await httpService.get(`${API.client_feedback}/${sub_route}`);
      return result;
    }
    if (methodName === POST) {
      const result = id?.length > 0 ? await httpService.post(`${API.client_feedback}/${id}/${sub_route}`, body) : await httpService.post(`${API.client_feedback}/${sub_route}`, body);
      return result;
    }
    if (methodName === PUT) {
      const result = sub_route?.length > 0 ? await httpService.put(`${API.client_feedback}/${sub_route}/${id}`, body) : await httpService.put(`${API.client_feedback}/${id}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in clientFeedbackDataApi::', e);
  }
};

export const hlDataApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${API.hl}/${sub_route}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.hl}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in hlDataApi::', e);
  }
};

export const activitiesDataApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${API.activities}?${sub_route}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.activities}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${API.activities}/${sub_route}`);
    }
  } catch (e) {
    console.log('Error found in activitiesDataApi::', e);
  }
};

export const loeDataApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      if (sub_route) {
        return await httpService.get(`${IGN_API.loe}/${sub_route}`);
      }
      return await httpService.get(`${IGN_API.loe}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${IGN_API.loe}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${IGN_API.loe}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${IGN_API.loe}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in rolesApi::', e);
  }
};

export const appSecurityApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${API.appSecurity}/${sub_route}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.appSecurity}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${API.appSecurity}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.appSecurity}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in appSecurityApi::', e);
  }
};

export const authApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.auth}/${sub_route}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${IGN_API.auth}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${IGN_API.auth}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${IGN_API.auth}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in auth::', e);
  }
};

export const userSecurityApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${API.userSecurity}/${sub_route}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.userSecurity}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${API.userSecurity}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.userSecurity}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in userSecurityApi::', e);
  }
};

export const rolesApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      if (sub_route) {
        return await httpService.get(`${API.roleSecurity}/${sub_route}`);
      }
      return await httpService.get(`${API.roleSecurity}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.roleSecurity}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${API.roleSecurity}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.roleSecurity}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in rolesApi::', e);
  }
};

export const userRolesApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      if (sub_route) {
        return await httpService.get(`${API.userRoles}/${sub_route}`);
      }
      return await httpService.get(`${API.userRoles}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.userRoles}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${API.userRoles}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.userRoles}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in rolesApi::', e);
  }
};

export const userRolesResourceApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      if (sub_route) {
        return await httpService.get(`${API.userRolesResources}/${sub_route}`);
      }
      return await httpService.get(`${API.userRolesResources}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.userRolesResources}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${API.userRolesResources}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.userRolesResources}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in rolesApi::', e);
  }
};

export const resourcesApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      if (sub_route) {
        return await httpService.get(`${API.resources}/${sub_route}`);
      }
      return await httpService.get(`${API.resources}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.resources}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${API.resources}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.resources}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in resourcesApi::', e);
  }
};

export const ignUsersApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      if (sub_route) {
        return await httpService.get(`${API.ignUser}/${sub_route}`);
      }
      return await httpService.get(`${API.ignUser}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.ignUser}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${API.ignUser}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.ignUser}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in ignUsersKsAPI::', e);
  }
};

export const roleResourcesApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      if (sub_route) {
        return await httpService.get(`${API.roleResources}/${sub_route}`);
      }
      return await httpService.get(`${API.roleResources}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.roleResources}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${API.roleResources}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.roleResources}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in roleResourcesApi::', e);
  }
};

export const gptDataApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      if (sub_route) {
        return await httpService.get(`${IGN_API.gpt}/${sub_route}`);
      }
      return await httpService.get(`${IGN_API.gpt}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${IGN_API.gpt}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${IGN_API.gpt}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${IGN_API.gpt}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in gptDataApi::', e);
  }
};

export const echoSenseApi = async (methodName, sub_route = '', body = {}, headers = {}) => {
  try {
    if (methodName === GET) {
      if (sub_route) {
        return await httpService.get(`${IGN_API.echoSense}/${sub_route}`);
      }
      return await httpService.get(`${IGN_API.echoSense}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${IGN_API.echoSense}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${IGN_API.echoSense}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${IGN_API.echoSense}/${sub_route}`, body, headers);
    }
  } catch (e) {
    console.log('Error found in echoSenseApi::', e);
  }
};

export const emailTemplatesApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      if (sub_route) {
        return await httpService.get(`${API.emailTemplates}/${sub_route}`);
      }
      return await httpService.get(`${API.emailTemplates}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.messageTemplates}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${API.messageTemplates}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.emailTemplates}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in emailTemplatesAPI::', e);
  }
};
export const tagSetupApi = async (methodName, body, sub_route) => {
  if (methodName === GET) {
    if (sub_route) {
      return await httpService.get(`${API.tagCount}/${sub_route}`);
    }
    return await httpService.get(`${API.tagSetup}`);
  }
  if (methodName === POST) {
    return await httpService.post(`${API.tagSetup}`, body);
  }
  if (methodName === PUT) {
    return await httpService.put(`${API.tagSetup}/${sub_route}`, body);
  }
  if (methodName === DELETE) {
    return await httpService.delete(`${API.tagCount}/${sub_route}`);
  }
};

export const messageTemplatesApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      if (sub_route) {
        return await httpService.get(`${API.messageTemplates}/${sub_route}`);
      }
      return await httpService.get(`${API.messageTemplates}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.messageTemplates}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${API.messageTemplates}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.messageTemplates}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in messageTemplates::', e);
  }
};

export const messageTemplateDataApi = async (methodName, sub_route = '', params) => {
  try {
    if (methodName === GET && sub_route) {
      return await httpService.get(`${API.contextFields}/${sub_route}`, params);
    }
  } catch (e) {
    console.log('Error found in messageTemplateDataApi::', e);
  }
};

export const searchDocumentApi = async (methodName, sub_route = '', params = {}, customHeaders = {}, responseType = '', body) => {
  try {
    if (methodName === GET) {
      const result = responseType ? await httpService.get(`${API.searches}/${sub_route}`, params, customHeaders, responseType) : await httpService.get(`${API.searches}/${sub_route}`);
      return result;
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${API.searches}/${sub_route}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.searches}/${sub_route}`, body);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.searches}/${sub_route}`);
    }
  } catch (e) {
    console.log('Error found in searchDocumentApi::', e);
  }
};

export const bdDataApi = async (methodName, id = '', body = {}) => {
  try {
    if (methodName === PUT) {
      return await httpService.put(`${API.business_developments}/${id}`, body);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.business_developments}`, body);
    }
  } catch (e) {
    console.log('Error found in bdDataApi::', e);
  }
};

export const contactReferencesApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${API.contacts}/${sub_route}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.contacts}/${sub_route}`, body);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.contacts}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in contactReferencesApi::', e);
  }
  if (methodName === DELETE) {
    try {
      const result = await httpService.delete(`${API.contacts}/${sub_route}`);
      return result;
    } catch (e) {
      console.log('Error found in contactReferencesApi::', e);
    }
  }
};

export const offLimitsContactApi = async (methodName, body = {}) => {
  try {
    if (methodName === POST) {
      return await httpService.post(`${API.offlimits}`, body);
    }
  } catch (e) {
    console.log('Error found in offLimitsContactApi::', e);
  }
};

export const userGridApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      const data = await httpService.get(`${API.user_grid}?${sub_route}`);
      if (data.data.data.settings === '[]') {
        data.data.data.settings = null;
      }
      return data.data;
    }
    if (methodName === POST) {
      if (body.settings === '[]') {
        return;
      }
      return await httpService.post(`${API.user_grid}`, body);
    }
  } catch (e) {
    console.log('Error found in userGridApi::', e);
  }
};

export const notesAttachmentDocumentApi = async (methodName, url, data = '', id = '', responseType = '') => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${url}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${url}/${id}`, data);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${url}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${url}`, data, responseType);
    }
  } catch (e) {
    console.log('Error found in notesAttachmentDocumentApi::', e);
  }
};

export const athenaInviteApi = async (sub_route = '', body = {}) => {
  try {
    return await httpService.post(`${API.athena}/${sub_route}`, body);
  } catch (e) {
    console.log('Error found in athenaInviteApi::', e);
  }
};

export const masterSearchApi = async (sub_route = '') => {
  try {
    return await httpService.get(`${API.master_search}/${sub_route}`);
  } catch (e) {
    console.log('Error found in masterSearchApi::', e);
  }
};

export const globalSearchApi = async (body = {}) => {
  try {
    return await httpService.post(`${API.global_search}`, body);
  } catch (e) {
    console.log('Error found in globalSearchApi::', e);
  }
};

export const globalSearchFiltersApi = async (methodName, body = {}, params = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${API.global_search_filters}`, params);
    }
  } catch (e) {
    console.log('Error found in globalSearchApi::', e);
  }
};

export const contactEducationApi = async (methodName, sub_route = '', body = {}, params = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${API.contact_education}${sub_route}`, params);
    }
  } catch (e) {
    console.log('Error found in globalSearchApi::', e);
  }
};

export const clientsuiteInviteApi = async (sub_route = '', body = {}) => {
  try {
    return await httpService.post(`${API.clientsuite}/${sub_route}`, body);
  } catch (e) {
    console.log('Error found in clientsuiteInviteApi::', e);
  }
};

export const clientsuiteDataApi = async (methodName, sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${API.clientsuite}/${sub_route}`);
      return result;
    }
  } catch (e) {
    console.log('Error found in clientsuiteDataApi::', e);
  }
};

export const synclinkDataApi = async (methodName, sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${API.synclink}/${sub_route}`);
      return result;
    }
  } catch (e) {
    console.log('Error found in clientsuiteDataApi::', e);
  }
};

export const reportsDataApi = async (methodName, sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${API.reports}/${sub_route}`);
      return result;
    }
  } catch (e) {
    console.log('Error found in reportsDataApi::', e);
  }
};

export const deletePopupApi = async (url = '', body = {}) => {
  try {
    return await httpService.delete(`${url}`, body);
  } catch (e) {
    console.log('Error found in deletePopupApi::', e);
  }
};

export const getMethodWithCancelTokenApi = async (url, params = {}, customHeaders = {}, responseType = 'json', cancelToken) => {
  try {
    return await httpService.get(url, params, customHeaders, responseType, cancelToken);
  } catch (e) {
    console.log('Error found in getMethodWithCancelTokenApi::', e);
  }
};

export const workbenchAdvanceSelectorApi = async (methodName, url, body = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${url}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${url}`, body);
    }
  } catch (e) {
    console.log('Error found in workbenchAdvanceSelectorApi::', e);
  }
};

export const allCandidateListApi = async (methodName, url, sub_route = '') => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${url}?${sub_route}`);
    }
  } catch (e) {
    console.log('Error found in allCandidateListApi::', e);
  }
};

export const dashboardDataApi = async (methodName, url, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${url}?${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${url}`, body);
    }
    if (methodName === PUT) {
      return await httpService.put(`${url}`, body);
    }
  } catch (e) {
    console.log('Error found in dashboardDataApi::', e);
  }
};

export const productOneApi = async (methodName, body = {}, sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${API.product_one}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = await httpService.put(`${API.product_one}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result = await httpService.post(`${API.product_one}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = await httpService.patch(`${API.product_one}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in productOneApi::', e);
  }
};

export const notificationsApi = async (methodName, body = {}, sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${IGN_API.notifications}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = await httpService.put(`${IGN_API.notifications}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result = await httpService.post(`${IGN_API.notifications}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = await httpService.patch(`${IGN_API.notifications}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in productOneApi::', e);
  }
};

export const ignContactDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.contact}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.contact}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.contact}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.contact}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.contact}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.contact}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = id?.length > 0 ? await httpService.patch(`${IGN_API.contact}/${id}/${sub_route}`, body) : await httpService.patch(`${IGN_API.contact}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.contact}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.contact}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.contact}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.contact}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log(ERROR_MESSAGE.GET_SELECTED_CONTACT_ERROR, e);
  }
};
export const ignCompanyDataApi = async (methodName, body = {}, id = '', sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.company}/${id}/${sub_route}`) : sub_route ? `${IGN_API.company}/${sub_route}` : await httpService.get(`${IGN_API.company}/`);
      return result;
    }
    if (methodName === POST) {
      const result =
        id?.length > 0
          ? await httpService.post(`${IGN_API.company}/${id}/${sub_route}`, body)
          : sub_route
            ? await httpService.post(`${IGN_API.company}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.company}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = await httpService.delete(`${IGN_API.company}/${id}`);
      return result;
    }
    if (methodName === PATCH) {
      const result = await httpService.patch(`${IGN_API.company}/${id}`, body);
      return result;
    }
  } catch (e) {
    console.log(ERROR_MESSAGE.GET_SELECTED_COMPANY_ERROR, e);
  }
};

export const picklistsDataApis = async (methodName, type) => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${IGN_API.translate_value_picklists}/${type}`);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactpicklistsDataApi::', e);
  }
};

export const picklistDropDownApis = async (methodName, type, body = {}, params = {}) => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${IGN_API.picklist_value}/${type}`, params);
      return result;
    }
    if (methodName === POST) {
      const result = await httpService.post(`${IGN_API.picklist_value}/${type}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in picklistDropDownApis::', e);
  }
};

export const fetchBrandConfigsDataApi = async (methodName, sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${IGN_API.brand_configs}/${sub_route}`);
      return result;
    }
  } catch (e) {
    console.log('Error found in picklistDropDownApis::', e);
  }
};
export const fetchTagByModule = async (methodName, sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${IGN_API.tag_by_module}${sub_route}`);
      return result;
    }
  } catch (e) {
    console.log('Error found in gettingTagByModule::', e);
  }
};

export const IgnContactFromResumeApi = async (methodName, url, body) => {
  try {
    if (methodName === POST) {
      const result = await httpService.post(`${url}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in picklistDropDownApis::', e);
  }
};

export const contactDocumentApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '', type = '') => {
  try {
    if (methodName === GET) {
      const result = type ? await httpService.get(`${IGN_API.contact}/${id}/${type}`) : await httpService.get(`${IGN_API.document_download}/${sub_route}`);
      return result;
    }
    if (methodName === DELETE) {
      const result = type ? await httpService.delete(`${IGN_API.contact}/${id}/${type}`) : await httpService.delete(`${IGN_API.contact_attachment}/${id}`);
      return result;
    }
    if (methodName === POST) {
      return await httpService.post(`${sub_route}`, body, responseType);
    }
  } catch (e) {
    console.log('Error found in picklistDropDownApis::', e);
  }
};

export const projectTagsApi = async (methodName, body = {}, params = {}, sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(IGN_API.project_tags, params);
      return result;
    }
    if (methodName === DELETE) {
      const result = await httpService.delete(IGN_API.project_tags, body);
      return result;
    }
    if (methodName === POST) {
      return await httpService.post(IGN_API.project_tags, body);
    }
    if (methodName === PATCH) {
      return await httpService.patch(IGN_API.project_tags + sub_route, body, { params });
    }
  } catch (e) {
    console.log('Error found in projectTagsApi::', e);
  }
};

export const AllDocumentsApi = async (
  methodName,
  url,
  data = '',
  //id = "",
  responseType = ''
) => {
  try {
    if (methodName == POST) {
      return await httpService.post(`${url}`, data, responseType);
    }
    if (methodName == PATCH) {
      return await httpService.patch(`${url}`, data, responseType);
    }
    if (methodName === GET) {
      return await httpService.get(`${url}`);
    }
    if (methodName == DELETE) {
      return await httpService.delete(`${url}`, data, responseType);
    }
  } catch (e) {
    console.log('Error found in notesAttachmentDocumentApi::', e);
  }
};

export const logAnActivityApi = async (methodName, id = '', body = {}, sub_route = '') => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.contact}/${sub_route}/${id}`, body);
    }
    if (methodName === POST) {
      return await httpService.post(`${IGN_API.contact}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${IGN_API.contact}/${sub_route}/${id}`);
    }
  } catch (error) {
    console.log('Error found in logAnActivityApi::', error);
  }
};

export const activityTypesApi = async (methodName, id = '', body = {}, sub_route = '') => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.contact}/${sub_route}/${id}`, body);
    }
    if (methodName === POST) {
      return await httpService.post(`${IGN_API.contact}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${IGN_API.contact}/${sub_route}/${id}`);
    }
  } catch (error) {
    throw new Error('Error found in activityTypesApi::', error);
  }
};

export const industryApi = async (methodName, sub_route = '', body = {}) => {
  try {
    if (methodName === GET) {
      if (sub_route) {
        return await httpService.get(`${IGN_API.industry}/${sub_route}`);
      }
      return await httpService.get(`${IGN_API.industry}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${IGN_API.industry}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${IGN_API.industry}/${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${IGN_API.industry}/${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in industryAPI::', e);
  }
};

export const skillDetailApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${API.skillDetails}/${id}/${sub_route}`) : await httpService.get(`${API.skillDetails}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${API.skillDetails}/${id}/${sub_route}`, body) : await httpService.put(`${API.skillDetails}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${API.skillDetails}/${id}/${sub_route}`, body) : await httpService.delete(`${API.skillDetails}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${API.skillDetails}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${API.skillDetails}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${API.skillDetails}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.skillDetails}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in SkillDetailsDataApi::', e);
  }
};

export const ProjectDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      console.log('api', `${IGN_API.project}?id=${id}`);
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.project}/?id=${id}`) : await httpService.get(`${IGN_API.project}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.project}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.project}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.project}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.project}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.project}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.project}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.project}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.project}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const CandidateColorsDataApi = async (methodName, projectId = '') => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${IGN_API.candidate_colors}/?projectId=${projectId}`);
      return result;
    }
  } catch (e) {
    console.log('Error found in CandidateColorsDataApi::', e);
  }
};

export const CandidateColorLabelDataApi = async (methodName, body) => {
  try {
    if (methodName === POST) {
      const result = await httpService.post(`${IGN_API.candidate_color_label}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in CandidateColorLabelDataApi::', e);
  }
};

export const ProjectDataBulkApi = async (methodName, body = {}) => {
  try {
    if (methodName === PUT) {
      console.log('api', `${IGN_API.project}/bulk-update`);

      const result = await httpService.put(`${IGN_API.project}/bulk-update`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in ProjectDataBulkApi::', e);
  }
};

export const ProjectGroupStageLabelApi = async (methodName, params = {}, data = {}) => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${IGN_API.project_stage_group_label}`, params);
      return result;
    }
    if (methodName === PUT) {
      const result = await httpService.put(`${IGN_API.update_stage_group_label}`, data);
      return result;
    }
  } catch (e) {
    console.log('Error found in ProjectGroupStageLabelApi::', e);
  }
};

export const PositionProfileDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.position_profile}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.position_profile}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.position_profile}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.position_profile}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.position_profile}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.position_profile}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.position_profile}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.position_profile}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.position_profile}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.position_profile}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const ProjectContactDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.project_client_contact}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.project_client_contact}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result =
        id?.length > 0 ? await httpService.put(`${IGN_API.project_client_contact}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.project_client_contact}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result =
        id?.length > 0 ? await httpService.delete(`${IGN_API.project_client_contact}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.project_client_contact}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.project_client_contact}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.project_client_contact}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.project_client_contact}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.project_client_contact}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const PicklistProjectContactDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result =
        id?.length > 0 ? await httpService.get(`${IGN_API.picklist_project_client_contact}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.picklist_project_client_contact}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result =
        id?.length > 0
          ? await httpService.put(`${IGN_API.picklist_project_client_contact}/${id}/${sub_route}`, body)
          : await httpService.put(`${IGN_API.picklist_project_client_contact}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result =
        id?.length > 0
          ? await httpService.delete(`${IGN_API.picklist_project_client_contact}/${id}/${sub_route}`, body)
          : await httpService.delete(`${IGN_API.picklist_project_client_contact}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.picklist_project_client_contact}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.picklist_project_client_contact}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.picklist_project_client_contact}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.picklist_project_client_contact}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const PicklistIndustryDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${API.industry_picklist}/${id}/${sub_route}`) : await httpService.get(`${API.industry_picklist}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${API.industry_picklist}/${id}/${sub_route}`, body) : await httpService.put(`${API.industry_picklist}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${API.industry_picklist}/${id}/${sub_route}`, body) : await httpService.delete(`${API.industry_picklist}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${API.industry_picklist}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${API.industry_picklist}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${API.industry_picklist}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.industry_picklist}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const TargetTitleDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.target_title}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.target_title}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.target_title}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.target_title}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.target_title}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.target_title}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.target_title}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.target_title}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.target_title}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.target_title}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const TargetCompanyToAvoidDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.target_companies_to_avoid}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.target_companies_to_avoid}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result =
        id?.length > 0 ? await httpService.put(`${IGN_API.target_companies_to_avoid}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.target_companies_to_avoid}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result =
        id?.length > 0
          ? await httpService.delete(`${IGN_API.target_companies_to_avoid}/${id}/${sub_route}`, body)
          : await httpService.delete(`${IGN_API.target_companies_to_avoid}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.target_companies_to_avoid}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.target_companies_to_avoid}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.target_companies_to_avoid}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.target_companies_to_avoid}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const DecisionGraphDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.decision_graph}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.decision_graph}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.decision_graph}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.decision_graph}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.decision_graph}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.decision_graph}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.decision_graph}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.decision_graph}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.decision_graph}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.decision_graph}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const ProjectSkillDetailsDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.project_skill_details}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.project_skill_details}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.project_skill_details}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.project_skill_details}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result =
        id?.length > 0 ? await httpService.delete(`${IGN_API.project_skill_details}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.project_skill_details}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.project_skill_details}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.project_skill_details}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.project_skill_details}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.project_skill_details}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const SkillDetailsDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${API.skillDetails}/${id}/${sub_route}`) : await httpService.get(`${API.skillDetails}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${API.skillDetails}/${id}/${sub_route}`, body) : await httpService.put(`${API.skillDetails}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${API.skillDetails}/${id}/${sub_route}`, body) : await httpService.delete(`${API.skillDetails}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${API.skillDetails}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${API.skillDetails}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${API.skillDetails}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.skillDetails}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const TargetIndustryDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.target_industry}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.target_industry}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.target_industry}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.target_industry}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.target_industry}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.target_industry}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.target_industry}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.target_industry}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.target_industry}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.target_industry}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const TargetCompanyDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.target_company}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.target_company}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.target_company}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.target_company}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.target_company}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.target_company}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.target_company}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.target_company}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.target_company}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.target_company}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const TargetLocationDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.target_location}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.target_location}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.target_location}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.target_location}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.target_location}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.target_location}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.target_location}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.target_location}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.target_location}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.target_location}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const ProjectEducationCertificationDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result =
        id?.length > 0 ? await httpService.get(`${IGN_API.project_education_certification}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.project_education_certification}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result =
        id?.length > 0
          ? await httpService.put(`${IGN_API.project_education_certification}/${id}/${sub_route}`, body)
          : await httpService.put(`${IGN_API.project_education_certification}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result =
        id?.length > 0
          ? await httpService.delete(`${IGN_API.project_education_certification}/${id}/${sub_route}`, body)
          : await httpService.delete(`${IGN_API.project_education_certification}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.project_education_certification}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.project_education_certification}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.project_education_certification}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.project_education_certification}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const TargetJobFunctionDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.target_job_function}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.target_job_function}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.target_job_function}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.target_job_function}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result =
        id?.length > 0 ? await httpService.delete(`${IGN_API.target_job_function}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.target_job_function}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.target_job_function}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.target_job_function}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.target_job_function}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.target_job_function}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const TargetCapitalStructureDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.target_capital_structure}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.target_capital_structure}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result =
        id?.length > 0 ? await httpService.put(`${IGN_API.target_capital_structure}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.target_capital_structure}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result =
        id?.length > 0 ? await httpService.delete(`${IGN_API.target_capital_structure}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.target_capital_structure}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.target_capital_structure}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.target_capital_structure}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.target_capital_structure}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.target_capital_structure}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const SFPADataApi = async (methodName, id = '', body = {}, sub_route = SFPA_SUB_ROUTE, responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.project}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.project}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.project}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.project}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.project}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.project}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.project}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.project}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.project}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.project}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const SFPASaveDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.sfpa_save}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.sfpa_save}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.sfpa_save}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.sfpa_save}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in SfpaSaveDataApi::', e);
  }
};

export const BulkSaveTargetDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.bulk_save_targets}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.bulk_save_targets}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.bulk_save_targets}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.bulk_save_targets}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in SfpaSaveDataApi::', e);
  }
};

export const LocationSetupApi = async (methodName, input) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.location_setup}${input}`);
    }
  } catch (e) {
    console.log('Error found in currencyInfoApi::', e);
  }
};

export const CandidateStageSetupAPi = async (methodName, sub_route, body = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.candidate_stage_setup}${sub_route}`);
    } else if (methodName === PUT) {
      return await httpService.put(`${IGN_API.candidate_stage_setup}${sub_route}`, body);
    } else if (methodName === POST) {
      return await httpService.post(`${IGN_API.candidate_stage_setup}${sub_route}`, body);
    } else if (methodName === DELETE) {
      return await httpService.delete(`${IGN_API.candidate_stage_setup}${sub_route}`);
    }
  } catch (e) {
    console.log('Error found in CandidateStageSetupAPi::', e);
  }
};

export const StageWorkflowApi = async (methodName, sub_route, body = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.stage_workflow_setup}${sub_route}`);
    } else if (methodName === PUT) {
      return await httpService.put(`${IGN_API.stage_workflow_setup}${sub_route}`, body);
    } else if (methodName === POST) {
      return await httpService.post(`${IGN_API.stage_workflow_setup}${sub_route}`, body);
    } else if (methodName === DELETE) {
      return await httpService.delete(`${IGN_API.stage_workflow_setup}${sub_route}`);
    }
  } catch (e) {
    console.log('Error found in Workflow setup APi::', e);
  }
};

export const CandidateStageGroupAPi = async (methodName, sub_route) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.candidate_stage_group}${sub_route}`);
    }
  } catch (e) {
    console.log('Error found in currencyInfoApi::', e);
  }
};

export const ClientPortalMenuSetupApi = async (methodName, sub_route) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.client_portal_menu_setup}${sub_route}`);
    }
  } catch (e) {
    console.log('Error found in currencyInfoApi::', e);
  }
};

export const ProjectCandidateApi = async (methodName, sub_route, body, params = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.project_candidate}${sub_route}`, params);
    } else if (methodName === DELETE) {
      return await httpService.delete(`${IGN_API.project_candidate}${sub_route}`);
    } else if (methodName === PATCH && sub_route === '/bulk') {
      return await httpService.patch(`${IGN_API.project_candidate}${sub_route}`, body);
    } else if (methodName === PATCH) {
      return await httpService.patch(`${IGN_API.project_candidate}${sub_route}`, body);
    } else if (methodName === POST) {
      return await httpService.post(`${IGN_API.project_candidate}${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in currencyInfoApi::', e);
  }
};

export const CustomReportTemplatesApi = async (methodName, sub_route = '', body = {}, params = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.custom_report_templates}${sub_route}`, params);
    }
    if (methodName === POST) {
      return await httpService.post(`${IGN_API.custom_report_templates}${sub_route}`, body);
    }
    if (methodName === PUT) {
      return await httpService.put(`${IGN_API.custom_report_templates}${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in CustomReportTemplatesApi::', e);
  }
};

export const TemplateGroupsApi = async (methodName, sub_route = '', body = {}, params = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.template_groups}${sub_route}`, params);
    }
    if (methodName === POST) {
      return await httpService.post(`${IGN_API.template_groups}${sub_route}`, body);
    }
    if (methodName === PUT) {
      return await httpService.put(`${IGN_API.template_groups}${sub_route}`, body);
    }
  } catch (e) {
    console.log('Error found in TemplateGroupsApi::', e);
  }
};

export const assumeRoleApi = async methodName => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.assume_role}`);
    }
  } catch (e) {
    console.log('Error found in ssumeRoleApi::', e);
  }
};

export const getNearestCenterApi = async methodName => {
  try {
    if (methodName === GET) {
      return await httpService.get('https://dns.google/resolve?name=transcribe-latency.ignyteai-test.com&type=cname');
    }
  } catch (e) {
    console.log('Error found in ssumeRoleApi::', e);
  }
};

export const sfpaDecisionApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id.length > 0 ? await httpService.get(`${API.sfpa_decision}/${id}/${sub_route}`) : await httpService.get(`${API.sfpa_decision}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id.length > 0 ? await httpService.put(`${API.sfpa_decision}/${id}/${sub_route}`, body) : await httpService.put(`${API.sfpa_decision}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id.length > 0 ? await httpService.delete(`${API.sfpa_decision}/${id}/${sub_route}`, body) : await httpService.delete(`${API.sfpa_decision}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType.length > 0
          ? id.length > 0
            ? await httpService.post(`${API.sfpa_decision}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${API.sfpa_decision}/${sub_route}`, body, responseType)
          : id.length > 0
            ? await httpService.post(`${API.sfpa_decision}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.sfpa_decision}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = await httpService.patch(`${API.sfpa_decision}/${id}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in searchDataApi::', e);
  }
};

export const CompetencyDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      console.log('api', `${IGN_API.competency}?id=${id}`);
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.competency}/?id=${id}`) : await httpService.get(`${IGN_API.competency}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.competency}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.competency}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.competency}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.competency}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.competency}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.competency}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.competency}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.competency}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};
export const ProjectCompetencyDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      console.log('api', `${IGN_API.project_competency}?id=${id}`);
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.project_competency}/?id=${id}`) : await httpService.get(`${IGN_API.project_competency}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.project_competency}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.project_competency}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.project_competency}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.project_competency}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.project_competency}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.project_competency}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.project_competency}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.project_competency}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const ProjectCandidateDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '', params = {}) => {
  try {
    if (methodName === GET) {
      console.log('api', `${IGN_API.project_candidate}?id=${id}`);
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.project_candidate}/?id=${id}`) : await httpService.get(`${IGN_API.project_candidate}/${sub_route}`, params);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.project_candidate}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.project_candidate}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.project_candidate}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.project_candidate}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.project_candidate}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.project_candidate}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.project_candidate}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.project_candidate}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in ProjectCandidateDataApi::', e);
  }
};
export const getRecentActivities = async (params = {}) => {
  try {
    const response = await httpService.get(`${IGN_API.activities_all}`, params);

    return response;
  } catch (error) {
    console.error('Error found in getRecentActivities::', error);
  }
};
export const ProjectDocumentDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    console.log(body, responseType);
    if (methodName === GET) {
      const result = await httpService.get(`${IGN_API.project_document}/${id}/${sub_route}`);
      return result;
    }
  } catch (e) {
    console.log('Error found in contactDataApi::', e);
  }
};

export const DownloadDocumentDataApi = async (methodName, id = '', sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${API.contact_attachment}/${id}`) : await httpService.get(`${API.contact_attachment}/${sub_route}`);
      return result;
    }
  } catch (e) {
    console.log('Error found in ProjectCandidateDataApi::', e);
  }
};

export const ProjectStagesDataApi = async (methodName, sub_route = '') => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.project_stage}?${sub_route}`);
    }
  } catch (e) {
    console.log('Error found in stageListApi::', e);
  }
};

export const ClientSuiteDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      console.log('api', `${IGN_API.clientSuite}?id=${id}`);
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.clientSuite}/?id=${id}`) : await httpService.get(`${IGN_API.clientSuite}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.clientSuite}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.clientSuite}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.clientSuite}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.clientSuite}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.clientSuite}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.clientSuite}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.clientSuite}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.clientSuite}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in ProjectCandidateDataApi::', e);
  }
};
export const getProjectAndBdCount = async () => {
  try {
    const response = await httpService.get(IGN_API.projectBdCount);

    return response;
  } catch (error) {
    console.error('Error found in getRecentActivities::', error);
  }
};

export const clientPortalMenuSetupApi = async (methodName, sub_route, data) => {
  if (methodName === POST) {
    return await httpService.post(`${IGN_API.clientPortalMenuSetup}/${sub_route}`, data);
  } else if (methodName === GET) {
    return await httpService.get(`${IGN_API.clientPortalMenuSetup}/${sub_route}`);
  }
};

export const candidateStageGroupApi = async (methodName, sub_route, data) => {
  if (methodName === POST) {
    return await httpService.post(`${IGN_API.candidateStageGroup}/${sub_route}`, data);
  } else if (methodName === GET) {
    return await httpService.get(`${IGN_API.candidateStageGroup}/${sub_route}`);
  } else if (methodName === DELETE) {
    return await httpService.delete(`${IGN_API.candidateStageGroup}/${sub_route}`, data);
  }
};
export const requestExtendedSearchApi = async body => {
  try {
    const response = await httpService.post(IGN_API.product_one_extended_search, body);
    return response;
  } catch (error) {
    console.error('Error found in requestExtendedSearchApi::', error);
  }
};
export const requestQuickSearchApi = async body => {
  try {
    const response = await httpService.post(IGN_API.product_one_quick_search, body);
    return response;
  } catch (error) {
    console.error('Error found in requestQuickSearchApi::', error);
  }
};

export const ProjectStageGroupColor = async (methodName, sub_route, body) => {
  if (methodName === GET) {
    return await httpService.get(`${IGN_API.project_stage_group_color}/${sub_route}`);
  } else if (methodName === POST) {
    return await httpService.post(`${IGN_API.project_stage_group_color}/${sub_route}`, body);
  }
};

export const ignAthenaDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.athena}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.athena}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.athena}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.athena}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.athena}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.athena}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = id?.length > 0 ? await httpService.patch(`${IGN_API.athena}/${id}/${sub_route}`, body) : await httpService.patch(`${IGN_API.athena}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.athena}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.athena}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.athena}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.athena}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log(ERROR_MESSAGE.GET_SELECTED.CONTACT_ERROR, e);
  }
};

export const UsersDataApi = async (methodName, id = '', sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.users}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.users}/${sub_route}`);
      return result;
    }
  } catch (error) {
    console.error(error);
  }
};
export const ClientDispositionMap = async (methodName, sub_route, body) => {
  if (methodName === GET) {
    return await httpService.get(`${IGN_API.clientDispositionMap}/${sub_route}`);
  } else if (methodName === POST) {
    return await httpService.post(`${IGN_API.clientDispositionMap}/${sub_route}`, body);
  } else if (methodName === DELETE) {
    return await httpService.delete(`${IGN_API.clientDispositionMap}/${sub_route}`, body);
  }
};

export const FitScoreDataApi = async (methodName, sub_route, body) => {
  if (methodName === GET) {
    return await httpService.get(`${IGN_API.activities_fit_data}${sub_route}`);
  } else if (methodName === POST) {
    return await httpService.post(`${IGN_API.activities_fit_data}/${sub_route}`, body);
  }
};

export const GetCandidateTagsApi = async () => {
  return await httpService.get(`${IGN_API.getCandidateTags}`);
};

export const BulkCandidateCreateApi = async body => {
  return await httpService.post(`${IGN_API.bulkCandidateCreate}`, body);
};
export const getUserIp = async () => {
  return await httpService.get(`${IGN_PUBLIC_API.getIp}`);
};

export const getTranslationUploadUrl = async sub_route => {
  return await httpService.get(`${IGN_API.labelConfig}${sub_route}`);
};

export const downloadHeliaReports = async payload => {
  try {
    const result = await httpService.post(`${IGN_API.helia_report}`, payload, 'blob');
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const ignWorkbenchDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.workbench}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.workbench}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.workbench}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.workbench}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.workbench}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.workbench}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = id?.length > 0 ? await httpService.patch(`${IGN_API.workbench}/${id}/${sub_route}`, body) : await httpService.patch(`${IGN_API.workbench}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.workbench}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.workbench}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.workbench}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.workbench}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log(ERROR_MESSAGE.GET_SELECTED_CONTACT_ERROR, e);
  }
};

export const ignWorkbenchQueryApi = async (methodName, body = {}, query = '') => {
  try {
    if (methodName === POST) {
      const { data: result } = await httpService.post(`${IGN_API.query}`, body);
      return result;
    }
    if (methodName === PUT) {
      const { data: result } = await httpService.put(`${IGN_API.query}/${query}`, body);
      return result;
    }
    if (methodName === GET) {
      const { data: result } = await httpService.get(`${IGN_API.query}${query}`);
      return result;
    }

    if (methodName === DELETE) {
      const { data: result } = await httpService.delete(`${IGN_API.query}${query}`);
      return result;
    }
  } catch (error) {
    console.log('error in creating ign query', error);
  }
};
export const createEducation = async payload => {
  const { status, data } = await httpService.post(`${IGN_API.degreeEducation}`, payload);
  return { status, data };
};

export const companyRelationshipApi = async (methodName, id = '', body = {}, sub_route = '') => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${IGN_API.companyRelationship}/${sub_route}/${id}`, body);
    }
    if (methodName === POST) {
      return await httpService.post(`${IGN_API.companyRelationship}/${sub_route}`, body);
    }
    if (methodName === PUT) {
      return await httpService.put(`${IGN_API.companyRelationship}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${IGN_API.companyRelationship}/${sub_route}/${id}`);
    }
  } catch (error) {
    console.log('Error found in companyRelationshipApi::', error);
  }
};

/**
 * @param {string} methodName
 * @param {object} params
 * @param {string} params.run_id
 * @param {string} params.project_id
 */
export const productOneTaskCandidateApi = async (methodName, params) => {
  try {
    if (methodName === GET) {
      const { data: result } = await httpService.get(`${IGN_API.productOneTaskCandidate}`, params);
      return result;
    }
    if (methodName === POST) {
      /**
       * @description: "This is used to add product one candidate to project pipeline "
       */
      const { data: result } = await httpService.post(`${IGN_API.productOneTaskCandidate}`, params);
      return result;
    }
    if (methodName === PATCH) {
      const { data: result } = await httpService.patch(`${IGN_API.productOneTaskCandidate}`, params);
      return result;
    }
  } catch (error) {
    console.log('error in productOneTaskCandidateApi', error);
  }
};

export const productOneTaskApi = async params => {
  try {
    const data = await httpService.get(`${IGN_API.productOneTask}`, params);

    return data;
  } catch (error) {
    console.log('error in productOneTaskApi', error);
  }
};

/* comparsion of pdl and db contact api */
export const getPdlAndContactDataApi = async id => {
  try {
    return await httpService.get(`${API.pdl_contact_comparison}?contactId=${id}`);
  } catch (e) {
    console.log('Error found in contactDataRefreshApi::', e);
  }
};

/* add  */
export const pdlDataRefreshApi = async body => {
  try {
    return await httpService.post(`${API.contact_data_refresh}`, body);
  } catch (e) {
    console.log('Error found in contactDataRefreshApi::', e);
  }
};

export const pdlContactUpdateApi = async body => {
  try {
    return await httpService.patch(`${API.pdl_contact_update}`, body);
  } catch (e) {
    console.log('Error found in pdlContactMergeApi::', e);
  }
};
/**
 * Report Template APIs
 */
export const reportsTemplateDataApi = async (methodName, sub_route = '', body = null, header = null) => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${IGN_API.reports}/${sub_route}`);
      return result;
    } else if (methodName === POST) {
      const result = await httpService.post(`${IGN_API.reports}/${sub_route}`, body, header);
      return result;
    } else if (methodName === DELETE) {
      const result = await httpService.delete(`${IGN_API.reports}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in reportsTemplateDataApi::', e);
  }
};

export const userConfigs = async (methodName, body = null) => {
  try {
    if (methodName === POST) {
      const result = await httpService.post(`${IGN_API.userConfigs}`, body);
      return result;
    } else if (methodName === PATCH) {
      const result = await httpService.patch(`${IGN_API.updateUserConfigs}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error found in userConfigs::', e);
  }
};

export const LOEGetAll = async id => {
  const result = await httpService.post(`${IGN_API.loeAll}`, {
    query: {
      project_id: id
    }
  });
  return result?.data;
};
export const fetchLOETasks = async (instanceId, email) => {
  const response = await httpService.get(IGN_API.loeTasks, { instanceId, email });
  return response.data;
};
export const fetchLOETasksList = async instanceId => {
  const response = await httpService.get(IGN_API.loeTasksList, { instanceId });
  return response.data;
};

export const updateLOE = async (loeId, updateData) => {
  const response = await httpService.put(
    IGN_API.loeUpdate,
    { loeId, ...updateData },
    {
      params: {
        id: loeId
      }
    }
  );
  return response.data;
};
