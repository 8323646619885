import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import './index.scss';
import { Box, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomConfirmationPopup from '../CustomConfirmationPopup';
import { sideScroll, handleMouseWheel } from '../../../utils/cardSliderUtil';

const CardSlider = props => {
  const { children, cardData, styleData = {} } = props;
  const { width = 400 } = styleData;
  const [prevBtn, setPrevBtn] = useState(false);
  const [nextBtn, setNextBtn] = useState(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const closeConfirmationPopup = () => {
    setOpenConfirmationPopup(false);
  };

  //   const handleEdit = () => {
  //     handleOpenPopup();
  //   };
  useEffect(() => {}, [cardData, prevBtn]);

  const [breakPoint, setBreakPoint] = useState();
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 1024) {
        setBreakPoint(width);
      } else {
        setBreakPoint(width * 2 + width / 4 - 20);
      }
    };
    handleWindowResize();
  }, []);

  const handleSlideClick = () => {
    const container = document.getElementById('slider-container');
    if (container.scrollLeft >= container.scrollWidth - container.clientWidth) {
      setNextBtn(true);
    }
    setPrevBtn(false);
    sideScroll(container, 'right', 4, breakPoint, 10);
  };

  const handleSlideBackClick = () => {
    const container = document.getElementById('slider-container');
    setNextBtn(false);
    if (container.scrollLeft === 0) {
      setPrevBtn(true);
    }
    sideScroll(container, 'left', 4, breakPoint, 10);
  };

  useEffect(() => {
    const container = document.getElementById('slider-container');
    const handleWheel = event => {
      handleMouseWheel(container, event, setNextBtn, setPrevBtn, width, 4);
    };
    container.addEventListener('wheel', handleWheel);
    return () => {
      container.removeEventListener('wheel', handleWheel);
    };
  }, [breakPoint]);
  return (
    <Container id='card-slider-component' className='m-0 pt-3 pb-3'>
      <Box className={`card-slider-container  m-0 ${cardData?.length > 1 ? 'p-1' : 'p-0'}`} id='slider-container'>
        <Box className='card-Container'>
          {children}
          {/* {cardData.map((card, cardIndex) => (
            <React.Fragment key={cardIndex}>
              <CustomCard
                data={card}
                e
                addIcon={addIcon}
                editItems={handleEdit}
                styleData={styleData}
              />
              {isArrow && cardIndex < cardData.length - 1 && (
                <Box className="pr-2 pl-2 arrowIcon">
                  <ArrowBackIcon />
                </Box>
              )}
            </React.Fragment>
          ))} */}
        </Box>
        <CustomConfirmationPopup open={openConfirmationPopup} onClose={closeConfirmationPopup} type='delete' cancelText='No' confirmText='Yes' setOpen={setOpenConfirmationPopup} />
      </Box>
      <Box className={`button-container mt-3 ${cardData?.length > 1 ? 'visible' : 'd-none'}`}>
        <IconButton id='slideBack' disabled={prevBtn} onClick={handleSlideBackClick}>
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton id='slide' disabled={nextBtn} onClick={handleSlideClick}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Container>
  );
};
CardSlider.propTypes = {
  cardData: PropTypes.object,
  isArrow: PropTypes.bool,
  addIcon: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  minWidth: PropTypes.number,
  fontSize: PropTypes.number,
  handleOpenPopup: PropTypes.func,
  styleData: PropTypes.object,
  children: PropTypes.element
};
export default CardSlider;
