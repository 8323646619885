//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { removeContactsFromWorkbench } from './Utils';
import WorkbenchAction from './WorkbenchAction';

export default function RemoveContactFromWorkbench(props) {
  const { open, contactId, onClose } = props;

  return (
    <WorkbenchAction
      title='Remove Contact From Workbench'
      type={'contact'}
      contact={contactId}
      contactIds={[contactId]}
      onClose={onClose}
      open={open}
      onSubmit={removeContactsFromWorkbench}
      buttonText='Remove From Workbench'
    />
  );
}

RemoveContactFromWorkbench.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  contactId: PropTypes.string
};
