import { ArrowDropDown, FilterList as FilterIcon } from '@mui/icons-material';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpDownCircleIcon from '@mui/icons-material/ArrowDropUp';
import { Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AddContactToProject from '../../common/AddContactToProject';
import AssignTag from './AssignTag';

import { globalSearchSelector } from '../../../selectors';
import { updateGlobalSearchData } from '../../../store/globalSearchSlice';
import { ButtonDropDown } from '../../common/ButtonDropdown';

import { useTranslation } from 'react-i18next';
import AddToWorkbench from '../../common/AddToWorkbench/index';
import '../index.scss';

const CustomHeader = props => {
  const { displayName, enableSorting, enableMenu, column, showColumnMenu, hasColumnSelector = false } = props;
  const { filterModel, gridApi, data, colId, sort } = useSelector(globalSearchSelector);
  const dispatch = useDispatch();
  const filterButtonRef = useRef(null);

  const onSortRequested = () => {
    if (enableSorting) {
      let newSortOrder;
      if (colId !== column?.colId) {
        newSortOrder = 'asc';

        dispatch(updateGlobalSearchData({ key: 'colId', data: column?.colId }));
        dispatch(updateGlobalSearchData({ key: 'sort', data: newSortOrder }));
      } else {
        newSortOrder = sort === 'asc' ? 'desc' : sort === 'desc' ? null : 'asc';
        dispatch(updateGlobalSearchData({ key: 'sort', data: newSortOrder }));
      }
      const updatedFilterModel = { ...filterModel, sortFilter: { filterType: 'text', filter: JSON.stringify({ colId: column?.colId, sort: newSortOrder, data }) } };
      const appliedFilters = gridApi?.current?.getFilterModel();
      gridApi?.current?.setFilterModel({ ...appliedFilters, ...updatedFilterModel });
    }
  };

  const onFilterClicked = e => {
    e.stopPropagation();
    if (filterButtonRef.current) {
      showColumnMenu(filterButtonRef.current);
    }
  };

  let isLastColumn = false;
  try {
    const displayedColumns = gridApi.current.columnController.allDisplayedColumns;
    const lastColumn = displayedColumns[displayedColumns.length - 1];
    isLastColumn = lastColumn.colId === column.colId;
  } catch (error) {
    isLastColumn = false;
  }

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      onClick={onSortRequested}
      className='custom-header'
      style={{
        marginRight: !hasColumnSelector ? 0 : isLastColumn ? 30 : 0,
        cursor: enableSorting ? 'pointer' : 'default'
      }}
    >
      {displayName}
      <Box display={'flex'} alignItems={'center'} gap={1}>
        {enableMenu && (
          <IconButton size='small' onClick={onFilterClicked} aria-label='filter' ref={filterButtonRef}>
            <FilterIcon fontSize='small' style={{ color: 'white' }} />
          </IconButton>
        )}
        {enableSorting && (
          <Box display={'flex'} position={'relative'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
            {(column?.colId !== colId || (column?.colId === colId && (sort === null || sort === 'desc'))) && (
              <ArrowUpDownCircleIcon fontSize='small' style={{ position: 'absolute', top: '-0.8rem', color: 'white' }} />
            )}
            {(column?.colId !== colId || (column?.colId === colId && (sort === null || sort === 'asc'))) && (
              <ArrowDropDownCircleIcon fontSize='small' style={{ position: 'absolute', top: '-0.4rem', color: 'white' }} />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const CustomAction = () => {
  const { filterModel, gridApi } = useSelector(globalSearchSelector);
  const { t } = useTranslation();

  const [selectedRows, setSelectedRows] = useState([]);
  const [showAddContactToProject, setShowAddContactToProject] = useState(false);
  const [showAddContactToWorkbench, setShowAddContactToWorkbench] = useState(false);
  const [showTagsPopup, setShowTagsPopup] = useState(false);

  const { selectedTabId } = useParams();
  const module = selectedTabId?.replace('ign-', '');

  const reloadData = () => {
    gridApi?.current?.setFilterModel(filterModel);
    gridApi?.current?.refreshInfiniteCache();
  };

  useEffect(() => {
    const handleSelectionChange = () => {
      const selected = gridApi?.current?.getSelectedRows();
      setSelectedRows(selected || []);
    };

    // Add event listener for selection changes
    gridApi?.current?.addEventListener('selectionChanged', handleSelectionChange);

    // Cleanup event listener when component unmounts or gridApi changes
    return () => {
      gridApi?.current?.removeEventListener('selectionChanged', handleSelectionChange);
    };
  }, [gridApi?.current]);

  const getOptionsByModules = () => {
    if (module === 'projects') {
      return ['Add Tags', 'Add to Workbench'];
    }
    if (module === 'contacts') {
      return ['Add to Project', 'Add Tags', 'Add to Workbench'];
    }
    if (module === 'companies') {
      return ['Add Tags'];
    }

    return [];
  };

  const getModulesIds = () => {
    switch (module) {
      case 'projects':
        return selectedRows?.map(item => item?.project_id);

      case 'contacts':
        return selectedRows?.map(item => item?.contact_id);

      case 'companies':
        return selectedRows?.map(item => item?.company_id);

      default:
        return [];
    }
  };

  const options = getOptionsByModules();

  async function handleOptionClick(option) {
    if (option === 'Add to Project') {
      setShowAddContactToProject(true);
    }
    if (option === 'Add Tags') {
      setShowTagsPopup(true);
    }
    if (option === 'Add to Workbench') {
      setShowAddContactToWorkbench(true);
    }
  }

  return (
    <Box position={'relative'} sx={{ cursor: selectedRows?.length === 0 ? 'not-allowed' : 'pointer' }}>
      <ButtonDropDown
        disabled={selectedRows?.length === 0}
        buttonStyle={{
          fontWeight: 600,
          fontSize: 12,
          textTransform: 'capitalize',
          color: '#959fb7',
          border: '1px solid #959fb7',
          opacity: selectedRows?.length === 0 ? 0.5 : 1,
          padding: '0 0.5rem',
          backgroundColor: 'white'
        }}
        MenuItemStyle={{
          borderBottom: '1px solid black',
          fontSize: '12px',
          fontWeight: 600
        }}
        MenuStyle={{
          '& .MuiMenu-list': {
            padding: 0
          }
        }}
        show={options.length !== 0}
        variant='outlined'
        options={options}
        buttonText={t('common.bulkActions')}
        onClick={handleOptionClick}
        iconRight={<ArrowDropDown />}
      />

      {showAddContactToProject && (
        <AddContactToProject
          data={selectedRows?.map(item => item?.contact_id)}
          handleClose={() => {
            setShowAddContactToProject(false);
          }}
          isPopupOpen={showAddContactToProject}
          multiple
        />
      )}
      {showAddContactToWorkbench && (
        <AddToWorkbench
          moduleIds={getModulesIds()}
          handleClose={() => {
            setShowAddContactToWorkbench(false);
          }}
          isPopupOpen={showAddContactToWorkbench}
          isProject={module === 'projects'}
        />
      )}

      <AssignTag
        assignedTags={[]}
        moduleIds={getModulesIds()}
        module={module}
        setIsPopupOpen={setShowTagsPopup}
        isPopupOpen={showTagsPopup}
        label={'Tags'}
        getHeaderDetails={() => {}}
        setIsEdited={() => {}}
        onSuccess={reloadData}
        multiple
      />
    </Box>
  );
};

export default CustomHeader;

CustomHeader.propTypes = {
  displayName: PropTypes.string,
  enableSorting: PropTypes.bool,
  setSort: PropTypes.func,
  column: PropTypes.object,
  enableMenu: PropTypes.bool,
  showColumnMenu: PropTypes.func,
  hasColumnSelector: PropTypes.bool
};
