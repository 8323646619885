//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

//import EducationDetails from "../../../AddContact/Components/EducationDetails";

export default function EducationDetailsEdit() {
  //const {defaultValues, register,watch,setValue,unregister,control} = props

  return (
    // <EducationDetails
    //   register={register}
    //   unregister={unregister}
    //   watch={watch}
    //   setValue={setValue}
    //   control={control}
    //   currentValues={defaultValues}
    // />
    <></>
  );
}

EducationDetailsEdit.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  dynamicKey: PropTypes.string,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  defaultValues: PropTypes.object
};
