//-----------------------------------------------------------// In-built Imports // ------------------------------
import React, { useEffect, useRef, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import withRouter from '../../utils/withRouter';

//-----------------------------------------------------------// Internal Imports // ------------------------------
import ColumnFilter from '../Commons/ColumnFilter';
import './index.scss';
// import {
//   CustomLoadingOverlayComponent,
//   defaultColumns,
//   PAGE_LIMIT,
//   SUCCESS_STATUS_CODE,
// } from "../Companies/utils";
import { fetchUserList } from '../../actions';
import ConfirmationPopup from '../../components/ConfirmationPopup';
import { checkWorkbenchAccess, getArchivedDate } from '../../components/ViewWorkbench/utils';
import { workbenchAdvanceSelectorApi, workbenchDataApi } from '../../services/ApiService';
import { API, BLOB, ERROR, EXPORT_LIMIT, GET, POST, PUT, SUCCESS, WARNING, WORKBENCHES_MESSAGE, WORKBENCH_TYPES } from '../../services/constantService';
import { VALIDATION_MESSAGE, exportMessage, notFoundMessage, successMessage, unableMessage } from '../../services/MessageService';
import { SCROLL_TIMEOUT } from '../../utils/common';
import { formatDate } from '../../utils/date';
import AdvancedQuery from '../Commons/AdvancedQuery';
import CustomFilter from '../Commons/CustomFilter';
import Search from '../Commons/Search';
import { CustomLoadingOverlayComponent, DateRenderer, PAGE_LIMIT, ReplistRenderer, SUCCESS_STATUS_CODE, loadColumnStateFromLocalStorage, saveColumnStateToLocalStorage } from '../Commons/Utils';
import ActionsPopover from '../Contacts/ActionsPopover';
import { IdRenderer, cleanupWorkbenchQuery, columnDefs, fieldDefinitions, getArchiveDateFrom, getFilterParamStringForWorkbench, getWorkbenchQueryParamsString, getWorkbenchTitle } from './utils';
import WorkbenchQuery from './WorkbenchQuery';
// import { workbenchActions } from "../../store/workbenchSlice";
import { useSnackbar } from 'notistack';
import Loader from '../../components/common/Loader';
import { defaultColumns } from '../Companies/Utils';

let gridApi, columnApi;

const Workbenches = props => {
  const { type, userData } = props;
  const [rowCount, setRowCount] = useState(0);
  const [isGridReady, setIsGridReady] = useState(false);
  const [uiSelector, setUiSelector] = useState(false);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  /*  const [queryRun, setQueryRun] = useState(false); */
  // const [advanceSelector, setAdvanceSelector] = useState(false);
  //const [classicSelector.current, setClassicSelector] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchString, setSearchString] = useState('');
  const [string, setString] = useState('');
  // const [headerCheckbox , setHeaderCheckbox]= useState(false);
  const [exportSingleValue, setExportSingleValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchWorkbench /* setIsSearchWorkbench */] = useState([WORKBENCH_TYPES.kgpSearches, WORKBENCH_TYPES.userSearches, WORKBENCH_TYPES.archivedSearches].includes(type) ? true : false);
  const [isContactWorkbench /* setIsContactWorkbench */] = useState([WORKBENCH_TYPES.kgpContacts, WORKBENCH_TYPES.userContacts, WORKBENCH_TYPES.archivedContacts].includes(type) ? true : false);
  const [isRootWorkbench /* setIsRootWorkbench */] = useState([WORKBENCH_TYPES.kgp, WORKBENCH_TYPES.user, WORKBENCH_TYPES.archived].includes(type) ? true : false);
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [isQuerySelector, setIsQuerySelector] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showArchivePopup, setShowArchivePopup] = useState(false);
  const [showQuery, setShowQuery] = useState(false);
  const [minimizeQuery, setMinimizeQuery] = useState(false);
  const [paramsData, setParamsData] = useState({});
  const [filterModels, setFilterModels] = useState({});
  const [workbenchIds, setWorkbenchIds] = useState(null);
  const [userValue, setUserValue] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  // const [defaultValue , setDefaultValue] =(null);
  let location = useLocation();
  //const userData = useSelector((state) => state.commonReducer.userData);
  const users = useSelector(state => state.commonReducer.users);
  let navigate = useNavigate();
  const classicSelector = useRef(false);
  const queriesData = useRef({});
  const advanceSelector = useRef(false);

  useEffect(() => {
    setUiSelector(false);
    if (location && location.state) {
      setString(location.state);
    }
    if (!users) {
      fetchUserList('users');
    }
  }, [location]);

  useEffect(() => {
    return () => {
      const columnApis = columnApi;
      saveColumnStateToLocalStorage(`${type}Workbenches`, { columnApis });
    };
  }, []);

  /*  const getParams = (selectedRows) => {
    return selectedRows.map((row) => `id=${row.id}`).join("&");
  }; */

  const updateWorkbench = async (formData, successMessage, errorMessage, params) => {
    setIsLoading(true);
    const { status, data } = await workbenchDataApi(PUT, params.data.id, formData);
    if (status === 200) {
      enqueueSnackbar(successMessage, { variant: SUCCESS });
      // dispatch(workbenchActions.resetWorkbenchList())
      gridApi.onFilterChanged();
      setIsLoading(false);
    } else {
      enqueueSnackbar(data?.message || errorMessage, {
        variant: ERROR
      });
      setIsLoading(false);
    }
  };

  const archiveWorkbench = async () => {
    try {
      const formData = { archive_date: formatDate(new Date(), 'YYYY-MM-DD') };
      await updateWorkbench(formData, 'Workbench archived successfully', 'Unable to archive workbench', paramsData);
    } catch (e) {
      console.log('Error found in archiveWorkbench::', e);
    }
    handleArchivePopupClose();
  };

  const resetFilter = async () => {
    gridApi.setFilterModel(null);
    const customFilterField = columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item?.filter === 'CustomFilter') {
        gridApi.destroyFilter(item.field);
      }
    });
  };

  const resetSort = async () => {
    gridApi.setSortModel(null);
  };

  const handleExport = async () => {
    const selectedRows = gridApi.getSelectedRows();
    if (isAllRowsSelected && rowCount > EXPORT_LIMIT) {
      const message = exportMessage(`${EXPORT_LIMIT}`, 'Workbench');
      enqueueSnackbar(message, { variant: ERROR });
    } else if (!isAllRowsSelected && selectedRows?.length > EXPORT_LIMIT) {
      const message = exportMessage(`${EXPORT_LIMIT}`, 'Workbench');
      enqueueSnackbar(message, { variant: ERROR });
    } else {
      const paramsString = getWorkbenchQueryParamsString(queriesData.current);
      const classicQueryParams = paramsString?.length > 0 && classicSelector.current ? paramsString : '';

      if (selectedRows?.length > 0) {
        let sub_route = 'export-as-excel?';
        /* let types = type; */

        switch (type) {
          case WORKBENCH_TYPES.archivedContacts:
            sub_route = classicQueryParams ? `${sub_route}${classicQueryParams}` : `${sub_route}${classicQueryParams ? classicQueryParams : `&sub_type=${'Contacts'}`}`;
            break;
          case WORKBENCH_TYPES.archivedSearches:
            sub_route = classicQueryParams ? `${sub_route}${classicQueryParams}` : `${sub_route}${classicQueryParams ? classicQueryParams : `&sub_type=${'Searches'}`}`;
            break;
          case WORKBENCH_TYPES.kgp:
            sub_route = classicQueryParams ? `${sub_route}${classicQueryParams}` : `${sub_route}&archive_date_from=${getArchiveDateFrom()}${classicQueryParams}`;
            break;
          case WORKBENCH_TYPES.kgpSearches:
            sub_route = classicQueryParams
              ? `${sub_route}${classicQueryParams}`
              : `${sub_route}&archive_date_from=${getArchiveDateFrom()}${classicQueryParams ? classicQueryParams : `&sub_type=${'Searches'}`}`;
            break;
          case WORKBENCH_TYPES.kgpContacts:
            sub_route = classicQueryParams
              ? `${sub_route}${classicQueryParams}`
              : `${sub_route}&archive_date_from=${getArchiveDateFrom()}${classicQueryParams ? classicQueryParams : `&sub_type=${'Contacts'}`}`;
            break;
          case WORKBENCH_TYPES.user:
            !searchString && (classicQueryParams ? (sub_route = `${sub_route}${classicQueryParams}`) : (sub_route = `${sub_route}&archive_date_from=${getArchiveDateFrom()}${classicQueryParams}`));
            break;
          case WORKBENCH_TYPES.userContacts:
            sub_route = classicQueryParams
              ? `${sub_route}${classicQueryParams}`
              : `${sub_route}&archive_date_from=${getArchiveDateFrom()}${classicQueryParams ? classicQueryParams : `&sub_type=${'Contacts'}`}`;
            break;
          case WORKBENCH_TYPES.userSearches:
            sub_route = classicQueryParams
              ? `${sub_route}${classicQueryParams}`
              : `${sub_route}&archive_date_from=${getArchiveDateFrom()}${classicQueryParams ? classicQueryParams : `&sub_type=${'Searches'}`}`;
            break;
          default:
            sub_route = `${sub_route}${classicQueryParams}`;
        }

        if (searchString) {
          const value = encodeURIComponent(searchString);
          if (exportSingleValue) {
            sub_route = `${sub_route}&id=${value}&quickSearch=true`;
          } else {
            sub_route = `${sub_route}&searchValue=${value}&quickSearch=true`;
          }
          /* let type = "kgp"; */
        }
        if (Object.keys(filterModels)?.length) {
          sub_route = sub_route.concat(getFilterParamStringForWorkbench(filterModels, showQuery || searchValue || type === 'kgp' || type === 'user'));
        }
        let id = [];
        if (!isAllRowsSelected) {
          selectedRows.map(data => id.push(data.id));
        }
        const columnHeaders = columnApi.getAllDisplayedColumns().map(column => column.getColDef().headerName);
        gridApi.showLoadingOverlay();
        const payload = {
          headers: {
            columnHeaders: columnHeaders,
            selectedRows: { id: id },
            allRowSelected: isAllRowsSelected,
            limit: EXPORT_LIMIT,
            type: type
          }
        };
        if (!classicQueryParams) {
          payload.headers.userID = userData?.id;
        }
        if (advanceSelector.current) {
          payload.headers.queryData = queriesData.current;
        }
        let { status, data, headers } = await workbenchDataApi(POST, '', payload, sub_route, BLOB);
        if (status === 200) {
          let fileName = 'Workbenches.xlsx';
          let fileNameHeader = headers['content-disposition'].split('"');
          if (fileNameHeader && fileNameHeader?.length > 2) {
            fileName = fileNameHeader[1];
          }
          saveAs(new File([data], fileName));
          gridApi.hideOverlay();
        }
      } else {
        enqueueSnackbar(VALIDATION_MESSAGE.no_record_selected, {
          variant: ERROR
        });
      }
    }
  };

  let isSelectedFromList = false;

  const setSelectAllRows = isAllRowsSelect => {
    setIsAllRowsSelected(isAllRowsSelect);
    gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelect);
    });
  };

  // const handleChange = () => {
  //   setSelectAllRows(!isAllRowsSelected);
  // };

  const handleChange = event => {
    const checked = event.currentTarget.checked;
    setSelectAllRows(checked);
  };

  const HeaderCheckbox = () => {
    return <Checkbox style={{ padding: 0, width: 16, height: 16, color: 'white' }} size='small' color='primary' onChange={handleChange} />;
  };

  const classicQuery = data => {
    advanceSelector.current = false;
    classicSelector.current = true;
    setSearchString('');
    handleQuery(data);
  };

  const AdvancedQueryFunction = data => {
    advanceSelector.current = true;
    classicSelector.current = false;
    setSearchString('');
    handleQuery(data);
  };

  const saveColumnsState = async () => {
    try {
      setIsLoading(true);
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage(`${type}Workbenches`, {
        columnApi: columnApis,
        gridApi: gridApis
      });
      setIsLoading(false);
    } catch (e) {
      console.log('Error found in saveColumnsState::', e);
    }
  };

  const saveColumnsStateForFilter = async () => {
    try {
      setIsLoading(true);
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage(`${type}Workbenches`, { columnApi: columnApis, gridApi: gridApis }, true, false);
      setIsLoading(false);
    } catch (e) {
      console.log('Error found in saveColumnsState::', e);
    }
  };

  const saveColumnsStateForSort = async () => {
    try {
      setIsLoading(true);
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage(`${type}Workbenches`, { columnApi: columnApis, gridApi: gridApis }, false, true);
      setIsLoading(false);
    } catch (e) {
      console.log('Error found in saveColumnsState::', e);
    }
  };

  const dataSource = {
    getRows: async params => {
      let value = localStorage.getItem(`${type}Workbenches`);
      if (value === 'true') {
        setShowQuery(true);
        // resetFilter();
        // resetSort();
        params.successCallback([], 0);
        setRowCount(0);
        setFilterModels(params.filterModel);
        setShowFilterCount(Object.keys(params.filterModel)?.length);
        return;
      }
      setIsLoading(true);
      if (params.filterModel) {
        setFilterModels(params.filterModel);
        setShowFilterCount(Object.keys(params.filterModel)?.length);
        // gridApi.deselectAll();
      }
      if (params.filterModel && Object.keys(params.filterModel)?.length > 0) gridApi.deselectAll();
      if (string) {
        handleSearchChange('', string);
      } else {
        // const workbenchType = WORKBENCH_TYPES[type]
        // const { searchValue, showQuery, queryData, searchString, classicSelector.current } = state;

        const { filterModel, sortModel } = params;
        /*const isLoad = Object.keys(filterModel)?.length > 0 || sortModel?.length > 0 || searchValue?.length > 0 || Object.keys(queryData)?.length > 0;
        if (!props[workbenchType] || isLoad) {*/
        const paginationString = `limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}`;
        let url = '';
        if (advanceSelector.current) {
          url = `${API.master_query}?${paginationString}`;
          if (isSearchWorkbench) {
            url = `${url}&sub_type=${'Searches'}`;
          } else if (isContactWorkbench) {
            url = `${url}&sub_type=${'Contacts'}`;
          }
          if (Object.keys(filterModel)?.length) {
            url = url.concat(getFilterParamStringForWorkbench(filterModel, showQuery || searchValue || type === 'kgp' || type === 'user'));
          }
          if (sortModel?.length > 0) {
            url = url.concat(`&sortOn=${sortModel[0].colId}&sortType=${sortModel[0].sort.toUpperCase()}`);
          }
        } else {
          gridApi.showLoadingOverlay();
          const paramsString = getWorkbenchQueryParamsString(queriesData.current);
          const classicQueryParams = paramsString?.length > 0 && classicSelector.current ? paramsString : '';
          if (classicSelector.current && paramsString?.length > 0) {
            url = `${API.workbench}?${paginationString}${classicQueryParams}`;
          } else {
            switch (type) {
              case WORKBENCH_TYPES.archived:
                url = url = `${API.workbench}/${type}?${paginationString}`;
                break;
              case WORKBENCH_TYPES.archivedContacts:
                url = `${API.workbench}/archived?${paginationString}&sub_type=${'Contacts'}`;
                break;
              case WORKBENCH_TYPES.archivedSearches:
                url = `${API.workbench}/archived?${paginationString}&sub_type=${'Searches'}`;
                break;
              case WORKBENCH_TYPES.kgp:
                url = `${API.workbench}?${paginationString}&archive_date_from=${getArchiveDateFrom()}`;
                break;
              case WORKBENCH_TYPES.kgpSearches:
                url = `${API.workbench}?${paginationString}&archive_date_from=${getArchiveDateFrom()}&sub_type=${'Searches'}`;
                break;
              case WORKBENCH_TYPES.kgpContacts:
                url = `${API.workbench}?${paginationString}&archive_date_from=${getArchiveDateFrom()}&sub_type=${'Contacts'}`;
                break;
              case WORKBENCH_TYPES.user:
                url = `${API.workbench}/user?${paginationString}&archive_date_from=${getArchiveDateFrom()}`;
                break;
              case WORKBENCH_TYPES.userContacts:
                url = `${API.workbench}/user?${paginationString}&archive_date_from=${getArchiveDateFrom()}&sub_type=${'Contacts'}`;
                break;
              case WORKBENCH_TYPES.userSearches:
                url = `${API.workbench}/user?${paginationString}&archive_date_from=${getArchiveDateFrom()}&sub_type=${'Searches'}`;
                break;
              default:
                console.log('No Match Found');
            }
          }

          if (searchString) {
            const value = encodeURIComponent(searchString);
            url = `${API.workbench}?${paginationString}&${isSelectedFromList ? 'id' : 'searchValue' /* 'name' */}=${value}`;
            if (isSearchWorkbench) {
              url = `${url}&sub_type=${'Searches'}`;
            } else if (isContactWorkbench) {
              url = `${url}&sub_type=${'Contacts'}`;
            }
          }

          if (Object.keys(filterModel)?.length) {
            url = url.concat(getFilterParamStringForWorkbench(filterModel, showQuery || searchValue || type === 'kgp' || type === 'user'));
          }
          if (sortModel?.length > 0 && !isSelectedFromList) {
            url = url.concat(`&sortOn=${sortModel[0].colId}&sortType=${sortModel[0].sort.toUpperCase()}`);
          }
        }
        const { status, data } = advanceSelector.current ? await workbenchAdvanceSelectorApi(POST, url, queriesData.current) : await workbenchAdvanceSelectorApi(GET, url);
        if (status === SUCCESS_STATUS_CODE && data?.data) {
          if (data?.paging?.totalCount === 0) {
            const message = notFoundMessage('records');
            enqueueSnackbar(message, { variant: WARNING });
          }
          /*if (!isLoad) {
            dispatch(workbenchActions.updateWorkbenchLists({ key: workbenchType, data }))
          }*/
          params.successCallback(data.data, data.paging?.totalCount);
          setRowCount(data.paging?.totalCount);
          isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
        }
        params.failCallback();
        gridApi.hideOverlay();
        // if (defaultValue) {
        //   setDefaultValue(false);
        // }
        /*}
        else {
          params.successCallback(props[workbenchType].data, props[workbenchType].paging?.totalCount);
          setState({ rowCount: props[workbenchType].paging?.totalCount });
          gridApi.hideOverlay();
        }*/
      }
      // localStorage.removeItem(`${type}Workbenches`)
      setIsLoading(false);
    },
    rowCount: null
  };

  const ActionsRenderer = params => {
    let list = [];
    if (params?.data?.archive_date && new Date(params?.data?.archive_date) <= new Date()) {
      list = [
        {
          label: 'Restore',
          onClick: async () => {
            const isValidUser = await checkAccessWorkbench(params.data);
            params.api && params.api.refreshCells();
            if (!isValidUser) return null;
            setShowConfirmPopup(true);
            setWorkbenchIds(params.data.id);
            setUserValue(params.data.tagged_users);
          }
        }
      ];
    } else {
      list = [
        {
          label: 'Archive Workbench',
          onClick: async () => {
            const isValidUser = await checkAccessWorkbench(params.data);
            params.api && params.api.refreshCells();
            if (!isValidUser) return null;
            setShowArchivePopup(true), setParamsData(params);
          }
        }
      ];
    }
    return params.data ? <ActionsPopover list={list} /> : null;
  };

  const MaxWidthOfActionColumn = () => {
    if (type === 'archived' || type === 'archived-contacts' || type === 'archived-searches') {
      return 90;
    } else {
      return 150;
    }
  };

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    // const columnApi = params.columnApi
    // const gridApi = params.api
    loadColumnStateFromLocalStorage(`${type}Workbenches`, {
      columnApi,
      gridApi
    });
    setIsGridReady(true);
  };

  const handleSearchChange = (event, value) => {
    classicSelector.current = false;
    advanceSelector.current = false;
    isSelectedFromList = false;
    if (string) {
      setString('');
    }
    if (typeof value === 'string') {
      setSearchString(value);
      setSearchValue(value);
      setExportSingleValue(false);
    } else if (value && value.id) {
      isSelectedFromList = true;
      setSearchString(value.id);
      setSearchValue(value.name);
      setExportSingleValue(true);
    }
    setSearchString(value?.id ? value?.id : value ? value : '', () => {
      gridApi.onFilterChanged();
    });
    resetFilter();
    resetSort();
  };

  const handleConfirmPopupClose = () => setShowConfirmPopup(false);
  const handleArchivePopupClose = () => setShowArchivePopup(false);

  const checkAccessWorkbench = async workbench => {
    try {
      const taggedUser = Object.prototype.hasOwnProperty.call(workbench, 'tagged_users');
      if (!taggedUser) {
        const workbenchInfo = await getWorkbenchInfo(workbench.id);
        if (workbenchInfo) {
          workbench.tagged_users = workbenchInfo.tagged_users;
        } else {
          workbench.tagged_users = [];
        }
      }
      const workbenchAccess = checkWorkbenchAccess(workbench, userData);
      if (!workbenchAccess) {
        enqueueSnackbar(WORKBENCHES_MESSAGE.VALIDATION_MESSAGE, {
          variant: WARNING
        });
      }
      return workbenchAccess;
    } catch (e) {
      console.log('Error found in checkAccessWorkbench::', e);
    }
  };

  const getWorkbenchInfo = async workbenchId => {
    const { status, data } = await workbenchDataApi(GET, workbenchId);
    if (status === 200 && data !== null) {
      setIsLoading(false);
      return data;
    }
    return false;
  };

  const restoreWorkbench = async () => {
    setIsLoading(true);
    const payload = { archive_date: getArchivedDate() };
    const { status, data } = await workbenchDataApi(PUT, workbenchIds, payload);
    if (status === 200) {
      const message = successMessage('Workbench', 'restored');
      enqueueSnackbar(message, { variant: SUCCESS });
      const typeOfUser = userValue.find(user => user.name === userData.name);
      // dispatch(workbenchActions.resetWorkbenchList())
      if (typeOfUser?.name === userData.name) {
        navigate('/workbenches/my-workbenches');
      } else {
        navigate('/workbenches/kgp-workbenches');
      }
      setIsLoading(false);
    } else {
      const message = unableMessage('workbench', 'restore');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
      setIsLoading(false);
    }

    handleConfirmPopupClose();
  };

  const handleStartQuery = async () => {
    await resetSort();
    await resetFilter();
    setShowQuery(true);
    localStorage.setItem(`${type}Workbenches`, true);
    setString(''), setSearchString('');
    setSearchValue('');
  };

  const handleQueryClose = async () => {
    await resetSort();
    await resetFilter();
    // setClassicSelector(false);
    classicSelector.current = false;
    advanceSelector.current = false;
    setShowQuery(false);
    queriesData.current = {};
    gridApi.onFilterChanged();
    if (uiSelector === true) {
      setIsQuerySelector(true);
    } else {
      setIsQuerySelector(false);
    }
    localStorage.removeItem(`${type}Workbenches`);
  };

  const handleQuery = queryData => {
    if (advanceSelector.current === false) {
      if (type.includes('contacts')) {
        queryData.type = 'Contacts';
      } else if (type.includes('searches')) {
        queryData.type = 'Searches';
      }
    }
    if (isAllRowsSelected === true) {
      setSelectAllRows(false);
      setIsAllRowsSelected(true);
    } else {
      gridApi.forEachNode(node => {
        node.setSelected(false);
      });
    }
    queriesData.current = cleanupWorkbenchQuery(queryData);
    gridApi.onFilterChanged();
    localStorage.removeItem(`${type}Workbenches`);
  };

  /* const runQuery = () => {
    setQueryRun(true);
  }; */

  const pullData = () => {
    if (uiSelector === false && isQuerySelector === false) {
      setUiSelector(true);
      advanceSelector.current = true;
      setIsQuerySelector(true);
    } else if (uiSelector === true && isQuerySelector === true) {
      setUiSelector(false);
      advanceSelector.current = false;
      setIsQuerySelector(false);
    }
  };

  const getDefaultQuery = () => {
    if (isContactWorkbench) {
      return `sub_type=${'Contacts'}`;
    } else if (isSearchWorkbench) {
      return `sub_type=${'Searches'}`;
    } else return null;
  };

  const maxWidth = MaxWidthOfActionColumn();
  let newFieldDefinitions = { ...fieldDefinitions };
  if (!isRootWorkbench) {
    delete newFieldDefinitions.Type;
  }
  return (
    <div className='list-work-benches d-flex flex-column'>
      <Helmet>
        <title>{getWorkbenchTitle(type)}</title>
      </Helmet>
      {showConfirmPopup && <ConfirmationPopup message={VALIDATION_MESSAGE.restore_workbench} onClose={handleConfirmPopupClose} onConfirm={restoreWorkbench} />}
      {showArchivePopup && <ConfirmationPopup message={VALIDATION_MESSAGE.archive_workbench} onClose={handleArchivePopupClose} onConfirm={archiveWorkbench} />}
      {showQuery ? (
        <div className='d-flex query-toolbar d-flex p-0 mb-2'>
          <Accordion className='flex-grow-1 w-100 m-0' expanded={showQuery && !minimizeQuery}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon onClick={() => setMinimizeQuery(true)} />}
              aria-controls='panel1a-content'
              id='panel1a-header'
              onClick={() => setMinimizeQuery(!minimizeQuery)}
              style={{ padding: '0px 14px', lineHeight: 1 }}
            >
              Workbenches Query
            </AccordionSummary>
            <AccordionDetails className='w-100 p-0 pr-3 pl-3'>
              {uiSelector ? (
                <AdvancedQuery fieldDefinitions={newFieldDefinitions} type={'work_bench'} pullData={pullData} handleQuery={AdvancedQueryFunction} />
              ) : (
                <WorkbenchQuery type={type} handleQuery={classicQuery} pullData={pullData} option={users} />
              )}
            </AccordionDetails>
          </Accordion>
          <div className='expansion-panel-close-container'>
            <span
              style={{
                display: minimizeQuery ? 'inline' : 'none',
                padding: 12
              }}
              className='cursor-pointer d-flex'
              onClick={handleQueryClose}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
      ) : (
        <div className={'toolbar'}>
          <Search value={searchValue} onChange={handleSearchChange} type='workbenches' defaultQuery={getDefaultQuery()} />
          <Button className='start-query' variant='outlined' color='primary' onClick={handleStartQuery}>
            Start Query
          </Button>
        </div>
      )}
      <div className='d-flex align-items-center justify-content-end'>
        <div className='action-container' style={{ minWidth: '0' }} onClick={() => resetFilter()}>
          <span className='action-text'>Reset Filter</span>
        </div>
        <div className='action-container' style={{ minWidth: '0' }} onClick={() => resetSort()}>
          <span className='action-text'>Reset Sort</span>
        </div>
        <Button disabled={!selectedRowsCount} className='mr-3' variant='outlined' color='primary' onClick={handleExport}>
          Export List
        </Button>
        <Typography className='mr-3'>Total count: {rowCount}</Typography>
      </div>
      <div className='list-view flex-grow-1'>
        {isGridReady && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} filterModel={filterModels} showFilterCount={showFilterCount} />}
        <div id='myGrid' className='ag-theme-alpine'>
          <AgGridReact
            rowModelType={'infinite'}
            enableBrowserTooltips={true}
            blockLoadDebounceMillis={SCROLL_TIMEOUT}
            tooltipShowDelay={0}
            scrollbarWidth={12}
            paginationPageSize={20}
            suppressHorizontalScroll={false}
            suppressMenuHide={true}
            suppressDragLeaveHidesColumns={true}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            columnDefs={columnDefs(false, true, true, maxWidth, type)}
            datasource={dataSource}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            frameworkComponents={{
              DateRenderer,
              ReplistRenderer,
              CustomLoadingOverlayComponent,
              IdRenderer,
              ActionsRenderer,
              CustomFilter,
              HeaderCheckbox: HeaderCheckbox
            }}
            rowSelection={'multiple'}
            onGridReady={onGridReady}
            onDisplayedColumnsChanged={saveColumnsState}
            suppressRowClickSelection={true}
            onDragStopped={saveColumnsState}
            onSortChanged={saveColumnsStateForSort}
            onFilterChanged={saveColumnsStateForFilter}
            onRowSelected={() => {
              setSelectedRowsCount(gridApi.getSelectedRows()?.length);
            }}
            getRowNodeId={data => data.id}
          />
        </div>
      </div>
      <Loader show={isLoading} />
    </div>
  );
};

Workbenches.propTypes = {
  location: PropTypes.object,
  type: PropTypes.string,
  enqueueSnackbar: PropTypes.func,
  userData: PropTypes.object,
  history: PropTypes.object,
  navigate: PropTypes.func,
  users: PropTypes.array
};

const mapStateToProps = state => {
  return {
    userData: state.commonReducer.userData,
    users: state.rootReducer.users
  };
};

const mapDispatchToProps = () => ({
  fetchUserList
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Workbenches));
