import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export const useEnforceSecurity = props => {
  const securityData = useSelector(state => state.commonReducer.userSecurityData);
  const isSecurityEnabled = useSelector(state => state.commonReducer.isSecurityEnabled);

  const enforceSecurity = (elementName, action, type = null) => {
    if (!isSecurityEnabled) {
      return true;
    }

    if (type === 'menuItems') {
      if (securityData?.menuItems) {
        const menuItems = securityData.menuItems;
        const menuItem = menuItems.find(item => item === elementName);
        if (menuItem) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  return { enforceSecurity };
};

useEnforceSecurity.propTypes = {
  app: PropTypes.object
};
