//----------------------------------------------// In-built Imports // -------------------------------------------------

import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------

import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------

import InputField from './common/InputField';

const options = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' }
];
export default function OffLimitsSelection(props) {
  const name = 'Off Limits';
  const { label = name, placeholder = name, InputLabelProps, value, ...rest } = props;
  const [defaultValue, setDefaultValue] = useState({});
  console.log(value);
  useEffect(() => {
    if (value) {
      let option = value;
      if (typeof value === 'object') {
        option = options.find(ele => ele.id === value.id);
      } else {
        option = options.find(ele => ele.id === value);
      }
      setDefaultValue(option);
    } else {
      setDefaultValue(null);
    }
  }, [value]);
  return (
    <Autocomplete
      {...rest}
      value={defaultValue}
      options={options}
      getOptionLabel={option => option.name || ''}
      renderInput={params => <InputField {...params} variant='standard' InputLabelProps={InputLabelProps} label={label} placeholder={placeholder} />}
    />
  );
}

OffLimitsSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  value: PropTypes.string
};
