import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------

const TotalRevenue = props => {
  const { watch, getTotalPercentageOfBase, getGrandTotal, type = 'estimated' } = props;
  const label = type === 'estimated' ? 'Estimated' : 'Actual';

  const displayTooltipContent = title => {
    return (
      <Tooltip title={title}>
        <span className='pl-4'>&nbsp;&nbsp;{title}</span>
      </Tooltip>
    );
  };

  return (
    <div>
      <div className={'d-flex flex-column team-information-table revenue-table'}>
        <div className={'d-flex table-header '}>{label} Revenue</div>
        <div className=' d-flex flex-column py-1 px-3'>
          <div className='d-flex p-1'>
            <div className='col-4 px-0'>Base Fee</div>
            <div className='col pr-0 text-truncate'>{displayTooltipContent(getTotalPercentageOfBase(watch(`${type}_percentage_bases`), `calculated_${type}_amt`, true))}</div>
          </div>
          <div className='d-flex p-1'>
            <div className='col-4 px-0'>Indirect Fee</div>
            <div className='col pr-0 text-truncate'>{displayTooltipContent(getTotalPercentageOfBase(watch('indirect_fees_searches'), 'calculation_amt', true))}</div>
          </div>
          <hr style={{ height: '2px', width: '100%', borderWidth: 0, backgroundColor: '#2A364C' }} />
          <div className='d-flex p-1'>
            <div className='col-4 px-0 text-wrap'>Revenue</div>
            <div className='col pr-0 text-truncate'>{displayTooltipContent(getGrandTotal())}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

TotalRevenue.propTypes = {
  watch: PropTypes.func,
  getTotalPercentageOfBase: PropTypes.func,
  getGrandTotal: PropTypes.func,
  type: PropTypes.string
};

export default TotalRevenue;
