import { useAutocomplete } from '@mui/base/useAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import CustomButton from '../../../../components/common/CustomButton';
import { roleResourcesApi } from '../../../../services/ApiService';
import { POST } from '../../../../services/constantService';

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default function RoleAutocomplete({ resources, roleResourceData, roleId, refetchRoleResource }) {
  if (resources.error || roleResourceData.error) return null;

  const [initialResources, setInitialResources] = React.useState(resources);
  const [selectedResources, setSelectedResources] = React.useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedResources(
      resources.filter(resource => {
        return roleResourceData.some(roleResource => {
          return roleResource.resource_id === resource.id;
        });
      })
    );

    setInitialResources(
      resources.filter(resource => {
        return roleResourceData.some(roleResource => {
          return roleResource.resource_id === resource.id;
        });
      })
    );
  }, [resources, roleResourceData]);

  const addResources = async () => {
    const newResources = selectedResources.filter(resource => {
      return !initialResources.some(initialResource => {
        return initialResource.id === resource.id;
      });
    });
    if (newResources.length === 0) return;
    setLoading(true);

    const resourceBody = newResources.map(resource => {
      return {
        role_id: roleId,
        resource_id: resource.id,
        can_create: true,
        can_read: true,
        can_update: true,
        can_delete: true
      };
    });

    await roleResourcesApi(POST, '', {
      resources: resourceBody
    });

    setLoading(false);
  };

  const removeResources = async () => {
    // Return resouces that are a part of initialResources but not a part of selectedResources
    const removedResources = initialResources.filter(resource => {
      return !selectedResources.some(selectedResource => {
        return selectedResource.id === resource.id;
      });
    });

    if (removedResources.length === 0) return;
    setLoading(true);
    const resourceBody = removedResources.map(resource => {
      return {
        role_id: roleId,
        resource_id: resource.id
      };
    });

    await roleResourcesApi(POST, 'deleteByRoleAndResource', {
      resources: resourceBody
    });

    setLoading(false);
  };

  const { getRootProps, getInputProps, getTagProps, getListboxProps, getOptionProps, groupedOptions, value, focused, setAnchorEl } = useAutocomplete({
    id: 'resources-autocomplete',
    value: selectedResources,
    multiple: true,
    options: resources,
    getOptionLabel: option => option.name,
    disableCloseOnSelect: true
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        my: 2
      }}
    >
      <div>
        <div {...getRootProps()}>
          <div ref={setAnchorEl} className={focused ? 'focused' : ''} id='input-wrapper'>
            {value.map((option, index) => (
              <Tag
                label={option.name}
                {...getTagProps({ index })}
                key={option.name}
                onDelete={() => {
                  setSelectedResources(prev => prev.filter(resource => resource.id !== option.id));
                }}
                className='role-tag'
              />
            ))}
            <input {...getInputProps()} />
          </div>
        </div>
        {groupedOptions.length > 0 ? (
          <ul {...getListboxProps()} id='role-listbox'>
            {groupedOptions.map((option, index) => (
              <li
                {...getOptionProps({ option, index })}
                key={option.name}
                onClick={() => {
                  // If option is not selected then add it to selectedResources and remove it from removedResources
                  if (!selectedResources.some(resource => resource.id === option.id)) {
                    setSelectedResources(prev => [...prev, option]);
                  } else {
                    // If option is already selected then remove it from selectedResources and add it to removedResources
                    setSelectedResources(prev => prev.filter(resource => resource.id !== option.id));
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    width: '100%'
                  }}
                >
                  <Box
                    sx={{
                      width: '25%'
                    }}
                  >
                    <Typography fontSize={10} className={`${index === 0 ? 'd-block' : 'd-none'}`}>
                      Resource Name
                    </Typography>
                    <Typography className='font-weight-bold body-font fs-12 mt-1'>{option.name}</Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '20%'
                    }}
                  >
                    <Typography fontSize={10} className={`${index === 0 ? 'd-block' : 'd-none'}`}>
                      Type
                    </Typography>
                    <Typography className='font-weight-bold body-font fs-12 mt-1'>{option.of_type}</Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '25%'
                    }}
                  >
                    <Typography fontSize={10} className={`${index === 0 ? 'd-block' : 'd-none'}`}>
                      Parent
                    </Typography>
                    <Typography className='font-weight-bold body-font fs-12 mt-1'>{option.parent}</Typography>
                  </Box>
                  <CheckIcon fontSize='small' sx={{ ml: 'auto' }} />
                </Box>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
      <CustomButton
        buttonText='Assign Resource'
        size='small'
        onClick={() => {
          addResources().then(() => {
            removeResources().then(() => {
              refetchRoleResource();
            });
          });
        }}
        iconRight={<Spinner animation='border' variant='light' size='sm' className='ml-2' style={{ display: loading ? 'inline-block' : 'none' }} />}
      />
    </Box>
  );
}

RoleAutocomplete.propTypes = {
  resources: PropTypes.array,
  roleResourceData: PropTypes.array,
  roleId: PropTypes.string,
  refetchRoleResource: PropTypes.func
};
