import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet';
import EditIcon from '@mui/icons-material/Edit';
import { InputLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ViewDocumentTypePopup = props => {
  const { documentTypeData, editForm, closePopup } = props;

  return (
    <>
      <Helmet>
        <title>Document Type - KGP Galaxy</title>
      </Helmet>

      <div className=''>
        <div className='' style={{ background: '#2a364c', color: '#fff' }}>
          <div className='quick-add-header'>
            <Typography>View Document Type</Typography>
            <CloseIcon className='cursor-pointer' onClick={closePopup} />
          </div>
        </div>

        <div className='d-flex flex-column py-3'>
          <div className='d-flex contact-details-row '>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>Organization ID</InputLabel>
              <span className='page-label-value'>{documentTypeData.org_id ? documentTypeData.org_id : '--'}</span>
            </div>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>Document Code</InputLabel>
              <span className='page-label-value'>{documentTypeData.doctype_code ? documentTypeData.doctype_code : '--'}</span>
            </div>
          </div>
          <div className='d-flex contact-details-row '>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>Document Description</InputLabel>
              <span className='page-label-value'>{documentTypeData.doctype_desc ? documentTypeData.doctype_desc : '--'}</span>
            </div>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>Allow Extensions</InputLabel>
              <span className='page-label-value'>{documentTypeData.allow_extensions ? documentTypeData.allow_extensions : '--'}</span>
            </div>
          </div>
          <div className='d-flex contact-details-row '>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>Upload Role</InputLabel>
              <span className='page-label-value'>{documentTypeData.upload_role ? documentTypeData.upload_role : '--'}</span>
            </div>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>Delete Role</InputLabel>
              <span className='page-label-value'>{documentTypeData.delete_role ? documentTypeData.delete_role : '--'}</span>
            </div>
          </div>

          <div className='d-flex contact-details-row '>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>View Role</InputLabel>
              <span className='page-label-value'>{documentTypeData.view_role ? documentTypeData.view_role : '--'}</span>
            </div>
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>Download Role</InputLabel>
              <span className='page-label-value'>{documentTypeData.download_role ? documentTypeData.download_role : '--'}</span>
            </div>
          </div>
        </div>

        <div className='page-content-edit-footer'>
          <div className='d-flex align-items-center action-edit' onClick={editForm}>
            <span className='section-icon'>
              <EditIcon fontSize='inherit' />
            </span>
            <span>Edit Details</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDocumentTypePopup;

ViewDocumentTypePopup.propTypes = {
  documentTypeData: PropTypes.object,
  editForm: PropTypes.bool,
  closePopup: PropTypes.func
};
