//-----------------------------------------------------------// In-built Imports // ------------------------------
import React /*{ useEffect, useState }*/ from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PropTypes from 'prop-types';
import ArticleIcon from '@mui/icons-material/Article';
import PermMediaIcon from '@mui/icons-material/PermMedia';
// import { useDispatch, useSelector } from "react-redux";

//-----------------------------------------------------------// Internal Imports // ------------------------------
import CustomDropdown from '../../CustomDropdown';
// import { fetchPickList } from "../../../../actions";

const filterTypes = [
  { logo: PictureAsPdfIcon, label: 'PDF' },
  { logo: ArticleIcon, label: 'Word' },
  { logo: PermMediaIcon, label: 'Image' }
];

// const [options, setOptions] = useState([]);
// const fileTypeSelection = useSelector(state => state.commonReducer.FileType)
// const dispatch = useDispatch()

// useEffect(() => {
//   if (!fileTypeSelection) {
//     dispatch(fetchPickList('file-type', 'fileType'))
//   } else {
//     setOptions(fileTypeSelection);
//   }
// }, [fileTypeSelection, dispatch])

const FileTypes = props => {
  const { label } = props;
  return <CustomDropdown className='w-100' options={filterTypes} multiple={true} isCheckBox={true} label={label} disableCloseOnSelect isIcon={true} />;
};

FileTypes.propTypes = {
  label: PropTypes.string
};

export default FileTypes;
