//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------

//----------------------------------------------// Internal Imports // -------------------------------------------------

// import "./index.scss";
import { AppBar, Toolbar, Typography, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../../selectors';
import ClientSuiteBrandLogo from './ClientSuiteBrandLogo/ClientSuiteBrandLogo';
import HeaderProfile from '../../../../components/Header/HeaderProfile';

const Navbar = () => {
  const user = useSelector(getUserSelector);

  return (
    <AppBar className='mb-2' color={'error'}>
      <Toolbar variant='dense' className='pr-2 my-1'>
        <ClientSuiteBrandLogo />
        <Typography className='header-nav-items font-weight-bold'>Welcome {user?.first_name ?? user?.name}!</Typography>
        <Stack spacing={1} direction={'row'} alignItems='center'>
          <HeaderProfile className='profile-container' isMoreOption={false} showMyProfile={false} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
