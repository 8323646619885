import React, { useEffect, useState } from 'react';
import TotalCount from '../../../common/TotalCount';
import { Box, Checkbox } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import { GET, TAG_SETUP_LOGS_MESSAGE } from '../../../../services/constantService';
// import { ignCompanyDataApi } from '../../../../services/ApiService';
import { SUCCESS_STATUS_CODE, defaultColumns } from '../../../../Containers/Contacts/utils';
import { useParams } from 'react-router-dom';
import { columnInvoicesDefs } from '../../utils';
import ColumnFilter from '../../../../Containers/Commons/ColumnFilter';
import { ignCompanyDataApi } from '../../../../services/ApiService';
let gridApi, columnApi;
const ViewInvoices = () => {
  const { id } = useParams();
  const [rowCount, setRowCount] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [, /*isAllRowsSelected*/ setIsAllRowsSelected] = useState(false);
  const [isGridReady, setIsGridReady] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sub_route = '?tabName=INVOICES_TAB';
        const { status, data } = await ignCompanyDataApi(GET, '', id, sub_route);
        if (status === SUCCESS_STATUS_CODE) {
          setRowData(data || []);
          setRowCount(data?.length || 0);
          // if (data?.length === 0) {
          //   const message = notFoundMessage("records");
          //   enqueueSnackbar(message, { variant: WARNING });
          // }
        } else {
          throw new Error(TAG_SETUP_LOGS_MESSAGE.GET_ROWS_FOUND_ERROR);
        }
      } catch (error) {
        console.error(TAG_SETUP_LOGS_MESSAGE.GET_ROWS_FOUND_ERROR, error);
        // Handle error
      }
    };

    fetchData();
  }, [id]);
  const setSelectAllRows = isAllRowsSelected => {
    setIsAllRowsSelected(isAllRowsSelected);
    gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };
  const handleChange = event => {
    const checked = event.currentTarget.checked;
    setSelectAllRows(checked);
  };
  const onGridReady = params => {
    columnApi = params.columnApi;
    gridApi = params.api;
    params.columnApi.autoSizeColumns();
    setIsGridReady(true);
  };
  const headerCheckbox = () => {
    return (
      <Checkbox
        // className="checkbox-style"
        className='header-checkbox'
        classes={{ root: 'custom-checkbox-root' }}
        size='small'
        color='primary'
        onChange={e => handleChange(e)}
      />
    );
  };
  const getActionRenderer = () => {
    return <Box></Box>;
  };

  return (
    <div id='view-company' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className='details-container'>
        <Box className='details-container-subtab scroll-content'>
          <Box className='d-flex justify-content-end mb-1 mr-3'>
            <TotalCount rowCount={rowCount} />
          </Box>
          <Box className='list-view'>
            {isGridReady && (
              <ColumnFilter
                columnApi={columnApi}
                defaultColumns={defaultColumns}
                //showFilterCount={showFilterCount}
                //filterModel={filterModel}
              />
            )}
            <Box className='ag-theme-alpine text-center mr-3' id='myGrid'>
              <AgGridReact
                onGridReady={onGridReady}
                rowData={rowData}
                columnDefs={columnInvoicesDefs()}
                frameworkComponents={{
                  HeaderCheckbox: headerCheckbox,
                  GetActionRenderer: getActionRenderer
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

ViewInvoices.propTypes = {
  companyData: PropTypes.func
};

export default ViewInvoices;
