import React, { useContext } from 'react';
import CustomButton from '../../../../../components/common/CustomButton';
import { WorkBenchContext } from '../../../Context';
import { useLanguageHooks } from '../../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';

export default function NoMyListComponent() {
  const { setAddWorkBenchModalOpen } = useContext(WorkBenchContext);
  const { t } = useLanguageHooks([TRANS_KEYS.WELCOME_TO_WORKBENCH, TRANS_KEYS.NO_WORKBENCH_PARA, TRANS_KEYS.ADD_LIST]);
  return (
    <div className='vw-50 p-4'>
      <b className='fs-16'>{t(`${TRANS_KEYS.WELCOME_TO_WORKBENCH}:key`)}</b>
      <p className='fs-14 mt-2'>{t(`${TRANS_KEYS.NO_WORKBENCH_PARA}:key`)}</p>
      <CustomButton buttonText={'+ ' + t(`${TRANS_KEYS.ADD_LIST}:key`)} type='primary mt-4' onClick={() => setAddWorkBenchModalOpen(true)} />
    </div>
  );
}
