import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdown from '../../CustomDropdown';
import { fetchPickLists } from '../../../../actions';

const ModuleSelection = props => {
  const { onChange = () => {}, label = '', defaultValue = '', required = false, ...rest } = props;
  const [options, setOptions] = useState([]);
  const systemModulesOption = useSelector(state => state.commonReducer.systemModules);
  const dispatch = useDispatch();
  const [option, setOption] = useState(false);
  const [values, setValues] = useState({});

  useEffect(() => {
    if (!systemModulesOption) {
      dispatch(fetchPickLists('SYSTEM_MODULES', 'systemModules'));
    } else {
      setOptions(systemModulesOption);
    }
  }, [systemModulesOption, dispatch]);

  const getValueOfSelected = () => {
    let array = [];
    for (let x of defaultValue) {
      const index = options && options.findIndex(obj => obj?.field_value === x.module_name);
      array.push(index);
    }
    let value = [];
    for (let y of array) {
      value.push(options[y]);
    }
    return value;
  };
  const handleChange = (e, item) => {
    setOption(true);
    setValues(item);
    onChange(item);
  };

  return (
    <>
      <CustomDropdown
        required={required}
        options={options}
        onChange={handleChange}
        label={label}
        value={option ? values : defaultValue ? getValueOfSelected() : []}
        multiple={true}
        isCheckBox={true}
        selectedValue={defaultValue}
        {...rest}
      />
    </>
  );
};
ModuleSelection.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.array
};

export default ModuleSelection;
