import { Box, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CustomButton from '../../../components/common/CustomButton';
import CustomDropdown from '../../../components/common/CustomDropdown';
import FileDragUpload from '../../../components/common/FileUpload';
import RichText from '../../../components/common/RichText';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';

const PositionProfile = props => {
  //Translation
  const { t } = useLanguageHooks([
    TRANS_KEYS.ADD_PROJECT_LABEL_HIDE_IN_JOB_POSTING,
    TRANS_KEYS.ADD_PROJECT_BUTTON_REVIEW_AND_PUBLISH,
    TRANS_KEYS.GENERATE,
    TRANS_KEYS.RE_GENERATE,
    TRANS_KEYS.ADD_PROJECT_INPUT_PROJECT,
    TRANS_KEYS.POSITION_PROFILE,
    TRANS_KEYS.CONTACTS_UPLOAD_FILE,
    TRANS_KEYS.CLIENT_INTAKE_CALL
  ]);

  const {
    handleUniversalState,
    getValueFromUniversalState,
    reviewAndPublish = true,
    onClickReview = () => {},
    hideOptions = [],
    handleGenerate = () => {},
    handleHideOptions = () => {},
    isHideOption = false,
    handleChangeDocument = () => {},
    fileUploaded = false
  } = props;

  const handleRichText = value => {
    const event = {
      target: {
        value: value,
        name: 'description'
      }
    };
    handleUniversalState(event);
  };

  const handleReviewAndPublish = () => {
    onClickReview();
  };

  const [generateDone, setGenerateDone] = useState(false);
  const handleGenerateLocal = () => {
    handleGenerate(getValueFromUniversalState('description'));
    setGenerateDone(true);
  };
  return (
    <Box fullWidth className='p-2'>
      <Stack direction='row' justifyContent='space-between' alignItems={'baseline'}>
        <TextField
          size='small'
          label={t(`${TRANS_KEYS.ADD_PROJECT_INPUT_PROJECT}:key`)}
          value={getValueFromUniversalState('projectId')}
          variant='outlined'
          color='secondary'
          type='text'
          name='projectId'
          disabled={true}
          onChange={handleUniversalState}
        />
        {isHideOption && (
          <CustomDropdown
            options={hideOptions ?? []}
            multiple={true}
            disableCloseOnSelect={true}
            isCheckBox={true}
            onChange={(event, newValue) => {
              handleHideOptions(newValue);
            }}
            label={t(`${TRANS_KEYS.ADD_PROJECT_LABEL_HIDE_IN_JOB_POSTING}:key`)}
            name='hideOption'
            value={getValueFromUniversalState('hideOption') && getValueFromUniversalState('hideOption').length ? getValueFromUniversalState('hideOption') : []}
          />
        )}
      </Stack>
      <Stack direction='row' justifyContent={'space-between'} alignItems={'baseline'}>
        <Box className='custom-personality-input my-2'>
          <Box className='formParent flex justify-center align-center resume'>
            <FileDragUpload
              className={'position-profile-transcript'}
              label={t(`${TRANS_KEYS.CLIENT_INTAKE_CALL}:key`)}
              handleChangeDocument={handleChangeDocument}
              width={'12vw'}
              showFileName={fileUploaded}
              horizontal={true}
            />
          </Box>
        </Box>
        <Stack direction='row' justifyContent={'flex-end'}>
          <CustomButton
            variant='text'
            type='btn btn-outlined-secondary mr-1'
            buttonText={!generateDone ? t(`${TRANS_KEYS.GENERATE}:key`) : t(`${TRANS_KEYS.RE_GENERATE}:key`)}
            onClick={handleGenerateLocal}
          />

          {reviewAndPublish && <CustomButton variant='contained' buttonText={t(`${TRANS_KEYS.ADD_PROJECT_BUTTON_REVIEW_AND_PUBLISH}:key`)} onClick={handleReviewAndPublish} />}
        </Stack>
      </Stack>
      <div className='f-12 ml-2 font-bold mt-2'>{t(`${TRANS_KEYS.POSITION_PROFILE}:key`)}</div>
      <Box sx={{ margin: '10px 0px' }}>
        <RichText defaultValue={getValueFromUniversalState('description')} onChange={handleRichText} style={{ height: '60vh', maxHeight: '60vh' }} customClassName={'position-profile-headers'} />
      </Box>
    </Box>
  );
};

PositionProfile.propTypes = {
  handleUniversalState: PropTypes.func.isRequired,
  getValueFromUniversalState: PropTypes.func.isRequired,
  reviewAndPublish: PropTypes.bool,
  hideOptions: PropTypes.array,
  onClickReview: PropTypes.func,
  handleGenerate: PropTypes.func,
  handleHideOptions: PropTypes.func,
  isHideOption: PropTypes.bool,
  handleChangeDocument: PropTypes.func,
  fileUploaded: PropTypes.bool
};

export default PositionProfile;
