//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Link } from 'react-router-dom';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { DateRenderer, loadColumnStateFromLocalStorage, saveColumnStateToLocalStorage } from '../../Containers/Commons/Utils';
import { CustomLoadingOverlayComponent, defaultColumns, PAGE_LIMIT, SUCCESS_STATUS_CODE } from '../../Containers/Contacts/utils';
import { IdRenderer } from '../../Containers/Workbenches/utils';
import { workbenchesColumns } from './utils';

import { SCROLL_TIMEOUT } from '../../utils/common';
import ColumnFilter from '../../Containers/Commons/ColumnFilter';
import { masterSearchApi } from '../../services/ApiService';
// import { useSnackbar } from "notistack";

let gridApi, columnApi;
export function Workbenches(props) {
  const { searchKey, setWorkbenchesCount, height = '100%', rowHeight = 42, minHeight = 180 } = props;
  const [rowCount, setRowCount] = useState(0);
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [filterModel, setFilterModel] = useState(0);
  const [isGridReady, setIsGridReady] = useState(false);
  // const { enqueueSnackbar } = useSnackbar();

  const prevSearchKeyRef = useRef(null);

  useEffect(() => {
    return () => {
      const columnApis = columnApi;
      saveColumnStateToLocalStorage('masterSearchCompanyColumns', { columnApis });
    };
  }, []);

  useEffect(() => {
    if (searchKey !== prevSearchKeyRef.searchKey) {
      gridApi && gridApi.onFilterChanged();
    }
    prevSearchKeyRef.current = searchKey;
  }, [searchKey]);

  const dataSource = {
    getRows: async params => {
      // params.filterModel && this.setState({ filterModel: params.filterModel });
      params.filterModel && setFilterModel(params.filterModel);

      // this.setState({ showFilterCount: Object.keys(this.state.filterModel)?.length })
      setShowFilterCount(Object.keys(filterModel)?.length);

      gridApi.showLoadingOverlay();
      const value = encodeURIComponent(searchKey);
      let sub_route = `workbench?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}&searchValue=${value}`;
      if (params.sortModel?.length > 0) {
        sub_route = sub_route.concat(`&sortOn=${params.sortModel[0].colId}&sortType=${params.sortModel[0].sort.toUpperCase()}`);
      }
      sub_route = `${sub_route}&mastersearch=true`;
      const { status, data } = await masterSearchApi(sub_route);
      if (status === SUCCESS_STATUS_CODE) {
        params.successCallback(data.data, data.paging?.totalCount);
        // this.setState({ rowCount: data.paging.showTotal ? data.paging.showTotal : data.paging?.totalCount })
        setRowCount(data.paging.showTotal ? data.paging.showTotal : data.paging?.totalCount);
        setWorkbenchesCount(data.paging.showTotal ? data.paging.showTotal : data.paging?.totalCount);
      }
      params.failCallback();
      gridApi.hideOverlay();
    },
    rowCount: null
  };

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    // const columnApi = params.columnApi
    params.columnApi.autoSizeAllColumns();
    loadColumnStateFromLocalStorage('masterSearchWorkbenchColumns', { columnApi });
    // this.setState({ isGridReady: true });
    setIsGridReady(true);
  };

  return (
    <div className='list-contacts d-flex flex-column mt-4' style={{ height: rowCount ? height : minHeight }}>
      <div className='list-header d-flex justify-content-between'>
        <Typography className='table-header-text' style={{ paddingLeft: '1.5%' }}>
          <PersonOutlineIcon style={{ fill: '#DE9F6D' }} />
          Workbenches
        </Typography>
        <Typography className='total-count' style={{ paddingRight: '1.5%' }}>
          Total count: {rowCount}
        </Typography>
      </div>
      <div className='list-view flex-grow-1'>
        {isGridReady && <ColumnFilter defaultColumns={defaultColumns} gridApi={gridApi} columnApi={columnApi} filterModel={filterModel} showFilterCount={showFilterCount} />}
        <div id='myGrid' className='ag-theme-alpine'>
          <AgGridReact
            rowModelType={'infinite'}
            enableBrowserTooltips={true}
            blockLoadDebounceMillis={SCROLL_TIMEOUT}
            scrollbarWidth={12}
            paginationPageSize={PAGE_LIMIT}
            suppressHorizontalScroll={false}
            onGridReady={onGridReady}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            rowHeight={rowHeight}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            frameworkComponents={{
              DateRenderer,
              IdRenderer,
              CustomLoadingOverlayComponent
            }}
            datasource={dataSource}
            columnDefs={workbenchesColumns}
            colResizeDefault={'shift'}
            getRowNodeId={data => data.id}
            suppressRowClickSelection={true}
          ></AgGridReact>
          <Link
            to={{
              pathname: '/workbenches/my-workbenches',
              state: searchKey
            }}
            style={{ fontSize: '18px', float: 'right' }}
            className='MuiTypography-colorPrimary mt-2'
          >
            More Records&gt;{' '}
          </Link>
        </div>
      </div>
    </div>
  );
}

Workbenches.propTypes = {
  enqueueSnackbar: PropTypes.func,
  searchKey: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowHeight: PropTypes.number,
  minHeight: PropTypes.number,
  setWorkbenchesCount: PropTypes.func.isRequired
};

export default Workbenches;
