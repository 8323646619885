import React from 'react';
import proptype from 'prop-types';
const CustomHeader = props => {
  const onMenuClick = () => {
    props.showColumnMenu(props.column);
  };

  return (
    <div>
      {props.displayName}
      <button onClick={onMenuClick}>
        <i className='fa fa-bars'></i>
      </button>
    </div>
  );
};

CustomHeader.propTypes = {
  displayName: proptype.string,
  showColumnMenu: proptype.func,
  column: proptype.object
};

export default CustomHeader;
