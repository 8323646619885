//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import './index.scss';
import ProfileDetailsView from './components/ProfileDetailsView';
import CommunicationView from './components/CommunicationView';
import EmploymentHistoryView from './components/EmploymentHistoryView';
import IndustryAndJobFunctionView from './components/IndustryAndJobFunctionView';
import BoardDetailsView from './components/BoardDetailsView';
import EducationDetailsView from './components/EducationDetailsView';

import RecentSearchesActivities from '../RecentSearchesActivities';
import Edit from './Edit';
import { rightNav } from '../../../Containers/Commons/styleUtils';
import LanguageDetailsView from './components/LanguageDetailsView';
export default function DetailsView(props) {
  const {
    contact,
    isEditing,
    individualFieldSave,
    renderLabel,
    renderActions,
    editingField,
    handleRichTextSave,
    handelScroll,
    userData,
    defaultValues,
    saveStatus,
    option,
    handleMouseLeave,
    handleMouseEnter,
    setIsLoading,
    setContact
  } = props;
  const { register, watch, setValue, getValues, unregister, reset, control } = props.useForm;
  useEffect(() => {
    register('current_job_start_year', { required: true });
    register('current_job_start_year', { required: true });
    register('current_job_end_year');
    register('mailing_list');
    register('international_experience');
    register('country', { required: true });
  }, [register]);

  useEffect(() => {
    setTimeout(() => {
      handelScroll();
    }, 3000);
  }, []);
  const setContactData = (newData, field) => {
    setContact({ ...contact, [field]: newData });
  };

  const renderView = () => {
    return (
      <div className='d-flex contact-view'>
        <div className='d-flex flex-column tab-view-content'>
          <div id='profile'>
            <ProfileDetailsView
              option={option}
              editingField={editingField}
              renderLabel={renderLabel}
              renderActions={renderActions}
              setValue={setValue}
              register={register}
              watch={watch}
              contact={contact}
            />
          </div>
          <div id='communication'>
            <CommunicationView
              option={option}
              userData={userData}
              defaultValues={defaultValues}
              handleRichTextSave={handleRichTextSave}
              editingField={editingField}
              renderLabel={renderLabel}
              renderActions={renderActions}
              setValue={setValue}
              getValues={getValues}
              register={register}
              watch={watch}
              contact={contact}
              individualFieldSave={individualFieldSave}
              saveStatus={saveStatus}
              handleMouseLeave={handleMouseLeave}
              handleMouseEnter={handleMouseEnter}
            />
          </div>
          <div id='industryAndFunction'>
            <IndustryAndJobFunctionView
              defaultValues={defaultValues}
              handleRichTextSave={handleRichTextSave}
              editingField={editingField}
              renderLabel={renderLabel}
              renderActions={renderActions}
              setValue={setValue}
              register={register}
              watch={watch}
              industries={contact.industries}
              contactId={contact.id}
              jobFunctions={contact.job_functions}
            />
          </div>
          <div id='jobExperience'>
            <EmploymentHistoryView contactId={contact.id} setIsLoading={setIsLoading} setContactData={setContactData} employmentHistory={defaultValues.job_history} />
          </div>
          <div>
            <BoardDetailsView contactId={contact.id} setIsLoading={setIsLoading} setContactData={setContactData} boardDetails={defaultValues.board_details} />
          </div>
          <div id='education'>
            <EducationDetailsView contactId={contact.id} setIsLoading={setIsLoading} setContactData={setContactData} educationDetails={defaultValues.education_details} />
          </div>
          <div id='languages'>
            <LanguageDetailsView contactId={contact.id} languageDetails={contact.contact_languages} setIsLoading={setIsLoading} setContactData={setContactData} boardDetails={contact.board_details} />
          </div>
        </div>
        <div className='tab-view-right-nav d-flex flex-column'>
          <div className='right-nav-section right-nav-recent-activity-section d-flex flex-column'>
            <a
              style={rightNav}
              href='/'
              className='right-nav-header'
              onClick={e => {
                e.preventDefault();
                handelScroll('profile');
              }}
            >
              Profile
            </a>
            <a
              style={rightNav}
              href='/'
              className='right-nav-header'
              onClick={e => {
                e.preventDefault();
                handelScroll('communication');
              }}
            >
              Communication
            </a>
            <a
              style={rightNav}
              href='/'
              className='right-nav-header'
              onClick={e => {
                e.preventDefault();
                handelScroll('industryAndFunction');
              }}
            >
              Industry and Function
            </a>
            <a
              style={rightNav}
              href='/'
              className='right-nav-header'
              onClick={e => {
                e.preventDefault();
                handelScroll('jobExperience');
              }}
            >
              Job Experience
            </a>
            <a
              style={rightNav}
              href='/'
              className='right-nav-header'
              onClick={e => {
                e.preventDefault();
                handelScroll('education');
              }}
            >
              Education
            </a>
            <a
              style={rightNav}
              href='/'
              className='right-nav-header'
              onClick={e => {
                e.preventDefault();
                handelScroll('languages');
              }}
            >
              Languages
            </a>
          </div>
          <RecentSearchesActivities contact={contact} />
        </div>
      </div>
    );
  };

  return isEditing ? (
    <Edit contact={contact} register={register} defaultValues={defaultValues} watch={watch} setValue={setValue} control={control} unregister={unregister} reset={reset} handelScroll={handelScroll} />
  ) : (
    renderView()
  );
}

DetailsView.propTypes = {
  contact: PropTypes.object,
  saveContact: PropTypes.func,
  isEditing: PropTypes.bool,
  individualFieldSave: PropTypes.func,
  setContact: PropTypes.func,
  reloadData: PropTypes.func,
  useForm: PropTypes.object,
  userData: PropTypes.object,
  renderLabel: PropTypes.func,
  renderActions: PropTypes.func,
  editingField: PropTypes.string,
  handleRichTextSave: PropTypes.func,
  defaultValues: PropTypes.object,
  saveStatus: PropTypes.bool,
  option: PropTypes.array,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  setIsLoading: PropTypes.func,
  handelScroll: PropTypes.func,
  getContact: PropTypes.func
};
