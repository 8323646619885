//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../../../../common/InputField';
import { fetchPickList } from '../../../../../../actions';

const filter = createFilterOptions();
function AttributeSelection(props) {
  const {
    label = 'Attribute',
    placeholder = 'Attribute',
    required = false,
    inputRef,
    selectedOptions = [],
    // eslint-disable-next-line no-unused-vars
    closeSnackbar,
    InputLabelProps,
    ...rest
  } = props;

  const [options, setOptions] = useState([]);
  const sfpaAttributes = useSelector(state => state.commonReducer.sfpaAttributes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sfpaAttributes) {
      dispatch(fetchPickList('sfpa-attributes', 'sfpaAttributes'));
    } else {
      setOptions(sfpaAttributes);
    }
  }, [sfpaAttributes, dispatch]);

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionSelected={(option, value) => {
        if (typeof value === 'string') {
          return option.name === value;
        }
        return option.name === value.name;
      }}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered?.filter(attribute => {
          return !selectedOptions.includes(attribute.id);
        });
      }}
      renderInput={params => <InputField {...params} ref={inputRef} variant='standard' InputLabelProps={InputLabelProps} required={required} label={label} placeholder={placeholder} />}
    />
  );
}

AttributeSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  closeSnackbar: PropTypes.func,
  InputLabelProps: PropTypes.object,
  inputRef: PropTypes.object,
  selectedOptions: PropTypes.array
};

export default AttributeSelection;
