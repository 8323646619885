//-----------------------------------------------------------// In-built Imports // ------------------------------

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

//-----------------------------------------------------------// External Imports // ------------------------------

import { getUserSelector } from '../../selectors';
import { authenticateUserInZendesk } from '../dataSource';
import styles from './../../variables.scss';

export const useWebWidgetHook = () => {
  const userData = useSelector(getUserSelector);
  useEffect(() => {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        authenticate: {
          jwtFn: async callback => callback(await authenticateUserInZendesk())
        },
        color: {
          theme: styles.primary,
          button: styles.primary
        },
        position: {
          horizontal: 'right',
          vertical: 'top'
        },
        offset: {
          vertical: '34px'
        },
        contactForm: {
          fields: [
            {
              id: 'email',
              prefill: { '*': userData?.email ? userData.email : '' }
            },
            {
              id: 'name',
              prefill: { '*': userData?.name ? userData.name : '' }
            }
          ]
        }
      }
    });
    window.zE('webWidget', 'hide');
    window.zE('webWidget:on', 'close', function () {
      window.zE('webWidget', 'hide');
      document.querySelector('#zdLauncher').style.opacity = 1;
    });
  }, [userData]);
  return {};
};
