import { Box } from '@mui/material';
import React from 'react';
import ActivityTypes from './TypesSetup';

const ActivityTypesSetup = () => {
  return (
    <React.Fragment>
      <Box
        sx={{
          height: '85%',
          mr: 3,
          mt: 3,
          position: 'relative'
        }}
      >
        <ActivityTypes index='Activity Types' />
      </Box>
    </React.Fragment>
  );
};

export default ActivityTypesSetup;
