//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import EditDocumentTypePopup from './EditDocumentTypePopup';
import ViewDocumentTypePopup from './ViewDocumentTypePopup';

function DocumentType(props) {
  const { onClose, documentTypeData, setDocumentTypeData } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [localDocumentType] = useState(documentTypeData);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});

  const { register, reset } = useForm({ defaultValues });

  const isFormInEditMode = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  useEffect(() => {
    setDefaultValues({
      org_id: localDocumentType.org_id,
      doctype_code: localDocumentType.doctype_code,
      doctype_desc: localDocumentType.doctype_desc,
      allow_extensions: localDocumentType.allow_extensions,
      upload_role: localDocumentType.upload_role,
      delete_role: localDocumentType.delete_role,
      view_role: localDocumentType.view_role,
      download_role: localDocumentType.download_role
    });
  }, [localDocumentType, setDefaultValues]);

  useEffect(() => {
    register('org_id');
    register('doctype_code');
    register('doctype_desc');
    register('allow_extensions');
    register('upload_role');
    register('delete_role');
    register('view_role');
    register('download_role');
  }, [register]);

  const onPopupClose = () => {
    onClose(isDataUpdated);
  };
  return (
    <Popup open={props.visible} modal onClose={onPopupClose} className={'add-document-value'} closeOnDocumentClick={false} closeOnEscape={false}>
      {isEditing ? (
        <EditDocumentTypePopup
          documentTypeData={documentTypeData}
          closePopup={onPopupClose}
          setIsEditing={setIsEditing}
          setDocumentTypeData={setDocumentTypeData}
          setIsDataUpdated={setIsDataUpdated}
        />
      ) : (
        <ViewDocumentTypePopup documentTypeData={documentTypeData} editForm={isFormInEditMode} closePopup={onPopupClose} />
      )}
    </Popup>
  );
}

export default DocumentType;

DocumentType.propTypes = {
  onClose: PropTypes.func,
  documentTypeData: PropTypes.object,
  visible: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  setDocumentTypeData: PropTypes.func
};
