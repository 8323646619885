import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomDropdown from '../../CustomDropdown';
import { fetchPickLists } from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';
// import httpService from "../../../../services/httpService";

const PhoneTypeSelection = props => {
  const { value, name = '', onChange = () => {}, disabled = false, selectedList, isDrawer = false, label = '', setPhoneList = () => {}, isApplyWidth = false, ...rest } = props;
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const phone_type = useSelector(state => state.commonReducer.phoneType);
  useEffect(() => {
    if (!phone_type) {
      dispatch(fetchPickLists('PHONE_TYPE', 'phoneType'));
    } else {
      if (phone_type) {
        setPhoneList(phone_type);
        const filterData = phone_type?.filter(item =>
          selectedList?.every(listItem => (listItem?.phone_type?.field_value ? listItem?.phone_type?.field_value : listItem?.phone_type) !== item?.field_value)
        );
        setOptions(filterData);
      } else {
        setOptions([]);
      }
    }
  }, [phone_type, dispatch, selectedList]);

  return <CustomDropdown {...rest} value={value} options={options} name={name} onChange={onChange} disabled={disabled} label={label} isDrawer={isDrawer} viewAddress={isApplyWidth} />;
};
PhoneTypeSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isDrawer: PropTypes.bool,
  onChange: PropTypes.func,
  selectedList: PropTypes.array,
  label: PropTypes.string,
  setPhoneList: PropTypes.func,
  isApplyWidth: PropTypes.bool
};
export default PhoneTypeSelection;
