//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import * as AttachmentsService from '../../services/AttachmentsService';
import Attachment from './Attachment';
import Loader from '../common/Loader';
import { ERROR } from '../../services/constantService';
import { unableMessage, VALIDATION_MESSAGE } from '../../services/MessageService';

/* eslint-disable-next-line react/display-name */
const Attachments = forwardRef((props, ref) => {
  const { apiConfig, className, enqueueSnackbar, uiConfig, disabled = false, attachmentsDetails, dispatchDetails } = props;
  const [isLoading, setIsLoading] = useState(true);
  const fileRef = useRef();
  const [attachments, setAttachments] = React.useState([]);

  useImperativeHandle(ref, () => ({
    handleAddAttachment() {
      if (!isUploadingInProcess()) {
        fileRef.current.click();
      } else {
        enqueueSnackbar(VALIDATION_MESSAGE.another_upload, { variant: ERROR });
      }
    }
  }));

  const isUploadingInProcess = () => {
    return attachments.some(item => item.id === undefined);
  };

  useEffect(() => {
    const getAttachments = async () => {
      try {
        if (!apiConfig.parentId) {
          return;
        }
        if (!attachmentsDetails) {
          let { status, data } = await AttachmentsService.getAll(apiConfig.parentId, apiConfig.parentPath, apiConfig.attachmentType);
          setIsLoading(false);
          if (status === 200) {
            setAttachments(data);
            dispatchDetails && dispatchDetails(data);
          } else {
            const message = unableMessage('attachments', 'fetch');
            enqueueSnackbar(data?.message || message, { variant: ERROR });
          }
        } else {
          setIsLoading(false);
          setAttachments(attachmentsDetails);
        }
      } catch (e) {
        console.log('Error found in getAttachments::', e);
      }
    };
    getAttachments();
  }, [apiConfig, enqueueSnackbar]);

  const getIndexOfAttachment = (attachments, attachment) => {
    let index = -1;
    if (attachment.id) {
      index = attachments
        .map(function (item) {
          return item.id;
        })
        .indexOf(attachment.id);
    }

    if (index === -1) {
      index = attachments
        .map(function (item) {
          return item.file_name;
        })
        .indexOf(attachment.file_name);
    }
    return index;
  };

  const handleDeleteAttachment = async attachment => {
    let updatedAttachments = [...attachments];
    let index = getIndexOfAttachment(attachments, attachment);
    if (index !== -1) {
      updatedAttachments.splice(index, 1);
      setAttachments(updatedAttachments);
      if (apiConfig.parentPath) {
        dispatchDetails && dispatchDetails(updatedAttachments);
      }
    }
  };

  const handleSaveAttachment = async attachment => {
    let updatedAttachments = [...attachments];
    let index = getIndexOfAttachment(attachments, attachment);
    if (index !== -1) {
      updatedAttachments[index] = attachment;
    }
    setAttachments(updatedAttachments);
    if (apiConfig.parentPath) {
      dispatchDetails && dispatchDetails(updatedAttachments);
    }
  };

  const onFileChange = async event => {
    const file = event.target.files[0];

    if (file) {
      setAttachments(prev => [{ file_name: file.name, file: file }, ...prev]);
    }
  };

  return (
    <div className={`${className} position-relative`}>
      <Loader show={isLoading} />
      <input
        ref={fileRef}
        type='file'
        disabled={disabled}
        onClick={e => {
          e.target.value = null;
        }}
        onChange={onFileChange}
        hidden
      />
      {attachments && attachments?.length > 0 ? (
        attachments.map(attachment => (
          <Attachment
            disabled={disabled}
            key={`attachment_${attachment.id ? attachment.id : attachment.file_name}`}
            apiConfig={apiConfig}
            uiConfig={uiConfig}
            attachment={attachment}
            handleDeleteAttachment={handleDeleteAttachment}
            handleSaveAttachment={handleSaveAttachment}
          />
        ))
      ) : (
        <div className='attachment-details'>--</div>
      )}
    </div>
  );
});

Attachments.propTypes = {
  apiConfig: PropTypes.object,
  uiConfig: PropTypes.object,
  className: PropTypes.string,
  enqueueSnackbar: PropTypes.func,
  disabled: PropTypes.bool,
  attachmentsDetails: PropTypes.object,
  dispatchDetails: PropTypes.func
};

export default Attachments;
