import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Checkbox, Divider, Grid, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkIcon from '@mui/icons-material/Work';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CustomButton from '../../../components/common/CustomButton';

const candidateDetails = {
  id: 1,
  name: 'Helenna',
  role: 'Data Analyst',
  company: 'Google Inc',
  experience: '4 years',
  location: 'Philadelphia',
  region: 'Metropolitan',
  country: 'India',
  imageUrl: 'https://files.axshare.com/gsc/FOGXD7/26/74/b0/2674b03382724ae7a1ec7657eeffa6a9/images/projects_page/u199.png?pageId=febbb3f9-f4cb-469f-93bf-831dff78dd30'
};

const CandidateCard = ({ data, handleCheckFunction, isChecked }) => {
  return (
    <Box sx={{ border: '0.5px solid gray', borderRadius: '8px' }} className='p-3'>
      <Grid container fullWidth spacing={1}>
        <Grid item xs='2'>
          <Avatar src={data.imageUrl} sx={{ width: '100%', height: '100%' }} />
        </Grid>
        <Grid item xs='8'>
          <Typography variant='body1' sx={{ fontWeight: '600' }}>
            {data.name}
          </Typography>
          <Typography variant='caption'>{data.role + ', ' + data.company}</Typography>
        </Grid>
        <Grid item xs='2'>
          <Checkbox checked={isChecked} onChange={() => handleCheckFunction(data)} sx={{ '& .MuiSvgIcon-root': { fontSize: 34 } }} />
        </Grid>
      </Grid>
      <Divider className='mt-4' />
      <Grid container fullWidth spacing={0} mt={1} mb={1}>
        <Grid item xs={6} md={4}>
          <Stack direction='row'>
            <WorkIcon fontSize='small' className='p-1' />
            <Stack direction='column'>
              <Typography variant='body2'>{data.experience}</Typography>
              <Typography sx={{ fontSize: '12px' }}>of Experience</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6} md={4}>
          <Stack direction='row'>
            <LocationOnIcon fontSize='small' className='p-1' />
            <Stack direction='column'>
              <Typography variant='Body2'>{data.location}</Typography>
              <Typography sx={{ fontSize: '12px' }}>{data.region + ', ' + data.country}</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <CustomButton
            variant='text'
            type={'p-0 m-auto'}
            customWidth={0}
            buttonText={
              <Typography variant='Button' color={'red'}>
                <u>View More</u>
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const Candidates = props => {
  const { getValueFromUniversalState, handleUniversalState } = props;
  const [selectedCandidates, setSelectedCandidates] = useState([]);

  const handleUniversalStateLocally = data => {
    const event = {
      target: {
        name: 'candidates',
        value: data
      }
    };
    handleUniversalState(event);
  };
  const isCheckedFunction = candidateId => {
    if (selectedCandidates.filter(attachment => attachment.id === candidateId).length) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheckFunction = data => {
    if (selectedCandidates.filter(attachment => attachment.id === data.id).length == 0) {
      selectedCandidates.push(data);
      setSelectedCandidates(selectedCandidates.slice());
      handleUniversalStateLocally(selectedCandidates.slice());
      return;
    }
    if (selectedCandidates.filter(attachment => attachment.id == data.id).length > 0) {
      const newSelectedCandidates = selectedCandidates.filter(attachment => attachment.id != data.id);
      setSelectedCandidates(newSelectedCandidates.slice());
      handleUniversalStateLocally(newSelectedCandidates.slice());
    }
  };

  useEffect(() => {
    //first render
    const candidate = getValueFromUniversalState('candidate').length ? getValueFromUniversalState('candidate') : [];
    console.log(candidate);
    setSelectedCandidates(candidate);
  }, []);
  return (
    <Accordion fullWidth className='m-2'>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='body1' sx={{ fontWeight: '600' }}>
          Candidates
        </Typography>
      </AccordionSummary>
      <AccordionDetails className='py-4'>
        <Grid container fullWidth spacing={2}>
          {[{ ...candidateDetails, id: 2 }, candidateDetails].map((details, index) => (
            <Grid item xs='12' md='4' key={index}>
              <CandidateCard data={details} handleCheckFunction={handleCheckFunction} isChecked={isCheckedFunction(details.id)} />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

Candidates.propTypes = {
  handleUniversalState: PropTypes.func.isRequired,
  getValueFromUniversalState: PropTypes.func.isRequired
};

CandidateCard.propTypes = {
  data: PropTypes.object.isRequired,
  handleCheckFunction: PropTypes.func,
  isChecked: PropTypes.bool
};
export default Candidates;
