//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { useDispatch } from 'react-redux';
import axios from 'axios';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { fetchUserDetails } from '../../actions';
import { AWS_TOKEN_GRANT_TYPE_AUTH_CODE, PROD_URL2, PROD_URL1, STAGE_URL, TEST_URL, PROD_URL3, LAB_URL, PUBLIC_ROUTES } from '../../services/constantService';
import { getAwsAppClientId, getAwsTokenApiEndPoint, getGalaxyAppRedirectUrl, getHostedUILoginUrl } from './urlUtil';
// import { clearAuthFromLocalStorage } from "../../services/cognitoService";

export const fetchToken = async code => {
  try {
    //client without client secret
    const params = {
      grant_type: AWS_TOKEN_GRANT_TYPE_AUTH_CODE,
      code: code || localStorage.getItem('access_token'), // fetched from query params
      redirect_uri: getGalaxyAppRedirectUrl(),
      client_id: getAwsAppClientId()
    };
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    const response = await axios.post(getAwsTokenApiEndPoint(), null, {
      headers: headers,
      params: params
    });
    if (response && response.data) {
      return response.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log('Error found in fetchToken::', e);
  }
};

export const RedirectToLogin = () => {
  setEnvironment();
  window.localStorage.setItem('initialPath', window?.location?.pathname);
  window.location = getHostedUILoginUrl();
};

export const setEnvironment = () => {
  if (window.location.origin.includes(TEST_URL)) localStorage.setItem('env', 'test');
  else if (window.location.origin.includes(LAB_URL)) localStorage.setItem('env', 'lab-test');
  else if (window.location.origin.includes(STAGE_URL)) localStorage.setItem('env', 'stage');
  else if (window.location.origin.includes(PROD_URL1) || window.location.origin.includes(PROD_URL2)) localStorage.setItem('env', 'prod');
  else if (window.location.origin.includes(PROD_URL3)) localStorage.setItem('env', 'prod-asia');
  else localStorage.setItem('env', 'local'); // set local-prod to activate prod on local
};

const TokenValidator = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const path = window.location.pathname;
    if (PUBLIC_ROUTES.includes(path.split('/')[1])) {
      console.log('Public Route');
      return;
    } else {
      let updateUser = () => {
        dispatch(fetchUserDetails());
      };
      // Hub.listen('auth', updateUser)
      updateUser();
      // return () => Hub.remove('auth', updateUser)
    }
  }, [dispatch]);

  return <div>&nbsp;</div>;
};

export default TokenValidator;
