//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../common/InputField';

export default function YearSelection(props) {
  const { label = 'Year', disabled = false, variant = 'standard', placeholder = 'Enter year', selectedStartYear = '', InputLabelProps, required, yearType, defaultValue, inputRef, ...rest } = props;
  const [options, setOptions] = useState([]);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    if (defaultValue) {
      setOptions([defaultValue]);
    }
  }, [defaultValue]);

  const getYears = (searchKey = '') => {
    let years = [];
    // if (searchKey?.length > 4 && searchKey !== 'pres' && searchKey > selectedStartYear) {
    //   years.push(searchKey)
    // }
    if (searchKey?.length >= 2 || yearType === 'tillEnd') {
      if (yearType === 'tillEnd') {
        years.push('Present');
      }
      const numberOfYears = Math.pow(10, 4 - searchKey?.length);
      const startYear = parseInt(searchKey) * numberOfYears;
      if (!yearType === 'tillEnd' && selectedStartYear && selectedStartYear?.length > 0) {
        for (let year = parseInt(selectedStartYear); year < startYear + numberOfYears; year++) {
          years.push(year.toString());
        }
      } else if (yearType === 'tillEnd' && selectedStartYear) {
        for (let year = new Date().getFullYear(); year >= selectedStartYear; year--) {
          years.push(year.toString());
        }
      } else {
        for (let year = startYear; year < startYear + numberOfYears; year++) {
          years.push(year.toString());
        }
      }
    }

    if (yearType === 'end') {
      years.push('Present');
    }

    return years;
  };

  useEffect(() => {
    if (yearType === 'tillEnd') {
      const values = getYears();
      setOptions(values);
    }
  }, []);

  const checkForError = value => {
    if (!isNaN(value) && value?.length > 4) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };
  return (
    <Autocomplete
      {...rest}
      autoSelect={true}
      autoHighlight={true}
      defaultValue={defaultValue || null}
      options={options}
      onInputChange={(e, data) => {
        if (data?.length <= 4) {
          setIsError(false);
        }
        const matchString = new RegExp(/[present]/gi);
        if (!isNaN(data) || (data && matchString.test(data))) {
          const values = getYears(data);
          setOptions(values);
        }
      }}
      disabled={disabled}
      renderInput={params => (
        <InputField
          {...params}
          ref={inputRef}
          InputLabelProps={InputLabelProps}
          required={required}
          variant={variant}
          label={label}
          onChange={e => checkForError(e.target.value)}
          error={isError}
          helperText={isError ? 'Enter Valid Value ' : ''}
          // type="number"
          placeholder={placeholder}
        />
      )}
    />
  );
}

YearSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  yearType: PropTypes.string,
  defaultValue: PropTypes.string,
  inputRef: PropTypes.object,
  disabled: PropTypes.bool,
  selectedStartYear: PropTypes.string,
  variant: PropTypes.string
};
