//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import SearchApproval from './List.js';

export default function searchApprovalRejected() {
  return <SearchApproval searchType={'approvalRejected'} columnStateKey={'myPlacementsColumns'} searchStatus={'Placement'} title='My Placements' placedSearches />;
}
