//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

export default function DetailsPopup(props) {
  const { data } = props;
  const reasons = data && data?.reasons ? data?.reasons : [];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const getMessage = reason => {
    if (reason.kg_25_user) {
      return reason.kg_25_user;
    }
    if (reason.bd_record) {
      return reason.bd_record.name;
    } else if (reason.company) {
      return (
        <Link to={`/companies/${reason.company.id}/details`} target='_blank'>
          {reason.company.name}
        </Link>
      );
    } else if (reason.search) {
      return (
        <Typography style={{ fontSize: 'inherit' }}>
          Search <Link to={`/searches/${reason.search.id}/active-candidates`} target='_blank'>{`#${reason.search.job_number}`}</Link>
        </Typography>
      );
    } else {
      return data?.name;
    }
  };

  return (
    <div>
      <div className='off-limits-reason'>
        <Typography className='mb-2' component='div'>
          {data?.name} is off-limits for{' '}
          <Typography aria-describedby={id} className='details-link' onClick={handleClick}>
            multiple reasons
          </Typography>
        </Typography>
        <Typography>Would you like to continue to add this contact to a search?</Typography>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        className='reason-container'
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <TableContainer component={Paper}>
          <Table aria-label='spanning table'>
            <TableHead>
              <TableRow style={{ backgroundColor: '#5c52c6' }}>
                <TableCell style={{ color: '#FFFFFF' }} align='center' colSpan={3}>
                  Off Limits Reasons for {data?.name}
                </TableCell>
              </TableRow>
              <TableRow style={{ backgroundColor: '#e9e9ea', color: 'black' }}>
                <TableCell align='center'>Off Limits Cause</TableCell>
                <TableCell align='center'>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reasons.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align='center' style={{ backgroundColor: 'red', color: 'white' }}>
                      {item.cause}
                    </TableCell>
                    <TableCell align='center'>{getMessage(item)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Popover>
    </div>
  );
}

DetailsPopup.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func,
  contactId: PropTypes.string
};
