//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
// import { getUserSelector } from "../../../selectors";
import { Box } from '@mui/material';
import Logo from '../../../assets/images/logo.png';
import axios from '../../../services/axios';
import { ROUTES } from '../../../services/constantService';

const BrandLogo = () => {
  const [brandURL, setBrandURL] = useState(null);

  // const location = "https://sgcorp.sandbox.ignyte.app/";
  const location = window.location.origin;

  /* Extract Brand logo from tenant data once tenant api is ready*/
  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);

  useEffect(() => {
    /* Load the logo once brand config details fetched */
    if (brandConfigs && brandConfigs.file_key) {
      axios.get(`${location}/${brandConfigs.file_key}`).then(res => {
        if (res.status === 200) {
          setBrandURL(`${location}/${brandConfigs.file_key}`);
        } else {
          setBrandURL(Logo);
        }
      });
    } else {
      setBrandURL(Logo);
    }
  }, [brandConfigs]);

  return (
    <Box id='BrandLogo'>
      <Link to={ROUTES.home}>
        <div className='logo-container'>
          <img src={brandURL} alt='brand-logo' />
        </div>
      </Link>
    </Box>
  );
};

export default BrandLogo;
