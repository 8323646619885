//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import CloudUploadOutlined from '@mui/icons-material/CloudUploadOutlined';
import { useSnackbar } from 'notistack';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//----------------------------------------------// Internal Imports // -------------------------------------------------

import DocumentTypeSelection from '../../../../AddUser/components/DocumentTypeSelection';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { dashboardDataApi } from '../../../../../services/ApiService';
import { API, GET } from '../../../../../services/constantService';
import { disallowFiles } from '../../../../../utils/common';

function SearchDocument(props) {
  const { register, setValue, currentValues, unregister } = props;
  const [list, setList] = useState([{}]);
  const [options, setOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    register('doc_desc');
    return () => {
      unregister('doc_desc');
    };
  }, [register, unregister]);

  useEffect(() => {
    setValue('search_documents', list);
  }, [list, setValue]);

  useEffect(() => {
    register('search_documents');
    setList(Array.isArray(currentValues.search_documents) ? currentValues.search_documents : []);
  }, [currentValues, register]);

  const addSearchDocument = () => {
    const updatedList = [...list, {}];
    setList(updatedList);
  };

  const handleUploadDocument = (e, field, index) => {
    let existingSearchDocuments = [...list];
    if (e.target.files?.length > 0) {
      // existingSearchDocuments[e.target.id][field] = e?.target?.files[0]
      // list.filter((item)=> item.file_name);

      //eslint-disable-next-line
      if (!disallowFiles(e?.target?.value, enqueueSnackbar, list, index)) return;
      const result = list.map(({ /* file_name, file_id, */ ...rest }) => ({ ...rest }));
      // const result = list.map(({ ...rest }) => ({ ...rest }));
      existingSearchDocuments[e.target.id] = { ...result[e.target.id], [field]: e?.target?.files[0] };
      setList(existingSearchDocuments);
    }
  };

  const getData = async () => {
    try {
      const url = `${API.product_setting}/document_type`;
      const { status, data } = await dashboardDataApi(GET, url);
      if (status === 200) {
        let array = [];
        data.data.rows.map(data => array.push({ doctype_code: data.doctype_code, allowed_extension: data.allow_extensions }));
        setOptions(array);
      }
    } catch (err) {
      console.log('error in Fetch Token::', err);
    }
  };

  const removeDocument = index => {
    setList(prevState => {
      const documents = [...prevState];
      documents.splice(index, 1);
      return documents;
    });
  };

  const doctype = (data, index, name) => {
    let allowedExt;
    if (data && data.doctype_code !== '') {
      options.forEach(item => {
        if (item.doctype_code === data.doctype_code) {
          allowedExt = item.allowed_extension;
        }
      });
    }

    let existingSearchDocuments = [...list];
    if (data) {
      existingSearchDocuments[index] = { ...list[index], ['doctype_code']: data.doctype_code, ['doc_desc']: data.doctype_desc, ['allowed_extension']: allowedExt };
      // existingSearchDocuments[index]["doctype_code"] = data.doctype_code
      // existingSearchDocuments[index]["doc_desc"] = data.doctype_desc
      setList(existingSearchDocuments);
      setValue('doc_desc', data.doctype_desc);
    } else {
      const updatedList = [...list];
      updatedList[index] = { ...list[index], [name]: data, ['doctype_code']: data };
      setList(updatedList);
    }
  };

  return (
    <div className='section-content flex-grow-1' id='clientInformation'>
      <Accordion defaultExpanded={true} className='w-100' style={{ background: '#fff', color: '#fff' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />} aria-controls='panel1a-content' style={{ background: '#2a364c' }}>
          <div>Upload & Attach Files</div>
        </AccordionSummary>
        <AccordionDetails className='d-flex flex-column p-0'>
          <div className='section-content team-information-table flex-grow-1 mb-2 p-0'>
            <div className='client-info-table d-flex flex-column'>
              <div className='d-flex align-items-center table-main-header'>
                <div className='col-1'></div>
                <div className='col-3'>Document Type</div>
                <div className='col-2'>Allowed Extensions</div>
                <div className='col-4'>Upload</div>
                <div className='col-2 ml-2'>Action</div>
              </div>
              {list?.length > 0 &&
                list.map((item, index) => (
                  <div className='d-flex pt-1 doc-upload' key={index}>
                    <div className=' d-flex  col-1 align-items-center justify-content-center align-self-baseline' style={{ color: 'black', whiteSpace: 'normal' }}>
                      {index + 1}{' '}
                    </div>
                    <div className=' d-flex   col-3 align-items-center align-self-baseline'>
                      <DocumentTypeSelection
                        variant='outlined'
                        className='add-search-input team-info-field'
                        InputLabelProps={{ focused: true }}
                        list={list}
                        value={item.doc_desc ? item.doc_desc : ''}
                        onChange={(e, data) => doctype(data, index, 'doc_desc')}
                      />
                    </div>
                    <div className=' d-flex  col-2 align-items-center justify-content-center align-self-baseline' style={{ color: 'black', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                      {' '}
                      {item.allowed_extension ? item.allowed_extension : '--'}{' '}
                    </div>
                    <div className=' d-flex  col-4 align-items-center justify-content-center align-self-baseline' style={{ color: 'black', whiteSpace: 'normal', wordBreak: 'break-word' }}>
                      {' '}
                      {item.file_name ? item.file_name : item?.files?.name ? item?.files?.name : '--'}{' '}
                    </div>

                    <div className='col-2  d-flex align-items-center justify-content-center align-self-baseline'>
                      <label htmlFor={index} className='mt-2'>
                        <input
                          type='file'
                          name='searchDocument'
                          id={index}
                          style={{ display: 'none' }}
                          onChange={e => {
                            handleUploadDocument(e, 'files', index);
                          }}
                        />
                        <CloudUploadOutlined cursor='pointer' fontSize='large' style={{ color: 'black' }} />
                      </label>

                      <div className='close-action'>
                        <span className='close-icon'>
                          <CloseIcon
                            color='error'
                            fontSize='large'
                            onClick={() => {
                              removeDocument(index);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className='text-start ml-2'>
              {' '}
              <Button variant={'text'} startIcon={<AddIcon style={{ color: 'red' }} />} onClick={() => addSearchDocument()} className='button-text-capitalized' style={{ width: '175px' }}>
                <span
                  style={{
                    fontWeight: '600',
                    fontSize: '13px',
                    fontStyle: 'italic'
                  }}
                >
                  Add File
                </span>
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

SearchDocument.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  unregister: PropTypes.func
};

export default SearchDocument;
