export const sideScroll = (element, direction, speed, distance, step) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    if (direction === 'left') {
      element.scrollLeft -= step;
    } else {
      element.scrollLeft += step;
    }
    scrollAmount += step;
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
  return slideTimer;
};

export const handleMouseWheel = (container, event, nextBtnSetter, prevBtnSetter, distance, speed) => {
  if (event.deltaY > 0) {
    if (container.scrollLeft >= container.scrollWidth - container.clientWidth) {
      nextBtnSetter(true);
    }
    prevBtnSetter(false);
    sideScroll(container, 'right', speed, distance, 10);
  } else if (event.deltaY < 0) {
    nextBtnSetter(false);
    if (container.scrollLeft === 0) {
      prevBtnSetter(true);
    }
    sideScroll(container, 'left', speed, distance, 10);
  }
};
