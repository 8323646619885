import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import { Box, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import CustomButton from '../../../../components/common/CustomButton';
import { roundOffToDecimalFirst } from '../../../../utils/common';
import ContactImageAvatar from '../../../Searches/ContactImageAvatar';
import './candidateCard.scss';

const CandidateCard = props => {
  const { onClick = () => {} } = props;
  const candidateData = {
    name: props.candidate.name,
    image: props.candidate.image,
    ...props.candidate
  };
  // const [profileUrl, setProfileUrl] = useState("");

  return (
    <Grid item xs={12} md={4} className=''>
      <Box className='w-100 background-white border shadow rounded p-2'>
        <Box className='d-flex justify-content-between align-items-center'>
          <Box className='d-flex align-items-center'>
            <ContactImageAvatar id={candidateData?.contact_id} className='rounded-circle card-img w-10 m-1' />
            <div>
              <Typography className='fs-12 font-weight-bold capitalize-text'>{candidateData?.contact?.first_name + ' ' + candidateData?.contact?.last_name}</Typography>
              <Typography className='fs-11 text-muted'>
                {candidateData.contact?.current_job_title},{candidateData.contact?.contact_company?.name}
              </Typography>
            </div>
          </Box>
          {/* STAGE DISPLAY */}
          {/* <Box className='text-center m-2'>
            <Typography className='fs-10 text-muted'>Stage</Typography>
            <Typography className='fs-12 primary'>{candidateData?.stage}</Typography>
          </Box> */}
        </Box>
        <Divider className='mt-2' />
        <Box className='d-flex justify-content-between align-items-center p-2 '>
          <Box className='d-flex'>
            <WorkIcon color='gray' fontSize='1rem' className='text-gray mr-1 ' />
            <Box>
              <Typography className='fs-10'>{roundOffToDecimalFirst(+candidateData?.total_experience ?? 0)} years</Typography>
              <Typography className='fs-8 text-muted'>Of Experience</Typography>
            </Box>
          </Box>

          <Box className='d-flex'>
            <LocationOnIcon fontSize='1rem' className='text-gray mr-1 ' />
            <Box>
              <Typography className='fs-10'>{candidateData?.contact?.contact_address[0] ? candidateData?.contact?.contact_address[0]?.metropolitan_area : '-'}</Typography>
              <Typography className='fs-8 text-muted'>Metropolitan Area India</Typography>
            </Box>
          </Box>

          <CustomButton type='tertiary-error' buttonText={'View More'} variant={'text'} onClick={() => onClick(props.candidate)} textGap='gap-2'></CustomButton>
        </Box>
      </Box>
    </Grid>
  );
};

CandidateCard.propTypes = {
  candidate: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default CandidateCard;
