//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import CustomDropdown from '../../CustomDropdown';
import { fetchPickListData } from '../../../../actions';
// import { BD_STAGES, OPEN_STAGES } from "../../../../services/constantService";

const StageSelection = props => {
  const { defaultValue, onChange = () => {}, label = '', ...rest } = props;
  const dispatch = useDispatch();
  const projectStage = useSelector(state => state.commonReducer.projectStage);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!projectStage) {
      dispatch(fetchPickListData('stages?name=', 'projectStage'));
    } else {
      setOptions(projectStage?.data);
    }
  }, [projectStage, dispatch]);

  return (
    <CustomDropdown
      {...rest}
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
      label={label}
      // value={value}
    />
  );
};

StageSelection.propTypes = {
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
  label: PropTypes.string
  // value: PropTypes.object
};

export default StageSelection;
