import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { globalSearchSelector } from '../../../selectors';
import AssignTag from './AssignTag';

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  ['& .MuiTooltip-tooltip']: {
    backgroundColor: '#fffffF',
    fontSize: '1rem',
    border: '0.2px solid black',
    color: 'black'
  }
}));

export const Tags = ({ tags, loading = false }) => {
  const { selectedTabId } = useParams();
  const { filterModel, gridApi } = useSelector(globalSearchSelector);
  const [isTagPopupOpen, setIsTagPopupOpen] = useState();
  const module = selectedTabId?.replace('ign-', '');

  const reloadData = () => {
    gridApi?.current?.setFilterModel(filterModel);
    gridApi?.current?.refreshInfiniteCache();
  };

  return loading ? (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Typography sx={{ textDecoration: tags?.length > 0 ? 'underline' : null, marginLeft: 0.5 }} color={'#007e6b'} fontSize={12} fontWeight={500}>
        <Skeleton variant='text' sx={{ fontSize: 12 }} width={'100px'} />
      </Typography>
    </Box>
  ) : (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <CustomTooltip
        placement='top'
        title={
          <Box display={'flex'} flexWrap={'wrap'} gap={1}>
            {tags?.map((tag, index) => (
              <Box key={tag?.name || index} width={'fit-content'}>
                <Typography sx={{ textDecoration: 'underline', marginLeft: 0.5 }} fontSize={12} fontWeight={800}>
                  {tag?.name || '--'}
                </Typography>
              </Box>
            ))}
          </Box>
        }
        sx={{ margin: 0, padding: 0 }}
      >
        <Typography sx={{ cursor: 'pointer', textAlign: 'center', textDecoration: tags?.length > 0 ? 'underline' : null, marginLeft: 0.5 }} fontSize={12} fontWeight={800}>
          {tags?.length > 0 ? tags?.[0]?.name : ''}
        </Typography>
      </CustomTooltip>

      <Box
        onClick={() => setIsTagPopupOpen(true)}
        sx={{ padding: 0, margin: 0, width: 'fit-content', color: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer', textTransform: 'none', marginLeft: 0.5, fontSize: 12, fontWeight: 600 }}
      >
        + Tag
      </Box>

      <AssignTag
        assignedTags={tags?.filter(tag => tag?.name)?.map(tag => tag?.name)}
        moduleIds={[tags?.[0]?.moduleId]}
        module={module}
        setIsPopupOpen={setIsTagPopupOpen}
        isPopupOpen={isTagPopupOpen}
        label={'Tags'}
        getHeaderDetails={() => {}}
        setIsEdited={() => {}}
        onSuccess={reloadData}
      />
    </Box>
  );
};

Tags.propTypes = {
  tags: PropTypes.array,
  loading: PropTypes.bool
};
