import { Avatar, Divider, Stack, Typography, Box, Button } from '@mui/material';
import React, { useEffect } from 'react';
import CustomMuiDropdown from '../../components/common/CustomMuiDropdown';
import * as momentTimeZone from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { updateDateFormats, updateTimeFormats, updateTimeZoneFormat, updateLanguage } from '../../store/profileFormatSlice';
import { getUserSelector } from '../../selectors';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { userConfigs } from '../../services/ApiService';
import { PATCH } from '../../services/constantService';
import { enqueueSnackbar } from 'notistack';
function ProfilePage() {
  const profileFormat = useSelector(state => state.profileFormatSlice);
  const userData = useSelector(getUserSelector);
  const navigate = useNavigate();
  // const [profileFormat.dateFormat, setDateFormat] = React.useState();
  // const [profileFormat.timeFormat, setTimeFormat] = React.useState();
  // const [profileFormat.timeZone, setTimeZone] = React.useState();
  // const [profileFormat.language, setLanguage] = React.useState();
  const dispatch = useDispatch();
  const setDateFormat = value => {
    dispatch(updateDateFormats(value));
  };
  const setTimeFormat = value => {
    dispatch(updateTimeFormats(value));
  };
  const setTimeZone = value => {
    dispatch(updateTimeZoneFormat(value));
  };
  const setLanguage = value => {
    dispatch(updateLanguage(value));
  };

  const timezoneOption = momentTimeZone.tz.names();
  const languageOptions = [
    { label: 'English', value: 'en' },
    { label: 'Spanish', value: 'es' },
    { label: 'Portuguese', value: 'esp' }
  ];
  const handleUpdateProfile = async () => {
    try {
      await userConfigs(PATCH, {
        userId: userData.id || '',
        time_zone: profileFormat.timeZoneFormat.value,
        date_format: profileFormat.dateFormat.value,
        time_format: profileFormat.timeFormats.value,
        language: profileFormat.language.value
      });
      enqueueSnackbar('Profile Updated Successfully', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Error updating profile', { variant: 'error' });
    }
  };
  return (
    <Stack
      spacing={4}
      sx={{
        width: '100%',
        height: '100%',
        padding: 2
      }}
      bgcolor={'Background'}
    >
      <Stack direction='row' gap={2} alignItems='center'>
        <Box onClick={() => navigate(-1)} sx={{ cursor: 'pointer' }}>
          <KeyboardBackspaceOutlinedIcon />
        </Box>
        <Typography variant='h6' fontWeight='bold'>
          Profile
        </Typography>
      </Stack>
      <Stack direction='row' gap={4} alignItems='center'>
        <Avatar sx={{ width: 100, height: 100 }} />
        <Stack gap={0.5}>
          <Typography variant='h6' fontWeight={'bold'}>
            {userData?.name || ''}
          </Typography>
          <Typography variant='p'>{userData?.email || ''}</Typography>
          <Typography variant='p'>{userData?.type || ''}</Typography>
        </Stack>
      </Stack>
      <Divider />
      <Typography
        variant='h6'
        sx={{
          margin: 2,
          fontWeight: 'bold'
        }}
      >
        Preferences
      </Typography>
      <Stack gap={2}>
        <Typography variant='p' fontWeight={'bold'}>
          Date Format
        </Typography>
        <CustomMuiDropdown
          options={[
            { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
            { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' }
          ]}
          label=''
          value={profileFormat?.dateFormat || {}}
          getOptionLabel={option => option.label}
          onChange={(event, value) => {
            setDateFormat(value);
          }}
        />
      </Stack>
      <Stack gap={2}>
        <Typography variant='p' fontWeight={'bold'}>
          Time Format
        </Typography>
        <CustomMuiDropdown
          options={[
            { label: '12-hours', value: '12-hours' },
            { label: '24-hours', value: '24-hours' }
          ]}
          label=''
          value={profileFormat?.timeFormats}
          getOptionLabel={option => option.label}
          onChange={(event, value) => {
            setTimeFormat(value);
          }}
        />
      </Stack>
      <Stack gap={2}>
        <Typography variant='p' fontWeight={'bold'}>
          Time Zone
        </Typography>
        <CustomMuiDropdown
          options={timezoneOption.map(timezone => ({
            label: timezone,
            value: timezone
          }))}
          label=''
          value={profileFormat?.timeZoneFormat}
          getOptionLabel={option => option.label}
          onChange={(event, value) => {
            setTimeZone(value);
          }}
        />
      </Stack>
      <Stack gap={2}>
        <Typography variant='p' fontWeight={'bold'}>
          Language
        </Typography>
        <CustomMuiDropdown
          options={languageOptions}
          label=''
          value={profileFormat?.language}
          getOptionLabel={option => option.label}
          onChange={(event, value) => {
            setLanguage(value);
          }}
        />
      </Stack>
      <Stack
        sx={{
          justifyContent: 'right',
          flexDirection: 'row'
        }}
      >
        <Button
          variant='outlined'
          color='primary'
          onClick={handleUpdateProfile}
          sx={{
            width: '300px',
            height: '40px'
          }}
        >
          Save and Refresh
        </Button>
      </Stack>
    </Stack>
  );
}

export default ProfilePage;
