import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CommentIcon from '@mui/icons-material/Comment';
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, CircularProgress, Switch, TextField, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomMuiDropdown from '../../../../components/common/CustomMuiDropdown';
import { echoSenseApi } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import { ANALYSIS_TYPE_LIST, getDefaultDecisionOptions, getInterviewDecisionOptions, MOCK_SPEAKER_DATA, showToast } from '../../constants';
import { processTranscriptData } from '../../helpers';

const TranscriptUploadForm = props => {
  const {
    file,
    uploadLoading,
    analysisType = 'Consultant Interview',
    setAnalysisType = () => {},
    friendlyName = null,
    setFriendlyName = () => {},
    selectedLanguage = null,
    setSelectedLanguage = () => {},
    analysisTypeList = ANALYSIS_TYPE_LIST,
    languageList = ['English', 'Spanish', 'German'],
    submitAnalyzeRequest = () => {},
    selectedDecisions = [],
    setSelectedDecisions = () => {},
    speakerData = [],
    setSpeakerData,
    projectCandidate = null,
    submitDisabled = false
  } = props;
  const { t } = useTranslation();
  const [audioSource, setAudioSource] = useState(null);
  const [audioLoading, setAudioLoading] = useState(false);

  const fetchTranscriptFromS3 = async ({ transcriptKey, audioKey, friendly_name }) => {
    const apiPromise = [];
    setFriendlyName(friendly_name);
    try {
      apiPromise.push(echoSenseApi(GET, `read-file?key=${transcriptKey}`));
      apiPromise.push(echoSenseApi(GET, `read-file?key=${audioKey}`));
      const [transcriptResponse, audioResponse] = await Promise.all(apiPromise);
      const audioUrl = audioResponse.data.data;
      setAudioSource(audioUrl);
      const transcriptUrl = transcriptResponse.data.data;

      if (window.location.hostname.includes('localhost')) {
        showToast('Using Mock speakers for localhost', 'info');
        setSpeakerData(MOCK_SPEAKER_DATA);
      } else {
        const s3Response = await axios.get(transcriptUrl, { timeout: 1000 * 60 * 3 }); // wait for 3 minutes
        const { speakerData } = processTranscriptData(s3Response.data);
        setSpeakerData(speakerData);
      }
      setAudioLoading(false);
    } catch (error) {
      showToast(error.message, 'error');
      setAudioLoading(false);
    }
  };

  const checkMarkedCandidate = () => {
    return speakerData.find(item => item.candidate);
  };

  useEffect(() => {
    if (file.remote) {
      setAudioLoading(true);
      fetchTranscriptFromS3(file);
    } else {
      setAudioLoading(false);
    }
  }, [file]);
  const checkValue = decisionVal => {
    return selectedDecisions.includes(decisionVal);
  };

  const renderSwitch = useCallback(
    decision => {
      return (
        <Switch
          size='small'
          className={decision === 'summary' ? '' : 'SwitchStyle'}
          disabled={decision === 'summary'}
          checked={checkValue(decision)}
          onChange={e => {
            let tempList = new Set(selectedDecisions);
            if (e.target.checked) {
              tempList.add(decision);
            } else {
              tempList.delete(decision);
            }
            setSelectedDecisions([...tempList]);
          }}
        />
      );
    },
    [selectedDecisions]
  );
  return (
    <Box py={2}>
      <Box className='row m-3'>
        <Box className='col-4 px-2'>
          <Box className='bold my-2'>
            Analysis Type <span style={{ color: 'var(--palette-error-main)' }}>*</span>
          </Box>
          <Box>
            <CustomMuiDropdown
              size='small'
              options={analysisTypeList}
              value={analysisType}
              getOptionLabel={option => option}
              onChange={(e, data) => {
                setAnalysisType(data);
              }}
            />
          </Box>
        </Box>
        <Box className='col-4 px-2'>
          <Box className='bold my-2'>
            Friendly Name <span style={{ color: 'var(--palette-error-main)' }}>*</span>
          </Box>
          <Box>
            <TextField
              fullWidth
              size='small'
              value={friendlyName}
              onChange={e => {
                setFriendlyName(e.target.value);
              }}
            />
          </Box>
        </Box>
        <Box className='col-4 px-2'>
          <Box className='bold my-2'>
            Language <span style={{ color: 'var(--palette-error-main)' }}>*</span>
          </Box>
          <Box>
            <CustomMuiDropdown
              size='small'
              options={languageList}
              value={selectedLanguage}
              getOptionLabel={option => option}
              onChange={(e, data) => {
                setSelectedLanguage(data);
              }}
            />
          </Box>
        </Box>
      </Box>
      {audioSource && (
        <Box className='row m-3 align-items-center'>
          <Box className='col-6 px-2'>
            <Box className='bold'>{t('echoSense.identifySpeakers')}</Box>
            <Box>{t('echoSense.speakerDesc')}</Box>
          </Box>
          <Box className='col-6 text-right'>
            <audio className='w-75' controls src={audioSource} />
          </Box>
        </Box>
      )}
      <Box className='m-3 border' style={{ height: 150, overflow: 'scroll' }}>
        {audioLoading ? (
          <Box className='flex align-item-center justify-content-center' height='100%'>
            <CircularProgress />
          </Box>
        ) : (
          speakerData.map((speaker, index) => (
            <Box className='row justify-content-between align-items-center m-2 border rounded p-2' key={index}>
              <Box className='flex flex-row align-items-center' style={{ flex: 0.3 }}>
                <Box style={{ width: 120 }}>
                  <TextField
                    inputProps={{ style: { fontSize: 10 } }} // font size of input text
                    InputLabelProps={{ style: { fontSize: 10 } }}
                    size='small'
                    value={speaker.speaker}
                    onChange={e => {
                      let tempSpeakers = speakerData.map((item, mapIndex) => {
                        return mapIndex == index ? { ...item, speaker: e.target.value } : { ...item, speaker: item.speaker };
                      });
                      setSpeakerData(() => tempSpeakers);
                    }}
                    fullWidth
                  />
                </Box>
                <Box className='fs-12'>
                  <Checkbox
                    color='secondary'
                    size='small'
                    checked={speaker.candidate}
                    onChange={e => {
                      let tempSpeakers = speakerData.map((item, mapIndex) => {
                        return mapIndex == index
                          ? { ...item, speaker: projectCandidate && projectCandidate.full_name && e.target.checked ? projectCandidate.full_name : item.originalSpeaker, candidate: e.target.checked }
                          : { ...item, speaker: item.originalSpeaker, candidate: false };
                      });
                      setSpeakerData(() => tempSpeakers);
                    }}
                  />{' '}
                  Mark as candidate
                </Box>
              </Box>
              <Box className='flex flex-row align-items-center fs-12' style={{ flex: 0.48, overflow: 'hidden' }}>
                <CommentIcon style={{ fontSize: 15 }} className='mx-1' />
                <Tooltip title={speaker.firstUtterance}>
                  <Box className='text-truncate'>{speaker.firstUtterance}</Box>
                </Tooltip>
              </Box>
              <Box className='flex flex-row align-items-center fs-12' style={{ flex: 0.15, justifyContent: 'flex-end' }}>
                {speaker.startTime && (
                  <>
                    <AccessTimeIcon style={{ fontSize: 15 }} className='mx-1' />{' '}
                    <Box>
                      {speaker.startTime} - {speaker.endTime}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          ))
        )}
      </Box>
      <Box className='m-3 row justify-content-between align-items-center fs-11'>
        <Typography className='col-2 px-2 bold'>Decision: </Typography>
        {getDefaultDecisionOptions().map(decision => {
          return (
            <Box key={decision.value} className='col-2'>
              {renderSwitch(decision.value)}
              {decision.label}
            </Box>
          );
        })}
      </Box>
      {checkMarkedCandidate() && (
        <Box className='m-3 row justify-content-between align-items-center fs-11'>
          <Box className='col-2'></Box>
          {getInterviewDecisionOptions().map(decision => {
            return (
              <Box key={decision.value} className='col-2'>
                {renderSwitch(decision.value)}
                {decision.label}
              </Box>
            );
          })}
        </Box>
      )}
      <hr />
      <Box className='col-12 text-right'>
        <LoadingButton
          loading={uploadLoading}
          loadingPosition='end'
          disabled={submitDisabled}
          size='small'
          color='primary'
          variant='contained'
          className='text-transform-none'
          onClick={() => submitAnalyzeRequest()}
          style={{ width: 150 }}
        >
          {uploadLoading ? t('echoSense.uploading') : t('actions.submit')}
        </LoadingButton>
      </Box>
    </Box>
  );
};

TranscriptUploadForm.propTypes = {
  file: PropTypes.object,
  uploadLoading: PropTypes.bool,
  analysisType: PropTypes.string,
  setAnalysisType: PropTypes.func,
  friendlyName: PropTypes.string,
  setFriendlyName: PropTypes.func,
  selectedLanguage: PropTypes.string,
  setSelectedLanguage: PropTypes.func,
  analysisTypeList: PropTypes.array,
  languageList: PropTypes.array,
  submitAnalyzeRequest: PropTypes.func,
  selectedDecisions: PropTypes.array,
  setSelectedDecisions: PropTypes.func,
  speakerData: PropTypes.array,
  setSpeakerData: PropTypes.func,
  projectCandidate: PropTypes.object,
  submitDisabled: PropTypes.bool
};

export default TranscriptUploadForm;
