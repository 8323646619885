import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Loader from '../../components/common/Loader';
import { echoSenseApi } from '../../services/ApiService';
import { DELETE, ERROR, GET, STANDARD_ERROR_MESSAGE, SUCCESS } from '../../services/constantService';
import EchoSenseControls from './components/EchoSenseControls';
import EchoSenseCreateNewRequest from './components/EchoSenseCreateNewRequest';
import EchoSenseTable from './components/EchoSenseTable';
import TableControls from './components/TableControls';
import { showToast } from './constants';

export default function IgnyteIntelligence() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [subRoute, setSubRoute] = useState(searchParams.get('tab') ? searchParams.get('tab') : 'analyze');
  const [fetchRequestType, setFetchRequestType] = useState(searchParams.get('subTab') ? parseInt(searchParams.get('subTab'), 10) : 0);
  const [openCreateNewRequest, setOpenCreateNewRequest] = useState(false);
  const [records, setRecords] = useState([]);
  const [totalTranscribeCount, setTotalTranscribeCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(15);
  const [transcribeCurrentPage, setTranscribeCurrentPage] = useState(1);
  const [defaultProject, setDefaultProject] = useState(null);
  const [defaultCandidate, setDefaultCandidate] = useState(null);
  const [defaultFileType, setDefaultFileType] = useState(null);
  const [defaultFile, setDefaultFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const changePageNumber = dir => {
    if (dir == 'left' && transcribeCurrentPage != 1) {
      setTranscribeCurrentPage(val => val - 1);
    } else if (dir == 'right' && transcribeCurrentPage != Math.ceil(totalTranscribeCount / pageLimit)) {
      setTranscribeCurrentPage(val => val + 1);
    }
  };

  const fetchEchoSenseTranscribes = async (showLoader = true) => {
    try {
      if (showLoader) {
        setIsLoading(true);
      }
      const type = fetchRequestType ? 'all' : 'my';
      let sub_route = `transcribe?type=${type}&page=${transcribeCurrentPage}&size=${pageLimit}`;
      const records = await echoSenseApi(GET, sub_route);
      if (records.status == 200 && Array.isArray(records?.data?.data?.rows)) {
        setRecords(records.data.data.rows);
        setTotalTranscribeCount(records.data.data.count);
      } else {
        showToast('Failed to fetch records', ERROR);
      }
    } catch (err) {
      showToast(STANDARD_ERROR_MESSAGE, ERROR);
    } finally {
      setIsLoading(false);
      setRefreshLoading(false);
    }
  };

  const fetchEchoSenseAnalyzeRecords = async (showLoader = true) => {
    try {
      if (showLoader) {
        setIsLoading(true);
      }
      const status = !fetchRequestType ? '' : fetchRequestType == 1 ? 'processing' : 'completed';
      let sub_route = `analyze?status=${status}&page=${transcribeCurrentPage}&size=${pageLimit}`;
      const records = await echoSenseApi(GET, sub_route);
      if (records.status == 200 && Array.isArray(records?.data?.data?.rows)) {
        setRecords(records.data.data.rows);
        setTotalTranscribeCount(records.data.data.count);
      } else {
        showToast('Failed to fetch records', ERROR);
      }
    } catch (err) {
      showToast(STANDARD_ERROR_MESSAGE, ERROR);
    } finally {
      setIsLoading(false);
      setRefreshLoading(false);
    }
  };

  const deleteEchoSenseTranscribe = async taskId => {
    try {
      setIsLoading(true);
      let sub_route = `transcribe/${taskId}`;
      const response = await echoSenseApi(DELETE, sub_route);
      if (response.status == 200) {
        const { error, msg } = response.data;
        if (!error) {
          showToast(msg, SUCCESS);
        } else {
          showToast(msg, ERROR);
        }
      } else {
        showToast('Failed to delete records', ERROR);
      }
    } catch (err) {
      showToast(STANDARD_ERROR_MESSAGE, ERROR);
    } finally {
      fetchEchoSenseTranscribes();
      setIsLoading(false);
    }
  };

  const downloadReport = async key => {
    try {
      setIsLoading(true);
      let sub_route = `read-file?key=${key}`;
      const response = await echoSenseApi(GET, sub_route);
      if (response.status == 200) {
        const { error, msg, data } = response.data;
        if (!error) {
          window.open(data, '_blank');
        } else {
          showToast(msg, ERROR);
        }
      } else {
        showToast('Failed to download transcription', ERROR);
      }
    } catch (err) {
      showToast(STANDARD_ERROR_MESSAGE, ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const analyzeTranscript = params => {
    const candidateDetails = params.data.candidate?.contact;
    const candidate_name = !candidateDetails ? null : `${candidateDetails.first_name} ${candidateDetails.last_name}`;
    setDefaultProject(params.data.project);
    setDefaultCandidate({
      candidate_id: params.data.candidate_id,
      contact_id: params.data.contact_id,
      full_name: candidate_name
    });
    setDefaultFileType('transcript');
    setDefaultFile({
      transcribeId: params.data.id,
      name: params.data.transcript_file_s3_key.split('/').pop(),
      transcriptKey: params.data.transcript_file_s3_key,
      remote: true,
      audioKey: params.data.audio_file_s3_key,
      friendly_name: params.data.friendly_name
    });
    setOpenCreateNewRequest(true);
    setIsLoading(false);
  };

  const navigateTo = useCallback((route = 'transcribe') => {
    setSearchParams({ tab: route, subTab: 0 });
    setSubRoute(route);
    setFetchRequestType(0);
  }, []);

  const changeRequestType = type => {
    setSearchParams(searchParams => {
      searchParams.set('subTab', type);
      return searchParams;
    });
    setFetchRequestType(type);
  };

  useEffect(() => {
    const hasTab = searchParams.get('tab');
    if (!hasTab) {
      setSearchParams({ tab: 'analyze', subTab: 0 });
    }
  }, []);

  useEffect(() => {
    if (subRoute === 'transcribe') {
      fetchEchoSenseTranscribes();
    } else {
      fetchEchoSenseAnalyzeRecords();
    }
  }, [subRoute, fetchRequestType, transcribeCurrentPage, pageLimit]);

  return (
    <>
      <EchoSenseCreateNewRequest
        subRoute={subRoute}
        open={openCreateNewRequest}
        setOpen={setOpenCreateNewRequest}
        defaultFileType={defaultFileType}
        defaultProject={defaultProject}
        defaultCandidate={defaultCandidate}
        defaultFile={defaultFile}
        navigateTo={navigateTo}
        refetch={() => (subRoute == 'transcribe' ? fetchEchoSenseTranscribes(false) : fetchEchoSenseAnalyzeRecords(false))}
        processingList={records && Array.isArray(records) ? records.filter(item => item.status === 'PROCESSING' || item.status === 'INITIATED') : []}
        completedList={records && Array.isArray(records) ? records.filter(item => item.status === 'COMPLETED') : []}
        analyzeTranscript={data => analyzeTranscript({ data })}
        downloadReport={downloadReport}
      />
      <Box py={3} pr={2}>
        <Loader show={isLoading} />
        <EchoSenseControls navigateTo={navigateTo} subRoute={subRoute} setOpenCreateNewRequest={setOpenCreateNewRequest} />
        <Box className='mt-3 bg-white'>
          <TableControls
            refreshLoading={refreshLoading}
            subRoute={subRoute}
            refetch={() => {
              setRefreshLoading(true);
              subRoute == 'transcribe' ? fetchEchoSenseTranscribes(false) : fetchEchoSenseAnalyzeRecords(false);
            }}
            fetchRequestType={fetchRequestType}
            setFetchRequestType={changeRequestType}
            totalTranscribeCount={totalTranscribeCount}
            totalPages={Math.ceil(totalTranscribeCount / pageLimit)}
            currentPageNumber={transcribeCurrentPage}
            pageLimit={pageLimit}
            updatePageLimit={setPageLimit}
            changePageNumber={changePageNumber}
          />
          <EchoSenseTable subRoute={subRoute} records={records} deleteRecord={deleteEchoSenseTranscribe} downloadReport={downloadReport} analyzeTranscript={analyzeTranscript} />
        </Box>
      </Box>
    </>
  );
}
