//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import './index.scss';

export default function VideoView(props) {
  const { video, increaseFontSize = false } = props;

  const getVideo = () => {
    if (video && video.fileName) {
      window.open(`${video.url}`, '_blank');
    }
  };

  const renderVideoView = () => {
    return (
      <span className='contact-profile-link' style={{ fontSize: '22px' }}>
        <OndemandVideoIcon fontSize={increaseFontSize ? 'default' : 'inherit'} color={video && video.fileName ? '' : 'disabled'} onClick={getVideo} />
      </span>
    );
  };

  return renderVideoView();
}
VideoView.propTypes = {
  video: PropTypes.object,

  increaseFontSize: PropTypes.bool
};
