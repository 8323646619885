//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------0
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { capitalize } from '../../../utils/string';
import { fetchPickList } from '../../../actions';

function RoleSelection(props) {
  const { className = 'w-100', label, InputLabelProps, required, placeholder = 'Select Role', variant = '', ...rest } = props;
  const [options, setOptions] = useState([]);

  const roles = useSelector(state => state.commonReducer.roles);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!roles) {
      dispatch(fetchPickList('user-roles', 'roles'));
    } else {
      let array = [];
      roles.map(data => array.push(data.name));
      setOptions(array);
    }
  }, [roles, dispatch]);

  return (
    <Autocomplete
      {...rest}
      className={className}
      getOptionLabel={option => capitalize(option)}
      options={options}
      renderInput={params => (
        <TextField
          // className={className}
          variant={variant}
          {...params}
          required={required}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}

RoleSelection.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  InputLabelProps: PropTypes.object
};

export default RoleSelection;
