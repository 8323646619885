import { Box, Checkbox, Radio, Slider, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import CustomMuiDropdown from '../../../common/CustomMuiDropdown';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import { RenderSummary } from '../../../../Containers/Searches/ViewProject/Comman';
import { FitScoreDataApi } from '../../../../services/ApiService';
import { ERROR, POST, SUCCESS } from '../../../../services/constantService';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import CustomButton from '../../../common/CustomButton';
import Loader from '../../../common/Loader';
import RichTextCustomPopup from './RichTextCustomPopup';
import './styles.scss';

const ViewExperienceHeader = props => {
  const {
    isLogAnActivityOpen = false,
    approvedToPublish = false,
    recruiterAverageRating = null,
    consultantAverageRating = null,
    setApprovedToPublishAll = () => {},
    isCopyAllRecruiterComments = false,
    handleCopyAllRecruiterComments = () => {}
  } = props;
  return (
    <Box className='row align-items-center'>
      <Box className={`col-${isLogAnActivityOpen ? '6' : '3'} p-2 align-items-center text-center`}>
        <Checkbox
          className='checkboxStyle'
          checked={approvedToPublish}
          onChange={() => {
            setApprovedToPublishAll();
          }}
          size='small'
          name={'approved_to_publish'}
        />{' '}
        <Box className='text-danger d-inline-block'>Approved to publish</Box>
      </Box>
      <Box className={`col-${isLogAnActivityOpen ? '6' : '3'} p-2 text-center`}>
        <Box className='d-inline-block p-2 text-center' style={{ backgroundColor: '#eee' }}>
          Recuriter Average Rating
        </Box>
        <Box className='d-inline-block text-center p-2 average-rating-value'>{recruiterAverageRating}</Box>
      </Box>
      <Box className={`col-${isLogAnActivityOpen ? '6' : '3'} p-2 text-center`}>
        <Box className='d-inline-block p-2 text-center' style={{ backgroundColor: '#eee' }}>
          Consultant Average Rating
        </Box>
        <Box className='d-inline-block text-center p-2 average-rating-value'>{consultantAverageRating}</Box>
      </Box>
      <Box className={`col-${isLogAnActivityOpen ? '6' : '3'} p-2 align-items-center  text-center`}>
        <Checkbox
          className='checkboxStyle'
          checked={isCopyAllRecruiterComments}
          onChange={e => {
            console.log(e.target.value);
            handleCopyAllRecruiterComments();
          }}
          size='small'
          name={'copy_ai_recuriter_comments'}
        />{' '}
        Copy All Recruiter Comments
      </Box>
    </Box>
  );
};

const ExperienceItem = props => {
  const { experience, isLogAnActivityOpen, no, handleChange } = props;
  const [isRichTextCustomPopupOpen, setIsRichTextCustomPopupOpen] = useState(false);
  const [richTextCustomPopupDefaultValue, setRichTextCustomPopupDefaultValue] = useState(null);
  const [richTextCustomPopupOnChangeCallback, setRichTextCustomPopupOnChangeCallback] = useState(() => () => {});
  return (
    <>
      <RichTextCustomPopup
        isOpen={isRichTextCustomPopupOpen}
        title='Add Comments'
        setIsOpen={setIsRichTextCustomPopupOpen}
        defaultValue={richTextCustomPopupDefaultValue}
        onChange={richTextCustomPopupOnChangeCallback}
      />
      <Box className='border shadow row p-3 mx-2'>
        <Box className={`col-${isLogAnActivityOpen ? '12' : '6'}`}>
          <Box>
            <Box>Experience - {Number.isInteger(no) ? no + 1 : ''}</Box>
            <ReactQuill readOnly={true} value={experience?.ign_skills?.skill_name} modules={{ toolbar: false }} className='m-1 border-bottom view-only-quill' theme='bubble' />
          </Box>
          <Box className='row mx-0 my-2'>
            <Box className='col-6 d-flex flex-column justify-content-between'>
              <Box>Minimum</Box>
              <Box>
                <Box>
                  <ReactQuill readOnly={true} value={experience?.minimal} modules={{ toolbar: false }} className='m-1 border-bottom view-only-quill' theme='bubble' />
                </Box>
                <Box className='flex flex-wrap my-1'>
                  <Box>
                    <Radio
                      checked={experience?.ratings?.project_skill_min_rating === 'deficient'}
                      onChange={e => {
                        handleChange(no, 'project_skill_min_rating', e.target.value);
                      }}
                      value='deficient'
                      name='radio-buttons'
                      inputProps={{ 'aria-label': 'Deficient' }}
                      size='small'
                    />
                    Deficient
                  </Box>
                  <Box>
                    <Radio
                      checked={experience?.ratings?.project_skill_min_rating === 'meets'}
                      onChange={e => {
                        handleChange(no, 'project_skill_min_rating', e.target.value);
                      }}
                      value='meets'
                      name='radio-buttons'
                      inputProps={{ 'aria-label': 'Meets' }}
                      size='small'
                    />
                    Meets
                  </Box>
                  <Box>
                    <Radio
                      checked={experience?.ratings?.project_skill_min_rating === 'exceeds'}
                      onChange={v => {
                        handleChange(no, 'project_skill_min_rating', v.target.value);
                      }}
                      value='exceeds'
                      name='radio-buttons'
                      inputProps={{ 'aria-label': 'Exceeds' }}
                      size='small'
                    />
                    Exceeds
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className='col-6 d-flex flex-column justify-content-between'>
              <Box>Ideal</Box>
              <Box>
                <Box>
                  <ReactQuill readOnly={true} value={experience?.ideal} modules={{ toolbar: false }} className='m-1 border-bottom view-only-quill' theme='bubble' />
                </Box>
                <Box className='flex flex-wrap my-1'>
                  <Box>
                    <Radio
                      checked={experience?.ratings?.project_skill_ideal_rating === 'deficient'}
                      onChange={v => {
                        handleChange(no, 'project_skill_ideal_rating', v.target.value);
                      }}
                      value='deficient'
                      name='radio-buttons'
                      inputProps={{ 'aria-label': 'Deficient' }}
                      size='small'
                    />
                    Deficient
                  </Box>
                  <Box>
                    <Radio
                      checked={experience?.ratings?.project_skill_ideal_rating === 'meets'}
                      onChange={v => {
                        handleChange(no, 'project_skill_ideal_rating', v.target.value);
                      }}
                      value='meets'
                      name='radio-buttons'
                      inputProps={{ 'aria-label': 'Meets' }}
                      size='small'
                    />
                    Meets
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={`col-${isLogAnActivityOpen ? '12' : '6'} row`}>
          <Box className='col-6 pr-2'>
            <Box>Recruiter Rating</Box>
            <Box className='mt-4'>
              <Box className='mx-2'>
                <Slider
                  step={0.1}
                  min={0}
                  max={5}
                  value={experience?.ratings?.project_skill_recruiter_rating}
                  onChange={(_, v) => {
                    handleChange(no, 'project_skill_recruiter_rating', v);
                  }}
                  marks={[
                    { value: 0, label: '0' },
                    { value: 1, label: '1' },
                    { value: 2, label: '2' },
                    { value: 3, label: '3' },
                    { value: 4, label: '4' },
                    { value: 5, label: '5' }
                  ]}
                  track={false}
                  className='rating-slider'
                  valueLabelDisplay='on'
                  size='small'
                />
              </Box>
              {/* <CustomMuiDropdown label='Rating' options={[]} textFieldProps={{ size: 'small', fullWidth: true }} /> */}
              <Box
                className='border p-2 my-2'
                style={{ borderRadius: '4px' }}
                onClick={() => {
                  setRichTextCustomPopupDefaultValue(experience?.ratings?.project_skill_recruiter_comments);
                  setRichTextCustomPopupOnChangeCallback(() => v => {
                    handleChange(no, 'project_skill_recruiter_comments', v);
                    setRichTextCustomPopupDefaultValue(() => '');
                    setRichTextCustomPopupOnChangeCallback(() => {});
                  });
                  setIsRichTextCustomPopupOpen(true);
                }}
              >
                {experience?.ratings?.project_skill_recruiter_comments ? <RenderSummary value={experience?.ratings?.project_skill_recruiter_comments} /> : 'Comments'}
              </Box>
            </Box>
          </Box>
          <Box className='col-6 pl-2'>
            <Box>Consultant Rating</Box>
            <Box className='mt-4'>
              <Box className='mx-2'>
                <Slider
                  step={0.1}
                  min={0}
                  max={5}
                  value={experience?.ratings?.project_skill_consultant_rating}
                  onChange={(_, v) => {
                    handleChange(no, 'project_skill_consultant_rating', v);
                  }}
                  marks={[
                    { value: 0, label: '0' },
                    { value: 1, label: '1' },
                    { value: 2, label: '2' },
                    { value: 3, label: '3' },
                    { value: 4, label: '4' },
                    { value: 5, label: '5' }
                  ]}
                  track={false}
                  className='rating-slider'
                  valueLabelDisplay='on'
                  size='small'
                />
              </Box>
              {/* <CustomMuiDropdown label='Rating' options={[]} textFieldProps={{ size: 'small', fullWidth: true }} /> */}
              <Box
                className='border p-2 my-2'
                style={{ borderRadius: '4px' }}
                onClick={() => {
                  setRichTextCustomPopupDefaultValue(experience?.ratings?.project_skill_consultant_comments);
                  setRichTextCustomPopupOnChangeCallback(() => v => {
                    handleChange(no, 'project_skill_consultant_comments', v);
                    setRichTextCustomPopupOnChangeCallback(() => {});
                    setRichTextCustomPopupDefaultValue(() => '');
                  });
                  setIsRichTextCustomPopupOpen(true);
                }}
              >
                {experience?.ratings?.project_skill_consultant_comments ? <RenderSummary value={experience?.ratings?.project_skill_consultant_comments} /> : 'Comments'}
              </Box>
            </Box>
            <Box className='my-3'>
              <Checkbox
                className='checkboxStyle'
                checked={experience?.ratings?.copyRecruiterCommentsFlag}
                onChange={() => {
                  handleChange(no, 'copyRecruiterCommentsFlag', !experience?.ratings?.copyRecruiterCommentsFlag);
                  handleChange(no, 'project_skill_consultant_comments', experience?.ratings?.project_skill_recruiter_comments);
                }}
                size='small'
                name={'copy_ai_recuriter_comments'}
              />{' '}
              <Box className='d-inline-block'>Copy Recruiter Comments</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const ViewExperienceCard = props => {
  const { isLogAnActivityOpen = false, experiences = [], setExperiences = () => {} } = props;
  const handleChange = (index, field, value) => {
    const updatedData = [...experiences];
    if (!updatedData[index]['ratings']) {
      updatedData[index]['ratings'] = {};
    }
    updatedData[index]['ratings'][field] = value;
    setExperiences(updatedData);
  };
  return (
    <>
      {experiences.map((experience, no) => (
        <ExperienceItem key={no} experience={experience} isLogAnActivityOpen={isLogAnActivityOpen} no={no} handleChange={handleChange} />
      ))}
    </>
  );
};

const ViewExperience = props => {
  const { isLogAnActivityOpen = false, experience_data = [], project_id = null, candidate_id = null, getFitScoreDetails = () => {} } = props;
  const [experiences, setExperiences] = useState([]);
  const [approvedToPublish, setApprovedToPublish] = useState(false);
  const [recruiterAverageRating, setRecruiterAverageRating] = useState(0);
  const [consultantAverageRating, setConsultantAverageRating] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isCopyAllRecruiterComments, setIsCopyAllRecruiterComments] = useState(false);
  useEffect(() => {
    getFitScoreDetails();
  }, []);
  useEffect(() => {
    let data = experience_data.map(item => {
      return {
        ...item,
        ratings: {
          ...item.ratings,
          copyRecruiterCommentsFlag: false
        }
      };
    });
    setExperiences(data ?? []);
  }, [experience_data]);

  useEffect(() => {
    if (experiences && experiences.length > 0) {
      let recuriter_ratings_sum = 0;
      let consultant_ratings_sum = 0;
      experiences.map(item => {
        if (item?.ratings?.project_skill_approved_to_publish) {
          setApprovedToPublish(true);
        }
        recuriter_ratings_sum += parseFloat(item?.ratings?.project_skill_recruiter_rating ? item?.ratings?.project_skill_recruiter_rating : 0);
        consultant_ratings_sum += parseFloat(item?.ratings?.project_skill_consultant_rating ? item?.ratings?.project_skill_consultant_rating : 0);
      });
      setRecruiterAverageRating((recuriter_ratings_sum / experiences.length).toFixed(1));
      setConsultantAverageRating((consultant_ratings_sum / experiences.length).toFixed(1));
    }
  }, [experiences]);

  const handleCopyAllRecruiterComments = () => {
    let flagValue = !isCopyAllRecruiterComments;
    let data = [...experiences];
    const updatedData = data.map(item => {
      // Deep clone the ratings object to avoid mutating the original state
      let newItem = { ...item };
      if (newItem?.ratings) {
        newItem.ratings = { ...newItem.ratings };
        newItem.ratings.project_skill_consultant_comments = newItem.ratings.project_skill_recruiter_comments;
        newItem.ratings.copyRecruiterCommentsFlag = flagValue;
      }
      return newItem;
    });
    setExperiences(updatedData ?? []);
    setIsCopyAllRecruiterComments(flagValue);
  };

  const setApprovedToPublishAll = () => {
    let body = [...experiences];
    if (body && body.length > 0) {
      for (let i = 0; i < body?.length; i++) {
        if (!body[i]?.ratings) {
          body[i].ratings = {};
        }
        body[i].ratings['project_skill_approved_to_publish'] = !approvedToPublish;
      }
      setExperiences(body);
      setApprovedToPublish(!approvedToPublish);
    }
  };

  const { t } = useLanguageHooks([TRANS_KEYS.SAVE_AND_NEXT, TRANS_KEYS.CONTACTS_SAVE]);
  const handleSave = async () => {
    try {
      setLoading(true);
      const sub_route = '';
      // let skills = experiences.map(item => item.ratings);
      const { status, data } = await FitScoreDataApi(POST, sub_route, { candidate_id, project_id, skills: experiences, competencies: [] });
      if (status === 200) {
        enqueueSnackbar(data?.message || 'Fit data saved successfully', { variant: SUCCESS });
      } else {
        enqueueSnackbar(data?.message || 'Failed to save fit data', { variant: ERROR });
      }
      setLoading(false);
      return data;
    } catch (err) {
      console.log('Error in Save Fit Score Data', err);
      enqueueSnackbar(err?.message || 'Failed to save fit data', { variant: ERROR });
      setLoading(false);
    }
  };
  return (
    <>
      <Loader show={loading} />
      <Box className='mx-3 overflow-scroll' style={{ height: '50vh', overflowY: 'scroll', overflowX: 'hidden' }}>
        <ViewExperienceHeader
          isLogAnActivityOpen={isLogAnActivityOpen}
          approvedToPublish={approvedToPublish}
          setApprovedToPublish={setApprovedToPublish}
          recruiterAverageRating={recruiterAverageRating}
          consultantAverageRating={consultantAverageRating}
          setApprovedToPublishAll={setApprovedToPublishAll}
          isCopyAllRecruiterComments={isCopyAllRecruiterComments}
          handleCopyAllRecruiterComments={handleCopyAllRecruiterComments}
        />
        <ViewExperienceCard isCopyAllRecruiterComments={isCopyAllRecruiterComments} isLogAnActivityOpen={isLogAnActivityOpen} experiences={experiences} setExperiences={setExperiences} />

        <Stack className='m-2' direction='row' justifyContent={'flex-end'}>
          {/* <CustomButton onClick={handleSave} type={'tertiary'} variant='outlined' size={'small'} buttonText={t(`${TRANS_KEYS.SAVE_AND_NEXT}:key`)} /> */}
          <CustomButton onClick={handleSave} type={'primary'} variant='outlined' size={'small'} buttonText={t(`${TRANS_KEYS.CONTACTS_SAVE}:key`)} />
        </Stack>
      </Box>
    </>
  );
};

ViewExperience.propTypes = {
  isLogAnActivityOpen: PropTypes.bool,
  experience_data: PropTypes.array,
  project_id: PropTypes.string,
  candidate_id: PropTypes.string,
  getFitScoreDetails: PropTypes.func
};

ViewExperienceHeader.propTypes = {
  isLogAnActivityOpen: PropTypes.bool,
  approvedToPublish: PropTypes.bool,
  setApprovedToPublishAll: PropTypes.func,
  recruiterAverageRating: PropTypes.number,
  consultantAverageRating: PropTypes.number,
  isCopyAllRecruiterComments: PropTypes.bool,
  handleCopyAllRecruiterComments: PropTypes.func
};

ViewExperienceCard.propTypes = {
  isLogAnActivityOpen: PropTypes.bool,
  experiences: PropTypes.array,
  setExperiences: PropTypes.func,
  key: PropTypes.number,
  no: PropTypes.number
};
ExperienceItem.propTypes = {
  isLogAnActivityOpen: PropTypes.bool,
  experience: PropTypes.object,
  handleChange: PropTypes.func,
  key: PropTypes.number,
  no: PropTypes.number
};
export default ViewExperience;
