import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  colors: [],
  selectedColor: null
};

const viewProjectSlice = createSlice({
  name: 'project/viewProject',
  initialState,
  reducers: {
    updateViewProjectData(state, action) {
      state[action.payload.key] = action.payload.data;
    }
  }
});

export const { updateViewProjectData, selectedColor } = viewProjectSlice.actions;

export default viewProjectSlice.reducer;
