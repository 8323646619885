//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Typography, InputLabel, Button } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import AthenaInvitePopUp from '../../ViewSearch/Components/SFPA/Components/ClientAppAccess/AthenaInvitePopUp';
import { formatDate } from '../../../utils/date';

function Athena(props) {
  const { contactDetails, activityLog, getActivityLogInfo } = props;
  const contact = contactDetails ? contactDetails : activityLog.contact;
  const [isAthenaInviteOpen, toggleAthenaInviteOpen] = useState(false);
  const [isDisableSendButton, setIsDisableSendButton] = useState(false);
  const getName = () => {
    const firstName = contact.first_name || '';
    const lastName = contact.last_name || '';
    return firstName + ' ' + lastName;
  };

  const openAthenaPopUp = () => {
    if (!contact.work_email && !contact.private_email) {
      setIsDisableSendButton(true);
    } else {
      setIsDisableSendButton(false);
    }
    toggleAthenaInviteOpen(true);
  };

  const getAthenaSentUser = () => {
    const firstName = contact?.athena_sent_user?.first_name ? contact.athena_sent_user.first_name : '';
    const lastName = contact?.athena_sent_user?.last_name ? contact.athena_sent_user.last_name : '';
    if (lastName === '' && firstName === '') return 'N.A';
    else return firstName + ' ' + lastName;
  };

  const handleAthenaPopupClose = async invited => {
    try {
      toggleAthenaInviteOpen(false);
      if (invited === true) {
        getActivityLogInfo && (await getActivityLogInfo());
      }
    } catch (e) {
      console.log('Error found in handleAthenaPopupClose::', e);
    }
  };

  return (
    <div className='d-flex contact-view input-form' style={{ overflow: 'hidden' }}>
      {isAthenaInviteOpen ? (
        <AthenaInvitePopUp inviteFor={'candidate'} selectedDropDown={'Athena'} client={activityLog} isdisableSendButton={isDisableSendButton} onClose={handleAthenaPopupClose} />
      ) : null}
      <div className='w-100 section-content'>
        <Typography className='section-label'>{getName()}</Typography>
        <div className='d-flex flex-column mb-3'>
          <InputLabel style={{ fontSize: '12 !important' }} focused className='m-0'>
            Athena Invite Sent on
          </InputLabel>
          <span>{formatDate(contact?.athena_invitation_sent_on) || 'N.A'}</span>
        </div>
        <div className='d-flex flex-column mb-3'>
          <InputLabel style={{ fontSize: '12 !important' }} focused className='m-0'>
            Athena Invite Sent By
          </InputLabel>
          <span>{getAthenaSentUser() || 'N.A'}</span>
        </div>
        <div className='d-flex flex-column mb-3'>
          <InputLabel style={{ fontSize: '12 !important' }} focused className='m-0'>
            Athena First Logged In On
          </InputLabel>
          <span>{formatDate(contact?.first_athena_login) || 'N.A'}</span>
        </div>
        <div className='d-flex flex-column mb-3'>
          <InputLabel style={{ fontSize: '12 !important' }} focused className='m-0'>
            Athena Completed On
          </InputLabel>
          <span>{formatDate(contact?.athena_completion_date) || 'N.A'}</span>
        </div>

        {!contactDetails ? (
          <Button onClick={openAthenaPopUp} variant='contained' color='primary' className='mt-3' style={{ textTransform: 'none' }}>
            Send Invite for Athena
          </Button>
        ) : null}
      </div>
    </div>
  );
}

Athena.propTypes = {
  activityLog: PropTypes.object,
  contactDetails: PropTypes.object,
  getActivityLogInfo: PropTypes.func
};

export default Athena;
