import { Box, Checkbox, Grid, InputLabel, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/common/CustomButton';
import DynamicTabLayout from '../../../components/DynamicTabs/TabLayout';
import Search from '../Search';
import Attachments from './Attachments';
import Candidates from './Candidates';
import PositionProfile from './PositionProfile';
import ProjectInformation from './ProjectInformation';
const CloneProject = props => {
  const { t } = useTranslation();
  const [setList, setListShow] = useState(false);
  const [projectSearchValue, setProjectSearchValue] = useState('');
  const [universalState, setUniversalState] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const handleProjectSearchChange = event => {
    setProjectSearchValue(event.target.value);
  };

  const handleUniversalState = event => {
    const key = event.target.name;
    const value = event.target.value;
    universalState[key] = value;
    setUniversalState(Object.assign({}, universalState));
  };

  const getValueFromUniversalState = key => {
    return universalState[key] ?? '';
  };

  const TabData = [
    {
      label: t('project.cloneExistingProject'),
      content: '',
      subTabs: []
    }
  ];
  return (
    <div className='my-search d-flex flex-column'>
      <DynamicTabLayout
        navItems={TabData}
        baseRoute={'/projects/clone-project'}
        isDrawer={true}
        isDrawerCss={true}
        style={{ paddingTop: '100px', padding: '30px' }}
        activeTab={activeTab}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        setActiveTab={setActiveTab}
      >
        <Box index={t('project.cloneExistingProject')} height='auto' minHeight={'90vh'}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <InputLabel id='demo-multiple-name-label' className='pl-4'>
                Search Project to Copy from
              </InputLabel>
              <Search fullWidth value={projectSearchValue} onChange={handleProjectSearchChange} setListShow={setListShow} label='Search Project To Copy From' />
            </Grid>
            <Grid item xs={12} md={8}>
              <InputLabel id='demo-multiple-name-label' className='pl-2'>
                Client Contact Details
              </InputLabel>
              <Stack direction='row' justifyContent='flex-start'>
                <Stack direction='row' alignItems={'center'}>
                  <Checkbox />
                  <Typography variant='body2'>Attachments</Typography>
                </Stack>
                <Stack direction='row' alignItems={'center'}>
                  <Checkbox />
                  <Typography variant='body2'>Project Information</Typography>
                </Stack>
                <Stack direction='row' alignItems={'center'}>
                  <Checkbox />
                  <Typography variant='body2'>Candidates</Typography>
                </Stack>
                <Stack direction='row' alignItems={'center'}>
                  <Checkbox />
                  <Typography variant='body2'>Position Profile</Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Stack fullWidth direction='row' justifyContent='flex-end' className='p-2'>
            <CustomButton variant='contained' buttonText={`${t('common.add')} ${t('module.project')}}`} />
          </Stack>

          {/* Accordion Part */}
          <Box fullWidth className='p-1 py-2' height='100%'>
            <Attachments getValueFromUniversalState={getValueFromUniversalState} handleUniversalState={handleUniversalState} />
            <ProjectInformation getValueFromUniversalState={getValueFromUniversalState} handleUniversalState={handleUniversalState} />
            <Candidates getValueFromUniversalState={getValueFromUniversalState} handleUniversalState={handleUniversalState} />
            <PositionProfile getValueFromUniversalState={getValueFromUniversalState} handleUniversalState={handleUniversalState} />
          </Box>
        </Box>
      </DynamicTabLayout>
    </div>
  );
};

export default CloneProject;
