import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Checkbox, FormControlLabel, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import { ExpandableSection } from '../../../ActivityLog/Components/ExpandableSection';
import CustomButton from '../../../common/CustomButton';
import UserSelection from '../../../common/FunctionalComponents/UserSelection';
import CustomInputField from '../../../common/StyledComponents/CustomInputField';

const getCredits = list => {
  let total_origination_credit = 0;
  let total_selling_credit = 0;
  let total_execution_credit = 0;
  for (let i = 0; i < list.length; i++) {
    total_origination_credit += parseFloat(list[i].origination_credit || 0);
    total_selling_credit += parseFloat(list[i].selling_credit || 0);
    total_execution_credit += parseFloat(list[i].execution_credit || 0);
  }
  return { total_origination_credit, total_selling_credit, total_execution_credit };
};

const useHelperTextStyles = makeStyles(() => ({
  root: {
    whiteSpace: 'nowrap',
    overflow: 'hidden', // Hides any overflowed content
    textOverflow: 'ellipsis'
  }
}));

const TeamInfoTable = props => {
  const helperStyles = useHelperTextStyles();
  const { title = '', label = '', isPartner = false, inputConfig = {}, option, currentValues, bd = '', readOnlyMessage = '' } = props;
  const { t } = useLanguageHooks([
    TRANS_KEYS.BD_TEAM_NAME,
    TRANS_KEYS.BD_TEAM_LEAD,
    TRANS_KEYS.BD_TEAM_ORIGINATION,
    TRANS_KEYS.BD_TEAM_SELLING,
    TRANS_KEYS.BD_TEAM_EXECUTION,
    TRANS_KEYS.BD_TEAM_TOTAL_CREDIT
  ]);

  const headerData = [
    '',
    t(`${TRANS_KEYS.BD_TEAM_NAME}:key`),
    t(`${TRANS_KEYS.BD_TEAM_ORIGINATION}:key`),
    t(`${TRANS_KEYS.BD_TEAM_SELLING}:key`),
    t(`${TRANS_KEYS.BD_TEAM_EXECUTION}:key`),
    t(`${TRANS_KEYS.BD_TEAM_TOTAL_CREDIT}:key`),
    ''
  ];

  const { list = [], fields = {}, setter } = inputConfig;

  const [isError, setIsError] = useState(false);
  const [credits, setCredits] = useState({});
  const [isRowDeleted, setIsRowDeleted] = useState(false);

  const addElement = () => {
    const updatedList = [...list, {}];
    setter(updatedList);
  };

  const removeElement = index => {
    let updatedList = [...list];
    updatedList.splice(index, 1);
    updateCredits(updatedList);
    if (bd === 'AddBD') {
      currentValues != undefined && currentValues.basic_partners != undefined && currentValues.basic_partners.splice(index, 1);
    }
    setter(updatedList);
    setIsRowDeleted(!isRowDeleted);
  };
  const handleUserChange = (index, name, data) => {
    let newList = [...list];
    newList[index] = { ...newList[index], [name]: data };
    const updatedList = [...newList];

    setter(updatedList);
  };
  const updateCredits = useCallback(list => {
    setCredits(getCredits(list));
  }, []);
  const checkForError = useCallback(
    list => {
      const { total_origination_credit, total_selling_credit, total_execution_credit } = getCredits(list);
      let isError = false;
      if (fields.origination_credit) {
        if (total_origination_credit > fields.origination_credit) {
          isError = true;
        }
      }
      if (fields.selling_credit) {
        if (total_selling_credit > fields.selling_credit) {
          isError = true;
        }
      }
      if (fields.execution_credit) {
        if (total_execution_credit > fields.execution_credit) {
          isError = true;
        }
      }
      return isError;
    },
    [fields]
  );

  const handleChange = (index, name, data) => {
    const value = data && Math.abs(data);
    let newList = [...list];

    if (parseFloat(value) < 0) {
      setIsError(true);
    } else {
      newList[index] = { ...newList[index], [name]: parseFloat(value) };
    }

    let isError = checkForError(newList);
    const { total_origination_credit, total_selling_credit, total_execution_credit } = getCredits(newList);
    let isErrorRelatedToSameField = false;
    if (isError) {
      if (fields.origination_credit) {
        if (total_origination_credit > fields.origination_credit) {
          isErrorRelatedToSameField = isErrorRelatedToSameField || checkIsErrorRelatedToSameField(name, 'origination_credit_error');
        }
      }
      if (fields.selling_credit) {
        if (total_selling_credit > fields.selling_credit) {
          isErrorRelatedToSameField = isErrorRelatedToSameField || checkIsErrorRelatedToSameField(name, 'selling_credit_error');
        }
      }
      if (fields.execution_credit) {
        if (total_execution_credit > fields.execution_credit) {
          isErrorRelatedToSameField = isErrorRelatedToSameField || checkIsErrorRelatedToSameField(name, 'execution_credit_error');
        }
      }
    } else {
      newList = newList.map(item => ({ ...item, [`${name}_error`]: false }));
    }

    if (isError && isErrorRelatedToSameField) {
      newList[index][`${name}_error`] = true;
    } else {
      newList[index][`${name}_error`] = false;
    }
    newList.forEach(item => {
      if (item[name] && isNaN(item[name])) {
        item[`${name}_error`] = false;
      }
    });
    setIsError(isError);
    setter([...newList]);
  };
  useEffect(() => {
    updateCredits(list);
    let isError = checkForError(list);
    setIsError(isError);
  }, [updateCredits, checkForError, list]);

  const getTotalCredit = item => {
    return parseFloat(item.origination_credit || 0) + parseFloat(item.selling_credit || 0) + parseFloat(item.execution_credit || 0);
  };
  const checkIsErrorRelatedToSameField = (fieldName, errorField) => {
    return errorField.includes(fieldName);
  };
  const getGrandTotalCredit = () => {
    return parseFloat(credits.total_origination_credit || 0) + parseFloat(credits.total_selling_credit || 0) + parseFloat(credits.total_execution_credit || 0);
  };

  const [leadPartnerIndex, setLeadPartnerIndex] = useState(0);

  const getColumnWidthForHeader = index => {
    if (isPartner) {
      if (index === 0) return 0.5;
      if (index === 1) return 3;
      if (index === 2) return 0.5;
      if (index === 3) return 1.8;
      if (index === 4) return 1.8;
      if (index === 5) return 1.8;
      if (index === 6) return 1.8;
      if (index === 7) return 0.5;
    } else {
      if (index === 0 || index === headerData.length - 1) {
        return 0.5;
      } else if (index === 1) {
        return 3;
      }
      return 2;
    }
  };

  const getColumnWidth = index => {
    if (isPartner) {
      if (index === 0) return 0.5;
      if (index === 1) return 3;
      if (index === 2) return 0.5;
      if (index === 3) return 1.8;
      if (index === 4) return 1.8;
      if (index === 5) return 1.8;
      if (index === 6) return 1.8;
      if (index === 7) return 0.5;
    } else {
      if (index === 0) return 0.5;
      if (index === 1) return 3;
      if (index === 2) return 2;
      if (index === 3) return 2;
      if (index === 4) return 2;
      if (index === 5) return 2;
      if (index === 6) return 2;
      if (index === 7) return 0.5;
    }
  };

  const getPartnersHeaders = () => {
    const copyArr = [...headerData];
    copyArr?.splice(2, 0, t(`${TRANS_KEYS.BD_TEAM_LEAD}:key`));
    return copyArr;
  };

  useEffect(() => {
    const leadPartnerIndex = list.findIndex(ele => ele?.is_lead === true);
    setLeadPartnerIndex(leadPartnerIndex);
  }, [list]);

  return (
    <Box>
      <Box className='d-flex flex-column  my-2'>
        <ExpandableSection title={title} defaultOpen={true} isTeamInfo={true} accordionClassName='accordion' titleClassName={readOnlyMessage ? 'read-only' : 'title-text'}>
          <Box className={`${readOnlyMessage ? 'layout-content read-only' : ''}`}>
            <Grid container xs={isPartner ? 14 : 12} alignItems='center' className='table-header fs-13 py-2 px-1'>
              {(isPartner ? getPartnersHeaders() : headerData).map((header, index) => (
                <Grid item key={index} xs={getColumnWidthForHeader(index)}>
                  <Typography variant='body2' align='center' className={readOnlyMessage ? 'read-only' : ''}>
                    {header}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <Box className={`table-body ${isError ? 'validation-error' : ''}`}>
              {list.length > 0 ? (
                list.map((elem, index) => {
                  return (
                    <Grid xs={12} container alignItems='center' className='py-2 mb-2' key={index} style={{ alignItems: 'flex-start' }}>
                      <Grid item xs={getColumnWidth(0)} className='d-flex justify-content-center' alignItems='stretch'>
                        <Typography className='d-flex justify-content-center'>{index + 1}.</Typography>
                      </Grid>
                      <Grid item xs={getColumnWidth(1)}>
                        <Box display='flex' alignItems='center' gap={2}>
                          <UserSelection value={elem.user || null} option={option} list={list} onChange={(e, data) => handleUserChange(index, 'user', data)} />
                          <Box className='profile-image-container'>
                            <Box className='image-containers'>
                              <Box className='default-profile-icon'>
                                <AccountCircleIcon fontSize='inherit' />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      {isPartner && (
                        <Grid item xs={getColumnWidth(2)}>
                          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginLeft={'1.5rem'} marginTop={'0.5rem'}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  className='checkbox-component'
                                  checked={leadPartnerIndex === index}
                                  defaultChecked={leadPartnerIndex === index}
                                  size='small'
                                  style={{ padding: '0 5px' }}
                                  defaultValue={leadPartnerIndex}
                                  onChange={() => {
                                    setLeadPartnerIndex(index);
                                    const newList = list.map((ele, i) => {
                                      return { ...ele, is_lead: i === index };
                                    });
                                    setter(newList);
                                  }}
                                />
                              }
                            />
                          </Box>
                        </Grid>
                      )}
                      <Grid item xs={getColumnWidth(3)} className='pl-2 text-wrap team-info-table-input'>
                        {fields.origination_credit ? (
                          <CustomInputField
                            type='number'
                            onChange={e => handleChange(index, 'origination_credit', e.target.value)}
                            value={elem.origination_credit || null}
                            error={elem.origination_credit_error ? true : false}
                            helperText={
                              elem.origination_credit_error && (
                                <Tooltip title={`${label}s Origination Credit total cannot be more than ${fields.origination_credit}%.`}>
                                  {`${label}s Origination Credit total cannot be more than ${fields.origination_credit}%.`}
                                </Tooltip>
                              )
                            }
                            FormHelperTextProps={{ classes: { root: helperStyles.root } }}
                          />
                        ) : (
                          <Box></Box>
                        )}
                      </Grid>
                      <Grid item xs={getColumnWidth(4)} className='pl-2 text-wrap team-info-table-input'>
                        {fields.selling_credit ? (
                          <CustomInputField
                            type='number'
                            value={elem.selling_credit || null}
                            onChange={e => handleChange(index, 'selling_credit', e.target.value)}
                            error={elem.selling_credit_error ? true : false}
                            helperText={
                              elem.selling_credit_error && (
                                <Tooltip title={`${label}s Selling Credit total cannot be more than ${fields.selling_credit}%.`}>
                                  {`${label}s Selling Credit total cannot be more than ${fields.selling_credit}%.`}
                                </Tooltip>
                              )
                            }
                            FormHelperTextProps={{ classes: { root: helperStyles.root } }}
                          />
                        ) : (
                          <Box></Box>
                        )}
                      </Grid>
                      <Grid item xs={1.8} className='pl-2 text-wrap team-info-table-input'>
                        {fields.execution_credit ? (
                          <CustomInputField
                            type='number'
                            value={elem.execution_credit || null}
                            onChange={e => handleChange(index, 'execution_credit', e.target.value)}
                            error={elem.execution_credit_error ? true : false}
                            helperText={
                              elem.execution_credit_error && (
                                <Tooltip title={`${label}s Execution Credit total cannot be more than ${fields.execution_credit}%.`}>
                                  {`${label}s Execution Credit total cannot be more than ${fields.execution_credit}%.`}
                                </Tooltip>
                              )
                            }
                            FormHelperTextProps={{ classes: { root: helperStyles.root } }}
                          />
                        ) : (
                          <Box></Box>
                        )}
                      </Grid>
                      <Grid item xs={getColumnWidth(6)} className={`sum-value d-flex justify-content-center ${getGrandTotalCredit() === 100 && !isError && 'text-success'}`}>
                        {getTotalCredit(elem)}
                      </Grid>
                      <Grid item xs={getColumnWidth(7)}>
                        {((label === 'Partner' && list.length > 1) || label !== 'Partner') && (
                          <IconButton size='small' aria-label='delete' onClick={() => removeElement(index)}>
                            <CloseIcon className='close-icon' fontSize='inherit' />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <Box className='d-flex no-data-added'> {label} info is not added</Box>
              )}
              {list.length > 0 ? (
                <Grid container alignItems='center'>
                  <Grid item xs={0.5}></Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={0.5}></Grid>
                  <Grid item xs={1.8} className='d-flex justify-content-center'>
                    {fields.origination_credit ? (
                      <Typography className={`${!isError ? 'total-content' : 'text-red'}`}>
                        <strong>{credits['total_origination_credit'] || 0}</strong>
                      </Typography>
                    ) : (
                      <Box></Box>
                    )}
                  </Grid>
                  <Grid item xs={1.8} className='d-flex justify-content-center'>
                    {fields.selling_credit ? (
                      <Typography className={`${!isError ? 'total-content' : 'text-red'}`}>
                        <strong>{credits['total_selling_credit'] || 0}</strong>
                      </Typography>
                    ) : (
                      <Box></Box>
                    )}
                  </Grid>
                  <Grid item xs={1.8} className='d-flex justify-content-center'>
                    {fields.execution_credit ? (
                      <Typography className={`${!isError ? 'total-content' : 'text-red'}`}>
                        <strong>{credits['total_execution_credit'] || 0}</strong>
                      </Typography>
                    ) : (
                      <Box></Box>
                    )}
                  </Grid>
                  <Grid item xs={1.8} className='d-flex justify-content-center'>
                    <Typography className='total-content'>
                      <strong>{getGrandTotalCredit()}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={0.5}></Grid> {/* Empty grid item for alignment */}
                </Grid>
              ) : (
                ''
              )}
            </Box>
            <Box className='table-footer team-add-button'>
              <CustomButton
                variant='text'
                startIcon={<AddIcon sx={{ width: 14 }} />}
                onClick={addElement}
                customWidth={96}
                buttonText={`Add  ${list.length > 0 ? 'Another' : ''} ${label}`}
                type='secondary'
                size='medium'
              />
            </Box>
          </Box>
        </ExpandableSection>
      </Box>
      {readOnlyMessage ? (
        <Box className={readOnlyMessage ? 'layout-content read-only' : ''}>
          <Box className='mb-2' dangerouslySetInnerHTML={{ __html: readOnlyMessage }}></Box>{' '}
        </Box>
      ) : null}
    </Box>
  );
};

TeamInfoTable.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  inputConfig: PropTypes.object.isRequired,
  option: PropTypes.array,
  currentValues: PropTypes.object,
  bd: PropTypes.string,
  readOnlyMessage: PropTypes.string,
  isPartner: PropTypes.bool
};

export default TeamInfoTable;
