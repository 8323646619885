import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { FormControlLabel, Switch, TextField, InputLabel } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import CurrencySelection from '../../../../CurrencySelection';
import RegionSelection from '../../../../AddUser/components/RegionSelection';
import InputField from '../../../../common/InputField';
import RichTextPopup from '../../../../RichTextPopup';
import IntervalSelection from '../../../../CreateSearch/Components/BillingInfo/Components/IntervalSelection';
import TermTypeSelection from '../../../../CreateSearch/Components/BillingInfo/Components/TermTypeSelection';
import '../billingDetails.scss';
import { getValueWithoutHtml } from '../../../../../utils/common';
import { FEE_TYPES } from '../../../../../services/constantService';
import FeetypeSelection from '../../../../FeetypeSelection';

const BillingForm = props => {
  const { register, watch, setValue, editingField, currentValues, currencyDetails } = props;
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Opportunity#</InputLabel>
          <span className='page-label-value'>{currentValues.job_number || '--'}</span>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Service Offering</InputLabel>
          <span className='page-label-value'>{currentValues.brand_desc ? currentValues.brand_desc : currentValues.brand || '--'}</span>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Currency</InputLabel>
          {editingField === 'currency' ? (
            <CurrencySelection
              label=''
              customInput={TextField}
              required={true}
              value={watch('currency') || null}
              className='input-form-field'
              InputLabelProps={{ focused: true }}
              onChange={(e, data) => {
                setValue('currency', data ? data.code : null);
              }}
              variant='outlined'
            />
          ) : (
            <span className='page-label-value'>{currentValues && currentValues.currency ? currentValues.currency : '--'}</span>
          )}
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Geography</InputLabel>
          <span className='page-label-value'>{(currentValues.markets && currentValues.markets.market) || '--'}</span>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Fee Cap</InputLabel>
          {editingField === 'is_fee_cap' ? (
            <FormControlLabel
              control={
                <Switch
                  checked={watch('is_fee_cap') || false}
                  onChange={e => {
                    setValue('is_fee_cap', e.target.checked);
                  }}
                  color='primary'
                  {...register('is_fee_cap')}
                  name={'is_fee_cap'}
                />
              }
              label=''
              className='input-form-field'
            />
          ) : (
            <span className='page-label-value'>{currentValues && currentValues.is_fee_cap ? 'Yes' : 'No'}</span>
          )}
        </div>
        {currentValues && currentValues.is_fee_cap === true && (
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Fee Cap Amount</InputLabel>
            {editingField === 'fee_cap_amount' ? (
              <InputField
                variant='outlined'
                {...register('fee_cap_amount')}
                className='input-form-field'
                label=''
                type='number'
                placeholder='Fee Cap Amount'
                InputLabelProps={{ focused: true }}
                name='fee_cap_amount'
              />
            ) : (
              <span className='page-label-value'>{currentValues && currentValues.fee_cap_amount ? `${currencyDetails.currency_icon} ${currentValues.fee_cap_amount}` : '--'}</span>
            )}
          </div>
        )}
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Term Type</InputLabel>
          {/* <ContactSelection
            className='add-bd-input'
            label={``}
            {...register(`contact_name`)}
            defaultValue={watch('contact_name') || null}
            InputLabelProps={{ focused: true }}
            onChange={(e, data) => {
              let contact = data || {};
              setValue('contact_name', data)
              setValue("mobile_phone", contact.mobile_phone || null)
              setValue("work_email", contact.work_email || null)
            }}
            variant="outlined"
          /> */}
          {editingField === 'term_type' ? (
            <TermTypeSelection defaultValue={watch('term_type') || null} className='w-100' variant='outlined' onChange={e => setValue('term_type', e.target.value)} name='term_type' />
          ) : (
            <span className='page-label-value'>{currentValues && currentValues.term_desc ? currentValues.term_desc : '--'}</span>
          )}
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Intervals</InputLabel>
          {editingField === 'intervals' ? (
            <IntervalSelection defaultValue={watch('intervals') || null} className='w-100' variant='outlined' onChange={e => setValue('intervals', e.target.value)} name='intervals' />
          ) : (
            <span className='page-label-value'>{currentValues && currentValues.intervals_desc ? currentValues.intervals_desc : '--'}</span>
          )}
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Billing Region</InputLabel>
          {editingField === 'intervals' ? (
            <RegionSelection
              className='input-form-field'
              defaultValue={watch('billing_region') || null}
              {...register('billing_region')}
              label={''}
              InputLabelProps={{ focused: true }}
              onChange={(e, data) => {
                setValue('billing_region', data ? data.name : '');
              }}
              variant='outlined'
            />
          ) : (
            <span className='page-label-value'>{currentValues && currentValues.billing_region ? currentValues.billing_region : '--'}</span>
          )}
        </div>
        {currentValues.intervals === 'Other Interval' ? (
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Interval Other</InputLabel>
            <span className='page-label-value'>{currentValues && currentValues.intervals_other ? currentValues.intervals_other : '--'}</span>
          </div>
        ) : null}
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Fee Type</InputLabel>
          {editingField === 'intervals' ? (
            <FeetypeSelection
              variant='outlined'
              className='input-form-field'
              defaultValue={watch('fee_type_desc') || watch('fee_type') || FEE_TYPES.ONE_THIRD}
              {...register('fee_type_desc')}
              InputLabelProps={{ focused: true }}
              placeholder='Fee Type'
              type='FEE_TYPE'
              onChange={(e, data) => {
                setValue('fee_type', data ? data.field_value : null);
                setValue('fee_type_desc', data ? data.short_desc : null);
                setValue('fee_percentage', !data || data.short_desc === FEE_TYPES.ONE_THIRD ? 33.33 : !data || data.short_desc === FEE_TYPES.FIXED_FEE ? 0 : null);
              }}
              label=''
            />
          ) : (
            <span className='page-label-value'>
              {currentValues && currentValues.fee_type_desc ? currentValues.fee_type_desc : currentValues.fee_type ? currentValues.fee_type : FEE_TYPES.ONE_THIRD}
            </span>
          )}
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className='page-label'>Fee Percentage</InputLabel>
          {editingField === 'intervals' ? (
            <InputField
              variant='outlined'
              defaultValue={watch('fee_percentage') || ''}
              className='input-form-field'
              label=''
              type='number'
              InputLabelProps={{ focused: true }}
              onChange={e => setValue('fee_percentage', e.target.value)}
              name='fee_percentage'
            />
          ) : (
            <span className='page-label-value'>{currentValues && currentValues.fee_percentage ? currentValues.fee_percentage : '--'}</span>
          )}
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view '>
          <InputLabel className='page-label'>Billing Notes</InputLabel>
          {editingField === 'intervals' ? (
            <div className='outlined-box'>
              <RichTextPopup
                className=''
                label='Billing Notes'
                placeholder='Billing Notes'
                InputLabelProps={{ focused: true }}
                name='billing_notes'
                variant='outlined'
                value={watch('billing_notes') || null}
                onSave={data => setValue('billing_notes', data.billing_notes)}
              />
            </div>
          ) : getValueWithoutHtml(currentValues?.billing_notes) ? (
            <div className='page-label-value' style={{ maxHeight: 'fit-content' }}>
              <RichTextPopup
                className='input-form-field padding-rich-remove'
                setOpen={false}
                {...register('billing_notes')}
                placeholder=''
                InputLabelProps={{ focused: true }}
                name='billing_notes'
                variant='outlined'
                value={currentValues?.billing_notes || ''}
              />
            </div>
          ) : (
            <span className='page-label-value'>{'--'}</span>
          )}
        </div>
      </div>
    </div>
  );
};

BillingForm.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  editingField: PropTypes.string,
  currentValues: PropTypes.object,
  currencyDetails: PropTypes.object
};

export default BillingForm;
