import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';
import CustomMuiDropdown from '../../CustomMuiDropdown';

const VerticalPracticeSelection = props => {
  const dispatch = useDispatch();
  const verticalPractice = useSelector(state => state.commonReducer.vertical_practice);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!verticalPractice) {
      dispatch(fetchPickLists('VERTICAL_PRACTICE', 'vertical_practice'));
    } else {
      setOptions(verticalPractice);
    }
  }, [verticalPractice, dispatch]);

  const { className = 'SingleSelectStyle', onChange = () => {}, isDrawer = false, label = '', defaultValue, required, value, size = 'small', ...rest } = props;
  // const probabilityOptions = [
  //   { label: "0%", value: "0" },
  //   { label: "25%", value: "25" },
  //   { label: "50%", value: "50" },
  //   { label: "75%", value: "75" },
  //   { label: "100%", value: "100" },
  // ];

  return (
    <>
      <CustomMuiDropdown
        {...rest}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return options?.find(item => item?.field_value === option)?.short_desc;
          }
          return option.short_desc;
        }}
        value={value}
        label={label}
        options={options}
        className={className}
        onChange={onChange}
        isDrawer={isDrawer}
        required={required}
        defaultValue={defaultValue}
        size={size}
      />
    </>
  );
};

VerticalPracticeSelection.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  isDrawer: PropTypes.bool,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  size: PropTypes.string
};

export default VerticalPracticeSelection;
