import React from 'react';

import { Checkbox, Switch } from '@mui/material';
import PropTypes from 'prop-types';

const columnDefs = [
  {
    field: 'name',
    headerName: 'Resources',
    cellRenderer: 'nameRenderer',
    width: 200,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'ign_role_resources.can_create',
    headerName: 'Create',
    cellRenderer: 'switchRenderer',
    width: 134,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'ign_role_resources.can_read',
    headerName: 'Read',
    cellRenderer: 'switchRenderer',
    width: 134,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'ign_role_resources.can_update',
    headerName: 'Update',
    cellRenderer: 'switchRenderer',
    width: 134,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'ign_role_resources.can_delete',
    headerName: 'Delete',
    cellRenderer: 'switchRenderer',
    width: 134,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'of_type',
    headerName: 'Type',
    width: 200,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'parent',
    headerName: 'Parent',
    width: 200,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  }
];

function createNestedArray(data, parentName) {
  const nestedArray = [];
  const remainingData = [];

  // Find objects with parent as Root
  const rootObjects = data.filter(item => item.parent === parentName);

  // Create nested arrays with the first element being the root object
  rootObjects.forEach(rootObject => {
    const nested = [rootObject];
    nestedArray.push(nested);

    // Check for remaining elements in the original array
    remainingData.push(...data.filter(item => item.parent !== parentName && item.parent === rootObject.name));
  });

  // Continue until nothing is left in the remainingData
  while (remainingData.length > 0) {
    remainingData.forEach(item => {
      nestedArray.forEach(nested => {
        if (item.parent === nested[0].name) {
          nested.push(item);
        }
      });
    });

    // Remove elements from remainingData that have been inserted into nested arrays
    const remainingCopy = [...remainingData];
    remainingCopy.forEach(item => {
      nestedArray.forEach(nested => {
        if (item.parent === nested[0].name) {
          remainingData.splice(remainingData.indexOf(item), 1);
        }
      });
    });
  }

  return nestedArray;
}

function SwitchRenderer(props) {
  const { data, colDef, togglePermissions } = props;

  const field = colDef.field.split('.')[1];

  if (!data.resourceData) return <Switch defaultChecked={false} disabled size='small' />; // Just to prevent eager loading of switch

  return (
    <Switch
      defaultChecked={data.resourceData[field]}
      size='small'
      onChange={e => {
        togglePermissions(data.resourceData.id, {
          ...data.resourceData,
          [field]: e.target.checked
        });
      }}
    />
  );
}

function NameRenderer(props) {
  const { data, toggleResource } = props;

  const resourceId = data.id;
  const userResourcesDataId = data?.resourceData?.id;

  const isChecked = userResourcesDataId ? true : false;

  return (
    <div className='d-flex align-items-center w-100'>
      <Checkbox
        defaultChecked={isChecked}
        onChange={e => {
          toggleResource(resourceId, e.target.checked);
        }}
      />
      <p>{props.value}</p>
    </div>
  );
}

NameRenderer.propTypes = {
  value: PropTypes.string,
  data: PropTypes.object,
  colDef: PropTypes.object,
  toggleResource: PropTypes.func
};

SwitchRenderer.propTypes = {
  value: PropTypes.bool,
  data: PropTypes.object,
  colDef: PropTypes.object,
  togglePermissions: PropTypes.func
};

export { columnDefs, createNestedArray, SwitchRenderer, NameRenderer };
