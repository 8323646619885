//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import Button from '@mui/material/Button';

// ----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';

function TabPanel(props) {
  const { children, value, index } = props;

  return value === index && <React.Fragment>{children}</React.Fragment>;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    //   top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: '#f1a06b !important'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#f1a06b !important'
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#c3c3c35c',
    borderRadius: 1
  }
})(StepConnector);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  container: {
    backgroundColor: 'transparent'
    // cursor: 'pointer'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const TabLayoutWithSteppers = props => {
  // statusMessage = '', to be put in the props
  const {
    children,
    navItems,
    tabChange,
    onCancel,
    onClose,
    fieldItem = '',
    handleTabValue = () => {},
    showTab,
    editPopup = '',
    bd,
    steppersProps = {},
    tabValueNumber,
    setTabValueNumber = () => {}
  } = props;

  const classes = useStyles();
  const [tabValue, setTabValue] = useState(showTab ? showTab : 0);

  const handleNext = () => {
    if (tabValue < 2) {
      handleChange(tabValue + 1);
    }
    if (tabValue === 2) {
      // setIsSaveAndNextClicked(true)
      handleChange(tabValue + 1, 'saveButton');
    }
  };
  const handleBack = () => {
    handleChange(tabValue - 1);
    // setIsSaveAndNextClicked(false)
  };

  const handleChange = async (newValue, saveButton = '') => {
    try {
      if (tabValue === newValue) return;
      if (fieldItem !== 'ViewBd') {
        const result = await tabChange(tabValue, newValue, saveButton);
        if (result && newValue !== navItems?.length) {
          setTabValue(newValue);
          handleTabValue(newValue);
        }
        if (result) {
          if (tabValue === 2 && saveButton === 'saveButton') {
            onClose();
          }
          return;
        }
      } else {
        setTabValue(newValue);
        handleTabValue && handleTabValue(newValue);
      }
    } catch (e) {
      console.log('Error found in handleChange::', e);
    }
  };

  const buildTabDetails = () => {
    return children
      ?.filter(item => item)
      .map((item, index) => {
        return (
          <TabPanel key={index} index={index} value={tabValue}>
            {item}
          </TabPanel>
        );
      });
  };

  useEffect(() => {
    if (tabValueNumber || tabValueNumber === 0) {
      setTabValue(tabValueNumber);
      setTabValueNumber(null);
    }
  }, [tabValueNumber]);

  return (
    <>
      <div className={`${classes.root} mt-2 `}>
        <div className='container d-flex align-items-center justify-content-between tab-info-container'>
          <div className={`${tabValue === 0 ? 'invisible' : null} align-values w-25 text-start`}>
            <Button disabled={tabValue === 0} onClick={handleBack}>
              <b className='back-button'>{'< Back'}</b>
            </Button>
          </div>
          <div className='w-50'>
            {bd && bd.billing_desc !== null && bd.billing_desc !== 'None' && <div className='status-message'>{bd.billing_desc}</div>}
            <Stepper alternativeLabel className={classes.container} activeStep={tabValue} connector={<ColorlibConnector />}>
              {navItems.map((label, index) => (
                <Step className='step' {...steppersProps} onClick={() => handleChange(index)} key={label}>
                  <StepLabel>
                    <span className={`${index === tabValue ? 'step-child-border' : ''} step-child`}>{label}</span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div className='align-values w-25 text-end'>
            {/* {
              fieldItem !== "ViewBd" && <Button
                onClick={onCancel}
                className={classes.button}
              >
                <b>Cancel</b>
              </Button>
            } */}
            {editPopup === 'editPopup' && (
              <Button onClick={onCancel} className={classes.button}>
                <b className='cancel-button'>Cancel</b>
              </Button>
            )}
            <Button
              onClick={handleNext}
              className={classes.button}
              style={{ background: tabValue === navItems?.length - 1 && fieldItem !== 'ViewBd' ? '#4BB543' : null, color: tabValue === navItems?.length - 1 ? '#ffff' : null }}
            >
              <b className='save-and-next-button'>{fieldItem === 'ViewBd' ? tabValue < 2 && 'Next >' : tabValue === navItems?.length - 1 ? 'Save' : 'Save & Next >'}</b>
            </Button>
            {/* {fieldItem !== "ViewBd" && tabValue === navItems?.length - 1 ? (<Button
              onClick={onClose}
              className={classes.button}
            >
              <b>Close</b>
            </Button>) : null} */}
          </div>
        </div>
      </div>
      <div className='add-bd-content w-100'>{buildTabDetails(props.children)}</div>
    </>
  );
};

TabLayoutWithSteppers.propTypes = {
  children: PropTypes.node,
  navItems: PropTypes.array,
  tabChange: PropTypes.func,
  handleTabValue: PropTypes.func,
  onCancel: PropTypes.func,
  setTabValueOfBusinessDev: PropTypes.func,
  onClose: PropTypes.func,
  statusMessage: PropTypes.string,
  bd: PropTypes.object,
  fieldItem: PropTypes.string,
  tabNumber: PropTypes.number,
  showTab: PropTypes.number,
  editPopup: PropTypes.string,
  steppersProps: PropTypes.object,
  tabValueNumber: PropTypes.number,
  setTabValueNumber: PropTypes.func
};

export default TabLayoutWithSteppers;
