//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import GroupIcon from '@mui/icons-material/Group';
import PhoneIcon from '@mui/icons-material/Phone';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchPickList } from '../../../../../actions';

const methods = {
  'Face to Face': <GroupIcon style={{ margin: '3px 0' }} color='primary' />,
  Video: <PlayCircleOutlineIcon style={{ margin: '3px 0' }} color='primary' />,
  Phone: <PhoneIcon style={{ margin: '3px 0' }} color='primary' />
};

function MethodSelection(props) {
  const { label = 'Type', placeholder = '', InputLabelProps, ...rest } = props;

  const [options, setOptions] = useState([]);
  const clientInterviewTypes = useSelector(state => state.commonReducer.clientInterviewTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!clientInterviewTypes) {
      dispatch(fetchPickList('client-interview-types', 'clientInterviewTypes'));
    } else {
      setOptions(clientInterviewTypes);
    }
  }, [clientInterviewTypes, dispatch]);

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionSelected={(option, value) => {
        if (typeof value === 'string') {
          return option.name === value;
        }
        return option.name === value.name;
      }}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      renderOption={(props, option) => (
        <li {...props}>
          <div className='d-flex align-items-center'>
            {methods[option.name]}
            <Typography style={{ marginLeft: 10 }}>{option.name}</Typography>
          </div>
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: methods[params.inputProps.value]
          }}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}

MethodSelection.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object
};

export default MethodSelection;
