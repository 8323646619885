import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Box, TableCell, TableRow } from '@mui/material';
import CustomTable from '../../../common/CustomTable';

//----------------------------------------------// Internal Imports // -------------------------------------------------

const PercentageOfBase = props => {
  const { type = 'estimated', getValueWithCurrency, currentValues } = props;
  const label = type === 'estimated' ? 'Estimated' : 'Actual';
  // const feeTypes = useSelector(state => state.commonReducer['EST_FEE_TYPE'])

  return (
    <Box id='container-communication'>
      <Box className='head-container'>
        <Box className='sub-head-content'>
          <Box className='field-label-input'>
            <Box className='d-flex flex-column  my-2'>
              <CustomTable
                headerData={['Fee Items', 'Value', 'Calculated Amount']}
                title={`Base Fee - ${label}`}
                rows={currentValues?.estimated_percentage_bases}
                showAddAnotherButton={false}
                // dataList={phoneList}
              >
                {currentValues?.estimated_percentage_bases.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className='input-field- p-2'>
                        <Box className=''>{item?.type || '--'}</Box>
                      </TableCell>
                      <TableCell className='p-2'>
                        <Box className=''>{item[`${type}_amt`] ? getValueWithCurrency(item[`${type}_amt`]) : '--'}</Box>
                      </TableCell>
                      <TableCell className='primary-checkbox p-2'>{<Box className=''>{item[`calculated_${type}_amt`] ? getValueWithCurrency(item[`calculated_${type}_amt`]) : '--'}</Box>}</TableCell>
                    </TableRow>
                  );
                })}
              </CustomTable>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

PercentageOfBase.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  watch: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  getValueWithCurrency: PropTypes.func,
  fee_type: PropTypes.string
};

export default PercentageOfBase;
