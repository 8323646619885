//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { CLIENTS } from '../../../../../services/constantService';
import { fetchClientList } from '../../../../../actions';

function ClientSelection(props) {
  const { label = 'Client', placeholder = '', InputLabelProps, searchId, ...rest } = props;

  const [options, setOptions] = useState([]);
  const clients = useSelector(state => state.rootReducer.clients);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!clients) {
      dispatch(fetchClientList(CLIENTS, searchId));
    } else {
      setOptions(clients.map(item => ({ ...item.contact })));
    }
  }, [searchId, clients]);

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionLabel={option => (option.first_name || '') + ' ' + (option.last_name || '')}
      getOptionSelected={(option, value) => option.id === value.id}
      renderInput={params => <TextField {...params} InputLabelProps={InputLabelProps} label={label} placeholder={placeholder} />}
    />
  );
}

ClientSelection.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  searchId: PropTypes.string,
  enqueueSnackbar: PropTypes.func
};

export default ClientSelection;
