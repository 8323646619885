import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomSelectionComponent from '../../CustomSelectionComponent';
const TagGridActionSelection = props => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([{ short_desc: '' }, { short_desc: t('actions.delete') }]);
  const { params, handledeleteTag, handleChangeStatus } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (params?.data?.status !== undefined) {
      const action = params.data.status === false ? t('actions.activate') : t('actions.deactivate');
      setOptions(prevOptions => [{ short_desc: action }, ...prevOptions.slice(1)]);
    }
  }, [params?.data?.status]);

  const customButtonForAllContact = <MoreVertIcon className='moveVerticalIconStyle' onClick={handleClick} />;
  const handleMenuItemClick = option => {
    if (option?.short_desc === t('actions.delete')) {
      handledeleteTag(params?.data, params?.data.id, option?.short_desc);
    } else {
      handleChangeStatus(params?.data, option?.short_desc);
    }
    setAnchorEl(null);
  };

  return <CustomSelectionComponent options={options} handleMenuItemClick={handleMenuItemClick} component={customButtonForAllContact} setAnchorEl={setAnchorEl} anchorEl={anchorEl} />;
};
TagGridActionSelection.propTypes = {
  params: PropTypes.object,
  handledeleteTag: PropTypes.func,
  handleChangeStatus: PropTypes.func
};

export default TagGridActionSelection;
