import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import BillingForm from './component/BillingForm';
import PercentageOfBase from './component/PercentageOfBase';
import TotalRevenue from './component/TotalRevenue';
import { Box } from '@mui/material';
import { currencyWithCommas, getCurrencyPrefix } from '../../../Containers/Commons/Utils';
import { getFixedDecimal } from '../../../utils/common';
import variables from '../../../variables.scss';
import { BILLING_VALIDATIONS } from '../../../services/constantService';
import IndirectFee from './component/IndirectFee';

const BillingDetailsView = props => {
  const { defaultValues, currencyDetails } = props;
  const fe_Cap_amount = defaultValues.fee_cap_amount;
  const is_fee_cap = defaultValues.is_fee_cap;
  const totalEstimate = defaultValues.estimated_revenue;

  const getTotalPercentageOfBase = (data, key, formattedValue) => {
    const currency = getCurrencyPrefix(defaultValues?.bd_currency_values?.code || '');
    const percentage =
      data?.reduce((prev, curr) => {
        if (curr[key]) prev = prev + Number(curr[key]);
        return prev;
      }, 0) || 0;
    return formattedValue ? getValueWithCurrency(percentage) : { currency, number: percentage };
  };

  const getGrandTotal = () => {
    const totalPercentageOfBase = getTotalPercentageOfBase(defaultValues?.estimated_percentage_bases, 'calculated_estimated_amt', false);
    const totalIndirectFee = getTotalPercentageOfBase(defaultValues?.project_indirect_fees, 'calculation_amt');
    return getValueWithCurrency(totalPercentageOfBase?.number + totalIndirectFee?.number);
  };

  const getValueWithCurrency = data => {
    return `${getCurrencyPrefix(defaultValues?.bd_currency_values?.code || '')} ${currencyWithCommas(getFixedDecimal(data || 0).toFixed(2))}`;
  };

  return (
    <div className='flex-grow-1'>
      <BillingForm currentValues={defaultValues} currencyDetails={currencyDetails} />
      <div className='mt-5 w-50 ml-5'></div>
      <div className='row'>
        <div className='col-9'>
          <PercentageOfBase currentValues={defaultValues} type='estimated' disabled={true} getValueWithCurrency={getValueWithCurrency} />
        </div>
        <Box className='w-25'>
          <TotalRevenue
            // watch={watch}
            getTotalPercentageOfBase={getTotalPercentageOfBase}
            getGrandTotal={getGrandTotal}
            currentValues={defaultValues}
          />
          {is_fee_cap && Number(totalEstimate) > Number(fe_Cap_amount) && (
            <Box sx={{ marginTop: '23px', marginLeft: '15px' }}>
              <Box component='span' sx={{ color: variables.error, marginTop: '20px' }}>
                {BILLING_VALIDATIONS.FEE_CAP_AMOUNT}
              </Box>
            </Box>
          )}
        </Box>
      </div>
      <Box id='team-info' className='mt-5'>
        <IndirectFee
          currentValues={defaultValues}
          // watch={watch}
          getTotalPercentageOfBase={getTotalPercentageOfBase}
          // disabled={isHide}
          getValueWithCurrency={getValueWithCurrency}
        />
      </Box>
    </div>
  );
};

BillingDetailsView.propTypes = {
  defaultValues: PropTypes.object,
  currencyDetails: PropTypes.object
};
export default BillingDetailsView;
