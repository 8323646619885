import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const DialogHeader = ({ onClose }) => (
  <Grid container alignItems='center' sx={{ backgroundColor: 'primary.main', color: 'white', padding: '3px 10px' }}>
    <Grid item xs={4}>
      <Typography variant='h7' sx={{ fontWeight: 'bold' }}>
        Review Enriched Contact
      </Typography>
    </Grid>
    <Grid item xs={7} container alignItems='center'>
      {/* <a href='https://www.youtube.com' target='_blank' rel='noopener noreferrer' style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
        <YouTubeIcon sx={{ marginRight: '5px' }} />
        <Typography sx={{ textDecoration: 'underline' }}>Tutorial</Typography>
      </a> */}
    </Grid>
    <Grid item xs={1} container justifyContent='flex-end'>
      <IconButton onClick={onClose} sx={{ color: 'white' }}>
        <CloseIcon />
      </IconButton>
    </Grid>
  </Grid>
);

DialogHeader.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default DialogHeader;
