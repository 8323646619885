//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

//-----------------------------------------------------------// External Imports // ------------------------------

import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Popup from 'reactjs-popup';

import { Typography, Button, InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import ContactSelection from '../../../../components/ContactSelection/ContactSelection';
import Loader from '../../../../components/common/Loader';
import CandidateStageSelection from '../../../../components/CandidateStageSelection';
import './index.scss';
import SearchSelection from '../../../../components/SearchSelection';
import { getUserSelector } from '../../../../selectors';
import OffLimitsWarningPopup from '../../../Commons/OffLimitsWarningPopup';
import { offLimitsContactApi, searchDataApi } from '../../../../services/ApiService';
import { POST, SUCCESS, ERROR } from '../../../../services/constantService';
import { duplicateMessage, unableMessage, successMessage } from '../../../../services/MessageService';
import RichTextPopup from '../../../../components/RichTextPopup';
import ColorSelection from '../../../../components/ColorSelection';
import { RELOAD_ALL_CANDIDATE_GRID } from '../../../../types';
import { customFormValidator, requireValidMessage } from '../../../../utils/common';
import { useSnackbar } from 'notistack';

function AddCandidateByContact(props) {
  const { open, handleClose = () => {}, searchId, searchName, contact, header = '' } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, setValue, watch } = useForm();
  const user = useSelector(getUserSelector);
  const [isLoading, setLoading] = useState(false);
  const [offLimitsData, setOffLimitsData] = useState({});
  const [showOffLimitsPopup, setShowOffLimitsPopup] = useState(false);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();

  const checkIsOffLimits = async contactId => {
    const payload = { contacts: [contactId] };
    const { status, data } = await offLimitsContactApi(POST, payload);
    if (status === 200 && data.offLimitContacts?.length) {
      setOffLimitsData(data);
      setShowOffLimitsPopup(true);
      return true;
    }
    return false;
  };

  const addToSearch = async data => {
    setLoading(true);
    const { status, data: res } = await searchDataApi(POST, data.searchId, [data], 'candidates');
    if (status === 201 && (res?.candidatesAdded?.length > 0 || res?.candidatesUpdated?.length > 0)) {
      const message = successMessage('Candidate', 'added to search');
      enqueueSnackbar(message, { variant: SUCCESS });
      dispatch({ type: RELOAD_ALL_CANDIDATE_GRID });
      handleClose(true);
    } else if (status === 201 && res?.candidatesDuplicate?.length > 0) {
      const message = duplicateMessage('');
      enqueueSnackbar(message, { variant: ERROR });
      dispatch({ type: RELOAD_ALL_CANDIDATE_GRID });
      handleClose(true);
    } else {
      const message = unableMessage('candidate', 'add');
      enqueueSnackbar(res.message || message, { variant: ERROR });
    }
    setLoading(false);
  };

  const CreateSchema = yup.object().shape({
    searchId: yup.object().required(),
    stage: yup.string().required()
  });

  const ValidSchema = yup.object().shape({
    contact: yup.object().required(),
    stage: yup.string().required()
  });

  const addCandidate = async data => {
    try {
      if (!data.searchId) {
        const isValid = await CreateSchema.isValid(data);
        if (!isValid) {
          let requiredField = [
            { fieldName: 'searchId', label: 'Search Name', type: Object },
            { fieldName: 'stage', label: 'Stage', type: String }
          ];
          let dirtyField = customFormValidator(data, requiredField);
          if (dirtyField) {
            const message = requireValidMessage(dirtyField);
            enqueueSnackbar(message, { variant: ERROR });
            return;
          }
        }
      } else {
        const isValid = await ValidSchema.isValid(data);
        if (!isValid) {
          let requiredField = [
            { fieldName: 'contact', label: 'Contact Name', type: Object },
            { fieldName: 'stage', label: 'Stage', type: String }
          ];
          let dirtyField = customFormValidator(data, requiredField);
          if (dirtyField) {
            const message = requireValidMessage(dirtyField);
            enqueueSnackbar(message, { variant: ERROR });
            return;
          }
        }
      }

      data.contact_id = data.contact.id;
      data.source = data.contact.source || 'Galaxy';
      delete data.contact;
      const offLimits = await checkIsOffLimits(data.contact_id);
      if (offLimits) {
        setFormData(data);
        return;
      }
      await addToSearch(data);
    } catch (e) {
      console.log('Error found in addCandidate::', e);
    }
  };

  useEffect(() => {
    register('contact');
    register('searchId');
    register('stage');
    register('color');
    if (contact) {
      setValue('contact', contact);
    }
    if (searchId) {
      setValue('searchId', searchId);
    }
  }, [register, contact, searchId, setValue]);

  const handleOffLimitsPopupClose = async continueAdd => {
    try {
      if (continueAdd) {
        setShowOffLimitsPopup(false);
        await addToSearch(formData);
      } else {
        handleClose(false);
      }
    } catch (e) {
      console.log('Error found in handleOffLimitsPopupClose::', e);
    }
  };

  return showOffLimitsPopup ? (
    <OffLimitsWarningPopup data={[offLimitsData.offLimitContacts[0]]} handleClose={handleOffLimitsPopupClose} contactId={formData.contact_id} />
  ) : (
    <Popup open={open} className='candidate-by-contact' closeOnEscape={false} closeOnDocumentClick={false} modal>
      <React.Fragment>
        <Loader show={isLoading} />
        <form className='h-100' onSubmit={handleSubmit(addCandidate)}>
          {typeof header == 'string' ? (
            <div className='quick-add-header'>
              <Typography className='title'>{header}</Typography>
              <CloseIcon className='cursor-pointer' onClick={() => handleClose()} />
            </div>
          ) : (
            header
          )}

          <div className='container'>
            {searchName && (
              <div className='d-flex align-items-center input-field-old'>
                <InputLabel className='label' color='primary'>
                  Search title
                </InputLabel>
                <Typography className='input'>{searchName}</Typography>
              </div>
            )}
            {contact ? (
              <div className='d-flex align-items-center input-field-old'>
                <InputLabel className='label' color='primary'>
                  CONTACT NAME
                </InputLabel>
                <Typography className='input'>{`${contact.first_name || ''} ${contact.last_name || ''}`}</Typography>
              </div>
            ) : (
              <div className='d-flex align-items-center input-field-old'>
                <InputLabel required={true} className='label' color='primary'>
                  CONTACT NAME
                </InputLabel>
                <ContactSelection
                  // required={true}
                  className='input'
                  label=''
                  onChange={(e, data) => setValue('contact', data)}
                />
              </div>
            )}
            {!searchId && (
              <div className='d-flex align-items-center input-field-old'>
                <InputLabel required={true} className='label' color='primary'>
                  SEARCH
                </InputLabel>
                <SearchSelection
                  // required={true}
                  className='input'
                  label=''
                  onChange={(e, data) => setValue('searchId', data ? data.id : null)}
                />
              </div>
            )}
            <div className='d-flex align-items-center input-field-old'>
              <InputLabel required={true} className='label' color='primary'>
                STAGE
              </InputLabel>
              <CandidateStageSelection
                // required={true}
                className='input'
                label=''
                placeholder='Select'
                onChange={(e, data) => setValue('stage', data ? data.name : null)}
                hideP1RejectedStage={true}
              />
            </div>
            <div className='d-flex align-items-center input-field-old'>
              <InputLabel className='label' color='primary'>
                COLOR
              </InputLabel>
              <ColorSelection className='input' label='' placeholder='Select' onChange={(e, data) => setValue('color', data ? data.value : null)} />
            </div>
            <div className='d-flex align-items-center input-field-old'>
              <Typography className='label' color='primary'>
                RATIONALE
              </Typography>
              <div className='input'>
                <div className='outlined-border transform'>
                  <RichTextPopup
                    name='rationale'
                    className='input'
                    value={watch('rationale')}
                    {...register('rationale')}
                    onChange={data => {
                      setValue('rationale', data);
                    }}
                    title='Rationale'
                  />
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center input-field-old' style={{ minHeight: 32 }}>
              <Typography className='label' color='primary'>
                Identified By
              </Typography>
              <Typography className='input'>{user?.name}</Typography>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-end'>
            <Button className='button' type='submit' color='primary' variant='contained'>
              {contact ? 'Add Contact' : 'Add Candidate'}
            </Button>
          </div>
        </form>
      </React.Fragment>
    </Popup>
  );
}

AddCandidateByContact.propTypes = {
  open: PropTypes.bool,
  searchId: PropTypes.string,
  searchName: PropTypes.string,
  contact: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  handleClose: PropTypes.func,
  identifiedBy: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default AddCandidateByContact;
