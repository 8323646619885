//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InputField from '../../../components/common/InputField';
import { GET, ERROR } from '../../../services/constantService';
import { userDataApi } from '../../../services/ApiService';
import { unableMessage } from '../../../services/MessageService';

function SavedQuerySelection(props) {
  const { label = 'Saved Queries', placeholder = 'Search', enqueueSnackbar, InputLabelProps, onChange, userId, refreshQueries, page, saveQueryValuesForDefaultValue, ...rest } = props;

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getQueries = async () => {
      let { status, data } = await userDataApi(GET, userId, {}, `queries?section=${page}`);
      if (status && status === 200) {
        saveQueryValuesForDefaultValue(data);
        setOptions(data);
      } else {
        const message = unableMessage('saved queries', 'fetch');
        enqueueSnackbar(message, { variant: ERROR });
      }
    };
    if (userId) {
      getQueries().then(null);
    }
  }, [userId, enqueueSnackbar, refreshQueries]);

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionLabel={option => option.name}
      onChange={(e, data) => {
        onChange(data);
      }}
      renderInput={params => <InputField {...params} InputLabelProps={InputLabelProps} label={label} placeholder={placeholder} />}
    />
  );
}

SavedQuerySelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  InputLabelProps: PropTypes.object,
  userId: PropTypes.string,
  refreshQueries: PropTypes.bool,
  page: PropTypes.string,
  saveQueryValuesForDefaultValue: PropTypes.func,
  onChange: PropTypes.func
};

export default SavedQuerySelection;
