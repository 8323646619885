//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
//import RightSideNav from "../../../RightSideNav";
import Notes from './Components/Notes';
import SearchDocument from './Components/SearchDocument';
function SearchDocuments(props) {
  const { register, setValue, watch, control, currentValues, unregister } = props;

  return (
    <div className='input-form d-flex'>
      <div className='tab-view-content col-sm-12 p-0'>
        <div id='loeDocument'>
          <SearchDocument register={register} setValue={setValue} watch={watch} currentValues={currentValues} control={control} unregister={unregister} />
        </div>
        <div id='notes'>
          <Notes register={register} setValue={setValue} watch={watch} control={control} />
        </div>
      </div>
    </div>
  );
}

SearchDocuments.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  currentValues: PropTypes.object,
  unregister: PropTypes.func
};

export default SearchDocuments;
