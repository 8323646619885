import { Box } from '@mui/material';
import React, { useState } from 'react';
import FluidLayoutComponent from '../../common/FluidLayoutComponent';
import DescriptionIcon from '@mui/icons-material/Description';
import security_icon from '../../../assets/icons/security_icon.svg';
import { Col } from 'react-grid-system';
import AddDocuments from '../../AddContact/Components/Documents/AddDocuments';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddIcon from '@mui/icons-material/Add';
import '../../../../src/utils/common.scss';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CustomButton from '../../common/CustomButton';
import DocumentCard from '../../common/FunctionalComponents/DocumentCard';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';
import { ERROR, IGN_API, PATCH, POST, SUCCESS } from '../../../services/constantService';
import * as AttachmentsService from '../../../services/AttachmentsService';
import { AllDocumentsApi } from '../../../services/ApiService';
import { useCustomMessageHook } from '../../../utils/Hooks/useCustomMessageHook';
import { splitLastIfMultiple } from '../../../utils/common';

const ViewDocuments = props => {
  const { register, data, unregister, setValue = () => {}, getContactDetails = () => {}, isHeaderNav, getHeaderDetails = () => {} } = props;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [indexValue, setIndexValue] = useState(false);
  const { translateMessage } = useCustomMessageHook();

  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_DOCUMENT, TRANS_KEYS.CONTACTS_ADD_ANOTHER, TRANS_KEYS.CONTACTS_WORD_ADD]);
  const editData = () => {};

  const openPopupEditing = index => {
    setIndexValue(index);
    setIsEdit(true);
    setIsPopupOpen(true);
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };
  const download = async index => {
    let attachmentId = data?.contact_attachments[index].id;
    try {
      const url = await AttachmentsService.documentDownload(attachmentId);
      // const url = await ignContactDataApi(GET, null, null, sub_route)
      if (url) {
        const origin = window.location.origin;
        const subdomain = origin.split('.')[0];
        const restOfDomain = origin.substring(origin.indexOf('.') + 1);
        window.open(`${subdomain}.app.${restOfDomain}/${url}`);
      } else {
        const message = translateMessage('UnableMessage', false, 'Document', 'download');

        enqueueSnackbar(message, { variant: ERROR });
      }
    } catch (e) {
      console.log('Error found in downloadAttachment::', e);
    }
  };

  const sub_route = `${IGN_API.contact}/${data?.id}`;
  const sub_route1 = `${IGN_API.add_document}/${data?.id}/attachments`;
  const uploadDocuments = async (document, item) => {
    if (!document) {
      enqueueSnackbar('Attachment data not fill', { variant: ERROR });
      return;
    }

    if (!document.file_type && !document.file_name) {
      const message = translateMessage('Required', ...splitLastIfMultiple(['Type', 'Name']));
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (!document.file_type) {
      const message = translateMessage('Required', false, 'Type');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (!document.file_name) {
      const message = translateMessage('Required', false, 'Name');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (!isEdit) {
      if (!document.file) {
        const message = translateMessage('SelectFile', false, '');
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    let payload = {};
    if (isEdit) {
      payload = {
        attachment: {
          id: item.id,
          contact_id: item.contact_id,
          file_type: document.file_type,
          file_name: document.file_name,
          is_confidential: document.is_confidential,
          ign_contact_attachment_visibilities: document.ign_contact_attachment_visibilities
        }
      };
      const { status, data } = await AllDocumentsApi(PATCH, sub_route, payload);
      console.log(status, data);
      if (status === 200) {
        getHeaderDetails();
        getContactDetails(data?.id);
        const message = translateMessage('Successfully', false, 'Attachment', 'uploaded');
        enqueueSnackbar(message, { variant: SUCCESS });

        setIsPopupOpen(false);
      } else {
        enqueueSnackbar(data?.message || 'Attachment', { variant: ERROR });
      }
    } else {
      let fileNameSplit = document.file?.name?.split('.');
      let bodyFormData = new FormData();
      bodyFormData.append('is_confidential', document.is_confidential);
      bodyFormData.append('file_name', `${document.file_name}.${fileNameSplit[fileNameSplit.length - 1]}`);
      bodyFormData.append('file_type', document.file_type);
      bodyFormData.append('file', document.file);
      bodyFormData.append('ign_contact_attachment_visibilities', JSON.stringify(document.ign_contact_attachment_visibilities));
      const { status, data } = await AllDocumentsApi(POST, sub_route1, bodyFormData);
      console.log(status, data);
      processUpload(status, data, document.file.name);
    }
  };

  const processUpload = (status, data, fileName) => {
    if (status === 200) {
      getContactDetails(data?.id);
      getHeaderDetails();
      const message = translateMessage('Successfully', false, `${fileName}`, 'uploaded');
      enqueueSnackbar(message, { variant: SUCCESS });
      setIsPopupOpen(false);
    } else {
      const message = translateMessage('UnableMessage', false, `${fileName}`, 'upload');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };

  // const colSize = cardData.length > 6 ? 3 : 4;

  return (
    <div id='view-contact' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className={`${isHeaderNav === 'closed' ? 'details-container-close' : 'details-container'} scroll-content`}>
        <Box className='section-details m-2'>
          <Box className='d-flex flex-column w-100 row-gap'>
            <Box className='d-flex flex-row w-100 column-gap viewcontact-icon-style'>
              <DescriptionIcon color='disabled'></DescriptionIcon>
              <Box className='d-flex flex-column w-100 '>
                <Box className='title-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_DOCUMENT}:key`)}</Box>
              </Box>
            </Box>
            <Box id='bio_card'>
              <Box className='custom-card'>
                <Box className='d-flex'>
                  <FluidLayoutComponent>
                    {data &&
                      data?.contact_attachments?.map((card, cardIndex) => (
                        <Col key={cardIndex} sm={12} md={12} lg={6} className='p-3'>
                          <DocumentCard
                            data={card}
                            index={cardIndex}
                            editData={editData}
                            editItems={openPopupEditing}
                            download={download}
                            getContact={getContactDetails}
                            downloadIcon={<CloudDownloadIcon />}
                            addIcon={card.is_primary ? security_icon : ''}
                            styleData={{
                              titleSize: 13,
                              textSize: 10,
                              height: 130,
                              width: 'auto'
                            }}
                          ></DocumentCard>
                        </Col>
                      ))}
                  </FluidLayoutComponent>
                </Box>
                <Box className='add-document padding-left-20 pt-2'>
                  <Box onClick={handleOpenPopup}>
                    <CustomButton
                      type={'secondary'}
                      variant='outlined'
                      size={'medium'}
                      buttonText={data?.contact_attachments?.length === 0 ? t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`) : t(`${TRANS_KEYS.CONTACTS_ADD_ANOTHER}:key`)}
                      customWidth={120}
                      iconLeft={<AddIcon sx={{ width: 14 }} />}
                    />
                  </Box>
                  {isPopupOpen && (
                    <AddDocuments
                      title={`${isEdit ? 'Update' : 'Add'} Document`}
                      setIsPopupOpen={setIsPopupOpen}
                      isPopupOpen={isPopupOpen}
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                      data={isEdit ? data?.contact_attachments[indexValue] : null}
                      register={register}
                      unregister={unregister}
                      handleSave={uploadDocuments}
                      setValue={setValue}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
ViewDocuments.propTypes = {
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  data: PropTypes.object,
  isHeaderNav: PropTypes.string,
  getContactDetails: PropTypes.func,
  getHeaderDetails: PropTypes.func
};

export default ViewDocuments;
