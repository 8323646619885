//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useMemo, useRef, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import HomeIcon from '@mui/icons-material/Home';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchUserList } from '../../../src/actions';
import { getUserSelector } from '../../selectors';
import { ignContactDataApi } from '../../services/ApiService';
import { CONFIRMATION_POPUP_MESSAGE, ERROR, GET, IGNYTE_CONSTANTS, IGNYTE_GLOBALS, PATCH, POST, ROUTES, SUCCESS } from '../../services/constantService';
import { VALIDATION_MESSAGE } from '../../services/MessageService';
import { customFormValidator, requireValidMessage } from '../../utils/common';
import { useCustomMessageHook } from '../../utils/Hooks/useCustomMessageHook';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../utils/languageNamespaces';
import ErrorPopup from '../AddCompany/ErrorPopup';
import CustomConfirmationPopup from '../common/CustomConfirmationPopup';
import DuplicateContactNotifier from '../common/CustomPopup/DuplicateContactNotifier';
import Loader from '../common/Loader';
import DynamicTabLayout from '../DynamicTabs/TabLayout';
import { translateTabItems } from '../DynamicTabs/Utils';
import { validateEmail } from '../MessageTemplatePopup/utils';
import Communications from './Components/Communications/index';
import Compensation from './Components/Compensation';
import Documents from './Components/Documents';
import EducationAndLanguages from './Components/EducationAndLanguages';
import Personality from './Components/Personality/Personality';
import ProfessionalExperience from './Components/ProfessionalExperience';
import ProfileDetails from './Components/ProfileDetails/ProfileDetails';
import TabData, { contactFreezedTabs } from './TabData';
import { createRequiredField, CreateSchema, getCleanedContact } from './utils';
// import DuplicateContactMatchesModal from "../common/CustomPopup/DuplicateContactMatchesModal";

const defaultValues = {
  job_history: [],
  education_details: [],
  board_details: [],
  contact_country: '',
  direct_line: null,
  mobile_phone: null,
  home_phone: null,
  // current_job_start_year: "",
  // current_job_end_year: "Present",
  contact_industries: [{}]
};

const breadcrumbsLinks = [
  {
    label: (
      <>
        {' '}
        <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />
      </>
    ),
    link: '/contacts'
  },
  { label: 'Contacts', link: '/contacts' }
];
const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const AddContact = props => {
  const [currentValues, setCurrentValues] = useState({ ...defaultValues });
  const [duplicateContacts, setDuplicateContacts] = useState(false);
  const [image, setImage] = useState(null);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [isConfirmationPopup, setIsConfirmationPopup] = useState(false);
  const [recordData, setRecordData] = useState();
  const [forceCreatePayload, setForceCreatePayload] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const { register, unregister, setValue, reset, trigger, control, getValues, watch } = useForm({
    defaultValues: currentValues
  });
  const users = useSelector(state => state.rootReducer.users);
  const userData = useSelector(getUserSelector);
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  let query = useQuery();
  const locationState = location?.state?.contact;
  const { translateMessage } = useCustomMessageHook();

  const { t, tReady } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_LAYOUT_DETAILS,
    TRANS_KEYS.CONTACTS_ACTION,
    TRANS_KEYS.CONTACTS_PROFILE,
    TRANS_KEYS.CONTACTS_SEARCH,
    TRANS_KEYS.CONTACTS_EXPORT_LIST,
    TRANS_KEYS.CONTACTS_SAVE_LIST,
    TRANS_KEYS.CONTACTS_PERSONAL_INFO,
    TRANS_KEYS.CONTACTS_COMMUNICATION,
    TRANS_KEYS.CONTACTS_PROFESSIONAL_EXPERIENCE,
    TRANS_KEYS.CONTACTS_EDUCATION_AND_LANGUAGES,
    TRANS_KEYS.CONTACTS_DOCUMENT,
    TRANS_KEYS.CONTACTS_COMPENSATION,
    TRANS_KEYS.CONTACTS_PERSONALITY,
    TRANS_KEYS.CONTACTS_PROJECTS,
    TRANS_KEYS.CONTACTS_RECENT_ACTIVITY
  ]);

  const tabRefArray = {
    PROFILE_INFO_TAB: useRef(),
    PERSONAL_INFO_TAB: useRef(),
    COMMUNICATION_INFO_TAB: useRef(),
    PERSONALITY_INFO_TAB: useRef(),
    COMPENSATION_INFO_TAB: useRef(),
    EDUCATION_LANGUAGES_TAB: useRef(),
    PROFESSIONAL_EXPERIENCE_TAB: useRef()
  };

  useEffect(() => {
    if (!users) {
      dispatch(fetchUserList('users'));
    } else {
      setOptions(users);
    }
  }, [users, dispatch]);

  // useEffect(() => {
  //   const accessToken = async () => {
  //     let token = await getAccessToken();
  //     setSrcUrl(`${IGN_API.contact}/${currentValues?.id}/image?token=${token}&time=${Date.now()}`)

  //   };
  //   if (currentValues?.image_id) accessToken();
  // }, [currentValues, image]);

  useEffect(() => {
    if (locationState) {
      reset(locationState);
      setCurrentValues(locationState);
    }
  }, [locationState]);
  const getFreezedTabs = useMemo(() => {
    return query.get('id') ? [] : contactFreezedTabs;
  }, [query.get('id')]);

  const contactId = useMemo(() => {
    return query.get('id');
  }, [query.get('id')]);

  const addImage = async (file, id) => {
    console.log(id, 'id');
    if (!file) return;
    const sub_route = 'image';
    const { status, data } = await ignContactDataApi(POST, id, file, sub_route);
    if (status === 200) {
      const message = translateMessage('Successfully', false, 'Profile image', 'uploaded');
      enqueueSnackbar(data?.message || message, { variant: SUCCESS });
    } else {
      const message = translateMessage('UnableMessage', false, 'Profile image', 'upload');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };

  const createContact = async allowDuplicate => {
    await trigger();
    const contact = { ...currentValues, ...getValues() };
    let isValid = await CreateSchema.isValid(contact);

    if (!isValid) {
      let dirtyField = customFormValidator(contact, createRequiredField);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    let validateEmailSyntax = validateEmail(contact?.email);
    if (!validateEmailSyntax) {
      const message = translateMessage('ValidFiled', false, 'Email');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    setIsLoading(true);
    const final = getCleanedContact(contact, false, userData, contactId);
    const sub_route = allowDuplicate ? '?allowDuplicate=true' : '';
    const { status, data } = final.id ? await ignContactDataApi(PATCH, final.id, final, sub_route) : await ignContactDataApi(POST, '', final, sub_route);
    if (status === 201) {
      navigate({
        pathname: location.pathname,
        search: `?${createSearchParams({
          id: data.id
        })}`
      });
      if (contactId) {
        await getContact(contactId);
      }
      const message = translateMessage('Successfully', false, 'Contact', 'created');

      enqueueSnackbar(message, { variant: SUCCESS });
      setDuplicateContacts(false);
    } else if (status === 200) {
      if (contactId) {
        await getContact(contactId);
      }
      setDuplicateContacts(data.contacts);
    } else {
      const message = translateMessage('Successfully', false, 'Contact', 'create');
      props.enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setIsLoading(false);
  };
  const addContact = async () => {
    try {
      await createContact();
    } catch (e) {
      console.log('Error found in addContact::', e);
    }
  };

  const handleAddDuplicate = async () => {
    try {
      await createContact(true);
    } catch (e) {
      console.log('Error found in handleAddDuplicate::', e);
    }
  };

  const handleShowContacts = () => {
    const idQuery = duplicateContacts.map(contact => `id=${contact.id}`).join('&');
    window.open(`/contacts?${idQuery}`);
  };

  const handleCancel = () => {
    setDuplicateContacts(false);
  };
  const getContact = async (id, tab) => {
    try {
      let sub_route;
      if (tab) {
        sub_route = `?tabName=${tab}`;
      }
      const { status, data: result } = await ignContactDataApi(GET, id, null, sub_route);
      if (status === 200) {
        setCurrentValues(result);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  // useEffect(() => {
  //   if (contactId) {
  //     getContact(contactId)
  //   }
  // }, [contactId])

  const handleSaveTabData = async (contact, tab, setIsPopupOpen = () => {}) => {
    setIsLoading(true);
    const final = contact;
    setForceCreatePayload(final);
    let allowDuplicate;
    const sub_route = allowDuplicate ? '?allowDuplicate=true' : '';
    const { status, data } = contactId ? await ignContactDataApi(PATCH, contactId, final, sub_route) : await ignContactDataApi(POST, '', final, sub_route);
    if (status === 201) {
      navigate({
        pathname: location.pathname,
        search: `?${createSearchParams({
          id: data?.id
        })}`
      });
      if (contactId) {
        await getContact(contactId, tab);
      }

      const message = translateMessage('Successfully', false, 'Contact', 'created');

      enqueueSnackbar(message, { variant: SUCCESS });
      setDuplicateContacts(false);
    } else if (status === 200) {
      if (contactId) {
        const message = translateMessage('Successfully', false, 'Contact', 'updated');
        setIsPopupOpen(false);
        enqueueSnackbar(message, { variant: SUCCESS });
        await getContact(contactId, tab);
      }
      setDuplicateContacts(data.contacts);
    } else {
      // const message = translateMessage('UnableMessage', false, 'Contact', 'create');
      // enqueueSnackbar(data?.reason || message, { variant: ERROR });
      if (status === 409) {
        // setIsLoading(false);
        // let concatenatedArray = [....data.duplicateByEmail, ...data];
        setRecordData(data?.data);
        setIsConfirmationPopup(true);
      }
      if (status === 500) {
        const message = translateMessage('UnableMessage', false, 'Contact', 'create');
        enqueueSnackbar(data?.reason || message, { variant: ERROR });
      }
      setIsLoading(false);
      return false;
    }
    if (data?.id || final?.id || contactId) {
      await addImage(image, data?.id ? data?.id : final?.id ? final?.id : contactId);
    }
    setIsLoading(false);
    return true;
  };
  const onTabChange = async () => {
    if (tabRefArray?.PROFILE_INFO_TAB?.current) {
      const result = await tabRefArray?.PROFILE_INFO_TAB?.current?.saveTabData();
      return result;
    }
    if (tabRefArray?.COMMUNICATION_INFO_TAB?.current) {
      const result = await tabRefArray?.COMMUNICATION_INFO_TAB?.current?.saveTabData();
      return result;
    }
    if (tabRefArray?.COMPENSATION_INFO_TAB?.current) {
      const result = await tabRefArray?.COMPENSATION_INFO_TAB?.current?.saveTabData();
      return result;
    }
    if (tabRefArray?.PROFESSIONAL_EXPERIENCE_TAB?.current) {
      const result = await tabRefArray?.PROFESSIONAL_EXPERIENCE_TAB?.current?.saveTabData();
      return result;
    }
    if (tabRefArray?.EDUCATION_LANGUAGES_TAB?.current) {
      const result = await tabRefArray?.EDUCATION_LANGUAGES_TAB?.current?.saveTabData();
      return result;
    }
    return true;
  };

  const onCancel = isDirty => {
    if (isDirty) {
      setOpenConfirmationPopup(true);
    } else {
      navigate(ROUTES.allContactGrid);
    }
  };

  const closeConfirmationPopup = () => {
    setOpenConfirmationPopup(false);
  };

  const onConfirm = () => {
    navigate(ROUTES.allContactGrid);
  };

  const handleClose = () => {
    setIsConfirmationPopup(false);
  };

  const onConfirmData = async () => {
    forceCreatePayload.force = true;
    let allowDuplicate;
    const sub_route = allowDuplicate ? '?allowDuplicate=true' : '';
    setIsLoading(true);
    const { status, data } = contactId ? await ignContactDataApi(PATCH, contactId, forceCreatePayload, sub_route) : await ignContactDataApi(POST, '', forceCreatePayload, sub_route);
    if (status === 201) {
      navigate({
        pathname: location.pathname,
        search: `?${createSearchParams({
          id: data?.id
        })}`
      });
      if (contactId) {
        await getContact(contactId, 'PROFILE_DETAILS_TAB');
      }
      setIsConfirmationPopup(false);
      setIsLoading(false);
      const message = translateMessage('Successfully', false, 'Contact', 'created');
      enqueueSnackbar(message, { variant: SUCCESS });
    } else if (status === 200) {
      if (contactId) {
        setIsConfirmationPopup(false);
        setIsLoading(false);
        const message = translateMessage('Successfully', false, 'Contact', 'updated');
        enqueueSnackbar(message, { variant: SUCCESS });
        await getContact(contactId, 'PROFILE_DETAILS_TAB');
      }
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Add Contact - {IGNYTE_GLOBALS.BRAND}</title>
      </Helmet>
      {duplicateContacts ? (
        <ErrorPopup
          header={VALIDATION_MESSAGE.contact_already_exist}
          viewButtonText={'View/Edit existing contacts'}
          onView={handleShowContacts}
          onCancel={handleCancel}
          onContinue={handleAddDuplicate}
        />
      ) : null}
      <Loader show={isLoading} />
      {tReady && (
        <DynamicTabLayout
          navItems={translateTabItems(t, TabData, getFreezedTabs)}
          baseRoute='/contacts/add'
          navLinks={breadcrumbsLinks}
          showBreadcrumbs={false}
          onTabChange={onTabChange}
          queryParams={
            query.get('id')
              ? `?${createSearchParams({
                  id: query.get('id')
                })}`
              : ''
          }
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
        >
          <ProfileDetails
            index={'Details'}
            childIndex={'Profile'}
            option={options}
            register={register}
            unregister={unregister}
            control={control}
            watch={watch}
            getValues={getValues}
            setValue={setValue}
            values={currentValues}
            handleSave={addContact}
            setImage={setImage}
            image={image}
            ref={tabRefArray['PROFILE_INFO_TAB']}
            handleSaveTabData={handleSaveTabData}
            id={contactId}
            onCancel={onCancel}
          />
          <Compensation
            index={'Compensation'}
            register={register}
            unregister={unregister}
            getValues={getValues}
            setValue={setValue}
            values={currentValues}
            handleSaveTabData={handleSaveTabData}
            control={control}
            watch={watch}
            id={contactId}
            ref={tabRefArray['COMPENSATION_INFO_TAB']}
            onCancel={onCancel}
          ></Compensation>
          <div>Engagement</div>
          <Documents
            index='Documents'
            handleSave={addContact}
            setValue={setValue}
            register={register}
            unregister={unregister}
            watch={watch}
            currentValues={currentValues?.contact_attachments}
            getContact={getContact}
            contactId={contactId}
          ></Documents>
          <Communications
            index='Details'
            childIndex={'Communication'}
            values={currentValues}
            handleSave={addContact}
            getValues={getValues}
            setValue={setValue}
            register={register}
            unregister={unregister}
            watch={watch}
            ref={tabRefArray['COMMUNICATION_INFO_TAB']}
            handleSaveTabData={handleSaveTabData}
            id={contactId}
            onCancel={onCancel}
          />
          <ProfessionalExperience
            index='Details'
            values={currentValues}
            childIndex='Professional Experience'
            handleSaveTabData={handleSaveTabData}
            getValues={getValues}
            setValue={setValue}
            register={register}
            unregister={unregister}
            ref={tabRefArray['PROFESSIONAL_EXPERIENCE_TAB']}
            watch={watch}
            id={contactId}
            onCancel={onCancel}
          ></ProfessionalExperience>
          <Personality
            index={'Personality'}
            handleSave={addContact}
            setValue={setValue}
            register={register}
            unregister={unregister}
            currentValues={currentValues}
            reset={reset}
            getContact={getContact}
            ref={tabRefArray['PERSONALITY_INFO_TAB']}
            handleSaveTabData={handleSaveTabData}
            id={contactId}
          ></Personality>
          <EducationAndLanguages
            index={'Details'}
            childIndex={'Education and Languages'}
            register={register}
            unregister={unregister}
            handleSaveTabData={handleSaveTabData}
            watch={watch}
            setValue={setValue}
            values={currentValues}
            id={contactId}
            setIsLoading={setIsLoading}
            ref={tabRefArray['EDUCATION_LANGUAGES_TAB']}
            onCancel={onCancel}
          />
        </DynamicTabLayout>
      )}
      <CustomConfirmationPopup
        open={openConfirmationPopup}
        onClose={closeConfirmationPopup}
        type='cancel'
        cancelText={IGNYTE_CONSTANTS?.Cancel}
        confirmText={IGNYTE_CONSTANTS?.Continue}
        onConfirm={onConfirm}
        setOpen={setOpenConfirmationPopup}
        customMessage={CONFIRMATION_POPUP_MESSAGE.CANCEL_CONFIRMATION}
      />
      {isConfirmationPopup && <DuplicateContactNotifier open={isConfirmationPopup} data={recordData} handleClose={handleClose} onConfirmData={onConfirmData} label={contactId ? 'Update' : 'Create'} />}
    </React.Fragment>
  );
};

AddContact.propTypes = {
  enqueueSnackbar: PropTypes.func,
  location: PropTypes.object
};

export default AddContact;
