//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { API, DASHBOARD, ERROR, GET } from '../../services/constantService';
import { dashboardDataApi } from '../../services/ApiService';
import './index.scss';
import { unableMessage } from '../../services/MessageService';
import { useSnackbar } from 'notistack';

const DashboardContent = props => {
  const { viewName } = props;
  const [token, setToken] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const getTableauToken = async () => {
    try {
      const url = `${API.dashboard}/authorize`;
      const { status, data } = await dashboardDataApi(GET, url);
      if (status === 200) {
        setToken(data.result);
      } else {
        const message = unableMessage('sign token', 'get');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
    } catch (err) {
      console.log('error in Fetch Token::', err);
    }
  };
  useEffect(() => {
    getTableauToken();
  }, []);

  return (
    <>
      {token && (
        <tableau-viz
          id='tableauViz'
          class='tableauViz'
          width='100%'
          height='100%'
          src={`${DASHBOARD.HOST_URL}${DASHBOARD.SITE_ROOT}/views/${viewName}`}
          showAppBanner='false'
          toolbar='yes'
          tabs='yes'
          token={token}
        />
      )}
    </>
  );
};

DashboardContent.propTypes = {
  viewName: PropTypes.string
};

export default DashboardContent;
