//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Checkbox, FormControl, FormControlLabel, FormLabel, Input, InputLabel, RadioGroup, TextField, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { NumericFormat } from 'react-number-format';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import CurrencySelection from '../../../CurrencySelection';
import InputField from '../../../common/InputField';
import RichTextPopup from '../../../RichTextPopup';
import CustomDatePicker from '../MilestoneDates/Components/CustomDatePicker';
import { currencyValueSetter, DateRenderer, isCurrency, separator } from '../../../../Containers/Commons/Utils';
import { ExpandableSection } from '../ExpandableSection';
// import { removerBorder } from "../../../../Containers/Commons/Utils";
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR, WARNING } from '../../../../services/constantService';
import { fetchCurrencyDetails } from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityScreeningNotesTabPayload, getScreeningNotesDefaultValue, removeSkipFields, screeningNotesTabDefaultValues } from '../../utils';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import TabLayoutFooter from '../../../TabLayoutFooter';
import { cleanupFormValues } from '../../../../utils/common';
import { formatDate } from '../../../../utils/date';
import { VALIDATION_MESSAGE } from '../../../../services/MessageService';

const useStyles = makeStyles(() => ({
  root: {
    height: 25,
    '&$selected': {
      color: '#FFFFFF',
      backgroundColor: '#5C52C6',
      '&:hover': {
        backgroundColor: '#5C52C6'
      }
    },
    backgroundColor: '#FCFCFC',
    '&:focus': {
      outline: 'none'
    }
  },
  selected: {}
}));

// eslint-disable-next-line react/display-name
const ScreeningNotesAndCompensation = forwardRef((props, ref) => {
  const { userdata, updateContactInfo, saveStatus, autoSaveContactFields, getActivityLogInfoTabData, handleSaveTabData, readOnly, isRichTextSaved } = props;
  const { register, reset, handleSubmit, getValues, watch, setValue, formState } = useForm({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const [timeStamp, setTimestamp] = useState();
  const [initialLength, setInitialLength] = useState();
  const currencyDetails = useSelector(state => (state.commonReducer[watch('currency_iso_code')] ? state.commonReducer[watch('currency_iso_code')] : {}));
  const [screeningNotesToggleUpdatedFlag, setScreeningNotesToggleFlag] = useState(false);
  const [tabInfo, setTabInfo] = useState(null);
  const [currentValues, setCurrentValues] = useState(null);
  const [formDirtyFields, setFormDirtyFields] = useState([]);
  const { id, path, contactId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { dirtyFields } = formState;
  const actions = [
    {
      label: 'Save & Close',
      className: 'primary-btn mr-3',
      onClick: () => {
        handleSave(true);
      }
    },
    {
      label: 'Save',
      className: 'primary-btn',
      onClick: () => {
        handleSave(false, false, true);
      }
    }
  ];

  const getActivityLogInfo = useCallback(
    async hideLoader => {
      const data = await getActivityLogInfoTabData(hideLoader, 'SCREENING_NOTES_TAB');
      if (data) {
        setTabInfo(data);
      }
    },
    [contactId, id, path, enqueueSnackbar]
  );

  useEffect(() => {
    getActivityLogInfo();
  }, [getActivityLogInfo]);

  useEffect(() => {
    let defaultValues = screeningNotesTabDefaultValues(tabInfo);
    if (defaultValues && isRichTextSaved) {
      defaultValues = { ...defaultValues, ...cleanupFormValues(getValues()) };
      const forcedDirtyFields = removeSkipFields({ ...dirtyFields, ...formDirtyFields });
      setFormDirtyFields(forcedDirtyFields);
    }
    if (defaultValues) {
      reset(defaultValues);
      setCurrentValues(defaultValues);
    }
  }, [tabInfo, reset, getValues]);

  const handleSave = async (saveAndClose, skipMessage = false, reload) => {
    const formValues = getValues();
    let result;
    if (Number(formValues.aum_min) > Number(formValues.aum_max)) {
      const message = VALIDATION_MESSAGE.aum_validation_message;
      enqueueSnackbar(message, { variant: WARNING });
      return false;
    }
    const newDirtyFields = [dirtyFields, ...formDirtyFields];
    const payload = await getActivityScreeningNotesTabPayload(formValues, newDirtyFields, currentValues);
    result = await handleSaveTabData(payload, saveAndClose, skipMessage, false, 0);
    if (result && reload) {
      setFormDirtyFields([]);
      await getActivityLogInfo();
    }
    if (!result) {
      handleSubmit(null);
    }
    return result;
  };

  useImperativeHandle(ref, () => ({
    saveTabData: (saveAndClose, skipMessage = false, reload) => {
      return handleSave(saveAndClose, skipMessage, reload);
    },
    isDirty: () => {
      let fields = removeSkipFields({ ...dirtyFields, ...formDirtyFields });
      return fields?.length > 0;
    },
    formData: () => {
      return getValues();
    },
    reload: hideLoader => {
      getActivityLogInfo(hideLoader);
    }
  }));

  useEffect(() => {
    if (currentValues) {
      reset(currentValues);
    }
  }, [currentValues]);

  useEffect(() => {
    register('screening_notes');
    register('currency_iso_code');
    register('target_bonus_value');
    register('actual_bonus_value');
    register('compensation_expectation');
    register('base_salary');
    register('equity');
    register('bonus_payout_date');
    register('compensation_updated_date');
  }, [register]);

  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(watch('currency_iso_code'), dispatch);
      } catch (e) {
        console.log('Error found in currencyHandler::', e);
      }
    };
    if (watch('currency_iso_code') && Object.keys(currencyDetails)?.length === 0) {
      currencyHandler();
    }
  }, [watch('currency_iso_code')]);

  const update = async data => {
    try {
      let HTMLArray;
      data.screening_notes = getScreeningNotesDefaultValue(data.screening_notes);
      const doc = new DOMParser().parseFromString(data.screening_notes, 'text/html');
      HTMLArray = [...doc.body.children].map(el => el.outerHTML);
      let newNotes = HTMLArray.slice(initialLength, HTMLArray?.length).join('');
      const span = document.createElement('span');
      span.innerHTML = newNotes;
      newNotes = span.textContent;
      newNotes = newNotes.trim();
      let stringCompare = newNotes.substring(0, timeStamp?.length);
      if (newNotes?.length <= timeStamp?.length || stringCompare !== timeStamp) {
        HTMLArray = HTMLArray.splice(0, initialLength);
        HTMLArray = HTMLArray.join(' ');
        data.screening_notes = HTMLArray;
      }
      await updateContactInfo(data);
      //used for screening notes rendering issue after onSave function
      setScreeningNotesToggleFlag(prevState => !prevState);
    } catch (e) {
      console.log('Error found in update::', e);
    }
  };

  const renderCheckbox = (label, field) => {
    return (
      <FormControl classes={{ root: 'input-label' }} component='fieldset' className='input-form-field input-field-old'>
        <Typography className={'input-label'}>{label}</Typography>
        <FormControlLabel
          className='checkbox-container'
          control={<Checkbox className='checkbox-component' size='small' style={{ padding: '5px 10px' }} defaultChecked={currentValues[field]} name={field} {...register(field)} />}
          style={{ marginBottom: 0 }}
          label='Yes'
        />
      </FormControl>
    );
  };

  return (
    currentValues && (
      <div>
        <div className='compensation-screening-notes d-flex'>
          <div className='compensation' style={{ flex: '1 0' }}>
            <ExpandableSection title='Compensation'>
              <div className='w-100 d-flex flex-column'>
                <CurrencySelection
                  value={watch('currency_iso_code') || null}
                  className='input-form-field input-field-old'
                  InputLabelProps={{ focused: true }}
                  onChange={(e, data) => {
                    setValue('currency_iso_code', data ? data.code : null, { shouldDirty: true });
                  }}
                />
                {/* <InputField
                {...register('')}
                className="input-form-field input-field-old"
                label="Base Salary"
                InputLabelProps={{ focused: true }}
                name="base_salary"
                type="number"
              /> */}
                <div className='contact-details mb-2'>
                  {/* <InputLabel className="contact-view-label" >Base Salary</InputLabel> */}
                  <NumericFormat
                    value={watch('base_salary')}
                    // style={removerBorder}
                    customInput={TextField}
                    label='Base Salary'
                    placeholder='Base Salary'
                    className='input-form-field input-field-old'
                    InputLabelProps={{ focused: true }}
                    thousandSeparator={separator(watch('currency_iso_code'), THOUSAND_SEPARATOR)}
                    decimalSeparator={separator(watch('currency_iso_code'), DECIMAL_SEPARATOR)}
                    decimalScale={2}
                    isNumericString={true}
                    prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                    suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                    onValueChange={values => {
                      const { value } = values;
                      setValue('base_salary', value, { shouldDirty: true });
                    }}
                  />
                </div>

                <div className='d-flex align-items-end'>
                  <div className='d-flex align-items-baseline'>
                    <InputLabel shrink={true} focused>
                      Target Bonus
                    </InputLabel>
                    <ToggleButtonGroup
                      {...register('target_bonus_type')}
                      exclusive
                      value={watch('target_bonus_type')}
                      onChange={(e, data) => {
                        setValue('target_bonus_type', data);
                      }}
                      aria-label='target_bonus_type'
                    >
                      <ToggleButton classes={classes} value='CURRENCY' aria-label='Currency'>
                        <span>{watch('currency_iso_code') ? currencyDetails?.currency_icon : '$'}</span>
                      </ToggleButton>
                      <ToggleButton classes={classes} value='PERCENTAGE' aria-label='Percentage'>
                        <span>%</span>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
                <NumericFormat
                  // style={removerBorder}
                  customInput={TextField}
                  value={watch('target_bonus_value')}
                  className='mt-1 mb-2 input-form-field input-field-old'
                  thousandSeparator={isCurrency('target_bonus_value', watch('target_bonus_type')) ? separator(watch('currency_iso_code'), THOUSAND_SEPARATOR) : false}
                  decimalSeparator={separator(watch('currency_iso_code'), DECIMAL_SEPARATOR)}
                  decimalScale={2}
                  renderText={value => currencyValueSetter(value, watch('currency_iso_code'))}
                  isNumericString={true}
                  prefix={isCurrency('target_bonus_value', watch('target_bonus_type')) && currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                  suffix={isCurrency('target_bonus_value', watch('target_bonus_type')) && currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                  onValueChange={values => {
                    const { value } = values;
                    setValue('target_bonus_value', value, { shouldDirty: true });
                  }}
                />
                <div className='d-flex align-items-end'>
                  <div className='d-flex align-items-baseline'>
                    <InputLabel shrink={true} focused>
                      Actual Bonus
                    </InputLabel>
                    <ToggleButtonGroup
                      {...register('actual_bonus_type')}
                      exclusive
                      value={watch('actual_bonus_type')}
                      onChange={(e, data) => {
                        setValue('actual_bonus_type', data);
                      }}
                      aria-label='actual_bonus_type'
                    >
                      <ToggleButton classes={classes} value='CURRENCY' aria-label='Currency'>
                        <span>{watch('currency_iso_code') ? currencyDetails?.currency_icon : '$'}</span>
                      </ToggleButton>
                      <ToggleButton classes={classes} value='PERCENTAGE' aria-label='Percentage'>
                        <span>%</span>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
                <NumericFormat
                  // style={removerBorder}
                  customInput={TextField}
                  value={watch('actual_bonus_value')}
                  className='mt-1 input-form-field input-field-old'
                  renderText={value => currencyValueSetter(value, watch('currency_iso_code'))}
                  thousandSeparator={isCurrency('actual_bonus_value', watch('actual_bonus_type')) ? separator(watch('currency_iso_code'), THOUSAND_SEPARATOR) : false}
                  decimalSeparator={separator(watch('currency_iso_code'), DECIMAL_SEPARATOR)}
                  decimalScale={2}
                  isNumericString={true}
                  prefix={isCurrency('actual_bonus_value', watch('actual_bonus_type')) && currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                  suffix={isCurrency('actual_bonus_value', watch('actual_bonus_type')) && currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                  onValueChange={values => {
                    const { value } = values;
                    setValue('actual_bonus_value', value, { shouldDirty: true });
                  }}
                />
                <InputField
                  {...register('equity')}
                  className='input-form-field input-field-old'
                  label='LTI'
                  placeholder='LTI'
                  InputLabelProps={{ focused: true }}
                  name='equity'
                  onChange={e => setValue('equity', e.target.value)}
                />

                <InputField
                  {...register('compensation_notes')}
                  className='input-form-field input-field-old'
                  label='Compensation Notes'
                  placeholder='Compensation Notes'
                  InputLabelProps={{ focused: true }}
                  name='compensation_notes'
                />
                <div className='contact-details mt-2 mb-2'>
                  {/* <InputLabel className="contact-view-label" >Compensation Expectation</InputLabel> */}
                  <NumericFormat
                    customInput={TextField}
                    InputLabelProps={{ focused: true }}
                    label='Compensation Expectation'
                    placeholder='Compensation Expectation'
                    value={watch('compensation_expectation')}
                    className='input-form-field input-field-old'
                    // style={removerBorder}
                    thousandSeparator={separator(watch('currency_iso_code'), THOUSAND_SEPARATOR)}
                    decimalSeparator={separator(watch('currency_iso_code'), DECIMAL_SEPARATOR)}
                    decimalScale={2}
                    isNumericString={true}
                    onPaste={e => {
                      e.preventDefault();
                    }}
                    prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                    suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                    onValueChange={values => {
                      const { value } = values;
                      setValue('compensation_expectation', value, { shouldDirty: true });
                    }}
                  />
                </div>
                <KeyboardDatePicker
                  className='input-form-field input-field-old'
                  //disableToolbar
                  {...register('bonus_payout_date')}
                  name='bonus_payout_date'
                  autoOk
                  variant='inline'
                  label='Bonus Payout Date'
                  placeholder='Bonus Payout Date'
                  format='dd/MM/yyyy'
                  value={formatDate(watch('bonus_payout_date')) || null}
                  InputLabelProps={{ focused: true }}
                  onChange={date => {
                    setValue('bonus_payout_date', formatDate(date), { shouldDirty: true });
                  }}
                />
                <KeyboardDatePicker
                  className='input-form-field input-field-old'
                  //disableToolbar
                  {...register('compensation_updated_date')}
                  autoOk
                  name='compensation_updated_date'
                  label='Updated Date'
                  variant='inline'
                  format='dd/MM/yyyy'
                  value={formatDate(watch('compensation_updated_date')) || null}
                  InputLabelProps={{ focused: true }}
                  placeholder='Compensation Updated Date'
                  onChange={date => {
                    setValue('compensation_updated_date', formatDate(date), { shouldDirty: true });
                  }}
                />
                <FormControl className='d-flex w-100'>
                  <FormLabel focused={true} component='legend' className='MuiInputLabel-shrink'>
                    Compensation Release
                  </FormLabel>
                  <RadioGroup row className='radio-group'>
                    <FormControlLabel
                      className='checkbox-container'
                      id={'re'}
                      value='true'
                      control={
                        <Checkbox
                          className='checkbox-component'
                          style={{ padding: '0px 10px' }}
                          defaultChecked={watch('compensation_release')}
                          name='compensation_release'
                          {...register('compensation_release')}
                        />
                      }
                      label='Yes'
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </ExpandableSection>
            <ExpandableSection title='Scale/Size Managed'>
              <div className='d-flex flex-column'>
                <InputField
                  {...register('business_unit_revenue')}
                  className='input-form-field input-field-old'
                  label='Business Unit Revenue'
                  placeholder='Business Unit Revenue'
                  InputLabelProps={{ focused: true }}
                  name='business_unit_revenue'
                />
                <InputField
                  {...register('direct_reports')}
                  className='input-form-field input-field-old'
                  label='# Direct Reports'
                  placeholder='# Direct Reports'
                  type='number'
                  InputLabelProps={{ focused: true }}
                  name='direct_reports'
                />
                <div className='d-flex input-form-field input-field-old flex-column'>
                  <label className='MuiFormLabel-root Mui-focused MuiInputLabel-shrink' style={{ marginBottom: 0 }}>
                    AUM
                  </label>
                  <div className='d-flex align-items-end'>
                    <Input {...register('aum_min')} name='aum_min' type='number' id='aum' />
                    <Typography className='to-label'>to</Typography>
                    <Input
                      {...register('aum_max')}
                      name='aum_max'
                      type='number'
                      inputProps={{ min: watch('aum_min') }}
                      onBlur={e => {
                        if (e.target.value < Number(watch('aum_min'))) {
                          setValue('aum_max', e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
                <InputField {...register('reports_to')} className='input-form-field input-field-old' label='Report To' placeholder='Report To' InputLabelProps={{ focused: true }} name='reports_to' />
                <CustomDatePicker
                  disabled={true}
                  value={watch('scale_size_last_updated') || null}
                  InputLabelProps={{ focused: true }}
                  label='Last Updated'
                  placeholder='Last Updated'
                  className='input-form-field input-field-old'
                />
              </div>
            </ExpandableSection>
          </div>
          <div className='screening-notes' style={{ flex: '2 0' }}>
            <div className='d-flex align-items-end' style={{ fontSize: 18 }}>
              <Typography className='section-label' style={{ width: '100%' }}>
                Screening Notes
                <Typography style={{ float: 'right', marginRight: '20px' }} color={'primary'} display='inline' align='right' variant='caption'>
                  {(watch('screening_notes_last_updated_at') || watch('screening_notes_last_updated_user')) && <span>Last updated</span>}
                  {watch('screening_notes_last_updated_at') && (
                    <span>
                      {' '}
                      at <DateRenderer value={watch('screening_notes_last_updated_at')} />
                    </span>
                  )}
                  {watch('screening_notes_last_updated_user') && <span> by {watch('screening_notes_last_updated_user').name}</span>}
                </Typography>
              </Typography>
            </div>
            <div className='input-form-field input-field-old quill-div react-quill-container'>
              <div className='text-view-value p-0 w-50'>
                <div className='outlined-border transform p-0 '>
                  <RichTextPopup
                    value={currentValues['screening_notes'] === '<p><br /></p>' || currentValues['screening_notes'] === '<p><br></p>' ? null : currentValues['screening_notes']}
                    name='screening_notes'
                    onSave={async data => await update(data)}
                    userdata={userdata}
                    eventBinding={true}
                    bounds={'.quill-div'}
                    setTimestamp={setTimestamp}
                    setInitialLength={setInitialLength}
                    title={'Screening Notes'}
                    onChange={data => {
                      setValue('screening_notes', data);
                    }}
                    autoSave={autoSaveContactFields}
                    saveStatus={saveStatus}
                    updatedDate={currentValues?.updated_at}
                    restrictHeight={false}
                    screeningNotesFlag={true}
                    screeningNotesToggleUpdatedFlag={screeningNotesToggleUpdatedFlag}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex align-items-end' style={{ fontSize: 18 }}>
              <Typography className='section-label' style={{ width: '100%' }}>
                <span className='mr-1'>HL Recruiter Screening Notes</span>
                <Tooltip title='This comment will not be available in any reports'>
                  <InfoIcon style={{ fontSize: '1rem' }} />
                </Tooltip>
              </Typography>
            </div>
            <div>
              <div className='text-view-value p-0 w-50'>
                <div className='outlined-border transform p-0 '>
                  <RichTextPopup
                    value={
                      currentValues['hl_recruiter_screening_notes'] === '<p><br /></p>' || currentValues['hl_recruiter_screening_notes'] === '<p><br></p>'
                        ? null
                        : currentValues['hl_recruiter_screening_notes']
                    }
                    setOpen={false}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex align-items-end' style={{ fontSize: 18 }}>
              <Typography className='section-label' style={{ width: '100%' }}>
                <span className='mr-1'>HL Partner Screening Notes</span>
                <Tooltip title='This comment will not be available in any reports'>
                  <InfoIcon style={{ fontSize: '1rem' }} />
                </Tooltip>
              </Typography>
            </div>
            <div>
              <div className='text-view-value p-0 w-50'>
                <div className='outlined-border transform p-0 '>
                  <RichTextPopup
                    value={currentValues['hl_screening_notes'] === '<p><br /></p>' || currentValues['hl_screening_notes'] === '<p><br></p>' ? null : currentValues['hl_screening_notes']}
                    setOpen={false}
                  />
                </div>
              </div>
              <div className='d-flex pt-4'>
                {renderCheckbox('GDPR Release', 'gdpr_release')}
                {renderCheckbox('Exclude from ClientSuite', 'hide_in_client_suite')}
              </div>
            </div>
          </div>
        </div>
        {!readOnly && <TabLayoutFooter actions={actions} />}
      </div>
    )
  );
});

ScreeningNotesAndCompensation.propTypes = {
  updateContactInfo: PropTypes.func,
  saveStatus: PropTypes.bool,
  autoSaveContactFields: PropTypes.func,
  userdata: PropTypes.object,
  getActivityLogInfoTabData: PropTypes.func,
  handleSaveTabData: PropTypes.func,
  readOnly: PropTypes.bool,
  isRichTextSaved: PropTypes.bool
};

export default ScreeningNotesAndCompensation;
