//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { fetchPickList } from '../actions';
import InputField from './common/InputField';

let counter = 1;
export default function DegreeSelection(props) {
  const { InputProps, InputLabelProps, required, label, placeholder, inputRef, disabled = false, ...rest } = props;

  const [options, setOptions] = React.useState([]);
  const degrees = useSelector(state => state.commonReducer.degrees);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!degrees) {
      counter === 1 && dispatch(fetchPickList('educations/degrees', 'degrees'));
      counter++;
    } else {
      let array = [];
      degrees.map(data => array.push(data.name));
      setOptions(array);
    }
  }, [degrees, dispatch]);

  return (
    <Autocomplete
      {...rest}
      options={options}
      disabled={disabled}
      renderInput={params => (
        <InputField
          {...params}
          ref={inputRef}
          InputProps={{ ...params.InputProps, ...InputProps }}
          variant='standard'
          InputLabelProps={InputLabelProps}
          required={required}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}

DegreeSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputRef: PropTypes.object,
  disabled: PropTypes.bool
};
