import { Box, FormControlLabel, Grid, MenuItem, Select, Switch, Typography } from '@mui/material';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import CustomModalWithHeader from '../../../Containers/Commons/CustomModalWithHeader';
import { BillingInfoApi, picklistsDataApis, ProjectDataApi } from '../../../services/ApiService';
import { GET, POST } from '../../../services/constantService';
import CustomButton from '../CustomButton';
import LoadingScreen from '../LoadingScreen';
import SuccessScreen from '../SuccessScreen';

const CloneProjectModal = ({ candidateStages, projectData, title, open, onClose, onSuccess, setCreatedProjectId }) => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [candidatePipeline, setCandidatePipeline] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const menuRef = useRef(null);
  const [instructionHelperText, setInstructionHelperText] = useState('');
  const [billingHelperText, setBillingHelperText] = useState('');
  const [loadingHelperText, setLoadingHelperText] = useState('');

  const fetchTranslateValue = async () => {
    const res = await picklistsDataApis(GET, 'CLONE_PROJECT_HELPER');

    if (res && res?.data && res.data.length > 0) {
      const helperText = res.data;
      helperText.forEach(text => {
        switch (text.field_value) {
          case 'clone_project_to_project_helper':
            setInstructionHelperText(text.short_desc);
            break;
          case 'clone_project_to_project_loading_helper':
            setLoadingHelperText(text.short_desc);
            break;
          case 'clone_project_billing_helper':
            setBillingHelperText(text.short_desc);
            break;
        }
      });
    }
  };

  const sections = {
    Overview: {
      options: ['Project Details', 'Team', 'Client Contacts']
    },
    Roadmap: {
      options: ['Position Profile', 'Scope', 'Experience', 'Competencies', 'Decisions']
    },
    Billing: {
      options: ['Billing Details']
    }
  };

  const allOptions = Object.values(sections).flatMap(section => section.options);

  const handleGlobalSelectAll = () => {
    const allSelected = allOptions.every(option => selectedOptions[option]);
    const newSelectedOptions = {};
    allOptions.forEach(option => {
      newSelectedOptions[option] = !allSelected;
    });
    setSelectedOptions(newSelectedOptions);

    // Select all candidate pipeline stages if all options are selected
    if (!allSelected) {
      setCandidatePipeline(candidateStages);
    } else {
      setCandidatePipeline([]);
    }
  };

  const isAllSelected = candidatePipeline.length === candidateStages.length;

  const handleCandidatePipelineChange = event => {
    const { value } = event.target;

    // Save the current scroll position
    const scrollPosition = menuRef.current?.scrollTop || 0;

    if (value.includes('selectAll')) {
      setCandidatePipeline(isAllSelected ? [] : candidateStages);
    } else {
      setCandidatePipeline(value);
    }

    // Set scroll position back to saved value after state change
    setTimeout(() => {
      if (menuRef.current) menuRef.current.scrollTop = scrollPosition;
    }, 0);
  };

  const handleOptionChange = option => {
    setSelectedOptions(prev => ({ ...prev, [option]: !prev[option] }));
  };

  const handleProjectClone = async () => {
    try {
      const subTabsMapping = {
        'Overview Details': {
          'Project Details': 'overview',
          Team: 'team',
          'Client Contacts': 'clientContacts'
        },
        Roadmap: {
          'Position Profile': 'POSITION_PROFILE',
          Scope: 'SCOPE',
          Experience: 'EXPERIENCE',
          Competencies: 'COMPETENCY',
          Decisions: 'DECISION'
        },
        CandidatePipeline: {}
      };

      const createProjectPayload = {
        location_place_id: projectData?.location_place_id,
        location: projectData?.location,
        company_id: projectData?.company_id,
        industry_id: projectData?.industry_id,
        job_title: projectData?.job_title,
        bd_project_id: projectData.id,
        bd_currency: projectData?.currency,
        job_type: projectData?.job_type || '',
        max_compensation: projectData?.projectData || 0,
        max_experience: projectData?.max_experience || 0,
        min_compensation: projectData?.min_compensation || 0,
        min_experience: projectData?.min_experience || 0,
        pay_frequency: projectData?.pay_frequency || '',
        stage: 'Draft'
      };

      setIsLoading(true);
      const { data: response } = await ProjectDataApi(POST, '', createProjectPayload, '', '');
      const createdProjectId = response.id;

      const overviewSubTabs = [];
      const roadmapSubTabs = [];

      for (const option of allOptions) {
        if (selectedOptions[option]) {
          if (subTabsMapping['Overview Details'][option]) {
            overviewSubTabs.push(subTabsMapping['Overview Details'][option]);
          } else if (subTabsMapping['Roadmap'][option]) {
            roadmapSubTabs.push(subTabsMapping['Roadmap'][option]);
          }
        }
      }

      // API calls for each section
      if (overviewSubTabs.length > 0) {
        await ProjectDataApi(
          POST,
          '',
          {
            projectId: createdProjectId,
            bdId: projectData.id,
            subTabs: overviewSubTabs
          },
          'copy-overview-from-bd'
        );
      }

      if (selectedOptions['Billing Details']) {
        await BillingInfoApi(
          POST,
          {
            projectId: createdProjectId,
            bdId: projectData.id
          },
          'replicate_revenues'
        );
      }

      if (roadmapSubTabs.length > 0) {
        await ProjectDataApi(
          POST,
          '',
          {
            projectId: createdProjectId,
            bdId: projectData.id,
            subTabs: roadmapSubTabs
          },
          'copy-roadmap-from-bd'
        );
      }

      // Clone selected candidate pipeline stages
      if (candidatePipeline.length > 0) {
        await ProjectDataApi(
          POST,
          '',
          {
            projectId: createdProjectId,
            bdId: projectData.id,
            stages: candidatePipeline
          },
          'copy-candidates-from-project'
        );
      }

      setCreatedProjectId(createdProjectId);
      setIsLoading(false);
      setIsSuccess(true);
    } catch (error) {
      console.error(error); // Log error for debugging
      setIsLoading(false);
      setIsSuccess(false);
    }
  };

  useEffect(() => {
    fetchTranslateValue();
  }, []);

  return (
    <CustomModalWithHeader label={title} closeIcon={true} isOpen={open} onClose={onClose} maxWidth='md'>
      {isLoading && <LoadingScreen title={'Cloning Your Project'} description={loadingHelperText} />}
      {!isLoading && !isSuccess && (
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              bgcolor: 'background.paper',
              p: 1.5,
              borderRadius: 1,
              border: '1px solid #e0e0e0',
              mb: 2
            }}
          >
            <Typography variant='subtitle2' sx={{ fontWeight: 600 }}>
              Instructions
            </Typography>
            <Typography variant='body2'>{instructionHelperText}</Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div />
            <FormControlLabel
              control={<Switch className='Switch-component' checked={allOptions.every(option => selectedOptions[option])} onChange={handleGlobalSelectAll} size='small' />}
              label={<Typography sx={{ fontWeight: 500 }}>Select All</Typography>}
            />
          </Box>

          {/* First Row: Overview Details and Candidate Pipeline */}
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {/* Overview Details */}
            <Grid item xs={12} sm={6}>
              <Box sx={{ mb: 1.5 }}>
                <Typography variant='subtitle1' sx={{ fontWeight: 500, mb: 0.5 }}>
                  Overview Details
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, ml: 2 }}>
                  {sections['Overview'].options.map(
                    (
                      option // Change 'Overview Details' to 'Overview'
                    ) => (
                      <FormControlLabel
                        key={option}
                        control={<Switch className='Switch-component' checked={selectedOptions[option] || false} onChange={() => handleOptionChange(option)} size='small' />}
                        label={<Typography variant='body2'>{option}</Typography>}
                        sx={{ margin: 0 }}
                      />
                    )
                  )}
                </Box>
              </Box>
            </Grid>

            {/* Candidate Pipeline */}
            <Grid item xs={12} sm={6}>
              <Box sx={{ mb: 1, maxWidth: '320px' }}>
                <Typography variant='subtitle2' sx={{ fontWeight: 500, mb: 0.5 }}>
                  Candidate Pipeline
                </Typography>
                <Select
                  value={candidatePipeline}
                  multiple
                  onChange={handleCandidatePipelineChange}
                  displayEmpty
                  renderValue={selected => (selected.length === 0 ? 'Select Stages' : selected.join(', '))}
                  size='small'
                  sx={{
                    width: '100%',
                    '& .MuiSelect-select': {
                      py: 0.25,
                      minHeight: '20px'
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: '4px 14px'
                    }
                  }}
                  MenuProps={{
                    MenuListProps: {
                      ref: menuRef, // Attach ref to the menu list for scroll control
                      sx: {
                        maxHeight: '200px',
                        overflowY: 'auto'
                      }
                    }
                  }}
                >
                  {/* "Select All" option */}
                  <MenuItem value='selectAll' dense>
                    <Switch className='Switch-component' checked={isAllSelected} indeterminate={candidatePipeline.length > 0 && candidatePipeline.length < candidateStages.length} size='small' />
                    <Typography variant='body2'>Select All</Typography>
                  </MenuItem>

                  {/* Individual stage options */}
                  {candidateStages.map(stage => (
                    <MenuItem key={stage} value={stage} dense>
                      <Switch className='Switch-component' checked={candidatePipeline.includes(stage)} size='small' />
                      <Typography variant='body2'>{stage}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Grid>
          </Grid>

          {/* Second Row: Roadmap and Billing */}
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {/* Roadmap */}
            <Grid item xs={12} sm={6}>
              <Box sx={{ mb: 1.5 }}>
                <Typography variant='subtitle1' sx={{ fontWeight: 500, mb: 0.5 }}>
                  Roadmap
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, ml: 2 }}>
                  {sections['Roadmap'].options.map(
                    (
                      option // This line is already correct
                    ) => (
                      <FormControlLabel
                        key={option}
                        control={<Switch className='Switch-component' checked={selectedOptions[option] || false} onChange={() => handleOptionChange(option)} size='small' />}
                        label={<Typography variant='body2'>{option}</Typography>}
                        sx={{ margin: 0 }}
                      />
                    )
                  )}
                </Box>
              </Box>
            </Grid>

            {/* Billing */}
            <Grid item xs={12} sm={6}>
              <Box sx={{ mb: 1.5 }}>
                <Typography variant='subtitle1' sx={{ fontWeight: 500, mb: 0.5 }}>
                  Billing
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, ml: 2 }}>
                  {sections['Billing'].options.map(
                    (
                      option // This line is already correct
                    ) => (
                      <FormControlLabel
                        key={option}
                        control={<Switch className='Switch-component' checked={selectedOptions[option] || false} onChange={() => handleOptionChange(option)} size='small' />}
                        label={<Typography variant='body2'>{option}</Typography>}
                        sx={{ margin: 0 }}
                      />
                    )
                  )}
                </Box>
                <Typography variant='caption' sx={{ color: 'error.main', ml: 2, display: 'block' }}>
                  {billingHelperText}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div className='w-100 d-flex justify-content-end align-items-center mt-4'>
              <CustomButton type='tertiary-error' variant='text' buttonText='Cancel' style={{ marginRight: '12px' }} onClick={onClose} />
              <CustomButton buttonText='Create Project' style={{ marginRight: '12px' }} onClick={handleProjectClone} />
            </div>
          </Grid>
        </Box>
      )}
      {isSuccess && <SuccessScreen title={t('project.projectCreated')} description={t('project.projectCreatedSuccessMessage')} successBtnText={t('project.viewProject')} onSuccess={onSuccess} />}
    </CustomModalWithHeader>
  );
};

CloneProjectModal.propTypes = {
  projectData: PropTypes.object,
  onSuccess: PropTypes.func,
  setCreatedProjectId: PropTypes.func,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  candidateStages: PropTypes.array
};

export default CloneProjectModal;
