import * as React from 'react';
import CustomDropdown from '../../CustomDropdown';
import PropTypes from 'prop-types';
import '../../../../Containers/Commons/SingleSelectAutoComplete/index.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import { picklistDropDownApis } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
/*import { useDispatch, useSelector } from "react-redux";
import { fetchPickList } from "../../../../actions";*/
export default function BonusUnitSelection(props) {
  const {
    name = '',
    onChange = () => {},
    disabled = false,
    isDrawer = false,
    multiple = false,
    label,
    disableCloseOnSelect = false,
    className = '',
    isCheckBox = false,
    value,
    defaultValue,
    code
  } = props;
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const getUnit = async () => {
      const sub_route = `currency-details?code=${code}`;
      const { status, data } = await picklistDropDownApis(GET, sub_route);
      if (status === 200) {
        let array = [{ label: '%', name: '%', id: 'percentage' }];
        array.push(data ? { label: data?.currency_icon, name: data?.currency_icon, id: data?.id ? data?.id : null } : { label: '$', name: '$', id: 'dollar' }), setOptions(array);
        console.log(array, 'option array');
      } else {
        setOptions([]);
      }
    };
    getUnit();
  }, [code]);
  return (
    <CustomDropdown
      options={options}
      name={name}
      onChange={onChange}
      disabled={disabled}
      isDrawer={isDrawer}
      multiple={multiple}
      label={label}
      disableCloseOnSelect={disableCloseOnSelect}
      className={className}
      isCheckBox={isCheckBox}
      value={value}
      defaultValue={defaultValue}
    />
  );
}
BonusUnitSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isDrawer: PropTypes.bool,
  multiple: PropTypes.bool,
  isCheckBox: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool,
  defaultValue: PropTypes.string,
  code: PropTypes.string
};
