import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails, Box, IconButton, Tooltip } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility'; // Visible icon
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'; // Hidden icon

export function ExpandableSection(props) {
  const {
    panelName = '',
    ariaControlls = '',
    accID = '',
    handleExpanded,
    defaultOpen,
    className = '',
    width = '100%',
    titleClassName = 'fs-14',
    accordionClassName = '',
    isTeamInfo = false,
    handleExperienceVisibility,
    isVisible = true,
    showHideIcon = false
  } = props;

  const [expanded, setExpanded] = useState(defaultOpen);

  useEffect(() => {
    setExpanded(defaultOpen);
  }, [defaultOpen]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleHide = () => {
    handleExperienceVisibility();
  };

  return (
    <Accordion
      className={`expandable-section ${className} ${isVisible ? '' : 'grayish'}`}
      expanded={panelName === '' ? expanded : defaultOpen}
      onChange={panelName === '' ? handleExpand : handleExpanded}
      sx={{
        width: width
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={ariaControlls} id={accID} className={accordionClassName}>
        <Box className={`${!props.isSectionNotLabel ? 'section-label' : ''} m-0 ${isTeamInfo ? 'py-2' : 'p-0'} expandable-section-title ${titleClassName} ${!isVisible && 'grayish'}`}>
          {props.title}{' '}
        </Box>
        {showHideIcon && (
          <IconButton onClick={handleHide} edge='end' size='small'>
            <Tooltip title={'Hide Experience'} placement='top' arrow className={'p-0'}>
              {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </Tooltip>
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails className={`${isTeamInfo && 'p-0'} ${!isVisible && 'grayish'}`}>{props.children}</AccordionDetails>
    </Accordion>
  );
}

ExpandableSection.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  className: PropTypes.string,
  isSectionNotLabel: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  showActivityLabel: PropTypes.bool,
  panelName: PropTypes.string,
  ariaControlls: PropTypes.string,
  accID: PropTypes.string,
  handleExpanded: PropTypes.func,
  width: PropTypes.string,
  titleClassName: PropTypes.string,
  accordionClassName: PropTypes.string,
  isTeamInfo: PropTypes.bool,
  handleExperienceVisibility: PropTypes.func,
  isVisible: PropTypes.bool,
  showHideIcon: PropTypes.bool
};
