//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState, useRef } from 'react';
import * as yup from 'yup';

//-----------------------------------------------------------// External Imports // ------------------------------

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-input-material-ui';

import Popup from 'reactjs-popup';
import { Button, Typography, TextField, InputLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import CompanySelection from '../../../../components/AddContact/Components/CompanySelection';
import CountrySelection from '../../../../components/CountrySelection';
import IndustrySelection from '../../../../components/IndustrySelection';
import InputField from '../../../../components/common/InputField';
import JobFunctionSelection from '../../../../components/jobFunctionSelection';
import Loader from '../../../../components/common/Loader';
import YearSelection from '../../../../components/YearSelection';
import ErrorPopup from '../../../../components/AddCompany/ErrorPopup';
import { getUserSelector } from '../../../../selectors';
import { EMAIL_REGEX, ERROR, POST, SUCCESS } from '../../../../services/constantService';
import { contactDataApi } from '../../../../services/ApiService';
import { unableMessage, validateMessage, successMessage, VALIDATION_MESSAGE } from '../../../../services/MessageService';
import { customFormValidator, requireValidMessage } from '../../../../utils/common';
import { useSnackbar } from 'notistack';

const QuickAddContact = props => {
  const {
    open,
    handleClose,
    contact = null,

    isAddToSearchEnabled = false,
    addToText = 'Create Candidate & add to Search'
  } = props;
  const { register, unregister, reset, setValue, handleSubmit, watch, control, getValues } = useForm(/* {defaultValues: contact} */);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(getUserSelector);
  const [duplicateContacts, setDuplicateContacts] = useState(false);
  const [addToSearch, setAddToSearch] = useState(false);
  const [defaultValues, setDefaultValues] = useState();

  const companyRef = useRef();
  const countryRef = useRef();
  const industryRef = useRef();
  const startYearRef = useRef();

  const handleCancel = () => {
    setDuplicateContacts(false);
  };

  const handleAddDuplicate = async () => {
    try {
      await createContact({ ...getValues() }, addToSearch, true);
    } catch (e) {
      console.log('Error found in handleAddDuplicate::', e);
    }
  };

  const handleShowContacts = () => {
    const idQuery = duplicateContacts.map(contact => `id=${contact.id}`).join('&');
    window.open(`/contacts?${idQuery}`);
  };

  useEffect(() => {
    register('current_company_id');
    register('country');
    register('industries', { required: true, validate: value => value?.length > 0 });
    register('job_functions');
    return () => {
      unregister('current_company_id');
      unregister('country');
      unregister('industries');
      unregister('job_functions');
    };
  }, [register, unregister]);

  useEffect(() => {
    let data = contact ? contact : null;
    setDefaultValues(data);
    reset(data);
  }, []);

  const CreateSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    current_company_id: yup.string().required(),
    current_job_title: yup.string().required(),
    current_job_start_year: yup.string().required(),
    country: yup.string().required(),
    industries: yup.array().of(yup.string()).required(),
    job_functions: yup.array().of(yup.string()).required()
  });

  const createContact = async (payload, isAddToSearch, allowDuplicate) => {
    const workEmail = payload.work_email;
    const privateEmail = payload.private_email;
    const workEmailValidator = EMAIL_REGEX.test(workEmail);
    const privateEmailValidator = EMAIL_REGEX.test(privateEmail);
    const isValid = await CreateSchema.isValid(payload);
    if (!isValid) {
      let requiredField = [
        { fieldName: 'first_name', label: 'First Name', type: String },
        { fieldName: 'last_name', label: 'Last Name', type: String },
        { fieldName: 'current_company_id', label: 'Company Name', type: String },
        { fieldName: 'current_job_title', label: 'Job Title', type: String },
        { fieldName: 'current_job_start_year', label: 'Job Start Year', type: String },
        { fieldName: 'country', label: 'Country', type: String },
        { fieldName: 'industries', label: 'Industry', type: [] },
        { fieldName: 'job_functions', label: 'Job Functions', type: [] }
      ];
      let dirtyField = customFormValidator(payload, requiredField);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    if (workEmail.trim()?.length > 0 && privateEmail.trim()?.length > 0 && !workEmailValidator && !privateEmailValidator) {
      const message = validateMessage('valid WORK EMAIL and PRIVATE EMAIL', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
    } else if (workEmail.trim()?.length > 0 && !workEmailValidator) {
      const message = validateMessage('valid WORK EMAIL', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
    } else if (privateEmail.trim()?.length > 0 && !privateEmailValidator) {
      const message = validateMessage('valid PRIVATE EMAIL', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
    } else {
      saveDate(payload, isAddToSearch, allowDuplicate);
    }
  };

  const saveDate = async (payload, isAddToSearch, allowDuplicate) => {
    setIsLoading(true);
    const sub_route = allowDuplicate ? '?allowDuplicate=true' : '';
    const { status, data } = await contactDataApi(POST, '', payload, sub_route);
    if (status === 201) {
      const message = successMessage('Contact', 'created');
      enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      handleClose(
        {
          id: data.id,
          first_name: payload.first_name,
          last_name: payload.last_name
        },
        isAddToSearch
      );
    } else if (status === 200) {
      setAddToSearch(isAddToSearch);
      setDuplicateContacts(data.contacts);
    } else {
      const message = unableMessage('contact', 'create');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setIsLoading(false);
  };

  const handleCreateContact = async payload => {
    console.log('payload', payload);
    try {
      // if (
      //   !payload.country ||
      //   !payload.industries?.length > 0 ||
      //   !payload.current_job_start_year ||
      //   !payload.current_job_title ||
      //   payload.current_company_id === null ||
      //   !payload.first_name ||
      //   !payload.last_name ||
      //   !payload.job_functions?.length > 0
      // ) {
      //   const message = validateMessage("required fields", "fill");
      //   return enqueueSnackbar(message, { variant: ERROR });
      // }
      await createContact(payload, false);
    } catch (e) {
      console.error('Error found in handleCreateContact::', e);
    }
  };

  const handleAddToSearch = async payload => {
    try {
      // if (
      //   !payload.country ||
      //   !payload.industries?.length > 0 ||
      //   !payload.current_job_start_year ||
      //   !payload.current_job_title ||
      //   payload.current_company_id === null ||
      //   !payload.first_name ||
      //   !payload.last_name ||
      //   !payload.job_functions?.length > 0
      // ) {
      //   const message = validateMessage("required fields", "fill");
      //   return enqueueSnackbar(message, { variant: ERROR });
      // }
      await createContact(payload, true);
    } catch (e) {
      console.error('Error found in handleAddToSearch::', e);
    }
  };

  return (
    <Popup open={open} className={'quick-add-contact-full'} onClose={() => handleClose()} closeOnDocumentClick={false} closeOnEscape={false}>
      <React.Fragment>
        <Loader show={isLoading} />
        {duplicateContacts && (
          <ErrorPopup
            onCancel={handleCancel}
            onView={handleShowContacts}
            onContinue={handleAddDuplicate}
            header={VALIDATION_MESSAGE.contact_already_exist}
            viewButtonText={'View/Edit existing contacts'}
          />
        )}
        <div className='h-100 overflow-auto'>
          <div className='quick-add-header'>
            <Typography>Quick Add Contact</Typography>
            <CloseIcon className='cursor-pointer' onClick={() => handleClose()} />
          </div>
          <div className={'quick-add-container'}>
            <div className='d-flex flex-column h-100'>
              <div className='quick-add-content flex-row d-flex'>
                <div className='left-section'>
                  <div className='field-details'>
                    <InputLabel required={true} className='field-title' color='primary'>
                      First Name
                    </InputLabel>
                    <TextField
                      placeholder='Text here'
                      // required={true}
                      {...register('first_name')}
                      className='field-value'
                      name={'first_name'}
                    />
                  </div>
                  <div className='field-details'>
                    <InputLabel required={true} className='field-title' color='primary'>
                      Last Name
                    </InputLabel>
                    <TextField
                      placeholder='Text here'
                      // required={true}
                      {...register('last_name')}
                      className='field-value'
                      name={'last_name'}
                    />
                  </div>
                  <div className='field-details'>
                    <InputLabel required={true} className='field-title' color='primary'>
                      Company Name
                    </InputLabel>
                    <Controller
                      name='current_company_id'
                      defaultValue={null}
                      render={({ field }) => (
                        <CompanySelection
                          {...field}
                          name='current_company_id'
                          {...register('current_company_id')}
                          // inputRef={companyRef}
                          className='field-value'
                          label=''
                          placeholder={'Search here'}
                          onChange={(e, data) => {
                            console.log('current_company_id', data);
                            setValue('current_company_id', data ? data.id : null);
                          }}
                        />
                      )}
                      control={control}
                      onFocus={() => {
                        companyRef.current.focus();
                      }}
                      onChange={([, data]) => (data ? data.id : null)}
                      // rules={{ required: true }}
                    />
                  </div>
                  <div className='field-details'>
                    <InputLabel required={true} className='field-title' color='primary'>
                      Job Title
                    </InputLabel>
                    <TextField
                      // required
                      placeholder='Text here'
                      className='field-value'
                      {...register('current_job_title')}
                      name='current_job_title'
                    />
                  </div>
                  <div className='field-details'>
                    <InputLabel required={true} className='field-title' color='primary'>
                      Job Start Year
                    </InputLabel>
                    {(defaultValues || !contact) && (
                      <Controller
                        name={'current_job_start_year'}
                        render={({ field }) => (
                          <YearSelection
                            {...field}
                            name={'current_job_start_year'}
                            {...register('current_job_start_year')}
                            autoSelect
                            autoHighlight
                            className='field-value'
                            label=''
                            yearType='start'
                            inputRef={startYearRef}
                            InputLabelProps={{ focused: true }}
                            onChange={(e, data) => {
                              setValue('current_job_start_year', data);
                            }}
                          />
                        )}
                        control={control}
                        onFocus={() => {
                          startYearRef.current.focus();
                        }}
                        onChange={([, data]) => data}
                        // rules={{ required: true }}
                      />
                    )}
                  </div>
                  <div className='field-details'>
                    <InputLabel required={true} className='field-title' color='primary'>
                      Country
                    </InputLabel>
                    <Controller
                      name={'country'}
                      defaultValue={null}
                      render={({ field }) => (
                        <CountrySelection
                          {...field}
                          // required={true}
                          // inputRef={countryRef}
                          name={'country'}
                          {...register('country')}
                          className='input-form-field'
                          value={watch('country') || ''}
                          onChange={(e, data) => {
                            setValue('country', data.name);
                          }}
                          placeholder={'country'}
                          InputLabelProps={{ focused: true }}
                          label={''}
                          variant='outlined'
                        />
                      )}
                      control={control}
                      onFocus={() => {
                        countryRef.current.focus();
                      }}
                      onChange={([, data]) => (data ? data.name : null)}
                      // rules={{ required: true }}
                    />
                    {/* <CountrySelection
                      // {...field}
                      // required={true}
                      // inputRef={countryRef}
                      name={'country'}
                      className='input-form-field'
                      value={watch('country') || ""}
                      onChange={(e, data) => {
                        setValue('country', data.name)
                      }}
                      placeholder={"country"}
                      InputLabelProps={{ focused: true }}
                      label={''}
                      variant="outlined"
                    /> */}
                  </div>
                  <div className='field-details'>
                    <InputLabel required={true} className='field-title' color='primary'>
                      Industry
                    </InputLabel>
                    {
                      (defaultValues || !contact) && (
                        <Controller
                          name='industries'
                          render={({ field }) => (
                            <IndustrySelection
                              {...field}
                              name={'industries'}
                              {...register('industries')}
                              multiple
                              // required={true}
                              // placeholder={"Select"}
                              variant='outlined'
                              className='input-form-field'
                              inputRef={industryRef}
                              value={watch('industries') || []}
                              onChange={(e, data) => {
                                setValue('industries', data ? data.map(item => (typeof item === 'string' ? item : item.name)) : []);
                              }}
                              size='small'
                              filterSelectedOptions={true}
                              InputLabelProps={{ focused: true }}
                            />
                          )}
                          control={control}
                          onFocus={() => {
                            industryRef.current.focus();
                          }}
                          onChange={([, data]) => (Array.isArray(data) ? data.map(item => item.name) : [])}
                          // rules={{ required: true, validate: d => d?.length > 0 }}
                        />
                      )
                      // <IndustrySelection
                      //   // {...field}
                      //   name={'industries'}
                      //   multiple
                      //   // required={true}
                      //   // placeholder={"Select"}
                      //   variant="outlined"
                      //   className="input-form-field"
                      //   // inputRef={industryRef}
                      //   value={watch('industries') || []}
                      //   onChange={(e, data) => {
                      //     setValue('industries', data ? data.map((item) => typeof item === 'string' ? item : item.name) : [])
                      //   }}
                      //   size="small"
                      //   filterSelectedOptions={true}
                      //   InputLabelProps={{ focused: true }}
                      // />
                    }
                  </div>
                </div>
                <div className='right-section'>
                  <div className='field-details'>
                    <InputLabel className='field-title' color='primary'>
                      Mobile Phone
                    </InputLabel>
                    <div className='field-value'>
                      <ReactPhoneInput
                        {...register('mobile_phone')}
                        placeholder='Enter Here'
                        value={watch('mobile_phone') || ''}
                        name='mobile_phone'
                        onChange={(data, c, e, value) => {
                          if (data?.length > 1) {
                            setValue('mobile_phone', value);
                          } else {
                            setValue('mobile_phone', '');
                          }
                        }}
                        enableSearch={true}
                        component={TextField}
                      />
                    </div>
                  </div>
                  <div className='field-details'>
                    <InputLabel className='field-title' color='primary'>
                      Direct line
                    </InputLabel>
                    <div className='field-value'>
                      <ReactPhoneInput
                        {...register('direct_line')}
                        name='direct_line'
                        placeholder='Enter Here'
                        value={watch('direct_line') || ''}
                        onChange={(data, c, e, value) => {
                          if (data?.length > 1) {
                            setValue('direct_line', value);
                          } else {
                            setValue('direct_line', '');
                          }
                        }}
                        enableSearch={true}
                        component={TextField}
                      />
                    </div>
                  </div>
                  <div className='field-details'>
                    <InputLabel className='field-title' color='primary'>
                      Work Email
                    </InputLabel>
                    <InputField {...register('work_email')} className='field-value' type='email' placeholder='Text Here' name='work_email' />
                  </div>
                  <div className='field-details'>
                    <InputLabel className='field-title' color='primary'>
                      Private Email
                    </InputLabel>
                    <InputField {...register('private_email')} className='field-value' type='email' placeholder='Text Here' name='private_email' />
                  </div>
                  <div className='field-details'>
                    <InputLabel className='field-title' color='primary'>
                      LinkedIn URL
                    </InputLabel>
                    <InputField className='field-value' placeholder='Text Here' {...register('linkedin_url')} name='linkedin_url' />
                  </div>
                  <div className='field-details'>
                    <InputLabel required={true} className='field-title' color='primary'>
                      Job Function
                    </InputLabel>
                    <JobFunctionSelection
                      multiple
                      value={watch('job_functions') || []}
                      className='field-value'
                      style={{ overflow: 'hidden' }}
                      label=''
                      filterSelectedOptions={true}
                      onChange={(e, data) => {
                        setValue('job_functions', data ? data.map(item => (typeof item === 'string' ? item : item.name)) : []);
                      }}
                    />
                  </div>
                  <div className='field-details mt-2'>
                    <InputLabel className='field-title mb-0' color='primary'>
                      Identified By
                    </InputLabel>
                    <span className='field-value'>{user?.name || ''}</span>
                  </div>
                </div>
              </div>
              <div className='quick-add-footer'>
                <Button variant={isAddToSearchEnabled ? 'text' : 'contained'} onClick={handleSubmit(handleCreateContact)} className='button' color='primary'>
                  Create Contact
                </Button>
                {isAddToSearchEnabled && (
                  <Button color='primary' variant='contained' className='button' onClick={handleSubmit(handleAddToSearch)}>
                    {addToText}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </Popup>
  );
};

QuickAddContact.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  isAddToSearchEnabled: PropTypes.bool,
  addToText: PropTypes.string,
  contact: PropTypes.object
};

export default QuickAddContact;
