//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchPickList } from '../../../../../actions';

function SearchResultSelection(props) {
  const { className = 'w-100', label = 'Search Result', placeholder = 'Search Result', required = false, InputLabelProps, variant = 'standard', ...rest } = props;
  const [options, setOptions] = useState([]);
  const searchResults = useSelector(state => state.commonReducer.searchResults);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!searchResults) {
      dispatch(fetchPickList('searchresults', 'searchResults'));
    } else {
      setOptions(searchResults);
    }
  }, [searchResults, dispatch]);

  return (
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      getOptionSelected={(option, value) => {
        if (typeof value === 'string') {
          return option.name === value;
        }
        return option.name === value.name;
      }}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      renderInput={params => <TextField {...params} required={required} InputLabelProps={InputLabelProps} placeholder={placeholder} label={label} variant={variant} />}
    />
  );
}

SearchResultSelection.propTypes = {
  register: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  variant: PropTypes.string
};

export default SearchResultSelection;
