import { AgGridReact } from 'ag-grid-react';
import proptype from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../services/constantService';
import ExportCSV from '../../../utils/ExportCSV';
import ColumnFilter from '../../Commons/ColumnFilter';
import { defaultColumns } from '../../Companies/Utils';
import CustomHeader from './CustomHeader';
let columnApi;
const TableView = ({ projectGridColumn, projectGridData, sx, loading }) => {
  const [isGridReady, setIsGridReady] = useState(false);
  const navigate = useNavigate();

  const onClickGridProject = project => {
    const { id, candidate_count } = project;

    let pathWithTab = ROUTES.viewProjectWithDeepLink;
    pathWithTab = pathWithTab.replace(':id', id);
    if (candidate_count > 0) {
      pathWithTab = pathWithTab.replace(':parentTab', 'Candidate Pipeline');
      pathWithTab = pathWithTab.replace(':subTab', 'null');
    } else {
      pathWithTab = pathWithTab.replace(':parentTab', 'Overview');
      pathWithTab = pathWithTab.replace(':subTab', 'Details');
    }
    navigate(pathWithTab);
  };
  const columns = useMemo(() => {
    return projectGridColumn.map(col => {
      if (col.field === 'job_number') {
        return {
          ...col,
          cellRenderer: params => {
            const a = document.createElement('a');
            a.textContent = params.value;
            a.style.textDecoration = 'underline';
            a.onclick = () => {
              onClickGridProject(params?.data);
            };
            return a;
          }
        };
      }
      return col;
    });
  }, [projectGridColumn, navigate]);

  const data = useMemo(() => projectGridData, [projectGridData, loading]);
  const isRowSelectable = useMemo(() => {
    return params => (params.data ? params.data.stage === 'Open' : false);
  }, []);
  const onGridReady = params => {
    columnApi = params.columnApi;
    params.columnApi.autoSizeColumns();
    // loadColumnStateFromLocalStorage("contactColumns", { columnApi, gridApi });
    params.columnApi?.getAllColumns().map(col => {
      console.log('check', col?.colDef);
      params.columnApi?.setColumnVisible(col.colId, col?.colDef?.visible);
    });
    setIsGridReady(true);
  };

  const exportCSVColumns = [
    { key: 'job_number', label: 'Project ID' },
    { key: 'job_title', label: 'Job Title' },
    { key: 'ign_companies.name', label: 'Company' },
    { key: 'creator.name', label: 'Added By' },
    { key: 'stage', label: 'Stage' },
    { key: 'location', label: 'Location' },
    { key: 'ign_industries.name', label: 'Industry' },
    { key: 'service_offering', label: 'Service Offering' },
    { key: 'candidate_count', label: 'Candidates' },
    { key: 'probability', label: 'Probability' },
    { key: 'min_experience', label: 'Minimum Experience' },
    { key: 'max_experience', label: 'Maximum Experience' },
    { key: 'min_compensation', label: 'Minimum Compensation' },
    { key: 'max_compensation', label: 'Maximum Compensation' },
    { key: 'fee_type', label: 'Fee Type' },
    { key: 'fee_percentage', label: 'Fee Percentage' },
    { key: 'term_type', label: 'Term Type' }
  ];
  return (
    <>
      <div className='d-flex justify-content-end align-items-center mt-2 p-2'>
        <ExportCSV columns={exportCSVColumns} data={data} fileName='Projects.csv' />
      </div>
      <div id='myGrid' className='ag-theme-alpine header-column-filter'>
        <AgGridReact
          columnDefs={columns}
          rowData={data}
          suppressRowClickSelection={true}
          isRowSelectable={isRowSelectable}
          paginationPageSize={10}
          rowSelection='multiple'
          enableRangeSelection={true}
          defaultColDef={{
            resizable: true,
            filter: true,
            sortable: true
          }}
          headerComponentFramework={CustomHeader}
          {...sx}
          columnMenu={true}
          onGridReady={onGridReady}
        />
        {isGridReady && (
          <ColumnFilter
            columnApi={columnApi}
            defaultColumns={defaultColumns}
            //showFilterCount={showFilterCount}
            //filterModel={filterModel}
          />
        )}
      </div>
    </>
  );
};

TableView.propTypes = {
  projectGridColumn: proptype.array,
  projectGridData: proptype.array,
  sx: proptype.object,
  loading: proptype.bool
};

export default TableView;
