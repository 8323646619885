//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

// -------------------------------------------------// External Imports// -------------------------------------------------
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ExpandMore } from '@mui/icons-material';
import CustomButton from '../../components/common/CustomButton';

function PopUpOnClick({ list, title, selector = false }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = item => {
    if (item?.onClick) {
      item.onClick();
    }
    setAnchorEl(null);
  };

  return (
    <div id='pop-up-on-click'>
      {selector ? (
        <MoreVertIcon className='moveVerticalIconStyle' onClick={handleClick} />
      ) : (
        <CustomButton
          type={'primary'}
          variant='contained'
          size={'medium'}
          buttonText={title}
          customWidth={96}
          onClick={handleClick}
          iconRight={<ExpandMore className={`${open ? 'animate-icon-open' : 'animate-icon-closed'}`} sx={{ width: 20, height: 20 }} />}
        />
      )}
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {list &&
          list.map((item, index) => {
            return (
              <MenuItem className='fs-14 black-color' key={index} onClick={() => handleMenuItemClick(item)}>
                {item.label}
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
}

PopUpOnClick.propTypes = {
  list: PropTypes.object,
  title: PropTypes.string,
  selector: PropTypes.bool
};

export default PopUpOnClick;
