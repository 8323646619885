//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect, useRef } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { SEARCH_TIMEOUT } from '../../utils/common';
import { API } from '../../services/constantService';
import { getMethodWithCancelTokenApi } from '../../services/ApiService';

const CancelToken = axios.CancelToken;
const useStyles = makeStyles(() => ({
  root: {
    top: 10,
    width: 320,
    height: 40,
    fontSize: 13
  },
  inputRoot: {
    height: 37,
    paddingLeft: 10,
    fontSize: 19
  },
  input: {
    fontSize: 14,
    padding: 10
  },
  endAdornment: {
    paddingRight: 10
  }
}));

export default function Search(props) {
  const { placeholder = 'Search', value, type, defaultQuery, ...rest } = props;
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  let call = useRef();

  useEffect(() => {
    if (call.current) {
      call.current.cancel();
    }
    if (searchValue?.length < 3) {
      setOptions([]);
      setLoading(false);
      return;
    }
    call.current = CancelToken.source();
    setLoading(true);
    let url = `${API.picklists}/${type}?limit=100&searchValue=${searchValue}`;
    if (type === 'users') {
      url = `${API.user_search}?limit=100&name=${searchValue}`;
    }

    if (defaultQuery) {
      url = `${url}&${defaultQuery}`;
    }

    const timer = setTimeout(() => {
      getMethodWithCancelTokenApi(url, {}, {}, call.current.token).then(response => {
        const { status, data } = response;
        if (status === 200) {
          setOptions(JSON.parse(data)?.data);
        }
        setLoading(false);
      });
    }, SEARCH_TIMEOUT);
    return () => clearTimeout(timer);
  }, [searchValue, type]);

  return (
    <div style={{ display: 'flex' }}>
      <Autocomplete
        {...rest}
        classes={classes}
        freeSolo
        filterOptions={options => options}
        loading={false}
        options={options}
        getOptionLabel={option => {
          if (typeof option === 'string') {
            return option;
          } else if (type === 'quicksearches') {
            return option.job_title + ' ' + option.job_number;
          } else if (type === 'workbenches') {
            return option.name + '' + option.type;
          } else if (type === 'users') {
            return option.first_name + ' ' + option.last_name;
          } else if (type === 'translate_value') {
            return option.field_name + ' ' + option.field_value;
          }
        }}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              {type === 'quicksearches' && (
                <div>
                  <Typography>{option.job_title}</Typography>
                  <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>({option.job_number})</Typography>
                </div>
              )}
              {type === 'workbenches' && (
                <div>
                  <Typography>{option.name}</Typography>
                  <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>({option.type})</Typography>
                </div>
              )}
              {type === 'users' && (
                <div>
                  <Typography>
                    {option.first_name} {option.last_name}
                  </Typography>
                  <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>{option.email || option.personal_email}</Typography>
                </div>
              )}
              {type === 'translate_value' && (
                <div>
                  <Typography>{option.field_name}</Typography>
                  <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>{option.field_value}</Typography>
                </div>
              )}
            </li>
          );
        }}
        onInputChange={(e, val) => {
          setSearchValue(val);
        }}
        value={value ? value : ''}
        renderInput={params => (
          <TextField
            {...params}
            className='search'
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: isLoading ? <CircularProgress color='primary' size={20} /> : params.InputProps.endAdornment
            }}
          />
        )}
      />
      <div
        onClick={event => {
          props.onChange(event, searchValue);
        }}
        className={'search-icon'}
      >
        <SearchIcon />
      </div>
    </div>
  );
}

Search.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  searchText: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultQuery: PropTypes.string
};
