import { FilterList as FilterIcon } from '@mui/icons-material';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpDownCircleIcon from '@mui/icons-material/ArrowDropUp';
import { Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { globalSearchSelector } from '../../../selectors';
import '../index.scss';

const CustomHeader = props => {
  const { displayName, enableSorting = false, enableMenu = false, showColumnMenu = () => {}, column } = props;
  const filterButtonRef = React.useRef(null);
  const { gridApi } = useSelector(globalSearchSelector);
  console.log('gridApi', gridApi);

  const onFilterClicked = e => {
    e.stopPropagation();
    if (filterButtonRef.current) {
      showColumnMenu(filterButtonRef.current);
    }
  };

  const onSortRequested = () => {
    if (enableSorting) {
      // Get the current sort model
      const currentSortModel = gridApi?.current?.getSortModel();
      let newSortOrder;

      // Determine the new sort order
      const isColumnSorted = currentSortModel.some(sort => sort.colId === column.colId);
      if (isColumnSorted) {
        const currentSort = currentSortModel.find(sort => sort.colId === column.colId).sort;
        newSortOrder = currentSort === 'asc' ? 'desc' : 'asc';
      } else {
        newSortOrder = 'asc'; // Default to ascending if not sorted
      }

      // Set the new sort model
      gridApi?.current?.setSortModel([{ colId: column.colId, sort: newSortOrder }]);
    }
  };

  return (
    <Box onClick={onSortRequested} display={'flex'} justifyContent={'space-between'} alignItems={'center'} className='custom-header' style={{ cursor: enableSorting ? 'pointer' : 'default' }}>
      {displayName}
      <Box display={'flex'} alignItems={'center'} gap={1}>
        {enableMenu && (
          <IconButton size='small' onClick={onFilterClicked} aria-label='filter' ref={filterButtonRef}>
            <FilterIcon fontSize='small' style={{ color: 'white' }} />
          </IconButton>
        )}
        {enableSorting && (
          <Box display={'flex'} position={'relative'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
            <ArrowUpDownCircleIcon fontSize='small' style={{ position: 'absolute', top: '-0.8rem', color: 'white' }} />
            <ArrowDropDownCircleIcon fontSize='small' style={{ position: 'absolute', top: '-0.4rem', color: 'white' }} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomHeader;

CustomHeader.propTypes = {
  displayName: PropTypes.string,
  enableSorting: PropTypes.bool,
  enableMenu: PropTypes.bool,
  showColumnMenu: PropTypes.func,
  column: PropTypes.object,
  gridApi: PropTypes.object
};
