import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTagData } from '../../actions';
import CustomDropdown from './CustomDropdown';

const TagSelection = props => {
  const { label, defaultValue, onChange, disableCloseOnSelect } = props;
  const [options, setOptions] = React.useState([]);
  const [values, setValues] = useState({});
  const [option, setOption] = useState(false);
  const dispatch = useDispatch();
  const moduleTag = useSelector(state => state.commonReducer.companyWiseTag);
  useEffect(() => {
    if (!moduleTag) {
      dispatch(fetchTagData('', 'companyWiseTag', '?module=companies&name='));
    } else {
      setOptions(moduleTag);
    }
  }, [moduleTag, dispatch]);
  const getValueOfSelected = () => {
    let array = [];
    for (let x of defaultValue) {
      const index = options && options.findIndex(obj => obj?.name === x?.name);
      array.push(index);
    }
    let value = [];
    for (let y of array) {
      value.push(options[y]);
    }
    return value;
  };

  const handleChange = (e, item) => {
    setOption(true);
    setValues(item);
    onChange(item);
  };
  return (
    <div>
      <CustomDropdown
        className='tag-auto-complete'
        options={options}
        isCheckBox={true}
        value={option ? values : defaultValue ? getValueOfSelected() : []}
        label={label}
        disableCloseOnSelect={disableCloseOnSelect}
        //commonValue={commonValue}
        // value={""}
        defaultValue={defaultValue}
        onChange={handleChange}
        multiple={true}
        selectedValue={defaultValue}
      />
    </div>
  );
};
TagSelection.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
  disableCloseOnSelect: PropTypes.bool
};

export default TagSelection;
