import React, { useEffect, useState } from 'react';
import { ignCompanyDataApi } from '../../services/ApiService';
import { convertArrayBufferToBlobUrl } from '../../utils/common';
import { GET } from '../../services/constantService';
import { Avatar } from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PropTypes from 'prop-types';
import { isLocalhost } from '../../serviceWorker';

function CompanyImageAvatar({ id, ...rest }) {
  const [profileUrl, setProfileUrl] = useState('');

  const fetchImages = async id => {
    if (id && id.length && !isLocalhost) {
      const response = await ignCompanyDataApi(GET, null, id, 'image');
      // const response = '';
      if (response.status === 200) {
        const url = await convertArrayBufferToBlobUrl(response?.data?.Body?.data);
        console.log({ url });
        setProfileUrl(url);
      }
    }
  };
  useEffect(() => {
    fetchImages(id);
  }, [id]);
  return (
    <Avatar {...rest} src={profileUrl}>
      <ApartmentIcon className='w-100' />
    </Avatar>
  );
}

CompanyImageAvatar.propTypes = {
  id: PropTypes.string
};
export default CompanyImageAvatar;
