export const successMessage = (field, operation) => {
  let message = capitalizeFirstLetter(`${field} ${operation} successfully.`);
  return message;
};

// program to convert first letter of a string to uppercase
function capitalizeFirstLetter(str) {
  // converting first letter to uppercase
  if (str !== '') {
    const trimString = str.trim();
    const capitalized = trimString.replace(/^./, trimString[0].toUpperCase());
    return capitalized;
  }
}

export const unableMessage = (field, operation) => {
  return `Unable to ${operation} ${field}.`;
};

export const validateMessage = (field, operation) => {
  return `Please ${operation} the ${field}.`;
};

export const validationMessage = field => {
  return `Candidate does not have valid ${field}.`;
};
export const duplicateMessage = field => {
  return `Duplicate candidate found ${field}.`;
};
export const requireMessage = (field, word) => {
  return `${field} ${word} required.`;
};
export const requireTemplateMessage = (fieldName, verb, min, max) => {
  if (min !== undefined && max !== undefined) {
    return `${fieldName} ${verb} required and It should be between ${min} and ${max}`;
  } else if (min !== undefined) {
    return `${fieldName} ${verb} required and should be greater than or equal to ${min}`;
  } else if (max !== undefined) {
    return `${fieldName} ${verb} required and should be less than or equal to ${max}`;
  } else if (fieldName !== undefined || fieldName !== ' ') {
    return `${fieldName} ${verb} required`;
  }
};
export const notFoundMessage = field => {
  return `No ${field} found.`;
};
export const exportMessage = (limit, module) => {
  return `Only ${limit} ${module} records allowed to be exported. Please select less number of records than given limit`;
};

export const inviteEmailValidateMessage = field => {
  return `Email is mandatory for ${field} invite. Please update the email in candidate's contact.`;
};

export const invalidMessage = field => {
  return `Invalid ${field}.`;
};

export const VALIDATION_MESSAGE = {
  start_end_year: 'End year must be greater than start year',
  copy_search: "You can't copy to same search",
  required: 'Reason is required for stage',
  special_character: 'In Job Title  special character " is not supported',
  bd_target: 'This contact is already a BD target for',
  candidate_personal_email: 'Candidate does not have personal email',
  athena_email: "Email is mandatory for athena invite. Please update the email in candidate's contact.",
  candidate_suite_email: "Email is mandatory for Candidate suite invite. Please update the email in candidate's contact.",
  edit: 'Edit in progress.',
  there_edit: 'There is already edit in progress.',
  another_upload: 'Another attachment uploading is in progress, please complete it',
  hireLogic_workspace: 'No HireLogic workspace found for current user',
  max_ndas: 'You are only able to upload up to three NDAs',
  max_retainers: 'Added maximum retainers',
  hl_connect: "Couldn't connect to HL",
  notes_adding: 'Another notes adding is in progress, please complete it',
  edit_save: 'Edit is in progress. Save the details to view.',
  no_record_selected: 'Records not selected',
  export_fail: 'Failed to Export the Records',
  special_character_not_supported: 'Special is not supported',
  kgp_number_validation: 'KGP number should be under 4 digits only',
  company_not_available_message: 'Company is not available with given id',
  updated_message: 'updated',
  create_workbech_add_search: 'Create Workbench And Add Searches',
  unsave_changes_message: 'You are exiting with unsaved changes. Do you want to save?',
  contact_already_exist: 'This contact may already exist in Galaxy, please review before saving',
  close_textbox: 'Are you sure you want to close the text box?',
  add_contact_to_search: 'Add Contact to Search',
  add_contacts_to_search: 'Add Contacts to Search',
  researcher_information: 'Researcher Information',
  add_contact_as_client: 'Add Contact as a Client',
  create_workbench_add_contacts: 'Create Workbench And Add Contacts',
  admin_information: 'Admin Information',
  restore_workbench: 'Are you sure you want to restore this workbench?',
  archive_workbench: 'Are you sure you want to Archive this workbench?',
  aum_validation_message: 'From value must be less than To value',
  review_contact: 'Not Allowed more than 10 record to review.',
  review_contact_fail: 'Something went wrong. Please check the LinkedIn url in selected records.',
  delete_bd_search: 'Unsaved data will be lost. Do you wish to continue?',
  sfpa_close: 'Are you sure you want to proceed? Your current selection will be lost.',
  sfpa_save_confirm: 'Continue to save the selected data into SFPA. Please note that the unselected fetched results will be lost on this action.',
  threshold_confirmation: 'Estimated Revenue is below threshold. Request will be routed to Regional Head for approval. Do you want to continue?',
  date_validation_error: 'Invalid Date',
  contact_linkedin_url: 'Invalid LinkedIn URL structure, Valid structure: https://linkedin.com/in/username/'
};
