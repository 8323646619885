//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import YearSelection from '../../../YearSelection';
import InputField from '../../../common/InputField';
import UserSelection from '../../../UserSelection';
import CountrySelection from '../../../CountrySelection';
import StateSelection from '../../../StateSelection';
import { START_YEAR } from '../../../../services/constantService';

export default function ProfileDetailsView(props) {
  const { contact, renderLabel, editingField, setValue, register, renderActions, watch, option } = props;
  const country = watch('country');
  useEffect(() => {
    register('country', { required: true });
    register('state');
  }, [register]);
  const stateChange = state => {
    setValue('state', state);
  };
  return (
    <div className='section-container'>
      <div className='section-header'>Profile</div>
      <div className='d-flex contact-details-row'>
        <div className='contact-details'>
          {renderLabel('Job Start Year', 'current_job_start_year', editingField === 'current_job_start_year')}
          {editingField === 'current_job_start_year' ? (
            <div className='d-flex'>
              <YearSelection
                autoSelect
                autoHighlight
                className='contact-view-value'
                label=''
                defaultValue={contact['current_job_start_year']}
                required={true}
                onChange={(e, data) => {
                  setValue('current_job_start_year', data);
                }}
                InputLabelProps={{ focused: true }}
                yearType='start'
                closeIcon={null}
              />
              {renderActions('current_job_start_year', true)}
            </div>
          ) : (
            <span className='contact-view-value'>{contact.current_job_start_year || '--'}</span>
          )}
        </div>
        <div className='contact-details'>
          {renderLabel('Job End Year', 'current_job_end_year')}
          {editingField === 'current_job_end_year' ? (
            <div className='d-flex'>
              <YearSelection
                autoSelect
                autoHighlight
                className='contact-view-value'
                label=''
                defaultValue={contact['current_job_end_year']}
                required={true}
                onChange={(e, data) => {
                  setValue('current_job_end_year', data);
                }}
                InputLabelProps={{ focused: true }}
                yearType='tillEnd'
                selectedStartYear={START_YEAR}
              />
              {renderActions('current_job_end_year')}
            </div>
          ) : (
            <span className='contact-view-value'>{contact.current_job_end_year || '--'}</span>
          )}
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='contact-details'>
          <div className='contact-view-label'>Annual Revenue</div>
          <div className='contact-view-value'>{(contact.company && contact.company.revenue) || '--'}</div>
        </div>
        <div className='contact-details'>
          <div className='contact-view-label'>Company Ticker</div>
          <div className='contact-view-value'>{(contact.company && contact.company.ticker_sym) || '--'}</div>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='contact-details'>
          {renderLabel('Age', 'age')}
          {editingField === 'age' ? (
            <div className='d-flex'>
              <InputField
                {...register('age')}
                className='contact-view-value'
                name='age'
                label=''
                type='number'
                InputProps={{
                  inputProps: { min: 0 }
                }}
                InputLabelProps={{ focused: true }}
              />
              {renderActions('age')}
            </div>
          ) : (
            <span className='contact-view-value'>{contact.age || '--'}</span>
          )}
        </div>
        <div className='contact-details'>
          {renderLabel('Gender', 'gender')}
          {editingField === 'gender' ? (
            <div className='d-flex'>
              <FormControl className='w-100' style={{ paddingRight: 20 }}>
                <RadioGroup row aria-label='gender' defaultValue={contact['gender']} name='gender' className='radio-group'>
                  <FormControlLabel control={<Radio size='small' value='FEMALE' {...register('gender')} />} label='Female' />
                  <FormControlLabel control={<Radio size='small' value='MALE' {...register('gender')} />} label='Male' />
                  <FormControlLabel control={<Radio size='small' value='OTHER' {...register('gender')} />} label='Other' />
                </RadioGroup>
              </FormControl>
              {renderActions('gender')}
            </div>
          ) : (
            <div className='contact-view-value'>{contact.gender || '--'}</div>
          )}
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='contact-details'>
          {renderLabel('Location', 'country', editingField === 'country')}
          {editingField === 'country' ? (
            <div className='d-flex'>
              <CountrySelection
                defaultValue={contact.country}
                className='input-form-field input-field-old'
                onChange={(e, data) => {
                  stateChange('');
                  setValue('country', data?.name);
                }}
                label=''
                placeholder='Search'
                InputLabelProps={{ focused: true }}
              />
              {renderActions('country', true)}
            </div>
          ) : (
            <div className='contact-view-value'>{contact.country || '--'}</div>
          )}
        </div>
        <div className='contact-details'>
          {renderLabel('State', 'state')}
          {editingField === 'state' ? (
            <div className='d-flex'>
              <StateSelection
                value={watch('state') || null}
                defaultValue={contact.state}
                className='input-form-field input-field-old contact-view-value'
                country={country}
                register={register}
                label=''
                onChange={(e, state) => {
                  stateChange(state);
                }}
              />
              {renderActions('state')}
            </div>
          ) : (
            <div className='contact-view-value'>{contact.state || '--'}</div>
          )}
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='contact-details'>
          {renderLabel('City', 'city')}
          {editingField === 'city' ? (
            <div className='d-flex'>
              <InputField {...register('city')} className='contact-view-value' name='city' label='' InputLabelProps={{ focused: true }} />
              {renderActions('city')}
            </div>
          ) : (
            <span className='contact-view-value'>{contact.city || '--'}</span>
          )}
        </div>
        <div className='contact-details'>
          {renderLabel('Client', 'is_client')}
          {editingField === 'is_client' ? (
            <div className='d-flex'>
              <FormControlLabel control={<Checkbox size='small' defaultChecked={contact['is_client']} name='is_client' inputRef={register('is_client')} />} label='Client' />
              {renderActions('is_client')}
            </div>
          ) : (
            <div className='contact-view-value'>{contact.is_client != null ? (contact.is_client ? 'Yes' : 'No') : '--'}</div>
          )}
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='contact-details'>
          {renderLabel('Star', 'is_rock_star')}
          {editingField === 'is_rock_star' ? (
            <div className='d-flex'>
              <FormControlLabel control={<Checkbox size='small' defaultChecked={contact['is_rock_star']} name='is_rock_star' inputRef={register('is_rock_star')} />} label='Star' />
              {renderActions('is_rock_star')}
            </div>
          ) : (
            <div className='contact-view-value'>{contact.is_rock_star != null ? (contact.is_rock_star ? 'Yes' : 'No') : '--'}</div>
          )}
        </div>
        <div className='contact-details'>
          {renderLabel('Board Candidate', 'is_board_candidate')}
          {editingField === 'is_board_candidate' ? (
            <div className='d-flex'>
              <FormControlLabel
                control={<Checkbox size='small' defaultChecked={contact['is_board_candidate']} name='is_board_candidate' {...register('is_board_candidate')} />}
                label='Board Candidate'
              />
              {renderActions('is_board_candidate')}
            </div>
          ) : (
            <div className='contact-view-value'>{contact.is_board_candidate != null ? (contact.is_board_candidate ? 'Yes' : 'No') : '--'}</div>
          )}
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='contact-details'>
          {renderLabel('GDPR Release', 'gdpr_release')}
          {editingField === 'gdpr_release' ? (
            <div className='d-flex'>
              <FormControlLabel
                className='checkbox-container'
                control={
                  <Checkbox
                    className='checkbox-component'
                    size='small'
                    defaultChecked={contact['gdpr_release']}
                    name='gdpr_release'
                    // inputRef={register('gdpr_release')}
                    {...register('gdpr_release')}
                  />
                }
                label='Yes'
              />
              {renderActions('gdpr_release')}
            </div>
          ) : (
            <div className='contact-view-value'>{contact.gdpr_release ? 'Yes' : 'No'}</div>
          )}
        </div>
        <div className='contact-details'>
          {renderLabel('Exclude from ClientSuite', 'hide_in_client_suite')}
          {editingField === 'hide_in_client_suite' ? (
            <div className='d-flex'>
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    defaultChecked={contact['hide_in_client_suite']}
                    name='hide_in_client_suite'
                    // inputRef={register('hide_in_client_suite')}
                    {...register('hide_in_client_suite')}
                  />
                }
                label='Yes'
              />
              {renderActions('hide_in_client_suite')}
            </div>
          ) : (
            <div className='contact-view-value'>{contact.hide_in_client_suite ? 'Yes' : 'No'}</div>
          )}
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='contact-details'>
          {renderLabel('Mailing List', 'mailing_list')}
          {editingField === 'mailing_list' ? (
            <div className='d-flex'>
              <UserSelection
                option={option}
                multiple
                filterSelectedOptions={true}
                value={watch('mailing_list')}
                className='contact-view-value'
                label=''
                placeholder=''
                InputLabelProps={{ focused: true }}
                onChange={(e, data) => {
                  setValue('mailing_list', data);
                }}
              />
              {renderActions('mailing_list')}
            </div>
          ) : (
            <div className='contact-view-value'>{contact.mailing_list_user?.length ? contact.mailing_list_user.map(data => data.name).join(', ') : '--'}</div>
          )}
        </div>
        <div className='contact-details'>
          {renderLabel('International Experience', 'international_experience')}
          {editingField === 'international_experience' ? (
            <div className='d-flex'>
              <CountrySelection
                defaultValue={contact.international_experience || []}
                multiple={true}
                filterSelectedOptions={true}
                size={'small'}
                className='input-form-field input-field-old'
                onChange={(e, data) => {
                  setValue('international_experience', data);
                }}
                label=''
                placeholder=''
                InputLabelProps={{ focused: true }}
              />
              {renderActions('international_experience')}
            </div>
          ) : (
            <div className='contact-view-value'>{contact.international_experience ? contact.international_experience.join(', ') : '--'}</div>
          )}
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='contact-details'>
          {renderLabel('Diversity Candidate', 'diversity_candidate')}
          {editingField === 'diversity_candidate' ? (
            <div className='d-flex'>
              <FormControlLabel
                control={<Checkbox defaultChecked={contact['diversity_candidate']} size='small' style={{ paddingRight: 5 }} name='diversity_candidate' inputRef={register('diversity_candidate')} />}
                label='Yes'
              />
              {renderActions('diversity_candidate')}
            </div>
          ) : (
            <div className='contact-view-value'>{contact.diversity_candidate ? 'Yes' : 'No'}</div>
          )}
        </div>
      </div>
    </div>
  );
}

ProfileDetailsView.propTypes = {
  contact: PropTypes.object,
  renderLabel: PropTypes.func,
  editingField: PropTypes.string,
  setValue: PropTypes.func,
  register: PropTypes.func,
  renderActions: PropTypes.func,
  watch: PropTypes.func,
  option: PropTypes.array
};
