//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import Loader from '../Loader';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CustomPopup from '../CustomPopup';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import CustomInputField from '../StyledComponents/CustomInputField';
import '../../AddContact/index.scss';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { CONTACTS_LOGS_MESSAGE, EMAIL_TYPE, PHONE_TYPE } from '../../../services/constantService';
import CustomPhoneInput from '../CustomPhoneInput';

export default function QuickAddContact(props) {
  const { openQuickAddContact = false, handleClose, quickAddContactTitle = '', first_name, createContact, setContactName } = props;
  const { register, handleSubmit, getValues, setValue /* watch */ } = useForm({ defaultValues: { first_name } });
  const [email, setEmail] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState('');
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_ADD_CONTACT,
    TRANS_KEYS.CONTACTS_WORD_FOR,
    TRANS_KEYS.CONTACTS_FIRST_NAME,
    TRANS_KEYS.CONTACTS_LAST_NAME,
    TRANS_KEYS.CONTACTS_PASTE_LINKEDIN_URL,
    TRANS_KEYS.CONTACTS_EMAIL,
    TRANS_KEYS.CONTACTS_PHONE_NUMBER,
    TRANS_KEYS.CONTACTS_JOB_TITLE,
    TRANS_KEYS.CONTACTS_WORD_ADD,
    TRANS_KEYS.CONTACTS_ADD_CONTACT
  ]);

  useEffect(() => {
    register('first_name');
    register('last_name');
    register('contact_emails');
    register('linkedin_url');
    register('contact_phones');
    register('current_job_title');
  }, [register]);

  useEffect(() => {
    setValue('contact_emails', email, { shouldDirty: true });
    setValue('first_name', first_name, { shouldDirty: true });
    setValue('contact_phones', phoneNumber, { shouldDirty: true });
    setValue('current_job_title', jobTitle, { shouldDirty: true });
  }, [email, first_name, phoneNumber, jobTitle]);

  const handleEmail = e => {
    setEmail([{ email: e.target.value, email_type: EMAIL_TYPE.PERSONAL, is_primary: true }]);
  };

  const handlePhoneNumber = event => {
    setPhoneNumber([{ phone_number: event, phone_type: PHONE_TYPE.PERSONAL, is_primary: true }]);
  };
  const handleInputChange = event => {
    setValue('linkedin_url', event.target.value, { shouldDirty: true });
  };

  const addContact = async () => {
    try {
      setIsLoading(true);
      await createContact('', { ...getValues() });
      setIsLoading(false);
    } catch (e) {
      console.log(CONTACTS_LOGS_MESSAGE.ADD_CONTACT_ERROR, e);
      setIsLoading(false);
    }
  };

  return (
    <CustomPopup
      title={
        quickAddContactTitle ? `${t(`${TRANS_KEYS.CONTACTS_ADD_CONTACT}:key`)} ${t(`${TRANS_KEYS.CONTACTS_WORD_FOR}:key`)} : ${quickAddContactTitle}` : t(`${TRANS_KEYS.CONTACTS_ADD_CONTACT}:key`)
      }
      open={openQuickAddContact}
      onClose={handleClose}
      showAction={true}
      confirmText={t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`)}
      handleSubmit={handleSubmit(addContact)}
    >
      <Box id='education-experiencess' className='education-container '>
        <Loader show={isLoading} />
        <Box className='my-cards flex flex-wrap'>
          <Box className='form-section flex flex-column'>
            <Box className='section-content'>
              <Box className='flex flex-row flex-wrap d-flex p-2 pl-3 justify-content-between'>
                <Box className='row d-flex '>
                  <Box className='col-md-6'>
                    <Box className='education-input-base'>
                      <Box className='education-input'>
                        <CustomInputField
                          defaultValue={first_name || ''}
                          required={true}
                          label={t(`${TRANS_KEYS.CONTACTS_FIRST_NAME}:key`)}
                          onChange={e => {
                            setContactName(e.target.value);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className='col-md-6'>
                    <Box className='education-input-base'>
                      <Box className='education-input'>
                        <CustomInputField
                          label={t(`${TRANS_KEYS.CONTACTS_LAST_NAME}:key`)}
                          required={true}
                          onChange={e => {
                            setValue('last_name', e.target.value, { shouldDirty: true });
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className='flex flex-row flex-wrap d-flex p-2 pl-3 justify-content-between'>
                <Box className='row d-flex '>
                  <Box className='col-md-6'>
                    <Box className='education-input-base'>
                      <Box className='education-input'>
                        <CustomInputField
                          label={t(`${TRANS_KEYS.CONTACTS_EMAIL}:key`)}
                          onChange={e => {
                            handleEmail(e);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box className='col-md-6'>
                    <Box className='education-input-base'>
                      <Box className='education-input'>
                        <CustomInputField label={t(`${TRANS_KEYS.CONTACTS_PASTE_LINKEDIN_URL}:key`)} onChange={handleInputChange} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className='flex flex-row flex-wrap d-flex p-2 pl-3 justify-content-between'>
                <Box className='row d-flex '>
                  <Box className='col-md-6'>
                    <CustomPhoneInput
                      id='outlined-basic'
                      variant='outlined'
                      size='small'
                      label={t(`${TRANS_KEYS.CONTACTS_PHONE_NUMBER}:key`)}
                      value={phoneNumber.phone_number || ''}
                      onChange={e => handlePhoneNumber(e)}
                    />
                  </Box>
                  <Box className='col-md-6'>
                    <Box className='education-input-base'>
                      <Box className='education-input'>
                        <CustomInputField
                          label={t(`${TRANS_KEYS.CONTACTS_JOB_TITLE}:key`)}
                          onChange={e => {
                            setJobTitle(e.target.value);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomPopup>
  );
}

QuickAddContact.propTypes = {
  openQuickAddContact: PropTypes.bool,
  handleClose: PropTypes.func,
  quickAddContactTitle: PropTypes.string,
  createContact: PropTypes.func,
  first_name: PropTypes.string,
  setContactName: PropTypes.func
};
