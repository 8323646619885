//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import CloseIcon from '@mui/icons-material/Close';
import { Button, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
//-----------------------------------------------------------// Internal Imports // ------------------------------

import Loader from '../../../components/common/Loader';
import CompanySelection from '../../../components/AddContact/Components/CompanySelection';
import InputField from '../../../components/common/InputField';
import SearchStageSelection from '../../../components/CreateSearch/Components/SearchInformation/Components/SearchStageSelection';
import SearchTypeSelection from '../../../components/CreateSearch/Components/SearchInformation/Components/SearchTypeSelection';
import CurrencySelection from '../../../components/CurrencySelection';
import CountrySelection from '../../../components/CountrySelection';
import JobFunctionSelection from '../../../components/jobFunctionSelection';
import { KeyboardDatePicker } from '@material-ui/pickers';

import IndustrySelection from '../../../components/IndustrySelection';
import { POST, SUCCESS, ERROR } from '../../../services/constantService';
import { formatDate } from '../../../utils/date';
import { customFormValidator, requireValidMessage } from '../../../utils/common';
import { cleanupCloneData, cleanupSearchData } from '../../../components/CreateSearch/utils';
import { searchDataApi } from '../../../services/ApiService';
import { successMessage, unableMessage } from '../../../services/MessageService';

const SearchCloning = props => {
  const { open, handelClose, isFromViewSearch = false, searchData } = props;

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: { ...searchData, job_title: `${searchData.job_title} Clone` }
  });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const country = watch('country');
  useEffect(() => {
    register('stage');
    register('stage_desc');
  }, [register]);
  const SearchSchema = yup.object().shape({
    company: yup.object().required(),
    job_title: yup.string().required(),
    stage: yup.string().required(),
    type: yup.string().required(),
    industries: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string()
        })
      )
      .required(),
    job_functions: yup.array().required(),
    country: yup.string().required(),
    currency: yup.string().required()
  });

  const handelCreate = async formData => {
    const isValid = await SearchSchema.isValid(formData);
    if (!isValid) {
      let requiredField = [
        { fieldName: 'company', label: 'Company Name', type: Object },
        { fieldName: 'job_title', label: 'Search Job Title', type: String },
        { fieldName: 'stage', label: 'Stage', type: String },
        { fieldName: 'type', label: 'Search Type', type: String },
        { fieldName: 'industries', label: 'Industry', type: [] },
        { fieldName: 'job_functions', label: 'Job Function', type: [] },
        { fieldName: 'country', label: 'Location', type: String },
        { fieldName: 'currency', label: 'Currency Code', type: String }
      ];
      let dirtyField = customFormValidator(formData, requiredField);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    const payload = cleanupSearchData({ ...searchData, ...formData }, 'clone');
    const datas = cleanupCloneData(payload);
    setLoading(true);
    const { status, data: response } = await searchDataApi(POST, '', datas);
    if (status === 201) {
      const message = successMessage('Clone Search', 'created');
      enqueueSnackbar(message, { variant: SUCCESS });
      handelClose();
    } else {
      const message = unableMessage('search', 'clone');
      enqueueSnackbar(response?.message || message, { variant: ERROR });
    }
    setLoading(false);
  };

  const handleChange = (name, value) => {
    setValue(name, formatDate(value));
  };
  return (
    <Popup open={open} className={'live-search-clone'} onClose={handelClose}>
      <React.Fragment>
        <Loader show={loading} />
        <div className='cloning-search-data h-100 overflow-auto'>
          <div className='quick-add-header' style={{ background: '#2a364c', color: '#fff' }}>
            <Typography className='ml-4'>Clone Search</Typography>
            <CloseIcon className='cursor-pointer' onClick={handelClose} />
          </div>
          <div className='search-clone-container'>
            <div className='d-flex flex-column h-100'>
              <div className='search-clone-content all-popup-content-height'>
                <div className='d-flex flex-grow-1'>
                  <CompanySelection
                    className='input-form-field input-field-old'
                    defaultValue={watch('company') || null}
                    {...register('company')}
                    includeVcpe={true}
                    required={true}
                    placeholder={'Search Company'}
                    InputLabelProps={{ focused: true }}
                    label='Company Name'
                    onChange={(e, data) => {
                      setValue('company', data);
                      if (!isFromViewSearch && Array.isArray(data?.vc_pe)) {
                        const value = data.vc_pe.map(value => ({ id: value.vcpe_id, name: value.company?.name }));
                        setValue('vc_pe', value);
                      }
                    }}
                  />
                  <InputField
                    {...register('job_title')}
                    required={true}
                    value={watch('job_title') || ''}
                    name='job_title'
                    label='Search Job Title'
                    placeholder='Search Job Title'
                    className='input-form-field input-field-old'
                    InputLabelProps={{ focused: true }}
                  />
                </div>
                <div className='d-flex flex-grow-1'>
                  <SearchStageSelection
                    className='input-form-field input-field-old'
                    {...register('stage')}
                    value={watch('stage_desc') || null}
                    //   disabled={!isEditing}
                    required={true}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => setValue('stage', data ? data.field_value : null, setValue('stage_desc', data ? data.short_desc : null))}
                  />
                  <SearchTypeSelection
                    className='input-form-field input-field-old'
                    {...register('type')}
                    value={watch('type') || null}
                    required={true}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('type', data ? data.name : null);
                    }}
                  />
                </div>
                <div className='d-flex flex-grow-1 w-100'>
                  <IndustrySelection
                    multiple
                    value={watch('industries') || []}
                    {...register('industries')}
                    className='input-form-field input-field-old'
                    label={'Industry'}
                    required={true}
                    size='small'
                    filterSelectedOptions={true}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('industries', Array.isArray(data) ? data : []);
                    }}
                  />
                </div>
                <div className='d-flex flex-grow-1'>
                  <KeyboardDatePicker
                    className='input-form-field input-field-old'
                    {...register('loe_date')}
                    format='MM/dd/yyyy'
                    autoOk
                    InputLabelProps={{ focused: true }}
                    label={'LOE Date'}
                    placeholder={'LOE Date'}
                    value={formatDate(watch('loe_date')) || null}
                    onChange={date => handleChange('loe_date', date)}
                  />

                  <JobFunctionSelection
                    multiple
                    value={watch('job_functions') || []}
                    {...register('job_functions')}
                    className='input-form-field input-field-old'
                    label={'Job Function'}
                    required={true}
                    size='small'
                    filterSelectedOptions={true}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('job_functions', data ? data.map(item => (typeof item === 'string' ? item : item.name)) : []);
                    }}
                  />
                </div>
                <div className='d-flex flex-grow-1'>
                  <CountrySelection
                    //value={watch('country') || null}
                    {...register('country')}
                    required={true}
                    defaultValue={country}
                    className='input-form-field input-field-old'
                    InputLabelProps={{ focused: true }}
                    label='Location'
                    onChange={(e, data) => {
                      setValue('country', data?.name);
                    }}
                  />
                  <CurrencySelection
                    customInput={TextField}
                    {...register('currency')}
                    required={true}
                    value={watch('currency') || null}
                    className='input-form-field remove-right-padding input-field-old'
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('currency', data ? data.code : null);
                    }}
                  />
                </div>
              </div>
              <div className='page-content-footer'>
                <Button variant='text' onClick={handelClose} className='page-btn-cancel'>
                  Cancel
                </Button>
                <Button className='page-btn-save' variant='contained' onClick={handleSubmit(handelCreate)}>
                  Clone Search
                </Button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </Popup>
  );
};
SearchCloning.propTypes = {
  open: PropTypes.bool,
  handelClose: PropTypes.func,
  isFromViewSearch: PropTypes.bool,
  searchData: PropTypes.array
};

export default SearchCloning;
