//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Grid, Button, InputLabel, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { getIcon } from './utils';
import AttributeSelectionV1 from './AttributeSelectionV1';
import RichTextPopup from '../../../../../RichTextPopup';
export default function AttributeV1(props) {
  const { position, attribute: attributeInfo, updateAttribute = () => {}, readOnly = false, selectedOptions = [], attributeIndex, updatedDate } = props;

  const [attribute, setAttribute] = useState(attributeInfo);
  const [flag, setFlag] = useState(false);

  const currentQuestion = attribute && Object.keys(attribute)?.length > 0 && (flag || attribute.questions?.trim()?.length > 0) ? [attribute.questions] : [];

  useEffect(() => {
    setAttribute(attributeInfo);
  }, [attributeInfo]);

  const addQuestionElement = () => {
    attribute.questions = '';
    setFlag(true);
    updateAttribute(attributeIndex, attribute);
    setAttribute(attribute);
  };

  const removeQuestionElement = () => {
    attribute.questions = '';
    setFlag(false);
    updateAttribute(attributeIndex, attribute);
    setAttribute(attribute);
  };

  const renderAttributeDefinition = definition => {
    if (!definition) return '';

    const definitionList = definition.split(';');

    return (
      <ul className='attribute-defination'>
        {definitionList.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  };

  const renderAddQuestionButton = currentQuestion => {
    if (currentQuestion?.length > 0) {
      return;
    }
    return (
      <Button variant={'text'} startIcon={<AddCircleIcon />} color='primary' style={{ justifyContent: 'flex-start' }} onClick={addQuestionElement}>
        Add Question
      </Button>
    );
  };

  const renderAttributeQuestions = (definition, readOnly) => {
    if (!definition) return '';

    return (
      <div className='clients-list d-flex flex-column attributeQuestions'>
        <DndProvider backend={HTML5Backend}>
          {currentQuestion?.length > 0 && (
            <Grid container item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid item xs={12}>
                <Typography color={'primary'} style={{ fontSize: '12px' }}>
                  Question
                </Typography>
              </Grid>
            </Grid>
          )}
          {currentQuestion &&
            currentQuestion.map((item, index) => (
              <Grid className='d-flex' key={index} item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className='container'>
                  <div className='row'>
                    <div className='col-11'>
                      <div className='outlined-border transform p-0'>
                        <RichTextPopup
                          // className="input-form-field"
                          // ref={register('bio')}
                          setOpen={readOnly ? false : true}
                          onChange={data => {
                            attribute.questions = data;
                            updateAttribute(attributeIndex, attribute, true);
                            setAttribute(attribute);
                          }}
                          key={index}
                          item={item}
                          index={index}
                          placeholder={''}
                          title='Question'
                          name='questions'
                          value={item || ''}
                          StrengthsAndPotentialIssues={true}
                          expandToContent={true}
                          saveStatus={true}
                          InputLabelProps={{ focused: true }}
                          updatedDate={updatedDate}
                        />
                      </div>
                    </div>
                    <span>
                      <DeleteIcon onClick={() => removeQuestionElement()} className='cursor-pointer' />
                    </span>
                  </div>
                </div>
              </Grid>
            ))}
          {renderAddQuestionButton(currentQuestion)}
        </DndProvider>
      </div>
    );
  };

  return (
    <div className={`attribute d-flex ${readOnly ? 'read-only' : ''}`}>
      <div className='attribute-image'>{attribute?.name && getIcon(attribute?.name)}</div>
      <div className='d-flex flex-column flex-grow-1'>
        {!readOnly ? (
          <AttributeSelectionV1
            InputLabelProps={{ focused: true }}
            position={position}
            selectedOptions={selectedOptions}
            //questionHandler={questionHandler}
            value={attribute?.name || null}
            label={`Attribute ${position !== null ? position + 1 : ''}`}
            onChange={(e, data) => {
              updateAttribute(position, data);
              setAttribute(data);
            }}
          />
        ) : (
          <div className='d-flex flex-column'>
            <InputLabel focused color='primary' style={{ fontSize: 12 }} className='m-0'>
              Attribute {position + 1}
            </InputLabel>
            <span style={{ fontSize: 14 }}>{attribute.name}</span>
          </div>
        )}

        <div className='row mt-3 attributeDefinitioneQuestions'>
          <div className='clients-list pr-5 d-flex flex-column'>{renderAttributeDefinition(attribute?.definition)}</div>
          {renderAttributeQuestions(attribute?.definition, readOnly)}
        </div>
      </div>
    </div>
  );
}

AttributeV1.propTypes = {
  position: PropTypes.number,
  attribute: PropTypes.object,
  updateAttribute: PropTypes.func,
  readOnly: PropTypes.bool,
  selectedOptions: PropTypes.array,
  attributeIndex: PropTypes.number,
  updatedDate: PropTypes.string
};
