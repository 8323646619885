import { Box } from '@mui/material';
import isArray from 'lodash/isArray';
import React from 'react';

import { useTranslation } from 'react-i18next';
import Loader from '../../../components/common/Loader';
import { CandidateStageSetupAPi } from '../../../services/ApiService';
import useApi from '../../../utils/Hooks/useApiHook';
import StageTable from './Components/StageTable';
import './index.scss';

const StageSetup = () => {
  const { t } = useTranslation();
  const { data, loading } = useApi({
    queryKey: 'getStageSetup',
    queryFn: async () => {
      return await CandidateStageSetupAPi('get', '/all');
    }
  });
  return (
    <div
      style={{
        height: '80vh'
      }}
    >
      <Box sx={{ height: '95%' }}>
        {loading ? (
          <Loader loadingMessage={t('stageSetup.stageFetching')} show={loading} className='loader-override' />
        ) : data && isArray(data.data) ? (
          <StageTable stages={data.data} />
        ) : (
          <Box mt={4}>
            <h5>{t('common.somethingWentWrong')}</h5>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default StageSetup;
