import * as React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import propTypes from 'prop-types';

function MultiSelect(props) {
  const { value, setValue, listOfOption, sx } = props;
  return (
    <Stack spacing={3} sx={{ width: 500 }}>
      <Autocomplete
        multiple
        id='tags-standard'
        options={listOfOption ? listOfOption : []}
        getOptionLabel={option => option.title}
        renderInput={params => <TextField {...params} variant='standard' label='Select Reason' placeholder='Select Reason' />}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={sx}
      />
    </Stack>
  );
}
MultiSelect.propTypes = {
  value: propTypes.array,
  setValue: propTypes.func,
  listOfOption: propTypes.array,
  sx: propTypes.object,
  default: propTypes.array
};
export default MultiSelect;
