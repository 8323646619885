import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Skeleton, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { globalSearchSelector } from '../../../selectors';
import { updateGlobalSearchData } from '../../../store/globalSearchSlice';
import { getLocalSearchResults, getSearchResults } from '../helper/utils';
import CustomDateFilter from './CustomDateFilter';
import { CustomMinMaxFilter } from './CustomMinMaxFilter';
import { FilterItem } from './FilterItem';

export const FilterBox = ({ onClose = () => {}, show = false }) => {
  if (!show) return null;

  const { selectedTabId } = useParams();

  const { selectedSubTabId, filterModel, gridApi, selectedMoreFilterTabId, subTabs, filters, data: globalSearchData } = useSelector(globalSearchSelector);
  const commonSelector = useSelector(state => state.commonReducer);
  const selectedTabData = globalSearchData?.find(data => data?.model === selectedTabId);
  let tab = subTabs.find(tab => tab?.id === selectedSubTabId);

  if (tab?.type === 'COMPANY_SIZE') {
    return <CustomMinMaxFilter show={show} onClose={onClose} />;
  }

  if (tab?.type === 'DATE') {
    return <CustomDateFilter show={show} onClose={onClose} />;
  }

  if (selectedSubTabId === 'more_filters') {
    tab = subTabs.find(tab => tab?.id === selectedMoreFilterTabId);
  }

  const [search, setSearch] = useState('');

  // Show loader when searching
  const [loading, setLoading] = useState(false);
  const timerRef = useRef(null);
  const [data, setData] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  // Show loader when fetching the data
  const [isDataLoading, setIsDataLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function fetchSearchResults(delay, loader, customSearch = null) {
    let searchValue = customSearch;
    if (searchValue === null) {
      searchValue = search;
    }
    timerRef.current = setTimeout(async () => {
      loader(true);
      let data;
      if (tab?.search_type === 'local') {
        data = getLocalSearchResults(tab, searchValue, selectedTabData, dispatch, selectedTabId);
      } else {
        data = await getSearchResults(tab, searchValue, selectedTabId, commonSelector, dispatch);
      }
      setData(data);
      loader(false);
    }, delay);
  }

  const initialFetchCondition =
    ['PROJECT_STAGE', 'CONTACT_TAGS', 'COMPANY_TAGS', 'PROJECT_TAGS', 'CONTACT_SOURCE', 'COMPANY_CAPITAL_STRUCTURE', 'COMPANY_RECORD_STATUS'].includes(tab?.type) || tab?.search_type === 'local';

  useEffect(() => {
    if (search.length < 2) {
      if (initialFetchCondition) {
        fetchSearchResults(0, setIsDataLoading, '');
        return;
      }
      setData(selectedValues);
      return;
    }

    fetchSearchResults(500, setLoading);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [search]);

  useEffect(() => {
    const appliedValue = filters[tab?.search_field?.[0]];
    setData(appliedValue?.values);
    setSelectedValues(appliedValue?.values || []);
  }, [tab?.type]);

  const handleSave = () => {
    const values = tab?.search_field?.reduce((acc, curr) => ({ ...acc, [curr]: { values: selectedValues, isLocal: tab?.search_type === 'local' } }), {});
    const updatedFilters = { ...filters, ...values };
    Object.keys(updatedFilters).forEach(key => {
      if (updatedFilters?.[key]?.values?.length === 0) delete updatedFilters[key];
    });
    dispatch(updateGlobalSearchData({ key: 'filters', data: updatedFilters }));
    setSearch('');
    setData(selectedValues);
    onClose();

    const updatedFilterModel = {
      ...filterModel,
      subTabsFilter: { filterType: 'text', filter: JSON.stringify({ filters: updatedFilters, isLocal: tab?.search_type === 'local', fields: tab?.search_field, globalSearchData }) }
    };
    dispatch(updateGlobalSearchData({ key: 'filterModel', data: updatedFilterModel }));
    const appliedFilters = gridApi?.current?.getFilterModel();
    if (appliedFilters?.sortFilter?.filter) {
      const newSortFilter = JSON.parse(appliedFilters?.sortFilter?.filter);
      newSortFilter.type = 'noChange';
      gridApi?.current?.setFilterModel({
        ...appliedFilters,
        ...updatedFilterModel,
        sortFilter: { ...appliedFilters.sortFilter, filter: JSON.stringify(newSortFilter) }
      });
    } else {
      gridApi?.current?.setFilterModel({
        ...appliedFilters,
        ...updatedFilterModel
      });
    }
  };

  const handleOnCancel = () => {
    setSearch('');
    const appliedValue = filters[tab?.search_field?.[0]];
    setData(appliedValue?.values);
    setSelectedValues(appliedValue?.values);
    onClose();
  };

  const handleOnChange = (e, checked, item) => {
    if (checked) {
      setSelectedValues(prev => [...prev, item]);
    } else {
      setSelectedValues(prev => prev.filter(i => i?.id !== item?.id));
    }
  };

  return !show ? (
    <></>
  ) : (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          top: '2rem',
          position: 'absolute',
          width: '350px',
          height: '300px',
          background: 'white',
          zIndex: 5,
          boxShadow: 1
        }}
      >
        <Box sx={{ textAlign: 'right', paddingX: 2, marginY: 0.5 }}>
          <CloseIcon sx={{ color: '#959fb7', cursor: 'pointer', fontSize: '1.2rem' }} onClick={handleOnCancel} />
        </Box>

        <Box paddingX={2}>
          <TextField
            variant='outlined'
            id='outlined-basic'
            placeholder={`Search ${tab?.label}`}
            size='small'
            sx={{
              width: '100%',
              '& .MuiInputBase-input': {
                fontWeight: '500',
                color: '#959fb7'
              }
            }}
            InputProps={{
              endAdornment: <React.Fragment>{loading ? <CircularProgress color='primary' size={20} /> : null}</React.Fragment>
            }}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </Box>

        <Box overflow={'scroll'} paddingX={2} display={'flex'} flex={1} gap={1} marginY={2} flexDirection={'column'}>
          {isDataLoading &&
            selectedValues?.map(item => {
              return <FilterItem disabled key={item?.id} checked={selectedValues?.some(val => val?.id === item?.id)} label={item?.value} onChange={(e, checked) => handleOnChange(e, checked, item)} />;
            })}

          {!loading &&
            !isDataLoading &&
            data?.map(item => {
              return <FilterItem key={item?.id} checked={selectedValues?.some(val => val?.id === item?.id)} label={item?.value} onChange={(e, checked) => handleOnChange(e, checked, item)} />;
            })}

          {(isDataLoading || loading) && Array.from({ length: 4 }).map(key => <Skeleton key={key} variant='text' width={'100%'} height={'30px'} />)}
        </Box>

        <Box display={'flex'} padding={1} borderTop={'1px solid #959fb7'} justifyContent={'flex-end'} gap={1}>
          <Button variant='text' onClick={handleOnCancel}>
            <Typography fontSize={12} fontWeight={600} color='#c3423f'>
              Cancel
            </Typography>
          </Button>
          <Button variant='contained' onClick={handleSave}>
            <Typography fontSize={12} fontWeight={600}>
              Save
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 2
        }}
        onClick={handleOnCancel}
      ></Box>
    </>
  );
};

FilterBox.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool
};
