import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import Loader from '../../../components/common/Loader';
import { reportsTemplateDataApi } from '../../../services/ApiService';
import { DELETE, ERROR, GET, POST, SUCCESS } from '../../../services/constantService';
import ReportTemplateList from './ReportTemplateList';

const ReportTemplateSetup = () => {
  const [allReports, setAllReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchAllReports = async () => {
    try {
      setIsLoading(true);
      const sub_route = 'get-all';
      let { status, data } = await reportsTemplateDataApi(GET, sub_route);
      if (status && status === 200) {
        setAllReports(data);
        setIsLoading(false);
      } else {
        enqueueSnackbar(data.message, { variant: ERROR });
        setIsLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: ERROR });
    }
  };
  const uploadReportTemplateSetup = async (file, reportCode) => {
    try {
      setIsLoading(true);
      // Create a FormData object
      const formData = new FormData();
      formData.append('report_code', reportCode); // Add other fields
      formData.append('report', file); // Add the file
      const sub_route = 'upload';
      const options = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      let { status, data } = await reportsTemplateDataApi(POST, sub_route, formData, options);
      if (status && status === 200) {
        enqueueSnackbar(data?.message ? data.message : 'Successfully uploaded Report Template', { variant: SUCCESS });
        setIsLoading(false);
      } else {
        enqueueSnackbar(data.message, { variant: ERROR });
        setIsLoading(false);
      }
      fetchAllReports();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: ERROR });
    }
  };
  const removeReportTemplateSetup = async reportCode => {
    try {
      setIsLoading(true);
      const sub_route = 'delete-report';
      const reportId = allReports.find(item => item.report_code == reportCode)?.id;
      let { status, data } = await reportsTemplateDataApi(DELETE, sub_route, { reportId: reportId });
      if (status && status === 200) {
        enqueueSnackbar(data?.message ? data.message : 'Successfully deleted Report Template', { variant: SUCCESS });
        setIsLoading(false);
      } else {
        enqueueSnackbar(data.message, { variant: ERROR });
        setIsLoading(false);
      }
      fetchAllReports();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: ERROR });
    }
  };
  useEffect(() => {
    fetchAllReports();
  }, []);
  return (
    <React.Fragment>
      <Loader show={isLoading} />
      <Box
        sx={{
          height: '85%',
          mr: 3,
          mt: 3,
          position: 'relative'
        }}
      >
        <ReportTemplateList data={allReports} uploadReportTemplateSetup={uploadReportTemplateSetup} removeReportTemplateSetup={removeReportTemplateSetup} />
      </Box>
    </React.Fragment>
  );
};

export default ReportTemplateSetup;
