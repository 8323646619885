import { DELETE, GET, IGN_API, PATCH, POST, PUT } from '../constantService';
import httpService from '../httpService';

export const ignDirectOffLimitDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.directOffLimit}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.directOffLimit}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.directOffLimit}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.directOffLimit}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.directOffLimit}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.directOffLimit}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = id?.length > 0 ? await httpService.patch(`${IGN_API.directOffLimit}/${id}/${sub_route}`, body) : await httpService.patch(`${IGN_API.directOffLimit}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.directOffLimit}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.directOffLimit}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.directOffLimit}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.directOffLimit}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error handling Direct Off-Limit API:', e);
  }
};

export const ignCompanyOffLimitDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.companyOffLimit}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.companyOffLimit}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.companyOffLimit}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.companyOffLimit}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.companyOffLimit}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.companyOffLimit}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = id?.length > 0 ? await httpService.patch(`${IGN_API.companyOffLimit}/${id}/${sub_route}`, body) : await httpService.patch(`${IGN_API.companyOffLimit}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.companyOffLimit}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.companyOffLimit}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.companyOffLimit}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.companyOffLimit}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error handling Company Off-Limit API:', e);
  }
};

export const ignOffLimitSummaryDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.offLimitSummary}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.offLimitSummary}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.offLimitSummary}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.offLimitSummary}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.offLimitSummary}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.offLimitSummary}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = id?.length > 0 ? await httpService.patch(`${IGN_API.offLimitSummary}/${id}/${sub_route}`, body) : await httpService.patch(`${IGN_API.offLimitSummary}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.offLimitSummary}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.offLimitSummary}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.offLimitSummary}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.offLimitSummary}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error handling Off-Limit Summary API:', e);
  }
};

export const ignOffLimitConfigDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.offLimitConfig}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.offLimitConfig}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = id?.length > 0 ? await httpService.put(`${IGN_API.offLimitConfig}/${id}/${sub_route}`, body) : await httpService.put(`${IGN_API.offLimitConfig}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result = id?.length > 0 ? await httpService.delete(`${IGN_API.offLimitConfig}/${id}/${sub_route}`, body) : await httpService.delete(`${IGN_API.offLimitConfig}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = id?.length > 0 ? await httpService.patch(`${IGN_API.offLimitConfig}/${id}/${sub_route}`, body) : await httpService.patch(`${IGN_API.offLimitConfig}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await httpService.post(`${IGN_API.offLimitConfig}/${id}/${sub_route}`, body, responseType)
            : await httpService.post(`${IGN_API.offLimitConfig}/${sub_route}`, body, responseType)
          : id?.length > 0
            ? await httpService.post(`${IGN_API.offLimitConfig}/${id}/${sub_route}`, body)
            : await httpService.post(`${IGN_API.offLimitConfig}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log('Error handling Off-Limit Config API:', e);
  }
};
