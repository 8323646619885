import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './index.scss';
import { CONFIRMATION_POPUP_MESSAGE } from '../../../services/constantService';

const CustomConfirmationPopup = props => {
  const {
    open,
    setOpen,
    onClose,
    onConfirm,
    type = '',
    confirmText = 'Yes',
    cancelText = 'No',
    disabledCancel = false,
    deletingItem = '',
    deletingItemName = '',
    isEmailMessage = false,
    customMessage = ''
  } = props;

  const handleConfirm = () => {
    setOpen(false);
    onConfirm && onConfirm();
  };

  return (
    <Dialog id='conformation-popup' open={open} onClose={onClose}>
      <Box className='d-flex justify-content-between align-items-center' px={3} py={2}>
        <DialogTitle className='confirmation-title'>Confirm {type}</DialogTitle>
        <CloseIcon onClick={onClose} className='confirmation-close-icon' />
      </Box>
      <DialogContent className='dialog-content'>
        {customMessage ? (
          <DialogContentText className='dialog-content-text'>{customMessage}</DialogContentText>
        ) : isEmailMessage ? (
          <DialogContentText className='dialog-content-text'>{CONFIRMATION_POPUP_MESSAGE.DELETE_PRIMARY_EMAIL}</DialogContentText>
        ) : (
          <DialogContentText className='dialog-content-text'>
            Are you sure you want to {type} this {deletingItem} {deletingItemName || 'item'} ?
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions className='dialog-actions px-4'>
        {!disabledCancel && (
          <Button variant='text' className='cancel-button confirmation-button' onClick={onClose}>
            {cancelText}
          </Button>
        )}
        <Button className='delete-button confirmation-button' variant='contained' onClick={handleConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CustomConfirmationPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  type: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  disabledCancel: PropTypes.bool,
  setOpen: PropTypes.func,
  onConfirm: PropTypes.func,
  deletingItem: PropTypes.string,
  deletingItemName: PropTypes.string,
  isEmailMessage: PropTypes.bool,
  customMessage: PropTypes.string
};

export default CustomConfirmationPopup;
