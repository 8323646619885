import { Typography } from '@mui/material';
import React from 'react';
import CustomButton from '../../components/common/CustomButton';
import { userLogout } from '../../components/Header/UserMenu';

const Unauthorized = () => {
  return (
    <div className='d-flex flex-column gap-2 justify-content-center align-items-center h-100'>
      <Typography className='error-title mb-2'>Incorrect Authorization, Please login again</Typography>
      <CustomButton variant='contained' color='primary' size='medium' buttonText='Log-out' onClick={userLogout} />
    </div>
  );
};

export default Unauthorized;
