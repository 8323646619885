import * as React from 'react';
import '../../../../Containers/Commons/AutoCompleteStyle/index.scss';
import CustomDropdown from '../../CustomDropdown';
import { useState } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { fetchPickLists } from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';

export default function BonusTypeSelection(props) {
  const {
    value,
    name = '',
    onChange = () => {},
    disabled = false,
    isDrawer = false,
    multiple = false,
    label,
    disableCloseOnSelect = false,
    className = '',
    isCheckBox = false,
    selectedList,
    setBonusList = () => {}
  } = props;
  const dispatch = useDispatch();
  const bonusType = useSelector(state => state.commonReducer.bonusType);
  const [options, setOptions] = useState([]);

  // const data = [
  //   { label: "Actual", name: "Actual" },
  //   { label: "Target", name: "Target" },
  // ];

  useEffect(() => {
    if (!bonusType) {
      dispatch(fetchPickLists('BONUS_TYPE', 'bonusType'));
    } else {
      if (bonusType) {
        setBonusList(bonusType);
        const filterData = bonusType?.filter(item => selectedList?.every(listItem => (listItem?.bonus?.field_value ? listItem?.bonus?.field_value : listItem?.bonus) !== item?.short_desc));
        setOptions(filterData);
      } else {
        setOptions([]);
      }
    }
  }, [bonusType, dispatch, selectedList]);

  return (
    <CustomDropdown
      options={options}
      value={value}
      name={name}
      onChange={onChange}
      disabled={disabled}
      isDrawer={isDrawer}
      multiple={multiple}
      label={label}
      disableCloseOnSelect={disableCloseOnSelect}
      className={className}
      isCheckBox={isCheckBox}
    />
  );
}
BonusTypeSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isDrawer: PropTypes.bool,
  multiple: PropTypes.bool,
  isCheckBox: PropTypes.bool,
  className: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool,
  selectedList: PropTypes.array,
  setBonusList: PropTypes.array
};
