//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useRef, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { CircularProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import PropTypes from 'prop-types';
// import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { getMethodWithCancelTokenApi } from '../../services/ApiService';
import { IGN_API } from '../../services/constantService';
import { SEARCH_TIMEOUT } from '../../utils/common';
import './index.scss';

const CancelToken = axios.CancelToken;

const Search = props => {
  const { placeholder = 'Search', isAddContactToProject = false, type, customWidth = null, customClassName = '', ...rest } = props;
  // const {setListShow}=props;
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  let call = useRef();

  useEffect(() => {
    // if (value?.length === 0) setListShow(false);
    // if (value?.length !== 0) setListShow(true);
    if (call.current) {
      call.current.cancel();
    }
    if (value?.length < 3) {
      setOptions([]);
      setLoading(false);
      return;
    }
    // if (value?.length > 2) { setListShow(true) }
    call.current = CancelToken.source();
    setLoading(true);
    let url = `${IGN_API.picklists}/${type}?name=${value}`;
    if (type === 'users') {
      url = `${IGN_API.user_search}?limit=100&name=${value}`;
    }

    const timer = setTimeout(() => {
      getMethodWithCancelTokenApi(url, {}, {}, call.current.token).then(response => {
        const { status, data } = response;
        if (status === 200) {
          setOptions(JSON.parse(data)?.data);
        }
        setLoading(false);
      });
    }, SEARCH_TIMEOUT);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <>
      <div id='contact-search' className={`${!isAddContactToProject ? 'px-4 add-search' : 'add-contact-to-project pl-2'}`}>
        <Autocomplete
          {...rest}
          className={`grid-search ${customClassName}`}
          freeSolo
          filterOptions={options => options}
          loading={isLoading}
          options={options}
          getOptionLabel={option => {
            if (typeof option === 'string') {
              return option;
            } else if (type === 'contacts') {
              return option.first_name + ' ' + option.last_name;
            } else if (type === 'companies') {
              return option.name;
            }
          }}
          renderOption={(props, option) => {
            const jobTitle = option.current_job_title || '';
            const companyName = option.company?.name || '';
            return (
              <li {...props}>
                {type === 'contacts' && (
                  <div>
                    <Typography>{option.first_name + ' ' + option.last_name}</Typography>
                    <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>{'(' + jobTitle + ' - ' + companyName + ')'}</Typography>
                  </div>
                )}
                {type === 'companies' && (
                  <div>
                    <Typography>{option.name}</Typography>
                  </div>
                )}
              </li>
            );
          }}
          onInputChange={(e, val) => setValue(val)}
          value={props.value ? props.value : ''}
          renderInput={params => (
            <TextField
              {...params}
              // onChange={() => { setListShow(true) }}
              // onFocus={() => {setListShow(true) }}
              // onBlur={() => { if(!value)setListShow(false) }}
              variant='standard'
              className={`${!isAddContactToProject && 'search p-1'}`}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                endAdornment: isLoading ? <CircularProgress className='loader-class' size={20} /> : params.InputProps.endAdornment
              }}
              style={customWidth ? { width: customWidth ? customWidth : 'auto' } : {}}
            />
          )}
        />
        <span
          onClick={event => {
            props.onChange(event, value);
          }}
          className={'search-icon p-0'}
        >
          <SearchIcon />
        </span>
      </div>
    </>
  );
};

Search.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  // setListShow: PropTypes.func
  isAddContactToProject: PropTypes.bool,
  customWidth: PropTypes.number,
  customClassName: PropTypes.string
};

export default Search;
