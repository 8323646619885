//----------------------------------------------// In-built Imports // -------------------------------------------------

import React, { useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------

import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------

import CandidateStageSelection from '../../CandidateStageSelection';
import { qualifiedStages } from '../utils';

function ActivityStageSelection(props) {
  const { watch, setValue } = props;

  const [activityReasonOptions, setActivityReasonOptions] = useState([]);

  return (
    <React.Fragment>
      <CandidateStageSelection
        className='input-form-field input-field-old'
        label='Stage'
        required={true}
        value={watch('stage') || null}
        placeholder='Select'
        InputLabelProps={{ focused: true }}
        setActivityReasonOptions={setActivityReasonOptions}
        onChange={(e, data) => {
          setValue('stage', data ? data.name : null);
          setValue('reason', '');
          setActivityReasonOptions(data ? data.reason : []);
        }}
        watch={watch}
        hideP1RejectedStage={true}
      />
      <Autocomplete
        className='input-form-field input-field-old'
        value={watch('reason') || null}
        onChange={(e, data) => setValue('reason', data)}
        options={activityReasonOptions ? [...activityReasonOptions].sort() : []}
        renderInput={params => <TextField {...params} required={qualifiedStages.includes(watch('stage'))} InputLabelProps={{ focused: true }} label='Reason' placeholder='' />}
      />
    </React.Fragment>
  );
}

ActivityStageSelection.propTypes = {
  watch: PropTypes.func,
  setValue: PropTypes.func
};

export default ActivityStageSelection;
