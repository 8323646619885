import React, { useState } from 'react';
import { Box, DialogContent, Grid, IconButton, Typography } from '@mui/material';
import delete_icon from '../../../assets/icons/delete_icon.svg';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import CustomConfirmationPopup from '../CustomConfirmationPopup';
import CustomPopup from '../CustomPopup';
import './index.scss';

const ViewMorePopup = props => {
  const { data, setIsViewMore, isViewMore, addIcon = false, editItems } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);

  const handleClose = () => {
    setIsViewMore(false);
    setIsEditing(true);
  };
  // const handleBack = () => {
  //   setIsViewMore(false)
  // }
  const editItem = () => {
    // setIsEditing(true)
    editItems();
  };

  const deleteItem = () => {
    setOpenConfirmationPopup(true);
  };
  const closeConfirmationPopup = () => {
    setOpenConfirmationPopup(false);
  };
  const editIcon = (
    <Box id='view-more-title'>
      <Grid item xs={4}>
        {' '}
        {/* Another column for the delete icon */}
        <Box className='d-flex fit-content'>
          {!isEditing && (
            <>
              {addIcon && (
                <IconButton className='security-icon pr-2 m'>
                  <img src={addIcon} alt='' />
                </IconButton>
              )}
              <IconButton className='green-bg edit-icon mr-1 mb-0'>
                <EditIcon onClick={editItem} />
              </IconButton>
              <Box className='delete-icon m-2'>
                <img src={delete_icon} alt='' onClick={deleteItem}></img>
              </Box>
            </>
          )}
        </Box>
      </Grid>
    </Box>
  );

  const icons = [editIcon];

  return (
    <Box>
      <CustomPopup onClose={handleClose} aria-labelledby='customized-dialog-title' open={isViewMore} title={data.type} icons={icons}>
        <DialogContent className='content-value'>
          <Typography variant='body2' className='card-containt view-text'>
            {data.text}
          </Typography>
        </DialogContent>
      </CustomPopup>
      <CustomConfirmationPopup open={openConfirmationPopup} onClose={closeConfirmationPopup} type='delete' cancelText='No' confirmText='Yes' setOpen={setOpenConfirmationPopup} />
    </Box>
  );
};
ViewMorePopup.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isViewMore: PropTypes.bool,
  setIsViewMore: PropTypes.func,
  types: PropTypes.string,
  addIcon: PropTypes.string,
  itemValue: PropTypes.array,
  onSubmit: PropTypes.func,
  isTypes: PropTypes.bool,
  editItems: PropTypes.func
};
export default ViewMorePopup;
