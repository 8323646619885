import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Box, Divider, Tooltip } from '@mui/material';
import variables from '../../../../variables.scss';
import '../../../CreateBD/TeamInfo/index.scss';

//----------------------------------------------// Internal Imports // -------------------------------------------------

const TotalRevenue = props => {
  /* getIndirectFees is used when calculate for estimated and actual fee calculation */
  const { /* watch, */ getTotalPercentageOfBase, getIndirectFees, getGrandTotal, type = 'estimated', currentValues } = props;
  const label = type === 'estimated' ? 'Estimated' : 'Actual';

  const displayTooltipContent = title => {
    return (
      <Tooltip title={title}>
        <Box component='span' className='pl-4'>
          &nbsp;&nbsp;{title}
        </Box>
      </Tooltip>
    );
  };
  return (
    <Box id='team-info'>
      <Box className={'d-flex flex-column ign-team-information-table revenue-table mt-2 fs-14'}>
        <Box className={'d-flex table-heading font-weight-bold fs-15 '}>{label} Revenue</Box>
        <Box className=' d-flex flex-column py-1 px-3'>
          <Box className='d-flex p-1'>
            <Box className='col-4 px-0'>Base Fee</Box>
            <Box className='col pr-0 text-truncate'>{displayTooltipContent(getTotalPercentageOfBase(currentValues?.estimated_percentage_bases, `calculated_${type}_amt`, true))} </Box>
            {/* <Box className='col pr-0 text-truncate'>{displayTooltipContent(getTotalPercentageOfBase(currentValues?.estimated_percentage_bases, currentValues?.calculated_estimated_amt, true))} </Box> */}
          </Box>
          <Box className='d-flex p-1'>
            <Box className='col-4 px-0'>Indirect Fee</Box>
            <Box className='col pr-0 text-truncate'>
              {displayTooltipContent(getTotalPercentageOfBase(getIndirectFees ? getIndirectFees(type) : currentValues?.project_indirect_fees, 'calculation_amt', true))}
            </Box>
          </Box>
          <Divider className='divider-hr' sx={{ height: '2vh', borderWidth: 0, backgroundColor: variables.main }} />
          <Box className='d-flex p-1'>
            <Box className='col-4 px-0 text-wrap'>Revenue</Box>
            <Box className='col pr-0 text-truncate'>{displayTooltipContent(getGrandTotal() || '--')}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

TotalRevenue.propTypes = {
  watch: PropTypes.func,
  getTotalPercentageOfBase: PropTypes.func,
  getGrandTotal: PropTypes.func,
  type: PropTypes.string,
  currentValues: PropTypes.object,
  getIndirectFees: PropTypes.func
};

export default TotalRevenue;
