import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../selectors';
import { ignContactDataApi } from '../../services/ApiService';
import { GET } from '../../services/constantService';
import { getProfileDetailsDefaultValues, removeSkipFields } from '../../components/AddContact/utils';

const useContactFormHook = ({ id, path, getPayload, handleSaveTabData, ref, formSchema, isValidated, defaultFormValue, tabName, isEdit, indexValue, setIsPopupOpen, isPopupOpen }) => {
  const { register, reset, handleSubmit, getValues, watch, setValue, formState } = useForm({});
  const [currentValues, setCurrentValues] = useState(null);
  const [tabInfo, setTabInfo] = useState(null);
  const [formDirtyFields, setFormDirtyFields] = useState([]);
  const { dirtyFields, isDirty } = formState;
  const userData = useSelector(getUserSelector);
  const [loading, setLoading] = useState(false);

  const getContactInfo = useCallback(async () => {
    if (id) {
      setLoading(true);
      let sub_route;
      if (tabName) {
        sub_route = `?tabName=${tabName}`;
      }
      const { status, data } = await ignContactDataApi(GET, id, null, sub_route);
      if (status === 200) {
        setTabInfo(data);
      }
    } else {
      if (!tabInfo) {
        setTabInfo(defaultFormValue);
      }
    }
  }, [id, path, enqueueSnackbar, setTabInfo]);

  const resetProfileInfoTab = data => {
    let defaultValues = getProfileDetailsDefaultValues(data);
    if (defaultValues) {
      setCurrentValues(defaultValues);
      reset(defaultValues);
      setLoading(false);
    }
  };

  const handleSave = async () => {
    const formValues = { ...currentValues, ...getValues() };
    let result;
    const isValid = await isValidated(formValues);
    if (isValid) {
      const newDirtyFields = [dirtyFields, ...formDirtyFields];
      const payload = await getPayload(formValues, newDirtyFields, userData, id, currentValues, isEdit, indexValue, isPopupOpen);
      result = await handleSaveTabData(payload, tabName, setIsPopupOpen);
      if (result) {
        setFormDirtyFields([]);
        await getContactInfo();
      }
      if (!result) {
        handleSubmit(null);
      }
      return result;
    }
  };
  useEffect(() => {
    getContactInfo();
  }, [getContactInfo]);

  useEffect(() => {
    resetProfileInfoTab(tabInfo);
  }, [tabInfo, reset, getValues]);

  useEffect(() => {
    if (tabName === 'PROFILE_DETAILS_TAB' && !id) {
      if (currentValues && Object.keys(currentValues).length > 2) {
        reset(currentValues);
      }
    } else {
      reset(currentValues);
    }
  }, [currentValues]);

  useImperativeHandle(ref, () => ({
    saveTabData: () => {
      let fields = removeSkipFields([dirtyFields]);
      const filteredData = fields.filter(obj => Object.keys(obj).length !== 0);
      if (filteredData.length > 0) {
        return handleSave();
      } else {
        return true;
      }
    },
    isDirty: async () => {
      if (formSchema) {
        const formValues = getValues();
        const isValid = await formSchema.isValid(formValues);
        if (!isValid) {
          return false;
        }
      }
      let fields = removeSkipFields([dirtyFields]);
      const filteredData = fields.filter(obj => Object.keys(obj).length !== 0);
      return filteredData.length > 0;
    },
    formData: () => {
      return getValues();
    },
    reload: () => {
      // getValues(hideLoader);
    },
    getContact: () => {
      return tabInfo?.contact;
    }
  }));
  return { getContactInfo, resetProfileInfoTab, handleSave, register, watch, setValue, currentValues, getValues, reset, loading, setLoading, dirtyFields, isDirty };
};

export default useContactFormHook;
