//-----------------------------------------------------------// External Imports // ------------------------------

import QueryString from 'query-string';
import { API } from '../services/constantService';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import httpService from '../services/httpService';

export const querySelector = async (obj, getFilterParamString, getQueryParamsString) => {
  const { params, context, pageLimit, url, subScreen, subString, defaultSort, companiesSubScreen, SearchesSubScreen, contactsScreen, queryString, mySearches, usersScreen, companiesScreen } = obj;

  let queryParams = `?limit=${pageLimit}&page=${params.endRow / pageLimit}`;

  const filterValue = filter(params, context.quickQuery, context.isAdvanceQuery, getFilterParamString, queryParams, context, subScreen);
  filterValue && (queryParams = filterValue);

  const sortValue = sort(params);
  sortValue && (queryParams = `${queryParams}${sortValue}`);

  const ids = context.location && context.location.search && QueryString.parse(context.location.search)?.id;
  ids && (queryParams = `${queryParams}&${Array.isArray(ids) ? ids.map(id => `id=${id}`).join('&') : `id=${ids}`}`);

  context.searchStatus && context.isClassicQuery && !context.quickQuery && (queryParams = `${queryParams}&searchStatus=${context.searchStatus}`);
  queryString && !params.sortModel?.length && defaultSort ? (queryParams = `${queryParams}${queryString}`) : queryString && !defaultSort && (queryParams = `${queryParams}${queryString}`);

  let formURL = Object.keys(params.filterModel)?.length && mySearches ? `${url.quickQuery}${queryParams}` : `${url.listURl}${queryParams}`;

  if (context.isClassicQuery) {
    queryParams = `${queryParams}${getQueryParamsString(context.queryData)}`;
    formURL = `${url.classicURl}/query${queryParams}`;
  } else if (context.isAdvanceQuery) {
    formURL = `${url.advanceURL}${queryParams}`;
  } else if (context.quickQuery && typeof context.quickQuery === 'string') {
    const value = encodeURIComponent(context.quickQuery);
    queryParams = `${queryParams}&${context.isSelectedFromList ? 'id' : SearchesSubScreen || usersScreen || mySearches || contactsScreen || companiesScreen ? 'searchValue' : 'name'}=${value}`;
    formURL = companiesSubScreen || usersScreen ? `${url.quickQuery}${queryParams}` : `${url.quickQuery}/query${queryParams}`;
  }
  if (subString === '&type=board-contacts') {
    formURL = formURL + subString;
  }
  const data = context.advanceSelector ? await httpService.post(formURL, context.queryData) : await httpService.get(formURL);
  return data;
};

export const dataSourceUtils = (params, getFilterParamString, getQueryParamsString) => {
  return querySelector(params, getFilterParamString, getQueryParamsString);
};

export const filter = (params, queryType, advanceQueryType, getFilterParamString, queryParams, context, subScreen) => {
  if (Object.keys(params.filterModel)?.length) {
    let filterValue = getFilterParamString(params.filterModel, queryType, advanceQueryType);
    return !context.isClassicQuery && !context.quickQuery && !subScreen && !advanceQueryType ? `/query/${queryParams}${filterValue}` : `${queryParams}${filterValue}`;
  }
};

export const sort = params => {
  if (params.sortModel?.length > 0) {
    return `&sortOn=${params.sortModel[0].colId}&sortType=${params.sortModel[0].sort.toUpperCase()}`;
  }
};

export const authenticateUserInZendesk = async () => {
  try {
    const res = await httpService.get(`${API.get_zendesk_token}`);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log('error in getting zendesk JWT:: ', error);
  }
};
