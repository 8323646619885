import { GET, IGN_PUBLIC_API, POST } from './constantService';
import publicHttpService from './publicHttpService';

export const PortalDataApi = async (methodName, id = '', body = {}, sub_route = '', responseType = 'json', header = {}) => {
  try {
    if (methodName === GET) {
      const result =
        id?.length > 0
          ? sub_route.length > 0
            ? await publicHttpService.get(`${IGN_PUBLIC_API.jobBoard}/${sub_route}/${id}`, '', header)
            : await publicHttpService.get(`${IGN_PUBLIC_API.jobBoard}/${id}`, '', header)
          : await publicHttpService.get(`${IGN_PUBLIC_API.jobBoard}`);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType?.length > 0
          ? id?.length > 0
            ? await publicHttpService.post(`${IGN_PUBLIC_API.jobBoard}/${id}/${sub_route}`, body, responseType, header)
            : await publicHttpService.post(`${IGN_PUBLIC_API.jobBoard}/${sub_route}`, body, responseType, header)
          : id?.length > 0
            ? await publicHttpService.post(`${IGN_PUBLIC_API.jobBoard}/${id}/${sub_route}`, body, responseType, header)
            : await publicHttpService.post(`${IGN_PUBLIC_API.jobBoard}/${sub_route}`, body, responseType, header);
      return result;
    }
  } catch (e) {
    console.log('Error found in PortalDataApi::', e);
  }
};
