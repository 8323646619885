import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, Divider, FormHelperText, Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { capitalize, isObject, startCase } from 'lodash';
import { useSnackbar } from 'notistack';
import propTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../../components/common/CustomButton';
import CustomMuiDropdown from '../../../../../components/common/CustomMuiDropdown';
import { getMethodWithCancelTokenApi, ignUsersApi, StageWorkflowApi } from '../../../../../services/ApiService';
import { GET, IGN_API } from '../../../../../services/constantService';
import useApi from '../../../../../utils/Hooks/useApiHook';
import { createNewStageTransition, deleteStageAction, updateStageTransition } from '../../api';
import * as CONSTANTS from '../utils/constants';
import * as helpers from '../utils/helpers';
import MultiSelectDropdown from './Multiselect';

const StageWorkflowForm = props => {
  const { t } = useTranslation();
  const { id, refetch, workflows, clearSelection } = props;

  const [open, setOpen] = React.useState(false);
  const { watch, setValue, register, unregister, handleSubmit } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [_, setContactLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [jsonError, setJsonError] = useState(null);
  const [headerError, setHeaderError] = useState(null);
  const [showHeader, setShowHeader] = useState(null);

  const formatJson = React.useCallback((jsonString, isHeader) => {
    if (!jsonString) {
      return;
    }
    try {
      isHeader ? setHeaderError(null) : setJsonError(null);
      const parsedJson = JSON.parse(jsonString);
      return JSON.stringify(parsedJson, null, 2); // Format with 2 spaces indentation
    } catch (error) {
      isHeader ? setHeaderError('Invalid JSON') : setJsonError('Invalid JSON');

      return jsonString; // Return the original string if it is not valid JSON
    }
  }, []);
  // -----------------------------------------setup functions and states ------------------------------------------
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    resetForm();
    setOpen(false);
    clearSelection();
  };

  const toggleHeaderViewer = actionId => {
    setShowHeader(prevState => {
      return { ...prevState, [actionId]: !prevState[actionId] };
    });
  };

  useEffect(() => {
    register('setup_name');
    register('to_stage_code');
    register('from_stage_code');
    register('workflowActions');

    setValue('workflowActions', []);
    setValue('to_stage_code', CONSTANTS.defaultState);
    setValue('from_stage_code', CONSTANTS.defaultState);
    return () => {
      resetForm();
      unregister('setup_name');
      unregister('to_stage_code');
      unregister('from_stage_code');
      unregister('workflowActions');
    };
  }, []);
  const resetForm = () => {
    setValue('setup_name', '');
    setValue('to_stage_code', CONSTANTS.defaultState);
    setValue('from_stage_code', CONSTANTS.defaultState);
    setValue('workflowActions', [CONSTANTS.generateNewAction()]);
    setJsonError(null);
    setSubmitLoading(false);
    setHeaderError(null);
    setShowHeader(null);
  };
  useEffect(() => {
    if (id) {
      const workflow = workflows.find(workflow => workflow.id === id);
      setValue('setup_name', workflow.setup_name);
      setValue('to_stage_code', { title: workflow.to_stage_code, value: workflow.to_stage_code });
      setValue('from_stage_code', { title: workflow.from_stage_code === null ? 'Any' : workflow.from_stage_code, value: workflow.from_stage_code === null ? null : workflow.from_stage_code });
      const action = workflow.workflowActions.map(action => {
        if (action.action_type === 'WEBHOOK') {
          setShowHeader(prevState => ({ ...prevState, [action.id]: false }));
        }
        return {
          id: action.id,
          action_description: action.action_description,
          action_type: { title: action.action_type, value: action.action_type },

          recipient_type: { title: action.recipient_type, value: action.recipient_type },
          // action.recipient_type.value === 'CONTACTS'
          //     ? action.contacts.map(contact => contact.value).join(', ')
          //     : action.recipient_type.value === 'USERS'
          //       ? action.users.map(contact => contact.value).join(', ')
          //       : action.recipients_or_url.value
          users: action.recipient_type === 'USERS' ? action?.formattedRecipients?.map(user => ({ title: `${user?.first_name} ${user?.last_name}`, value: user.id })) : [],
          contacts: action.recipient_type === 'CONTACTS' ? action.formattedRecipients?.map(contact => ({ title: `${contact?.first_name} ${contact?.last_name}`, value: contact.id })) : [],
          recipients_or_url:
            action.recipient_type === 'CONTACTS'
              ? action.formattedRecipients?.map(contact => ({ title: `${contact?.first_name} ${contact?.last_name}`, value: contact.id }))
              : action.recipient_type === 'USERS'
                ? action?.formattedRecipients?.map(user => ({ title: `${user?.first_name} ${user?.last_name}`, value: user.id }))
                : action.recipient_type === 'JSON'
                  ? action.recipients_or_url
                  : { title: capitalize(action.recipients_or_url), value: action.recipients_or_url },
          template_id: action.template_id,
          template_name: action.template_name,
          action_disabled: action.action_disabled,
          payload: action.payload,
          webhook_header: action.webhook_header ? JSON.stringify(action.webhook_header, null, 2) : null
        };
      });
      setValue('workflowActions', action);
      handleClickOpen();
    }
  }, [id]);

  const handleAddMoreAction = () => {
    const actions = watch('workflowActions');
    setValue('workflowActions', [...actions, CONSTANTS.generateNewAction()]);
  };
  // -----------------------------------------api calls ------------------------------------------
  const { data: configData, loading: configLoading } = useApi({
    queryKey: 'getAllWorkflow',
    queryFn: async () => {
      return await StageWorkflowApi('get', '/config');
    }
  });
  const { data: userData, loading: usersDataLoading } = useApi({
    queryKey: 'getAllUsers',
    queryFn: async () => {
      const users = await ignUsersApi(GET, '/getAll');
      return users;
    }
  });
  const getAllUsers = () => {
    if (!userData) return [];
    if (usersDataLoading) return [];
    const allUsers = userData?.data?.map(item => {
      return {
        value: item?.id,
        title: item?.name
      };
    });

    return allUsers;
  };
  const getSearchData = async (searchValue = '') => {
    const url = `${IGN_API.picklists}/contacts?name=${searchValue}`;
    setContactLoading(true);
    if (searchValue && searchValue.length > 3) {
      await getMethodWithCancelTokenApi(url, {}, {}).then(response => {
        const { status, data } = response;
        if (status === 200) {
          const records = data?.data?.map(record => ({ ...record, company_name: record?.contact_company?.name }));
          setRowData(records);
          if (data?.data?.length === 0) {
            helpers.raiseErrorSnackbar('No data found');
            setRowData([]);
          }
        }
      });
      setContactLoading(false);
    }
  };
  const getContactsList = () => {
    if (!rowData) return [];
    const contact = rowData.map(item => {
      return {
        value: item.id,
        title: item.first_name + ' ' + item.last_name
      };
    });

    return [...contact];
  };

  const handleActionDelete = id => {
    const actions = watch('workflowActions');
    const actionToRemove = actions.find(action => action.id === id);
    if (!actionToRemove.isNew) {
      deleteStageAction(id, false);
    }
    const updatedActions = actions.filter(action => action.id !== id);
    setValue('workflowActions', updatedActions);
  };
  const handleWorkActionChange = (e, index, key) => {
    const actions = watch('workflowActions');
    actions[index][key] = e;
    setValue('workflowActions', actions);
  };

  const getStageOptions = oppositeStage => {
    if (!configData) return [];
    if (configLoading) return [];
    const stageOptions = configData?.data?.data?.stages
      ?.filter(stage => (oppositeStage ? stage.stage_name !== oppositeStage.value : stage.stage_name))
      .map(stage => {
        return { title: stage.stage_name, value: stage.stage_name };
      });
    return stageOptions;
  };
  const getActionTypes = () => {
    if (!configData) return [];
    if (configLoading) return [];
    const actionTypes = configData?.data?.data?.actionTypes
      ?.filter(action => !CONSTANTS.HOLD_OFF_ACTION_TYPE.includes(action))
      .map(action => {
        return { title: action, value: action };
      });
    return actionTypes;
  };
  const getActionRecipientTypes = id => {
    if (!configData) return [];
    if (configLoading) return [];
    const actionType = watch('workflowActions')[id]?.action_type?.value;
    if (!actionType) return [];
    const actionRecipientTypes = configData?.data?.data?.actionRecipientMapper[actionType]?.map(action => {
      return { title: action, value: action };
    });
    return actionRecipientTypes;
  };
  const getRecipientOrUrl = id => {
    if (!configData) return [];
    if (configLoading) return [];
    const recipientType = watch('workflowActions')[id]?.recipient_type?.value;
    if (!recipientType) return [];
    if (CONSTANTS.mapperRecipients[recipientType]) {
      const recipientOrUrl = configData?.data?.data[CONSTANTS.mapperRecipients[recipientType]]?.map(template => {
        return { title: template.short_desc, value: template.field_value };
      });

      return recipientOrUrl;
    }
    return [];
  };
  const getMessagePayload = id => {
    if (!configData) return [];
    if (configLoading) return [];
    const actionType = watch('workflowActions')[id]?.action_type?.value;
    if (!actionType) return [];
    if (CONSTANTS.mapperMessageTemplates[actionType]) {
      const recipientOrUrl = configData?.data?.data[CONSTANTS.mapperMessageTemplates[actionType]]?.map(template => {
        return { title: template.title, value: template.id };
      });
      return recipientOrUrl;
    }
    return [];
  };

  const onSubmit = async data => {
    if (!data.setup_name || !data.to_stage_code || data.workflowActions.length === 0) {
      helpers.raiseErrorSnackbar('Please fill all the fields');
      return;
    }

    setSubmitLoading(true);
    const payload = {
      setup_name: data.setup_name,
      from_stage_code: data.from_stage_code.value,
      to_stage_code: data.to_stage_code.value,
      actions: data.workflowActions.map(action => {
        let payload = null;
        let webhook_header = null;
        if (action.action_type.value === 'WEBHOOK') {
          payload = action.payload;
          webhook_header = action.webhook_header;
        }
        return {
          isNew: action.isNew,
          id: action.id,
          action_description: action.action_description,
          action_type: action.action_type.value,
          recipient_type: action.recipient_type.value,
          recipients_or_url:
            action.recipient_type.value === 'CONTACTS'
              ? action.contacts.map(contact => contact.value).join(', ')
              : action.recipient_type.value === 'USERS'
                ? action.users.map(contact => contact.value).join(', ')
                : isObject(action.recipients_or_url)
                  ? action.recipients_or_url.value
                  : action.recipients_or_url,
          payload,
          webhook_header,
          action_disabled: action.action_disabled,
          template_id: action.template_id,
          template_name: action.template_name
        };
      })
    };

    const { isValid, errorMessage } = helpers.validateFormData(payload);
    if (!isValid) {
      setSubmitLoading(false);
      helpers.raiseErrorSnackbar(errorMessage);
      return;
    }
    let response;
    if (id) {
      response = await updateStageTransition(id, payload);
    } else {
      response = await createNewStageTransition(payload);
    }
    if (response.error) {
      setSubmitLoading(false);
      helpers.raiseErrorSnackbar(response.error);
    } else {
      resetForm();
      handleClose();
      refetch(true);
      setSubmitLoading(false);
      enqueueSnackbar(response.msg, { variant: 'success' });
    }
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleClickOpen}
        startIcon={<AddIcon sx={{ fontSize: '1rem' }} />}
        variant='contained'
        size='small'
        style={{ background: 'white', color: '#1b4965', fontWeight: 'bold', textTransform: 'none' }}
      >
        {t('actions.add')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        maxHeight={'80vh'}
        maxWidth={'lg'}
        PaperProps={{ sx: { minHeight: '60%' } }}
      >
        <DialogTitle id='alert-dialog-title'> {id ? 'Edit' : 'Create'} Workflow Configuration</DialogTitle>
        <DialogContent>
          <Stack className='w-100 h-100' p={1} bgcolor={'white'}>
            <Stack direction='row' gap={4} justifyContent={'flex-start'}>
              <Grid container spacing={2} pt={'16px'}>
                <Grid item xs={4}>
                  <TextField
                    label='Workflow Name'
                    required
                    fullWidth
                    type='text'
                    className='fs-12'
                    sx={{ px: '4px' }}
                    value={watch('setup_name')}
                    onChange={e => setValue('setup_name', e.target.value)}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomMuiDropdown
                    label='From stage'
                    options={[
                      {
                        title: 'Any',
                        value: null
                      },
                      ...getStageOptions(watch('to_stage_code'))
                    ]}
                    value={watch('from_stage_code') || ''}
                    onChange={(e, newValue) => {
                      if (watch('to_stage_code')?.value === newValue?.value) {
                        helpers.raiseErrorSnackbar('From stage and to stage cannot be same');
                        return;
                      }
                      setValue('from_stage_code', newValue || '');
                    }}
                    renderInput={value => value.title}
                    getOptionLabel={option => option.title}
                    textFieldProps={{ required: true, placeholder: 'Choose a stage' }}
                    autoCompleteProps={{ autoHighlight: true }}
                    isLoading={configLoading}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomMuiDropdown
                    options={getStageOptions(watch('from_stage_code'))}
                    label='To stage'
                    value={watch('to_stage_code') || ''}
                    onChange={(e, newValue) => {
                      if (watch('from_stage_code')?.value === newValue?.value) {
                        helpers.raiseErrorSnackbar('From stage and to stage cannot be same');
                        return;
                      }
                      setValue('to_stage_code', newValue);
                    }}
                    renderValue={value => value.title}
                    getOptionLabel={option => option.title}
                    textFieldProps={{ required: true, placeholder: 'Choose a stage' }}
                    autoCompleteProps={{ autoHighlight: true }}
                    isLoading={configLoading}
                  />
                </Grid>
              </Grid>
            </Stack>
            {/* section 2 .. table edit */}
            {/* create 4x4 grid empty but table like structure */}
            {/* 4 columns */}

            <br />
            <Typography className='fs-16'>Workflow Actions</Typography>

            <Divider />
            <br />
            {watch('workflowActions')?.length > 0 &&
              watch('workflowActions').map((action, index) => (
                <Grid container spacing={2} pt={'16px'} key={index}>
                  <Grid item xs={2.5}>
                    <TextField
                      label='Description'
                      placeholder='Notify Candidate via Email'
                      fullWidth
                      InputLabelProps={{
                        style: { fontSize: 14 }
                      }}
                      inputProps={{
                        style: { fontSize: 14 }
                      }}
                      value={action.action_description}
                      onChange={e => handleWorkActionChange(e.target.value, index, 'action_description')}
                    />
                  </Grid>
                  <Grid item xs={2.5}>
                    <>
                      <CustomMuiDropdown
                        label='Action Type'
                        options={getActionTypes()}
                        value={action?.action_type || CONSTANTS.defaultState}
                        onChange={(e, newValue) => {
                          // clear the recipients_or_url field if the action type changes
                          if (action?.recipients_or_url) {
                            handleWorkActionChange(CONSTANTS.defaultState, index, 'recipients_or_url');
                            handleWorkActionChange(CONSTANTS.defaultState, index, 'recipient_type');
                          }
                          if (newValue?.value === 'WEBHOOK') {
                            handleWorkActionChange('', index, 'recipients_or_url');
                            setShowHeader(prevState => ({ ...prevState, [action.id]: false }));
                          }

                          if (!newValue) {
                            handleWorkActionChange(CONSTANTS.defaultState, index, 'action_type');
                            return;
                          }
                          handleWorkActionChange(newValue, index, 'action_type');
                        }}
                        renderInput={value => value?.title}
                        getOptionLabel={option => option?.title}
                        textFieldProps={{ required: true, placeholder: 'Choose an action' }}
                        autoCompleteProps={{ autoHighlight: true }}
                        isLoading={configLoading}
                      />
                      <CustomMuiDropdown
                        autoCompleteProps={{ autoHighlight: true, style: { marginTop: 10 } }}
                        label={action?.action_type?.value === 'WEBHOOK' ? 'Content Type' : 'Recipient Type'}
                        options={getActionRecipientTypes(index)}
                        value={action.recipient_type || CONSTANTS.defaultState}
                        onChange={(e, newValue) => {
                          // clear the recipients_or_url field if the recipient type changes
                          if (action?.recipients_or_url) {
                            handleWorkActionChange(CONSTANTS.defaultState, index, 'recipients_or_url');
                          }
                          if (!newValue) {
                            handleWorkActionChange(CONSTANTS.defaultState, index, 'recipients_or_url');
                            return;
                          }
                          if (newValue?.value === 'USERS') {
                            handleWorkActionChange([], index, 'recipients_or_url');
                          }
                          if (newValue?.value === 'WEBHOOK') {
                            handleWorkActionChange('', index, 'recipient_type');
                          }
                          handleWorkActionChange(newValue, index, 'recipient_type');
                        }}
                        renderInput={value => value?.title}
                        getOptionLabel={option => option?.title}
                        textFieldProps={{ required: true, placeholder: action?.action_type?.value === 'WEBHOOK' ? 'Choose Content type' : 'Choose a recipient type' }}
                        isLoading={configLoading}
                        disabled={!action.action_type?.value}
                      />
                      <FormHelperText>
                        {action?.action_type?.value === 'EMAIL'
                          ? `Email will be send to primary mail of the ${startCase(capitalize(action?.recipient_type?.value)) || 'Contact/Candidate/User'}`
                          : action?.action_type?.value === 'SMS'
                            ? `SMS will be send to primary phone of the  ${startCase(capitalize(action?.recipient_type?.value)) || 'Contact/Candidate/User'}`
                            : ''}
                      </FormHelperText>
                    </>
                  </Grid>
                  <Grid item xs={3}>
                    {action?.action_type?.value === 'WEBHOOK' ? (
                      <TextField label='URL*' required fullWidth value={action.recipients_or_url} onChange={e => handleWorkActionChange(e.target.value, index, 'recipients_or_url')} />
                    ) : action?.recipient_type?.value === 'USERS' ? (
                      <MultiSelectDropdown
                        label='Recipient'
                        multiple
                        textFieldProps={{ required: true }}
                        value={action.users || []}
                        fullWidth
                        variant='outlined'
                        onChange={e => handleWorkActionChange(e, index, 'users')}
                        options={getAllUsers()}
                      />
                    ) : action?.recipient_type?.value === 'CONTACTS' ? (
                      <>
                        <MultiSelectDropdown
                          label='Recipient'
                          textFieldProps={{ required: true }}
                          getOptions={getSearchData}
                          value={action.contacts || []}
                          variant='outlined'
                          onChange={e => handleWorkActionChange(e, index, 'contacts')}
                          options={getContactsList()}
                        />
                        <FormHelperText>Type and press enter to search</FormHelperText>
                      </>
                    ) : action?.recipient_type?.value === 'CANDIDATE' ? (
                      <TextField value={'Project Candidate'} disabled={true} fullWidth />
                    ) : (
                      <CustomMuiDropdown
                        label='Recipient'
                        options={getRecipientOrUrl(index) || CONSTANTS.defaultState}
                        value={action?.recipients_or_url || CONSTANTS.defaultState}
                        onChange={(e, newValue) => {
                          if (!newValue) {
                            handleWorkActionChange(CONSTANTS.defaultState, index, 'recipients_or_url');
                            return;
                          }
                          handleWorkActionChange(newValue, index, 'recipients_or_url');
                        }}
                        renderInput={value => value?.title}
                        getOptionLabel={option => option?.title}
                        textFieldProps={{ required: true, placeholder: 'Choose a recipient' }}
                        autoCompleteProps={{ autoHighlight: true }}
                        isLoading={configLoading}
                        disabled={!action.recipient_type?.value}
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {action.action_type.value === 'WEBHOOK' ? (
                      <>
                        <TextField
                          label='JSON Payload'
                          rows={3}
                          error={jsonError}
                          fullWidth
                          required
                          InputLabelProps={{
                            style: { fontSize: 14 }
                          }}
                          inputProps={{
                            style: { fontSize: 14, lineHeight: 1.5 }
                          }}
                          helperText={jsonError}
                          multiline
                          value={action.payload}
                          onBlur={e => {
                            const formattedJson = formatJson(e.target.value);
                            handleWorkActionChange(formattedJson, index, 'payload');
                          }}
                          onChange={e => handleWorkActionChange(e.target.value, index, 'payload')}
                        />

                        <TextField
                          label='Webhook Header'
                          type={showHeader && showHeader[action.id] ? 'text' : 'password'}
                          style={{ marginTop: 10 }}
                          InputLabelProps={{
                            style: { fontSize: 14 }
                          }}
                          inputProps={{
                            style: { fontSize: 14, lineHeight: 1.5 }
                          }}
                          error={headerError}
                          fullWidth
                          helperText={headerError}
                          value={action.webhook_header}
                          onBlur={e => {
                            const formattedJson = formatJson(e.target.value, true);
                            handleWorkActionChange(formattedJson, index, 'webhook_header');
                          }}
                          onChange={e => handleWorkActionChange(e.target.value, index, 'webhook_header')}
                        />
                      </>
                    ) : (
                      <CustomMuiDropdown
                        label='Message Template'
                        options={getMessagePayload(index) || CONSTANTS.defaultState}
                        value={{ title: action.template_name, value: action.template_id } || CONSTANTS.defaultState}
                        onChange={(e, newValue) => {
                          if (!newValue) {
                            handleWorkActionChange('', index, 'template_id');
                            handleWorkActionChange('', index, 'template_name');
                            return;
                          }
                          handleWorkActionChange(newValue.value, index, 'template_id');
                          handleWorkActionChange(newValue.title, index, 'template_name');
                        }}
                        renderInput={value => value?.title}
                        getOptionLabel={option => option?.title}
                        textFieldProps={{ required: true, placeholder: 'Choose a recipient' }}
                        autoCompleteProps={{ autoHighlight: true }}
                        isLoading={configLoading}
                      />
                    )}
                  </Grid>
                  <Grid item xs={1}>
                    <Switch
                      checked={!action.action_disabled}
                      onChange={e => {
                        handleWorkActionChange(!e.target.checked, index, 'action_disabled');
                      }}
                      color='primary'
                    />
                    <Button variant='text' color='error' onClick={() => handleActionDelete(action.id)}>
                      <DeleteIcon />
                    </Button>
                    {action.action_type.value === 'WEBHOOK' && showHeader && (
                      <Button sx={{ marginTop: 5 }} variant='text' color='primary' onClick={() => toggleHeaderViewer(action.id)}>
                        {showHeader[action.id] ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              ))}

            <Stack maxWidth={'150px'} pt={'16px'}>
              <Button variant='text' color='primary' width={'150px'} onClick={handleAddMoreAction}>
                <AddIcon />
                {t('stageWorkflow.addAction')}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <CustomButton type='tertiary-error' style={{ margin: '0 20px 20px 0' }} size='medium' buttonText={t('actions.cancel')} customWidth={90} variant='text' onClick={handleClose} />
          <CustomButton
            type='primary'
            size='medium'
            style={{ margin: '0 20px 20px 0' }}
            buttonText={id ? t('actions.update') : t('actions.save')}
            customWidth={90}
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            disabled={submitLoading}
            isLoading={submitLoading}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

StageWorkflowForm.propTypes = {
  id: propTypes.string,
  refetch: propTypes.func,
  workflows: propTypes.array,
  clearSelection: propTypes.func
};

export default StageWorkflowForm;
