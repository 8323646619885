import React from 'react';
import { Container, Row } from 'react-grid-system';
import PropTypes from 'prop-types';
import './index.scss';
import { Box } from '@mui/material';
const CardComponent = ({ children }) => {
  return (
    <Box className='w-100' id='fluid-layout'>
      <Container fluid>
        <Row className='card-row'>{children}</Row>
      </Container>
    </Box>
  );
};
CardComponent.propTypes = {
  children: PropTypes.node
};
export default CardComponent;
