//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Typography, Button, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { NumericFormat } from 'react-number-format';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../../../../common/InputField';
import JobFunctionSelection from '../../../../../jobFunctionSelection';
import IndustrySelection from '../../../../../IndustrySelection';
import CountrySelection from '../../../../../CountrySelection';
import CapitalStructure from './CapitalStructure';
import { separator } from '../../../../../../Containers/Commons/Utils';
import { DECIMAL_SEPARATOR, TARGET_COUNTRY_RANKING, TARGET_INDUSTRY_RANKING, THOUSAND_SEPARATOR } from '../../../../../../services/constantService';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGroupIndustryPickList } from '../../../../../../actions';
import CustomStateSelection from '../../../../../CustomStateSelection';

export default function TargetDetails(props) {
  const { register, watch, setValue, currentValues = {}, currencyDetails, unregister, readOnly } = props;
  const [contactTargets, setContactTargets] = useState([{}]);
  const [targetIndustryGroup, setTargetIndustryGroup] = useState([]);
  const [targetCountryGroup, setTargetCountryGroup] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);
  const groupIndustriesCatagory = useSelector(state => state.commonReducer.groupIndustriesCatagory);
  const [availableCategory, setAvailableCategory] = useState([]);
  const [existingCountry, setExistingCountry] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (targetCountryGroup) {
      let existingCountry = targetCountryGroup.map(item => item.country);
      setExistingCountry(existingCountry);
    }
  }, [targetCountryGroup]);
  useEffect(() => {
    if (!groupIndustriesCatagory) {
      dispatch(fetchGroupIndustryPickList('industries/groups', 'groupIndustriesCatagory'));
    } else {
      let existingIndustry = targetIndustryGroup.map(item => item.industry)?.filter(item => item.name);
      let availableGroup = groupIndustriesCatagory
        ?.filter(item => !existingIndustry.map(item => item.name).includes(item))
        .map(item => {
          return { name: item };
        });
      setAvailableCategory(availableGroup);
    }
  }, [targetIndustryGroup, groupIndustriesCatagory, dispatch]);
  useEffect(() => {
    register('contact_targets');
    setContactTargets(currentValues.contact_targets || []);
    return () => {
      unregister('contact_targets');
    };
  }, [currentValues.contact_targets, register, setValue]);

  // useEffect(() => {
  //   register('contact_targets')
  //   setValue('contact_targets', contactTargets);
  // }, [contactTargets, setValue])

  useEffect(() => {
    register('target_country_group');
    let targetCountries;
    if (currentValues.search_target_country_rankings) {
      targetCountries = currentValues.search_target_country_rankings;
    }
    if (targetCountries) {
      setTargetCountryGroup(targetCountries);
    }
  }, [currentValues.search_target_country_rankings, register, setValue]);
  useEffect(() => {
    register('target_industries_group');
    let targetIndustries;
    if (currentValues.search_industry_rankings) {
      targetIndustries = currentValues.search_industry_rankings.map(item => {
        return {
          ...item,
          industry: { name: item.industry },
          sub_industries: item.sub_industries.map(itm => {
            return { name: itm };
          })
        };
      });
    }
    if (targetIndustries) {
      setTargetIndustryGroup(targetIndustries);
    }
  }, [currentValues.search_industry_rankings, register, setValue]);

  const updateContactTargets = (index, name, data) => {
    const currentContactTargets = [...contactTargets];
    currentContactTargets[index][name] = data;
    setContactTargets(currentContactTargets);
    if (updateCount > 1) {
      register('contact_targets');
      setValue('contact_targets', contactTargets);
    }
    setUpdateCount(updateCount + 1);
  };

  const deleteContactTargets = index => {
    const currentContactTargets = [...contactTargets];
    const callApi = Object.values(currentContactTargets[index])
      .slice(0, -1)
      .every(value => value == null)
      ? false
      : true;
    currentContactTargets.splice(index, 1);
    setContactTargets(currentContactTargets);
    if (callApi || updateCount > 1) {
      register('contact_targets');
      setValue('contact_targets', currentContactTargets);
    }
  };
  const deleteTargateIndustries = index => {
    const currentTargetIndustryGroup = [...targetIndustryGroup];
    const callApi = Object.values(currentTargetIndustryGroup[index])
      .slice(0, -1)
      .every(value => value == null)
      ? false
      : true;
    currentTargetIndustryGroup.splice(index, 1);
    setTargetIndustryGroup(currentTargetIndustryGroup);
    if (callApi || updateCount > 1) {
      register('target_industries_group');
      setValue('target_industries_group', currentTargetIndustryGroup);
    }
  };

  const deleteTargateCountry = index => {
    const currentTargetCountryGroup = [...targetCountryGroup];
    const callApi = Object.values(currentTargetCountryGroup[index])
      .slice(0, -1)
      .every(value => value == null)
      ? false
      : true;
    currentTargetCountryGroup.splice(index, 1);
    setTargetCountryGroup(currentTargetCountryGroup);
    if (callApi || updateCount > 1) {
      register('target_country_group');
      setValue('target_country_group', currentTargetCountryGroup);
    }
  };

  const addContactTarget = () => {
    const currentContactTargets = [...contactTargets, {}];
    setContactTargets(currentContactTargets);
  };
  const addTargateIndustries = () => {
    const currentTargetIndustryGroup = [...targetIndustryGroup, { industry: {}, sub_industries: [], ranking: 0 }];
    setTargetIndustryGroup(currentTargetIndustryGroup);
  };
  const addTargateCountry = () => {
    const currentTargetCountryGroup = [...targetCountryGroup, { country: '', states: [], ranking: 0 }];
    setTargetCountryGroup(currentTargetCountryGroup);
  };
  // const getTotalRanking = () => {
  //   return targetIndustryGroup ? targetIndustryGroup.reduce((acc, curr) => { return acc + Number(curr.ranking) }, 0) : null;
  // }

  const updateTargateIndustries = (index, name, data) => {
    let newTargetIndustry = [...targetIndustryGroup];
    if (name === 'industry') {
      newTargetIndustry[index] = { ...newTargetIndustry[index], [name]: data, sub_industries: [], ranking: 0 };
    } else {
      newTargetIndustry[index] = { ...newTargetIndustry[index], [name]: data };
    }
    setTargetIndustryGroup(newTargetIndustry);
    setValue('target_industries_group', newTargetIndustry);
  };

  const updateTargateCountries = (index, name, data) => {
    let newTargetCountry = [...targetCountryGroup];
    if (name === 'country') {
      newTargetCountry[index] = { ...newTargetCountry[index], [name]: data, states: [], ranking: 0 };
    } else {
      newTargetCountry[index] = { ...newTargetCountry[index], [name]: data };
    }
    setTargetCountryGroup(newTargetCountry);
    setValue('target_country_group', newTargetCountry);
  };

  const validateRankingValue = value => {
    return value < 0 || value > 100;
  };

  // Filter country to check state available in database
  // const filterTargetCountry = () => {
  //   const countryList = watch('target_locations')
  //   const checkCountryOptions = countryList?.find(ele => ele.name === 'United States of America (USA)')
  //   return checkCountryOptions?.name
  // }

  const handleKeyDown = (event, index) => {
    switch (event.key) {
      case ',': {
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value?.length > 0) {
          const newArray = [...targetCountryGroup];
          newArray[index].states = [...newArray[index].states, event.target.value];
          updateTargateCountries(index, 'states', newArray[index].states);
        }
        break;
      }
      default:
    }
  };

  const handleChangeTargetStates = (event, newValue, index) => {
    if (Array.isArray(newValue)) {
      const newArray = newValue.map(item => {
        if (typeof item === 'string') {
          return item;
        } else if (item && item.inputValue) {
          // Create a new value from the user input
          return item.inputValue;
        } else return item.title;
      });

      updateTargateCountries(index, 'states', newArray);
    } else updateTargateCountries(index, 'states', []);
  };

  useEffect(() => {
    register('target_job_functions');
    register('target_locations');
    register('target_states');
    // register("target_industries");
    register('capital_structure');
    register('revenue_min');
    register('revenue_max');
    register('target_capital_structure');
  }, [register]);
  return (
    <div className={`section-content ${readOnly ? 'read-only' : ''}`}>
      <Typography className='section-label'>Target Details</Typography>
      <React.Fragment>
        {targetIndustryGroup?.length > 0 && (
          <React.Fragment>
            <Grid container spacing={8} alignItems={'baseline'}>
              <Grid item sm={3}>
                <Typography color={'primary'} style={{ fontSize: '15px' }}>
                  Target Industry
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography color={'primary'} style={{ fontSize: '15px' }}>
                  Target Sub Industries
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography color={'primary'} style={{ fontSize: '15px' }}>
                  Weight (1-100)
                </Typography>
              </Grid>
              <Grid item sm={1}>
                <Typography color={'primary'} style={{ fontSize: '15px' }}></Typography>
              </Grid>
            </Grid>
            {targetIndustryGroup.map((item, index) => (
              <Grid container spacing={4} alignItems={'center'} key={index}>
                <Grid item sm={3}>
                  <IndustrySelection
                    multiple={false}
                    filterSelectedOptions
                    size='small'
                    className='input-form-field input-field-old'
                    label='Target Industry'
                    value={item.industry || []}
                    onChange={(e, data) => {
                      updateTargateIndustries(index, 'industry', data && Object.keys(data)?.length ? data : {});
                    }}
                    InputLabelProps={{ focused: true }}
                    isGroupIndustry={true}
                    availableCategory={availableCategory}
                    placeholder='Target Industry'
                  />
                </Grid>
                <Grid item sm={4}>
                  <IndustrySelection
                    multiple
                    filterSelectedOptions
                    size='small'
                    className='input-form-field input-field-old'
                    label='Target Sub Industries'
                    value={item.sub_industries || []}
                    onChange={(e, data) => {
                      updateTargateIndustries(index, 'sub_industries', Array.isArray(data) ? data : []);
                    }}
                    InputLabelProps={{ focused: true }}
                    mainCatagory={item.industry?.name || 'empty'}
                    placeholder='Target Sub Industries'
                  />
                </Grid>

                <Grid item sm={3}>
                  <InputField
                    value={item.ranking}
                    type='number'
                    placeholder='Weight (1-100)'
                    InputProps={{
                      inputProps: { min: 0, max: 100, pattern: '[0-9]*' }
                    }}
                    error={validateRankingValue(item.ranking)}
                    helperText={validateRankingValue(item.ranking) ? TARGET_COUNTRY_RANKING.RANKING_VALIDATION_MESSAGE : ''}
                    onChange={e => {
                      updateTargateIndustries(index, 'ranking', parseInt(e.target.value));
                    }}
                    style={{ marginTop: '15px' }}
                    className='input-form-field input-field-old'
                  />
                </Grid>
                <Grid item sm={2}>
                  <DeleteIcon style={{ marginTop: '20px' }} className='cursor-pointer' onClick={() => deleteTargateIndustries(index)} />
                </Grid>
              </Grid>
            ))}
            {/* <Grid container spacing={5} justify={'flex-end'}>
            <Grid item xs={5}>
              <InputField
                type="number"
                value={getTotalRanking()}
                // error={getTotalRanking()}
                // helperText={validateRankingValue(getTotalRanking()) ? "Total Ranking must between 0 to 100" : ""}
                InputProps={{ disableUnderline: true }}
                disabled
              />
            </Grid>
          </Grid> */}
          </React.Fragment>
        )}

        <Button variant={'text'} startIcon={<AddCircleIcon />} onClick={addTargateIndustries} style={{ marginTop: '2%', marginBottom: '2%' }} color='primary'>
          Add Target Industry
        </Button>
      </React.Fragment>
      <React.Fragment>
        {targetCountryGroup?.length > 0 && (
          <React.Fragment>
            <Grid container spacing={8} alignItems={'baseline'}>
              <Grid item sm={3}>
                <Typography color={'primary'} style={{ fontSize: '15px' }}>
                  Target Country
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography color={'primary'} style={{ fontSize: '15px' }}>
                  Target States
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography color={'primary'} style={{ fontSize: '15px' }}>
                  Weight (1-100)
                </Typography>
              </Grid>
              <Grid item sm={1}>
                <Typography color={'primary'} style={{ fontSize: '15px' }}></Typography>
              </Grid>
            </Grid>
            {targetCountryGroup.map((item, index) => (
              <Grid container spacing={4} alignItems={'center'} key={index}>
                <Grid item sm={3}>
                  <CountrySelection
                    existingCountry={existingCountry}
                    filterSelectedOptions
                    size='small'
                    value={item.country || null}
                    className='input-form-field input-field-old'
                    label='Target country'
                    placeholder={'Target Country'}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      updateTargateCountries(index, 'country', data && Object.keys(data)?.length ? data.name : '');
                    }}
                  />
                </Grid>
                <Grid item sm={4}>
                  <CustomStateSelection
                    value={item.states || []}
                    className='input-form-field input-field-old'
                    country={item.country}
                    register={register}
                    label='Target States'
                    multiple
                    name='target_states'
                    placeholder={'Target States'}
                    filterSelectedOptions
                    onChange={(event, newValue) => handleChangeTargetStates(event, newValue, index)}
                    onKeyDown={event => handleKeyDown(event, index)}
                  />
                </Grid>

                <Grid item sm={3}>
                  <InputField
                    value={item.ranking}
                    type='number'
                    placeholder='Weight (1-100)'
                    InputProps={{
                      inputProps: { min: 0, max: 100, pattern: '[0-9]*' }
                    }}
                    error={validateRankingValue(item.ranking)}
                    helperText={validateRankingValue(item.ranking) ? TARGET_INDUSTRY_RANKING.RANKING_VALIDATION_MESSAGE : ''}
                    onChange={e => {
                      updateTargateCountries(index, 'ranking', parseInt(e.target.value));
                    }}
                    style={{ marginTop: '15px' }}
                    className='input-form-field input-field-old'
                  />
                </Grid>
                <Grid item sm={2}>
                  <DeleteIcon style={{ marginTop: '20px' }} className='cursor-pointer' onClick={() => deleteTargateCountry(index)} />
                </Grid>
              </Grid>
            ))}
          </React.Fragment>
        )}

        <Button variant={'text'} startIcon={<AddCircleIcon />} onClick={addTargateCountry} style={{ marginTop: '2%', marginBottom: '2%' }} color='primary'>
          Add Target Locations
        </Button>
      </React.Fragment>

      {/* <div className="d-flex flex-grow-1 align-items-end">
      <IndustrySelection
          multiple
          filterSelectedOptions
          size="small"
          className="input-form-field"
          name={"target_industries"}
          label="Target Industries"
          value={watch("target_industries") || []}
          onChange={(e, data) => {
            setValue("target_industries", Array.isArray(data) ? data : []);
          }}
          InputLabelProps={{ focused: true }}
        />
      </div> */}
      <div className='d-flex flex-grow-1  align-items-end'>
        <div className='w-50'>
          <JobFunctionSelection
            multiple
            filterSelectedOptions
            value={watch('target_job_functions') || []}
            size='small'
            className='input-form-field input-field-old'
            label='Target Functions'
            placeholder={'Target Functions'}
            InputLabelProps={{ focused: true }}
            onChange={(e, data) => {
              setValue('target_job_functions', data ? data.map(item => (typeof item === 'string' ? item : item.name)) : []);
            }}
          />
        </div>
        {/* <div className="w-25">
          <CountrySelection
            multiple
            filterSelectedOptions
            size="small"
            value={watch("target_locations") || []}
            className="input-form-field"
            label="Target Geography"
            placeholder={'Target Geography'}
            InputLabelProps={{ focused: true }}
            onChange={(e, data) => {
              setValue("target_locations", Array.isArray(data) ? data : []);
            }}
          />
        </div>
        <div className="w-25">
          <CustomStateSelection
            value={watch('target_states') || []}
            className='input-form-field'
            country={filterTargetCountry()}
            register={register}
            label='Target States'
            multiple
            name="target_states"
            placeholder={'Target States'}
            filterSelectedOptions
            onChange={handleChangeTargetStates}
            onKeyDown={handleKeyDown}
          />
        </div> */}
        <div className='w-50'>
          <CapitalStructure
            multiple
            size='small'
            value={watch('target_capital_structure') || []}
            className='input-form-field truncate-label input-field-old'
            label='Target Capital Structure'
            placeholder='Target Capital Structure'
            InputLabelProps={{ focused: true }}
            filterSelectedOptions
            onChange={(e, data) => {
              setValue('target_capital_structure', Array.isArray(data) ? data.map(item => item?.name || item) : []);
            }}
          />
        </div>
      </div>
      <React.Fragment>
        <Grid container spacing={8} alignItems={'center'}>
          <Grid item sm={3}>
            <Typography color={'primary'} style={{ fontSize: '15px' }}>
              Target Title
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography color={'primary'} style={{ fontSize: '15px' }}>
              Revenue Range
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography color={'primary'} style={{ fontSize: '15px' }}>
              Employee Range
            </Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography color={'primary'} style={{ fontSize: '15px' }}>
              Weight (1-100)
            </Typography>
          </Grid>
          <Grid item sm={1}>
            <Typography color={'primary'} style={{ fontSize: '15px' }}></Typography>
          </Grid>
        </Grid>
        {contactTargets &&
          contactTargets.map((item, index) => (
            <Grid container spacing={8} alignItems={'center'} key={index}>
              <Grid item sm={3}>
                <InputField
                  value={item.title || ''}
                  InputProps={{
                    startAdornment: (
                      <Typography style={{ padding: '0 5px' }} color='primary'>
                        {index + 1}.
                      </Typography>
                    )
                  }}
                  InputLabelProps={{ focused: true }}
                  onChange={e => {
                    updateContactTargets(index, 'title', e.target.value);
                  }}
                />
              </Grid>
              <Grid container spacing={1} sm={3} alignItems={'flex-end'} className='mr-3'>
                <Grid item sm={5}>
                  <div>
                    <NumericFormat
                      customInput={TextField}
                      // style={{
                      //   border: 'none',
                      //   borderBottom: '2px solid #a3a3a3',
                      //   width: '125px'
                      // }}

                      value={item.revenue_min || ''}
                      thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                      decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                      decimalScale={2}
                      isNumericString={true}
                      prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                      suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                      onValueChange={values => {
                        const { value } = values;
                        updateContactTargets(index, 'revenue_min', value);
                      }}
                    />
                  </div>
                </Grid>
                <Grid item sm={'auto'}>
                  <span>to</span>
                </Grid>
                <Grid item sm={5}>
                  <div>
                    <NumericFormat
                      style={{
                        border: 'none'
                        // borderBottom: '2px solid #a3a3a3',
                      }}
                      customInput={TextField}
                      value={item.revenue_max || ''}
                      thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                      decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                      decimalScale={2}
                      isNumericString={true}
                      prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                      suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                      onValueChange={values => {
                        const { value } = values;
                        updateContactTargets(index, 'revenue_max', value);
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={1} sm={3} alignItems={'flex-end'}>
                <Grid item sm={5}>
                  <InputField
                    type='number'
                    value={item.employee_min || ''}
                    onChange={e => {
                      updateContactTargets(index, 'employee_min', e.target.value);
                    }}
                    InputLabelProps={{ focused: true }}
                  />
                </Grid>
                <Grid item sm={'auto'}>
                  <span>to</span>
                </Grid>
                <Grid item sm={5}>
                  <InputField
                    type='number'
                    value={item.employee_max || ''}
                    onChange={e => {
                      updateContactTargets(index, 'employee_max', e.target.value);
                    }}
                    InputLabelProps={{ focused: true }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} sm={2} alignItems={'flex-end'}>
                <InputField
                  value={item.ranking}
                  type='number'
                  placeholder='Weight (1-100)'
                  InputProps={{
                    inputProps: { min: 0, max: 100, pattern: '[0-9]*' }
                  }}
                  error={validateRankingValue(item.ranking)}
                  helperText={validateRankingValue(item.ranking) ? TARGET_INDUSTRY_RANKING.RANKING_VALIDATION_MESSAGE : ''}
                  onChange={e => {
                    updateContactTargets(index, 'ranking', parseInt(e.target.value));
                  }}
                  className='input-form-field'
                />
              </Grid>
              <Grid sm={1}>
                <DeleteIcon style={{ marginTop: '10px' }} onClick={() => deleteContactTargets(index)} className='cursor-pointer' />
              </Grid>
            </Grid>
          ))}

        <Button variant={'text'} startIcon={<AddCircleIcon />} onClick={addContactTarget} style={{ marginTop: '2%' }} color='primary'>
          Add Target Title
        </Button>
      </React.Fragment>
    </div>
  );
}

TargetDetails.propTypes = {
  register: PropTypes.func,
  watch: PropTypes.func,
  readOnly: PropTypes.bool,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  currencyDetails: PropTypes.object,
  unregister: PropTypes.func
};
