import { DeleteForever } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../components/common/InputField';
import Loader from '../../../../../components/common/Loader';
import { SFPADataApi, TargetIndustryDataApi, picklistDropDownApis } from '../../../../../services/ApiService';
import { DELETE, DROP_DOWN_PICKLIST, GET, POST } from '../../../../../services/constantService';
import useApi from '../../../../../utils/Hooks/useApiHook';
import { useLanguageHooks } from '../../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';
import { ProjectDataContext } from '../../Context';
import { AccordionHeader } from '../Scope';

const TargetIndustry = () => {
  const { projectData, handleUniversalState, showDeletedRows, refresh, setRefresh } = useContext(ProjectDataContext);
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  //API Integration
  const { data: targetIndustryOptionsResponse } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.INDUSTRIES_PICKLIST);
    }
  });

  const { t } = useLanguageHooks([
    TRANS_KEYS.ADD_PROJECT_LABEL_TARGET_INDUSTRIES,
    TRANS_KEYS.ADD_PROJECT_BUTTON_ADD_INDUSTRY,
    TRANS_KEYS.ADD_PROJECT_LABEL_WEIGHT,
    TRANS_KEYS.ADD_PROJECT_LABEL_SUB_INDUSTRY
  ]);

  const [recommendedIndustries, setRecommendedIndustries] = useState(projectData?.targetIndustries || []);

  const handleUniversalStateLocally = updatedVal => {
    const event = {
      target: {
        name: 'targetIndustries',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };

  const handleTargetIndustryChange = (key, value, index) => {
    recommendedIndustries.filter(data => (showDeletedRows ? data?.deleted_at == null || data?.deleted_at != null : data?.deleted_at == null))[index][key] = value;
    const newTargetIndustries = recommendedIndustries.slice();
    setRecommendedIndustries(newTargetIndustries);
    handleUniversalStateLocally(newTargetIndustries);
  };

  const deleteTargetIndustry = async cardData => {
    try {
      setLoader(true);
      const projectId = projectData?.id;
      if (!cardData?.industry_id.includes('new') && !cardData?.industry_id.includes('bulk')) {
        await TargetIndustryDataApi(
          DELETE,
          '',
          {
            projectId: projectId,
            industryId: cardData?.industry?.id
          },
          'bulk-delete'
        );
      }

      const updatedVal = recommendedIndustries.filter(selectedTargetIndustry => selectedTargetIndustry.industry_id != cardData?.industry_id);
      const newValues = [...updatedVal.slice(), { ...cardData, deleted_at: new Date() }];
      setRecommendedIndustries(newValues);
      handleUniversalStateLocally(newValues);
      setLoader(false);
    } catch (err) {
      enqueueSnackbar(err, 'Target industry is not deleted');
    }
  };

  const addTargetIndustry = event => {
    let newIndustry = {};

    if (typeof event == 'object') {
      newIndustry = {
        industry: event,
        industry_id: 'new-' + event.id,
        weight: '0'
      };
    } else {
      newIndustry = {
        industry: {
          name: event,
          id: 'new-type-' + recommendedIndustries.length + 1
        },
        weight: '0',
        industry_id: 'new-type-' + recommendedIndustries.length + 1
      };
    }

    const updatedIndustries = [newIndustry, ...recommendedIndustries];
    setRecommendedIndustries(updatedIndustries);
    handleUniversalStateLocally(updatedIndustries);
  };

  const onClickShowMore = async () => {
    try {
      setLoader(true);
      const companyName = projectData?.ign_companies?.name;
      const ign_industry = {
        name: projectData?.ign_industries?.name
      };
      const fetchedIndustry = recommendedIndustries?.map(industry => {
        return {
          isSelected: true,
          isNew: false,
          name: industry?.industry?.name
        };
      });
      const payload = {
        template_name: 'SFPA-Industry-Search',
        mode: 'more',
        variables: {
          searchCompany: companyName,
          searchIndustry: [ign_industry],
          fetchedIndustryList: fetchedIndustry,
          key: 'fetchedIndustryList'
        }
      };

      const res = await SFPADataApi(POST, projectData?.id, payload);
      if (res && res?.data && res?.data?.target_industries) {
        const allData = res?.data?.target_industries ?? [];
        const newData = allData?.filter(data => data?.isNew == true);

        const targetIndustries = newData.map((data, index) => {
          return {
            industry: {
              name: data?.name,
              industry_id: 'bulk-' + index
            },
            weight: '0',
            industry_id: 'bulk-' + index,
            deleted_at: null
          };
        });
        const newTargetIndustries = [...targetIndustries, ...recommendedIndustries];
        setRecommendedIndustries(newTargetIndustries);
        handleUniversalStateLocally(newTargetIndustries);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      enqueueSnackbar(err, 'error');
    }
  };

  const addBackSoftDelete = async cardData => {
    setLoader(true);
    const payload = {
      industryId: cardData?.industry_id,
      projectId: projectData?.id,
      newValues: {
        weight: +cardData?.weight,
        deleted_at: null
      }
    };

    if (!cardData?.industry_id.includes('new') && !cardData?.industry_id.includes('bulk')) {
      await TargetIndustryDataApi(POST, '', payload, 'bulk-restore');
    }
    const updatedVal = recommendedIndustries.filter(selectedTargetIndustry => selectedTargetIndustry.industry_id != cardData?.industry_id);
    const newValues = [...updatedVal.slice(), { ...cardData, deleted_at: null }];
    setRecommendedIndustries(newValues);
    handleUniversalStateLocally(newValues);
    setLoader(false);
  };
  useEffect(() => {
    if (!isDataLoaded) {
      setRecommendedIndustries(projectData?.targetIndustries ?? []);
      if (projectData?.targetIndustries) {
        setIsDataLoaded(true);
      }
    }
    if (refresh) {
      setRecommendedIndustries(projectData?.targetIndustries ?? []);
      setRefresh(false);
    }
  }, [projectData]);
  return (
    <Box>
      <Loader show={loader} />
      <AccordionHeader
        onSearch={addTargetIndustry}
        searchOptions={targetIndustryOptionsResponse?.data ?? []}
        searchLabel={t(`${TRANS_KEYS.ADD_PROJECT_BUTTON_ADD_INDUSTRY}:key`)}
        isDropDown={false}
        onClickGetMore={() => onClickShowMore()}
      />
      <Stack
        direction='column'
        sx={{
          maxHeight: '40vh',
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}
      >
        {recommendedIndustries
          .filter(data => (showDeletedRows ? data?.deleted_at == null || data?.deleted_at != null : data?.deleted_at == null))
          .map((cardData, index) => (
            <Box
              key={index}
              sx={{
                width: '93%',
                backgroundColor: 'background.main',
                color: 'titleBarBackground.main',
                position: 'relative'
              }}
              className='pl-2 pt-3 pb-3 pr-2 rounded border m-1'
            >
              {cardData?.deleted_at == null ? (
                <DeleteForever
                  sx={{
                    color: 'error.main',
                    top: '37%',
                    left: '103%',
                    position: 'absolute',
                    cursor: 'pointer'
                  }}
                  fontSize='small'
                  onClick={() => deleteTargetIndustry(cardData)}
                />
              ) : (
                <DoneIcon
                  sx={{
                    color: 'primary.main',
                    top: '37%',
                    left: '103%',
                    position: 'absolute',
                    cursor: 'pointer'
                  }}
                  fontSize='small'
                  onClick={() => addBackSoftDelete(cardData)}
                />
              )}
              <Grid container fullWidth spacing={3} alignItems={'center'}>
                <Grid item xs={9}>
                  {cardData?.deleted_at == null ? (
                    <p className='fs-14' style={{ color: 'black' }}>
                      {cardData?.industry?.name}
                    </p>
                  ) : (
                    <p className='fs-14' style={{ color: 'black', textDecoration: 'line-through' }}>
                      {cardData?.industry?.name}
                    </p>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <InputField
                    label={t(`${TRANS_KEYS.ADD_PROJECT_LABEL_WEIGHT}:key`)}
                    fullWidth
                    value={cardData?.weight}
                    onChange={event => {
                      handleTargetIndustryChange('weight', event.target.value, index);
                    }}
                    size='small'
                    disabled={cardData?.deleted_at != null}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
      </Stack>
    </Box>
  );
};

export default TargetIndustry;
