//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import CustomPopup from '../common/CustomPopup';
import ViewBdPopup from './ViewBdPopup';
import './index.scss';
import { Box } from '@mui/material';
import EditBdPopup from './EditBdPopup';
import { useSelector } from 'react-redux';
import { ERROR, FEE_TYPES, GET } from '../../services/constantService';
import { BDDataApi } from '../../services/ApiService';
import { enqueueSnackbar } from 'notistack';
import { unableMessage } from '../../services/MessageService';
import { useEffect } from 'react';
// const defaultValues = {
//   "id": "0caee15e-f100-4467-85a6-ec75eb147140",
//   job_number: 47950,
//   job_title: "TESTING 0007",
//   type: "Retained",
//   stage: "BD",
//   stage_desc: "BD",
//   result: null,
//   is_sfpa_completed: false,
//   is_nda: false,
//   is_confidential: false,
//   industries: [
//     "Distribution - Supply Chain/Logisitcs"
//   ],
//   job_functions: [
//     "Consultant"
//   ],
//   billing_address: null,
//   billable: false,
//   zip_code: null,
//   next_status_call: null,
//   capital_structure: null,
//   vc_pe: [],
//   start_date: null,
//   close_date: null,
//   on_hold_date: null,
//   off_hold_date: null,
//   loe_date: null,
//   currency: "USD",
//   estimated_total_comp: null,
//   fee_type: "STD",
//   billing_region: "Asia",
//   milestones: null,
//   new_client: null,
//   fee_amount: null,
//   estimated_fee_amount: null,
//   fee_percentage: 33.33,
//   is_fee_cap: false,
//   fee_cap_amount: null,
//   billing_notes: "dddddddddddddddddddddddddddddddd",
//   admin_fee: null,
//   frequency: null,
//   duration: null,
//   by_product_fee: null,
//   is_po_direct: null,
//   po_number: null,
//   invoice_delivery_type: null,
//   send_notification: null,
//   projected_fee: null,
//   projected_start_date: "2023-02-09",
//   probability: "25%",
//   bd_status: null,
//   competition: "",
//   decision: "",
//   work_group: null,
//   target_industries: null,
//   target_locations: null,
//   target_base_max: null,
//   target_base_min: null,
//   target_bonus: null,
//   target_equity: null,
//   years_of_experience: null,
//   is_years_of_experience_mandatory: null,
//   degree_name: null,
//   is_degree_mandatory: null,
//   max_years_of_experience_current_company: null,
//   certifications: null,
//   off_limits: null,
//   partner_brief: null,
//   target_companies: null,
//   success_probability: null,
//   is_executive_search_arena: false,
//   benchmark_feedback: null,
//   summary_feedback: null,
//   executive_feedback: null,
//   target_job_functions: null,
//   city: "",
//   state: null,
//   country: "United States of America (USA)",
//   encore_number: "",
//   position_id: null,
//   workspace_id: null,
//   created_at: "2023-05-04T09:03:27.145Z",
//   updated_at: "2024-03-07T07:37:08.778Z",
//   deleted_at: null,
//   products_services_overview: null,
//   position_overview: null,
//   professional_experience: null,
//   is_approved_by_partner: null,
//   target_capital_structure: null,
//   vertical_practice: null,
//   region: null,
//   horizontal_practice: null,
//   product_one: true,
//   brand: "Advisory",
//   target_states: null,
//   actual_total_comp: null,
//   term_type: "Milestone",
//   intervals: null,
//   intervals_other: null,
//   vendor_portal: null,
//   billing_status: null,
//   estimated_percentage_base: 0,
//   actual_percentage_base: null,
//   estimated_indirect_total_fee: 0,
//   actual_indirect_total_fee: null,
//   estimated_revenue: 0,
//   actual_revenue: null,
//   actual_overridden_revenue: 0,
//   total_invoice_amt: 2100,
//   total_collected_amt: 300,
//   collected_percentage: 0,
//   is_invoice_approved: null,
//   email_invoice_to: null,
//   synclink_url: "https://partner-dev.kgp-synclink.com/deck/1060",
//   market: "27f79a34-b992-4457-b6e9-5633ef83a731",
//   helia_version: "Athena Questions Motive Pairs",
//   approval_requested_date: null,
//   invoice_terms: null,
//   total_invoice_expense: 0,
//   bd_reject_reason: null,
//   revenue_changed: false,
//   helia_title: null,
//   approval_requested_by: null,
//   billing_contact_id: null,
//   company: {
//     id: "d7b24a7e-a567-4471-bf67-4e4828de2c47",
//     name: "test",
//     country: "Nepal"
//   },
//   partners: [
//     {
//       id: "4ff02f91-5d40-443c-8df3-7b0a6ba03530",
//       origination_credit: 60,
//       selling_credit: 10,
//       execution_credit: 5,
//       position: 0,
//       user: {
//         name: "Ten'D souja 10",
//         id: "8a0c11a2-ffce-44e6-b0ba-d73f50dc9e97",
//         first_name: "Ten'D souja",
//         last_name: "10",
//         email: "ten@mailinator.com"
//       }
//     },
//     {
//       id: "0bdad696-24ad-40e8-a41b-0330f7025a91",
//       origination_credit: 2,
//       selling_credit: 2,
//       execution_credit: 2,
//       position: 1,
//       user: {
//         name: "Pratik Patel For D2, PhD",
//         id: "6f080cd2-c65b-48a8-a6f2-014541d3626d",
//         first_name: "Pratik",
//         last_name: "Patel For D2, PhD",
//         email: "pratik.patel@aspiresoftware.in"

//       }
//     },
//   ],
//   recruiters: [
//     {
//       id: "ea95ee3f-72cd-4df0-a7f1-e6dd79360c0e",
//       execution_credit: null,
//       position: null,
//       user: {
//         name: "chirag 12 kamani12",
//         id: "26b58528-4a5e-40d3-8282-80c69f898251",
//         first_name: "chirag 12",
//         last_name: "kamani12",
//         email: "chirag.kamani+12@aspiresoftserv.com"
//       }
//     }
//   ],
//   researchers: [
//     {
//       id: "f6b52e88-55c8-4275-bfc0-bf925a2eed85",
//       execution_credit: null,
//       position: null,
//       user: {
//         name: "Bhavesh1 prajapati1",
//         id: "2cf12c40-17ff-4b69-92c4-1479311ede7b",
//         first_name: "Bhavesh1",
//         last_name: "prajapati1",
//         email: "bhavesh.prajapati+1@aspiresoftserv.com"
//       }
//     }
//   ],
//   eas: [
//     {
//       id: "d5e1b9d2-8105-42db-bff7-b2f7f51d905a",
//       execution_credit: null,
//       position: null,
//       user: {
//         name: "Binal TestBD",
//         id: "5907a186-a59e-4bd6-ad75-4de573bd8f37",
//         first_name: "Binal",
//         last_name: "TestBD",
//         email: "BD@mailinator.com"
//       }
//     }
//   ],

//   client_team: [],
//   indirect_fees_searches: [
//     {
//       id: "4b0f16f6-064e-491b-b3f3-783a8b1d48a9",
//       search_id: "0caee15e-f100-4467-85a6-ec75eb147140",
//       type: null,
//       calculation_type: null,
//       percentage: null,
//       calculation_amt: 0,
//       created_at: "2023-05-04T0:03:45.374Z",
//       updated_at: "2023-05-04T09:03:45.381Z"
//     }
//   ],
//   estimated_percentage_bases: [],
//   markets: {
//     id: "27f79a34-b992-4457-b6e9-5633ef83a731",
//     org_id: "KGP",
//     market: "France",
//     threshold: 40000,
//     recommended_percent: 33,
//     admin_percent_min: 10,
//     admin_percent_max: 18,
//     admin_fee_limit: 0,
//     regional_head_role_name: "RegionHead-EUROPE",
//     updated_at: "2023-04-27T1:09:44.182Z",
//     created_at: "2023-04-27T10:09:44.182Z"
//   },
// }
// const bd = {
//   id: "a19ee57b-0d00-4e37-ac70-f538ec5e616f"
// }
const ViewBD = props => {
  const { open, onClose, bd, companyId } = props;
  const [defaultValues, setDefaultValues] = useState({});
  const [tabValueNumber, setTabValueNumber] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const currencyCodeDetails = useSelector(state => (state.commonReducer[defaultValues?.currency] ? state.commonReducer[defaultValues?.currency] : {}));
  const editForm = ele => {
    setIsEditing(ele);
  };
  const onPopupClose = () => {
    onClose();
  };
  const bdbyId = async () => {
    try {
      if (bd.id) {
        setLoading(true);
        const { status, data } = await BDDataApi(GET, bd.id);
        if (status === 200) {
          let feeObj = {};
          if (data.fee_type_desc === FEE_TYPES.ONE_THIRD) {
            feeObj = { fee_type_desc: FEE_TYPES.ONE_THIRD, fee_percentage: 33.33 };
          }
          setDefaultValues({
            ...data,
            intervals_desc: data.intervals_desc ? data.intervals_desc : data.intervals,
            project_indirect_fees: data.project_indirect_fees?.length ? data.project_indirect_fees : [{}],
            estimated_percentage_bases: data.estimated_percentage_bases?.length ? data.estimated_percentage_bases : [{}],
            recruiters: data.recruiters?.length ? data.recruiters : [{}],
            researchers: data.researchers?.length ? data.researchers : [{}],
            basic_partners: data.partners ? data.partners.map(partner => partner.user)?.filter(user => user) : [],
            client_team: data.client_team ? data.client_team.map(client_team => client_team?.contact)?.filter(contact => contact) : [],
            eas: data.eas?.length ? data.eas : [{}],
            partners: data.partners ? data.partners : [],
            billing_desc: data.billing_desc ? data.billing_desc : '',
            bd_status: data.bd_status ? data.bd_status : 'Target Identified',
            ...feeObj
          });
        } else {
          const message = unableMessage('BD Details', 'fetch');
          enqueueSnackbar(data?.message || message, { variant: ERROR });
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    bdbyId();
  }, [bd]);
  return (
    <Box id='view-bd-container'>
      <CustomPopup
        title={`${isEditing ? 'Update' : 'View'} Business Development`}
        open={open}
        onClose={onPopupClose}
        showAction={false}
        overFlowClass='overflow-hidden'
        className='bd-view-popup'
        loading={loading}
        popupHeight='80vh'
      >
        {isEditing ? (
          <EditBdPopup
            editForm={editForm}
            setDefaultValues={setDefaultValues}
            bd={defaultValues}
            tabValueNumber={tabValueNumber}
            setTabValueNumber={setTabValueNumber}
            closePopup={onPopupClose}
            isEditing={isEditing}
            setLoading={setLoading}
            companyId={companyId}
          />
        ) : (
          <ViewBdPopup
            key={defaultValues}
            isBdInViewMode={true}
            tabValueNumber={tabValueNumber}
            setTabValueNumber={setTabValueNumber}
            defaultValues={defaultValues}
            editForm={editForm}
            currencyDetails={currencyCodeDetails}
          />
        )}
      </CustomPopup>
    </Box>
  );
};
ViewBD.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes,
  bd: PropTypes.object,
  companyId: PropTypes.string
};

export default ViewBD;
