import { Box } from '@mui/material';
import { random } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { picklistsDataApis } from '../../../../../services/ApiService';
import { DROP_DOWN_PICKLIST, GET } from '../../../../../services/constantService';
import useApi from '../../../../../utils/Hooks/useApiHook';
import { useLanguageHooks } from '../../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';
import { ProjectDataContext } from '../../Context';
import { AccordionHeader } from '../Scope';

export default function TargetCapitalStructure() {
  const { projectData, handleUniversalState } = useContext(ProjectDataContext);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [recommendedTargetCapitalStructure, setRecommendedTargetCapitalStructure] = useState([]);

  //Translate
  const { t } = useLanguageHooks([TRANS_KEYS.ADD_CAPITAL_STRUCTURE]);

  //Integration
  const { data: capitalStructureResponse } = useApi({
    queryFn: () => {
      // TODO
      return picklistsDataApis(GET, DROP_DOWN_PICKLIST.CAPITAL_STRUCTURE);
    }
  });

  const handleUniversalStateLocally = updatedVal => {
    const event = {
      target: {
        name: 'targetCapital',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };

  const deleteCapitalStructure = async cardData => {
    try {
      const updatedCapitalStructures = recommendedTargetCapitalStructure.filter(curr => curr.id != cardData.id);
      setRecommendedTargetCapitalStructure(updatedCapitalStructures);
      handleUniversalStateLocally([{ capital_structure: updatedCapitalStructures }]);
    } catch (err) {
      console.log('target-capital-structure-err', err);
    }
  };

  const addCapitalStructure = value => {
    let newCapitalStructure = {};
    console.log('target-capital-structure-add', value, typeof value == 'object');
    if (value && typeof value == 'object') {
      newCapitalStructure = {
        id: 'new-' + random(1000),
        name: value.short_desc
      };
    } else {
      newCapitalStructure = {
        id: 'new-type' + random(1000),
        name: value
      };
    }

    setRecommendedTargetCapitalStructure([...recommendedTargetCapitalStructure, newCapitalStructure]);
    handleUniversalStateLocally([{ capital_structure: [...recommendedTargetCapitalStructure, newCapitalStructure], old: true }]);
  };

  useEffect(() => {
    console.log({ targetCapitalProject: projectData?.targetCapital });
    if (projectData?.targetCapital && recommendedTargetCapitalStructure.length == 0 && !isDataLoaded && projectData?.targetCapital.length > 0) {
      console.log({ targetCapitalProjectFirstIf: 'in first condition', val: projectData?.targetCapital[0]?.capital_structure });
      if (projectData?.targetCapital[0] && projectData?.targetCapital[0]?.capital_structure) {
        const data = projectData?.targetCapital[0]?.capital_structure;
        const targetCapital = data?.map((capital, index) => {
          return {
            name: capital,
            id: index
          };
        });
        console.log({ targetCapital });
        setRecommendedTargetCapitalStructure(targetCapital);
      }

      if (projectData?.targetCapital[0]) {
        setIsDataLoaded(true);
        handleUniversalState({
          target: {
            name: 'targetCapitalId',
            value: projectData?.targetCapital[0]?.id
          }
        });
      }
    }
  }, [projectData]);
  return (
    <Box>
      <AccordionHeader
        onSearch={value => addCapitalStructure(value)}
        searchOptions={capitalStructureResponse?.data || []}
        searchLabel={t(`${TRANS_KEYS.ADD_CAPITAL_STRUCTURE}:key`)}
        isDropDown={false}
        isGetMore={false}
        recommendedOptions={recommendedTargetCapitalStructure}
        onClickRecommendOption={deleteCapitalStructure}
      />
    </Box>
  );
}
