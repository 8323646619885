//-----------------------------------------------------------// In-built Imports // ------------------------------
import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { CircularProgress } from '@mui/material';

//-----------------------------------------------------------// Internal Imports // ------------------------------
import { getFilterQuery, getList, getMultiValueParam, getSingleValueParam, DateAndTimeRenderer } from '../Commons/Utils';
import { SUCCESS, ERROR } from '../../services/constantService';
import { unableMessage, successMessage } from '../../services/MessageService';

export const PAGE_LIMIT = 200;
export const SUCCESS_STATUS_CODE = 200;

const getRequestedBy = params => {
  if (params.value) {
    const name = `${params.value.first_name} ${params.value.last_name}`;
    return name;
  }
  return '';
};

export const columnDefs = () => {
  const columns = [
    {
      field: 'job_number',
      colId: 'job_number',
      headerName: 'Search#',
      minWidth: 250,
      maxWidth: 250,
      sortable: false,
      suppressSizeToFit: true,
      cellRenderer: 'IdRenderer',
      tooltipField: 'job_number'
    },
    {
      field: 'job_title',
      colId: 'job_title',
      headerName: 'Search Title',
      minWidth: 250,
      maxWidth: 200,
      sortable: false,
      tooltipField: 'job_title',
      cellRenderer: 'TitleRenderer'
    },
    {
      field: 'approval_requested_date',
      colId: 'approval_requested_date',
      headerName: 'Request Date',
      cellRenderer: 'DateTimeRenderer',
      sortable: false,
      tooltipValueGetter: DateAndTimeRenderer,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: 'approve_request_by',
      colId: 'approve_request_by',
      headerName: 'Requested By',
      tooltipValueGetter: getRequestedBy,
      cellRenderer: getRequestedBy,
      minWidth: 250,
      maxWidth: 250,
      sortable: false
    },
    {
      colId: 'billing_notes',
      field: 'billing_notes',
      headerName: 'Billing Notes',
      minWidth: 250,
      maxWidth: 550,
      width: 550,
      sortable: false,
      cellRenderer: 'RichTextPopupRendered',
      cellRendererParams: {
        placement: 'left'
      }
    }
  ];
  return columns;
};

export const CustomLoadingOverlayComponent = () => {
  return <CircularProgress />;
};

export const defaultColumns = ['job_title', 'company', 'freeze_dropdown'];

export const getQueryParamsString = data => {
  //const jobTitle = getMultiValueParam(getList(data['title'], 'job_title'), 'title');
  const jobTitle = getSingleValueParam(/* getList(data['title'], 'job_title'), 'title' */ data, 'title');
  const revenueFrom = getSingleValueParam(data, 'revenue_from');
  const toRevenue = getSingleValueParam(data, 'revenue_to');
  const estimatedTotalCompensation = getSingleValueParam(data, 'estimatedTotalCompensation');
  const activePartners = getSingleValueParam(data, 'activePartners');
  const fromStart = getSingleValueParam(data, 'start_date_from');
  const toStart = getSingleValueParam(data, 'start_date_to');
  const fromClose = getSingleValueParam(data, 'close_date_from');
  const toClose = getSingleValueParam(data, 'close_date_to');
  const vertical_practice = getSingleValueParam(data, 'vertical_practice');
  const horizontal_practice = getSingleValueParam(data, 'horizontal_practice');
  const region = getSingleValueParam(data, 'region');
  const company = getMultiValueParam(getList(data['company'], 'id'), 'company');
  const industries = getMultiValueParam(getList(data['industries'], 'name'), 'industries');
  const jobFunctions = getMultiValueParam(getList(data['job_functions'], 'name'), 'job_functions');
  const kgpTeamMember = getMultiValueParam(getList(data['kgpTeamMember'], 'id'), 'kgpTeamMember');
  const location = getMultiValueParam(getList(data['location'], 'name'), 'country');
  const stage = getMultiValueParam(getList(data['stage'], 'name'), 'stage');
  const type = getMultiValueParam(getList(data['type'], 'name'), 'type');
  const vc_pe = getMultiValueParam(getList(data['vc_pe'], 'name'), 'vc_pe');
  const result = getMultiValueParam(getList(data['result'], 'name'), 'result');
  const capital_structure = getMultiValueParam(getList(data['capital_structure'], 'name'), 'capital_structure');
  const billable = getSingleValueParam(data, 'billable');
  return (
    jobTitle +
    revenueFrom +
    toRevenue +
    estimatedTotalCompensation +
    activePartners +
    fromStart +
    toStart +
    fromClose +
    toClose +
    company +
    industries +
    jobFunctions +
    kgpTeamMember +
    location +
    stage +
    type +
    vc_pe +
    result +
    vertical_practice +
    region +
    horizontal_practice +
    capital_structure +
    billable
  );
};

export const getFilterParamString = (data, prefix, AdvancedQuery = false) => {
  const queryAliases = {
    job_title: 'title' /* 'job_title' */,
    job_number: /* 'job_number' */ 'jobNumber'
  };
  const queryValueTypes = {
    job_functions: { field: 'name' },
    capital_structure: { field: 'name' },
    country: { field: 'name' },
    vertical_practice: { field: 'name' },
    horizontal_practice: { field: 'name' },
    industries: { field: 'name' },
    stage: { field: 'name' },
    work_group: { field: 'name' },
    result: { field: 'name' },
    region: { field: 'name' },
    vc_pe: { field: 'name' }
  };
  return getFilterQuery(data, queryAliases, queryValueTypes, prefix, AdvancedQuery);
};

export const getSelectedSearchIds = gridApi => {
  const searchIds = [];
  gridApi.forEachNode(node => {
    if (node.selected) {
      searchIds.push(node.data.id); // Only for contact grids
    }
  });
  return searchIds;
};

export const getFilterParamStringForApprovalSearch = data => {
  return getFilterQuery(data, {}, {}, '');
};

export const processAddSearchesResponse = (status, data, enqueueSnackbar) => {
  if (status === 200) {
    const message = successMessage('Searches added to', 'workbench');
    enqueueSnackbar(message, { variant: SUCCESS });
    return true;
  } else {
    const message = unableMessage('workbench', 'add search(s) to');
    enqueueSnackbar(data?.message || message, { variant: ERROR });
  }
  return false;
};
