import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdown from '../../CustomDropdown';
import { fetchPickLists } from '../../../../actions';

const CompanyEmailTypeSelection = props => {
  const { value, name = '', label = '', onChange = () => {}, disabled = false, isDrawer = false, selectedList, setEmailList = () => {}, required = false, ...rest } = props;
  const [options, setOptions] = useState([]);
  const companyEmailTypeOption = useSelector(state => state.commonReducer.companyEmailType);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!companyEmailTypeOption) {
      dispatch(fetchPickLists('COMPANY_EMAIL_TYPE', 'companyEmailType'));
    } else {
      if (companyEmailTypeOption) {
        setEmailList(companyEmailTypeOption);
        const filterData = companyEmailTypeOption?.filter(item =>
          selectedList?.every(listItem => (listItem?.email_type?.field_value ? listItem?.email_type?.field_value : listItem?.email_type) !== item?.field_value)
        );
        setOptions(filterData);
      } else {
        setOptions([]);
      }
    }
  }, [companyEmailTypeOption, dispatch, selectedList]);

  return (
    <div>
      <CustomDropdown {...rest} value={value} options={options} name={name} onChange={onChange} isDrawer={isDrawer} disabled={disabled} label={label} required={required} />
    </div>
  );
};
CompanyEmailTypeSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isDrawer: PropTypes.bool,
  onChange: PropTypes.func,
  selectedList: PropTypes.array,
  label: PropTypes.string,
  setEmailList: PropTypes.func,
  required: PropTypes.bool
};

export default CompanyEmailTypeSelection;
