import propTypes from 'prop-types';
import React from 'react';
import style from '../../Home.module.scss';
import { Box, Button, Stack, Tab, Tabs, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InterViewTable from './InterviewTable';
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
function Popup(props) {
  const { openInterview, handleClose, allInterview } = props;
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Stack className={`${style.interview_modal} ${!openInterview && style.none}`}>
      {openInterview && (
        <Stack className={style.interview_modal_content}>
          <Button className={style.close_icon} onClick={handleClose}>
            <CloseIcon />
          </Button>
          <Box className={style.border_bottom}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
              color={theme.palette.heading.main}
              TabIndicatorProps={{
                style: {
                  backgroundColor: theme.palette.heading.main
                }
              }}
            >
              <Tab label='Interview Schedule' {...a11yProps(0)} style={{ color: theme.palette.heading.main }} />
            </Tabs>
          </Box>

          <InterViewTable data={allInterview} />
        </Stack>
      )}
    </Stack>
  );
}
Popup.propTypes = {
  openInterview: propTypes.bool,
  handleClose: propTypes.func,
  allInterview: propTypes.array
};
export default Popup;
