//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import ConfirmationPopup from '../../components/ConfirmationPopup';
import Loader from '../../components/common/Loader';
import { deletePopupApi, releaseLockApi } from '../../services/ApiService';
import { successMessage } from '../../services/MessageService';
import { SUCCESS, ERROR } from '../../services/constantService';

export default function DeletePopup(props) {
  const { onClose, id, module = 'companies', updateParent, popupText = 'Are you sure you want to delete?', payload = {}, search = {}, setInitialLoader } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    if (setInitialLoader) {
      setInitialLoader(true);
    } else {
      setLoading(true);
    }
    const url = `/api/${module}/${id}`;
    const { status, data } = await deletePopupApi(url, payload);
    setLoading(false);
    if (status === 200) {
      let message = successMessage('Company', 'deleted');
      if (module === 'contacts') {
        if (payload?.failed) {
          message = successMessage('Record', 'deleted');
        } else {
          message = successMessage('Contact', 'deleted');
        }
      } else if (module === 'searches') {
        message = successMessage(`${search.stage === 'BD' ? 'BD Search' : 'Search'}`, 'deleted');
      } else if (module === 'candidates') {
        message = successMessage('Candidate', 'deleted');
      } else if (module === 'translate-product') {
        message = successMessage('Translate product', 'deleted');
      }
      setInitialLoader && setInitialLoader(false);
      updateParent && updateParent(false);
      enqueueSnackbar(message, { variant: SUCCESS });
      onClose(true);
    } else {
      await releaseLockApi(id);
      updateParent && updateParent(false);
      enqueueSnackbar(data?.message, { variant: ERROR });
      onClose();
    }
  };
  return (
    <div>
      <Loader show={loading} />
      <ConfirmationPopup message={popupText} onClose={onClose} onConfirm={handleDelete} />
    </div>
  );
}

DeletePopup.propTypes = {
  onClose: PropTypes.func,
  updateParent: PropTypes.func,
  id: PropTypes.string,
  module: PropTypes.string,
  popupText: PropTypes.string,
  payload: PropTypes.object,
  search: PropTypes.object,
  setInitialLoader: PropTypes.func
};
