import aws4fetch, { dynamicUrl } from './publicAxios';

const publicHttpService = {
  get: async (url, params, customHeaders = {}, responseType = 'json', cancelToken) => {
    const res = await aws4fetch.fetch(dynamicUrl + url, { method: 'GET' });
    return res.json();
  },
  post: async (url, data, responseType = 'json', options) => {
    // const payload = responseType == 'json' ? JSON.stringify(data) : data;
    const res = await aws4fetch.fetch(dynamicUrl + url, { method: 'POST', headers: { ...options }, body: JSON.stringify(data) });
    return res.json();
  }
};

export default publicHttpService;
