//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { GET } from '../../services/constantService';
import { picklistsCountryStateDataApi } from '../../services/ApiService';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_STATE_LIST } from '../../types';

export default function StateSelection(props) {
  const { country, className, InputLabelProps = { focused: true }, placeholder = 'State', label = 'State', disabled = false, variant = 'standard', ...rest } = props;
  const [options, setOptions] = useState([]);
  const usState = useSelector(state => state.usState);
  const filter = createFilterOptions();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchStates = async () => {
      if (country) {
        if (!usState) {
          const sub_route = `?countryName=${country}`;
          const { status, data } = await picklistsCountryStateDataApi(GET, sub_route);
          if (status === 200) {
            if (data && data?.length) {
              const sortAscState = (a, b) => (a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : -1);
              data.sort(sortAscState);
            }
            dispatch({ type: UPDATE_STATE_LIST, payload: { key: 'usState', data } });
            let array = [];
            data.map(data => array.push({ title: data.name }));
            setOptions(array);
          }
        } else {
          let array = [];
          usState.map(data => array.push({ title: data.name }));
          setOptions(array);
        }
      } else {
        setOptions([]);
      }
    };
    fetchStates().then(null);
  }, [country]);

  return (
    <Autocomplete
      {...rest}
      autoSelect
      className={className}
      options={options}
      key={country}
      freeSolo
      disabled={disabled}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        // Suggest the creation of a new value
        const isExisting = options.some(option => option.title === inputValue);
        if (inputValue !== '' && !isExisting && country) {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add item "${inputValue}"`
          });
        }
        return filtered;
      }}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      getOptionSelected={(option, value) => {
        if (!value) {
          return false;
        }
        if (typeof value === 'string') {
          return option.title === value;
        }
        return option.title === value.title;
      }}
      renderOption={(props, option) => (
        <>
          <li {...props}>{` ${option.title}`}</li>
        </>
      )}
      renderInput={params => <TextField {...params} name='state' InputLabelProps={InputLabelProps} label={label} placeholder={placeholder} variant={variant} />}
    />
  );
}

StateSelection.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  country: PropTypes.string,
  className: PropTypes.string,
  register: PropTypes.func,
  InputLabelProps: PropTypes.object,
  disabled: PropTypes.bool,
  variant: PropTypes.string
};
