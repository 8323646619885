import { enqueueSnackbar } from 'notistack';

export const requiredFields = {
  setup_name: 'Setup name is required.',
  to_stage_code: 'To stage code is required.',
  actions: 'At least one action is required.'
};

export const actionSpecificFields = {
  EMAIL: [
    { field: 'recipients_or_url', message: 'Recipients is required for EMAIL actions.' },
    { field: 'template_id', message: 'Template is required for EMAIL actions.' }
  ],
  WEBHOOK: [
    { field: 'recipients_or_url', message: 'URL is required for WEBHOOK actions.' },
    { field: 'payload', message: 'Payload is required for WEBHOOK actions.' }
  ]
};

export const validateFormData = data => {
  for (const field in requiredFields) {
    if (!data[field] || (field === 'actions' && data[field].length === 0)) {
      return { isValid: false, errorMessage: requiredFields[field] };
    }
  }

  for (let action of data.actions) {
    if (!action.action_type) {
      return { isValid: false, errorMessage: 'Action type is required.' };
    }

    if (!action.recipient_type) {
      return { isValid: false, errorMessage: 'Recipient type is required.' };
    }

    const specificFields = actionSpecificFields[action.action_type];
    if (specificFields) {
      for (const { field, message } of specificFields) {
        if (action.recipient_type === 'CANDIDATE' && field === 'recipients_or_url') {
          continue;
        }
        if (!action[field]) {
          return { isValid: false, errorMessage: message };
        }
      }
    }
  }

  return { isValid: true, errorMessage: '' };
};

export const raiseErrorSnackbar = message => {
  enqueueSnackbar(message, {
    variant: 'error',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    preventDuplicate: true
  });
};
