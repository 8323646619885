//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';

// eslint-disable-next-line react/display-name
const ToggleSelection = forwardRef((props, ref) => {
  const initialValue = props.value === null ? null : props.value || false;
  const [selectedOption, setSelectedOption] = useState(initialValue);
  const { stopEditing } = props;

  useImperativeHandle(ref, () => {
    return {
      getValue: () => selectedOption,
      afterGuiAttached: () => {}
    };
  });

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react/prop-types
      props.api.refreshCells(props.column);
      stopEditing();
    };
  }, [stopEditing, props]);

  return (
    <Select style={{ width: '100%' }} value={selectedOption} onChange={e => setSelectedOption(e.target.value)}>
      <MenuItem value={true}>Yes</MenuItem>
      <MenuItem value={false}>No</MenuItem>
    </Select>
  );
});
ToggleSelection.propTypes = {
  value: PropTypes.bool,
  stopEditing: PropTypes.func
};

export default ToggleSelection;
