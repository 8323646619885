export const prepareDefaultValues = data => {
  if (Array.isArray(data.departments)) {
    data.departments = data.departments.map(item => {
      return { name: item };
    });
  }
  if (Array.isArray(data.secondary_departments)) {
    data.secondary_departments = data.secondary_departments.map(item => {
      return { name: item };
    });
  }
  if (Array.isArray(data.specialities)) {
    data.specialities = data.specialities.map(item => {
      return { name: item };
    });
  }
  if (data.country) {
    data.country = { name: data.country };
  }
  if (data.location) {
    data.location = { name: data.location };
  }
  if (data.website_tag) {
    data.website_tag = { name: data.website_tag };
  }
  if (data.time_zone) {
    data.time_zone = { name: data.time_zone };
  }
  if (data.work_group) {
    data.work_group = { name: data.work_group };
  }
  return data;
};

export const isAuthorizedUser = (selectedUser, currentUser, control) => {
  if (currentUser.roles.includes('admin') || currentUser.roles.includes('finance')) {
    return true;
  } else {
    if (selectedUser.id === currentUser.id && currentUser.roles.includes(control)) {
      return true;
    } else {
      return false;
    }
  }
};
