export default function calculateBoxCoordinates(input_values, box_axis_max) {
  let coordinate_x = 0;
  let coordinate_y = 0;
  const score_max = box_axis_max;

  const item_score_map = {
    0: 0,
    1: -2,
    2: -1,
    3: 0,
    4: 1,
    5: 2
  };

  const item_offset_map = {
    item_1: { x: 0, y: 1 },
    item_2: { x: 1, y: 0 },
    item_3: { x: 1, y: 0 },
    item_4: { x: 1, y: 0 },
    item_5: { x: 0, y: 1 },
    item_6: { x: 0, y: 1 },
    item_7: { x: 0, y: 1 }
  };

  const question_scale = 5;
  const questions_x = 2;
  const step_count_x = questions_x * ((question_scale - 1) / 2) * 3;
  const step_size_x = (score_max / step_count_x).toPrecision(2);

  const questions_y = 3;
  const step_count_y = questions_y * ((question_scale - 1) / 2) * 3;
  const step_size_y = (score_max / step_count_y).toPrecision(2);

  // Process factor math
  const item_keys = Object.keys(input_values);
  for (const item in item_keys) {
    if (item_keys[item] == 'item_1' || item_keys[item] == 'item_2') {
      coordinate_x += (item_offset_map[item_keys[item]]['x'] * item_score_map[input_values[item_keys[item]]] * score_max) / (question_scale - 1);
      coordinate_y += (item_offset_map[item_keys[item]]['y'] * item_score_map[input_values[item_keys[item]]] * score_max) / (question_scale - 1);
    } else {
      coordinate_x += item_offset_map[item_keys[item]]['x'] * item_score_map[input_values[item_keys[item]]] * step_size_x;
      coordinate_y += item_offset_map[item_keys[item]]['y'] * item_score_map[input_values[item_keys[item]]] * step_size_y;
    }
  }

  const coordinates = {
    x: coordinate_x,
    y: coordinate_y
  };

  return coordinates;
}
