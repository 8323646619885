//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect, useRef } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import axios from 'axios';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../common/InputField';
import { SEARCH_TIMEOUT } from '../../utils/common';
import { API, WARNING } from '../../services/constantService';
import { getMethodWithCancelTokenApi } from '../../services/ApiService';
import '../../Containers/Commons/AutoCompleteStyle/index.scss';
import '../../../src/index.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CancelToken = axios.CancelToken;
export default function SearchSelection(props) {
  const {
    label = 'Search',
    //placeholder = "Search",
    InputLabelProps,
    required,
    defaultValue = null,
    onChange,
    inputRef,
    // eslint-disable-next-line no-unused-vars
    closeSnackbar,
    contactId,
    contactName,
    stage,
    form = false,
    watch,
    searchResult,
    ...rest
  } = props;

  const [options, setOptions] = useState(['Not related to a search']);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  let call = useRef();
  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, setValue]);

  useEffect(() => {
    if (call.current) {
      call.current.cancel();
    }
    if (!contactId && name?.length < 1) {
      setOptions([]);
      setLoading(false);
      return;
    }
    call.current = CancelToken.source();
    setLoading(true);
    const timer = setTimeout(() => {
      let searchValue = name;
      const jobIdDelimiterIndex = searchValue.indexOf('- ');
      if (jobIdDelimiterIndex !== -1) {
        searchValue = searchValue.slice(jobIdDelimiterIndex + 2);
      }

      const companyNameDelimiterIndex = searchValue.lastIndexOf(' (');
      if (companyNameDelimiterIndex !== -1) {
        searchValue = searchValue.slice(0, companyNameDelimiterIndex);
      }
      getMethodWithCancelTokenApi(`${API.picklists_search}`, { searchValue, contactId, stage, searchResult }, {}, {}, call.current.token).then(response => {
        const { status, data } = response;
        let dataOption;
        if (data) dataOption = JSON.parse(data);
        if (status === 200) {
          if (dataOption.paging?.totalCount === 0) {
            const name = contactName ? `associated with  ${contactName}` : '';
            // Removed active word as we started fetching all searches on client's request
            //const active = contactName ? `Active` : '';
            const message = `No Searches found ${name}`;
            enqueueSnackbar(message, { variant: WARNING });
          }
          setOptions(dataOption?.data ? dataOption?.data : []);
        }
        setLoading(false);
      });
    }, SEARCH_TIMEOUT);
    return () => clearTimeout(timer);
  }, [name, contactId, stage, contactName, enqueueSnackbar]);

  return (
    <Autocomplete
      {...rest}
      className='AutoCompleteStyle animate-icon'
      value={form ? watch('search') || null : value}
      popupIcon={<ExpandMoreIcon />}
      options={options}
      getOptionLabel={option => {
        if (typeof option === 'string' && value !== null && typeof value !== 'object') {
          return value;
        } else if (typeof option === 'string' && value !== null && typeof value === 'object') {
          return 'Not related to a search';
        } else {
          if (typeof option === 'string') {
            return 'Not related to a search';
          } else {
            return option.job_number + ' - ' + option.job_title + ` (${option?.company?.name || '-'})`;
          }
        }
      }}
      onInputChange={(e, val, reason) => {
        if (reason !== 'reset') {
          setName(val);
        }
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={(event, newValue) => {
        if (newValue && newValue.inputValue) {
          setName(newValue.inputValue);
          return;
        }
        setValue(newValue);
        onChange(event, newValue);
      }}
      renderInput={params => (
        <InputField
          {...params}
          ref={inputRef}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='primary' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
          InputLabelProps={InputLabelProps}
          required={required}
          label={label}
          //placeholder={placeholder}
        />
      )}
    />
  );
}

SearchSelection.propTypes = {
  label: PropTypes.string,
  watch: PropTypes.func,
  form: PropTypes.bool,
  //placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  defaultValue: PropTypes.object,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  closeSnackbar: PropTypes.func,
  inputRef: PropTypes.object,
  contactId: PropTypes.string,
  contactName: PropTypes.string,
  stage: PropTypes.string,
  searchResult: PropTypes.string
};
