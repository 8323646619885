//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from 'react';
import * as yup from 'yup';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { getUserSelector } from '../../selectors';
import WorkbenchAddScreen from './Components/WorkbenchAddScreen';
import { cleanupWorkbenchData, getArchivedDate } from '../ViewWorkbench/utils';
import { POST, SUCCESS, ERROR } from '../../services/constantService';
import { workbenchDataApi } from '../../services/ApiService';
import { unableMessage, successMessage } from '../../services/MessageService';
import { customFormValidator, requireValidMessage } from '../../utils/common';
// import { workbenchActions } from '../../store/workbenchSlice';

export default function AddWorkbench(props) {
  const { onClose, buttonText = 'Create Workbench', DefaultValue, Disabled = false } = props;
  const [open, setOpen] = useState(true);
  const { register, handleSubmit, watch, setValue, control } = useForm({
    defaultValues: {
      archive_date: getArchivedDate(),
      type: DefaultValue
    }
  });
  const user = useSelector(getUserSelector);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
    onClose ? onClose() : navigate(-1);
  };

  const WorkbenchAddSchema = yup.object().shape({
    name: yup.string().required(),
    type: yup.string().required(),
    users: yup.array().of(yup.string()).required()
  });

  const handleCreate = async formData => {
    let tab;
    const isValid = await WorkbenchAddSchema.isValid(formData);
    if (!isValid) {
      let requiredField = [
        { fieldName: 'name', label: 'Workbench Name', type: String },
        { fieldName: 'type', label: 'Type', type: String },
        { fieldName: 'users', label: 'Users', type: [] }
      ];
      let dirtyField = customFormValidator(formData, requiredField);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    setLoading(true);
    formData = cleanupWorkbenchData(formData);
    const { status, data } = await workbenchDataApi(POST, '', formData);
    if (data?.type === 'Searches') {
      tab = 'searches';
    } else {
      tab = 'contacts';
    }
    if (status === 200) {
      const message = successMessage('Workbench', 'created');
      enqueueSnackbar(message, { variant: SUCCESS });
      // dispatch(workbenchActions.resetWorkbenchList())
      onClose ? onClose(data.id) : navigate(`/workbenches/${data.id}/${tab}`);
    } else {
      const message = unableMessage('workbench', 'create');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setLoading(false);
  };

  return (
    <WorkbenchAddScreen
      title='Add Workbench'
      handleSubmit={handleSubmit}
      onSubmit={handleCreate}
      onClose={handleClose}
      watch={watch}
      users={[user]}
      setValue={setValue}
      register={register}
      control={control}
      buttonText={buttonText}
      open={open}
      loading={loading}
      Disabled={Disabled}
      DefaultValue={DefaultValue}
    />
  );
}

AddWorkbench.propTypes = {
  onClose: PropTypes.func,
  buttonText: PropTypes.string,
  DefaultValue: PropTypes.string,
  Disabled: PropTypes.bool
};
