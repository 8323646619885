//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import Contacts from './Components/Contacts';
//import { SUCCESS_STATUS_CODE } from "../ViewContact/utils";
import { checkForUnlockWorkbench, checkWorkbenchAccess, cleanupWorkbenchData } from './utils';
import WorkbenchInfo from './Components/WorkbenchInfo';
import ConfirmationPopup from '../ConfirmationPopup';
import Searches from './Components/Searches';
import { GET, PUT, ERROR, SUCCESS } from '../../services/constantService';
import TabLayoutWithHeader from '../TabLayoutWithHeader';
import Loader from '../common/Loader';
import Header from './Components/Header';
import { fetchUserList } from '../../../src/actions';
import { workbenchDataApi } from '../../services/ApiService';
import { VALIDATION_MESSAGE, unableMessage, successMessage, notFoundMessage } from '../../services/MessageService';
import { SPECIAL_CHARACTERS_REPLACE_REGEX, REPLACE_SPACE_REGEX } from '../../services/constantService';
import './index.scss';
import { customFormValidator, requireValidMessage } from '../../utils/common';

export default function ViewWorkBench() {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [workbench, setWorkbench] = useState({});
  const [showConfirmBack, setShowConfirmBack] = useState(false);
  const [count, setTotalCount] = useState(0);
  const users = useSelector(state => state.rootReducer.users);
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [isLockedWorkbench, setLockedWorkbench] = useState(false);
  const [workbenchLockAccess, setWorkbenchLockAccess] = useState(false);
  const [activeCurrentTabIndex, setActiveCurrentTabIndex] = useState(-1);
  const { enqueueSnackbar } = useSnackbar();
  const { register, setValue, watch, control, reset, trigger, getValues, formState } = useForm();
  const { isDirty } = formState;
  let { id, currentTab } = useParams();
  const navigate = useNavigate();
  const userData = useSelector(state => state.commonReducer.userData);
  const [navItems, setNavItems] = useState(['WORKBENCH OVERVIEW', 'Workbench Information', 'Contacts']);
  const [newNavItems, setNewNavItems] = useState([]);
  useEffect(() => {
    const NAV_ITEMS = workbench.type === 'Searches' ? ['WORKBENCH OVERVIEW', 'Workbench Information', 'Searches'] : ['WORKBENCH OVERVIEW', 'Workbench Information', 'Contacts'];
    const newNavItems = NAV_ITEMS.map(item => {
      if (item === 'Contacts') {
        item = 'workbench contact';
      }
      item = item.replace(SPECIAL_CHARACTERS_REPLACE_REGEX, 'and');

      return item.toLowerCase().replace(REPLACE_SPACE_REGEX, '-');
    });
    setNavItems(NAV_ITEMS);
    setNewNavItems(newNavItems);
  }, [workbench.type]);

  useEffect(() => {
    let currentTabIndex = newNavItems.findIndex(item => item === currentTab);
    currentTabIndex = currentTabIndex === -1 ? navigate(`/workbenches/${id}/${newNavItems[2]}`) : currentTabIndex;
    setActiveCurrentTabIndex(currentTabIndex);
  }, [currentTab, newNavItems]);
  useEffect(() => {
    if (!users) {
      dispatch(fetchUserList('users'));
    } else {
      setOptions(users);
    }
  }, [users, dispatch]);

  const getWorkbench = useCallback(
    async id => {
      setIsLoading(true);
      const { status, data } = await workbenchDataApi(GET, id);
      if (status === 200 && data !== null) {
        setWorkbench(data);
      } else if (status === 200) {
        const message = notFoundMessage('workbech details');
        enqueueSnackbar(message, { variant: ERROR });
      } else {
        const message = unableMessage('workbench details', 'fetch');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setIsLoading(false);
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    if (Object.keys(workbench)?.length > 0 && userData) {
      const AccessWorkbench = checkWorkbenchAccess(workbench, userData);
      const canLockWorkbench = checkForUnlockWorkbench(workbench, userData);
      setWorkbenchLockAccess(canLockWorkbench);
      setLockedWorkbench(!AccessWorkbench);
    }
  }, [userData, workbench]);

  useEffect(() => {
    const defaultValues = { ...workbench };
    reset({ ...defaultValues });
  }, [workbench, reset]);

  useEffect(() => {
    getWorkbench(id);
  }, [id, getWorkbench]);

  const handleEdit = async () => {
    try {
      await getWorkbench(id);
      setIsEditing(true);
    } catch (e) {
      console.log('Error found in handleEdit::', e);
    }
  };

  const handleEditCancel = () => {
    setIsEditing(false);
  };

  const tabChange = () => {
    if (isEditing) {
      return isDirty ? handleSave() : setIsEditing(false);
    }
  };
  const handelNewTabChange = newtabValue => {
    if (newtabValue !== undefined || newtabValue !== null) {
      navigate(`/workbenches/${id}/${newNavItems[newtabValue]}`, {
        data: { isEdit: isEditing }
      });
    }
  };

  const WorkbenchEditSchema = yup.object().shape({
    name: yup.string().required(),
    type: yup.string().required(),
    users: yup.array().of(yup.string()).required()
  });

  const handleSave = async () => {
    try {
      await trigger();
      // if (Object.keys(errors)?.length !== 0) {
      //   const message = validateMessage("required fields", "fill all")
      //   enqueueSnackbar(message, { variant: WARNING })
      //   return false;
      // }
      const payload = { ...getValues(), updated_at: workbench.updated_at };
      payload.users = payload.tagged_users;
      let saved = false;
      const formData = cleanupWorkbenchData(payload);
      const isValid = await WorkbenchEditSchema.isValid(formData);
      if (!isValid) {
        let requiredField = [
          { fieldName: 'name', label: 'Workbench Name', type: String },
          { fieldName: 'type', label: 'Type', type: String },
          { fieldName: 'users', label: 'Users', type: [] }
        ];
        let dirtyField = customFormValidator(formData, requiredField);
        if (dirtyField) {
          const message = requireValidMessage(dirtyField);
          enqueueSnackbar(message, { variant: ERROR });
          return;
        }
      }
      setIsLoading(true);
      const { status, data } = await workbenchDataApi(PUT, id, formData);
      if (status) {
        const message = successMessage('Workbench', VALIDATION_MESSAGE.updated_message);
        enqueueSnackbar(message, { variant: SUCCESS });
        saved = true;
        await reloadData();
      } else {
        const message = unableMessage('Workbench', 'update');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }

      setIsEditing(false);
      setIsLoading(false);
      return saved;
    } catch (e) {
      console.log('Error found in handleSave::', e);
    }
  };

  const reloadData = async () => {
    try {
      await getWorkbench(id);
    } catch (e) {
      console.log('Error found in reloadData::', e);
    }
  };

  const handleBack = () => {
    if (isEditing && isDirty) {
      setShowConfirmBack(true);
    } else {
      goBack();
    }
  };

  const goBack = () => {
    setShowConfirmBack(false);
    navigate('/workbenches');
  };

  const handleConfirm = async () => {
    setShowConfirmBack(false);
    const saved = await handleSave();
    if (saved) {
      goBack();
    }
  };

  const handleClose = () => {
    setShowConfirmBack(false);
  };

  const getTotalCount = count => {
    setTotalCount(count);
  };

  const headerLayout = () => {
    return (
      <>
        <div className='cursor-pointer' onClick={handleBack}>
          <span className='header-back-icon'>
            <ArrowBackIcon color='primary' />
          </span>
        </div>
        <span className='header-text'>{isEditing ? 'Edit Workbench' : 'View Workbench'}</span>
      </>
    );
  };

  const withHeader = (Component, showEdit = true) => {
    return (
      <React.Fragment>
        <Header
          workbench={workbench}
          name={workbench.name}
          users={workbench.tagged_users}
          handleEdit={handleEdit}
          showEdit={showEdit}
          isEditing={isEditing}
          handleEditCancel={handleEditCancel}
          handleSave={handleSave}
          reloadData={reloadData}
          isLockedWorkbench={isLockedWorkbench}
        />
        {Component}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Loader show={isLoading} />
      {showConfirmBack && (
        <ConfirmationPopup header={VALIDATION_MESSAGE.unsave_changes_message} onConfirm={handleConfirm} onCancel={goBack} onClose={handleClose} cancelText='Discard' confirmText='Save' />
      )}
      <Helmet>
        <title>{`${workbench.name || 'KGP Galaxy'}`}</title>
      </Helmet>
      {workbench && workbench?.type ? (
        <TabLayoutWithHeader headerLayout={headerLayout()} navItems={[...navItems]} isActionsOnTop={true} tabChange={tabChange} newTabChange={handelNewTabChange} currentTab={activeCurrentTabIndex}>
          <div disabled={true}></div>
          {withHeader(
            <WorkbenchInfo
              option={options}
              workbench={workbench}
              register={register}
              watch={watch}
              setValue={setValue}
              control={control}
              isEditing={isEditing}
              count={count}
              isLockedWorkbench={isLockedWorkbench}
              workbenchLockAccess={workbenchLockAccess}
            />
          )}
          {workbench?.type === 'Searches' ? (
            <Searches id={id} key={id} workbench={workbench} reloadData={reloadData} getTotalCount={getTotalCount} option={options} isLockedWorkbench={isLockedWorkbench} />
          ) : (
            <Contacts
              enqueueSnackbar={enqueueSnackbar}
              workbench={workbench}
              id={id}
              key={id}
              reloadData={reloadData}
              getTotalCount={getTotalCount}
              option={options}
              isLockedWorkbench={isLockedWorkbench}
            />
          )}
        </TabLayoutWithHeader>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}
