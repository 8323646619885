//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Contacts from './Contacts';

const MyBDTargetsList = () => <Contacts type='my-clients' storageKey='contactMyClientsColumns' title='My Clients' />;

export default MyBDTargetsList;
