import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import './index.scss';

import { ArrowBack, SearchRounded } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Spinner } from 'react-bootstrap';
import CustomButton from '../../../components/common/CustomButton';
import CustomInputField from '../../../components/common/StyledComponents/CustomInputField';
import { resourcesApi } from '../../../services/ApiService';
import { DELETE, GET, RESOURCE_VALIDATION } from '../../../services/constantService';
import useApi from '../../../utils/Hooks/useApiHook';
import useDebounce from '../../../utils/Hooks/useDebounce';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import AddResourcePopup from './components/AddResourcePopup';
import { CheckBoxRenderer, columnDefs } from './utils';

const ResourcesSecurity = () => {
  const [rowData, setRowData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [toDelete, setToDelete] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_SEARCH]);

  const { enqueueSnackbar } = useSnackbar();

  const { data, refetch } = useApi({
    queryFn: () => {
      return resourcesApi(GET, `getAll?search=${searchValue}`);
    }
  });

  const deleteResources = async () => {
    setLoading(true);
    for (const toDel of toDelete) {
      await resourcesApi(DELETE, `deleteById/${toDel}`);
    }
    setToDelete([]);
    await refetch();
    enqueueSnackbar(RESOURCE_VALIDATION.DELETE, {
      variant: 'success'
    });
    setLoading(false);
  };

  useEffect(() => {
    if (data && data?.data) {
      setRowData(data.data);
    } else {
      setRowData(null);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [debouncedSearchValue]);

  return (
    <React.Fragment>
      <div
        className='position-relative'
        style={{
          top: '50%',
          transform: 'translateY(-50%)'
        }}
      >
        <div
          style={{
            height: '90vh',
            backgroundColor: 'white',
            marginRight: '20px',
            borderRadius: '10px'
          }}
        >
          <Box height='85%' paddingX='20px'>
            <div className='py-2 text-right d-flex justify-content-between'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3
                }}
              >
                <div
                  className='roles-back-button'
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  <ArrowBack className='icon-size' /> Back
                </div>

                <CustomInputField
                  label={t(`${TRANS_KEYS.CONTACTS_SEARCH}:key`)}
                  size='small'
                  endIcon={<SearchRounded />}
                  className='search-component'
                  onChange={e => {
                    setSearchValue(e.target.value);
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3
                }}
              >
                <CustomButton
                  type={'tertiary-error'}
                  disabled={toDelete.length === 0}
                  iconRight={<Spinner animation='border' variant='success' size='sm' className='ml-2' style={{ display: loading ? 'inline-block' : 'none' }} />}
                  onClick={() => deleteResources()}
                  buttonText={'Delete Resources'}
                />
                <CustomButton variant='contained' className='button-add' iconLeft={<AddIcon className='icon-size' />} onClick={() => setIsPopupOpen(!isPopupOpen)} buttonText='Add Resource' />
              </Box>
            </div>
            <div className='ag-theme-alpine text-left' id='myGrid'>
              <AgGridReact
                disableStaticMarkup
                rowData={rowData ?? null}
                columnDefs={columnDefs}
                frameworkComponents={{
                  checkboxRenderer: props => <CheckBoxRenderer {...props} setToDelete={setToDelete} />
                }}
              />
            </div>
            <AddResourcePopup isOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} refetch={refetch} />
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResourcesSecurity;
