//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Box, Checkbox, CircularProgress, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import DuplicateContactNotifier from '../CustomPopup/DuplicateContactNotifier';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import CustomModalWithHeader from '../../../Containers/Commons/CustomModalWithHeader';
import { AllDocumentsApi, LogCandidateActivityAPI } from '../../../services/ApiService';
import {
  DUPLICATION_FIELDS,
  EMAIL_TYPE,
  IGN_API,
  PHONE_TYPE,
  POST
  // POST
} from '../../../services/constantService';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { checkDuplicateOnBlur } from '../../../utils/common';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import '../../AddContact/index.scss';
import DynamicTabLayout from '../../DynamicTabs/TabLayout';
import CustomButton from '../CustomButton';
import CustomConfirmationPopup from '../CustomConfirmationPopup';
import ContactMatchView from '../CustomPopup/ContactMatchView';
import Communications from './Communications';
import EducationAndLanguages from './EducationAndLanguages';
import ProfessionalExperience from './ProfessionalExperience';
import ProfileDetails from './ProfileDetails';
// import { ignCompanyDataApi } from '../../../services/ApiService';

export default function CreateContactFromResume(props) {
  const {
    companyList,
    openCreateContactFromResume,
    handleClose,
    resumeData,
    createContact,
    formatterFunction,
    files,
    bulkAdd,
    bulkLoader,
    setBulkCreatedUpdate,
    handleViewSummary,
    projectId,
    handleBulkCreateUpdate
  } = props;
  const { register } = useForm({});
  const [isLoading, setIsLoading] = useState(false);
  const [docs, setDocs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [duplicateRecords, setDuplicateRecords] = useState(null);
  const [duplicationField, setDuplicationField] = useState('');
  const [isConfirmationPopup, setIsConfirmationPopup] = useState(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [selectedResumeIndex, setSelectedResumeIndex] = useState(0);
  const [parsedResumeData, setParsedResumeData] = useState([]);
  const [currentResumeValues, setCurrentResumeValues] = useState({});
  const [uploadResume, setUploadResume] = useState(false);
  const [isUploadResume, setIsUploadResume] = useState(false);
  const [viewSummaryButton, setViewSummaryButton] = useState(false);
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_WORD_ADD,
    TRANS_KEYS.CONTACTS_PHONE_NUMBER,
    TRANS_KEYS.CONTACTS_ADDRESS,
    TRANS_KEYS.CONTACTS_COUNTRY,
    TRANS_KEYS.CONTACTS_STATE,
    TRANS_KEYS.CONTACTS_ZIP,
    TRANS_KEYS.CONTACTS_CITY,
    TRANS_KEYS.CONTACTS_METROPOLITAN_AREA,
    TRANS_KEYS.CONTACTS_ADDRESS_TYPE,
    TRANS_KEYS.CONTACTS_COMPANY,
    TRANS_KEYS.CONTACTS_JOB_TITLE_AND_COMPANY,
    TRANS_KEYS.CONTACTS_WORD_FROM,
    TRANS_KEYS.CONTACTS_WORD_TO,
    TRANS_KEYS.CONTACTS_TIME_PERIOD,
    TRANS_KEYS.CONTACTS_THEY_CURRENTLY_WORK_HERE,
    TRANS_KEYS.CONTACTS_BOARD_MEMBER,
    TRANS_KEYS.CONTACTS_THEY_ARE_A_BOARD_MEMBERS,
    TRANS_KEYS.CONTACTS_FROM_YEAR,
    TRANS_KEYS.CONTACTS_TO_YEAR,
    TRANS_KEYS.CONTACTS_COMMITTEE_DETAILS,
    TRANS_KEYS.CONTACTS_DESCRIPTION,
    TRANS_KEYS.CONTACTS_EDUCATION,
    TRANS_KEYS.CONTACTS_LEVEL_OF_EDUCATION,
    TRANS_KEYS.CONTACTS_FIELD_OF_STUDY,
    TRANS_KEYS.CONTACTS_SCHOOL_NAME,
    TRANS_KEYS.CONTACTS_CURRENTLY_ENROLLED,
    TRANS_KEYS.CONTACTS_LANGUAGE,
    TRANS_KEYS.CONTACTS_LANGUAGES,
    TRANS_KEYS.CONTACTS_LEVEL_OF_EXPERTISE,
    TRANS_KEYS.CONTACTS_EDUCATION_AND_LANGUAGES,
    TRANS_KEYS.CONTACTS_PROFESSIONAL_EXPERIENCE,
    TRANS_KEYS.CONTACTS_COMMUNICATION,
    TRANS_KEYS.CONTACTS_PROFILE,
    TRANS_KEYS.CONTACTS_ADD_CONTACT,
    TRANS_KEYS.CONTACT_CREATE_FROM_RESUME
  ]);

  const TabData = [
    {
      label: `${t(`${TRANS_KEYS.CONTACTS_PROFILE}:key`)}`,
      content: 'Content for Profile',
      subTabs: []
    },
    {
      label: `${t(`${TRANS_KEYS.CONTACTS_COMMUNICATION}:key`)}`,
      content: 'Content Communication',
      subTabs: []
    },
    {
      label: `${t(`${TRANS_KEYS.CONTACTS_PROFESSIONAL_EXPERIENCE}:key`)}`,
      content: 'Content for Professional Experience',
      subTabs: []
    },
    {
      label: `${t(`${TRANS_KEYS.CONTACTS_EDUCATION_AND_LANGUAGES}:key`)}`,
      content: 'Content for Education and Languages',
      subTabs: []
    }
  ];

  const onTabChange = () => {
    // setCurrentValues((prevState) => ({ ...prevState, ...currentResumeValues }));
  };

  const addContact = async () => {
    try {
      setIsLoading(true);
      let contactInfoPresent = false;

      if (currentResumeValues.contact_emails.length > 0 || currentResumeValues.contact_phones.length > 0 || currentResumeValues.linkedin_url.length > 0) {
        contactInfoPresent = true;
      }

      if (!contactInfoPresent) {
        enqueueSnackbar('Please Fill at least Email or Phone or LinkedIn URL', 'error');
        setIsLoading(false);
        return;
      }

      console.log('second addContact');

      const hasPrimaryPhone = currentResumeValues.contact_phones.some(phone => phone.primary);

      if (!hasPrimaryPhone && currentResumeValues.contact_phones.length > 0) {
        currentResumeValues.contact_phones[0].primary = true;
      }
      const hasPrimaryEmail = currentResumeValues.contact_emails.some(email => email.primary);

      if (!hasPrimaryEmail && currentResumeValues.contact_emails.length > 0) {
        currentResumeValues.contact_emails[0].primary = true;
      }
      // const formatContactPhoneNumber = currentResumeValues?.contact_phones?.map((phoneNumber) => ({ ...phoneNumber, phone_type: phoneNumber?.phone_type?.field_value || phoneNumber?.phone_type || PHONE_TYPE.PERSONAL.field_value} ) );
      const formatContactPhoneNumber = currentResumeValues?.contact_phones?.map(phoneNumber => {
        const res = {
          phone_type: phoneNumber?.phone_type?.field_value || phoneNumber?.phone_type || PHONE_TYPE.PERSONAL.field_value,
          phone_number: phoneNumber?.phone_number == 0 ? null : phoneNumber?.phone_number,
          is_primary: phoneNumber?.primary
        };
        delete phoneNumber.id;
        delete res.primary;
        return res;
      });

      const formatContactEmail = currentResumeValues?.contact_emails?.map(email => {
        delete email.id;
        const res = {
          email_type: email?.email_type?.field_value || email?.email_type || EMAIL_TYPE.PERSONAL.field_value,
          is_primary: email?.primary,
          ign_translate_value: email?.ign_translate_value || email?.email,
          email: email?.email
        };
        return res;
      });
      const address = currentResumeValues?.contact_address?.map(address => {
        delete address.id;
        return {
          country: address?.country,
          city: address?.city,
          state: address?.state,
          zip_code: address?.zip_code ? address?.zip_code : null,
          address_type: address?.address_type ? address?.address_type : null,
          country_id: address?.country_id ? address?.country_id : null,
          state_id: address?.state_id ? address?.state_id : null
        };
      });

      let updatedExperience = currentResumeValues?.contact_work_experience?.map(experience => {
        const end_date = experience?.end_date && moment(experience?.end_date).utc().local().isValid() ? moment(experience?.end_date).utc().local().format() : null;
        delete experience.id;
        return {
          ...experience,
          start_date: experience?.start_date && moment(experience?.start_date).utc().local().isValid() ? moment(experience?.start_date).utc().local().format() : null,
          end_date: !experience?.is_present ? end_date : null
        };
      });
      const updatedEducation = currentResumeValues?.contact_education_details.map(education => {
        const end_date = education?.end_date && moment(education?.end_date).utc().local().isValid() ? moment(education?.end_date).utc().local().format() : null;
        delete education.id;
        const res = {
          ...education,
          start_date: education?.start_date && moment(education?.start_date).utc().local().isValid() ? moment(education?.start_date).utc().local().format() : null,
          end_date: !education?.is_present ? end_date : null
        };
        delete res.is_present;
        return res;
      });

      const updatedCertificate = currentResumeValues?.contact_certificate?.map(certificate => {
        delete certificate.id;
        return {
          ...certificate
        };
      });

      const formData = {
        ...currentResumeValues,
        contact_address: address,
        contact_certificate: updatedCertificate,
        contact_education_details: updatedEducation,
        contact_work_experience: updatedExperience?.filter(experience => !experience.hide),
        contact_phones: formatContactPhoneNumber,
        contact_emails: formatContactEmail
      };
      const transformedPayload = formatterFunction(formData);
      await createContact('', transformedPayload, selectedResumeIndex);
      setViewSummaryButton(true);
      handleBulkCreateUpdate({ alreadyCreated: true, ...transformedPayload });
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (parsedResumeData.length > 0) {
      const selectedFile = parsedResumeData[selectedResumeIndex].file;
      const documents = [
        {
          uri: window.URL.createObjectURL(selectedFile),
          fileName: selectedFile.name
        }
      ];
      setDocs(documents);
    }
  }, [selectedResumeIndex]);

  useEffect(() => {
    register('contact_record_types');
    register('source');
    register('first_name');
    register('last_name');
    register('linkedin_url');
    register('current_company_id');
    register('contact_company');
    register('current_job_title');
    register('contact_emails');
    register('contact_phones');
    register('contact_address');
    register('contact_work_experience');
    register('contact_industries');
    register('contact_job_functions');
    register('expire_on');
    register('contact_languages');
    register('contact_certificate');
    register('contact_education_details');
  }, []);

  useEffect(() => {
    setParsedResumeData(resumeData);
    // setResumeValues(resumeData[selectedResumeIndex]);
    if (resumeData.length == 1) {
      setCurrentResumeValues(resumeData[0].templatedData);
      const selectedFile = resumeData[0].file;
      console.log({ selectedFile });
      const documents = [
        {
          uri: window.URL.createObjectURL(selectedFile),
          fileName: selectedFile.name
        }
      ];
      setDocs(documents);
    }
  }, [resumeData]);

  useEffect(() => {
    if (parsedResumeData.length > 0) {
      // setResumeValues(parsedResumeData[selectedResumeIndex]);
      setCurrentResumeValues(parsedResumeData[selectedResumeIndex].templatedData);
    }
  }, [selectedResumeIndex]);

  const onBlurPhoneNumber = async e => {
    if (e.target.value && e.relatedTarget?.tagName !== 'BUTTON') {
      const phoneNumberData = { contact_phones: [{ phone_number: e.target.value }] };
      const { isDuplicate, duplicateRecords } = await checkDuplicateOnBlur(phoneNumberData, setIsLoading);
      setDuplicationField(DUPLICATION_FIELDS.PHONE_NUMBER);
      setDuplicateRecords(duplicateRecords);
      updateDuplicateData(duplicateRecords);
      setIsConfirmationPopup(isDuplicate);
    }
  };

  const onBlurLinkedInUrl = async e => {
    if (e.target.value && e.relatedTarget?.tagName !== 'BUTTON') {
      const linkedInUrlData = { linkedin_url: e.target.value };
      const { isDuplicate, duplicateRecords } = await checkDuplicateOnBlur(linkedInUrlData, setIsLoading);
      setDuplicationField(DUPLICATION_FIELDS.LINKEDIN);
      setDuplicateRecords(duplicateRecords);
      updateDuplicateData(duplicateRecords);
      setIsConfirmationPopup(isDuplicate);
    }
  };

  const onBlurEmail = async e => {
    if (e.target.value && e.relatedTarget?.tagName !== 'BUTTON') {
      const emailData = { contact_emails: [{ email: e.target.value || '' }] };
      const { isDuplicate, duplicateRecords } = await checkDuplicateOnBlur(emailData, setIsLoading);
      setDuplicationField(DUPLICATION_FIELDS.EMAIL);
      setDuplicateRecords(duplicateRecords);
      updateDuplicateData(duplicateRecords);
      setIsConfirmationPopup(isDuplicate);
    }
  };

  const setSpecificValue = (form, id, value) => {
    const specificValue = currentResumeValues[form]?.map(elem => {
      if (elem.id === id) {
        return { ...elem, ...value };
      }
      return elem;
    });
    setValue(form, specificValue);
    return specificValue;
  };

  const deleteSpecificValue = (form, id) => {
    const specificValue = currentResumeValues[form].filter(elem => elem.id !== id);
    setValue(form, specificValue);
    return specificValue;
  };
  const closeConfirmationPopup = () => {
    setIsConfirmationPopup(false);
    setDuplicateRecords(null);
    setDuplicationField('');
  };
  const onConfirm = () => {
    closeConfirmationPopup();
  };

  const setValue = (key, value) => {
    parsedResumeData[selectedResumeIndex].templatedData[key] = value;
    currentResumeValues[key] = value;
    setParsedResumeData([...parsedResumeData]);
    setCurrentResumeValues(currentResumeValues);
  };

  const BulkCreateLabel = () => {
    return (
      <div className='w-100 d-flex justify-content-between align-items-center'>
        <div>{t(`${TRANS_KEYS.CONTACT_CREATE_FROM_RESUME}:key`)}</div>
        <div className='d-flex align-items-center'>
          {viewSummaryButton && <CustomButton buttonText={'View Summary'} type={'tertiary'} onClick={() => handleViewSummary()} />}
          {files.length != 1 && (
            <>
              {files.length > 1 && !bulkLoader ? (
                <CustomButton
                  buttonText={'Bulk Add'}
                  onClick={() => {
                    bulkAdd(parsedResumeData);
                    setViewSummaryButton(true);
                  }}
                />
              ) : (
                <CircularProgress />
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const updateDuplicateData = duplicate => {
    parsedResumeData[selectedResumeIndex].duplicateData = duplicate;
    setParsedResumeData([...parsedResumeData]);
  };

  const handleCheckbox = index => {
    parsedResumeData[index].checked = !parsedResumeData[index].checked;
    setParsedResumeData([...parsedResumeData]);
  };

  const handleUploadContactResume = async () => {
    setIsUploadResume(true);
    const contactData = parsedResumeData[selectedResumeIndex].duplicateData?.data?.exactMatch;
    const file = parsedResumeData[selectedResumeIndex].file;
    if (!contactData) return;
    for (let i = 0; i < contactData.length; i++) {
      const { id } = contactData[i];
      const sub_route1 = `${IGN_API.add_document}/${id}/attachments`;

      let bodyFormData = new FormData();
      bodyFormData.append('file_name', file);
      bodyFormData.append('file_type', 'resume');
      bodyFormData.append('file', file);
      const { status, data } = await AllDocumentsApi(POST, sub_route1, bodyFormData);

      if (status == 200) {
        await LogCandidateActivityAPI(POST, '', {
          notes_type: 'professional_notes',
          notes: "<p>Update candidate's resume</p>",
          activity_type: 'NOTE',
          project_id: projectId,
          contact_id: id
        });
      }
      console.log(status, data);
    }

    parsedResumeData[selectedResumeIndex].contactUpdated = true;
    parsedResumeData[selectedResumeIndex].alreadyCreated = true;

    setParsedResumeData([...parsedResumeData]);
    setBulkCreatedUpdate(parsedResumeData[selectedResumeIndex]);
    enqueueSnackbar('Resume Updated Successfully', 'success');
    setIsUploadResume(false);
  };

  return (
    <CustomModalWithHeader closeIcon={true} label={<BulkCreateLabel />} colorClass='w-100' isOpen={openCreateContactFromResume} onClose={handleClose}>
      <Grid container spacing={1} style={{ width: '90vw', height: '90vh' }} className='p-1' justifyContent={'start'} alignItems={'flex-start'}>
        {files.length > 1 && (
          <Grid item xs={2} columnSpacing={2} direction={'column'} sx={{ height: '90vh', overflowY: 'scroll' }}>
            {parsedResumeData.map((resumeData, index) => (
              <Grid container key={index} alignItems={'center'} className={`mb-2 p-2 pt-3 pb-3 rounded ${index == selectedResumeIndex ? 'selected-resume' : 'cursor-pointer'}`}>
                <Grid item xs={2}>
                  {index == selectedResumeIndex ? (
                    <Checkbox
                      sx={{
                        color: 'white',
                        '&.Mui-checked': {
                          color: 'white'
                        }
                      }}
                      checked={resumeData?.checked}
                      onClick={() => handleCheckbox(index)}
                    />
                  ) : (
                    <Checkbox checked={resumeData?.checked} onClick={() => handleCheckbox(index)} />
                  )}
                </Grid>
                <Grid item xs={8} onClick={() => setSelectedResumeIndex(index)}>
                  <div className='fs-14 ml-1'>{resumeData?.templatedData?.first_name ? resumeData?.templatedData?.first_name : 'NA' + ' ' + resumeData?.templatedData?.last_name}</div>
                </Grid>
                <Grid item xs={2}>
                  {bulkLoader && resumeData.loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {resumeData.templatedData?.alreadyCreated == true ? (
                        <CheckCircleOutlineIcon />
                      ) : (
                        <>{(resumeData.duplicateData?.data?.exactMatch || resumeData.templatedData?.error) && <ErrorOutlineOutlinedIcon />}</>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            ))}
            {files.length != parsedResumeData.length && (
              <div className='w-100 d-flex justify-content-center'>
                <CircularProgress />
              </div>
            )}
          </Grid>
        )}
        <Grid item xs={files.length > 1 ? 10 : 12} container spacing={1} rowSpacing={2} justifyContent={'start'} alignItems={'flex-start'}>
          <Grid item container xs={12} sm={12} md={12} lg={12} justifyContent={'start'} alignItems={'center'}>
            <Grid xs={10} className='p-1'>
              {parsedResumeData[selectedResumeIndex]?.templatedData?.alreadyCreated ? (
                <div className='fs-18'>Contact Created Successfully</div>
              ) : (
                <>
                  {parsedResumeData[selectedResumeIndex]?.error && <div className='fs-18 fw-bolder font-color-red'>{parsedResumeData[selectedResumeIndex].error}</div>}
                  {((parsedResumeData[selectedResumeIndex]?.duplicateData?.data?.exactMatch && parsedResumeData[selectedResumeIndex]?.duplicateData?.data?.exactMatch.length > 0) ||
                    (parsedResumeData[selectedResumeIndex]?.duplicateData?.data?.partialMatch && parsedResumeData[selectedResumeIndex]?.duplicateData.data.partialMatch.length > 0)) && (
                    <Box>{parsedResumeData[selectedResumeIndex]?.duplicateData?.data && <ContactMatchView data={parsedResumeData[selectedResumeIndex]?.duplicateData} />}</Box>
                  )}
                </>
              )}
            </Grid>
            <Grid xs={2}>
              <Box className='d-flex w-100 justify-content-end'>
                {!parsedResumeData[selectedResumeIndex]?.templatedData?.alreadyCreated && (
                  <CustomButton
                    isLoading={isLoading}
                    variant='contained'
                    onClick={addContact}
                    type={'primary'}
                    size={'medium'}
                    buttonText={t(`${TRANS_KEYS.CONTACTS_ADD_CONTACT}:key`)}
                    customWidth={110}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} height={'80vh'} className='overflow-auto'>
            <DynamicTabLayout
              navItems={TabData}
              isDrawer={true}
              isDrawerCss={true}
              onTabChange={onTabChange}
              activeTab={activeTab}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              setActiveTab={setActiveTab}
              className='overview-auto'
            >
              <ProfileDetails
                index='Profile'
                setValue={setValue}
                data={currentResumeValues}
                onBlurPhoneNumber={onBlurPhoneNumber}
                setIsLoading={setIsLoading}
                onBlurLinkedInUrl={onBlurLinkedInUrl}
                onBlurEmail={onBlurEmail}
                duplicateRecords={duplicateRecords}
                duplicationField={duplicationField}
                setDuplicateRecords={setDuplicateRecords}
                setDuplicationField={setDuplicationField}
                setSpecificValue={setSpecificValue}
                companyList={companyList}
              />
              <Communications
                index='Communication'
                setValue={setValue}
                data={currentResumeValues}
                onBlurPhoneNumber={onBlurPhoneNumber}
                setIsLoading={setIsLoading}
                onBlurLinkedInUrl={onBlurLinkedInUrl}
                onBlurEmail={onBlurEmail}
                duplicateRecords={duplicateRecords}
                duplicationField={duplicationField}
                setDuplicateRecords={setDuplicateRecords}
                setDuplicationField={setDuplicationField}
                setSpecificValue={setSpecificValue}
                deleteSpecificValue={deleteSpecificValue}
              />
              <ProfessionalExperience
                index='Professional Experience'
                setValue={setValue}
                data={currentResumeValues}
                onBlurPhoneNumber={onBlurPhoneNumber}
                setIsLoading={setIsLoading}
                onBlurLinkedInUrl={onBlurLinkedInUrl}
                onBlurEmail={onBlurEmail}
                duplicateRecords={duplicateRecords}
                duplicationField={duplicationField}
                setDuplicateRecords={setDuplicateRecords}
                setDuplicationField={setDuplicationField}
                setSpecificValue={setSpecificValue}
                deleteSpecificValue={deleteSpecificValue}
                companyList={companyList}
              />
              <EducationAndLanguages
                index='Education and Languages'
                setValue={setValue}
                data={currentResumeValues}
                onBlurPhoneNumber={onBlurPhoneNumber}
                setIsLoading={setIsLoading}
                onBlurLinkedInUrl={onBlurLinkedInUrl}
                onBlurEmail={onBlurEmail}
                duplicateRecords={duplicateRecords}
                duplicationField={duplicationField}
                setDuplicateRecords={setDuplicateRecords}
                setDuplicationField={setDuplicationField}
                setSpecificValue={setSpecificValue}
                deleteSpecificValue={deleteSpecificValue}
              />
            </DynamicTabLayout>
            {isConfirmationPopup && (
              <DuplicateContactNotifier
                open={isConfirmationPopup}
                data={duplicateRecords}
                duplicationField={duplicationField}
                showAction={false}
                handleClose={() => {
                  setIsConfirmationPopup(false);
                  setDuplicateRecords(null);
                  setDuplicationField('');
                }}
              />
            )}
            <CustomConfirmationPopup
              open={openConfirmationPopup}
              onClose={closeConfirmationPopup}
              type='delete'
              cancelText='No'
              confirmText='Yes'
              onConfirm={onConfirm}
              setOpen={setOpenConfirmationPopup}
              isEmailMessage={true}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} height={'80vh'} className='overflow-auto'>
            <div className='w-100 d-flex align-items-center'>
              {parsedResumeData[selectedResumeIndex]?.duplicateData?.data?.exactMatch && (
                <>
                  {parsedResumeData[selectedResumeIndex]?.contactUpdated != true ? (
                    <>
                      <div>
                        <Checkbox checked={uploadResume} onChange={() => setUploadResume(!uploadResume)} />
                        Add Resume to Existing Contact
                      </div>
                      {isUploadResume ? <CircularProgress /> : <>{uploadResume && <CustomButton buttonText={'Update'} type='primary ml-2' onClick={handleUploadContactResume} />}</>}
                    </>
                  ) : (
                    <div>Resume is Already updated</div>
                  )}
                </>
              )}
            </div>
            <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
          </Grid>
        </Grid>
      </Grid>
    </CustomModalWithHeader>
  );
}

CreateContactFromResume.propTypes = {
  openCreateContactFromResume: PropTypes.bool,
  handleClose: PropTypes.func,
  resumeData: PropTypes.array,
  createContact: PropTypes.func,
  setResumeData: PropTypes.func,
  templatedData: PropTypes.array,
  files: PropTypes.array,
  bulkAdd: PropTypes.func,
  handleBulkCreateUpdate: PropTypes.func,
  bulkLoader: PropTypes.bool,
  setBulkCreatedUpdate: PropTypes.func,
  handleViewSummary: PropTypes.func,
  projectId: PropTypes.string,
  companyList: PropTypes.array,
  formatterFunction: PropTypes.func
};
