//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import ProfileDetails from '../../../AddContact/Components/ProfileDetails/ProfileDetails';

export default function ProfileDetailsEdit(props) {
  const { defaultValues, register, watch, setValue, unregister, option } = props;

  return <ProfileDetails option={option} register={register} unregister={unregister} watch={watch} setValue={setValue} values={defaultValues} />;
}

ProfileDetailsEdit.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  dynamicKey: PropTypes.string,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  defaultValues: PropTypes.object,
  option: PropTypes.array
};
