//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, FormGroup } from '@mui/material/';
import { Typography, Grid } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../../../../common/InputField';
export default function EducationLicensesandCertifications(props) {
  const { currentValues } = props;
  return (
    <div
      style={{
        marginTop: '20px',
        marginLeft: '20px',
        marginBottom: '0px',
        padding: '0px'
      }}
    >
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{ float: 'left', fontWeight: 'bold' }}>Education and Experience</Typography>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className='d-flex flex-grow-1'>
            <div className='input-form-field'>
              <div className='d-flex flex-grow-1'>
                <InputField
                  value={currentValues.years_of_experience || ''}
                  className='input-form-field'
                  disabled
                  label='Minimum years of experience'
                  name='years_of_experience'
                  InputLabelProps={{ focused: true }}
                />
                <div className='input-form-field'>
                  <FormGroup style={{ color: 'black', width: '20%' }} row>
                    <FormControlLabel
                      style={{
                        color: 'black',
                        margin: '10px 0 0',
                        left: '100%'
                      }}
                      disabled
                      control={<Checkbox checked={currentValues.is_years_of_experience_mandatory || false} />}
                      label='Mandatory'
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-grow-1 mb-2'>
            <div className='input-form-field'>
              <div className='d-flex flex-grow-1'>
                <InputField
                  // {...register('')}
                  type='number'
                  placeholder='Maximum years of experience in current company'
                  className='input-form-field'
                  label='Maximum years of experience in current company'
                  value={currentValues.max_years_of_experience_current_company || ''}
                  InputLabelProps={{ focused: true }}
                  name='max_years_of_experience_current_company'
                  disabled
                />
                {/* <div className="input-form-field"></div> */}
              </div>
            </div>
          </div>
          <div className='d-flex flex-grow-1'>
            <div className='input-form-field'>
              <div className='d-flex flex-grow-1'>
                <InputField className='input-form-field' value={currentValues.degree_name || ''} label='Education 1' disabled InputLabelProps={{ focused: true }} />
                <div className='input-form-field'>
                  <FormGroup style={{ color: 'black', width: '20%' }} row>
                    <FormControlLabel
                      style={{
                        color: 'black',
                        margin: '10px 0 0',
                        left: '100%'
                      }}
                      disabled
                      control={<Checkbox checked={currentValues.is_degree_mandatory || false} />}
                      label='Mandatory'
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-grow-1'>
            <div className='input-form-field'>
              <div className='d-flex flex-grow-1'>
                <InputField className='input-form-field' label='Certification' disabled value={currentValues.certifications || ''} InputLabelProps={{ focused: true }} />
                <div className='input-form-field'></div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
EducationLicensesandCertifications.propTypes = {
  register: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object
};
