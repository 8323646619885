//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useCallback, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import update from 'immutability-helper';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../../../../common/InputField';
import DraggableComponent from '../Draggable/DraggableComponent';
import ItemTypes from '../Draggable/ItemTypes';
export default function SchoolsAndColleges(props) {
  const { register, setValue, currentValues } = props;
  if (currentValues.cs_location_schoolsandcolleges?.length === 0) {
    currentValues.cs_location_schoolsandcolleges = [{}];
  }
  const [schoolsAndColleges, setSchoolsAndColleges] = useState([{}]);
  const skillIndex = 0;
  useEffect(() => {
    setSchoolsAndColleges(currentValues.cs_location_schoolsandcolleges || []);
    register('cs_location_schoolsandcolleges');
  }, [currentValues, register]);

  useEffect(() => {
    setValue('cs_location_schoolsandcolleges', schoolsAndColleges);
  }, [schoolsAndColleges, setValue]);

  const updateschoolsandcolleges = (index, name, data) => {
    const currentschoolsandcolleges = [...schoolsAndColleges];
    currentschoolsandcolleges[index][name] = data;
    setSchoolsAndColleges(currentschoolsandcolleges);
  };

  const deleteschoolsandcolleges = index => {
    const currentschoolsandcolleges = [...schoolsAndColleges];
    currentschoolsandcolleges.splice(index, 1);
    setSchoolsAndColleges(currentschoolsandcolleges);
  };

  const addschoolsandcolleges = () => {
    const currentschoolsandcolleges = [...schoolsAndColleges, {}];
    setSchoolsAndColleges(currentschoolsandcolleges);
  };

  const onSkillElementsChange = useCallback((skillIndex, elements) => {
    setSchoolsAndColleges(elements);
  }, []);

  const moveElement = useCallback(
    (dragIndex, hoverIndex) => {
      const dragSkillElement = schoolsAndColleges[dragIndex];
      let currentschoolsandcolleges = update(schoolsAndColleges, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSkillElement]
        ]
      });
      onSkillElementsChange(skillIndex, currentschoolsandcolleges);
    },
    [schoolsAndColleges, skillIndex, onSkillElementsChange]
  );

  return (
    <div className='section-content'>
      <Typography className='section-label'>Schools And Colleges</Typography>
      <DndProvider backend={HTML5Backend}>
        {schoolsAndColleges.map((item, index) => (
          <DraggableComponent
            key={index}
            index={index}
            id={item.id || index}
            moveNode={moveElement}
            componentType={ItemTypes.SAC}
            content={(dragHandle, preview) => (
              <div key={index} ref={preview} style={{ marginBottom: 10 }}>
                <Typography className='sub-section-label'>Schools And Colleges Details {index + 1}</Typography>
                <div className='d-flex align-items-center'>
                  <span ref={dragHandle}>
                    <DragIndicatorOutlinedIcon style={{ padding: '0px', color: 'gray' }} />
                  </span>
                  <InputField
                    label='Title'
                    placeholder='Title'
                    className='input-form-field'
                    value={item.title || ''}
                    InputLabelProps={{ focused: true }}
                    onChange={e => {
                      updateschoolsandcolleges(index, 'title', e.target.value);
                    }}
                  />
                  <InputField
                    label='Description'
                    placeholder='Description'
                    multiline
                    rowsMax='10'
                    className='input-form-field'
                    value={item.description || ''}
                    InputLabelProps={{ focused: true }}
                    onChange={e => {
                      updateschoolsandcolleges(index, 'description', e.target.value);
                    }}
                  />
                  <InputField
                    label='Website url'
                    placeholder='Website url'
                    className='input-form-field'
                    value={item.website_url || ''}
                    InputLabelProps={{ focused: true }}
                    onChange={e => {
                      updateschoolsandcolleges(index, 'website_url', e.target.value);
                    }}
                  />
                  <DeleteIcon fontSize='small' className='cursor-pointer' onClick={() => deleteschoolsandcolleges(index)} />
                </div>
              </div>
            )}
          />
        ))}
      </DndProvider>
      {schoolsAndColleges?.length < 10 ? (
        <Button variant={'text'} startIcon={<AddCircleIcon />} onClick={addschoolsandcolleges} color='primary'>
          Add Schools And Colleges
        </Button>
      ) : null}
    </div>
  );
}

SchoolsAndColleges.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object
};
