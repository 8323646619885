//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------

const TabPanel = ({ children, value, index }) => {
  return value === index && <Box>{children}</Box>;
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  index: PropTypes.string
};

export default TabPanel;
