import { TRANS_KEYS } from '../../utils/languageNamespaces';

const TabData = [
  {
    label: TRANS_KEYS.CONTACTS_LAYOUT_DETAILS,
    subTabs: [
      {
        label: TRANS_KEYS.CONTACTS_PROFILE
      },
      {
        label: TRANS_KEYS.CONTACTS_COMMUNICATION
      },
      {
        label: TRANS_KEYS.CONTACTS_PROFESSIONAL_EXPERIENCE
      },
      {
        label: TRANS_KEYS.CONTACTS_EDUCATION_AND_LANGUAGES
      }
    ]
  },
  {
    label: TRANS_KEYS.CONTACTS_COMPENSATION,
    subTabs: []
  },
  {
    label: TRANS_KEYS.CONTACTS_PERSONALITY,
    subTabs: []
  },
  {
    label: TRANS_KEYS.CONTACTS_DOCUMENT,
    subTabs: []
  },
  {
    label: TRANS_KEYS.CONTACTS_PROJECTS_AND_ACTIVITIES,
    subTabs: [
      {
        label: TRANS_KEYS.CONTACTS_PROJECTS
      },
      {
        label: TRANS_KEYS.CONTACTS_RECENT_ACTIVITY
      }
    ]
  }
];

export const contactFreezedTabs = [
  TRANS_KEYS.CONTACTS_COMMUNICATION,
  TRANS_KEYS.CONTACTS_PROFESSIONAL_EXPERIENCE,
  TRANS_KEYS.CONTACTS_EDUCATION_AND_LANGUAGES,
  TRANS_KEYS.CONTACTS_COMPENSATION,
  TRANS_KEYS.CONTACTS_PERSONALITY,
  TRANS_KEYS.CONTACTS_DOCUMENT,
  TRANS_KEYS.CONTACTS_PROJECTS_AND_ACTIVITIES,
  TRANS_KEYS.CONTACTS_PROJECTS,
  TRANS_KEYS.CONTACTS_RECENT_ACTIVITY
];
export default TabData;
