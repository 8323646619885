//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';

import { Button, Typography, Checkbox } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
//-----------------------------------------------------------// Internal Imports // ------------------------------

import { defaultColumns, productOneCandidateColumnDef } from './utils';
import { CustomLoadingOverlayComponent, PAGE_LIMIT, LinkedInRenderer, saveColumnStateToLocalStorage, loadColumnStateFromLocalStorage } from '../../Commons/Utils';
import '../../Commons/index.scss';
import './index.scss';
import { checkContactFetchingStatus, ContactNameRenderer } from '../../Contacts/utils';
import Header from '../../../components/ViewSearch/Components/Header';
import GenericCellEditor from '../../Commons/CellEditors/GenericCellEditor';
import CustomFilter from '../../Commons/CustomFilter';
import ReasonEditor from '../../Commons/CellEditors/ReasonEditor';
import ColumnFilter from '../../Commons/ColumnFilter';
import Loader from '../../../components/common/Loader';
import { isLoggedInUserAdmin } from '../../../services/cognitoService';
import {
  API,
  ERROR,
  POST,
  PRODUCTONE_TERMINATE_EXTENDED,
  PRODUCT_ONE,
  PRODUCT_ONE_MESSAGE,
  SUCCESS,
  WARNING,
  EXTENDED_SEARCH_LOAD_CANDIDATES,
  LINKEDIN_SCRAPER,
  SFPA_DATA
} from '../../../services/constantService';
import AddContactsToWorkbench from '../../Commons/AddContactToWorkbench';
import LinkedInDialog from '../../Commons/LinkedInDialog';

import { RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE, UPDATE_CANDIDATE_LIST } from '../../../types';
import { notFoundMessage, successMessage, unableMessage, VALIDATION_MESSAGE } from '../../../services/MessageService';
import ConfirmationPopup from '../../../components/ConfirmationPopup';
import { productOneApi } from '../../../services/ApiService';
import { fetchSfpaData } from '../../../actions';
import { filterBlankContactTarget } from '../../../components/ViewSearch/Components/SFPA/utils';
import PopupEditor from '../../Commons/CellEditors/PopupEditor';
import { dataSourceUtils } from '../../../utils/dataSource';
import { checkFilterAndSort, getColorDot, getFlagForLifer } from '../../../utils/common';
import { getFilterParamString, SUCCESS_STATUS_CODE } from '../Utils';
import ActionsPopover from '../../../Containers/Contacts/ActionsPopover';
import ScoresDefinitionPopup from '../../../components/ScoresDefinitionPopup';
import ScoresAdvanceFilterPopup from '../../../components/ScoresAdvanceFilterPopup';
import FeetypeSelection from '../../../components/FeetypeSelection';
import { CompanyNameRenderer } from '../../Companies/Utils';

const productOneStages = ['P1', 'P1 Rejected'];
let gridApi, columnApi;
const ProductOneCandidatesList = props => {
  const {
    id,
    search = {},
    jobTitle,
    confidential,
    // eslint-disable-next-line react/prop-types
    initialLoader,
    location,
    navigate,
    candidateStatus,
    setInitialLoader,
    checkSfpaLock
  } = props;
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [linkedPopup, setLinkedPopup] = useState(false);
  const [linkedInValue, setLinkedInValue] = useState({});
  const [loadingMessage, setLoadingMessage] = useState(null);
  // const [initialLoad, setInitialLoad] = useState(false);
  const [inprogressZoomExtended, setInProgressZoomExtended] = useState(false);
  // const [inprogressTetrasExtended, setInProgressTetrasExtended] = useState(false);
  const [extendedType, setExtendedType] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  // const [canTerminate, setCanTerminate] = useState(false);
  const [filterValue, setFilterValue] = useState({});
  const [filterModel, setFilterModel] = useState([]);
  const [sfpaValidatePopup, setSfpaValidatePopup] = useState(false);
  const [invalidSfpaMessage, setInvalidSfpaMessage] = useState('');
  const [showScoresDefinition, setShowScoresDefinition] = useState(false);
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [candidateParams, setCandidateParams] = useState({});
  const [showReasonPopup, setShowReasonPopup] = useState(false);
  const [isGridReady, setIsGridReady] = useState(false);
  const [isCopyToWorkbenchOpen, setIsCopyToWorkbenchOpen] = useState(false);
  const [resetGrid, setResetGrid] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [contacts, setContacts] = useState([{}]);
  const [requestedUser, setRequestedUser] = useState();
  const [forceLoad, setForceLoad] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const linkedinScraperNotify = useSelector(state => state.rootReducer[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES]);
  const productOneCandidates = useSelector(state => state.rootReducer.productOneCandidates);
  const userData = useSelector(state => state.commonReducer.userData);
  const sfpaData = useSelector(state => state.rootReducer.sfpaData);
  // const socket = useSelector(state => state.rootReducer.socket)
  console.log(contacts, filterModel, isAdmin, sortModel, inprogressZoomExtended);
  useEffect(() => {
    return () => {
      saveColumnStateToLocalStorage(`candidatesColumns-${candidateStatus || ''}`, { columnApi });
      dispatch({ type: 'resetLinkedinScraperEvents' });
      dispatch({
        type: UPDATE_CANDIDATE_LIST,
        payload: { key: 'productOneCandidates', data: undefined }
      });
    };
  }, []);

  useEffect(() => {
    const getSfpaData = async () => {
      checkUserRole();
      // getRowData();
      if (!sfpaData) {
        await fetchSfpaData(dispatch, id, enqueueSnackbar);
      }
      dispatch({ type: 'resetLinkedinScraperEvents' });
    };
    getSfpaData();
  }, [sfpaData]);

  useEffect(() => {
    // if (!productOneCandidates && initialLoad) {
    //   getRowData();
    // }

    // if (socket) {
    //   socket.on(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE, (msg, obj) => {
    //     let candidateFound;
    //     gridApi.forEachNode(node => {
    //       if (node.data?.id === obj?.candidate?.id) {
    //         candidateFound = node;
    //       }
    //     });
    //     if (candidateFound) {
    //       candidateFound.setData(obj.candidate);
    //       dispatch({ type: RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE });
    //       gridApi.refreshCells({ force: true });
    //       let reduxCandidate = [...productOneCandidates];
    //       reduxCandidate = reduxCandidate.map(ele =>
    //         ele.id === obj.candidate.id ? obj.candidate : ele
    //       );
    //       dispatch({
    //         type: UPDATE_CANDIDATE_LIST,
    //         payload: { key: 'productOneCandidates', data: reduxCandidate }
    //       });
    //     }
    //   });
    // }

    if (linkedinScraperNotify) {
      const event = linkedinScraperNotify;
      let candidateFound;
      gridApi.forEachNode(node => {
        if (node.data?.id === event.obj?.candidate?.id) {
          candidateFound = node;
        }
      });
      if (candidateFound) {
        candidateFound.setData(event.obj.candidate);
        dispatch({ type: RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE });
        gridApi.refreshCells({ force: true });
        let reduxCandidate = productOneCandidates;
        reduxCandidate.data = reduxCandidate.data.map(ele => (ele.id === event.obj.candidate.id ? event.obj.candidate : ele));
        dispatch({
          type: UPDATE_CANDIDATE_LIST,
          payload: { key: 'productOneCandidates', data: reduxCandidate }
        });
      }
      dispatch({
        type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE,
        payload: undefined
      });
    }
  }, [linkedinScraperNotify, gridApi, productOneCandidates]);

  const getURLs = {
    listURl: `${API.search}/${id}/candidates`
  };

  const dataSource = {
    getRows: async params => {
      try {
        params.filterModel && setFilterValue(params.filterModel);
        setShowFilterCount(Object.keys(filterValue)?.length);
        if (forceLoad || !productOneCandidates || Object.keys(params.filterModel)?.length !== 0 || params.sortModel?.length !== 0 || resetGrid === 0) {
          params.filterModel && setFilterModel(params.filterModel);
          gridApi.deselectAll();
          const thisValue = { location };
          // setIsLoading(true);
          setInitialLoader(true);
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: getURLs,
            subScreen: true,
            queryString: `&candidateStatus=${candidateStatus}`
          };
          const { status, data } = await dataSourceUtils(obj, getFilterParamString);
          // data.data.forEach((item) => {
          //   item.title_score = 90;
          //   item.industry_score = 50;
          //   item.job_function_score = 70;
          //   item.company_score = 95;
          //   item.tenure_weight = 30;
          //   item.stint_weight = 72;
          //   item.recency_weight = 85;
          //   item['lifer'] = 1.0
          // })
          if (checkFilterAndSort(params.filterModel, params.sortModel)) {
            dispatch({
              type: UPDATE_CANDIDATE_LIST,
              payload: { key: 'productOneCandidates', data }
            });
          }

          if (status === SUCCESS_STATUS_CODE) {
            if (data?.paging?.totalCount === 0) {
              const message = notFoundMessage('records');
              enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(data.data, data.paging?.totalCount);
            setContacts(data.data);
            setRowCount(data.paging?.totalCount);
            isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
          } else {
            params.failCallback();
          }
          // setIsLoading(false);
          setInitialLoader(false);
          gridApi.hideOverlay();
        } else {
          if (productOneCandidates?.paging?.totalCount === 0) {
            const message = notFoundMessage('records');
            enqueueSnackbar(message, { variant: WARNING });
          }
          params.successCallback(productOneCandidates.data, productOneCandidates.paging?.totalCount);
          setContacts(productOneCandidates.data);
          setRowCount(productOneCandidates.paging?.totalCount);
          isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
        }
        setForceLoad(false);
      } catch (e) {
        console.log('Error found in downloadAttachment::', e);
      }
    },
    rowCount: null
  };

  const onGridReady = async params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    loadColumnStateFromLocalStorage(`candidatesColumns-${candidateStatus || ''}`, { columnApi, gridApi });
    // params.api.sizeColumnsToFit();
    setIsGridReady(true);
  };

  const handleCopySearchesToWorkbench = () => {
    setIsCopyToWorkbenchOpen(true);
  };

  const handleCopySearchesToWorkbenchClose = () => {
    setIsCopyToWorkbenchOpen(false);
  };

  const setSelectAllRows = isAllRowsSelected => {
    setIsAllRowsSelected(isAllRowsSelected);
    gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };

  const handleChange = () => {
    setSelectAllRows(!isAllRowsSelected);
  };

  const linkedInPopupHandler = data => {
    setLinkedPopup(true);
    setLinkedInValue(data.value);
  };

  const LinkedInRenderers = params => {
    return <LinkedInRenderer params={params} linkedInPopup={linkedInPopupHandler} />;
  };

  const LinkedInCloseHandler = () => {
    setLinkedPopup(false);
  };

  const HeaderCheckbox = () => {
    return <Checkbox style={{ padding: 0, width: 16, height: 16, color: 'white' }} size='small' color='primary' onChange={e => handleChange(e)} />;
  };

  const checkUserRole = () => {
    let isAdminUser = isLoggedInUserAdmin();
    isAdminUser = isAdminUser === 'true' ? true : false;
    setIsAdmin(isAdminUser);
  };

  const CompanyNameRenderers = params => {
    const status = checkContactFetchingStatus(params);
    if (status) return status;
    return <CompanyNameRenderer hidePopover={true} company={params?.data?.contact?.company} />;
  };

  const NameRenderer = params => {
    return (
      <div className='product-one-score-content'>
        {params?.data?.contact?.linkedin_url && <LinkedInRenderers params={params} linkedInPopup={linkedInPopupHandler} className='d-inline-flex align-self-start' contact={params?.data?.contact} />}
        <ContactNameRenderer
          params={params}
          // handleAddToSearch={handleAddToSearch}
        />
      </div>
    );
  };

  const ColorDotRenderer = params => {
    const status = checkContactFetchingStatus(params);
    if (status) return status;
    return getColorDot(params.value);
  };

  const LiferRenderer = params => {
    return getFlagForLifer(params.value);
  };

  const ActionsRenderer = params => {
    const status = checkContactFetchingStatus(params);
    if (status) return status;
    const label = 'Agree/Disagree';
    const list = [
      {
        label: 'Agree & Add',
        checkDisable: checkProductOneStages(params?.data?.stage) ? false : true,
        onClick: async () => {
          changeStageToTargets(params);
        }
      },
      {
        label: 'Disagree',
        checkDisable: params && params.data && params.data.disagree ? params.data.disagree : '',
        onClick: async () => {
          setRowChange(params, 'disagree');
        }
      }
    ];
    return <ActionsPopover list={list} label={label} />;
  };

  // const getParams = (selectedRows) => {
  //   if (isAllRowsSelected) {
  //     if (candidateStatus) {
  //       return `candidateStatus=${candidateStatus}`;
  //     }
  //     return "";
  //   } else {
  //     return selectedRows.map((row) => `id=${row.id}`).join("&");
  //   }
  // };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    setFilterModel(filterModel);
    setResetGrid(Object.keys(filterModel)?.length);
    let filteredCount = 0;
    const filter = JSON.parse(JSON.stringify(filterModel));
    filter && setFilterValue(filter);
    setShowFilterCount(Object.keys(filterValue)?.length);
    gridApi.forEachNode(() => filteredCount++);
    setRowCount(filteredCount);
    saveColumnStateForFilter();
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    setSortModel(sortModel);
    saveColumnStateForSort();
  };

  const onFirstDataRendered = () => {
    const locationState = location.state;
    if (locationState) {
      const sortModel = locationState.sortModel;
      if (sortModel) {
        gridApi.setSortModel(sortModel);
      }

      const filterModel = locationState.filterModel;
      if (filterModel) {
        gridApi.setFilterModel(filterModel);
      }
    }
  };

  const saveColumnState = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      await saveColumnStateToLocalStorage(`candidatesColumns-${candidateStatus || ''}`, { columnApi, gridApi });
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForFilter = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      await saveColumnStateToLocalStorage(`candidatesColumns-${candidateStatus || ''}`, { columnApi, gridApi }, true, false);
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForSort = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      await saveColumnStateToLocalStorage(`candidatesColumns-${candidateStatus || ''}`, { columnApi, gridApi }, false, true);
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  // const loaderChange = async (value) => {
  //   setIsLoading(value)
  // };

  // const getRowData = async () => {
  //   try {
  //     if (!productOneCandidates) {
  //       const newData = await getProductOneDataFromGalaxy()
  //       if (!newData?.length) {
  //         const message = notFoundMessage("records");
  //         enqueueSnackbar(message, { variant: WARNING });
  //       }
  //       setRowCount(newData?.length)
  //       setInitialLoad(true)
  //       dispatch({
  //         type: UPDATE_CANDIDATE_LIST,
  //         payload: { key: "productOneCandidates", data: newData },
  //       });

  //     } else {
  //       setRowCount(productOneCandidates?.length)
  //       setInitialLoad(true)
  //     }
  //   } catch (e) {
  //     console.log("Error found in getRowData::", e);
  //   }
  // }

  // const getProductOneDataFromGalaxy = async () => {
  //   const subRoute = `${PRODUCTONE_LOAD_CANDIDATES}?id=${id}`
  //   let newData = [];
  //   const { status, data } = await productOneApi(GET, null, subRoute);
  //   if (status === 200) {
  //     newData = data
  //   }
  //   return newData;
  // }

  const getTerminateAccess = () => {
    if ((requestedUser && requestedUser?.id === userData.id) || userData.roles[0] === 'admin') {
      return true;
    }
    return false;
  };

  const validateSfpaInfo = () => {
    const { target_job_functions: functions, target_industries: industries, search_industry_rankings: industryRanking, contact_targets: contacts = [] } = sfpaData;

    const contactTarget = filterBlankContactTarget(contacts);
    let fields = [];
    if (!functions?.length) {
      fields = [...fields, 'Functions'];
    }
    if (!industries?.length || !industryRanking?.length) {
      fields = [...fields, 'Industries or Industry Rankings'];
    }
    if (!contactTarget?.length) {
      fields = [...fields, 'Target Titles'];
    }
    if (!fields?.length) {
      return true;
    }

    const message = `The ${fields.join(', ')} section has missing or incomplete data`;
    setInvalidSfpaMessage(message);
    setSfpaValidatePopup(true);
    return false;
  };

  const handleCloseSfpaPopup = () => {
    setSfpaValidatePopup(false);
    setInvalidSfpaMessage('');
  };

  const handleConfirmSfpaPopup = () => {
    setSfpaValidatePopup(false);
    setInvalidSfpaMessage('');
    navigate(`/searches/${id}/sfpa/target`);
    checkSfpaLock();
  };

  // const getCandidatesFromZoom = async () => {
  //   const isValid = validateSfpaInfo()
  //   if (!isValid) return false;

  //   setLoadingMessage(`Zoom quick ${PRODUCT_ONE_MESSAGE.QUICK_SEARCH_PROCESS_STARTED}`)
  //   setIsLoading(true)
  //   const subRoute = EXTENDED_SEARCH_LOAD_CANDIDATES

  //   const obj = { id: id, "candidates_recs": true, type: PRODUCT_ONE.QUICK, sourceType: PRODUCT_ONE.ZOOM }
  //   const { status, data } = await productOneApi(POST, obj, subRoute);
  //   if (status === 200) {
  //     const message = 'Zoom Quick Search Complete';
  //     enqueueSnackbar(message, {
  //       variant: SUCCESS,
  //     });
  //     setForceLoad(true)
  //     gridApi.onFilterChanged();
  //     // const newData = data.map(ele => ({ ...ele, dq_reason: ele.dq_reason?.split(', ') || [] /* accept: false, disagree: false, isFromGalaxy: false */ }))
  //     // const filteredData = newData?.filter(ele => {
  //     //   return ele.contact_id !== productOneCandidates.find(el => el.contact_id === ele.contact_id)?.contact_id
  //     // })
  //     // setState({ rowCount: [...productOneCandidates, ...filteredData]?.length })
  //     // dispatch({
  //     //   type: UPDATE_CANDIDATE_LIST,
  //     //   payload: { key: "productOneCandidates", data: [...productOneCandidates, ...filteredData] },
  //     // });
  //   } else if (status === 400) {
  //     enqueueSnackbar(data?.message || SFPA_DATA.sfpa_invalid_data, {
  //       variant: ERROR,
  //     });
  //   } else {
  //     const message = getUnableMessage(PRODUCT_ONE.QUICK_LABEL);
  //     enqueueSnackbar(message, {
  //       variant: ERROR,
  //     });
  //   }
  //   dispatch({ type: RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE })
  //   setLoadingMessage('')
  //   setIsLoading(false)
  //   isAllRowsSelected &&
  //     setSelectAllRows(isAllRowsSelected);
  // }
  // const getCandidatesFromZoomExtended = async () => {
  //   const isValid = validateSfpaInfo()
  //   if (!isValid) return false;

  //   setIsLoading(true)
  //   const subRoute = EXTENDED_SEARCH_LOAD_CANDIDATES
  //   const obj = { id: id, "candidates_recs": true, type: PRODUCT_ONE.EXTENDED, sourceType: PRODUCT_ONE.ZOOM }

  //   const { status, data } = await productOneApi(POST, obj, subRoute);
  //   if (status === 200) {
  //     if (data.message) {
  //       const requestedUser = data?.data?.requested_user
  //       setInProgressZoomExtended(true)
  //       setExtendedType(PRODUCT_ONE.ZOOM)
  //       setShowPopup(true)
  //       setRequestedUser(requestedUser)
  //     } else {
  //       enqueueSnackbar(data?.message || PRODUCT_ONE_MESSAGE.STARTED, {
  //         variant: SUCCESS,
  //       });
  //     }
  //   } else if (status === 400) {
  //     enqueueSnackbar(data?.message || SFPA_DATA.sfpa_invalid_data, {
  //       variant: ERROR,
  //     });
  //   } else {
  //     const message = getUnableMessage(PRODUCT_ONE.EXTENDED_LABEL);
  //     enqueueSnackbar(message, {
  //       variant: ERROR,
  //     });
  //   }
  //   setIsLoading(false)
  //   isAllRowsSelected &&
  //     setSelectAllRows(isAllRowsSelected);
  // }

  const getCandidatesFromTetras = async () => {
    const isValid = validateSfpaInfo();
    if (!isValid) return false;

    setLoadingMessage(`Galaxy quick ${PRODUCT_ONE_MESSAGE.QUICK_SEARCH_PROCESS_STARTED}`);
    setIsLoading(true);
    const subRoute = EXTENDED_SEARCH_LOAD_CANDIDATES;
    const obj = {
      id: id,
      candidates_recs: true,
      type: PRODUCT_ONE.QUICK,
      sourceType: PRODUCT_ONE.TATRAS
    };
    const { status, data } = await productOneApi(POST, obj, subRoute);
    if (status === 200) {
      const message = 'Galaxy Quick Search Complete';
      enqueueSnackbar(message, {
        variant: SUCCESS
      });
      setForceLoad(true);
      gridApi.onFilterChanged();
      // const newData = data.map(ele => ({ ...ele, dq_reason: ele.dq_reason?.split(', ') || [] /* accept: false, disagree: false, isFromGalaxy: false */ }))
      // const filteredData = newData?.filter(ele => {
      //   return ele.contact_id !== productOneCandidates.find(el => el.contact_id === ele.contact_id)?.contact_id
      // })
      // setState({ rowCount: [...productOneCandidates, ...filteredData]?.length })
      // dispatch({
      //   type: UPDATE_CANDIDATE_LIST,
      //   payload: { key: "productOneCandidates", data: [...productOneCandidates, ...filteredData] },
      // });
    } else if (status === 400) {
      enqueueSnackbar(data?.message || SFPA_DATA.sfpa_invalid_data, {
        variant: ERROR
      });
    } else {
      const message = getUnableMessage(PRODUCT_ONE.QUICK_LABEL);
      enqueueSnackbar(message, {
        variant: ERROR
      });
    }
    dispatch({ type: RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE });
    setLoadingMessage('');
    setIsLoading(false);
    isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
  };

  const getCandidatesFromTetrasExtended = async () => {
    const isValid = validateSfpaInfo();
    if (!isValid) return false;

    setIsLoading(true);
    const subRoute = EXTENDED_SEARCH_LOAD_CANDIDATES;
    const obj = {
      id: id,
      candidates_recs: true,
      type: PRODUCT_ONE.EXTENDED,
      sourceType: PRODUCT_ONE.TATRAS
    };
    const { status, data } = await productOneApi(POST, obj, subRoute);
    if (status === 200) {
      if (data.message) {
        const requestedUser = data?.data?.requested_user;
        setInProgressZoomExtended(true);
        setExtendedType(PRODUCT_ONE.TATRAS);
        setShowPopup(true);
        setRequestedUser(requestedUser);
      } else {
        enqueueSnackbar(data?.message || PRODUCT_ONE_MESSAGE.STARTED, {
          variant: SUCCESS
        });
      }
    } else if (status === 400) {
      enqueueSnackbar(data?.message || SFPA_DATA.sfpa_invalid_data, {
        variant: ERROR
      });
    } else {
      const message = getUnableMessage(PRODUCT_ONE.EXTENDED_LABEL);
      enqueueSnackbar(message, {
        variant: ERROR
      });
    }
    setIsLoading(false);
    isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
  };

  const getUnableMessage = type => {
    const searchData = search;
    const message = `${type} Search for Job ${searchData.job_number} - ${searchData.company.name} - ${searchData.job_title}, ${PRODUCT_ONE_MESSAGE.UNABLE_TO_FETCH_MESSAGE}`;
    return <span dangerouslySetInnerHTML={{ __html: message }}></span>;
  };

  const getPayloadForProductOne = contacts => {
    const payload = contacts.map(ele => {
      if (ele.stage !== 'Target') {
        ele.reason = null;
      }
      const item = {
        ...ele,
        ...ele.contact,
        dq_reason: ele.dq_reason?.join(', ') || '',
        company_name: ele?.contact?.company?.name,
        stage: ele.disagree === true ? PRODUCT_ONE.REJECTED_STAGE : 'Target'
      };
      if (item.isFromGalaxy === false) {
        delete item.id;
      } else {
        item.id = item.contact_id;
      }
      item.user_id = userData?.id;
      delete item.contact_id;
      delete item.company;
      delete item.contact;
      delete item.disagree;
      // delete item.accept
      delete item.isFromGalaxy;
      return item;
    });
    return payload;
  };

  const changeStageToTargets = async (params, contactObj) => {
    try {
      setIsLoading(true);
      const contacts = [];
      /* In inline edit it will direct update by obj
       else iterate selected rows and change stage to target  */
      if (contactObj) {
        contactObj.reason = null;
        contacts.push(contactObj);
      } else {
        gridApi.forEachNode(node => {
          if (node.selected) {
            node.data.dq_reason = [];
            node.data.disagree = false;
            if (node.data.stage !== 'Target') {
              node.data.reason = null;
            }
            node.data.stage = 'Target';
            contacts.push(node.data);
          }
        });
        if (contacts?.length === 0) {
          params.data.dq_reason = [];
          params.data.disagree = false;
          if (params.data.stage !== 'Target') {
            params.data.reason = null;
          }
          params.data.stage = 'Target';
          contacts.push(params.data);
        }
      }

      // const contactIds = contacts.map(ele => ele.id);
      const payload = getPayloadForProductOne(contacts);
      let duplicatedList = [];
      let successCounter = 0;
      for await (const [, contact] of payload.entries()) {
        const subRoute = `searches/${id}/candidate-galaxy`;
        const { status, data } = await productOneApi(POST, contact, subRoute);
        if (status === 200) {
          successCounter++;
          if (data.candidatesDuplicate?.length) {
            // candidateId = data.candidatesDuplicate[0];
            duplicatedList = [...duplicatedList, data.candidatesDuplicate[0]];
          }
        }
      }
      if (successCounter) {
        const message = successMessage('Details', VALIDATION_MESSAGE.updated_message);
        enqueueSnackbar(message, {
          variant: SUCCESS
        });
      } else {
        const message = unableMessage('update', 'details');
        enqueueSnackbar(message, {
          variant: ERROR
        });
      }

      let newContactData = productOneCandidates.data.map(ele => {
        const contact = contacts.find(el => el.id === ele.id);
        if (contact) {
          return {
            ...contact,
            stage: contact.disagree === true ? PRODUCT_ONE.REJECTED_STAGE : 'Target'
          };
        } else {
          return ele;
        }
      });

      dispatch({
        type: UPDATE_CANDIDATE_LIST,
        payload: {
          key: 'productOneCandidates',
          data: { data: newContactData }
        }
      });

      gridApi.refreshCells({ force: true });
      setIsLoading(false);
      gridApi.forEachNode(rowNode => {
        let contact = contacts.find(el => el.id === rowNode.data.id);
        if (contact) {
          let selectable = checkProductOneStages(contact.disagree === true ? PRODUCT_ONE.REJECTED_STAGE : 'Target') ? true : false;
          rowNode.setRowSelectable(selectable);
          rowNode.setSelected(false);
        }
      });
      gridApi.refreshCells({ force: true });
      // gridApi.onFilterChanged();
      dispatch({ type: RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE });
      // const newContactData = productOneCandidates?.filter(ele => !(contactIds.includes(ele.id) || duplicatedList.includes(ele.contact_id)))
      // const galaxyCandidates = await getProductOneDataFromGalaxy();
      // if (galaxyCandidates?.length) {
      //   const filteredData = galaxyCandidates?.filter(ele => {
      //     return !(ele.id === newContactData.find(el => el.id === ele.id)?.id);
      //   })
      //   const combinedData = [...filteredData, ...newContactData];
      //   dispatch({
      //     type: UPDATE_CANDIDATE_LIST,
      //     payload: { key: "productOneCandidates", data: combinedData },
      //   });
      //   dispatch({ type: RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE })
      //   setState({ rowCount: combinedData?.length })
      // }
      // setIsLoading(false)
      // isAllRowsSelected &&
      //   setSelectAllRows(isAllRowsSelected);
    } catch (e) {
      console.log('Error found in changeStageToTargets::', e);
    }
  };

  const setRowChange = async (params, field, value) => {
    params.data[field] = value ? value : params.newValue;
    let contactData;
    for (let ele of productOneCandidates.data) {
      if (ele.id === params.data.id) {
        ele[field] = value ? value : params.newValue;
        if (field === 'dq_reason') {
          ele.disagree = true;
          params.data['disagree'] = true;
          params.data.stage = PRODUCT_ONE.REJECTED_STAGE;
        }
        if (field === 'disagree') {
          params.data['disagree'] = false;
        }
        contactData = ele;
        break;
      }
    }

    if (field === 'disagree') {
      setCandidateParams(params);
      setShowReasonPopup(true);
      // params.api.startEditingCell({
      //   rowIndex: params.node.rowIndex,
      //   colKey: 'dq_reason'
      // })
      return;
    }
    await changeStageToTargets(params, contactData);
  };

  const handleTerminateExtendedSearch = async type => {
    try {
      setIsLoading(true);
      const subRoute = `${PRODUCTONE_TERMINATE_EXTENDED}?id=${id}`;
      const { status, data } = await productOneApi(POST, { sourceType: type }, subRoute);
      if (status === 200) {
        enqueueSnackbar(data.message, {
          variant: SUCCESS
        });
      } else {
        const message = unableMessage('Terminate', 'extended search');
        enqueueSnackbar(message, {
          variant: ERROR
        });
      }
      setIsLoading(false);
    } catch (e) {
      console.log('Error found in handleTerminateExtendedSearch::', e);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setRequestedUser(null);
  };

  const handleCloseShowDefinition = () => {
    setShowScoresDefinition(false);
  };

  const handleCloseAdvanceFilter = () => {
    setShowAdvanceFilter(false);
  };

  const handleConfirmPopup = () => {
    setShowPopup(false);
    setRequestedUser(null);
    handleTerminateExtendedSearch(extendedType);
  };
  const resetFilter = async () => {
    gridApi.setFilterModel(null);
    const customFilterField = columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item?.filter === 'CustomFilter') {
        gridApi.destroyFilter(item.field);
      }
    });
  };
  const advanceFilter = async () => {
    setShowAdvanceFilter(true);
  };

  const scoresDefinition = async () => {
    setShowScoresDefinition(true);
  };
  const resetSort = async () => {
    gridApi.setSortModel(null);
  };

  const checkProductOneStages = stage => {
    return stage && productOneStages.includes(stage);
  };

  const closeReasonPopup = () => {
    setCandidateParams({});
    setShowReasonPopup(false);
  };

  return (
    <div className='all-candidates d-flex flex-column h-100'>
      <Header companyDetails={search.company} company={search.company && search.company.name} jobNumber={search.job_number} jobTitle={jobTitle} confidential={confidential}>
        <LinkedInDialog open={linkedPopup} linkedInValue={linkedInValue} handleClose={LinkedInCloseHandler}></LinkedInDialog>
        <div className='d-flex'>
          <Button onClick={handleCopySearchesToWorkbench} className='action-button mr-3' variant='outlined' color='primary'>
            Add to Search Workbench
          </Button>
        </div>
      </Header>
      <div className='d-flex flex-column w-100 h-100'>
        {showPopup && (
          <ConfirmationPopup
            header={`The Extended Search in ${extendedType === PRODUCT_ONE.ZOOM ? 'zoom' : 'galaxy'} is currently being run by ${requestedUser?.name}`}
            message={`${getTerminateAccess() ? PRODUCT_ONE_MESSAGE.CONFIRM_TERMINATE : ''}`}
            onClose={handleClosePopup}
            onConfirm={handleClosePopup}
            onCancel={handleConfirmPopup}
            disabledCancel={!getTerminateAccess()}
            confirmText={`${getTerminateAccess() ? 'No' : 'Close'}`}
            cancelText='Yes'
          />
        )}
        {showScoresDefinition && <ScoresDefinitionPopup onClose={handleCloseShowDefinition} showScoresDefinition={showScoresDefinition}></ScoresDefinitionPopup>}
        {showAdvanceFilter && <ScoresAdvanceFilterPopup onClose={handleCloseAdvanceFilter} gridApi={gridApi} columnApi={columnApi}></ScoresAdvanceFilterPopup>}
        {sfpaValidatePopup && (
          <ConfirmationPopup
            header={PRODUCT_ONE_MESSAGE.VALIDATE_POPUP_HEADER}
            message={invalidSfpaMessage}
            onClose={handleCloseSfpaPopup}
            onConfirm={handleConfirmSfpaPopup}
            onCancel={handleCloseSfpaPopup}
            confirmText='Go to SFPA'
            cancelText='Close'
          />
        )}
        {isCopyToWorkbenchOpen && <AddContactsToWorkbench open={isCopyToWorkbenchOpen} searchIds={[id]} onClose={handleCopySearchesToWorkbenchClose} sourceWorkbenchId={null} type='Searches' />}
        {showReasonPopup && (
          <PopupEditor
            InputComponent={FeetypeSelection}
            title='Reason'
            enqueueSnackbar={enqueueSnackbar}
            validateDqReason={true}
            type='PRODUCT_ONE_REASONS'
            sort={true}
            placeholder='Reason'
            value={[]}
            stopEditing={closeReasonPopup}
            onSave={value => {
              setRowChange(candidateParams, 'dq_reason', value);
              closeReasonPopup();
            }}
          />
        )}
        {!initialLoader && <Loader className={'sync-message'} loadingMessage={loadingMessage} show={isLoading} />}
        <div className='all-candidates-actions d-flex'>
          <Button
            disabled={!isRowSelected}
            onClick={() => {
              changeStageToTargets();
            }}
            variant='outlined'
            color='primary'
            className='mr-2'
          >
            Add to Target
          </Button>
          {/* <Button
              onClick={getCandidatesFromZoom}
              variant="outlined"
              color="primary"
              className="mr-2"
            >
              Zoom - Quick Search
            </Button> */}

          <Button onClick={getCandidatesFromTetras} variant='outlined' color='primary' className='mr-2'>
            Quick Search
          </Button>
          {/* <Button
              variant="outlined"
              color="primary"
              className="mr-2"
              onClick={getCandidatesFromZoomExtended}
            >
              Zoom - Extended Search
            </Button> */}
          <Button variant='outlined' color='primary' className='mr-2' onClick={getCandidatesFromTetrasExtended}>
            Extended Search
          </Button>
        </div>

        <div className='d-flex justify-content-between '>
          <div className='count-container pt-3'>
            <div className='action-container' onClick={() => scoresDefinition()}>
              <span className='action-text'>Scores Definition</span>
            </div>
          </div>

          <div className='count-container pt-3'>
            <div className='action-container' onClick={() => advanceFilter()}>
              <span className='action-text'>Advanced Filter</span>
            </div>
            <div className='action-container' onClick={() => resetFilter()}>
              <span className='action-text'>Reset Filter</span>
            </div>
            <div className='action-container' onClick={() => resetSort()}>
              <span className='action-text'>Reset Sort</span>
            </div>
            <Typography>Total count: {rowCount}</Typography>
          </div>
        </div>
        {/* {productOneCandidates ?  */}
        <div className='list-view'>
          {isGridReady && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} showFilterCount={showFilterCount} filterModel={filterValue} />}
          <div id='myGrid' className='ag-theme-alpine'>
            <AgGridReact
              onGridReady={onGridReady}
              enableBrowserTooltips={true}
              defaultColDef={{
                resizable: true,
                sortable: true,
                minWidth: 150,
                sortingOrder: ['asc', 'desc', null]
              }}
              isRowSelectable={params => {
                const status = checkContactFetchingStatus(params);
                if (status) return false;
                if (!checkProductOneStages(params?.data?.stage)) return false;
                return true;
              }}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={'CustomLoadingOverlayComponent'}
              frameworkComponents={{
                CustomLoadingOverlayComponent,
                CustomFilter,
                HeaderCheckbox: HeaderCheckbox,
                NameRenderer: NameRenderer,
                LiferRenderer: LiferRenderer,
                CompanyNameRenderer: CompanyNameRenderers,
                ColorDotRenderer: ColorDotRenderer,
                ActionsRenderer: ActionsRenderer,
                GenericCellEditor,
                ReasonEditor,
                PopupEditor
              }}
              suppressMenuHide={true}
              getRowNodeId={data => data.id}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              // rowData={productOneCandidates}
              columnDefs={productOneCandidateColumnDef(setRowChange, enqueueSnackbar)}
              paginationPageSize={PAGE_LIMIT}
              rowModelType={'infinite'}
              rowSelection={'multiple'}
              onRowSelected={() => {
                setIsRowSelected(gridApi.getSelectedRows()?.length > 0 ? true : false);
              }}
              datasource={dataSource}
              suppressRowClickSelection={true}
              suppressDragLeaveHidesColumns={true}
              onFilterChanged={onFilterChanged}
              onFirstDataRendered={onFirstDataRendered}
              onDisplayedColumnsChanged={saveColumnState}
              onDragStopped={saveColumnState}
              onSortChanged={onSortChanged}
            ></AgGridReact>
          </div>
        </div>
        {/* : null} */}
      </div>
    </div>
  );
};

ProductOneCandidatesList.propTypes = {
  id: PropTypes.string,
  search: PropTypes.object,
  jobTitle: PropTypes.string,
  confidential: PropTypes.bool,
  candidateStatus: PropTypes.string,
  location: PropTypes.object,
  navigate: PropTypes.func,
  initialLoader: PropTypes.bool,
  checkSfpaLock: PropTypes.func,
  setInitialLoader: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(null, mapDispatchToProps)(ProductOneCandidatesList);
