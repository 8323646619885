//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import Attribute from './Attribute';
// import { searchDataApi } from '../../../../../../services/ApiService';
// import { getUpdateSFPAPayload } from '../../utils';
// import { PUT } from '../../../../../../services/constantService';
// import { useParams } from 'react-router-dom';
import { oldSearchHandler } from '../../../../../ActivityLog/utils';
import AttributeV1 from './AttributeV1';
import { searchDataApi } from '../../../../../../services/ApiService';
import { ERROR, PUT, SUCCESS } from '../../../../../../services/constantService';
import { useParams } from 'react-router-dom';
import { getDefaultValues } from '../../utils';
import { successMessage, unableMessage, VALIDATION_MESSAGE } from '../../../../../../services/MessageService';

function Attributes(props) {
  const { currentValues = {}, register, setValue, getData, searchCreatedAt, setSfpaData, setCurrentValues, setLoading, enqueueSnackbar, readOnly } = props;
  const { id } = useParams();
  const [attributes, setAttributes] = useState([]);
  useEffect(() => {
    const attributes = currentValues.attributes;
    for (let i = attributes?.length; i < 4; i++) {
      attributes.push({});
    }
    setAttributes(attributes);
  }, [currentValues.attributes]);

  useEffect(() => {
    register('attributes');
    setValue('attributes', attributes);
  }, [register, attributes, setValue]);

  const updateAttribute = async (attribute, index, apiCall = false, loader, setAutoSaveLoading) => {
    loader && setLoading(true);
    let updatedAttributes = [...attributes];
    if (index != null) {
      const search_attribute_id = updatedAttributes[index].search_attribute_id;
      updatedAttributes[index] = {
        search_attribute_id: search_attribute_id,
        ...attribute
      };
      setAttributes(updatedAttributes);
    }
    if (apiCall) {
      const updatedDate = new Date().toISOString();
      const payload = getAttributePayload(updatedAttributes);
      payload.updated_at = updatedDate;
      if (setAutoSaveLoading) {
        setAutoSaveLoading(true);
      }
      const { status, data } = await searchDataApi(PUT, id, payload, 'sfpa');
      if (status === 200) {
        const message = successMessage('SFPA', VALIDATION_MESSAGE.updated_message);
        !setAutoSaveLoading && enqueueSnackbar(data?.message || message, { variant: SUCCESS });
        /* updatedAttributes = updatedAttributes.map(item => {
          return {
            ...item,
            updated_at: updatedDate,
          }
        }) */
        updatedAttributes = await getSfpaAttributes();
        setAttributes(updatedAttributes);
        setCurrentValues({ ...currentValues, attributes: updatedAttributes });
        setSfpaData({ attributes: updatedAttributes });
      } else {
        const message = unableMessage('SFPA', 'update');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      loader && setLoading(false);
      if (setAutoSaveLoading) {
        setTimeout(() => {
          setAutoSaveLoading(false);
        }, 500);
      }
    }
    // setLoading(false);
  };

  const getSfpaAttributes = async () => {
    const data = await getData(false, true);
    if (!data) return;
    const defaultValues = getDefaultValues(data);
    return defaultValues.attributes;
  };

  const getAttributePayload = payload => {
    let newPayload = payload
      ?.filter(item => item && item.id)
      .map((item, index) => {
        const attribute = {
          definition: item.definition,
          id: item.id,
          name: item.name
        };
        let data = {
          attribute,
          attribute_id: item.id,
          position: index,
          id: item.search_attribute_id
        };
        Object.prototype.hasOwnProperty.call(item, 'question') && (data.question = item.question);
        Object.prototype.hasOwnProperty.call(item, 'questions') && (data.questions = item.questions);
        return data;
      });
    return { attributes: newPayload };
  };

  const getSelectedIds = () => {
    return attributes.map(item => item.id)?.filter(id => id);
  };

  return (
    <div className={`tab-content d-flex d-flex attributes-content ${readOnly ? 'section-content read-only' : ''}`}>
      <div className='attributes-list pr-5'>
        {attributes &&
          attributes.map((item, index) => {
            return oldSearchHandler(searchCreatedAt) ? (
              <AttributeV1 readOnly={readOnly} key={index} selectedOptions={getSelectedIds()} attributeIndex={index} attribute={item} position={index} updateAttribute={item.updated_at} />
            ) : (
              <Attribute
                readOnly={readOnly}
                key={index}
                selectedOptions={getSelectedIds()}
                attributeIndex={index}
                attribute={item}
                position={index}
                updateAttribute={updateAttribute}
                updatedDate={item.updated_at}
              />
            );
          })}
      </div>
    </div>
  );
}

Attributes.propTypes = {
  register: PropTypes.func,
  currentValues: PropTypes.object,
  setValue: PropTypes.func,
  searchCreatedAt: PropTypes.bool,
  readOnly: PropTypes.bool,
  setSfpaData: PropTypes.func,
  setCurrentValues: PropTypes.func,
  setLoading: PropTypes.func,
  getData: PropTypes.func,
  enqueueSnackbar: PropTypes.func
};

export default Attributes;
