//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useRef } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

const DraggableComponent = ({ id, index, moveNode, content, componentType }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: componentType,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveNode(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });
  /* eslint-disable no-unused-vars */
  const [{ isDragging }, drag, preview] = useDrag({
    item: { id, index },
    type: componentType,
    collect: () => ({})
  });

  const dragNDrop = node => {
    drag(node);
    drop(ref);
  };

  return (
    <div ref={ref} style={{ width: '100%' }}>
      {content(dragNDrop, preview)}
    </div>
  );
};

DraggableComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number,
  moveNode: PropTypes.func,
  content: PropTypes.func,
  componentType: PropTypes.string
};

export default DraggableComponent;
