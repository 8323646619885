//-----------------------------------------------------------// In-built Imports // ------------------------------
import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CustomDropdown from '../../CustomDropdown';
// import { useDispatch, useSelector } from "react-redux";

//-----------------------------------------------------------// Internal Imports // ------------------------------
import PropTypes from 'prop-types';
// import { fetchPickList } from "../../../../actions";

const ExportData = props => {
  const { label } = props;

  // const [options, setOptions] = useState([]);
  // const exportDataTypeSelection = useSelector(state => state.commonReducer.ExportDataType)
  // const dispatch = useDispatch()

  // useEffect(() => {
  //   if (!exportDataTypeSelection) {
  //     dispatch(fetchPickList('export-data-type', 'exportDataType'))
  //   } else {
  //     setOptions(exportDataTypeSelection);
  //   }
  // }, [exportDataTypeSelection, dispatch])

  return <CustomDropdown className='w-100' options={filterTypes} isCheckBox={true} label={label} disableCloseOnSelect multiple={true} isIcon={true} />;
};

const filterTypes = [
  { logo: AccountCircleIcon, label: 'Data 1' },
  { logo: AccountCircleIcon, label: 'Data 2' },
  { logo: AccountCircleIcon, label: 'Data 3' },
  { logo: AccountCircleIcon, label: 'Data 4' }
];

ExportData.propTypes = {
  label: PropTypes.string
};
export default ExportData;
