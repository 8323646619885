import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearchData(state, action) {
      state.searchData = action.payload;
    },
    updateSfpaData(state, action) {
      state.sfpaData = action.payload;
    },
    basicPartnerData(state, action) {
      state.basicPartner = action.payload;
    },
    partners(state, action) {
      state.partners = action.payload;
    },
    resetSearchState: () => initialState
  }
});

export const searchActions = searchSlice.actions;

export default searchSlice.reducer;
