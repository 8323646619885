//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useRef, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { CircularProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import PropTypes from 'prop-types';
// import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { getMethodWithCancelTokenApi } from '../../services/ApiService';
import { IGN_API } from '../../services/constantService';
import { SEARCH_TIMEOUT } from '../../utils/common';
import './index.scss';

const CancelToken = axios.CancelToken;

const Search = props => {
  const { placeholder = 'Search', type, ...rest } = props;
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  let call = useRef();

  useEffect(() => {
    if (call.current) {
      call.current.cancel();
    }
    if (value?.length < 3) {
      setOptions([]);
      setLoading(false);
      return;
    }

    let url = `${IGN_API.picklists}/${type}`;
    if (rest.projectType) {
      url = `${IGN_API.picklists}/${type}?projectsType=${rest.projectType}`;
    }
    // if (value?.length > 2) { setListShow(true) }
    call.current = CancelToken.source();
    setLoading(true);
    const timer = setTimeout(() => {
      getMethodWithCancelTokenApi(url, { name: value }, {}, {}, call.current.token).then(response => {
        const { status, data } = response;
        if (status === 200) {
          setOptions(JSON.parse(data)?.data);
        }
        setLoading(false);
      });
    }, SEARCH_TIMEOUT);
    return () => clearTimeout(timer);
  }, [value]);

  useEffect(() => {
    if (rest?.projectType) {
      setOptions([]);
    }
  }, [rest?.projectType]);

  return (
    <>
      <div id='contact-search'>
        <Autocomplete
          {...rest}
          className='grid-search'
          freeSolo
          filterOptions={options => options}
          loading={isLoading}
          options={options ?? []}
          getOptionLabel={option => {
            if (typeof option === 'string') {
              return option;
            } else if (type === 'quicksearches') {
              return option.job_title + ' ' + option.job_number;
            }
          }}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <div>
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography>{option.job_title}</Typography>
                      <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>({option.job_number})</Typography>
                    </div>
                    <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>({option.ign_companies.name})</Typography>
                  </div>
                </div>
              </li>
            );
          }}
          onInputChange={(e, val) => setValue(val)}
          value={props.value ? props.value : ''}
          renderInput={params => (
            <TextField
              {...params}
              variant='standard'
              className='search'
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                endAdornment: isLoading ? <CircularProgress className='loader-class' size={20} /> : params.InputProps.endAdornment
              }}
            />
          )}
        />
        <span
          onClick={event => {
            props.onChange(event, value);
          }}
          className={'search-icon p-0'}
        >
          <SearchIcon />
        </span>
      </div>
    </>
  );
};

Search.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  setListShow: PropTypes.func,
  type: PropTypes.string
};

export default Search;
