import { LocationOn, ShowChart } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { React, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Link } from 'react-router-dom';
import CustomButton from '../../../components/common/CustomButton';
import CustomPhoneInput from '../../../components/common/CustomPhoneInput';
import DrawerComponent from '../../../components/common/Drawer';
import InputField from '../../../components/common/InputField';
import Loader from '../../../components/common/Loader';
import { getUserIp } from '../../../services/ApiService';
import { POST, PUBLIC_SUB_ROUTES } from '../../../services/constantService';
import { PortalDataApi } from '../../../services/PublicApiService';
import { DEFAULT_DATE_FORMAT, formatDateWithMomentTimeZone, getDifferenceInDays } from '../../../utils/date';
import './jobViewMore.scss';

function JobViewMore(props) {
  const [phoneNumbers, setPhoneNumbers] = useState([{ phone_type: '', phone_number: '', is_primary: false }]);
  const { data, isOpen = false, handleClose = () => {}, consent, setConsent } = props;
  const [ipAddress, setIpAddress] = useState('');
  const [name, setName] = useState('');
  const [formData, setFormData] = useState({});
  const [file, setFile] = useState(null);
  const [base64, setBase64] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (index, field, value) => {
    let updatedPhoneNumbers = phoneNumbers.map((phoneNumber, i) => {
      if (i === index) {
        return { ...phoneNumber, [field]: value, ign_translate_value: value };
      }
      if (field === 'is_primary') {
        return { ...phoneNumber, is_primary: false };
      }
      return phoneNumber;
    });
    // if (field === "is_primary") {
    //   setSelectedOptionForPhone(index);
    // }
    setPhoneNumbers(updatedPhoneNumbers);
  };
  // const navigate = useNavigate();

  const getIP = async () => {
    try {
      const response = await getUserIp();
      setIpAddress(response?.data?.ip);
    } catch (error) {
      enqueueSnackbar(error, 'error');
    }
  };
  useEffect(() => {
    getIP();
  }, []);

  const handleInputChange = (key, value) => {
    formData[key] = value;
    setFormData(formData);
  };

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const contactPayload = {
        ...formData,
        phonenumber: phoneNumbers[0].phone_number
      };
      const projectId = data?.ign_projects?.id;

      const payload = {
        project_id: projectId,
        project_name: data?.ign_projects?.job_title ?? '',
        contact: JSON.stringify(contactPayload),
        base64: base64
      };

      //creating contact and candidate == this api will return contact {}
      const res = await PortalDataApi(POST, 'parse-and-create', payload, '', 'json', {
        'Content-type': 'application/json'
      });
      if (res?.message) {
        enqueueSnackbar(res?.message, 'success');
      }
      // console.log({ payload });
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      setBase64(reader.result);
    };
    reader.onerror = function (error) {
      enqueueSnackbar(error, 'error');
    };
  }

  const handleFileChange = async file => {
    await getBase64(file);

    setFile(file);
  };

  const handleFileDelete = () => {
    setFile(null);
  };
  return (
    <div className='w-100'>
      <DrawerComponent anchor={'right'} width={'65vw'} id='job-view-more' open={isOpen} top={'10vh'} overFlowY={'scroll'} onClose={handleClose}>
        <div className='job-view-container'>
          <div className='job-view-header'>
            <div className='job-title-part m-4'>
              <div className='bold fs-14'> {data?.ign_projects?.job_title} </div>
              <div className='fs-12'> {data?.ign_projects?.ign_companies?.name} </div>
            </div>
            <div className='stat-part'>
              <div className='stat m-4'>
                <Typography component={'div'} className='light' variant='subtitle' color='initial'>
                  Job Location
                </Typography>
                <Typography variant='caption' component={'div'} color='initial'>
                  <LocationOn className='fs-10' /> {data?.ign_projects?.location}
                </Typography>
                <Typography variant='caption' component={'div'} color='initial' className='fs-10'>
                  Metropolitan Area
                </Typography>
              </div>
              <div className='stat m-4'>
                <Typography component={'div'} variant='subtitle' className='light' color='initial'>
                  Days Open
                </Typography>
                <Typography variant='caption' component={'div'} color='initial'>
                  <ShowChart className='fs-10' /> {getDifferenceInDays(new Date(), data?.published_at)} Days
                </Typography>
                <Typography variant='caption' component={'div'} color='initial' className='fs-10'>
                  Start Date: {formatDateWithMomentTimeZone(data?.published_at, DEFAULT_DATE_FORMAT)}
                </Typography>
              </div>
              <div className='stat m-4'>
                <Typography component={'div'} className='light' variant='subtitle' color='initial'>
                  Job No
                </Typography>
                <Typography className='job-number-id fs-12' variant='caption' component={'div'} color='initial'>
                  {data?.ign_projects?.job_number}
                </Typography>
              </div>
            </div>
          </div>
          <div className='tab-view'>
            <Divider />
            <div className='tab-title pt-1 pl-4 fs-12'>
              Job Description
              <Divider color='primary.main' className='mt-1' />
            </div>
            <Divider />
          </div>
          <div className='job-posting p-4'>
            <div className='fs-12' dangerouslySetInnerHTML={{ __html: data?.posted_profile }} />
          </div>
          <Grid container spacing={2} rowSpacing={2} className='job-form p-4' component={'form'} onSubmit={handleSubmit}>
            <Loader show={isLoading} />
            <Grid item sx={12} md={6}>
              <InputField size='small' className='w-100' label='First Name' name='first_name' required onChange={e => handleInputChange('first_name', e.target.value)}></InputField>
            </Grid>
            <Grid item sx={12} md={6}>
              <InputField size='small' className='w-100' label='last Name' name='last_name' required onChange={e => handleInputChange('last_name', e.target.value)}></InputField>
            </Grid>
            <Grid item sx={12} md={6}>
              <InputField size='small' className='w-100' label='Email' name='email' required onChange={e => handleInputChange('email', e.target.value)}></InputField>
            </Grid>
            <Grid item sx={12} md={6}>
              <CustomPhoneInput
                id='outlined-basic'
                variant='outlined'
                size='small'
                placeholder='Phone Number'
                name='phone_number'
                value={phoneNumbers[0].phone_number || ''}
                onChange={e => handleChange(0, 'phone_number', e)}
              />
            </Grid>
            <Grid item fullWidth sx={12} md={12}>
              <FileUploader handleChange={handleFileChange} name='file' classes='w-100'>
                <CustomButton type='tertiary add-resume-button' variant={'outlined'} fullWidth customWidth={'100%'} width={'100%'} className='w-100' required={true} buttonText={'Add Resume*'} />
              </FileUploader>
              {file && file.name && (
                <div className='m-2'>
                  {file.name}
                  <CloseIcon onClick={handleFileDelete} />
                </div>
              )}
            </Grid>
            <Grid item fullWidth sx={12} md={12}>
              <Typography value={'sample text'} spacing={1} />
              <Stack direction='row' spacing={1} justifyContent={'flex-start'} alignItems={'center'}>
                <Checkbox type='checkbox' className='fs-12' checked={consent} onChange={e => setConsent(e.target.checked)} />
                <Typography className='fs-12'>
                  I consent to the processing of my personal data in accordance with the{' '}
                  <Link to={PUBLIC_SUB_ROUTES.privacyPolicy} rel='noopener noreferrer' target='_blank' className='fs-12'>
                    Privacy Policy
                  </Link>
                </Typography>
              </Stack>
              <Stack direction={'row'} justifyContent={'flex-start'} gap={'100px'} padding={'0 38px'}>
                <Typography
                  className='fs-12'
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px'
                  }}
                >
                  Full Name:
                  <TextField value={name} onChange={e => setName(e.target.value)} variant='standard' />
                </Typography>
                <Typography className='fs-12'>IP: {ipAddress}</Typography>
              </Stack>
            </Grid>
            <Grid item fullWidth sx={12} md={12}>
              <CustomButton
                fullWidth
                className='w-100'
                buttonText={'Submit'}
                buttonType={'submit'}
                // onClick={e => {
                //   handleSubmit(e);
                // }}
                disabled={!consent || !name || !file}
              />
            </Grid>
          </Grid>
        </div>
      </DrawerComponent>
    </div>
  );
}
JobViewMore.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  consent: PropTypes.bool,
  setConsent: PropTypes.func
};
export default JobViewMore;
