import React, { useState } from 'react';
import DynamicTabLayout from '../../components/DynamicTabs/TabLayout';
import { ROUTES } from '../../services/constantService';
import List from './List';

const Container = () => {
  const TabData = [
    {
      label: 'Companies',
      content: '',
      subTabs: []
    }
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className='pt-8'>
      <DynamicTabLayout
        navItems={TabData}
        baseRoute={ROUTES.companiesList}
        isDrawer={true}
        isDrawerCss={true}
        className='p-8 pt-28'
        activeTab={activeTab}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        setActiveTab={setActiveTab}
      >
        <React.Fragment index='Companies'>
          <List></List>
        </React.Fragment>
      </DynamicTabLayout>
    </div>
  );
};

export default Container;
