import CloseIcon from '@mui/icons-material/Close';
import { Box, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../../../components/common/CustomTable';
import FileDragUpload from '../../../../components/common/FileUpload';

const ReportTemplateList = props => {
  const { data = [], uploadReportTemplateSetup = () => {}, removeReportTemplateSetup = () => {} } = props;
  const { t } = useTranslation();
  const isReportUploaded = reportCode => {
    return data.some(item => item.report_code == reportCode);
  };
  const reportList = [
    { title: 'Interview Preparation Report', reportCode: 'IGN_IPR', allowedExtension: ['Docx'] },
    { title: 'Candidate Details Report', reportCode: 'IGN_CDR', allowedExtension: ['Docx'] },
    { title: 'Search Status Report', reportCode: 'IGN_SSR', allowedExtension: ['Docx'] },
    { title: 'Search Roadmap Report', reportCode: 'IGN_SRR', allowedExtension: ['Docx'] },
    { title: 'Position Profile Report', reportCode: 'IGN_PPR', allowedExtension: ['Docx'] }
  ];
  return (
    <Box className='bg-light p-3'>
      <Box className='fs-20'>Report Template</Box>
      <Box className='py-3'>
        <CustomTable
          headerData={['', t('reportTemplateSetup.reportName'), t('reportTemplateSetup.allowedExtension'), t('reportTemplateSetup.action')]}
          title={t('reportTemplateSetup.uploadAttachReportTemplate')}
          showAddAnotherButton={false}
          rows={data}
          dataList={data}
        >
          {reportList &&
            reportList.length > 0 &&
            reportList.map((item, index) => (
              <TableRow key={index}>
                <TableCell className='text-center input-field-data p-1'>{index + 1}</TableCell>
                <TableCell className='text-center input-field-data p-1'>{item.title}</TableCell>
                <TableCell className='text-center input-field-data p-1'>{item.allowedExtension.toString()}</TableCell>
                <TableCell className='py-1'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <FileDragUpload
                      label={''}
                      handleChangeDocument={file => uploadReportTemplateSetup(file, item.reportCode)}
                      width={'auto'}
                      horizontal={true}
                      className={'border-0'}
                      showFileName={false}
                      acceptedFileTypes={['application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                    />
                    {isReportUploaded(item.reportCode) && <CloseIcon fontSize='small' color='error' className='cursor-pointer' onClick={() => removeReportTemplateSetup(item.reportCode)} />}
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </CustomTable>
      </Box>
    </Box>
  );
};

ReportTemplateList.propTypes = {
  data: PropTypes.object,
  uploadReportTemplateSetup: PropTypes.func,
  removeReportTemplateSetup: PropTypes.func
};

export default ReportTemplateList;
