//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchPickList } from '../../actions';
import { PRODUCT_ONE } from '../../services/constantService';

function CandidateStageSelection(props) {
  const { className = 'w-100', label = 'Stage', placeholder = '', InputLabelProps, inputRef, required = false, setActivityReasonOptions, watch, hideP1RejectedStage = false, ...rest } = props;
  const [options, setOptions] = useState([]);

  const candidateStages = useSelector(state => state.commonReducer.candidateStages);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!candidateStages) {
      dispatch(fetchPickList('candidatestages', 'candidateStages'));
    } else {
      const filteredStages = hideP1RejectedStage ? candidateStages?.filter(ele => ele.name !== PRODUCT_ONE.REJECTED_STAGE) : candidateStages;
      setOptions(filteredStages);
    }
  }, [candidateStages, dispatch]);

  useEffect(() => {
    if (watch) {
      const candidateStage = watch('stage');
      if (candidateStage) {
        const reaasonArray = options?.find(item => item.name === candidateStage)?.reason;
        if (reaasonArray) {
          setActivityReasonOptions(reaasonArray);
        }
      }
    }
  }, [watch]);

  return (
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      getOptionSelected={(option, value) => {
        let isEqual = false;
        if (typeof value === 'string') {
          isEqual = option.name === value;
        } else if (value) {
          isEqual = option.name === value.name;
        }
        if (isEqual && setActivityReasonOptions) {
          setActivityReasonOptions(option.reason);
        }
        return isEqual;
      }}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      renderInput={params => <TextField {...params} required={required} inputRef={inputRef} InputLabelProps={InputLabelProps} label={label} placeholder={placeholder} />}
    />
  );
}

CandidateStageSelection.propTypes = {
  register: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  inputRef: PropTypes.object,
  setActivityReasonOptions: PropTypes.func,
  watch: PropTypes.func,
  hideP1RejectedStage: PropTypes.bool
};

export default CandidateStageSelection;
