import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTagData } from '../../../actions';
import CustomPopup from '../../../components/common/CustomPopup';
import TagSelection from '../../../components/common/FunctionalComponents/TagSelection';
import Loader from '../../../components/common/Loader';
import { ignCompanyDataApi, ignContactDataApi, projectTagsApi } from '../../../services/ApiService';
import { ERROR, PATCH, SUCCESS } from '../../../services/constantService';
import { useCustomMessageHook } from '../../../utils/Hooks/useCustomMessageHook';

const AssignTag = props => {
  const { module = 'projects', moduleIds, setIsPopupOpen, isPopupOpen, assignedTags, label, setTagChange = () => {}, onSuccess, multiple = false } = props;
  if (!isPopupOpen) return <></>;

  const { register, unregister, setValue } = useForm({});
  const [commonValue, setCommonValue] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const moduleStoreKeyName = `${module}Tag`;
  const moduleTag = useSelector(state => state?.commonReducer?.[moduleStoreKeyName]);
  const { translateMessage } = useCustomMessageHook();

  useEffect(() => {
    register('tags');
    return () => {
      unregister('tags');
    };
  }, [register, unregister]);

  useEffect(() => {
    if (!moduleTag) {
      dispatch(fetchTagData('', moduleStoreKeyName, `?module=${module}&name=`));
    } else {
      setOptions(moduleTag?.map(i => ({ ...i, name: i?.value || i?.name })));
    }
  }, [moduleTag, dispatch]);

  useEffect(() => {
    let objectValues;
    if (assignedTags) {
      objectValues = options && options.map(item => item?.name);
      const commonValues = assignedTags.filter(value => objectValues.includes(value));
      setCommonValue(commonValues);
    }
  }, [assignedTags]);

  const getValueOfSelected = () => {
    let array = [];
    for (let x of assignedTags) {
      const index = options && options.findIndex(obj => obj?.name === x);
      array.push(index);
    }
    let value = [];
    for (let y of array) {
      value.push(options[y]);
    }
    return value;
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const getPayload = () => {
    let payload;
    const requestPayloadArray = [];

    selectedTag.map(item => {
      payload = {
        tag_id: item?.id,
        ign_tags: {
          name: item?.name
        }
      };
      requestPayloadArray.push(payload);
    });
    return requestPayloadArray;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const apiMap = {
        projects: projectTagsApi,
        contacts: ignContactDataApi,
        companies: ignCompanyDataApi
      };

      const payloadMap = {
        projects: _moduleId => ({ tags: getPayload() }),
        contacts: moduleId => ({ contact_tags: getPayload(), contact_id: moduleId }),
        companies: _moduleId => ({ company_tags: getPayload() })
      };

      let success = true;

      for (const moduleId of moduleIds) {
        const apiFn = apiMap[module];
        const payload = payloadMap[module](moduleId);

        let response;
        if (module === 'projects') {
          response = await apiFn(PATCH, payload, { projectId: moduleId, addOnly: multiple }, '/bulk-update');
        } else if (module === 'contacts') {
          response = await apiFn(PATCH, '', { ...payload, addOnly: multiple }, 'assign/tags');
        } else if (module === 'companies') {
          response = await apiFn(PATCH, { ...payload, addOnly: multiple }, moduleId);
        }

        if (response?.status !== 200) {
          success = false;
          break; // Stop execution if any request fails
        }
      }

      if (success) {
        setTagChange(true);
        const message = translateMessage('Successfully', false, 'Tag', 'Assigned');
        onSuccess();
        enqueueSnackbar(message, { variant: SUCCESS });
      } else {
        const message = translateMessage('UnableMessage', false, 'Assign Tag', '');
        enqueueSnackbar(message, { variant: ERROR });
      }
    } catch (e) {
      enqueueSnackbar(`Error found in handleSubmit:: ${e?.message}`, { variant: ERROR });
    } finally {
      setLoading(false);
      setIsPopupOpen(false);
    }
  };

  return (
    <div>
      <CustomPopup dropdown={true} title={'Assign Tag'} open={isPopupOpen} onClose={handleClose} showAction={true} handleSubmit={handleSubmit}>
        <Loader show={loading} className='h-75' />
        <Box id='assign-tag'>
          <Box className='tag-dropdown p-4'>
            <TagSelection
              {...register('tags')}
              className='tag-auto-complete'
              defaultValue={assignedTags && getValueOfSelected()}
              commonValue={assignedTags ? commonValue : null}
              label={label}
              options={options}
              setSelectedTag={setSelectedTag}
              setValue={setValue}
            />
          </Box>
        </Box>
      </CustomPopup>
    </div>
  );
};
AssignTag.propTypes = {
  title: PropTypes.string,
  setIsPopupOpen: PropTypes.func,
  isPopupOpen: PropTypes.bool,
  assignedTags: PropTypes.array,
  label: PropTypes.string,
  setTagChange: PropTypes.func,
  onSuccess: PropTypes.func,
  module: PropTypes.string,
  moduleIds: PropTypes.array,
  multiple: PropTypes.bool
};

export default AssignTag;
