//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InvoiceSummary from './List';

export default function BilledInvoice() {
  return <InvoiceSummary type='billedInvoice' storageKey='invoiceSummery' title='Billed Invoice' />;
}
