//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { Button, Typography } from '@mui/material';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { columnsDef, CompanyIdRenderer, ContactIdRenderer, OffLimitsCauseRenderer, DetailsRenderer } from './utils';
import { getSelectedContactIds } from '../../Contacts/utils';
import { VALIDATION_MESSAGE } from '../../../services/MessageService';

let gridApi;

export default function MultipleContacts(props) {
  const { data, handleAdd, handleCancel } = props;

  const [rowData, setRowData] = useState([]);

  const handleAddToSearch = () => {
    const contactIds = getSelectedContactIds(gridApi);
    handleAdd(contactIds);
  };

  useEffect(() => {
    setRowData([...data]);
  }, [data]);

  const handleGridReady = params => {
    gridApi = params.api;
    params.api.sizeColumnsToFit();
  };

  return (
    <Popup className='off-limits-warning' open={true} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className='popup-container'>
        <Typography className='off-limits-heading'>Off Limits Contacts</Typography>
        <div className='inside-container d-flex flex-column'>
          <Typography className='off-limits-reason p-4'>The following contacts are designated as off limits. Would you like to continue to add them to a search?</Typography>
          <div className='list-view flex-grow-1'>
            <div id='myGrid' className='ag-theme-alpine add-contact-section'>
              <AgGridReact
                defaultColDef={{ resizable: true }}
                rowData={rowData}
                columnDefs={columnsDef}
                onGridReady={handleGridReady}
                frameworkComponents={{
                  ContactIdRenderer,
                  CompanyIdRenderer,
                  OffLimitsCauseRenderer,
                  DetailsRenderer
                }}
                rowSelection='multiple'
                suppressRowClickSelection={true}
              />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-around bottom-actions'>
          <Button variant='contained' color='primary' onClick={handleAddToSearch}>
            {VALIDATION_MESSAGE.add_contact_to_search}
          </Button>
          <Button variant='contained' color='primary' onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Popup>
  );
}

MultipleContacts.propTypes = {
  data: PropTypes.array,
  handleAdd: PropTypes.func,
  handleCancel: PropTypes.func
};
