import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { Box } from '@mui/material';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import { END_POINTS_URL } from '../../../services/constantService';
const ContactMatchView = props => {
  const { t } = useLanguageHooks([TRANS_KEYS.EXACT_MATCH_IDENTIFIED, TRANS_KEYS.EXACT_MATCH_IDENTIFIED_VIA, TRANS_KEYS.POTENTIAL_MATCH_IDENTIFIED]);
  const { data, duplicationField = false } = props;
  const handleItemClick = id => {
    window.open(`${END_POINTS_URL.CONTACT_URL}${id}`);
  };
  return (
    <>
      <Box>
        <Box>
          {data?.data?.exactMatch && data?.data?.exactMatch.length > 0 && (
            <Box className='match-found-text fs-16'>
              {duplicationField ? t(`${TRANS_KEYS.EXACT_MATCH_IDENTIFIED_VIA}:key`) + duplicationField + ': ' : t(`${TRANS_KEYS.EXACT_MATCH_IDENTIFIED}:key`)}{' '}
              {data?.data?.exactMatch?.map((item, index) => (
                <React.Fragment key={index}>
                  {item.name && (
                    <>
                      <Box component='span' className='expand-collapse-button'>
                        {index !== 0 && ', '}
                      </Box>
                      <Link component='button' variant='body2' onClick={() => handleItemClick(item.id)} color='heading' className='expand-collapse-button fs-14'>
                        {item.name}
                      </Link>
                    </>
                  )}
                </React.Fragment>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Box>
          {data?.data?.potentialMatch && data.data.potentialMatch.length > 0 && (
            <Box>
              {t(`${TRANS_KEYS.POTENTIAL_MATCH_IDENTIFIED}:key`)}{' '}
              {data?.data?.potentialMatch?.map((item, index) => (
                <React.Fragment key={index}>
                  {(item?.contact?.name || item?.name) && (
                    <>
                      <Box component='span' className='expand-collapse-button'>
                        {index !== 0 && ', '}
                      </Box>
                      <Link component='button' variant='body2' onClick={() => handleItemClick(item?.contact?.id || item?.id)}>
                        {item?.contact?.name || item?.name}
                      </Link>
                    </>
                  )}
                </React.Fragment>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

ContactMatchView.propTypes = {
  data: PropTypes.object,
  duplicationField: PropTypes.string || PropTypes.bool
};

export default ContactMatchView;
