import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../components/common/Loader';
import { emailTemplatesApi } from '../../../../services/ApiService';
import useApi from '../../../../utils/Hooks/useApiHook';
import '../index.scss';
import AddEmailTemplate from './AddEmailTemplate';
import { DeleteButtonRenderer, TemplateNameRenderer, useTemplateColumnDefs } from './utils';

const EmailTemplatesList = () => {
  const { t } = useTranslation();
  const columnDefs = useTemplateColumnDefs();
  const [rowData, setRowData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [loading, setLoading] = useState(false);

  // Queries

  const {
    data: apiResponse,
    loading: isLoading,
    success: isSuccess,
    refetch
  } = useApi({
    queryFn: () => emailTemplatesApi('get', null)
  });

  // Queries
  const singleTemplateApiResponse = useApi({
    queryKey: currentTemplate,
    queryFn: () => {
      if (currentTemplate !== null) {
        return emailTemplatesApi('get', currentTemplate);
      }
      // Return a placeholder value, or null, if currentTemplate is null
      return null;
    }
  });

  const handleGetData = async () => {
    if (!isSuccess && isLoading) return;

    if (apiResponse && apiResponse.status >= 200 && apiResponse.status < 400) {
      const rowData = apiResponse?.data?.map(item => {
        return {
          id: item.id,
          title: item.title,
          description: item.description,
          language: item?.language?.toUpperCase() ?? 'EN',
          public: item.is_public ? 'Public' : 'Private'
        };
      });

      setRowData(rowData);
    }
  };

  useEffect(() => {
    handleGetData();
  }, [isLoading, apiResponse]);

  return (
    <div
      style={{
        height: '80vh'
      }}
    >
      <Loader show={loading || isLoading} />

      <Box height='85%' paddingX='20px'>
        <div className='py-2 text-right'>
          <Button variant='contained' className='button-add' startIcon={<AddIcon className='icon-size' />} onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
            {t('actions.add')}
          </Button>
        </div>
        <div className='ag-theme-alpine text-center' id='myGrid'>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            frameworkComponents={{
              deleteButtonRenderer: props => <DeleteButtonRenderer {...props} refetch={refetch} setLoading={setLoading} />,
              templateNameRenderer: props => <TemplateNameRenderer {...props} setIsDrawerOpen={setIsDrawerOpen} setCurrentTemplate={setCurrentTemplate} />
            }}
          />
        </div>
        <AddEmailTemplate
          setIsDrawerOpen={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
          templateData={singleTemplateApiResponse}
          currentTemplate={currentTemplate}
          setCurrentTemplate={setCurrentTemplate}
          refetch={refetch}
        />
      </Box>
    </div>
  );
};

export default EmailTemplatesList;
