import { FlipCameraAndroid, LocationOn, ShowChart } from '@mui/icons-material';
import { Divider, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import CustomButton from '../../../components/common/CustomButton';
import { DEFAULT_DATE_FORMAT, formatDateWithMomentTimeZone, getDifferenceInDays } from '../../../utils/date';
import './jobCard.scss';
function JobCard(props) {
  const { onClickViewButton = () => {}, data = {} } = props;
  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 700,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  }))(Tooltip);
  const handleMore = () => {
    console.log('more');
  };
  return (
    <div
      className='job-card-container p-3 rounded border shadow'
      style={{
        height: '300px'
      }}
    >
      {/* top-part */}
      <div className='top-part'>
        <div className='font-weight-bold fs-14'>{data?.ign_projects?.job_title}</div>
        <div className='fs-12'>{data?.ign_projects?.ign_companies?.name}</div>
        <div className='fs-10'>{data?.ign_projects?.ign_industries?.name}</div>
      </div>
      <Divider className='m-2' />
      {/* stat-part */}
      <div className='stat-part'>
        <div className='stat'>
          <Typography component={'div'} className='light' variant='subtitle' color='initial'>
            Job No
          </Typography>
          <Typography className='job-number-id fs-12' variant='caption' component={'div'} color='initial'>
            {data?.ign_projects?.job_number}
          </Typography>
        </div>
        <div className='stat'>
          <Typography component={'div'} variant='subtitle' className='light' color='initial'>
            Days Open
          </Typography>
          <Typography variant='caption' component={'div'} color='initial'>
            <ShowChart className='fs-10' /> {getDifferenceInDays(new Date(), data?.published_at)} Days
          </Typography>
          <Typography variant='caption' component={'div'} color='initial' className='fs-10'>
            Start Date: {formatDateWithMomentTimeZone(data?.published_at, DEFAULT_DATE_FORMAT)}
          </Typography>
        </div>
        <div className='stat'>
          <Typography component={'div'} className='light' variant='subtitle' color='initial'>
            Job Location
          </Typography>
          <Typography variant='caption' component={'div'} color='initial'>
            <LocationOn className='fs-10' /> {data?.ign_projects?.location}
          </Typography>
          <Typography variant='caption' component={'div'} color='initial' className='fs-10'>
            Metropolitan Area
          </Typography>
        </div>
      </div>
      <Divider className='m-2' />
      {/* desc-part */}
      <Typography
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          maxWidth: '500px',
          width: '100%'
        }}
      >
        <Typography sx={{ fontSize: '12px' }} className={'fs-12 text-blue'} onClick={handleMore}>
          <div className='fs-10' dangerouslySetInnerHTML={{ __html: data?.job_description?.slice(0, 100) }} />
        </Typography>

        <HtmlTooltip className='fs-10' title={<div className='fs-10' dangerouslySetInnerHTML={{ __html: data?.job_description }} />} placement='top'>
          <Typography className='fs-10'>{data?.job_description?.length > 100 && <>...Read more</>}</Typography>
        </HtmlTooltip>
      </Typography>
      <div className='button-part mt-2'>
        <CustomButton type={'primary card-button '} iconLeft={<FlipCameraAndroid className='fs-14 mr-1' />} buttonText={'View More'} onClick={() => onClickViewButton(data)} />
      </div>
      {/* button-part */}
    </div>
  );
}
JobCard.propTypes = {
  onClickViewButton: PropTypes.func,
  data: PropTypes.object
};
export default JobCard;
