import { useState } from 'react';
import { ignCandidateStatus } from '../../../services/ApiServices/candidateStages';
import { GET } from '../../../services/constantService';

export const useCandidateStages = () => {
  const [condidateStages, setCandidateStages] = useState([]);

  return {
    condidateStages,
    getAllCandidateStatus: async function getCandidateStatus() {
      try {
        const response = await ignCandidateStatus(GET, '', '', 'all');
        setCandidateStages(response);
      } catch (error) {
        console.error('Error updating company off-limit:', error);
      }
    }
  };
};
