//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { VALIDATION_MESSAGE } from '../../../services/MessageService';
// import { useLanguageHooks } from "../../../utils/Hooks/UseLanguageHook";
// import { TRANS_KEYS } from "../../../utils/languageNamespaces";
import { Box } from '@mui/material';
import TeamInfoView from './TeamInfoView';

const KGPTeamInfoView = props => {
  const { register, setValue, currentValues, renderActions, renderLabel, editingField, option } = props;
  const recruiterMessage =
    currentValues['brand'] === (currentValues['brand'] === 'Advisory' ? 'Advisory' : 'Advisory')
      ? '<span><b>Note:</b> Recruiter Information section is not available for ‘Advisory’ Service Offering.<br/> You may consider selecting another ‘Service Offering’ in the Basic Information page ,if Recruiter Information is required.</span>'
      : null;
  const researcherMessage =
    currentValues['brand'] === (currentValues['brand'] === 'Advisory' ? 'Advisory' : 'Advisory')
      ? '<span><b>Note:</b> Researcher Information section is not available for ‘Advisory’ Service Offering.<br/> You may consider selecting another ‘Service Offering’ in the Basic Information page if Researcher Information is required.</span>'
      : null;
  // const { t } = useLanguageHooks([
  //   TRANS_KEYS.BD_TEAM_INFO,
  // ]);

  return (
    <Box id='team-info' className='p-3'>
      <Box>
        <Box className='mb-3'>
          {/* <Box className="text-label text-center fs-20 pl-1 pb-1 header-font my-4">
            {t(`${TRANS_KEYS.BD_TEAM_INFO}:key`)}
          </Box> */}
          <Box className='team-info-table d-flex flex-column p-3 text-aligns-center'>
            <TeamInfoView
              option={option}
              title={'Partner Information'}
              label='Partner'
              field='partners'
              fields={{
                origination_credit: 30,
                selling_credit: 20,
                execution_credit: 50
              }}
              // register={register}
              // setValue={setValue}
              values={currentValues.partners}
              // renderLabel={renderLabel}
              // editingField={editingField}
              // renderActions={renderActions}
            />
            <TeamInfoView
              option={option}
              title={'Recruiter Information'}
              label='Recruiter'
              field='recruiters'
              fields={{
                execution_credit: 100
              }}
              // register={register}
              // setValue={setValue}
              values={currentValues.recruiters}
              // renderLabel={renderLabel}
              // editingField={editingField}
              // renderActions={renderActions}
              readOnlyMessage={recruiterMessage}
            />
            <TeamInfoView
              option={option}
              title={VALIDATION_MESSAGE.researcher_information}
              label='Researcher'
              field='researchers'
              fields={{
                execution_credit: 100
              }}
              register={register}
              setValue={setValue}
              values={currentValues.researchers}
              renderLabel={renderLabel}
              editingField={editingField}
              renderActions={renderActions}
              readOnlyMessage={researcherMessage}
            />
            <TeamInfoView
              option={option}
              title={VALIDATION_MESSAGE.admin_information}
              label='Admin'
              field='project_admin'
              fields={{
                execution_credit: 100
              }}
              register={register}
              setValue={setValue}
              values={currentValues?.project_admin}
              renderLabel={renderLabel}
              editingField={editingField}
              renderActions={renderActions}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

KGPTeamInfoView.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  currentValues: PropTypes.object,
  renderActions: PropTypes.func,
  renderLabel: PropTypes.func,
  editingField: PropTypes.string,
  option: PropTypes.array
};

export default KGPTeamInfoView;
