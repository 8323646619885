//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useNavigate, useLocation } from 'react-router-dom';
import { Delete } from '@mui/icons-material';
import Popover from '@mui/material/Popover';
import { useSnackbar } from 'notistack';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import ProfileView from './ProfileView';
import CopyCandidatesToSearch from '../../Containers/Searches/AllCandidates/CopyCandidatesToSearch';
import DeletePopup from '../../Containers/Commons/DeletePopup';
import { getMessage } from '../../Containers/Commons/OffLimitsWarningPopup/utils';
import AddContactsToWorkbench from '../../Containers/Commons/AddContactToWorkbench';
import { acquireLockApi, releaseLockApi } from '../../services/ApiService';
import { ERROR, WARNING, DELETE_CONFIRMATION_POPUP_MESSAGE } from '../../services/constantService';
import { VALIDATION_MESSAGE } from '../../services/MessageService';
// import { getSelectedContactIds } from '../../Containers/Contacts/utils';

export default function ProfileAndActionsView(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { contact, video, setIsEditing, isEditing, handleEdit, handleCancel, handleSave, canEdit = true, profileUrl, setNewProfilePic, setProfileUrl, option } = props;
  const [isAddToSearchOpen, setAddToSearchOpen] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isRemove, setRemove] = useState(false);
  const [isCopyToWorkbenchOpen, setIsCopyToWorkbenchOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const onSave = async () => {
    try {
      let { status, data } = await acquireLockApi(contact?.id, { detachedLockBroken: true });
      if (status && status === 200) {
        await handleSave();
      } else {
        enqueueSnackbar(data.message, {
          persist: true,
          variant: ERROR,
          // eslint-disable-next-line react/display-name
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              Dismiss
            </Button>
          )
        });
      }
    } catch (e) {
      console.log('Error found in onSave::', e);
    }
  };

  const handleCopyContactsToWorkbench = () => {
    setIsCopyToWorkbenchOpen(true);
  };

  const handleCopyContactsToWorkbenchClose = () => {
    setIsCopyToWorkbenchOpen(false);
  };
  const onCancel = () => {
    setIsEditing(false);
    handleCancel();
  };

  const handleDelete = () => {
    setRemove(true);
  };
  useEffect(() => {
    if (isRemove) {
      handleLogAnActivity();
    }
  }, [isRemove]);
  const handleLogAnActivity = async () => {
    let { status, data } = await acquireLockApi(contact?.id);
    if (status && status === 200 && data.message === undefined) {
      if (isRemove) {
        setPopupOpen(true);
      } else {
        await releaseLockApi(contact?.id);
        navigate(`/contacts/${contact?.id}/log-an-activity/general-info`, { contactId: contact?.id, previousPath: location?.pathname });
      }
    } else {
      //if (data.isAdmin) {
      enqueueSnackbar(data.message, {
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: key => (
          <>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key);
                releaseLock();
              }}
            >
              Force Unlock
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key);
                navigate(`/contacts/${contact?.id}/log-an-activity/read-only/general-info`, { contactId: contact?.id, previousPath: location?.pathname });
              }}
            >
              Open Read Only View
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                closeSnackbar(key);
              }}
            >
              CLOSE
            </Button>
          </>
        )
      });
      /*}
       else {
        enqueueSnackbar(data.message, {
          variant: WARNING,
          // eslint-disable-next-line react/display-name
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key)
                navigate(`/contacts/${contact?.id}/log-an-activity/read-only`,
                  { contactId: contact?.id }
                )
              }}
            >
              Open Read Only View
            </Button>
          )
        })
      } */
      setRemove(false);
    }
  };
  const releaseLock = async () => {
    await releaseLockApi(contact?.id);
    handleLogAnActivity();
  };
  const handlePopupClose = deleted => {
    setRemove(false);
    setPopupOpen(false);
    if (deleted) {
      navigate('/contacts');
    }
  };
  const update = value => {
    setRemove(value);
  };
  const displayOffLimitsMessage = () => {
    if (contact.offlimit?.length) {
      return showOffLimitWarningMessage();
    }
    return '';
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showOffLimitWarningMessage = () => {
    const offLimitPopOverOpen = Boolean(anchorEl);
    const id = offLimitPopOverOpen ? 'simple-popover' : undefined;
    const contactName = contact.offlimit[0].name;
    const reasons = contact.offlimit[0].reasons;
    const open = Boolean(anchorEl);

    if (reasons?.length > 1) {
      return (
        <React.Fragment>
          <Typography className='off-limit-badge' component='div'>
            {contactName} is Off Limits for
            <Typography color='inherit' aria-describedby={id} className='details-link' onClick={handleClick}>
              multiple reasons
            </Typography>
          </Typography>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            className='reason-container'
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label='spanning table'>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#5c52c6' }}>
                    <TableCell style={{ color: '#FFFFFF' }} align='center' colSpan={3}>
                      Off Limits Reasons for {contactName}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: '#e9e9ea', color: 'black' }}>
                    <TableCell align='center'>Off Limits Cause</TableCell>
                    <TableCell align='center'>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reasons.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align='center' style={{ backgroundColor: 'red', color: 'white' }}>
                          {item.cause}
                        </TableCell>
                        <TableCell align='center'>{getMessage(item, contactName)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Popover>
        </React.Fragment>
      );
    } else {
      return <Typography className='off-limit-badge'>{reasons[0].message}</Typography>;
    }
  };

  return (
    <div className='d-flex flex-column'>
      <div className='profile-action-section d-flex align-items-start'>
        {isPopupOpen ? <DeletePopup id={contact?.id} onClose={handlePopupClose} module={'contacts'} updateParent={update} popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_CONTACT} /> : null}
        {isAddToSearchOpen && (
          <CopyCandidatesToSearch
            header={VALIDATION_MESSAGE.add_contact_to_search}
            buttonText={VALIDATION_MESSAGE.add_contact_to_search}
            uiLabel='contact'
            open={true}
            contactIds={[contact.id]}
            handleClose={() => {
              setAddToSearchOpen(false);
            }}
          />
        )}
        {isCopyToWorkbenchOpen && <AddContactsToWorkbench open={isCopyToWorkbenchOpen} contactIds={[contact.id]} onClose={handleCopyContactsToWorkbenchClose} type='Contacts' />}
        <ProfileView
          setProfileUrl={setProfileUrl}
          setNewProfilePic={setNewProfilePic}
          profileUrl={profileUrl}
          option={option}
          contact={contact}
          video={video}
          isEditing={canEdit && isEditing}
          className='flex-grow-1'
        />
        {canEdit && isEditing ? (
          <div>
            <Button variant='text' onClick={onCancel} style={{ marginRight: 30 }}>
              Cancel
            </Button>
            <Button variant='contained' color='primary' onClick={onSave} style={{ borderRadius: 2 }}>
              Save Edits
            </Button>
          </div>
        ) : (
          <div>
            <div className='d-flex actions-container align-items-center'>
              {canEdit && (
                <div className='action-container d-flex align-items-center' onClick={handleEdit}>
                  <span className='action-icon'>
                    <EditIcon fontSize='inherit' />
                  </span>
                  <span className='action-text'>Edit</span>
                </div>
              )}
              <div className='action-container d-flex align-items-center' onClick={handleLogAnActivity}>
                <span className='action-icon'>
                  <TrendingUpIcon fontSize='inherit' />
                </span>
                <span className='action-text'>Log an Activity</span>
              </div>

              <button
                className='primary-btn d-flex justify-content-end '
                onClick={() => {
                  setAddToSearchOpen(true);
                }}
              >
                Add to Search
              </button>
            </div>
            <div className='d-flex actions-container align-items-center mt-4'>
              <div className='action-container d-flex align-items-center' style={{ marginRight: canEdit ? '40px' : '20px' }} onClick={handleDelete}>
                <span className='action-icon'>
                  <Delete fontSize='inherit' />
                </span>
                <span className='action-text'>Delete Record</span>
              </div>

              {canEdit ? (
                <button className='primary-btn px-3 ml-5' onClick={handleCopyContactsToWorkbench}>
                  Add to Workbench
                </button>
              ) : (
                <button className='primary-btn px-3' onClick={handleCopyContactsToWorkbench}>
                  Add to Workbench
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      {displayOffLimitsMessage()}
    </div>
  );
}

ProfileAndActionsView.propTypes = {
  contact: PropTypes.object,
  video: PropTypes.object,
  setIsEditing: PropTypes.func,
  isEditing: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
  canEdit: PropTypes.bool,
  setNewProfilePic: PropTypes.func,
  profileUrl: PropTypes.string,
  setProfileUrl: PropTypes.func,
  option: PropTypes.array
};
