import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Checkbox, TextField, Tooltip } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { currencyWithCommas, getCurrencyPrefix, separator } from '../../../../../Containers/Commons/Utils';
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR } from '../../../../../services/constantService';
import { getFixedDecimal } from '../../../../../utils/common';

//----------------------------------------------// Internal Imports // -------------------------------------------------

const TotalRevenue = props => {
  /* getIndirectFees is used when calculate for estimated and actual fee calculation */
  const { watch, getTotalPercentageOfBase, getGrandTotal, type = 'estimated', getIndirectFees, register, setValue, currencyDetails, disabled = false, currentValues } = props;
  const label = type === 'estimated' ? 'Estimated' : 'Actual';

  const displayTooltipContent = title => {
    return (
      <Tooltip title={title}>
        <span className='pl-4'>&nbsp;&nbsp;{title}</span>
      </Tooltip>
    );
  };
  return (
    <div>
      <div className={'d-flex flex-column team-information-table revenue-table'}>
        <div className={'d-flex table-header '}>{label} Revenue</div>
        <div className=' d-flex flex-column py-1 px-3'>
          <div className='d-flex p-1'>
            <div className='col-4 px-0'>Base Fee</div>
            <div className='col pr-0 text-truncate'>{displayTooltipContent(getTotalPercentageOfBase(watch(`${type}_percentage_bases`), `calculated_${type}_amt`, true))} </div>
          </div>
          <div className='d-flex p-1'>
            <div className='col-4 px-0'>Indirect Fee</div>
            <div className='col pr-0 text-truncate'>
              {displayTooltipContent(getTotalPercentageOfBase(getIndirectFees ? getIndirectFees(type) : watch('indirect_fees_searches'), 'calculation_amt', true))}
            </div>
          </div>
          <hr style={{ height: '2px', width: '100%', borderWidth: 0, backgroundColor: '#2A364C' }} />
          <div className='d-flex p-1'>
            <div className='col-4 px-0 text-wrap'>Revenue</div>
            <div className='col pr-0 text-truncate'>{displayTooltipContent(getGrandTotal() || '--')}</div>
          </div>
          {type === 'actual' && ((disabled && watch('is_actual_overridden_revenue')) || !disabled) && (
            <div className='row align-items-center p-1 mx-0'>
              <div className='col-4 px-0'>
                <div className='d-flex text-right text-red align-items-center justify-content-end'>
                  {!disabled && (
                    <Checkbox
                      checked={watch('is_actual_overridden_revenue') || false}
                      onChange={e => {
                        if (!e.target.checked) {
                          setValue('actual_overridden_revenue', 0);
                        }
                        setValue('is_actual_overridden_revenue', e.target.checked);
                      }}
                      color='primary'
                      {...register('is_actual_overridden_revenue')}
                      name={'is_actual_overridden_revenue'}
                      className='py-0'
                      disabled={disabled}
                    />
                  )}
                  <span className={`${!disabled ? '' : ''}`}>Override</span>
                </div>
              </div>
              <div className='col-8 pr-0'>
                {!disabled && watch('is_actual_overridden_revenue') ? (
                  <NumericFormat
                    customInput={TextField}
                    label=''
                    id='is_actual_overridden_revenue'
                    variant='outlined'
                    placeholder='Override Revenue'
                    InputLabelProps={{ focused: true }}
                    style={{ fontSize: 12 }}
                    value={watch('actual_overridden_revenue')}
                    thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                    decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                    decimalScale={2}
                    disabled={!watch('is_actual_overridden_revenue')}
                    isNumericString={true}
                    displayType={'input'}
                    prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                    suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                    onValueChange={values => {
                      const { value } = values;
                      setValue('old_actual_revenue', currentValues.actual_overridden_revenue);
                      setValue('actual_overridden_revenue', value ? value : '--');
                    }}
                  />
                ) : watch('is_actual_overridden_revenue') ? (
                  displayTooltipContent(`${getCurrencyPrefix(watch('currency') || '')} ${currencyWithCommas(getFixedDecimal(watch('actual_overridden_revenue')).toFixed(2))}`)
                ) : (
                  0
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TotalRevenue.propTypes = {
  watch: PropTypes.func,
  getTotalPercentageOfBase: PropTypes.func,
  getGrandTotal: PropTypes.func,
  type: PropTypes.string,
  getIndirectFees: PropTypes.func,
  register: PropTypes.func,
  setValue: PropTypes.func,
  currencyDetails: PropTypes.object,
  disabled: PropTypes.bool,
  currentValues: PropTypes.object
};

export default TotalRevenue;
