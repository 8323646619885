//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip, Typography } from '@mui/material';
import { ERROR, POST, PUT, SUCCESS } from '../../../../../../services/constantService';
import BusinessIcon from '@mui/icons-material/Business';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { Button } from '@mui/material';

import './index.scss';
import MessageLoader from '../../../../../common/MessageLoader/MessageLoader';
import ConfirmationPopup from '../../../../../ConfirmationPopup';
import { successMessage, unableMessage, VALIDATION_MESSAGE } from '../../../../../../services/MessageService';
import { searchDataApi } from '../../../../../../services/ApiService';

//-----------------------------------------------------------// Internal Imports // ------------------------------
let resultData;

export default function SfpaAutomationPopup(props) {
  const [highLighted, setHighLighted] = useState(0);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [tableData, setTableData] = useState({});
  const [tabEdited, setTabEdited] = useState(false);
  const [tabChangeName, setTabChangeName] = useState([]);
  const [currentValues, setCurrentValues] = useState([]);
  const { onClose, search, sfpa, enqueueSnackbar, setSfpaAutoDataGenerated } = props;
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isMultipleSelection, setIsMultipleSelection] = useState({});
  const [isClosePopup, setIsClosePopup] = useState(false);
  const [isSavePopup, setIsSavePopup] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [tabName, setTabName] = useState('target_companies');
  const [sfpaData, setSfpaData] = useState();
  const [initialSfpaDataSet, setInitialSfpaDataSet] = useState(false);
  const [keys, setKeys] = useState();
  let count = 0;
  if (Array.isArray(tableData) && tableData?.length > 0) {
    count = tableData?.filter(item => item.isSelected === true)?.length;
  }
  const fetchSFPAData = async () => {
    const payload = {
      template_name: 'SFPA-Search',
      search_id: search.id,
      mode: 'initial'
    };
    setLoading(true);
    setStatusMessage('AI engine in action. Hold tight while it fetches the data for you to create an epic SFPA..');
    const { status, data } = await searchDataApi(POST, search.id, payload, 'sfpa-by-ai');
    if (status && status === 200) {
      if (data) {
        setSfpaData(data);
        setInitialSfpaDataSet(true);
        resultData = data;
      }
      //   setSfpaData(true);
    } else {
      //enqueueSnackbar(VALIDATION_MESSAGE.review_contact_fail, { variant: ERROR });
    }
    setLoading(false);
    setStatusMessage('');
  };

  useEffect(() => {
    fetchSFPAData();
  }, []);

  useEffect(() => {
    if (initialSfpaDataSet) {
      getTargetRecords('target_companies', 0);
    }
  }, [initialSfpaDataSet]);

  const closePopup = () => {
    setIsAllSelected(false);
    setTableData(null);
    resultData = { ...resultData, [tabName]: tableData };
    delete resultData['target_compensation'];
    delete resultData['persistVariable'];
    if (sfpaData) {
      Object.keys(resultData).forEach(key => {
        let newData = resultData[key];
        newData.map(item => {
          if (item.isSelected) item.isSelected = false;
        });
      });
    }

    onClose && onClose();
  };

  const confirmClosePopup = () => {
    if (sfpaData) {
      let flag = false;
      tableData &&
        tableData.map(item => {
          if (item && item.isSelected === true) {
            flag = true;
          }
        });
      if (tabEdited || flag) {
        setIsClosePopup(true);
      } else {
        onClose && onClose();
      }
    } else {
      onClose && onClose();
    }
  };

  const handleDiscard = () => {
    // onClose && onClose();

    setIsClosePopup(false);
  };
  const onSave = async () => {
    resultData = { ...resultData, [tabName]: tableData };
    // const selectedData = {};
    delete resultData['target_compensation'];
    delete resultData['persistVariable'];
    const selectedNames = {};
    for (const category in resultData) {
      const selectedItems = resultData[category]?.filter(item => item.isSelected);
      console.log('selectedItems', selectedItems);
      if (selectedItems?.length > 0) {
        const names = selectedItems.map(item => {
          if (category === 'skills') {
            const { name: title, ideal, minimum, question } = item;
            return { title, elements: [{ ideal, minimum, question }] };
          } else {
            return item.name;
          }
        });
        selectedNames[category] = names;
      }
    }
    console.log('selectedNames', selectedNames);
    const mergedObj = { ...sfpa };
    for (const key in selectedNames) {
      if (key === 'target_industries' && selectedNames.target_industries && selectedNames.target_industries?.length > 0 && mergedObj.search_industry_rankings) {
        selectedNames.target_industries.forEach(industry => {
          const existingIndustryObj = mergedObj.search_industry_rankings.find(obj => obj.industry === industry);
          if (!existingIndustryObj) {
            const newIndustryObj = {
              industry,
              sub_industries: [],
              ranking: 0
            };
            mergedObj.search_industry_rankings.push(newIndustryObj);
          }
        });
      } else if (key === 'job_titles' && selectedNames.job_titles && selectedNames.job_titles?.length > 0 && mergedObj.contact_targets) {
        selectedNames.job_titles.forEach(title => {
          const existingTitleObj = mergedObj.contact_targets.find(obj => obj.title === title);
          if (!existingTitleObj) {
            const newTitleObj = {
              title,
              employee_max: 0,
              employee_min: 0,
              revenue_max: '0',
              revenue_min: '0',
              ranking: null
            };
            mergedObj.contact_targets.push(newTitleObj);
          }
        });
      } else if (key === 'skills' && mergedObj.skills && mergedObj.skills?.length > 0) {
        mergedObj.skills = mergedObj.skills?.filter(skill => {
          return skill.title !== '' || skill.elements?.length > 0;
        });
        const mergedSet = new Set([...mergedObj[key], ...selectedNames[key]]);
        mergedObj[key] = Array.from(mergedSet);
      }
      // eslint-disable-next-line no-prototype-builtins
      else if (mergedObj.hasOwnProperty(key) && mergedObj.hasOwnProperty(key) !== 'target_industries' && mergedObj.hasOwnProperty(key) !== 'job_titles') {
        if (Array.isArray(mergedObj[key]) && Array.isArray(selectedNames[key])) {
          const mergedSet = new Set([...mergedObj[key], ...selectedNames[key]]);
          mergedObj[key] = Array.from(mergedSet);
          // eslint-disable-next-line no-prototype-builtins
        } else if (!mergedObj.hasOwnProperty(key) || !Array.isArray(mergedObj[key])) {
          mergedObj[key] = [];
        }
        if (Array.isArray(selectedNames[key])) {
          const mergedSet = new Set([...mergedObj[key], ...selectedNames[key]]);
          mergedObj[key] = Array.from(mergedSet);
        }
      } else {
        mergedObj[key] = selectedNames[key];
      }
    }
    mergedObj.aiUpdate = true;
    setLoading(true);
    const { status, data } = await searchDataApi(PUT, search.id, mergedObj, 'sfpa');

    if (status === 200) {
      const message = successMessage('SFPA', VALIDATION_MESSAGE.updated_message);
      enqueueSnackbar(data?.message || message, { variant: SUCCESS });
    } else {
      const message = unableMessage('SFPA', 'update');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setLoading(false);
    setSfpaAutoDataGenerated(true);
    onClose && onClose();
  };

  useEffect(() => {
    let x = tableData && Object.values(tableData).every(v => v.isSelected && v.isSelected === true);
    if (x) setIsAllSelected(true);
    else setIsAllSelected(false);
  }, [tabName, tableData]);

  useEffect(() => {
    console.log('search', search);
    if (tabName === 'job_titles') {
      const searchTitle = [];
      searchTitle.push(search?.job_title);
      setCurrentValues(searchTitle);
    } else if (tabName === 'skills') {
      const title = [];
      if (search.job_title) title.push(search.job_title);
      setCurrentValues(title);
    } else if (tabName === 'target_industries') {
      const title = [];
      if (search.job_title) title.push(search.job_title);
      setCurrentValues(title);
    } else if (tabName === 'target_companies') {
      const searchCompany = [];
      if (search.company && search.company.name) searchCompany.push(search.company.name);
      const company = searchCompany;
      setCurrentValues(company);
    } else if (tabName === 'target_job_functions') {
      const title = [];
      if (search.job_title) title.push(search.job_title);
      setCurrentValues(title);
    }
  }, [tabName]);

  const getTargetRecords = async tab => {
    let keysArray = [];
    setShowTable(false);
    if (sfpaData) {
      const keysToStore = Object.keys(sfpaData);
      for (let i = 0; i < keysToStore?.length; i++) {
        const key = keysToStore[i];
        if (key !== 'target_compensation' && key !== 'persistVariable') {
          keysArray.push(key);
        }
      }
      setKeys(keysArray);
      setShowTable(true);
      setTabName(tab);
      setTableData(sfpaData[tab]);
      setIsMultipleSelection(sfpaData[tab]);
    }
  };

  const handleSaveClose = () => {
    setIsSavePopup(false);
  };

  const onSavePopup = () => {
    setIsSavePopup(true);
  };

  const handleRadioChange = async (tab, index) => {
    setHighLighted(index);

    await getTargetRecords(tab[index], index);
    let flag = false;
    tableData &&
      tableData.map(item => {
        if (item && item.isSelected === true) {
          flag = true;
          setTabEdited(true);
        }
      });
    if (flag) {
      setTabChangeName(prevArray => [...prevArray, tabName]);
    } else if (checkEdit(tabName)) {
      const newArray = tabChangeName?.filter(value => value !== tabName);
      setTabChangeName(newArray);
    }
    resultData = { ...resultData, [tabName]: tableData };
  };

  const handleSelectAllChange = () => {
    const newData = [...tableData];
    if (isMultipleSelection) {
      newData.map(item => {
        item.isSelected = !isAllSelected;
      });

      setTableData(newData);
      setIsAllSelected(!isAllSelected);
    }
  };

  const handleCheckboxChange = itemId => {
    const newData = [...tableData];
    if (isMultipleSelection) {
      const updatedItems = newData.map(item => {
        if (item === newData[itemId]) {
          console.log(item === newData[itemId], item, newData[itemId]);
          item.isSelected = !item.isSelected;
        }
        return item;
      });
      setSelectedItems(updatedItems?.filter(item => item.isSelected));
      setIsAllSelected(updatedItems?.filter(item => item.isSelected)?.length === newData?.length);
    } else {
      const clickedItem = newData.find(item => item === newData[itemId]);
      const updatedItems = newData.map(item => {
        console.log(updatedItems);
        if (item === newData[itemId]) {
          item.isSelected = true;
        } else {
          item.isSelected = false;
        }
        return item;
      });
      setSelectedItems([clickedItem]);
    }
    console.log('dsdffdf', selectedItems);
  };

  const getNameFormatted = value => {
    return value
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const onGetMore = async () => {
    let templateName;
    let keyName;
    if (tableData) {
      tableData.forEach(item => {
        item.isNew = false;
      });
    }
    if (tabName === 'target_companies') {
      templateName = 'SFPA-Company-Search';
      keyName = 'fetchedCompanyList';
    } else if (tabName === 'target_industries') {
      templateName = 'SFPA-Industry-Search';
      keyName = 'fetchedIndustryList';
    } else if (tabName === 'target_job_functions') {
      templateName = 'SFPA-Jobfunction-Search';
      keyName = 'fetchedJobFunctionList';
    } else if (tabName === 'skills') {
      templateName = 'SFPA-Skill-Search';
      keyName = 'fetchedSkillList';
    } else if (tabName === 'job_titles') {
      templateName = 'SFPA-Jobtitle-Search';
      keyName = 'fetchedJobTitleList';
    }
    const payload = {
      template_name: templateName,
      mode: 'more',
      variables: {
        searchCompany: search.company.name,
        [keyName]: tableData
      }
    };
    if (tabName === 'job_functions' || tabName === 'skills' || tabName === 'job_titles') {
      payload.variables = { ...payload.variables, searchJobTitle: search.job_title };
    }
    setLoading(true);
    setStatusMessage('Loading more results for you.');
    const { status, data } = await searchDataApi(POST, search.id, payload, 'sfpa-by-ai');
    if (status && status === 200) {
      if (data) {
        setTableData(data[tabName]);
        setSfpaData(prevSfpaData => ({
          ...prevSfpaData,
          [tabName]: data[tabName]
        }));
      }
    }
    setLoading(false);
    setStatusMessage('');
  };

  const checkEdit = e => tabChangeName.includes(e);

  return (
    <Popup overlayStyle={{ zIndex: '1501 !important' }} className={'add-sfpa-value'} open={true} modal closeOnDocumentClick={false} closeOnEscape={false}>
      <>
        <div className='sfpa-assistant'>
          <MessageLoader show={loading} message={statusMessage}></MessageLoader>
          {isClosePopup ? <ConfirmationPopup header={VALIDATION_MESSAGE.sfpa_close} onConfirm={closePopup} onCancel={handleDiscard} onClose={handleDiscard} cancelText='No' confirmText='Yes' /> : null}
          {isSavePopup ? (
            <div className='confirmation-popup-bd-search'>
              {' '}
              <ConfirmationPopup
                header={VALIDATION_MESSAGE.sfpa_save_confirm}
                onConfirm={onSave}
                onCancel={handleSaveClose}
                onClose={handleSaveClose}
                cancelText='Cancel'
                confirmText='Continue'
              />{' '}
            </div>
          ) : null}

          <div className='' style={{ background: '#2a364c', color: '#fff' }}>
            <div className='quick-add-header'>
              <Typography>
                SFPA Builder{' '}
                <Tooltip placement='top' title='SFPA Builder uses the GPT API to fetch live data'>
                  <InfoOutlinedIcon className='cursor-pointer tooltip-icon' style={{ fontSize: '18px' }} />
                </Tooltip>
              </Typography>
              <CloseIcon className='cursor-pointer' onClick={confirmClosePopup} />
            </div>
          </div>

          {!loading && !showTable && (
            <div className='view-container d-flex align-items-center justify-content-center'>
              <div className='center'>Record Not Found</div>
            </div>
          )}
          {showTable && (
            <div className='view-container'>
              <div className='table-header sidebar-width'>
                <div>
                  {keys.map((item, index) => (
                    <div className='table-header' key={index}>
                      <div
                        onClick={function () {
                          handleRadioChange(keys, index);
                        }}
                        className={'table-cell click ' + (index === highLighted ? ' active ' : '')}
                      >
                        <div className='product-one-score-content'>
                          {!checkEdit(item) && <div className='product-one-color tabs-icon'></div>}
                          {tabEdited && checkEdit(item) && <div className='product-one-color' style={{ backgroundColor: '#26C137' }}></div>}
                          <span className='action-icon'>
                            {item === 'target_companies' && <BusinessIcon fontSize='inherit' />}
                            {item === 'target_industries' && <PolicyOutlinedIcon fontSize='inherit' />}
                            {item === 'target_job_functions' && <DescriptionOutlinedIcon fontSize='inherit' />}
                            {item === 'job_titles' && <FindInPageOutlinedIcon fontSize='inherit' />}
                            {item === 'skills' && <WbIncandescentOutlinedIcon style={{ transform: 'rotate(180deg)' }} fontSize='inherit' />}
                            {item !== 'target_companies' && item !== 'target_industries' && item !== 'target_job_functions' && item !== 'job_titles' && item !== 'skills' && (
                              <AcUnitIcon fontSize='inherit' />
                            )}
                          </span>
                          <span>{getNameFormatted(item)}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className='px-2 table-width'>
                <div className='py-4 d-flex'>
                  <div className='col-9'>
                    {currentValues && currentValues?.length > 0 && (
                      <span className='text-black'>
                        {' '}
                        {getNameFormatted(tabName)} similar to {(tabName === 'skills' || tabName === 'target_job_functions' || tabName === 'target_industries') && 'job title'}{' '}
                        {currentValues.map((item, index) => (
                          <span key={index} style={{ color: '#5c52c6' }}>
                            {' '}
                            {item}
                            {index !== currentValues?.length - 1 && ','}
                          </span>
                        ))}{' '}
                        {tabName !== 'target_companies' && (
                          <span>
                            {' '}
                            for Company <span style={{ color: '#5c52c6' }}>{search?.company && search?.company?.name}</span>
                          </span>
                        )}{' '}
                      </span>
                    )}
                  </div>
                  <div className='col-3 text-end'>
                    <span>Selected Count: {count}</span>
                  </div>
                </div>
                <div className='list-values'>
                  <table className='table table-bordered sfpa-input-checkbox table-style'>
                    <thead style={{ background: '#2a364c', color: '#fff', position: 'sticky', top: 0 }}>
                      <tr>
                        <td>
                          <div className='d-flex gap-6'>
                            <div>
                              <input type='checkbox' className='custom-checkbox' checked={isAllSelected} onChange={handleSelectAllChange} />
                            </div>
                            <div className='border-left border-white border-left-width-2 border-content'>{getNameFormatted(tabName)}</div>
                          </div>
                        </td>
                        {tabName === 'skills' && <td>{'Minimal'}</td>}
                        {tabName === 'skills' && <td>{'Ideal'}</td>}
                        {tabName === 'skills' && <td style={{ width: '26.5%' }}>{'Question'}</td>}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData &&
                        tableData.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className='d-flex gap-6'>
                                <input type='checkbox' className='custom-checkbox' checked={item.isSelected} onChange={() => handleCheckboxChange(index)} />
                                <div className=''></div>
                                <div className='ml-4'>{item.name}</div>
                              </div>
                            </td>

                            {tabName === 'skills' && (
                              <td>
                                {' '}
                                <div>{item.minimum}</div>{' '}
                              </td>
                            )}

                            {tabName === 'skills' && (
                              <td>
                                {' '}
                                <div>{item.ideal}</div>{' '}
                              </td>
                            )}
                            {tabName === 'skills' && (
                              <td>
                                {' '}
                                <div>{item.question}</div>{' '}
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className='row'>
                  <Button className='getMore' onClick={onGetMore}>
                    Get More
                  </Button>
                </div>
              </div>
            </div>
          )}

          {!loading && showTable && (
            <div className='page-content-footer'>
              <Button variant='contained' type='submit' className='page-btn-save' disabled={!showTable} onClick={onSavePopup}>
                Save to SFPA
              </Button>
            </div>
          )}
        </div>
      </>
    </Popup>
  );
}

SfpaAutomationPopup.propTypes = {
  onClose: PropTypes.func,
  search: PropTypes.object,
  sfpa: PropTypes.object,
  setSfpaAutoDataGenerated: PropTypes.bool,
  enqueueSnackbar: PropTypes.func
};
