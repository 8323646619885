//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { Button, FormControlLabel, Checkbox } from '@mui/material';
import { useForm } from 'react-hook-form';

export default function StatusReportWithOptions(props) {
  const { options = { pipeline: true }, onClose, onContinue } = props;

  const defaultValues = {
    isWithDisqualifiedCandidate: true,
    isWithoutDisqualifiedCandidate: false,
    isShort: true,
    isWithBoardHistory: true,
    isWithNextStep: true,
    isWithCompensation: true,
    isWithFQDetails: true,
    isWithSummary: true,
    isWithPipeLine: 'pipelineWithTarget'
  };
  const { register, handleSubmit, setValue } = useForm({ defaultValues });

  useEffect(() => {
    register('isWithoutDisqualifiedCandidate');
    register('isWithPipeLine');
  }, [register]);

  return (
    <Popup open={true} className='report-options' onClose={onClose} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className='d-flex flex-column h-100'>
        <div className='d-flex align-items-center justify-content-between popup-header' style={{ background: '#c3c3c35c', color: '#fff' }}>
          <div className='d-flex align-items-center'>
            <h4 className='mr-5'>Status Report With</h4>
          </div>
          <span className='action-icon cursor-pointer' onClick={onClose}>
            <CloseIcon />
          </span>
        </div>
        <div className='d-flex report-options flex-grow-1 overflow-auto flex-column'>
          <div>
            <FormControlLabel
              className='checkbox-container'
              control={
                <Checkbox
                  className='checkbox-component'
                  size='small'
                  name='isWithDisqualifiedCandidate'
                  defaultChecked={defaultValues.isWithDisqualifiedCandidate}
                  {...register('isWithDisqualifiedCandidate')}
                  onChange={e => {
                    setValue('isWithDisqualifiedCandidate', e.target.checked);
                    setValue('isWithoutDisqualifiedCandidate', !e.target.checked);
                  }}
                />
              }
              label='With Disqualified Candidate'
            />
          </div>
          <div>
            <FormControlLabel
              className='checkbox-container'
              control={<Checkbox className='checkbox-component' size='small' name='isWithBoardHistory' defaultChecked={defaultValues.isWithBoardHistory} {...register('isWithBoardHistory')} />}
              label='With Board History'
            />
          </div>
          {!options.hideIsShort && (
            <div>
              <FormControlLabel
                className='checkbox-container'
                control={<Checkbox className='checkbox-component' size='small' name='isShort' defaultChecked={defaultValues.isShort} {...register('isShort')} />}
                label='Short'
              />
            </div>
          )}
          {!options.hideNextStep && (
            <div>
              <FormControlLabel
                className='checkbox-container'
                control={<Checkbox className='checkbox-component' size='small' name='isWithNextStep' defaultChecked={defaultValues.isWithNextStep} {...register('isWithNextStep')} />}
                label='With Next Step'
              />
            </div>
          )}
          {!options.hideCompensation && (
            <div>
              <FormControlLabel
                className='checkbox-container'
                control={<Checkbox className='checkbox-component' size='small' name='isWithCompensation' defaultChecked={defaultValues.isWithCompensation} {...register('isWithCompensation')} />}
                label='With Compensation'
              />
            </div>
          )}
          {!options.hideFQDetails && (
            <div>
              <FormControlLabel
                className='checkbox-container'
                control={<Checkbox className='checkbox-component' size='small' name='isWithFQDetails' defaultChecked={defaultValues.isWithFQDetails} {...register('isWithFQDetails')} />}
                label='With FQ Details'
              />
            </div>
          )}
          {!options.hideSummary && (
            <div>
              <FormControlLabel
                className='checkbox-container'
                control={<Checkbox className='checkbox-component' size='small' name='isWithSummary' defaultChecked={defaultValues.isWithSummary} {...register('isWithSummary')} />}
                label='With Summary'
              />
            </div>
          )}
          {options.pipeline && (
            <div className='field-details align-items-center'>
              <span className='field-title'>Select Pipeline</span>
              <div className='field-value'>
                <Select className='w-100' onChange={e => setValue('isWithPipeLine', e.target.value)} defaultValue={defaultValues.isWithPipeLine}>
                  <MenuItem value='pipelineWithTarget'>Pipeline With Targets</MenuItem>
                  <MenuItem value='pipelineNoTarget'>Pipeline No Targets</MenuItem>
                  <MenuItem value='fullPipeline'>Full Pipeline</MenuItem>
                  <MenuItem value='noPipeline'>No Pipeline</MenuItem>
                </Select>
              </div>
            </div>
          )}
        </div>
        <div className='action-buttons' style={{ background: '#c3c3c35c' }}>
          <Button onClick={onClose} color='primary' className='mr-2'>
            Cancel
          </Button>
          <Button variant='contained' className='button' color='primary' onClick={handleSubmit(onContinue)} autoFocus>
            Continue
          </Button>
        </div>
      </div>
    </Popup>
  );
}

StatusReportWithOptions.propTypes = {
  options: PropTypes.object,
  onClose: PropTypes.func,
  onContinue: PropTypes.func
};
