//----------------------------------------------// In-built Imports // -------------------------------------------------

import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from './common/InputField';

const options = [
  { id: 1, name: 'Yes' },
  { id: 0, name: 'No' }
];
export default function YesNoSelection(props) {
  const name = 'Lifer';
  const { label = name, placeholder = name, value, ...rest } = props;
  const [defaultValue, setDefaultValue] = useState({});
  useEffect(() => {
    if (value) {
      let option = value;
      if (typeof value === 'object') {
        option = options.find(ele => ele.id === value.id);
      }
      setDefaultValue(option);
    } else {
      setDefaultValue(null);
    }
  }, [value]);
  return (
    <Autocomplete
      {...rest}
      value={defaultValue}
      options={options}
      getOptionSelected={(option, value) => {
        let isEqual = false;
        if (typeof value === 'string') {
          isEqual = option.name === value;
        } else if (value) {
          isEqual = option.name === value.name;
        }
        return isEqual;
      }}
      getOptionLabel={option => option.name}
      renderInput={params => <InputField {...params} variant='standard' label={label} placeholder={placeholder} />}
    />
  );
}

YesNoSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object
};
