import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Grid, IconButton, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const ActionButtonGroup = ({ onAdd, onRemove, disableAdd, disableRemove, frompdl }) => {
  if (!frompdl) {
    return (
      <Stack
        sx={{
          margin: '0 auto'
        }}
      >
        <Grid container justifyContent='center' alignItems='center'>
          <IconButton onClick={onRemove} disabled={disableRemove} p={0}>
            <ArrowBackIcon sx={{ color: disableRemove ? 'gray' : 'primary.main', fontSize: '1.2rem' }} />
            <RemoveCircleIcon sx={{ color: disableRemove ? 'gray' : 'primary.main', fontSize: '1.2rem' }} />
          </IconButton>
        </Grid>

        <Grid container justifyContent='center' alignItems='center'>
          <IconButton onClick={onAdd} disabled={disableAdd}>
            <AddCircleIcon sx={{ color: disableAdd ? 'gray' : 'heading.main', fontSize: '1.2rem' }} />
            <ArrowForwardIcon sx={{ color: disableAdd ? 'gray' : 'heading.main', fontSize: '1.2rem' }} />
          </IconButton>
        </Grid>
      </Stack>
    );
  } else {
    return (
      <Stack
        sx={{
          margin: '0 auto'
        }}
      >
        <Grid container justifyContent='center' alignItems='center'>
          <IconButton onClick={onRemove} disabled={disableRemove}>
            <RemoveCircleIcon sx={{ color: disableRemove ? 'gray' : 'primary.main', fontSize: '1.2rem' }} />
            <ArrowForwardIcon sx={{ color: disableRemove ? 'gray' : 'primary.main', fontSize: '1.2rem' }} />
          </IconButton>
        </Grid>

        <Grid container justifyContent='center' alignItems='center'>
          <IconButton onClick={onAdd} disabled={disableAdd}>
            <ArrowBackIcon sx={{ color: disableAdd ? 'gray' : 'heading.main', fontSize: '1.2rem' }} />
            <AddCircleIcon sx={{ color: disableAdd ? 'gray' : 'heading.main', fontSize: '1.2rem' }} />
          </IconButton>
        </Grid>
      </Stack>
    );
  }
};

ActionButtonGroup.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  disableAdd: PropTypes.bool.isRequired,
  disableRemove: PropTypes.bool.isRequired,
  frompdl: PropTypes.bool.isRequired
};

export default ActionButtonGroup;
