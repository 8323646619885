import { AddCircleOutline, Close, EditOutlined, SaveOutlined } from '@mui/icons-material';
import { Box, IconButton, Slide, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CustomButton from '../../../../../components/common/CustomButton';
import CustomMuiDropdown from '../../../../../components/common/CustomMuiDropdown';
import CustomSwitch from '../../../../../components/common/CustomSwitch';
import DrawerComponent from '../../../../../components/common/Drawer';
import { EditableTextField } from '../../../../../components/common/EditableTextField';
import Loader from '../../../../../components/common/Loader';
import { CustomReportTemplatesApi, downloadHeliaReports } from '../../../../../services/ApiService';
import { ERROR, GET, POST, PUT, SUCCESS } from '../../../../../services/constantService';
import { useLanguageHooks } from '../../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';
import styles from '../../../../../variables.scss';
import DynamicTableWithHeader from './DynamicTableWithHeader';
import { TemplateScreen } from './TemplateScreen';
import { columnDefs, deepEqual } from './utils';

const useQuery = () => {
  const search = useParams();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const ViewReportDrawer = ({ candidateName, projectName, isDrawerOpen, setIsDrawerOpen, fromHeaderBar, contactId, candidateId }) => {
  let query = useQuery();
  const projectId = query.get('id');
  const [loading, setLoading] = useState(false);
  const [generateReportStart, setGenerateReportStart] = useState(false);
  const [candidatesFetchStatus, setCandidatesFetchStatus] = useState('idle');
  const [editCandidatePipeline, setEditCandidatePipeline] = useState(false);
  const [openTemplateScreen, setOpenTemplateScreen] = useState(null);
  const [template, setTemplate] = useState(null);

  const templatesTitle = { id: 'templates', template_name: 'Templates', template_label: 'Templates', shared_by: null, disabled: true };
  const standardTemplate = { id: 'STANDARD', template_name: 'STANDARD', template_label: 'STANDARD', shared_by: null, disabled: false };
  const myTemplates = { id: 'my_templates', template_name: 'My Templates', template_label: 'My Templates', shared_by: null, disabled: true };
  const sharedTemplates = { id: 'shared_templates', template_name: 'shared_templates', template_label: 'Shared Templates', shared_by: null, disabled: true };

  const [templates, setTemplates] = useState([standardTemplate]);

  const [stagesFormData, setStagesFormData] = useState({});
  const [templateFormData, setTemplateFormData] = useState({});

  const [defaultValues, setDefaultValues] = useState({
    stagesFormData: {},
    templateFormData: {}
  });

  const [candidates, setCandidates] = useState({
    rowData: [],
    Active: [],
    Eliminated: [],
    Pipeline: [],
    enabledGroupStages: {}
  });

  const [payloadCandidates, setPayloadCandidates] = useState({
    Active: [],
    Eliminated: [],
    Pipeline: []
  });

  const tr = useTranslation();

  const languages = [
    { title: 'English', value: 'eng' },
    { title: 'Spanish', value: 'esp' },
    { title: 'Portuguese', value: 'por' }
  ];
  const fileFormats = ['DOCX', 'PDF'];

  const reports = fromHeaderBar
    ? [
        { title: 'Search Status Report', value: 'IGN_SSR' },
        { title: 'Search Roadmap Report', value: 'IGN_SRR' }
      ]
    : [
        { title: 'Candidate Details Report', value: 'IGN_CDR' },
        { title: 'Interview Preparation Report', value: 'IGN_IPR' }
      ];

  const {
    register,
    unregister,
    setValue,
    watch,
    trigger,
    getValues,
    formState: { errors }
  } = useForm({ mode: 'onTouched' });

  const getCandidatesByStage = stage => {
    return payloadCandidates?.[stage]?.filter(can => can?.hide !== true)?.map(can => ({ id: can?.id }));
  };

  function getCandidatesPayload() {
    return {
      Active: getCandidatesByStage('Active'),
      Eliminated: getCandidatesByStage('Eliminated'),
      Pipeline: getCandidatesByStage('Pipeline')
    };
  }

  const handleDownloadReport = async () => {
    try {
      setLoading(true);
      const payload = {
        project_id: projectId || '',
        contact_id: contactId || '',
        language: watch('language')?.value,
        report_code: watch('report')?.value,
        candidate_id: candidateId,
        file_Type: `${watch('file_format')}`.toLocaleLowerCase(),
        report_group_name: getValues('pipeline')?.value,
        template_type: getValues('template')?.id === 'STANDARD' ? 'STANDARD' : 'CUSTOM',
        template_id: getValues('template')?.id === 'STANDARD' ? null : getValues('template')?.id,
        ...getCandidatesPayload()
      };

      const res = await downloadHeliaReports(payload);
      if (res.status === 200) {
        // its a pdf file so we can download it directly
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.setAttribute('download', `${candidateName || projectName}_${payload.report_code}_report.${`${watch('file_format')}`.toLocaleLowerCase()}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        enqueueSnackbar(res?.data?.message || 'Something went wrong!', { variant: ERROR });
      }
    } catch (err) {
      enqueueSnackbar(`Failed to generate Report: ${err?.message}`, { variant: ERROR });
    } finally {
      setLoading(false);
      setGenerateReportStart(false);
    }
  };

  const { t } = useLanguageHooks([
    TRANS_KEYS.REPORT_BUILDER_PROJECT,
    TRANS_KEYS.REPORT_BUILDER_CANDIDATE,
    TRANS_KEYS.REPORT_BUILDER_REPORT,
    TRANS_KEYS.REPORT_BUILDER_TEMPLATE,
    TRANS_KEYS.REPORT_BUILDER_FILE_FORMAT,
    TRANS_KEYS.REPORT_BUILDER_LANGUAGE,
    TRANS_KEYS.REPORT_BUILDER_SELECT_PIPELINE,
    TRANS_KEYS.REPORT_BUILDER_GENERATE_AND_DOWNLOAD,
    TRANS_KEYS.REPORT_BUILDER_REPORTS,
    TRANS_KEYS.PIPELINE_WITH_TARGETS,
    TRANS_KEYS.PIPELINE_NO_TARGETS,
    TRANS_KEYS.FULL_PIPELINE,
    TRANS_KEYS.NO_PIPELINE
  ]);

  const [pipelines] = useState([
    { key: t(`${TRANS_KEYS.PIPELINE_WITH_TARGETS}:key`), value: TRANS_KEYS.PIPELINE_WITH_TARGETS },
    { key: t(`${TRANS_KEYS.PIPELINE_NO_TARGETS}:key`), value: TRANS_KEYS.PIPELINE_NO_TARGETS },
    { key: t(`${TRANS_KEYS.FULL_PIPELINE}:key`), value: TRANS_KEYS.FULL_PIPELINE },
    { key: t(`${TRANS_KEYS.NO_PIPELINE}:key`), value: TRANS_KEYS.NO_PIPELINE }
  ]);

  const handleClose = () => {
    if (editCandidatePipeline || openTemplateScreen) {
      setEditCandidatePipeline(false);
      setOpenTemplateScreen(null);
      setTemplate(null);
      return;
    }
    setIsDrawerOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setValue('template', standardTemplate);
    setEditCandidatePipeline(false);
    setOpenTemplateScreen(null);
    trigger();
  };

  useEffect(() => {
    registerFields();
    setInitialValues();
    return () => unregisterFields();
  }, [register, unregister]);

  const registerFields = () => {
    register('template', { required: 'Template is mandatory' });
    register('report', { required: 'Report is mandatory' });
    register('language', { required: 'Please select the language' });
    register('file_format', { required: 'Please select the file format' });
    register('pipeline', { required: 'Please select the pipeline' });
  };

  const unregisterFields = () => {
    unregister('template');
    unregister('report');
    unregister('language');
    unregister('file_format');
  };

  const setInitialValues = () => {
    setValue('report', fromHeaderBar ? { title: 'Search Status Report', value: 'IGN_SSR' } : { title: 'Candidate Details Report', value: 'IGN_CDR' });
    setValue('template', standardTemplate);
    setValue('language', { title: 'English', value: 'eng' });
    setValue('file_format', 'DOCX');
    setValue('pipeline', pipelines?.[0]);
  };

  const iSSRReportSelected = watch('report')?.value === 'IGN_SSR';

  async function getTemplates() {
    try {
      if (iSSRReportSelected) {
        setLoading(true);
        const res = await CustomReportTemplatesApi(GET, '/get-templates');
        if (res.status === 200) {
          const templates = [templatesTitle, standardTemplate];
          const temp = res?.data?.map(item => ({ ...item, disabled: false }));
          const myTemp = temp?.filter(i => i?.shared_by === null);
          const sharedTemp = temp?.filter(i => i?.shared_by !== null);
          if (myTemp?.length > 0) {
            templates.push(myTemplates);
            templates.push(...myTemp);
          }
          if (sharedTemp?.length > 0) {
            templates.push(sharedTemplates);
            templates.push(...sharedTemp);
          }
          setTemplates(templates);
          setValue('template', standardTemplate);
        }
      } else {
        setTemplates([standardTemplate]);
        setValue('template', standardTemplate);
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: ERROR });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getTemplates();
  }, [watch('report')]);

  const handleGenerateAndDownload = async () => {
    trigger();
    if (Object.keys(errors).length !== 0) return;
    if (getValues('template')?.id === 'STANDARD') {
      handleDownloadReport();
      return;
    }
    setGenerateReportStart(true);
  };

  const handleOpenTemplate = async action => {
    setOpenTemplateScreen(action);
  };

  const isCustomTemplateSelected = watch('template') && watch('template')?.template_name !== 'STANDARD' && iSSRReportSelected;

  const handleEditPipeline = () => {
    setEditCandidatePipeline(!editCandidatePipeline);
  };

  const EditCandidatePipeline = () => (
    <Box paddingX={2} paddingY={1} borderRadius={styles.borderRadius} border={`1px solid ${styles.borderColor}`} display={'flex'} alignItems={'center'}>
      <CustomSwitch checked={editCandidatePipeline} onChange={handleEditPipeline} />
      <Typography onClick={handleEditPipeline} sx={{ cursor: 'pointer', fontSize: '14px !important' }}>
        {tr.t('reports.editTheCandidatePipeline')}
      </Typography>
    </Box>
  );

  const isDisabled = openTemplateScreen && deepEqual(defaultValues?.stagesFormData, stagesFormData) && deepEqual(defaultValues?.templateFormData, templateFormData);

  const ActionButton = () => (
    <CustomButton
      buttonStyle={{ color: isDisabled ? '#D3D3D3' : null, fontWeight: 'bold', backgroundColor: editCandidatePipeline || openTemplateScreen ? 'var(--palette-secondary-main)' : null }}
      isLoading={loading}
      disabled={!openTemplateScreen ? Object.keys(errors).length !== 0 : isDisabled}
      buttonText={!openTemplateScreen ? tr.t('reports.generateAndDownload') : tr.t('actions.save')}
      variant='contained'
      onClick={!openTemplateScreen ? handleGenerateAndDownload : handleSaveTemplate}
      fullWidth
      iconLeft={editCandidatePipeline || openTemplateScreen ? <SaveOutlined sx={{ fontSize: '18px', mr: 0.5 }} /> : null}
    />
  );

  async function getTemplateByTemplateName(templateName) {
    setLoading(true);
    try {
      const res = await CustomReportTemplatesApi(GET, `/get-by-template-name?templateName=${templateName}`);
      if (res.status === 200) {
        const parseReportSection = JSON.parse(res.data?.report_section);
        setStagesFormData(parseReportSection?.candidate_stages);
        setTemplateFormData(parseReportSection?.template_data);

        setDefaultValues({
          templateFormData: parseReportSection?.template_data,
          stagesFormData: parseReportSection?.candidate_stages
        });
      } else {
        enqueueSnackbar(res.data?.message, {
          variant: ERROR
        });
      }
    } catch (error) {
      enqueueSnackbar(`Failed to get template ${templateName}: ${error?.message}`, {
        variant: ERROR
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (openTemplateScreen === null) return;

    if (openTemplateScreen === 'edit' && !template?.template_name) {
      const template = getValues('template');
      getTemplateByTemplateName(template?.template_name);
      setTemplate(template);
    } else if (openTemplateScreen === 'new') {
      /** Added random key to make Save Template button active */
      setTemplate({ template_label: 'Untitled_Report' });
    }
  }, [openTemplateScreen]);

  const getCandidates = async templateName => {
    // Reset the values
    setPayloadCandidates({
      rowData: [],
      Active: [],
      Eliminated: [],
      Pipeline: []
    });

    setPayloadCandidates({
      Active: [],
      Eliminated: [],
      Pipeline: []
    });

    try {
      setCandidatesFetchStatus('pending');
      const res = await CustomReportTemplatesApi(GET, `/candidates?templateName=${templateName}&projectId=${projectId}`);
      if (res.status === 200) {
        const candidates = res.data?.candidates || [];
        const activeCandidates = getCandidatedByGroupName(candidates, 'Active');
        const eliminatedCandidates = getCandidatedByGroupName(candidates, 'Eliminated');
        const pipelineCandidates = getCandidatedByGroupName(candidates, 'Pipeline');

        setCandidates({
          rowData: res.data || [],
          Active: activeCandidates,
          Eliminated: eliminatedCandidates,
          Pipeline: pipelineCandidates,
          enabledGroupStages: res.data?.enabled_stage_groups
        });
        setPayloadCandidates({
          Active: activeCandidates,
          Eliminated: eliminatedCandidates,
          Pipeline: pipelineCandidates
        });
        setCandidatesFetchStatus('succeeded');
      } else {
        enqueueSnackbar(res.data?.message, {
          variant: ERROR
        });
        setCandidatesFetchStatus('failed');
      }
    } catch (error) {
      setCandidatesFetchStatus('failed');
      enqueueSnackbar(`Failed to get candidates for ${templateName}: ${error?.message}`, {
        variant: ERROR
      });
    }
  };

  useEffect(() => {
    if (editCandidatePipeline) {
      if (candidatesFetchStatus === 'pending') {
        setLoading(true);
      }
      setTemplate(getValues('template'));
    } else {
      setTemplate(null);
    }
  }, [editCandidatePipeline]);

  useEffect(() => {
    if (generateReportStart) {
      if (candidatesFetchStatus === 'pending') {
        setLoading(true);
      }
      if (candidatesFetchStatus === 'succeeded') {
        handleDownloadReport();
      }
    }
  }, [generateReportStart]);

  useEffect(() => {
    if (candidatesFetchStatus === 'succeeded') {
      if (generateReportStart) {
        handleDownloadReport();
      }
    }
  }, [candidatesFetchStatus]);

  useEffect(() => {
    if (candidatesFetchStatus === 'succeeded' && editCandidatePipeline) {
      setLoading(false);
    }
  }, [candidatesFetchStatus]);

  useEffect(() => {
    const tempName = getValues('template')?.template_name;
    if (tempName && tempName !== 'STANDARD') {
      getCandidates(tempName);
    }
  }, [getValues('template')?.template_name]);

  const getPayloadForTemplateSave = () => {
    if (openTemplateScreen === 'new') {
      return {
        template_label: template?.template_label,
        report_name: 'search_template_report',
        report_section: JSON.stringify({
          candidate_stages: stagesFormData,
          template_data: templateFormData
        })
      };
    } else {
      return {
        template_name: template?.template_name,
        action: 'UPDATE_REPORT_SECTION',
        payload: {
          template_name: template?.template_name,
          report_section: JSON.stringify({
            candidate_stages: stagesFormData,
            template_data: templateFormData
          })
        }
      };
    }
  };

  const handleSaveTemplateRes = res => {
    if (openTemplateScreen === 'new') {
      if (res.status === 201) {
        const updatedTemplates = [...templates];
        updatedTemplates.splice(2, 0, { ...res?.data, disabled: false });
        setTemplates(updatedTemplates);
        enqueueSnackbar("Template created successfully! You're all set to start using it.", { variant: SUCCESS });
        setOpenTemplateScreen('edit');
        setTemplate(res?.data);
        setValue('template', res?.data);
        setDefaultValues({
          stagesFormData,
          templateFormData
        });
      } else {
        enqueueSnackbar(res?.data?.message, { variant: ERROR });
      }
    } else {
      if (res.status === 200) {
        getCandidates(getValues('template')?.template_name);
        setDefaultValues({
          stagesFormData,
          templateFormData
        });
        enqueueSnackbar('Your template is all set! Changes saved successfully.', { variant: SUCCESS });
      } else {
        enqueueSnackbar(res?.data?.message, { variant: ERROR });
      }
    }
  };

  const handleSaveTemplate = async () => {
    const httpMethod = openTemplateScreen === 'new' ? POST : PUT;
    const payload = getPayloadForTemplateSave();
    try {
      setLoading(true);
      const res = await CustomReportTemplatesApi(httpMethod, '', payload);
      handleSaveTemplateRes(res);
    } catch (error) {
      enqueueSnackbar(`Failed to save template: ${error?.message}`, { variant: ERROR });
    } finally {
      setLoading(false);
    }
  };

  const handleSaveTemplateName = async (oldTempLabel, newTempLabel) => {
    const newTemp = { ...template, template_label: newTempLabel };
    if (openTemplateScreen === 'new') {
      setTemplate(newTemp);
      return;
    }
    try {
      setLoading(true);
      const res = await CustomReportTemplatesApi(PUT, '', {
        template_name: template?.template_name,
        action: 'UPDATE_TEMPLATE_LABEL',
        payload: {
          template_name: template?.template_name,
          template_label: newTempLabel,
          report_name: 'search_template_report'
        }
      });
      if (res.status === 200) {
        setTemplate(newTemp);
        const index = templates.findIndex(val => val?.template_name === template?.template_name);
        if (index !== -1) {
          const updatedTemplates = [...templates];
          updatedTemplates[index].template_label = newTempLabel;
          setTemplates(updatedTemplates);
          if (getValues('template')?.template_name === template?.template_name) {
            setValue('template', newTemp);
          }
        }
      } else {
        enqueueSnackbar(res?.data?.message, { variant: ERROR });
      }
    } catch (error) {
      enqueueSnackbar(`Failed to update template name: ${error?.message}`, { variant: ERROR });
    } finally {
      setLoading(false);
    }
  };

  const Header = () => {
    const isDisabled = template?.shared_by;
    const headerContent =
      editCandidatePipeline || openTemplateScreen ? (
        <Box paddingLeft={3} paddingRight={1} paddingY={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{ backgroundColor: 'primary.main' }}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography sx={{ color: 'secondary.main', fontWeight: 800, fontSize: 11, marginBottom: 0.5 }}>{tr.t('reports.reportsTemplate')}</Typography>
            <EditableTextField
              InputProps={{ color: 'white', fontWeight: 800, width: '250px', fontSize: 14 }}
              textStyle={{ color: 'white', fontWeight: 800, fontSize: 14 }}
              defaultValue={template?.template_label}
              onSave={handleSaveTemplateName}
              onCancel={() => {}}
              saveIconColor='white'
              disabled={isDisabled}
            />
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <ActionButton />
            <IconButton onClick={handleClose}>
              <Close sx={{ marginLeft: 1, color: 'white', fontSize: 20 }} />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box paddingLeft={3} paddingRight={1} paddingY={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{ backgroundColor: 'primary.main' }}>
          <Typography sx={{ color: 'white', fontWeight: 800 }}>{tr.t('reports.title')}</Typography>
          <Box display={'flex'} alignItems={'center'}>
            {iSSRReportSelected && (
              <CustomButton
                boxClassName={'px-2'}
                variant={'outlined'}
                buttonStyle={{ color: 'white', backgroundColor: 'var(--palette-secondary-main)' }}
                iconLeft={<AddCircleOutline fontSize='small' sx={{ marginRight: '0.2rem' }} />}
                buttonText={tr.t('reports.newTemplate')}
                onClick={() => handleOpenTemplate('new')}
              />
            )}
            <IconButton onClick={handleClose}>
              <Close sx={{ marginLeft: 1, color: 'white', fontSize: 20 }} />
            </IconButton>
          </Box>
        </Box>
      );

    return headerContent;
  };

  const renderDropdown = (label, name, options, show = true, disabled = false) => {
    if (!show) return null;
    return (
      <Box display={'flex'} flex={1} flexDirection={'column'}>
        <CustomMuiDropdown
          size='small'
          label={label}
          name={name}
          getOptionLabel={option => option?.title || option?.value || option}
          onChange={(e, val) => {
            setValue(name, val);
            trigger(name);
          }}
          disabled={disabled}
          options={options}
          value={watch(name)}
        />
        <Typography color={'red'} fontSize={'12px'}>
          {errors?.[name]?.message}
        </Typography>
      </Box>
    );
  };

  const getCandidatedByGroupName = (data, groupName) => {
    return (
      data
        ?.filter(i => i?.report_group_name === groupName)
        ?.reduce((acc, curr) => {
          return [...acc, ...(curr?.stage?.candidates || [])];
        }, [])
        ?.map(candidate => ({
          id: candidate?.id,
          name: candidate?.contact?.name,
          company: candidate?.contact?.contact_company?.name,
          title: candidate?.contact?.current_job_title,
          stage: candidate?.stage,
          fq: candidate?.fq || 0,
          hide: false
        })) || []
    );
  };

  const handleOnSortHideAndDrag = (stage, arr) => {
    setPayloadCandidates(prev => ({ ...prev, [stage]: arr }));
  };

  const handleOnClick = (e, { onClick }) => {
    onClick?.(e);
  };

  return (
    <DrawerComponent
      setIsMenuOpen={setIsDrawerOpen}
      isMenuOpen={isDrawerOpen}
      anchor={'right'}
      open={isDrawerOpen}
      isViewDrawer={false}
      onClose={handleClose}
      closeIconPosition='close-rectangle-position'
      width={editCandidatePipeline || openTemplateScreen ? '92%' : '340px'}
      transitionType={Slide}
      isViewCloseIcon={false}
      overFlowY={'scroll'}
    >
      <Loader show={loading} />
      <Header />
      {/* FORM START */}
      {!openTemplateScreen && (
        <Box paddingX={3}>
          <Box marginTop={4} display={'flex'} flexDirection={editCandidatePipeline ? 'row' : 'column'} gap={4}>
            {/* PROJECT FIELD START */}
            <TextField label={t(`${TRANS_KEYS.REPORT_BUILDER_PROJECT}:key`)} value={projectName} disabled size='small' />
            {/* PROJECT FIELD END */}

            {/* CANDIDATE FIELD START */}
            {!fromHeaderBar && <TextField label={t(`${TRANS_KEYS.REPORT_BUILDER_CANDIDATE}:key`)} value={candidateName} disabled size='small' />}
            {/* CANDIDATE FIELD END */}

            {/* REPORT FIELD START */}
            {renderDropdown(tr.t('reports.report'), 'report', reports, true, editCandidatePipeline)}
            {/* REPORT FIELD END */}

            {/* TEMPLATE FIELD START */}
            {!editCandidatePipeline && (
              <Box display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box flex={1}>
                    <CustomMuiDropdown
                      size='small'
                      label={tr.t('reports.template')}
                      name='template'
                      getOptionLabel={option => (option?.shared_by === null ? option?.template_label : `${option?.template_label} Shared By ( ${option?.owner?.name} )`)}
                      onChange={(e, val) => {
                        setValue('template', val);
                        trigger('template');
                      }}
                      options={templates}
                      value={watch('template')}
                      renderOption={(props, option) => {
                        const isSelected = getValues('template')?.id === option?.id;
                        return (
                          <Box
                            {...props}
                            onClick={e => {
                              if (option?.disabled) return;
                              handleOnClick(e, props);
                            }}
                            sx={{
                              background: option?.disabled ? styles.white : isSelected ? styles.primary : 'inherit',
                              '&:hover': {
                                background: option?.disabled ? `${styles.white} !important` : `${styles.primary} !important`,
                                cursor: option?.disabled ? 'default' : 'pointer',
                                '& .MuiTypography-root': {
                                  color: option?.disabled ? styles.borderColor : styles.white,
                                  fontWeight: 800
                                }
                              }
                            }}
                          >
                            <Typography component='span'>
                              {option?.shared_by === null ? (
                                <Typography
                                  sx={
                                    option?.disabled
                                      ? {
                                          color: styles.borderColor,
                                          fontWeight: 800,
                                          fontSize: 13
                                        }
                                      : {
                                          color: isSelected ? styles.white : styles.text,
                                          fontWeight: isSelected ? 600 : 500,
                                          fontSize: 15
                                        }
                                  }
                                >
                                  {option?.template_label}
                                </Typography>
                              ) : (
                                <Typography display={'flex'} alignItems={'center'} gap={1} sx={{ fontSize: 15, color: isSelected ? styles.white : styles.text, fontWeight: isSelected ? 600 : 500 }}>
                                  {option?.template_label}{' '}
                                  <Typography
                                    sx={{
                                      fontSize: 13,
                                      color: isSelected ? styles.white : styles.text,
                                      fontWeight: 600
                                    }}
                                  >
                                    ( {option?.owner?.name || 'Unknown'} )
                                  </Typography>
                                </Typography>
                              )}
                            </Typography>
                          </Box>
                        );
                      }}
                    />
                  </Box>
                  {isCustomTemplateSelected && watch('template')?.shared_by === null && (
                    <IconButton onClick={() => handleOpenTemplate('edit')} sx={{ backgroundColor: 'secondary.main', borderRadius: 0.5 }}>
                      <EditOutlined sx={{ color: styles.white }} fontSize='small' />
                    </IconButton>
                  )}
                </Box>
                <Typography color={'red'} fontSize={'12px'}>
                  {errors?.['template']?.message}
                </Typography>
              </Box>
            )}
            {/* TEMPLATE FIELD END */}

            {/* EDIT THE CANDIDATE PIPELINE SWITCH FIELD START */}
            {isCustomTemplateSelected && !editCandidatePipeline && <EditCandidatePipeline />}
            {/* EDIT THE CANDIDATE PIPELINE SWITCH FIELD END */}

            {/* LANGUAGE FIELD START */}
            {renderDropdown(tr.t('reports.language'), 'language', languages)}
            {/* LANGUAGE FIELD END */}

            {/* FILE_FORMAT FIELD START */}
            {renderDropdown(tr.t('reports.fileFormat'), 'file_format', fileFormats)}
            {/* FILE_FORMAT FIELD END */}

            {/* EDIT THE CANDIDATE PIPELINE SWITCH FIELD START WHEN EXPANDED */}
            {isCustomTemplateSelected && editCandidatePipeline && <EditCandidatePipeline />}
            {/* EDIT THE CANDIDATE PIPELINE SWITCH FIELD END WHEN EXPANDED */}

            {/* SELECT PIPELINE FIELD START */}
            {renderDropdown(tr.t('reports.pipeline'), 'pipeline', pipelines, !isCustomTemplateSelected && fromHeaderBar && getValues('report')?.value === 'IGN_SSR')}
            {/* SELECT PIPELINE FIELD END */}
          </Box>

          {!editCandidatePipeline && (
            <Box marginTop={4} width='100%'>
              <ActionButton />
            </Box>
          )}
        </Box>
      )}
      {/* FORM END */}

      {/* EDIT THE CANDIDATE PIPELINE START */}
      {editCandidatePipeline && (
        <Box display={'flex'} flexDirection={'column'} padding={3} marginBottom={10}>
          <DynamicTableWithHeader
            toggledOn={candidates?.enabledGroupStages?.Active || false}
            onSortHideAndDrag={arr => handleOnSortHideAndDrag('Active', arr)}
            rowData={candidates.Active}
            columnDefs={columnDefs}
            title={'ACTIVE STAGES CANDIDATES'}
          />
          <DynamicTableWithHeader
            toggledOn={candidates?.enabledGroupStages?.Eliminated || false}
            onSortHideAndDrag={arr => handleOnSortHideAndDrag('Eliminated', arr)}
            rowData={candidates.Eliminated}
            columnDefs={columnDefs}
            title={'ELIMINATED STAGES CANDIDATES'}
          />
          <DynamicTableWithHeader
            toggledOn={candidates?.enabledGroupStages?.Pipeline || false}
            onSortHideAndDrag={arr => handleOnSortHideAndDrag('Pipeline', arr)}
            rowData={candidates.Pipeline}
            columnDefs={columnDefs}
            title={'PIPELINE CANDIDATES'}
          />
        </Box>
      )}
      {/* EDIT THE CANDIDATE PIPELINE END */}

      {/* EDIT TEMPLATE START */}
      <TemplateScreen
        show={openTemplateScreen}
        stagesFormData={stagesFormData}
        setStagesFormData={setStagesFormData}
        templateFormData={templateFormData}
        setTemplateFormData={setTemplateFormData}
        setLoading={setLoading}
        loading={loading}
        template={template}
        openTemplateScreen={openTemplateScreen}
      />
      {/* EDIT TEMPLATE END */}
    </DrawerComponent>
  );
};

ViewReportDrawer.propTypes = {
  candidateName: PropTypes.string,
  projectName: PropTypes.string,
  isDrawerOpen: PropTypes.bool,
  fromHeaderBar: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func,
  contactId: PropTypes.string,
  candidateId: PropTypes.string
};

export default ViewReportDrawer;
