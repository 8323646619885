//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../common/InputField';
import { fetchPickList } from '../../actions';

function CandidatePrioritySelection(props) {
  const { label = 'Priority', placeholder = 'Priority', required = false, inputRef, InputLabelProps, ...rest } = props;

  const [options, setOptions] = useState([]);

  const priorities = useSelector(state => state.commonReducer.priorities);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!priorities) {
      dispatch(fetchPickList('priorities'));
    } else {
      setOptions(priorities);
    }
  }, [priorities, dispatch]);

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionSelected={(option, value) => {
        if (typeof value === 'string') {
          return option.value === value;
        }
        return option.value === value.value;
      }}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        return option.value;
      }}
      renderInput={params => <InputField {...params} ref={inputRef} variant='standard' InputLabelProps={InputLabelProps} required={required} label={label} placeholder={placeholder} />}
    />
  );
}

CandidatePrioritySelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  inputRef: PropTypes.object
};

export default CandidatePrioritySelection;
