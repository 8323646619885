//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { rightNav } from '../../../Containers/Commons/styleUtils';
import RichTextPopup from '../../RichTextPopup';

export default function Edit(props) {
  const { strengths = [], issues = [], removeIssue, removeStrength, addIssue, addStrength, handleChange, contact, individualFieldSave, saveStatus, setSaveStatus, handelScroll } = props;
  const handleDelete = (type, index) => {
    if (type === 'issues') {
      removeIssue(index);
    } else {
      removeStrength(index);
    }
  };

  const saveStrengthPotential = async (Data, Index, Type, item, setAutoSaveLoading) => {
    try {
      const updatedContact = {};
      if (Type === 'strengths') {
        const updatedStrengths = [...strengths];
        updatedStrengths[Index] = Data[`Strength ${Index + 1}`];
        updatedContact.old = [Data.old];
        updatedContact.strengths = updatedStrengths;
      }
      if (Type === 'issues') {
        const updatedIssues = [...issues];
        updatedIssues[Index] = Data[`Potential Issue ${Index + 1}`];
        updatedContact.old = [Data.old];
        updatedContact.issues = updatedIssues;
      }

      const saveStatus = await individualFieldSave(updatedContact, true, setAutoSaveLoading, false);
      setSaveStatus(saveStatus);
    } catch (e) {
      console.log('Error found in saveStrengthPotential::', e);
    }
  };
  const renderElements = (items, type) => {
    if (items?.length === 0) {
      if (type === 'issues') {
        addIssue();
      } else {
        addStrength();
      }
    }
    return items.map((item, index) => (
      <>
        <div style={{ fontSize: '12px', lineHeight: '14px', color: 'rgba(92, 82, 198, 0.8)', marginBottom: '2px' }}>
          <span>{`${type === 'strengths' ? 'Strength' : 'Issue'} ${index + 1}`}</span>
        </div>
        <div className='contact-view-value d-flex' key={item?.id + '-' + index} style={{ paddingBottom: 14 }}>
          <div className='w-100'>
            <RichTextPopup
              className='strength-potential'
              InputLabelProps={{ focused: true }}
              onSave={async data => {
                await handleChange(type, index, data);
              }}
              displayToolbar={false}
              expandToContent={true}
              value={item}
              autoSave={saveStrengthPotential}
              item={item}
              index={index}
              commentType={type}
              label={`${type === 'issues' ? 'Potential Issue' : 'Strength'} ${index + 1}`}
              title={`${type === 'issues' ? 'Potential Issue' : 'Strength'} ${index + 1}`}
              name={`${type === 'issues' ? 'Potential Issue' : 'Strength'} ${index + 1}`}
              updatedDate={contact.updated_at}
              saveStatus={saveStatus}
              strengthsAndPotentialIssues={true}
              payloadType={'default'}
            />
          </div>
          <div>
            <CloseIcon cursor='pointer' onClick={() => handleDelete(type, index)} style={{ width: 18, height: 18, marginTop: 5 }} />
          </div>
        </div>
      </>
    ));
  };

  return (
    <div className='input-form d-flex'>
      <div className='tab-view-content col-sm-9 mt-2 '>
        <div id='strengths' className='section-container'>
          <div className='section-header'>Strengths</div>
          {renderElements(strengths, 'strengths')}
          <Button disabled={strengths?.length > 7} variant={'text'} startIcon={<AddCircleIcon />} onClick={addStrength} color='primary'>
            Add Element
          </Button>
        </div>
        <div id='potential' className='section-container'>
          <div className='section-header'>Potential Issues</div>
          {renderElements(issues, 'issues')}
          <Button disabled={issues?.length > 7} variant={'text'} startIcon={<AddCircleIcon />} onClick={addIssue} color='primary'>
            Add Element
          </Button>
        </div>
      </div>
      <div className='tab-view-right-nav col-sm-3 mt-2' style={{ position: 'absolute', right: 0 }}>
        <div className='right-nav-section right-nav-recent-activity-section d-flex flex-column' style={{ position: 'relative' }}>
          <a
            style={rightNav}
            href='/'
            className='right-nav-header'
            onClick={e => {
              e.preventDefault();
              handelScroll('strengths');
            }}
          >
            Strengths
          </a>
          <a
            style={rightNav}
            href='/'
            className='right-nav-header'
            onClick={e => {
              e.preventDefault();
              handelScroll('potential');
            }}
          >
            Potential Issues
          </a>
        </div>
      </div>
    </div>
  );
}

Edit.propTypes = {
  strengths: PropTypes.array,
  issues: PropTypes.array,
  removeIssue: PropTypes.func,
  removeStrength: PropTypes.func,
  addIssue: PropTypes.func,
  addStrength: PropTypes.func,
  handleChange: PropTypes.func,
  contact: PropTypes.object,
  individualFieldSave: PropTypes.func,
  saveStatus: PropTypes.bool,
  setSaveStatus: PropTypes.func,
  handelScroll: PropTypes.func
};
