//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import * as NotesService from '../../services/NotesService';

import Note from './Note';
import Loader from '../common/Loader';
import './index.scss';
import { ERROR } from '../../services/constantService';
import { unableMessage, VALIDATION_MESSAGE } from '../../services/MessageService';

/* eslint-disable-next-line react/display-name */
const Notes = forwardRef((props, ref) => {
  const { apiConfig = {}, className, enqueueSnackbar, notesDetails, dispatchDetails } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [notes, setNotes] = React.useState([]);
  const [isAdding, setIsAdding] = useState(false);

  useImperativeHandle(ref, () => ({
    handleAddNotes() {
      handleAddNotes();
    }
  }));

  useEffect(() => {
    const getNotes = async () => {
      try {
        if (!apiConfig.parentId) {
          return;
        }
        if (!notesDetails) {
          let { status, data } = await NotesService.getAll(apiConfig.parentId, apiConfig.parentPath);
          setIsLoading(false);
          if (status === 200) {
            setNotes(data);
            dispatchDetails(data);
          } else {
            const message = unableMessage('notes', 'fetch');
            enqueueSnackbar(data?.message || message, { variant: ERROR });
          }
        } else {
          setIsLoading(false);
          setNotes(notesDetails);
        }
      } catch (e) {
        console.log('Error found in getNotes::', e);
      }
    };

    getNotes();
  }, [apiConfig, enqueueSnackbar]);

  const handleAddNotes = () => {
    if (!isAdding) {
      setNotes(prev => [...prev, []]);
      setIsAdding(true);
    } else {
      enqueueSnackbar(VALIDATION_MESSAGE.notes_adding, { variant: ERROR });
    }
  };

  const removedNewNote = () => {
    return notes?.filter(item => {
      return item.id != null;
    });
  };

  const handleDeleteNote = async noteId => {
    let existingNotes = [...notes];
    let removeIndex = existingNotes
      .map(function (item) {
        return item.id;
      })
      .indexOf(noteId);
    if (removeIndex !== -1) {
      existingNotes.splice(removeIndex, 1);
      setNotes(existingNotes);
      if (apiConfig.parentPath) {
        dispatchDetails(existingNotes);
      }
    } else {
      setNotes(removedNewNote());
    }
  };

  const handleSaveNote = async note => {
    let existingNotes = [...notes];
    let index = existingNotes
      .map(function (item) {
        return item.id;
      })
      .indexOf(note.id);
    if (index !== -1) {
      existingNotes[index] = note;
    } else {
      let temp = removedNewNote();
      existingNotes = [...temp, note];
    }
    if (apiConfig.parentPath) {
      dispatchDetails(existingNotes);
    }
    setNotes(existingNotes);
    setIsAdding(false);
  };

  return (
    <div className={`${className} position-relative`}>
      <Loader show={isLoading} />
      {notes && notes?.length > 0 ? (
        notes.map((note, index) => (
          <div className='notes-details' key={`notes_${note ? note.id : ''}`}>
            <div className='notes-count'>Note {index + 1}</div>
            <Note note={note} apiConfig={apiConfig} handleDeleteNote={handleDeleteNote} handleSaveNote={handleSaveNote} setIsAdding={setIsAdding} />
          </div>
        ))
      ) : (
        <div className='notes-details'>--</div>
      )}
    </div>
  );
});

Notes.propTypes = {
  apiConfig: PropTypes.object,
  className: PropTypes.string,
  enqueueSnackbar: PropTypes.func,
  dispatchDetails: PropTypes.func,
  notesDetails: PropTypes.object
};

export default Notes;
