//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Typography } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import ListSave from './ListSave';
import ExportList from './ExportList';
import DrawerComponent from '../../components/common/Drawer';

const CustomTabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  index: PropTypes.string
};

const ExportSideNav = props => {
  const { location, isMenuOpen, setIsMenuOpen } = props;
  const { navItems /* className */ } = props;
  const [value, setValue] = React.useState(0);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (!open) setIsMenuOpen(false);
    setState({ ...state, [anchor]: open });
  };

  const getNavItems = () => {
    return navItems && navItems.map((item, index) => <Tab className='export-tab header-font fs-16' key={index} label={item.label} {...a11yProps(index)} />);
  };

  useEffect(() => {
    if (isMenuOpen) setState({ ...state, ['right']: open });
  }, [isMenuOpen]);

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };

  return (
    <>
      <DrawerComponent setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} id={'side-nav-container'} anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)} width='25vw'>
        <>
          <Box className='side-nav-bar p-3'>
            <Box className='side-nav-header'>
              <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                {getNavItems()}
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <ListSave location={location} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ExportList />
            </CustomTabPanel>
          </Box>
        </>
      </DrawerComponent>
    </>
  );
};

ExportSideNav.propTypes = {
  location: PropTypes.object,
  navItems: PropTypes.array,
  className: PropTypes.string,
  isMenuOpen: PropTypes.bool,
  setIsMenuOpen: PropTypes.func
};

export default ExportSideNav;
