//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useRef, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Button, Typography, TextField } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
import { InputLabel } from '@material-ui/core';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import CompanySelection from '../AddContact/Components/CompanySelection';
import YearSelection from '../YearSelection';
import CountrySelection from '../CountrySelection';
import ErrorPopup from '../AddCompany/ErrorPopup';
import Loader from '../common/Loader';
import IndustrySelection from '../IndustrySelection';
import JobFunctionSelection from '../jobFunctionSelection';
import { VALIDATION_MESSAGE } from '../../services/MessageService';

export default function QuickAddContact(props) {
  const { first_name, addContact, handleClose, currentCompany } = props;
  const { register, unregister, control, handleSubmit, getValues, setValue, watch } = useForm({ defaultValues: { first_name } });

  const watchValues = watch();
  const companyRef = useRef();
  const countryRef = useRef();
  const startYearRef = useRef();
  const industryRef = useRef();
  const jobFunctionRef = useRef();
  const [duplicateContacts, setDuplicateContacts] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAdd = async () => {
    setLoading(true);
    console.log('Get Values', getValues());
    console.log({ watchValues });
    //let contact = { ...getValues() };

    const duplicateContacts = await addContact({ ...getValues() });
    if (duplicateContacts) {
      setDuplicateContacts(duplicateContacts);
    }
    setLoading(false);
    // enqueueSnackbar("Contact Added Successfully" ,{ variant: SUCCESS })
  };

  const handleCancel = () => {
    setDuplicateContacts(false);
    handleClose();
  };

  const handleAddDuplicate = async () => {
    try {
      await addContact({ ...getValues() }, true);
    } catch (e) {
      console.log('Error found in handleAddDuplicate::', e);
    }
  };

  const handleShowContacts = () => {
    const idQuery = duplicateContacts.map(contact => `id=${contact.id}`).join('&');
    window.open(`/contacts?${idQuery}`);
  };

  useEffect(() => {
    register('current_company');
    register('current_job_start_year');
    register('country');
    register('industries', { required: true, validate: value => value?.length > 0 });
    register('job_functions', { required: true, validate: value => value?.length > 0 });
    return () => {
      unregister('current_company');
    };
  }, [register, unregister]);

  return (
    <React.Fragment>
      <Loader show={loading} />
      {duplicateContacts && (
        <ErrorPopup
          onCancel={handleCancel}
          onView={handleShowContacts}
          onContinue={handleAddDuplicate}
          header={VALIDATION_MESSAGE.contact_already_exist}
          viewButtonText={'View/Edit existing contacts'}
        />
      )}

      <div className='d-flex quick-add-header text-left'>
        <Typography>Quick Add Contact</Typography>
        <CloseIcon cursor='pointer' onClick={handleClose} />
      </div>
      <div className='d-flex flex-column py-3'>
        <div className='d-flex contact-details-row'>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>
              First Name<span className='MuiInputLabel-asterisk'>*</span>
            </InputLabel>
            <div className='page-label-value'>
              <TextField
                placeholder='First Name'
                // required={true}
                InputLabelProps={{ focused: true }}
                className='input-form-field'
                {...register('first_name')}
                name={'first_name'}
                variant='outlined'
              />
            </div>
          </div>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>
              Last Name<span className='MuiInputLabel-asterisk'>*</span>
            </InputLabel>
            <div className='page-label-value'>
              <TextField
                placeholder='Last Name'
                // required={true}
                InputLabelProps={{ focused: true }}
                className='input-form-field'
                {...register('last_name')}
                name={'last_name'}
                variant='outlined'
              />
            </div>
          </div>
        </div>
        <div className='d-flex contact-details-row '>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>
              Company Name<span className='MuiInputLabel-asterisk'>*</span>
            </InputLabel>
            <div className='page-label-value'>
              <Controller
                name='current_company'
                defaultValue={currentCompany || null}
                render={({ field }) => (
                  <CompanySelection
                    {...field}
                    name='current_company'
                    {...register('current_company')}
                    // required={true}
                    inputRef={companyRef}
                    defaultValue={currentCompany || null}
                    className='input-form-field'
                    placeholder={'Search'}
                    InputLabelProps={{ focused: true }}
                    variant='outlined'
                    label=''
                    onChange={(e, data) => setValue('current_company', data ? data : null)}
                  />
                )}
                control={control}
                onFocus={() => {
                  companyRef.current.focus();
                }}
                onChange={([, data]) => (data ? data : null)}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>
              Job Title<span className='MuiInputLabel-asterisk'>*</span>
            </InputLabel>
            <div className='page-label-value'>
              <TextField
                // required
                placeholder='Job Title'
                className='input-form-field'
                {...register('current_job_title')}
                name='current_job_title'
                InputLabelProps={{ focused: true }}
                variant='outlined'
              />
            </div>
          </div>
        </div>
        <div className='d-flex contact-details-row '>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>
              Job Start Year<span className='MuiInputLabel-asterisk'>*</span>
            </InputLabel>
            <div className='page-label-value'>
              <Controller
                name={'current_job_start_year'}
                render={({ field }) => (
                  <YearSelection
                    name={'current_job_start_year'}
                    {...field}
                    {...register('current_job_start_year')}
                    autoSelect
                    autoHighlight
                    className='input-form-field'
                    value={watch('current_job_start_year') || null}
                    onChange={(e, data) => {
                      setValue('current_job_start_year', data);
                    }}
                    yearType='start'
                    // inputRef={startYearRef}
                    variant='outlined'
                    // required={true}
                    label={''}
                    InputLabelProps={{ focused: true }}
                  />
                )}
                control={control}
                onFocus={() => {
                  startYearRef.current.focus();
                }}
                onChange={([, data]) => data}
                // rules={{ required: true }}
              />
            </div>
          </div>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>
              Location<span className='MuiInputLabel-asterisk'>*</span>
            </InputLabel>
            <div className='page-label-value'>
              <Controller
                name='country'
                render={({ field }) => (
                  <CountrySelection
                    {...field}
                    name={'country'}
                    {...register('country')}
                    // required={true}
                    // inputRef={countryRef}
                    className='input-form-field'
                    value={watch('country') || ''}
                    onChange={(e, data) => {
                      setValue('country', data.name);
                    }}
                    placeholder={'country'}
                    InputLabelProps={{ focused: true }}
                    label={''}
                    variant='outlined'
                  />
                )}
                control={control}
                onFocus={() => {
                  countryRef.current.focus();
                }}
                onChange={([, data]) => (data ? data.name : null)}
                // rules={{ required: true }}
              />
            </div>
          </div>
        </div>
        <div className='d-flex contact-details-row '>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>
              Industry<span className='MuiInputLabel-asterisk'>*</span>
            </InputLabel>
            <div className='page-label-value multiple-selection-list'>
              <Controller
                name='industries'
                render={({ field }) => (
                  <IndustrySelection
                    {...field}
                    name={'industries'}
                    multiple
                    {...register('industries')}
                    // required={true}
                    // placeholder={"Select"}
                    variant='outlined'
                    className='input-form-field'
                    inputRef={industryRef}
                    // value={watch('industries') || []}
                    onChange={(e, data) => {
                      setValue('industries', data ? data.map(item => (typeof item === 'string' ? item : item.name)) : []);
                    }}
                    size='small'
                    filterSelectedOptions={true}
                    InputLabelProps={{ focused: true }}
                  />
                )}
                control={control}
                onFocus={() => {
                  industryRef.current.focus();
                }}
                onChange={([, data]) => (Array.isArray(data) ? data.map(item => item.name) : [])}
                // rules={{ required: true, validate: d => d?.length > 0 }}
              />
            </div>
          </div>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>
              Job function<span className='MuiInputLabel-asterisk'>*</span>
            </InputLabel>
            <div className='page-label-value multiple-selection-list'>
              <Controller
                name='job_functions'
                render={({ field }) => (
                  <JobFunctionSelection
                    {...field}
                    name={'job_functions'}
                    {...register('job_functions')}
                    multiple
                    variant='outlined'
                    // required={true}
                    // inputRef={jobFunctionRef}
                    className='input-form-field '
                    value={watch('job_functions') || []}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('job_functions', data ? data.map(item => (typeof item === 'string' ? item : item.name)) : []);
                    }}
                    filterSelectedOptions={true}
                    label=''
                  />
                )}
                control={control}
                onFocus={() => {
                  jobFunctionRef.current.focus();
                }}
                onChange={([, data]) => {
                  return data ? data.map(item => (typeof item === 'string' ? item : item.name)) : [];
                }}
                // rules={{ required: true, validate: d => d?.length > 0 }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='page-content-footer'>
        <Button className='page-btn-cancel' onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant='contained' type='submit' className='page-btn-save' onClick={handleSubmit(handleAdd)}>
          Submit
        </Button>
      </div>
    </React.Fragment>
  );
}

QuickAddContact.propTypes = {
  first_name: PropTypes.string,
  addContact: PropTypes.func,
  handleClose: PropTypes.func,
  currentCompany: PropTypes.object
};
