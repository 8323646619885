//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
/* import { useDispatch, useSelector } from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchPickList } from "../../actions"; */
import InputField from '../common/InputField';
import { GET } from '../../services/constantService';
import { picklistsDataApi } from '../../services/ApiService';
import { fetchPickList } from '../../actions';
function IndustrySelection(props) {
  const {
    label,
    placeholder = 'Industry',
    inputRef,
    InputLabelProps,
    required = false,
    disabled = false,
    mainCatagory,
    isGroupIndustry,
    availableCategory,
    isSearchIndustry = false,
    variant = 'standard',
    ...rest
  } = props;

  const [options, setOptions] = useState([]);
  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const filterOptions = data => {
    return data?.filter(item => item.name.match(new RegExp(value, 'gi')));
  };
  const industries = useSelector(state => state.commonReducer.industries);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    if ((!industries && mainCatagory !== 'empty') || (!industries && !isGroupIndustry)) {
      dispatch(fetchPickList('industries'));
    } else if (mainCatagory) {
      let sub_route = `industries/search?group=${mainCatagory}`;
      if (mainCatagory === 'empty') {
        setOptions([]);
        setLoading(false);
      } else {
        picklistsDataApi(GET, '', sub_route).then(response => {
          const { status, data } = response;
          if (status === 200) {
            const filteredData = filterOptions(data.data);
            setOptions(filteredData);
          }
          setLoading(false);
        });
      }
    } else if (isGroupIndustry) {
      const filteredData = filterOptions(availableCategory);
      setOptions(filteredData);
      setLoading(false);
    } else if (isSearchIndustry) {
      const filteredData = filterOptions(industries);
      setOptions(filteredData);
      setLoading(false);
    } else if (value?.length > 0) {
      const sub_route = `industries/search?name=${value}`;
      picklistsDataApi(GET, '', sub_route).then(response => {
        const { status, data } = response;
        if (status === 200) {
          let filteredData = data.data?.filter(singleData => {
            return singleData.name.toLowerCase().includes(value.toLowerCase());
          });
          setOptions(filteredData || data.data);
        }
        setLoading(false);
      });
    } else {
      setOptions(industries);
    }
    setLoading(false);
  }, [availableCategory, isGroupIndustry, mainCatagory, value, industries, isSearchIndustry]);

  /* useEffect(() => {
   if (!industries) {
     dispatch(fetchPickList('industries'))
   } else {
     setOptions(industries);
   }
 }, [industries, dispatch]) */

  return (
    <Autocomplete
      {...rest}
      options={options}
      filterOptions={options => options}
      getOptionSelected={(option, value) => {
        if (!value) {
          return false;
        }
        if (typeof value === 'string') {
          return option.name === value;
        }
        return option.name === value.name;
      }}
      getOptionLabel={option => {
        if (!option) {
          return '';
        }
        if (typeof option === 'string') {
          return option;
        }
        return option.name;
      }}
      onInputChange={(e, val, reason) => {
        if (reason === 'input') {
          setValue(val);
        }
      }}
      // onChange={(e)=>{
      //   console.log(e.target.value)
      // }}
      disabled={disabled}
      renderInput={params => (
        <InputField
          {...params}
          ref={inputRef}
          required={required}
          variant={variant}
          InputLabelProps={InputLabelProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color='primary' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}

IndustrySelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  inputRef: PropTypes.object,
  disabled: PropTypes.bool,
  mainCatagory: PropTypes.string,
  isGroupIndustry: PropTypes.bool,
  availableCategory: PropTypes.array,
  variant: PropTypes.string,
  isSearchIndustry: PropTypes.bool
};
export default IndustrySelection;
