//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import ActivityHistoryDetails from '../../ActivityLog/Components/GeneralInfo/Components/ActivityHistory/ActivityHistoryDetails';

export default function ActivityLog(props) {
  const { contact = {} } = props;

  return (
    <div className='activity-histories-content'>
      <ActivityHistoryDetails contact={contact} />
    </div>
  );
}

ActivityLog.propTypes = {
  contact: PropTypes.object
};
