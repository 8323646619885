//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------

const options = [{ label: 'Others', value: 'Others' }];

const IntervalSelection = props => {
  const { defaultValue, onChange, variant, className, name } = props;
  return (
    <Select defaultValue={defaultValue} className={className} variant={variant} onChange={onChange} name={name}>
      {options.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

IntervalSelection.propTypes = {
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string
};

export default IntervalSelection;
