export const CONSTANTS_SHOW_OFF_LIMIT = {
  DIRECT: 'Direct',
  INHERITED: 'Inherited',
  CLASSNAMES: {
    EDIT_OFF_LIMIT_ICON: 'edit-offlimit-icon',
    CAUTION_ICON: 'cuation-icon',
    CONTENT_CONTAINER: 'content-container',
    ICON_CONTAINER: 'icon-container',
    OFF_LIMIT_ENTRY: 'off-limit-entry',
    TITLE: 'title',
    VALUE: 'value',
    UNDERLINE: 'underline'
  }
};
