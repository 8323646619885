//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import PopoverStickOnHover from '../../components/common/PopoverStickOnHover';

const ActionsPopover = props => {
  const { list = [], label, className = '' } = props;

  return (
    <div className={className}>
      <PopoverStickOnHover
        component={
          <Typography component={'div'} className='pop-over-actions'>
            <div className='d-flex flex-column'>
              {list.map((item, index) => {
                if (item.link) {
                  return (
                    <Link key={index} to={item.link}>
                      <span className='pop-over-action-item pop-over-action-value cursor-pointer' onClick={item.onClick}>
                        {item.label}
                      </span>
                    </Link>
                  );
                }
                if (item.externalLink) {
                  return (
                    <a key={index} href={item.externalLink} target={'noopener'} className='action-label pop-over-action-item pop-over-action-value cursor-pointer'>
                      {item.label}
                    </a>
                  );
                }
                return (
                  <span key={index} className={`pop-over-action-item pop-over-action-value cursor-pointer ${item.checkDisable === true ? 'disagree-disable' : ''}`} onClick={item.onClick}>
                    {item.label}
                  </span>
                );
              })}
            </div>
          </Typography>
        }
        placement='bottom'
        onMouseEnter={() => {}}
        delay={200}
      >
        {label ? <div className='action-label'>{label}</div> : <div className='action-label'>{list?.length ? `${list[0].label}` : ''}</div>}
      </PopoverStickOnHover>
    </div>
  );
};

ActionsPopover.propTypes = {
  list: PropTypes.array,
  label: PropTypes.node,
  className: PropTypes.string
};

export default ActionsPopover;
