import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Grid, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CustomButton from '../../../components/common/CustomButton';

const attachment = {
  id: '1',
  title: 'Certificate',
  subTitle: 'Pearl Academy, Mumbai, Maharashtra',
  yearFrom: '2017',
  yearTo: '2021'
};
const AttachmentCard = ({ cardData, handleCheckFunction, isChecked }) => {
  return (
    <Box
      className='p-2'
      sx={{
        backgroundColor: 'white',
        borderRadius: '8px',
        border: '0.5px solid gray'
      }}
    >
      <Stack direction='row' justifyContent='space-between' fullWidth>
        <Box>
          <Typography variant='body1' sx={{ fontWeight: '600' }}>
            {cardData.title}
          </Typography>
          <Typography variant='caption'>{cardData.subTitle}</Typography>
        </Box>
        <Checkbox checked={isChecked} className='p-1' sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }} color='primary' onChange={() => handleCheckFunction(cardData)}></Checkbox>
      </Stack>
      <Stack direction='row' justifyContent='space-between' fullWidth>
        <Box>
          <Typography variant='caption'>{cardData.yearFrom + '-' + cardData.yearTo}</Typography>
        </Box>
        <Stack direction='row'>
          <CustomButton variant='contained' type='primary p-1 m-1' customWidth={0} buttonText={<EditIcon fontSize='small' />} />

          <CustomButton variant='contained' type='p-1 m-1' customWidth={0} buttonText={<DeleteForeverIcon />} />
        </Stack>
      </Stack>
    </Box>
  );
};

const Attachments = props => {
  const { getValueFromUniversalState, handleUniversalState } = props;
  const [selectedAttachments, setSelectedAttachments] = useState([]);

  const handleUniversalStateLocally = data => {
    const event = {
      target: {
        name: 'attachments',
        value: data
      }
    };
    handleUniversalState(event);
  };
  const isCheckedFunction = candidateId => {
    if (selectedAttachments.filter(attachment => attachment.id === candidateId).length) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheckFunction = data => {
    if (selectedAttachments.filter(attachment => attachment.id === data.id).length == 0) {
      selectedAttachments.push(data);
      setSelectedAttachments(selectedAttachments.slice());
      handleUniversalStateLocally(selectedAttachments.slice());
      return;
    }
    if (selectedAttachments.filter(attachment => attachment.id == data.id).length > 0) {
      const newSelectedAttachments = selectedAttachments.filter(attachment => attachment.id != data.id);
      setSelectedAttachments(newSelectedAttachments.slice());
      handleUniversalStateLocally(newSelectedAttachments.slice());
    }
  };

  useEffect(() => {
    //first render
    const attachments = getValueFromUniversalState('attachments').length ? getValueFromUniversalState('attachments') : [];
    console.log(attachments);
    setSelectedAttachments(attachments);
  }, []);
  return (
    <Accordion fullWidth className='m-2' defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='body1' sx={{ fontWeight: '600' }}>
          Attachments
        </Typography>
      </AccordionSummary>
      <AccordionDetails className='py-4'>
        <Grid container spacing={2}>
          {[attachment, { ...attachment, id: 2 }, { ...attachment, id: 3 }, { ...attachment, id: 4 }].map((attachment, index) => (
            <Grid item xs={12} md={4} key={index}>
              <AttachmentCard cardData={attachment} handleCheckFunction={handleCheckFunction} isChecked={isCheckedFunction(attachment.id)} />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

Attachments.propTypes = {
  handleUniversalState: PropTypes.func.isRequired,
  getValueFromUniversalState: PropTypes.func.isRequired
};
AttachmentCard.propTypes = {
  cardData: PropTypes.object.isRequired,
  handleCheckFunction: PropTypes.func,
  isChecked: PropTypes.bool
};

export default Attachments;
