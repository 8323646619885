import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { TabSelect } from '../SubTabs';

export const MoreFilter = ({ show, remainingTabs, onClose }) => {
  if (!show) return <></>;

  return (
    <>
      <Box
        gap={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          top: '2rem',
          position: 'absolute',
          width: '150px',
          background: 'white',
          zIndex: 5,
          boxShadow: 1,
          padding: 1
        }}
      >
        {remainingTabs.map(tab => (
          <TabSelect fromMoreFilter key={tab?.id} tab={tab} width='100%' />
        ))}
      </Box>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 2
        }}
        onClick={onClose}
      ></Box>
    </>
  );
};

MoreFilter.propTypes = {
  show: PropTypes.bool,
  remainingTabs: PropTypes.array,
  onClose: PropTypes.func
};
