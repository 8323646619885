import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
const columnDefs = [
  {
    cellRenderer: 'checkboxRenderer',
    width: 70,
    resizable: true,
    sortable: true,
    headerClass: 'ag-right-aligned-header'
  },
  {
    field: 'name',
    headerName: 'Resource Name',
    width: 250,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'of_type',
    headerName: 'Type',
    width: 250,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'parent',
    headerName: 'Parent',
    width: 250,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 600,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  }
];

const CheckBoxRenderer = props => {
  const { setToDelete } = props;
  return (
    <Checkbox
      onClick={() => {
        setToDelete(prev => {
          const index = prev.indexOf(props.data.id);
          if (index > -1) {
            return prev.filter(id => id !== props.data.id);
          }
          return [...prev, props.data.id];
        });
      }}
    />
  );
};

CheckBoxRenderer.propTypes = {
  data: PropTypes.object,
  setToDelete: PropTypes.func
};

const TypeOptions = [
  {
    label: 'Menu'
  },
  {
    label: 'Page'
  },
  {
    label: 'Section'
  },
  {
    label: 'Report'
  },
  {
    label: 'Attachment'
  },
  {
    label: 'Toolbar'
  }
];

const ParentOptions = [
  {
    label: 'Root'
  },
  {
    label: 'Contact'
  },
  {
    label: 'Project'
  },
  {
    label: 'CompanyDetails'
  },
  {
    label: 'Company'
  },
  {
    label: 'Setup'
  }
];

export { CheckBoxRenderer, ParentOptions, TypeOptions, columnDefs };
