//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Button, Typography, TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../../../../common/InputField';
import { separator } from '../../../../../../Containers/Commons/Utils';
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR } from '../../../../../../services/constantService';

export default function RepresentativeTargets(props) {
  const { register, setValue, currentValues, currencyDetails, watch, unregister, readOnly } = props;
  const [representativeTargets, setRepresentativeTargets] = useState([{}]);
  const [updateCount, setUpdateCount] = useState(0);

  useEffect(() => {
    register('representative_target_companies');
    setRepresentativeTargets(currentValues.representative_target_companies || []);
    return () => {
      unregister('representative_target_companies');
    };
  }, [currentValues.representative_target_companies, register, setValue]);

  // useEffect(() => {
  //   setValue('representative_target_companies', representativeTargets);
  //   return () => {
  //     unregister('representative_target_companies');
  //   };
  // }, [representativeTargets, setValue])

  const updateRepresentativeTargets = (index, name, data) => {
    const currentContactTargets = [...representativeTargets];
    currentContactTargets[index][name] = data;
    setRepresentativeTargets(currentContactTargets);
    if (updateCount > 1) {
      register('representative_target_companies');
      setValue('representative_target_companies', currentContactTargets);
    }
    setUpdateCount(updateCount + 1);
  };

  const deleteRepresentativeTarget = index => {
    const currentContactTargets = [...representativeTargets];
    const callApi = Object.values(currentContactTargets[index])
      .slice(0, -1)
      .every(value => value == null)
      ? false
      : true;
    currentContactTargets.splice(index, 1);
    setRepresentativeTargets(currentContactTargets);
    if (callApi || updateCount > 0) {
      register('representative_target_companies');
      setValue('representative_target_companies', currentContactTargets);
    }
  };

  const addRepresentativeTarget = () => {
    const currentContactTargets = [...representativeTargets, {}];
    setRepresentativeTargets(currentContactTargets);
  };

  return (
    <div className={`section-content ${readOnly ? 'read-only' : ''}`}>
      <Typography className='section-label'>Synclink Target Companies</Typography>
      {representativeTargets.map((item, index) => (
        <div key={index} style={{ marginBottom: 10 }}>
          <Typography className='sub-section-label'>Target Company {index + 1}</Typography>
          <div className='d-flex align-items-center'>
            <InputField
              label='Company'
              placeholder='Company'
              className='input-form-field input-field-old'
              value={item.name || ''}
              InputLabelProps={{ focused: true }}
              onChange={e => {
                updateRepresentativeTargets(index, 'name', e.target.value);
              }}
            />
            <InputField
              label='Industries'
              placeholder='Industries'
              className='input-form-field input-field-old'
              value={item.industries || ''}
              InputLabelProps={{ focused: true }}
              onChange={e => {
                updateRepresentativeTargets(index, 'industries', e.target.value);
              }}
            />
            <div className=' mt-1'>
              {/* <InputLabel className="contact-view-label" >Revenue</InputLabel> */}
              <div className='input-form-field input-field-old'>
                <NumericFormat
                  customInput={TextField}
                  style={{
                    border: 'none',
                    // borderBottom: '2px solid #a3a3a3',
                    width: '100px'
                  }}
                  label='Revenue'
                  placeholder='Revenue'
                  InputLabelProps={{ focused: true }}
                  value={item.revenue}
                  thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                  decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                  decimalScale={2}
                  isNumericString={true}
                  prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                  suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                  onValueChange={values => {
                    const { value } = values;
                    updateRepresentativeTargets(index, 'revenue', value);
                  }}
                />
              </div>
            </div>
            <InputField
              label='Website'
              placeholder='Website'
              className='input-form-field input-field-old'
              value={item.website || ''}
              InputLabelProps={{ focused: true }}
              onChange={e => {
                updateRepresentativeTargets(index, 'website', e.target.value);
              }}
            />
            <DeleteIcon fontSize='small' className='cursor-pointer ' onClick={() => deleteRepresentativeTarget(index)} />
          </div>
        </div>
      ))}
      {representativeTargets?.length < 10 ? (
        <Button variant={'text'} startIcon={<AddCircleIcon />} onClick={addRepresentativeTarget} color='primary'>
          Add Company
        </Button>
      ) : null}
    </div>
  );
}

RepresentativeTargets.propTypes = {
  register: PropTypes.func,
  unregister: PropTypes.func,
  readOnly: PropTypes.bool,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currencyDetails: PropTypes.object,
  currentValues: PropTypes.object
};
