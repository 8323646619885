//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useCallback, useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import View from './View';
import Edit from './Edit';
import Loader from '../../common/Loader';
import { GET, ERROR } from '../../../services/constantService';
import { userDataApi } from '../../../services/ApiService';
import { unableMessage } from '../../../services/MessageService';

function Details(props) {
  const { user: userInfo, setIsDataUpdated, userUpdate } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState({ ...userInfo });

  const getUser = useCallback(async () => {
    setLoading(true);
    const { status, data } = await userDataApi(GET, userInfo.id);
    if (status === 200) {
      setUser(data);
    } else {
      const message = unableMessage('user details', 'fetch');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setLoading(false);
  }, [userInfo, enqueueSnackbar]);

  useEffect(() => {
    getUser().then(null);
  }, [getUser]);

  return (
    <>
      <Loader show={loading} />
      {isEditing ? (
        <Edit user={user} getUser={getUser} setIsEditing={setIsEditing} setIsDataUpdated={setIsDataUpdated} userUpdate={userUpdate} />
      ) : (
        <View user={user} getUser={getUser} setUser={setUser} setIsEditing={setIsEditing} isEditing={isEditing} userUpdate={userUpdate} setIsDataUpdated={setIsDataUpdated} />
      )}
    </>
  );
}

Details.propTypes = {
  user: PropTypes.object,
  setIsDataUpdated: PropTypes.func,
  userUpdate: PropTypes.func
};

export default Details;
