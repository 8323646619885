import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Box, TableRow, TableCell } from '@mui/material';
import CustomTable from '../../../common/CustomTable';
import { getFixedDecimal } from '../../../../utils/common';

//----------------------------------------------// Internal Imports // -------------------------------------------------

const IndirectFee = props => {
  const { currentValues, getTotalPercentageOfBase, type = 'estimated' } = props;
  const [indirectFee, setIndirectFee] = useState([]);

  useEffect(() => {
    if (currentValues) {
      setIndirectFee(currentValues.project_indirect_fees || []);
    }
  }, [currentValues]);

  const handleAddIndirectFee = () => {
    let newIndirectFee = [...indirectFee, {}];
    setIndirectFee(newIndirectFee);
  };

  const calculateAmount = () => {
    setIndirectFee(prevState => [
      ...prevState.map(ele => {
        return {
          ...ele,
          calculation_amt:
            ele.calculation_type === 'flat_amount'
              ? ele.calculation_amt
              : getFixedDecimal((getTotalPercentageOfBase(currentValues?.estimated_percentage_bases, `calculated_${type}_amt`).number * ele.percentage) / 100)
        };
      })
    ]);
  };

  useEffect(() => {
    if (indirectFee) {
      calculateAmount();
    }
  }, [currentValues?.estimated_percentage_bases]);
  return (
    <Box id='container-communication'>
      <Box className='head-container'>
        <Box className='sub-head-content'>
          <Box className='field-label-input'>
            <Box className='d-flex flex-column  my-2'>
              <CustomTable
                headerData={['Fee Type', 'Calculation Type', 'Percentage', 'Calculated Amount']}
                title={'Indirect Fee'}
                handleAnotherRow={handleAddIndirectFee}
                rows={indirectFee}
                showAddAnotherButton={false}
                // dataList={phoneList}
              >
                {indirectFee.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className='input-field- p-2'>{<Box className='col-3 team-info-field'>{item.type || '--'}</Box>}</TableCell>
                      <TableCell className='p-2'>{<Box className='col-3 team-info-field'>{item.calculation_type || '--'}</Box>}</TableCell>
                      <TableCell className='p-2'>{<Box className=''>{item?.percentage || '--'}</Box>}</TableCell>
                      <TableCell className='p-2'>{<Box className='col-3 team-info-field'>{item.calculation_amt || '--'}</Box>}</TableCell>
                    </TableRow>
                  );
                })}
              </CustomTable>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

IndirectFee.propTypes = {
  currentValues: PropTypes.object,
  getTotalPercentageOfBase: PropTypes.func,
  watch: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  getValueWithCurrency: PropTypes.func
};

export default IndirectFee;
