import { SearchRounded } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomInputField from '../../../../components/common/StyledComponents/CustomInputField';
import './index.scss';
import CustomButton from '../../../../components/common/CustomButton';
import { ClientPortalMenuSetupApi } from '../../../../services/ApiService';
import PropTypes from 'prop-types';
import Loader from '../../../../components/common/Loader';
import { GET } from '../../../../services/constantService';
import { enqueueSnackbar } from 'notistack';

const ClientSuiteHeader = ({ projectId = 0, onChangeSearch = () => {}, onClickMenuItem = () => {} }) => {
  const [headerButtons, setHeaderButtons] = useState([]);
  const [loader, setLoader] = useState(false);
  const fetchClientMenuSetupData = async () => {
    try {
      setLoader(true);
      if (projectId) {
        const res = await ClientPortalMenuSetupApi(GET, `/get-all?item_type=CLIENT_PORTAL_MAIN_MENU_ITEMS&project_id=${projectId}`);
        setHeaderButtons(res?.data ?? []);
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchClientMenuSetupData();
  }, [projectId]);

  return (
    <Box className='header-container p-2'>
      <Loader show={loader} />
      <CustomInputField
        id='outlined-basic'
        className='header-input'
        hiddenLabel={true}
        // label="Search"
        fullWidth={false}
        startIcon={<SearchRounded />}
        onChange={e => onChangeSearch(e.target.value)}
      />
      <Stack direction={'row'} justifyContent={'flex-start'} className='w-100 ml-2'>
        {headerButtons &&
          headerButtons.map(button => {
            if (button?.enabled && button?.long_desc && !button?.long_desc.includes('N')) {
              return <CustomButton variant='text' type={'tertiary bar-buttons mr-2'} key={button?.field_value} buttonText={button?.short_desc} onClick={() => onClickMenuItem(button?.field_value)} />;
            }
          })}
      </Stack>
    </Box>
  );
};

ClientSuiteHeader.propTypes = {
  projectId: PropTypes.string,
  onChangeSearch: PropTypes.func,
  onClickMenuItem: PropTypes.func
};

export default ClientSuiteHeader;
