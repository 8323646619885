import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DynamicTabLayout from '../../../components/DynamicTabs/TabLayout';
import TagSetupList from './List';

const TagSetup = () => {
  const { t } = useTranslation();
  const TabData = [
    {
      label: t('tagSetup.title'),
      content: t('tagSetup.title'),
      subTabs: []
    }
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <React.Fragment>
      <div className='position-relative m-3'>
        <DynamicTabLayout navItems={TabData} isDrawer={true} isDrawerCss={true} activeTab={activeTab} tabIndex={tabIndex} setTabIndex={setTabIndex} setActiveTab={setActiveTab}>
          <TagSetupList index={t('tagSetup.title')} />
        </DynamicTabLayout>
      </div>
    </React.Fragment>
  );
};

export default TagSetup;
