//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { IconButton, Tooltip } from '@mui/material';
import './index.scss';
import styles from './../../../variables.scss';

const HelpDesk = ({ className }) => {
  //const [setIsMenuOpen] = useState(false);
  // const toggleMenu = () => {
  //   setIsMenuOpen(prev => !prev);
  // };
  const openWidget = () => {
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        color: {
          theme: styles.primary,
          button: styles.primary
        }
      }
    });
    //toggleMenu()
  };
  return (
    <Tooltip title='Help' className={className}>
      <IconButton className='help-icon-button' onClick={openWidget} edge='end' aria-label='logo'>
        <HelpRoundedIcon className='help-icon' />
      </IconButton>
    </Tooltip>
  );
};

HelpDesk.propTypes = {
  className: PropTypes.string
};

export default HelpDesk;
