import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { globalSearchSelector } from '../../../selectors';
import { updateGlobalSearchData } from '../../../store/globalSearchSlice';
import { getDateRange } from '../helper/utils';

const CustomDateFilter = ({ show, onClose }) => {
  if (!show) return <></>;
  const { selectedSubTabId, selectedMoreFilterTabId, subTabs, filters, filterModel, gridApi, data: globalSearchData, selectedRanges, savedDate } = useSelector(globalSearchSelector);
  const dispatch = useDispatch();

  let tab = subTabs.find(tab => tab?.id === selectedSubTabId);

  if (selectedSubTabId === 'more_filters') {
    tab = subTabs.find(tab => tab?.id === selectedMoreFilterTabId);
  }

  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');

  const handleCheckboxChange = range => {
    dispatch(
      updateGlobalSearchData({
        key: 'selectedRanges',
        data: {
          ...selectedRanges,
          [range]: !selectedRanges[range]
        }
      })
    );
  };

  const isCustomRangeSelected = selectedRanges.custom;
  const startDate = selectedRanges?.startDate;
  const endDate = selectedRanges?.endDate;

  const handleSave = () => {
    setStartDateError('');
    setEndDateError('');

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (isCustomRangeSelected && !startDate) {
      setStartDateError('Please select start date');
      return;
    } else if (isCustomRangeSelected && !endDate) {
      setEndDateError('Please select end date');
      return;
    } else if (isCustomRangeSelected && start?.getTime() > end?.getTime()) {
      setStartDateError('Start date cannot be greater than end date');
      return;
    }

    dispatch(
      updateGlobalSearchData({
        key: 'savedDate',
        data: selectedRanges
      })
    );

    const payload = Object.keys(selectedRanges).reduce((acc, curr) => {
      if (curr !== 'startDate' && curr !== 'endDate' && selectedRanges[curr]) {
        const range = getDateRange(curr, startDate, endDate);
        return [...acc, { value: range }];
      }
      return acc;
    }, []);

    const values = tab?.search_field?.reduce((acc, curr) => ({ ...acc, [curr]: { values: payload, isLocal: tab?.search_type === 'local' } }), {});
    const updatedFilters = { ...filters, ...values };

    Object.keys(updatedFilters).forEach(key => {
      if (updatedFilters?.[key]?.values?.length === 0) delete updatedFilters[key];
    });

    const updatedFilterModel = {
      ...filterModel,
      subTabsFilter: {
        filterType: 'text',
        filter: JSON.stringify({ filters: updatedFilters, isLocal: tab?.search_type === 'local', fields: tab?.search_field, globalSearchData })
      }
    };

    dispatch(updateGlobalSearchData({ key: 'selected', data: updatedFilters }));
    dispatch(updateGlobalSearchData({ key: 'filters', data: updatedFilters }));
    dispatch(updateGlobalSearchData({ key: 'filterModel', data: updatedFilterModel }));
    gridApi?.current?.setFilterModel(updatedFilterModel);
    onClose();
  };

  const handleOnCancel = () => {
    dispatch(
      updateGlobalSearchData({
        key: 'selectedRanges',
        data: savedDate
      })
    );
    onClose();
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          top: '2rem',
          position: 'absolute',
          width: '400px',
          background: 'white',
          zIndex: 5,
          boxShadow: 1,
          paddingX: 2
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginY: 2 }}>
          <Typography fontWeight={600} fontSize={14}>
            Select Date
          </Typography>

          <CloseIcon sx={{ color: '#959fb7', cursor: 'pointer', fontSize: '1.2rem' }} onClick={onClose} />
        </Box>

        <Box display={'flex'} flexDirection={'column'}>
          <FormControlLabel
            sx={{ margin: 0, width: 'fit-content' }}
            control={<Checkbox classes={{ root: 'primary-checkbox' }} size='small' checked={selectedRanges.today} onChange={() => handleCheckboxChange('today')} />}
            label={<Typography sx={{ fontSize: '0.8rem', fontWeight: 500 }}>Today</Typography>}
          />
          <FormControlLabel
            sx={{ margin: 0, width: 'fit-content' }}
            control={<Checkbox classes={{ root: 'primary-checkbox' }} size='small' checked={selectedRanges.yesterday} onChange={() => handleCheckboxChange('yesterday')} />}
            label={<Typography sx={{ fontSize: '0.8rem', fontWeight: 500 }}>Yesterday</Typography>}
          />
          <FormControlLabel
            sx={{ margin: 0, width: 'fit-content' }}
            control={<Checkbox classes={{ root: 'primary-checkbox' }} size='small' checked={selectedRanges.thisWeek} onChange={() => handleCheckboxChange('thisWeek')} />}
            label={<Typography sx={{ fontSize: '0.8rem', fontWeight: 500 }}>This Week</Typography>}
          />
          <FormControlLabel
            sx={{ margin: 0, width: 'fit-content' }}
            control={<Checkbox classes={{ root: 'primary-checkbox' }} size='small' checked={selectedRanges.thisMonth} onChange={() => handleCheckboxChange('thisMonth')} />}
            label={<Typography sx={{ fontSize: '0.8rem', fontWeight: 500 }}>This Month</Typography>}
          />
          <FormControlLabel
            sx={{ margin: 0, width: 'fit-content' }}
            control={<Checkbox classes={{ root: 'primary-checkbox' }} size='small' checked={selectedRanges.custom} onChange={() => handleCheckboxChange('custom')} />}
            label={<Typography sx={{ fontSize: '0.8rem', fontWeight: 500 }}>Custom Date Range</Typography>}
          />

          {isCustomRangeSelected && (
            <Grid container spacing={2} paddingY={1}>
              <Grid item xs={6}>
                <TextField
                  label='Start Date'
                  size='small'
                  type='date'
                  value={startDate}
                  onChange={e => {
                    dispatch(
                      updateGlobalSearchData({
                        key: 'selectedRanges',
                        data: {
                          ...selectedRanges,
                          startDate: e.target.value
                        }
                      })
                    );
                  }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
                {startDateError && (
                  <Typography fontWeight={500} color='red' fontSize={10}>
                    {startDateError}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label='End Date'
                  size='small'
                  type='date'
                  value={endDate}
                  onChange={e =>
                    dispatch(
                      updateGlobalSearchData({
                        key: 'selectedRanges',
                        data: {
                          ...selectedRanges,
                          endDate: e.target.value
                        }
                      })
                    )
                  }
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
                {endDateError && (
                  <Typography fontWeight={500} color='red' fontSize={10}>
                    {endDateError}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
        <Box display={'flex'} paddingY={1.5} justifyContent={'flex-end'} gap={1}>
          <Button variant='text' onClick={handleOnCancel}>
            <Typography fontSize={12} fontWeight={600} color='#c3423f'>
              Cancel
            </Typography>
          </Button>
          <Button variant='contained' onClick={handleSave}>
            <Typography fontSize={12} fontWeight={600}>
              Save
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 2
        }}
        onClick={handleOnCancel}
      ></Box>
    </>
  );
};

export default CustomDateFilter;

CustomDateFilter.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool
};
