import React, { useState, useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import InputField from '../common/InputField';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Loader from '../common/Loader';
import { InputLabel, Typography } from '@mui/material';
import { Button } from '@mui/material';
import { PUT, ERROR, SUCCESS } from '../../services/constantService';
import RoleSelection from '../AddUser/components/Roles';
import * as yup from 'yup';
import { ProductSettingsApi } from '../../services/ApiService';

import { unableMessage, successMessage, VALIDATION_MESSAGE } from '../../services/MessageService';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { customFormValidator, requireValidMessage } from '../../utils/common';

const EditDocumentTypePopup = props => {
  const { enqueueSnackbar } = useSnackbar();
  const { documentTypeData, closePopup, setIsEditing, setDocumentTypeData, setIsDataUpdated } = props;
  const { register, handleSubmit, setValue, unregister, watch } = useForm({ defaultValues: { ...documentTypeData } });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    register('upload_role');
    register('delete_role');
    register('view_role');
    register('download_role');
    return () => {
      unregister('upload_role');
      unregister('delete_role');
      unregister('view_role');
      unregister('download_role');
    };
  }, [register, unregister]);

  const addSchema = yup.object().shape({
    org_id: yup.string().required(),
    doctype_code: yup.string().required(),
    doctype_desc: yup.string().required(),
    allow_extensions: yup.string().required(),
    upload_role: yup.string(),
    delete_role: yup.string(),
    view_role: yup.string(),
    download_role: yup.string()
  });

  const editTranslateValue = async documentData => {
    const isValid = await addSchema.isValid(documentData);
    if (!isValid) {
      let requiredField = [
        { fieldName: 'org_id', label: 'Organization Id', type: String },
        { fieldName: 'doctype_code', label: 'Document Code', type: String },
        { fieldName: 'doctype_desc', label: 'Description', type: String },
        { fieldName: 'allow_extensions', label: 'Allow Extensions', type: String }
      ];
      let dirtyField = customFormValidator(documentData, requiredField);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    setIsLoading(true);
    const { status, data } = await ProductSettingsApi(PUT, documentData, 'document_type', documentTypeData.id);
    if (status === 200) {
      const message = successMessage('Document Type', VALIDATION_MESSAGE.updated_message);
      enqueueSnackbar && enqueueSnackbar(message, { variant: SUCCESS });
      setIsDataUpdated(true);
      setDocumentTypeData(data[1][0]);
      setIsLoading(false);
      setIsEditing(false);
    } else {
      const message = unableMessage('Document Type', 'update');
      enqueueSnackbar && enqueueSnackbar(data?.message || message, { variant: ERROR });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    register('upload_role');
    register('delete_role');
    register('view_role');
    register('download_role');
    return () => {
      unregister('upload_role');
      unregister('delete_role');
      unregister('view_role');
      unregister('download_role');
    };
  }, [register, unregister]);

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <>
      <Helmet>
        <title>Edit Document Type - KGP Galaxy</title>
      </Helmet>

      <React.Fragment>
        <Loader show={isLoading} />
        <form autoComplete='off'>
          <div className='' style={{ background: '#2a364c', color: '#fff' }}>
            <div className='quick-add-header'>
              <Typography>Edit Document Type</Typography>
              <CloseIcon className='cursor-pointer' onClick={closePopup} />
            </div>
          </div>

          <div className='d-flex flex-column py-3'>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className='page-label'>
                  Organization Id
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant='outlined'
                    className='input-form-field'
                    label=''
                    placeholder='Organization Id'
                    {...register('org_id')}
                    name='org_id'
                    InputLabelProps={{ focused: true }}
                    required={true}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className='page-label'>
                  Document Code
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant='outlined'
                    className='input-form-field'
                    label=''
                    placeholder='Document Code'
                    {...register('doctype_code')}
                    name='doctype_code'
                    required={true}
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </div>

              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className='page-label'>
                  Description
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant='outlined'
                    className='input-form-field'
                    label=''
                    placeholder='Description'
                    {...register('doctype_desc')}
                    required={true}
                    name='doctype_desc'
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className='page-label'>
                  Allowed Extensions
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant='outlined'
                    className='input-form-field'
                    label=''
                    required={true}
                    placeholder='Allowed Extensions'
                    {...register('allow_extensions')}
                    name='allow_extensions'
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div id='clientDetails'>
            <div className='team-information-table flex-grow-1 document-table-bg-hide'>
              <Typography className='' style={{ background: '#2a364c', color: '#fff', border: '6px solid #2a364c' }}>
                <div className='d-flex align-items-center table-header p-0' style={{ color: '#fff' }}>
                  Security
                  {/* {editIndex === "client_team" && renderActions("client_team")} */}
                </div>
              </Typography>

              <div className='client-info-table d-flex flex-column py-3 document-table-background'>
                <div className='d-flex contact-details-row'>
                  <div className='d-flex intersection-page-view'>
                    <InputLabel className='page-label'>Upload Role</InputLabel>
                    <div className='page-label-value'>
                      <RoleSelection
                        variant='outlined'
                        className='input-form-field'
                        InputLabelProps={{ focused: true }}
                        onChange={(e, data) => setValue('upload_role', data)}
                        value={watch('upload_role') || null}
                      />
                    </div>
                  </div>
                  <div className='d-flex intersection-page-view'>
                    <InputLabel className='page-label'>View Role</InputLabel>
                    <div className='page-label-value'>
                      <RoleSelection
                        variant='outlined'
                        className='input-form-field'
                        InputLabelProps={{ focused: true }}
                        onChange={(e, data) => setValue('view_role', data)}
                        value={watch('view_role') || null}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex contact-details-row'>
                  <div className='d-flex intersection-page-view'>
                    <InputLabel className='page-label'>Delete Role</InputLabel>
                    <div className='page-label-value'>
                      <RoleSelection
                        variant='outlined'
                        className='input-form-field'
                        InputLabelProps={{ focused: true }}
                        onChange={(e, data) => setValue('delete_role', data)}
                        value={watch('delete_role') || null}
                      />
                    </div>
                  </div>
                  <div className='d-flex intersection-page-view'>
                    <InputLabel className='page-label'>Download Role</InputLabel>
                    <div className='page-label-value'>
                      <RoleSelection
                        variant='outlined'
                        className='input-form-field'
                        InputLabelProps={{ focused: true }}
                        value={watch('download_role') || null}
                        onChange={(e, data) => setValue('download_role', data)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='page-content-footer'>
            <Button className='page-btn-cancel' onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant='contained' type='submit' className='page-btn-save' onClick={handleSubmit(editTranslateValue)}>
              Submit
            </Button>
          </div>
        </form>
      </React.Fragment>
    </>
  );
};

export default EditDocumentTypePopup;

EditDocumentTypePopup.propTypes = {
  documentTypeData: PropTypes.object,
  closePopup: PropTypes.func,
  setIsEditing: PropTypes.func,
  setDocumentTypeData: PropTypes.func,
  setIsDataUpdated: PropTypes.func
};
