/* eslint-disable no-unused-vars */
import { Box, Checkbox, Divider, Grid, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../components/common/CustomButton';
import CustomDropdown from '../../../../components/common/CustomDropdown';
import CustomMuiDropdown from '../../../../components/common/CustomMuiDropdown';
import CustomPopup from '../../../../components/common/CustomPopup';
import CustomInputField from '../../../../components/common/StyledComponents/CustomInputField';
import { activityInfoApi } from '../../../../services/ApiService';
import { GET, POST, PUT } from '../../../../services/constantService';
import useApi from '../../../../utils/Hooks/useApiHook';
import './index.scss';

const additionalFields = [
  {
    label: 'project-id',
    title: 'Project Id',
    lookupData: ['all-projects', 'all-bds']
  },
  {
    label: 'dictation',
    title: 'Dictation',
    lookupData: []
  },
  {
    label: 'description',
    title: 'Description',
    lookupData: []
  },
  {
    label: 'date-time',
    title: 'Date & Time',
    lookupData: []
  },
  {
    label: 'timezone',
    title: 'Time Zone',
    lookupData: ['all-timezones']
  },
  {
    label: 'attendees',
    title: 'Attendees',
    lookupData: ['project-team', 'client-contacts', 'all-users']
  },
  {
    label: 'due-date',
    title: 'Due Date',
    lookupData: []
  },
  {
    label: 'send-ical',
    title: 'Send iCal',
    lookupData: []
  }
];

const additionalLabels = [
  {
    label: 'English',
    language_code: 'en-US',
    required: true
  },
  {
    label: 'Spanish',
    language_code: 'es-US'
  },
  {
    label: 'Portugese',
    language_code: 'pt-BR'
  }
];

const AddTypePopup = props => {
  const { t } = useTranslation();
  const { currentRowData, isOpen, setIsOpen, setCurrentRowData, refetch } = props;
  const { register, handleSubmit, getValues, setValue, watch, reset } = useForm({});
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);

  const { refetch: refetchFields } = useApi({
    queryKey: 'getAllActivityTypes',
    queryFn: async () => {
      setIsLoading(true);
      if (currentRowData) {
        const data = await activityInfoApi(GET, '', `activity-fields?activityTypeCode=${currentRowData?.activity_type_code}`);
        setValue(
          'additional_fields',
          additionalFields.map(item => {
            const fieldData = data?.data.find(field => field.field === item.label);
            return {
              label: item.label,
              title: capitalize(item.title),
              active: fieldData ? true : false,
              mandatory: fieldData ? fieldData.mandatory : false,
              lookupData: !fieldData?.lookup_data || fieldData?.lookup_data[0] === '' ? [] : fieldData?.lookup_data
            };
          })
        );
        return data;
      }
      setIsLoading(false);
    }
  });

  useEffect(() => {
    register('show_in');
    register('activity_type_code');
    register('labels');
    register('additional_fields');

    setValue(
      'labels',
      additionalLabels.map(item => ({
        label: '',
        language_code: item.language_code
      }))
    );

    setValue(
      'additional_fields',
      additionalFields.map(item => {
        return {
          label: item.label,
          active: false,
          mandatory: 'No',
          lookupData: item.lookupData.length === 1 ? [item.lookupData[0]] : []
        };
      })
    );
    setValue('show_in', []);
  }, []);

  useEffect(() => {
    if (currentRowData) {
      setValue('id', currentRowData.id);
      setValue('activity_type_code', currentRowData.activity_type_code);
      setValue('show_in', currentRowData.show_in ?? []);
      refetchFields();
      setValue(
        'labels',
        additionalLabels.map(item => ({
          label: currentRowData.labels.find(label => label.language_code === item.language_code)?.label ?? '',
          language_code: item.language_code
        }))
      );
      setIsLoading(false);
    }
  }, [currentRowData]);

  const onSubmit = async data => {
    if (currentRowData) {
      const res = await activityInfoApi(PUT, '', 'activity-types', data);
      if (res.status === 200) {
        setIsOpen(false);
        refetch();
        enqueueSnackbar('Activity Type Added Successfully', { variant: 'success' });
      }
    } else {
      const res = await activityInfoApi(POST, '', 'activity-types', data);
      if (res.status === 200) {
        setIsOpen(false);
        refetch();
        enqueueSnackbar('Activity Type Added Successfully', { variant: 'success' });
      }
    }
  };

  return (
    <CustomPopup
      open={isOpen}
      title={`${t('actions.add')} Activity Type`}
      width='80vw'
      onClose={() => {
        reset({
          show_in: [],
          activity_type_code: '',
          labels: additionalLabels.map(item => ({
            label: '',
            language_code: item.language_code
          })),
          additional_fields: additionalFields.map(item => {
            return {
              label: item.label,
              active: false,
              mandatory: 'No',
              lookupData: item.lookupData.length === 1 ? [item.lookupData[0]] : []
            };
          })
        });
        setIsOpen(false);
        setCurrentRowData(null);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 3,
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Tooltip title='Unique identifier for Activities in the system.'>
          <CustomInputField
            label='Activity Type'
            required
            autoComplete='off'
            className='w-25'
            disabled={currentRowData !== null}
            value={watch('activity_type_code')}
            onChange={e => setValue('activity_type_code', e.target.value)}
          />
        </Tooltip>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 3
          }}
        >
          <Typography className='body-font fs-14'>Show In:</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mr: 1
            }}
          >
            <Checkbox
              className='fs-18'
              onChange={e => {
                if (e.target.checked) {
                  setValue('show_in', [...getValues('show_in'), 'Contacts']);
                } else {
                  setValue(
                    'show_in',
                    getValues('show_in')?.filter(item => item !== 'Contacts')
                  );
                }
              }}
              checked={watch('show_in')?.includes('Contacts')}
            />
            <Typography className='body-font fs-14'>Contacts</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mr: 1
            }}
          >
            <Checkbox
              className='fs-18'
              onChange={e => {
                if (e.target.checked) {
                  setValue('show_in', [...getValues('show_in'), 'Candidates']);
                } else {
                  setValue(
                    'show_in',
                    getValues('show_in')?.filter(item => item !== 'Candidates')
                  );
                }
              }}
              checked={watch('show_in')?.includes('Candidates')}
            />
            <Typography className='body-font fs-14'>Candidates</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mr: 1
            }}
          >
            <Checkbox
              className='fs-18'
              onChange={e => {
                if (e.target.checked) {
                  setValue('show_in', [...getValues('show_in'), 'Companies']);
                } else {
                  setValue(
                    'show_in',
                    getValues('show_in')?.filter(item => item !== 'Companies')
                  );
                }
              }}
              checked={watch('show_in')?.includes('Companies')}
            />
            <Typography className='body-font fs-14'>Companies</Typography>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{
          mt: 2
        }}
      />

      {additionalLabels.map((label, idx) => {
        return (
          <Grid container spacing={3} marginTop={0} alignItems='center' key={idx}>
            <Grid item xs>
              <Typography className='body-font fs-14'>{label.label}</Typography>
            </Grid>
            <Grid item xs>
              <CustomInputField
                label='Label Name'
                required={label?.required}
                value={watch('labels')?.find(item => item.language_code === label.language_code)?.label}
                onChange={e => {
                  const labels = getValues('labels');
                  setValue(
                    'labels',
                    labels.map(item => {
                      if (item.language_code === label.language_code) {
                        return {
                          ...item,
                          label: e.target.value
                        };
                      }
                      return item;
                    })
                  );
                }}
              />
            </Grid>
          </Grid>
        );
      })}

      <Divider
        sx={{
          mt: 2
        }}
      />

      {additionalFields.map((field, idx) => additionalFieldRows(field, setValue, getValues, idx, watch))}

      <Stack direction='row' className='mt-3' spacing={2} justifyContent='flex-end'>
        <CustomButton
          buttonText={t('actions.save')}
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
        />
      </Stack>
    </CustomPopup>
  );
};

const additionalFieldRows = (fieldData, setValue, getValues, idx, watch) => {
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation();
  const handleActiveSwitchChange = (field, checked) => {
    const additional_fields = getValues('additional_fields');
    // If date-time active switched off then send-ical active and mandatory auto switch-off.
    if (field.label == 'date-time' && !checked) {
      setValue(
        'additional_fields',
        additional_fields?.map(item => {
          if (item.label === field.label) {
            return {
              ...item,
              active: checked
            };
          } else if (item.label === 'send-ical') {
            return {
              ...item,
              active: false,
              mandatory: false
            };
          }
          return item;
        })
      );
    }
    // if send-ical active is switched on then date-time active and mandatory is auto switched on.
    else if (field.label == 'send-ical' && checked) {
      setValue(
        'additional_fields',
        additional_fields?.map(item => {
          if (item.label === field.label) {
            return {
              ...item,
              active: checked
            };
          } else if (item.label === 'date-time') {
            return {
              ...item,
              active: true,
              mandatory: true
            };
          }
          return item;
        })
      );
    } else {
      setValue(
        'additional_fields',
        additional_fields?.map(item => {
          if (item.label === field.label) {
            return {
              ...item,
              active: checked
            };
          }
          return item;
        })
      );
    }
    setDisabled(checked);
  };
  const handleMandatorySwitchChange = (field, checked) => {
    const additional_fields = getValues('additional_fields');
    // If date-time mandatory is switched of then send-ical active and mandatory will also be switched off.
    if (field.label == 'date-time' && !checked) {
      setValue(
        'additional_fields',
        additional_fields?.map(item => {
          if (item.label === field.label) {
            return {
              ...item,
              mandatory: checked
            };
          } else if (item.label === 'send-ical') {
            return {
              ...item,
              active: false,
              mandatory: false
            };
          }
          return item;
        })
      );
    } else {
      setValue(
        'additional_fields',
        additional_fields.map(item => {
          if (item.label === field.label) {
            return {
              ...item,
              mandatory: checked
            };
          }
          return item;
        })
      );
    }
  };
  useEffect(() => {
    const isDisabled = !watch('additional_fields')?.find(item => item.label === fieldData.label)?.active;
    setDisabled(isDisabled);
  }, [watch('additional_fields')]);
  return (
    <Grid container spacing={3} marginTop={0} alignItems='flex-start'>
      <Grid item xs>
        {idx === 0 && <Typography className='header-font font-weight-bold fs-14 mb-2'>Additional Fields</Typography>}
        <Typography className='body-font fs-14'>{fieldData.title}</Typography>
      </Grid>

      <Grid item xs>
        {idx === 0 && <Typography className='header-font font-weight-bold fs-14 mb-2'>Active</Typography>}

        <Switch
          checked={watch('additional_fields')?.find(item => item.label === fieldData.label).active}
          onChange={e => {
            handleActiveSwitchChange(fieldData, e.target.checked);
          }}
        />
      </Grid>

      <Grid item xs>
        {idx === 0 && <Typography className='header-font font-weight-bold fs-14 mb-2'>Mandatory</Typography>}

        <Switch
          checked={watch('additional_fields')?.find(item => item.label === fieldData.label).mandatory}
          onChange={e => {
            handleMandatorySwitchChange(fieldData, e.target.checked);
          }}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={4}>
        {idx === 0 && <Typography className='header-font font-weight-bold fs-14 mb-2'>&nbsp;</Typography>}
        <CustomMuiDropdown
          label='Lookup data'
          options={fieldData.lookupData}
          disabled={disabled || fieldData.lookupData.length === 0}
          value={watch('additional_fields')?.find(item => item.label === fieldData.label)?.lookupData ?? []}
          onChange={(e, v) => {
            const additional_fields = getValues('additional_fields');
            setValue(
              'additional_fields',
              additional_fields?.map(item => {
                if (item.label === fieldData.label) {
                  return {
                    ...item,
                    lookupData: v
                  };
                }
                return item;
              })
            );
          }}
          getOptionLabel={option => {
            return option.label ?? t(`activityTypeSetup.${option}`);
          }}
          autoCompleteProps={{
            size: 'small',
            multiple: true
          }}
        />
      </Grid>

      <Grid item xs>
        {idx === 0 && <Typography className='header-font font-weight-bold fs-14 mb-2'>&nbsp;</Typography>}
        <CustomDropdown disabled label='Show in' />
      </Grid>
    </Grid>
  );
};

AddTypePopup.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  refetch: PropTypes.func,
  currentRowData: PropTypes.object,
  setCurrentRowData: PropTypes.func
};
export default AddTypePopup;
