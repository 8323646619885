import React, { useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import InputField from '../common/InputField';
import TextArea from '../common/TextArea';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Loader from '../common/Loader';
import { InputLabel, Typography } from '@mui/material';
import { Button } from '@mui/material';
import { PUT, ERROR, SUCCESS } from '../../services/constantService';
import { ProductSettingsApi } from '../../services/ApiService';

import { unableMessage, successMessage, VALIDATION_MESSAGE } from '../../services/MessageService';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const EditTranslatePopup = props => {
  const { enqueueSnackbar } = useSnackbar();
  const { translateData, closePopup, setIsEditing, setTranslateData, setIsDataUpdated } = props;
  const { register, handleSubmit } = useForm({ defaultValues: { ...translateData } });
  const [isLoading, setIsLoading] = useState(false);

  const editTranslateValue = async translateValue => {
    setIsLoading(true);
    const { status, data } = await ProductSettingsApi(PUT, translateValue, 'translate_value', translateData.id);
    if (status === 200) {
      const message = successMessage('Translate Values', VALIDATION_MESSAGE.updated_message);
      enqueueSnackbar && enqueueSnackbar(message, { variant: SUCCESS });
      setTranslateData(data[1][0]);
      setIsDataUpdated(true);
      setIsLoading(false);
      setIsEditing(false);
    } else {
      const message = unableMessage('Translate Values', 'update');
      enqueueSnackbar && enqueueSnackbar(data?.message || message, { variant: ERROR });
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <>
      <Helmet>
        <title>Edit Translate Value - KGP Galaxy</title>
      </Helmet>

      <React.Fragment>
        <Loader show={isLoading} />
        <form autoComplete='off'>
          <div className='' style={{ background: '#2a364c', color: '#fff' }}>
            <div className='quick-add-header'>
              <Typography>Edit Translate Value</Typography>
              <CloseIcon className='cursor-pointer' onClick={closePopup} />
            </div>
          </div>

          <div className='d-flex flex-column py-3'>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className='page-label'>
                  Organization Id
                </InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant='outlined'
                    className='input-form-field'
                    label=''
                    placeholder='Organization Id'
                    {...register('org_id')}
                    name='org_id'
                    InputLabelProps={{ focused: true }}
                    required={true}
                    disabled
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <InputLabel className='page-label'>Field Name</InputLabel>
                <div className='page-label-value'>
                  <InputField variant='outlined' className='input-form-field' label='' placeholder='Field Name' {...register('field_name')} name='field_name' InputLabelProps={{ focused: true }} />
                </div>
              </div>
            </div>

            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel className='page-label'>Field Value</InputLabel>
                <div className='page-label-value'>
                  <InputField variant='outlined' className='input-form-field' label='' placeholder='Field Value' {...register('field_value')} name='field_value' InputLabelProps={{ focused: true }} />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <InputLabel className='page-label'>Language CD</InputLabel>
                <div className='page-label-value'>
                  <InputField variant='outlined' className='input-form-field' label='' placeholder='Language CD' {...register('lang_cd')} name='lang_cd' InputLabelProps={{ focused: true }} />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel className='page-label'>Short Description</InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant='outlined'
                    className='input-form-field'
                    label=''
                    placeholder='Short Description'
                    {...register('short_desc')}
                    name='short_desc'
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <InputLabel className='page-label'>Long Description</InputLabel>
                <div className='page-label-value h-overflow'>
                  <TextArea
                    {...register('long_desc')}
                    className='input-form-field text-area-value'
                    variant='outlined'
                    name={'long_desc'}
                    placeholder='Long Description'
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='page-content-footer'>
            <Button className='page-btn-cancel' onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant='contained' type='submit' className='page-btn-save' onClick={handleSubmit(editTranslateValue)}>
              Submit
            </Button>
          </div>
        </form>
      </React.Fragment>
    </>
  );
};

export default EditTranslatePopup;

EditTranslatePopup.propTypes = {
  translateData: PropTypes.object,
  closePopup: PropTypes.func,
  setIsEditing: PropTypes.func,
  setTranslateData: PropTypes.func,
  setIsDataUpdated: PropTypes.func
};
