//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useRef, useEffect } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import axios from 'axios';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { formatDate } from '../../../utils/date';
import { SEARCH_TIMEOUT } from '../../../utils/common';
import { API } from '../../../services/constantService';
import { getMethodWithCancelTokenApi } from '../../../services/ApiService';

const CancelToken = axios.CancelToken;
export default function RecentSearches(props) {
  const { contact } = props;
  const [searchesHistory, setSearchesHistory] = useState([]);
  const call = useRef();

  const dataSource = () => {
    const getData = async id => {
      const url = `${API.contact}/${id}/searches/?limit=2&stage=Open`;
      const { status, data } = await getMethodWithCancelTokenApi(url, {}, {}, {}, call.current.token);
      if (status && status === 200) {
        if (data?.data?.length) {
          data.data.forEach(record => {
            record.activities.sort(function (a, b) {
              return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
            });
          });
          setSearchesHistory(data.data.slice(0, 2));
        }
      }
    };
    if (contact?.id) {
      getData(contact?.id).then();
    }
  };

  useEffect(() => {
    if (call.current) {
      call.current.cancel();
    }
    call.current = CancelToken.source();
    const timer = setTimeout(() => {
      // gridApi && gridApi.setDatasource(dataSource)
      dataSource();
    }, SEARCH_TIMEOUT);
    return () => clearTimeout(timer);
  }, [contact]);

  return (
    <div className='right-nav-section right-nav-recent-activity-section d-flex flex-column'>
      <span className='right-nav-header'>
        Searches{' '}
        <span style={{ color: '#5C52C6', fontSize: 18 }}>
          <ZoomInIcon fontSize='inherit' />
        </span>
      </span>
      {searchesHistory?.length
        ? searchesHistory.map(item => {
            return (
              <div key={item.id}>
                <Typography color='primary' className='mr-2'>
                  <Link to={`/searches/${item.id}/active-candidates`} target='_blank' rel='noopener noreferrer'>
                    <Button
                      style={{
                        textTransform: 'none',
                        outline: 'none'
                      }}
                      color='primary'
                    >
                      #{item.job_number}
                    </Button>
                  </Link>
                  <span className='searches-job-title'>{item.job_title}</span>
                </Typography>
                <div className='d-flex'>
                  <p className='searches-stage'>{item.candidates && item.candidates[0].stage ? item.candidates[0].stage : '--'}</p>
                </div>
                <div style={{ color: '#999', fontSize: 14 }}>{item.updated_at ? formatDate(item.updated_at, 'hh:mmA DD MMM YYYY') : '--'}</div>
              </div>
            );
          })
        : '--'}
      {searchesHistory?.length ? (
        <div className='d-flex'>
          <Link to={`/contacts/${contact?.id}/searches`} target='_blank' rel='noopener noreferrer'>
            <Button
              style={{
                textTransform: 'none',
                outline: 'none',
                textDecoration: 'underline',
                marginTop: '10%'
              }}
              color='primary'
            >
              View Searches
            </Button>
          </Link>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

RecentSearches.propTypes = {
  contact: PropTypes.object
};
