import React, { useEffect, useState } from 'react';
import CustomDropdown from '../../CustomDropdown';
import PropTypes from 'prop-types';
const TagStatusSelection = props => {
  const { placeholder, onChange = () => {}, label = '', defaultValue = '', required = false, ...rest } = props;
  const [selectedValue, setSelectedValue] = useState('Active');

  useEffect(() => {
    if (!defaultValue) {
      setSelectedValue('Active');
    }
  }, [defaultValue]);
  const options = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' }
  ];

  let updatedDefaultValue = defaultValue;

  if (typeof defaultValue === 'boolean') {
    updatedDefaultValue = defaultValue ? { label: 'Active', value: 'Active' } : { label: 'Inactive', value: 'Inactive' };
  }
  return (
    <>
      <CustomDropdown {...rest} required={required} options={options} placeholder={placeholder} onChange={onChange} label={label} defaultValue={updatedDefaultValue || defaultValue || selectedValue} />
    </>
  );
};
TagStatusSelection.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.any
};

export default TagStatusSelection;
