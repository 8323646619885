import React from 'react';
import CustomPopup from '../../components/common/CustomPopup';
import Loader from '../../components/common/Loader';
import { Box } from '@mui/material';
import TagSelection from '../../components/common/FunctionalComponents/TagSelection';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { fetchTagData } from '../../actions';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ignCompanyDataApi } from '../../services/ApiService';
import { ERROR, PATCH, POST, SUB_ROUTES, SUCCESS } from '../../services/constantService';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useCustomMessageHook } from '../../utils/Hooks/useCustomMessageHook';

const CompanyAssignTag = props => {
  const {
    setIsPopupOpen,
    isPopupOpen,
    tagPopUp,
    label,
    companyId = null,
    isBulkTag = false,
    setTagChange = () => {},
    getHeaderDetails = () => {},
    // setIsEdited = () => { },
    selectedRows
  } = props;
  const { register, unregister, setValue } = useForm({});
  const [commonValue, setCommonValue] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const moduleTag = useSelector(state => state.commonReducer.companiesTag);
  const { translateMessage } = useCustomMessageHook();
  useEffect(() => {
    register('tags');
    return () => {
      unregister('tags');
    };
  }, [register, unregister]);

  useEffect(() => {
    if (!moduleTag) {
      dispatch(fetchTagData('', 'companiesTag', '?module=companies&name='));
    } else {
      setOptions(moduleTag);
    }
  }, [moduleTag, dispatch]);

  useEffect(() => {
    let objectValues;
    if (tagPopUp) {
      objectValues = options && options.map(item => item.name);
      const commonValues = tagPopUp.filter(value => objectValues.includes(value));
      setCommonValue(commonValues);
    }
  }, [tagPopUp]);

  const getValueOfSelected = () => {
    let array = [];
    for (let x of tagPopUp) {
      const index = options && options.findIndex(obj => obj?.name === x?.ign_tag?.name);
      array.push(index);
    }
    let value = [];
    for (let y of array) {
      value.push(options[y]);
    }
    return value;
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const getPayload = (selectedTags, selectedRowData = '') => {
    let payload;
    const requestPayloadArray = [];

    if (isBulkTag) {
      const allTagIds = [];
      const allCompanyIds = [];
      selectedTags.forEach(tag => {
        allTagIds.push(tag?.id);
      });
      selectedRowData.forEach(company => {
        allCompanyIds.push(company?.id);
      });
      payload = {
        company_id: allCompanyIds,
        tag_id: allTagIds
      };
      return payload;
    } else {
      selectedTag.map(item => {
        payload = {
          tag_id: item.id
        };
        requestPayloadArray.push(payload);
      });
      return requestPayloadArray;
    }
  };

  const handleSubmit = async () => {
    try {
      let payload;
      let status, data;
      if (isBulkTag) {
        payload = getPayload(selectedTag, selectedRows);
      } else {
        payload = { company_tags: getPayload(selectedTag) };
      }
      setLoading(true);
      if (isBulkTag) {
        const response = await ignCompanyDataApi(POST, payload, SUB_ROUTES.bulkAssignTag);
        status = response.status;
        data = response.data;
      } else {
        const response = await ignCompanyDataApi(PATCH, payload, companyId);
        status = response.status;
        data = response.data;
      }

      if (status === 200) {
        console.log('data', data);
        setTagChange(true);
        getHeaderDetails();
        // setIsEdited(true);
        const message = translateMessage('Successfully', false, 'Tag', 'Assigned');

        enqueueSnackbar(message, { variant: SUCCESS });
      } else {
        const message = translateMessage('UnableMessage', false, 'Assign Tag', '');

        enqueueSnackbar(message, { variant: ERROR });
      }
      setLoading(false);
      setIsPopupOpen(false);
    } catch (e) {
      console.log('Error found in handleSubmit::', e);
    }
  };

  return (
    <div>
      <CustomPopup dropdown={true} title={'Assign Tag'} open={isPopupOpen} onClose={handleClose} showAction={true} handleSubmit={handleSubmit}>
        <Loader show={loading} className='h-75' />
        <Box id='assign-tag'>
          <Box className='tag-dropdown p-4'>
            <TagSelection
              {...register('tags')}
              className='tag-auto-complete'
              defaultValue={tagPopUp && isBulkTag === false ? getValueOfSelected() : []}
              commonValue={tagPopUp ? commonValue : null}
              label={label}
              options={options}
              setSelectedTag={setSelectedTag}
              setValue={setValue}
            />
          </Box>
        </Box>
      </CustomPopup>
    </div>
  );
};
CompanyAssignTag.propTypes = {
  title: PropTypes.string,
  setIsPopupOpen: PropTypes.func,
  isPopupOpen: PropTypes.bool,
  tagPopUp: PropTypes.array,
  selectedRows: PropTypes.array,
  tags: PropTypes.array,
  label: PropTypes.string,
  companyId: PropTypes.string,
  isBulkTag: PropTypes.bool,
  setTagChange: PropTypes.func,
  getHeaderDetails: PropTypes.func,
  setIsEdited: PropTypes.func
};

export default CompanyAssignTag;
