//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Button, InputAdornment, FormControl, Input, InputLabel, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { KeyboardDatePicker } from '@material-ui/pickers';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import IndustrySelection from '../../../components/IndustrySelection';
import CompanySelection from '../../../components/CompanySelection';
import CountrySelection from '../../../components/CountrySelection';
import JobFunctionSelection from '../../../components/jobFunctionSelection';
import UserSelection from '../../../components/UserSelection';
import SearchStageSelection from '../../../components/CreateSearch/Components/SearchInformation/Components/SearchStageSelection';
import SearchTypeSelection from '../../../components/CreateSearch/Components/SearchInformation/Components/SearchTypeSelection';
import SearchResultSelection from '../../../components/CreateSearch/Components/SearchInformation/Components/SearchResultSelection';
import OffLimitsSelection from '../../../components/OffLimitsSelection';
import { getUserSelector } from '../../../selectors';
import SavedQuerySelection from '../../Contacts/components/SavedQueries';
import SaveQueryDialog from '../../Contacts/components/SaveQueryDialog';
import PracticeSelection from '../../../components/AddUser/components/PracticeSelection';
import RegionSelection from '../../../components/AddUser/components/RegionSelection';
import BillableSelection from '../../../components/BillableSelection';
import CapitalStructure from '../../../components/ViewSearch/Components/SFPA/Components/Targets/CapitalStructure';
/* import JobTitleSelection from '../../../components/JobTitleSelection' */

const defaultValues = {
  company: [],
  kgpTeamMember: [],
  industries: [],
  job_functions: [],
  location: [],
  vc_pe: [],
  type: [],
  stage: [],
  result: [],
  capital_structure: []
};

const Query = props => {
  const { option } = props;
  const { handleSubmit, setValue, register, reset, watch } = useForm({
    defaultValues
  });
  const [open, setOpen] = React.useState(false);
  const [queryData, setQueryData] = useState({});
  const userData = useSelector(getUserSelector);

  const handleClose = () => {
    setOpen(false);
    setQueryData({});
  };

  const handleSaveQuery = data => {
    setQueryData(data);
    setOpen(true);
  };

  const handleSaveQueryChange = (e, data) => {
    if (data) {
      let newQuery = {
        ...defaultValues,
        ...data.query
      };
      reset(newQuery);
      props.handleQuery(newQuery);
    }
  };

  useEffect(() => {
    register('title');
    register('company');
    register('kgpTeamMember');
    register('industries');
    register('job_functions');
    register('location');
    register('vc_pe');
    register('type');
    register('stage');
    register('result');
    register('capital_structure');
    register('start_date_from');
    register('start_date_to');
    register('close_date_from');
    register('close_date_to');
    register('activePartners');
    register('vertical_practice');
    register('horizontal_practice');
    register('region');
    register('billable');
  }, [register]);

  const rowOne = () => {
    return (
      <div className='d-flex align-items-center'>
        {/* <JobTitleSelection
          multiple
          className="query-input"
          value={watch('title') || []}
          InputLabelProps={{focused:true,shrink:true}}
          filterSelectedOptions={true}
          placeholder={"Select"}
          label={"JOB TITLE"}
          onChange={(e, data) => { setValue("title", Array.isArray(data) ? data : [] );}}
        /> */}
        <FormControl className='query-input'>
          <InputLabel focused htmlFor='title'>
            JOB TITLE
          </InputLabel>
          <Input
            {...register('title')}
            name='title'
            id='title'
            placeholder={'Search'}
            startAdornment={
              <InputAdornment position='start' style={{ color: '#C4C4C4' }}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <CompanySelection
          multiple
          className='query-input'
          value={watch('company') || []}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={'COMPANY'}
          placeholder={'Select'}
          onChange={(e, data) => {
            setValue('company', Array.isArray(data) ? data : []);
          }}
        />
        <UserSelection
          option={option}
          multiple
          value={watch('kgpTeamMember')}
          className='query-input'
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={'KGP MEMBER'}
          placeholder={'Select'}
          onChange={(e, data) => {
            setValue('kgpTeamMember', Array.isArray(data) ? data : []);
          }}
        />
        <SearchStageSelection
          multiple
          className='query-input'
          value={watch('stage') || []}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={'STAGE'}
          placeholder={'Select'}
          onChange={(e, data) => {
            setValue('stage', Array.isArray(data) ? data : []);
          }}
        />
      </div>
    );
  };

  const rowTwo = () => {
    return (
      <div className='d-flex align-items-center'>
        <IndustrySelection
          multiple
          className='query-input'
          value={watch('industries') || []}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          placeholder={'Select'}
          label={'INDUSTRY'}
          onChange={(e, data) => {
            setValue('industries', Array.isArray(data) ? data : []);
          }}
        />
        <JobFunctionSelection
          multiple
          value={watch('job_functions') || []}
          className='query-input'
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          placeholder={'Select'}
          label={'JOB FUNCTION'}
          onChange={(e, data) => {
            setValue('job_functions', Array.isArray(data) ? data.map(item => item) : []);
          }}
        />
        <CountrySelection
          multiple
          value={watch('location') || []}
          className='query-input'
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={'LOCATION'}
          placeholder={'Select'}
          onChange={(e, data) => {
            setValue('location', Array.isArray(data) ? data : []);
          }}
        />
        <CompanySelection
          multiple
          className='query-input'
          value={watch('vc_pe') || []}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={'VC/PE'}
          placeholder={'Select'}
          onChange={(e, data) => {
            setValue('vc_pe', Array.isArray(data) ? data : []);
          }}
        />
      </div>
    );
  };

  const rowThree = () => {
    return (
      <div className='d-flex align-items-center'>
        <SearchTypeSelection
          multiple
          className='query-input'
          value={watch('type') || []}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={'TYPE'}
          placeholder={'Select'}
          onChange={(e, data) => {
            setValue('type', Array.isArray(data) ? data : []);
          }}
        />
        <SearchResultSelection
          multiple
          className='query-input'
          value={watch('result') || []}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={'RESULT'}
          placeholder={'Select'}
          onChange={(e, data) => {
            setValue('result', Array.isArray(data) ? data : []);
          }}
        />
        <FormControl className='query-input'>
          <InputLabel focused shrink={true} htmlFor='revenue'>
            REVENUE RANGE ($)
          </InputLabel>
          <div className='d-flex align-items-center' style={{ marginTop: '15px' }}>
            <Input {...register('revenue_from')} name='revenue_from' type='number' placeholder='Min' />
            <Typography className='to-label' style={{ marginLeft: '10px', marginRight: '10px' }}>
              to
            </Typography>
            <Input {...register('revenue_to')} name='revenue_to' type='number' placeholder='Max' />
          </div>
        </FormControl>
        <FormControl className='query-input'>
          <InputLabel focused htmlFor='estimatedTotalCompensation'>
            ESTIMATED TOTAL COMP
          </InputLabel>
          <Input
            {...register('estimatedTotalCompensation')}
            name='estimatedTotalCompensation'
            id='estimatedTotalCompensation'
            placeholder={'Search'}
            type='number'
            startAdornment={
              <InputAdornment position='start' style={{ color: '#C4C4C4' }}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
    );
  };

  const rowFour = () => {
    return (
      <div className='d-flex align-items-center second-row'>
        <div className='d-flex flex-column date-range query-input'>
          <InputLabel className='range-label' focused shrink={true} htmlFor='date-picker-inline'>
            START DATE RANGE
          </InputLabel>
          <div className='d-flex align-items-center '>
            <KeyboardDatePicker
              //disableToolbar
              autoOk
              variant='inline'
              format='MM/dd/yyyy'
              margin='normal'
              placeholder='From date'
              value={watch('start_date_from') || null}
              maxDate={watch('start_date_to')}
              onChange={date => {
                setValue('start_date_from', date);
              }}
            />
            <Typography className='to-label'>to</Typography>
            <KeyboardDatePicker
              //disableToolbar
              autoOk
              placeholder='End date'
              variant='inline'
              format='MM/dd/yyyy'
              margin='normal'
              minDate={watch('start_date_from')}
              value={watch('start_date_to') || null}
              onChange={date => {
                setValue('start_date_to', date);
              }}
            />
          </div>
        </div>
        <div className='d-flex flex-column date-range query-input'>
          <InputLabel className='range-label' focused shrink={true} htmlFor='date-picker-inline'>
            CLOSE DATE RANGE
          </InputLabel>
          <div className='d-flex align-items-center '>
            <KeyboardDatePicker
              //disableToolbar
              autoOk
              variant='inline'
              format='MM/dd/yyyy'
              margin='normal'
              placeholder='From date'
              value={watch('close_date_from') || null}
              maxDate={watch('close_date_to')}
              onChange={date => {
                setValue('close_date_from', date);
              }}
            />
            <Typography className='to-label'>to</Typography>
            <KeyboardDatePicker
              //disableToolbar
              autoOk
              placeholder='End date'
              variant='inline'
              format='MM/dd/yyyy'
              margin='normal'
              minDate={watch('close_date_from')}
              value={watch('close_date_to') || null}
              onChange={date => {
                setValue('close_date_to', date);
              }}
            />
          </div>
        </div>
        <OffLimitsSelection
          className='query-input'
          value={watch('activePartners')}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={'ACTIVE PARTNERS'}
          placeholder={'Select'}
          onChange={(e, data) => {
            setValue('activePartners', data?.id ? data.id : '');
          }}
        />
        <RegionSelection
          className='query-input'
          value={watch('region') || null}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={'REGION'}
          onChange={(e, data) => {
            setValue('region', data?.name);
          }}
        />
        {/* <div className='query-input d-flex align-items-center'>
        <Button
          className={"query-button"}
          variant={"contained"}
          color={"primary"}
          onClick={
            handleSubmit(props.handleQuery)
          }
        >
          Search
        </Button>
        <Button className={"save-query"} variant={"outlined"} color={"primary"} onClick={handleSubmit(handleSaveQuery)}>Save Query</Button>
      </div> */}
      </div>
    );
  };

  const advanceHandler = () => {
    props.pullData();
  };

  const rowFive = () => {
    return (
      <div className='d-flex align-items-center'>
        <PracticeSelection
          className='query-input'
          name='vertical_practice'
          value={watch('vertical_practice') || null}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={'VERTICAL PRACTICE'}
          onChange={(e, data) => {
            setValue('vertical_practice', data?.name);
          }}
          type='vertical'
        />
        <PracticeSelection
          className='query-input'
          name='horizontal_practice'
          value={watch('horizontal_practice') || null}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={'HORIZONTAL PRACTICE'}
          placeholder={'Horizontal Practice'}
          onChange={(e, data) => {
            setValue('horizontal_practice', data?.name);
          }}
          type='horizontal'
        />
        <BillableSelection
          className='query-input'
          value={watch('billable') || null}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={'BILLABLE'}
          placeholder={'Select'}
          onChange={(e, data) => {
            setValue('billable', data?.id ? data.id : '');
          }}
        />
        <CapitalStructure
          multiple
          label={'CAPITAL STRUCTURE'}
          value={watch('capital_structure')}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          placeholder={'Select'}
          className='query-input'
          onChange={(e, data) => {
            setValue('capital_structure', Array.isArray(data) ? data : []);
          }}
        />
      </div>
    );
  };

  const rowSix = () => {
    return (
      <div className='d-flex align-items-center second-row mt-3'>
        <div style={{ position: 'absolute', right: '80px' }}>
          <Button className={'query-button'} variant={'contained'} color={'primary'} onClick={handleSubmit(props.handleQuery)}>
            Search
          </Button>
          <Button className={'save-query'} variant={'outlined'} color={'primary'} onClick={handleSubmit(handleSaveQuery)}>
            Save Query
          </Button>
        </div>
      </div>
    );
  };

  const rowSeven = () => {
    return (
      <div className='d-flex align-items-center second-row justify-content-between mt-4'>
        {props.flag ? (
          <Button className='mr-3 mt-1' variant='outlined' color='primary' onClick={advanceHandler}>
            Switch to Advanced Query
          </Button>
        ) : null}
        <SavedQuerySelection
          onChange={handleSaveQueryChange}
          style={{ marginLeft: 'auto', justifySelf: 'end' }}
          refreshQueries={open}
          InputLabelProps={{ focused: true }}
          className='saved-queries'
          userId={userData?.id}
          disableClearable
          type='searches'
        />
      </div>
    );
  };

  return (
    <div className='query'>
      <SaveQueryDialog type='searches' open={open} queryData={queryData} userId={userData?.id} handleClose={handleClose} />
      {rowOne()}
      {rowTwo()}
      {rowThree()}
      {rowFour()}
      {rowFive()}
      {rowSix()}
      {rowSeven()}
    </div>
  );
};

Query.propTypes = {
  setSelectAllRows: PropTypes.func,
  handleQuery: PropTypes.func,
  pullData: PropTypes.func,
  flag: PropTypes.bool,
  option: PropTypes.array
};

export default Query;
