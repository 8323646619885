//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { Link } from 'react-router-dom';
import { Paper, Popover, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

export const CompanyIdRenderer = params => {
  return (
    <Link to={`/companies/${params?.value?.id}/details`} target='_blank' rel='noopener noreferrer'>
      <Typography className='text-link' color='primary'>
        {params?.value?.name}
      </Typography>
    </Link>
  );
};

export const ContactIdRenderer = params => {
  return (
    <Link to={`/contacts/${params?.data?.id}/details`} target='_blank' rel='noopener noreferrer'>
      <Typography className='text-link' color='primary'>
        {params.value}
      </Typography>
    </Link>
  );
};

export const OffLimitsCauseRenderer = params => {
  if (params.data) {
    const { reasons } = params.data;
    if (reasons?.length === 1) {
      return reasons[0].cause;
    } else {
      return 'Multiple Reasons';
    }
  }
  return '';
};

export const getMessage = (reason, name) => {
  if (reason.kg_25_user) {
    return reason.kg_25_user;
  }
  if (reason.bd_record) {
    return reason.bd_record.name;
  } else if (reason.company) {
    return (
      <Link to={`/companies/${reason.company.id}/details`} target='_blank'>
        {reason.company.name}
      </Link>
    );
  } else if (reason.search) {
    return (
      <Typography style={{ fontSize: 'inherit' }}>
        Search <Link to={`/searches/${reason.search.id}/active-candidates`} target='_blank'>{`#${reason.search.job_number}`}</Link>
      </Typography>
    );
  } else {
    return name;
  }
};

export const DetailsRenderer = params => {
  const { data } = params;
  const { reasons } = data;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {reasons?.length > 1 ? (
        <React.Fragment>
          <Typography color='primary' aria-describedby={id} className='text-link' onClick={handleClick}>
            Multiple reasons
          </Typography>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            className='reason-container'
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label='spanning table'>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#5c52c6' }}>
                    <TableCell style={{ color: '#FFFFFF' }} align='center' colSpan={3}>
                      Off Limits Reasons for {data.name}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: '#e9e9ea', color: 'black' }}>
                    <TableCell align='center'>Off Limits Cause</TableCell>
                    <TableCell align='center'>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reasons.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align='center' style={{ backgroundColor: 'red', color: 'white' }}>
                          {item.cause}
                        </TableCell>
                        <TableCell align='center'>{getMessage(item, data.name)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Popover>
        </React.Fragment>
      ) : (
        getMessage(reasons[0], data.name)
      )}
    </div>
  );
};

export const columnsDef = [
  {
    headerName: 'Name',
    field: 'name',
    colId: 'name',
    tooltipField: 'name',
    cellRenderer: 'ContactIdRenderer',
    checkboxSelection: true
  },
  {
    field: 'company',
    colId: 'company',
    headerName: 'Company',
    cellRenderer: 'CompanyIdRenderer'
  },
  {
    field: 'current_job_title',
    colId: 'current_job_title',
    tooltipField: 'current_job_title',
    headerName: 'Current Job Title'
  },
  {
    field: 'cause',
    colId: 'cause',
    headerName: 'Off Limits Cause',
    cellRenderer: 'OffLimitsCauseRenderer'
  },
  {
    field: 'details',
    colId: 'details',
    headerName: 'Details',
    cellRenderer: 'DetailsRenderer'
  }
];
