//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useState } from 'react';

import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import '../../index.scss';
import BioAndRecommendation from './BioAndRecommendation';
import ProfessionalAndPersonal from './ProfessionalAndPersonal';
import ScreeningNotes from './ScreeningNotes';
import StrengthAndConcern from './StrengthAndConcern';
import { useParams } from 'react-router-dom';
import useContactFormHook from '../../../../utils/Hooks/useContactFormHook';
import { formSchema } from '../../../ActivityLog/utils';
import { getPersonalityPayload } from '../../utils';
import { ERROR } from '../../../../services/constantService';
import { enqueueSnackbar } from 'notistack';
import { requireValidMessage } from '../../../../utils/common';
import Loader from '../../../common/Loader';

const Personality = forwardRef((props, ref) => {
  const { handleSaveTabData, id } = props;
  const { path } = useParams();
  const [loading, setLoading] = useState(false);

  const isValidated = async data => {
    const isBioNotesType = data?.contact_notes_all?.some(item => item?.notes_type === 'published_bio');
    // if (isBioNotesType) {
    //   const bioDirtyField = [];
    //   const isBioNotes = data?.contact_notes_all?.some(item => !item?.notes);
    //   if (isBioNotes) {
    //     bioDirtyField.push("Notes")
    //   }
    //   if (bioDirtyField.length) {
    //     const message = requireValidMessage(bioDirtyField);
    //     enqueueSnackbar(message, { variant: ERROR });
    //     return false;
    //   } else {
    //     return true
    //   }
    // } else {
    if (!isBioNotesType) {
      const dirtyField = [];
      const hasNullProjectId = data?.contact_notes_all?.some(item => !item?.project_id);
      //const isNotes = data?.contact_notes_all?.some(item => item?.seq === 0 && (!item?.notes || item?.notes === emptyString));
      if (hasNullProjectId) {
        dirtyField.push('Project');
        if (dirtyField.length) {
          const message = requireValidMessage(dirtyField);
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
    //}
  };
  const {
    getContactInfo,
    handleSave,
    register,
    setValue,
    currentValues,
    reset,
    loading: showLoading
  } = useContactFormHook({
    id,
    path,
    getPayload: getPersonalityPayload,
    handleSaveTabData,
    ref,
    formSchema: formSchema,
    isValidated,
    defaultFormValue: { contact_notes_all: [] },
    tabName: 'PERSONALITY_TAB'
  });
  return (
    <>
      <Loader show={showLoading} />
      {currentValues ? (
        <>
          <Loader show={loading} />
          <Box id='container-personality'>
            <BioAndRecommendation
              setValue={setValue}
              register={register}
              currentValues={currentValues?.contact_notes_all}
              handleSave={handleSave}
              reset={reset}
              contact_id={id || null}
              getContact={getContactInfo}
              setLoading={setLoading}
            />
            <ProfessionalAndPersonal
              setValue={setValue}
              register={register}
              currentValues={currentValues?.contact_notes_all}
              handleSave={handleSave}
              reset={reset}
              contact_id={id || null}
              getContact={getContactInfo}
              setLoading={setLoading}
            />
            <StrengthAndConcern
              setValue={setValue}
              register={register}
              currentValues={currentValues?.contact_notes_all}
              handleSave={handleSave}
              reset={reset}
              contact_id={id || null}
              getContact={getContactInfo}
              setLoading={setLoading}
            />
            <ScreeningNotes
              setValue={setValue}
              register={register}
              currentValues={currentValues?.contact_notes_all}
              handleSave={handleSave}
              reset={reset}
              contact_id={id || null}
              getContact={getContactInfo}
              setLoading={setLoading}
            />
          </Box>
        </>
      ) : null}
    </>
  );
});

Personality.propTypes = {
  handleSaveTabData: PropTypes.func,
  id: PropTypes.string
};
export default Personality;
