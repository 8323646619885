import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const ProbabilitySelection = props => {
  const dispatch = useDispatch();
  const probability = useSelector(state => state.commonReducer.probability);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!probability) {
      dispatch(fetchPickLists('PROBABILITY', 'probability'));
    } else {
      setOptions(probability);
    }
  }, [probability, dispatch]);

  const { className = 'SingleSelectStyle', onChange = () => {}, isDrawer = false, label = '', defaultValue, required, value, size = 'small', ...rest } = props;
  // const probabilityOptions = [
  //   { label: "0%", value: "0" },
  //   { label: "25%", value: "25" },
  //   { label: "50%", value: "50" },
  //   { label: "75%", value: "75" },
  //   { label: "100%", value: "100" },
  // ];

  return (
    <>
      <CustomDropdown
        {...rest}
        value={value}
        label={label}
        options={options}
        className={className}
        size={size}
        onChange={onChange}
        isDrawer={isDrawer}
        required={required}
        defaultValue={defaultValue}
      />
    </>
  );
};

ProbabilitySelection.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  isDrawer: PropTypes.bool,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  size: PropTypes.string
};

export default ProbabilitySelection;
