import { Box, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import CustomCardUi from '../../CustomCardUi';
import { propTypes } from 'react-bootstrap/esm/Image';

const CompanyDocumentCard = props => {
  const {
    index,
    downloadIcon,
    editItems = () => {},
    download = () => {},
    deleteItem = () => {},
    styleData = {},
    data,
    addIcon,
    isForCompany,
    getContact = () => {},
    setLoading = () => {}
    // children
  } = props;
  const { titleSize = 14, textSize = 8 } = styleData;
  const handleEdit = () => {
    editItems(index);
  };
  const handleDownload = () => {
    download(index);
  };
  const handleDelete = () => {
    deleteItem(index);
  };
  return (
    <CustomCardUi
      downloadIcon={downloadIcon}
      editItems={handleEdit}
      download={handleDownload}
      deleteItem={handleDelete}
      addIcon={addIcon}
      item={data}
      isForCompany={isForCompany}
      styleData
      getContactDetails={getContact}
      deleteToast={'Document'}
      setLoading={setLoading}
    >
      <Grid className='justify-content-between w-100 pr-2'>
        <Box className='d-flex align-items-center ' gap={1}>
          <Box component='div' className={`header-title font-weight-bold m-0 fs-${titleSize}`}>
            {data?.ign_translate_value.short_desc || ''}
          </Box>
        </Box>
        <Box className={`fs-${textSize} header-containt`}>{data?.file_name || ''}</Box>
      </Grid>
    </CustomCardUi>
  );
};
CompanyDocumentCard.propTypes = {
  addIcon: PropTypes.string,
  editItems: PropTypes.func,
  deleteItem: PropTypes.func,
  data: PropTypes.object,
  styleData: PropTypes.object,
  downloadIcon: PropTypes.string,
  children: PropTypes.element,
  index: PropTypes.number,
  download: propTypes.func,
  getContact: PropTypes.func,
  getContactDetails: PropTypes.func,
  setLoading: PropTypes.func,
  isForCompany: PropTypes.bool
};

export default CompanyDocumentCard;
