import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ERROR } from '../../../services/constantService';
import { getTranslateValue } from '../../../utils/common';
import { formatDate, formatDateUtcLocal } from '../../../utils/date';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CustomPopup, { POPUP_CLOSE_REASONS } from '../CustomPopup';
import DatePickerCommon from '../DatePicker/DatePicker';
import Loader from '../Loader';
import RichText from '../RichText';
import { CONSANTS_MARK_OFF_LIMITS } from './constant';
import { BpCheckedIcon, BpIcon } from './Icon';
import './index.scss';

const MarkOffLimits = ({ isPopupOpen, handleClose = () => {}, onSubmit = () => {}, editMode = false, data = [], onDelete = () => {} }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_MARK_OFF_LIMITS,
    TRANS_KEYS.CONTACTS_DURATION,
    TRANS_KEYS.CONTACTS_INDEFINITE,
    TRANS_KEYS.CONTACTS_SPECIFIC_END_DATE,
    TRANS_KEYS.CONTACTS_SEVERITY,
    TRANS_KEYS.CONTACTS_HARD,
    TRANS_KEYS.CONTACTS_SOFT,
    TRANS_KEYS.CONTACTS_OFF_LIMITS_REASON,
    TRANS_KEYS.CONTACTS_CANCEL,
    TRANS_KEYS.CONTACTS_SAVE,
    TRANS_KEYS.PICK_A_DATE,
    TRANS_KEYS.MARK_OFF_LIMIT_TITLE,
    TRANS_KEYS.EDIT_OFF_LIMIT_TITLE
  ]);

  const offLimitsDetails = editMode ? data?.[0] : null;

  const [showCalender, setShowCalender] = useState(!!offLimitsDetails?.end_date);

  useEffect(() => {
    setValue(CONSANTS_MARK_OFF_LIMITS.DATE, offLimitsDetails?.end_date ?? null);
    setValue(CONSANTS_MARK_OFF_LIMITS.DURATION, offLimitsDetails?.end_date ? CONSANTS_MARK_OFF_LIMITS.SPECIFIC : CONSANTS_MARK_OFF_LIMITS.INDEFINITE);
    setValue(CONSANTS_MARK_OFF_LIMITS.SEVERITY, offLimitsDetails?.severity ?? CONSANTS_MARK_OFF_LIMITS.HARD);
    setValue(CONSANTS_MARK_OFF_LIMITS.REASON, offLimitsDetails?.reason ?? '');
  }, []);

  const { setValue, getValues } = useForm();

  const handleSave = async () => {
    const formValue = getValues();

    if (!formValue?.duration || !formValue?.severity || !formValue?.reason || (formValue?.duration === CONSANTS_MARK_OFF_LIMITS.SPECIFIC && !formValue?.date)) {
      enqueueSnackbar('All fields are required', { variant: ERROR });
      return;
    }
    const payload = {
      severity: formValue.severity,
      endDate: formValue.date,
      reason: formValue.reason
    };
    setIsLoading(true);
    const newValue = { ...data?.[0], severity: formValue.severity, end_date: formValue.date, reason: formValue.reason };
    onSubmit?.(payload, setIsLoading, newValue);
  };

  function onEditorChange(text) {
    setValue(CONSANTS_MARK_OFF_LIMITS.REASON, text);
  }

  const titlePopup = editMode ? getTranslateValue(t, TRANS_KEYS.EDIT_OFF_LIMIT_TITLE) : getTranslateValue(t, TRANS_KEYS.MARK_OFF_LIMIT_TITLE);

  return (
    <CustomPopup
      onClose={(_, reason) => {
        if (reason && reason === POPUP_CLOSE_REASONS.BACKDROP_CLICK && isLoading) return;
        handleClose();
      }}
      open={isPopupOpen}
      title={titlePopup}
      showAction
      handleSubmit={handleSave}
      paperStyles={CONSANTS_MARK_OFF_LIMITS.CLASSNAMES.CUSTOM_PAPERE}
      titleClassName={CONSANTS_MARK_OFF_LIMITS.CLASSNAMES.OFF_LIMIT_TITLE}
      titleIcon={
        editMode ? (
          <DeleteIcon
            id='deleteIcon'
            onClick={() => {
              setIsLoading(true);
              onDelete(setIsLoading);
            }}
          />
        ) : null
      }
    >
      <Loader show={isLoading} className='abolute-position' />
      <Box className='d-flex flex flex-column' gap={4} id='mark-off-limits-popup'>
        {/* Duration Selection */}
        <Box className='form-group position-relative '>
          <FormLabel>{getTranslateValue(t, TRANS_KEYS.CONTACTS_DURATION)}</FormLabel>
          <RadioGroup
            className='d-flex flex flex-row'
            defaultValue={offLimitsDetails?.end_date ? CONSANTS_MARK_OFF_LIMITS.SPECIFIC : CONSANTS_MARK_OFF_LIMITS.INDEFINITE}
            onChange={e => {
              setValue(CONSANTS_MARK_OFF_LIMITS.DURATION, e.target.value);
              if (e.target.value === CONSANTS_MARK_OFF_LIMITS.INDEFINITE) {
                setValue(CONSANTS_MARK_OFF_LIMITS.DATE, null);
              } else {
                setValue(CONSANTS_MARK_OFF_LIMITS.DATE, offLimitsDetails?.end_date ? formatDateUtcLocal(offLimitsDetails?.end_date, 'YYYY-MM-DD') : null, { shouldDirty: true });
              }
              setShowCalender(e.target.value === CONSANTS_MARK_OFF_LIMITS.SPECIFIC);
            }}
          >
            <FormControlLabel
              value={CONSANTS_MARK_OFF_LIMITS.INDEFINITE}
              control={<Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />}
              label={getTranslateValue(t, TRANS_KEYS.CONTACTS_INDEFINITE)}
            />
            <FormControlLabel
              value={CONSANTS_MARK_OFF_LIMITS.SPECIFIC}
              control={<Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />}
              label={getTranslateValue(t, TRANS_KEYS.CONTACTS_SPECIFIC_END_DATE)}
            />
          </RadioGroup>

          {showCalender && (
            <Box className='input-field-data flex h-100 position-absolute postion'>
              <DatePickerCommon
                hideLabel
                value={offLimitsDetails?.end_date ? formatDate(offLimitsDetails?.end_date, 'DD/MM/YYYY') : null}
                slots={{
                  textField: params => (
                    <TextField
                      InputLabelProps={{
                        size: 'normal',
                        color: 'error'
                      }}
                      variant='standard'
                      {...params}
                      label={getTranslateValue(t, TRANS_KEYS.PICK_A_DATE)}
                    />
                  ),
                  openPickerIcon: () => <CalendarMonthIcon fontSize='small' />
                }}
                className='input-field-data'
                onChange={e => {
                  const selectedDate = e?.$d;
                  setValue(CONSANTS_MARK_OFF_LIMITS.DATE, selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null, { shouldDirty: true });
                }}
              />
            </Box>
          )}
        </Box>

        {/* Severity Selection */}
        <Box className='form-group'>
          <FormLabel>{getTranslateValue(t, TRANS_KEYS.CONTACTS_SEVERITY)}</FormLabel>
          <RadioGroup
            className='d-flex flex flex-row'
            defaultValue={offLimitsDetails?.severity ?? CONSANTS_MARK_OFF_LIMITS.HARD}
            onChange={e => setValue(CONSANTS_MARK_OFF_LIMITS.SEVERITY, e.target.value)}
          >
            <FormControlLabel value={CONSANTS_MARK_OFF_LIMITS.HARD} control={<Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />} label={getTranslateValue(t, TRANS_KEYS.CONTACTS_HARD)} />
            <FormControlLabel value={CONSANTS_MARK_OFF_LIMITS.SOFT} control={<Radio checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />} label={getTranslateValue(t, TRANS_KEYS.CONTACTS_SOFT)} />
          </RadioGroup>
        </Box>

        {/* Off-limits Reason */}
        <Box className='form-group'>
          <FormLabel id='off-limit-reason'>{getTranslateValue(t, TRANS_KEYS.CONTACTS_OFF_LIMITS_REASON)}</FormLabel>
          <RichText skipFirstRender defaultValue={offLimitsDetails?.reason} onChange={onEditorChange} />
        </Box>
      </Box>
    </CustomPopup>
  );
};

MarkOffLimits.propTypes = {
  isPopupOpen: PropTypes.bool,
  editMode: PropTypes.bool,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  data: PropTypes.any
};

export default MarkOffLimits;
