import React, { useEffect, useState } from 'react';
import CustomDropdown from '../../CustomDropdown';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';

const GenderSelection = props => {
  const dispatch = useDispatch();
  const gender = useSelector(state => state.commonReducer.gender);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!gender) {
      dispatch(fetchPickLists('GENDER', 'gender'));
    } else {
      setOptions(gender);
    }
  }, [gender, dispatch]);
  /*   const [options, setOptions] = useState([]);
  const gender = useSelector((state) => state.commonReducer.gender);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!gender) {
      dispatch(fetchPickList("gender"));
    } else {
      setOptions(gender);
    }
  }, [gender, dispatch]); */
  const { className = 'SingleSelectStyle', onChange = () => {}, isDrawer = false, label = '', defaultValue } = props;
  // const genderOptions = [
  //   { label: "Male", value: "male" },
  //   { label: "Female", value: "female" },
  //   { label: "Other", value: "other" },
  // ];

  return (
    <>
      <CustomDropdown label={label} options={options} className={className} onChange={onChange} isDrawer={isDrawer} defaultValue={defaultValue} />
    </>
  );
};

GenderSelection.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  isDrawer: PropTypes.bool,
  defaultValue: PropTypes.string
};

export default GenderSelection;
