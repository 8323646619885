//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

// -------------------------------------------------// External Imports// -------------------------------------------------

import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

export default function TypeSelection(props) {
  const { type, register, setValue } = props;

  let disabled = false;
  if (type === 'Contacts' || type === 'Searches') {
    disabled = true;
  }
  useEffect(() => {
    register('type');
  }, [register, disabled]);

  return (
    <Autocomplete
      disabled={disabled}
      name={'type'}
      {...register('type')}
      defaultValue={type}
      className='input'
      renderInput={params => (
        <TextField
          {...params}
          // inputRef={typeRef}
          variant='standard'
          InputLabelProps={{ focused: true }}
          label={
            <label>
              Type<span className='MuiInputLabel-asterisk'>*</span>
            </label>
          }
          name='type'
          placeholder='Type'
          onChange={(event, data) => {
            setValue('type', data);
          }}
        />
      )}
      options={['Contacts', 'Searches']}
    />
  );
}

TypeSelection.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  DefaultValue: PropTypes.string,
  type: PropTypes.string,
  setValue: PropTypes.func,
  getType: PropTypes.func
};
