import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { globalSearchSelector } from '../../../selectors';
import { updateGlobalSearchData } from '../../../store/globalSearchSlice';

export const CustomMinMaxFilter = ({ onClose, show }) => {
  if (!show) return <></>;

  const { selectedSubTabId, filterModel, gridApi, selectedMoreFilterTabId, subTabs, filters, data: globalSearchData } = useSelector(globalSearchSelector);
  let tab = subTabs.find(tab => tab?.id === selectedSubTabId);

  if (selectedSubTabId === 'more_filters') {
    tab = subTabs.find(tab => tab?.id === selectedMoreFilterTabId);
  }

  const [min, setMin] = useState('');
  const [max, setMax] = useState('');

  const [minError, setMinError] = useState(false);
  const [maxError, setMaxError] = useState(false);

  const dispatch = useDispatch();

  const handleSave = () => {
    if (min === '') {
      setMinError(true);
      return;
    } else if (max === '') {
      setMaxError(true);
      return;
    }
    const updatedFilters = {
      ...filters,
      [tab?.search_field?.[1]]: { values: [{ value: min }] },
      [tab?.search_field?.[2]]: { values: [{ value: max }] }
    };

    Object.keys(updatedFilters).forEach(key => {
      if (updatedFilters?.[key]?.values?.length === 0) delete updatedFilters[key];
    });

    const updatedFilterModel = {
      ...filterModel,
      subTabsFilter: {
        filterType: 'text',
        filter: JSON.stringify({ filters: updatedFilters, isLocal: tab?.search_type === 'local', fields: [tab?.search_field?.[1], tab?.search_field?.[2]], globalSearchData })
      }
    };

    dispatch(updateGlobalSearchData({ key: 'filters', data: updatedFilters }));
    dispatch(updateGlobalSearchData({ key: 'filterModel', data: updatedFilterModel }));
    gridApi?.current?.setFilterModel(updatedFilterModel);
    onClose();
  };

  const handleOnCancel = () => {
    setMin(filters?.[tab?.search_field?.[1]]?.values?.[0]?.value || '');
    setMax(filters?.[tab?.search_field?.[2]]?.values?.[0]?.value || '');
    onClose();
  };

  useEffect(() => {
    setMin(filters?.[tab?.search_field?.[1]]?.values?.[0]?.value || '');
    setMax(filters?.[tab?.search_field?.[2]]?.values?.[0]?.value || '');
  }, [tab?.type]);

  return !show ? (
    <></>
  ) : (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          top: '2rem',
          position: 'absolute',
          width: '350px',
          background: 'white',
          zIndex: 5,
          boxShadow: 1,
          paddingX: 2
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginY: 2 }}>
          <Typography fontWeight={600} fontSize={14}>
            {tab?.label}
          </Typography>

          <CloseIcon sx={{ color: '#959fb7', cursor: 'pointer', fontSize: '1.2rem' }} onClick={handleOnCancel} />
        </Box>

        <Box display={'flex'} gap={2} alignItems={'center'}>
          <Box>
            <TextField
              variant='outlined'
              id='outlined-basic'
              placeholder={'Min'}
              size='small'
              sx={{
                width: '100%',
                '& .MuiInputBase-input': {
                  fontWeight: '500',
                  color: '#959fb7',
                  height: '0.8rem'
                }
              }}
              type='number'
              value={min}
              onChange={e => {
                setMinError(false);
                if (e.target.value >= 0) setMin(e.target.value);
              }}
            />
            {minError && (
              <Typography fontWeight={500} color='red' fontSize={10}>
                Please specify min. value
              </Typography>
            )}
          </Box>
          <Typography fontWeight={600}>to</Typography>
          <Box>
            <TextField
              variant='outlined'
              id='outlined-basic'
              placeholder={'Max'}
              size='small'
              sx={{
                width: '100%',
                '& .MuiInputBase-input': {
                  fontWeight: '500',
                  fontSize: '12px',
                  color: '#959fb7',
                  height: '0.8rem'
                }
              }}
              type='number'
              value={max}
              onChange={e => {
                setMaxError(false);
                if (e.target.value >= 0) setMax(e.target.value);
              }}
            />
            {maxError && (
              <Typography fontWeight={500} color='red' fontSize={10}>
                Please specify max. value
              </Typography>
            )}
          </Box>
        </Box>

        <Box display={'flex'} paddingY={1.5} justifyContent={'flex-end'} gap={1}>
          <Button variant='text' onClick={handleOnCancel}>
            <Typography fontSize={12} fontWeight={600} color='#c3423f'>
              Cancel
            </Typography>
          </Button>
          <Button variant='contained' onClick={handleSave}>
            <Typography fontSize={12} fontWeight={600}>
              Save
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 2
        }}
        onClick={handleOnCancel}
      ></Box>
    </>
  );
};

CustomMinMaxFilter.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool
};
