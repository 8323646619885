import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, Paper, Snackbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomDropdown from '../../../../components/common/CustomDropdown';
import MessageLoader from '../../../../components/common/MessageLoader/MessageLoader';
import { getUserSelector } from '../../../../selectors';
import { UsersDataApi, getRecentActivities } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import useApi from '../../../../utils/Hooks/useApiHook';
import LogItem from '../LogItem';
// import Search from '../../../Searches/Search';
// import ProjectSearch from '../ProjectSearch';

const useStyles = makeStyles({
  dialogContainer: {
    zIndex: 1 // Set a low value for z-index
  },
  dropDownContainer: {
    display: 'flex',
    gap: '1rem',
    marginTop: '0.4rem',
    alignItems: 'center'
  },
  content: {
    display: 'grid',
    gap: '1rem',
    marginTop: '1rem',
    gridTemplateColumns: '1.5fr 1fr',
    fontSize: '14px'
  },
  longContent: {
    backgroundColor: '#f7f8fa',
    padding: '1.2rem .8rem',
    height: '75vh'
  },
  crossIcon: { marginLeft: 'auto' },
  dialogTitle: {
    display: 'flex'
  },
  dialogTitleContainer: {
    padding: '0 1rem'
  },
  dialogContentContainer: {
    minHeight: '80vh'
  },
  logListContainer: {
    height: '75vh',
    overflowY: 'auto'
  },
  largeDropDown: {
    width: '35rem'
  },
  text: {
    fontSize: '14px',
    fontWeight: 'lighter'
  }
});

function RecentActivityDialog({ open, setOpen }) {
  const styles = useStyles();
  const userData = useSelector(getUserSelector);
  const [recentActivities, setRecentActivities] = useState([]);
  const [selectedRecentActivity, setSelectedRecentActivity] = useState({});
  const timeRangeOptions = [
    { label: 'All', value: 'all' },
    { label: '3 hours', value: '3-hours' },
    { label: '12 hours', value: '12-hours' },
    { label: '1 day', value: '1-day' },
    { label: '3 days', value: '3-days' }
  ];
  const [timeRange, setTimeRange] = useState(timeRangeOptions[0]);
  const [loadingData, setLoadingData] = useState(false);
  const [showMessage, setShowMessage] = useState({ open: false, message: '' });
  const [userFilter, setUserFilter] = useState(null);
  const logTypeOptions = [
    { label: 'Projects', value: 'Projects' },
    { label: 'Contacts', value: 'Contacts' },
    { label: 'Companies', value: 'Companies' }
  ];
  const [logType, setLogType] = useState('');
  const { data: usersData } = useApi({
    queryFn: () => {
      return UsersDataApi(GET, '', 'all');
    }
  });

  const onClose = () => {
    setOpen(false);
  };

  const getAllRecentActivity = async (userId, timeRange = '3-hours', projectId = null, contactId = null, companyId = null, logType = null) => {
    setLoadingData(true);
    let reqBody = null;

    try {
      if (timeRange === '3-hours') {
        reqBody = {
          filterable: true,
          createdBy: userId,
          // last 24 hours
          startTime: new Date(new Date().setHours(new Date().getHours() - 3)).toISOString(),
          endTime: new Date().toISOString(),
          projectId,
          contactId,
          companyId,
          logType
        };
      } else if (timeRange === '12-hours') {
        reqBody = {
          filterable: true,
          createdBy: userId,
          // last week
          startTime: new Date(new Date().setHours(new Date().getHours() - 12)).toISOString(),
          endTime: new Date().toISOString(),
          projectId,
          contactId,
          companyId,
          logType
        };
      } else if (timeRange === '1-day') {
        reqBody = {
          filterable: true,
          createdBy: userId,
          // last month
          startTime: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
          endTime: new Date().toISOString(),
          projectId,
          contactId,
          companyId,
          logType
        };
      } else if (timeRange === '3-days') {
        reqBody = {
          filterable: true,
          createdBy: userId,
          // last 3 months
          startTime: new Date(new Date().setDate(new Date().getDate() - 3)).toISOString(),
          endTime: new Date().toISOString(),
          projectId,
          contactId,
          companyId,
          logType
        };
      } else if (timeRange === 'all') {
        reqBody = {
          filterable: true,
          createdBy: userId,
          projectId,
          contactId,
          companyId,
          logType
        };
      }

      if (reqBody) {
        const result = await getRecentActivities(reqBody);

        if (result && result.status < 400) {
          setRecentActivities(result.data);
        } else {
          new Error('Failed to fetch recent activities');
        }
      } else {
        new Error('Invalid time range');
      }
    } catch (err) {
      console.log(err);
      setShowMessage({ open: true, message: err.message });
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getAllRecentActivity(userFilter?.value || userData?.id, timeRange?.value, null, null, null, logType?.value?.toLowerCase());
  }, [timeRange, logType, userFilter]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth='xl' classes={{ paper: styles.dialogContainer }}>
      <DialogTitle className={styles.dialogTitleContainer}>
        <div className={styles.dialogTitle}>
          <IconButton onClick={onClose} className={styles.crossIcon}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={styles.dialogContentContainer}>
        <div className={styles.dropDownContainer}>
          <Typography variant='p' className={styles.text}>
            View Activities for last
          </Typography>
          <CustomDropdown
            label={'Time Period'}
            value={timeRange}
            selectedValue={timeRange}
            options={timeRangeOptions}
            onChange={(event, value) => setTimeRange(() => (value ? value : ''))}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <div>
                    <div>
                      <Typography>{option.job_title}</Typography>
                      <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>({option.job_number})</Typography>
                    </div>
                  </div>
                </li>
              );
            }}
          />
          <Typography variant='p' className={styles.text}>
            by
          </Typography>
          {/* <ProjectSearch type='quicksearches' projectType='my-project' onChange={(event, value) => setProjectFilter(value ? value : null)} value={projectFilter} /> */}
          <CustomDropdown
            label={'Select User'}
            className={styles.largeDropDown}
            options={usersData?.data?.map(user => ({ label: `${user.first_name || ''} ${user.lastName || ''} (${user.email})`, value: user.id }))}
            onChange={(event, value) => setUserFilter(value ? value : null)}
          />
          <Typography variant='p' className={styles.text}>
            in
          </Typography>
          <CustomDropdown label={'Type'} value={logType} selectedValue={logType} options={logTypeOptions} onChange={(event, value) => setLogType(() => (value ? value : ''))} />
        </div>
        <div className={styles.content}>
          <div className={styles.logListContainer}>
            <MessageLoader show={loadingData} message={'Loading Recent Activities'} />
            {recentActivities &&
              recentActivities?.map(activity => {
                return <LogItem log={activity} key={activity.id} isSelected={selectedRecentActivity?.id === activity?.id} onClick={setSelectedRecentActivity} />;
              })}
          </div>
          <Paper className={styles.longContent}>{parse(selectedRecentActivity.notes ? selectedRecentActivity.notes : '<p>No notes available</p>')}</Paper>
        </div>
      </DialogContent>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showMessage.open}
        autoHideDuration={6000}
        onClose={() => setShowMessage(prev => ({ ...prev, open: false }))}
        message={showMessage.message}
      />
    </Dialog>
  );
}

RecentActivityDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
};
export default RecentActivityDialog;
