import ApartmentIcon from '@mui/icons-material/Apartment';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import HomeIcon from '@mui/icons-material/Home';
import ImportantDevicesOutlinedIcon from '@mui/icons-material/ImportantDevicesOutlined';
import LockIcon from '@mui/icons-material/Lock';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTES, ROUTE_KEY } from '../../services/constantService';
// import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

export const useNavItems = () => {
  const { t } = useTranslation();
  const navItems = useMemo(
    () => [
      {
        label: t('navMenu.home'),
        route: '/',
        key: 'Home',
        logo: HomeIcon
      },
      {
        label: t('navMenu.projects'),
        route: '/searches',
        key: 'searches',
        logo: DashboardOutlinedIcon
      },
      {
        label: t('navMenu.contacts'),
        route: '/contacts',
        key: 'contacts',
        logo: AssignmentIndOutlinedIcon
      },
      {
        label: t('navMenu.companies'),
        route: '/companies',
        key: 'companies',
        logo: ApartmentIcon
      },
      // {
      //   label: t('navMenu.reports'),
      //   route: '/reports',
      //   key: 'reports',
      //   logo: DescriptionOutlinedIcon
      // },
      {
        label: t('navMenu.setup'),
        route: '/productSetUp',
        key: 'productSetUp',
        logo: SettingsOutlinedIcon
      },
      // {
      //   label: t('navMenu.security'),
      //   route: '/security',
      //   key: 'security',
      //   logo: LockIcon
      // },
      /*  { label: "Users", route: "/users", key: "users", logo: GroupOutlinedIcon }, */
      {
        label: t('navMenu.clientPortal'),
        route: '/client-portal',
        key: 'clientSuite',
        logo: ImportantDevicesOutlinedIcon,
        clientSuite: true,
        target: 'new'
      },
      {
        label: t('navMenu.jobPortal'),
        route: '/portal',
        key: 'jobPortal',
        logo: TravelExploreOutlinedIcon,
        target: 'new'
      },
      // {
      //   label: 'Athena',
      //   route: '/athena/54411541-4a97-4204-914b-e7cf9f6bbc85',
      //   key: 'athena',
      //   logo: TravelExploreOutlinedIcon
      //   // target: "new",
      // },
      {
        label: t('navMenu.workbench'),
        route: '/workbench',
        key: 'workbench',
        logo: DashboardOutlinedIcon
      },
      {
        label: t('navMenu.ignyteIntelligence'),
        route: ROUTES.ignyteIntelligence,
        key: ROUTE_KEY.ignyteIntelligence,
        logo: PsychologyOutlinedIcon
      }
    ],
    [t]
  );

  return navItems;
};
