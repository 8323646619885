//----------------------------------------------// In-built Imports // -------------------------------------------------
import * as React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import Box from '@mui/material/Box';
import CustomTabPanel from './CustomTabPanel';

//----------------------------------------------// Internal Imports // -------------------------------------------------

import PropTypes from 'prop-types';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import '../index.scss';
import ActiveProjects from './ActiveProjectDetails';
import ActivityHistoryDetail from './ActivityHistoryDetail';

const ActivityHistory = React.memo(props => {
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_RECENT_ACTIVITIES, TRANS_KEYS.CONTACTS_PROJECT]);

  const tabsData = [
    {
      label: t(`${TRANS_KEYS.CONTACTS_RECENT_ACTIVITIES}:key`),
      key: 'ActivityLogs'
    }
    // { label: t(`${TRANS_KEYS.CONTACTS_PROJECT}:key`), key: "project" },
  ];

  const [value] = React.useState(0);

  const getContentComponent = key => {
    switch (key) {
      case 'ActivityLogs':
        return (
          <ActivityHistoryDetail
            activityDetails={props.activityDetails}
            isHeaderNav={props.isHeaderNav}
            setActivityComponent={props.setActivityComponent}
            getActivityDetails={props.getActivityDetails}
          />
        );
      case 'project':
        return <ActiveProjects />;

      default:
        return null;
    }
  };

  return (
    <Box id='ActivityHistory_container'>
      {tabsData.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {getContentComponent(tab.key)}
        </CustomTabPanel>
      ))}
    </Box>
  );
});

ActivityHistory.propTypes = {
  isHeaderNav: PropTypes.string,
  activityDetails: PropTypes.object,
  setActivityComponent: PropTypes.func,
  getActivityDetails: PropTypes.func
};

export default ActivityHistory;
