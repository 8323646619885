//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import ContactSelection from '../../ContactSelection/ContactSelection';
import { useSnackbar } from 'notistack';
import SearchSelection from '../../SearchSelection';
import { customFormValidator, requireValidMessage } from '../../../utils/common';
import { ERROR } from '../../../services/constantService';
export default function AddContactToWorkbench(props) {
  const { addToWorkbench, handleClose, open, type, title = 'Contact' } = props;
  const { /* control, */ handleSubmit, register /* , getValues */, setValue } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  /*   const contactsRef = useRef()
  const searchesRef = useRef(); */

  const ContactSchema = yup.object().shape({
    contacts: yup.array().required()
  });

  const SearchSchema = yup.object().shape({
    search_id: yup.string().required()
  });

  const handleAdd = async data => {
    try {
      let success;
      if (data.contacts) {
        const valid = await ContactSchema.isValid(data);
        if (!valid) {
          let requiredField = [{ fieldName: 'contacts', label: 'Contact Name', type: [] }];
          let dirtyField = customFormValidator(data, requiredField);
          if (dirtyField) {
            const message = requireValidMessage(dirtyField);
            enqueueSnackbar(message, { variant: ERROR });
            return;
          }
        }
      } else {
        const valid = await SearchSchema.isValid(data);
        if (!valid) {
          let requiredField = [{ fieldName: 'search_id', label: 'Search Name', type: String }];
          let dirtyField = customFormValidator(data, requiredField);
          if (dirtyField) {
            const message = requireValidMessage(dirtyField);
            enqueueSnackbar(message, { variant: ERROR });
            return;
          }
        }
      }
      if (type === 'Searches') {
        success = await addToWorkbench([data?.search_id]);
      } else {
        success = await addToWorkbench(data.contacts.map(contact => contact.id));
      }
      handleClose(success);
    } catch (e) {
      console.log('Error found in handleAdd::', e);
    }
  };

  const handleCancel = () => handleClose();
  const getValueOf = data => {
    setValue('search_id', data.id);
  };

  return (
    <Popup open={open} className='add-contact-to-workbench' closeOnDocumentClick={false} closeOnEscape={false}>
      <div className='quick-add-contact'>
        <div className='quick-add-header'>
          <Typography>Add {title} To Workbench</Typography>
          <CloseIcon cursor='pointer' onClick={handleCancel} />
        </div>
        <div className={'quick-add-container'}>
          <div className='d-flex flex-column h-100'>
            <div className='quick-add-content'>
              {type === 'Searches' ? (
                <SearchSelection
                  {...register('search_id')}
                  className='field-value'
                  // required={true}
                  label={
                    <label>
                      Search Name<span className='MuiInputLabel-asterisk'>*</span>
                    </label>
                  }
                  placeholder={'Search'}
                  // contactId={contactId}
                  onChange={(e, data) => {
                    getValueOf(data);
                  }}
                  searchResult='All'
                />
              ) : (
                <ContactSelection
                  multiple={true}
                  // required={true}
                  filterSelectedOptions={true}
                  {...register('contacts')}
                  className='input-form-field input-field-old'
                  label={
                    <label>
                      Contact Name<span className='MuiInputLabel-asterisk'>*</span>
                    </label>
                  }
                  placeholder={'Search'}
                  onChange={(e, data) => {
                    setValue('contacts', data);
                  }}
                  InputLabelProps={{ focused: true }}
                />
              )}
            </div>
            <div className='quick-add-footer'>
              <Button variant='text' color='primary' className='button' onClick={handleCancel}>
                Cancel
              </Button>
              <Button variant='contained' onClick={handleSubmit(handleAdd)} className='button' color='primary' type='submit'>
                Add To Workbench
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
}

AddContactToWorkbench.propTypes = {
  open: PropTypes.bool,
  addToWorkbench: PropTypes.func,
  handleClose: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string
};
