import { Autocomplete, Chip, TextField } from '@mui/material';
import propTypes from 'prop-types';
import React, { useState } from 'react';
const MultiSelectDropdown = ({ options, label, placeholder, onChange, getOptions, textFieldProps = {}, ...rest }) => {
  const [selectedValues, setSelectedValues] = useState([]);

  const handleSelectionChange = (event, newValues) => {
    setSelectedValues(newValues);
    if (onChange) {
      onChange(newValues);
    }
  };

  return (
    <Autocomplete
      multiple
      options={options}
      value={selectedValues}
      disableCloseOnSelect
      getOptionLabel={option => option.title}
      onChange={handleSelectionChange}
      renderOption={(props, option, { selected }) => <li {...props}>{option.title}</li>}
      renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => <Chip key={option.value} label={option.title} {...getTagProps({ index })} />)}
      renderInput={params => <TextField {...params} label={label} placeholder={placeholder} {...textFieldProps} />}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          getOptions(e.target.value);
        }
      }}
      {...rest}
    />
  );
};
MultiSelectDropdown.propTypes = {
  options: propTypes.array.isRequired,
  label: propTypes.string,
  placeholder: propTypes.string,
  onChange: propTypes.func,
  rest: propTypes.any,
  getOptions: propTypes.func,
  textFieldProps: propTypes.any
};

export default MultiSelectDropdown;
