import React, { useEffect } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import CustomSelectionComponent from '../CustomSelectionComponent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../actions';

const CompanyGridActionSelection = props => {
  const { handleAssignTag, data = null, handleDeleteCompany, id = '', handleChangeStatus } = props;
  const [options, setOptions] = useState([]);
  const companyActionOptions = useSelector(state => state.commonReducer.companyAction);
  const dispatch = useDispatch();
  const actionLabel = {
    label1: 'Assign Tag',
    label2: 'Delete',
    label3: 'Change Status'
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    if (!companyActionOptions) {
      dispatch(fetchPickLists('COMPANY_ACTIONS', 'companyAction'));
    } else {
      setOptions(companyActionOptions);
    }
  }, [companyActionOptions, dispatch]);
  const customButtonForAllCompany = <MoreVertIcon className='moveVerticalIconStyle' onClick={handleClick} />;
  const handleMenuItemClick = option => {
    // if (option?.short_desc === actionLabel.label1) handleAddToProject(id);
    if (option?.short_desc === actionLabel.label2) handleDeleteCompany(id, data);
    if (option?.short_desc === actionLabel.label1) handleAssignTag(data);
    if (option?.short_desc === actionLabel.label3) handleChangeStatus(data);
    setAnchorEl(null);
  };
  return (
    <div>
      <CustomSelectionComponent options={options} handleMenuItemClick={handleMenuItemClick} component={customButtonForAllCompany} setAnchorEl={setAnchorEl} anchorEl={anchorEl} />
    </div>
  );
};
CompanyGridActionSelection.propTypes = {
  handleAssignTag: PropTypes.func,
  handleDeleteCompany: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  // toggleActivity: PropTypes.func,
  // handleAddToProject: PropTypes.func,
  id: PropTypes.string,
  data: PropTypes.object
};

export default CompanyGridActionSelection;
