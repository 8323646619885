import { Box, IconButton, Modal, Typography, Button } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

function ConfirmModal({ message, open, setOpen, onConfirm }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={{ ...style, width: '22rem', border: 'none' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ebedf1', paddingBottom: '.6rem', marginBottom: '1rem', alignItems: 'center' }}>
          <Typography variant='h3' style={{ fontWeight: 'bolder', fontSize: '1.5rem' }}>
            {message}
          </Typography>

          <IconButton onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ display: 'flex', justifySelf: 'flex-end' }}>
          <Button type='submit' variant='contained' color='primary' onClick={onConfirm}>
            Confirm
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color='error'
            style={{ marginLeft: '.7rem' }}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onConfirm: PropTypes.func
};

export default ConfirmModal;
