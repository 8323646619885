//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { FormControl, Checkbox, FormControlLabel, FormLabel, TextField, RadioGroup, InputLabel } from '@mui/material';
import ReactPhoneInput from 'react-phone-input-material-ui';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../../../common/InputField';
import CountrySelection from '../../../../CountrySelection';
import CompanySelection from '../../../../CompanySelection';
import { determineInitialCountryGuess } from '../../../../../utils/phone';
import StateSelection from '../../../../StateSelection';
import { ExpandableSection } from '../../ExpandableSection';
import RichTextPopup from '../../../../RichTextPopup';

export default function Details(props) {
  const { register, watch, setValue, currentValues, isContactActivityLog, saveStatus, autoSave } = props;
  const country = watch('country');

  useEffect(() => {
    register('country');
    register('state');
    register('current_company');
    register('international_experience');
    register('matrix_notes');
  }, [register]);

  const stateChange = state => {
    setValue('state', state);
  };

  return (
    <ExpandableSection title='Details'>
      <div className='d-flex flex-column w-100'>
        <InputField
          {...register('candidate_name')}
          className='input-form-field input-field-old'
          label='Candidate Name'
          InputProps={{
            readOnly: true,
            disableUnderline: true
          }}
          InputLabelProps={{ focused: true }}
          name='candidate_name'
        />
        <InputField
          {...register('current_job_title', { required: true })}
          required={true}
          className='input-form-field input-field-old'
          label='Title'
          placeholder='Title'
          InputLabelProps={{ focused: true }}
          /* name="current_job_title" */
        />
        <CountrySelection
          defaultValue={country}
          required={true}
          className='input-form-field input-field-old'
          InputLabelProps={{ focused: true }}
          label={'Location'}
          placeholder={'Location'}
          onChange={(e, data) => {
            stateChange('');
            setValue('country', data?.name);
            setValue('country', data?.name, { shouldDirty: true });
          }}
        />
        <CompanySelection
          className='input-form-field input-field-old'
          required={true}
          value={watch('current_company') || null}
          label={'Current Company Name'}
          placeholder={'Search'}
          InputLabelProps={{ focused: true }}
          onChange={(e, data) => {
            setValue('current_company', data, { shouldDirty: true });
            setValue('current_company', data);
            setValue('phone', data ? data.phone : '');
          }}
        />
        <InputField {...register('city')} className='input-form-field input-field-old' name='city' label='City' placeholder='City' InputLabelProps={{ focused: true }} />
        <StateSelection
          value={watch('state') || null}
          defaultValue={currentValues.state}
          className='input-form-field input-field-old'
          country={country}
          register={register}
          onChange={(e, state) => {
            stateChange(state);
            setValue('state', state, { shouldDirty: true });
          }}
        />
        <InputField {...register('revenue')} className='input-form-field input-field-old' label='Company Revenue' placeholder='Company Revenue' InputLabelProps={{ focused: true }} name='revenue' />
        <div className='input-form-field input-field-old'>
          <ReactPhoneInput
            {...register('home_phone')}
            placeholder='Home Phone'
            value={watch('home_phone') || ''}
            onChange={(data, c, e, value) => {
              if (data?.length > 1) {
                setValue('home_phone', value);
              } else {
                setValue('home_phone', '');
              }
            }}
            enableSearch={true}
            disableInitialCountryGuess={determineInitialCountryGuess(currentValues.home_phone)}
            inputProps={{
              label: 'Home Phone',
              InputLabelProps: { focused: true }
            }}
            component={TextField}
          />
        </div>
        <div className='input-form-field input-field-old'>
          <ReactPhoneInput
            {...register('mobile_phone')}
            placeholder='Mobile Phone'
            value={watch('mobile_phone') || ''}
            onChange={(data, c, e, value) => {
              if (data?.length > 1) {
                setValue('mobile_phone', value);
                setValue('mobile_phone', value, { shouldDirty: true });
              } else {
                setValue('mobile_phone', '');
              }
            }}
            enableSearch={true}
            disableInitialCountryGuess={determineInitialCountryGuess(currentValues.mobile_phone)}
            inputProps={{
              label: 'Mobile Phone',
              InputLabelProps: { focused: true }
            }}
            component={TextField}
          />
        </div>
        <div className='input-form-field input-field-old'>
          <ReactPhoneInput
            {...register('direct_line')}
            placeholder='Work Number'
            value={watch('direct_line') || ''}
            onChange={(data, c, e, value) => {
              if (data?.length > 1) {
                setValue('direct_line', value);
                setValue('direct_line', value, { shouldDirty: true });
              } else {
                setValue('direct_line', '');
              }
            }}
            enableSearch={true}
            disableInitialCountryGuess={determineInitialCountryGuess(currentValues.direct_line)}
            inputProps={{
              label: 'Work Number',
              InputLabelProps: { focused: true }
            }}
            component={TextField}
          />
        </div>
        <div className='input-form-field input-field-old'>
          <ReactPhoneInput
            {...register('phone')}
            placeholder='Switchboard Number'
            value={watch('phone') || ''}
            onChange={(data, c, e, value) => {
              if (data?.length > 1) {
                setValue('phone', value);
                setValue('phone', value, { shouldDirty: true });
              } else {
                setValue('phone', '');
              }
            }}
            enableSearch={true}
            disableInitialCountryGuess={determineInitialCountryGuess(currentValues.phone)}
            inputProps={{
              label: 'Switchboard Number',
              InputLabelProps: { focused: true }
            }}
            component={TextField}
          />
        </div>
        <InputField
          {...register('work_email')}
          className='input-form-field input-field-old'
          type='email'
          label='Work Email'
          placeholder='Work Email'
          InputLabelProps={{ focused: true }}
          name='work_email'
        />
        <InputField
          {...register('private_email')}
          className='input-form-field input-field-old'
          type='email'
          label='Private Email'
          placeholder='Private Email'
          InputLabelProps={{ focused: true }}
          name='private_email'
        />
        <InputField
          {...register('linkedin_url')}
          className='input-form-field input-field-old'
          type='email'
          label='LinkedIn Link'
          placeholder='LinkedIn Link'
          InputLabelProps={{ focused: true }}
          name='linkedin_url'
        />
        <CountrySelection
          size='small'
          placeholder='International Experience'
          multiple={true}
          filterSelectedOptions={true}
          className='input-form-field input-field-old'
          label='International Experience'
          InputLabelProps={{ focused: true }}
          defaultValue={currentValues.international_experience}
          onChange={(e, data) => {
            setValue('international_experience', data);
            setValue('international_experience', data, { shouldDirty: true });
          }}
        />
        {!isContactActivityLog ? (
          <div className='contact-details mt-2 mb-2'>
            <InputLabel className='contact-view-label'>Matrix Notes</InputLabel>
            <div className='outlined-border transform p-0'>
              <RichTextPopup
                name={'matrix_notes'}
                onSave={autoSave}
                value={currentValues.matrix_notes}
                title='Matrix Notes'
                autoSave={autoSave}
                saveStatus={saveStatus}
                updatedDate={currentValues.candidateUpdatedAt}
              />
            </div>
          </div>
        ) : null}
        <FormControl className='d-flex w-100'>
          <FormLabel focused={true} className='MuiInputLabel-shrink'>
            Diversity Candidate
          </FormLabel>
          <RadioGroup row className='radio-group'>
            <FormControlLabel
              className='checkbox-container'
              control={
                <Checkbox
                  className='checkbox-component'
                  style={{ padding: '0px 10px' }}
                  defaultChecked={watch('diversity_candidate')}
                  name='diversity_candidate'
                  {...register('diversity_candidate')}
                />
              }
              label='Yes'
            />
          </RadioGroup>
        </FormControl>
      </div>
    </ExpandableSection>
  );
}

Details.propTypes = {
  register: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  isContactActivityLog: PropTypes.bool,
  saveStatus: PropTypes.bool,
  autoSave: PropTypes.func
};
