//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
//import InputBase from "@mui/material/TextField";
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';

const CustomInputField = React.forwardRef(props => {
  const {
    color = 'heading',
    variant = 'outlined',
    fullWidth = true,
    hiddenLabel = true,
    endIcon = null,
    startIcon = null,
    onChange = () => {},
    onBlur = () => {},
    required = false,
    label = '',
    value,
    defaultValue,
    helperText = '',
    error = false,
    inputPropsMin = null,
    inputPropsMax = null,
    minRows,
    multiline,
    isDisabled,
    size = 'small',
    InputLabelProps = {},
    ...rest
  } = props;

  return (
    <TextField
      {...rest}
      disabled={isDisabled}
      id='outlined-basic'
      color={color}
      value={value}
      required={required}
      label={label}
      size={size}
      fullWidth={fullWidth}
      hiddenLabel={hiddenLabel}
      variant={variant}
      onChange={onChange}
      onBlur={onBlur}
      multiline={multiline}
      minRows={minRows}
      defaultValue={defaultValue}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: endIcon ? <InputAdornment position='end'>{endIcon}</InputAdornment> : null,
        startAdornment: startIcon ? <InputAdornment position='start'>{startIcon}</InputAdornment> : null,
        min: inputPropsMin ? inputPropsMin : null,
        max: inputPropsMax ? inputPropsMax : null
      }}
      InputLabelProps={InputLabelProps}
    />
  );
});

CustomInputField.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  hiddenLabel: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  inputPropsMin: PropTypes.number,
  inputPropsMax: PropTypes.number,
  multiline: PropTypes.bool,
  isDisabled: PropTypes.bool,
  minRows: PropTypes.number,
  size: PropTypes.string,
  InputLabelProps: PropTypes.object
};

export default CustomInputField;
