//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Button, Typography, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { InputLabel } from '@mui/material';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import IndustrySelection from '../../IndustrySelection';
import CountrySelection from '../../CountrySelection';
import ErrorPopup from '../../AddCompany/ErrorPopup';
import Loader from '../../common/Loader';
import { POST, SUCCESS, ERROR } from '../../../services/constantService';
import { companyDataApi } from '../../../services/ApiService';
import { unableMessage, successMessage } from '../../../services/MessageService';
import { customFormValidator, requireValidMessage } from '../../../utils/common';

export default function QuickAddCompany(props) {
  const { name, addCompany: handleAdd, handleClose } = props;
  const { register, unregister, control, handleSubmit, getValues, watch, setValue } = useForm({ defaultValues: { name } });
  const [duplicateCompanies, setDuplicateCompanies] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const countryRef = useRef();

  useEffect(() => {
    register('industries');
    register('country');
    return () => {
      unregister('industries');
      unregister('country');
    };
  }, [register, unregister]);

  const CompanySchema = yup.object().shape({
    name: yup.string().required(),
    website: yup.string().required(),
    country: yup.string().required(),
    industries: yup.array().of(yup.string()).required()
  });

  const addCompany = async formData => {
    let isValid = await CompanySchema.isValid(formData);
    if (!isValid) {
      let requiredField = [
        { fieldName: 'name', label: 'First Name', type: String },
        { fieldName: 'website', label: 'Website', type: String },
        { fieldName: 'country', label: 'Location', type: String },
        { fieldName: 'industries', label: 'Industry', type: [] }
      ];
      let dirtyField = customFormValidator(formData, requiredField);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    setLoading(true);
    let sub_route = '';
    if (formData.allowDuplicate) {
      sub_route = sub_route.concat('?allowDuplicate=true');
    }
    const { status, data } = await companyDataApi(POST, '', formData, sub_route);
    if (status === 201) {
      const company = { ...formData, id: data.id };
      handleAdd(company);
      const message = successMessage('Company', 'created');
      enqueueSnackbar(message, { variant: SUCCESS });
    } else if (status === 200) {
      setDuplicateCompanies(data.companies);
    } else {
      const message = unableMessage('company', 'add');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setLoading(false);
  };

  const handleAddDuplicate = async () => {
    try {
      await addCompany({ ...getValues(), allowDuplicate: true });
    } catch (e) {
      console.log('Error found in handleAddDuplicate::', e);
    }
  };

  const handleShowCompanies = () => {
    const idQuery = duplicateCompanies.map(company => `id=${company.id}`).join('&');
    window.open(`/companies?${idQuery}`);
  };

  const handleCancel = () => {
    setDuplicateCompanies(false);
  };

  return (
    <React.Fragment>
      <Loader show={loading} />
      {duplicateCompanies ? <ErrorPopup onCancel={handleCancel} onView={handleShowCompanies} onContinue={handleAddDuplicate} /> : null}
      <div className='quick-add-company'>
        <div className='d-flex quick-add-header text-left'>
          <Typography>Quick Add Company</Typography>
          <CloseIcon cursor='pointer' onClick={handleClose} />
        </div>
        <div className={'quick-add-container'}>
          <div className='d-flex flex-column py-3 all-popup-content'>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel className='page-label'>
                  Company Name<span className='MuiInputLabel-asterisk'>*</span>
                </InputLabel>
                <div className='page-label-value'>
                  <TextField variant='outlined' placeholder='Company Name' InputLabelProps={{ focused: true }} className='input-form-field' {...register('name')} name={'name'} />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <InputLabel className='page-label'>
                  Website<span className='MuiInputLabel-asterisk'>*</span>
                </InputLabel>
                <div className='page-label-value'>
                  <TextField variant='outlined' placeholder='Website' InputLabelProps={{ focused: true }} className='input-form-field' {...register('website')} name={'website'} />
                </div>
              </div>
            </div>

            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel className='page-label'>
                  Industry<span className='MuiInputLabel-asterisk'>*</span>
                </InputLabel>
                <div className='page-label-value multiple-selection-list'>
                  {/* <IndustrySelection
										// {...field}
										name={'industries'}
										multiple
										// required={true}
										// placeholder={"Select"}
										variant="outlined"
										className="input-form-field"
										// inputRef={industryRef}
										value={watch('industries') || []}
										onChange={(e, data) => {
											setValue('industries', data ? data.map((item) => typeof item === 'string' ? item : item.name) : [])
										}}
										size="small"
										filterSelectedOptions={true}
										InputLabelProps={{ focused: true }}
									/> */}
                  <Controller
                    name='industries'
                    render={({ field }) => (
                      <IndustrySelection
                        {...field}
                        name={'industries'}
                        {...register('industries')}
                        multiple
                        placeholder='Select Industry'
                        variant='outlined'
                        // required={true}
                        // inputRef={industryRef}
                        filterSelectedOptions={true}
                        InputLabelProps={{ focused: true }}
                        className='input-form-field add-quick-input-industries'
                        onChange={(e, data) => {
                          setValue('industries', data ? data.map(item => (typeof item === 'string' ? item : item.name)) : []);
                        }}
                      />
                    )}
                    control={control}
                    // onFocus={() => {
                    // 	industryRef.current.focus();
                    // }}
                    onChange={([, data]) => (Array.isArray(data) ? data.map(item => item.name) : [])}
                    // rules={{ required: true, validate: d => d?.length > 0 }}
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <InputLabel className='page-label'>
                  Location<span className='MuiInputLabel-asterisk'>*</span>
                </InputLabel>
                <div className='page-label-value'>
                  {/* <CountrySelection
										// {...field}
										// required={true}
										// inputRef={countryRef}
										name={'country'}
										className='input-form-field'
										value={watch('country') || ""}
										onChange={(e, data) => {
											setValue('country', data.name)
										}}
										placeholder={"country"}
										InputLabelProps={{ focused: true }}
										label={''}
										variant="outlined"
									/> */}
                  <Controller
                    name={'country'}
                    render={({ field }) => (
                      <CountrySelection
                        {...field}
                        name={'country'}
                        // required={true}
                        {...register('country')}
                        inputRef={countryRef}
                        variant='outlined'
                        className='input-form-field'
                        label=''
                        placeholder={'choose country'}
                        InputLabelProps={{ focused: true }}
                        onChange={(e, data) => {
                          console.log('Quick Add', data);
                          setValue('country', data ? data.name : null);
                        }}
                      />
                    )}
                    control={control}
                    onFocus={() => {
                      countryRef.current.focus();
                    }}
                    onChange={([, data]) => (data ? data.name : null)}
                    // rules={{ required: true }}
                  />
                </div>
              </div>
            </div>

            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view-telephone phone_input'>
                <InputLabel className='page-label-telephone'>Switchboard</InputLabel>
                <div className='page-label-value-telephone'>
                  <ReactPhoneInput
                    {...register('switchboard')}
                    style={{ color: 'black' }}
                    value={watch('switchboard') || ''}
                    onChange={(data, c, e, value) => {
                      if (data?.length > 1) {
                        setValue('switchboard', value);
                      } else {
                        setValue('switchboard', '');
                      }
                    }}
                    enableSearch={true}
                    inputProps={{
                      className: 'input-form-field',
                      InputLabelProps: { focused: true },
                      variant: 'outlined'
                    }}
                    component={TextField}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='page-content-footer'>
            <Button className='page-btn-cancel' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='contained' type='submit' className='page-btn-save' onClick={handleSubmit(addCompany)}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

QuickAddCompany.propTypes = {
  name: PropTypes.string,
  addCompany: PropTypes.func,
  handleClose: PropTypes.func
};
