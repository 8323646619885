import { styled } from '@mui/material/styles';
import styles from './index.scss';

export const BpIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 18,
  height: 18,
  backgroundColor: 'white',
  border: '1px solid #b2b8ca'
}));

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: styles.palettePrimary,
  borderColor: styles.palettePrimary,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 16.5,
    height: 16.5,
    backgroundImage: 'radial-gradient(#fff,#fff 27%,transparent 32%)',
    content: '""'
  }
});
