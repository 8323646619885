import { Delete } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../services/constantService';
import { CustomActionRenderer } from '../../../../../utils/ActionRenderer';
import { calculateNumberOfDays } from '../../../../../utils/date';
import { generateWorkbenchProjectColumn } from './GridData';

function ProjectGrid({ data, onSelect, handleDeleteContact }) {
  const { t } = useTranslation();
  const sx = {};
  const navigate = useNavigate();

  const selector = {
    width: 60,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    pinned: 'left'
  };

  const columns = useMemo(() => {
    return generateWorkbenchProjectColumn(t).map(col => {
      return col;
    });
  }, [t]);

  const onGridReady = params => {
    // columnApi = params.columnApi;
    params.columnApi.autoSizeColumns();
    // loadColumnStateFromLocalStorage("contactColumns", { columnApi, gridApi });
  };
  const handleDelete = async elem => {
    handleDeleteContact([elem]);
  };

  const otherActionOption = [
    {
      component: Delete,
      onClick: handleDelete
    }
  ];

  const routeProject = id => {
    const path = ROUTES.vProject.replace(':id', id);
    navigate(path);
  };

  const rowData = useMemo(() => {
    return data.map(elem => {
      return {
        ...elem,
        ...elem.project,
        job_number: (
          <div className='cursor-pointer' onClick={() => routeProject(elem?.id)}>
            <u>{elem?.project?.job_number}</u>
          </div>
        ),
        actions: <CustomActionRenderer item={elem} options={[]} otherButtons={otherActionOption}></CustomActionRenderer>,
        days_open: calculateNumberOfDays(elem?.project?.created_at),
        industry: elem?.project?.ign_industries?.name,
        created_by: elem?.project?.creator?.name,
        company_name: elem?.project?.ign_companies?.name
      };
    });
  }, [data]);
  return (
    <Stack id='myGrid' className='ag-theme-alpine workbench-table'>
      <AgGridReact
        columnDefs={[selector, ...columns]}
        rowData={rowData}
        suppressRowClickSelection={true}
        paginationPageSize={10}
        isRowSelectable={() => {
          return true;
        }}
        rowSelection='multiple'
        enableRangeSelection={true}
        defaultColDef={{
          resizable: true,
          filter: true,
          sortable: true
        }}
        // headerComponentFramework={CustomHeader}
        {...sx}
        onSelectionChanged={onSelect}
        columnMenu={true}
        onGridReady={onGridReady}
        render
      />
    </Stack>
  );
}

ProjectGrid.propTypes = {
  data: PropTypes.array,
  onSelect: PropTypes.func,
  handleDeleteContact: PropTypes.func
};

export default ProjectGrid;
