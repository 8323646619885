//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useRef, useState } from 'react';

import { Box, Typography } from '@mui/material';
import CustomCard from '../../../common/CustomCard';
import CustomCommonPersonality from '../../../common/CustomCommonPersonality';
import PropTypes from 'prop-types';
import CustomButton from '../../../common/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import '../../index.scss';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';

const noteType1 = 'professional';
const noteType2 = 'personal_notes';
const approved1 = `is_${noteType1}_approved_by_partner`;
const approved2 = `is_${noteType2}_approved_by_partner`;

const ProfessionalAndPersonal = props => {
  const {
    professionalCardHeight,
    professionalCardWidth,
    register = () => {},
    setValue = () => {},
    handleSave = () => {},
    currentValues,
    isContactView = false,
    contact_id,
    getContact = () => {},
    getContactDetails = () => {},
    setLoading = () => {},
    popupClose
  } = props;
  const [isCommonPopup, setIsCommonPopup] = useState(false);
  const [item, setItem] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isMorePopupOpen, setIsMorePopupOpen] = useState(false);
  const [indexNumbers, setIndexNumbers] = useState();
  const indexValueRef = useRef();
  indexValueRef.current = indexNumbers;
  const itemRef = useRef();
  itemRef.current = item;
  const [isInlineEditing, setIsInLineEditing] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const isInlineEditingRef = useRef();
  isInlineEditingRef.current = isInlineEditing;
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_PERSONAL_NOTE, TRANS_KEYS.CONTACTS_PROFESSIONAL, TRANS_KEYS.CONTACTS_WORD_AND, TRANS_KEYS.CONTACTS_WORD_ADD]);

  const notesLabel = {
    label1: t(`${TRANS_KEYS.CONTACTS_PROFESSIONAL}:key`),
    label2: t(`${TRANS_KEYS.CONTACTS_PERSONAL_NOTE}:key`)
  };
  const styleData = {
    height: professionalCardHeight || 250,
    width: professionalCardWidth || 400,
    minWidth: professionalCardWidth || 400
  };

  const handleCommonPopup = () => {
    setIsCommonPopup(true);
  };
  useEffect(() => {
    const convertData = () => {
      let newData = [];
      if (currentValues && currentValues.length) {
        currentValues.forEach(itemEntry => {
          if (itemEntry?.notes_type === noteType1 || itemEntry?.notes_type === noteType2 || itemEntry?.notes_type === 'professional_notes') {
            let projectIndex = newData.findIndex(project => project?.projects?.project_id === itemEntry?.project?.id);

            if (projectIndex === -1) {
              newData.push({
                projects: { project_name: itemEntry?.project?.job_title, project_id: itemEntry?.project?.id },
                [noteType1]: [],
                [noteType2]: [],
                [approved1]: false,
                [approved2]: false
              });
              projectIndex = newData.length - 1;
            }

            if (itemEntry.notes_type === noteType1 || itemEntry?.notes_type === 'professional_notes') {
              newData[projectIndex][noteType1].push({ ...itemEntry });
              newData[projectIndex][approved1] = itemEntry.is_partner_approved;
            } else if (itemEntry.notes_type === noteType2) {
              newData[projectIndex][noteType2].push({ ...itemEntry });
              newData[projectIndex][approved2] = itemEntry.is_partner_approved;
            }
          }
        });
        return newData;
      } else {
        return newData;
      }
    };
    setItem(convertData());
    setIsCommonPopup(false);
  }, [currentValues]);
  const handlePopupCommonSubmit = async () => {
    // if (itemRef.current.length > 0) {
    //   let updatedItem = isEditing ? itemRef.current : [...itemRef.current];
    //   if (isEditing || isInlineEditingRef.current) {
    //     const projectObj = item.find(
    //       (itemProject) => itemProject.project_id === data.project_id,
    //     );
    //     if (projectObj) {
    //       for (let key in data) {
    //         if (key === noteType1) {
    //           projectObj[noteType1] = data[noteType1];
    //         } else if (key === noteType2) {
    //           const existingIndex = projectObj[noteType2].indexOf(
    //             data[noteType2],
    //           );
    //           if (existingIndex !== -1) {
    //             projectObj[noteType2][existingIndex] = data[noteType2];
    //           } else {
    //             projectObj[noteType2] = data[noteType2];
    //           }
    //         }
    //       }
    //       projectObj[approved1] = data[approved1];
    //       projectObj[approved2] = data[approved2];
    //     }
    //     setIsEditing(false);
    //     setIsInLineEditing(false);
    //   } else {
    //     const projectIndex = updatedItem.findIndex(
    //       (obj) => obj.project_id === data.project_id,
    //     );
    //     if (projectIndex !== -1) {
    //       for (let key in data) {
    //         if (key === noteType1) {
    //           updatedItem[projectIndex][noteType1].push(...data[noteType1]);
    //         } else if (key === noteType2) {
    //           updatedItem[projectIndex][noteType2].push(...data[noteType2]);
    //         }
    //       }
    //     } else {
    //       setItem((prevTexts) => [...prevTexts, data]);
    //     }
    //   }
    // } else {
    //   setItem((prevTexts) => [...prevTexts, data]);
    // }
    if (isEditing || isInlineEditingRef.current) {
      if (isContactView) {
        const result = await handleSave('contact_notes_all', false);
        if (result) {
          setIsEditing(false);
          setIsInLineEditing(false);
        }
      } else {
        const result = await handleSave();
        if (result) {
          setIsEditing(false);
          setIsInLineEditing(false);
        }
      }
    } else {
      if (isContactView) {
        await handleSave('contact_notes_all', false);
      } else {
        await handleSave();
      }
    }
  };
  const editItems = indexValue => {
    setIndexNumbers(indexValue);
    setIsEditing(true);
  };
  const viewItems = indexValue => {
    setIsMorePopupOpen(true);
    setIndexNumbers(indexValue);
    // setIsEditing(true)
  };
  const inlineEditing = value => {
    setIsInLineEditing(true);
    setSelectedType(value);
  };

  const displayData = data => {
    return (
      data &&
      data.map((elem, parentIndex) => (
        <Box className='d-flex custom-card' key={parentIndex} gap={2}>
          <CustomCard
            data={elem}
            isTypes={true}
            onSubmit={handlePopupCommonSubmit}
            // itemLength={elem[noteType1].length}
            indexValue={parentIndex}
            editItems={editItems}
            isEditing={isEditing}
            noteType1={noteType1}
            noteType2={noteType2}
            // height={professionalCardHeight || 250}
            // width={professionalCardWidth || 400}
            fontSize={12}
            viewItems={viewItems}
            approved1={approved1}
            approved2={approved2}
            styleData={styleData}
            notesLabel={notesLabel}
            contact_id={contact_id}
            getContact={getContact}
            getContactDetails={getContactDetails}
            setLoading={setLoading}
          />
        </Box>
      ))
    );
  };
  return (
    <Box className='personality_sub flex flex-column'>
      <Box className='d-flex align-items-center gap-2'>
        <Typography className='compensation-info' variant='body1'>
          {notesLabel.label1} {t(`${TRANS_KEYS.CONTACTS_WORD_AND}:key`)} {notesLabel.label2}
        </Typography>
        {item.length > 0 && (
          <Box mt={0} ml={2}>
            <CustomButton
              type={'secondary'}
              variant='outlined'
              size={'small'}
              buttonText={t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`)}
              customWidth={96}
              onClick={handleCommonPopup}
              iconLeft={<AddIcon sx={{ width: 14 }} />}
            />
          </Box>
        )}
      </Box>
      {item.length === 0 ? (
        <Box className='personality-head'>
          <Box className='add-another-buttons'>
            <CustomButton
              type={'secondary'}
              variant='outlined'
              size={'small'}
              buttonText={t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`)}
              customWidth={96}
              onClick={handleCommonPopup}
              iconLeft={<AddIcon sx={{ width: 14 }} />}
            />
          </Box>
        </Box>
      ) : (
        <Box className='personality-head d-flex'>
          <Box className='d-flex' gap={2}>
            {itemRef.current.length > 0 && displayData(itemRef.current)}
          </Box>
          {/* <Box>
              <Typography variant='body1'>Professional Notes</Typography>
              {
                professionalData.length > 0 && professionalData.map((text, index) => (
                  <Box className="custom-card" key={index}>
                    <CustomCard text={text} title="Professional" />
                  </Box>
                ))
              }
              {
                professionalData.length === 0 && <Box className="add-another-buttons" onClick={handleProfessionalOpenPopup}>
                  <AddButton
                    title="Add Professional Notes"
                  />
                </Box>

              }

              {isProfessionalPopupOpen && (
                <CustomizedDialogs
                  title="Professional Notes"
                  setIsPopupOpen={setIsProfessionalPopupOpen}
                  isPopupOpen={isProfessionalPopupOpen}
                  onSubmit={handleProfessionalSubmit}
                />
              )}
            </Box> */}
          {/* <Box>
              <Typography variant='body1'>Personal Notes</Typography>
              {
                personalNotesData.length > 0 && personalNotesData.map((text, index) => (
                  <Box className="custom-card" key={index}>
                    <CustomCard text={text} title="Personal" />

                  </Box>
                ))
              }
              {
                personalNotesData.length === 0 && <Box className="add-another-buttons" onClick={handlePersonalNotesOpenPopup}>
                  <AddButton
                    title="Add Personal Notes"
                  />
                </Box>
              }

              {isPersonalNotesPopupOpen && (
                <CustomizedDialogs
                  title="Personal Notes"
                  setIsPopupOpen={setIsPersonalNotesPopupOpen}
                  isPopupOpen={isPersonalNotesPopupOpen}
                  onSubmit={handlePersonalNotesSubmit}
                />
              )}
            </Box> */}
        </Box>
      )}
      {isCommonPopup && (
        <CustomCommonPersonality
          setIsPopupOpen={setIsCommonPopup}
          isPopupOpen={isCommonPopup}
          onSubmit={handlePopupCommonSubmit}
          // types="ScreeningAndRecommendation"
          noteType1={noteType1}
          noteType2={noteType2}
          itemValue={itemRef.current}
          approved1={approved1}
          approved2={approved2}
          notesLabel={notesLabel}
          setValue={setValue}
          register={register}
          contact_id={contact_id}
          getContact={getContact}
          popupClose={popupClose}
        />
      )}
      {isEditing && (
        <CustomCommonPersonality
          // types={types}
          setIsPopupOpen={setIsEditing}
          isPopupOpen={isEditing}
          onSubmit={handlePopupCommonSubmit}
          isEditing={isEditing}
          itemValue={itemRef.current[indexValueRef.current]}
          noteType1={noteType1}
          noteType2={noteType2}
          // isMorePopupOpen={isMorePopupOpen}
          // setIsMorePopupOpen={setIsMorePopupOpen}
          // editItems={editItems}
          // indexValue={indexValueRef.current}
          approved1={approved1}
          approved2={approved2}
          notesLabel={notesLabel}
          setValue={setValue}
          register={register}
          contact_id={contact_id}
          getContact={getContact}
          popupClose={popupClose}
        />
      )}
      {isMorePopupOpen && (
        <CustomCommonPersonality
          // types={types}
          setIsPopupOpen={setIsMorePopupOpen}
          isPopupOpen={isMorePopupOpen}
          onSubmit={handlePopupCommonSubmit}
          isEditing={isEditing}
          itemValue={itemRef.current[indexValueRef.current]}
          noteType1={noteType1}
          noteType2={noteType2}
          isMorePopupOpen={isMorePopupOpen}
          setIsMorePopupOpen={setIsMorePopupOpen}
          // editItems={editItems}
          // indexValue={indexValueRef.current}
          approved1={approved1}
          approved2={approved2}
          inlineEditing={inlineEditing}
          isInlineEditing={isInlineEditingRef.current}
          setIsInLineEditing={setIsInLineEditing}
          selectedType={selectedType}
          notesLabel={notesLabel}
          setValue={setValue}
          register={register}
          contact_id={contact_id}
          getContact={getContact}
        />
      )}
    </Box>
  );
};

ProfessionalAndPersonal.propTypes = {
  professionalCardHeight: PropTypes.number,
  professionalCardWidth: PropTypes.number,
  register: PropTypes.func,
  setValue: PropTypes.func,
  handleSave: PropTypes.func,
  currentValues: PropTypes.object,
  isContactView: PropTypes.bool,
  getContact: PropTypes.func,
  contact_id: PropTypes.string,
  getContactDetails: PropTypes.func,
  setLoading: PropTypes.func,
  popupClose: PropTypes.bool
};

export default ProfessionalAndPersonal;
