import { Box, Checkbox, Typography } from '@mui/material';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { fetchPickLists } from '../../../../actions';
import ColumnFilter from '../../../../Containers/Commons/ColumnFilter';
import { globalSearchSelector } from '../../../../selectors';
import { globalSearchApi } from '../../../../services/ApiService';
import { updateGlobalSearchData } from '../../../../store/globalSearchSlice';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import ViewCompanyDrawer from '../../../ViewCompany/ViewCompanyDrawer';
import ViewContactDrawer from '../../../ViewContactDrawer';
import { columnDefs, SUCCESS_STATUS_CODE } from '../../helper/columnDefs';
import { findMatchingValue, getFormattedData, getTotalCount, htmlToPlainText } from '../../helper/utils';
import '../../index.scss';

let prevColumnFilters = [];
let prevGlobalSearchData = [];

export const TabContent = () => {
  const { selectedTabId, search } = useParams();

  const searchValue = search;

  const { tabs, limit, selectedContactId, selectedCompanyId } = useSelector(globalSearchSelector);
  const companyStatusOption = useSelector(state => state.commonReducer.companyStatus);
  const { pathname } = useLocation();

  const PAGE_LIMIT = limit;
  const selectedTab = tabs?.find(t => t?.model === selectedTabId);

  const [options, setOptions] = useState([]);

  const gridApi = useRef(null);
  const columnApi = useRef(null);

  const dispatch = useDispatch();

  const getRows = (selectedTabId, data, sort) => {
    const tabData = data?.find(t => t?.model === selectedTabId);
    const results = (tabData?.data?.hits?.hits || [])?.map(item => getFormattedData(selectedTabId, item, sort));
    return results;
  };

  const onGridReady = params => {
    gridApi.current = params.api;
    columnApi.current = params.columnApi;

    dispatch(updateGlobalSearchData({ key: 'gridApi', data: gridApi }));
    params.api.sizeColumnsToFit();
  };

  const defaultColumns = columnDefs(selectedTabId, useLanguageHooks);

  const handleLocalSortAndFilter = (params, globalSearchData, sortFilter, columnFilters, filterModel) => {
    if (globalSearchData.length === 0) {
      globalSearchData = prevGlobalSearchData;
    }
    let results = getRows(selectedTabId, globalSearchData, sortFilter?.sort);
    let count = getTotalCount(selectedTabId, globalSearchData);

    // APPLY SORTING
    if (sortFilter?.colId && sortFilter?.sort && sortFilter.sort !== null) {
      let propertyName = sortFilter?.colId;

      results = results.sort((a, b) => {
        let valA, valB;
        if (Array.isArray(a?.[propertyName])) {
          valA = a?.[propertyName]?.[0] || '';
          valB = b?.[propertyName]?.[0] || '';
        } else if (sortFilter?.colId === 'name') {
          valA = a?.name?.name || '';
          valB = b?.name?.name || '';
        } else if (sortFilter?.colId === 'company_size') {
          const minA = a?.company_size?.split?.('-')?.[0] || 0;
          const minB = b?.company_size?.split?.('-')?.[0] || 0;
          valA = Number(minA);
          valB = Number(minB);
        } else if (sortFilter?.colId === 'note_text') {
          valA = htmlToPlainText(a?.item?._source?.notes || '');
          valB = htmlToPlainText(b?.item?._source?.notes || '');
        } else {
          valA = a?.[propertyName];
          valB = b?.[propertyName];
        }
        if (sortFilter?.sort === 'asc') {
          if (typeof valA === 'number' && typeof valB === 'number') {
            return valA - valB;
          }
          return valA?.toString()?.localeCompare(valB?.toString());
        }
        if (typeof valA === 'number' && typeof valB === 'number') {
          return valB - valA;
        }
        return valB?.toString().localeCompare(valA?.toString());
      });
    }

    // APPLY FILTERING
    if (columnFilters && columnFilters.length > 0) {
      columnFilters.forEach(key => {
        const filterValue = String(filterModel[key].filter).toLowerCase();

        // Check if the key exists in the filterModel
        const columnExists = Object.keys(params.filterModel).includes(key);
        if (columnExists) {
          results = results.filter(item => {
            let itemValue = item[key];
            if (key === 'name' && typeof item[key] === 'object') {
              itemValue = item[key].name;
            } else if (key === 'tags' && Array.isArray(item[key])) {
              itemValue = item[key].map(tag => tag.name).join(', ');
            } else if (key === 'note_text') {
              const log = item?.item?._source;
              itemValue = findMatchingValue(log, filterValue) ? filterValue : '';
            } else if (key === 'activity_name') {
              const log = item?.item?._source;
              const logOwner = log?.updated_user?.first_name ? log?.updated_user : log?.created_user;
              const logOwnerName = ((logOwner?.first_name || '') + ' ' + (logOwner?.last_name || '')).trim();
              if (log?.activity_type?.includes('candidate_stage_change')) {
                const name = ((log?.contact?.first_name || '') + ' ' + (log?.contact?.last_name || '')).trim();
                itemValue = `${name} moved to ${log?.to_stage} on ${log?.project?.job_number || '0000'}-${log?.project?.job_title || '.'}`;
              } else if (log?.activity_type?.includes('project_stage_update')) {
                itemValue = findMatchingValue(log, filterValue) ? filterValue : '';
              } else {
                itemValue = `${logOwnerName || 'Unknown user'} logged a ${log?.activity_type} for`;
              }
            }
            itemValue = String(itemValue).toLowerCase();
            return itemValue.includes(filterValue);
          });
        }
      });
      count = results.length;
    } else {
      count = getTotalCount(selectedTabId, globalSearchData);
    }

    params.successCallback(results, count);
    return { count };
  };

  useEffect(() => {
    prevColumnFilters = [];
    prevGlobalSearchData = [];
  }, [selectedTabId]);

  useEffect(() => {
    if (!companyStatusOption) {
      dispatch(fetchPickLists('COMPANY_STATUS', 'companyStatus'));
    } else {
      setOptions(companyStatusOption);
    }
  }, [companyStatusOption, dispatch]);

  const dataSource = {
    getRows: async params => {
      const { filterModel } = params;

      const updatedFilterModel = { ...filterModel };

      const columnFilters = Object.keys(filterModel).filter(key => key !== 'subTabsFilter' && key !== 'sortFilter');
      const columnFilterValues = Object.entries(filterModel).filter(([key]) => key !== 'subTabsFilter' && key !== 'sortFilter');
      // Efficiently compare prevColumnFilters and columnFilters arrays

      const arraysAreSame =
        prevColumnFilters.length === columnFilters.length &&
        prevColumnFilters.every((filter, index) => {
          return filter[0] === columnFilterValues[index][0] && filter[1].filter === columnFilterValues[index][1].filter;
        });

      prevColumnFilters = columnFilterValues;

      const subTabfilter = updatedFilterModel?.subTabsFilter?.filter ? JSON.parse(updatedFilterModel?.subTabsFilter?.filter || '{}') : {};
      const sortFilter = updatedFilterModel?.sortFilter?.filter ? JSON.parse(updatedFilterModel?.sortFilter?.filter || '{}') : {};
      let formattedFilters = {},
        globalSearchData = subTabfilter?.globalSearchData || sortFilter?.data || [];

      if (subTabfilter?.filters) {
        Object.keys(subTabfilter?.filters).forEach(key => {
          const values = subTabfilter?.filters?.[key]?.values?.map(item => item?.value);
          formattedFilters[key] = values;
        });
      }

      if ((Object.keys(sortFilter).length > 0 && sortFilter?.type !== 'noChange') || !arraysAreSame) {
        return handleLocalSortAndFilter(params, globalSearchData, sortFilter, columnFilters, filterModel);
      }

      const models = selectedTabId && selectedTabId !== 'all' ? [selectedTabId] : [];

      const payload = {
        keyword: searchValue,
        size: PAGE_LIMIT,
        from: params.endRow - PAGE_LIMIT,
        models,
        filters: formattedFilters
      };

      if (!searchValue && Object.keys(filterModel).length === 0) {
        params.successCallback([], 0);
        return;
      }

      try {
        dispatch(updateGlobalSearchData({ key: 'loading', data: true }));
        gridApi.current.showLoadingOverlay();
        const { status, data } = await globalSearchApi(payload);
        if (status === SUCCESS_STATUS_CODE) {
          const oldData = structuredClone(prevGlobalSearchData);
          const index = prevGlobalSearchData.findIndex(item => item?.model === selectedTabId);
          prevGlobalSearchData = data;

          if (index !== -1) {
            prevGlobalSearchData[index].data.hits.hits = [...oldData[index].data.hits.hits, ...data[index].data.hits.hits];
          }
          const { count } = handleLocalSortAndFilter(params, data, sortFilter, columnFilters, filterModel);
          dispatch(updateGlobalSearchData({ key: 'data', data }));
          dispatch(updateGlobalSearchData({ key: 'totalCount', data: count }));
        } else {
          params.failCallback();
        }
      } catch (error) {
        params.failCallback();
      } finally {
        gridApi.current.hideOverlay();
        dispatch(updateGlobalSearchData({ key: 'loading', data: false }));
      }
    }
  };

  const onSelectionChanged = () => {
    // const selectedRows = gridApi.current.getSelectedRows();
    // console.log('Selected Rows:', selectedRows);
  };

  const setSelectAllRows = isAllRowsSelected => {
    gridApi.current.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };

  const handleChange = event => {
    const checked = event.currentTarget.checked;
    setSelectAllRows(checked);
  };

  const headerCheckbox = () => {
    return <Checkbox classes={{ root: 'custom-checkbox' }} size='small' onChange={e => handleChange(e)} />;
  };

  const customNameMappings = {
    check_box: 'Checkboxes',
    contact_id: 'Avatar',
    project_id: 'Avatar',
    company_id: 'Avatar'
  };

  return (
    <Box width='100%'>
      {selectedContactId && (
        <ViewContactDrawer
          navigateToAllContacts={false}
          isDrawerOpen={selectedContactId !== null}
          setIsDrawerOpen={() => dispatch(updateGlobalSearchData({ key: 'selectedContactId', data: null }))}
          id={selectedContactId}
          baseRoute={pathname}
        />
      )}
      {selectedCompanyId && (
        <ViewCompanyDrawer
          baseRoute={pathname}
          setIsEdited={() => {}}
          setIsUpdateCompany={() => {}}
          isUpdateCompany={false}
          options={options}
          isDrawerOpen={selectedCompanyId !== null}
          setIsDrawerOpen={() => dispatch(updateGlobalSearchData({ key: 'selectedCompanyId', data: null }))}
          id={selectedCompanyId}
        />
      )}
      <Box display={'flex'} borderRadius={1} minHeight={'70vh'} flexDirection={'column'} bgcolor={'Background'}>
        {selectedTabId === 'all' && (
          <Typography fontWeight={'bolder'} fontSize={14} color={'black'}>
            {selectedTab?.label}
          </Typography>
        )}
        <Box className='ag-theme-alpine header-column-filter' style={{ height: '600px', width: '100%' }}>
          <AgGridReact
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              width: 100,
              // resizable: true,
              // sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            frameworkComponents={{
              HeaderCheckbox: headerCheckbox
            }}
            columnDefs={defaultColumns}
            datasource={dataSource}
            rowModelType='infinite'
            rowSelection='multiple'
            tooltipShowDelay={0}
            cacheBlockSize={PAGE_LIMIT}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            suppressRowClickSelection={true}
            getRowNodeId={data => data.id}
            onSelectionChanged={onSelectionChanged}
            rowHeight={100}
            disableStaticMarkup
          />
          {columnApi.current && (
            <ColumnFilter
              classNameValue={'columns-icon mt-2'}
              columnApi={columnApi.current}
              defaultColumns={defaultColumns}
              hiddenColumns={{ extra_column: true }}
              customNameMappings={customNameMappings}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
