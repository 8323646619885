import { Editor } from '@tinymce/tinymce-react';
import { PropTypes } from 'prop-types';
import React from 'react';

export default function MessageTemplatesRT(props) {
  const { onChange = () => {}, onFocus = () => {}, inputRef, value, height = 500 } = props;

  const editorRef = inputRef;

  return (
    <>
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          height: height,
          menubar: false,
          plugins: ['advlist', 'anchor', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'searchreplace', 'visualblocks', 'insertdatetime', 'media', 'table', 'wordcount'],
          branding: false,
          toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' + 'alignleft aligncenter alignright alignjustify | ' + 'bullist numlist outdent indent | code table help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
        onEditorChange={newValue => {
          onChange(newValue);
        }}
        value={value}
        onFocus={onFocus}
      />
    </>
  );
}

MessageTemplatesRT.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string || PropTypes.object,
  inputRef: PropTypes.object,
  height: PropTypes.number
};
