//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

//import BoardDetails from "../../../AddContact/Components/BoardDetails";

export default function BoardDetailsEdit() {
  //const {defaultValues, register,watch,setValue,unregister,control} = props

  return (
    // <BoardDetails
    //   register={register}
    //   unregister={unregister}
    //   watch={watch}
    //   setValue={setValue}
    //   currentValues={defaultValues}
    //   control={control}
    // />
    <></>
  );
}

BoardDetailsEdit.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  dynamicKey: PropTypes.string,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  defaultValues: PropTypes.object
};
