//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState, useCallback, useRef } from 'react';
import * as yup from 'yup';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { Button, InputLabel, Tooltip } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import SearchDocuments from './Components/SearchDocuments';
import { /* formSchema, */ cleanupSearchData } from '../CreateSearch/utils';
import { updateContactDetails, getBillingContactDetails } from '../CreateSearch/utils';
import MessageLoader from '../common/MessageLoader/MessageLoader';
import EditAction from './Components/Header/EditAction';
import SFPA from './Components/SFPA';
import PositionProfile from './Components/PositionProfile';
import CsLocInfo from './Components/CSLocInfo';
import SearchInformationContainer from './Components/SearchInformation';
import BillingInfoContainer from './Components/BillingInfo';
import CandidatesList from '../../Containers/Searches/AllCandidates/CandidatesList';
import './index.scss';
import KGPTeamInfoContainer from './Components/KGPTeamInfo';
import { checkForError, getDefaultRetainerFields, getSearchField, getSingleFieldDataOfSearch, validateByProductFeeField, validateCalculationAmount, validatePercentageFields } from './utils';
import ConfirmationPopup from '../ConfirmationPopup';
import { getUserSelector } from '../../selectors';
import {
  API,
  GET,
  POST,
  PUT,
  ERROR,
  SUCCESS,
  WARNING,
  EMAIL_REGEX,
  INLINE_EDIT_RECORD_VALIDATION_MESSAGE,
  LINKEDIN_SCRAPER,
  BILLING_VALIDATIONS,
  INVOICE_VALIDATION,
  PATCH,
  FEE_TYPES,
  PENDING_ACKNOWLEDGMENT
} from '../../services/constantService';
import { fetchCurrencyDetails, fetchUserList } from '../../../src/actions';
import {
  acquireLockApi,
  hlDataApi,
  notesAttachmentDocumentApi,
  picklistsColorsDataApi,
  releaseLockApi,
  renewLockApi,
  searchDataApi,
  searchDocumentApi,
  dashboardDataApi
} from '../../services/ApiService';
import { unableMessage, notFoundMessage, successMessage, validateMessage, VALIDATION_MESSAGE, requireMessage } from '../../services/MessageService';
// import { UPDATE_SEARCHDATA } from '../../types';
import { searchActions } from '../../store/searchSlice';
import Loader from '../common/Loader';
import { RELOAD_ALL_CANDIDATE_GRID, UPDATE_SFPADATA } from '../../types';
import { SPECIAL_CHARACTERS_REPLACE_REGEX, REPLACE_SPACE_REGEX } from '../../services/constantService';
import ProductOneCandidatesList from '../../Containers/Searches/AllCandidates/ProductOneCandidatesList';
import { customFormValidator, customFormValidatorInline, getInvoiceEmail, handelScroll, isEmpty, requireValidMessage } from '../../utils/common';
import TabLayoutWithCollapseAndHeader from '../TabLayoutWithCollapseAndHeader';
import InvoiceInfo from './Components/InvoiceInfo';
import ApproveRejectAction from './Components/BillingInfo/ApproveRejectAction';
import checkForChanges from '../../utils/checkForChanges';
import { useSnackbar } from 'notistack';

const ViewSearch = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let { id, currentTabs, currentSfpaTab } = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  const userData = useSelector(getUserSelector);
  //const SFPA_NAV_ITEMS = ["Target", "Skills", "Attributes", "Partner Brief", "Client App Acces", "Legacy Skills", "Legacy Attributes"];
  const SFPA_NAV_ITEMS = ['Target', 'Skills', 'Attributes', 'Partner Brief', 'Client App Acces'];

  const sfpaNavItems = SFPA_NAV_ITEMS.map(item => {
    item = item.replace(SPECIAL_CHARACTERS_REPLACE_REGEX, 'and');

    return item.toLowerCase().replace(REPLACE_SPACE_REGEX, '-');
  });

  let currentTabIndex;
  let currentSfpaIndex;
  // const NAV_ITEMS = ["SEARCH OVERVIEW", "Search Information", "Billing Info", "KGP Team Info", "Search Documents", "FRAME", "SFPA", "Position Profile", "Location Info", "FIND/FIT", "Active Candidates", "All Candidates", 'Product One', 'ClientSuite/Report', 'My LinkedIn Candidates', "FLOURISH"];
  const [NAV_ITEMS] = useState([
    {
      label: 'Search Overview',
      position: 0,
      items: [
        {
          label: 'Client Info',
          position: 1
        },
        {
          label: 'Billing Info',
          position: 2
        },
        {
          label: 'Invoice Info',
          position: 3
        },
        {
          label: 'KGP Team Info',
          position: 4
        },
        {
          label: 'Search Documents',
          position: 5
        }
      ]
    },
    {
      label: 'Frame',
      position: 6,
      items: [
        {
          label: 'SFPA',
          position: 7
        },
        {
          label: 'Position Profile',
          position: 8
        },
        {
          label: 'Location Info',
          position: 9
        }
      ]
    },
    {
      label: 'Find/Fit',
      position: 10,
      items: [
        {
          label: 'Active Candidates',
          position: 11
        },
        {
          label: 'All Candidates',
          position: 12
        },
        {
          label: 'Product One',
          position: 13
        },
        {
          label: 'ClientSuite/Report',
          position: 14
        },
        {
          label: 'My LinkedIn Candidates',
          position: 15
        }
      ]
    }
  ]);
  const getTabRoute = tabNumber => {
    let tabRoute;
    NAV_ITEMS.forEach(mainItem => {
      mainItem.items.forEach(subItem => {
        if (subItem.position === tabNumber) {
          tabRoute = subItem.label === 'ClientSuite/Report' ? 'client-suite-report' : subItem.label.toLowerCase().replace(REPLACE_SPACE_REGEX, '-');
          subItem.selected = true;
        } else {
          subItem.selected = false;
        }
      });
    });
    return tabRoute;
  };

  const newNavItems = [].concat
    .apply(
      [],
      NAV_ITEMS.map(ele => [{ label: ele.label, position: ele.position }, ...ele.items])
    )
    .map(item => {
      if (item.label === 'ClientSuite/Report') {
        return { ...item, label: 'client-suite-report' };
      }
      return { ...item, label: item.label.toLowerCase().replace(REPLACE_SPACE_REGEX, '-') };
    });
  if (currentTabs === 'sfpa') {
    currentSfpaIndex = sfpaNavItems.findIndex(item => item === currentSfpaTab);
    currentSfpaIndex = currentSfpaIndex === -1 ? navigate(`/searches/${id}/${newNavItems[7].label}/target`) : currentSfpaIndex;
    getTabRoute(7);
  } else {
    currentTabIndex = newNavItems.find(item => item.label === currentTabs)?.position;
    if (currentTabIndex) {
      getTabRoute(currentTabIndex);
    }
    currentTabIndex = !currentTabIndex ? navigate(`/searches/${id}/${newNavItems[11].label}`) : currentTabIndex;
  }

  const isItFromBD = useCallback(() => {
    return location.state && location.state.isFromBD;
  }, [location]);
  const defaultTab = () => {
    // if (window.location.href.includes('all-candidates')) return 11
    // else if (window.location.href.includes('client-suite-report')) return 12
    return 11;
  };

  const [isEditing, setIsEditing] = useState(isItFromBD() || false);
  const [statusMessage, setStatusMessage] = useState('');
  const [search, setSearch] = useState({});
  const [currentValues, setCurrentValues] = useState({});
  const { register, setValue, watch, control, getValues, handleSubmit, reset, formState, unregister } = useForm();
  const { isDirty } = formState;
  const [editingField, setEditingField] = useState('');
  const [showConfirmBack, setShowConfirmBack] = useState(false);
  const [isSFPADirty, setSFPADirty] = useState(false);
  const jobTitle = watch('job_title');
  const confidential = watch('is_confidential');
  const [colorData, setColorData] = useState(null);
  const [searchId, setSearchId] = useState('');
  const users = useSelector(state => state.rootReducer.users);
  const searchData = useSelector(state => state.searchReducer.searchData);
  const dispatch = useDispatch();
  const [readOnly, setReadOnly] = useState(true);
  const [options, setOptions] = useState([]);
  const [initialLoader, setInitialLoader] = useState(true);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [renderActionFlag, setRenderActionFlag] = useState(false);
  const [optionValid, setOptionsValid] = useState([]);
  const currencyCodeDetails = useSelector(state => (state.commonReducer[watch('currency')] ? state.commonReducer[watch('currency')] : {}));
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [approveRejectData, setApproveRejectData] = useState(null);
  const [thresholdPopup, setThresholdPopup] = useState(false);
  const [thresholdValue, setThresholdValue] = useState(false);
  const [skipCheckThresholdValue, setSkipCheckThresholdValue] = useState(false);
  const [handleSaveArgs, setHandleSaveArgs] = useState({});
  const [isTabChange, setIsTabChange] = useState(false);
  const [newTab, setNewTab] = useState(null);
  const readOnlyHandler = useCallback(() => {
    setReadOnly(false);
  }, [readOnly]);
  const locker = async (saveHandler, isDirty) => {
    let { status, data } = await acquireLockApi(id);
    if (status && status === 200 && data.message) {
      enqueueSnackbar(data.message, {
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: key => (
          <>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                readOnlyHandler();
                closeSnackbar(key);
                await releaseLockApi(id);
                locker();
              }}
            >
              Force Unlock
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                closeSnackbar(key);
              }}
            >
              CLOSE
            </Button>
          </>
        )
      });
    } else if (data.id) {
      readOnlyHandler();
      typeof saveHandler === 'function' && saveHandler(undefined, isDirty);
    }
  };

  useEffect(() => {
    if (currentSfpaTab) {
      locker();
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const acquire = async () => {
    let { status, data } = await acquireLockApi(id, { detachedLockBroken: true });
    if (status && status === 200) {
      return true;
    } else {
      enqueueSnackbar(data.message, {
        persist: true,
        variant: ERROR,
        // eslint-disable-next-line react/display-name
        action: key => (
          <Button
            size='small'
            variant='text'
            color='inherit'
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            Dismiss
          </Button>
        )
      });
    }
  };

  let updateSFPA;
  const setUpdateSFPA = update => {
    updateSFPA = update;
  };
  useEffect(() => {
    if (!users) {
      dispatch(fetchUserList('users'));
    } else {
      setOptions(users);
    }
  }, [users, dispatch]);

  const tabChange = async (tabNumber, newNumber) => {
    try {
      dispatch({ type: 'resetLinkedinScraperEvents' });
      if (newNumber === tabNumber) return;
      const tabData = getValues();
      if (tabNumber === 1 && isEditing) {
        const isValid = await SearchSchema.isValid(tabData);
        if (!isValid) {
          newNumber = tabNumber;
          let requiredField = [
            { fieldName: 'company', label: 'Company Name', type: Object },
            { fieldName: 'job_title', label: 'Search Job Title', type: String },
            { fieldName: 'stage', label: 'Stage', type: String },
            { fieldName: 'type', label: 'Search Type', type: String },
            { fieldName: 'industries', label: 'Industry', type: [] },
            { fieldName: 'job_functions', label: 'Job Function', type: [] },
            { fieldName: 'country', label: 'Location', type: String },
            { fieldName: 'brand', label: 'Service Offering', type: String },
            { fieldName: 'basic_partners', label: 'Partners', type: [] },
            { fieldName: 'probability', label: 'Probability', type: String },
            { fieldName: 'projected_start_date', label: 'Projected Start Date', type: String }
          ];
          let dirtyField = customFormValidator(tabData, requiredField);
          if (dirtyField) {
            const message = requireValidMessage(dirtyField);
            enqueueSnackbar(message, { variant: ERROR });
            return false;
          }
        }
      }
      if (tabNumber === 5 && isEditing) {
        let formData = getValues();
        // formData.search_documents.forEach((item) => {
        //   if ((item.doctype_code === undefined || item.doctype_code === '' || item.doctype_code === null) && (item.files === undefined || item.files === null || item.files === [])) {
        //     formData.search_documents = [];
        //   }
        // })
        if (formData && formData.search_documents && optionValid?.length > 0) {
          formData.search_documents = formData.search_documents?.filter(item => {
            if (
              (item.doc_desc === undefined || item.doc_desc === '' || item.doc_desc === null) &&
              (item.doctype_code === undefined || item.doctype_code === '' || item.doctype_code === null) &&
              !item.id &&
              (item.files === undefined || item.files === null || Array.isArray(item.files))
            ) {
              return false;
            }
            return true;
          });
          formData.search_documents = [...formData.search_documents].map(ele => ({
            ...ele,
            required:
              (ele.doctype_code ? ele.doctype_code === '' : ele[0] ? ele[0].doctype_code === '' : ele.doctype_code === null) &&
              ((ele && !ele.files && !ele.file_id) || (ele && ele.files ? !ele?.files.name : ele.file_name === ''))
                ? 'Document type and Files are required'
                : (ele.doctype_code ? ele.doctype_code === '' : ele[0] ? ele[0].doctype_code === '' : ele.doctype_code === null) && (ele && ele.files ? ele.files.name : ele.file_name !== '')
                  ? 'Document type is required'
                  : (ele.doctype_code ? ele.doctype_code !== '' : ele[0] ? ele[0].doctype_code === '' : ele.doctype_code === null) &&
                      (ele && ele.files === undefined ? (ele.file_name ? ele.file_name === '' : !ele.file_name) : ele.files && ele.files.name ? ele.files.name === '' : !ele.files.name)
                    ? 'File is required'
                    : ''
          }));
          formData.search_documents.forEach(item => {
            if (item.required !== '') {
              enqueueSnackbar(item.required, { variant: ERROR });
            }
          });
          const inValid = formData && formData.search_documents.some(ele => ele.required);
          if (inValid) return false;
        }
        if (formData && formData.search_documents && optionValid?.length > 0) {
          formData &&
            formData.search_documents.forEach(ele => {
              if (ele.allowed_extension !== 'all') {
                const findCat = optionValid.find(data => data.doctype_code === ele.doctype_code);
                let fileName = ele.files ? ele.files.name?.split('.').pop() : ele.file_name?.split('.').pop();
                const isValid = findCat?.allowed_extension
                  ? findCat.allowed_extension
                      ?.split(',')
                      .map(item => item.trim())
                      .includes(fileName)
                  : '';
                ele['errorMessage'] = isValid ? '' : `${ele.doc_desc} does not support  ${fileName} type`;
              }
            });
          const searchDoc = formData.search_documents?.filter(item => item.errorMessage);
          searchDoc.forEach(item => {
            if (item.errorMessage !== '') {
              enqueueSnackbar(item.errorMessage, { variant: ERROR });
            }
          });
          if (searchDoc?.length > 0) {
            return false;
          }
        }
      }
      if (tabNumber === 2 && isEditing) {
        let formData = getValues();
        const isValid = await BillingSchema.isValid(tabData);
        if (!isValid) {
          let requiredField = [
            { fieldName: 'currency', label: 'Currency Code', type: String },
            { fieldName: 'markets', label: 'Geography', type: String },
            { fieldName: 'billing_region', label: 'Billing Region', type: String }
          ];
          let dirtyField = customFormValidator(tabData, requiredField);
          if (dirtyField) {
            const message = requireValidMessage(dirtyField);
            enqueueSnackbar(message, { variant: ERROR });
            return false;
          }
        }
        if (tabData?.email_invoice_to) {
          if (!getInvoiceEmail(tabData, enqueueSnackbar)) return false;
        }
        if (
          formData &&
          search.billing_status !== 'PND_RHEAD' &&
          search.brand === 'Executive' &&
          formData.estimated_revenue < formData.markets?.threshold &&
          formData.estimated_revenue !== 0 &&
          !skipCheckThresholdValue &&
          search?.estimated_revenue !== formData?.estimated_revenue
        ) {
          setThresholdPopup(true);
          setIsTabChange(true);
          setNewTab(newNumber);
          return false;
        }
      }
      let itemRoute = getTabRoute(newNumber);

      if (isEditing && isDirty && tabNumber <= 5) {
        if (tabNumber === 3) {
          navigate(`/searches/${id}/${itemRoute}`);
          return true;
        }
        navigate(`/searches/${id}/${itemRoute}`);
        tabData.search_documents &&
          tabData.search_documents.forEach(item => {
            if ((item.doctype_code === undefined || item.doctype_code === '' || item.doctype_code === null) && (item.files === undefined || item.files === null || item.files === [])) {
              tabData.search_documents = [];
            }
          });
        return editSearch(tabData, true, tabNumber === 5);
      }
      if (newNumber === 7) {
        locker(tabNumber);
      }
      if (tabNumber === 7 && !readOnly) {
        const data = acquire(id);
        if (data) {
          if (isSFPADirty) {
            setUpdateLoader(true);
            await updateSFPA(tabNumber, true);
            setUpdateLoader(false);
          }
          if (isEditing) {
            await reloadData();
          }
        }
        await releaseLockApi(id);
      }

      if (tabNumber === 8 || tabNumber === 9) {
        if (isSFPADirty) {
          await updateSFPA(tabNumber, true);
        }
        if (isEditing) {
          await reloadData();
        }
      }
      setCurrentValues(prevState => ({ ...prevState, ...currentValues }));
      reset({ ...currentValues, ...tabData });
      setEditingField('');
      if (newNumber === 12) {
        navigate(`/searches/${id}/all-candidates`);
      } else if (newNumber === 13) {
        navigate(`/searches/${id}/product-one`);
      } else if (newNumber === 14) {
        navigate(`/searches/${id}/client-suite-report`);
      } else if (newNumber === 15) {
        navigate(`/searches/${id}/my-linkedin-candidates`);
      } else {
        navigate(`/searches/${id}/${itemRoute}`);
      }
    } catch (e) {
      console.log('Error found in tabChange::', e);
    }
  };

  const prepareDefaultValues = useCallback(
    (data, formData) => {
      // data.billing_country = data.country
      data.currency = data.currency ? data.currency : 'USD';
      data.contact_name = data.billing_contact || null;
      data.mobile_phone = data.billing_contact ? data.billing_contact.mobile_phone : null;
      data.work_email = data.billing_contact ? data.billing_contact.work_email : '';
      data.loeDocuments = data.loe_documents;
      data.next_status_call_date = data.next_status_call;
      data.next_status_call_time = data.next_status_call;
      data.actual_percentage_bases = data.actual_percentage_bases?.length ? data.actual_percentage_bases : [{}];
      data.estimated_percentage_bases = data.estimated_percentage_bases?.length ? data.estimated_percentage_bases : [{}];
      data.indirect_fees_searches = data.indirect_fees_searches?.length ? data.indirect_fees_searches : [{}];
      data.old_actual_revenue = formData !== undefined ? formData.old_actual_revenue : null;
      data.basic_partners = data.partners ? data.partners.map(partner => partner.user)?.filter(user => user) : [];
      if (data.industries) {
        data.industries = data.industries.map(item => {
          return { name: item };
        });
      }
      if (Array.isArray(data.vc_pe)) {
        data.vc_pe = data.vc_pe.map(item => {
          return { name: item };
        });
      } else {
        data.vc_pe = [];
      }
      data.stage = isItFromBD() ? 'Pending Acknowledgement' : data.stage;
      if (!data.client_team || data.client_team?.length === 0) {
        data.client_team = [{}, {}, {}];
      } else {
        data.client_team = data.client_team.sort((a, b) => a.position - b.position).map(item => ({ ...item, ...item.contact, client_id: item.id }));
        let isHiringManagerIndex = data.client_team.findIndex(ele => ele.is_hiring_manager);
        if (isHiringManagerIndex !== -1) {
          data.client_team = data.client_team.map((ele, i) => {
            return i === isHiringManagerIndex ? { ...ele } : { ...ele, is_hiring_manager: false };
          });
        }
      }
      if (data?.retainers?.length) {
        let retainersArray = [...data.retainers];
        retainersArray = retainersArray.sort((a, b) => a.position - b.position);
        data.retainers = getDefaultRetainerFields(retainersArray);
        if (retainersArray?.length) {
          const additionalRetainers = [];
          retainersArray.forEach((item, index) => {
            additionalRetainers.push({ ...item, field: `Retainer ${index + 4}` });
          });
          data.retainers.splice(3, 0, ...additionalRetainers);
        }
      } else {
        data.retainers = getDefaultRetainerFields([]);
      }
      if (data?.partners?.length) {
        data.partners = data.partners.sort((a, b) => a.position - b.position);
      }
      if (data?.recruiters?.length) {
        data.recruiters = data.recruiters.sort((a, b) => a.position - b.position);
      }
      if (data?.researchers?.length) {
        data.researchers = data.researchers.sort((a, b) => a.position - b.position);
      }
      if (data?.eas?.length) {
        data.eas = data.eas.sort((a, b) => a.position - b.position);
      }

      if (data?.actual_overridden_revenue) {
        data.is_actual_overridden_revenue = true;
      }

      data.intervals_desc = data.intervals_desc ? data.intervals_desc : data.intervals;
      if (!data.fee_type_desc || data.fee_type_desc === FEE_TYPES.ONE_THIRD) {
        data.fee_type_desc = FEE_TYPES.ONE_THIRD;
        data.fee_percentage = 33.33;
      }
      if (!data.bd_status || data.bd_status === '') {
        data.bd_status = 'Target Identified';
      }
      return data;
    },
    [isItFromBD]
  );

  const validateTabMandatoryFields = async searchResult => {
    const isValidSearchTab = await SearchSchema.isValid(searchResult);
    const isValidBillingInfo = await BillingSchema.isValid(searchResult);
    const isValidKgpInfo = searchResult.partners?.find(ele => ele.user);
    NAV_ITEMS[0].items[0].completed = isValidSearchTab ? true : false;
    NAV_ITEMS[0].items[1].completed = isValidBillingInfo ? true : false;
    NAV_ITEMS[0].items[3].completed = isValidKgpInfo ? true : false;
  };

  const getSearch = useCallback(
    async (id, formData) => {
      try {
        setUpdateLoader(true);
        const { status, data } = await searchDataApi(GET, id);
        if (status === 200 && data !== null) {
          setSearchId(id);
          const searchResult = prepareDefaultValues(data, formData);
          setSearch(searchResult);
          await validateTabMandatoryFields(searchResult);
          // dispatch({ type: UPDATE_SEARCHDATA, payload: { key: 'searchData', data } })
          dispatch(searchActions.updateSearchData(data));
        } else if (status === 200) {
          const message = notFoundMessage('search details');
          enqueueSnackbar(message, { variant: ERROR });
        } else {
          const message = unableMessage('search details', 'fetch');
          enqueueSnackbar(data?.message || message, { variant: ERROR });
        }
        setInitialLoader(false);
        setUpdateLoader(false);
      } catch (e) {
        console.log(e);
      }
    },
    [enqueueSnackbar, prepareDefaultValues]
  );

  const getColors = useCallback(
    async id => {
      setUpdateLoader(true);
      const { status, data } = await picklistsColorsDataApi(GET, id);
      if (status === 200 && data !== null) {
        setSearchId(id);
        setColorData(data);
      } else if (status === 200) {
        const message = notFoundMessage('color details');
        enqueueSnackbar(message, { variant: ERROR });
      } else {
        const message = unableMessage('color details', 'fetch');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setUpdateLoader(false);
    },
    [enqueueSnackbar]
  );

  const setColorDesc = async (event, color, index) => {
    if (event.target.value?.length <= 20) {
      let newArr = [...colorData];
      // colorData.map(() => newArr[index].desc = event.target.value);
      newArr = newArr.map((item, i) => (i === index ? { ...item, desc: event.target.value } : { ...item }));
      setColorData(newArr);
      const data = { data: { searchId: searchId, colorId: color.id, desc: event.target.value } };
      await picklistsColorsDataApi(PUT, id, data);
    }
  };

  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(watch('currency'), dispatch);
      } catch (e) {
        console.log('Error found in currencyHandler::', e);
      }
    };
    if (watch('currency') && Object.keys(currencyCodeDetails)?.length === 0) {
      currencyHandler();
    }
  }, [watch('currency')]);

  const getSearchDocuments = useCallback(
    async id => {
      setUpdateLoader(true);
      const sub_route = `${id}/documents`;
      const { status, data } = await searchDocumentApi(GET, sub_route);
      if (status === 200 && data !== null) {
        setSearch(prevState => ({ ...prevState, searchDocuments: data }));
      } else {
        const message = unableMessage('searchDocuments', 'fetch');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setUpdateLoader(false);
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    if (!searchData) {
      getSearch(id).then(null);
    } else {
      setInitialLoader(false);
      setSearch(searchData);
    }
  }, [id, getSearch, searchData]);

  useEffect(() => {
    getColors(id).then(null);
  }, [id, getColors]);

  useEffect(() => {
    if (search) {
      setCurrentValues(search);
      reset({ ...search });
    }
  }, [search, reset]);

  useEffect(() => {
    if (skipCheckThresholdValue) {
      const { formData, skipEditCancel = false, uploadDocs, index } = handleSaveArgs;
      console.log(formData);
      const tabData = getValues();
      const result = editSearch(tabData, skipEditCancel, uploadDocs, index);
      if (!isTabChange) {
        result && setEditingField('');
        return;
      } else {
        let itemRoute = getTabRoute(newTab);
        navigate(`/searches/${id}/${itemRoute}`);
      }
    }
  }, [skipCheckThresholdValue]);

  const releaseLock = useCallback(() => {
    if ((isEditing || editLockRenewTimer.current) && search.id) {
      releaseLockApi(search.id);
      editLockRenewTimer.current = clearInterval(editLockRenewTimer.current);
    }
    window.removeEventListener('beforeunload', releaseLock, true);
  }, [isEditing, search.id]);

  useEffect(() => {
    return () => {
      releaseLock();
    };
  }, [isEditing]);

  useEffect(() => {
    return () => {
      dispatch(searchActions.resetSearchState());
      dispatch({ type: UPDATE_SFPADATA, payload: { key: 'sfpaData', undefined } });
      dispatch({ type: RELOAD_ALL_CANDIDATE_GRID });
    };
  }, []);

  const processUpload = (status, data) => {
    let fileName = data?.id?.file_name;
    if (status === 200) {
      const message = successMessage(`${fileName}`, 'uploaded');
      enqueueSnackbar(message, { variant: SUCCESS });
    } else {
      const message = unableMessage(`${fileName}`, 'upload');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };

  const uploadDocuments = async (url, documents) => {
    if (Array.isArray(documents)) {
      for (const [i, document] of documents.entries()) {
        if (!document.id) {
          let bodyFormData = new FormData();
          bodyFormData.append('file', document.files);
          bodyFormData.append('doctype_code', document.doctype_code);
          bodyFormData.append('show_in_candidatesuite', document.show_in_candidatesuite);
          url = `${API.searches}/${currentValues.id}/documents`;
          const { status, data } = await notesAttachmentDocumentApi(POST, url, bodyFormData);
          processUpload(status, data);
        }

        if (
          (document.files && document.id) ||
          (currentValues && currentValues?.search_documents[i]?.doctype_code !== document.doctype_code && document.id) ||
          (currentValues && currentValues?.search_documents[i]?.show_in_candidatesuite !== document.show_in_candidatesuite && document.id)
        ) {
          let bodyFormData = new FormData();
          if (document.files) {
            bodyFormData.append('file', document.files);
          }
          bodyFormData.append('doctype_code', document.doctype_code);
          bodyFormData.append('show_in_candidatesuite', document.show_in_candidatesuite);
          url = `${API.searches}/documents`;
          const { status, data } = await notesAttachmentDocumentApi(PUT, url, bodyFormData, document.id);
          if (status === 200) {
            enqueueSnackbar('Updated Search Document Successfully', { variant: SUCCESS });
          } else {
            const message = unableMessage(`${document.doctype_code}`, 'upload');
            enqueueSnackbar(data?.message || message, { variant: ERROR });
          }
        }
      }
    }
  };

  const BillingSchema = yup.object().shape({
    currency: yup.string().required(),
    markets: yup.object().required(),
    billing_region: yup.string().required()
  });

  const SearchSchema = yup.object().shape({
    company: yup.object().required(),
    job_title: yup.string().required(),
    stage: yup.string().required(),
    type: yup.string().required(),
    industries: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string()
        })
      )
      .required(),
    job_functions: yup.array().required(),
    country: yup.string().required(),
    brand: yup.string().required().min(1),
    basic_partners: yup.array().required().min(1),
    probability: yup.string().required(),
    projected_start_date: yup.string().required()
  });

  const getData = async () => {
    try {
      const url = `${API.product_setting}/document_type`;
      const { status, data } = await dashboardDataApi(GET, url);
      if (status === 200) {
        let array = [];
        data.data.rows.map(data => array.push({ doctype_code: data.doctype_code, allowed_extension: data.allow_extensions }));
        setOptionsValid(array);
      }
    } catch (err) {
      console.log('error in Fetch Token::', err);
    }
  };

  const handleThresholdClose = () => {
    setThresholdPopup(false);
  };

  const handleConfirmThresholdPopup = () => {
    setThresholdValue(true);
    setThresholdPopup(false);
    setSkipCheckThresholdValue(true);
  };

  const handleThresholdCancelPopup = () => {
    setThresholdValue(false);
    setThresholdPopup(false);
    setSkipCheckThresholdValue(false);
  };

  const editSearch = async (formData, skipEditCancel = false, uploadDocs, index) => {
    try {
      let isError = false;
      setHandleSaveArgs({ formData, skipEditCancel, uploadDocs, index });
      if (currentTabIndex === 1) {
        /* Now its commented as per requirements
          if (formData.stage_desc === "Closed") {
          if (currentValues.collected_percentage != 100) {
            enqueueSnackbar(INVOICE_VALIDATION.COLLECTED_PERCENTAGE, { variant: ERROR })
            return false
          }
        } */
        const isValid = await SearchSchema.isValid(formData);
        if (!isValid) {
          let requiredField = [
            { fieldName: 'company', label: 'Company Name', type: Object },
            { fieldName: 'job_title', label: 'Search Job Title', type: String },
            { fieldName: 'stage', label: 'Stage', type: String },
            { fieldName: 'type', label: 'Search Type', type: String },
            { fieldName: 'industries', label: 'Industry', type: [] },
            { fieldName: 'job_functions', label: 'Job Function', type: [] },
            { fieldName: 'country', label: 'Location', type: String },
            { fieldName: 'brand', label: 'Service Offering', type: String },
            { fieldName: 'basic_partners', label: 'Partners', type: [] },
            { fieldName: 'probability', label: 'Probability', type: String },
            { fieldName: 'projected_start_date', label: 'Projected Start Date', type: String }
          ];
          let dirtyField = customFormValidator(formData, requiredField);
          //setIsShowInfo(false)
          if (dirtyField) {
            const message = requireValidMessage(dirtyField);
            enqueueSnackbar(message, { variant: ERROR });
            return;
          }
        }
      }
      if (currentTabIndex === 2) {
        const isValid = await BillingSchema.isValid(formData);
        if (!isValid) {
          let requiredField = [
            { fieldName: 'currency', label: 'Currency Code', type: String },
            { fieldName: 'markets', label: 'Geography', type: String },
            { fieldName: 'billing_region', label: 'Billing Region', type: String }
          ];
          let dirtyField = customFormValidator(formData, requiredField);
          if (dirtyField) {
            const message = requireValidMessage(dirtyField);
            enqueueSnackbar(message, { variant: ERROR });
            return;
          }
        }
        if (formData?.email_invoice_to) {
          if (!getInvoiceEmail(formData, enqueueSnackbar)) return;
        }
        const isValidIndirectFee = validatePercentageFields(formData);
        if (!isValidIndirectFee) {
          enqueueSnackbar(BILLING_VALIDATIONS.FEE_PERCENTAGE_MAX, { variant: ERROR });
          return;
        }
        const isValidByProductFee = validateByProductFeeField(formData);
        if (!isValidByProductFee) {
          enqueueSnackbar(BILLING_VALIDATIONS.BY_PRODUCT_FEE_MAX, { variant: ERROR });
          return;
        }
        const isCalculationAmount = validateCalculationAmount(formData);
        if (!isCalculationAmount) {
          enqueueSnackbar(BILLING_VALIDATIONS.CALCULATION_AMOUNT_MAX, { variant: ERROR });
          return;
        }

        // if (formData.estimated_revenue > 0 && !formData.market) {
        //   enqueueSnackbar(BILLING_VALIDATIONS.MARKET_VALUE, { variant: ERROR });
        //   return
        // }
        if (formData.estimated_percentage_bases && formData.estimated_percentage_bases?.length) {
          const isValid = formData.estimated_percentage_bases.some(item => item.type === null && item.estimated_amt !== null);
          if (isValid) {
            enqueueSnackbar(BILLING_VALIDATIONS.FEE_TYPE, { variant: ERROR });
            return;
          }
        }
        if (formData.actual_percentage_bases && formData.actual_percentage_bases?.length) {
          const isValid = formData.actual_percentage_bases.some(item => item.type === null && item.actual_amt !== null);
          if (isValid) {
            enqueueSnackbar(BILLING_VALIDATIONS.FEE_TYPE, { variant: ERROR });
            return;
          }
        }
        if (formData.indirect_fees_searches && formData.indirect_fees_searches) {
          const isValid = formData.indirect_fees_searches.some(item => item?.type === null && item?.calculation_type !== null && item?.calculation_type !== '');
          if (isValid) {
            enqueueSnackbar(BILLING_VALIDATIONS.INDIRECT_FEE, { variant: ERROR });
            return;
          }
        }
        if (
          formData &&
          search.billing_status !== 'PND_RHEAD' &&
          search.brand === 'Executive' &&
          formData.estimated_revenue < formData.markets?.threshold &&
          formData.estimated_revenue !== 0 &&
          !skipCheckThresholdValue &&
          search?.estimated_revenue !== formData?.estimated_revenue
        ) {
          setThresholdPopup(true);
          return false;
        }
      }
      if (formData.partners && formData.partners?.length > 0) {
        const fields = {
          origination_credit: 30,
          selling_credit: 20,
          execution_credit: 50
        };
        isError = checkForError(formData.partners, fields);
      }
      if (!isError && formData.recruiters && formData.recruiters?.length > 0) {
        const fields = {
          execution_credit: 100
        };
        isError = checkForError(formData.recruiters, fields);
      }
      if (!isError && formData.researchers && formData.researchers?.length > 0) {
        const fields = {
          execution_credit: 100
        };
        isError = checkForError(formData.researchers, fields);
      }
      if (!isError && formData.eas && formData.eas?.length > 0) {
        const fields = {
          execution_credit: 100
        };
        isError = checkForError(formData.eas, fields);
      }
      /*  septate api used for update invoice info details
        if (formData && formData.invoice_info_searches && formData.invoice_info_searches?.length > 0) {
        const totalValue = formData.invoice_info_searches.reduce((prev, curr) => {
          prev = prev + (curr['retainer'] || 0)
          return prev
        }, 0) || 0;
        let retainerAmtError = currentValues.actual_revenue - currentValues.actual_indirect_total_fee - totalValue
        if (retainerAmtError < 0) {
          const message = validateMessage('valid number in retainer of invoice info table', 'enter')
          enqueueSnackbar(message, { variant: ERROR });
          return false
        }
      }
      if (formData && formData.invoice_info_searches && formData.invoice_info_searches?.length > 0) {
        let collectedAmtError = false
        formData.invoice_info_searches.forEach(ele => {
          if (ele.collected_amt > ele.invoice_amt) {
            collectedAmtError = true
          }
        })
        if (collectedAmtError) {
          const message = validateMessage('valid number in collected amount of invoice info table', 'enter')
          enqueueSnackbar(message, { variant: ERROR });
          return false
        }
      } */
      if (isError) {
        const message = validateMessage('valid number', 'enter');
        enqueueSnackbar(message, { variant: ERROR });
      } else {
        if (formData?.job_title?.includes('"')) {
          enqueueSnackbar(VALIDATION_MESSAGE.special_character, { variant: ERROR });
        } else {
          /* If needs Notes validation for checking empty for any one of the field (title or description)
          let validateNotes = [];
          validateNotes = formData.notes ? (formData.notes.map(item => (!item.description || item.description.trim() === '') && (!item.title || item.title.trim() === '') ? false : true)) : []
          if (validateNotes.includes(false)) {
            const message = CONSTANT_MESSAGE
            enqueueSnackbar(message, { variant: ERROR })
            return false;
          } */
          const validateClientEmails = formData.client_team ? formData.client_team.map(item => (item.work_email && !item.work_email.match(EMAIL_REGEX) ? false : true)) : [];
          const validateBillingWorkEmail = formData.work_email ? formData.work_email.match(EMAIL_REGEX) : false;
          // if(!formData.currency){
          //   const message = requireMessage("currency");
          //   enqueueSnackbar(message, { variant : ERROR})
          //   return false
          // }
          if (validateClientEmails.includes(false) || (formData.work_email && !validateBillingWorkEmail)) {
            const message = validateMessage('valid work email', 'enter');
            enqueueSnackbar(message, { variant: ERROR });
            return false;
          }
          if (formData && formData.search_documents && optionValid?.length > 0) {
            formData.search_documents = formData.search_documents?.filter(item => {
              if (
                (item.doc_desc === undefined || item.doc_desc === '' || item.doc_desc === null) &&
                (item.doctype_code === undefined || item.doctype_code === '' || item.doctype_code === null) &&
                !item.id &&
                (item.files === undefined || item.files === null || Array.isArray(item.files))
              ) {
                return false;
              }
              return true;
            });
            formData.search_documents = [...formData.search_documents].map(ele => ({
              // if(Object.keys(ele)?.length===0 ||   ele.doctype_code === '' || (ele && ele.files ? ele.files?.length === 0 : ele.file_name === '')){
              //   ele['required'] = 'Document type and Files are required';
              // }else{
              //   ele['required'] = '';
              // }
              // ...ele, required: Object.keys(ele)?.length === 0 || ele.doctype_code === '' || (ele && ele.files ? ele.files?.length === 0 : ele.file_name === '' ? ele.file_name === '' : !ele.file_name) ? 'Document type and Files are required'  : ''
              ...ele,
              required:
                (ele.doctype_code ? ele.doctype_code === '' : ele[0] ? ele[0].doctype_code === '' : ele.doctype_code === null) &&
                ((ele && !ele.files && !ele.file_id) || (ele && ele.files ? !ele?.files.name : ele.file_name === ''))
                  ? 'Document type and Files are required'
                  : (ele.doctype_code ? ele.doctype_code === '' : ele[0] ? ele[0].doctype_code === '' : ele.doctype_code === null) && (ele && ele.files ? ele.files.name : ele.file_name !== '')
                    ? 'Document type is required'
                    : (ele.doctype_code ? ele.doctype_code !== '' : ele[0] ? ele[0].doctype_code === '' : ele.doctype_code === null) &&
                        (ele && ele.files === undefined ? (ele.file_name ? ele.file_name === '' : !ele.file_name) : ele.files && ele.files.name ? ele.files.name === '' : !ele.files.name)
                      ? 'File is required'
                      : ''
            }));
            if (formData.search_documents?.length > 0) {
              formData.search_documents.forEach(item => {
                if (item.required !== '') {
                  enqueueSnackbar(item.required, { variant: ERROR });
                }
              });
            }
            const inValid = formData && formData.search_documents.some(ele => ele.required);
            if (inValid) return false;
          }
          if (formData && formData.search_documents && optionValid?.length > 0) {
            //  const filterDoc = formData.search_documents?.filter(item => item && item.files);

            formData &&
              formData.search_documents.forEach(ele => {
                if (ele.allowed_extension !== 'all') {
                  const findCat = optionValid.find(data => data.doctype_code === ele.doctype_code);
                  let fileName = ele.files ? ele.files.name?.split('.').pop() : ele.file_name?.split('.').pop();
                  const isValid = findCat?.allowed_extension
                    ? findCat.allowed_extension
                        ?.split(',')
                        .map(item => item.trim())
                        .includes(fileName)
                    : '';
                  ele['errorMessage'] = isValid ? '' : `${ele.doc_desc} does not support  ${fileName} type`;
                }
              });
            const searchDoc = formData.search_documents?.filter(item => item.errorMessage);
            searchDoc.forEach(item => {
              if (item.errorMessage !== '') {
                enqueueSnackbar(item.errorMessage, { variant: ERROR });
              }
            });
            if (searchDoc?.length > 0) {
              return false;
            }
          }

          let searchDatas = { ...currentValues, ...formData };
          if (search.stage === 'Closed' && searchDatas.stage === 'Closed') {
            searchDatas.closeFlag = true;
          }
          // const valid = uploadDocs || await formSchema.isValid(searchData);
          const isDataequal = checkForChanges(searchData, searchDatas);
          // if (valid) {
          if (!isDataequal) {
            setUpdateLoader(true);
            setStatusMessage('Updating Search');
            let body = cleanupSearchData({ ...searchDatas }, '', currentTabIndex);
            if (skipCheckThresholdValue) {
              setSkipCheckThresholdValue(false);
              if (thresholdValue) {
                body['isBelowThreshold'] = true;
              } else {
                body['isBelowThreshold'] = false;
              }
            }
            if (index === 1) {
              delete body.retainers;
              // delete body.partners
              delete body.recruiters;
              delete body.researchers;
              delete body.eas;
              delete body.notes;
              delete body.skills;
              delete body.attributes;
              // delete body.company
            } else if (index === 2) {
              delete body.client_team;
              delete body.partners;
              delete body.recruiters;
              delete body.researchers;
              delete body.eas;
              delete body.notes;
              delete body.skills;
              delete body.attributes;
              // delete body.company
            } else if (index === 3) {
              body = {
                partners: body.partners,
                recruiters: body.recruiters,
                researchers: body.researchers,
                eas: body.eas
              };
            } else if (index === 4) {
              delete body.client_team;
              delete body.retainers;
              delete body.partners;
              delete body.recruiters;
              delete body.researchers;
              delete body.eas;
              delete body.skills;
              delete body.attributes;
              // delete body.company
            }

            let editingLabel = 'Search';
            let unableLabel = 'search';

            if (editingField === 'client_team') {
              setStatusMessage('Updating Client Contact Details');
              editingLabel = 'Client contact details';
              unableLabel = 'client contact details';
              body = getClientTeamPayload(body);
            }

            const { status, data: response } = await searchDataApi(PUT, currentValues.id, body);
            if (status === 200) {
              resetIsFromBD();
              // const message = successMessage(editingLabel, VALIDATION_MESSAGE.updated_message)
              // enqueueSnackbar(message, { variant: SUCCESS })
              if (response.errorDetails) {
                const message = unableMessage('in HL', 'update data');
                enqueueSnackbar(message, { variant: ERROR });
              }
              setStatusMessage('Updating contacts details');
              await updateContactDetails([...searchDatas.client_team, getBillingContactDetails(searchDatas)], enqueueSnackbar);
              await createInterviewer(userData, searchDatas);
              setStatusMessage('Uploading Search Documents');
              await uploadDocuments(`${API.searches}/${currentValues.id}/documents`, searchDatas.search_documents);
              setStatusMessage('');
              await getSearchDocuments(currentValues.id);
              await getSearch(currentValues.id, formData);
              if (!isTabChange) {
                !skipEditCancel && setIsEditing(false);
              }
              !skipCheckThresholdValue && setEditingField('');
              setUpdateLoader(false);
              //setIsShowInfo(true)
              !skipEditCancel && releaseLock();
              dispatch({ type: UPDATE_SFPADATA, payload: { key: 'sfpaData', undefined } });
              const message = successMessage(editingLabel, VALIDATION_MESSAGE.updated_message);
              enqueueSnackbar(message, { variant: SUCCESS });
              return true;
            } else if (status === 409) {
              const message = unableMessage(unableLabel, 'update');
              setUpdateLoader(false);
              enqueueSnackbar(response.message || message, {
                persist: true,
                variant: ERROR,
                // eslint-disable-next-line react/display-name
                action: key => (
                  <Button
                    size='small'
                    variant='text'
                    color='inherit'
                    onClick={() => {
                      closeSnackbar(key);
                    }}
                  >
                    Dismiss
                  </Button>
                )
              });
            } else {
              setUpdateLoader(false);
              const message = unableMessage(unableLabel, 'update');
              enqueueSnackbar(response.message || message, { variant: ERROR });
            }
          } else {
            !skipEditCancel && setIsEditing(false);
            !skipCheckThresholdValue && setEditingField('');
            return true;
          }

          // }
          // else {
          //   setUpdateLoader(false)
          //   const message = validateMessage("required fields in Search Information Tab", "fill all")
          //   enqueueSnackbar(message, { variant: ERROR })
          // }
          return false;
        }
      }
    } catch (e) {
      console.log('Error found in editSearch::', e);
    }
  };

  const getClientTeamPayload = data => {
    return {
      id: data.id,
      client_team: data.client_team,
      updated_at: data.updated_at
    };
  };

  const resetIsFromBD = () => {
    if (location.state) {
      location.state.isFromBD = false;
    }
  };

  const createInterviewer = async (userData, searchData) => {
    const interviewerTab = document.querySelector('.side-nav-item-collapse.active span')?.innerHTML;
    if (interviewerTab === 'KGP Team Info') {
      const interviewerPayload = {
        userId: userData.id,
        existingSearch: searchData,
        positionId: searchData.position_id,
        workspaceId: searchData.workspace_id,
        candidateId: null
      };
      const sub_route = 'add-interviewers-into-position';
      if (searchData.position_id != null && searchData.workspace_id != null) await hlDataApi(PUT, sub_route, { interviewerPayload });
    }
  };

  const onCancel = () => {
    reset({ ...search });
    setCurrentValues(search);
    releaseLock();
    setIsEditing(false);
  };

  const reloadData = async (forceLoad = false) => {
    try {
      if (!searchData || forceLoad) {
        await getSearch(currentValues.id);
      }
    } catch (e) {
      console.log('Error found in reloadData::', e);
    }
  };

  const handleSearchEdit = async () => {
    try {
      let { status, data } = await acquireLockApi(search.id);
      if (status && status === 200) {
        if (editingField !== '') {
          enqueueSnackbar(INLINE_EDIT_RECORD_VALIDATION_MESSAGE, {
            variant: WARNING,
            // eslint-disable-next-line react/display-name
            action: key => (
              <>
                <Button
                  size='small'
                  variant='text'
                  color='inherit'
                  onClick={() => {
                    setEditingField('');
                    reset({ ...searchData });
                    closeSnackbar(key);
                    setTimeout(() => {
                      setIsEditing(true);
                    }, 500);
                  }}
                >
                  Force Edit
                </Button>
                <Button
                  size='small'
                  variant='text'
                  color='inherit'
                  onClick={async () => {
                    closeSnackbar(key);
                  }}
                >
                  CLOSE
                </Button>
              </>
            )
          });
        } else {
          await reloadData();
          setIsEditing(true);
          window.addEventListener('beforeunload', releaseLock);
          editLockRenewTimer.current = setInterval(renewLock, halfAnHour);
        }
      } else {
        enqueueSnackbar(data.message, { variant: WARNING });
      }
    } catch (e) {
      console.log('Error found in handleSearchEdit::', e);
    }
  };

  let retryCount = 0;
  const halfAnHour = 30 * 60 * 1000;
  const editLockRenewTimer = useRef();

  const renewLock = () => {
    if (document.hidden) {
      if (retryCount > 3) {
        clearInterval(editLockRenewTimer.current);
      }
      retryCount++;
    } else {
      retryCount = 0;
      renewLockApi(search.id);
    }
  };

  const searchStatusApproval = async payload => {
    setUpdateLoader(true);
    const { status, ...rest } = await searchDataApi(PATCH, search.id, payload, 'search-autosave-field');

    if (status === 200) {
      if (location?.state?.isFromApproval) {
        navigate(-1);
      }
      if (rest?.data?.result?.billing_status.includes('REJ')) {
        enqueueSnackbar('Request Rejected', { variant: SUCCESS });
      } else if (rest?.data?.result?.billing_status.includes('APR')) {
        enqueueSnackbar('Request Approved', { variant: SUCCESS });
      } else if (rest?.data?.result?.billing_status.includes('ACK_DONE')) {
        enqueueSnackbar('Acknowledged Successfully', { variant: SUCCESS });
      } else if (rest?.data?.result?.billing_status.includes('PND_ACK')) {
        enqueueSnackbar('Billing Desk Notified Successfully', { variant: SUCCESS });
      }
      await getSearch(currentValues.id);
      setUpdateLoader(false);
      // enqueueSnackbar('Billing status updated successfully', { variant: SUCCESS })
    } else {
      setUpdateLoader(false);
    }
  };

  const submitForApproval = async (payload, confirm) => {
    if (editingField === 'fee_and_admin_info' || (editingField === 'billing_info' && !confirm)) {
      setConfirmPopup(true);
    } else {
      const body = {
        billing_status: search.billing_status,
        stage: PENDING_ACKNOWLEDGMENT,
        estimated_revenue: payload.estimated_revenue,
        markets: search.markets,
        brand: search.brand,
        approval: true,
        action: 'submitForApproval'
      };
      setUpdateLoader(true);
      const { status } = await searchDataApi(PUT, currentValues.id, body);
      if (status === 200) {
        enqueueSnackbar('Billing Desk Notified Successfully', { variant: SUCCESS });
        await getSearch(currentValues.id);
        setUpdateLoader(false);
      } else {
        setUpdateLoader(false);
      }
    }
  };

  const withHeader = (children, hideEditAction = false, hideApproveRejection = false) => {
    return (
      <React.Fragment>
        <EditAction
          handleEdit={handleSearchEdit}
          handleSave={handleSubmit(data => editSearch(data, false, undefined, children.props?.index))}
          handleCancel={onCancel}
          isEditing={isEditing}
          jobNumber={search.job_number}
          jobTitle={jobTitle}
          companyDetails={search.company}
          company={search.company && search.company.name}
          confidential={confidential}
          renderActionFlag={renderActionFlag}
          hideEditAction={hideEditAction}
          search={search}
          index={children.props?.index}
          setInitialLoader={setInitialLoader}
        />
        {hideApproveRejection && !isEditing && (
          <ApproveRejectAction
            handleApproval={searchStatusApproval}
            handleSave={handleSubmit(data => {
              submitForApproval(data);
              setApproveRejectData(data);
            })}
            isEditing={isEditing}
            location={location}
            billingStatus={search.billing_status}
            search={search}
            enqueueSnackbar={enqueueSnackbar}
          />
        )}
        {children}
      </React.Fragment>
    );
  };

  // const handleMouseEnter = (field) => {
  //   setHoveredField(field)
  // }

  // const handleMouseLeave = () => {
  //   setHoveredField('')
  // }

  const updateSearch = async formData => {
    // eslint-disable-next-line no-debugger
    try {
      setUpdateLoader(true);
      const { status, data } = await searchDataApi(PUT, currentValues.id, formData);
      if (status === 200) {
        const message = successMessage('Search', VALIDATION_MESSAGE.updated_message);
        enqueueSnackbar(message, { variant: SUCCESS });
        if (data.errorDetails) {
          const message = unableMessage('in HL', 'update data');
          enqueueSnackbar(message, { variant: ERROR });
        }
        const formValues = getValues();
        let newSearch = { ...search };
        newSearch.partners = formValues.partners;
        newSearch.eas = formValues.eas;
        newSearch.recruiters = formValues.recruiters;
        newSearch.researchers = formValues.researchers;
        await createInterviewer(userData, newSearch);
        await getSearch(currentValues.id);
      } else if (status === 409) {
        const message = unableMessage('search', VALIDATION_MESSAGE.updated_message);
        enqueueSnackbar(data?.message || message, {
          persist: true,
          variant: ERROR,
          // eslint-disable-next-line react/display-name
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              Dismiss
            </Button>
          )
        });
      } else {
        const message = unableMessage('search', 'update');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setUpdateLoader(false);
    } catch (e) {
      console.log('Error found in updateSearch::', e);
    }
  };

  const prepareInvoiceInfoPayload = (formData, index, obj) => {
    let invoiceObj = formData?.invoice_info_searches[index];
    if (!obj) {
      invoiceObj.billed_date = invoiceObj.billed_date || null;
      invoiceObj.due_by_date = invoiceObj.due_by_date || null;
      invoiceObj.collected_date = invoiceObj.collected_date || null;
    }

    return {
      id: currentValues.id,
      total_invoice_amt: formData?.total_invoice_amt || 0,
      total_collected_amt: formData?.total_collected_amt || 0,
      collected_percentage: formData?.collected_percentage || 0,
      invoice_info_obj: obj ? obj : invoiceObj,
      total_invoice_expense: formData?.total_invoice_expense || 0,
      updated_at: currentValues.updated_at
    };
  };
  const updateSearchInvoiceInfo = async (index, message, updateType, deletedInvoice) => {
    try {
      let formData = getValues();
      formData = prepareInvoiceInfoPayload(formData, index, deletedInvoice);
      formData.updateType = updateType;
      setUpdateLoader(true);
      setStatusMessage(message);
      const { status, data: response } = await searchDataApi(PUT, currentValues.id, formData, 'invoice-info');
      if (status === 200) {
        const message = successMessage('Record', updateType + 'd');
        enqueueSnackbar(response.message || message, { variant: SUCCESS });
        await getSearch(currentValues.id);
        dispatch({ type: UPDATE_SFPADATA, payload: { key: 'sfpaData', undefined } });
        return true;
      } else if (status === 409) {
        const message = unableMessage('record', updateType);
        setUpdateLoader(false);
        setStatusMessage('');
        enqueueSnackbar(response.message || message, {
          persist: true,
          variant: ERROR,
          // eslint-disable-next-line react/display-name
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              Dismiss
            </Button>
          )
        });
      } else {
        setUpdateLoader(false);
        setStatusMessage('');

        const message = unableMessage('record', updateType);
        enqueueSnackbar(message, { variant: ERROR });
      }
      return false;
    } catch (err) {
      console.log('Error found in updateSearchInvoiceInfo::', err);
    }
  };

  const updateField = async (field, required, listValues) => {
    let formData = listValues ? { [field]: listValues } : getValues();
    if (formData.estimated_revenue > 0 && !formData.markets) {
      enqueueSnackbar(BILLING_VALIDATIONS.MARKET_VALUE, { variant: ERROR });
      return;
    }
    if (field === 'client_team') {
      if (formData.client_team?.length > 0) {
        if (!isEmpty(formData.client_team[0]) || !isEmpty(formData.client_team[1]) || !isEmpty(formData.client_team[2])) {
          formData.client_team = formData.client_team?.filter(el => Object.keys(el)?.length);
        }
      }
    }
    if (field === 'fee_and_admin_info') {
      if (formData.estimated_percentage_bases && formData.estimated_percentage_bases?.length) {
        const isValid = formData.estimated_percentage_bases.some(item => item.type === null && item.estimated_amt !== null);
        if (isValid) {
          enqueueSnackbar(BILLING_VALIDATIONS.FEE_TYPE, { variant: ERROR });
          return;
        }
      }
      if (formData.actual_percentage_bases && formData.actual_percentage_bases?.length) {
        const isValid = formData.actual_percentage_bases.some(item => item.type === null && item.actual_amt !== null);
        if (isValid) {
          enqueueSnackbar(BILLING_VALIDATIONS.FEE_TYPE, { variant: ERROR });
          return;
        }
      }
      if (formData.indirect_fees_searches && formData.indirect_fees_searches) {
        const isValid = formData.indirect_fees_searches.some(item => item?.type === null && item?.calculation_type !== null && item?.calculation_type !== '');
        if (isValid) {
          enqueueSnackbar(BILLING_VALIDATIONS.INDIRECT_FEE, { variant: ERROR });
          return;
        }
      }
    }
    if (field === 'billing_info' || field === 'fee_and_admin_info' || field === 'client_info' || field === 'basic_info' || field === 'additional_info' || field === 'client_team') {
      if (formData?.email_invoice_to) {
        if (!getInvoiceEmail(formData, enqueueSnackbar)) return;
      }
      const result = await editSearch(formData);
      result && setEditingField('');
      return;
    }
    let isError = false;
    if (field === 'partners') {
      const fields = {
        origination_credit: 30,
        selling_credit: 20,
        execution_credit: 50
      };
      isError = checkForError(formData.partners, fields);
    } else if (field === 'recruiters') {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.recruiters, fields);
    } else if (field === 'researchers') {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.researchers, fields);
    } else if (field === 'eas') {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.eas, fields);
    }
    if (isError) {
      const message = validateMessage('valid number', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
    } else {
      if (Object.prototype.hasOwnProperty.call(formData, 'actual_total_comp') && !Object.prototype.hasOwnProperty.call(formData, 'fee_percentage')) {
        formData.fee_percentage = search.fee_percentage;
      } else if (Object.prototype.hasOwnProperty.call(formData, 'estimated_total_comp') && !Object.prototype.hasOwnProperty.call(formData, 'fee_percentage')) {
        formData.fee_percentage = search.fee_percentage;
      } else if (!Object.prototype.hasOwnProperty.call(formData, 'actual_total_comp') && Object.prototype.hasOwnProperty.call(formData, 'fee_percentage')) {
        formData.actual_total_comp = search.actual_total_comp;
      } else if (!Object.prototype.hasOwnProperty.call(formData, 'estimated_total_comp') && Object.prototype.hasOwnProperty.call(formData, 'fee_percentage')) {
        formData.estimated_total_comp = search.estimated_total_comp;
      }

      if (field === 'job_title' && formData['job_title'].includes('"')) {
        enqueueSnackbar(VALIDATION_MESSAGE.special_character_not_supported, { variant: ERROR });
      } else {
        if (field === 'mobile_phone' || field === 'work_email') {
          if (formData.work_email) {
            const isValidEmail = validateWorkEmail(formData.work_email);
            if (!isValidEmail) return;
          }

          if (search.billing_contact && formData[field] !== search.billing_contact[field]) {
            const contact = { ...search.billing_contact, contact: search.billing_contact, [field]: formData[field] };
            setUpdateLoader(true);
            const contact_mobile_number = contact.mobile_phone === '+' ? null : contact.mobile_phone;
            await updateContactDetails([contact], enqueueSnackbar, setUpdateLoader);
            if (field === 'mobile_phone') {
              setSearch(prevState => ({ ...prevState, [field]: contact_mobile_number }));
            } else {
              setSearch(prevState => ({ ...prevState, [field]: formData[field] }));
            }
          }
          setEditingField('');
          return;
        }
        if (formData[field] !== search[field]) {
          field = getSearchField(field);
          const data = getSingleFieldDataOfSearch(field, formData, search);
          const isEmpty = !(Array.isArray(data[field]) ? data[field]?.length : data[field]);
          if (required && isEmpty) {
            let requiredField = [
              { fieldName: 'company_id', label: 'Company Name', type: Object },
              { fieldName: 'job_title', label: 'Search Job Title', type: String },
              { fieldName: 'stage', label: 'Stage', type: String },
              { fieldName: 'type', label: 'Type', type: String },
              { fieldName: 'industries', label: 'Industry', type: [] },
              { fieldName: 'job_functions', label: 'Job Function', type: [] },
              { fieldName: 'country', label: 'Location', type: String },
              { fieldName: 'currency', label: 'Currency Code', type: String }
            ];
            const dirtyField = customFormValidatorInline(field, requiredField);
            if (dirtyField) {
              const message = requireMessage(dirtyField, 'is');
              enqueueSnackbar(message, { variant: ERROR });
              return;
            }
          }
          await updateSearch(data);
        }
        setEditingField('');
      }
    }
  };

  const validateWorkEmail = email => {
    if (email && !email.match(EMAIL_REGEX)) {
      const message = validateMessage('valid work email', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
      return false;
    }
    return true;
  };

  const renderEditIcon = field => {
    return (
      !(editingField === field) && (
        <span>
          <EditIcon
            className='edit-icon'
            onClick={() => {
              setCurrentValues({ ...search });
              reset({ ...search });
              setEditingField(field);
            }}
            fontSize='inherit'
            color='secondary'
          />
        </span>
      )
    );
  };

  const renderActions = (field, required = false) => {
    if (editingField === field) {
      return (
        <>
          <span className='' onClick={() => updateField(field, required)}>
            <DoneIcon className='done-icon' cursor='pointer' fontSize='inherit' color='primary' />
          </span>
          <span
            className=''
            onClick={() => {
              setCurrentValues({ ...search });
              reset({ ...search });
              setEditingField('');
            }}
          >
            <CloseIcon className='close-icon' cursor='pointer' fontSize='inherit' />
          </span>
        </>
      );
    }
    return null;
  };

  const renderLabel = (label, field, required = false, tooltip = false, message) => {
    return (
      <InputLabel
        required={required}
        className={`${field === 'partners' || field === 'recruiters' || field === 'researchers' || field === 'eas' ? 'contact-team-info' : 'contact-view-label'} mr-2 cursor-pointer`}
      >
        {label}
        {tooltip && (
          <span className='m-1'>
            <Tooltip title={message}>
              <InfoIcon style={{ fontSize: '1rem' }} />
            </Tooltip>
          </span>
        )}
        {renderEditIcon(field)}
      </InputLabel>
    );
  };
  const renderFieldLabel = (label, field, required = false, tooltip = false, message, className = '') => {
    return (
      <InputLabel
        required={field === 'bd_status' ? false : required}
        className={`${field === 'partners' || field === 'recruiters' || field === 'researchers' || field === 'eas' ? 'contact-team-info' : 'page-label'} ${className}`}
      >
        {label}
        {tooltip && (
          <span className=''>
            <Tooltip title={message}>
              <InfoIcon style={{ fontSize: '1rem' }} />
            </Tooltip>
          </span>
        )}
        {/* {renderEditIcon(field)} */}
      </InputLabel>
    );
  };
  const renderSectionLabel = (label, field) => {
    return (
      <div className='d-flex'>
        {label}
        {renderEditIcon(field)}
      </div>
    );
  };

  const goBack = async () => {
    const locationState = location?.state;
    setShowConfirmBack(false);
    if (readOnly === false) {
      await releaseLockApi(id);
    }
    if (locationState?.isFromApproval) {
      navigate(location?.previousPath);
    } else {
      navigate('/searches');
    }
  };

  const handleConfirm = async () => {
    try {
      setShowConfirmBack(false);
      if (isSFPADirty) {
        await updateSFPA();
        goBack();
      } else {
        handleSubmit(async data => {
          const saved = await editSearch(data);
          if (saved) {
            goBack();
          }
        })();
      }
    } catch (e) {
      console.log('Error found in handleConfirm::', e);
    }
  };

  const handleBack = () => {
    if ((isEditing && isDirty) || isSFPADirty) {
      setShowConfirmBack(true);
    } else {
      goBack();
    }
  };

  const handleClose = () => {
    setShowConfirmBack(false);
  };

  const headerLayout = () => {
    return (
      <>
        <div className='cursor-pointer' onClick={handleBack}>
          <span className='header-back-icon'>
            <ArrowBackIcon color='primary' />
          </span>
        </div>
        <span className='header-text'>{isEditing ? 'Edit Search' : 'View Search'}</span>
      </>
    );
  };

  const setSfpaValue = value => {
    if (value !== undefined || value !== null) {
      navigate(`/searches/${id}/sfpa/${sfpaNavItems[value]}`);
    }
  };
  const setCurrentTabIndex = () => {
    if (window.location.href.includes('sfpa')) {
      return 7;
    } else if (currentTabIndex === undefined) {
      return 11;
    } else {
      return currentTabIndex;
    }
  };

  const handleConfirmPopup = async () => {
    setEditingField('');
    setCurrentValues({ ...search });
    reset({ ...search });
    setConfirmPopup(false);
    submitForApproval(approveRejectData, true);
  };

  const handleCancelPopup = () => {
    setConfirmPopup(false);
    return;
  };

  return (
    <React.Fragment>
      <Loader show={initialLoader} />
      {!initialLoader && <MessageLoader show={updateLoader} message={statusMessage} />}
      {showConfirmBack && (
        <ConfirmationPopup header={VALIDATION_MESSAGE.unsave_changes_message} onConfirm={handleConfirm} onCancel={goBack} onClose={handleClose} cancelText='Discard' confirmText='Save' />
      )}
      {confirmPopup ? (
        <ConfirmationPopup header={INVOICE_VALIDATION.UNSAVED_DATA} onConfirm={handleConfirmPopup} onCancel={handleCancelPopup} onClose={handleCancelPopup} cancelText='No' confirmText='Yes' />
      ) : null}
      {thresholdPopup && (
        <div className='confirmation-popup-bd-search'>
          <ConfirmationPopup
            header={VALIDATION_MESSAGE.threshold_confirmation}
            onConfirm={handleConfirmThresholdPopup}
            onCancel={handleThresholdCancelPopup}
            onClose={handleThresholdClose}
            cancelText='No'
            confirmText='Save & Continue'
          />
        </div>
      )}
      <Helmet>
        <title>{`${currentValues.job_number || 'Welcome'} - ${currentValues.job_title || 'KGP Galaxy'}`}</title>
      </Helmet>
      <TabLayoutWithCollapseAndHeader
        headerLayout={headerLayout()}
        navItems={[...NAV_ITEMS]}
        tabChange={tabChange}
        isActionsOnTop={true}
        selectedTabIndex={isItFromBD() ? 1 : defaultTab()}
        // grid={'candaidate'}
        currentTab={setCurrentTabIndex}
      >
        <div disabled={true}></div>
        {withHeader(
          <SearchInformationContainer
            editSearch={editSearch}
            register={register}
            setValue={setValue}
            renderLabel={renderLabel}
            renderActions={renderActions}
            editingField={editingField}
            watch={watch}
            reset={reset}
            control={control}
            getValues={getValues}
            isEditing={isEditing}
            setCurrentValues={setCurrentValues}
            currentValues={currentValues}
            isFromViewSearch={true}
            reloadData={reloadData}
            index={1}
            handelScroll={handelScroll}
            setInitialLoader={setInitialLoader}
            setRenderActionFlag={setRenderActionFlag}
            renderActionFlag={renderActionFlag}
            renderSectionLabel={renderSectionLabel}
            renderFieldLabel={renderFieldLabel}
          />
        )}
        {withHeader(
          <BillingInfoContainer
            register={register}
            setValue={setValue}
            watch={watch}
            control={control}
            currencyDetails={currencyCodeDetails}
            getValues={getValues}
            isEditing={isEditing}
            currentValues={currentValues}
            renderFieldLabel={renderFieldLabel}
            renderActions={renderActions}
            editingField={editingField}
            reloadData={reloadData}
            index={2}
            handelScroll={handelScroll}
            setUpdateLoader={setUpdateLoader}
            renderSectionLabel={renderSectionLabel}
            location={location}
          />,
          false,
          true
        )}
        {withHeader(
          <InvoiceInfo
            register={register}
            setValue={setValue}
            watch={watch}
            currencyDetails={currencyCodeDetails}
            currentValues={currentValues}
            renderFieldLabel={renderFieldLabel}
            renderActions={renderActions}
            updateField={updateSearchInvoiceInfo}
          />,
          true
        )}
        {withHeader(
          <KGPTeamInfoContainer
            option={options}
            register={register}
            setValue={setValue}
            watch={watch}
            control={control}
            isEditing={isEditing}
            currentValues={currentValues}
            renderLabel={renderLabel}
            renderActions={renderActions}
            editingField={editingField}
            index={3}
          />
        )}
        <SearchDocuments
          register={register}
          setValue={setValue}
          watch={watch}
          currentValues={currentValues}
          setSearch={setSearch}
          control={control}
          isEditing={isEditing}
          getValues={getValues}
          handleEdit={handleSearchEdit}
          handleSave={handleSubmit(data => editSearch(data, false, true, 4))}
          handleCancel={onCancel}
          search={search}
          jobTitle={jobTitle}
          confidential={confidential}
          setUpdateLoader={setUpdateLoader}
          setStatusMessage={setStatusMessage}
          reloadData={reloadData}
          handelScroll={handelScroll}
          unregister={unregister}
        />
        <div disabled={true}></div>
        <SFPA
          readOnly={readOnly}
          locker={locker}
          acquire={acquire}
          search={search}
          jobTitle={jobTitle}
          confidential={confidential}
          setSFPADirty={setSFPADirty}
          setUpdateSFPA={setUpdateSFPA}
          onValueChange={value => setSfpaValue(value)}
          currentSfpaIndex={currentSfpaIndex}
        />
        <PositionProfile key={id} search={search} jobTitle={jobTitle} confidential={confidential} setSFPADirty={setSFPADirty} setUpdateSFPA={setUpdateSFPA} getSearch={getSearch} />
        <CsLocInfo search={search} jobTitle={jobTitle} confidential={confidential} setSFPADirty={setSFPADirty} setUpdateSFPA={setUpdateSFPA} />
        <div disabled={true}></div>
        <CandidatesList
          key={id}
          id={id}
          enqueueSnackbar={enqueueSnackbar}
          closeSnackbar={closeSnackbar}
          setColorDesc={setColorDesc}
          colorList={colorData}
          location={location}
          navigate={navigate}
          search={search}
          jobTitle={jobTitle}
          confidential={confidential}
          candidateStatus='ACTIVE'
          initialLoader={initialLoader}
        />
        <CandidatesList
          key={id}
          id={id}
          enqueueSnackbar={enqueueSnackbar}
          closeSnackbar={closeSnackbar}
          setColorDesc={setColorDesc}
          colorList={colorData}
          navigate={navigate}
          location={location}
          search={search}
          jobTitle={jobTitle}
          confidential={confidential}
          initialLoader={initialLoader}
        />
        <ProductOneCandidatesList
          key={id}
          id={id}
          navigate={navigate}
          location={location}
          search={search}
          jobTitle={jobTitle}
          confidential={confidential}
          candidateStatus='PRODUCT_ONE'
          initialLoader={initialLoader}
          enqueueSnackbar={enqueueSnackbar}
          setInitialLoader={setInitialLoader}
          checkSfpaLock={locker}
        />
        <CandidatesList
          key={id}
          id={id}
          enqueueSnackbar={enqueueSnackbar}
          closeSnackbar={closeSnackbar}
          setColorDesc={setColorDesc}
          colorList={colorData}
          navigate={navigate}
          location={location}
          search={search}
          jobTitle={jobTitle}
          confidential={confidential}
          candidateStatus='CLIENTSUITEANDREPORTS'
          initialLoader={initialLoader}
        />
        <CandidatesList
          key={id}
          id={id}
          enqueueSnackbar={enqueueSnackbar}
          closeSnackbar={closeSnackbar}
          setColorDesc={setColorDesc}
          colorList={colorData}
          location={location}
          navigate={navigate}
          search={search}
          jobTitle={jobTitle}
          confidential={confidential}
          candidateStatus={LINKEDIN_SCRAPER.MY_LINKEDIN_CANDIDATES}
          initialLoader={initialLoader}
        />
        <div disabled={true}></div>
      </TabLayoutWithCollapseAndHeader>
    </React.Fragment>
  );
};

ViewSearch.propTypes = {
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func
};
export default ViewSearch;
