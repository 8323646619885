//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../../../common/InputField';
import AddIcon from '@mui/icons-material/Add';

const defaultValue = {
  title: '',
  description: ''
};
export default function Notes(props) {
  const [list, setList] = useState([{ ...defaultValue }]);
  const { register, setValue, isEditing, currentValues } = props;

  useEffect(() => {
    setValue('notes', list);
  }, [list]);

  useEffect(() => {
    register('notes');
    setList(currentValues.notes?.length > 0 ? currentValues.notes : [{ ...defaultValue }]);
  }, [register]);

  const addNotes = () => {
    const updatedList = [...list, {}];
    setList(updatedList);
  };

  const handleChange = (index, name, data) => {
    let newList = [...list];
    newList[index] = { ...list[index], [name]: data };
    setList(newList);
  };

  const removeElement = index => {
    let updatedList = [...list];
    updatedList.splice(index, 1);
    setList(updatedList);
  };

  return (
    // <div className='section-content flex-grow-1'>
    //   <Typography className="section-label">Notes</Typography>
    //   {fields.map((item, index) =>
    //     <div key={item.id} className="section-sub-content">
    //       <div className='d-flex'>
    //         <Typography component={'legend'} color='primary' style={{ fontSize: 12 }}>Note {index + 1}</Typography>
    //         {fields?.length > 1 && <CloseIcon cursor="pointer" onClick={() => remove(index)} style={{ width: 18, height: 18, marginRight: 15 }} />}
    //       </div>
    //       <div className='flex-grow-1'>
    //         <InputField
    //           InputProps={{
    //             style: {
    //               fontWeight: 500,
    //               lineHeight: 19
    //             }
    //           }}
    //           defaultValue={item.title}
    //           disabled={!isEditing}
    //           {...register('')}
    //           className='input-form-field'
    //           placeholder="Heading Text Here"
    //           InputLabelProps={{ focused: true }}
    //           name={`notes[${index}].title`}
    //         />
    //         <InputField
    //           InputProps={{
    //             style: {
    //               fontSize: 14
    //             }
    //           }}
    //           {...register('')}
    //           defaultValue={item.description}
    //           disabled={!isEditing}
    //           className='input-form-field'
    //           placeholder='Text Here'
    //           InputLabelProps={{ focused: true }}
    //           name={`notes[${index}].description`}
    //         />
    //       </div>
    //     </div>
    //   )}
    //   {isEditing &&
    //     <Button
    //       variant={"text"}
    //       startIcon={<AddCircleIcon />}
    //       onClick={() => {
    //         append({})
    //       }}
    //       color='primary'>
    //       Add Notes
    //     </Button>
    //   }
    // </div>

    <div className='section-content flex-grow-1' id='clientInformation'>
      <Accordion defaultExpanded={true} className='w-100 mb-5' style={{ background: '#fff', color: '#fff' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />} aria-controls='panel1a-content' style={{ background: '#2a364c' }}>
          <Typography>Notes</Typography>
        </AccordionSummary>

        <AccordionDetails className='d-flex flex-column pt-2 p-0'>
          {list.map((item, index) => (
            <div key={item.id} className='mx-2'>
              <div className='section-content team-information-table flex-grow-1'>
                <div className='d-flex align-items-center table-header p-0' style={{ color: '#fff' }}>
                  <Typography
                    className='m-0'
                    style={{
                      background: '#2a364c',
                      color: '#fff',
                      border: '6px solid #2a364c'
                    }}
                  >
                    Note {index + 1}
                  </Typography>
                  {list?.length > 1 && <CloseIcon cursor='pointer' onClick={() => removeElement(index)} style={{ width: 18, height: 18, marginRight: 15, color: '#fff' }} />}
                </div>
                <div className='d-flex contact-details-row '>
                  <div className='d-flex intersection-page-view w-100'>
                    <InputField
                      InputProps={{
                        style: {
                          fontWeight: 500,
                          lineHeight: 19
                        }
                      }}
                      disabled={!isEditing}
                      className='input-form-field'
                      placeholder='Heading Text Here'
                      InputLabelProps={{ focused: true }}
                      variant='outlined'
                      value={item.title || null}
                      onChange={e => handleChange(index, 'title', e.target.value)}
                    />
                  </div>
                </div>
                <div className='d-flex contact-details-row '>
                  <div className='d-flex intersection-page-view w-100'>
                    <InputField
                      InputProps={{
                        style: {
                          fontSize: 14
                        }
                      }}
                      value={item.description || null}
                      disabled={!isEditing}
                      className='input-form-field'
                      placeholder='Text Here'
                      InputLabelProps={{ focused: true }}
                      variant='outlined'
                      onChange={e => handleChange(index, 'description', e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className='text-start'>
            {' '}
            <Button variant={'text'} startIcon={<AddIcon style={{ color: 'red' }} />} onClick={() => addNotes()} className='button-text-capitalized' style={{ width: '175px' }}>
              <span
                style={{
                  fontWeight: '600',
                  fontSize: '13px',
                  fontStyle: 'italic'
                }}
              >
                Add Note
              </span>
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

Notes.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  isEditing: PropTypes.bool,
  currentValues: PropTypes.object
};
