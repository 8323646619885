import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

/**
 * A custom text field component for phone input.
 * @param {object} props - Component props.
 * @param {string} [props.value] - The current value of the phone number input.
 * @param {function} [props.onChange] - Callback function to handle value changes.
 * @param {object} [props.rest] - Additional props to pass to the TextField component.
 * @param {object} ref - Ref object for the input element.
 * @returns {JSX.Element} A custom text field component for phone input.
 */
const PhoneInputTextField = React.forwardRef(({ value, onChange, ...rest }, ref) => {
  const handleChange = event => {
    onChange(event.target.value);
  };

  return <TextField {...rest} inputRef={ref} value={value || ''} onChange={handleChange} type='tel' autoComplete='tel' />;
});

/**
 * A custom phone input component using PhoneInputTextField.
 * @param {object} props - Component props. (Refer PhoneInput component props & can pass MUI TextField props as well)
 * @param {function} [props.onChange] - Callback function to handle value changes. Gets the phone number as a string in argument.
 * @param {string} [props.value] - The current value of the phone number input.
 * @returns {JSX.Element} A custom phone input component.
 */
const CustomPhoneInput = props => {
  return <PhoneInput {...props} inputComponent={PhoneInputTextField} />;
};

const formatPhoneNumber = number => formatPhoneNumberIntl(number);

export { formatPhoneNumber, isValidPhoneNumber };
export default CustomPhoneInput;

/**
 * PropTypes for PhoneInputTextField component.
 * @type {object}
 * @property {string} [value] - The current value of the phone number input.
 * @property {function} [onChange] - Callback function to handle value changes.
 */
PhoneInputTextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};
