import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, FormControl, Skeleton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { globalSearchSelector } from '../../../selectors';
import { globalSearchFiltersApi } from '../../../services/ApiService';
import { GET } from '../../../services/constantService';
import { updateGlobalSearchData } from '../../../store/globalSearchSlice';
import ExportCSV from '../../../utils/ExportCSV';
import { CustomAction } from '../Components/CustomHeader';
import { FilterBox } from '../Components/FilterBox';
import { MoreFilter } from '../Components/MoreFilter';

export const TabSelect = ({ show = true, fromMoreFilter = false, tab, showMoreFilterTab = false, totalMoreFilterCount = 0, remainingTabs, width = 'fit-content' }) => {
  const dispatch = useDispatch();
  const { selectedSubTabId, selectedMoreFilterTabId, filters, filterModel, gridApi, data: globalSearchData } = useSelector(globalSearchSelector);
  const hasFilter = Object.keys(filters).some(field => tab?.search_field?.includes(field));
  let selectedCount = 0;

  tab?.search_field?.forEach(field => {
    if (filters?.[field]?.values?.length) {
      selectedCount = filters?.[field]?.values?.length;
      return;
    }
  });

  const isSelected = (hasFilter && selectedCount > 0) || (showMoreFilterTab && totalMoreFilterCount > 0);

  const filterBoxCondition = (!showMoreFilterTab && selectedSubTabId === tab?.id) || (fromMoreFilter && selectedMoreFilterTabId === tab?.id);
  const iconCondition = selectedSubTabId === tab?.id || (fromMoreFilter && selectedMoreFilterTabId === tab?.id);
  const color = isSelected ? '#009d81' : '#959fb7';
  const background = isSelected ? 'rgba(0, 157, 129, 0.15)' : fromMoreFilter ? '#204d6f26' : 'white';

  const onClose = () => {
    if (fromMoreFilter) {
      dispatch(updateGlobalSearchData({ key: 'selectedMoreFilterTabId', data: null }));
    } else {
      dispatch(updateGlobalSearchData({ key: 'selectedSubTabId', data: null }));
    }
  };

  const removeFilter = e => {
    e?.stopPropagation();
    const updatedFilters = { ...filters };

    if (tab?.id === 'more_filters') {
      remainingTabs?.forEach(tab => {
        tab?.search_field?.forEach(field => {
          delete updatedFilters?.[field];
          if (tab?.search_type !== 'local' && updatedFilters?.[field]?.isLocal) delete updatedFilters?.[field];
        });
      });
    } else {
      tab?.search_field?.forEach(field => {
        delete updatedFilters?.[field];
        if (tab?.search_type !== 'local' && updatedFilters?.[field]?.isLocal) delete updatedFilters?.[field];
      });
    }
    dispatch(updateGlobalSearchData({ key: 'filters', data: updatedFilters }));
    const appliedFilters = gridApi?.current?.getFilterModel();
    const updatedFilterModel = {
      ...filterModel,
      subTabsFilter: { filterType: 'text', filter: JSON.stringify({ filters: updatedFilters, isLocal: tab?.search_type === 'local', fields: tab?.search_field, globalSearchData }) }
    };
    dispatch(updateGlobalSearchData({ key: 'filterModel', data: updatedFilterModel }));
    gridApi?.current?.setFilterModel({ ...appliedFilters, ...updatedFilterModel });
  };

  return !show ? (
    <></>
  ) : (
    <FormControl sx={{ position: 'relative' }}>
      <Box
        onClick={() => {
          if (fromMoreFilter) {
            dispatch(updateGlobalSearchData({ key: 'selectedMoreFilterTabId', data: selectedMoreFilterTabId === tab?.id ? null : tab?.id }));
          } else {
            dispatch(updateGlobalSearchData({ key: 'selectedSubTabId', data: selectedSubTabId === tab?.id ? null : tab?.id }));
          }
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          height: '1.6rem',
          width,
          margin: 0,
          background,
          border: '1px solid #959fb7',
          borderRadius: '4px',
          padding: '0 8px',
          '&:hover': {
            borderColor: '#959fb7'
          }
        }}
      >
        {isSelected && <CloseIcon onClick={removeFilter} sx={{ cursor: 'pointer', height: '14px', width: '14px', color }} />}
        <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', marginX: 0.5, fontSize: '12px', fontWeight: 'bolder', color }}>{tab?.label || 'Select...'}</Typography>
        {isSelected && (
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'12px'} height={'12px'} borderRadius={'50%'} bgcolor={'rgba(0, 157, 129, 0.15)'}>
            <Typography color='#009d81' fontSize={'8px'}>
              {showMoreFilterTab ? totalMoreFilterCount : selectedCount}
            </Typography>
          </Box>
        )}
        {iconCondition ? <ArrowDropUpIcon sx={{ color }} /> : <ArrowDropDownIcon sx={{ color }} />}
      </Box>

      <MoreFilter show={showMoreFilterTab && selectedSubTabId === tab?.id} remainingTabs={remainingTabs} onClose={onClose} />

      <FilterBox show={filterBoxCondition} onClose={onClose} />
    </FormControl>
  );
};

TabSelect.propTypes = {
  tab: PropTypes.object,
  showMoreFilterTab: PropTypes.bool,
  remainingTabs: PropTypes.array,
  width: PropTypes.string,
  show: PropTypes.bool,
  fromMoreFilter: PropTypes.bool,
  isSelected: PropTypes.bool,
  totalMoreFilterCount: PropTypes.number
};

export const SubTabs = () => {
  const maxVisibleTab = 5;
  const { selectedTabId } = useParams();
  const { subTabs, filtersLoading, gridApi, filterModel, data: globalSearchData } = useSelector(globalSearchSelector);

  const dispatch = useDispatch();

  const handleResetFilter = () => {
    if (Object.keys(filters).length === 0) return;
    dispatch(updateGlobalSearchData({ key: 'filters', data: {} }));
    const appliedFilters = gridApi?.current?.getFilterModel();
    const updatedFilterModel = { ...filterModel, subTabsFilter: { filterType: 'text', filter: JSON.stringify({ fields: [], isLocal: false, filters: {}, globalSearchData }) } };

    dispatch(updateGlobalSearchData({ key: 'filterModel', data: updatedFilterModel }));
    gridApi?.current?.setFilterModel({ ...appliedFilters, ...updatedFilterModel });
  };

  const { filters } = useSelector(globalSearchSelector);

  const remainingTabs = subTabs?.slice(maxVisibleTab);
  const totalMoreFilterCount = remainingTabs?.reduce((acc, tab) => {
    const count = filters[tab?.search_field?.[0]]?.values?.length || 0;
    return acc + count;
  }, 0);

  const timerRef = useRef(null);

  useEffect(() => {
    if (selectedTabId !== 'all') {
      timerRef.current = setTimeout(() => {
        dispatch(updateGlobalSearchData({ key: 'filtersLoading', data: true }));
        globalSearchFiltersApi(GET, {}, { modelName: selectedTabId })
          .then(({ data }) => dispatch(updateGlobalSearchData({ key: 'subTabs', data })))
          .finally(() => {
            dispatch(updateGlobalSearchData({ key: 'filtersLoading', data: false }));
          });
      }, 0);
    }

    return () => {
      clearTimeout(timerRef?.current);
    };
  }, [selectedTabId]);

  const csvData = Array.isArray(globalSearchData) ? globalSearchData.find(ele => ele?.model === selectedTabId)?.data?.hits?.hits || [] : [];

  const contactCSVColumns = [
    { key: '_source.name', label: 'Name' },
    { key: '_source.company_name_p1', label: 'Company Name' },
    { key: '_source.current_job_title', label: 'Job Title' },
    { key: '_source.linkedin_username', label: 'Linkedin Username' },
    { key: '_source.linkedin_url', label: 'Linkedin Url' },
    { key: '_source.gender', label: 'Gender' },
    { key: '_source.contact_status', label: 'Contact Status' },
    { key: '_source.contact_industries_p1', label: 'Contact Industries' },
    { key: '_source.contact_country_p1', label: 'Contact Country' },
    { key: '_source.contact_state_p1', label: 'Contact State' },
    { key: '_source.age', label: 'Contact Age' }
  ];

  const companyCSVColumns = [
    { key: '_source.name', label: 'Company Name' },
    { key: '_source.location_description', label: 'Location' },
    { key: '_source.linkedin_url', label: 'Linkedin Url' },
    { key: '_source.revenue_range_from', label: 'Revenue Range From' },
    { key: '_source.revenue_range_to', label: 'Revenue Range To' },
    { key: '_source.website_url', label: 'Website Url' },
    { key: '_source.employee_range_from', label: 'Employee Range From' },
    { key: '_source.employee_range_to', label: 'Employee Range To' },
    { key: '_source.company_status', label: 'Status' }
  ];

  const companyArrayMapping = {};

  const contactArrayMapping = {};

  const csvFileName = {
    'ign-contacts': 'Global-Contacts.csv',
    'ign-companies': 'Global-Companies.csv'
  };

  return (
    <Box width='100%' marginY={1} display='flex' alignItems='center' justifyContent={'space-between'} flexWrap='wrap'>
      <Box display={'flex'} alignItems='center'>
        {filtersLoading && (
          <Box display={'flex'} gap={1}>
            {Array.from({ length: 5 }).map((_, index) => (
              <Skeleton variant='text' key={index} width={'100px'} height={'40px'} sx={{ fontSize: 14 }} />
            ))}
          </Box>
        )}
        <Box display={'flex'} gap={1}>
          {!filtersLoading && subTabs.slice(0, maxVisibleTab).map(tab => <TabSelect key={tab?.id} tab={tab} />)}
        </Box>
        {!filtersLoading && (
          <Box marginX={1.5}>
            <TabSelect
              show={subTabs.length > maxVisibleTab}
              remainingTabs={remainingTabs}
              showMoreFilterTab
              totalMoreFilterCount={totalMoreFilterCount}
              tab={{ id: 'more_filters', label: 'More Filters', values: subTabs.slice(5) }}
            />
          </Box>
        )}
        {!filtersLoading && subTabs?.length !== 0 && (
          <Button onClick={handleResetFilter}>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 600,
                color: '#959fb7',
                textTransform: 'none',
                textDecoration: 'underline'
              }}
            >
              Reset Filters
            </Typography>
          </Button>
        )}
      </Box>
      <Box display={'flex'} alignItems='center' justifyContent={'space-between'}>
        {['ign-contacts', 'ign-companies'].includes(selectedTabId) && (
          <ExportCSV
            columns={selectedTabId === 'ign-contacts' ? contactCSVColumns : companyCSVColumns}
            data={csvData}
            iconHeight={14}
            iconWidth={14}
            fileName={csvFileName[selectedTabId]}
            arrayKeyMapping={selectedTabId === 'ign-contacts' ? contactArrayMapping : companyArrayMapping}
          />
        )}
        <CustomAction />
      </Box>
    </Box>
  );
};
