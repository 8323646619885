//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Searches from './Searches';

export default function KGPSearchesList() {
  return <Searches searchType='kgpsearches' columnStateKey='kgpSearchesColumns' title='KGP Searches' activeSearches />;
}
