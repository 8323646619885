import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import useApi from '../../../../../utils/Hooks/useApiHook';
import { SFPADataApi, TargetJobFunctionDataApi, picklistDropDownApis } from '../../../../../services/ApiService';
import { DELETE, DROP_DOWN_PICKLIST, GET, POST } from '../../../../../services/constantService';
import { AccordionHeader } from '../Scope';
import { useLanguageHooks } from '../../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';
import { ProjectDataContext } from '../../Context';
import Loader from '../../../../../components/common/Loader';
import { useSnackbar } from 'notistack';

export default function TargetFunction() {
  const { projectData, handleUniversalState } = useContext(ProjectDataContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [recommendedTargetFunctions, setRecommendedTargetFunctions] = useState([]);

  //API Integration
  const { data: jobFunctionOptionsResponse } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.JOB_FUNCTION);
    }
  });

  //Translate
  const { t } = useLanguageHooks([TRANS_KEYS.ADD_TARGET_FUNCTION]);

  const handleUniversalStateLocally = updatedVal => {
    const event = {
      target: {
        name: 'targetJobFunctions',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };
  const deleteTargetFunction = async cardData => {
    try {
      setLoader(true);
      if (!cardData?.id.includes('new') && !cardData?.id.includes('bulk')) {
        const res = await TargetJobFunctionDataApi(DELETE, '', {
          id: cardData.id
        });
        console.log('target-job-function-delete', res);
      }
      const updatedTargetFunctions = recommendedTargetFunctions.filter(curr => curr?.id != cardData?.id);
      handleUniversalStateLocally(updatedTargetFunctions);
      setRecommendedTargetFunctions(updatedTargetFunctions);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log('target-job-function-err', err);
      enqueueSnackbar(err, 'error');
    }
  };
  const addTargetFunction = value => {
    let newTargetFunction = {};
    if (typeof value == 'object') {
      newTargetFunction = {
        id: 'new-' + value.id,
        job_function: value
      };
    } else {
      newTargetFunction = {
        name: value,
        job_function: {
          id: 'new-' + recommendedTargetFunctions.length + 1,
          name: value
        },
        id: 'new-type' + recommendedTargetFunctions.length + 1
      };
    }
    console.log('target-function-add', newTargetFunction);
    setRecommendedTargetFunctions([...recommendedTargetFunctions, newTargetFunction]);
    handleUniversalStateLocally([...recommendedTargetFunctions, newTargetFunction]);
  };

  const onClickShowMore = async () => {
    try {
      setLoader(true);
      console.log({ projectData });
      const companyName = projectData?.ign_companies?.name;
      const jobTitle = projectData?.job_title;
      const ign_industry = {
        name: projectData?.ign_industries?.name
      };
      const fetchedJobFunctionList = recommendedTargetFunctions?.map(targetFunction => {
        return {
          isSelected: true,
          isNew: false,
          name: targetFunction?.job_function?.name
        };
      });

      const payload = {
        template_name: 'SFPA-Jobfunction-Search',
        mode: 'more',
        variables: {
          searchCompany: companyName,
          searchIndustry: ign_industry?.name ? [ign_industry] : [],
          fetchedJobFunctionList: fetchedJobFunctionList,
          key: 'fetchedJobFunctionList',
          searchJobTitle: jobTitle
        }
      };
      console.log('target-job-function-more-payload', payload);

      const res = await SFPADataApi(POST, projectData?.id, payload);

      console.log('target-title-res', res);
      if (res && res?.status == 200 && res?.data && res?.data?.target_job_functions) {
        const allData = res?.data?.target_job_functions ?? [];
        const newData = allData?.filter(data => data?.isNew == true);

        const jobTitles = newData.map((data, index) => {
          return {
            name: data?.name,
            job_function: {
              id: 'bulk-' + index,
              name: data?.name
            },
            id: 'bulk' + index
          };
        });
        console.log('target-job', { allData, newData, jobTitles });
        const newJobTitles = [...jobTitles, ...recommendedTargetFunctions];
        setRecommendedTargetFunctions(newJobTitles);
        handleUniversalStateLocally(newJobTitles);
      } else {
        enqueueSnackbar(res?.data?.message, 'error');
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      enqueueSnackbar(err, 'error');
    }
  };

  useEffect(() => {
    if (recommendedTargetFunctions.length == 0 && !isDataLoaded) {
      setRecommendedTargetFunctions(projectData?.targetJobFunctions || []);
      if (projectData?.targetJobFunctions) {
        setIsDataLoaded(true);
      }
    }
  }, [projectData]);
  return (
    <Box>
      <Loader show={loader} />
      <AccordionHeader
        onSearch={addTargetFunction}
        searchOptions={jobFunctionOptionsResponse?.data || []}
        searchLabel={t(`${TRANS_KEYS.ADD_TARGET_FUNCTION}:key`)}
        isDropDown={false}
        isGetMore={true}
        onClickGetMore={() => onClickShowMore()}
        recommendedOptions={recommendedTargetFunctions}
        onClickRecommendOption={deleteTargetFunction}
      />
    </Box>
  );
}
