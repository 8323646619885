import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import './index.scss';

const IconBreadcrumbs = props => {
  const { tabItem, separator = '>', navLinks = [] } = props;
  return (
    <div id='customBreadCrumb'>
      <Breadcrumbs aria-label='breadcrumb' separator={separator}>
        {navLinks.map((ele, i) => (
          <Link key={i} underline='hover' color='inherit' className='nav-links' href={ele.link}>
            {ele.label}
          </Link>
        ))}
        <Typography className='nav-links' color='heading.main'>
          {tabItem.label}
        </Typography>
      </Breadcrumbs>
    </div>
  );
};

IconBreadcrumbs.propTypes = {
  separator: PropTypes.string,
  tabItem: PropTypes.object,
  navLinks: PropTypes.array
};

export default IconBreadcrumbs;
