import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from '../../../services/constantService';
import { updateGlobalSearchData } from '../../../store/globalSearchSlice';
import { highlightText, renderRichText } from '../../../utils/common';
import MarkOffLimits from '../../common/MarkOffLimits';
import ShowOffLimits from '../../common/ShowOffLimits';

import { useOfflimit } from '../../../utils/Hooks/useOfflimit';
import { renderHtmlContent, useStyles } from '../helper/utils';
import '../index.scss';

export const TextRenderer = props => {
  const { value } = props;
  const classes = useStyles();
  const { search } = useParams();

  return (
    <Tooltip sx={{ cursor: 'pointer', textAlign: 'center' }} title={value?.toString()?.trim()} arrow placement='top'>
      <Typography>
        {renderHtmlContent(highlightText(value?.toString()?.trim() || '--', search), { className: classes?.text, sx: { fontSize: 13, fontWeight: 500, color: 'rgba(0, 0, 0, 0.5)' } })}
      </Typography>
    </Tooltip>
  );
};

export const OfflimitRenderer = props => {
  const { value } = props;

  const contactId = value?.contactId;
  const companyId = value?.companyId;

  const [directOffLimit, setDirectOfflimit] = useState(value?.offlimits || []);

  const isOffLimitApplied = directOffLimit?.length > 0;
  const isDirectLimitApplied = directOffLimit?.find(entry => entry.type === 'Direct');

  const offLimitClass = useMemo(() => {
    const isHardLimitPresent = directOffLimit?.find(offlimit => offlimit?.severity === 'hard');
    return isHardLimitPresent ? 'hard' : 'soft';
  }, [directOffLimit]);

  const [showContactOffLimit, setShowContactOffLimit] = useState(false);
  const [isDirectOffLimitPopupOpen, setIsDirectOffLimitPopupOpen] = useState(false);
  const { createDirectOfflimit, updateDirectOfflimit, deleteDirectOfflimit, updateCompanyOffLimit, createCompanyOffLimit, deleteCompanyOffLimit } = useOfflimit();

  const handleOffLimitInfo = () => {
    setShowContactOffLimit(false);
  };

  const handleCloseDirectOffLimitPopup = () => {
    setIsDirectOffLimitPopupOpen(false);
  };

  const onSubmitOffLimit = async (payload, setIsLoading, newValue) => {
    let obj, offLimitAction;
    const isEditMode = showContactOffLimit && !!isDirectLimitApplied;

    if (contactId) {
      obj = { ...payload, contactID: contactId };
      offLimitAction = isEditMode ? updateDirectOfflimit : createDirectOfflimit;
    } else if (companyId) {
      obj = { ...payload, companyID: companyId };
      offLimitAction = isEditMode ? updateCompanyOffLimit : createCompanyOffLimit;
    }

    await offLimitAction(obj);
    setIsLoading(false);
    handleCloseDirectOffLimitPopup();
    handleOffLimitInfo();
    setDirectOfflimit([newValue]);
  };

  const onDeleteContactOffLimit = async setIsLoading => {
    if (contactId) {
      await deleteDirectOfflimit(contactId);
    } else if (companyId) {
      await deleteCompanyOffLimit(companyId);
    }
    setIsLoading(false);
    handleCloseDirectOffLimitPopup();
    handleOffLimitInfo();
  };

  const showOfflimitHandler = async () => {
    if (directOffLimit?.length === 0) return;
    setShowContactOffLimit(true);
  };

  return (
    <Box display={'flex'} justifyContent={'center'}>
      {isOffLimitApplied ? (
        <WarningIcon sx={{ cursor: 'pointer' }} onClick={showOfflimitHandler} className={`offlimit-caution ${isOffLimitApplied ? offLimitClass : ''}`} size='10px' />
      ) : (
        <Typography>--</Typography>
      )}
      {showContactOffLimit && (
        <ShowOffLimits
          showInherited={true}
          showDirect={true}
          isPopupOpen={true}
          handleClose={handleOffLimitInfo}
          onEdit={() => {
            setIsDirectOffLimitPopupOpen(true);
          }}
          data={directOffLimit}
          contactId={contactId}
          companyId={companyId}
        />
      )}

      {isDirectOffLimitPopupOpen && (
        <MarkOffLimits
          data={[isDirectLimitApplied]}
          editMode={showContactOffLimit && !!isDirectLimitApplied}
          isPopupOpen={isDirectOffLimitPopupOpen}
          handleClose={handleCloseDirectOffLimitPopup}
          onSubmit={onSubmitOffLimit}
          onDelete={onDeleteContactOffLimit}
        />
      )}
    </Box>
  );
};

export const NoteTextRenderer = props => {
  const { value } = props;
  const log = value?._source;
  const item = value;

  return (
    <Box width={'100%'} borderRadius={2} padding={1} bgcolor={'rgba(149, 159, 183, 0.07)'}>
      <Typography fontSize={'12px'} color={'black'} marginRight={0.5} fontWeight={400}>
        {renderRichText(highlightText(log?.notes?.trim() || '--', item?.highlight?.notes?.[0]?.match(/<em>(.*?)<\/em>/)?.[1] || ''), {
          maxWidth: '100%',
          maxCount: 250,
          textStyles: {
            color: '#979FB5',
            fontWeight: 600
          },
          readMoreStyles: {
            color: '#1b4965',
            fontSize: '10px'
          }
        })}
      </Typography>
    </Box>
  );
};

export const TitleRenderer = props => {
  const { value, isContact } = props;
  const classes = useStyles();
  const { search } = useParams();

  const dispatch = useDispatch();

  const handleOnClick = () => {
    if (value?.contactId) {
      dispatch(updateGlobalSearchData({ key: 'selectedContactId', data: value?.contactId }));
    }
    if (value?.companyId) {
      dispatch(updateGlobalSearchData({ key: 'selectedCompanyId', data: value?.companyId }));
    }
  };

  return (
    <Box display={'flex'} gap={0.5} alignItems={'center'}>
      {isContact && (
        <Link
          target={value?.linkedin_url ? '_blank' : '_self'}
          to={value?.linkedin_url || '#'}
          style={{ marginBottom: 4, color: value?.linkedin_url ? '' : 'inherit', pointerEvents: value?.linkedin_url ? 'auto' : 'none' }}
        >
          <LinkedInIcon sx={{ width: '18px', height: '18px', cursor: value?.linkedin_url ? 'pointer' : null }} />
        </Link>
      )}
      <Tooltip sx={{ cursor: 'pointer' }} title={value?.name?.trim()} arrow placement='top'>
        <Link target={!value?.projectId ? '_self' : '_blank'} to={!value?.projectId ? location.pathname : `${ROUTES.projectDetails}/${value?.projectId}`}>
          <Box onClick={handleOnClick}>
            {renderHtmlContent(highlightText(value?.name?.toString()?.trim() || '--', search), { className: classes?.text, sx: { fontSize: 14, fontWeight: 600, color: 'black' } })}
          </Box>
        </Link>
      </Tooltip>
    </Box>
  );
};

TitleRenderer.propTypes = {
  value: PropTypes.object,
  isContact: PropTypes.bool
};

TextRenderer.propTypes = {
  value: PropTypes.string
};

OfflimitRenderer.propTypes = {
  value: PropTypes.string
};

NoteTextRenderer.propTypes = {
  value: PropTypes.string
};
