import AddIcon from '@mui/icons-material/Add';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { AppBar, Box, Checkbox, Link, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import { columnDefs, createRequiredField, createSchema, getFilterParamString, getQueryParamsString } from './Utils';
// import Loader from '../../components/common/Loader';
import { Helmet } from 'react-helmet';
import CustomButton from '../../components/common/CustomButton';
//import IosShareIcon from "@mui/icons-material/IosShare";
import AddCompany from '../../components/AddCompany';
import CompanyGridSelection from '../../components/common/FunctionalComponents/CompanyGridSelection';
import { addHttps } from '../../utils/string';
import ColumnFilter from '../Commons/ColumnFilter';
import Search from '../Contacts/Search';
import { checkContactFetchingStatus, defaultColumns } from '../Contacts/utils';
import './index.scss';
//import AddCompany from "../../components/AddCompany";
import { enqueueSnackbar } from 'notistack';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { fetchPickLists } from '../../actions';
import CompanyGridActionSelection from '../../components/common/CompanyGridActionSelection';
import CustomConfirmationPopup from '../../components/common/CustomConfirmationPopup';
import CustomPopover from '../../components/common/CustomPopover';
import Loader from '../../components/common/Loader';
import TotalCount from '../../components/common/TotalCount';
import ViewCompanyDrawer from '../../components/ViewCompany/ViewCompanyDrawer';
import { ignCompanyDataApi } from '../../services/ApiService';
import { DEFAULT_STATUS, DELETE, ERROR, FIELDS, IGN_API, IGNYTE_GLOBALS, POST, REVENUE_MAX_LIMIT, ROUTES, SUB_ROUTES, SUCCESS, WARNING } from '../../services/constantService';
import { notFoundMessage, successMessage } from '../../services/MessageService';
import { updateGlobalSearchData } from '../../store/globalSearchSlice';
import { customFormValidator, SCROLL_TIMEOUT, splitLastIfMultiple } from '../../utils/common';
import { dataSourceUtils } from '../../utils/dataSource';
import ExportCSV from '../../utils/ExportCSV';
import { useCustomMessageHook } from '../../utils/Hooks/useCustomMessageHook';
import { SUCCESS_STATUS_CODE } from '../Commons/Utils';
import CompanyAssignTag from './CompanyAssignTag';
import CompanyChangeStatus from './CompanyChangeStatusPopup';
let gridApi, columnApi;
const PAGE_LIMIT = 200;
const List = () => {
  const [isGridReady, setIsGridReady] = useState(false);
  const [, /*isEdited*/ setIsEdited] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { getValues, setValue, register, unregister, watch, reset } = useForm({});
  const { translateMessage } = useCustomMessageHook();
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const [anchorElTagsHoverPopup, setAnchorElTagsHoverPopup] = useState(null);
  const [options, setOptions] = useState([]);
  const [, /*isAllRowsSelected*/ setIsAllRowsSelected] = useState(false);
  const [tagPopUp, setTagPopUp] = useState([]);
  const [tagDataId, setTagDataId] = useState([]);
  const [tagChange, setTagChange] = useState(false);
  const [statusChange, setStatusChange] = useState(false);
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
  const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [isUpdateCompany, setIsUpdateCompany] = useState(false);
  const [companyDetails, setCompanyDetails] = useState();
  const [isClassicQuery, setIsClassicQuery] = useState(false);
  const [quickQuery, setQuickQuery] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isSelectedFromList, setIsSelectedFromList] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [loadedContactCount, setLoadedContactCount] = useState(0);

  const isSelectedListRef = useRef();
  const quickQueryRef = useRef();
  const isClassicQueryRef = useRef();
  isSelectedListRef.current = isSelectedFromList;
  quickQueryRef.current = quickQuery;
  isClassicQueryRef.current = isClassicQuery;
  const [isCompanyAdd, setIsCompanyAdd] = useState(false);
  const [companyStatus, setCompanyStatus] = useState();
  const [isBulkTag, setIsBulkTag] = useState();
  const [allCompanies, setAllCompanies] = useState([]);

  const companyStatusOption = useSelector(state => state.commonReducer.companyStatus);
  const openTagsViewMore = Boolean(anchorElTagsHoverPopup);
  const [tagList, setTagsList] = useState();
  // const [companyId, setCompanyId] = useState();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const tagListRef = useRef();
  tagListRef.current = tagList;
  const setGridApi = useRef(null);
  const setColumnApi = useRef(null);

  useEffect(() => {
    if (!companyStatusOption) {
      dispatch(fetchPickLists('COMPANY_STATUS', 'companyStatus'));
    } else {
      setOptions(companyStatusOption);
    }
  }, [companyStatusOption, dispatch]);

  useEffect(() => {
    if (location.pathname === ROUTES.addCompany && isComponentLoaded) {
      setIsPopupOpen(true);
    }
  }, [location.pathname, isComponentLoaded]);
  const openDrawer = params => {
    // setIsDrawerOpen(true);
    navigate(`/companies/all-companies/${params.data.id}`);
  };
  useEffect(() => {
    const isContactView = location.pathname.includes(id);
    if (isContactView && !isDrawerOpen && isComponentLoaded) {
      setIsDrawerOpen(true);
    }

    if (state?.isEdited) {
      setIsPopupOpen(true);
    }
  }, [location?.pathname, isComponentLoaded]);
  const setSelectAllRows = isAllRowsSelected => {
    setIsAllRowsSelected(isAllRowsSelected);
    gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };
  useEffect(() => {
    if (tagChange && !isTagPopupOpen) {
      gridApi.deselectAll();
      gridApi && gridApi.onFilterChanged();
      setSelectAllRows(false);
    }
  }, [tagChange, isTagPopupOpen, gridApi]);

  useEffect(() => {
    if (statusChange) {
      gridApi && gridApi.onFilterChanged();
      setStatusChange(false);
    }
  }, [statusChange]);
  useEffect(() => {
    if (isCompanyAdd) {
      setIsUpdateCompany(true);
      setIsCompanyAdd(false);
    } else {
      setIsUpdateCompany(false);
    }
  }, [id]);

  const onGridReady = params => {
    columnApi = params.columnApi;
    gridApi = params.api;
    setGridApi.current = params.api;
    setColumnApi.current = params.columnApi;
    params.columnApi?.getAllColumns().map(col => {
      console.log('check', col?.colDef);
      params.columnApi?.setColumnVisible(col.colId, col?.colDef?.visible);
    });
    params.columnApi.autoSizeColumns();
    dispatch(updateGlobalSearchData({ key: 'gridApi', data: setGridApi }));
    // loadColumnStateFromLocalStorage("contactColumns", { columnApi, gridApi });
    setIsGridReady(true);
  };

  const handleChange = event => {
    const checked = event.currentTarget.checked;
    setSelectAllRows(checked);
    console.log(columnApi.getAllDisplayedColumns(), 'COLUMN API>>>>');
  };
  const headerCheckbox = () => {
    return <Checkbox className='header-checkbox' classes={{ root: 'custom-checkbox-root' }} size='small' color='primary' onChange={e => handleChange(e)} />;
  };
  const handleAssignTag = (data, bulkTag = '') => {
    if (bulkTag === 'bulkTag') {
      setTagDataId('');
      setIsBulkTag(true);
      setIsTagPopupOpen(true);
    } else {
      setTagPopUp(data.company_tags);
      setTagDataId(data.id);
      setIsBulkTag(false);
      setIsTagPopupOpen(true);
    }
  };
  const handleChangeStatus = data => {
    setCompanyStatus(data);
    setIsStatusPopupOpen(true);
  };
  const onConfirm = async () => {
    setIsLoading(true);
    const { status } = await ignCompanyDataApi(DELETE, '', companyDetails.id);
    if (status === 200) {
      setIsLoading(false);
      // navigate(`/contacts/list/all-contacts`);
      const message = successMessage('Company', 'Deleted');
      enqueueSnackbar(message, { variant: SUCCESS });
      gridApi && gridApi.onFilterChanged();
    }
  };
  const handleDeleteCompany = (id, data) => {
    setOpenConfirmationPopup(true);
    setCompanyDetails(data);
  };
  const getActionRenderer = params => {
    return (
      <CompanyGridActionSelection
        handleChangeStatus={handleChangeStatus}
        handleAssignTag={handleAssignTag}
        handleDeleteCompany={handleDeleteCompany}
        params={params}
        id={params?.data?.id}
        data={params?.data}
      ></CompanyGridActionSelection>
    );
  };
  const nameRenderer = params => {
    return (
      <Box className=''>
        {params.data?.linkedin_url ? (
          <a rel='noopener noreferrer' target='_blank' href={addHttps(params.data?.linkedin_url)} className='linkedin-icon-header company-display mb-1'>
            <LinkedInIcon className='mb-1 mr-1 fs-22'></LinkedInIcon>
          </a>
        ) : (
          <LinkedInIcon className='disabled-link mb-1 mr-1 fs-22' />
        )}
        <Tooltip title={params.data?.name}>
          <Box
            component={'span'}
            onClick={() => {
              openDrawer(params);
            }}
          >
            <Typography className='fs-14 company_name company-display'>{params.data?.name}</Typography>
          </Box>
        </Tooltip>
      </Box>
    );
  };
  const openTagsPopup = (event, tags) => {
    setTagsList(tags);
    setAnchorElTagsHoverPopup(event.currentTarget);
  };
  const closeTagsPopup = () => {
    setAnchorElTagsHoverPopup(null);
  };
  const getTags = params => {
    if (params?.data) {
      return (
        <Box className='tag-container tag-pointer'>
          {params?.data?.company_tags &&
            params?.data?.company_tags?.slice(0, 2)?.map(
              (item, index) =>
                item && (
                  <Box
                    component='div'
                    onMouseOver={e => {
                      openTagsPopup(e, params?.data?.company_tags);
                    }}
                    onMouseLeave={closeTagsPopup}
                    className={'p-1 fs-14 tag-border'}
                    sx={{
                      color: `${item?.ign_tag?.color}`
                    }}
                    key={index}
                  >
                    {item?.ign_tag?.name}
                  </Box>
                )
            )}
        </Box>
      );
    }
  };
  const employeeRangeRendrer = params => {
    const fromRange = params?.data?.employee_range_from || '';
    const toRange = params?.data?.employee_range_to || '';
    let sign = '';
    if (fromRange && toRange) {
      sign = '-';
    }
    const employeeRange = `${fromRange} ${sign} ${toRange}`;
    return <Box className='fs-14 w-100 d-flex justify-content-center cell-text'>{employeeRange}</Box>;
  };
  const revenueRange = params => {
    const currency = params.data?.currency_code;
    const unit = params.data?.currency_unit_trans_value?.short_desc;
    const fromRange = params?.data?.revenue_range_from || '';
    const toRange = params?.data?.revenue_range_to || '';
    let sign = '';
    if (fromRange && toRange) {
      sign = '-';
    }
    const revenue = [fromRange, sign, toRange];
    if (fromRange || toRange) {
      revenue.unshift(currency);
      revenue.push(unit);
    }
    const revenueRange = revenue.join(' ');

    return <Box className='fs-14 w-100 d-flex justify-content-center cell-text'>{revenueRange}</Box>;
  };
  const websiteUrlRenderer = params => {
    return (
      <Box className='fs-14 details-width'>
        {/* {params.data?.linkedin_url && ( */}
        <Link rel='noopener noreferrer' target='_blank' href={addHttps(params.data?.website_url)} className='link'>
          {params.data?.website_url}
        </Link>
        {/* )} */}
      </Box>
    );
  };
  const ownerRenderer = params => {
    return <Box className='fs-14  w-100 d-flex justify-content-center cell-text'>{params?.data?.created_user?.name}</Box>;
  };
  const emailRenderer = params => {
    const primaryEmail = params.data?.company_emails.find(emailObj => emailObj.is_primary === true);
    return <Box className='fs-14 details-width'>{primaryEmail?.email}</Box>;
  };
  const phoneRenderer = params => {
    const primaryPhone = params.data?.company_phones.find(phoneObj => phoneObj.is_primary === true);
    return <Box className='fs-14 w-100 d-flex justify-content-center cell-text'>{primaryPhone?.phone_number}</Box>;
  };
  const capitalStructureRenderer = params => {
    return <Box className='fs-14 w-100 d-flex justify-content-center cell-text'>{params?.data?.capital_struct_trans_value?.short_desc}</Box>;
  };
  const addressRenderer = params => {
    const primaryAddress = params.data?.company_address.find(addressObj => addressObj.is_primary === true);
    const addressArray = [
      primaryAddress?.address_lines || '',
      primaryAddress?.metropolitan_area || '',
      primaryAddress?.city || '',
      primaryAddress?.company_state?.name || '',
      primaryAddress?.company_country?.name || '',
      primaryAddress?.zip_code || ''
    ];
    const newAddress = addressArray.filter(item => item !== '');
    const commaSeparatedAddress = newAddress.join(', ');

    return (
      <Tooltip placement='bottom-start' title={commaSeparatedAddress}>
        <Box className='fs-14 details-width'>{commaSeparatedAddress}</Box>
      </Tooltip>
    );
  };
  const closeAddCompanyPopup = () => {
    setIsPopupOpen(false);
  };
  const closeConfirmationPopup = () => {
    setOpenConfirmationPopup(false);
  };
  const saveAddCompanyPopup = async () => {
    const companyId = await saveData();
    if (companyId) {
      setIsPopupOpen(false);
      navigate(`/companies/all-companies/${companyId}`);
      setIsDrawerOpen(true);
      // setIsUpdateCompany(true);
    }
  };
  const getPayload = data => {
    const addCompanyPayload = {
      ...data,
      company_tags: data?.company_tags ? data?.company_tags?.map(tag => ({ tag_id: tag.id })) : [],
      company_industries: data?.company_industries ? data?.company_industries?.map(industry => ({ industry_id: industry.id })) : [],
      capital_structure: data?.capital_structure?.field_value,
      currency_unit: data?.currency_unit?.field_value,
      company_status: DEFAULT_STATUS
    };
    return addCompanyPayload;
  };
  const isValidated = async requestBody => {
    let isValid = await createSchema.isValid(requestBody);
    if (!isValid) {
      let dirtyField = customFormValidator(requestBody, createRequiredField);
      if (dirtyField) {
        const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    } else {
      return true;
    }
  };
  const isValidatedRevenue = async formValues => {
    if (formValues.revenue_range_from >= REVENUE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.revenue_from_massage, { variant: ERROR });
      return false;
    }
    if (formValues.revenue_range_to >= REVENUE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.revenue_to_massage, { variant: ERROR });
      return false;
    }
    if (formValues.employee_range_from >= REVENUE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.employee_from_massage, { variant: ERROR });
      return false;
    }
    if (formValues.employee_range_to >= REVENUE_MAX_LIMIT) {
      enqueueSnackbar(FIELDS.employee_to_massage, { variant: ERROR });
      return false;
    }
    return true;
  };
  const saveData = async () => {
    let formValues = { ...getValues() };
    const requestBody = getPayload(formValues);
    const IsValidRevenueRange = await isValidatedRevenue(formValues);
    const IsValidRequestBody = await isValidated(requestBody);
    if (IsValidRequestBody && IsValidRevenueRange) {
      setIsLoading(true);
      const { status, data } = await ignCompanyDataApi(POST, requestBody);

      if (status === 201) {
        const message = translateMessage('Successfully', false, 'Company', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
        // setCompanyId(data.id);
        setIsPopupOpen(false);
        reset();
        setIsCompanyAdd(true);
        setIsLoading(false);
        return data.id;
      } else if (status === 409) {
        const message = translateMessage('Duplicate', false, 'Company Name');
        enqueueSnackbar(message, {
          variant: ERROR
        });
      } else {
        const message = translateMessage('UnableMessage', false, 'Create', 'Company');
        enqueueSnackbar(message, { variant: ERROR });
      }
      setIsLoading(false);
    }
  };
  const handleBulkDelete = async companyData => {
    try {
      setIsLoading(true);
      const ids = companyData?.map(dataItem => dataItem.id);
      const { status, data } = await ignCompanyDataApi(POST, { id: ids }, SUB_ROUTES.bulkDelete);
      console.log(data);
      if (status === 200) {
        setIsLoading(false);
        const message = successMessage('Companies', 'Deleted');
        enqueueSnackbar(message, { variant: SUCCESS });
        gridApi && gridApi.onFilterChanged();
        setIsRowSelected(false);
      } else {
        const message = translateMessage('UnableMessage', false, 'Companies', 'delete');
        enqueueSnackbar(message, { variant: ERROR });
        setIsLoading(false);
      }
    } catch (e) {
      console.log('Error found in bulkdelete companies::', e);
    }
  };
  const commentRenderer = params => {
    const comments = params.data?.comments ? parse(params.data?.comments) : '';
    return (
      <Tooltip placement='bottom-start' title={comments}>
        <Box className='cell-text fs-14 content-wrap '>{comments}</Box>
      </Tooltip>
    );
  };
  const locationRenderer = params => {
    const location = params?.data?.location_description;
    return (
      <Tooltip title={location}>
        <Box className='fs-14 content-wrap'>{location}</Box>
      </Tooltip>
    );
  };
  const addCompany = () => {
    setIsPopupOpen(true);
    navigate(ROUTES.addCompany);
  };
  const industriesRenderer = params => {
    const industriesList = params.data?.company_industries?.map(industry => {
      return industry.industries?.name;
    });
    if (Array.isArray(industriesList)) {
      const industries = industriesList.join(', ');
      return (
        <Tooltip title={industries}>
          <Box className='fs-14 content-wrap'>{industries}</Box>
        </Tooltip>
      );
    }
    return '';
  };
  const getURLs = {
    listURl: `${IGN_API.company}`,
    quickQuery: `${IGN_API.company}`
  };
  let globalData = [];
  const dataSource = {
    getRows: async params => {
      try {
        setIsLoading(true);
        const thisValue = {
          quickQuery: quickQueryRef.current,
          isSelectedFromList: isSelectedListRef.current
        };
        const { sortModel, filterModel } = params;

        console.log('sortModel, filterModel==', sortModel, filterModel);

        if ((filterModel && Object.keys(filterModel).length > 0) || (sortModel && sortModel.length > 0)) {
          const { data, count } = applyClientSideSortingAndFiltering(globalData, filterModel, sortModel);
          // Set the success callback with the filtered/sorted data
          setAllCompanies([...data]);
          params.successCallback(data, count);

          // Update necessary state variables
          setRowCount(count);
          setLoadedContactCount(count);
          setIsComponentLoaded(true);
          setIsLoading(false);
          return;
        }

        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: getURLs,
          contactsScreen: true
        };
        const getData = await dataSourceUtils(obj, getFilterParamString, getQueryParamsString);
        const { status, data } = getData;
        const rowData = data?.rows ? data.rows : data.data ? data.data : data?.companies ? data?.companies : [];
        if (status === SUCCESS_STATUS_CODE) {
          if (data?.paging) {
            if (data?.paging?.totalCount === 0) {
              const message = notFoundMessage('records');
              enqueueSnackbar(message, { variant: WARNING });
            }
            setAllCompanies([...data.data]);
            params.successCallback(data?.data, data?.data?.length);
            setRowCount(data?.data?.length);
            setLoadedContactCount(0);
          } else if (Array.isArray(rowData)) {
            const message = notFoundMessage('records');
            if (data?.length === 0) {
              enqueueSnackbar(message, { variant: WARNING });
            }
            globalData = [...globalData, ...rowData];
            setAllCompanies([...rowData]);
            params.successCallback(rowData, data?.total);
            setRowCount(data?.total);
            setLoadedContactCount(data?.totalRowCount);
          }
        } else {
          params.failCallback();
        }
        setIsComponentLoaded(true);
        setIsLoading(false);
      } catch (e) {
        console.log('Error found in getRows::', e);
      }
      // localStorage.removeItem('contactColumns')
    },
    rowCount: null
  };

  const applyClientSideSortingAndFiltering = (data, filterModel, sortModel) => {
    let modifiedData = [...data];

    if (filterModel && Object.keys(filterModel).length > 0) {
      Object.keys(filterModel).forEach(filterField => {
        const filter = filterModel[filterField];
        const filterValue = filter?.filter?.toLowerCase();

        if (filterValue) {
          modifiedData = modifiedData.filter(row => {
            let rowValue = '';

            if (filterField === 'location') {
              rowValue = (row.location_description || '').toString().toLowerCase();
            } else if (filterField === 'industry') {
              rowValue =
                row.company_industries
                  ?.map(industryObj => industryObj.industries?.name || '')
                  .join(', ')
                  .toLowerCase() || '';
            } else if (filterField === 'name') {
              rowValue = (row.name || '').toString().toLowerCase();
            } else if (filterField === 'employee') {
              rowValue = (row.employee_range || '').toString().toLowerCase();
            } else if (filterField === 'revenue') {
              rowValue = (row.revenue_range || '').toString().toLowerCase();
            } else if (filterField === 'website_url') {
              rowValue = (row.website_url || '').toString().toLowerCase();
            } else if (filterField === 'owner') {
              rowValue = (row.created_user?.name || '').toString().toLowerCase();
            } else {
              rowValue = (row[filterField] || '').toString().toLowerCase(); // Default behavior for other fields
            }

            return rowValue.includes(filterValue);
          });
        }
      });
    }

    if (sortModel && sortModel.length > 0) {
      sortModel.forEach(sortItem => {
        const sortField = sortItem.colId;
        const sortDirection = sortItem.sort; // 'asc' or 'desc'

        modifiedData.sort((a, b) => {
          let aValue = '';
          let bValue = '';

          if (sortField === 'location') {
            aValue = (a.location_description || '').toString().toLowerCase();
            bValue = (b.location_description || '').toString().toLowerCase();
          } else if (sortField === 'industry') {
            aValue =
              a.company_industries
                ?.map(industryObj => industryObj.industries?.name || '')
                .join(', ')
                .toLowerCase() || '';
            bValue =
              b.company_industries
                ?.map(industryObj => industryObj.industries?.name || '')
                .join(', ')
                .toLowerCase() || '';
          } else if (sortField === 'name') {
            aValue = (a.name || '').toString().toLowerCase();
            bValue = (b.name || '').toString().toLowerCase();
          } else if (sortField === 'employee') {
            aValue = (a.employee_range || '').toString().toLowerCase();
            bValue = (b.employee_range || '').toString().toLowerCase();
          } else if (sortField === 'revenue') {
            aValue = (a.revenue_range || '').toString().toLowerCase();
            bValue = (b.revenue_range || '').toString().toLowerCase();
          } else if (sortField === 'website_url') {
            aValue = (a.website_url || '').toString().toLowerCase();
            bValue = (b.website_url || '').toString().toLowerCase();
          } else if (sortField === 'owner') {
            aValue = (a.created_user?.name || '').toString().toLowerCase();
            bValue = (b.created_user?.name || '').toString().toLowerCase();
          } else {
            aValue = (a[sortField] || '').toString().toLowerCase();
            bValue = (b[sortField] || '').toString().toLowerCase();
          }

          if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
          if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
          return 0;
        });
      });
    }
    return { data: modifiedData, count: modifiedData.length };
  };

  const handleSearchChange = (event, value) => {
    setIsClassicQuery(false);
    setIsSelectedFromList(false);

    if (typeof value === 'string') {
      setQuickQuery(value);
      setSearchValue(value);
    } else if (value && value.id) {
      setIsSelectedFromList(true);
      setQuickQuery(value.id);
      setSearchValue(value.name);
    }
    setQuickQuery(value?.id ? value?.id : value ? value : '', () => {
      gridApi.onFilterChanged();
    });
    resetFilter();
    resetSort();
  };

  const resetFilter = async () => {
    gridApi.setFilterModel(null);
  };

  const resetSort = async () => {
    gridApi.setSortModel(null);
  };

  const companyColumnsExports = [
    { key: 'name', label: 'Company Name' },
    { key: 'location_description', label: 'Location' },
    { key: 'company_status', label: 'Status' },
    // { key: 'company_industries', label: 'Company Industries' },
    { key: 'employee_range_to', label: 'Employee Range' },
    { key: 'revenue_range_to', label: 'Revenue Range' },
    { key: 'linkedin_url', label: 'Linkedin Url' },
    { key: 'website_url', label: 'Website Url' },
    { key: 'created_at', label: 'Created At' },
    { key: 'updated_at', label: 'Updated At' },
    { key: 'created_by', label: 'Created By' },
    { key: 'updated_by', label: 'Updated By' },
    { key: 'comments', label: 'Comments' },
    { key: 'currency_code', label: 'Currency Code' },
    { key: 'company_emails', label: 'Company Emails' },
    { key: 'company_phones', label: 'Company Phone' }
  ];

  const companyExportColumnsArrayMapping = {
    // 'company_industries': 'industries.name',
  };

  console.log('dataSource==', globalData);

  return (
    <Box id='company-tab-container' className='list-contacts p-4 d-flex flex-column'>
      <Loader show={isLoading} />
      <Helmet>
        <title>Companies - {IGNYTE_GLOBALS.BRAND}</title>
      </Helmet>

      <Box className='tool-bar mb-3'>
        <AppBar position='static'>
          <Toolbar className='pb-4'>
            <Search value={searchValue} onChange={handleSearchChange} type={'companies'} className='search-bar' placeholder={'Search'} />

            <Box className='tool-bar' />
            <Stack direction='row' spacing={3}>
              {isRowSelected && selectedRows !== 0 ? (
                <CompanyGridSelection
                  handleAssignTag={handleAssignTag}
                  // handleBulkChangeStatus={handleChangeStatus}
                  handleBulkDelete={handleBulkDelete}
                  //handleAddToProject={handleAddToProject}
                  data={selectedRows}
                  // id={params?.data?.id}
                  //setIsAddToProjectOpen={setIsAddToProjectOpen}
                />
              ) : null}
              {/* <Link
                to="/companies/business-development/add"
              >
                <CustomButton
                  variant="contained"
                  type={"primary"}
                  size={"medium"}
                  buttonText={"Add BD"}
                  customWidth={110}
                  iconLeft={<AddIcon sx={{ width: 20, height: 20 }} />}
                />
              </Link> */}

              {/* <CustomButton
                // onClick={toggleMenu}
                type={"secondary"}
                size={"medium"}
                variant="outlined"
                disabled={!isRowSelected}
                width={42}
                customWidth={42}
                iconLeft={<IosShareIcon sx={{ width: 20, height: 20 }} />}
              /> */}
              <ExportCSV iconHeight={26} iconWidth={50} columns={companyColumnsExports} data={allCompanies} arrayKeyMapping={companyExportColumnsArrayMapping} fileName='Companies.csv' />
              <CustomButton
                variant='contained'
                onClick={() => {
                  addCompany();
                }}
                type={'primary'}
                size={'medium'}
                buttonText={'Add Company'}
                customWidth={110}
                iconLeft={<AddIcon sx={{ width: 20, height: 20 }} />}
              />
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
      <Box className='d-flex justify-content-end mb-3'>
        <TotalCount rowCount={rowCount} selectedRowsCount={selectedRowsCount} isRowSelected={isRowSelected} loadedContactCount={loadedContactCount} showTotalCount={false} />
      </Box>
      <CompanyAssignTag
        tagPopUp={tagPopUp}
        companyId={tagDataId}
        isBulkTag={isBulkTag}
        setIsPopupOpen={setIsTagPopupOpen}
        isPopupOpen={isTagPopupOpen}
        setTagChange={setTagChange}
        //label={t(`${TRANS_KEYS.CONTACTS_SELECT_TAG}:key`)}
        selectedRows={selectedRows}
        // isAllRowsSelected={isAllRowsSelected}
      />
      <Box className='list-view'>
        {isGridReady && (
          <ColumnFilter
            columnApi={columnApi}
            defaultColumns={defaultColumns}
            //showFilterCount={showFilterCount}
            //filterModel={filterModel}
          />
        )}
        <Box className='ag-theme-alpine header-column-filter'>
          <AgGridReact
            onGridReady={onGridReady}
            // enableBrowserTooltips={true}
            defaultColDef={{
              width: 1501,
              resizable: true,
              sortable: false,
              sortingOrder: ['asc', 'desc', null]
            }}
            rowHeight={65}
            datasource={dataSource}
            frameworkComponents={{
              NameRenderer: nameRenderer,
              EmployeeRangeRenderer: employeeRangeRendrer,
              RevenueRange: revenueRange,
              WebsiteUrlRenderer: websiteUrlRenderer,
              GetActionRenderer: getActionRenderer,
              TagRenderer: getTags,
              IndustriesRenderer: industriesRenderer,
              CommentRenderer: commentRenderer,
              LocationRenderer: locationRenderer,
              EmailRenderer: emailRenderer,
              HeaderCheckbox: headerCheckbox,
              PhoneRenderer: phoneRenderer,
              AddressRenderer: addressRenderer,
              CapitalStructureRenderer: capitalStructureRenderer,
              OwnerRenderer: ownerRenderer
            }}
            isRowSelectable={params => {
              const status = checkContactFetchingStatus(params);
              if (status) return false;
              return true;
            }}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            blockLoadDebounceMillis={SCROLL_TIMEOUT}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            suppressDragLeaveHidesColumns={true}
            rowModelType={'infinite'}
            columnDefs={columnDefs()}
            suppressMenuHide={true}
            paginationPageSize={PAGE_LIMIT}
            rowSelection={'multiple'}
            getRowNodeId={data => data.id}
            onRowSelected={() => {
              // if (!e.node.selected) {
              //   this.setState({ isAllRowsSelected: false })
              // }
              setSelectedRowsCount(gridApi?.getSelectedRows()?.length);
              setIsRowSelected(gridApi?.getSelectedRows()?.length > 0);
              setSelectedRows(gridApi?.getSelectedRows());
            }}
            suppressRowClickSelection={true}
            // getRowClass={getRowClass}
          ></AgGridReact>
        </Box>
      </Box>
      <AddCompany
        isPopupOpen={isPopupOpen}
        handleClose={closeAddCompanyPopup}
        handleSubmit={saveAddCompanyPopup}
        setIsLoading={setIsLoading}
        setValue={setValue}
        register={register}
        reset={reset}
        setIsPopupOpen={setIsPopupOpen}
        unregister={unregister}
        watch={watch}
      ></AddCompany>
      <Box id='view-contact-drawer'>
        <ViewCompanyDrawer
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          id={id}
          setIsEdited={setIsEdited}
          options={options}
          gridApi={gridApi}
          isUpdateCompany={isUpdateCompany}
          setIsUpdateCompany={setIsUpdateCompany}
        />
      </Box>
      {/* <AddCompany setIsPopupOpen={setIsPopupOpen} isPopupOpen={isPopupOpen}></AddCompany> */}
      <CustomPopover
        id='mouse-over-popover-tags'
        open={openTagsViewMore}
        anchorEl={anchorElTagsHoverPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={closeTagsPopup}
        pointerEvents='none'
      >
        <Box onMouseEnter={event => event.stopPropagation()} sx={{ pointerEvents: 'auto' }}>
          {tagListRef.current && (
            <Box>
              {tagListRef?.current?.map((tag, index) => (
                <Box
                  key={index}
                  className={'tag-border m-1 p-1'}
                  sx={{
                    color: `${tag?.ign_tag?.color}`
                  }}
                >
                  {tag?.ign_tag?.name}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </CustomPopover>
      <CustomConfirmationPopup
        open={openConfirmationPopup}
        onClose={closeConfirmationPopup}
        type='delete'
        deletingItem='Company'
        deletingItemName={companyDetails?.name}
        cancelText='No'
        confirmText='Yes'
        onConfirm={onConfirm}
        setOpen={setOpenConfirmationPopup}
      />
      <CompanyChangeStatus isPopupOpen={isStatusPopupOpen} setIsPopupOpen={setIsStatusPopupOpen} companyStatus={companyStatus} setChange={setStatusChange}></CompanyChangeStatus>
    </Box>
  );
};

export default List;
