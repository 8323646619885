import { SearchRounded } from '@mui/icons-material';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { Box, Grid, Tooltip } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/common/Loader';
import CustomInputField from '../../components/common/StyledComponents/CustomInputField';
import { CandidateStageGroupAPi, ClientDispositionMap, ignContactDataApi, ProjectCandidateApi, ProjectCandidateDataApi, ProjectDataApi } from '../../services/ApiService';
import { CLIENT_SUITE_FEEDBACL_SUCCESS, GET, PATCH, POST } from '../../services/constantService';
import CustomModalWithHeader from '../Commons/CustomModalWithHeader';
import styles from './../../variables.scss';
import CandidateCard from './components/CandidateCard/CandidateCard';
import ClientSuiteHeader from './components/Header/ClientSuiteHeader';
import Modal from './components/Modal/Modal';
import ClientSuiteJobDetails from './containers/ClientSuiteJobDetails/ClientSuiteJobDetails';
import './index.scss';

const ClientSuite = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loaderFeedback, setLoaderFeedback] = useState(false);

  // const [apiQuery, setApiQuery] = useState({
  //   query: {
  //     order: [["job_number", "DESC"]],
  //   },
  //   type: "all-projects",
  //   countForStages: [],
  // });

  const [feedbackModal, setFeedbackModal] = useState(false);

  // console.log({ projects, loader, setApiQuery });
  const [candidates, setCandidates] = useState([]);
  const [selectedProject, setSelectedProject] = useState(projects[0] ?? []);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [candidateStats, setCandidateStats] = useState([]);
  const [selectedGroupStat, setSelectedGroupStat] = useState('');
  const [searchTextProject, setSearchTextProject] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [searchTextCandidates, setSearchTextCandidates] = useState('');
  const [selectedMenuItemKey, setSelectedMenuItemKey] = useState('');

  const fetchProjects = async () => {
    try {
      setLoader(true);
      const projectData = await ProjectDataApi(GET, '', {}, 'client-portal');
      if (projectData?.data) {
        setProjects(projectData?.data);
        if (projectData?.data.length > 0) {
          setSelectedProject(projectData?.data[0]);
        }
        setLoader(false);
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    }
  };

  const onClickCandidate = candidate => {
    setSelectedCandidate(candidate);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedCandidate({});
  };

  const fetchCandidate = async (group = '') => {
    try {
      setLoader(true);
      const projectId = selectedProject?.id;
      if (projectId) {
        if (group.length > 0) {
          const candidates = await ProjectCandidateDataApi(GET, '', '', `all-by-stage-group?project_id=${projectId}&stage_group=${group}`);
          console.log('candidates', candidates);
          setCandidates(candidates?.data?.rows);
        } else {
          const candidates = await ProjectCandidateDataApi(GET, '', '', `all?onlyGroupedCandidates=true&projectId=${projectId}&hideHiddenCandidate=true`);
          console.log('candidates', candidates);
          setCandidates(candidates?.data?.rows);
        }
      }
      //Todo Pagination
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const handleFeedBackSave = async data => {
    try {
      setLoaderFeedback(true);
      console.log({ feedback: data, selectedCandidate });
      let payload = {};
      if (data?.type === 'rejected') {
        let listOfReasons = data.reason ? JSON.stringify(data.reason.map(item => item.short_desc)) : '[]';
        payload = {
          project_id: selectedProject?.id,
          notes_type: 'client_feedback',
          notes: data?.comment,
          activity_type: 'client_feedback',
          activity_added_at: new Date(),
          // "created_by": "c384fc0c-e967-457a-b7ad-18a4a8d14430", //if error new Data()
          // "updated_by": "c384fc0c-e967-457a-b7ad-18a4a8d14430",// same
          internal_attendees: null,
          external_attendees: null,
          contact_id: selectedCandidate?.contact_id ?? '',
          rating: data?.rating,
          outcome_reason: listOfReasons,
          client_feedback: 'rejected'
        };
      } else {
        payload = {
          project_id: selectedProject?.id,
          notes_type: 'client_feedback',
          notes: data?.comment,
          activity_type: 'client_feedback',
          activity_added_at: new Date(),
          // "created_by": "c384fc0c-e967-457a-b7ad-18a4a8d14430", //if error new Data()
          // "updated_by": "c384fc0c-e967-457a-b7ad-18a4a8d14430",// same
          internal_attendees: null,
          external_attendees: null,
          contact_id: selectedCandidate?.contact_id ?? '',
          rating: data?.rating,
          outcome_reason: data?.nextStep,
          client_feedback: 'approved'
        };
      }

      const res = await ignContactDataApi(POST, '', payload, 'create-activity');
      console.log('share feedback', res);

      //candidate disposition
      const disposition = await ClientDispositionMap(GET, `?project_id=${selectedProject?.id}&disposition_name=${data?.type}`);
      console.log({ disposition });

      const dispositionStageArray = disposition?.data;
      if (dispositionStageArray.length > 0) {
        const stage = dispositionStageArray[0];
        const updateStagePayload = {
          id: selectedCandidate?.id,
          stage: stage?.stage?.stage_name
        };

        const candidateStageResponse = await ProjectCandidateApi(PATCH, '', updateStagePayload);
        console.log({ candidateStageResponse });
      }

      setFeedbackModal(false);
      enqueueSnackbar(CLIENT_SUITE_FEEDBACL_SUCCESS, 'success');
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoaderFeedback(false);
    }
  };

  const fetchStats = async () => {
    try {
      setLoader(true);
      const projectId = selectedProject?.id;
      if (projectId) {
        const candidateStats = await CandidateStageGroupAPi(GET, `/all-candidates-count-by-stage-group?projectId=${projectId}`);

        const stages = candidateStats?.data?.stageGroups ?? [];
        const sortedStages = stages.sort((a, b) => +a.long_desc - +b.long_desc);
        setCandidateStats(sortedStages);
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const handleStatClick = data => {
    if (selectedGroupStat === data?.field_value) {
      setSelectedGroupStat('');
    } else {
      setSelectedGroupStat(data?.field_value);
    }
  };

  const handleSearchValue = value => {
    console.log({ searchValue: value });
    setSearchTextProject(value);
    if (value.length > 0) {
      const filteredProjects = projects.filter(project => project.name.toLowerCase().includes(value.toLowerCase()));
      console.log({ filteredProjects });
      setFilteredProjects(filteredProjects);
    }
  };

  const handleSearchValueCandidate = value => {
    console.log({ searchValue: value });
    setSearchTextCandidates(value);
    if (value.length > 0) {
      const filteredCandidates = candidates.filter(
        candidate => candidate.contact.first_name.toLowerCase().includes(value.toLowerCase()) || candidate.contact.last_name.toLowerCase().includes(value.toLowerCase())
      );
      console.log({ filteredCandidates });
      setFilteredCandidates(filteredCandidates);
    }
  };

  const handleOnClickMenuItems = key => {
    console.log({ handleOnClickMenuItems: key });
    setSelectedMenuItemKey(key);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    fetchCandidate(selectedGroupStat);
  }, [selectedGroupStat]);

  useEffect(() => {
    fetchCandidate();
    fetchStats();
  }, [selectedProject]);

  const openWidget = () => {
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        color: {
          theme: styles.error,
          button: styles.error
        }
      }
    });
    //toggleMenu()
  };

  return (
    <Grid container className='w-100 p-4' spacing={2}>
      <Loader show={loader} />
      <Grid item xs={2} md={2}>
        <Box className={'background-white w-100 pt-2 pr-1 border shadow rounded'}>
          <div className='fs-14 pl-2'>{t('module.projects')}</div>
          <CustomInputField
            id='outlined-basic'
            className='header-input p-1 w-100'
            hiddenLabel={true}
            // label="Search"
            fullWidth={true}
            size={'small'}
            startIcon={<SearchRounded />}
            value={searchTextProject}
            onChange={e => handleSearchValue(e.target.value)}
          />
          <div className='side-bar-projects'>
            {searchTextProject.length > 0 &&
              filteredProjects.map(project => (
                <Box
                  className={`w-100 m-1 p-2 cursor-pointer ${selectedProject?.id == project?.id ? 'selected-project border shadow rounded' : ''}`}
                  key={project.id}
                  onClick={() => setSelectedProject(project)}
                >
                  <div className='fs-12 d-flex'>
                    <div>{project.job_title}</div>
                    <Tooltip title={`Pending Review ${project?.pending_review_count}`}>
                      <div className='mr-1'>{project?.pending_review_count && project?.pending_review_count > 0 ? `(${project?.pending_review_count})` : ''}</div>
                    </Tooltip>
                  </div>
                  <div className='fs-10'>{project?.ign_companies?.name}</div>
                </Box>
              ))}
            {searchTextProject.length == 0 &&
              projects.map(project => {
                if (project?.ign_companies?.name && project.job_title)
                  return (
                    <Box
                      className={`w-100 m-1 p-2 cursor-pointer ${selectedProject?.id == project?.id ? 'selected-project border shadow rounded' : ''}`}
                      key={project.id}
                      onClick={() => setSelectedProject(project)}
                    >
                      <div className='fs-12 d-flex justify-content-between'>
                        <div>{project.job_title}</div>
                        <Tooltip title={`Pending Review ${project?.pending_review_count}`}>
                          <div className='mr-1'>{project?.pending_review_count && project?.pending_review_count > 0 ? `(${project?.pending_review_count})` : ''}</div>
                        </Tooltip>
                      </div>
                      <div className='fs-10'>{project?.ign_companies?.name}</div>
                    </Box>
                  );
              })}
          </div>
        </Box>
      </Grid>
      <Grid item xs={10} md={10} className='w-100'>
        <div className='client-setup'>
          <ClientSuiteHeader projectId={selectedProject?.id} onChangeSearch={handleSearchValueCandidate} onClickMenuItem={handleOnClickMenuItems} />
          <div className='d-flex justify-content-between'>
            {candidateStats &&
              candidateStats.map(data => (
                <div
                  key={data.name}
                  className={`pt-4 pb-4 pl-3 pr-3 d-flex flex-row rounded stat-card mt-4 justify-content-between cursor-pointer ${data?.field_value === selectedGroupStat ? 'selected-stat' : ''}`}
                  style={{
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '14px',
                    borderLeftColor: data?.color
                  }}
                  onClick={() => handleStatClick(data)}
                >
                  <div className='fs-14'>{data?.custom_label?.label || data?.short_desc}</div>
                  <div className='fs-14 ml-2 bold'>{data?.candidate_count}</div>
                </div>
              ))}
          </div>
          <Grid container fullWidth spacing={2} rowGap={2} className='mt-2 '>
            {searchTextCandidates.length > 0 &&
              filteredCandidates &&
              filteredCandidates.length > 0 &&
              filteredCandidates.map(data => <CandidateCard key={data?.id} candidate={data} onClick={onClickCandidate} />)}
            {searchTextCandidates.length == 0 && candidates && candidates.length > 0 && candidates.map(data => <CandidateCard key={data?.id} candidate={data} onClick={onClickCandidate} />)}
            {searchTextCandidates.length == 0 && candidates && candidates.length == 0 && (
              <div className=' mt-5 w-100 d-flex justify-content-center align-items-center'>Candidate Profiles shared with you will show up here. No Candidates added yet!</div>
            )}
          </Grid>
        </div>
      </Grid>
      <ClientSuiteJobDetails drawerOpen={drawerOpen} setDrawerOpen={handleDrawerClose} data={selectedCandidate} onClickFeedback={() => setFeedbackModal(true)} />
      <CustomModalWithHeader closeIcon={true} label={'Share Feedback'} onClose={() => setFeedbackModal(false)} isOpen={feedbackModal} colorClass={'theme-color-text'}>
        <Modal isLoader={loaderFeedback} onCancel={() => setFeedbackModal(false)} onSave={handleFeedBackSave} />
      </CustomModalWithHeader>
      <CustomModalWithHeader closeIcon={true} label={'Search Status'} onClose={() => setSelectedMenuItemKey('')} isOpen={selectedMenuItemKey === 'search_status'} colorClass={'theme-color-text'}>
        <div className='temp-modal d-flex justify-content-center align-items-center'>Search Status feature is currently Under development</div>
      </CustomModalWithHeader>
      <CustomModalWithHeader closeIcon={true} label={'Search Reports'} onClose={() => setSelectedMenuItemKey('')} isOpen={selectedMenuItemKey === 'search_reports'} colorClass={'theme-color-text'}>
        <div className='temp-modal d-flex justify-content-center align-items-center'>Search Reports feature is currently Under development</div>
      </CustomModalWithHeader>
      <CustomModalWithHeader closeIcon={true} label={'Team Info'} onClose={() => setSelectedMenuItemKey('')} isOpen={selectedMenuItemKey === 'team_info'} colorClass={'theme-color-text'}>
        <div className='temp-modal d-flex justify-content-center align-items-center'>Team Info feature is currently Under development</div>
      </CustomModalWithHeader>
      <CustomModalWithHeader
        closeIcon={true}
        label={'Candidate Compare'}
        onClose={() => setSelectedMenuItemKey('')}
        isOpen={selectedMenuItemKey === 'candidate_compare'}
        colorClass={'theme-color-text'}
      >
        <div className='temp-modal d-flex justify-content-center align-items-center'>Candidate Compare feature is currently Under development</div>
      </CustomModalWithHeader>
      <HelpRoundedIcon className={'help-desk'} onClick={openWidget} />
    </Grid>
  );
};

export default ClientSuite;
