//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Button, Checkbox, FormControl, FormControlLabel, TextField, Typography } from '@mui/material';
import ReactPhoneInput from 'react-phone-input-material-ui';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import ContactSelection from '../../../ContactSelection/ContactSelection';
import InputField from '../../../common/InputField';
import { determineInitialCountryGuess } from '../../../../utils/phone';
import ClientSuiteInvitePopUp from '../../../CreateSearch/Components/SearchInformation/Components/ClientSuiteInvitePopUp';
import AthenaInvitePopUp from '../SFPA/Components/ClientAppAccess/AthenaInvitePopUp';
import { ERROR } from '../../../../services/constantService';
import { VALIDATION_MESSAGE } from '../../../../services/MessageService';

function ClientContacts(props) {
  const { register, currentValues, setValue, renderActions, renderSectionLabel, editingField, watch } = props;
  const [hiringManagerIndex, setHiringManagerIndex] = useState(-1);
  // const [editIndex, setEditIndex] = useState(-1)
  // const [hoverIndex, setHoverIndex] = useState(-1)
  // const [isAdding, setIsAdding] = useState(false)
  // const [prevManagerIndex, setPrevManagerIndex] = useState(-1)
  const [clientContacts, setClientContacts] = useState([]);
  const [open, toggleOpen] = useState(false);
  const [clientSuiteInviteClient, setClientSuiteInviteClient] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isAthenaInviteOpen, toggleAthenaInviteOpen] = useState(false);
  const [selectedDropDown, setSelectedDropDown] = useState('');
  // const [updateHiringManager, setUpdateHiringManager] = useState(-1)
  // const [showHover, setShowHover] = useState(false)

  const { enqueueSnackbar } = useSnackbar();

  // useEffect(() => {
  //   setValue('client_team', clientContacts)
  // }, [clientContacts, setValue])

  // const setManagerIndex = (clientTeam) => {
  //   let hiringManagerIndex = -1;
  //   for (let index = 0; index < clientTeam?.length; index++) {
  //     if (clientTeam[index].is_hiring_manager) {
  //       hiringManagerIndex = index;
  //       break;
  //     }
  //   }
  //   setHiringManagerIndex(hiringManagerIndex)
  //   setPrevManagerIndex(hiringManagerIndex)
  // }

  // useEffect(() => {
  //   const clientTeam = currentValues.client_team
  //   if (Array.isArray(clientTeam)) {
  //     setManagerIndex(clientTeam)
  //     setClientContacts(clientTeam.map(item => ({ ...item })));
  //   }
  //   register('client_team');
  // }, [currentValues, register])

  const handleChange = (index, field, value) => {
    let previousData = [...clientContacts];
    previousData[index] = { ...previousData[index], [field]: value };
    setClientContacts(previousData);
  };

  // const handleContactChange = (data, index) => {
  //   let previousData = [...clientContacts]
  //   previousData[index] = { client_id: previousData[index].client_id, contact: data, ...data }
  //   setClientContacts(previousData);
  // }

  // const handleHiringManagerChange = (isChecked, index) => {
  //   const previousState = [...clientContacts]
  //   if (prevManagerIndex !== -1) {
  //     previousState[prevManagerIndex] = { ...previousState[prevManagerIndex], is_hiring_manager: false }
  //     setUpdateHiringManager(prevManagerIndex)
  //   }
  //   previousState[index] = { ...previousState[index], is_hiring_manager: isChecked }
  //   setPrevManagerIndex(index)
  //   setClientContacts(previousState)
  //   isChecked ? setHiringManagerIndex(index) : setHiringManagerIndex(-1);
  // }

  // const addClientContact = () => {
  //   setClientContacts(prevState => [...prevState, {}])
  // }

  // const removeClientContact = (index) => {
  //   setClientContacts(prevState => {
  //     const clientContacts = [...prevState]
  //     clientContacts.splice(index, 1)
  //     setManagerIndex(clientContacts)
  //     return clientContacts
  //   })
  // }

  // const handleClick = (event, client) => {
  //   setClientSuiteInviteClient(client);
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const openAthenaPopUp = (client, selectedDropDown) => {
  //   if (!client.contact?.work_email && !client.contact?.private_email) {
  //     enqueueSnackbar(VALIDATION_MESSAGE.athena_email, { variant: ERROR });
  //     return;
  //   }
  //   setAnchorEl(null);
  //   setSelectedDropDown(selectedDropDown);
  //   toggleAthenaInviteOpen(true);
  // }

  // const handleAdd = () => {
  //   if (isAdding) {
  //     const message = validateMessage("add", "complete")
  //     enqueueSnackbar(message, { variant: WARNING })
  //     return
  //   }
  //   // setEditIndex(clientContacts?.length)
  //   addClientContact()
  //   setIsAdding(true)
  // }

  // const getData = (index) => {
  //   const { client_id: id, is_hiring_manager, id: contact_id } = clientContacts[index]
  //   const data = { id, is_hiring_manager, position: index, contact_id }
  //   if (!isAdding) {
  //     const { client_id: id, is_hiring_manager, id: contact_id } = currentValues.client_team[index]
  //     data.old = { id, is_hiring_manager, position: index, contact_id }
  //   }
  //   return data
  // }
  // const getPreviousHiringManager = () => {
  //   const isHiringManager = currentValues.client_team.find(ele => ele.is_hiring_manager)
  //   if (isHiringManager) {
  //     const { client_id: id, id: contact_id, position } = isHiringManager
  //     const data = { id, is_hiring_manager: false, position, contact_id }
  //     return data
  //   }
  //   return false
  // }

  // const handleUpdate = async (index) => {
  //   try {
  //     if (!clientContacts[index].contact) {
  //       const message = validateMessage("required client contacts", "fill")
  //       enqueueSnackbar(message, { variant: ERROR });
  //       return;
  //     }
  //     if (clientContacts[index].contact) {
  //       const payload = getData(index)
  //       // if (currentValues.client_team?.length === clientContacts?.length) {
  //       // if (currentValues.client_team[index].work_email === clientContacts[index].work_email) {
  //       //   setIsAdding(false)
  //       //   setEditIndex(-1)
  //       //   return;
  //       // }
  //       const isValidEmail = validateWorkEmail(clientContacts[index].work_email)
  //       if (!isValidEmail) return;
  //       // }
  //       setInitialLoader(true)
  //       const { status, data } = await searchDataApi(PUT, currentValues.id, payload, sub_route);
  //       if (status === 200) {
  //         const message = successMessage("saved", "Client team")
  //         enqueueSnackbar(message, { variant: SUCCESS });
  //         if (updateHiringManager !== -1 && updateHiringManager !== index) {
  //           const payload = getPreviousHiringManager()
  //           if (payload) {
  //             await searchDataApi(PUT, currentValues.id, payload, sub_route);
  //           }
  //           setUpdateHiringManager(-1);
  //         }

  //         await updateContactDetails([clientContacts[index]], enqueueSnackbar)
  //         setInitialLoader(false)

  //         await reloadData(true);

  //         dispatch({ type: UPDATE_SFPADATA, payload: { key: 'sfpaData', undefined } })
  //         if (isAdding) {
  //           // commented due to already reloaded data to get letest currentValues
  //           // handleChange(index, 'id', data.id)
  //           setIsAdding(false)
  //         }
  //       } else {
  //         const message = unableMessage("client team", "update")
  //         enqueueSnackbar(data?.message || message, { variant: ERROR })
  //       }
  //     } else {
  //       const { status } = await searchDataApi(DELETE, currentValues.id, { id: clientContacts[index].client_id }, sub_route);
  //       if (status === 200) {
  //         removeClientContact(index)
  //         await reloadData(true)
  //       }
  //     }
  //     setEditIndex(-1)
  //   } catch (e) {
  //     console.log("Error found in handleUpdate::", e);
  //   }
  // }

  // const validateWorkEmail = (email) => {
  //   if (email && !email.match(EMAIL_REGEX)) {
  //     const message = validateMessage("valid work email", "enter")
  //     enqueueSnackbar(message, { variant: ERROR });
  //     return false
  //   }
  //   return true
  // }

  // const deleteBoardCompany = async (index) => {
  //   try {
  //     debugger
  //     const { status, data } = await searchDataApi(DELETE, currentValues.id, { id: clientContacts[index].client_id }, sub_route);
  //     if (status === 200) {
  //       const message = successMessage("Deleted", "")
  //       enqueueSnackbar(message, { variant: SUCCESS });
  //       removeClientContact(index)
  //       await reloadData(true)
  //     } else {
  //       const message = unableMessage("client contact", "delete")
  //       enqueueSnackbar(data?.message || message, { variant: ERROR })
  //     }
  //     setEditIndex(-1)
  //   } catch (e) {
  //     console.log("Error found in deleteBoardCompany::", e);
  //   }
  // }

  // const handleCancel = async (index) => {
  //   if (isAdding) {
  //     removeClientContact(index)
  //     setIsAdding(false)
  //   } else {
  //     setClientContacts(prevState => {
  //       prevState[index] = { ...currentValues.client_team[index] }
  //       setManagerIndex(prevState)
  //       return [...prevState]
  //     })
  //   }
  //   await reloadData(true)
  //   setUpdateHiringManager(-1);
  //   setEditIndex(-1)
  // }

  // const renderEditIcon = (index) => {
  //   return !(editIndex === index) &&
  //     <EditIcon className='edit-icon cursor-pointer' onClick={async () => {
  //       // if (isAdding) {
  //       //   const message = validateMessage("add", "complete")
  //       //   enqueueSnackbar(message, { variant: WARNING })
  //       //   return
  //       // }
  //       // await reloadData(true)
  //       setEditIndex(index)
  //     }} fontSize='inherit' color='secondary' />
  // }

  // const renderActions = (index) => {
  //   return (
  //     editIndex === index ?
  //       <div className='ml-2' style={{ fontSize: 16 }}>
  //         <DoneIcon cursor='pointer' fontSize="inherit" color='primary' onClick={() => handleUpdate()} />
  //         <CloseIcon cursor='pointer' fontSize="inherit" onClick={() => handleCancel(index)} />
  //       </div>
  //       :
  //       renderEditIcon(index)
  //   )
  // }
  // const handleMouseEnter = () => {
  //   setShowHover(true)
  // }
  // const handleMouseLeave = () => {
  //   setShowHover(false)
  // }
  // const renderLabel = (label, field, required = false, tooltip = false, message) => {
  //   return <InputLabel required={required} className="contact-details-view-label mr-2 cursor-pointer">
  //     {label}
  //     {tooltip &&
  //       <span className='m-1'>
  //         <Tooltip title={message}><InfoIcon style={{ fontSize: '1rem' }} /></Tooltip>
  //       </span>}
  //     {renderEditIcon(field)}
  //   </InputLabel>
  // }
  // const renderSubHeader = (index) => {
  //   return (
  //     <div className="secion-sub-header d-flex">
  //       <span>{index + 1}</span>
  //       {renderActions(index)}
  //     </div>
  //   )
  // }

  useEffect(() => {
    setValue('client_team', clientContacts);
  }, [clientContacts, setValue]);

  const setManagerIndex = clientTeam => {
    let hiringManagerIndex = -1;
    for (let index = 0; index < clientTeam?.length; index++) {
      if (clientTeam[index].is_hiring_manager) {
        hiringManagerIndex = index;
        break;
      }
    }
    setHiringManagerIndex(hiringManagerIndex);
    // setPrevManagerIndex(hiringManagerIndex)
  };

  useEffect(() => {
    const clientTeam = currentValues.client_team;
    if (Array.isArray(clientTeam)) {
      setManagerIndex(clientTeam);
      setClientContacts(clientTeam);
    }
    register('client_team');
  }, [currentValues, register]);

  const handleContactChange = (data, index) => {
    let previousData = [...clientContacts];
    previousData[index] = { contact: data, ...data };
    setClientContacts(previousData);
  };

  const handleHiringManagerChange = (isChecked, index) => {
    let previousState = [...clientContacts];
    if (hiringManagerIndex !== -1) {
      previousState[hiringManagerIndex] = { ...previousState[hiringManagerIndex], is_hiring_manager: false };
    }
    previousState[index] = { ...previousState[index], is_hiring_manager: isChecked };
    setClientContacts(previousState);
    isChecked ? setHiringManagerIndex(index) : setHiringManagerIndex(-1);
  };

  const addClientContact = () => {
    setClientContacts(prevState => [...prevState, {}]);
  };

  const removeClientContact = index => {
    setClientContacts(prevState => {
      const clientContacts = [...prevState];
      clientContacts.splice(index, 1);
      setManagerIndex(clientContacts);
      return clientContacts;
    });
  };

  const handleClick = (event, client) => {
    setClientSuiteInviteClient(client);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openAthenaPopUp = (client, selectedDropDown) => {
    if (!client.contact?.work_email && !client.contact?.private_email) {
      enqueueSnackbar(VALIDATION_MESSAGE.athena_email, { variant: ERROR });
      return;
    }
    setAnchorEl(null);
    setSelectedDropDown(selectedDropDown);
    toggleAthenaInviteOpen(true);
  };

  const inputClass = editingField === 'client_team' ? 'col-3' : 'col-2';
  const hiringClass = editingField === 'client_team' ? 'col-2' : 'col-1';

  const renderEdit = (item, index) => {
    return (
      <div className='d-flex flex-grow-1' id='client-contacts'>
        <div className='d-flex flex-column col-12'>
          <div className={'table-content p-0 '}>
            <div className='d-flex align-items-center' key={index}>
              <div className='col-0-5 align-item-center team-info-field'>
                <span>{index + 1}.</span>
              </div>
              <div className={`${inputClass} align-item-center team-info-field`}>
                {editingField === 'client_team' ? (
                  <ContactSelection
                    variant='outlined'
                    //autoFocus={true}
                    defaultValue={item.contact || null}
                    className=''
                    label={''}
                    list={watch('client_team') || []}
                    disabled={editingField === 'client_team' ? false : true}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      handleContactChange(data, index);
                    }}
                  />
                ) : (
                  <span className='d-inline-block text-truncate' style={{ whiteSpace: 'break-spaces' }}>
                    {item.contact ? `${item.contact.first_name} ${item.contact.last_name}` : '--'}
                  </span>
                )}
              </div>
              <div className={`${inputClass} align-item-center team-info-field`}>
                {editingField === 'client_team' ? (
                  <InputField
                    variant='outlined'
                    label=''
                    value={item.work_email || ''}
                    defaultValue={item.work_email || ''}
                    type='email'
                    placeholder='Email'
                    disabled={editingField === 'client_team' ? false : true}
                    onChange={e => {
                      handleChange(index, 'work_email', e.target.value);
                    }}
                    InputLabelProps={{ focused: true }}
                    className='MuiFormControl-fullWidth'
                  />
                ) : (
                  <span title={item.work_email} className='d-block text-truncate'>
                    {item.work_email || '--'}
                  </span>
                )}
              </div>
              <div className={`${inputClass} align-item-center team-info-field`}>
                {editingField === 'client_team' ? (
                  <ReactPhoneInput
                    variant='outlined'
                    placeholder='Phone Number'
                    value={item.mobile_phone || ''}
                    onChange={(data, c, e, value) => {
                      if (data?.length > 1) {
                        handleChange(index, 'mobile_phone', value);
                      } else {
                        handleChange(index, 'mobile_phone', '');
                      }
                    }}
                    enableSearch={true}
                    disabled={editingField === 'client_team' ? false : true}
                    disableInitialCountryGuess={determineInitialCountryGuess(item.mobile_phone)}
                    inputProps={{
                      label: '',
                      variant: 'outlined'
                    }}
                    component={TextField}
                  />
                ) : (
                  <span className='d-inline-block text-truncate'>{item.mobile_phone || '--'}</span>
                )}
              </div>
              <div className={`${hiringClass} align-item-center team-info-field`}>
                {
                  editingField === 'client_team' ? (
                    <FormControl className='w-auto ' style={{ margin: '11px 0', marginLeft: '20px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size='small'
                            checked={hiringManagerIndex === index}
                            onChange={e => {
                              handleHiringManagerChange(e.target.checked, index);
                            }}
                          />
                        }
                        label=''
                        style={{ color: 'black' }}
                      />
                    </FormControl>
                  ) : (
                    <span style={{ color: 'black' }}>{item.is_hiring_manager ? 'Yes' : 'No'}</span>
                  )
                  // item.is_hiring_manager &&
                  // <FormControl className='w-auto' style={{ margin: '11px 0' }}>
                  //   <FormControlLabel
                  //     control={<Checkbox size='small' checked={true} readOnly={true} />}
                  //     label="Hiring Manager"
                  //     style={{ color: "black" }}
                  //   />
                  // </FormControl>
                }
              </div>
              {editingField !== 'client_team' && (
                <div className='col-2 align-item-center team-info-field'>
                  {item.contact ? (
                    <>
                      <Button
                        variant='outlined'
                        color='primary'
                        style={{ textTransform: 'none', margin: '5px 0' }}
                        size='small'
                        aria-controls='simple-menu'
                        aria-haspopup='true'
                        onClick={event => handleClick(event, item)}
                      >
                        <span className='ml-1'>Athena Invite </span>
                        <ArrowDropDownIcon />
                      </Button>
                      <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                        <MenuItem
                          onClick={() => {
                            openAthenaPopUp(clientSuiteInviteClient, 'Athena');
                          }}
                        >
                          Invite to Athena only
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            openAthenaPopUp(clientSuiteInviteClient, 'Redo Attribute Selector');
                          }}
                        >
                          Invite to Attribute Selector only
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            openAthenaPopUp(clientSuiteInviteClient, 'Athena,Redo Attribute Selector');
                          }}
                        >
                          Invite to Athena & Attribute Selector
                        </MenuItem>
                      </Menu>
                    </>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </div>
              )}
              {editingField !== 'client_team' && (
                <div className='col-2 align-item-center team-info-field'>
                  {item.contact ? (
                    <Button
                      variant='outlined'
                      color='primary'
                      size='small'
                      style={{ textTransform: 'none' }}
                      onClick={() => {
                        toggleOpen(true);
                        setClientSuiteInviteClient(item);
                      }}
                    >
                      {`${currentValues.type === 'Inside Track' ? 'Arena' : 'ClientSuite'} Invite`}
                    </Button>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </div>
              )}
              {/* <div className="col-1 align-item-center">
                {editIndex === "client_team" ? <FormControl className='w-auto align-item-center ml-4' style={{ margin: '11px 0' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size='small'
                        checked={hiringManagerIndex === index}
                        onChange={(e) => {
                          handleHiringManagerChange(e.target.checked, index)
                        }}
                      />
                    }
                    label=""
                    style={{ color: "black" }}
                  />
                </FormControl>
                  :
                  <span className='w-auto ml-3' style={{ color: "black" }}>{item.is_hiring_manager ? 'Yes' : 'No'}</span>
                  // item.is_hiring_manager &&
                  // <FormControl className='w-auto' style={{ margin: '11px 0' }}>
                  //   <FormControlLabel
                  //     control={<Checkbox size='small' checked={true} readOnly={true} />}
                  //     label="Hiring Manager"
                  //     style={{ color: "black" }}
                  //   />
                  // </FormControl>
                }
              </div> */}
              <div className='col-0-5 align-items-center team-info-field'>
                {editingField === 'client_team' ? (
                  <CloseIcon
                    variant='large'
                    style={{ color: 'red' /* marginTop: "25px", position: "absolute", right: "30px" */ }}
                    cursor='pointer'
                    fontSize='medium'
                    onClick={async () => {
                      await removeClientContact(index);
                    }}
                  />
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column'>
          <FormControl className='w-auto' style={{ margin: '11px 0' }}>
            <FormControlLabel
              control={
                <Checkbox
                  size='small'
                  checked={hiringManagerIndex === index}
                  onChange={e => {
                    handleHiringManagerChange(e.target.checked, index);
                  }}
                />
              }
              label='Hiring Manager'
            />
          </FormControl>
        </div>
      </div>
    );
  };
  // const renderView = (item) => {
  //   return (
  //     <div className='d-flex' style={{ marginLeft: 10 }}>
  //       <div className='d-flex flex-column w-50'>
  //         <div className='contact-details-row contact-details'>
  //           <span className='contact-view-label'>Name</span>
  //           <span
  //             className='contact-view-value'>{item.contact ? `${item.contact.first_name || ''} ${item.contact.last_name || ''}` : '--'}</span>
  //         </div>
  //         <div className='contact-details-row contact-details'>
  //           <span className='contact-view-label'>Email</span>
  //           <span className='contact-view-value'>{item.work_email || '--'}</span>
  //         </div>
  //         <div className='contact-details-row contact-details'>
  //           <span className='contact-view-label'>Phone Number</span>
  //           <span className='contact-view-value'>{item.mobile_phone || '--'}</span>
  //         </div>
  //       </div>
  //       <div>
  //         {
  //           item.contact ?
  //             <div className='d-flex flex-column'>
  //               <Button
  //                 variant='outlined'
  //                 color='primary'
  //                 style={{ textTransform: 'none', margin: '5px 0' }}
  //                 size="small"
  //                 aria-controls="simple-menu"
  //                 aria-haspopup="true"
  //                 onClick={(event) => handleClick(event, item)}
  //               >
  //                 Athena Invite <ArrowDropDownIcon />
  //               </Button>
  //               <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
  //                 <MenuItem
  //                   onClick={() => {
  //                     openAthenaPopUp(clientSuiteInviteClient, 'Athena')
  //                   }}
  //                 >Invite to Athena only</MenuItem>
  //                 <MenuItem
  //                   onClick={() => {
  //                     openAthenaPopUp(clientSuiteInviteClient, 'Redo Attribute Selector')
  //                   }}
  //                 >Invite to Attribute Selector only</MenuItem>
  //                 <MenuItem
  //                   onClick={() => {
  //                     openAthenaPopUp(clientSuiteInviteClient, 'Athena,Redo Attribute Selector')
  //                   }}
  //                 >Invite to Athena & Attribute Selector</MenuItem>
  //               </Menu>
  //               <Button
  //                 variant='outlined'
  //                 color='primary'
  //                 size="small"
  //                 style={{ textTransform: 'none', margin: '5px 0' }}
  //                 onClick={() => {
  //                   toggleOpen(true);
  //                   setClientSuiteInviteClient(item)
  //                 }}
  //               >
  //                 {`${currentValues.type === 'Inside Track' ? 'Arena' : 'ClientSuite'} Invite`}
  //               </Button>
  //               {
  //                 item.is_hiring_manager &&
  //                 <FormControl className='w-auto' style={{ margin: '5px 0' }}>
  //                   <FormControlLabel
  //                     control={<Checkbox size='small' checked={true} readOnly={true} />}
  //                     label="Hiring Manager"
  //                   />
  //                 </FormControl>
  //               }
  //             </div>
  //             :
  //             null
  //         }
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <div className='team-information-table flex-grow-1 p-0 pt-1'>
      <Typography className='w-100 m-0' style={{ background: '#2a364c', borderRadius: '4px' }}>
        <div className='d-flex align-items-center table-header'>
          {renderSectionLabel('Client Contact Details', 'client_team')}
          {renderActions('client_team')}
        </div>
      </Typography>
      <div className='client-info-table d-flex flex-column'>
        <div className='d-flex align-items-center table-main-header'>
          <div className='col-0-5 text-center'></div>
          <div className={`${inputClass} text-center`}>Name</div>
          <div className={`${inputClass} text-center`}>Email</div>
          <div className={`${inputClass} text-center`}>Phone</div>
          <div className={`${hiringClass} text-center`}>Hiring Manager</div>
          {editingField !== 'client_team' && <div className='col-2 text-center'>Athena Invite</div>}
          {editingField !== 'client_team' && <div className='col-2 text-center'>ClientSuite Invite</div>}
          <div className='col-0-5 text-center'></div>
        </div>
        {clientContacts.map((item, index) => (
          <div className='d-flex flex-column ml-1' key={index}>
            {/* {renderSubHeader(index)} */}
            {renderEdit(item, index)}
          </div>
        ))}
        {open ? <ClientSuiteInvitePopUp client={clientSuiteInviteClient} onClose={() => toggleOpen(false)} type={currentValues?.type} /> : null}
        {isAthenaInviteOpen ? <AthenaInvitePopUp inviteFor={'client'} selectedDropDown={selectedDropDown} client={clientSuiteInviteClient} onClose={() => toggleAthenaInviteOpen(false)} /> : null}

        {editingField === 'client_team' && (
          <div className='text-start ml-3 margin-button'>
            <Button variant={'text'} startIcon={<AddIcon style={{ color: 'red' }} />} onClick={addClientContact} className='button-text-capitalized' style={{ width: '175px' }}>
              <span
                style={{
                  fontWeight: '600',
                  fontSize: '13px',
                  fontStyle: 'italic'
                }}
              >
                Add New Client
              </span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

ClientContacts.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  renderActions: PropTypes.func,
  editingField: PropTypes.string,
  renderSectionLabel: PropTypes.func,
  watch: PropTypes.func
};

export default ClientContacts;
