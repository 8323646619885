//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import '../../index.scss';
import AddEductionAndExperience from './AddEductionAndExperience';
import '../../../../Containers/Commons/SingleSelectAutoComplete/index.scss';
import ProfileLabel from '../../../common/ProfileLabel';
import CardSlider from '../../../common/CardsSlider';
import CustomTable from '../../../common/CustomTable';
import Footer from '../../../common/Footer';
import CustomButton from '../../../common/CustomButton';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import LanguagesSelection from '../../../common/FunctionalComponents/LanguagesSelection';
import LevelOfExpertiseSelection from '../../../common/FunctionalComponents/LevelOfExpertiseSelection';
import EducationCard from '../../../common/FunctionalComponents/EducationCard';
import useContactFormHook from '../../../../utils/Hooks/useContactFormHook';
import { useParams } from 'react-router-dom';
import { createRequiredFieldEducationDetails, createSchemaEducationDetails, getEducationLanguagePayload, validateDate } from '../../utils';
import { customFormValidator, requireValidMessage } from '../../../../utils/common';
import { enqueueSnackbar } from 'notistack';
import { ERROR, IGN_API } from '../../../../services/constantService';
import { getAccessToken } from '../../../../services/cognitoService';
import Loader from '../../../common/Loader';
import CustomInputField from '../../../common/StyledComponents/CustomInputField';
import CertificationTypeSelection from '../../../common/FunctionalComponents/CertificationTypeSelection';
import DatePickerYear from '../../../common/DatePickerYear/DatePickerYear';

const EducationAndLanguages = forwardRef((props, ref) => {
  const { unregister = () => {}, handleSaveTabData = () => {}, id, setIsLoading, onCancel } = props;
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_EDUCATION,
    TRANS_KEYS.CONTACTS_LANGUAGES,
    TRANS_KEYS.CONTACTS_UPDATE_EDUCATION_DETAILS,
    TRANS_KEYS.CONTACTS_ADD_EDUCATION_DETAILS,
    TRANS_KEYS.CONTACTS_LEVEL_OF_EXPERTISE,
    TRANS_KEYS.CONTACTS_ADD_LANGUAGES,
    TRANS_KEYS.CONTACTS_ADD_ANOTHER,
    TRANS_KEYS.CONTACTS_WORD_ADD,
    TRANS_KEYS.CONTACT_CERTIFICATION_AND_LICENSE,
    TRANS_KEYS.ADD_CERTIFICATION_AND_LICENSE,
    TRANS_KEYS.CERTIFICATION_EXPIRE_ON,
    TRANS_KEYS.CERTIFICATION_TYPE,
    TRANS_KEYS.CERTIFICATION_TITLE
  ]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [indexValue, setIndexValue] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [srcUrl, setSrcUrl] = useState(null);
  const { path } = useParams();
  const [language, setLanguage] = useState([{ language: '', expertise: '' }]);

  const [certificate, setCertificate] = useState([{ certification_type: '', certifications: '', expire_on: '' }]);
  const isValidated = async contact => {
    let isValidDate = validateDate(contact?.start_date, contact?.end_date);
    if (isPopupOpen) {
      let isValid = await createSchemaEducationDetails.isValid(contact);
      if (!isValid) {
        let dirtyField = customFormValidator(contact, createRequiredFieldEducationDetails);
        if (dirtyField) {
          const message = requireValidMessage(dirtyField);
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
      }
      if (!isValidDate.isValid) {
        enqueueSnackbar(isValidDate?.message, { variant: ERROR });
        return false;
      } else {
        return true;
      }
    } /* else {
      const dirtyField = [];
      if (!contact?.contact_industries?.length) {
        dirtyField.push("Industries");
      }
      if (!contact?.contact_job_functions?.length) {
        dirtyField.push("Job Functions");
      }
      if (dirtyField?.length) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      } 
    }
 */
    return true;
  };

  const { getContactInfo, register, handleSave, watch, setValue, currentValues, loading, isDirty } = useContactFormHook({
    id,
    path,
    getPayload: getEducationLanguagePayload,
    handleSaveTabData,
    isEdit,
    ref,
    indexValue,
    isValidated,
    tabName: 'EDUCATION_AND_LANGUAGES_TAB',
    setIsPopupOpen,
    isPopupOpen
  });

  useEffect(() => {
    register('expire_on');
    register('contact_languages');
    register('contact_certificate');

    return () => {
      unregister('expire_on');
      unregister('contact_languages');
      unregister('contact_certificate');
    };
  }, [register, unregister]);

  useEffect(() => {
    const accessToken = async () => {
      let token = await getAccessToken();
      setSrcUrl(`${IGN_API.contact}/${currentValues?.id}/image?token=${token}&time=${Date.now()}`);
    };
    if (currentValues?.image_id) accessToken();
  }, [currentValues]);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
    setIsEdit(false);
  };

  const openPopupEditing = index => {
    setIndexValue(index);
    setIsEdit(true);
    setIsPopupOpen(true);
  };

  const handleRemoveLanguage = index => {
    const updatedLanguage = [...language];
    updatedLanguage.splice(index, 1);
    setLanguage(updatedLanguage);
  };

  const handleAddLanguage = () => {
    setLanguage([...language, { language: '', expertise: '' }]);
  };

  const handleChangeCertification = (index, field, value) => {
    const updatedCertificate = certificate.map((certificate, i) => {
      if (i === index) {
        return { ...certificate, [field]: value };
      }
      return certificate;
    });
    setCertificate(updatedCertificate);
  };

  const handleAddCertificate = () => {
    setCertificate([...certificate, { certification_type: '', certifications: '', expire_on: '' }]);
  };

  const handleRemoveCertificate = index => {
    const updatedCertificate = [...certificate];
    updatedCertificate.splice(index, 1);
    setCertificate(updatedCertificate);
  };

  useEffect(() => {
    setValue(
      'contact_certificate',
      certificate.map(elem => {
        return {
          ...elem,
          certification_type: elem?.certification_type?.field_value ? elem?.certification_type?.field_value : elem?.certification_type,
          certifications: elem?.certifications ? elem?.certifications : '',
          expire_on: elem?.expire_on ? elem.expire_on : ''
        };
      }),
      { shouldDirty: true }
    );
  }, [certificate]);

  const handleLanguageChange = (index, field, value) => {
    const updatedLanguage = language.map((ele, i) => {
      if (i === index) {
        return { ...ele, [field]: value };
      }
      return ele;
    });
    setLanguage(updatedLanguage);
  };

  useEffect(() => {
    setValue(
      'contact_languages',
      language.map(elem => {
        return {
          ...elem,
          expertise: elem?.expertise?.field_value ? elem?.expertise?.field_value : elem?.expertise
        };
      }),
      { shouldDirty: true }
    );
  }, [language]);
  useEffect(() => {
    if (currentValues?.contact_languages && currentValues?.contact_languages.length) {
      setLanguage(currentValues?.contact_languages);
    }
  }, [currentValues?.contact_languages]);

  useEffect(() => {
    if (currentValues?.contact_certificate && currentValues?.contact_certificate.length) {
      setCertificate(currentValues?.contact_certificate);
    }
  }, [currentValues?.contact_certificate]);
  const cancelData = () => {
    onCancel(isDirty);
  };

  return (
    <>
      <Loader show={loading} />
      {currentValues ? (
        <Box id='education' className='final-page-layout overflow-auto'>
          <Box>
            <Footer onSubmit={handleSave} onClose={cancelData}></Footer>
          </Box>
          <Box className='d-flex justify-content-between'>
            <Typography className='text-label font-weight-bold' variant='body1'>
              {t(`${TRANS_KEYS.CONTACTS_EDUCATION}:key`)}
            </Typography>
            <Box className='profile-label-position'>
              <ProfileLabel value={currentValues} srcUrl={srcUrl} />
            </Box>
          </Box>
          <Box className='mt-3 h-auto card-carousel p-11'>
            <Box>
              <CardSlider
                cardData={currentValues?.contact_education_details}
                styleData={{
                  titleSize: 13,
                  textSize: 11,
                  height: 110,
                  width: 400,
                  minWidth: 400
                }}
                handleOpenPopup={openPopupEditing}
              >
                {currentValues?.contact_education_details.map((card, cardIndex) => (
                  <React.Fragment key={cardIndex}>
                    <EducationCard
                      getContactDetails={getContactInfo}
                      setIsLoading={setIsLoading}
                      data={card}
                      index={cardIndex}
                      editItems={openPopupEditing}
                      styleData={{
                        titleSize: 13,
                        textSize: 11,
                        height: 100,
                        width: 400,
                        minWidth: 400
                      }}
                    />
                    {cardIndex < currentValues?.contact_education_details?.length - 1 && (
                      <Box className='pr-2 pl-2 arrowIcon'>
                        <ArrowBackIcon />
                      </Box>
                    )}
                  </React.Fragment>
                ))}
              </CardSlider>
            </Box>
            <Box className='add-document'>
              <Box>
                <CustomButton
                  type={'secondary'}
                  size={'medium'}
                  buttonText={currentValues?.contact_education_details?.length === 0 ? t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`) : t(`${TRANS_KEYS.CONTACTS_ADD_ANOTHER}:key`)}
                  customWidth={96}
                  iconLeft={<AddIcon sx={{ width: 14 }} />}
                  onClick={handleOpenPopup}
                />
              </Box>
              {isPopupOpen && (
                <AddEductionAndExperience
                  title={`${isEdit ? `${t(`${TRANS_KEYS.CONTACTS_UPDATE_EDUCATION_DETAILS}:key`)}` : `${t(`${TRANS_KEYS.CONTACTS_ADD_EDUCATION_DETAILS}:key`)}`}`}
                  setIsPopupOpen={setIsPopupOpen}
                  data={isEdit ? currentValues?.contact_education_details[indexValue] : null}
                  isPopupOpen={isPopupOpen}
                  register={register}
                  unregister={unregister}
                  handleSave={handleSave}
                  setValue={setValue}
                  watch={watch}
                  setIsEdit={setIsEdit}
                  isEditing={isEdit}
                />
              )}
            </Box>
          </Box>

          {/* <Box className="final-page-layout"> */}

          <Box className='communication-section flex-row flex-wrap pt-4'>
            <Box className='head-container'>
              <Typography className='text-label mb-3 mt-3 font-weight-bold' variant='body1'>
                {t(`${TRANS_KEYS.CONTACTS_LANGUAGES}:key`)}
              </Typography>
              <Box className='mt-3 h-auto p-11'>
                <Box className='d-flex'>
                  <Box className='d-inline license-content'>
                    <CustomTable
                      headerData={[`${t(`${TRANS_KEYS.CONTACTS_LANGUAGES}:key`)}`, `${t(`${TRANS_KEYS.CONTACTS_LEVEL_OF_EXPERTISE}:key`)}`]}
                      title={t(`${TRANS_KEYS.CONTACTS_ADD_LANGUAGES}:key`)}
                      handleAnotherRow={handleAddLanguage}
                      rows={language}
                      dataList={languageList}
                    >
                      {language.map((selectedLanguages, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell className='input-field-data justify-center align-center p-2'>
                              <LanguagesSelection
                                defaultValue={selectedLanguages?.language?.name || ''}
                                onChange={(e, data) => {
                                  handleLanguageChange(index, 'language', data);
                                }}
                                selectedList={language}
                                setLanguageList={setLanguageList}
                                viewAddress={true}
                              />
                            </TableCell>
                            <TableCell className='input-field-data justify-center align-center  p-2'>
                              <LevelOfExpertiseSelection
                                defaultValue={selectedLanguages?.expertise || ''}
                                onChange={(e, data) => {
                                  handleLanguageChange(index, 'expertise', data);
                                }}
                                viewAddress={true}
                              />
                            </TableCell>
                            {language.length > 1 && (
                              <TableCell className='remove-another-button d-flex align-center justify-content-center pt-3'>
                                <CloseIcon onClick={() => handleRemoveLanguage(index)} className='table-close-icon' />
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </CustomTable>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className='communication-section flex-row flex-wrap pt-4'>
            <Box className='head-container'>
              <Typography className='text-label mb-3 mt-3 font-weight-bold' variant='body1'>
                {t(`${TRANS_KEYS.CONTACT_CERTIFICATION_AND_LICENSE}:key`)}
              </Typography>
              <Box className='mt-3 h-auto p-11'>
                <Box className='d-flex'>
                  <Box className='d-inline license-content'>
                    <CustomTable
                      headerData={[`${t(`${TRANS_KEYS.CERTIFICATION_TYPE}:key`)}`, `${t(`${TRANS_KEYS.CERTIFICATION_TITLE}:key`)}`, `${t(`${TRANS_KEYS.CERTIFICATION_EXPIRE_ON}:key`)}`]}
                      title={t(`${TRANS_KEYS.ADD_CERTIFICATION_AND_LICENSE}:key`)}
                      handleAnotherRow={handleAddCertificate}
                      rows={certificate}
                    >
                      {certificate.map((selectedCertificate, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell className='input-field-data p-2 ' sx={{ width: '13vw' }}>
                              <CertificationTypeSelection
                                defaultValue={selectedCertificate?.certification_type || ''}
                                onChange={(e, data) => handleChangeCertification(index, 'certification_type', data)}
                                isApplyWidth={true}
                              />
                            </TableCell>
                            <TableCell className='input-field-data flex p-2'>
                              <CustomInputField value={selectedCertificate?.certifications || ''} onChange={e => handleChangeCertification(index, 'certifications', e.target.value)} />
                            </TableCell>
                            <TableCell className='p-2 expire-width'>
                              {/* <CustomInputField
                                  type="number"
                                  value={selectedCertificate?.expire_on || ""}
                                  onChange={(e) =>
                                    handleChangeCertification(
                                      index,
                                      "expire_on",
                                      e.target.value,
                                    )
                                  }

                                /> */}

                              <DatePickerYear
                                value={selectedCertificate?.expire_on || ''}
                                className='input-field-data'
                                onChange={e => handleChangeCertification(index, 'expire_on', e)}
                                showOnlyYear={true}
                              />
                            </TableCell>
                            {certificate.length > 1 && (
                              <TableCell className='remove-another-button d-flex align-center justify-content-center pt-3'>
                                <CloseIcon onClick={() => handleRemoveCertificate(index)} className='table-close-icon' />
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </CustomTable>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
});

EducationAndLanguages.propTypes = {
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  handleSaveTabData: PropTypes.func,
  watch: PropTypes.func,
  srcUrl: PropTypes.string,
  id: PropTypes.string,
  setIsPopupOpen: PropTypes.bool,
  setIsLoading: PropTypes.func,
  onCancel: PropTypes.func
};

export default EducationAndLanguages;
