import { ignCompanyOffLimitDataApi, ignDirectOffLimitDataApi, ignOffLimitConfigDataApi, ignOffLimitSummaryDataApi } from '../../../services/ApiServices/offLimitServices';
import { DELETE, GET, PATCH, POST } from '../../../services/constantService';

export const createCompanyOffLimit = async data => {
  try {
    const response = await ignCompanyOffLimitDataApi(POST, '', data, 'create');
    return response?.data;
  } catch (error) {
    console.error('Error creating company off-limit:', error);
  }
};

export const updateCompanyOffLimit = async data => {
  try {
    const response = await ignCompanyOffLimitDataApi(PATCH, '', data, 'update');
    return response?.data;
  } catch (error) {
    console.error('Error updating company off-limit:', error);
  }
};

export const deleteCompanyOffLimit = async companyId => {
  try {
    const response = await ignCompanyOffLimitDataApi(DELETE, '', {}, `delete/${companyId}`);
    return response?.status === 200;
  } catch (error) {
    console.error('Error deleting company off-limit:', error);
  }
};

export const createDirectOfflimit = async data => {
  try {
    const response = await ignDirectOffLimitDataApi(POST, '', data, 'create');
    return response?.data;
  } catch (error) {
    console.error('Error creating direct off-limit:', error);
  }
};

export const updateDirectOfflimit = async data => {
  try {
    const response = await ignDirectOffLimitDataApi(PATCH, '', data, 'update');
    return response?.data;
  } catch (error) {
    console.error('Error updating direct off-limit:', error);
  }
};

export const deleteDirectOfflimit = async contactId => {
  try {
    const response = await ignDirectOffLimitDataApi(DELETE, '', {}, `delete/${contactId}`);
    return response?.status === 200;
  } catch (error) {
    console.error('Error deleting direct off-limit:', error);
    return [];
  }
};

export const getOffLimitFromSummary = async (contactId, projectId) => {
  try {
    const response = await ignOffLimitSummaryDataApi(GET, `${contactId}?projectId=${projectId}`);
    return response?.data;
  } catch (error) {
    console.error('Error fetching off-limit summary:', error);
    return [];
  }
};
export const getOffLimitFromSummaryForUsers = async (contactIds, projectId) => {
  try {
    const response = await ignOffLimitSummaryDataApi(POST, '', { contactIds, projectId }, 'offlimits');
    return response?.data;
  } catch (error) {
    console.error('Error fetching off-limit summary:', error);
    return [];
  }
};
export const getOffLimitFromSummaryForCompanies = async companyIds => {
  try {
    const response = await ignCompanyOffLimitDataApi(POST, '', { companyIds }, 'companyOfflimits');
    return response?.data;
  } catch (error) {
    console.error('Error fetching off-limit summary:', error);
    return [];
  }
};

export const getOffLimitbasedOnStatus = async contactId => {
  try {
    const response = await ignOffLimitSummaryDataApi(GET, contactId, {}, 'status-based-inherited-off-limits');
    return response?.data ?? [];
  } catch (error) {
    console.error('Error fetching off-limit summary:', error);
  }
};

export const getCompanyOffLimit = async companyId => {
  try {
    const response = await ignCompanyOffLimitDataApi(GET, companyId);
    return response?.data;
  } catch (error) {
    console.error('Error fetching off-limit summary:', error);
  }
};

export const getOffLimitConfig = async () => {
  try {
    const response = await ignOffLimitConfigDataApi(GET, '', {}, 'config');
    return response?.data;
  } catch (error) {
    console.error('Error fetching off-limit config:', error);
  }
};

export const updateOffLimitConfig = async data => {
  try {
    const response = await ignOffLimitConfigDataApi(POST, '', data, 'config');
    return response?.data;
  } catch (error) {
    console.error('Error updating off-limit config:', error);
  }
};
