//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { picklistDropDownApis } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import CustomDropdown from '../../CustomDropdown';

const UserSelection = props => {
  const { label = '', placeholder = '', onChange = () => {}, required = false, list = [], option = [], ...rest } = props;
  const [options, setOptions] = useState(option ? option : []);
  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [newOptions, setNewOptions] = useState([]);

  const filterDataOptions = data => {
    return data.filter(item => {
      return list.every(listItem => listItem?.user?.id !== item?.id);
    });
  };
  const filterSearchOptions = data => {
    return data.filter(item => item?.name?.match(new RegExp(value, 'gi')));
  };
  useEffect(() => {
    const getUserList = async () => {
      setOptions(option);
      if (value.length > 0 || options.length === 0) {
        try {
          setLoading(true);
          const sub_route = `user?name=${value}`;
          const { status, data } = await picklistDropDownApis(GET, sub_route);
          if (status === 200) {
            setOptions(data.data);
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getUserList();
  }, [value]);

  useEffect(() => {
    if (list.length > 0) {
      const filterData = filterDataOptions(options);
      const SearchOption = filterSearchOptions(filterData);
      setNewOptions(SearchOption);
    } else {
      setNewOptions(options);
    }
  }, [options, list]);
  return (
    <CustomDropdown
      {...rest}
      options={newOptions}
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      required={required}
      isLoading={isLoading}
      onInputChange={(e, val, reason) => {
        if (reason === 'input') {
          setValue(val);
        }
      }}
    />
  );
};

UserSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  list: PropTypes.list,
  option: PropTypes.array
};

export default UserSelection;
