//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { formatDate } from '../../../utils/date';

export default function SearchItem(props) {
  const { search } = props;
  if (search.id) {
    return (
      <div style={{ paddingBottom: '5px' }}>
        <Typography color='primary' className='mr-2'>
          <Link to={`/searches/${search.id}/active-candidates`} target='_blank' rel='noopener noreferrer'>
            <Button variant={'text'} color='primary'>
              #{search.job_number}
            </Button>
          </Link>
          <span style={{ fontSize: 14, color: 'black' }}>{search.job_title}</span>
        </Typography>
        <div style={{ marginLeft: '20px', fontSize: 14 }}>
          <span>Last Updated:</span>
          {formatDate(search.updated_at, 'hh:mmA DD MMM YYYY')}
        </div>
      </div>
    );
  } else {
    return (
      <>
        {search.map((search, index) => {
          if (search.client_team) {
            return (
              <div key={index} style={{ paddingBottom: '5px' }}>
                <Typography color='primary' className='mr-2'>
                  <Link to={`/searches/${search.id}/active-candidates`} target='_blank' rel='noopener noreferrer'>
                    <Button variant={'text'} color='primary'>
                      #{search.job_number}
                    </Button>
                  </Link>
                  <span style={{ fontSize: 14, color: 'black' }}>{search.job_title}</span>
                </Typography>
                <div style={{ marginLeft: '20px', fontSize: 14 }}>
                  <span>Last Updated:</span>
                  {formatDate(search.updated_at, 'hh:mmA DD MMM YYYY')}
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </>
    );
  }
}

SearchItem.propTypes = {
  search: PropTypes.array
};
