//-----------------------------------------------------------// In-built Imports // ------------------------------
import React, { forwardRef, useEffect, useState } from 'react';
//-----------------------------------------------------------// External Imports // ------------------------------
import { Checkbox, Box, Paper, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import MakeConfidential from '../../../common/MakeConfidential/MakeConfidential';
import RichText from '../../../common/RichText';
import './index.scss';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import '../../../../Containers/Commons/SingleSelectAutoComplete/index.scss';
import '../../../../Containers/Commons/AutoCompleteStyle/index.scss';
import '../../../../Containers/Commons/CheckboxStyle/index.scss';
import '../../../../../src/utils/common.scss';
import CustomTable from '../../../common/CustomTable';
import '../../../../../src/index.scss';
import CustomInputField from '../../../common/StyledComponents/CustomInputField';
import Footer from '../../../common/Footer';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import CurrencySelection from '../../../common/FunctionalComponents/CurrencySelection';
import BonusUnitSelection from '../../../common/FunctionalComponents/BonusUnitSelection';
import BonusTypeSelection from '../../../common/FunctionalComponents/BonusTypeSelection';
import { useParams } from 'react-router-dom';
import useContactFormHook from '../../../../utils/Hooks/useContactFormHook';
import { getCompensationPayload } from '../../utils';
import { picklistDropDownApis } from '../../../../services/ApiService';
import { ERROR, GET } from '../../../../services/constantService';
import Loader from '../../../common/Loader';
import { enqueueSnackbar } from 'notistack';
//import { useForm } from "react-hook-form";

const Compensation = forwardRef((props, ref) => {
  const { handleSaveTabData = () => {}, id, onCancel } = props;
  /*  const { register, unregister, handleSave = () => { }, setValue = () => { }, watch = () => { } } = useForm({}); */

  const [compensationDetails, setCompensationDetails] = useState([{ bonus_type: '', bonus: '', bonus_value: '' }]);
  const { path } = useParams();
  const [bonusList, setBonusList] = useState([]);
  const [isMakeConfidential, setIsMakeConfidential] = useState(false);
  const isValidated = async () => {
    return true;
  };
  const { handleSave, register, watch, setValue, currentValues, loading, isDirty } = useContactFormHook({
    id,
    path,
    getPayload: getCompensationPayload,
    handleSaveTabData,
    ref,
    isValidated,
    defaultFormValue: { base_salary: '' },
    tabName: 'COMPENSATION_TAB'
  });

  useEffect(() => {
    let actualBonusType = null;
    let actualBonusValue = null;
    let targetBonusType = null;
    let targetBonusValue = null;
    compensationDetails.forEach(details => {
      if ((details.bonus?.field_value ? details.bonus?.field_value : details.bonus) === 'Actual') {
        actualBonusType = details.bonus_type === '%' ? 'PERCENTAGE' : 'CURRENCY';
        actualBonusValue = details.bonus_value;
      }
      if ((details.bonus?.field_value ? details.bonus?.field_value : details.bonus) === 'Target') {
        targetBonusType = details.bonus_type === '%' ? 'PERCENTAGE' : 'CURRENCY';
        targetBonusValue = details.bonus_value;
      }
    });
    setValue('contact_compensations.actual_bonus_type', actualBonusType, {
      shouldDirty: true
    });
    setValue('contact_compensations.actual_bonus_value', actualBonusValue, {
      shouldDirty: true
    });
    setValue('contact_compensations.target_bonus_type', targetBonusType, {
      shouldDirty: true
    });
    setValue('contact_compensations.target_bonus_value', targetBonusValue, {
      shouldDirty: true
    });
  }, [compensationDetails]);

  useEffect(() => {
    register('contact_compensations.base_salary');
    register('contact_compensations.currency_iso_code');
    register('contact_compensations.compensation_expectation');
    register('contact_compensations.equity');
    register('contact_compensations.compensation_release');
    register('contact_compensations.compensation_notes');
    register('contact_compensations.is_confidential');
    register('contact_compensations.actual_bonus_type');
    register('contact_compensations.actual_bonus_value');
    register('contact_compensations.target_bonus_type');
    register('contact_compensations.target_bonus_value');
    register('contact_compensations.compensations.id');
  }, [register]);

  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_COMPENSATION_INFO,
    TRANS_KEYS.CONTACTS_CURRENCY_CODE,
    TRANS_KEYS.CONTACTS_BASE_SALARY,
    TRANS_KEYS.CONTACTS_EXPECTED_COMPENSATION,
    TRANS_KEYS.CONTACTS_LTI,
    TRANS_KEYS.CONTACTS_COMPENSATION_RELEASE,
    TRANS_KEYS.CONTACTS_BONUS,
    TRANS_KEYS.CONTACTS_BONUS_TYPE,
    TRANS_KEYS.CONTACTS_BONUS_VALUE,
    TRANS_KEYS.CONTACTS_COMPENSATION_NOTES,
    TRANS_KEYS.CONTACT_OR,
    TRANS_KEYS.CONTACTS_MAKE_CONFIDENTIAL
  ]);
  const handleAddAnother = () => {
    setCompensationDetails(prevState => [...prevState, { bonus_type: '', bonus: '', bonus_value: '' }]);
  };
  const handleRemoveCompensationDetails = index => {
    const updatedCompensationDetails = [...compensationDetails];
    updatedCompensationDetails.splice(index, 1);
    setCompensationDetails(updatedCompensationDetails);
  };

  const title = t(`${TRANS_KEYS.CONTACTS_MAKE_CONFIDENTIAL}:key`);

  const handleChangeDetails = (index, field, value) => {
    const updatedCompensationDetails = compensationDetails.map((compensationDetail, i) => {
      if (i === index) {
        if (field === 'bonus_type') {
          return { ...compensationDetail, [field]: value, bonus_value: null };
        } else {
          return { ...compensationDetail, [field]: value };
        }
      }
      return compensationDetail;
    });
    setCompensationDetails(updatedCompensationDetails);
  };
  useEffect(() => {
    const bonusObjects = [];
    const getUnit = async () => {
      const sub_route = `currency-details?code=${currentValues?.contact_compensation?.currency_iso_code}`;
      const { status, data } = await picklistDropDownApis(GET, sub_route);
      if (status === 200) {
        return data?.currency_icon;
      } else {
        return null;
      }
    };
    const getBonusObject = async () => {
      if (currentValues?.contact_compensation && currentValues?.contact_compensation?.actual_bonus_value) {
        const bonusType = currentValues.contact_compensation?.actual_bonus_type;
        const sign = bonusType === 'CURRENCY' ? await getUnit() : '%';

        bonusObjects.push({
          bonus: currentValues?.contact_compensation?.actual_type,
          bonus_value: currentValues.contact_compensation?.actual_bonus_value,
          bonus_type: sign
        });
      }

      if (currentValues?.contact_compensation && currentValues.contact_compensation?.target_bonus_value) {
        const bonusType = currentValues.contact_compensation?.target_bonus_type;
        const sign = bonusType === 'CURRENCY' ? await getUnit() : '%';

        bonusObjects.push({
          bonus: currentValues?.contact_compensation?.target_type,
          bonus_value: currentValues.contact_compensation?.target_bonus_value,
          bonus_type: sign
        });
      }
      if (bonusObjects.length > 0) {
        setCompensationDetails(bonusObjects);
      }
    };
    getBonusObject();
  }, [currentValues?.contact_compensation]);
  const handleMakeConfidential = e => {
    setIsMakeConfidential(e.target.checked);
    setValue('contact_compensations.is_confidential', e.target.checked, {
      shouldDirty: true
    });
  };
  const handleRelease = e => {
    setValue('contact_compensations.compensation_release', e.target.checked, {
      shouldDirty: true
    });
  };
  useEffect(() => {
    setIsMakeConfidential(currentValues?.contact_compensation?.is_confidential);
    setValue('contact_compensations.currency_iso_code', currentValues?.contact_compensation?.currency_iso_code);
  }, [currentValues]);

  const cancelData = () => {
    onCancel(isDirty);
  };
  return (
    <>
      <Loader show={loading} />
      {currentValues ? (
        <Box id='compensation-container'>
          <Box>
            <Footer onSubmit={handleSave} onClose={cancelData}></Footer>
          </Box>
          <Box className='compensation-header'>
            <Box className='header-font fs-16 compensation-text'>{t(`${TRANS_KEYS.CONTACTS_COMPENSATION_INFO}:key`)}</Box>
            <Box className='d-flex item-center'>
              <MakeConfidential name={title} defaultChecked={isMakeConfidential} handleChange={e => handleMakeConfidential(e)} />
            </Box>
          </Box>
          <Box className='compensation-section'>
            <Paper className='paper-container pb-3 p-11' elevation={0}>
              <Stack spacing={5}>
                <Box className='currency-block'>
                  <Box className='d-flex flex-row flex-wrap column-space row-space'>
                    <Box className='content-gap d-flex flex-row row-space flex-wrap column-space align-items-end'>
                      <Box>
                        {/* <Box className="compensation-content fs-14 mb-1">
                      {t(`${TRANS_KEYS.CONTACTS_CURRENCY_CODE}:key`)}
                    </Box> */}
                        <Box className='compensation-autocomplete-code'>
                          <CurrencySelection
                            defaultValue={currentValues?.contact_compensation?.currency_iso_code || ''}
                            onChange={(e, data) => {
                              setValue('contact_compensations.currency_iso_code', data && data ? data.label : '', {
                                shouldDirty: true
                              });
                            }}
                            label={t(`${TRANS_KEYS.CONTACTS_CURRENCY_CODE}:key`)}
                          ></CurrencySelection>
                        </Box>
                      </Box>
                      <Box>
                        <Box className='compensation-input'>
                          {/* <Box className="compensation-content fs-14 mb-1">
                        {t(`${TRANS_KEYS.CONTACTS_BASE_SALARY}:key`)}
                      </Box> */}
                          <Box className='compensation-field'>
                            <CustomInputField
                              type='number'
                              defaultValue={currentValues?.contact_compensation?.base_salary || ''}
                              onChange={e => {
                                setValue('contact_compensations.base_salary', Number(e.target.value), { shouldDirty: true });
                              }}
                              label={t(`${TRANS_KEYS.CONTACTS_BASE_SALARY}:key`)}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box className='compensation-input'>
                        {/* <Box className="compensation-content fs-14 mb-1">
                      {t(`${TRANS_KEYS.CONTACTS_EXPECTED_COMPENSATION}:key`)}
                    </Box> */}
                        <Box className='compensation-field'>
                          <CustomInputField
                            type='number'
                            defaultValue={currentValues?.contact_compensation?.compensation_expectation || ''}
                            onChange={e => {
                              setValue('contact_compensations.compensation_expectation', e.target.value, { shouldDirty: true });
                            }}
                            label={t(`${TRANS_KEYS.CONTACTS_EXPECTED_COMPENSATION}:key`)}
                          />
                        </Box>
                      </Box>
                      <Box id='compensation-lti'>
                        {/* <Box className="compensation-content fs-14 mb-1">
                      {t(`${TRANS_KEYS.CONTACTS_LTI}:key`)}
                    </Box> */}
                        <Box className='compensation-field'>
                          <CustomInputField
                            type='number'
                            defaultValue={currentValues?.contact_compensation?.equity || ''}
                            onChange={e => {
                              setValue('contact_compensations.equity', e.target.value, { shouldDirty: true });
                            }}
                            label={t(`${TRANS_KEYS.CONTACTS_LTI}:key`)}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Box className='compensation-content fs-14'>{t(`${TRANS_KEYS.CONTACTS_COMPENSATION_RELEASE}:key`)}</Box>
                        <Box className='compensation-check-typo'>
                          <Checkbox className='checkboxStyle' defaultChecked={currentValues?.contact_compensation?.compensation_release} onChange={e => handleRelease(e)}></Checkbox>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className='compensation-table'>
                  <CustomTable
                    headerData={[`% ${t(`${TRANS_KEYS.CONTACT_OR}:key`)} $`, t(`${TRANS_KEYS.CONTACTS_BONUS_TYPE}:key`), t(`${TRANS_KEYS.CONTACTS_BONUS_VALUE}:key`)]}
                    title={t(`${TRANS_KEYS.CONTACTS_BONUS}:key`)}
                    handleAnotherRow={handleAddAnother}
                    rows={compensationDetails}
                    dataList={bonusList}
                  >
                    {compensationDetails.map((details, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className='p-2'>
                            <Box className='compensation-autocomplete'>
                              <BonusUnitSelection
                                value={details.bonus_type || ''}
                                onChange={(e, value) => {
                                  handleChangeDetails(index, 'bonus_type', value?.name);
                                }}
                                code={watch('contact_compensations.currency_iso_code')}
                              />
                            </Box>
                          </TableCell>

                          <TableCell className='p-2'>
                            <Box className='bonus-type'>
                              <BonusTypeSelection
                                value={details.bonus || ''}
                                onChange={(e, value) => {
                                  handleChangeDetails(index, 'bonus', value?.short_desc);
                                }}
                                // label={t(`${TRANS_KEYS.CONTACTS_BONUS_TYPE}:key`)}
                                selectedList={compensationDetails}
                                setBonusList={setBonusList}
                              ></BonusTypeSelection>
                            </Box>
                          </TableCell>
                          <TableCell className='p-2'>
                            <Box className='compensation-input'>
                              <Box className='compensation-field'>
                                <CustomInputField
                                  type='number'
                                  value={details.bonus_value || ''}
                                  onChange={e => {
                                    const newBonusValue = Number(e.target.value);
                                    if (details.bonus_type === '%') {
                                      if (newBonusValue <= 100) {
                                        handleChangeDetails(index, 'bonus_value', newBonusValue);
                                      } else {
                                        enqueueSnackbar(' Value should be less than 100', { variant: ERROR });
                                      }
                                    } else {
                                      handleChangeDetails(index, 'bonus_value', newBonusValue);
                                    }
                                    // setValue(`${watch("bonus")}_bonus_value`, Number(e.target.value));
                                  }}
                                />
                              </Box>
                            </Box>
                          </TableCell>
                          {compensationDetails.length > 1 && (
                            <TableCell className='p-2'>
                              <CloseIcon onClick={() => handleRemoveCompensationDetails(index)} className='table-close-icon' />
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </CustomTable>
                </Box>

                {/* section 5 */}
                <Box className='currency-block'>
                  <Box className='d-flex flex-row flex-wrap w-100'>
                    <Box className='d-flex flex-column row-space flex-1 w-100 content-gap'>
                      <Box className='compensation-content fs-14'>{t(`${TRANS_KEYS.CONTACTS_COMPENSATION_NOTES}:key`)}</Box>
                      <Box className='text-area'>
                        <RichText
                          onChange={data => {
                            setValue('contact_compensations.compensation_notes', data, { shouldDirty: true });
                          }}
                          defaultValue={currentValues?.contact_compensation?.compensation_notes || ''}
                        ></RichText>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </Paper>
          </Box>
        </Box>
      ) : null}
    </>
  );
});
Compensation.propTypes = {
  register: PropTypes.func,
  unregister: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  handleSaveTabData: PropTypes.func,
  id: PropTypes.string,
  onCancel: PropTypes.func
};

export default Compensation;
