import { getFilterQuery } from '../../Commons/Utils';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';

export const PAGE_LIMIT = 200;
export const SUCCESS_STATUS_CODE = 200;

const defaultColDef = {
  lockPinned: true,
  tooltipField: ''
};

export const columnDefs = useLanguageHooks => {
  const { t } = useLanguageHooks([
    TRANS_KEYS.BACK,
    TRANS_KEYS.TRANSLATE_VALUE_ADD_VALUES,
    TRANS_KEYS.TRANSLATE_VALUE_FIELD_NAME,
    TRANS_KEYS.TRANSLATE_VALUE_FIELD_VALUE,
    TRANS_KEYS.TRANSLATE_VALUE_LANGUAGE,
    TRANS_KEYS.TRANSLATE_VALUE_LONG_DESC,
    TRANS_KEYS.TRANSLATE_VALUE_ACTIVE
  ]);
  return [
    {
      ...defaultColDef,
      headerName: t(`${TRANS_KEYS.TRANSLATE_VALUE_FIELD_NAME}:key`),
      field: 'field_name',
      colId: 'field_name',
      sortable: true,
      width: 250,
      tooltipField: 'field_name',
      cellRenderer: 'translateViewRenderer',
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      ...defaultColDef,
      headerName: t(`${TRANS_KEYS.TRANSLATE_VALUE_FIELD_VALUE}:key`),
      field: 'field_value',
      colId: 'field_value',
      sortable: true,
      width: 250,
      tooltipField: 'field_value',
      cellRenderer: 'translateViewRenderer',
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      ...defaultColDef,
      headerName: t(`${TRANS_KEYS.TRANSLATE_VALUE_LANGUAGE}:key`),
      sortable: true,
      field: 'lang_cd',
      colId: 'lang_cd',
      width: 250,
      tooltipField: 'lang_cd',
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      ...defaultColDef,
      headerName: t(`${TRANS_KEYS.TRANSLATE_VALUE_SHORT_DESC}:key`),
      sortable: true,
      field: 'short_desc',
      colId: 'short_desc',
      width: 300,
      tooltipField: 'short_desc',
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      ...defaultColDef,
      headerName: t(`${TRANS_KEYS.TRANSLATE_VALUE_ACTIVE}:key`),
      field: 'is_active',
      colId: 'is_active',
      width: 270,
      cellRenderer: 'ActionsRenderer'
    },
    {
      ...defaultColDef,
      colId: 'searchValueFilter',
      hide: true
    }
  ];
};

export const getFilterParamStringForTranslateValue = data => {
  return getFilterQuery(data, {}, {}, '');
};
