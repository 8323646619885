import PropTypes from 'prop-types';
import React, { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Box, Card, CardContent, Container, Grid, IconButton } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import delete_icon from '../../../assets/icons/delete_icon.svg';
import { AllDocumentsApi, contactDocumentApi } from '../../../services/ApiService';
import { DELETE, IGN_API, SUCCESS } from '../../../services/constantService';
import { useCustomMessageHook } from '../../../utils/Hooks/useCustomMessageHook';
import CustomConfirmationPopup from '../CustomConfirmationPopup';

export const CustomCardUi = props => {
  const {
    addIcon,
    children,
    downloadIcon,
    editItems,
    download,
    item,
    styleData = {},
    getContact = () => {},
    getContactDetails = () => {},
    type,
    deleteToast,
    //getContact= () => {},
    isForCompany = false,
    setLoading = () => {}
  } = props;
  const {
    // titleSize = 14,
    // textSize = 12,
    height = 110,
    //width = "auto",
    minWidth = 'auto'
  } = styleData;

  const { translateMessage } = useCustomMessageHook();
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [id, setId] = useState();

  const deleteItems = () => {
    setId(item?.id);
    setOpenConfirmationPopup(true);
  };
  const closeConfirmationPopup = () => {
    setOpenConfirmationPopup(false);
  };

  const onConfirm = async () => {
    setLoading(true);
    if (type == 'project_document') {
      const sub_route = `${IGN_API.project}/documents/${id}`;
      const { status } = await AllDocumentsApi(DELETE, sub_route);
      if (status == 200) {
        getContact();
        setLoading(false);
        const message = translateMessage('Successfully', false, deleteToast, 'Deleted');
        enqueueSnackbar(message, { variant: SUCCESS });
      }
    } else if (!isForCompany) {
      const { status } = await contactDocumentApi(DELETE, id, null, null, null, type);
      if (status === 200) {
        setLoading(false);
        await getContactDetails(item?.contact_id);
        setLoading(false);
        const message = translateMessage('Successfully', false, deleteToast, 'Deleted');
        enqueueSnackbar(message, { variant: SUCCESS });
      }
    } else {
      const sub_route = `${IGN_API.company_attachment}/${id}`;
      const { status } = await AllDocumentsApi(DELETE, sub_route);
      if (status === 200) {
        setLoading(false);
        await getContactDetails();
        setLoading(false);
        const message = translateMessage('Successfully', false, deleteToast, 'Deleted');
        enqueueSnackbar(message, { variant: SUCCESS });
      }
    }
  };

  return (
    <>
      <Container id='custom-card' className='m-0 p-0'>
        <Card
          sx={{
            maxHeight: height,
            minHeight: height,
            minWidth: minWidth
          }}
        >
          <CardContent>
            <Grid container>
              <Grid item xs={9} className='justify-content-between w-100 pr-2'>
                <Box className='d-flex align-items-center ' gap={1}>
                  <Box component='div' className={'card-title font-weight-bold m-0 fs-12'}></Box>
                  <Box>{children}</Box>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className='top-level'>
                  {downloadIcon && (
                    <IconButton className='download-icon p-0 m-0 pr-1' onClick={download}>
                      {downloadIcon}
                    </IconButton>
                  )}
                  <IconButton onClick={editItems} className='green-bg edit-icon mr-1 p-1'>
                    <EditIcon className='img'></EditIcon>
                  </IconButton>
                  <IconButton className='delete-icons pr-2 pt-0 mt-1' onClick={deleteItems}>
                    <img src={delete_icon} alt='' />
                  </IconButton>
                </Box>
                <Box className='bottom-level pt-1'>
                  {addIcon && (
                    <Box className='security-icon pr-2'>
                      <img src={addIcon} alt='' />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>

      <CustomConfirmationPopup open={openConfirmationPopup} onClose={closeConfirmationPopup} type='delete' cancelText='No' confirmText='Yes' onConfirm={onConfirm} setOpen={setOpenConfirmationPopup} />
    </>
  );
};

CustomCardUi.propTypes = {
  children: PropTypes.node,
  downloadIcon: PropTypes.string,
  editItems: PropTypes.func,
  download: PropTypes.func,
  deleteItem: PropTypes.func,
  // height: PropTypes.number,
  // width: PropTypes.number,
  // minWidth: PropTypes.number,
  addIcon: PropTypes.string,
  styleData: PropTypes.object,
  item: PropTypes.object,
  getContact: PropTypes.func,
  getContactDetails: PropTypes.func,
  type: PropTypes.string,
  deleteToast: PropTypes.string,
  setLoading: PropTypes.func,
  isForCompany: PropTypes.bool
};
export default CustomCardUi;
