import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { ExpandableSection } from '../../../../../components/ActivityLog/Components/ExpandableSection';
import CurrencySelection from '../../../../../components/common/FunctionalComponents/CurrencySelection';
import FeetypeSelection from '../../../../../components/common/FunctionalComponents/FeetypeSelection';
import RevenueMarketSelection from '../../../../../components/common/FunctionalComponents/RevenueMarketSelection';
import TermOfInvoice from '../../../../../components/common/FunctionalComponents/TermOfInvoice';
import TermtypeIntervalSelection from '../../../../../components/common/FunctionalComponents/TermtypeIntervalSelection';
import TermtypeSelection from '../../../../../components/common/FunctionalComponents/TermtypeSelection';
import CustomInputField from '../../../../../components/common/StyledComponents/CustomInputField';
import TotalRevenue from '../../../../../components/CreateBD/BillingDetails/Components/EstimatedRevenue';
import TotalViewRevenue from '../../../../../components/CreateBD/BillingDetails/Components/EstimatedRevenue/TotalViewRevenue';
import IndirectFee from '../../../../../components/CreateBD/BillingDetails/Components/IndirectFee';
import PercentageOfBase from '../../../../../components/CreateBD/BillingDetails/Components/PercentageOfBase';
import { BILLING_VALIDATIONS, DECIMAL_SEPARATOR, FEE_TYPES, THOUSAND_SEPARATOR } from '../../../../../services/constantService';
import { getFixedDecimal } from '../../../../../utils/common';
import variables from '../../../../../variables.scss';
import { currencyWithCommas, getCurrencyPrefix, separator } from '../../../../Commons/Utils';
//----------------------------------------------// Internal Imports // -------------------------------------------------

const FeeTypeInformation = props => {
  const { register, watch, setValue, isEditing = true, currentValues, currencyDetails, isDisabled = false } = props;

  useEffect(() => {
    if (isEditing) {
      const field = [
        'billable',
        'billing_region',
        'contact_name',
        'billing_notes',
        'zip_code',
        'mobile_phone',
        'work_email',
        'is_po_direct',
        'invoice_delivery_type',
        'po_number',
        'billing_notes',
        'is_fee_cap',
        'markets',
        'fee_percentage',
        'term_type',
        'intervals',
        'fee_type',
        'bd_currency_values',
        'market'
      ];
      field.forEach(fieldName => {
        if (fieldName === 'bd_currency_values') {
          setValue('bd_currency', currentValues[fieldName]?.id);
        } else if (fieldName === 'market') {
          setValue('market', currentValues['markets']?.id);
        } else {
          setValue(fieldName, currentValues[fieldName]);
        }
      });
    }
  }, []);
  const [calculationType, setCalculationType] = useState('estimated');

  const [totalEstimate, setTotalEstimate] = useState(0);
  const fe_Cap_amount = watch('fee_cap_amount');
  const is_fee_cap = watch('is_fee_cap');

  useEffect(() => {
    register('fee_type');
    register('estimated_total_comp');
    register('actual_total_comp');
    register('estimated_fee_amount');
    register('currency');
    register('fee_amount');
    register('probability');
    register('bd_status');
    register('intervals_other');
    register('intervals');
    register('term_type');
    register('invoice_terms');
    register('invoice_terms_desc');
    register('actual_overridden_revenue');
    register('actual_percentage_bases');
    register('estimated_percentage_bases');
    register('estimated_revenue');
    register('actual_revenue');
    register('is_actual_overridden_revenue');
    register('actual_percentage_base');
    register('estimated_percentage_base');
    register('estimated_indirect_total_fee');
    register('actual_indirect_total_fee');
    register('fee_cap_amount');
    register('by_product_fee');
    register('old_actual_revenue');
    register('bd_currency');
  }, [register]);

  useEffect(() => {
    if (watch('actual_percentage_bases')?.find(ele => ele?.type || ele?.actual_amt)) {
      setCalculationType('actual');
    } else {
      setCalculationType('estimated');
    }
  }, [watch('actual_percentage_bases')]);

  const getTotalPercentageOfBase = (data, key, formattedValue) => {
    const currency = getCurrencyPrefix(watch('currency') || '');
    const percentage =
      data?.reduce((prev, curr) => {
        if (curr[key]) prev = prev + Number(curr[key]);
        return prev;
      }, 0) || 0;
    return formattedValue ? getValueWithCurrency(percentage) : { currency, number: percentage };
  };

  const getIndirectFees = type => {
    if (type === 'actual' && calculationType === 'estimated') return [];
    let estimatedIndirectFees = watch('project_indirect_fees') || [];
    estimatedIndirectFees = estimatedIndirectFees.map(ele => {
      return {
        ...ele,
        calculation_amt:
          ele.calculation_type === 'flat_amount' ? ele.calculation_amt : (getTotalPercentageOfBase(watch(`${type}_percentage_bases`), `calculated_${type}_amt`).number * ele.percentage) / 100
      };
    });
    return estimatedIndirectFees;
  };

  const getEstimatedGrandTotal = () => {
    const totalPercentageOfBase = getTotalPercentageOfBase(watch('estimated_percentage_bases'), 'calculated_estimated_amt', false);
    const estimatedIndirectFees = getIndirectFees('estimated');
    const totalIndirectFee = getTotalPercentageOfBase(estimatedIndirectFees, 'calculation_amt');
    setValue('estimated_percentage_base', getFixedDecimal(totalPercentageOfBase.number));
    setValue('estimated_indirect_total_fee', getFixedDecimal(totalIndirectFee.number));
    setValue('estimated_revenue', getFixedDecimal(totalPercentageOfBase?.number + totalIndirectFee?.number));
    setTotalEstimate(getFixedDecimal(totalPercentageOfBase?.number + totalIndirectFee?.number));
    return getValueWithCurrency(totalPercentageOfBase?.number + totalIndirectFee?.number);
  };

  const getActualGrandTotal = () => {
    const totalPercentageOfBase = getTotalPercentageOfBase(watch('actual_percentage_bases'), 'calculated_actual_amt', false);
    const actualIndirectFees = getIndirectFees('actual');
    const totalIndirectFee = getTotalPercentageOfBase(actualIndirectFees, 'calculation_amt');
    setValue('old_actual_revenue', getFixedDecimal(currentValues.actual_revenue));
    setValue('actual_percentage_base', getFixedDecimal(totalPercentageOfBase.number));
    setValue('actual_indirect_total_fee', getFixedDecimal(totalIndirectFee.number));
    setValue('actual_revenue', getFixedDecimal(totalPercentageOfBase?.number + totalIndirectFee?.number));
    return getValueWithCurrency(totalPercentageOfBase?.number + totalIndirectFee?.number);
  };

  const getTotalIndirectFee = type => {
    return getValueWithCurrency(currentValues[`${type}_indirect_total_fee`] || 0);
  };
  const getRevenue = type => {
    return getValueWithCurrency(currentValues[`${type}_revenue`] || 0);
  };
  const getTotalPercentageOfBaseAdd = type => {
    setTotalEstimate(currentValues[`${type}_percentage_base`] || 0);
    return getValueWithCurrency(currentValues[`${type}_percentage_base`] || 0);
  };
  const getValueWithCurrency = data => {
    return `${getCurrencyPrefix(watch('currency') || '')} ${currencyWithCommas(getFixedDecimal(data || 0).toFixed(2))}`;
  };
  return (
    <ExpandableSection title='Fee & Admin Information' defaultOpen={true} accordionClassName='accordion' titleClassName='title-text' isTeamInfo={true}>
      <Box>
        <Box id='education-experiences' className=' p-4'>
          <Box className='my-cards flex flex-wrap'>
            <Box className='form-section flex flex-column'>
              <Box className='d-flex flex-wrap p-2 justify-content-between'>
                <Box className='col-md-6'>
                  <CurrencySelection
                    disabled={isDisabled}
                    className={'w-100'}
                    defaultValue={currentValues?.bd_currency_values?.code || watch('bd_currency_values.code') || null}
                    onChange={(e, data) => {
                      setValue('bd_currency', data ? data?.id : null);
                      setValue('bd_currency_values.code', data?.label);
                    }}
                    label={'Currency'}
                    required={true}
                  />
                </Box>
                <Box className='col-md-6'>
                  <CustomInputField
                    isDisabled={isDisabled}
                    defaultValue={watch('by_product_fee') || ''}
                    label={'By Product Fee'}
                    onChange={e => {
                      setValue('by_product_fee', e.target.value);
                    }}
                    flag='true'
                    type='number'
                    onKeyPress={event => {
                      if (event?.key === '-' || event?.key === '+') {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{ min: 0, max: 100 }}
                  />
                </Box>
              </Box>

              <Box className='d-flex flex-wrap p-2 justify-content-between'>
                <Box className='col-md-6 d-flex align-items-center'>
                  <Typography className='p-2' variant='body2'>
                    {'Fee Cap'}
                  </Typography>
                  <FormControlLabel
                    className='mb-0'
                    control={
                      <Switch
                        disabled={isDisabled}
                        className='SwitchStyle'
                        checked={watch('is_fee_cap') || false}
                        onChange={e => {
                          setValue('fee_cap_amount', null);
                          setValue('is_fee_cap', e.target.checked);
                        }}
                        {...register('is_fee_cap')}
                      />
                    }
                  />
                </Box>
                <Box className='col-md-6'>
                  {watch('is_fee_cap') ? (
                    <NumericFormat
                      isDisabled={isDisabled}
                      label={'Fee Cap Amount'}
                      customInput={CustomInputField}
                      fullWidth='true'
                      name='fee_cap_amount'
                      {...register('fee_cap_amount')}
                      defaultValue={currentValues.fee_cap_amount}
                      thousandSeparator={separator(watch('bd_currency_values.code'), THOUSAND_SEPARATOR)}
                      decimalSeparator={separator(watch('bd_currency_values.code'), DECIMAL_SEPARATOR)}
                      decimalScale={2}
                      isNumericString={true}
                      prefix={currencyDetails ? (currencyDetails.prefix ? `${currencyDetails.currency_icon} ` : '') : ''}
                      suffix={currencyDetails ? (currencyDetails.prefix === false ? ` ${currencyDetails.currency_icon}` : '') : ''}
                      onValueChange={values => {
                        const { value } = values;
                        setValue('fee_cap_amount', value);
                      }}
                    />
                  ) : (
                    <Box component={'span'} className='search-details'>
                      &nbsp;
                    </Box>
                  )}
                </Box>
              </Box>

              <Box className='d-flex flex-wrap p-2 justify-content-between'>
                <Box className='col-md-6'>
                  <TermtypeSelection
                    isDisabled={isDisabled}
                    className={'w-100'}
                    value={watch('term_type') || null}
                    {...register('term_desc')}
                    label='Term Type'
                    onChange={(e, data) => {
                      setValue('intervals_desc', null);
                      setValue('intervals', null);
                      setValue('intervals_other', null);
                      setValue('term_type', data ? data.short_desc : null);
                      setValue('term_desc', data ? data.short_desc : null);
                    }}
                  />
                </Box>
                <Box className='col-md-6'>
                  <TermtypeIntervalSelection
                    className={'w-100'}
                    isDisabled={isDisabled}
                    disabled={!watch('term_type')}
                    value={watch('intervals') || null}
                    {...register('intervals_desc')}
                    type={watch('term_type') === 'Milestone' ? 'INTERVAL_MILE' : 'INTERVALS'}
                    label='Intervals'
                    onChange={(e, data) => {
                      setValue('intervals', data ? data.short_desc : null);
                      setValue('intervals_desc', data ? data.short_desc : null);
                      if (data !== null && data.field_value !== 'Other Interval') {
                        setValue('intervals_other', '');
                      }
                    }}
                  />
                </Box>
              </Box>

              <Box className='d-flex flex-wrap p-2 justify-content-between'>
                <Box className='col-md-6'>
                  <RevenueMarketSelection
                    disabled={isDisabled}
                    className={'w-100'}
                    defaultValue={currentValues?.markets?.market || null}
                    onChange={(e, data) => {
                      setValue('markets', data);
                    }}
                    label={'Geography'}
                    required={true}
                  />
                </Box>
                <Box className='col-md-6'>
                  <TermOfInvoice
                    isDisabled={isDisabled}
                    className={'w-100'}
                    ref={register('invoice_terms_desc')}
                    value={currentValues?.invoice_terms_desc?.short_desc ?? watch('invoice_terms_desc')}
                    InputLabelProps={{ focused: true }}
                    label='Terms of Invoice'
                    onChange={(e, data) => {
                      setValue('invoice_terms', data ? data.field_value : null);
                      setValue('invoice_terms_desc', data ? data.short_desc : null);
                    }}
                  />
                </Box>
              </Box>

              <Box className='d-flex flex-wrap p-2 justify-content-between'>
                <Box className='col-md-6'>
                  <FeetypeSelection
                    isDisabled={isDisabled}
                    className={'w-100'}
                    defaultValue={watch('fee_type') || currentValues.fee_type || FEE_TYPES.ONE_THIRD}
                    {...register('fee_type_desc')}
                    type='FEE_TYPE'
                    onChange={(e, data) => {
                      setValue('fee_type', data ? data.short_desc : null);
                      setValue('fee_type_desc', data ? data.short_desc : null);
                      setValue('fee_percentage', !data || data.short_desc === FEE_TYPES.ONE_THIRD ? 33.33 : !data || data.short_desc === FEE_TYPES.FIXED_FEE ? 0 : null);
                    }}
                    label='Fee Type'
                  />
                </Box>
                <Box className='col-md-6'>
                  <CustomInputField
                    isDisabled={isDisabled}
                    value={watch('fee_percentage') || ''}
                    label={'Fee Percentage'}
                    onChange={e => {
                      setValue('fee_percentage', e.target.value);
                    }}
                    disabled={watch('fee_type') !== FEE_TYPES.OTHER}
                    type='number'
                    inputPropsMin={0}
                    inputPropsMax={100}
                    error={watch('fee_percentage') > 100}
                    flag='true'
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className='d-flex flex-column pl-4 pr-4 pt-2 justify-content-start'>
          <Box id='education-experiences' className='d-flex flex-column'>
            <Box className='d-flex mt-5 mx-3 justify-content-between'>
              <Box className='flex-2' sx={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <PercentageOfBase
                    register={register}
                    setValue={setValue}
                    currentValues={currentValues}
                    watch={watch}
                    fee_type={watch('fee_type')}
                    type='estimated'
                    disabled={false} //WARNING: Defaulted to false
                    isDisabled={isDisabled}
                    getValueWithCurrency={getValueWithCurrency}
                  />
                </Box>
              </Box>
              <Box className='justify-content-end' sx={{ width: '25%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  {currentValues.billing_status === 'ACK_DONE_EST' || currentValues.billing_status === 'PND_ACK' || currentValues.billing_status === 'ACK_DONE' ? (
                    <TotalViewRevenue
                      getTotalIndirectFee={getTotalIndirectFee}
                      type='estimated'
                      getRevenue={getRevenue}
                      getTotalPercentageOfBase={getTotalPercentageOfBaseAdd}
                      billingStatus={currentValues.billing_desc}
                    />
                  ) : (
                    <TotalRevenue watch={watch} getTotalPercentageOfBase={getTotalPercentageOfBase} getGrandTotal={getEstimatedGrandTotal} type='estimated' getIndirectFees={getIndirectFees} />
                  )}
                  {is_fee_cap && Number(totalEstimate) > Number(fe_Cap_amount) && (
                    <Box sx={{ marginTop: '23px', marginLeft: '15px' }}>
                      <Box component='span' sx={{ color: variables.error }}>
                        {BILLING_VALIDATIONS.FEE_CAP_AMOUNT}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            {currentValues.record_type !== 'Business Development' && (
              <Box className='d-flex mt-5 mx-3 justify-content-between'>
                <Box className='flex-2' sx={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <PercentageOfBase
                      isDisabled={isDisabled}
                      register={register}
                      setValue={setValue}
                      currentValues={currentValues}
                      watch={watch}
                      fee_type={watch('fee_type')}
                      type='actual'
                      getValueWithCurrency={getValueWithCurrency}
                    />
                  </Box>
                </Box>
                <Box className='justify-content-end' sx={{ width: '25%', display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <TotalRevenue
                      watch={watch}
                      getTotalPercentageOfBase={getTotalPercentageOfBase}
                      getGrandTotal={getActualGrandTotal}
                      type='actual'
                      getIndirectFees={getIndirectFees}
                      register={register}
                      setValue={setValue}
                      currencyDetails={currencyDetails}
                      currentValues={currentValues}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            <Box className='d-flex mt-5 mx-3 justify-content-between'>
              <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <IndirectFee
                  register={register}
                  setValue={setValue}
                  currentValues={currentValues}
                  watch={watch}
                  getTotalPercentageOfBase={getTotalPercentageOfBase}
                  type={calculationType}
                  getValueWithCurrency={getValueWithCurrency}
                  isDisabled={isDisabled}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </ExpandableSection>
  );
};

FeeTypeInformation.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  isEditing: PropTypes.bool,
  isDisabled: PropTypes.bool,
  currencyDetails: PropTypes.object,
  currentValues: PropTypes.object
};

export default FeeTypeInformation;
