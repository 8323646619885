//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { POST, PUT, SUCCESS } from '../../../services/constantService';
import { userDataApi } from '../../../services/ApiService';
import { successMessage } from '../../../services/MessageService';
import { useSnackbar } from 'notistack';

function SaveQueryDialog(props) {
  const { open, handleClose, queryData, userId, page, renamePopup, defaultValue, savedValues, idSelected } = props;
  const { register, handleSubmit } = useForm();
  const [error, setError] = useState(false);

  const saveQuery = async data => {
    const filterData = savedValues?.filter(value => value === data.name);
    const { enqueueSnackbar } = useSnackbar();
    if (data.name === defaultValue || filterData?.length) {
      setError(true);
    } else {
      let payload = {};
      if (renamePopup) {
        payload = { ...data, idSelected };
      } else {
        payload = { ...data, query: queryData, section: page };
      }
      const { status } = renamePopup ? await userDataApi(PUT, userId, payload, 'queries') : await userDataApi(POST, userId, payload, 'queries');
      if (status === 201) {
        handleClose();
        const message = successMessage('Query Saved', 'Contact by ');
        enqueueSnackbar(`${data.name} ${message}`, { variant: SUCCESS });
      } else {
        setError(true);
      }
    }
  };

  const close = () => {
    handleClose();
    if (error) {
      setError(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle style={{ width: 400 }} id='form-dialog-title'>
        {renamePopup ? 'Rename Query' : 'Save Query'}
      </DialogTitle>
      <DialogContent>
        <TextField
          defaultValue={renamePopup && defaultValue ? defaultValue : ''}
          {...register('name', { required: true })}
          autoFocus
          InputLabelProps={{ focused: true }}
          required={true}
          onChange={() => setError(false)}
          error={error}
          helperText={error ? 'The query with given name already exist' : ''}
          margin='dense'
          name='name'
          label='Query Name'
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleSubmit(saveQuery)} color='primary'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SaveQueryDialog.propTypes = {
  open: PropTypes.bool,
  userId: PropTypes.string,
  queryData: PropTypes.object,
  handleClose: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  page: PropTypes.string,
  renamePopup: PropTypes.bool,
  defaultValue: PropTypes.string,
  savedValues: PropTypes.array,
  idSelected: PropTypes.object
};

export default SaveQueryDialog;
