import { Box, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import CustomButton from '../../../../components/common/CustomButton';
import CustomPopup from '../../../../components/common/CustomPopup';
import { rolesApi } from '../../../../services/ApiService';
import { POST, ROLE_VALIDATION } from '../../../../services/constantService';

const AddRolePopup = ({ isOpen, setIsPopupOpen, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = React.useState({
    name: '',
    description: ''
  });

  const addRole = async () => {
    if (formData.name === '') {
      enqueueSnackbar(ROLE_VALIDATION.ADD_NAME, { variant: 'error' });
      return;
    }
    await rolesApi(POST, '', {
      name: formData.name,
      description: formData.description
    }).then(async res => {
      if (res.status === 201) {
        enqueueSnackbar(ROLE_VALIDATION.SUCCESS, { variant: 'success' });
        await refetch();
        setIsPopupOpen(false);
      } else {
        enqueueSnackbar(ROLE_VALIDATION.FAILED, { variant: 'error' });
      }
    });
    setFormData({
      name: '',
      description: ''
    });
  };

  return (
    <CustomPopup
      open={isOpen}
      onClose={() => {
        setIsPopupOpen(false);
      }}
      title={'Add Role'}
      confirmText={'Add Role'}
    >
      <Box
        sx={{
          width: '50vw',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly'
        }}
      >
        <TextField
          id='outlined-basic'
          label='Role Name'
          variant='outlined'
          size='small'
          sx={{
            width: '50%'
          }}
          onChange={e => {
            setFormData({
              ...formData,
              name: e.target.value
            });
          }}
        />

        <TextField
          id='outlined-basic'
          label='Description'
          variant='outlined'
          size='small'
          sx={{
            mt: 2,
            mb: 4
          }}
          onChange={e => {
            setFormData({
              ...formData,
              description: e.target.value
            });
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2
          }}
        >
          <CustomButton
            type='tertiary-error'
            buttonText='Cancel'
            onClick={() => {
              setIsPopupOpen(false);
            }}
          />
          <CustomButton
            buttonText='Assign Resource'
            onClick={() => {
              addRole();
            }}
          />
        </Box>
      </Box>
    </CustomPopup>
  );
};

AddRolePopup.propTypes = {
  isOpen: PropTypes.bool,
  setIsPopupOpen: PropTypes.func,
  data: PropTypes.object,
  refetch: PropTypes.func
};

export default AddRolePopup;
