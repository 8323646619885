//-----------------------------------------------------------// Internal Imports // ------------------------------

import { LINKEDIN_SCRAPER, LOE_GENERATION } from '../services/constantService';
import {
  GET_CLIENT_LIST,
  GET_KGP_TEAM_LIST,
  UPDATE_CANDIDATE_LIST,
  UPDATE_ATTACHMENTS_LIST,
  UPDATE_LOES_LIST,
  UPDATE_PICK_LIST,
  UPDATE_SEARCHDATA,
  UPDATE_SFPADATA,
  UPDATE_TIMEZONE_DATA,
  UPDATE_USER_DETAILS,
  UPDATE_USER_LIST,
  UPDATE_STATE_LIST,
  UPDATE_ARENA_FEEDBACK,
  UPDATE_TRACK_COMMENTS,
  UPDATE_CLIENTSUITE_FEEDBACK,
  UPDATE_CANDIDATESUITE_FEEDBACK,
  RELOAD_ALL_CANDIDATE_GRID,
  RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE,
  UPDATE_SOCKET,
  REFRESH_CONTACTS,
  REFRESH_SEARCH,
  REFRESH_BDLIST
} from '../types';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        userData: action.payload
      };
    case UPDATE_PICK_LIST:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    case UPDATE_USER_LIST:
      return {
        ...state,
        [action.payload.key]: action.payload.data.data
      };
    case GET_CLIENT_LIST:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    case GET_KGP_TEAM_LIST:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    case RELOAD_ALL_CANDIDATE_GRID:
      return {
        ...state,
        clientsuiteandreports: undefined,
        activeCandidate: undefined,
        allCandidate: undefined,
        productOneCandidates: undefined,
        [LINKEDIN_SCRAPER.myLinkedinCandidatesRedux]: undefined
      };
    case RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE:
      return {
        ...state,
        clientsuiteandreports: undefined,
        activeCandidate: undefined,
        allCandidate: undefined,
        [LINKEDIN_SCRAPER.myLinkedinCandidatesRedux]: undefined
        // productOneCandidates: undefined
      };
    case UPDATE_ATTACHMENTS_LIST:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    case UPDATE_LOES_LIST:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    case UPDATE_TIMEZONE_DATA:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    case UPDATE_SEARCHDATA:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    case UPDATE_SFPADATA:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    case UPDATE_CANDIDATE_LIST:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    case UPDATE_STATE_LIST:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    case UPDATE_TRACK_COMMENTS:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    case UPDATE_ARENA_FEEDBACK:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    case UPDATE_CLIENTSUITE_FEEDBACK:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    case UPDATE_CANDIDATESUITE_FEEDBACK:
      return {
        ...state,
        [action.payload.key]: action.payload.data
      };
    case 'RESET_MILSTONE_PICKLIST': {
      return {
        ...state,
        clients: undefined,
        kgpTeam: undefined
      };
    }
    case UPDATE_SOCKET: {
      return {
        ...state,
        socket: action.payload
      };
    }
    case REFRESH_CONTACTS: {
      return {
        ...state,
        refreshContact: action.payload
      };
    }
    case REFRESH_SEARCH: {
      return {
        ...state,
        refreshSearch: action.payload
      };
    }
    case REFRESH_BDLIST: {
      return {
        ...state,
        refreshBD: action.payload
      };
    }
    case 'resetLinkedinScraperEvents': {
      return {
        ...state,
        [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]: undefined,
        [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES]: undefined,
        [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE]: undefined
      };
    }
    case LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED: {
      return {
        ...state,
        [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]: action.payload
      };
    }
    case LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES: {
      return {
        ...state,
        [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES]: action.payload
      };
    }
    case LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE: {
      return {
        ...state,
        [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE]: action.payload
      };
    }
    case LOE_GENERATION.LOE_DOCUMENT_UPLOADED: {
      return {
        ...state,
        [LOE_GENERATION.LOE_DOCUMENT_UPLOADED]: action.payload
      };
    }
    default:
      return state;
  }
};

export default reducer;
