import sanitizeHtml from 'sanitize-html';
import { DEFAULT_ALLOWED_SANITIZE_TAGS, DEFAULT_ALLOWED_SANITIZE_ATTRIBUTES, DISCARD } from '../services/constantService';

export const sanitize = (isDirty, options) => {
  if (!isDirty) {
    return null;
  }
  const option = {
    ...{
      disallowedTagsMode: DISCARD, // set it false in boolean if want to allow all tags. set [] to restrict all.
      allowedTags: DEFAULT_ALLOWED_SANITIZE_TAGS, // default is discard which will discard the tags. set 'recursiveEscape' to escape them
      allowedAttributes: DEFAULT_ALLOWED_SANITIZE_ATTRIBUTES
    }, // set it false in boolean if want to allow all tags. set {} to restrict all.
    ...options
  };
  const sanitizedHTML = sanitizeHtml(isDirty, option);
  if (sanitizedHTML) {
    return sanitizedHTML.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  } else {
    return null;
  }
};

// module.exports = {
//     sanitize
// };
