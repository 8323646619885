//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from 'react';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { formatDateUtcLocal } from '../../../utils/date';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import { ExpandableSection } from '../../ActivityLog/Components/ExpandableSection';
import CustomTable from '../CustomTable';
import DatePickerCommon from '../DatePicker/DatePicker';
import DatePickerYear from '../DatePickerYear/DatePickerYear';
import CertificationTypeSelection from '../FunctionalComponents/CertificationTypeSelection';
import CountrySelection from '../FunctionalComponents/CountrySelection';
import LanguagesSelection from '../FunctionalComponents/LanguagesSelection';
import LevelOfEducationTypeSelection from '../FunctionalComponents/LevelOfEducationTypeSelection';
import LevelOfExpertiseSelection from '../FunctionalComponents/LevelOfExpertiseSelection';
import CustomInputField from '../StyledComponents/CustomInputField';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Delete } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Checkbox, Grid, Link, TableCell, TableRow, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const EducationAndLanguages = props => {
  const { setValue = () => {}, data, setSpecificValue, deleteSpecificValue } = props;

  const [dateRangeEducation, setDateRangeEducation] = useState({ start: '', end: '' });
  const { enqueueSnackbar } = useSnackbar();
  // const [educationEndDate, setEducationEndDate] = useState(null);
  // const [selectedCheckboxIndex, setSelectedCheckboxIndex] = useState(null);
  const [languageList, setLanguageList] = useState([]);
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_EDUCATION,
    TRANS_KEYS.EXPAND_ALL,
    TRANS_KEYS.COLLAPSE_ALL,
    TRANS_KEYS.CONTACTS_LEVEL_OF_EDUCATION,
    TRANS_KEYS.CONTACTS_FIELD_OF_STUDY,
    TRANS_KEYS.CONTACTS_SCHOOL_NAME,
    TRANS_KEYS.CONTACTS_COUNTRY,
    TRANS_KEYS.CONTACTS_WORD_FROM,
    TRANS_KEYS.CONTACTS_WORD_TO,
    TRANS_KEYS.CONTACTS_TIME_PERIOD,
    TRANS_KEYS.CONTACTS_CURRENTLY_ENROLLED,
    TRANS_KEYS.CONTACTS_EDUCATION,
    TRANS_KEYS.CONTACTS_LANGUAGES,
    TRANS_KEYS.CONTACTS_LEVEL_OF_EXPERTISE,
    TRANS_KEYS.CONTACTS_ADD_LANGUAGES,
    TRANS_KEYS.CONTACTS_ADD_ANOTHER,
    TRANS_KEYS.CONTACTS_WORD_ADD,
    TRANS_KEYS.CONTACT_CERTIFICATION_AND_LICENSE,
    TRANS_KEYS.ADD_CERTIFICATION_AND_LICENSE,
    TRANS_KEYS.CERTIFICATION_EXPIRE_ON,
    TRANS_KEYS.CERTIFICATION_TYPE,
    TRANS_KEYS.CERTIFICATION_TITLE
  ]);

  const [expanded, setExpanded] = useState('panel1');
  const handleExpand = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleExpandAll = () => {
    setExpanded(true);
  };

  const handleCollapseAll = () => {
    setExpanded(false);
  };

  // --------------------------------------------------- // Education Section // ---------------------------------------------------
  const handleChangeEducationData = (index, name, data, recordData) => {
    let updatedValue = { [name]: data };
    if (name === 'country_id') {
      updatedValue['country'] = recordData?.name;
    }

    setSpecificValue('contact_education_details', index, updatedValue);
  };

  const handleAddAnotherEducation = () => {
    const newList = [...data['contact_education_details'], { degree_name: ' ', school_name: ' ', start_date: '', end_date: '', id: data['contact_education_details'].length + 1 }];
    setValue('contact_education_details', newList);
  };

  const handleDeleteEducation = index => {
    deleteSpecificValue('contact_education_details', index);
  };

  const handleWorkingCheckChangeEducation = (id, value) => {
    setSpecificValue('contact_education_details', id, { is_present: value });
  };
  // --------------------------------------------------- // Certification Section // ---------------------------------------------------
  const handleChangeCertification = (index, field, value) => {
    setSpecificValue('contact_certificate', index, { [field]: value });
  };

  const handleAddCertificate = () => {
    const getData = data['contact_certificate'];
    const newList = [...getData, { certification_type: '', certifications: '', expire_on: '', id: data['contact_certificate'].length + 1 }];
    setValue('contact_certificate', newList);
  };

  const handleRemoveCertificate = index => {
    deleteSpecificValue('contact_certificate', index);
  };
  // --------------------------------------------------- // Language Section // ---------------------------------------------------

  const validateUniqueLanguage = name => {
    return data['contact_languages'].some(element => element?.Language === name);
  };
  const handleRemoveLanguage = index => {
    deleteSpecificValue('contact_languages', index);
  };
  const handleAddLanguage = () => {
    const getData = data['contact_languages'];
    setValue('contact_languages', [...getData, { language: '', expertise: '', id: data['contact_languages'].length + 1 }]);
  };

  const handleLanguageChange = (index, field, value) => {
    if (validateUniqueLanguage(value)) enqueueSnackbar('Language already exists', { variant: 'error' });
    setSpecificValue('contact_languages', index, { [field]: value });
  };

  const ExperienceDropdown = ({ index = '', id = '' }) => {
    return (
      <div className='w-100 d-flex justify-content-between'>
        <div>{`${t(`${TRANS_KEYS.CONTACTS_EDUCATION}:key`)} ${index + 1}`}</div>
        <Delete onClick={() => handleDeleteEducation(id)} />
      </div>
    );
  };
  ExperienceDropdown.propTypes = {
    index: PropTypes.string,
    id: PropTypes.string
  };

  return (
    <Box className='w-100 p-2'>
      <Box className='d-flex justify-content-between align-items-center mt-4 mb-2 w-100'>
        <Box className='text-label fs-16 pl-1 header-font'>{t(`${TRANS_KEYS.CONTACTS_EDUCATION}:key`)}</Box>
        <Box className='d-flex'>
          <Link
            component='button'
            className='expand-collapse-button mr-2'
            onClick={() => {
              handleExpandAll();
            }}
          >
            {t(`${TRANS_KEYS.EXPAND_ALL}:key`)}
          </Link>
          <Link
            component='button'
            className='expand-collapse-button'
            onClick={() => {
              handleCollapseAll();
            }}
          >
            {t(`${TRANS_KEYS.COLLAPSE_ALL}:key`)}
          </Link>
        </Box>
      </Box>

      {data['contact_education_details']?.map((element, index) => {
        return (
          <ExpandableSection
            key={index}
            title={<ExperienceDropdown index={index} id={element?.id} />}
            showCheckbox={false}
            defaultOpen={expanded === true ? expanded : expanded === `panel${index + 1}`}
            panelName={`panel${index + 1}`}
            ariaControlls={`panel${index + 1}d-content`}
            accID={`panel${index + 1}d-header`}
            handleExpanded={handleExpand(`panel${index + 1}`)}
            className='mb-3 w-100'
            titleClassName='text-label fs-16 header-font w-100'
          >
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <LevelOfEducationTypeSelection
                    onChange={(e, data) => {
                      handleChangeEducationData(element?.id, 'degree_name', data?.value);
                    }}
                    label={t(`${TRANS_KEYS.CONTACTS_LEVEL_OF_EDUCATION}:key`)}
                    defaultValue={element?.degree_name || null}
                    required={true}
                    className={'w-100'}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <FeildOfStudySelection
                    onChange={(e, data) => {
                      handleChangeEducationData(element?.id, 'major', data?.value);
                    }}
                    label={t(`${TRANS_KEYS.CONTACTS_FIELD_OF_STUDY}:key`)}
                    value={element?.major || null}
                    required={true}
                    className={'w-100'}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <CountrySelection
                    onChange={(e, data) => {
                      handleChangeEducationData(element?.id, 'country_id', data?.id, data);
                    }}
                    label={t(`${TRANS_KEYS.CONTACTS_COUNTRY}:key`)}
                    value={element?.country || ''}
                    className={'w-100'}
                    s
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInputField
                    // className="name-input-box"
                    onChange={e => {
                      handleChangeEducationData(element?.id, 'school_name', e.target.value);
                    }}
                    label={t(`${TRANS_KEYS.CONTACTS_SCHOOL_NAME}:key`)}
                    value={element?.school_name || ''}
                    className={'w-100'}
                  />
                </Grid>

                <Grid item xs={6}>
                  <DatePickerCommon
                    value={formatDateUtcLocal(element?.start_date, 'MM-DD-YYYY' || null)}
                    format='MM/DD/YYYY'
                    placeholder='mm/dd/yyyy'
                    onChange={e => {
                      const selectedDate = e?.$d;
                      setDateRangeEducation({ ...dateRangeEducation, start: e });
                      handleChangeEducationData(element?.id, 'start_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null);
                    }}
                    maxDate={dateRangeEducation.end}
                    label={t(`${TRANS_KEYS.CONTACTS_WORD_FROM}:key`)}
                    disableFuture
                    isDefaultDatePickerStyle={false}
                    className={'w-100'}
                  />
                </Grid>

                <Grid item xs={6}>
                  <DatePickerCommon
                    value={formatDateUtcLocal(element?.end_date, 'MM-DD-YYYY') || null}
                    placeholder='mm/dd/yyyy'
                    format='MM/DD/YYYY'
                    onChange={e => {
                      const selectedDate = e?.$d;
                      // setEducationEndDate(selectedDate);
                      handleChangeEducationData(element?.id, 'end_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null);
                      setDateRangeEducation({ ...dateRangeEducation, end: e });
                    }}
                    label={t(`${TRANS_KEYS.CONTACTS_WORD_TO}:key`)}
                    minDate={dateRangeEducation.start}
                    disabled={element?.is_present}
                    isDefaultDatePickerStyle={false}
                    className={'w-100'}
                  />
                </Grid>
              </Grid>

              {/* </Box> */}

              <Box className='flex flex-row flex-wrap d-flex p-2'>
                <Box className=' row d-flex pl-1 w-100'>
                  <Box className='col-md-6'>
                    <Typography className='text-label fs-16 pl-1 pb-1 header-font' variant='body2'>
                      {t(`${TRANS_KEYS.CONTACTS_TIME_PERIOD}:key`)}
                    </Typography>
                    <Box className='input-field flex align-items-center d-flex'>
                      <Checkbox className='compensation-checkbox' checked={element?.is_present} onChange={e => handleWorkingCheckChangeEducation(element?.id, e.target.checked)}></Checkbox>
                      <Typography className='ml-2 check-box-title'>{t(`${TRANS_KEYS.CONTACTS_CURRENTLY_ENROLLED}:key`)}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          </ExpandableSection>
        );
      })}

      <Box className='d-flex'>
        <Link
          component='button'
          onClick={() => {
            handleAddAnotherEducation();
          }}
        >
          {`+ ${t(`${TRANS_KEYS.CONTACTS_ADD_ANOTHER}:key`)}`}
        </Link>
      </Box>

      <Box className='communication-section flex-row flex-wrap pt-4 w-100'>
        <Box className='head-container w-100'>
          <Typography className='text-label mb-3 mt-3 font-weight-bold pl-3' variant='body1'>
            {t(`${TRANS_KEYS.CONTACT_CERTIFICATION_AND_LICENSE}:key`)}
          </Typography>

          <CustomTable
            headerData={[`${t(`${TRANS_KEYS.CERTIFICATION_TYPE}:key`)}`, `${t(`${TRANS_KEYS.CERTIFICATION_TITLE}:key`)}`, `${t(`${TRANS_KEYS.CERTIFICATION_EXPIRE_ON}:key`)}`]}
            title={t(`${TRANS_KEYS.ADD_CERTIFICATION_AND_LICENSE}:key`)}
            handleAnotherRow={handleAddCertificate}
            rows={data['contact_certificate']}
          >
            {data['contact_certificate']?.map((selectedCertificate, index) => {
              return (
                <TableRow key={index}>
                  <TableCell className=' p-2'>
                    <CertificationTypeSelection
                      label={t(`${TRANS_KEYS.CERTIFICATION_TYPE}:key`)}
                      value={selectedCertificate?.certification_type || ''}
                      onChange={(e, data) => handleChangeCertification(selectedCertificate?.id, 'certification_type', data?.short_desc)}
                      isApplyWidth={true}
                    />
                  </TableCell>
                  <TableCell className=' flex p-2'>
                    <CustomInputField
                      label={t(`${TRANS_KEYS.CERTIFICATION_TITLE}:key`)}
                      value={selectedCertificate?.certifications || ''}
                      onChange={e => handleChangeCertification(selectedCertificate?.id, 'certifications', e.target.value)}
                    />
                  </TableCell>
                  <TableCell className=' p-2'>
                    <DatePickerYear
                      value={selectedCertificate?.expire_on || ''}
                      label={t(`${TRANS_KEYS.CERTIFICATION_EXPIRE_ON}:key`)}
                      className=''
                      onChange={e => handleChangeCertification(selectedCertificate?.id, 'expire_on', e)}
                      showOnlyYear={true}
                      isDefaultDatePickerStyle={false}
                    />
                  </TableCell>
                  {data['contact_certificate'].length > 1 && (
                    <TableCell className='remove-another-button d-flex align-center pt-3'>
                      <CloseIcon onClick={() => handleRemoveCertificate(selectedCertificate?.id)} className='table-close-icon' />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </CustomTable>
        </Box>
      </Box>

      <Box className='communication-section flex-row flex-wrap pt-4'>
        <Box className='head-container w-100'>
          <Typography className='text-label mb-3 mt-3 font-weight-bold pl-3' variant='body1'>
            {t(`${TRANS_KEYS.CONTACTS_LANGUAGES}:key`)}
          </Typography>

          <CustomTable
            headerData={[`${t(`${TRANS_KEYS.CONTACTS_LANGUAGES}:key`)}`, `${t(`${TRANS_KEYS.CONTACTS_LEVEL_OF_EXPERTISE}:key`)}`]}
            title={t(`${TRANS_KEYS.CONTACTS_ADD_LANGUAGES}:key`)}
            handleAnotherRow={handleAddLanguage}
            rows={data['contact_languages']}
            dataList={languageList}
          >
            {data['contact_languages']?.map((selectedLanguages, index) => {
              return (
                <TableRow key={index}>
                  <TableCell className='  p-2'>
                    <LanguagesSelection
                      className={'w-100'}
                      defaultValue={selectedLanguages?.Language || ''}
                      onChange={(e, data) => {
                        handleLanguageChange(selectedLanguages?.id, 'Language', data?.name);
                      }}
                      label={t(`${TRANS_KEYS.CONTACTS_LANGUAGES}:key`)}
                      selectedList={data['contact_languages']}
                      setLanguageList={setLanguageList}
                    />
                  </TableCell>
                  <TableCell className='  p-2'>
                    <LevelOfExpertiseSelection
                      className={'w-100'}
                      value={selectedLanguages?.expertise || ''}
                      onChange={(e, data) => {
                        handleLanguageChange(selectedLanguages?.id, 'expertise', data?.field_value);
                      }}
                      label={t(`${TRANS_KEYS.CONTACTS_LEVEL_OF_EXPERTISE}:key`)}
                    />
                  </TableCell>
                  {data['contact_languages'].length > 1 && (
                    <TableCell className='remove-another-button d-flex align-center pt-3'>
                      <CloseIcon onClick={() => handleRemoveLanguage(selectedLanguages?.id)} className='table-close-icon' />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </CustomTable>
        </Box>
      </Box>
    </Box>
  );
};

EducationAndLanguages.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  data: PropTypes.object,
  setSpecificValue: PropTypes.func,
  deleteSpecificValue: PropTypes.func
};

export default EducationAndLanguages;
