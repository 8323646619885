//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect, useCallback } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { isQueryDataEmpty } from './utils';
import { getUserSelector } from '../../../selectors';
import SaveQueryDialog from './AdvSaveQueryDialog';
import AdvSavedQuerySelection from './AdvSavedQuerySelection';
import { validateMessage } from '../../../services/MessageService';
import { WARNING } from '../../../services/constantService';

export default function AdvancedQuery(props) {
  const { fieldDefinitions = {}, type, pullData, handleQuery } = props;
  const [queryTerms, setQueryTerms] = useState([{}]);
  const { enqueueSnackbar } = useSnackbar();
  const userData = useSelector(getUserSelector);
  const [open, setOpen] = React.useState(false);
  const [queryData, setQueryData] = useState({});
  const [querySelected, setQuerySelected] = useState({});
  const [renamePopup, setRenamePopup] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');
  const [savedValues, setSavedValues] = useState();
  const [idSelected, setIdSelected] = useState();
  const [flag, setFlag] = useState(false);
  const handleClose = () => {
    if (renamePopup) {
      setRenamePopup(false);
    }
    setOpen(false);
    setQueryData({});
  };

  const saveQueryValuesForDefaultValue = data => {
    let array = [];
    data.map(data => array.push(data.name));
    setSavedValues(array);
  };

  const handleSaveQuery = () => {
    if (JSON.stringify(queryTerms) === JSON.stringify(querySelected)) {
      setRenamePopup(true);
    }
    setQueryData(queryTerms);
    setOpen(true);
  };

  const handleRunQuery = useCallback(async () => {
    if (isQueryDataEmpty(queryTerms, fieldDefinitions)) {
      const message = validateMessage('query fields', 'fill all');
      enqueueSnackbar(message, { variant: WARNING });
    } else if (queryTerms && Array.isArray(queryTerms)) {
      let payload = [];
      queryTerms.forEach(item => {
        const filteredIds = [];
        const filterData = item && item.value && Array.isArray(item.value) ? item.value?.filter(data => Object.prototype.hasOwnProperty.call(data, 'id')) : false;
        if (item && item.value && Array.isArray(item.value) && filterData?.length && item.name !== 'DIVERSITY CANDIDATE') {
          item.value.forEach(element => {
            filteredIds.push(element.id);
          });
          payload.push({ key: item.key, name: item.name, value: filteredIds });
        } else if (item && item.value && (item.name === 'DIVERSITY CANDIDATE' || item.name === 'Active Partners')) {
          payload.push({
            key: item.key,
            name: item.name,
            value: item.value.id === 'true' ? true : false
          });
        } else if (item && item.value && item.value.id) {
          payload.push({
            key: item.key,
            name: item.name,
            value: item.value.id
          });
        } else {
          payload.push(item);
        }
        handleQuery({ type: type, fields: payload });
      });
    }
  }, [enqueueSnackbar, fieldDefinitions, queryTerms, type, handleQuery]);

  useEffect(() => {
    if (flag) {
      setFlag(false);
      handleRunQuery();
    }
  }, [flag, handleRunQuery]);

  const handleSaveQueryChange = data => {
    setIdSelected(data.id);
    setDefaultValue(data.name);
    setQuerySelected(data.query);
    setQueryTerms(data.query);
    setFlag(true);
  };

  const addQueryTerm = () => {
    setQueryTerms(prevState => [...prevState, {}]);
  };

  const removeQueryTerm = index => {
    let details = [...queryTerms];
    details.splice(index, 1);
    setQueryTerms(details);
  };

  const classicViewHAndler = () => {
    pullData();
  };

  const handleChange = (field, index, value) => {
    const currentQueryTerms = [...queryTerms];
    currentQueryTerms[index][field] = value;
    setQueryTerms(currentQueryTerms);
  };

  const renderInput = (queryTerm, index) => {
    const Component = queryTerm.name ? fieldDefinitions[queryTerm.name].component : '';
    const componentProps = queryTerm.name ? fieldDefinitions[queryTerm.name].props || {} : {};

    const getValue = e => {
      let value;
      if (e) {
        if (fieldDefinitions[queryTerm.name].type === 'dateRange') {
          value = e.toLocaleDateString('en-US');
        } else {
          value = e && e.target && e.target.value;
        }
      }
      return value;
    };

    if (Component) {
      if (fieldDefinitions[queryTerm.name].type?.includes('Range')) {
        return (
          <div className='date-range d-flex input-form-field input-field-old mw-100 mr-0'>
            <Component
              {...componentProps}
              label=''
              autoFocus={true}
              placeholder='From'
              value={queryTerm.gte || null}
              maxDate={queryTerm.lte}
              className='pr-1'
              onChange={e => {
                handleChange('format', index, 'MM/dd/yyyy||M/d/yyyy||MM/d/yyyy||M/dd/yyyy');
                handleChange('type', index, 'range');
                handleChange('gte', index, getValue(e));
              }}
            />
            <Typography className='m-2'>AND</Typography>
            <Component
              {...componentProps}
              label=''
              placeholder='To'
              value={queryTerm.lte || null}
              minDate={queryTerm.gte}
              onChange={e => {
                handleChange('type', index, 'range');
                handleChange('format', index, 'MM/dd/yyyy||M/d/yyyy||MM/d/yyyy||M/dd/yyyy');
                handleChange('lte', index, getValue(e));
              }}
            />
          </div>
        );
      } else if (fieldDefinitions[queryTerm.name].type === 'checkbox') {
        return (
          <FormControlLabel
            className='input-form-field input-field-old query-checkbox'
            control={<Checkbox size='small' defaultChecked={false} />}
            label={queryTerm.name}
            onChange={(event, checked) => {
              handleChange('value', index, checked);
            }}
          />
        );
      } else if (fieldDefinitions[queryTerm.name].type === 'selection') {
        return (
          <Component
            {...componentProps}
            label=''
            autoFocus={true}
            className={'input-form-field input-field-old'}
            value={queryTerm.value ? (queryTerm.value === 'BD' ? 'Business Development' : queryTerm.value) : componentProps.multiple ? [] : null}
            onChange={(e, data) => {
              let value = '';
              if (Array.isArray(data)) {
                if (data?.length > 0) {
                  value = data.map(singleValue => {
                    return singleValue[fieldDefinitions[queryTerm.name].dataField || 'id'] || singleValue;
                  });
                }
              } else {
                value = fieldDefinitions[queryTerm.name].dataField === 'name' ? (data?.field_value ? data?.field_value : data && data[fieldDefinitions[queryTerm.name].dataField]) : data;
              }
              handleChange('value', index, value);
            }}
          />
        );
      } else if (fieldDefinitions[queryTerm.name].type === 'search') {
        return (
          <Component
            {...componentProps}
            label=''
            autoFocus={true}
            className={'input-form-field input-field-old'}
            value={queryTerm.value || (componentProps.multiple ? [] : '')}
            onChange={(e, data) => {
              let value = '';
              if (Array.isArray(data)) {
                value = data.map(singleValue => {
                  return singleValue;
                });
              }
              handleChange('value', index, value);
            }}
          />
        );
      } else {
        return (
          <Component
            {...componentProps}
            label=''
            autoFocus={true}
            className={'input-form-field input-field-old'}
            value={queryTerm.value || (componentProps.multiple ? [] : '')}
            onChange={(e, data) => {
              const value = data || (e && e.target && e.target.value) || (e && !e.nativeEvent && e);
              handleChange('value', index, value);
            }}
          />
        );
      }
    }
    return (
      <>
        {/* <Button
          className="mr-3"
          variant="outlined"
          color="primary"
          onClick={classicViewHAndler}
        >
          Switch to Classic View
        </Button> */}
        <TextField className='input-form-field input-field-old' disabled={true} placeholder='Select/Search' />
      </>
    );
  };
  return (
    <div className='query w-100'>
      <SaveQueryDialog
        defaultValue={defaultValue}
        savedValues={savedValues}
        renamePopup={renamePopup}
        open={open}
        page={type}
        queryData={queryData}
        userId={userData?.id}
        handleClose={handleClose}
        idSelected={idSelected}
      />
      {queryTerms.map((queryTem, index) => {
        return (
          <div key={queryTem.name ? queryTem.name + index : index} className='d-flex w-75 pb-3'>
            <Autocomplete
              className='input-form-field input-field-old'
              value={queryTem.name || ''}
              getOptionSelected={(option, value) => option !== value}
              renderInput={params => <TextField {...params} label='' placeholder='Select a field to query' />}
              options={Object.keys(fieldDefinitions)}
              onChange={(e, data) => {
                handleChange('name', index, data);
                handleChange('key', index, data ? fieldDefinitions[data].field : '');
                handleChange('value', index, '');
                handleChange('gte', index, '');
                handleChange('lte', index, '');
              }}
            />
            {renderInput(queryTem, index)}
            {queryTerms?.length > 1 && queryTerms?.length - 1 !== index ? (
              <Autocomplete
                value={queryTem.operator || 'AND'}
                className='input-form-field input-field-old'
                renderInput={params => <TextField {...params} label='' placeholder='AND/OR' />}
                onChange={(e, data) => {
                  handleChange('operator', index, data);
                }}
                options={['AND', 'OR']}
              />
            ) : (
              <Autocomplete
                disabled
                value={queryTem.operator || 'AND'}
                className='input-form-field input-field-old'
                renderInput={params => <TextField {...params} label='' placeholder='AND/OR' />}
                onChange={(e, data) => {
                  handleChange('operator', index, data);
                }}
                options={['AND', 'OR']}
              />
            )}
            {queryTerms?.length > 1 ? (
              <CloseIcon
                cursor='pointer'
                onClick={() => {
                  removeQueryTerm(index);
                }}
                fontSize='small'
              />
            ) : (
              ''
            )}
          </div>
        );
      })}
      <div>
        <Button variant={'text'} startIcon={<AddCircleIcon />} onClick={addQueryTerm} color='primary'>
          Add Query Term
        </Button>
      </div>

      <div className='d-flex align-items-left justify-content pb-3'>
        <Button className='mr-3' variant='outlined' style={{ marginLeft: 'auto' }} color='primary' onClick={handleSaveQuery}>
          Save Query
        </Button>
        <Button variant='contained' color='primary' onClick={handleRunQuery}>
          Run Query
        </Button>
      </div>
      {
        <div className='d-flex align-items-center justify-content-between mt-4'>
          <Button className='mr-3 mt-1' variant='outlined' color='primary' onClick={classicViewHAndler}>
            Switch to Classic View
          </Button>
          {/* <div
            style={{
              display: "inline-grid",
              gridTemplateColumns: "auto",
              width: "100%",
              justifySelf: "end",
            }}
          > */}
          <AdvSavedQuerySelection
            saveQueryValuesForDefaultValue={saveQueryValuesForDefaultValue}
            onChange={handleSaveQueryChange}
            page={type}
            refreshQueries={open}
            InputLabelProps={{ focused: true }}
            className='saved-queries'
            userId={userData?.id}
            disableClearable
            style={{ marginLeft: 'auto', justifySelf: 'end' }}
          />
          {/* </div> */}
        </div>
      }
    </div>
  );
}

AdvancedQuery.propTypes = {
  fieldDefinitions: PropTypes.object,
  pullData: PropTypes.func,
  isRowSelected: PropTypes.bool,
  handleQuery: PropTypes.func,
  type: PropTypes.string,
  queryData: PropTypes.object
};
