//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { Typography, Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { DateRenderer, DateAndTimeRenderer, getDateFilterOptions, getFilterQuery, getMatchFilterOptions } from '../../Commons/Utils';
import CandidateStageSelection from '../../../components/CandidateStageSelection';
import CompanySelection from '../../../components/CompanySelection';
import DiversityCandidateSelection from '../../../components/DiversityCandidateSelection';
import GdprReleaseSelection from '../../../components/GdprReleaseSelection';
import ExcludeFromClientSuite from '../../../components/ExcludeFromClientSuite';
import CountrySelection from '../../../components/CountrySelection';
import ColorSelection from '../../../components/ColorSelection';
import ReasonSelection from '../../../components/ReasonSelection';
import { updateCandidate, updateContact } from '../../Commons/Utils';
import CandidatePrioritySelection from '../../../components/CandidatePrioritySelection';
import IndustrySelection from '../../../components/IndustrySelection';
import UserSelection from '../../../components/UserSelection';
import ArenaStageSelection from '../../../components/ActivityLog/Components/ArenaStageSelection';
import { EMAIL_REGEX, ERROR, GET, WARNING } from '../../../services/constantService';
import * as AttachmentsService from '../../../../src/services/AttachmentsService';
import { acquireLockApi, contactDataApi, releaseLockApi } from '../../../services/ApiService';
import { notFoundMessage, requireMessage, unableMessage, validateMessage } from '../../../services/MessageService';
import { RELOAD_ALL_CANDIDATE_GRID } from '../../../types';
import { checkContactFetchingStatus } from '../../Contacts/utils';
import { tooltipValueGrid } from '../../../utils/common';
import StateSelection from '../../../components/StateSelection';
import GenderSelection from '../../../components/GenderSelection';
//import HireLogicSelection from "../../../components/HireLogicSelection";
import FeetypeSelection from '../../../components/FeetypeSelection';
import YesNoSelection from '../../../components/YesNoSelection';

export const defaultColumns = ['first_name', 'freeze_dropdown'];
const getName = params => params.data && params.data.contact && params.data.contact.first_name + ' ' + params.data.contact.last_name;
const getCompany = params => params.data && params.data.contact && params.data.contact.company && params.data.contact.company.name;
const getExEmployee = params => (params.data && params.data.ex_employee === true ? 'Yes' : '');
const getGender = params => params.data && params.data.contact && params.data.contact.gender;
const getTitle = params => params.data && params.data.contact && params.data.contact.current_job_title;
//const getHireLogic = (params => params.data && params.data.interview[0] && params.data.interview[0].interviewStatus)
const getLinkedIn = params => params.data && params.data.contact && params.data.contact.linkedin_url;
const getMatrixNotes = params => params.data && params.data.matrix_notes;
const getLocation = params => params.data && params.data.contact && params.data.contact.country;
const getState = params => params.data && params.data.contact && params.data.contact.state;
const getCity = params => params.data && params.data.contact && params.data.contact.city;
const getStateCity = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  const contact = params?.data?.contact;
  if (!contact?.state && !contact?.city) return '--';
  return (contact?.state || '--') + '/' + (contact?.city || '--');
};
const getActualBonusValue = params => params.data && params.data.contact && params.data.contact.actual_bonus_value;
const getEquity = params => params.data && params.data.contact && params.data.contact.equity;
const getCreatedUser = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  return params.value && params.value.name;
};
const stateSelection = params => {
  const country = params.data.contact.country ? params.data.contact.country : null;
  return StateSelection({ ...params, country: country });
};
const getEditable = params => {
  return params?.data?.contact?.country ? true : false;
};
const getLastActivity = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  return params.data?.contact?.contact_activities[0]?.activity;
};
const getLastActivityType = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  return params.data?.contact?.contact_activities[0]?.type;
};

const getLastActivityDate = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  return params.data?.contact?.contact_activities[0]?.updated_at;
};

const getLastActivityUser = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  return params.data?.contact?.contact_activities[0]?.created_user.name;
};

const getDQReason = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  return params?.data?.dq_reason.join(', ') || '';
};
const getIndustries = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  if (params.data && params.data.contact && Array.isArray(params.data.contact.industries)) {
    return params.data.contact.industries.join(', ');
  }
  return '';
};
const getContactField = (data, field) => {
  return (data && data.contact && data.contact[field]) || '';
};

const getPersonalEmail = params => {
  return getContactField(params.data, 'private_email');
};

const getWorkEmail = params => {
  return getContactField(params.data, 'work_email');
};

const renderInternationalExperience = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  const experience = getContactField(params.data, 'international_experience');
  return Array.isArray(experience) ? experience.join(', ') : null;
};
export const syncCandidatesToHlColumndefs = showReason => [
  {
    field: 'name',
    colId: 'name',
    headerName: 'Name',
    pinned: 'left',
    minWidth: 50,
    maxWidth: 200,
    valueGetter: getName,
    tooltipValueGetter: getName,
    cellRenderer: 'NameRenderer'
  },
  {
    field: 'company',
    colId: 'company',
    minWidth: 50,
    maxWidth: 200,
    tooltipValueGetter: getCompany,
    valueGetter: getCompany,
    headerName: 'Company',
    cellRenderer: 'CompanyNameRenderer'
  },
  {
    field: 'title',
    colId: 'title',
    tooltipValueGetter: getTitle,
    valueGetter: getTitle,
    headerName: 'Title',
    minWidth: 50,
    maxWidth: 200
  },
  {
    field: 'stage',
    colId: 'stage',
    headerName: 'Stage',
    tooltipField: 'stage',
    minWidth: 50,
    maxWidth: 200
  },
  {
    field: 'work_email',
    colId: 'work_email',
    headerName: 'Work Email',
    tooltipField: 'work_email',
    cellRenderer: 'EmailRenderer',
    valueGetter: getWorkEmail,
    minWidth: 50,
    maxWidth: 200
  },
  {
    field: 'private_email',
    colId: 'private_email',
    headerName: 'Personal Email',
    tooltipField: 'private_email',
    cellRenderer: 'EmailRenderer',
    editable: true,
    valueGetter: getPersonalEmail,
    minWidth: 50,
    maxWidth: 200
  },
  ...(showReason
    ? [
        {
          field: 'hl_reason',
          colId: 'hl_reason',
          headerName: 'Reason',
          tooltipField: 'hl_reason',
          minWidth: 50,
          maxWidth: 200,
          cellRenderer: 'ReasonRenderer'
        }
      ]
    : [])
];

export const columnDefs = (showActivityHistory, enqueueSnackbar, closeSnackbar, includeHideInClientSuite = false, SetLoader, option, searchStage, dispatch = () => {}, setJobHistoryPopupStates) => {
  let columns = [
    {
      headerName: '',
      field: 'check_box',
      colId: 'check_box',
      maxWidth: 50,
      minWidth: 50,
      headerComponent: 'HeaderCheckbox',
      headerCheckboxSelectionFilteredOnly: false,
      checkboxSelection: true,
      sortable: false,
      pinned: 'left'
    },
    {
      field: 'color',
      colId: 'color',
      headerName: 'Color',
      cellRenderer: 'ColorRenderer',
      maxWidth: 100,
      minWidth: 50,
      pinned: 'left',
      filter: 'CustomFilter',
      filterParams: {
        Component: ColorSelection,
        field: 'value'
      }
    },
    {
      field: 'name',
      colId: 'name',
      headerName: 'Name',
      cellClass: 'text-capitalize',
      pinned: 'left',
      minWidth: 50,
      maxWidth: 200,
      valueGetter: getName,
      cellRenderer: 'NameRenderer',
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'company',
      colId: 'company',
      minWidth: 50,
      cellClass: 'text-capitalize',
      maxWidth: 200,
      valueGetter: getCompany,
      headerName: 'Company',
      cellRenderer: 'CompanyNameRenderer',
      filter: 'CustomFilter',
      filterParams: {
        Component: CompanySelection,
        multiple: true
      }
    },
    {
      field: 'title',
      tooltipValueGetter: getTitle,
      colId: 'title',
      valueGetter: getTitle,
      headerName: 'Title',
      cellClass: 'text-capitalize',
      minWidth: 50,
      maxWidth: 200,
      /* if enable to editable it must show an employment history confirmation popup */
      editable: true,
      valueSetter: async params => {
        if (!params.newValue) {
          const message = requireMessage('Title', 'is');
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
        const newValue = { ...params };
        setJobHistoryPopupStates({ newContactData: newValue });
        SetLoader(true);
        let returnResult = true;
        const { status, data } = await contactDataApi(GET, params.data.contact.id);
        if (status === 200) {
          setJobHistoryPopupStates({ currentContact: data, showJobTitleChangedPopup: true });
        } else {
          const message = notFoundMessage('Contact');
          enqueueSnackbar(message, { variant: ERROR });
          returnResult = false;
        }
        SetLoader(false);
        return returnResult;
      },
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    } /* ,
  {
    field: 'HireLogic',
    tooltipValueGetter: getHireLogic,
    colId: 'HireLogic',
    valueGetter: getHireLogic,
    cellRenderer: 'HireLogicLinkRenderer',
    headerName: 'HireLogic',
    filter: 'CustomFilter',
    filterParams: {
      Component: HireLogicSelection,
      multiple: true
    }
  } */,
    {
      field: 'work_email',
      colId: 'work_email',
      headerName: 'Work Email',
      tooltipValueGetter: getWorkEmail,
      cellRenderer: 'EmailRenderer',
      editable: true,
      valueGetter: getWorkEmail,
      minWidth: 50,
      maxWidth: 200,
      valueSetter: params => {
        if (params.oldValue === params.newValue || params.newValue === undefined) {
          return false;
        }
        const email = EMAIL_REGEX.test(params.newValue) ? params.newValue : null;
        if (params.newValue && !email) {
          const message = validateMessage('valid work email', 'enter');
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        } else {
          const label = 'work_email';
          return acquire(params, label, enqueueSnackbar, closeSnackbar, false, '', SetLoader, dispatch);
        }
      },
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'private_email',
      colId: 'private_email',
      headerName: 'Personal Email',
      tooltipValueGetter: getPersonalEmail,
      cellRenderer: 'EmailRenderer',
      editable: true,
      valueGetter: getPersonalEmail,
      minWidth: 50,
      maxWidth: 200,
      valueSetter: params => {
        if (params.oldValue === params.newValue || params.newValue === undefined) {
          return false;
        }
        const email = EMAIL_REGEX.test(params.newValue) ? params.newValue : null;
        if (params.newValue && !email) {
          const message = validateMessage('valid personal email', 'enter');
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        } else {
          const label = 'private_email';
          return acquire(params, label, enqueueSnackbar, closeSnackbar, false, '', SetLoader, dispatch);
        }
      },
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'gender',
      colId: 'gender',
      headerName: 'Gender',
      tooltipField: 'gender',
      minWidth: 50,
      maxWidth: 150,
      sortable: true,
      editable: false,
      valueGetter: getGender,
      //cellEditor: "GenericCellEditor",
      filter: 'CustomFilter',
      filterParams: {
        Component: GenderSelection,
        multiple: false
      }
    },
    {
      field: 'fq',
      colId: 'fq',
      headerName: 'FQ',
      tooltipField: 'fq',
      minWidth: 50,
      maxWidth: 100,
      cellRenderer: 'FQRenderer',
      hide: true,
      sortable: false
    },
    {
      field: 'stage',
      colId: 'stage',
      headerName: 'Stage',
      tooltipField: 'stage',
      cellRenderer: 'StageRenderer',
      editable: true,
      minWidth: 50,
      maxWidth: 200,
      cellEditor: 'StageEditor',
      cellEditorParams: {
        SetLoader
      },
      filter: 'CustomFilter',
      filterParams: {
        Component: CandidateStageSelection,
        field: 'name'
      }
    },
    {
      colId: 'linkedin',
      headerName: 'LinkedIn',
      // tooltipField: "linkedin_url",
      tooltipValueGetter: params => {
        return tooltipValueGrid(params);
      },
      valueGetter: getLinkedIn,
      minWidth: 50,
      maxWidth: 100,
      cellRenderer: 'LinkedInRenderer',
      editable: true,
      sortable: false,
      valueSetter: params => {
        const label = 'linkedin_url';
        return acquire(params, label, enqueueSnackbar, closeSnackbar, false, '', SetLoader, dispatch);
      }
    },
    {
      colId: 'matrix_notes',
      headerName: 'Matrix Notes',
      valueGetter: getMatrixNotes,
      minWidth: 50,
      maxWidth: 200,
      editable: true,
      sortable: true,
      cellEditor: 'RichTextBoxEditor',
      cellRenderer: 'RichTextPopupRendered',
      cellEditorParams: {
        title: 'Matrix Notes',
        type: 'candidate'
      },
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      valueSetter: params => {
        const label = 'matrix_notes';
        const candidate = 'candidate';
        return acquire(params, label, enqueueSnackbar, closeSnackbar, false, candidate, SetLoader, dispatch);
      }
    },
    {
      field: 'city',
      colId: 'city',
      tooltipValueGetter: getCity,
      valueGetter: getCity,
      headerName: 'City',
      editable: true,
      sortable: true,
      minWidth: 50,
      maxWidth: 200,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      valueSetter: params => {
        const label = 'city';
        return acquire(params, label, enqueueSnackbar, closeSnackbar, false, '', SetLoader, dispatch);
      }
    },
    {
      field: 'location',
      colId: 'location',
      tooltipValueGetter: getLocation,
      valueGetter: getLocation,
      minWidth: 50,
      maxWidth: 200,
      headerName: 'Location',
      editable: true,
      sortable: true,
      cellEditor: 'GenericCellEditor',
      cellEditorParams: {
        InputComponent: CountrySelection,
        label: '',
        placeholder: '',
        classes: { option: 'ag-custom-component-popup' }
      },
      valueSetter: params => {
        params.newValue = params.newValue.name;
        const label = 'country';
        return acquire(params, label, enqueueSnackbar, closeSnackbar, false, '', SetLoader, dispatch);
      },
      filter: 'CustomFilter',
      filterParams: {
        Component: CountrySelection,
        field: 'name'
      }
    },
    {
      field: 'state',
      colId: 'state',
      tooltipValueGetter: getState,
      valueGetter: getState,
      headerName: 'State',
      minWidth: 50,
      maxWidth: 200,
      editable: getEditable,
      sortable: true,
      cellEditor: 'GenericCellEditor',
      cellEditorParams: {
        InputComponent: stateSelection,
        label: '',
        placeholder: '',
        classes: { option: 'ag-custom-component-popup' }
      },
      valueSetter: params => {
        params.newValue = params.newValue.inputValue ? params.newValue.inputValue : params.newValue.title ? params.newValue.title : params.newValue;
        if (params.oldValue === params.newValue || params.newValue === undefined) {
          return false;
        }
        params.country = params.data.contact.country;
        const label = 'state';
        return acquire(params, label, enqueueSnackbar, closeSnackbar, false, '', SetLoader, dispatch);
      },
      filter: false, //"CustomFilter",
      filterParams: {
        Component: StateSelection,
        field: 'name'
        // filterOptions: ["contains"],
        // buttons: ["reset", "apply"],
        // suppressAndOrCondition: true,
        // closeOnApply: true,
      }
    },
    {
      field: 'reason',
      colId: 'reason',
      headerName: 'Reason',
      tooltipField: 'reason',
      filter: 'CustomFilter',
      minWidth: 50,
      maxWidth: 200,
      filterParams: {
        Component: ReasonSelection
      },
      hide: true,
      editable: true,
      cellEditor: 'ReasonEditor',
      valueSetter: params => {
        const lable = 'reason';
        const candidate = 'candidate';
        return acquire(params, lable, enqueueSnackbar, closeSnackbar, false, candidate, SetLoader, dispatch);
      }
    },
    {
      colId: 'actions',
      headerName: 'Actions',
      maxWidth: 134,
      minWidth: 134,
      cellRenderer: 'ActionsRenderer',
      sortable: false,
      cellRendererParams: {
        showActivityHistory
      }
    },
    // {
    //   colId: "HireLogic",
    //   headerName: "HireLogic",
    //   maxWidth: 200,
    //   minWidth: 50,
    //   width: 60,
    //   cellRenderer: "HireLogicRenderer",
    //   sortable: false
    // },
    {
      colId: 'docs',
      headerName: 'Docs',
      maxWidth: 150,
      minWidth: 50,
      cellRenderer: 'DocsRenderer',
      sortable: false
    },
    {
      colId: 'athena',
      headerName: 'Athena',
      cellRenderer: 'AthenaRenderer',
      sortable: false,
      minWidth: 50,
      maxWidth: 200
    },
    {
      colId: 'candidatesuite',
      headerName: 'CandidateSuite',
      cellRenderer: 'CandidateSuiteRenderer',
      sortable: false,
      minWidth: 50,
      maxWidth: 200
    },
    {
      field: 'priority',
      colId: 'priority',
      headerName: 'Priority',
      tooltipField: 'priority',
      minWidth: 50,
      maxWidth: 150,
      sortable: true,
      editable: true,
      cellEditor: 'GenericCellEditor',
      cellEditorParams: {
        InputComponent: CandidatePrioritySelection,
        label: '',
        placeholder: '',
        classes: { option: 'ag-custom-component-popup' }
      },
      valueSetter: params => {
        params.newValue = params.newValue.value;
        const lable = 'priority';
        const candidate = 'candidate';
        return acquire(params, lable, enqueueSnackbar, closeSnackbar, false, candidate, SetLoader, dispatch);
      },
      filter: 'CustomFilter',
      filterParams: {
        Component: CandidatePrioritySelection,
        multiple: true
      }
    },
    {
      field: 'rationale',
      colId: 'rationale',
      headerName: 'Rationale',
      cellEditor: 'RichTextBoxEditor',
      cellRenderer: 'RichTextPopupRendered',
      cellEditorParams: {
        title: 'Rationale',
        type: 'candidate'
      },
      editable: true,
      valueSetter: params => {
        const lable = 'rationale';
        const candidate = 'candidate';
        return acquire(params, lable, enqueueSnackbar, closeSnackbar, false, candidate, SetLoader, dispatch);
      },
      maxWidth: 500,
      minWidth: 150,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'next_steps',
      colId: 'next_steps',
      headerName: 'Next Steps',
      hide: true,
      maxWidth: 500,
      minWidth: 150,
      editable: true,
      cellEditor: 'RichTextBoxEditor',
      cellRenderer: 'RichTextPopupRendered',
      cellEditorParams: {
        title: 'Next Steps',
        type: 'candidate'
      },
      valueSetter: params => {
        const lable = 'next_steps';
        const candidate = 'candidate';
        return acquire(params, lable, enqueueSnackbar, closeSnackbar, false, candidate, SetLoader, dispatch);
      },
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'industries',
      colId: 'industries',
      headerName: 'Industries',
      tooltipValueGetter: getIndustries,
      cellRenderer: getIndustries,
      minWidth: 50,
      maxWidth: 200,
      filter: 'CustomFilter',
      filterParams: {
        Component: IndustrySelection,
        multiple: true
      }
    },
    {
      field: 'base_salary',
      colId: 'base_salary',
      headerName: 'Base',
      cellRenderer: 'CompensationRenderer',
      maxWidth: 200,
      minWidth: 150,
      sortable: false
      // filter: 'agNumberColumnFilter',
      // filterParams: {
      //   filterOptions: ['lessThan', 'greaterThan'],
      //   buttons: ['reset', 'apply'],
      //   suppressAndOrCondition: true,
      //   closeOnApply: true
      // },
    },
    {
      field: 'bonus',
      colId: 'bonus',
      tooltipValueGetter: getActualBonusValue,
      valueGetter: getActualBonusValue,
      maxWidth: 200,
      minWidth: 50,
      headerName: 'Bonus',
      hide: true,
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: getMatchFilterOptions(),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'equity',
      colId: 'equity',
      tooltipValueGetter: getEquity,
      valueGetter: getEquity,
      maxWidth: 200,
      minWidth: 50,
      headerName: 'Equity',
      tooltipField: 'equity',
      hide: true,
      sortable: false
      // filter: 'agNumberColumnFilter',
      // filterParams: {
      //   filterOptions: ['contains'],
      //   buttons: ['reset', 'apply'],
      //   suppressAndOrCondition: true,
      //   closeOnApply: true
      // },
    },
    {
      field: 'resume',
      colId: 'resume',
      headerName: 'Resume',
      cellRenderer: 'ResumeRenderer',
      tooltipValueGetter: ResumeRenderer,
      cellRendererParams: { enqueueSnackbar },
      hide: true,
      maxWidth: 150,
      minWidth: 50
    },
    {
      field: 'date_added_to_search',
      colId: 'date_added_to_search',
      headerName: 'Date Added',
      tooltipValueGetter: DateRenderer,
      maxWidth: 150,
      minWidth: 50,
      cellRenderer: 'DateRenderer',
      hide: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'created_at',
      colId: 'created_at',
      headerName: 'Created On',
      tooltipValueGetter: DateAndTimeRenderer,
      cellRenderer: 'DateTimeRenderer',
      hide: true,
      maxWidth: 150,
      minWidth: 50,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'updated_at',
      colId: 'updated_at',
      headerName: 'Updated On',
      tooltipValueGetter: DateRenderer,
      cellRenderer: 'DateRenderer',
      hide: true,
      maxWidth: 150,
      minWidth: 50,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'created_user',
      colId: 'created_user',
      headerName: 'Created User',
      tooltipValueGetter: getCreatedUser,
      cellRenderer: getCreatedUser,
      hide: true,
      maxWidth: 200,
      minWidth: 50,
      filter: 'CustomFilter',
      filterParams: {
        Component: UserSelection,
        multiple: true,
        option: option ? option : []
      }
    },
    {
      field: 'activity',
      colId: 'activity',
      tooltipValueGetter: getLastActivity,
      cellRenderer: getLastActivity,
      maxWidth: 200,
      minWidth: 50,
      headerName: 'Last Activity',
      hide: true,
      sortable: false
    },
    {
      field: 'type',
      colId: 'type',
      tooltipValueGetter: getLastActivityType,
      cellRenderer: getLastActivityType,
      maxWidth: 200,
      minWidth: 50,
      headerName: 'Last Activity Type',
      hide: true,
      sortable: false
    },
    {
      field: 'activity_updated_at',
      colId: 'activity_updated_at',
      tooltipValueGetter: DateAndTimeRenderer,
      valueGetter: getLastActivityDate,
      cellRenderer: 'DateTimeRenderer',
      maxWidth: 200,
      minWidth: 50,
      headerName: 'Last Activity Date',
      hide: true,
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'name',
      colId: 'last_activity_user',
      tooltipValueGetter: getLastActivityUser,
      cellRenderer: getLastActivityUser,
      maxWidth: 200,
      minWidth: 50,
      headerName: 'Last Activity User',
      hide: true,
      sortable: false
    },
    {
      field: 'arena_stage',
      colId: 'arena_stage',
      headerName: 'Arena Stage',
      maxWidth: 200,
      minWidth: 50,
      filter: 'CustomFilter',
      filterParams: {
        Component: ArenaStageSelection,
        placeholder: 'Arena Stage'
      },
      editable: true,
      cellEditor: 'GenericCellEditor',
      cellEditorParams: {
        InputComponent: ArenaStageSelection,
        label: '',
        placeholder: '',
        classes: { option: 'ag-custom-component-popup' }
      },
      valueSetter: params => {
        if (params.newValue === false) {
          params.newValue = '';
        }
        const lable = 'arena_stage';
        const candidate = 'candidate';
        return acquire(params, lable, enqueueSnackbar, closeSnackbar, false, candidate, SetLoader, dispatch);
      },
      hide: true
    },
    {
      field: 'track_comments',
      colId: 'track_comments',
      maxWidth: 200,
      minWidth: 50,
      headerName: 'Track Comments',
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      },
      editable: searchStage === 'Inside Track' ? true : false,
      valueSetter: params => {
        const lable = 'track_comments';
        const candidate = 'candidate';
        return acquire(params, lable, enqueueSnackbar, closeSnackbar, false, candidate, SetLoader, dispatch);
      },
      hide: true
    },
    {
      field: 'gdpr_release',
      colId: 'gdpr_release',
      headerName: 'GDPR Release',
      maxWidth: 200,
      minWidth: 50,
      cellRenderer: params => {
        const status = checkContactFetchingStatus(params);
        if (status) return status;
        return getContactField(params.data, 'gdpr_release') ? 'Yes' : 'No';
      },
      filter: 'CustomFilter',
      filterParams: {
        Component: GdprReleaseSelection,
        field: 'id'
      }
    },
    {
      field: 'diversity_candidate',
      colId: 'diversity_candidate',
      headerName: 'Diversity Candidate',
      maxWidth: 200,
      minWidth: 50,
      cellRenderer: params => {
        const status = checkContactFetchingStatus(params);
        if (status) return status;
        return getContactField(params.data, 'diversity_candidate') ? 'Yes' : 'No';
      },
      filter: 'CustomFilter',
      filterParams: {
        Component: DiversityCandidateSelection,
        field: 'id'
      }
    },
    {
      field: 'international_experience',
      colId: 'international_experience',
      headerName: 'International Experience',
      cellRenderer: renderInternationalExperience,
      sortable: true,
      maxWidth: 200,
      minWidth: 50,
      filter: 'CustomFilter',
      filterParams: {
        Component: CountrySelection,
        multiple: true
      }
    },
    {
      field: 'match',
      tooltipField: 'match',
      colId: 'match',
      headerName: 'Match Score',
      minWidth: 50,
      maxWidth: 200,
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: getMatchFilterOptions(),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'source',
      colId: 'source',
      headerName: 'Source',
      cellClass: 'text-capitalize',
      minWidth: 50,
      maxWidth: 200,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'dq_reason',
      // tooltipField: "dq_reason",
      colId: 'dq_reason',
      headerName: 'DQ Reason',
      cellStyle: { overflowY: 'auto', lineHeight: '20px' },
      tooltipValueGetter: getDQReason,
      cellRenderer: getDQReason,
      minWidth: 50,
      maxWidth: 200,
      filter: 'agTextColumnFilter',
      sortable: true,
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    ...(includeHideInClientSuite
      ? [
          {
            field: 'hide_in_client_suite',
            colId: 'hide_in_client_suite',
            headerName: 'Exclude from ClientSuite',
            maxWidth: 200,
            minWidth: 50,
            cellRenderer: params => {
              const status = checkContactFetchingStatus(params);
              if (status) return status;
              return getContactField(params.data, 'hide_in_client_suite') ? 'Yes' : 'No';
            },
            filter: 'CustomFilter',
            filterParams: {
              Component: ExcludeFromClientSuite,
              field: 'id'
            }
          }
        ]
      : []),
    {
      field: 'freeze_dropdown',
      colId: 'freeze_dropdown',
      headerName: '',
      sortable: false,
      filter: false,
      minWidth: 44,
      maxWidth: 44,
      width: 44,
      pinned: 'right',
      lockPinned: true
    }
  ];
  columns.forEach(ele => {
    if (
      ele.field !== 'name' &&
      ele.field !== 'linkedin_url' &&
      ele.field !== 'freeze_dropdown' &&
      ele.field !== 'check_box' &&
      ele.field !== 'international_experience' &&
      ele.field !== 'stage' &&
      ele.field !== 'source' &&
      ele.field !== 'color' &&
      ele.field !== 'state'
    ) {
      ele.edit = ele.editable;
      ele.editable = params => {
        return checkContactFetchingStatus(params) ? false : ele.edit;
      };
      if (!ele.cellRenderer) {
        ele.cellRenderer = params => {
          const status = checkContactFetchingStatus(params);
          return status ? status : params?.value;
        };
      }
    }
  });
  return columns;
};

export const productOneCandidateColumnDef = (func, enqueueSnackbar) => {
  let columns = [
    {
      headerName: '',
      field: 'check_box',
      colId: 'check_box',
      maxWidth: 50,
      minWidth: 50,
      headerComponent: 'HeaderCheckbox',
      headerCheckboxSelectionFilteredOnly: false,
      checkboxSelection: true,
      sortable: false,
      pinned: 'left'
    },
    // {
    //   field: "contact_id",
    //   colId: "contact_id",
    //   headerName: "ContactId",
    //   pinned: "left",
    //   minWidth: 50,
    //   maxWidth: 300,
    // },
    {
      field: 'name',
      colId: 'name',
      headerName: 'Name',
      pinned: 'left',
      minWidth: 80,
      maxWidth: 200,
      cellClass: 'text-capitalize',
      tooltipValueGetter: params => {
        return tooltipValueGrid(params);
      },
      valueGetter: getName,
      cellRenderer: 'NameRenderer',
      filter: 'agTextColumnFilter',
      sortable: true,
      // comparator: function (valueA, valueB) {
      //   valueA = valueA.toLowerCase()
      //   valueB = valueB.toLowerCase()
      //   return (valueA == valueB) ? 0 : (valueA > valueB) ? 1 : -1;
      // },
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'company',
      colId: 'company',
      minWidth: 50,
      maxWidth: 200,
      tooltipValueGetter: getCompany,
      cellClass: 'text-capitalize',
      valueGetter: getCompany,
      headerName: 'Company',
      cellRenderer: 'CompanyNameRenderer',
      // filter: "agTextColumnFilter",
      sortable: true,
      // comparator: function (valueA, valueB) {
      //   valueA = valueA?.toLowerCase()
      //   valueB = valueB?.toLowerCase()
      //   return (valueA == valueB) ? 0 : (valueA > valueB) ? 1 : -1;
      // },
      filter: 'CustomFilter',
      filterParams: {
        Component: CompanySelection,
        multiple: true
      }
    },
    {
      field: 'title',
      tooltipValueGetter: getTitle,
      colId: 'title',
      valueGetter: getTitle,
      headerName: 'Job Title',
      minWidth: 50,
      maxWidth: 200,
      sortable: true,
      cellClass: 'text-capitalize',
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'title_score',
      tooltipField: 'title_score',
      colId: 'title_score',
      cellRenderer: 'ColorDotRenderer',
      headerName: 'Title',
      minWidth: 50,
      maxWidth: 200,
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: getMatchFilterOptions(true),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'job_function_score',
      tooltipField: 'job_function_score',
      colId: 'job_function_score',
      cellRenderer: 'ColorDotRenderer',
      headerName: 'Function',
      minWidth: 50,
      maxWidth: 200,
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: getMatchFilterOptions(true),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'industry_score',
      tooltipField: 'industry_score',
      colId: 'industry_score',
      cellRenderer: 'ColorDotRenderer',
      headerName: 'Industry',
      minWidth: 50,
      maxWidth: 200,
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: getMatchFilterOptions(true),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'company_score',
      tooltipField: 'company_score',
      colId: 'company_score',
      cellRenderer: 'ColorDotRenderer',
      headerName: 'Company',
      minWidth: 50,
      maxWidth: 200,
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: getMatchFilterOptions(true),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'tenure_weight',
      tooltipField: 'tenure_weight',
      colId: 'tenure_weight',
      cellRenderer: 'ColorDotRenderer',
      headerName: 'Career Experience',
      minWidth: 50,
      maxWidth: 200,
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: getMatchFilterOptions(true),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'stint_weight',
      tooltipField: 'stint_weight',
      colId: 'stint_weight',
      cellRenderer: 'ColorDotRenderer',
      headerName: 'Job Movement',
      minWidth: 50,
      maxWidth: 200,
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: getMatchFilterOptions(true),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'recency_weight',
      tooltipField: 'recency_weight',
      colId: 'recency_weight',
      cellRenderer: 'ColorDotRenderer',
      headerName: 'Current Title Tenure',
      minWidth: 50,
      maxWidth: 200,
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: getMatchFilterOptions(true),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'relevancy_weight',
      tooltipField: 'relevancy_weight',
      colId: 'relevancy_weight',
      cellRenderer: 'ColorDotRenderer',
      headerName: 'Relevancy',
      minWidth: 50,
      maxWidth: 200,
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: getMatchFilterOptions(true),
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    {
      field: 'lifer',
      tooltipField: 'lifer',
      colId: 'lifer',
      cellRenderer: 'LiferRenderer',
      headerName: 'Lifer',
      minWidth: 50,
      maxWidth: 200,
      filter: 'CustomFilter',
      filterParams: {
        Component: YesNoSelection,
        field: 'id'
      }
    },
    {
      field: 'ex_employee',
      tooltipField: 'ex_employee',
      colId: 'ex_employee',
      tooltipValueGetter: getExEmployee,
      valueGetter: getExEmployee,
      headerName: 'Ex Employee',
      minWidth: 50,
      maxWidth: 200,
      filter: 'CustomFilter',
      filterParams: {
        Component: YesNoSelection,
        field: 'id'
      }
    },
    {
      field: 'location',
      colId: 'location',
      tooltipValueGetter: getLocation,
      valueGetter: getLocation,
      minWidth: 50,
      maxWidth: 200,
      hide: true,
      headerName: 'Location',
      sortable: true,
      filter: 'CustomFilter',
      filterParams: {
        Component: CountrySelection,
        field: 'name'
      }
    },
    {
      field: 'city',
      colId: 'city',
      tooltipValueGetter: getStateCity,
      valueGetter: getStateCity,
      headerName: 'State/City',
      hide: true,
      editable: false,
      sortable: false,
      minWidth: 50,
      maxWidth: 200,
      filter: false
    },
    {
      field: 'dq_reason',
      // tooltipField: "dq_reason",
      colId: 'dq_reason',
      headerName: 'Reason',
      tooltipValueGetter: getDQReason,
      cellRenderer: getDQReason,
      minWidth: 50,
      maxWidth: 200,
      editable: true,
      cellEditor: 'PopupEditor',
      cellStyle: { overflowY: 'auto', lineHeight: '20px' },
      cellEditorParams: {
        InputComponent: FeetypeSelection,
        title: 'Reason',
        enqueueSnackbar,
        validateDqReason: true,
        type: 'PRODUCT_ONE_REASONS',
        sort: true,
        placeholder: 'Reason'
      },
      valueSetter: params => {
        let values = params.newValue?.map(ele => ele.short_desc || ele);
        params.newValue = values;
        func(params, 'dq_reason');
      },
      sortable: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },
    /* {
      field: "match",
      tooltipField: "match",
      colId: "match",
      headerName: "Match Score",
      minWidth: 50,
      hide: true,
      maxWidth: 200,
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: getMatchFilterOptions(),
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    }, */
    {
      field: 'source',
      colId: 'source',
      headerName: 'Source',
      cellClass: 'text-capitalize',
      minWidth: 50,
      maxWidth: 200,
      hide: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        buttons: ['reset', 'apply'],
        suppressAndOrCondition: true,
        closeOnApply: true
      }
    },

    {
      field: 'actions',
      colId: 'actions',
      sortable: false,
      maxWidth: 134,
      minWidth: 134,
      headerName: 'Actions',
      cellRenderer: 'ActionsRenderer'
    },
    /* {
      headerName: "Accept/Decline",
      field: "accept",
      colId: "accept",
      minWidth: 50,
      maxWidth: 200,
      cellRenderer: (params) => {
        const status = checkContactFetchingStatus(params)
        if (status) return status;
        return params?.data?.accept ? 'Accept' : ''
      },
      sortable: false,
      filter: false
    }, */
    {
      field: 'freeze_dropdown',
      colId: 'freeze_dropdown',
      headerName: '',
      sortable: false,
      filter: false,
      minWidth: 44,
      maxWidth: 44,
      width: 44,
      pinned: 'right',
      lockPinned: true
    }
  ];
  columns.forEach(ele => {
    if (ele.field !== 'name' && ele.field !== 'linkedin_url' && ele.field !== 'freeze_dropdown' && ele.field !== 'check_box' && ele.field !== 'source') {
      ele.edit = ele.editable;
      ele.editable = params => {
        return checkContactFetchingStatus(params) ? false : ele.edit;
      };
      if (!ele.cellRenderer) {
        ele.cellRenderer = params => {
          const status = checkContactFetchingStatus(params);
          return status ? status : params?.value;
        };
      }
    }
  });
  return columns;
};

export const StageRenderer = params => {
  // const status = checkContactFetchingStatus(params)
  // if (status) return status;
  return (
    <div className='d-flex align-items-center cursor-pointer'>
      <Typography style={{ fontSize: 'inherit' }}>{params.value}</Typography>
      <ArrowDropDownIcon fontSize='small' />
    </div>
  );
};

export const acquire = async (params, lable, enqueueSnackbar, closeSnackbar, required = false, candidate, SetLoader, dispatch, changeJobHistory = false) => {
  const newValue = { ...params };
  let { status, data } = await acquireLockApi(params.data.contact.id);
  if (status && status === 200 && data.message === undefined) {
    typeof dispatch === 'function' && dispatch({ type: RELOAD_ALL_CANDIDATE_GRID });
    if (candidate === 'candidate') {
      return updateCandidate(newValue, lable, enqueueSnackbar, required, SetLoader, undefined, changeJobHistory);
    } else {
      return updateContact(newValue, lable, enqueueSnackbar, required, SetLoader, undefined, changeJobHistory);
    }
  } else {
    //if (data.isAdmin) {
    enqueueSnackbar(data.message, {
      variant: WARNING,
      // eslint-disable-next-line react/display-name
      action: key => (
        <>
          <Button
            size='small'
            variant='text'
            color='inherit'
            onClick={() => {
              closeSnackbar(key);
              releaseLock(params.data.contact.id, newValue, lable, enqueueSnackbar, closeSnackbar, required, candidate, SetLoader, dispatch, changeJobHistory);
            }}
          >
            Force Unlock
          </Button>
          <Button
            size='small'
            variant='text'
            color='inherit'
            onClick={async () => {
              closeSnackbar(key);
            }}
          >
            CLOSE
          </Button>
        </>
      )
    });
    return false;
    /*  }
    else {
      enqueueSnackbar(data.message, { variant: WARNING });
      return false;
    } */
  }
};
const releaseLock = async (id, params, lable, enqueueSnackbar, closeSnackbar, required, candidate, SetLoader, dispatch, changeJobHistory) => {
  await releaseLockApi(id);
  acquire(params, lable, enqueueSnackbar, closeSnackbar, required, candidate, SetLoader, dispatch, changeJobHistory);
};
const getAverage = (data, fieldName) => {
  if (data) {
    const elems = data?.filter(elem => elem && elem[fieldName]).map(elem => elem[fieldName]);
    if (elems?.length === 0) {
      return null;
    }
    return elems.reduce((prev, cur) => prev + cur) / elems?.length;
  }
  return null;
};

export const FQRenderer = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  if (params && params.data) {
    const avgSkillRating = getAverage(params.data.skills, 'partner_rating');
    const avgAttributesRating = getAverage(params.data.attributes, 'rating');
    const fq = avgSkillRating + avgAttributesRating;
    return fq.toFixed(2);
  }
  return null;
};
const downloadAttachment = async (attachmentId, enqueueSnackbar) => {
  try {
    const url = await AttachmentsService.download(attachmentId, 'contact', 'resumes');
    if (url) {
      window.open(url);
    } else {
      const message = unableMessage('Resume', 'download');
      enqueueSnackbar(message, { variant: ERROR });
    }
  } catch (e) {
    console.log('Error found in downloadAttachment::', e);
  }
};
export const ResumeRenderer = params => {
  const status = checkContactFetchingStatus(params);
  if (status) return status;
  const contact_resumes = getContactField(params.data, 'contact_resumes');
  return contact_resumes && contact_resumes?.length ? (
    <Typography className='text-link' color={'primary'} onClick={() => downloadAttachment(contact_resumes[0].id, params.colDef?.cellRendererParams.enqueueSnackbar)}>
      Yes
    </Typography>
  ) : (
    'No'
  );
};

export const getFilterParamString = data => {
  const queryValueTypes = {
    job_functions: { isValueType: true },
    location: { field: 'name' },
    industries: { field: 'name' },
    priority: { field: 'value' },
    international_experience: { field: 'name' }
    //state:{field: "name"}
  };
  if (data.stage?.filter.includes('&')) data.stage.filter = data.stage.filter.replace('&', '%26');
  return getFilterQuery(data, {}, queryValueTypes);
};
