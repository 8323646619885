// TODO: Remove this once we finalized SMS & PUSH_NOTIFICATION Workflow
export const HOLD_OFF_ACTION_TYPE = ['SMS', 'PUSH_NOTIFICATION'];

export const defaultState = {
  title: '',
  value: ''
};
export const generateNewAction = () => {
  return {
    isNew: true,
    id: Math.random().toString(36).substr(2, 9),
    action_type: defaultState,
    action_description: undefined,
    recipient_type: defaultState,
    recipients_or_url: defaultState,
    template_name: '',
    template_id: '',
    action_disabled: false,
    payload: null,
    webhook_header: null,
    users: [],
    contacts: []
  };
};

export const mapperRecipients = {
  PROJECT_TEAM: 'projectTeamRecipients',
  CLIENT_TEAM: 'clientTeamRecipients'
};
export const mapperMessageTemplates = {
  EMAIL: 'emailTemplates',
  SMS: 'smsTemplates'
};
