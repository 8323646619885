//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState, useRef } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { useSnackbar } from 'notistack';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { Button, RadioGroup, FormControlLabel, Radio, Checkbox } from '@mui/material';
import { useForm } from 'react-hook-form';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import { getAccessToken } from '../../../services/cognitoService';
import UserSelection from '../../UserSelection';
import TimezoneSelection from '../../TimeZone/TimeSelection';
import { getUserSelector } from '../../../selectors';
import { ERROR } from '../../../services/constantService';
import { validateMessage } from '../../../services/MessageService';

function ReportOptions(props) {
  const { url = '/', defaultLocalTime, timeZoneOptions, options = { locale: true, pageSize: true }, params = {}, onClose, openOnMatrix } = props;
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues = { pageSize: 'USLetter', locale: 'en_US' };
  const { register, unregister, handleSubmit, setValue, getValues } = useForm({ defaultValues });
  const [defaultValue, setDefaultValue] = useState({});
  const userRef = useRef();
  const userData = useSelector(getUserSelector);

  const validateInputForSelectedReport = async data => {
    if (openOnMatrix) {
      const id = getValues('partnerId');
      if (id.partnerId) {
        downloadReport(data);
      } else {
        const message = validateMessage('partner', 'select');
        enqueueSnackbar(message, { variant: ERROR });
      }
    } else {
      downloadReport(data);
    }
  };

  const downloadReport = async data => {
    const token = await getAccessToken();
    if (!options.locale) {
      delete data.locale;
    }
    if (!options.pageSize) {
      delete data.pageSize;
    }

    const { pageSize, locale, partnerId, time_zone } = data;
    const queryParamsKey = { pageSize, locale: locale ? locale : 'en_US' };
    let matrixParams = { pageSize, partnerId };
    let queryParams = { ...params, ...queryParamsKey };
    time_zone && (time_zone && openOnMatrix ? (matrixParams.timezone = time_zone) : (queryParams.timezone = time_zone));
    openOnMatrix ? reportURL(matrixParams, token) : reportURL(queryParams, token);
  };

  const reportURL = async (queryParams, token) => {
    if (queryParams.timezone) {
      queryParams.timezone = JSON.stringify(queryParams.timezone);
    }
    let queryURL = Object.keys(queryParams)
      .map(key => `${key}=${queryParams[key]}`)
      .join('&');
    const updateURL = url + `?${queryURL}&token=${token}`;
    window.open(updateURL);
    onClose && onClose();
  };

  useEffect(() => {
    register('locale');
    register('partnerId');
    register('time_zone');
    return () => {
      unregister('time_zone');
    };
  }, [register]);

  useEffect(() => {
    setDefaultValue(defaultLocalTime);
    if (Object.keys(defaultValue)?.length === 0 && defaultValue.constructor === Object) {
      setValue('time_zone', defaultLocalTime);
    }
  }, []);

  return (
    <Popup open={true} className='report-options' onClose={onClose} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className='d-flex flex-column h-100'>
        <div className='d-flex align-items-center justify-content-between popup-header' style={{ background: '#c3c3c35c', color: '#fff' }}>
          <div className='d-flex align-items-center'>
            <h4 className='mr-5'>Choose Report Options</h4>
          </div>
          <span className='action-icon cursor-pointer' onClick={onClose}>
            <CloseIcon />
          </span>
        </div>
        <div className='report-options flex-grow-1 overflow-auto'>
          {options.pageSize ? (
            <div className='field-details align-items-center'>
              <span className='field-title'>Page Size</span>
              <span className='field-value'>
                <RadioGroup row aria-label='PageSize' name='pageSize' defaultValue={defaultValues.pageSize} className='radio-group'>
                  <FormControlLabel
                    className='checkbox-container'
                    style={{ marginBottom: 0 }}
                    control={<Radio className='checkbox-component' size='small' value='USLetter' inputRef={register('pageSize')} />}
                    label='US Letter'
                  />
                  <FormControlLabel
                    className='checkbox-container'
                    style={{ marginBottom: 0 }}
                    control={<Radio className='checkbox-component' size='small' value='A4' inputRef={register('pageSize')} />}
                    label='A4'
                  />
                </RadioGroup>
              </span>
            </div>
          ) : (
            ''
          )}
          {options.locale && !openOnMatrix ? (
            <div className='field-details align-items-center'>
              <span className='field-title'>Language</span>
              <div className='field-value'>
                <Select className='w-100' onChange={e => setValue('locale', e.target.value)} defaultValue={defaultValues.locale}>
                  <MenuItem value='en_US'>English</MenuItem>
                  <MenuItem value='es_ES'>Spanish</MenuItem>
                  <MenuItem value='pt_BR'>Portuguese</MenuItem>
                </Select>
              </div>
            </div>
          ) : (
            ''
          )}
          {props.params?.timeZone ? (
            <div className='field-details align-items-center'>
              <span className='field-title'>Timezone</span>
              <div className='field-value'>
                <TimezoneSelection
                  defaultValue={defaultLocalTime || null}
                  options={timeZoneOptions}
                  className='input-field'
                  name='time_zone'
                  InputLabelProps={{ focused: true }}
                  onChange={(e, data) => {
                    setValue('time_zone', data);
                  }}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          {openOnMatrix ? (
            <div className='field-details align-items-center'>
              <span className='field-title'>Partner Name</span>
              <div className='field-value'>
                <UserSelection
                  inputRef={userRef}
                  className='w-100'
                  InputLabelProps={{ focused: true }}
                  filterSelectedOptions={true}
                  label={'Partners'}
                  placeholder={'Select'}
                  required={true}
                  onChange={(e, data) => setValue('partnerId', data?.id)}
                />
              </div>
            </div>
          ) : null}

          {options.showLegacy && userData?.isAdmin ? (
            <div className='d-flex justify-content-end'>
              <FormControlLabel control={<Checkbox size='small' name='useLegacyTemplate' {...register('useLegacyTemplate')} value={true} />} label={'Use Legacy Format'} />
            </div>
          ) : null}
        </div>
        <div className='action-buttons' style={{ background: '#c3c3c35c' }}>
          <Button onClick={onClose} color='primary' className='mr-2'>
            Cancel
          </Button>
          <Button variant='contained' className='button' color='primary' onClick={handleSubmit(validateInputForSelectedReport)}>
            Download Report
          </Button>
        </div>
      </div>
    </Popup>
  );
}

ReportOptions.propTypes = {
  url: PropTypes.string,
  options: PropTypes.object,
  params: PropTypes.object,
  defaultLocalTime: PropTypes.object,
  timeZoneOptions: PropTypes.array,
  onClose: PropTypes.func,
  openOnMatrix: PropTypes.bool
};

export default ReportOptions;
