import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const columnDefs = [
  {
    cellRenderer: 'checkboxRenderer',
    width: 70,
    resizable: true,
    sortable: true,
    headerClass: 'ag-right-aligned-header'
  },
  {
    field: 'name',
    headerName: 'Role Name',
    cellRenderer: 'roleNameRenderer',
    width: 150,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    headerClass: 'ag-right-aligned-header'
  },
  // Using dot notation to access nested property
  {
    field: 'description',
    headerName: 'Description',
    width: 350,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  // Show default header name
  {
    field: 'cognitoName',
    headerName: 'Cognito Group Name',
    width: 350,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'resources',
    headerName: 'Resources',
    cellRenderer: 'resourcesRenderer',
    width: 200,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  }
];

const ResourcesRenderer = props => {
  return (
    <div
      className='py-2 text-center w-100'
      onClick={() => {
        props.setCurrentRole(props.data);
        props.setIsDrawerOpen(true);
      }}
    >
      Manage
    </div>
  );
};

const RoleNameRenderer = props => {
  return (
    <div
      className='py-2'
      style={{
        width: '100%'
      }}
      onClick={() => {
        props.setCurrentRole(props.data);
        props.setIsDrawerOpen(true);
      }}
    >
      {props.value}
    </div>
  );
};

const CheckBoxRenderer = props => {
  const { setToDelete } = props;
  return (
    <Checkbox
      onClick={() => {
        setToDelete(prev => {
          const index = prev.indexOf(props.data.id);
          if (index > -1) {
            return prev.filter(id => id !== props.data.id);
          }
          return [...prev, props.data.id];
        });
      }}
    />
  );
};

RoleNameRenderer.propTypes = {
  data: PropTypes.object,
  value: PropTypes.string,
  setIsDrawerOpen: PropTypes.func,
  setCurrentRole: PropTypes.func
};

CheckBoxRenderer.propTypes = {
  data: PropTypes.object,
  setToDelete: PropTypes.func
};

ResourcesRenderer.propTypes = {
  data: PropTypes.object,
  setCurrentRole: PropTypes.func,
  setIsDrawerOpen: PropTypes.func
};

export { CheckBoxRenderer, ResourcesRenderer, RoleNameRenderer, columnDefs };
