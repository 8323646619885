import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomDropdown from '../../CustomDropdown';
import { fetchPickLists } from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';

// const data = [
//   { label: "EA", name: "EA" },
//   { label: "Reference", name: "Reference" },
//   { label: "Top 25", name: "Top 25" },
// ];

const ContactRelationTypeSelection = props => {
  const { value, name = '', placeholder, onChange = () => {}, disabled = false, selectedList, isDrawer = false, className = '', label = '', setRelationList = () => {} } = props;
  const dispatch = useDispatch();
  const relationType = useSelector(state => state.commonReducer.relationType);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (!relationType) {
      dispatch(fetchPickLists('RELATION_TYPE', 'relationType'));
    } else {
      if (relationType) {
        setRelationList(relationType);
        const filterData = relationType?.filter(item => selectedList?.every(listItem => listItem?.type !== item?.field_value));
        setOptions(filterData);
      } else {
        setOptions([]);
      }
    }
  }, [relationType, dispatch, selectedList]);

  return <CustomDropdown value={value} options={options} name={name} onChange={onChange} disabled={disabled} isDrawer={isDrawer} className={className} placeholder={placeholder} label={label} />;
};
ContactRelationTypeSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isDrawer: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  selectedList: PropTypes.array,
  setRelationList: PropTypes.func
};
export default ContactRelationTypeSelection;
