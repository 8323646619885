//-----------------------------------------------------------// External Imports // ------------------------------

import axios from 'axios';

const url = window.location.origin,
  subdomain = url.split('.')[0];

const restOfDomain = url.substring(url.indexOf('.') + 1); // Get the rest of the domain

const dynamicUrl = subdomain.includes('localhost') && process.env.REACT_APP_IS_LOCAL ? 'http://localhost:3000' : `${subdomain}.app.${restOfDomain}`;

export default axios.create({
  baseURL: dynamicUrl,
  withCredentials: true
});

// const instance = axios.create({
//   baseURL: dynamicUrl,
//   withCredentials: true
// });
// // -----------------------------------------------------------------------------------------------------------------------
// // write a function that check for duplicate request and cancel the previous request. keep it optimized
// // -----------------------------------------------------------------------------------------------------------------------
// // Create a map to store cancel tokens and their associated request identifiers
// const activeRequests = new Map();

// // Request interceptor to check for duplicates
// instance.interceptors.request.use(
//   config => {
//     // Generate a unique identifier based on the request URL and method
//     const requestKey = `${config.url}:${config.method}`;

//     // Check if there's an ongoing request for the same key
//     if (activeRequests.has(requestKey)) {
//       // Cancel the previous request
//       const previousRequestCancelToken = activeRequests.get(requestKey);
//       previousRequestCancelToken.cancel('Request canceled due to a duplicate.');

//       // Remove the previous cancel token
//       activeRequests.delete(requestKey);
//     }

//     // Create a new cancel token for the current request
//     const cancelTokenSource = axios.CancelToken.source();
//     config.cancelToken = cancelTokenSource.token;

//     // Store the cancel token in the map
//     activeRequests.set(requestKey, cancelTokenSource);

//     // Log the request

//     return config;
//   },
//   error => {
//     // Handle request error
//     console.log('Request error', error);
//     return Promise.reject(error);
//   }
// );

// // Response interceptor to remove the cancel token when the request is completed
// instance.interceptors.response.use(
//   response => {
//     // Remove the cancel token from the map once the request is completed
//     const requestKey = `${response.config.url}:${response.config.method}`;
//     activeRequests.delete(requestKey);

//     return response;
//   },
//   error => {
//     // Handle response error
//     if (axios.isCancel(error)) {
//       console.log(error.message); // Log the cancelation message
//     }
//     return Promise.reject(error);
//   }
// );
// export default instance;
