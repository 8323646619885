/* eslint-disable no-unused-vars */

export default function checkForChanges(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }
  if (typeof obj1 !== typeof obj2) {
    return false;
  }
  if (typeof obj1 !== 'object' || obj1 === null || obj2 === null) {
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1?.length > keys2?.length) {
    if (keys2?.length === 0) {
      return false;
    }
    for (let key of keys2) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      if (val2 !== null) {
        if (keys1.includes(key)) {
          if (!(Array.isArray(val1) && Array.isArray(val2))) {
            if (typeof val2 === 'object' && typeof val1 === 'object') {
              if (!checkForChanges(val1, val2)) {
                return false;
              }
            } else if (val1 !== val2) {
              return false;
            }
          } else {
            if (Array.isArray(val1) && Array.isArray(val2)) {
              if (val1?.length !== val2?.length) {
                return false;
              }
              for (let i = 0; i < val1?.length; i++) {
                if (typeof val1[i] === 'object' && typeof val2[i] === 'object') {
                  if (!checkForChanges(val1[i], val2[i])) {
                    return false;
                  }
                } else if (val1[i] !== val2[i]) {
                  return false;
                }
              }
            } else if (typeof val1 === 'object' && typeof val2 === 'object') {
              if (!checkForChanges(val1, val2)) {
                return false;
              }
            }
          }
        }
      } else if (val1 && !val2) return false;
    }
  }
  if (keys2?.length > keys1?.length) {
    if (keys1?.length === 0) {
      return false;
    }
    for (let key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      if (val2 !== null) {
        if (keys2.includes(key)) {
          if (!(Array.isArray(val1) && Array.isArray(val2))) {
            if (typeof val2 === 'object' && typeof val1 === 'object') {
              if (!checkForChanges(val1, val2)) {
                return false;
              }
            } else if (val1 !== val2) {
              return false;
            }
          } else {
            if (Array.isArray(val1) && Array.isArray(val2)) {
              if (val1?.length !== val2?.length) {
                return false;
              }
              for (let i = 0; i < val2?.length; i++) {
                if (typeof val1[i] === 'object' && typeof val2[i] === 'object') {
                  if (!checkForChanges(val1[i], val2[i])) {
                    return false;
                  }
                } else if (val1[i] !== val2[i]) {
                  return false;
                }
              }
            } else if (typeof val1 === 'object' && typeof val2 === 'object') {
              if (!checkForChanges(val1, val2)) {
                return false;
              }
            }
          }
        }
      }
    }
  }
  if (keys1?.length === keys2?.length) {
    for (let key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      if (keys2.includes(key)) {
        if (!(Array.isArray(val1) && Array.isArray(val2))) {
          if (typeof val2 === 'object' && typeof val1 === 'object') {
            if (!checkForChanges(val1, val2)) {
              return false;
            }
          } else if (val1 !== val2) {
            return false;
          }
        } else {
          if (Array.isArray(val1) && Array.isArray(val2)) {
            if (val1?.length !== val2?.length) {
              return false;
            }
            for (let i = 0; i < val2?.length; i++) {
              if (typeof val1[i] === 'object' && typeof val2[i] === 'object') {
                if (!checkForChanges(val1[i], val2[i])) {
                  return false;
                }
              } else if (val1[i] !== val2[i]) {
                return false;
              }
            }
          } else if (typeof val1 === 'object' && typeof val2 === 'object') {
            if (!checkForChanges(val1, val2)) {
              return false;
            }
          }
        }
      }
    }
  }
  return true;
}
