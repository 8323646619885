import { enqueueSnackbar } from 'notistack';
import { DELETE, POST, PUT, STANDARD_ERROR_MESSAGE } from '../../../../services/constantService';

import { CandidateStageSetupAPi, StageWorkflowApi } from '../../../../services/ApiService';

export const updateStageData = async (stageId, payload) => {
  try {
    const sub_route = `/stage/${stageId}`;
    const { status, data } = await CandidateStageSetupAPi(PUT, sub_route, payload);
    if (status !== 200) {
      return {
        success: false,
        data: null,
        error: data.msg
      };
    }
    return {
      success: true,
      data: data.data,
      msg: data.msg,
      error: null
    };
  } catch (error) {
    return {
      error: STANDARD_ERROR_MESSAGE,
      success: false,
      data: null
    };
  }
};

export const createNewStageData = async payload => {
  try {
    const sub_route = '/stage';
    const { status, data } = await CandidateStageSetupAPi(POST, sub_route, payload);
    if (status !== 200) {
      return {
        success: false,
        data: null,
        error: data.msg
      };
    }
    return {
      success: true,
      data: data.data,
      error: null
    };
  } catch (error) {
    return {
      error: STANDARD_ERROR_MESSAGE,
      success: false,
      data: null
    };
  }
};

export const deactivateStage = async stageId => {
  try {
    const sub_route = `/stage/${stageId}`;
    const { status, data } = await CandidateStageSetupAPi(DELETE, sub_route);
    if (status !== 200) {
      return {
        success: false,
        data: null,
        warnMsg: null,
        error: data.msg
      };
    }
    return {
      success: true,
      data: data.msg,
      warnMsg: data.data?.warnMsg || null,
      error: null
    };
  } catch (error) {
    return {
      error: STANDARD_ERROR_MESSAGE,
      success: false,
      warnMsg: null,
      data: null
    };
  }
};

// DELETE /api/v1/stage-workflow/transition/:transitionId
export const deleteStageTransition = async transitionId => {
  try {
    const sub_route = `/transition/${transitionId}`;
    const { status, data } = await StageWorkflowApi(DELETE, sub_route);
    if (status !== 200) {
      enqueueSnackbar(data.msg, { autoHideDuration: 3000, variant: 'error' });
      return {
        success: false,
        data: null,
        error: data.msg
      };
    }
    enqueueSnackbar(data.msg, { autoHideDuration: 3000, variant: 'success' });
    return {
      success: true,
      msg: data.msg
    };
  } catch (error) {
    enqueueSnackbar(STANDARD_ERROR_MESSAGE, { autoHideDuration: 3000, variant: 'error' });
    return {
      error: STANDARD_ERROR_MESSAGE,
      success: false,
      data: null
    };
  }
};
// DELETE /api/v1/stage-workflow/action/:actionId
export const deleteStageAction = async (actionId, showToast = true) => {
  try {
    const sub_route = `/action/${actionId}`;
    const { status, data } = await StageWorkflowApi(DELETE, sub_route);
    if (status !== 200) {
      enqueueSnackbar(data.msg, { autoHideDuration: 3000, variant: 'error' });
      return {
        success: false,
        data: null,
        error: data.msg
      };
    }
    if (showToast) {
      enqueueSnackbar(data.msg, { autoHideDuration: 3000, variant: 'success' });
    }
    return {
      success: true,
      msg: data.msg
    };
  } catch (error) {
    enqueueSnackbar(STANDARD_ERROR_MESSAGE, { autoHideDuration: 3000, variant: 'error' });
    return {
      error: STANDARD_ERROR_MESSAGE,
      success: false,
      data: null
    };
  }
};

// POST /api/v1/stage-workflow/transition
export const createNewStageTransition = async payload => {
  try {
    const sub_route = '/transition';
    const { status, data } = await StageWorkflowApi(POST, sub_route, payload);
    if (status !== 200) {
      if (data.msg.includes('UniqueConstraint')) {
        return {
          error: 'This Transition already exists',
          success: false,
          data: null
        };
      }
      return {
        success: false,
        data: null,
        error: data.msg
      };
    }
    if (data.error) {
      return {
        error: data.msg,
        success: false,
        data: data.data
      };
    }
    return {
      success: true,
      msg: data.msg,
      data: data.data
    };
  } catch (error) {
    return {
      error: STANDARD_ERROR_MESSAGE,
      success: false,
      data: null
    };
  }
};

// PUT /api/v1/stage-workflow/transition/:transitionId
export const updateStageTransition = async (transitionId, payload) => {
  try {
    /**
     * MOCK PAYLOAD
     * {
     *  "setup_name": "Screened To KG Interview",
     *  "from_stage_code": "Screened",
     *  "to_stage_code": "P1"
     * }
     */
    const sub_route = `/transition/${transitionId}`;
    const { status, data } = await StageWorkflowApi(PUT, sub_route, payload);
    if (status !== 200) {
      return {
        success: false,
        data: null,
        error: data.msg
      };
    }
    if (data.error) {
      return {
        error: data.msg,
        success: false,
        data: data.data
      };
    }
    return {
      success: true,
      data: data.data,
      msg: data.msg,
      error: null
    };
  } catch (error) {
    return {
      error: STANDARD_ERROR_MESSAGE,
      success: false,
      data: null
    };
  }
};

// PUT /api/v1/stage-workflow/action/:actionId
export const updateStageAction = async (actionId, payload) => {
  try {
    //  MOCK PAYLOAD
    // {
    //   "action_description": "Notify Client",
    //   "action_type": "EMAIL",
    //   "recipient_type": "USERS",
    //   "recipients_or_url": "00022add-6db3-444f-b2fb-35acc6527a98, 000dcea0-9c70-4547-92f9-0fddf201cbec",
    //   "template_id": "d2da8c37-0ed4-44f2-ade3-6f738d11ccf8"
    // }
    const sub_route = `/action/${actionId}`;
    const { status, data } = await StageWorkflowApi(PUT, sub_route, payload);
    if (status !== 200) {
      return {
        success: false,
        data: null,
        error: data.msg
      };
    }
    return {
      success: true,
      data: data.data,
      msg: data.msg,
      error: null
    };
  } catch (error) {
    return {
      error: STANDARD_ERROR_MESSAGE,
      success: false,
      data: null
    };
  }
};

// POST /api/v1/stage-workflow/action/:transitionId
export const createNewAction = async (transitionId, payload) => {
  // MOCK PAYLOAD
  // {
  //   "action_description": "Notify 3rd Party",
  //   "action_type": "WEBHOOK",
  //   "recipient_type": "JSON",
  //   "recipients_or_url": "https://webhook-test.com/95b4a4751691900cac332036d1220e50",
  //   "payload": {
  //     "requiredAttributes": ["user_email", "job_title", "job_id"],
  //     "customPayload": {
  //       "custom_value": "123"
  //     },
  //     "headers": {
  //       "X-Client-Secret": "kjnaosijb234jnaskn92ninjksfi"
  //     }
  //   }
  // }
  try {
    const sub_route = `/action/${transitionId}`;
    const { status, data } = await StageWorkflowApi(POST, sub_route, payload);
    if (status !== 200) {
      return {
        success: false,
        data: null,
        error: data.msg
      };
    }
    return {
      success: true,
      msg: data.msg,
      data: data.data,
      error: null
    };
  } catch (error) {
    return {
      error: STANDARD_ERROR_MESSAGE,
      success: false,
      data: null
    };
  }
};
