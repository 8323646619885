//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import SearchInformation from '../../../CreateSearch/Components/SearchInformation';
import SearchInformationView from './SearchInformationView';
import { getUserSelector } from '../../../../selectors';
import { useSelector } from 'react-redux';
import { PENDING_REJECT_BILLING_STATUS } from '../../../../services/constantService';
import { checkBillingRole, checkSearchApprovalRoles } from '../../../../Containers/Commons/Utils';

export default function SearchInformationContainer(props) {
  const {
    editSearch,
    register,
    setValue,
    handelScroll,
    watch,
    control,
    getValues,
    currentValues,
    isEditing,
    renderActions,
    renderLabel,
    editingField,
    reloadData,
    setInitialLoader,
    setRenderActionFlag,
    renderActionFlag,
    renderSectionLabel,
    renderFieldLabel
  } = props;
  const userData = useSelector(getUserSelector);
  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    const disableEdit = checkSearchApprovalRoles(userData);
    if (!disableEdit && PENDING_REJECT_BILLING_STATUS.includes(currentValues.billing_status)) {
      setIsHide(true);
    } else if (currentValues.stage === 'Closed') {
      const editable = checkBillingRole(userData);
      if (!editable) {
        setIsHide(true);
      }
    }
  }, [currentValues]);

  return isEditing ? (
    <SearchInformation
      register={register}
      setValue={setValue}
      watch={watch}
      control={control}
      isEditing={isEditing}
      currentValues={currentValues}
      isFromViewSearch={true}
      handelScroll={handelScroll}
      setRenderActionFlag={setRenderActionFlag}
      renderActionFlag={renderActionFlag}
      isHide={isHide}
    />
  ) : (
    <SearchInformationView
      editSearch={editSearch}
      register={register}
      setValue={setValue}
      watch={watch}
      getValues={getValues}
      search={currentValues}
      renderActions={renderActions}
      renderLabel={renderLabel}
      editingField={editingField}
      reloadData={reloadData}
      handelScroll={handelScroll}
      setInitialLoader={setInitialLoader}
      setRenderActionFlag={setRenderActionFlag}
      renderActionFlag={renderActionFlag}
      renderSectionLabel={renderSectionLabel}
      renderFieldLabel={renderFieldLabel}
      isHide={isHide}
    />
  );
}

SearchInformationContainer.propTypes = {
  editSearch: PropTypes.func,
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  getValues: PropTypes.func,
  control: PropTypes.object,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  renderLabel: PropTypes.func,
  renderActions: PropTypes.func,
  editingField: PropTypes.string,
  reloadData: PropTypes.func,
  handelScroll: PropTypes.func,
  setInitialLoader: PropTypes.bool,
  setRenderActionFlag: PropTypes.bool,
  renderActionFlag: PropTypes.bool,
  renderSectionLabel: PropTypes.func,
  renderFieldLabel: PropTypes.func
};
