//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from 'react';
import * as yup from 'yup';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { Button, TextField, Switch, FormControlLabel, FormControl, Typography, Checkbox } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

// import { ERROR, WARNING, EMAIL_REGEX } from "../../services/constantService";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import Reference from './components/Reference';
import RoleSelection from './components/RoleSelection';
import TextArea from '../common/TextArea';
import './index.scss';
import Loader from '../common/Loader';
import UserSelection from '../UserSelection';
import { POST, SUCCESS, ERROR, EMAIL_REGEX } from '../../services/constantService';
import PracticeSelection from './components/PracticeSelection';
import CountrySelection from '../CountrySelection';
import StateSelection from '../StateSelection';
import DepartmentSelection from './components/DepartmentSelection';
import SecondaryDepartmentSelection from './components/SecondaryDepartmentSelection';
import LocationSelection from './components/LocationSelection';
import WebsiteTagsSelection from './components/WebsiteTagsSelection';
// import TimezoneSelection from "./components/TimezoneSelection";
import RegionSelection from './components/RegionSelection';
import WorkgroupSelection from './components/WorkgroupSelection';
import InputField from '../common/InputField';
import { userDataApi } from '../../services/ApiService';
import { formatDate } from '../../utils/date';
import { unableMessage, successMessage, validateMessage, VALIDATION_MESSAGE } from '../../services/MessageService';
import RichTextPopup from '../../../src/components/RichTextPopup';
import { customFormValidator, requireValidMessage } from '../../utils/common';
import AppRoleSelection from './components/AppRoleSelection';
import { InputLabel } from '@mui/material';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
function AddUser() {
  const { enqueueSnackbar } = useSnackbar();
  const { register, unregister, handleSubmit, setValue, watch } = useForm();
  const [loading, setLoading] = React.useState(false);
  const country = watch('country');
  const navigate = useNavigate();
  useEffect(() => {
    register('roles');
    register('country');
    register('state');
    register('departments');
    register('secondary_departments');
    register('reference');
    register('project_managers');
    register('region_field');
    register('app_roles');
    return () => {
      unregister('roles');
      unregister('country');
      unregister('departments');
      unregister('secondary_departments');
      unregister('reference');
      unregister('project_managers');
      unregister('region_field');
      unregister('app_roles');
    };
  }, [register, unregister]);

  const cleanup = userData => {
    if (userData['revenue_budget']?.length === 0) {
      delete userData['revenue_budget'];
    }
    if (userData['app_roles']) {
      const array = [];
      const app_roles = [];
      userData.app_roles.forEach(element => {
        array.push({ role_id: element.id });
        app_roles.push(element.name);
      });
      userData.app_roles = app_roles;
      userData.user_roles = array;
    }
    if (userData['capacity']?.length === 0) {
      delete userData['capacity'];
    }
    if (userData['kgp_number']?.length === 0) {
      delete userData['kgp_number'];
    }

    return userData;
  };

  const handleChange = (name, value) => {
    const date = formatDate(value);
    setValue(name, date);
  };

  const validateEmail = email => {
    return email && !email.match(EMAIL_REGEX) ? false : true;
  };

  const addUsersSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    title: yup.string().required(),
    email: yup.string().required(),
    roles: yup.string().required(),
    hiring_date: yup.string().required()
  });

  const addUser = async userData => {
    const isValid = await addUsersSchema.isValid(userData);
    if (!isValid) {
      let requiredField = [
        { fieldName: 'first_name', label: 'First Name', type: String },
        { fieldName: 'last_name', label: 'Last Name', type: String },
        { fieldName: 'title', label: 'Title', type: String },
        { fieldName: 'email', label: 'Email', type: String },
        { fieldName: 'roles', label: 'Role', type: String },
        { fieldName: 'hiring_date', label: 'Date of Hire', type: String }
      ];
      let dirtyField = customFormValidator(userData, requiredField);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    let validateEmail1 = true;
    validateEmail1 = validateEmail(userData.email);
    if (!validateEmail1) {
      const message = validateMessage('Valid Email', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (userData.kgp_number && (userData.kgp_number < 0 || userData.kgp_number > 9999)) {
      enqueueSnackbar(VALIDATION_MESSAGE.kgp_number_validation, {
        variant: ERROR
      });
    } else {
      setLoading(true);
      const { status, data } = await userDataApi(POST, '', cleanup({ ...userData }));
      console.log('data', data);
      if (status === 201) {
        const message = successMessage('User', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
        navigate('/users');
      } else {
        const message = unableMessage('user', 'create');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setLoading(false);
    }
  };

  const stateChange = state => {
    setValue('state', state);
  };

  return (
    <div className='add-user d-flex flex-column section-content'>
      <Helmet>
        <title>Add User - KGP Galaxy</title>
      </Helmet>
      <Loader show={loading} />
      <div className='d-flex align-items-center'>
        <Link to='/users'>
          <span className='header-back-icon'>
            <ArrowBackIcon color='primary' />
          </span>
        </Link>
        <span className='header-text'>Add New User</span>
      </div>
      <form onSubmit={handleSubmit(addUser)} autoComplete='off'>
        <div className='d-flex'>
          <InputField
            {...register('first_name')}
            className='input-field '
            name={'first_name'}
            label={
              <label>
                First Name<span className='MuiInputLabel-asterisk'>*</span>
              </label>
            }
            placeholder='First Name'
            // required={true}
            InputLabelProps={{ focused: true }}
          />
          <InputField
            {...register('last_name')}
            className='input-field'
            name={'last_name'}
            label={
              <label>
                Last Name<span className='MuiInputLabel-asterisk'>*</span>
              </label>
            }
            placeholder='Last Name'
            // required={true}
            InputLabelProps={{ focused: true }}
          />
          <InputField {...register('company_name')} className='input-field ' name={'company_name'} label='Company Name' placeholder='Company Name' InputLabelProps={{ focused: true }} />
        </div>
        <div className='d-flex'>
          <InputField
            {...register('title')}
            className='input-field '
            name={'title'}
            label={
              <label>
                Title<span className='MuiInputLabel-asterisk'>*</span>
              </label>
            }
            placeholder='Title'
            // required={true}
            InputLabelProps={{ focused: true }}
          />
          <InputField
            {...register('email')}
            className='input-field'
            type='text'
            // required={true}
            label={
              <label>
                Email<span className='MuiInputLabel-asterisk'>*</span>
              </label>
            }
            placeholder='Email'
            InputLabelProps={{ focused: true }}
            name='email'
          />
          <LocationSelection
            register={register('location')}
            className='input-field'
            name={'location'}
            label='Location'
            InputLabelProps={{ focused: true }}
            onChange={(e, data) => {
              setValue('location', data?.name || '');
            }}
          />
        </div>
        <div className='d-flex'>
          <div className='input-field '>
            <ReactPhoneInput
              {...register('work_phone')}
              placeholder='Phone Number'
              value={watch('work_phone') || ''}
              onChange={(data, c, e, value) => {
                if (data?.length > 1) {
                  setValue('work_phone', value);
                } else {
                  setValue('work_phone', '');
                }
              }}
              enableSearch={true}
              inputProps={{
                label: 'Work Phone',
                InputLabelProps: { focused: true }
              }}
              component={TextField}
            />
          </div>
          <div className='input-field '>
            <ReactPhoneInput
              {...register('mobile_phone')}
              placeholder='Phone Number'
              value={watch('mobile_phone') || ''}
              onChange={(data, c, e, value) => {
                if (data?.length > 1) {
                  setValue('mobile_phone', value);
                } else {
                  setValue('mobile_phone', '');
                }
              }}
              enableSearch={true}
              inputProps={{
                label: 'Mobile Phone',
                InputLabelProps: { focused: true }
              }}
              component={TextField}
            />
          </div>
          <InputField {...register('linkedin_url')} className='input-field' name={'linkedin_url'} label='LinkedIn URL' placeholder='LinkedIn URL' InputLabelProps={{ focused: true }} />
        </div>
        <div className='d-flex'>
          <InputField {...register('twitter_handle')} className='input-field' name={'twitter_handle'} label='Twitter Handle' placeholder='Twitter Handle' InputLabelProps={{ focused: true }} />
          {/* <InputField
            {...register('')}
            className="input-field"
            name={'skype_id'}
            label="Skype ID"
            InputLabelProps={{ focused: true }}
          /> */}
          <KeyboardDatePicker
            className='input-field'
            {...register('birth_date')}
            autoOk
            label='Birth Date'
            variant='inline'
            format='dd/MM/yyyy'
            margin='normal'
            value={watch('birth_date') || null}
            InputLabelProps={{ focused: true }}
            placeholder='dd/mm/yyyy'
            onChange={date => handleChange('birth_date', date)}
          />
          <InputField type='number' {...register('kgp_number')} className='input-field' name={'kgp_number'} label='KGP Number' placeholder='KGP Number' InputLabelProps={{ focused: true }} />
        </div>
        <div className='d-flex'>
          <InputField {...register('extension')} className='input-field' name={'extension'} label='Extension' placeholder='Extension' InputLabelProps={{ focused: true }} />
          <InputField {...register('street')} className='input-field' name={'street'} label='Street' placeholder='Street' InputLabelProps={{ focused: true }} />
          <InputField {...register('city')} className='input-field' name={'city'} label='City' placeholder='City' InputLabelProps={{ focused: true }} />
        </div>
        <div className='d-flex'>
          <CountrySelection
            className='input-field'
            label='Country'
            placeholder='Country'
            InputLabelProps={{ focused: true }}
            onChange={(e, data) => {
              stateChange('');
              setValue('country', data?.name);
            }}
          />
          <StateSelection
            value={watch('state') || null}
            country={country}
            register={register}
            className='input-field'
            onChange={(e, state) => {
              stateChange(state);
            }}
          />
          <InputField {...register('postal_code')} className='input-field' name={'postal_code'} InputLabelProps={{ focused: true }} label='Postal Code' placeholder='Postal Code' />
        </div>
        <div className='d-flex'>
          <DepartmentSelection
            multiple
            disableCloseOnSelect
            size='small'
            filterSelectedOptions={true}
            className='input-field'
            onChange={(e, data) => setValue('departments', Array.isArray(data) ? data.map(item => item.name) : null)}
            InputLabelProps={{ focused: true }}
          />
          <SecondaryDepartmentSelection
            multiple
            disableCloseOnSelect
            size='small'
            filterSelectedOptions={true}
            className='input-field'
            onChange={(e, data) => setValue('secondary_departments', Array.isArray(data) ? data.map(item => item.name) : null)}
            InputLabelProps={{ focused: true }}
          />
          <RegionSelection register={register('region')} className='input-field' name={'region'} size='small' label='Region' InputLabelProps={{ focused: true }} />
        </div>
        <div className='d-flex'>
          <WorkgroupSelection
            register={register('work_group')}
            name={'work_group'}
            onChange={(e, data) => {
              setValue('work_group', data ? data.name : null);
            }}
            className='input-field'
            InputLabelProps={{ focused: true }}
          />
          <InputField
            {...register('revenue_budget')}
            type='number'
            className='input-field'
            name={'revenue_budget'}
            label='Revenue Budget'
            placeholder='Revenue Budget'
            // required={true}
            InputLabelProps={{ focused: true }}
          />
          <InputField type='number' {...register('capacity')} className='input-field' name={'capacity'} label='Capacity' placeholder='Capacity' InputLabelProps={{ focused: true }} />
        </div>
        <div className='d-flex'>
          <Reference
            className='input-field'
            label='Referred By'
            // required={true}
            InputLabelProps={{ focused: true }}
            onChange={(e, data) => setValue('reference', data ? data.id : null)}
          />
          <FormControl className='input-field'>
            <Typography className='only-for-labels'>KGP Employee</Typography>
            <FormControlLabel
              className='checkbox-container'
              control={
                <Checkbox
                  className='checkbox-component'
                  size='small'
                  defaultChecked={watch('kgp_employee')}
                  {...register('kgp_employee')}
                  onChange={data => setValue('kgp_employee', data.target.checked)}
                />
              }
              label={watch('kgp_employee') ? 'Yes' : 'No'}
            />
          </FormControl>
          <div className='input-field'>
            <FormControlLabel
              className='checkbox-container'
              style={{ color: '#5c52c6', margin: '10px 0 0' }}
              control={<Switch className='checkbox-component' color='primary' {...register('is_exclude_from_website')} name={'is_exclude_from_website'} />}
              label='Exclude from Website'
              labelPlacement='start'
            />
          </div>
        </div>
        <div className='d-flex'>
          <WebsiteTagsSelection className='input-field' register={register('website_tag')} name={'website_tag'} InputLabelProps={{ focused: true }} />
          {/* <div style={{
            width: '33%',
            marginTop: '5px',
            position: "relative",
            marginRight: '10px'
          }}> */}
          <div className='text-view-value p-0'>
            <div className='outlined-border rich-text-field transform p-0 ' style={{ marginTop: '-15px' }}>
              {/* <Typography variant="body2" style={{ color: "#5F51CB", position: "absolute", top: "-10px" }}>KGP Bio </Typography> */}
              <InputLabel style={{ color: '#5c52c6' }} className='m-0 p-0'>
                KGP Bio
              </InputLabel>

              <RichTextPopup
                {...register('bio')}
                onChange={data => {
                  setValue('bio', data);
                }}
                title='KGP Bio'
                // placeholder={""}
                name={'bio'}
                InputLabelProps={{ focused: true }}
              />
            </div>
          </div>
          <UserSelection
            multiple
            option={[]}
            InputLabelProps={{ focused: true }}
            filterSelectedOptions={true}
            className='input-field'
            label='Project Managers'
            name='project_managers'
            onChange={(e, data) => {
              setValue('project_managers', Array.isArray(data) ? data.map(data => data.id) : null);
            }}
          />
        </div>
        <div className='d-flex'>
          <KeyboardDatePicker
            className='input-field'
            {...register('hiring_date')}
            autoOk
            label={
              <label>
                Date of Hire<span className='MuiInputLabel-asterisk'>*</span>
              </label>
            }
            // required={true}
            variant='inline'
            format='dd/MM/yyyy'
            margin='normal'
            value={watch('hiring_date') || null}
            InputLabelProps={{ focused: true }}
            placeholder='dd/mm/yyyy'
            onChange={date => handleChange('hiring_date', date)}
          />
          <InputField
            {...register('personal_email')}
            className='input-field'
            type='email'
            label='Personal Email'
            placeholder='Personal Email'
            InputLabelProps={{ focused: true }}
            name='personal_email'
          />
          <TextArea {...register('address')} className='input-field' name={'address'} label='Address' placeholder='Address' InputLabelProps={{ focused: true }} />
        </div>
        <div className='d-flex'>
          <PracticeSelection
            register={register('vertical_practice')}
            className='input-field'
            name={'vertical_practice'}
            label='Vertical Practice'
            InputLabelProps={{ focused: true }}
            type='vertical'
          />
          <PracticeSelection
            register={register('vertical_practice')}
            className='input-field'
            name={'vertical_practice_2'}
            label='Vertical Practice 2'
            InputLabelProps={{ focused: true }}
            type='vertical'
            onChange={(e, data) => setValue('vertical_practice_2', data ? data.name : null)}
          />
          <PracticeSelection
            register={register('horizontal_practice')}
            className='input-field'
            name={'horizontal_practice'}
            label='Horizontal Practice'
            placeholder='Horizontal Practice'
            InputLabelProps={{ focused: true }}
            type='horizontal'
          />
        </div>
        <div className='d-flex'>
          {/* <FormControl className='input-form-field input-field-old'>
            <Typography className='only-for-labels'>
              KGP Employee
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  defaultChecked={watch('kgp_employee')}
                  ref={register('kgp_employee')}
                  onChange={(data) => setValue('kgp_employee', data.target.checked)}
                />
              }
              label={watch('kgp_employee') ? 'Yes' : 'No'}
            />
          </FormControl> */}
          <RoleSelection
            className='input-field'
            label={
              <label>
                Role<span className='MuiInputLabel-asterisk'>*</span>
              </label>
            }
            // required={true}
            InputLabelProps={{ focused: true }}
            onChange={(e, data) => setValue('roles', data)}
          />
          <AppRoleSelection
            multiple
            register={register}
            className='input-field'
            label={<label>App Role</label>}
            list={watch('app_roles')}
            // required={true}
            InputLabelProps={{ focused: true }}
            onChange={(e, data) => {
              setValue('app_roles', data);
            }}
          />
          <div className='input-field'>&nbsp;</div>
        </div>
        <div className='buttons '>
          <Link to='/users' className='secondary-btn cancel-btn'>
            Cancel
          </Link>
          <Button type='submit' color='primary' variant='contained' className='primary-btn create-btn'>
            Create
          </Button>
        </div>
      </form>
    </div>
  );
}

AddUser.propTypes = {
  enqueueSnackbar: PropTypes.func
};

export default AddUser;
