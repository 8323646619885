//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Contacts from './Contacts';

const MyBDTargetsList = () => <Contacts type='my-bd-targets' storageKey='contactMyBDTargetColumns' title='My BD Targets' />;

export default MyBDTargetsList;
