//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { forwardRef, useCallback } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import { ERROR } from '../../services/constantService';
import { useForm } from 'react-hook-form';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const RejectReason = forwardRef(props => {
  const { title = 'Industries', reject, onClose, enqueueSnackbar } = props;
  const { register } = useForm();
  const [open, setOpen] = React.useState(true);
  const [currentValue, setCurrentValue] = React.useState(null);

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose(false);
  }, []);

  const handleSave = () => {
    if (!currentValue) {
      enqueueSnackbar('Please add reason for reject', { variant: ERROR });
    } else {
      reject(currentValue);
      setOpen(false);
      onClose(false);
    }
  };

  return (
    <Dialog className='ag-custom-component-popup' maxWidth='md' onClose={handleClose} disableBackdropClick={true} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers style={{ minWidth: '400px', maxWidth: '400px', maxHeight: 150 }}>
        <TextField
          {...register('reason')}
          className='input-form-field input-field-old'
          name={'reason'}
          label='Reason'
          placeholder='Reason'
          multiline
          onChange={e => {
            setCurrentValue(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSave} color='primary'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

RejectReason.propTypes = {
  title: PropTypes.string,
  enqueueSnackbar: PropTypes.func,
  reject: PropTypes.func,
  onClose: PropTypes.func
};

RejectReason.displayName = 'RejectReason';

export default RejectReason;
