import { Box } from '@mui/material';
import isArray from 'lodash/isArray';
import React from 'react';

import { useTranslation } from 'react-i18next';
import Loader from '../../../components/common/Loader';
import { StageWorkflowApi } from '../../../services/ApiService';
import useApi from '../../../utils/Hooks/useApiHook';
import WorkflowRenderer from './Components/StageWorkflow/WorkflowRenderer';
import './index.scss';

const Workflow = () => {
  const { t } = useTranslation();
  const { data, loading, refetch } = useApi({
    queryKey: 'getAllWorkflow',
    queryFn: async () => {
      return await StageWorkflowApi('get', '/all');
    }
  });

  return (
    <div style={{ minHeight: '75vh', height: '100%', display: 'flex' }}>
      <Box sx={{ display: 'flex', flex: 1 }}>
        {loading ? (
          <Loader loadingMessage={t('stageWorkflow.configFetching')} show={loading} className='loader-override' />
        ) : data && isArray(data.data.data) ? (
          <WorkflowRenderer workflows={data.data.data || []} refetch={refetch} />
        ) : (
          <Box mt={4}>
            <h5>{t('common.somethingWentWrong')}</h5>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Workflow;
