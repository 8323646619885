//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Link } from 'react-router-dom';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { DateRenderer, DateTimeRenderer, EmailRenderer, LinkedInRenderer, LinksRenderer, loadColumnStateFromLocalStorage, saveColumnStateToLocalStorage } from '../../Containers/Commons/Utils';
import { CustomLoadingOverlayComponent, defaultColumns, IdRenderer, PAGE_LIMIT, renderActiveCount, renderTargetCount, SUCCESS_STATUS_CODE } from '../../Containers/Searches/Utils';

import { SCROLL_TIMEOUT } from '../../utils/common';
import CustomFilter from '../../Containers/Commons/CustomFilter';
import ColumnFilter from '../../Containers/Commons/ColumnFilter';
import { searchColumnDefs } from './utils';
import { masterSearchApi } from '../../services/ApiService';
import { CompanyNameRenderer } from '../../Containers/Companies/Utils';

let gridApi, columnApi;
export function Searches(props) {
  const { searchKey, setSearchesCount, height = '100%', minHeight = 180 } = props;
  // const [viewColumns, setViewColumns] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [isGridReady, setIsGridReady] = useState(false);

  const prevSearchKeyRef = useRef(null);

  useEffect(() => {
    return () => {
      const columnApis = columnApi;
      saveColumnStateToLocalStorage('masterSearchSearchesColumns', {
        columnApis
      });
    };
  }, []);

  useEffect(() => {
    if (searchKey !== prevSearchKeyRef.searchKey) {
      gridApi && gridApi.onFilterChanged();
    }
    prevSearchKeyRef.current = searchKey;
  }, [searchKey]);

  const dataSource = {
    getRows: async params => {
      gridApi.showLoadingOverlay();
      const value = encodeURIComponent(searchKey);
      let sub_route = `searches?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}&searchValue=${value}`;
      if (params.sortModel?.length > 0) {
        sub_route = sub_route.concat(`&sortOn=${params.sortModel[0].colId}&sortType=${params.sortModel[0].sort.toUpperCase()}`);
      }
      sub_route = `${sub_route}&mastersearch=true`;
      const { status, data } = await masterSearchApi(sub_route);
      if (status === SUCCESS_STATUS_CODE) {
        params.successCallback(data.data, data.paging?.totalCount);
        // this.setState({ rowCount: data.paging.showTotal ? data.paging.showTotal : data.paging?.totalCount })
        setRowCount(data.paging.showTotal ? data.paging.showTotal : data.paging?.totalCount);
        setSearchesCount(data.paging.showTotal ? data.paging.showTotal : data.paging?.totalCount);
      } else {
        params.failCallback();
      }
      gridApi.hideOverlay();
    },
    rowCount: null
  };

  const NameRenderer = params => {
    return <CompanyNameRenderer company={params?.data?.company} />;
  };

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    // const columnApi = params.columnApi
    params.columnApi.autoSizeAllColumns();
    loadColumnStateFromLocalStorage('masterSearchSearchesColumns', {
      columnApi
    });
    // this.setState({ isGridReady: true });
    setIsGridReady(true);
  };

  const linkedInRenderer = params => {
    return <LinkedInRenderer params={params} />;
  };

  return (
    <div className='list-contacts d-flex flex-column mt-4' style={{ height: rowCount ? height : minHeight }}>
      <div className='list-header d-flex justify-content-between'>
        <Typography className='table-header-text' style={{ paddingLeft: '1.5%' }}>
          <ZoomInIcon style={{ fill: '#DE9F6D' }} />
          Searches
        </Typography>
        <Typography className='total-count' style={{ paddingRight: '1.5%' }}>
          Total count: {rowCount}
        </Typography>
      </div>
      <div className='list-view flex-grow-1'>
        {isGridReady && <ColumnFilter columnApi={columnApi} gridApi={gridApi} defaultColumns={defaultColumns} />}
        <div id='myGrid' className='ag-theme-alpine'>
          <AgGridReact
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            blockLoadDebounceMillis={SCROLL_TIMEOUT}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            frameworkComponents={{
              CustomLoadingOverlayComponent,
              EmailRenderer,
              LinkedInRenderer: linkedInRenderer,
              LinksRenderer,
              IdRenderer,
              DateTimeRenderer,
              NameRenderer: NameRenderer,
              DateRenderer,
              renderActiveCount,
              renderTargetCount,
              CustomFilter
            }}
            suppressMenuHide={true}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            rowModelType={'infinite'}
            datasource={dataSource}
            columnDefs={searchColumnDefs}
            paginationPageSize={PAGE_LIMIT}
            rowSelection={'multiple'}
            suppressRowClickSelection={true}
            suppressDragLeaveHidesColumns={true}
          ></AgGridReact>
          <Link
            to={{
              pathname: '/searches/my-searches',
              state: searchKey
            }}
            style={{ fontSize: '18px', float: 'right' }}
            className='MuiTypography-colorPrimary mt-2'
          >
            More Records&gt;{' '}
          </Link>
        </div>
      </div>
    </div>
  );
}
Searches.propTypes = {
  searchKey: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowHeight: PropTypes.number,
  minHeight: PropTypes.number,
  setSearchesCount: PropTypes.func.isRequired
};

export default Searches;
