//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../common/InputField';
import { fetchPickListData } from '../../actions';

const CurrencySelection = props => {
  const { disabled = false, className, InputLabelProps, required, ...rest } = props;

  const [options, setOptions] = useState([]);
  const currencies = useSelector(state => state.commonReducer.currencies);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currencies) {
      dispatch(fetchPickListData('currencies'));
    } else {
      setOptions(currencies);
    }
  }, [currencies, dispatch]);

  return (
    <Autocomplete
      {...rest}
      popupIcon={<ExpandMoreIcon />}
      options={options}
      color='red'
      getOptionSelected={(option, value) => {
        if (typeof value === 'string') {
          return option.code === value;
        }
        return option.code === value.code;
      }}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        return option.code;
      }}
      disabled={disabled}
      renderInput={params => <InputField {...params} InputLabelProps={InputLabelProps} required={required} variant='outlined' className={className} />}
    />
  );
};

CurrencySelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string
};

export default CurrencySelection;
