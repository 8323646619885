//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Popup from 'reactjs-popup';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import ContactAttachments from './ContactAttachments';
import ContactResume from './ContactResume';
import ContactOfferLetter from './ContactOfferLetter';
import { contactActions } from '../../../store/contactSlice';
import ContactNda from './ContactNda';

const DocsPopup = props => {
  const { enqueueSnackbar } = useSnackbar();
  const { contact, onPopupClose, disabled = false, isResetDocs = false } = props;
  const dispatch = useDispatch();
  const getContactName = () => {
    return `${contact.first_name || ''} ${contact.last_name || ''}`;
  };
  const onClosePopup = () => {
    if (isResetDocs) {
      dispatch(contactActions.resetCandidateDocList());
    }
    onPopupClose();
  };
  return (
    <Popup open={true} className='docs-popup' onClose={onClosePopup} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className='d-flex flex-column popup-height'>
        <div className='d-flex align-items-center justify-content-between popup-header'>
          <div className='d-flex align-items-center color-white'>
            <h4 className='mr-2'>Document List</h4>
            <Typography>{getContactName()}</Typography>
          </div>
          <span className='action-icon cursor-pointer color-white' onClick={onClosePopup}>
            <CloseIcon />
          </span>
        </div>
        <div className='d-flex flex-grow-1 flex-column' style={{ padding: 30, background: 'white', overflow: 'auto' }}>
          <ContactAttachments disabled={disabled} contactId={contact.id} />
          <ContactResume disabled={disabled} contactId={contact.id} />
          <ContactNda disabled={disabled} contactId={contact.id} enqueueSnackbar={enqueueSnackbar} />
          <ContactOfferLetter disabled={disabled} contactId={contact.id} />
        </div>
      </div>
    </Popup>
  );
};

DocsPopup.propTypes = {
  contact: PropTypes.object,
  onPopupClose: PropTypes.func,
  disabled: PropTypes.bool,
  isResetDocs: PropTypes.bool
};

export default DocsPopup;
