import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Avatar, Box } from '@mui/material';

const ProfileLabel = props => {
  const { value, srcUrl } = props;

  return (
    <Box id='profile-label-comp'>
      <Box className='profile-label d-flex justify-content-center align-items-center'>
        <Box className='m-1'>{srcUrl ? <Avatar className='avatar-style' alt='contact' src={srcUrl} /> : <Avatar className='avatar-style' />}</Box>
        <Box component='div' className='profile-label-text fs-14 my-1 mr-1'>{`${value?.first_name} ${value?.last_name}`}</Box>
      </Box>
    </Box>
  );
};

ProfileLabel.propTypes = {
  value: PropTypes.object,
  srcUrl: PropTypes.string
};

export default ProfileLabel;
