import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import propTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ViewContactDrawer from '../../../components/ViewContactDrawer';
import { ROUTES } from '../../../services/constantService';
import { useNavItems } from '../../../utils/Hooks/useNavItems';
import { createEnglishStatements } from '../utils';

const useStyles = makeStyles({
  link: {
    color: '#943f0c',
    textDecoration: 'underline',
    fontStyle: 'italic'
  },
  logItem: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    margin: '1rem 0',
    paddingRight: '.2rem'
  },
  rightText: {
    fontWeight: 'lighter',
    fontStyle: 'italic'
  },
  dialogChildSlider: {
    zIndex: 9999 // Set a higher value for z-index
  }
});

let iconNames = ['searches', 'contacts', 'companies'];

function LogItem({ log, onClick = () => {}, isSelected = false }) {
  const navItems = useNavItems();
  const iconItems = useMemo(() => navItems.filter(item => iconNames.includes(item.key)), [navItems]);

  const getIcon = log => {
    if (log?.contact_id) {
      return iconItems.find(item => item.key === iconNames[1])?.logo;
    } else if (log?.company_id) {
      return iconItems.find(item => item.key === iconNames[3])?.logo;
    } else {
      return iconItems.find(item => item.key === iconNames[0])?.logo;
    }
  };
  const styles = useStyles();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const logOwner = log.updated_user?.name ? log.updated_user : log.created_user;
  const Icon = getIcon(log);
  return (
    <div className={styles.logItem} style={{ fontWeight: isSelected ? 'bold' : 'inherit' }} onClick={() => onClick(log)}>
      {log?.activity_type?.includes('candidate_stage_change') ? (
        <div>
          <span>
            <Icon color={'disabled'} />
          </span>
          <span className={styles.link} onClick={() => setIsDrawerOpen(true)}>{`${log?.ign_contacts?.name ?? 'Unknown user'}`}</span>
          <span>{' moved to'}</span>
          <b>
            <span>{` ${log?.to_stage} `}</span>
          </b>
          <span>on</span>
          <span
            className={styles.link}
            onClick={() => {
              navigate(`${ROUTES.projectDetails}/${log.project_id}`);
            }}
          >{` #${log?.project?.job_number || '0000'}-${log?.project?.name || '.'}`}</span>
        </div>
      ) : log?.activity_type?.includes('project_stage_update') ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-start'
          }}
        >
          {createEnglishStatements([log])?.map((activity, index) => {
            return (
              <div key={index}>
                <span>
                  <Icon color={'disabled'} />
                </span>
                <span className={styles.link} onClick={() => navigate(`${ROUTES.projectDetails}/${activity.project_id}`)}>
                  #{activity.reference}.
                </span>
                <span>{activity.action}</span>
                <span
                  style={{
                    fontWeight: 'bold'
                  }}
                >
                  {' '}
                  {activity.stage_to}
                </span>
              </div>
            );
          })}
        </Box>
      ) : log?.activity_type?.includes('offlimits_override') ? (
        <div>
          <span>
            <Icon color={'disabled'} />
          </span>
          <span>Off-limit(s) overwritten on</span>
          <span
            className={styles.link}
            onClick={() => {
              navigate(`${ROUTES.projectDetails}/${log.project_id}`);
            }}
          >{` #${log?.project?.job_number || '0000'}-${log?.project?.name || '.'}`}</span>
          <span>{` By ${logOwner?.name || 'unknown'}`}</span>
        </div>
      ) : (
        <div>
          <span>
            <Icon color={'disabled'} />
          </span>
          <span>{` ${logOwner?.name ?? 'Unknown user'} logged a ${log?.activity_type} for `}</span>
          <span
            className={styles.link}
            onClick={() => {
              navigate(`${ROUTES.projectDetails}/${log.project_id}`);
            }}
          >{`#${log?.project?.job_number || '0000'}-${log?.project?.name || '.'}`}</span>
        </div>
      )}
      <div className={styles.rightText}>{`on ${moment(log?.created_at).format('DD/MM/YY LT ')} By ${logOwner?.name || 'unknown'}`}</div>
      {isDrawerOpen && <ViewContactDrawer navigateToAllContacts={false} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} id={log?.ign_contacts?.id} />}
    </div>
  );
}

LogItem.propTypes = {
  log: propTypes.object.isRequired,
  onClick: propTypes.func,
  isSelected: propTypes.bool
};

export default LogItem;
