import React, { useState } from 'react';
import CustomDropdown from '../../CustomDropdown';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchPickLists } from '../../../../actions';

const ProjectDocumentTypeSelection = props => {
  const { placeholder, onChange = () => {}, label = '', value = '', required = false } = props;
  const [options, setOptions] = useState([]);
  const projectDocumentType = useSelector(state => state.commonReducer.projectDocumentType);
  const dispatch = useDispatch();
  //key will replaced by document type key
  useEffect(() => {
    if (!projectDocumentType) {
      dispatch(fetchPickLists('PROJECT_DOCUMENT_TYPE', 'projectDocumentType'));
    } else {
      setOptions(projectDocumentType);
    }
  }, [projectDocumentType, dispatch]);

  return (
    <>
      <CustomDropdown required={required} options={options} placeholder={placeholder} onChange={onChange} label={label} defaultValue={value} />
    </>
  );
};
ProjectDocumentTypeSelection.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool
};
export default ProjectDocumentTypeSelection;
