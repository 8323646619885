import { Button, Paper } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickListData } from '../../../../actions';
import { createEducation } from '../../../../services/ApiService';
import CustomMuiDropdown from '../../CustomMuiDropdown';
const LevelOfEducationTypeSelection = props => {
  const { onChange = () => {}, label = '', defaultValue, required = false, ...res } = props;
  const [options, setOptions] = React.useState([]);
  const degrees = useSelector(state => state.commonReducer.degrees);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState({
    title: defaultValue || '',
    value: defaultValue || ''
  });
  const [searchValue, setSearchValue] = React.useState('');
  const [isLoaded, setIsLoaded] = React.useState(false);
  useEffect(() => {
    setValue({
      title: defaultValue || '',
      value: defaultValue || ''
    });
  }, [defaultValue]);
  const getPickListData = async () => {
    dispatch(fetchPickListData('degree-picklist?name=', 'degrees'));
  };
  useEffect(() => {
    if (!degrees) {
      setIsLoaded(true);
      getPickListData();
      setIsLoaded(false);
    } else {
      let array = [];
      degrees.data?.map(data => array.push({ title: data.name, value: data.name }));
      setOptions(array);
    }
  }, [degrees, dispatch]);
  const onAddEducationFunction = async value => {
    setIsLoaded(true);
    if (value === '' || value === null || options.find(option => option.title === value)) {
      setIsLoaded(false);
      enqueueSnackbar(`'${value}' education already exists`, { variant: 'error' });
      return;
    }
    const { data } = await createEducation({ name: value });
    await getPickListData();
    setValue({
      title: data?.name,
      value: data?.name
    });
    onChange(null, {
      title: data?.name,
      value: data?.name
    });
    setIsLoaded(false);
  };

  const PaperComponent = props => {
    return (
      <Paper {...props}>
        {props.children}
        <Button onMouseDown={() => onAddEducationFunction(searchValue)} fullWidth variant='text'>
          + Add Education
        </Button>
      </Paper>
    );
  };
  PaperComponent.propTypes = {
    children: PropTypes.node
  };

  return (
    <CustomMuiDropdown
      isLoading={isLoaded}
      label={label}
      options={options} // options in the form of {title: ..., value: ...}
      value={value}
      getOptionLabel={option => option.title} // Display the title field in the dropdown
      onChange={(e, d) => {
        onChange(e, d);
        setValue(d);
      }}
      name={label}
      autoCompleteProps={{
        disableCloseOnSelect: true,
        // fullWidth: true,
        // eslint-disable-next-line no-use-before-define
        PaperComponent: PaperComponent
      }}
      textFieldProps={{
        // fullWidth: true,
        // required: required,
        value: searchValue,
        onChange: e => {
          setSearchValue(e.target.value);
        }
      }}
      {...res}
    />
  );
};

LevelOfEducationTypeSelection.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool
};

export default LevelOfEducationTypeSelection;
