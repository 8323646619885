//-----------------------------------------------------------// External Imports // ------------------------------

import { notesAttachmentDocumentApi } from './ApiService';
import { getAccessToken } from './cognitoService';
import { COMPANY_DOCUMENT_DOWNLOAD_API, DELETE, GET, POST, PUT } from './constantService';

export const add = async (id, attachment, path = 'company', type = 'attachments', options) => {
  const url = `/api/${path}/${id}/${type}`;
  return await notesAttachmentDocumentApi(POST, url, attachment, options);
};

export const getAll = async (id, path = 'company', type = 'attachments') => {
  const url = `/api/${path}/${id}/${type}`;
  return await notesAttachmentDocumentApi(GET, url);
};

export const update = async (attachmentId, data, path = 'company', type = 'attachments') => {
  const url = `/api/${path}/${type}/${attachmentId}`;
  return await notesAttachmentDocumentApi(PUT, url, data);
};

export const remove = async (attachmentId, path = 'company', type = 'attachments') => {
  const url = `/api/${path}/${type}/${attachmentId}`;
  return await notesAttachmentDocumentApi(DELETE, url);
};

export const download = async (attachmentId, path = 'company', type = 'attachments') => {
  const token = await getAccessToken();
  return `/api/${path}/${type}/${attachmentId}/download?token=${token}`;
};

//----------------------------ING--------------------------------------

export const documentDownload = async attachmentId => {
  const token = await getAccessToken();

  return `api/v1/contact/attachments/${attachmentId}/download?token=${token}`;
};
export const downloadCompanyDocuments = async attachmentId => {
  return COMPANY_DOCUMENT_DOWNLOAD_API.replace(':attachmentId', attachmentId);
};
export const downloadProjectDocuments = async attachmentId => {
  const token = await getAccessToken();
  return `api/v1/project/documents/${attachmentId}/download?token=${token}`;
};
