import { Box, FormControl, InputLabel, MenuItem, Select, Switch, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CustomInputField from '../../../components/common/StyledComponents/CustomInputField';
import PropTypes from 'prop-types';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import { getLangCodes } from '../../../utils/common';

const TranslateForm = props => {
  const { watch, unregister, register, setValue, data = {}, isEdit = false } = props;

  const { t } = useLanguageHooks([
    TRANS_KEYS.TRANSLATE_VALUE_FIELD_NAME,
    TRANS_KEYS.TRANSLATE_VALUE_FIELD_VALUE,
    TRANS_KEYS.TRANSLATE_VALUE_LANG_CD,
    TRANS_KEYS.TRANSLATE_VALUE_SHORT_DESC,
    TRANS_KEYS.TRANSLATE_VALUE_LONG_DESC
  ]);

  useEffect(() => {
    register('id');
    register('field_name');
    register('field_value');
    register('lang_cd');
    register('short_desc');
    register('long_desc');
    register('is_active');

    setValue('id', isEdit ? data?.id : undefined);
    setValue('field_name', isEdit ? data?.field_name : '');
    setValue('field_value', isEdit ? data?.field_value : '');
    setValue('lang_cd', isEdit ? data?.lang_cd : 'ENG');
    setValue('short_desc', isEdit ? data?.short_desc : '');
    setValue('long_desc', isEdit ? data?.long_desc : '');
    setValue('is_active', isEdit ? data?.is_active : true);
    return () => {
      unregister('id');
      unregister('field_name');
      unregister('field_value');
      unregister('lang_cd');
      unregister('short_desc');
      unregister('long_desc');
      unregister('is_active');
    };
  }, [register, unregister]);

  return (
    <Box paddingX={2} paddingTop={2}>
      <Box width={'50vw'} display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={4}>
        <Box>
          <CustomInputField
            required={true}
            defaultValue={watch('field_name') || data?.field_name}
            label={t(`${TRANS_KEYS.TRANSLATE_VALUE_FIELD_NAME}:key`)}
            onChange={e => {
              setValue('field_name', e.target.value);
            }}
          />
        </Box>

        <Box>
          <CustomInputField
            required={true}
            defaultValue={watch('field_value') || data?.field_value}
            label={t(`${TRANS_KEYS.TRANSLATE_VALUE_FIELD_VALUE}:key`)}
            onChange={e => {
              setValue('field_value', e.target.value);
            }}
          />
        </Box>

        <FormControl sx={{ m: 0, minWidth: '100%' }} size='small'>
          <InputLabel id='demo-select-small-label'>{t(`${TRANS_KEYS.TRANSLATE_VALUE_LANG_CD}:key`)}</InputLabel>
          <Select
            labelId='demo-select-small-label'
            id='demo-select-small'
            value={watch('lang_cd') || data?.lang_cd || 'ENG'}
            label={t(`${TRANS_KEYS.TRANSLATE_VALUE_LANG_CD}:key`)}
            onChange={e => {
              setValue('lang_cd', e.target.value);
            }}
          >
            {getLangCodes().map(lang => {
              return (
                <MenuItem key={lang} value={lang}>
                  {lang}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <Box>
          <CustomInputField
            defaultValue={watch('short_desc') || data?.short_desc}
            label={t(`${TRANS_KEYS.TRANSLATE_VALUE_SHORT_DESC}:key`)}
            onChange={e => {
              setValue('short_desc', e.target.value);
            }}
          />
        </Box>
      </Box>
      {isEdit && (
        <Box className='mt-6'>
          <Box style={{ fontSize: '14px' }} className='text-bold'>
            {t(`${TRANS_KEYS.TRANSLATE_VALUE_ACTIVE}:key`)}
          </Box>
          <Switch onChange={e => setValue('is_active', e.target.checked)} defaultChecked={watch('is_active') || data?.is_active} size='large' />
        </Box>
      )}

      <Box marginTop={2}>
        <Typography style={{ fontSize: '12px', marginBottom: '0.2rem' }}>{t(`${TRANS_KEYS.TRANSLATE_VALUE_LONG_DESC}:key`)}</Typography>
        <CustomInputField
          defaultValue={watch('long_desc') || data?.long_desc}
          label={''}
          onChange={e => {
            setValue('long_desc', e.target.value);
          }}
          minRows={4}
          multiline
        />
      </Box>
    </Box>
  );
};
TranslateForm.propTypes = {
  watch: PropTypes.func,
  unregister: PropTypes.func,
  register: PropTypes.func,
  setValue: PropTypes.func,
  data: PropTypes.object,
  isEdit: PropTypes.bool
};
export default TranslateForm;
