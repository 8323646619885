import { Stack } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { queryContactColumn } from '../Grid/QueryData';

export const QueryContactGrid = ({ data }) => {
  const { queryDetails, queryResponse } = data;
  const columns = useMemo(() => queryContactColumn.map(col => col), []);

  const onGridReady = params => {
    params.columnApi.autoSizeColumns();
  };

  const capitalizeProperCase = string => {
    if (!string) return string;
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const rowData = useMemo(
    () =>
      queryResponse.map(query => ({
        contact_name: capitalizeProperCase(query?.name),
        company_name: query?.contact_company?.name || query?.contact_company?.[0]?.name,
        location: query?.contact_address?.city || query?.contact_address?.[0]?.city,
        job_title: query?.current_job_title,
        education: query?.contact_education_details?.degree_name || query?.contact_education_details?.map(contactEducation => contactEducation?.degree_name).join(', '),
        certifications: query?.contact_certificate?.certifications || query?.contact_certificate?.map(contactCertificate => contactCertificate?.certifications).join(', ')
      })),
    [data]
  );
  return (
    <Stack spacing={2} style={{ height: '500px', width: '100%' }}>
      <div className='ag-theme-alpine'>
        <AgGridReact
          rowData={rowData}
          columnDefs={columns}
          suppressRowClickSelection={true}
          paginationPageSize={10}
          rowSelection='multiple'
          enableRangeSelection={true}
          defaultColDef={{
            resizable: true,
            filter: true,
            sortable: true
          }}
          onGridReady={onGridReady}
        />
      </div>
    </Stack>
  );
};

QueryContactGrid.propTypes = {
  data: PropTypes.shape({
    queryDetails: PropTypes.string,
    queryResponse: PropTypes.array
  }).isRequired
};
