import { Box } from '@mui/material';
import React from 'react';
import BrandLogoDark from '../../components/Header/BrandLogoDark';
import './HeaderComponent.scss';
export default function HeaderComponent() {
  return (
    <Box className='header-component p-8 position-fixed w-100 top-0'>
      <BrandLogoDark />
    </Box>
  );
}
