import CloseIcon from '@mui/icons-material/Close';
import { Box, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { companyRelationshipApi, picklistsDataApis } from '../../../../services/ApiService';
import { GET, POST, ROUTES } from '../../../../services/constantService';
import useApi from '../../../../utils/Hooks/useApiHook';
import { getCompanyOffLimit } from '../../../../utils/Hooks/useOfflimit/useOfflimitHelpers';
import CustomDropdown from '../../../common/CustomDropdown';
import CustomTable from '../../../common/CustomTable';
import CompanySelection from '../../../common/FunctionalComponents/CompanySelection';
import { OffLimitsEntryLogo } from '../../../common/ShowOffLimits/OffLimitsEntry';
import CustomInputField from '../../../common/StyledComponents/CustomInputField';

const OffLimitIcon = ({ companyId }) => {
  const [offLimitResponse, setOffLimitResponse] = useState(false);
  const asyncUseEffect = async () => {
    const res = await getCompanyOffLimit(companyId);
    if (res.length > 0) {
      setOffLimitResponse(res[0]);
    }
  };
  useEffect(() => {
    asyncUseEffect();
  }, [companyId]);

  return <>{offLimitResponse ? <OffLimitsEntryLogo severity={offLimitResponse?.severity} /> : <>-</>}</>;
};

OffLimitIcon.propTypes = {
  companyId: PropTypes.string
};

const ViewAssociatedCompanies = props => {
  const { updateField, register, unregister, setValue, detailsData } = props;
  const [relationship, setRelationship] = useState([]);
  const [isEditing, setIsEditing] = useState(true);

  const COMPANY_TYPES = {
    PARENT: 'Parent',
    CHILD: 'Child',
    AFFILIATE: 'Affiliate'
  };
  useEffect(() => {
    register('company_associated');
    return () => {
      unregister('company_associated');
    };
  }, [register, unregister]);
  useEffect(() => {
    setValue('company_associated', relationship);
  }, [relationship]);
  useEffect(() => {
    if (detailsData && detailsData?.company_associated) {
      const companyAssociated = detailsData?.company_associated;
      if (companyAssociated.length) {
        setRelationship(companyAssociated);
      }
    }
  }, [detailsData]);
  const { data: relationTypeOptions } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, 'COMPANY_RELATIONSHIP');
    }
  });

  const { data: parentCompanyType } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, 'PARENT_COMPANY_TYPE');
    }
  });
  const { data: childCompanyType } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, 'CHILD_COMPANY_TYPE');
    }
  });
  const { data: affiliateCompanyType } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, 'AFFILIATE_COMPANY_TYPE');
    }
  });

  const handleAdd = () => {
    setRelationship([...relationship, { relationship: '', related_company: {}, relation_type: {}, comment: '', new: true }]);
  };

  const handleEdit = value => {
    setIsEditing(value);
  };

  const handleDeleteRelationRequest = async data => {
    const { related_company } = data;
    const payload = {
      company_id: detailsData.id,
      related_company_id: related_company.id
    };

    await companyRelationshipApi(POST, '', payload, 'delete');
  };

  const handleRemoveRelation = index => {
    const updatedRelation = [...relationship];
    const deletingElement = relationship[index];

    if (deletingElement?.id && deletingElement?.id.length > 0) {
      handleDeleteRelationRequest(deletingElement);
    }
    updatedRelation.splice(index, 1);
    setRelationship(updatedRelation);
  };

  const handleChangeRelationship = (index, field, value) => {
    relationship[index][field] = value;
    relationship[index] = { ...relationship[index], isUpdated: true };
    setRelationship(relationship.slice());
  };

  return (
    <div id='view-company' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className='details-container'>
        <Box className='details-container-subtab scroll-content'>
          <Box className='section-details m-2'>
            <Box className='communication-section'>
              <Box className='head-container'>
                <Box className='d-flex sub-head-content'>
                  <Box className='field-label-input'>
                    <CustomTable
                      headerData={['Related Company', 'Off limit', 'Relationship', 'Type', 'Comments']}
                      title={'Associated Company'}
                      handleAnotherRow={handleAdd}
                      rows={relationship}
                      disable={3}
                      isEditing={isEditing}
                      showAddAnotherButton={false}
                      onEditChange={handleEdit}
                      customTableId={'view-contact-content'}
                      headerSize={'fs-12'}
                      titleSize={'fs-13'}
                      isDrawer={true}
                      updateField={updateField}
                      //dataList={phoneList}
                      // handleCloseClick={handleCloseClick}
                      saveDataKey={'company_associated'}
                    >
                      {isEditing ? (
                        relationship?.length === 0 ? (
                          <TableRow>
                            <TableCell align='center' className='p-2'>
                              <Box className='fs-11'>-</Box>
                            </TableCell>
                            <TableCell align='center' className='p-2'>
                              <Box className='fs-11'>-</Box>
                            </TableCell>
                            <TableCell align='center' className='p-2'>
                              <Box className='fs-11'>-</Box>
                            </TableCell>
                            <TableCell align='center' className='p-2'>
                              <Box className='fs-11'>-</Box>
                            </TableCell>
                            <TableCell align='center' className='p-2'>
                              <Box className='fs-11'>-</Box>
                            </TableCell>
                          </TableRow>
                        ) : (
                          relationship?.map((relation, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'>
                                    <Link
                                      style={{
                                        color: 'black'
                                      }}
                                      to={`${ROUTES.allCompanies}/${relation?.related_company?.id}`}
                                    >
                                      {relation?.related_company?.name || '-'}
                                    </Link>
                                  </Box>
                                </TableCell>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'>
                                    {' '}
                                    <OffLimitIcon companyId={relation?.related_company?.id} />
                                  </Box>
                                </TableCell>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'>{relation?.relation_type_desc?.short_desc || '-'}</Box>
                                </TableCell>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'>{relation?.relationship || '-'}</Box>
                                </TableCell>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'>{relation?.comment || '-'}</Box>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )
                      ) : (
                        relationship?.map((relation, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell className='typo-box p-2' align='center'>
                                <CompanySelection
                                  isDrawer={true}
                                  value={relation?.related_company?.name}
                                  onChange={(e, data) => {
                                    handleChangeRelationship(index, 'related_company', data ? data : '');
                                  }}
                                />
                              </TableCell>
                              <TableCell align='center' className='p-2'>
                                <Box className='fs-11'>-</Box>
                              </TableCell>
                              <TableCell className='typo-box p-2' align='center'>
                                <CustomDropdown
                                  options={relationTypeOptions?.data ? relationTypeOptions?.data : []}
                                  value={relation?.relation_type_desc?.short_desc}
                                  onChange={(e, value) => handleChangeRelationship(index, 'relation_type_desc', value ? value : '')}
                                />
                              </TableCell>
                              <TableCell className='typo-box p-2' align='center'>
                                {relation?.relation_type_desc?.short_desc === COMPANY_TYPES.PARENT && (
                                  <CustomDropdown
                                    options={parentCompanyType?.data}
                                    value={relation?.relationship || ''}
                                    onChange={(e, value) => handleChangeRelationship(index, 'relationship', value?.short_desc)}
                                  />
                                )}
                                {relation?.relation_type_desc?.short_desc === COMPANY_TYPES.CHILD && (
                                  <CustomDropdown
                                    options={childCompanyType?.data}
                                    value={relation?.relationship || ''}
                                    onChange={(e, value) => handleChangeRelationship(index, 'relationship', value?.short_desc)}
                                  />
                                )}
                                {relation?.relation_type_desc?.short_desc === COMPANY_TYPES.AFFILIATE && (
                                  <CustomDropdown
                                    options={affiliateCompanyType?.data}
                                    value={relation?.relationship || ''}
                                    onChange={(e, value) => handleChangeRelationship(index, 'relationship', value?.short_desc)}
                                  />
                                )}
                              </TableCell>
                              <TableCell className='typo-box p-2' align='center'>
                                <CustomInputField value={relation?.comment || ''} onChange={e => handleChangeRelationship(index, 'comment', e.target.value)} />
                              </TableCell>

                              {relationship?.length > 0 && (
                                <TableCell align='center' className='remove-another-button p-2' mt={2}>
                                  <CloseIcon onClick={() => handleRemoveRelation(index)} className='table-close-icon' />
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })
                      )}
                    </CustomTable>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
ViewAssociatedCompanies.propTypes = {
  data: PropTypes.func,
  updateField: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  detailsData: PropTypes.func,
  companyData: PropTypes.object
};

export default ViewAssociatedCompanies;
