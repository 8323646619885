/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import CustomButton from '../../../../components/common/CustomButton';
import CustomMuiDropdown from '../../../../components/common/CustomMuiDropdown';
import { userRolesApi } from '../../../../services/ApiService';
import { PUT } from '../../../../services/constantService';

export default function RoleAutocomplete({ roles, userResourcesData, userResourcesRefetch, currentUser, userRolesData, refetchUserRoles }) {
  if (roles.error || userResourcesData.error) return null;

  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    setSelectedRoles(
      roles.filter(role => {
        return userRolesData.some(userResource => {
          return userResource.role_id === role.id;
        });
      })
    );
  }, [roles, userResourcesData, currentUser, userRolesData]);

  const upsertUserRoles = async () => {
    await userRolesApi(PUT, 'upsert', {
      roles: selectedRoles,
      userId: currentUser.id
    });
  };

  return (
    <>
      <CustomMuiDropdown
        label='Roles'
        options={roles || []}
        getOptionLabel={option => option.name}
        multiple={true}
        value={selectedRoles}
        autoCompleteProps={{
          size: 'small',
          sx: {
            width: '90%'
          },
          multiple: true,
          disableCloseOnSelect: true
        }}
        onChange={(event, newValue) => {
          setSelectedRoles(newValue);
        }}
      />
      <CustomButton
        buttonText='Assign Role'
        size='small'
        onClick={() => {
          upsertUserRoles().then(() => {
            userResourcesRefetch();
            refetchUserRoles();
          });
        }}
      />
    </>
  );
}

RoleAutocomplete.propTypes = {
  roles: PropTypes.array,
  userResourcesData: PropTypes.array,
  userResourcesRefetch: PropTypes.func,
  currentUser: PropTypes.object,
  userRolesData: PropTypes.array,
  refetchUserRoles: PropTypes.func
};
