//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Button, Checkbox, Typography, FormControlLabel } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';

import { saveAs } from 'file-saver';
// import { withRouter } from "react-router-dom";
import withRouter from '../../../../utils/withRouter';
import { connect, useDispatch } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import {
  addAsMyBDTarget,
  columnWorkbenchDefs,
  ContactNameRenderer,
  CustomLoadingOverlayComponent,
  defaultColumns,
  EmailRenderer,
  getFilterParamString,
  PAGE_LIMIT,
  PhoneRenderer,
  ResumeRenderer,
  SUCCESS_STATUS_CODE
  /*   acquire as acquireUtil, */
} from '../../../../Containers/Contacts/utils';
import ActionsPopover from '../../../../Containers/Contacts/ActionsPopover';
import { API, EXPORT_CONTACT_LIMIT, PUT, POST, BLOB, GET, ERROR, WARNING } from '../../../../services/constantService';
import { DateTimeRenderer, DateRenderer, LinkedInRenderer, loadColumnStateFromLocalStorage, removeContactsFromWorkbench, saveColumnStateToLocalStorage } from '../../../../Containers/Commons/Utils';
import ActivityHistories from '../../../ActivityLog/Components/GeneralInfo/Components/ActivityHistory/ActivityHistories';
import CopyCandidatesToSearch from '../../../../Containers/Searches/AllCandidates/CopyCandidatesToSearch';
import ColumnFilter from '../../../../Containers/Commons/ColumnFilter';
import GenericCellEditor from '../../../../Containers/Commons/CellEditors/GenericCellEditor';
import { CompensationRenderer } from '../../../../utils/common';
import Header from '../Header';
import QuickAddContact from '../../../../Containers/Searches/AllCandidates/Components/QuickAddContact';
import AddContactToWorkbench from '../AddContactToWorkbench';
import ConfirmationPopup from '../../../ConfirmationPopup';
import Loader from '../../../common/Loader';
import AddContactsToWorkbench from '../../../../Containers/Commons/AddContactToWorkbench';
import CustomFilter from '../../../../Containers/Commons/CustomFilter';
import ColorLegend from '../../../../Containers/Commons/ColorLegend';
import PopoverStickOnHover from '../../../common/PopoverStickOnHover';
import AthenaPopover from '../../../../../src/Containers/Searches/AllCandidates/Components/AthenaPopover';
import { formatDate } from '../../../../../src/utils/date';
import AthenaInvitePopUpContact from '../../../ViewSearch/Components/SFPA/Components/ClientAppAccess/AthenaInvitePopUpContact';
import LinkedInDialog from '../../../../Containers/Commons/LinkedInDialog';
import { dataSourceUtils } from '../../../../utils/dataSource';
import { acquireLockApi, picklistsColorsDataApi, releaseLockApi, workbenchDataApi } from '../../../../services/ApiService';
import { unableMessage, exportMessage, notFoundMessage, VALIDATION_MESSAGE } from '../../../../services/MessageService';
import { pickListActions } from '../../../../store/pickListSlice';
import EmploymentHistoryConfirmationDialog from '../../../../Containers/Commons/EmploymentHistoryConfirmationDialog';
import { useSnackbar } from 'notistack';
import { CompanyNameRenderer } from '../../../../Containers/Companies/Utils';

let columnApi, gridApi;
const Contacts = props => {
  const [rowCount, setRowCount] = useState(0);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isQuickAddOpen, setIsQuickAddOpen] = useState(false);
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [isAthenaPopupOpen, setIsAthenaPopupOpen] = useState(0);
  const [employmentHistoryMessageEndYearDate, setEmploymentHistoryMessageEndYearDate] = useState(null);
  const [filterModels, setFilterModels] = useState({});
  const [colorData, setColorData] = useState(null);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [isAddToSearchOpen, setIsAddToSearchOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [setSkipCheckJobTitle] = useState('');
  const [linkedPopup, setLinkedPopup] = useState(false);
  const [linkedInValue, setLinkedInValue] = useState(null);
  const [setIsUpdateEmploymentHistory] = useState(false);
  const [isRemoveConfirmationOpen, setIsRemoveConfirmationOpen] = useState(false);
  const [isCopyToWorkbenchOpen, setIsCopyToWorkbenchOpen] = useState(false);
  const [athenaData] = useState({});
  const [isAddToWorkbenchOpen, setIsAddToWorkbenchOpen] = useState(false);
  const [sortModel, setSortModel] = useState({});
  const [contactIds, setContactIds] = useState(null);
  const [showJobTitleChangedPopup, setShowJobTitleChangedPopup] = useState(false);
  const [isGridReady, setIsGridReady] = useState(false);
  const [activeContact, setActiveContact] = useState(false);
  const [, /* newContactData */ setNewContactData] = useState(null);
  const [currentContact, setCurrentContact] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  //const userData = useSelector((state) => state.commonReducer.userData);
  // const color_pick_list = useSelector(
  //   (state) => state.pickListReducer.color_pick_list
  // );
  const { workbench, id, reloadData, option, isLockedWorkbench, getTotalCount, color_pick_list, userData } = props;

  const dataSource = {
    getRows: async params => {
      setShowFilterCount(Object.keys(filterModels)?.length);
      try {
        const getURLs = {
          listURl: `${API.workbench}/${id}/contacts`
        };
        const thisValue = {
          location
          //   isAdvanceQuery,
          //   advanceSelector,
          //   isClassicQuery,
          //   quickQuery,
          //  isSelectedFromList,
        };
        // const thisValue = {

        //   location,

        //   // isClassicQuery,

        //   // quickQuery,

        //   // isAdvanceQuery,

        //   // searchStatus,

        //   /* queryData, */

        //   // isSelectedFromList,

        //   // advanceSelector,

        // };
        gridApi.showLoadingOverlay();
        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: getURLs,
          subScreen: true
        };

        const { status, data } = await dataSourceUtils(obj, getFilterParamString);
        if (status === SUCCESS_STATUS_CODE) {
          if (data?.paging?.totalCount === 0) {
            const message = notFoundMessage('records');
            enqueueSnackbar(message, { variant: WARNING });
          }
          setRowCount(data.paging?.totalCount);
          params.successCallback(data.data, data.paging?.totalCount);
          getTotalCount(data.paging?.totalCount);
          isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
        } else {
          params.failCallback();
        }
        gridApi.hideOverlay();
      } catch (e) {
        console.log('Error found in getRows::', e);
      }
    },
    rowCount: null
  };

  const setSelectAllRows = isAllRowsSelected => {
    setIsAllRowsSelected(isAllRowsSelected);
    gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };

  const handleAthenaPopupClose = () => {
    setIsAthenaPopupOpen(false);
    gridApi.onFilterChanged();
  };

  const linkedInPopupHandler = props => {
    setLinkedPopup(true);
    setLinkedInValue(props.value);
  };

  const LinkedInRenderers = params => {
    return <LinkedInRenderer params={params} linkedInPopup={linkedInPopupHandler} />;
  };

  const AthenaRenderer = params => {
    const handleInvite = () => {
      setIsAthenaPopupOpen(true);
      // setAthenaData(candidate = { id: params.data?.candidates }, contact = params.data)
    };
    if (params.data) {
      if (params.data.athena_completion_date || params.data.athena_invitation_sent_on) {
        return (
          <AthenaPopover contactDetails={params.data} handleInvite={handleInvite}>
            <Typography style={{ fontSize: 'inherit', cursor: 'pointer' }} color='primary'>
              {params.data.athena_completion_date ? `Completed on: ${formatDate(params.data.athena_completion_date)}` : `Invited on: ${formatDate(params.data.athena_invitation_sent_on)}`}
            </Typography>
          </AthenaPopover>
        );
      } else if (params.data && params.data.candidates && params.data.candidates?.length > 0) {
        return (
          <Button className='invite-button athena-button' variant='contained' color='primary' onClick={handleInvite}>
            Invite to Athena
          </Button>
        );
      } else {
        return (
          <Typography style={{ fontSize: 'inherit', cursor: 'pointer' }} color='primary'>
            Contact Is Not Associated With Any Search
          </Typography>
        );
      }
    }
    return null;
  };
  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    setIsGridReady(true);
  };

  const NameRenderer = params => {
    return <ContactNameRenderer params={params} handleAddToSearch={handleAddToSearch} />;
  };

  const AddContactToRenderer = params => {
    const list = [
      {
        label: 'To Search',
        onClick: () => handleAddToSearch(params?.data?.id)
      },
      {
        label: 'As BD Target',
        onClick: () => addAsMyBDTarget(params?.data, userData, gridApi, enqueueSnackbar)
      }
    ];
    return params.data ? <ActionsPopover list={list} /> : null;
  };

  const handleAddToSearch = contactId => {
    setIsAddToSearchOpen(true);
    setContactIds([contactId]);
  };

  const handleAddToSearchClose = () => {
    setIsAddToSearchOpen(false);
    setContactIds([]);
    gridApi.onFilterChanged();
  };

  const showContactActivityHistory = contact => {
    setActiveContact(contact);
  };

  const handleQuickAddContact = () => {
    setIsQuickAddOpen(true);
  };

  const addContactsToWorkbench = async contacts => {
    try {
      setIsLoading(true);
      const status = await addContactsToWorkbench(id, contacts, enqueueSnackbar);
      setIsLoading(false);
      return status;
    } catch (e) {
      console.log('Error found in addContactsToWorkbench::', e);
    }
  };

  const handleQuickAddContactClose = async (contact, addToWorkbench) => {
    setIsQuickAddOpen(false);
    if (addToWorkbench && contact) {
      const success = await addContactsToWorkbench([contact.id]);
      if (success) {
        gridApi.onFilterChanged();
      }
    }
  };

  const CompanyNameRenderers = params => {
    return <CompanyNameRenderer company={params?.data?.company} />;
  };

  // onFirstDataRendered = (params) => {
  //   const columnApi = params.columnApi
  //   const gridApi = params.api
  //   loadColumnStateFromLocalStorage('workbenchContactsColumns', { columnApi, gridApi })
  //   const locationState = props.location.state
  //   if (locationState) {
  //     const sortModel = locationState.sortModel
  //     if (sortModel) {
  //       gridApi.setSortModel(sortModel);
  //     }

  //     const filterModel = locationState.filterModel
  //     if (filterModel) {
  //       gridApi.setFilterModel(filterModel);
  //     }
  //   }
  // }

  const onFirstDataRendered = params => {
    const columnApi = params.columnApi;
    const gridApi = params.api;
    loadColumnStateFromLocalStorage('workbenchContactsColumns', {
      columnApi,
      gridApi
    });

    const locationState = location.state;
    if (locationState) {
      const sortModel = locationState.sortModel;
      if (sortModel) {
        gridApi.setSortModel(sortModel);
      }

      const filterModel = locationState.filterModel;
      if (filterModel) {
        gridApi.setFilterModel(filterModel);
      }
    }
  };

  const saveColumnState = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage('workbenchContactsColumns', {
        columnApi: columnApis,
        gridApi: gridApis
      });
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForFilter = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage('workbenchContactsColumns', { columnApi: columnApis, gridApi: gridApis }, true, false);
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const saveColumnStateForSort = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage('workbenchContactsColumns', { columnApi: columnApis, gridApi: gridApis }, false, true);
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log('Error found in saveColumnState::', e);
    }
  };

  const handleAddToWorkbench = () => {
    setIsAddToWorkbenchOpen(true);
  };

  const handleAddToWorkbenchClose = added => {
    if (added) {
      gridApi.onFilterChanged();
    }
    setIsAddToWorkbenchOpen(false);
  };

  const getSelectedContactIds = () => {
    const contactIds = [];
    gridApi.forEachNode(node => {
      if (node.selected) {
        contactIds.push(node.data.id);
      }
    });
    return contactIds;
  };

  const dispatchColorPickList = data => {
    props.dispatch(pickListActions.updateColorPickList(data));
  };

  // componentDidMount = async () => {
  //   const sub_route = '?type=workbench'
  //   if (props && !props.color_pick_list) {
  //     const { status, data } = await picklistsColorsDataApi(GET, props.id, '', sub_route, dispatchColorPickList);
  //     if (status === 200 && data !== null) {
  //       setState({ colorData: data });
  //     } else if (status === 200) {
  //       const message = notFoundMessage("color details")
  //      enqueueSnackbar(message, { variant: ERROR });
  //     } else {
  //       const message = unableMessage("color details", "fetch")
  //      enqueueSnackbar(data?.message || message, { variant: ERROR });
  //     }
  //   }
  //   else {
  //     setState({ colorData: props.color_pick_list });
  //   }
  // }

  useEffect(() => {
    async function fetchData() {
      const sub_route = '?type=workbench';
      if (!color_pick_list) {
        const { status, data } = await picklistsColorsDataApi(GET, id, '', sub_route, dispatchColorPickList);
        if (status === 200 && data !== null) {
          setColorData(data);
        } else if (status === 200) {
          const message = notFoundMessage('color details');
          enqueueSnackbar(message, { variant: ERROR });
        } else {
          const message = unableMessage('color details', 'fetch');
          enqueueSnackbar(data?.message || message, { variant: ERROR });
        }
      } else {
        setColorData(color_pick_list);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(pickListActions.updateColorPickList(colorData));
    };
  }, []);

  const setColorDesc = (event, color, index) => {
    if (event.target.value?.length <= 20) {
      let newArr = [...colorData];
      newArr = newArr.map((item, i) => (i === index ? { ...item, desc: event.target.value } : { ...item }));
      setColorData(newArr);
      const data = {
        data: {
          workbench_id: props.id,
          colorId: color.id,
          desc: event.target.value
        }
      };

      setTimeout(async () => {
        const sub_route = '?type=workbench';
        await picklistsColorsDataApi(PUT, props.id, data, sub_route);
      }, 500);
    }
  };

  const getColorByValue = value => {
    return colorData?.find(item => item.value === Number(value)) || {};
  };

  const RenderColorsList = params => {
    const handleChange = async event => {
      const color = event.target.name;
      const data = { contact_color: color, contactId: params.data?.id };
      const sub_route = 'workbench-contact';
      setIsLoading(true);
      const { status } = await workbenchDataApi(PUT, props.id, data, sub_route);
      if (status === 200) {
        setIsLoading(false);
        params.node.setDataValue('contact_color', color);
      } else {
        const message = unableMessage('candidate', 'update');
        enqueueSnackbar(message, { variant: ERROR });
      }
    };

    return (
      <div className='d-flex colors-container justify-content-between'>
        {colorData?.map(item => {
          return (
            <div
              key={item.hex_code}
              className='color-code-container d-flex'
              style={{
                background: `#${item.hex_code}`,
                color: `#${item.hex_code}`
              }}
            >
              <FormControlLabel
                style={{ margin: 0 }}
                control={<Checkbox style={{ padding: 0, width: 16, height: 16 }} color='default' checked={params.value === item.value} onChange={handleChange} name={item.value} />}
                label=''
              />
            </div>
          );
        })}
      </div>
    );
  };

  const ColorRenderer = params => {
    let color;
    if (params.data && params.data?.contact_color) {
      const isArray = Array.isArray(params.data?.contact_color);
      if (isArray) {
        color = getColorByValue(params.data?.contact_color[0]?.contact_color);
      } else {
        color = getColorByValue(params.data?.contact_color);
      }
    }
    if (params?.data?.color) {
      color = getColorByValue(params?.data?.color);
    }
    return (
      <div className='d-flex align-items-center'>
        <div className='color-code-box' style={{ background: `#${color?.hex_code}` }} />
        <PopoverStickOnHover component={RenderColorsList(params)} placement='bottom' onMouseEnter={() => {}} delay={200}>
          <ExpandMoreIcon color='primary' className='cursor-pointer' />
        </PopoverStickOnHover>
      </div>
    );
  };

  const handleCopyCandidates = () => {
    const contactIds = getSelectedContactIds();
    setIsAddToSearchOpen(true);
    setContactIds(contactIds);
  };

  const handleChange = event => {
    const checked = event.currentTarget.checked;
    setSelectAllRows(checked);
  };

  const HeaderCheckbox = () => {
    return <Checkbox style={{ padding: 0, width: 16, height: 16, color: 'white' }} size='small' color='primary' onChange={handleChange} />;
  };

  const handleRemoveContacts = () => {
    setIsRemoveConfirmationOpen(true);
  };

  const handleConfirmPopupClose = () => {
    setIsRemoveConfirmationOpen(false);
  };

  const removeContacts = async () => {
    try {
      const contactIds = getSelectedContactIds();

      setIsLoading(true);
      const removed = await removeContactsFromWorkbench(id, contactIds, enqueueSnackbar, { ...filterModels });
      if (removed) {
        setIsRowSelected(false);
        setSelectAllRows(false);
        gridApi.onFilterChanged();
      }

      setIsLoading(false);
      handleConfirmPopupClose();
    } catch (e) {
      console.log('Error found in removeContacts::', e);
    }
  };

  const getExtraClassName = () => {
    return props.isLockedWorkbench ? 'locked' : '';
  };

  const exportContacts = async () => {
    let selectedContactIds = [];
    selectedContactIds = getSelectedContactIds();
    if (isAllRowsSelected && rowCount > EXPORT_CONTACT_LIMIT) {
      const message = exportMessage(`${EXPORT_CONTACT_LIMIT}`, 'Workbench-Contact');
      enqueueSnackbar(message, { variant: ERROR });
    } else if (!isAllRowsSelected && selectedContactIds?.length > EXPORT_CONTACT_LIMIT) {
      const message = exportMessage(`${EXPORT_CONTACT_LIMIT}`, 'Workbench-Contact');
      enqueueSnackbar(message, { variant: ERROR });
    } else {
      let sub_route = 'contacts/export?';
      if (Object.keys(filterModels)?.length) {
        sub_route = sub_route.concat(getFilterParamString(filterModels));
      }
      if (isAllRowsSelected) selectedContactIds = [];
      const columnHeaders = columnApi.getAllDisplayedColumns().map(column => column.getColDef().headerName);
      setIsLoading(true);
      const payload = {
        headers: {
          columnHeaders: columnHeaders,
          selectedRows: { selectedContactIds },
          allRowSelected: isAllRowsSelected,
          limit: EXPORT_CONTACT_LIMIT
        }
      };
      const { status, data } = await workbenchDataApi(POST, props.id, payload, sub_route, BLOB);
      if (status === 200) {
        saveAs(new File([data], 'workbench_contacts.xlsx'));
      } else if (status === 400) {
        const message = exportMessage(`${EXPORT_CONTACT_LIMIT}`, 'Workbench-Contact');
        enqueueSnackbar(message, { variant: ERROR });
      } else {
        const message = unableMessage('Workbench-Contact', 'Export');
        enqueueSnackbar(message, { variant: ERROR });
      }
      setIsLoading(false);
    }
  };

  const handleCopyContactsToWorkbench = () => {
    const contactIds = getSelectedContactIds();
    setIsCopyToWorkbenchOpen(true);
    setContactIds(contactIds);
  };

  const handleCopyContactsToWorkbenchClose = () => {
    setIsCopyToWorkbenchOpen(false);
  };

  const navigateToActivityLog = (contactId, ReadOnly = false) => {
    // const { sortModel, filterModel } = state
    let url;
    if (ReadOnly === true) {
      url = `/contacts/${contactId}/log-an-activity/read-only/general-info`;
    } else {
      url = `/contacts/${contactId}/log-an-activity/general-info`;
    }
    props.navigate(url, {
      sortModel,
      filterModels,
      fromContact: true,
      previousPath: props.location.pathname
    });
  };

  const ActionsRenderer = params => {
    const list = [
      {
        label: 'Log An Activity',
        onClick: () => {
          acquire(params?.data?.id);
        }
      },
      {
        label: 'Show History',
        onClick: params.colDef ? () => showContactActivityHistory(params.data) : null
      }
    ];
    return <ActionsPopover list={list} />;
  };

  const acquire = async contactId => {
    let { status, data } = await acquireLockApi(contactId);
    if (status && status === 200 && data.message === undefined) {
      await releaseLockApi(contactId);
      navigateToActivityLog(contactId);
    } else {
      //if (data.isAdmin) {
      enqueueSnackbar(data.message, {
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: key => (
          <>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key);
                releaseLock(contactId);
              }}
            >
              Force Unlock
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                let ReadOnly = true;
                closeSnackbar(key);
                navigateToActivityLog(contactId, ReadOnly);
              }}
            >
              Open Read Only View
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                closeSnackbar(key);
              }}
            >
              CLOSE
            </Button>
          </>
        )
      });
      /* }
      else {
       enqueueSnackbar(data.message, {
          variant: WARNING,
          // eslint-disable-next-line react/display-name
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                let ReadOnly=true;
                props.closeSnackbar(key)
                navigateToActivityLog(contactId,ReadOnly)
              }}
            >
              Open Read Only View
            </Button>
          )
        });
      } */
    }
  };
  const releaseLock = async contactId => {
    await releaseLockApi(contactId);
    acquire(contactId);
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    setSortModel(sortModel);
    saveColumnStateForSort();
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    setFilterModels(filterModel);
    saveColumnStateForFilter();
  };

  const loaderChange = async value => {
    setIsLoading(value);
  };

  const handleClose = () => {
    setLinkedPopup(false);
  };

  const setEmploymentHistoryEndYearDate = endDate => {
    setEmploymentHistoryMessageEndYearDate(endDate);
  };

  const handleConfirmJobTitlePopup = async () => {
    setIsUpdateEmploymentHistory(true);
    setShowJobTitleChangedPopup(false);
    setSkipCheckJobTitle(true);
  };

  const handleCancelJobTitlePopup = async () => {
    setIsUpdateEmploymentHistory(false);
    setShowJobTitleChangedPopup(false);
    setSkipCheckJobTitle(true);
  };

  const setJobHistoryPopupStates = data => {
    const { employmentHistoryMessageEndYearDate, isUpdateEmploymentHistory, currentContact, newContactData } = data;
    setEmploymentHistoryMessageEndYearDate(employmentHistoryMessageEndYearDate);
    setIsUpdateEmploymentHistory(isUpdateEmploymentHistory);
    setCurrentContact(currentContact);
    setNewContactData(newContactData);
  };

  const resetFilter = async () => {
    gridApi.setFilterModel(null);
    const customFilterField = columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item?.filter === 'CustomFilter') {
        gridApi.destroyFilter(item.field);
      }
    });
  };

  const resetSort = async () => {
    gridApi.setSortModel(null);
  };

  /*  const handleUpdateJobHistory = async () => {
    setSkipCheckJobTitle(false);
    const params = newContactData;
    if (isUpdateEmploymentHistory) {
      setIsLoading(true);
      const contact = currentContact;
      let newJobHistory = [
        {
          company: contact.company,
          title: contact.current_job_title,
          start_year: contact.current_job_start_year,
          end_year:
            employmentHistoryMessageEndYearDate || new Date().getFullYear(),
        },
      ];
      newJobHistory = [...newJobHistory, ...contact.job_history]?.filter(
        (item) => item?.company
      );
      params.job_history = sortByChronologicalOrder(
        cleanupCompanyDetails(newJobHistory, contact.id)
      );
    }
    await acquireUtil(
      params,
      "current_job_title",
      enqueueSnackbar,
      closeSnackbar,
      true,
      loaderChange,
      undefined,
      undefined,
      true
    );
    setJobHistoryPopupStates({
      employmentHistoryMessageEndYearDate: null,
      isUpdateEmploymentHistory: false,
      currentContact: null,
      newContactData: null,
    });
  }; */

  const queryData = isAllRowsSelected ? Object.fromEntries(new URLSearchParams(getFilterParamString(gridApi.getFilterModel()))) : null;
  const extraClassName = getExtraClassName();

  return (
    <div className='list-work-benches d-flex flex-column'>
      <Loader show={isLoading} />
      <LinkedInDialog open={linkedPopup} linkedInValue={linkedInValue} handleClose={handleClose}></LinkedInDialog>
      {showJobTitleChangedPopup && (
        <EmploymentHistoryConfirmationDialog
          header='Update'
          employmentHistoryMessageEndYearDate={employmentHistoryMessageEndYearDate}
          setEmploymentHistoryEndYearDate={setEmploymentHistoryEndYearDate}
          open={showJobTitleChangedPopup}
          contact={currentContact}
          handleSubmit={handleConfirmJobTitlePopup}
          handleClose={handleCancelJobTitlePopup}
          cancelText='No'
          confirmText='Yes'
          extraClassName='jobhistory-popup'
        />
      )}
      {activeContact && (
        <ActivityHistories
          contact={{
            id: activeContact.id,
            first_name: activeContact.first_name,
            last_name: activeContact.last_name,
            company: activeContact.company,
            current_job_title: activeContact.current_job_title
          }}
          onPopupClose={() => showContactActivityHistory(false)}
        />
      )}
      {isAddToSearchOpen && (
        <CopyCandidatesToSearch
          header={VALIDATION_MESSAGE.add_contact_to_search}
          buttonText={VALIDATION_MESSAGE.add_contact_to_search}
          uiLabel='contact'
          open={isAddToSearchOpen}
          contactIds={contactIds}
          workbenchId={id ? id : null}
          handleClose={handleAddToSearchClose}
          queryData={queryData}
          hide={true}
        />
      )}
      {isRemoveConfirmationOpen && <ConfirmationPopup message='Are you sure you want to remove the contact(s) from this workbench?' onClose={handleConfirmPopupClose} onConfirm={removeContacts} />}
      {isCopyToWorkbenchOpen && (
        <AddContactsToWorkbench open={isCopyToWorkbenchOpen} contactIds={contactIds} onClose={handleCopyContactsToWorkbenchClose} sourceWorkbenchId={isAllRowsSelected ? id : null} type='Contacts' />
      )}
      {isAthenaPopupOpen ? (
        <AthenaInvitePopUpContact enqueueSnackbar={enqueueSnackbar} inviteFor={'candidate'} selectedDropDown={'Athena'} client={athenaData} onClose={handleAthenaPopupClose} />
      ) : null}
      <AddContactToWorkbench open={isAddToWorkbenchOpen} handleClose={handleAddToWorkbenchClose} addToWorkbench={addContactsToWorkbench} />
      <QuickAddContact enqueueSnackbar={enqueueSnackbar} open={isQuickAddOpen} handleClose={handleQuickAddContactClose} isAddToSearchEnabled={true} addToText='Create Contact & Add To Workbench' />
      <Header name={workbench.name} users={workbench.tagged_users} workbench={workbench} showEdit={false} reloadData={reloadData} isLockedWorkbench={isLockedWorkbench}>
        <Button variant='contained' color='primary' onClick={exportContacts} disabled={!isRowSelected}>
          Export
        </Button>
      </Header>
      <div className='d-flex align-items-center p-3'>
        <Button color='primary' variant='outlined' className='action-button' onClick={handleQuickAddContact} disabled={isLockedWorkbench}>
          Quick Add Contact
        </Button>
        <Button color='primary' variant='outlined' className='action-button' onClick={handleAddToWorkbench} disabled={isLockedWorkbench}>
          Add Contacts To Workbench
        </Button>
        <Button onClick={handleCopyContactsToWorkbench} className='action-button' disabled={!isRowSelected} variant='outlined' color='primary'>
          Copy Contacts to Workbench
        </Button>
        <Button disabled={!isRowSelected} onClick={handleCopyCandidates} className='action-button' variant='outlined' color='primary'>
          Copy Contacts to Search
        </Button>
        <Button color='primary' variant='outlined' className='action-button' disabled={!isRowSelected || isLockedWorkbench} onClick={handleRemoveContacts}>
          Remove Contacts From Workbench
        </Button>
      </div>
      <div className='count-container'>
        <div className='action-container' style={{ minWidth: '0' }} onClick={() => resetFilter()}>
          <span className='action-text'>Reset Filter</span>
        </div>
        <div className='action-container' style={{ minWidth: '0' }} onClick={() => resetSort()}>
          <span className='action-text'>Reset Sort</span>
        </div>
        <ColorLegend colorList={colorData} setColorDesc={setColorDesc} extraClassName={extraClassName} />
        <Typography>Total count: {rowCount}</Typography>
      </div>
      <div className='list-view flex-grow-1'>
        {isGridReady && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} showFilterCount={showFilterCount} filterModel={filterModels} />}
        <div id='myGrid' className='ag-theme-alpine'>
          <AgGridReact
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              sortable: true,
              filterable: false,
              sortingOrder: ['asc', 'desc', null]
            }}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            frameworkComponents={{
              CustomLoadingOverlayComponent,
              LinkedInRenderer: LinkedInRenderers,
              PhoneRenderer,
              EmailRenderer,
              ActionsRenderer: ActionsRenderer,
              AddContactToRenderer: AddContactToRenderer,
              ColorRenderer: ColorRenderer,
              NameRenderer: NameRenderer,
              ResumeRenderer,
              DateTimeRenderer,
              DateRenderer,
              CompanyNameRenderer: CompanyNameRenderers,
              GenericCellEditor,
              HeaderCheckbox: HeaderCheckbox,
              CompensationRenderer,
              CustomFilter,
              AthenaRenderer: AthenaRenderer
            }}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            suppressMenuHide={true}
            rowModelType={'infinite'}
            datasource={dataSource}
            columnDefs={columnWorkbenchDefs(showContactActivityHistory, enqueueSnackbar, closeSnackbar, true, id, loaderChange, option, setJobHistoryPopupStates)}
            paginationPageSize={PAGE_LIMIT}
            getRowNodeId={data => data.id}
            suppressRowClickSelection={true}
            rowSelection={'multiple'}
            onRowSelected={e => {
              if (!e.node.selected) {
                setIsAllRowsSelected(false);
              }
              setIsRowSelected(gridApi.getSelectedRows()?.length > 0 ? true : false);
            }}
            onDisplayedColumnsChanged={saveColumnState}
            onDragStopped={saveColumnState}
            onFirstDataRendered={onFirstDataRendered}
            onSortChanged={onSortChanged}
            onFilterChanged={onFilterChanged}
            blockLoadDebounceMillis={100}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userData: state.commonReducer.userData,
    color_pick_list: state.pickListReducer.color_pick_list
  };
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Contacts));

Contacts.propTypes = {
  workbench: PropTypes.object,
  color_pick_list: PropTypes.function,
  dispatch: PropTypes.func,
  id: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  navigate: PropTypes.func,
  reloadData: PropTypes.func,
  userData: PropTypes.object,
  getTotalCount: PropTypes.func,
  option: PropTypes.array,
  isLockedWorkbench: PropTypes.bool,
  value: PropTypes.object
};
