import React, { useEffect, useRef, useState } from 'react';
//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import { Box, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
//----------------------------------------------// Internal Imports // -------------------------------------------------

import { useSelector } from 'react-redux';
import { getFixedDecimal } from '../../../../../utils/common';
import CustomTable from '../../../../common/CustomTable';
import CalculationTypeSelection from '../../../../common/FunctionalComponents/CalculationTypeSelection';
import IndirectFeetypeSelection from '../../../../common/FunctionalComponents/IndirectFeetypeSelection';
import CustomInputField from '../../../../common/StyledComponents/CustomInputField';
import CustomNumbericField from '../../../../common/StyledComponents/CustomNumericField';

const IndirectFee = props => {
  const { register, setValue, currentValues, getTotalPercentageOfBase, watch, type = 'estimated', disabled = false, getValueWithCurrency, isDisabled = false } = props;
  const [indirectFee, setIndirectFee] = useState([]);
  const [availableType, setAvailableType] = useState([]);
  const inDirectFeeTypes = useSelector(state => state.commonReducer?.inDirectFeeTypes);
  const indirectFeeRef = useRef();
  indirectFeeRef.current = indirectFee;
  useEffect(() => {
    register('project_indirect_fees');
    setValue('project_indirect_fees', indirectFee);
  }, [register, indirectFee, setValue]);

  useEffect(() => {
    if (currentValues) {
      setIndirectFee(currentValues?.project_indirect_fees || []);
    }
  }, [currentValues]);

  const handleChangeIndirectFee = (data, field, index, shortDesc) => {
    let existingIndirectFee = [...indirectFee];
    existingIndirectFee[index] = { ...existingIndirectFee[index], [field]: data };
    if (field === 'type') {
      existingIndirectFee[index] = { ...existingIndirectFee[index], ['short_desc']: shortDesc };
    }
    if (field === 'calculation_type') {
      if (data === 'flat_amount') {
        existingIndirectFee[index].percentage = 0;
        existingIndirectFee[index].calculation_amt = 0;
      }
    }

    if (field.includes('percentage', 'calculation_amt') || field === 'calculation_type') {
      existingIndirectFee[index].calculation_amt =
        existingIndirectFee[index].calculation_type === 'flat_amount'
          ? existingIndirectFee[index].calculation_amt
          : getFixedDecimal((getTotalPercentageOfBase(watch(`${type}_percentage_bases`), `calculated_${type}_amt`).number * existingIndirectFee[index].percentage) / 100);
    }
    setIndirectFee(existingIndirectFee);
  };

  const handleRemoveIndirectFee = index => {
    let existingIndirectFee = [...indirectFee];
    existingIndirectFee.splice(index, 1);
    setIndirectFee(existingIndirectFee);
    calculateAmount();
  };
  const handleAddIndirectFee = () => {
    let newIndirectFee = [...indirectFee, {}];
    setIndirectFee(newIndirectFee);
  };

  const calculateAmount = () => {
    setIndirectFee(prevState => [
      ...prevState.map(ele => {
        return {
          ...ele,
          calculation_amt:
            ele.calculation_type === 'flat_amount'
              ? ele.calculation_amt
              : getFixedDecimal((getTotalPercentageOfBase(watch(`${type}_percentage_bases`), `calculated_${type}_amt`).number * ele.percentage) / 100)
        };
      })
    ]);
  };

  useEffect(() => {
    if (indirectFee) {
      calculateAmount();
    }
    console.log(watch(`${type}_percentage_bases`));
  }, [watch(`${type}_percentage_bases`)]);

  useEffect(() => {
    setAvailableType(indirectFee.map(ele => ({ short_desc: ele.short_desc, type: ele.type })) || []);
  }, [indirectFee]);

  return (
    <Box id='container-communication'>
      <Box className='head-container'>
        <Box className='sub-head-content'>
          <Box className='field-label-input'>
            <Box className='d-flex flex-column  my-2'>
              <CustomTable
                headerData={['Fee Type', 'Calculation Type', 'Percentage', 'Calculated Amount', ' ']}
                title={'Indirect Fee'}
                handleAnotherRow={handleAddIndirectFee}
                rows={indirectFeeRef.current}
                dataList={inDirectFeeTypes}
                isDisabled={isDisabled}
              >
                {indirectFeeRef.current?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell sx={{ width: '20vw' }} className='input-field- p-2'>
                        {!disabled ? (
                          <IndirectFeetypeSelection
                            isDisabled={isDisabled}
                            selectedType={availableType}
                            defaultValue={item?.type_desc?.short_desc || ''}
                            viewAddress={true}
                            type='feetypes'
                            label=''
                            onChange={(e, data) => {
                              handleChangeIndirectFee(data ? data.field_value : null, 'type', index, data ? data.short_desc : null);
                            }}
                          />
                        ) : (
                          <Box className='col-3 team-info-field'>{item.type || '--'}</Box>
                        )}
                      </TableCell>
                      <TableCell sx={{ width: '20vw' }} className='p-2'>
                        {!disabled ? (
                          <Box>
                            <CalculationTypeSelection
                              isDisabled={isDisabled}
                              defaultValue={item?.calculation_type_desc?.short_desc}
                              onChange={(e, data) => {
                                handleChangeIndirectFee(data?.field_value ? data?.field_value : null, 'calculation_type', index);
                              }}
                              viewAddress={true}
                              // label={""}
                            />
                          </Box>
                        ) : (
                          <Box className='col-3 team-info-field'>{item.calculation_type || '--'}</Box>
                        )}
                      </TableCell>
                      <TableCell className='p-2'>
                        {!disabled ? (
                          <CustomInputField
                            value={item.percentage}
                            onChange={e => handleChangeIndirectFee(e.target.value ? Number(e.target.value) : null, 'percentage', index)}
                            type='number'
                            flag='true'
                            inputProps={{ min: 0, max: 100 }}
                            error={item.percentage > 100}
                            isDisabled={isDisabled || !item.calculation_type || item.calculation_type === 'flat_amount'}
                            onKeyPress={event => {
                              if (event?.key === '-' || event?.key === '+') {
                                event.preventDefault();
                              }
                            }}
                          />
                        ) : (
                          <Box className=''>{item[`calculated_${type}_amt`] ? getValueWithCurrency(item[`calculated_${type}_amt`]) : '--'}</Box>
                        )}
                      </TableCell>
                      <TableCell className='p-2'>
                        {!disabled ? (
                          <CustomNumbericField
                            value={item.calculation_amt}
                            sx={{
                              width: '100%'
                            }}
                            disabled={isDisabled || !item.calculation_type || item.calculation_type === 'percentage'}
                            onChange={value => {
                              handleChangeIndirectFee(value ? Number(value) : null, 'calculation_amt', index);
                            }}
                            error={item.calculation_amt > 1000000000000000}
                            InputProps={{ min: 0, max: 100 }}
                          />
                        ) : (
                          <Box className='col-3 team-info-field'>{item.calculation_type || '--'}</Box>
                        )}
                      </TableCell>
                      {!isDisabled && indirectFee.length > 0 && (
                        <TableCell className='remove-another-button p-2 ' mt={2}>
                          <CloseIcon onClick={() => handleRemoveIndirectFee(index)} className='table-close-icon' />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </CustomTable>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

IndirectFee.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  getTotalPercentageOfBase: PropTypes.func,
  watch: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  getValueWithCurrency: PropTypes.func
};

export default IndirectFee;
