import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
// import { FormLabel } from "react-bootstrap";
import ExportFilters from '../../components/common/FunctionalComponents/ExportFilterSelection';
import FileTypes from '../../components/common/FunctionalComponents/FileTypeSelection';
import ExportData from '../../components/common/FunctionalComponents/ExportDataSelection';
import Footer from '../../components/common/Footer';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../utils/languageNamespaces';
export default function ExportList() {
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_SELECT_DATA, TRANS_KEYS.CONTACTS_SELECT_FILTERS, TRANS_KEYS.CONTACTS_SELECT_FILE_TYPE]);
  return (
    <div id='side-pop-up' className='p-4'>
      <form>
        <Stack spacing={2}>
          <Stack spacing={4}>
            {/* <FormLabel className="fs-14">
              {t(`${TRANS_KEYS.CONTACTS_SELECT_DATA}:key`)}
            </FormLabel> */}
            <ExportData label={t(`${TRANS_KEYS.CONTACTS_SELECT_DATA}:key`)} />
            {/* <FormLabel className="fs-14">
              {t(`${TRANS_KEYS.CONTACTS_SELECT_FILTERS}:key`)}
            </FormLabel> */}
            <ExportFilters label={t(`${TRANS_KEYS.CONTACTS_SELECT_FILTERS}:key`)} />
            {/* <FormLabel className="fs-14">
              {t(`${TRANS_KEYS.CONTACTS_SELECT_FILE_TYPE}:key`)}
            </FormLabel> */}
            <FileTypes label={t(`${TRANS_KEYS.CONTACTS_SELECT_FILE_TYPE}:key`)} />
          </Stack>
          <Stack className='sidePopup-stack' spacing={2} useFlexGap direction={'row'} mx={4}>
            <Footer></Footer>
          </Stack>
        </Stack>
      </form>
    </div>
  );
}

ExportList.propTypes = {
  location: PropTypes.object
};
