//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------0
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { capitalize } from '../../../../../utils/string';

function ScoreRoleSelection(props) {
  const { className = 'w-100', label, InputLabelProps, required, placeholder = 'Select Role', variant = '', ...rest } = props;

  const [options, setOptions] = useState(['All Scores', 'Individual Scores']);

  useEffect(() => {
    // You can add other options to the options array here
    setOptions(['All Scores', 'Individual Scores']);
  }, []);

  return (
    <Autocomplete
      {...rest}
      className={className}
      getOptionLabel={option => capitalize(option)}
      options={options}
      renderInput={params => <TextField variant={variant} {...params} required={required} InputLabelProps={InputLabelProps} label={label} placeholder={placeholder} />}
    />
  );
}

ScoreRoleSelection.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  InputLabelProps: PropTypes.object
};

export default ScoreRoleSelection;
