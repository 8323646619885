import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Avatar, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Button from '../../../components/common/CustomButton';
import TextField from '../../../components/common/InputField';
const names = ['Oliver Hansen', 'Van Henry', 'April Tucker', 'Ralph Hubbard', 'Omar Alexander', 'Carlos Abbott', 'Miriam Wagner', 'Bradley Wilkerson', 'Virginia Andrews', 'Kelly Snyder'];

const ProjectInformation = props => {
  const { handleUniversalState, getValueFromUniversalState } = props;
  const [serviceOfferingOptions, setServiceOfferingOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [projectOwnerOptions, setProjectOwnerOptions] = useState([]);
  const [selectedProjectOwners, setSelectedProjectOwners] = useState([]);
  const [selectedClientContact, setSelectedClientContact] = useState([]);

  const handleProjectOwnerSelect = event => {
    const {
      target: { value }
    } = event;
    setSelectedProjectOwners(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    const localEvent = {
      target: {
        value: typeof value === 'string' ? value.split(',') : value,
        name: 'projectOwner'
      }
    };
    handleUniversalState(localEvent);
  };

  const handleClientContactSelect = event => {
    const {
      target: { value }
    } = event;
    setSelectedClientContact(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    const localEvent = {
      target: {
        value: typeof value === 'string' ? value.split(',') : value,
        name: 'clientContact'
      }
    };
    handleUniversalState(localEvent);
  };

  useEffect(() => {
    const dummyServiceOffering = ['Ignyte', 'Advisory', 'Executive', 'Speciality'];
    setServiceOfferingOptions(dummyServiceOffering);
    setCompanyOptions(names);
    setIndustryOptions(names);
    setJobTypeOptions(dummyServiceOffering);
    setProjectOwnerOptions(names);
  }, []);

  return (
    <Accordion fullWidth className='m-2'>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='body1' sx={{ fontWeight: '600' }}>
          Project Information
        </Typography>
      </AccordionSummary>
      <AccordionDetails className='py-2'>
        <Stack direction='row' className='py-2' justifyContent='flex-end'>
          <Button variant='contained' buttonText='Save' />
        </Stack>
        <Grid container spacing={2} rowSpacing={4}>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth size='small'>
              <InputLabel id='demo-simple-select-label'>Service Offering</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Service Offering'
                name='serviceOffering'
                value={getValueFromUniversalState('serviceOffering')}
                onChange={handleUniversalState}
              >
                {serviceOfferingOptions.map((val, index) => (
                  <MenuItem key={index} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <Autocomplete
              // freeSolo
              sx={{ backgroundColor: 'white' }}
              id='combo-box-demo'
              options={companyOptions ?? []}
              size='small'
              value={getValueFromUniversalState('companyName')}
              renderInput={params => <TextField fullWidth {...params} label='Company Name' />}
              onChange={(event, newValue) => {
                const localEvent = {
                  target: {
                    value: newValue,
                    name: 'companyName'
                  }
                };
                handleUniversalState(localEvent);
              }}
              name='companyName'
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              size='small'
              label='Job Title'
              variant='outlined'
              color='secondary'
              type='text'
              name='jobTitle'
              value={getValueFromUniversalState('jobTitle')}
              onChange={handleUniversalState}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <Autocomplete
              sx={{ backgroundColor: 'white' }}
              // freeSolo
              id='combo-box-demo'
              options={industryOptions ?? []}
              value={getValueFromUniversalState('industry')}
              size='small'
              renderInput={params => <TextField fullWidth {...params} label='Industry' />}
              onChange={(event, newValue) => {
                const localEvent = {
                  target: {
                    value: newValue,
                    name: 'industry'
                  }
                };
                handleUniversalState(localEvent);
              }}
              name='industry'
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth size='small'>
              <InputLabel id='demo-simple-select-label'>Job Type</InputLabel>
              <Select labelId='demo-simple-select-label' id='demo-simple-select' label='Job Type' onChange={handleUniversalState} name='jobType' value={getValueFromUniversalState('jobType')}>
                {jobTypeOptions.map((val, index) => (
                  <MenuItem key={index} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth size='small'>
              <InputLabel id='demo-simple-select-label'>Location</InputLabel>
              <Select labelId='demo-simple-select-label' id='demo-simple-select' label='Location' onChange={handleUniversalState} name='location' value={getValueFromUniversalState('location')}>
                {serviceOfferingOptions.map((val, index) => (
                  <MenuItem key={index} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth size='small'>
              <InputLabel id='demo-multiple-name-label'>Project Owner</InputLabel>
              <Select
                fullWidth
                labelId='demo-multiple-name-label'
                id='demo-multiple-name'
                multiple
                name='projectOwner'
                value={selectedProjectOwners.length ? selectedProjectOwners : getValueFromUniversalState('projectOwner').length ? getValueFromUniversalState('projectOwner') : []}
                input={<OutlinedInput label='Project Owner' />}
                renderValue={selected => selected.join(', ')}
                onChange={handleProjectOwnerSelect}
                MenuProps={{ style: { width: '100% !important' } }}
              >
                {projectOwnerOptions.map(name => (
                  <MenuItem key={name} value={name} sx={{ width: '100% !important' }}>
                    <Stack direction='row' justifyContent='space-between' fullWidth>
                      <Stack direction='row' alignItems='center'>
                        <Avatar alt={name} src='../../../assets/images/profile-image.png' className='m-1' />
                        {name}
                      </Stack>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              size='small'
              label='Experience From'
              variant='outlined'
              color='secondary'
              type='number'
              name='experienceFrom'
              value={getValueFromUniversalState('experienceFrom')}
              onChange={handleUniversalState}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              size='small'
              label='Experience To'
              variant='outlined'
              color='secondary'
              type='number'
              fullWidth
              name='experienceTo'
              value={getValueFromUniversalState('experienceTo')}
              onChange={handleUniversalState}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              size='small'
              label='Min Compensation'
              variant='outlined'
              color='secondary'
              type='number'
              fullWidth
              value={getValueFromUniversalState('minCompensation')}
              onChange={handleUniversalState}
              name='minCompensation'
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <TextField
              size='small'
              label='Max Compensation'
              variant='outlined'
              color='secondary'
              type='number'
              fullWidth
              name='maxCompensation'
              value={getValueFromUniversalState('maxCompensation')}
              onChange={handleUniversalState}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              size='small'
              label='Additional Information'
              variant='outlined'
              color='secondary'
              type='text'
              fullWidth
              onChange={handleUniversalState}
              value={getValueFromUniversalState('additionalInformation')}
              name='additionalInformation'
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth size='small'>
              <InputLabel id='demo-multiple-name-label'>Client Contact Details</InputLabel>
              {/* PENDING */}
              <Select
                fullWidth
                labelId='demo-multiple-name-label'
                id='demo-multiple-name'
                multiple
                value={selectedClientContact.length ? selectedClientContact : getValueFromUniversalState('clientContact').length ? getValueFromUniversalState('clientContact') : []}
                input={<OutlinedInput label='Client Contact Details' />}
                name='clientContact'
                renderValue={selected => selected.join(', ')}
                onChange={handleClientContactSelect}
                MenuProps={{ style: { width: '100% !important' } }}
              >
                {names.map(name => (
                  <MenuItem key={name} value={name} sx={{ width: '100% !important' }}>
                    <Stack direction='row' justifyContent='space-between' fullWidth>
                      <Stack direction='row' alignItems='center'>
                        <Avatar alt={name} src='../../../assets/images/profile-image.png' className='m-1' />
                        {name}
                      </Stack>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <Button sx={{ textTransform: "none" }}>+Add Contact</Button> */}
            <Button buttonText={'+ Add Contact'} variant={'text'} type={''} />
          </Grid>
          <Grid item xs={6} md={4}>
            <Autocomplete
              sx={{ backgroundColor: 'white' }}
              id='combo-box-demo'
              options={names ?? []}
              size='small'
              value={getValueFromUniversalState('hiringManager')}
              onChange={(event, newValue) => {
                const localEvent = {
                  target: {
                    value: newValue,
                    name: 'hiringManager'
                  }
                };
                handleUniversalState(localEvent);
              }}
              renderOption={(props, name) => (
                <Stack direction='row' justifyContent='space-between' fullWidth {...props}>
                  <Stack direction='row' alignItems='center'>
                    <Avatar alt={name} src='../../../assets/images/profile-image.png' className='m-1' />
                    {name}
                  </Stack>
                </Stack>
              )}
              renderInput={params => <TextField fullWidth {...params} name='hiringManager' onChange={handleUniversalState} label='Hiring Manager' />}
            />
            <Button buttonText={'+ Add Contact'} variant={'text'} type={''} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

ProjectInformation.propTypes = {
  handleUniversalState: PropTypes.func.isRequired,
  getValueFromUniversalState: PropTypes.func.isRequired
};

export default ProjectInformation;
