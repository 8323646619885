//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { getActivityIcon } from './utils';
import { formatDate, formatTime } from '../../../../../../utils/date';

export default function HistoryItem(props) {
  const { details } = props;
  return (
    <div className='activity-history-card d-flex'>
      <div className='histroy-icons d-flex align-items-start'>
        <span className='activity-icon'>{getActivityIcon(details.activity)}</span>
        <span className='activity-icon activity-direction'>{details.direction === 'Incoming' ? <ArrowBackIcon fontSize='inherit' /> : <ArrowForwardIcon fontSize='inherit' />}</span>
      </div>
      <div className='history-data d-flex flex-column'>
        <div className='history-details d-flex history-subject flex-wrap'>
          <span className='history-lable'>Subject:</span>
          <span className='history-value'>{details.subject || ''}</span>
        </div>
        <div className='d-flex flex-wrap'>
          <span className='history-details d-flex mr-2'>
            <span className='history-lable'>Activity:</span>
            <span className='history-value'>{details.activity || ''},</span>
          </span>
          <span className='history-details d-flex'>
            <span className='history-lable'>Type:</span>
            <span className='history-value'>{details.type || ''}</span>
          </span>
        </div>
        {details.search != null && (
          <div className='d-flex flex-wrap'>
            <span className='history-details d-flex mr-2'>
              <span className='history-lable'>Search:</span>
              <span className='history-value'>{`${details.search.job_number} - ${details.search.job_title} ( ${details.search.company?.name} )`}</span>
            </span>
          </div>
        )}
        <div className='d-flex history-details flex-wrap'>
          <span className='history-details d-flex mr-2'>
            <span className='history-lable'>By:</span>
            <span className='history-value'>{details.updated_user ? details.updated_user.name : details.created_user ? details.created_user.name : ''}</span>
          </span>
          <span className='history-date'>
            {formatDate(details.udpated_at ? details.udpated_at : details.created_at) || ''} | {formatTime(details.updated_at) || ''}
          </span>
        </div>
        <div className='d-flex history-details flex-wrap'>
          <span className='history-details d-flex mr-2'>
            <span className='history-lable'>Comments:</span>
            <span style={{ wordWrap: 'break-word' }} className='contact-view-value' dangerouslySetInnerHTML={{ __html: details.comments || '--' }}></span>
          </span>
        </div>
      </div>
    </div>
  );
}

HistoryItem.propTypes = {
  details: PropTypes.object
};
