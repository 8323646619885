const columnDefs = [
  {
    field: 'activity_label',
    headerName: 'Activity Name',
    width: 350,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    cellRenderer: 'activityNameRenderer'
  },
  {
    field: 'show_in',
    headerName: 'Show In',
    width: 350,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  }
];

const activityNameRenderer = params => {
  const browserLocale = navigator.language;

  return params?.data?.labels?.find(item => item?.language_code === browserLocale)?.label || params?.data?.labels?.find(item => item?.language_code === 'en-US')?.label || 'No Label';
};

module.exports = { columnDefs, activityNameRenderer };
