//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Checkbox, FormControl, FormControlLabel, FormLabel } from '@mui/material';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InputField from '../../../common/InputField';
import DegreeSelection from '../../../DegreeSelection';
import YearSelection from '../../../../components/YearSelection';
import { DELETE, GET, PUT, SUCCESS, ERROR, WARNING } from '../../../../services/constantService';
import { acquireLockApi, contactDataApi, releaseLockApi } from '../../../../services/ApiService';
import { unableMessage, validateMessage, successMessage } from '../../../../services/MessageService';
import { compareByPosition } from '../../../../utils/common';
import { showSnackbar } from '../../../../Containers/Commons/Utils';
export default function EducationDetailsView(props) {
  const { educationDetails: education = [], contactId, setIsLoading, setContactData } = props;

  const [educationDetails, setEducationDetails] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [isAdding, setIsAdding] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [oldRecords, setOldRecords] = useState([]);
  const [schoolNameError, setSchoolNameError] = useState(false);
  const [majorError, setMajorError] = useState(false);
  const sub_route = 'education-details';
  useEffect(() => {
    setEducationDetails(education);
    setOldRecords(JSON.parse(JSON.stringify(education)));
  }, [education, setEducationDetails]);

  const handleChange = (index, field, value, updateProps = false) => {
    // updateProps for update on Perent component
    const details = [...educationDetails];
    details[index][field] = value;
    if (details[index]['degree_name'] === null) {
      details[index]['verify'] = false;
    }
    setEducationDetails(details);
    if (updateProps) {
      setContactData(details, 'education_details');
    }
  };

  const addEducation = () => {
    setEducationDetails(prevState => [...prevState, {}]);
  };

  const removeEducation = index => {
    let details = [...educationDetails];
    details.splice(index, 1);
    setEducationDetails(details);
    setContactData(details, 'education_details');
  };

  const deleteEducation = async index => {
    const { status, data } = await contactDataApi(DELETE, contactId, { id: educationDetails[index].id }, sub_route);
    if (status === 200) {
      const message = successMessage('', 'Deleted');
      enqueueSnackbar(message, { variant: SUCCESS });
      removeEducation(index);
      setEditIndex(-1);
    } else {
      const message = unableMessage('education details', 'delete');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    releaseLock();
  };

  const handleAdd = () => {
    if (isAdding) {
      const message = validateMessage('add', 'complete');
      enqueueSnackbar(message, { variant: WARNING });
      return;
    }
    setEditIndex(educationDetails?.length);
    addEducation();
    setIsAdding(true);
  };
  const isSameUser = (a, b) => {
    if (a.degree_name === b.degree_name && a.degree_year === b.degree_year && a.major === b.major && a.position === b.position && a.school_name === b.school_name && a.verify === b.verify) {
      return true;
    } else {
      return false;
    }
  };
  const handleUpdate = async index => {
    if (oldRecords?.length === educationDetails?.length) {
      const result = isSameUser(oldRecords[index], educationDetails[index]);
      if (result === true) {
        handleCancel(index);
      } else {
        const payload = {
          ...educationDetails[index],
          position: index
        };

        setIsLoading(true);
        const { status, data } = await contactDataApi(PUT, contactId, payload, sub_route);
        if (status === 200) {
          const message = successMessage('Education details', 'saved');
          enqueueSnackbar(message, { variant: SUCCESS });
          if (isAdding) {
            handleChange(index, 'id', data.id, true);
            setIsAdding(false);
          } else {
            setContactData(JSON.parse(JSON.stringify(educationDetails)), 'education_details');
          }
          setOldRecords(JSON.parse(JSON.stringify(educationDetails)));
        } else {
          const message = unableMessage('education details', 'delete');
          enqueueSnackbar(data?.message || message, { variant: ERROR });
        }
        setIsLoading(false);
        releaseLock();
        setEditIndex(-1);
      }
    } else {
      const payload = {
        ...educationDetails[index],
        position: index
      };

      setIsLoading(true);
      const { status, data } = await contactDataApi(PUT, contactId, payload, sub_route);
      if (status === 200) {
        const message = successMessage('Education details', 'saved');
        enqueueSnackbar(message, { variant: SUCCESS });
        if (isAdding) {
          handleChange(index, 'id', data.id, true);
          setIsAdding(false);
        }
        setOldRecords(JSON.parse(JSON.stringify(educationDetails)));
      } else {
        const message = unableMessage('education details', 'delete');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setIsLoading(false);
      releaseLock();
      setEditIndex(-1);
    }
  };
  const releaseLock = async () => {
    await releaseLockApi(contactId);
  };
  const handleCancel = async index => {
    if (isAdding) {
      removeEducation(index);
      setIsAdding(false);
    }
    setIsLoading(true);
    const { status, data } = await contactDataApi(GET, contactId);
    if (status && status == 200) setEducationDetails(data.education_details?.sort(compareByPosition));
    setIsLoading(false);
    setEditIndex(-1);
    releaseLock();
  };
  /* const checkForError = (index, field, value) => {
    if (/^[A-Za-z\s]*$/.test(value)) {
      if (field === 'school_name') setSchoolNameError(false)
      if (field === 'major') setMajorError(false)
      return true
    }
    else {
      if (field === 'school_name') setSchoolNameError(true)
      if (field === 'major') setMajorError(true)
      handleChange(index, field, '')
      return false
    }
  } */

  const renderView = item => {
    return (
      <React.Fragment>
        <div className='d-flex contact-details-row'>
          <div className='contact-details'>
            <div className='contact-view-label'>School Name</div>
            <div className='contact-view-value'>{item.school_name || '--'}</div>
          </div>
          <div className='contact-details'>
            <div className='contact-view-label'>Degree Name </div>
            <div className='contact-view-value'>
              {item.degree_name || '--'}
              <FormControlLabel control={<Checkbox style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 30 }} checked={item.verify || false} color='primary' />} />
            </div>
          </div>
        </div>
        <div className='d-flex contact-details-row'>
          <div className='contact-details'>
            <div className='contact-view-label'>Major</div>
            <div className='contact-view-value'>{item.major || '--'}</div>
          </div>
          <div className='contact-details'>
            <div className='contact-view-label'>Degree Year</div>
            <div className='contact-view-value'>{item.degree_year || '--'}</div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderEditIcon = index => {
    return (
      !(editIndex === index || hoverIndex !== index) && (
        <EditIcon
          className='ml-2 cursor-pointer'
          onClick={async () => {
            schoolNameError && setSchoolNameError(false);
            majorError && setMajorError(false);
            let { status, data } = await acquireLockApi(contactId);
            if (status && status === 200 && data.message && data.user === false) {
              //if (data.isAdmin) {
              const object = {
                enqueueSnackbar: enqueueSnackbar,
                closeSnackbar: closeSnackbar,
                message: data.message,
                id: contactId
              };
              showSnackbar(object);

              /* }
          else {
            enqueueSnackbar(data.message, { variant: WARNING });
          } */
            } else {
              setIsLoading(true);
              const { status, data } = await contactDataApi(GET, contactId);
              if (status && status == 200) {
                setEducationDetails(data.education_details?.sort(compareByPosition));
              }
              setIsLoading(false);
              setEditIndex(index);
            }
          }}
          fontSize='inherit'
          color='secondary'
        />
      )
    );
  };

  const renderActions = index => {
    return editIndex === index ? (
      <div className='ml-2' style={{ fontSize: 16 }}>
        {!isAdding && (
          <DeleteIcon
            cursor='pointer'
            fontSize='inherit'
            onClick={async () => {
              await deleteEducation(index);
            }}
          />
        )}
        <DoneIcon cursor='pointer' fontSize='inherit' color='primary' onClick={() => handleUpdate(index)} />
        <CloseIcon cursor='pointer' fontSize='inherit' onClick={() => handleCancel(index)} />
      </div>
    ) : (
      renderEditIcon(index)
    );
  };

  const renderSubHeader = index => {
    return (
      <div className='secion-sub-header d-flex align-items-center text-right'>
        <span>ENTRY {index + 1}</span>
        {renderActions(index)}
      </div>
    );
  };

  const renderEdit = (item, index) => {
    return (
      <>
        <div className='d-flex flex-grow-1'>
          <InputField
            autoFocus
            className='input-form-field input-field-old contact-view-value'
            label='School Name'
            placeholder='School Name'
            defaultValue={item.school_name}
            InputLabelProps={{ focused: true, shrink: true }}
            error={schoolNameError}
            helperText={schoolNameError ? 'Enter Valid Value ' : ''}
            onChange={e => {
              /* checkForError(index, 'school_name', e.target.value) && */
              handleChange(index, 'school_name', e.target.value);
            }}
          />
          <DegreeSelection
            className='input-form-field input-field-old contact-view-value'
            label='Degree Name'
            placeholder='Degree Name'
            value={item.degree_name}
            InputLabelProps={{ focused: true, shrink: true }}
            onChange={(e, data) => {
              handleChange(index, 'degree_name', data);
            }}
          />
        </div>
        <div className='d-flex flex-grow-1'>
          <InputField
            defaultValue={item.major}
            className='input-form-field input-field-old contact-view-value'
            label='Major'
            placeholder='Major'
            InputLabelProps={{ focused: true, shrink: true }}
            error={majorError}
            helperText={majorError ? 'Enter Valid Value ' : ''}
            onChange={e => {
              /* checkForError(index, 'major', e.target.value) &&  */
              handleChange(index, 'major', e.target.value);
            }}
          />
          {/* <InputField
            defaultValue={item.degree_year}
            className='input-form-field input-field-old contact-view-value'
            label='Degree Date'
            InputLabelProps={{ focused: true, shrink: true }}
            onChange={(e) => { handleChange(index, 'degree_year', e.target.value) }}
          /> */}
          <YearSelection
            // {...register('')}
            defaultValue={item.degree_year}
            className='input-form-field input-field-old contact-view-value'
            // value={item.degree_year || null}
            onChange={(e, data) => {
              handleChange(index, 'degree_year', data);
            }}
            label='Degree Year'
            InputLabelProps={{ focused: true, shrink: true }}
            yearType='start'
          />
        </div>
        {item.degree_name && (
          <div className='d-flex flex-grow-1'>
            <FormControl className='input-form-field input-field-old'>
              <FormLabel focused={true} className='input-label'>
                Degree Verified
              </FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={item.verify}
                    size='small'
                    style={{ paddingRight: 5 }}
                    name='verify'
                    onChange={e => {
                      handleChange(index, 'verify', e.target.checked);
                    }}
                  />
                }
                label='Yes'
              />
            </FormControl>
          </div>
        )}
      </>
    );
  };

  const renderEducationDetails = () => {
    if (educationDetails?.length === 0) {
      return <div className='d-flex contact-details-row no-data-available'>--</div>;
    }
    return educationDetails.map((item, index) => {
      return (
        <div
          key={index}
          onMouseEnter={() => {
            setHoverIndex(index);
          }}
          onMouseLeave={() => {
            setHoverIndex(-1);
          }}
        >
          {renderSubHeader(index)}
          {editIndex === index ? renderEdit(item, index) : renderView(item)}
        </div>
      );
    });
  };

  return (
    <div className='section-container'>
      <div className='section-header'>
        <span>Education</span>
        <AddCircleIcon className='ml-2 cursor-pointer' fontSize='inherit' color='primary' onClick={handleAdd} />
      </div>
      {renderEducationDetails()}
    </div>
  );
}

EducationDetailsView.propTypes = {
  educationDetails: PropTypes.array,
  contactId: PropTypes.string,
  setIsLoading: PropTypes.func,
  setContactData: PropTypes.func
};
