import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StageWorkflowForm from './StageWorkflowForm';
import WorkflowConfig from './WorkflowConfig';

const WorkflowRenderer = ({ workflows, refetch }) => {
  const { t } = useTranslation();
  const [editWorkFlowId, setEditWorkFlowId] = React.useState(null);
  const editWorkFlow = id => {
    setEditWorkFlowId(id);
  };
  return (
    <Box display='flex' flex={1} flexDirection='column'>
      <Typography color='primary'>
        <strong>Workflow Name: </strong>
        Ignyte Default
      </Typography>

      <Box display='flex' flex={1} flexDirection='column' border='1px solid grey'>
        <Box className='root-wrapper' display='flex' alignItems='center' justifyContent='space-between' padding={1}>
          <Typography color='white' fontWeight='700'>
            {t('stageWorkflow.configuration')}
          </Typography>
          <StageWorkflowForm id={editWorkFlowId} refetch={refetch} workflows={workflows} clearSelection={() => setEditWorkFlowId(null)} />
        </Box>
        {isEmpty(workflows) ? (
          <Box flex={0.5} display='flex' justifyContent='center' alignItems='center'>
            <Typography variant='h5'>{t('stageWorkflow.emptyConfig')}</Typography>
          </Box>
        ) : (
          <>
            <Box display='flex' flexDirection='column'>
              {workflows.map(workflow => {
                return <WorkflowConfig key={JSON.stringify(workflow)} workflow={workflow} refetch={refetch} setEditWorkFlowId={editWorkFlow} />;
              })}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

WorkflowRenderer.propTypes = {
  workflows: PropTypes.array,
  refetch: PropTypes.func
};

export default WorkflowRenderer;
