//-----------------------------------------------------------// In-built Imports // ------------------------------
import * as React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//-----------------------------------------------------------// Internal Imports // ------------------------------
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { fetchTagData } from '../../actions';
import CustomPopup from '../../components/common/CustomPopup';
import TagSelection from '../../components/common/FunctionalComponents/TagSelection';
import Loader from '../../components/common/Loader';
import { ignContactDataApi } from '../../services/ApiService';
import { ERROR, PATCH, SUCCESS } from '../../services/constantService';
import { useCustomMessageHook } from '../../utils/Hooks/useCustomMessageHook';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../utils/languageNamespaces';

export default function AssignTag(props) {
  const {
    setIsPopupOpen,
    isPopupOpen,
    tagPopUp,
    label,
    tagId = null,
    isBulkTag = false,
    setTagChange = () => {
      false;
    },
    getHeaderDetails = () => {},
    setIsEdited = () => {},
    selectedRows
  } = props;
  const { register, unregister, setValue } = useForm({});
  const [commonValue, setCommonValue] = React.useState([]);
  const [selectedTag, setSelectedTag] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_ASSIGN_TAG]);
  const [options, setOptions] = React.useState([]);
  const dispatch = useDispatch();
  const moduleTag = useSelector(state => state.commonReducer.moduleWiseTag);
  const { translateMessage } = useCustomMessageHook();

  useEffect(() => {
    register('tags');
    return () => {
      unregister('tags');
    };
  }, [register, unregister]);

  useEffect(() => {
    if (!moduleTag) {
      dispatch(fetchTagData('', 'moduleWiseTag', '?module=contacts&name='));
      setOptions(moduleTag);
    } else {
      setOptions(moduleTag);
    }
  }, [moduleTag, dispatch]);

  useEffect(() => {
    let objectValues;
    if (tagPopUp) {
      objectValues = options && options.map(item => item.name);
      const commonValues = tagPopUp.filter(value => objectValues.includes(value));
      setCommonValue(commonValues);
    }
  }, [tagPopUp]);

  const getValueOfSelected = () => {
    let array = [];
    for (let x of tagPopUp) {
      const index = options && options.findIndex(obj => obj?.name === x?.ign_tags?.name);
      array.push(index);
    }
    let value = [];
    for (let y of array) {
      value?.push(options?.[y]);
    }
    return value;
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const getPayload = (selectedTags, selectedRowData = '') => {
    let payload;
    const requestPayloadArray = [];

    if (isBulkTag) {
      selectedTags.forEach(tag => {
        selectedRowData.forEach(row => {
          payload = {
            tag_id: tag.id,
            ign_tags: {
              name: tag.name
            },
            contact_id: row.id
          };

          requestPayloadArray.push(payload);
        });
      });
    } else {
      selectedTag.map(item => {
        payload = {
          tag_id: item.id,
          ign_tags: {
            name: item.name
          }
        };
        requestPayloadArray.push(payload);
      });
    }
    return requestPayloadArray;
  };

  const handleSubmit = async () => {
    try {
      let payload;
      if (isBulkTag) {
        payload = {
          contact_tags: getPayload(selectedTag, selectedRows),
          assignTagToAll: true
        };
      } else {
        payload = { contact_tags: getPayload(selectedTag), contact_id: tagId };
      }
      const sub_route = 'assign/tags';
      setLoading(true);
      const { status } = await ignContactDataApi(PATCH, '', payload, sub_route);
      if (status === 200) {
        setTagChange(true);
        getHeaderDetails(payload);
        setIsEdited(true);
        const message = translateMessage('Successfully', false, 'Tag', 'Assigned');
        enqueueSnackbar(message, { variant: SUCCESS });
      } else {
        const message = translateMessage('UnableMessage', false, 'Assign Tag', '');

        enqueueSnackbar(message, { variant: ERROR });
      }
      setLoading(false);
      setIsPopupOpen(false);
    } catch (e) {
      console.log('Error found in handleSubmit::', e);
    }
  };

  return (
    <>
      <CustomPopup dropdown={true} title={t(`${TRANS_KEYS.CONTACTS_ASSIGN_TAG}:key`)} open={isPopupOpen} onClose={handleClose} showAction={true} handleSubmit={handleSubmit}>
        <Loader show={loading} className='h-75' />
        <Box id='assign-tag'>
          <Box className='tag-dropdown p-4'>
            <TagSelection
              {...register('tags')}
              className='tag-auto-complete'
              defaultValue={tagPopUp && isBulkTag === false ? getValueOfSelected() : []}
              commonValue={tagPopUp ? commonValue : null}
              label={label}
              options={options}
              setSelectedTag={setSelectedTag}
              setValue={setValue}
            />
          </Box>
        </Box>
      </CustomPopup>
    </>
  );
}

AssignTag.propTypes = {
  title: PropTypes.string,
  setIsPopupOpen: PropTypes.func,
  isPopupOpen: PropTypes.bool,
  tagPopUp: PropTypes.array,
  selectedRows: PropTypes.array,
  tags: PropTypes.array,
  label: PropTypes.string,
  tagId: PropTypes.string,
  isBulkTag: PropTypes.bool,
  setTagChange: PropTypes.func,
  getHeaderDetails: PropTypes.func,
  setIsEdited: PropTypes.func
};
