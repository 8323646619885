import { Launch } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import TabLayoutWithSteppers from '../TabLayoutWithSteppers/TabLayoutWithSteppers';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import BasicDetailsView from './BasicDetailsVeiw';
import KGPTeamInfoView from './KGPTeamInfoView';
import BillingDetailsView from './BillingDetailsView';
import { Link } from 'react-router-dom';
import { BD_PENDING_STATUS_MESSAGE } from '../../services/constantService';
//----------------------------------------------// External Imports // -------------------------------------------------

const ViewBdPopup = props => {
  const { editForm, defaultValues, setTabValueNumber, tabValueNumber = 0 } = props;
  const [tabValueOfBusinessDev, setTabValueOfBusinessDev] = useState(0);
  const editFormValue = () => {
    setTabValueNumber(tabValueOfBusinessDev);
    editForm(true);
  };
  const handleTabValue = value => {
    setTabValueOfBusinessDev(value);
  };
  const handleChange = () => {};
  return (
    <>
      <TabLayoutWithSteppers
        navItems={['Basic Information', 'Team Info', 'Billing Details']}
        tabValueNumber={tabValueNumber}
        setTabValueNumber={setTabValueNumber}
        showTab={tabValueNumber}
        handleTabValue={handleTabValue}
        tabChange={handleChange}
        fieldItem='ViewBd'
        bd={defaultValues}
        statusMessage={BD_PENDING_STATUS_MESSAGE}
      >
        <BasicDetailsView defaultValues={defaultValues} />
        <KGPTeamInfoView currentValues={defaultValues} />
        <BillingDetailsView defaultValues={defaultValues} />
      </TabLayoutWithSteppers>

      <Box className='bd-view-footer d-flex justify-content-between align-items-center'>
        <Box className='d-flex align-items-center action-edit' onClick={editFormValue}>
          <Box component={'span'} className='section-icon'>
            <EditIcon fontSize='inherit' />
          </Box>
          <Box component={'span'} className='edit-details-button'>
            Edit Details
          </Box>
        </Box>
        <Link to={`/projects/view-project/${defaultValues?.id}`} target='_blank' className='d-flex text-decoration-none align-items-center action-edit'>
          <Box component={'span'} className='section-icon'>
            <Launch fontSize='inherit' />
          </Box>
          <Box component={'span'} className='edit-details-button'>
            View Full Record
          </Box>
        </Link>
      </Box>
    </>
  );
};
ViewBdPopup.propTypes = {
  editForm: PropTypes.func,
  defaultValues: PropTypes.object,
  setTabValueNumber: PropTypes.func,
  tabValueNumber: PropTypes.number
};
export default ViewBdPopup;
