import { Delete } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import ViewContactDrawer from '../../../../../components/ViewContactDrawer';
import { CustomActionRenderer } from '../../../../../utils/ActionRenderer';
import { AthenaEmailInviteModal } from '../../../../../utils/AthenaInvite';
import { workbenchContactColumn } from './GridData';

function ContactGrid({ data, onSelect, handleDeleteContact }) {
  const tableData = workbenchContactColumn;
  const sx = {};

  const selector = {
    width: 60,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    pinned: 'left'
  };

  const actionOptions = [
    {
      name: 'Invite To Athena',
      onClick: elem => handleAthenaInviteOnClick(elem)
    }
  ];

  const handleDelete = async elem => {
    handleDeleteContact([elem]);
  };
  const otherActionOption = [
    {
      component: Delete,
      onClick: handleDelete
    }
  ];

  const columns = tableData;

  const onGridReady = params => {
    // columnApi = params.columnApi;
    params.columnApi.autoSizeColumns();
    // loadColumnStateFromLocalStorage("contactColumns", { columnApi, gridApi });
  };

  //Athena Invite
  const [athenaInviteModalOpen, setAthenaInviteModalOpen] = useState(false);
  const [athenaInviteData, setAthenaInviteData] = useState();

  //Contact Drawer
  const [selectedContactId, setSelectedContactId] = useState('');
  const openContactDrawer = id => {
    setSelectedContactId(id);
  };

  const handleAthenaInviteOnClick = async data => {
    const contact = {
      ...data.contact
    };

    setAthenaInviteData(contact);
    setAthenaInviteModalOpen(true);
  };

  const rowData = useMemo(() => {
    return data.map(elem => {
      return {
        ...elem,
        ...elem.contact,
        actions: <CustomActionRenderer item={elem} options={actionOptions} otherButtons={otherActionOption} />,
        name: <div onClick={() => openContactDrawer(elem?.contact_id)}>{elem?.contact?.name}</div>,
        email: elem.contact?.contact_emails[0]?.email,
        phone_number: elem.contact?.contact_phones[0]?.phone_number,
        company_name: elem.contact?.contact_company?.name,
        country: elem.contact?.contact_country_p1,
        status: elem?.contact?.contact_status,
        job_title: elem?.contact?.current_job_title,
        owner: elem?.contact?.created_user?.name,
        location: elem?.contact?.contact_address.length > 0 ? elem?.contact?.contact_address.length[0]?.city : ''
      };
    });
  }, [data]);
  return (
    <Stack id='myGrid' className='ag-theme-alpine workbench-table'>
      <AgGridReact
        columnDefs={[selector, ...columns]}
        rowData={rowData}
        suppressRowClickSelection={true}
        paginationPageSize={10}
        isRowSelectable={() => {
          return true;
        }}
        rowSelection='multiple'
        enableRangeSelection={true}
        defaultColDef={{
          resizable: true,
          filter: true,
          sortable: true
        }}
        // headerComponentFramework={CustomHeader}
        {...sx}
        onSelectionChanged={onSelect}
        columnMenu={true}
        onGridReady={onGridReady}
        render
      />
      <AthenaEmailInviteModal isOpen={athenaInviteModalOpen} onClose={() => setAthenaInviteModalOpen(false)} contact={athenaInviteData} />
      <ViewContactDrawer isDrawerOpen={selectedContactId.length} setIsDrawerOpen={() => setSelectedContactId('')} navigateToAllContacts={false} id={selectedContactId} />
    </Stack>
  );
}

ContactGrid.propTypes = {
  data: PropTypes.array,
  onSelect: PropTypes.func,
  handleDeleteContact: PropTypes.func
};

export default ContactGrid;
