import { GET, IGN_API } from '../constantService';
import httpService from '../httpService';

export const ignCandidateStatus = async (methodName, id = '', body = {}, sub_route = '') => {
  try {
    if (methodName === GET) {
      const result = id?.length > 0 ? await httpService.get(`${IGN_API.candidateStage}/${id}/${sub_route}`) : await httpService.get(`${IGN_API.candidateStage}/${sub_route}`);
      return result?.data;
    }
  } catch (e) {
    console.log('Error handling Company Off-Limit API:', e);
  }
};
