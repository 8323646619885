import { Box } from '@mui/material';
import React from 'react';
import './index.scss';

const ReportPage = () => {
  return (
    <React.Fragment>
      <Box>Report In-Progress</Box>
    </React.Fragment>
  );
};

export default ReportPage;
