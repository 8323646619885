//-----------------------------------------------------------// External Imports // ------------------------------

import Axios from 'axios';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import axios from './axios';
// import { Auth } from 'aws-amplify';
// import store from "../store/index";
// import { AWS_TOKEN_GRANT_TYPE_REFRESH_TOKEN } from "../services/constantService";
// import {
//   getAwsAppClientId,
//   getAwsTokenApiEndPoint,
// } from "../Containers/Commons/urlUtil";
// import { clearAuthFromLocalStorage } from "./cognitoService";
// import { RedirectToLogin } from "../Containers/Commons/TokenValidator";
/* import store from "../store/index" */
const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};
const getStaticHeaders = headers => {
  // The url should be TLD with .app inserted in between subdomain and domain
  const url = window.location.origin,
    subdomain = url.split('.')[0];

  let authHeaders = { ...headers };

  // For Sandbox
  const headersData = {
    'x-amzn-oidc-data':
      'eyJ0eXAiOiJKV1QiLCJraWQiOiI4MjQyNzM4Zi1iYTczLTQ3YzMtOGM2Ny1iYjIyMWMwYjA3YjMiLCJhbGciOiJFUzI1NiIsImlzcyI6Imh0dHBzOi8vY29nbml0by1pZHAudXMtZWFzdC0yLmFtYXpvbmF3cy5jb20vdXMtZWFzdC0yX3I4dG5VTFpObSIsImNsaWVudCI6IjJxOXBtc3ZtcGZwdmNtbWg2Nm9tZG5ib2JrIiwic2lnbmVyIjoiYXJuOmF3czplbGFzdGljbG9hZGJhbGFuY2luZzp1cy1lYXN0LTI6ODczMTg3NzAzNjI1OmxvYWRiYWxhbmNlci9hcHAvc2FuZGJveGVhYXMyLXNhbmRib3gtc2FuLTAwMTdhYTcvYmM4Nzg5ZjI3Yzk3NjdlOSIsImV4cCI6MTcyNTM1MDM5M30=.eyJzdWIiOiJiMmMyNzJkYS05YThjLTRkN2YtYmQ5NS1kNDU1YzVmOGIwNDEiLCJlbWFpbF92ZXJpZmllZCI6InRydWUiLCJlbWFpbCI6InNwcmFiaGFrYXJhK2RldjAwMStzYW5kQGtpbmdzbGV5Z2F0ZS5jb20iLCJ1c2VybmFtZSI6InNwcmFiaGFrYXJhK2RldjAwMStzYW5kIiwiZXhwIjoxNzI1MzUwMzkzLCJpc3MiOiJodHRwczovL2NvZ25pdG8taWRwLnVzLWVhc3QtMi5hbWF6b25hd3MuY29tL3VzLWVhc3QtMl9yOHRuVUxaTm0ifQ==.o9JyxGTYSKCnm8hRit3mxDQQJCDjKz1pBS8HnRtccBFGGib8ZK6kOG_IVR9MPODh-0tDlXtmVXWZFuRMjUopgQ==',
    'x-amzn-oidc-identity': 'b2c272da-9a8c-4d7f-bd95-d455c5f8b041',
    sub: 'b2c272da-9a8c-4d7f-bd95-d455c5f8b041',
    'x-amzn-oidc-accesstoken':
      'eyJraWQiOiJDOVh6Zmx3VjVpYlJzWVZXMXA1ZEhaRTh5Nm1UK0JyeVRNVHludWxLTXpRPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJiMmMyNzJkYS05YThjLTRkN2YtYmQ5NS1kNDU1YzVmOGIwNDEiLCJjb2duaXRvOmdyb3VwcyI6WyJhZG1pbi1ncm91cC1zZ2NvcnAtc2FuZGJveCIsImFkbWluLWdyb3VwLXNnY29ycCIsInNhbmRib3gtc2djb3JwLXNhbmRib3gtYWRtaW4tZ3JvdXAtc2djb3JwIiwic2FuZGJveC1zZ2NvcnAtc2FuZGJveC1iaWxsaW5nLWRlc2stZ3JvdXAtc2djb3JwIiwic2FuZGJveC1zZ2NvcnAtc2FuZGJveC1SZWdpb25IZWFkLUFQQUMtZ3JvdXAtc2djb3JwIl0sImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0yX3I4dG5VTFpObSIsInZlcnNpb24iOjIsImNsaWVudF9pZCI6IjJxOXBtc3ZtcGZwdmNtbWg2Nm9tZG5ib2JrIiwib3JpZ2luX2p0aSI6ImIyODE3NTc0LTYyNDgtNGJiOC1hZDVhLTNjMzY1Y2Y2NzBhNSIsImV2ZW50X2lkIjoiODQ1M2RlNWYtYjY0My00NTk4LTk4OTEtZTFjMzgyN2I2MDg5IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJvcGVuaWQiLCJhdXRoX3RpbWUiOjE3MjUzNDgwMTIsImV4cCI6MTcyNTM1MTYxMiwiaWF0IjoxNzI1MzQ4MDEyLCJqdGkiOiJmNTQxMWJlNS02ZDRlLTRiOGMtYTA1MS0yNzk1NTQyZmMwOWUiLCJ1c2VybmFtZSI6InNwcmFiaGFrYXJhK2RldjAwMStzYW5kIn0.f2ownq61E7zFaXaAwVRYhe_H1PQdYWA0zTeek1RcVXMYLHA_z0Zg1acudR3AEbc81ZJcTFhGx05sbFiaqI0Le-pkNt1atzAnRgag77oU1gaV6iuci2UcT87PrQazpW-fBfRnoiHRAgH8Tcm0yJ5EEDotLIaGM0Hh4z9L4fjS0Co_O413LvgFeFRvQMqmw5WcwX3BuyDQnnCPJM_s6VXzKMQVPcMqZdiE113qx9ymym0WNn8OCpcapTjDOKphnUZwH8ZO9Ujh-mynuX56gNwKh8dtxGDbbFKbIUTuNnuNE-KObgNwEcapZLBv3PpzHxZE64_YhpbsNirKUdnVJ5VECA'
  };

  // For Non-Prod
  // const headersData = {
  //   'x-amzn-oidc-data':
  //     'eyJ0eXAiOiJKV1QiLCJraWQiOiIyNTU3ZTgzNy0wNGE2LTQ5YzQtOTZiNS1hZWJhNDMxMDczYmIiLCJhbGciOiJFUzI1NiIsImlzcyI6Imh0dHBzOi8vY29nbml0by1pZHAudXMtZWFzdC0yLmFtYXpvbmF3cy5jb20vdXMtZWFzdC0yX3I4dG5VTFpObSIsImNsaWVudCI6IjJxOXBtc3ZtcGZwdmNtbWg2Nm9tZG5ib2JrIiwic2lnbmVyIjoiYXJuOmF3czplbGFzdGljbG9hZGJhbGFuY2luZzp1cy1lYXN0LTI6ODczMTg3NzAzNjI1OmxvYWRiYWxhbmNlci9hcHAvc2FuZGJveGVhYXMyLXNhbmRib3gtc2FuLTAwMTdhYTcvYmM4Nzg5ZjI3Yzk3NjdlOSIsImV4cCI6MTcxMjgyMzcwMH0.eyJzdWIiOiJiMmMyNzJkYS05YThjLTRkN2YtYmQ5NS1kNDU1YzVmOGIwNDEiLCJlbWFpbF92ZXJpZmllZCI6InRydWUiLCJlbWFpbCI6InNwcmFiaGFrYXJhQGtpbmdzbGV5Z2F0ZS5jb20iLCJ1c2VybmFtZSI6InNwcmFiaGFrYXJhIiwiZXhwIjoxNzEyODIzNzAwLCJpc3MiOiJodHRwczovL2NvZ25pdG8taWRwLnVzLWVhc3QtMi5hbWF6b25hd3MuY29tL3VzLWVhc3QtMl9yOHRuVUxaTm0ifQ.',
  //   'x-amzn-oidc-identity': 'b2c272da-9a8c-4d7f-bd95-d455c5f8b041',
  //   sub: 'b2c272da-9a8c-4d7f-bd95-d455c5f8b041',
  //   'x-amzn-oidc-accesstoken':
  //     'eyJraWQiOiJDOVh6Zmx3VjVpYlJzWVZXMXA1ZEhaRTh5Nm1UK0JyeVRNVHludWxLTXpRPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJiMmMyNzJkYS05YThjLTRkN2YtYmQ5NS1kNDU1YzVmOGIwNDEiLCJjb2duaXRvOmdyb3VwcyI6WyJhZG1pbi1ncm91cC1zZ2NvcnAtc2FuZGJveCIsImFkbWluLWdyb3VwLXNnY29ycCJdLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0yLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMl9yOHRuVUxaTm0iLCJ2ZXJzaW9uIjoyLCJjbGllbnRfaWQiOiIycTlwbXN2bXBmcHZjbW1oNjZvbWRuYm9iayIsIm9yaWdpbl9qdGkiOiJjZDRiZWFjMS0wNTM3LTQ5MzYtOWFiYy02NmYxZGNkM2QwNTciLCJldmVudF9pZCI6IjQ5MmQ4NDI1LWY4NmItNDAyMi1iOGRkLWRlYmUxZTI5NjliYSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoib3BlbmlkIiwiYXV0aF90aW1lIjoxNzEyODExNzM3LCJleHAiOjE3MTI4MjcxNzgsImlhdCI6MTcxMjgyMzU3OCwianRpIjoiOTZjOTk1ODUtYjg4Ny00NmE2LTg3N2QtOGZjZDVkM2U5YjNlIiwidXNlcm5hbWUiOiJzcHJhYmhha2FyYSJ9.'
  // };

  // If local development is enabled, use static headers to bypass ALB auth
  if (subdomain.includes('localhost') && process.env.REACT_APP_IS_LOCAL) {
    authHeaders = { ...authHeaders, ...headersData };
  }
  return authHeaders;
};

//const moment = require('moment')
/* Depricated
const addAuthorizationHeader = async (headers) => {
  let token;
  try {
    // token = await (await Auth.currentSession()).getAccessToken().getJwtToken()
    token = localStorage.getItem("token");
  } catch (e) {
    console.log("Error found in addAuthorizationHeader::", e);
    // await Auth.signOut();
  }

  const userData = store.getState()?.commonReducer?.userData;
  return {
    ...headers,
    Authorization: "Bearer " + token,
    tenantId: userData?.id,
  };
}; */

const requestHandler = async request => {
  const { noAuthorization = false, ...rest } = request.headers;

  request.headers = { ...defaultHeaders, ...rest };
  if (!noAuthorization) {
    /* Get headers for local machine environment */
    // request.headers = await addAuthorizationHeader(request.headers);
    request.headers = getStaticHeaders(request.headers);
  }
  return request;
};
axios.interceptors.request.use(request => requestHandler(request));

// const getNewAccessToken = async () => {
//   try {
//     //client without client secret
//     const body = {
//       grant_type: AWS_TOKEN_GRANT_TYPE_REFRESH_TOKEN,
//       refresh_token: localStorage.getItem("refresh_token"), // fetched from query params
//       client_id: getAwsAppClientId(),
//     };
//     const headers = {
//       "Content-Type": "application/x-www-form-urlencoded",
//       Authorization: "",
//     };
//     const response = await Axios.post(getAwsTokenApiEndPoint(), null, {
//       headers: headers,
//       params: body,
//     });
//     if (response && response.data && response.data.access_token) {
//       localStorage.setItem("token", response.data.access_token);
//     }
//   } catch (err) {
//     console.log("Error found in getNewAccessToken::", err);
//     if (err.response.data.error === "invalid_request") {
//       console.log("error while fetching refresh token : ", err);
//       if (localStorage.getItem("refresh_token") == null && localStorage.getItem("token") == null) {
//         RedirectToLogin();
//       }
//     } else if (err.response.data.error === "invalid_grant") {
//       console.log("Expired token : ", err);
//       clearAuthFromLocalStorage();
//       RedirectToLogin();
//     }
//   }
// };

const responseHandler = response => {
  return response;
};

const responseErrorHandler = async error => {
  const originalRequest = error.config;
  if (Axios.isCancel(error)) {
    return error;
  }
  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    try {
      // const cognitoUser = await Auth.currentAuthenticatedUser();
      // const currentSession = cognitoUser.signInUserSession;
      // cognitoUser.refreshSession(currentSession.refreshToken, async () => {
      //   axios.defaults.headers.common = await addAuthorizationHeader(axios.defaults.headers.common);
      //   return axios(originalRequest);
      // });
      // await getNewAccessToken();
      // return axios(originalRequest);
    } catch (e) {
      console.log('Error found in responseErrorHandler::', e);
      // await Auth.signOut();
    }
  }
  return error.response;
};

axios.interceptors.response.use(responseHandler, responseErrorHandler);
axios.defaults.timeout = 300000;
const httpService = {
  get: (url, params, customHeaders = {}, responseType = 'json', cancelToken) => {
    return axios({
      url,
      headers: { ...customHeaders },
      params,
      responseType,
      cancelToken
    });
  },
  post: (url, data, responseType = 'json', options) => {
    return axios.post(url, data, { responseType, ...options });
  },
  patch: (url, data, options) => {
    return axios.patch(url, data, options);
  },
  put: (url, data, options) => {
    return axios.put(url, data, options);
  },
  delete: (url, data) => {
    return axios.delete(url, { data });
  }
};

export default httpService;
