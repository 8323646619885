import React, { useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import IndirectFee from './Components/IndirectFee';
import PercentageOfBase from './Components/PercentageOfBase';
import BillingForm from './Components/BillingForm';
import { currencyWithCommas, getCurrencyPrefix } from '../../../Containers/Commons/Utils';
import TotalRevenue from './Components/EstimatedRevenue';
import { BILLING_VALIDATIONS } from '../../../services/constantService';
import { getFixedDecimal } from '../../../utils/common';

const BillingDetails = props => {
  const { register, watch, setValue, currentValues, currencyDetails, isHide } = props;
  const [totalEstimate, setTotalEstimate] = useState(0);
  const fe_Cap_amount = watch('fee_cap_amount');
  const is_fee_cap = watch('is_fee_cap');

  const getTotalPercentageOfBase = (data, key, formattedValue) => {
    const currency = getCurrencyPrefix(watch('currency') || '');
    const percentage =
      data?.reduce((prev, curr) => {
        if (curr[key]) prev = prev + Number(curr[key]);
        return prev;
      }, 0) || 0;
    return formattedValue ? getValueWithCurrency(percentage) : { currency, number: percentage };
  };
  const getGrandTotal = () => {
    const totalPercentageOfBase = getTotalPercentageOfBase(watch('estimated_percentage_bases'), 'calculated_estimated_amt', false);
    const totalIndirectFee = getTotalPercentageOfBase(watch('indirect_fees_searches'), 'calculation_amt');
    setValue('estimated_percentage_base', totalPercentageOfBase.number);
    setValue('estimated_indirect_total_fee', totalIndirectFee.number);
    setValue('estimated_revenue', totalPercentageOfBase?.number + totalIndirectFee?.number);
    setTotalEstimate(totalPercentageOfBase?.number + totalIndirectFee?.number);
    return getValueWithCurrency(totalPercentageOfBase?.number + totalIndirectFee?.number);
  };

  const getValueWithCurrency = data => {
    return `${getCurrencyPrefix(watch('currency') || '')} ${currencyWithCommas(getFixedDecimal(data || 0).toFixed(2))}`;
  };

  return (
    <div className='section-content flex-grow-1'>
      <BillingForm register={register} setValue={setValue} watch={watch} currentValues={currentValues} currencyDetails={currencyDetails} isHide={isHide} />
      <div className='mt-5 w-50 ml-3'></div>
      <div className='d-flex mt-5 ml-3'>
        <div className='w-75 mr-3'>
          <PercentageOfBase
            register={register}
            setValue={setValue}
            currentValues={currentValues}
            watch={watch}
            fee_type={watch('fee_type')}
            type='estimated'
            disabled={isHide}
            getValueWithCurrency={getValueWithCurrency}
          />
        </div>
        <div className='w-25'>
          <TotalRevenue watch={watch} getTotalPercentageOfBase={getTotalPercentageOfBase} getGrandTotal={getGrandTotal} disabled={isHide} />
          {is_fee_cap && Number(totalEstimate) > Number(fe_Cap_amount) && (
            <div style={{ marginTop: '23px', marginLeft: '15px' }}>
              <span style={{ color: 'red', marginTop: '20px' }}>{BILLING_VALIDATIONS.FEE_CAP_AMOUNT}</span>
            </div>
          )}
        </div>
      </div>
      <div className='mt-5 ml-3'>
        <IndirectFee
          register={register}
          setValue={setValue}
          currentValues={currentValues}
          watch={watch}
          getTotalPercentageOfBase={getTotalPercentageOfBase}
          disabled={isHide}
          getValueWithCurrency={getValueWithCurrency}
        />
      </div>
    </div>
  );
};

BillingDetails.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  currencyDetails: PropTypes.object,
  isHide: PropTypes.bool
};

export default BillingDetails;
