import React from 'react';
import PropTypes from 'prop-types';
import CustomPopup from '.';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import ContactMatchView from './ContactMatchView';

const DuplicateContactNotifier = props => {
  const { open = false, data, onConfirmData = () => {}, handleClose = () => {}, label, showAction = true, duplicationField } = props;
  const { t } = useLanguageHooks([TRANS_KEYS.DUPLICATE_RECORD_DETECTED]);
  const handleConfirm = () => {
    onConfirmData();
  };

  return (
    <CustomPopup
      title={t(`${TRANS_KEYS.DUPLICATE_RECORD_DETECTED}:key`)}
      open={open}
      onClose={handleClose}
      showAction={showAction}
      handleSubmit={handleConfirm}
      confirmText={label}
      disabled={data?.data?.exactMatch?.length > 0 ? true : false}
    >
      <ContactMatchView data={data} duplicationField={duplicationField} />
    </CustomPopup>
  );
};

DuplicateContactNotifier.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  final: PropTypes.object,
  onConfirmData: PropTypes.func,
  handleClose: PropTypes.func,
  label: PropTypes.string,
  showAction: PropTypes.bool,
  duplicationField: PropTypes.string
};

export default DuplicateContactNotifier;
