//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import TextField from '@mui/material/TextField';

/* eslint-disable-next-line react/display-name */
const InputField = React.forwardRef(({ ...rest }, ref) => {
  const typeSelector = rest;
  return (
    <TextField
      inputRef={ref}
      {...rest}
      onKeyDown={
        typeSelector?.type === 'number'
          ? typeSelector.flag && typeSelector.flag === 'true'
            ? e => (e.keyCode === 69 || /* e.keyCode === 190 || */ e.keyCode === 189 || e.keyCode === 107 || e.keyCode === 109) && e.preventDefault()
            : e => e.keyCode === 69 /* || e.keyCode === 190 */ && e.preventDefault()
          : null
      }
    />
  );
});

export default InputField;
