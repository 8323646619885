import CloseIcon from '@mui/icons-material/Close';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CustomPopup from '../CustomPopup';
import './index.scss';

/* file drag and drop */
const FileDragUpload = props => {
  const {
    /* setValue, */ label,
    width = 300,
    disabled,
    handleChangeDocument = () => {},
    types = [],
    acceptedFileTypes, // use - application/vnd.openxmlformats-officedocument.wordprocessingml.document for docx files
    className,
    children,
    isShowOutLineIcon = true,
    showFileName = true,
    fileUpoaderClass = '',
    horizontal = false,
    customFileName = null,
    handleDeleteFile = () => {},
    isDeleteConfirmation = false,
    maxSize
  } = props;
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_CREATE_FROM_RESUME, TRANS_KEYS.CONTACTS_FILE_NAME]);
  const [file, setFiles] = useState(null);
  const [isDeletePopup, setDeletePopup] = useState(false);

  const handleChange = file => {
    if (validateFile(file)) {
      setFiles(file);
      handleChangeDocument(file);
    } else {
      // setFiles(null);
      enqueueSnackbar('Invalid file type', { variant: 'error', preventDuplicate: true });
    }

    // setValue("file", file)
  };

  const deleteFile = () => {
    if (isDeleteConfirmation) {
      setDeletePopup(true);
    } else {
      setFiles(null);
    }
  };
  const validateFile = file => {
    if (!acceptedFileTypes) return true;
    if (file) {
      console.log(file.type);
      if (acceptedFileTypes.includes(file.type)) {
        return true;
      } else {
        enqueueSnackbar('Invalid file type', { variant: 'error', preventDuplicate: true });
        return false;
      }
    }
    return false;
  };
  useEffect(() => {
    setFiles({ name: customFileName });
  }, [customFileName]);

  return (
    <>
      {/** Pop-up for deletion of file confirmation */}
      <CustomPopup
        open={isDeletePopup}
        handleSubmit={async () => {
          //remove file from be
          await handleDeleteFile();
          setDeletePopup(false);
          setFiles(null);
        }}
        onClose={() => setDeletePopup(false)}
        title='Are you sure you want to delete this file?'
        confirmText='Delete'
        cancelText='Cancel'
        showAction={true}
      />
      <Box className={`fileUploader ${horizontal ? 'flex align-items-baseline' : ''}`}>
        <FileUploader
          handleChange={handleChange}
          name='file'
          classes={fileUpoaderClass}
          types={types}
          maxSize={maxSize}
          onSizeError={() => {
            enqueueSnackbar('File too big!', { variant: 'error', preventDuplicate: true });
          }}
        >
          {isShowOutLineIcon && (
            <Box width={width} className={`formParent ${className}`} style={disabled ? { background: 'rgb(0 0 0 / 12%)', borderColor: 'rgb(0 0 0 / 12%)' } : {}}>
              <Box className='font-size fs-13 d-flex align-items-center' style={disabled ? { color: 'rgb(0 0 0 / 35%)' } : {}}>
                {label}
                <UploadFileOutlinedIcon className='pl-1 fs-28' />
              </Box>
            </Box>
          )}
          {children}
        </FileUploader>
        {showFileName && (
          <Box className={`font-size name-width ${horizontal ? 'flex ml-2' : ''}`}>
            <Box>{file && file?.name !== null ? `${t(`${TRANS_KEYS.CONTACTS_FILE_NAME}:key`)} : ${file?.name || ''}` : ''}</Box>
            {file && file?.name !== null ? <CloseIcon onClick={deleteFile} className='close-icon' /> : ''}
          </Box>
        )}
      </Box>
    </>
  );
};
FileDragUpload.propTypes = {
  setValue: PropTypes.func,
  label: PropTypes.string,
  width: PropTypes.number,
  handleChangeDocument: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  isShowOutLineIcon: PropTypes.bool,
  showFileName: PropTypes.bool,
  fileUpoaderClass: PropTypes.string,
  horizontal: PropTypes.bool,
  customFileName: PropTypes.string,
  handleDeleteFile: PropTypes.func,
  isDeleteConfirmation: PropTypes.bool,
  acceptedFileTypes: PropTypes.array,
  types: PropTypes.array,
  maxSize: PropTypes.number,
  disabled: PropTypes.bool
};
export default FileDragUpload;
