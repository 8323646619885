import React from 'react';

import FmdGoodIcon from '@mui/icons-material/FmdGood';
import UpdateIcon from '@mui/icons-material/Update';
import { Box, ListItem, Skeleton, Typography } from '@mui/material';
import NoteImg from '../../../assets/images/note.svg';
import { Tags } from '../Components/Tags';

export default function CustomSkeletonCard() {
  return (
    <ListItem sx={{ padding: 0, marginBottom: 2 }}>
      <Box display={'flex'} width={'100%'} height={'10vh'} borderBottom={'1px solid rgba(0, 0, 0, 0.3)'}>
        <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <Skeleton variant='circular' width={'53px'} height={'53px'} />
        </Box>

        <Box display={'flex'} marginLeft={3} width={'100%'}>
          <Box display={'flex'} flex={1} flexDirection={'column'} justifyContent={'center'}>
            <Typography sx={{ cursor: 'pointer' }} color={'black'} fontSize={14} fontWeight={900}>
              <Skeleton variant='text' width={'100px'} sx={{ fontSize: 14 }} />
            </Typography>
            <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr'} alignItems={'center'}>
              <Box marginY={1} display={'flex'} alignItems={'center'}>
                <img width={'12px'} height={'12px'} src={NoteImg} alt='Company : Kingsley Gate Partners' />
                <Skeleton variant='text' width={'100px'} sx={{ marginLeft: 1, fontSize: 10 }} />
              </Box>
              <Typography display={'flex'} color={'#1b4965'} marginY={1} fontSize={10} fontWeight={500}>
                <FmdGoodIcon sx={{ width: '14px', height: '14px', marginRight: 0.5, color: '#979FB5' }} />
                <Skeleton variant='text' sx={{ fontSize: 10 }} width={'100px'} />
              </Typography>
              <Typography display={'flex'} color={'#1b4965'} marginY={1} fontSize={10} fontWeight={500}>
                <UpdateIcon sx={{ width: '14px', height: '14px', marginRight: 0.5, color: '#1b4965' }} />
                <Skeleton variant='text' sx={{ fontSize: 10 }} width={'100px'} />
              </Typography>
              <Typography color={'#959fb7'} marginY={1} fontSize={10} fontWeight={500}>
                <Tags tags={[]} loading />
              </Typography>
              <Typography color={'#1b4965'} marginY={1} fontSize={9} fontWeight={600}>
                <Box display={'flex'} alignItems={'center'}>
                  <Skeleton variant='text' sx={{ fontSize: 10 }} width={'100px'} />
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
}
