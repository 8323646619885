import WarningIcon from '@mui/icons-material/Warning';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import MarkOffLimits from '../../../../components/common/MarkOffLimits';
import ShowOffLimits from '../../../../components/common/ShowOffLimits';
import { useOfflimit } from '../../../../utils/Hooks/useOfflimit';

export const OfflimitRenderer = props => {
  const { value } = props;

  const contactId = value?.contactId;
  const companyId = value?.companyId;
  const [directOffLimit, setDirectOfflimit] = useState(value?.offlimits || []);

  const isOffLimitApplied = directOffLimit?.length > 0;
  const isDirectLimitApplied = directOffLimit?.find(entry => entry.type === 'Direct');

  const offLimitClass = useMemo(() => {
    const isHardLimitPresent = directOffLimit?.find(offlimit => offlimit?.severity === 'hard');
    return isHardLimitPresent ? 'hard' : 'soft';
  }, [directOffLimit]);

  const [showContactOffLimit, setShowContactOffLimit] = useState(false);
  const [isDirectOffLimitPopupOpen, setIsDirectOffLimitPopupOpen] = useState(false);
  const { createDirectOfflimit, updateDirectOfflimit, deleteDirectOfflimit } = useOfflimit();

  const handleOffLimitInfo = () => {
    setShowContactOffLimit(false);
  };

  const handleCloseDirectOffLimitPopup = () => {
    setIsDirectOffLimitPopupOpen(false);
  };

  const onSubmitOffLimit = async (payload, setIsLoading, newValue) => {
    const obj = { ...payload, contactID: contactId };
    const isEditMode = showContactOffLimit && !!isDirectLimitApplied;
    const contactOffLimitAction = isEditMode ? updateDirectOfflimit : createDirectOfflimit;
    await contactOffLimitAction(obj);
    setIsLoading(false);
    handleCloseDirectOffLimitPopup();
    handleOffLimitInfo();
    setDirectOfflimit([newValue]);
  };

  const onDeleteContactOffLimit = async setIsLoading => {
    await deleteDirectOfflimit(contactId);
    setIsLoading(false);
    handleCloseDirectOffLimitPopup();
    handleOffLimitInfo();
  };

  const showOfflimitHandler = async () => {
    if (directOffLimit?.length === 0) return;
    setShowContactOffLimit(true);
  };

  return (
    <Box display={'flex'} justifyContent={'center'}>
      {isOffLimitApplied ? (
        <WarningIcon sx={{ cursor: 'pointer' }} onClick={showOfflimitHandler} className={`offlimit-caution ${isOffLimitApplied ? offLimitClass : ''}`} size='10px' />
      ) : (
        <Typography>--</Typography>
      )}
      {showContactOffLimit && (
        <ShowOffLimits
          showInherited={true}
          showDirect={true}
          isPopupOpen={true}
          handleClose={handleOffLimitInfo}
          onEdit={() => {
            setIsDirectOffLimitPopupOpen(true);
          }}
          data={directOffLimit}
          contactId={contactId}
          companyId={companyId}
        />
      )}

      {isDirectOffLimitPopupOpen && (
        <MarkOffLimits
          data={[isDirectLimitApplied]}
          editMode={showContactOffLimit && !!isDirectLimitApplied}
          isPopupOpen={isDirectOffLimitPopupOpen}
          handleClose={handleCloseDirectOffLimitPopup}
          onSubmit={onSubmitOffLimit}
          onDelete={onDeleteContactOffLimit}
        />
      )}
    </Box>
  );
};

OfflimitRenderer.propTypes = {
  value: PropTypes.shape({
    contactId: PropTypes.string,
    companyId: PropTypes.string,
    offlimits: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        severity: PropTypes.string
      })
    )
  })
};

OfflimitRenderer.defaultProps = {
  value: {
    contactId: '',
    companyId: '',
    offlimits: []
  }
};
