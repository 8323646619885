import React, { useState } from 'react';
import CustomDropdown from '../../CustomDropdown';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchPickLists } from '../../../../actions';

const ShowInSelection = props => {
  const {
    name = '',
    defaultValue,
    onChange = () => {},
    disabled = false,
    isDrawer = false,
    multiple = false,
    placeholder,
    disableCloseOnSelect = false,
    className = '',
    isCheckBox = false,
    label = '',
    isForCompany = false
  } = props;
  const [options, setOptions] = useState([]);
  const showInOption = useSelector(state => state.commonReducer.showIn);
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [option, setOption] = useState(false);

  const getValueOfSelected = () => {
    let array = [];
    for (let x of defaultValue) {
      const index = options && options.findIndex(obj => obj?.field_value === x?.name);
      array.push(index);
    }
    let value = [];
    for (let y of array) {
      value.push(options[y]);
    }
    return value;
  };
  const handleChange = (e, item) => {
    setOption(true);
    setValues(item);
    onChange(item);
  };

  //key will replaced by document type key
  useEffect(() => {
    if (!showInOption) {
      dispatch(fetchPickLists('SHOW_IN', 'showIn'));
    } else {
      if (isForCompany) {
        const array = showInOption.slice(0, -1);
        setOptions(array);
      } else {
        setOptions(showInOption);
      }
    }
  }, [showInOption, dispatch]);

  return (
    <>
      {/* <CustomDropdown options={options} placeholder={placeholder} onChange={onChange} label={label} multiple={multiple} defaultValue={value} /> */}
      <CustomDropdown
        options={options}
        //defaultValue={defaultValue}
        name={name}
        value={option ? values : defaultValue ? getValueOfSelected() : []}
        onChange={handleChange}
        disabled={disabled}
        isDrawer={isDrawer}
        multiple={multiple}
        placeholder={placeholder}
        disableCloseOnSelect={disableCloseOnSelect}
        className={className}
        isCheckBox={isCheckBox}
        label={label}
        selectedValue={defaultValue}
      ></CustomDropdown>
    </>
  );
};
ShowInSelection.propTypes = {
  defaultValue: PropTypes.array,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isDrawer: PropTypes.bool,
  multiple: PropTypes.bool,
  isCheckBox: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool,
  selectedList: PropTypes.array,
  isForCompany: PropTypes.bool
};
export default ShowInSelection;
