//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import ThinkingStrategically from '../../../../../../assets/images/attr_thinking_strategically.png';
import Collaborating from '../../../../../../assets/images/attr_collaborating.png';
import InstillingConfidence from '../../../../../../assets/images/attr_instilling_confidence.png';
import Fostering from '../../../../../../assets/images/attr_fostering.png';
import LearningAdapting from '../../../../../../assets/images/attr_learning_adapting.png';
import DelegatingResp from '../../../../../../assets/images/attr_delegating_resp.png';
import Stimulating from '../../../../../../assets/images/attr_stimulating.png';
import KnowingOneself from '../../../../../../assets/images/attr_knowing_oneself.png';
import ManagingResources from '../../../../../../assets/images/attr_managing_resources.png';
import ConnectingWithPeople from '../../../../../../assets/images/attr_connecting_with_people.png';
import ResolvingConflict from '../../../../../../assets/images/attr_resolving_conflict.png';
import IncreasingKnowledge from '../../../../../../assets/images/attr_increasing_knowledge.png';
import BuildingAndDeveloping from '../../../../../../assets/images/attr_building_developing.png';
import Leading from '../../../../../../assets/images/attr_leading.png';
import ActingDecismely from '../../../../../../assets/images/attr_acting_decismely.png';
import DrivingPerformance from '../../../../../../assets/images/attr_driving_performance.png';
import Influencing from '../../../../../../assets/images/attr_influencing.png';
import SolvingProb from '../../../../../../assets/images/attr_solving_prob.png';
import Demonstrating from '../../../../../../assets/images/attr_demonstrating.png';
import LinkingObjects from '../../../../../../assets/images/attr_linking_objects.png';

export const getIcon = name => {
  switch (name) {
    case 'Linking Objectives to Customer Value Creation':
      return <img src={LinkingObjects} alt='Linking Objectives to Customer Value Creation' />;
    case 'Driving Performance':
      return <img src={DrivingPerformance} alt='Driving Performance' />;
    case 'Building and Developing People and Teams':
      return <img src={BuildingAndDeveloping} alt='Building and Developing People and Teams' />;
    case 'Connecting with People and the Vision of the Enterprise':
      return <img src={ConnectingWithPeople} alt='Connecting with People and the Vision of the Enterprise' />;
    case "Stimulating & Expanding People's Thinking":
      return <img src={Stimulating} alt="Stimulating & Expanding People's Thinking" />;
    case 'Fostering Open Communications':
      return <img src={Fostering} alt='Fostering Open Communications' />;
    case 'Solving Problems':
      return <img src={SolvingProb} alt='Solving Problems' />;
    case 'Acting Decisively':
      return <img src={ActingDecismely} alt='Acting Decisively' />;
    case 'Increasing Knowledge of the Business':
      return <img src={IncreasingKnowledge} alt='Increasing Knowledge of the Business' />;
    case 'Managing Resources':
      return <img src={ManagingResources} alt='Managing Resources' />;
    case 'Delegating Responsibility & Authority':
      return <img src={DelegatingResp} alt='Delegating Responsibility & Authority' />;
    case 'Demonstrating Courage':
      return <img src={Demonstrating} alt='Demonstrating Courage' />;
    case 'Collaborating Across Boundaries':
      return <img src={Collaborating} alt='Collaborating Across Boundaries' />;
    case 'Influencing & Inspiring':
      return <img src={Influencing} alt='Influencing & Inspiring' />;
    case 'Leading with Authenticity':
      return <img src={Leading} alt='Leading with Authenticity' />;
    case 'Resolving Conflict':
      return <img src={ResolvingConflict} alt='Resolving Conflict' />;
    case 'Knowing Oneself and Fine Tuning Impact on Others':
      return <img src={KnowingOneself} alt='Knowing Oneself and Fine Tuning Impact on Others' />;
    case 'Learning and Adapting':
      return <img src={LearningAdapting} alt='Learning and Adapting' />;
    case 'Instilling Confidence':
      return <img src={InstillingConfidence} alt='Instilling Confidence' />;
    case 'Thinking Strategically':
      return <img src={ThinkingStrategically} alt='Thinking Strategically' />;
    default:
      return <img src='' alt='NA' />;
  }
};
