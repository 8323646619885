import Rating from '@mui/material/Rating';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../components/common/CustomButton';
import CustomDropdown from '../../../../components/common/CustomDropdown';
import Loader from '../../../../components/common/Loader';
import RichText from '../../../../components/common/RichText';
import CustomInputField from '../../../../components/common/StyledComponents/CustomInputField';
import { picklistsDataApis } from '../../../../services/ApiService';
import { DROP_DOWN_PICKLIST, GET } from '../../../../services/constantService';
import useApi from '../../../../utils/Hooks/useApiHook';
function Modal({ data, isLoader = false, onCancel = () => {}, onSave = () => {} }) {
  console.log({ data });
  const { data: dispositionButton } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, 'DISPOSITION_NAME');
    }
  });
  console.log({ dispositionButton });
  const [selectedButton, setSelectedButton] = useState('');
  const [loader, setLoader] = useState(false);
  const ACCEPT = 'approved';
  const REJECT = 'rejected';
  const [nextStep, setNextStep] = useState('');
  const [reason, setReason] = useState([]);

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const [rejectReasons, setRejectReason] = useState([]);

  const fetchRejectReason = async () => {
    try {
      setLoader(true);
      const res = await picklistsDataApis(GET, DROP_DOWN_PICKLIST.REJECT_REASON);
      setRejectReason(res?.data && res?.data.length > 0 ? res?.data : []);
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const handleSave = () => {
    const feedback = {
      type: selectedButton,
      rating,
      comment,
      reason,
      nextStep
    };
    onSave(feedback);
  };

  useEffect(() => {
    fetchRejectReason();
  }, []);
  useEffect(() => {
    setLoader(isLoader);
  }, [isLoader]);
  return (
    <div className='pt-2 pb-2 w-100'>
      <Loader show={loader} />
      <div className='d-flex'>
        {dispositionButton &&
          dispositionButton?.data &&
          dispositionButton?.data?.map(button => (
            <CustomButton
              key={button?.id}
              buttonText={button?.short_desc}
              variant={'text'}
              onClick={() => setSelectedButton(button?.field_value)}
              type={`tertiary-error mr-1 border-red ${selectedButton == button?.field_value ? 'theme-color-bg theme-color-text-white' : ''}`}
            />
          ))}
        {/* <CustomButton
          buttonText={'Accept'}
          variant={'text'}
          onClick={() => setSelectedButton(ACCEPT)}
          type={`tertiary-error mr-1 border-red ${selectedButton == ACCEPT ? 'theme-color-bg theme-color-text-white' : ''}`}
        />
        <CustomButton
          buttonText={'Reject'}
          variant={'text'}
          onClick={() => setSelectedButton(REJECT)}
          type={`tertiary-error border-red  ${selectedButton == REJECT ? 'theme-color-bg theme-color-text-white' : ''}`}
        /> */}
      </div>
      <div className='mt-4'>
        {selectedButton == ACCEPT && <CustomInputField label='Next Steps' className='w-100' onChange={e => setNextStep(e.target.value)} />}
        {selectedButton == REJECT && (
          <>
            <CustomDropdown options={rejectReasons} label='Reason' className={'w-100'} onChange={(event, value) => setReason(value)} multiple={true} isCheckBox={true} />
          </>
        )}
      </div>

      <div className='mt-4'>
        <div className='fs-12'>Rating</div>
        <Rating
          name='simple-controlled'
          className='mt-1'
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
          size='large'
        />
      </div>

      <div className='mt-2'>
        <div className='mb-1 fs-12'>Comments</div>
        <RichText onChange={val => setComment(val)} />
      </div>

      <div className='mt-4 d-flex justify-content-end align-items-end'>
        <CustomButton buttonText={'Cancel'} variant={'text'} onClick={onCancel} type={'tertiary-error mr-1 '} />
        <CustomButton buttonText={'Save'} variant={'text'} onClick={handleSave} type={'tertiary-error border-red theme-color-bg theme-color-text-white'} />
      </div>
    </div>
  );
}

Modal.propTypes = {
  data: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  isLoader: PropTypes.bool
};

export default Modal;
