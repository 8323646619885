import React from 'react';
import CustomDropdown from './CustomDropdown';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../actions';
import { useState } from 'react';

const CurrencyUnitSelection = props => {
  const { placeholder, onChange = () => {}, label = '', defaultValue = '', required = false, ...rest } = props;
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const currencyUnitOption = useSelector(state => state.commonReducer.currencyUnit);

  useEffect(() => {
    if (!currencyUnitOption) {
      dispatch(fetchPickLists('CURRENCY_UNIT', 'currencyUnit'));
    } else {
      setOptions(currencyUnitOption);
    }
  }, [currencyUnitOption, dispatch]);
  return (
    <div>
      <CustomDropdown {...rest} required={required} options={options} placeholder={placeholder} onChange={onChange} label={label} defaultValue={defaultValue} />
    </div>
  );
};
CurrencyUnitSelection.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.any
};

export default CurrencyUnitSelection;
