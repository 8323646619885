//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import TeamInfoTable from './TeamInfoTable';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
const PartnerInformation = props => {
  const { register, setValue, currentValues = {}, isEditing, option, bd } = props;
  const fields = {
    origination_credit: 30,
    selling_credit: 20,
    execution_credit: 50
  };
  const { t } = useLanguageHooks([TRANS_KEYS.BD_CONSULTANT, TRANS_KEYS.BD_TEAM_CONSULTANT_TITLE]);
  const [partners, setPartners] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);

  useEffect(() => {
    if (partners && partners.length > 0) {
      const options = option.filter(array_el => {
        return partners.every(anotherOne_el => anotherOne_el?.user?.id !== array_el?.id);
      });
      setSelectedPartners(options);
    } else {
      setSelectedPartners(option);
    }
  }, [register, partners, setValue]);

  useEffect(() => {
    register('partners');
    setValue('partners', partners, { shouldDirty: true });
  }, [register, partners, setValue]);
  useEffect(() => {
    setPartners(currentValues.partners);
  }, [currentValues.partners]);

  return (
    <>
      <TeamInfoTable
        title={t(`${TRANS_KEYS.BD_TEAM_CONSULTANT_TITLE}:key`)}
        label={t(`${TRANS_KEYS.BD_CONSULTANT}:key`)}
        inputConfig={{ list: partners, fields, setter: setPartners }}
        isEditing={isEditing}
        option={selectedPartners}
        currentValues={currentValues}
        bd={bd}
        isPartner
      />
    </>
  );
};

PartnerInformation.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  option: PropTypes.array,
  bd: PropTypes.string
};

export default PartnerInformation;
