//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from 'react';
//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  SUBMIT_APPROVAL_STATUS,
  ERROR,
  REGIONAL_ROLE,
  BILLING_DESK_ROLE,
  BILLING_VALIDATIONS,
  STATUS_CHECK,
  PENDING_BILLING_ACKNOWLEDGEMENT,
  NOTIFY_BILLING_ACKNOWLEDGEMENT,
  PENDING_REGIONAL_BILLING_STATUS,
  ADMIN_ROLE,
  PENDING_ACKNOWLEDGMENT,
  OPEN
} from '../../../../services/constantService';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { getUserSelector } from '../../../../selectors';
import RejectReasonPopup from '../../../../Containers/Commons/RejectReasonPopup';

export default function ApproveRejectAction(props) {
  const { location, billingStatus, handleSave, handleApproval, search, enqueueSnackbar } = props;
  const userData = useSelector(getUserSelector);
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   const disableEdit = checkPmRecruiter(userData, search, true)
  //   // if (disableEdit) {
  //   //   setIsVisible(true)
  //   // }
  // }, [search])

  const reject = data => {
    let payload;
    // if (userData.roles.includes('BILLING_DESK') || userData.roles.includes('admin')) {
    //   payload = {
    //     billing_status: 'REJ_EST',
    //     action: 'reject'
    //   }
    // } else {
    payload = {
      billing_status: 'REJ_RHEAD',
      bd_reject_reason: data,
      revenue_changed: search.revenue_changed,
      action: 'reject'
    };
    // }
    handleApproval(payload);
  };

  const approve = () => {
    let payload;
    // if (userData.roles.includes('BILLING_DESK') || userData.roles.includes('admin')) {
    //   payload = {
    //     billing_status: 'APR_EST',
    //     stage: 'OPEN',
    //     action: 'approve'
    //   }
    // } else {
    payload = {
      billing_status: 'APR_RHEAD',
      revenue_changed: search.revenue_changed,
      action: 'approve'
    };
    // }
    handleApproval(payload);
  };

  const popupOpen = () => {
    setOpen(true);
  };

  const onClose = value => {
    setOpen(value);
  };

  const acknowledge = () => {
    if (billingStatus === 'PND_ACK_EST' && search && !search.loe_date) {
      enqueueSnackbar(BILLING_VALIDATIONS.LOE_DATE, { variant: ERROR });
      return;
    }
    let payload;
    if (userData.roles.includes(ADMIN_ROLE) || checkBillingRole()) {
      if (billingStatus === 'PND_ACK_EST') {
        payload = {
          billing_status: 'ACK_DONE_EST',
          job_title: search && search.job_title,
          revenue_changed: search && search.revenue_changed,
          stage: OPEN,
          action: 'acknowledgeEst'
        };
      } else {
        payload = {
          billing_status: 'ACK_DONE',
          job_title: search && search.job_title,
          revenue_changed: search && search.revenue_changed,
          stage: OPEN,
          action: 'acknowledge'
        };
      }
    }
    handleApproval(payload);
  };

  const checkRegionRoleWithMarketValue = () => {
    if (userData.roles.includes(ADMIN_ROLE)) {
      return true;
    } else {
      if (userData.all_roles && userData.all_roles?.length > 0) {
        return userData.all_roles.some(item => {
          if (item === 'admin') {
            return true;
          } else {
            return userData.all_roles.some(item => item === (search.markets ? search.markets.regional_head_role_name : null));
          }
        });
      }
    }
  };

  const checkRegionalRole = regional => {
    if (regional) {
      if (userData.all_roles && userData.all_roles?.length > 0) {
        return userData.all_roles.some(item => item.includes(REGIONAL_ROLE));
      }
    } else {
      if (userData.roles.includes(ADMIN_ROLE)) {
        return true;
      } else {
        if (userData.all_roles && userData.all_roles?.length > 0) {
          return userData.all_roles.some(item => item.includes(REGIONAL_ROLE));
        }
      }
    }
  };

  const checkBillingRole = billing => {
    if (billing) {
      if (userData.all_roles && userData.all_roles?.length > 0) {
        return userData.all_roles.some(item => item.includes(BILLING_DESK_ROLE));
      }
    } else {
      if (userData.roles.includes(ADMIN_ROLE)) {
        return true;
      } else {
        if (userData.all_roles && userData.all_roles?.length > 0) {
          return userData.all_roles.some(item => item.includes(BILLING_DESK_ROLE));
        }
      }
    }
  };

  const actualRevenueChange = () => {
    if (search.old_actual_revenue !== null && search.old_actual_revenue !== undefined) {
      if (
        billingStatus === 'ACK_DONE' &&
        (search.actual_revenue !== search.old_actual_revenue || (search.actual_revenue === search.actual_overridden_revenue && search.actual_overridden_revenue !== search.old_actual_revenue))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  // const checkRole = () => {
  //   if (checkRegionalRole() || (checkBillingRole() || userData.roles.includes('admin')))
  //     return true;
  //   else
  //     return false;
  // }

  const notifyBillingDesk = () => {
    if (search && (search.actual_revenue === 0 || !search.actual_revenue)) {
      enqueueSnackbar(BILLING_VALIDATIONS.NOTIFY_BILLING, { variant: ERROR });
      return;
    }

    let payload = {
      billing_status: 'PND_ACK',
      stage: PENDING_ACKNOWLEDGMENT,
      action: 'notifyBillingDesk'
    };
    handleApproval(payload);
  };
  return (
    <div className='billing-info-approval'>
      {PENDING_REGIONAL_BILLING_STATUS.includes(billingStatus) && checkRegionRoleWithMarketValue() && (
        <div className='pending-reject-billing-status'>
          <div className='approve-reject-button my-3'>
            <Button size='small' variant='text' className='search-approval-btn approve-btn' onClick={approve}>
              Approve
            </Button>
            <Button className='search-approval-btn reject-btn' onClick={popupOpen}>
              Reject
            </Button>
          </div>

          {!STATUS_CHECK.includes(billingStatus) && !checkRegionalRole() && <span className='ml-auto approval-status'>{search.billing_desc}</span>}
        </div>
      )}
      {PENDING_BILLING_ACKNOWLEDGEMENT.includes(billingStatus) && checkBillingRole() && (
        <div className='d-flex billing-form'>
          <div className='ml-auto my-2'>
            <Button className='search-approval-btn btn-green' onClick={acknowledge}>
              Acknowledge
            </Button>
          </div>
        </div>
      )}
      {(NOTIFY_BILLING_ACKNOWLEDGEMENT.includes(billingStatus) || actualRevenueChange()) && !checkRegionalRole(true) && !checkBillingRole(true) && (
        <div className='d-flex billing-form'>
          <div className='ml-auto'>
            <Button className='search-approval-btn btn-green' onClick={notifyBillingDesk}>
              Notify Billing Desk
            </Button>
          </div>
        </div>
      )}
      {SUBMIT_APPROVAL_STATUS.includes(billingStatus) && !location?.state?.isFromApproval && !checkRegionalRole(true) && !checkBillingRole(true) && (
        <div className='d-flex billing-form'>
          <div className='ml-auto my-2'>
            <Button className='search-approval-btn btn-green' onClick={handleSave}>
              Notify Billing Desk
            </Button>
          </div>
        </div>
      )}
      {STATUS_CHECK.includes(billingStatus) && !checkRegionalRole() && !checkBillingRole() && (
        <div className='pending-reject-billing-status'>
          <span className='ml-auto approval-status'>{search.billing_desc}</span>
        </div>
      )}
      {billingStatus === 'PND_ACK' && !checkRegionalRole() && !checkBillingRole() && (
        <div className='pending-reject-billing-status'>
          <span className='ml-auto approval-status'>Billing Desk Notified</span>
        </div>
      )}
      {open && <RejectReasonPopup title={'Reject Reason'} reject={reject} onClose={onClose} enqueueSnackbar={enqueueSnackbar} />}
    </div>
  );
}

ApproveRejectAction.propTypes = {
  isEditing: PropTypes.bool,
  handleSave: PropTypes.func,
  location: PropTypes.object,
  billingStatus: PropTypes.string,
  handleApproval: PropTypes.func,
  search: PropTypes.object,
  enqueueSnackbar: PropTypes.func
};
