import { Grid } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { enqueueSnackbar } from 'notistack';
import React, { useContext, useMemo, useState } from 'react';
import CustomButton from '../../../../components/common/CustomButton';
import Loader from '../../../../components/common/Loader';
import CustomInputField from '../../../../components/common/StyledComponents/CustomInputField';
import { ProjectDataApi } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import CustomModalWithHeader from '../../../Commons/CustomModalWithHeader';
import { WorkBenchContext } from '../../Context';

export default function AddProjectFromExisting() {
  const { setAddProjectFromExistingModal, addProjectFromExistingModal, addRecord, refetchFunc } = useContext(WorkBenchContext);
  const [rowData, setRowData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const gridRef = React.useRef(null);
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_SEARCH, TRANS_KEYS.PROJECT_NAME, TRANS_KEYS.ADD_TO_WORKBENCH, TRANS_KEYS.ADD_EXISTING_PROJECT]);

  const onSelectionChanged = event => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => {
      return {
        project_id: node.data.id,
        id: undefined,
        ...node.data
      };
    });
    setSelectedIds(selectedData);
  };

  const fetchProjects = async () => {
    setLoading(true);
    if (inputValue) {
      const res = await ProjectDataApi(GET, '', {}, `query?searchValue=${inputValue}`);
      setRowData(res?.data?.data ?? []);
    }
    setLoading(false);
  };

  const addToProjectWorkbench = async () => {
    try {
      setLoading(true);
      for (let data of selectedIds) {
        await addRecord(null, data?.project_id);
      }
      enqueueSnackbar('Added to Workbench', 'success');
      setAddProjectFromExistingModal(false);
      setLoading(false);
      refetchFunc();
    } catch (err) {
      enqueueSnackbar(err, 'error');
    }
  };

  const columnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      sortable: false,
      suppressMenu: true,
      resizable: false,
      headerName: '',
      field: 'id'
    },
    {
      headerName: 'Project Name',
      field: 'job_title',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Company',
      field: 'company_name',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Updated At',
      field: 'updated_at',
      cellRendererFramework: params => {
        return params.value ? new Date(params.value).toLocaleDateString() : '';
      },
      sortable: true,
      filter: true
    },
    {
      headerName: 'Owner',
      field: 'created_by',
      sortable: true,
      filter: true
    }
  ];

  const formattedRowData = useMemo(() => {
    return rowData.map(elem => {
      return {
        ...elem,
        created_by: elem?.creator?.name,
        company_name: elem?.ign_companies?.name
      };
    });
  }, [rowData]);
  return (
    <>
      <CustomModalWithHeader
        isOpen={addProjectFromExistingModal}
        style={{ width: '70vw !important', minHeight: '50vh' }}
        onClose={() => setAddProjectFromExistingModal(!addProjectFromExistingModal)}
        label={t(`${TRANS_KEYS.ADD_EXISTING_PROJECT}:key`)}
        closeIcon={true}
      >
        <div className='min-vw-50 p-4'>
          <Loader show={isLoading} />
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={10}>
              <CustomInputField className='w-100' label={t(`${TRANS_KEYS.PROJECT_NAME}:key`)} onChange={e => setInputValue(e.target.value)} />
            </Grid>
            <Grid item xs={2}>
              <CustomButton buttonText={t(`${TRANS_KEYS.CONTACTS_SEARCH}:key`)} type='primary w-100' onClick={fetchProjects} />
            </Grid>
          </Grid>
          <div id='myGrid' className='mt-2 w-100 ag-theme-alpine ag-grid-container' style={{ height: '300px' }}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={formattedRowData}
              suppressRowClickSelection={true}
              paginationPageSize={10}
              rowSelection='multiple'
              enableRangeSelection={true}
              onSelectionChanged={onSelectionChanged}
              defaultColDef={{
                resizable: true,
                filter: true,
                sortable: true
              }}
              columnMenu={true}
              suppressChangeDetection={true}
            />
          </div>

          <div className='mt-2 d-flex justify-content-end'>
            <CustomButton buttonText={t(`${TRANS_KEYS.ADD_TO_WORKBENCH}:key`)} disabled={selectedIds.length == 0} onClick={addToProjectWorkbench} />
          </div>
        </div>
      </CustomModalWithHeader>
    </>
  );
}
