//-----------------------------------------------------------// In-built Imports // ------------------------------
import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import PropTypes from 'prop-types';
// import { useDispatch, useSelector } from "react-redux";

//-----------------------------------------------------------// Internal Imports // ------------------------------
import CustomDropdown from '../../CustomDropdown';
// import { fetchPickList } from "../../../../actions";

const ExportFilters = props => {
  const { label } = props;

  // const [options, setOptions] = useState([]);
  // const filterTypesSelection = useSelector(state => state.commonReducer.FilterType)
  // const dispatch = useDispatch()

  // useEffect(() => {
  //   if (!filterTypesSelection) {
  //     dispatch(fetchPickList('filter-type', 'filterType'))
  //   } else {
  //     setOptions(filterTypesSelection);
  //   }
  // }, [filterTypesSelection, dispatch])

  return <CustomDropdown className='w-100' options={filterTypes} isCheckBox={true} label={label} disableCloseOnSelect isIcon={true} multiple={true} />;
};

const filterTypes = [
  { logo: FilterAltRoundedIcon, label: 'Filter 1' },
  { logo: FilterAltRoundedIcon, label: 'Filter 2' },
  { logo: FilterAltRoundedIcon, label: 'Filter 3' },
  { logo: FilterAltRoundedIcon, label: 'Filter 4' }
];

ExportFilters.propTypes = {
  label: PropTypes.string
};

export default ExportFilters;
