//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Searches from './Searches';

export default function KGPPlacements() {
  return <Searches searchType='kgpsearches' columnStateKey='kgpPlacementsColumns' searchStatus='Placement' title='KGP Placements' placedSearches />;
}
