//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useRef } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Notes from '../../Notes';
import { contactActions } from '../../../store/contactSlice';
import { useDispatch, useSelector } from 'react-redux';

const ContactNotes = props => {
  const { contactId } = props;
  const contactNotes = useSelector(state => state.contactReducer.contactNotes);
  const dispatch = useDispatch();
  const notesRef = useRef();

  const contactDispatch = data => {
    dispatch(contactActions.updateContactNotes(data));
  };

  return (
    <div className='section-container mb-5'>
      <div className='section-header'>Notes</div>
      <Notes ref={notesRef} dispatchDetails={contactDispatch} notesDetails={contactNotes} apiConfig={{ parentId: contactId, parentPath: 'contact' }} className='contact-notes' />
      <div>
        <Button variant={'text'} startIcon={<AddCircleIcon />} onClick={() => notesRef.current.handleAddNotes()} color='primary'>
          Add Element
        </Button>
      </div>
    </div>
  );
};

ContactNotes.propTypes = {
  contactId: PropTypes.string
};

export default ContactNotes;
