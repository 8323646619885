//-----------------------------------------------------------//  Internal Imports // ------------------------------

import ExpenseSelection from '../../components/ExpenseSelection';
import { getFixedDecimal } from '../../utils/common';
import { formatDate, getDifferenceInDays } from '../../utils/date';
import { currencyWithCommas, getCurrencyPrefix, getDateFilterOptions, getFilterQuery, getMatchFilterOptions } from '../Commons/Utils';

export const getQueryParamsStringForInvoice = data => {
  let paramsString = '';
  if (data.ign_companies) {
    data.ign_companies.forEach(data => {
      paramsString = paramsString.concat(`&company=${data.id}`);
    });
  }
  if (data.projected_bill_date_from) {
    paramsString = paramsString.concat(`&projected_bill_date_from=${data.projected_bill_date_from}`);
  }
  if (data.projected_bill_date_to) {
    paramsString = paramsString.concat(`&projected_bill_date_to=${data.projected_bill_date_to}`);
  }
  if (data.term_type) {
    paramsString = paramsString.concat(`&term_type=${data.term_type.field_value}`);
  }
  if (data.partners) {
    data.partners.forEach(data => {
      paramsString = paramsString.concat(`&partners=${data.id}`);
    });
  }
  if (data.intervals) {
    paramsString = paramsString.concat(`&intervals=${data.intervals}`);

    if (data.intervals_desc === 'Other Interval') {
      paramsString = paramsString.concat(`&interval_other=${data.intervals_other}`);
    }
  }
  if (data.region) {
    paramsString = paramsString.concat(`&region=${data.region}`);
  }

  return paramsString;
};

export const getFilterParamStringForInvoiceSummery = data => {
  return getFilterQuery(data, {}, {}, '');
};

const getOutStandingDays = params => {
  const collectedDate = params.data?.collected_date;
  const billedDate = params.data?.billed_date;
  let daysOutstanding;
  if (!collectedDate && !billedDate) {
    daysOutstanding = '';
  } else if (collectedDate) {
    daysOutstanding = 0;
  } else if (billedDate) {
    daysOutstanding = getDifferenceInDays(new Date(), billedDate);
  }
  return daysOutstanding;
};

const getExpenses = data => {
  if (data.value === true) return 'Yes';
  else if (data.value === false) return 'No';
  else return '';
};

const getJobNumber = params => {
  return params?.data?.ProjectInvoiceInfo?.job_number;
};

const getJobTitle = params => {
  return params?.data?.ProjectInvoiceInfo?.job_title;
};

const getDateFormate = params => {
  return formatDate(params?.value);
};

const getValueWithCurrency = params => {
  let currency = getCurrencyPrefix(params?.data?.ProjectInvoiceInfo.currency);
  const colId = params?.colDef?.colId;
  return params.data ? `${currency || ''} ${currencyWithCommas(getFixedDecimal(params?.data[colId] || 0).toFixed(2))}` : null;
};

export const columnDefs = () => [
  {
    headerName: '',
    field: 'check_box',
    colId: 'check_box',
    maxWidth: 50,
    minWidth: 50,
    headerComponent: 'HeaderCheckbox',
    headerCheckboxSelectionFilteredOnly: false,
    checkboxSelection: true,
    sortable: false,
    pinned: 'left',
    lockPinned: true,
    lockPosition: true
  },
  {
    headerName: 'Project#',
    field: 'job_number',
    colId: 'job_number',
    cellRenderer: 'IdRenderer',
    tooltipValueGetter: getJobNumber,
    sortable: true,
    maxWidth: 200,
    minWidth: 150,
    suppressSizeToFit: true,
    pinned: 'left',
    lockPinned: true,
    editable: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equal to'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Title',
    field: 'job_title',
    colId: 'job_title',
    cellRenderer: 'TitleRenderer',
    tooltipValueGetter: getJobTitle,
    sortable: true,
    maxWidth: 400,
    minWidth: 200,
    pinned: 'left',
    lockPinned: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'invoice_label',
    colId: 'invoice_label',
    headerName: 'Invoice Label',
    tooltipField: 'invoice_label',
    editable: false,
    sortable: true,
    maxWidth: 300,
    minWidth: 150,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'invoice_id',
    colId: 'invoice_id',
    headerName: 'Invoice ID',
    tooltipField: 'invoice_id',
    editable: false,
    sortable: true,
    maxWidth: 200,
    minWidth: 150,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'projected_bill_date',
    colId: 'projected_bill_date',
    headerName: 'Projected Bill Date',
    cellRenderer: 'ProjectedDateRenderer',
    tooltipValueGetter: getDateFormate,
    minWidth: 150,
    maxWidth: 200,
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: getDateFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'expenses',
    colId: 'expenses',
    headerName: 'Expenses',
    cellRenderer: getExpenses,
    sortable: true,
    maxWidth: 200,
    minWidth: 100,
    filter: 'CustomFilter',
    filterParams: {
      Component: ExpenseSelection,
      field: 'id'
    }
  },
  {
    field: 'retainer',
    colId: 'retainer',
    tooltipField: 'retainer',
    headerName: 'Retainers',
    cellRenderer: params => getValueWithCurrency(params),
    sortable: true,
    maxWidth: 200,
    minWidth: 150,
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: getMatchFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'indirect_fee',
    colId: 'indirect_fee',
    tooltipField: 'indirect_fee',
    headerName: 'Indirect Fee',
    cellRenderer: params => getValueWithCurrency(params),
    sortable: true,
    maxWidth: 200,
    minWidth: 100,
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: getMatchFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Invoice Amount',
    field: 'invoice_amt',
    colId: 'invoice_amt',
    tooltipField: 'invoice_amount',
    sortable: true,
    maxWidth: 200,
    minWidth: 100,
    cellRenderer: params => getValueWithCurrency(params),
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: getMatchFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Date Billed',
    field: 'billed_date',
    colId: 'billed_date',
    tooltipValueGetter: getDateFormate,
    sortable: true,
    maxWidth: 200,
    minWidth: 100,
    cellRenderer: 'DateRenderer',
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: getDateFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Due By',
    field: 'due_by_date',
    colId: 'due_by_date',
    tooltipValueGetter: getDateFormate,
    sortable: true,
    maxWidth: 200,
    minWidth: 100,
    cellRenderer: 'DateRenderer',
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: getDateFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    headerName: 'Date Collected',
    field: 'collected_date',
    colId: 'collected_date',
    tooltipValueGetter: getDateFormate,
    sortable: true,
    maxWidth: 200,
    minWidth: 100,
    cellRenderer: 'DateRenderer',
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: getDateFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'days',
    colId: 'days',
    headerName: 'Days Outstanding',
    valueGetter: getOutStandingDays,
    width: 200
  },
  {
    field: 'collected_amt',
    colId: 'collected_amt',
    headerName: 'Collected Amount',
    editable: true,
    width: 180,
    cellRenderer: params => getValueWithCurrency(params),
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: getMatchFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'outstanding_amt',
    colId: 'outstanding_amt',
    headerName: 'Outstanding Amount',
    width: 200,
    cellRenderer: params => getValueWithCurrency(params),
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: getMatchFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  }
];
