//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useRef, useState } from 'react';

import { Box, Typography } from '@mui/material';

import CustomCard from '../../../common/CustomCard';
import CustomizedDialogs from '../../../common/CustomizedDialogs';
import PropTypes from 'prop-types';
import CustomButton from '../../../common/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import '../../index.scss';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';

const notesData = {
  noteType: 'published_bio',
  approved: 'is_bio_approved_by_partner'
};
const BioAndRecommendation = props => {
  const {
    bioCardHeight,
    bioCardWidth,
    register = () => {},
    setValue = () => {},
    handleSave = () => {},
    currentValues,
    isContactView = false,
    getContact = () => {},
    contact_id,
    getContactDetails = () => {},
    setLoading = () => {}
  } = props;
  const [isBioPopupOpen, setIsBioPopupOpen] = useState(false);
  const [bioData, setBioData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isMorePopupOpen, setIsMorePopupOpen] = useState(false);
  const [isInlineEditing, setIsInLineEditing] = useState(false);
  const [selectedType, setSelectedType] = useState();
  const isInlineEditingRef = useRef();
  isInlineEditingRef.current = isInlineEditing;
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_PUBLISHED_BIO, TRANS_KEYS.CONTACTS_WORD_ADD]);
  const notesTypeLabel = {
    label: t(`${TRANS_KEYS.CONTACTS_PUBLISHED_BIO}:key`)
  };
  const styleData = {
    height: bioCardHeight || 250,
    width: bioCardWidth || 400,
    minWidth: bioCardWidth || 400
  };
  const handleBioOpenPopup = () => {
    setIsBioPopupOpen(true);
  };
  useEffect(() => {
    const convertData = () => {
      let newData = [];
      if (currentValues && currentValues.length) {
        currentValues.forEach(itemEntry => {
          if (itemEntry.notes_type === notesData.noteType || itemEntry.notes_type === 'candidate_snapshot') {
            newData.push({
              [notesData.noteType]: itemEntry.notes,
              [notesData.approved]: itemEntry.is_partner_approved,
              note_id: itemEntry?.id ? itemEntry?.id : null,
              seq: itemEntry?.seq,
              updated_at: itemEntry?.updated_at
            });
          }
        });
      }
      console.log(newData, 'currentValues');
      return newData;
    };
    setBioData(convertData());
    setIsBioPopupOpen(false);
  }, [currentValues]);
  const handleBioSubmit = async () => {
    if (isEditing || isInlineEditingRef.current) {
      if (isContactView) {
        await handleSave('contact_notes_all', false);
      } else {
        await handleSave();
      }
      setIsEditing(false);
      setIsInLineEditing(false);
    } else {
      if (isContactView) {
        await handleSave('contact_notes_all', false);
      } else {
        await handleSave();
      }
    }
  };
  const editItems = () => {
    setIsEditing(true);
  };
  const viewItems = () => {
    setIsMorePopupOpen(true);
    // setIndexNumbers(indexValue)
    // setIsEditing(true)
  };
  const inlineEditing = type => {
    setIsInLineEditing(true);
    setSelectedType(type);
  };
  return (
    <Box className='personality_sub flex flex-column'>
      <Typography className='compensation-info' variant='body1'>
        {notesTypeLabel.label}
      </Typography>
      <Box className='personality-head d-flex'>
        <Box>
          {bioData.length > 0 &&
            bioData.map((text, index) => (
              <>
                <Box className='custom-card' key={index}>
                  <CustomCard
                    data={text}
                    onSubmit={handleBioSubmit}
                    editItems={editItems}
                    isEditing={isEditing}
                    notesData={notesData}
                    isNotesType={true}
                    viewItems={viewItems}
                    // width={bioCardWidth || 400}
                    // height={bioCardHeight || 250}
                    styleData={styleData}
                    notesTypeLabel={notesTypeLabel}
                    getContact={getContact}
                    getContactDetails={getContactDetails}
                    contact_id={contact_id}
                    tab='PERSONALITY_TAB'
                    setLoading={setLoading}
                  />
                </Box>
              </>
            ))}
          {bioData.length === 0 && (
            <Box className='add-another-buttons'>
              <CustomButton
                type={'secondary'}
                variant='outlined'
                size={'small'}
                buttonText={t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`)}
                customWidth={96}
                onClick={handleBioOpenPopup}
                iconLeft={<AddIcon sx={{ width: 14 }} />}
              />
            </Box>
          )}

          {isBioPopupOpen && (
            <CustomizedDialogs
              title={notesTypeLabel.label}
              setIsPopupOpen={setIsBioPopupOpen}
              isPopupOpen={isBioPopupOpen}
              onSubmit={handleBioSubmit}
              notesData={notesData}
              setValue={setValue}
              register={register}
            />
          )}
          {isEditing && (
            <CustomizedDialogs
              title={notesTypeLabel.label}
              setIsPopupOpen={setIsEditing}
              isPopupOpen={isEditing}
              onSubmit={handleBioSubmit}
              isEditing={isEditing}
              data={bioData[0]}
              defaultValueString={bioData[0]?.published_bio}
              notesData={notesData}
              // isMorePopupOpen={isMorePopupOpen}
              // setIsMorePopupOpen={setIsMorePopupOpen}
              // editItems={editItems}
              setValue={setValue}
              register={register}
            />
          )}
          {isMorePopupOpen && (
            <CustomizedDialogs
              title={notesTypeLabel.label}
              setIsPopupOpen={setIsMorePopupOpen}
              isPopupOpen={isMorePopupOpen}
              onSubmit={handleBioSubmit}
              // isEditing={isEditing}
              data={bioData[0]}
              notesData={notesData}
              isMorePopupOpen={isMorePopupOpen}
              setIsMorePopupOpen={setIsMorePopupOpen}
              // editItems={editItems}
              isInlineEditing={isInlineEditingRef.current}
              setIsInLineEditing={setIsInLineEditing}
              inlineEditing={inlineEditing}
              selectedType={selectedType}
              setValue={setValue}
              register={register}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

BioAndRecommendation.propTypes = {
  bioCardHeight: PropTypes.number,
  bioCardWidth: PropTypes.number,
  register: PropTypes.func,
  setValue: PropTypes.func,
  handleSave: PropTypes.func,
  currentValues: PropTypes.object,
  isContactView: PropTypes.bool,
  getContact: PropTypes.func,
  contact_id: PropTypes.string,
  getContactDetails: PropTypes.func,
  setLoading: PropTypes.func
};

export default BioAndRecommendation;
