import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { clientPortalMenuSetupApi } from '../../../services/ApiService';
import { GET, POST } from '../../../services/constantService';
import { SCROLL_TIMEOUT } from '../../../utils/common';
import { CustomLoadingOverlayComponent, loadColumnStateFromLocalStorage } from '../../Commons/Utils';
import AsyncSwitch from './AsyncSwitch';
import { ProjectDataContext } from './Context';
import DispositionTable from './DispositionTable';

const clientSuiteMenuItemsColumnDefs = [
  {
    field: 'menuItem',
    headerName: 'Main Menu Item',
    minWidth: 258
  },
  {
    field: 'enable',
    headerName: 'Enable',
    cellRendererFramework: ({ value }) => value
  }
];

const candidateMenuItemsColumnDefs = [
  {
    field: 'menuItem',
    headerName: 'Candidate Menu Items',
    minWidth: 205
  },
  {
    field: 'enable',
    headerName: 'Enable',
    cellRendererFramework: ({ value }) => value
  }
];

const candidateUIElementControlColumnDefs = [
  {
    field: 'menuItem',
    headerName: 'UI Element Controls',
    minWidth: 205
  },
  {
    field: 'enable',
    headerName: 'Enable',
    cellRendererFramework: ({ value }) => value
  }
];

// const disableClientSuiteMainMenuItems = ['Team Info', 'Candidate Compare'];

// const disableCandidateMenuItems = ['Decision', 'Compensation Info', 'Contact Details'];

let clientSuiteMenuItemGridApi, clientSuiteMenuItemColumnApi;
let candidateMenuGridApi, candidateMenuColumnApi;
let candidateUIElementControldApi, candidateUIElementControlColumnApi;

const useQuery = () => {
  const search = useParams();
  return useMemo(() => new URLSearchParams(search), [search]);
};
function ClientPortalMenuSetup() {
  const [, setClientSuiteMenuIsGridReady] = useState(false);
  const [, setCandidateMenuIsGridReady] = useState(false);
  const [, setCandidateUIElementGridReady] = useState(false);
  const clientSuiteMenuItemGridApiRef = useRef(null);
  const candidateMenuItemGridApiRef = useRef(null);
  const assetsMenuItemGridRef = useRef(null);
  const { projectData } = useContext(ProjectDataContext);
  let query = useQuery();

  const formatData = async itemType => {
    const menuItems = await clientPortalMenuSetupApi(GET, `get-all?project_id=${projectData?.id}&item_type=${itemType}`);

    if (menuItems.status >= 400) throw new Error('Failed to fetch data');

    menuItems.data = menuItems.data.sort((a, b) => parseInt(a.long_desc?.split('-')?.[1]) - parseInt(b.long_desc?.split('-')?.[1]));

    return menuItems.data.map(menuItem => {
      const isDisabledItem = menuItem.long_desc?.split('-')?.[0] === 'N';
      return {
        id: menuItem?.id,
        menuItem: menuItem.short_desc,
        enable: <AsyncSwitch disable={isDisabledItem} defaultChecked={menuItem?.enabled} onToggle={checked => onToggleChange(projectData?.id, itemType, menuItem.field_value, checked)} />
      };
    });
  };

  const onToggleChange = async (projectId, itemType, menuItemFieldValue, enabled) => {
    return await clientPortalMenuSetupApi(POST, 'create-if-not-exist', {
      project_id: projectId,
      menu_item: menuItemFieldValue,
      item_type: itemType,
      enabled
    });
  };

  const clientSuiteMenuItemDataSource = {
    getRows: async params => {
      clientSuiteMenuItemGridApi.showLoadingOverlay();
      try {
        const formattedData = await formatData('CLIENT_PORTAL_MAIN_MENU_ITEMS');
        params.successCallback(formattedData, formattedData.length);
      } catch (err) {
        params.failCallback();
      }

      clientSuiteMenuItemGridApi.hideOverlay();
    }
  };

  const candidateMenuItemsDataSource = {
    getRows: async params => {
      candidateMenuGridApi.showLoadingOverlay();

      try {
        const formattedData = await formatData('CANDIDATE_MENU_ITEMS');
        params.successCallback(formattedData, formattedData.length);
      } catch (err) {
        params.failCallback();
      }
      candidateMenuGridApi.hideOverlay();
    }
  };

  const candidateUIElementDataSource = {
    getRows: async params => {
      candidateUIElementControldApi.showLoadingOverlay();
      try {
        const formattedData = await formatData('UI_ELEMENTS_CONTROL');
        params.successCallback(formattedData, formattedData.length);
      } catch (err) {
        params.failCallback();
      }

      candidateUIElementControldApi.hideOverlay();
    }
  };

  const onGridReadyClientSuiteMenu = params => {
    clientSuiteMenuItemGridApi = params.api;
    clientSuiteMenuItemColumnApi = params.columnApi;
    // const columnApi = params.columnApi
    params.columnApi.autoSizeAllColumns();
    loadColumnStateFromLocalStorage('masterSearchContactColumns', {
      clientSuiteMenuItemColumnApi
    });
    // this.setState({ isGridReady: true });
    setClientSuiteMenuIsGridReady(true);
  };

  const onGridReadyCandidateMenu = params => {
    candidateMenuGridApi = params.api;
    candidateMenuColumnApi = params.columnApi;
    // const columnApi = params.columnApi
    params.columnApi.autoSizeAllColumns();
    loadColumnStateFromLocalStorage('masterSearchContactColumns', {
      candidateMenuColumnApi
    });
    // this.setState({ isGridReady: true });
    setCandidateMenuIsGridReady(true);
  };

  const onGridReadyUIElement = params => {
    candidateUIElementControldApi = params.api;
    candidateUIElementControlColumnApi = params.columnApi;
    params.columnApi.autoSizeAllColumns();
    loadColumnStateFromLocalStorage('masterSearchContactColumns', {
      candidateUIElementControlColumnApi
    });
    setCandidateUIElementGridReady(true);
  };

  return (
    <div style={{ display: 'flex', height: '57vh' }}>
      <div id='myGrid' className='ag-theme-alpine mx-2 ag-grid-container' style={{ height: '340px', width: '397px' }}>
        <AgGridReact
          ref={clientSuiteMenuItemGridApiRef}
          onGridReady={onGridReadyClientSuiteMenu}
          enableBrowserTooltips={true}
          datasource={clientSuiteMenuItemDataSource}
          columnDefs={clientSuiteMenuItemsColumnDefs}
          getRowNodeId={data => data.id}
          scrollbarWidth={12}
          rowSelection='multiple'
          defaultColDef={{
            minWidth: 100,
            resizable: true,
            autoSizeStrategy: 'fitGridWidth'
          }}
          frameworkComponents={{
            CustomLoadingOverlayComponent
          }}
          loadingOverlayComponent={'CustomLoadingOverlayComponent'}
          blockLoadDebounceMillis={SCROLL_TIMEOUT}
          suppressHorizontalScroll={false}
          rowModelType='infinite'
        />
      </div>
      <div id='myGrid' className='ag-theme-alpine mx-2 ag-grid-container' style={{ height: '340px', width: '342px' }}>
        <AgGridReact
          ref={candidateMenuItemGridApiRef}
          enableBrowserTooltips={true}
          datasource={candidateMenuItemsDataSource}
          columnDefs={candidateMenuItemsColumnDefs}
          onGridReady={onGridReadyCandidateMenu}
          getRowNodeId={data => data.id}
          scrollbarWidth={12}
          rowSelection='multiple'
          defaultColDef={{
            minWidth: 100,
            resizable: true,
            autoSizeStrategy: 'fitGridWidth'
          }}
          frameworkComponents={{
            CustomLoadingOverlayComponent
          }}
          loadingOverlayComponent={'CustomLoadingOverlayComponent'}
          blockLoadDebounceMillis={SCROLL_TIMEOUT}
          suppressHorizontalScroll={false}
          rowModelType='infinite'
        />
      </div>

      <DispositionTable projectId={projectData?.id ? projectData?.id : query.get('id')} />
      <div id='myGrid' className='ag-theme-alpine mx-2 ag-grid-container' style={{ height: '340px', width: '342px' }}>
        <AgGridReact
          ref={assetsMenuItemGridRef}
          enableBrowserTooltips={true}
          datasource={candidateUIElementDataSource}
          columnDefs={candidateUIElementControlColumnDefs}
          onGridReady={onGridReadyUIElement}
          getRowNodeId={data => data.id}
          scrollbarWidth={12}
          rowSelection='multiple'
          defaultColDef={{
            minWidth: 100,
            resizable: true,
            autoSizeStrategy: 'fitGridWidth'
          }}
          frameworkComponents={{
            CustomLoadingOverlayComponent
          }}
          loadingOverlayComponent={'CustomLoadingOverlayComponent'}
          blockLoadDebounceMillis={SCROLL_TIMEOUT}
          suppressHorizontalScroll={false}
          rowModelType='infinite'
        />
      </div>
    </div>
  );
}

export default ClientPortalMenuSetup;
