//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchTypeList } from '../../../../../actions';

const ProbabilitySelection = props => {
  const { register, className = 'w-100', label = '', name = 'probability', placeholder = 'Probability', InputLabelProps, required, variant = 'standard', ...rest } = props;

  const [options, setOptions] = useState([]);
  const probability = useSelector(state => state.commonReducer.probability);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!probability) {
      dispatch(fetchTypeList('SEARCH_PROBABILITY', 'probability'));
    } else {
      const data = [...probability];
      setOptions(data);
    }
  }, [probability, dispatch]);

  return (
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option;
        }
        return option.short_desc;
      }}
      getOptionSelected={(option, value) => {
        if (!value) {
          return false;
        }
        if (typeof value === 'string') {
          return option.short_desc === value;
        }
        return option.short_desc === value.short_desc;
      }}
      renderInput={params => <TextField {...params} inputRef={register} name={name} InputLabelProps={InputLabelProps} label={label} required={required} placeholder={placeholder} variant={variant} />}
    />
  );
};

ProbabilitySelection.propTypes = {
  register: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool
};

export default ProbabilitySelection;
