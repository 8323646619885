/* eslint-disable react/prop-types */
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { toProperCase } from '../../../utils/common';
import './index.scss';

function OffLimitInfo(props) {
  const { details } = props;
  const { severity, reason, end_date, type, createdByUser } = details;
  const duration = end_date ? new Date(end_date).toLocaleDateString() : 'Indefinite';
  const addedBy = `${createdByUser?.first_name} ${createdByUser?.last_name}`;
  return (
    <Box display='flex' flexDirection='column' className={`offLimits-warning-content ${severity}-off-limits-warning-content`}>
      <Box display='flex' gap='40px' alignItems='center'>
        <Typography variant='h6'>{type} Off-limit</Typography>
        {severity === 'hard' && type === 'Direct' && (
          <Typography variant='body2'>
            <span className='bold'>Added By:</span> {addedBy}
          </Typography>
        )}
      </Box>
      <Box display='flex' gap='20px'>
        <Box display='flex' alignItems='center' justifyContent='center'>
          <WarningIcon sx={{ color: '#856404', fontSize: '30px' }} />
        </Box>
        <Box>
          <Typography variant='body2'>
            <span className='bold'>Duration:</span> {duration}
          </Typography>
          <Typography variant='body2'>
            <span className='bold'>Severity:</span> {toProperCase(severity)}
          </Typography>
        </Box>
        <Typography variant='body2'>
          <span className='bold'>Off-limits Reason:</span> {reason?.replace(/<\/?[^>]+(>|$)/g, '')}
        </Typography>
      </Box>
    </Box>
  );
}

export default OffLimitInfo;
