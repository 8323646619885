//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { forwardRef, useImperativeHandle, useEffect } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TextareaAutosize } from '@mui/material';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const DescriptionEditor = forwardRef((props, ref) => {
  const { stopEditing, value: initialValue } = props;
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState(initialValue);
  const [currentValue, setCurrentValue] = React.useState(initialValue);

  const handleClose = () => {
    setOpen(false);
    stopEditing();
  };

  const handleSave = () => {
    setValue(currentValue);
  };

  useEffect(() => {
    if (value !== initialValue) {
      handleClose();
    }
    // eslint-disable-next-line
  }, [value, initialValue]);

  const handleChange = text => {
    setCurrentValue(text);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => value,
      afterGuiAttached: () => {}
    };
  });

  return (
    <div style={{ position: 'relative' }}>
      <Dialog maxWidth='sm' onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle>Description</DialogTitle>
        <DialogContent dividers>
          <TextareaAutosize onChange={e => handleChange(e.target.value)} rows={3} rowsMax={6} cols={50} value={currentValue} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button autoFocus variant='contained' onClick={handleSave} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

DescriptionEditor.displayName = 'DescriptionEditor';

DescriptionEditor.propTypes = {
  stopEditing: PropTypes.func,
  value: PropTypes.string
};

export default DescriptionEditor;
