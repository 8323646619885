import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const CustomTabPanel = props => {
  const { children, value, index, ...rest } = props;

  return (
    <Box role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...rest}>
      {value === index && (
        <Box className='my-custom-box'>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default CustomTabPanel;
