import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Restore from '@mui/icons-material/LockReset';
import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const EditableMenuItem = ({ item, handleMenuItemSave, handleMenuItemCancel }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempMenuItem, setTempMenuItem] = useState(item?.custom_label?.label || item.short_desc);

  // Handle edit button click
  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Handle tick (save)
  const handleSave = (_e, isReset = false) => {
    setIsEditing(false);
    if (isReset && (item.short_desc === item?.custom_label?.label || !item?.custom_label?.label)) return;

    if (!isReset) {
      if (item?.custom_label?.label && tempMenuItem === item?.custom_label?.label) return;
      if (!item?.custom_label?.label && tempMenuItem === item.short_desc) return;
    }

    const newValue = isReset ? item.short_desc : tempMenuItem;
    handleMenuItemSave(newValue, item?.field_value);
  };

  // Handle cross (cancel)
  const handleCancel = () => {
    setIsEditing(false);
    setTempMenuItem(item.short_desc); // Reset to the original value
    handleMenuItemCancel?.(item.id);
  };

  return (
    <Box>
      {isEditing ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            value={tempMenuItem}
            onChange={e => setTempMenuItem(e.target.value)}
            variant='outlined'
            size='small'
            InputProps={{
              style: { fontSize: '13px' }
            }}
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '13px !important'
              }
            }}
          />
          <IconButton onClick={handleSave} sx={{ color: 'white', padding: 0, ml: 1 }}>
            <Tooltip title='Done' placement='top'>
              <DoneIcon className='fs-16' cursor='pointer' color='primary' />
            </Tooltip>
          </IconButton>
          <IconButton onClick={handleCancel} sx={{ color: 'white', padding: 0 }}>
            <Tooltip title='Cancel' placement='top'>
              <CloseIcon className='fs-16' cursor='pointer' color='error' />
            </Tooltip>
          </IconButton>
          <IconButton onClick={e => handleSave(e, true)} sx={{ color: 'white', padding: 0 }}>
            <Tooltip title='Reset' placement='top'>
              <Restore className='fs-16 ml-2' cursor='pointer' color='primary' />
            </Tooltip>
          </IconButton>
        </Box>
      ) : (
        <Typography onClick={handleEditClick} sx={{ cursor: 'text', fontSize: '13px' }}>
          {item?.custom_label?.label || item.short_desc}
        </Typography>
      )}
    </Box>
  );
};

export default EditableMenuItem;

EditableMenuItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    short_desc: PropTypes.string.isRequired,
    field_value: PropTypes.string,
    custom_label: PropTypes.object
  }).isRequired,
  handleMenuItemSave: PropTypes.func.isRequired,
  handleMenuItemCancel: PropTypes.func
};
