import { Box, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import CustomDropdown from '../../../../../components/common/CustomDropdown';
import CustomNumbericField from '../../../../../components/common/StyledComponents/CustomNumericField';
import { picklistDropDownApis } from '../../../../../services/ApiService';
import { DROP_DOWN_PICKLIST, GET } from '../../../../../services/constantService';
import { useLanguageHooks } from '../../../../../utils/Hooks/UseLanguageHook';
import useApi from '../../../../../utils/Hooks/useApiHook';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';
import { ProjectDataContext } from '../../Context';

export default function TargetCompensation() {
  const { projectData, handleUniversalState } = useContext(ProjectDataContext);

  //Translate
  const { t } = useLanguageHooks([
    TRANS_KEYS.ADD_PROJECT_LABEL_CURRENCY_TYPE,
    TRANS_KEYS.ADD_PROJECT_INPUT_MIN_COMPENSATION,
    TRANS_KEYS.ADD_PROJECT_INPUT_MAX_COMPENSATION,
    TRANS_KEYS.CONTACTS_BONUS,
    TRANS_KEYS.EQUITY,
    TRANS_KEYS.TOTAL_BASE_AND_BONUS
  ]);

  //API INTEGRATION
  const { data: currencyTypeResponse } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.CURRENCIES);
    }
  });

  const handleUniversalStateLocally = updatedVal => {
    const event = {
      //TODO
      target: {
        name: 'compensation',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };

  const [compensation, setCompensation] = useState({
    currency: '',
    min_compensation: '',
    max_compensation: '',
    target_compensation_bonus: '',
    target_compensation_equity: '',
    loaded: false
  });
  const handleChange = (key, value) => {
    compensation[key] = value;
    handleUniversalStateLocally({ ...compensation });
  };

  useEffect(() => {
    console.log('useEffect compensation');

    const compensationNew = {
      currency: projectData?.bd_currency_values,
      min_compensation: projectData?.min_compensation,
      max_compensation: projectData?.max_compensation,
      target_compensation_bonus: projectData?.target_compensation_bonus,
      target_compensation_equity: projectData?.target_compensation_equity,
      loaded: true
    };
    if (!compensation.loaded) {
      setCompensation(compensationNew);
    }
  }, [projectData]);
  return (
    <Box>
      <Grid container spacing={2} rowSpacing={2}>
        <Grid item xs={4}>
          <CustomDropdown
            options={currencyTypeResponse?.data ?? []}
            label={t(`${TRANS_KEYS.ADD_PROJECT_LABEL_CURRENCY_TYPE}:key`)}
            value={compensation?.currency}
            className={'w-100'}
            freeSolo={false}
            onChange={(event, newEvent) => {
              handleChange('currency', newEvent);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomNumbericField
            value={compensation.min_compensation}
            label={t(`${TRANS_KEYS.ADD_PROJECT_INPUT_MIN_COMPENSATION}:key`)}
            sx={{
              width: '100%'
            }}
            onChange={value => {
              handleChange('min_compensation', value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomNumbericField
            value={compensation.max_compensation}
            label={t(`${TRANS_KEYS.ADD_PROJECT_INPUT_MAX_COMPENSATION}:key`)}
            sx={{
              width: '100%'
            }}
            onChange={value => {
              handleChange('max_compensation', value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomNumbericField
            value={compensation.target_compensation_bonus}
            label={t(`${TRANS_KEYS.CONTACTS_BONUS}:key`)}
            sx={{
              width: '100%'
            }}
            onChange={value => {
              handleChange('target_compensation_bonus', value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomNumbericField
            value={compensation.target_compensation_equity}
            label={t(`${TRANS_KEYS.EQUITY}:key`)}
            sx={{
              width: '100%'
            }}
            onChange={value => {
              handleChange('target_compensation_equity', value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomNumbericField
            value={+compensation.target_compensation_bonus + +compensation.min_compensation + 0}
            label={t(`${TRANS_KEYS.TOTAL_BASE_AND_BONUS}:key`)}
            disabled
            sx={{
              width: '100%'
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
