//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef, useCallback } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { fetchPickList, fetchTypeList } from '../../../../actions';
import CandidateStageSelection from '../../../../components/CandidateStageSelection';
import { POST, PUT, WARNING, SUCCESS, ERROR, PRODUCT_ONE } from '../../../../services/constantService';
import { acquireLockApi, candidateDataApi, releaseLockApi } from '../../../../services/ApiService';
import { unableMessage, VALIDATION_MESSAGE, successMessage, requireMessage } from '../../../../services/MessageService';
import { RELOAD_ALL_CANDIDATE_GRID } from '../../../../types';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

// eslint-disable-next-line react/display-name
const StageEditor = forwardRef((props, ref) => {
  const { value, data: rowData, api, node, stopEditing, SetLoader } = props;
  const ReasonOrDQReason = value === PRODUCT_ONE.REJECTED_STAGE ? rowData.dq_reason || [] : rowData.reason;
  const [stage, setStage] = useState(value);
  const [currentStage, setCurrentStage] = useState(value);
  // const [reason, setReason] = useState(ReasonOrDQReason);
  const [stageAndReason, setStageAndReason] = useState({});
  const [currentReason, setCurrentReason] = useState(ReasonOrDQReason);
  const reasonRef = useRef();
  const [activityReasonOptions, setActivityReasonOptions] = useState([]);
  const inputRef = useRef();
  const [searchStages, setSearchStages] = useState({});
  const candidateStages = useSelector(state => state.commonReducer.candidateStages);
  const DqReasonOptions = useSelector(state => state.commonReducer.PRODUCT_ONE_REASONS);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!DqReasonOptions) {
      dispatch(fetchTypeList('PRODUCT_ONE_REASONS'));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!candidateStages) {
      dispatch(fetchPickList('candidatestages', 'candidateStages'));
    } else {
      let stageReasonMap = {};
      candidateStages
        ?.filter(obj => obj)
        .forEach(stageObj => {
          stageReasonMap[stageObj.name] = stageObj.reason;
        });

      if (DqReasonOptions) {
        stageReasonMap[PRODUCT_ONE.REJECTED_STAGE] = DqReasonOptions.map(ele => ele.short_desc);
      }
      setSearchStages(stageReasonMap);
      const reasons = stageReasonMap && stageReasonMap[value];
      setActivityReasonOptions(reasons);
    }
  }, [DqReasonOptions, value, candidateStages, dispatch]);

  const [open, setOpen] = React.useState(true);

  const handleClose = useCallback(() => {
    stopEditing();
    setOpen(false);
  }, [stopEditing]);

  const qualifiedStages = [
    'Client Interviewed and Declined',
    'Client Declined Background',
    'KGP Partner Interviewed and Declined',
    'KGP Declined',
    'Candidate Declined',
    'Offer Rejected',
    'Offer Withdrawn',
    'Withdrawn'
  ];

  const validReason = useCallback(
    (stage, reason) => {
      return qualifiedStages.includes(stage) ? reason && searchStages[stage].includes(reason) : true;
    },
    [qualifiedStages, searchStages]
  );

  const handleSave = async () => {
    if (!currentStage) {
      const message = requireMessage('Stage', 'is');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    let { status, data } = await acquireLockApi(rowData.contact.id);
    if (status && status === 200 && data.message === undefined) {
      dispatch({ type: RELOAD_ALL_CANDIDATE_GRID });
      if (!validReason(currentStage, currentReason) || (currentStage === PRODUCT_ONE.REJECTED_STAGE && !currentReason?.length)) {
        enqueueSnackbar(`${VALIDATION_MESSAGE.required} "${currentStage}"`, {
          variant: WARNING
        });
        return;
      }
      setStage(currentStage);
      // setReason(currentReason);
      setStageAndReason({ stage: currentStage, reason: currentReason });
      if (currentStage) {
        handleClose();
      }
    } else {
      //if (data.isAdmin) {
      enqueueSnackbar(data.message, {
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: key => (
          <>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key);
                releaseLock();
              }}
            >
              Force Unlock
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                closeSnackbar(key);
              }}
            >
              CLOSE
            </Button>
          </>
        )
      });
      /* }
      else {
        enqueueSnackbar(data.message, { variant: WARNING });
      } */
    }
  };

  const releaseLock = useCallback(
    async ForceUnlock => {
      await releaseLockApi(rowData.contact.id);
      if (ForceUnlock) {
        handleSave();
      }
    },
    [rowData.contact.id]
  );

  const handleChange = (e, data) => {
    setCurrentReason(null);
    if (data?.name === PRODUCT_ONE.REJECTED_STAGE) {
      setActivityReasonOptions(DqReasonOptions.map(ele => ele.short_desc));
    } else setActivityReasonOptions(data ? data.reason : []);
    reasonRef.current.focus();
    setCurrentStage(data ? data.name : null);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => stage,
      afterGuiAttached: () => {}
    };
  });

  useEffect(() => {
    return () => {
      const saveEdit = async () => {
        if (!stageAndReason.stage || (stageAndReason.stage === value && stageAndReason.reason === ReasonOrDQReason)) {
          return;
        }
        SetLoader(true);
        let oldData;
        const candidatePayload = {
          stage: stageAndReason.stage,
          reason: stageAndReason.stage === PRODUCT_ONE.REJECTED_STAGE ? null : stageAndReason.reason,
          dq_reason: stageAndReason.stage === PRODUCT_ONE.REJECTED_STAGE ? stageAndReason.reason.join(', ') : ''
        };
        const { status, data } = await candidateDataApi(PUT, rowData.id, candidatePayload);
        if (status === 200) {
          const message = successMessage('Stage and/or Reason', 'updated');
          enqueueSnackbar(message, { variant: SUCCESS });
          const payload = {
            activity: {
              activity: 'Stage Change',
              type: 'None',
              direction: 'Incoming',
              subject: 'Stage Change',
              comments: `Stage has been changed from ${value} to ${stageAndReason.stage}`,
              stage: stageAndReason.stage
            }
          };
          await candidateDataApi(POST, rowData.id, payload, 'activities');
          oldData = {
            ...rowData,
            stage: stageAndReason.stage,
            reason: stageAndReason.stage === PRODUCT_ONE.REJECTED_STAGE ? null : stageAndReason.reason,
            dq_reason: stageAndReason.stage === PRODUCT_ONE.REJECTED_STAGE ? stageAndReason.reason : []
          };
        } else {
          const message = unableMessage('stage', 'update');
          enqueueSnackbar(data?.message || message, { variant: ERROR });
          setStage(value);
          // setReason(ReasonOrDQReason);
          setStageAndReason({ stage: value, reason: ReasonOrDQReason });
          oldData = {
            ...rowData,
            stage: value,
            reason: value === PRODUCT_ONE.REJECTED_STAGE ? null : ReasonOrDQReason,
            dq_reason: value === PRODUCT_ONE.REJECTED_STAGE ? ReasonOrDQReason : []
          };
        }
        releaseLock(false);
        SetLoader(false);

        node && node.setData(oldData);
        api && api.refreshCells();
      };
      if (stage) {
        saveEdit().then(null);
      }
    };
  }, [stageAndReason, value, rowData, enqueueSnackbar, api, releaseLock]);

  return (
    <div style={{ position: 'relative' }}>
      <Dialog maxWidth='md' onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle style={{ background: '#2a364c', color: '#fff' }}>Select Stage & Reason</DialogTitle>
        <DialogContent dividers style={{ minWidth: '400px', maxWidth: '400px' }}>
          <CandidateStageSelection
            // disableClearable
            label={
              <label>
                Stage<span className='MuiInputLabel-asterisk'>*</span>
              </label>
            }
            // required={true}
            inputRef={inputRef}
            style={{ padding: 10 }}
            value={currentStage}
            onChange={handleChange}
          />
          <Autocomplete
            style={{ padding: '10px' }}
            value={currentStage === PRODUCT_ONE.REJECTED_STAGE ? currentReason || [] : currentReason}
            onChange={(e, data) => setCurrentReason(data)}
            options={activityReasonOptions ? [...activityReasonOptions].sort() : []}
            multiple={currentStage === PRODUCT_ONE.REJECTED_STAGE}
            renderInput={params => (
              <TextField
                {...params}
                ref={reasonRef}
                required={[...qualifiedStages, PRODUCT_ONE.REJECTED_STAGE].includes(currentStage)}
                label={currentStage === PRODUCT_ONE.REJECTED_STAGE ? 'DQ Reason' : 'Reason'}
                placeholder={currentStage === PRODUCT_ONE.REJECTED_STAGE ? 'DQ Reason' : 'Reason'}
              />
            )}
          />
        </DialogContent>
        <DialogActions className='page-content-footer'>
          <Button onClick={handleClose} className='page-btn-cancel'>
            Cancel
          </Button>
          <Button autoFocus variant='contained' onClick={handleSave} className='page-btn-save'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default StageEditor;

StageEditor.propTypes = {
  value: PropTypes.string,
  data: PropTypes.object,
  api: PropTypes.object,
  stopEditing: PropTypes.func,
  SetLoader: PropTypes.func,
  node: PropTypes.object
};
